const lightBlue = require('@material-ui/core/colors/lightBlue');
const yellow = require('@material-ui/core/colors/yellow');

// Colors
const primeColour = '#65C9A5';
const infoColor = lightBlue.default[500];
const warningColor = yellow.default[500];
const placeholderColor = "#dcdcdc";
const textColor = 'rgba(0, 0, 0, 0.87)';
const iconColor = '#7f898b';
const mapDarkColor = '#777777';
const mapWithColor = '#b6e59e';
const mapLightColor = '#eeeeee';
const btnLabelColor = '#ffffff';
const playmining = '#f53b78';
const jobtribes = '#c2b466';
const puzzle = '#843893';
const luckyfarmer = '#6aa31e';
const pmportal = '#c71622';
const nftdep = '#efb87b';
const cookinburger = '#944d11';
const graffitiracer = '#ff2525';
const dragonramen = '#af1928';
const lostarchvie = '#abd2c3';
const soulfusers = '#167cfe';
const roguerollrulers = '#d5ac4d';
const bouncybunny = '#f2adff';
const pictree = '#0ec8e7';
const statsTotal = '#7384e1';
const statsAds = '#4cc9c9';
const statsOrg = '#53bb90';
const statsTier1 = '#b8deee';
const statsTier2 = '#d3dbf6';
const statsTier3 = '#86c285';

// Spacing
const drawerWidth = 200;
const notificationCenterWidth = 300;
const MobileBreakpoint = 960;

// fontSize
const sidebarFontSize = 14;

// Chart
const chartLineWidth = 4;
const chartSymbolSize = 8;
const firstTimeColor = '#61cdbb';
const conversionColor = '#105375';
const regColor = '#fb8072';
const mapColor = '#4fbb93';
const pieTier = { 'Tier1':"#B2DF8A", 'Tier2': "#FF7F00", 'Tier3': "#FDBF6F" };
const pieGender = { 'Male': "#A6CEE3", 'Female':"#FB9A99" };
const pieAge = { '18-24':"#B2DF8A", '25-34': "#FF7F00", '35-44': "#FDBF6F", '45-54':"#CAB2D6", '55-64':"#A6CEE3", '65+' : "#9e9e9e" };
const acquisitionPallette = ["#FDBF6F", "#FB9A99", "#33A02C", "#A6CEE3", "#CAB2D6"];
const puzzleUserEventPallette = ["#FDBF6F", "#4cb992", "#FB9A99", "#A6CEE3", "#CAB2D6"];
const geoPallette = ["#FDBF6F", "#B2DF8A"];
const twoColorPallette = ["#61cdbb", "#f47560"];
const successColor = "#4cb992";
const failedColor = "#FB9A99";
const genericColorPallette = ["#FDBF6F", "#4cb992", "#FB9A99", "#A6CEE3", "#CAB2D6", "#45ccce", "#e5a9f4", "#ea7b7b", "#60a798", "#5572c3"];
const fiatColor = "#9c26b0";
// Map
const mapPolygonMaxColor = '#4cb992';
const mapPolygonMinColor = '#ccebc5';

export {
    primeColour,
    infoColor,
    warningColor,
    placeholderColor,
    textColor,
    iconColor,
    mapDarkColor,
    mapWithColor,
    mapLightColor,
    btnLabelColor,
    playmining,
    jobtribes,
    puzzle,
    luckyfarmer,
    pmportal,
    nftdep,
    cookinburger,
    graffitiracer,
    dragonramen,
    lostarchvie,
    soulfusers,
    roguerollrulers,
    bouncybunny,
    pictree,
    statsTotal,
    statsAds,
    statsOrg,
    statsTier1,
    statsTier2,
    statsTier3,
    drawerWidth,
    notificationCenterWidth,
    MobileBreakpoint,
    sidebarFontSize,
    chartLineWidth,
    chartSymbolSize,
    firstTimeColor,
    conversionColor,
    regColor,
    mapColor,
    pieTier,
    pieGender,
    pieAge,
    acquisitionPallette,
    puzzleUserEventPallette,
    geoPallette,
    twoColorPallette,
    successColor,
    failedColor,
    mapPolygonMaxColor,
    mapPolygonMinColor,
    genericColorPallette,
    fiatColor,
}
