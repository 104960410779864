import { JTCB } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    loading: {
        owner: false,
        scholar: false,
        perOwner: false,
        perOwnerMaxMin: false,
        amuletLending: false,
        deapCheck: false,
        ownerByCountry: false,
        scholarByCountry: false,
        countStatsByOwner: false,
    },
    focusTabIndex: 0,
    dataDeapCheck: {
        amount: 0,
        count: 0,
    },
    dataOwner: {
        total: 0,
        totalPrev: 0,
        new: 0,
        newPrev: 0,
        stop: 0,
        stopPrev: 0,
    },
    dataScholar: {
        total: 0,
        totalPrev: 0,
        new: 0,
        newPrev: 0,
        stop: 0,
        stopPrev: 0,
    },
    dataPerOwner: [],
    dataLendingCount: {
        current: 0,
        prev: 0,
    },
    dataDeapCheckAmount: {
        current: 0,
        prev: 0,
    },
    dataUniqueOwnerCountByDate: {
        date: '-',
        count: 0
    },
    dataUniqueScholarCountByDate: {
        date: '-',
        count: 0
    },
    dataTotalAmuletCountByDate: {
        date: '-',
        count: '-'
    },
    dataTotalDepCountByDate: {
        date: '-',
        count: '-'
    },
    dataTotalScholarDepCountByDate: {
        date: '-',
        count: '-'
    },
    dataOwnerByCountry: [],
    dataScholarByCountry: [],
    dataCountScholarByOwner: [],
    dataCountAmuletByOwner: [],
    dataCountDepByOwner: [],
    dataCountDepByScholar: [],
    dataNumOwner: {
        stop: "-",
        total: "-",
    },
    dataNumScholar: {
        stop: "-",
        total: "-",
    },
};

export default function scholarReducer(state = initialState, action) {
    switch (action.type) {
        case JTCB.SET_SCHOLAR_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case JTCB.SET_FOCUS_TAB_INDEX:
            return {
                ...state,
                focusTabIndex: action.payload
            };
        case JTCB.SET_OVERVIEW_OWNER_DATA:
            return {
                ...state,
                dataOwner: action.payload
            };
        case JTCB.SET_OVERVIEW_SCHOLAR_DATA:
            return {
                ...state,
                dataScholar: action.payload
            };
        case JTCB.SET_PER_OWNER_DATA:
            return {
                ...state,
                dataPerOwner: action.payload
            };
        case JTCB.SET_LENDING_COUNT:
            return {
                ...state,
                dataLendingCount: action.payload
            };
        case JTCB.SET_DEAP_CHECK_AMOUNT:
            return {
                ...state,
                dataDeapCheckAmount: action.payload
            };
        case JTCB.SET_UNIQUE_OWNER_COUNT_BY_DATE:
            return {
                ...state,
                dataUniqueOwnerCountByDate: action.payload
            };
        case JTCB.SET_UNIQUE_SCHOLAR_COUNT_BY_DATE:
            return {
                ...state,
                dataUniqueScholarCountByDate: action.payload
            };
        case JTCB.SET_OWNER_BY_COUNTRY:
            return {
                ...state,
                dataOwnerByCountry: action.payload
            };
        case JTCB.SET_SCHOLAR_BY_COUNTRY:
            return {
                ...state,
                dataScholarByCountry: action.payload
            };
        case JTCB.SET_TOTAL_AMULET_BY_DATE:
            return {
                ...state,
                dataTotalAmuletCountByDate: action.payload
            };
        case JTCB.SET_TOTAL_DEP_BY_DATE:
            return {
                ...state,
                dataTotalDepCountByDate: action.payload
            };
        case JTCB.SET_TOTAL_SCHOLAR_DEP_BY_DATE:
            return {
                ...state,
                dataTotalScholarDepCountByDate: action.payload
            };
        case JTCB.SET_COUNT_SCHOLAR_BY_OWNER:
            return {
                ...state,
                dataCountScholarByOwner: action.payload
            };
        case JTCB.SET_COUNT_AMULET_BY_OWNER:
            return {
                ...state,
                dataCountAmuletByOwner: action.payload
            };
        case JTCB.SET_COUNT_DEP_BY_OWNER:
            return {
                ...state,
                dataCountDepByOwner: action.payload
            };
        case JTCB.SET_COUNT_DEP_BY_SCHOLAR:
            return {
                ...state,
                dataCountDepByScholar: action.payload
            };
        case JTCB.SET_NUM_OWNER:
            return {
                ...state,
                dataNumOwner: action.payload
            };
        case JTCB.SET_NUM_SCHOLAR:
            return {
                ...state,
                dataNumScholar: action.payload
            };
        default:
            return state
    }
}
