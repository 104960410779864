import { NFTDEP } from '../constants';
import moment from "moment";

const initialState  = {
    todaysDate: '-',
    loading: {
        todaysDepSales: false,
        allDepSales: false,
        allDepFees: false,
        allTxCount: false,
        txAuction: false,
        txStore: false,
        activeWallet: false,
        txUniqueNew: false,
        txUniqueNewStore: false,
        txBlindSnap: false,
        ovStaking: false,
    },
    todaysDepSales: {
        auction: {
            count: '-',
            dep: '-',
        },
        store: {
            count: '-',
            dep: '-',
        },
    },
    allDepSales: {
        total: '-',
        prev: '-',
        auction: '-',
        store: '-',
    },
    allDepFees: {
        total: '-',
        prev: '-',
        auction: '-',
        withdraw: '-',
    },
    allTxCount: {
        total: '-',
        prev: '-',
        auction: '-',
        store: '-',
    },
    activeWallet: {
        current: '-',
        prev: '-',
    },
    txUniqueAndNew: {
        uniqueSellNew: {
            current: '-',
            prev: '-'
        },
        uniqueSellRepeat: {
            current: '-',
            prev: '-'
        },
        uniqueBuyNew: {
            current: '-',
            prev: '-'
        },
        uniqueBuyRepeat: {
            current: '-',
            prev: '-'
        },
    },
    txStore: {
        uniqueStoreNew: {
            current: '-',
            prev: '-'
        },
        uniqueStoreRepeat: {
            current: '-',
            prev: '-'
        },
    },
    txAuctionStats: [],
    txStoreStats: [],
    txFiatStats: [],
    txFirstTimePurchaseStats: {},
};

export default function nftDepReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case NFTDEP.SET_TODAYS_SALES_DATA:
            return {
                ...state,
                todaysDepSales: action.payload
            };
        case NFTDEP.SET_ALL_DEP_SALES_DATA:
            return {
                ...state,
                allDepSales: action.payload
            };
        case NFTDEP.SET_ALL_DEP_FEES_DATA:
            return {
                ...state,
                allDepFees: action.payload
            };
        case NFTDEP.SET_ALL_TX_COUNT_DATA:
            return {
                ...state,
                allTxCount: action.payload
            };
        case NFTDEP.SET_MAX_TX_DATA:
            return {
                ...state,
                maxTx: action.payload
            };
        case NFTDEP.SET_ACTIVE_WALLET_DATA:
            return {
                ...state,
                activeWallet: action.payload
            };
        case NFTDEP.SET_TX_UNIQUE_NEW_DATA:
            return {
                ...state,
                txUniqueAndNew: action.payload
            };
        case NFTDEP.SET_TX_STORE_UNIQUE_NEW_DATA:
            return {
                ...state,
                txStore: action.payload
            };
        case NFTDEP.SET_TX_AUCTION_STATS_DATA:
            return {
                ...state,
                txAuctionStats: action.payload
            };
        case NFTDEP.SET_TX_STORE_STATS_DATA:
            return {
                ...state,
                txStoreStats: action.payload
            };
        case NFTDEP.SET_TX_FIAT_STATS_DATA:
            return {
                ...state,
                txFiatStats: action.payload
            };
        case NFTDEP.SET_TX_FIRST_TIME_PURCHASE_STATS_DATA:
            return {
                ...state,
                txFirstTimePurchaseStats: action.payload
            };
        default:
        return state
    }
}
