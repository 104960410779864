import { DATA_LOAD, DATA } from '../../constants';

const initialState  = {
    dataLoaded: {},
    gameOptionLoader: false,
    campaignOption: [],
    gameSelected: "",
    campaignSelected: "",
};

export default function dataReducer(state = initialState, action) {
    switch (action.type) {
        case DATA_LOAD.SET_DATA_LOADED:
            return {
                ...state,
                dataLoaded: action.payload
            };
        case DATA.SET_GAME_OPTION_LOADER:
            return {
                ...state,
                gameOptionLoader: action.payload
            };
        case DATA.SET_CAMPAIGN_OPTION:
            return {
                ...state,
                campaignOption: action.payload
            };
        case DATA.SET_GAME_SELECTED:
            return {
                ...state,
                gameSelected: action.payload
            };
        case DATA.SET_CAMPAIGN_SELECTED:
            return {
                ...state,
                campaignSelected: action.payload
            };
        default:
            return state
    }
};
