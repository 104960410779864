import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setPictreeDataRegistTrend,
  setPictreeDataRegistTable,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";
import axios from "axios";

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;

export function* getPictreeDataRegistTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlQueryRegistTrend = {
      query: `
        SELECT DATE_FORMAT(CONVERT_TZ(registered_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY, COUNT(*) AS REG
        FROM player
        WHERE CONVERT_TZ(registered_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(CONVERT_TZ(registered_datetime, 'UTC', 'Asia/Tokyo'))
        ORDER BY registered_datetime ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryRegistPmidTrend = {
      query: `
        SELECT
          DATE_FORMAT(CONVERT_TZ(occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
          COUNT(*) AS REG
        FROM player ply
        JOIN account_connect_for_playmining acnp ON ply.id = acnp.player_id
        JOIN player_event pe ON ply.id = pe.player_id
        WHERE CONVERT_TZ(occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND pe.type = 'CONNECTED_TO_PLAYMINING'
        GROUP BY DATE(CONVERT_TZ(occurred_datetime, 'UTC', 'Asia/Tokyo'))
        ORDER BY occurred_datetime ASC;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempRegistTrend, dataTempRegistPmidTrend] = yield all([
      call(
        getDataFromSxi,
        sqlQueryRegistTrend,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
      call(
        getDataFromSxi,
        sqlQueryRegistPmidTrend,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
    ]);

    // Set xAxisDate
    let xAxisDate = [],
      dataArray = [],
      pmIdArray = [],
      recordArray = [];
    const daysArray = H.FormatDateHelper.getDaysListBetweenDays(
      rangeTarget[0],
      rangeTarget[1]
    );
    _.each(daysArray, function (data) {
      xAxisDate.push(data);
    });

    xAxisDate.forEach((date) => {
      // Check if the date exists in the data array
      const data = _.find(
        dataTempRegistTrend,
        (item) => _.get(item, "DAY") === date
      );
      if (data) {
        // console.log(`Date: ${date}, Value: ${_.get(data, '[1].longValue')}`);
        dataArray.push(_.get(data, "REG"));
      } else {
        // console.log(`Date: ${date}, No data available`);
        dataArray.push(0);
      }

      // PMID reg
      const pmidData = _.find(dataTempRegistPmidTrend, item => _.get(item, 'DAY') === date);
      if (pmidData) {
        pmIdArray.push(_.get(pmidData, 'REG'));
      } else {
        pmIdArray.push(0);
      }
    });

    const registCountData = {
      date: xAxisDate,
      reg: dataArray,
      pmid: pmIdArray,
    };
    recordArray.push(registCountData);

    yield put(setPictreeDataRegistTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataRegistTable(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlQueryRegistTrend = {
      query: `
          SELECT *
          FROM DenchuRegistEvtProd
          WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
          BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          ORDER BY logDate DESC LIMIT 20;`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempRegist] = yield all([
      call(
        getDataFromRDS,
        sqlQueryRegistTrend,
        ENDPOINT.GET_DATA_USERS,
        jwtToken
      ),
    ]);
    const dataRegist = JSON.parse(dataTempRegist.body);

    let recordArray = [],
      eachData = {};
    for (let data of dataRegist) {
      const response = yield axios.get(
        `https://nominatim.openstreetmap.org/reverse?lat=${parseFloat(
          data[8]["stringValue"]
        )}&lon=${parseFloat(
          data[9]["stringValue"]
        )}&format=json&accept-language=ja`
      );
      eachData.user = data[2]["stringValue"];
      eachData.asset_id = data[4]["stringValue"];
      eachData.address = response.data.display_name;
      eachData.dateTime = moment(data[10]["longValue"])
        .tz("Asia/Tokyo")
        .format("YYYY/MM/DD hh:mm A");
      recordArray.push(eachData);
      eachData = {};
    }
    yield put(setPictreeDataRegistTable(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_REGIST_TREND, getPictreeDataRegistTrend);
  yield takeEvery(PICTREE.GET_REGIST_TABLE, getPictreeDataRegistTable);
}
