import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import OverviewStyles from 'pages/NftDep/Auction/OverviewStyles';
import { setCurrentPage, setFocusTabIndex, getSalesOverviewData, getTodaysSalesData } from 'actions';
import * as C from 'components';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
    },
    titleTodaysSales: {
        paddingTop: 8,
        paddingBottom: 16,
        fontSize: 12,
    },
    fillRemailSpace: {
        flex: 'auto'
    },
    sectionTop: {
        marginTop: -16,
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            marginTop: 8
        },
    },
}));

const Overview = ({
    setCurrentPage,
    focusTabIndex,
    setFocusTabIndex,
    getSalesOverviewData,
    getTodaysSalesData,
    loading,
}) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFocusTabIndex(newValue);
    };

    useEffect(() => {
        setCurrentPage("nftdep/overview");
        getSalesOverviewData();
    }, []);

    const updateTodaySales = () => {
        getTodaysSalesData();
    };

    return (
        <C.ContentWrapper>
            <Grid container direction="row" className={classes.sectionTop} justifyContent="space-between">
                <Grid item>
                    <div className={classes.titleTodaysSales}>本日の売上:
                    {loading.todaysDepSales
                        ?   <CircularProgress size={12} style={{ marginLeft: 6, marginTop: 2 }}  />
                        :   <IconButton onClick={updateTodaySales} style={{ padding: 3 }}>
                                <RefreshIcon style={{ fontSize: 16 }} />
                            </IconButton>
                    }
                    </div>
                    <C.TodaysSales />
                </Grid>
                <Grid item className={classes.fillRemailSpace}></Grid>
                <Grid item>
                    <C.CalendarRangePicker
                        mDate={'2022/10/17'}
                        page="nftdep/overview"
                    />
                </Grid>
            </Grid>
            <C.SalesStats />
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        TabIndicatorProps={{style: {background:'#65c9a5', height: 4}}}
                    >
                        <Tab label="国別" {...a11yProps(0)} />
                        <Tab label="ゲーム別" {...a11yProps(1)} />
                        {/* <Tab disabled label="広告別" {...a11yProps(2)} /> */}
                        <Tab label="PMID別" {...a11yProps(2)} />
                    </Tabs>
                </AppBar>
            </div>
            <TabPanel value={value} index={0}>
                <C.ByCountry />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <C.ByGame />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                <C.ByCampaign />
            </TabPanel> */}
            <TabPanel value={value} index={2}>
                <C.ByPmid />
            </TabPanel>
        </C.ContentWrapper>
    );
};

Overview.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    focusTabIndex: state.nftDep.focusTabIndex,
    loading: state.nftDep.loading,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setFocusTabIndex: (param) => dispatch(setFocusTabIndex(param)),
    getSalesOverviewData: () => dispatch(getSalesOverviewData()),
    getTodaysSalesData: () => dispatch(getTodaysSalesData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(OverviewStyles, { withTheme: true })(Overview));
