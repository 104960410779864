const GamePuzzleStyles = theme => ({
    root: {
        display: 'flex',
        position: 'fixed',
        backgroundColor: '#fafafa',
        zIndex: 1000,
        width: '100%',
    },
    pageSectionTop: {
        marginTop: 44,
    },
    gridSection: {
        marginTop: theme.spacing(1),
    },
    cardHeadSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleAction: {
        //width: '2%',
        marginLeft: 'auto'
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
    cardContentSection: {
        display: 'flex',
    },
    cardContentSectionChart: {
        marginTop: 32,
        display: 'flex',
    },
});

export default GamePuzzleStyles;