// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from '@material-ui/core/styles';

const RankTable = ({
    title,
    loading,
    tableData,
    columnData,
    height,
    search,
}) => {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return(
        <MaterialTable
            isLoading={isLoading}
            style={{padding: 16, fontSize: 14, height: height, overflow: "scroll"}}
            title={<div style={{fontSize: 18, fontWeight: 500, color: '#000', minWidth: 120}}>{title}</div>}
            columns={columnData}
            data={tableData}
            options={{
                search: search ? true : false,
                paging: false,
                sorting: false,
                rowStyle: {
                    textAlign:'center',
                },
                headerStyle: {textAlign:'center'},
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: '表示可能なデータがありません',
                },
            }}
        />
    )
};

RankTable.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(RankTable));
