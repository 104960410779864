import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDataLoadingForBy, setDataByRid, setDataLoad } from 'actions';
import { getJwtToken, getDataFromRDS } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.nftDepOverview.loading;
export const dataLoaded = state => state.data.dataLoaded;

export function* getByRidData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByRid = true;
        yield put(setDataLoadingForBy(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let unixTs = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];

        // JTCB
        const queryJtcbAmountByRid = H.SqlHelper.getNftDepTxByRid("NftDepReceiptAuctionProd", "JtcbGameUsersProd", "JobTribes", 10, unixTs[0], unixTs[1]);
        const queryJtcbAuctionSumAmount = H.SqlHelper.getNftDepTxSumByRid("NftDepReceiptAuctionProd", "JtcbGameUsersProd", "JobTribes", unixTs[0], unixTs[1]);
        const queryJtcbStoreByRid = H.SqlHelper.getNftDepTxByRid("NftDepReceiptStoreProd", "JtcbGameUsersProd", "JobTribes", 10, unixTs[0], unixTs[1]);
        const queryJtcbStoreSumAmount = H.SqlHelper.getNftDepTxSumByRid("NftDepReceiptStoreProd", "JtcbGameUsersProd", "JobTribes", unixTs[0], unixTs[1]);

        // LF
        const queryLfAmountByRid = H.SqlHelper.getNftDepTxByRid("NftDepReceiptAuctionProd", "RegistrationLfProd", "LuckyFarmer", 10, unixTs[0], unixTs[1]);
        const queryLfAuctionSumAmount = H.SqlHelper.getNftDepTxSumByRid("NftDepReceiptAuctionProd", "RegistrationLfProd", "LuckyFarmer", unixTs[0], unixTs[1]);
        const queryLfStoreByRid = H.SqlHelper.getNftDepTxByRid("NftDepReceiptStoreProd", "RegistrationLfProd", "LuckyFarmer", 10, unixTs[0], unixTs[1]);
        const queryLfStoreSumAmount = H.SqlHelper.getNftDepTxSumByRid("NftDepReceiptStoreProd", "RegistrationLfProd", "LuckyFarmer", unixTs[0], unixTs[1]);

        // LF
        const queryPuzzleAmountByRid = H.SqlHelper.getNftDepTxByRid("NftDepReceiptAuctionProd", "PuzzleGameUsersProd", "Puzzle", 10, unixTs[0], unixTs[1]);
        const queryPuzzleAuctionSumAmount = H.SqlHelper.getNftDepTxSumByRid("NftDepReceiptAuctionProd", "PuzzleGameUsersProd", "Puzzle", unixTs[0], unixTs[1]);
        const queryPuzzleStoreByRid = H.SqlHelper.getNftDepTxByRid("NftDepReceiptStoreProd", "PuzzleGameUsersProd", "Puzzle", 10, unixTs[0], unixTs[1]);
        const queryPuzzleStoreSumAmount = H.SqlHelper.getNftDepTxSumByRid("NftDepReceiptStoreProd", "PuzzleGameUsersProd", "Puzzle", unixTs[0], unixTs[1]);

        const [
            tempJtcbAuctionAmount,
            tempJtcbAuctionSumAmount,
            tempJtcbStoreAmount,
            tempJtcbStoreSumAmount,
            tempLfAuctionAmount,
            tempLfAuctionSumAmount,
            tempLfStoreAmount,
            tempLfStoreSumAmount,
            tempPzlAuctionAmount,
            tempPzlAuctionSumAmount,
            tempPzlStoreAmount,
            tempPzlStoreSumAmount,
        ] = yield all([
            call(getDataFromRDS, queryJtcbAmountByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryJtcbAuctionSumAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryJtcbStoreByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryJtcbStoreSumAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryLfAmountByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryLfAuctionSumAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryLfStoreByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryLfStoreSumAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryPuzzleAmountByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryPuzzleAuctionSumAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryPuzzleStoreByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryPuzzleStoreSumAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedJtcbAuctionAmount = JSON.parse(tempJtcbAuctionAmount.body);
        const parsedJtcbAuctionSumAmount = JSON.parse(tempJtcbAuctionSumAmount.body);
        const parsedJtcbStoreAmount = JSON.parse(tempJtcbStoreAmount.body);
        const parsedJtcbStoreSumAmount = JSON.parse(tempJtcbStoreSumAmount.body);
        const totalAuctionAmount =  Number(parsedJtcbAuctionSumAmount[0][0]['stringValue']);
        const totalStoreAmount =  Number(parsedJtcbStoreSumAmount[0][0]['stringValue']);

        const parsedLfAuctionAmount = JSON.parse(tempLfAuctionAmount.body);
        const parsedLfAuctionSumAmount = JSON.parse(tempLfAuctionSumAmount.body);
        const parsedLfStoreAmount = JSON.parse(tempLfStoreAmount.body);
        const parsedLfStoreSumAmount = JSON.parse(tempLfStoreSumAmount.body);
        const totalLfAuctionAmount =  Number(parsedLfAuctionSumAmount[0][0]['stringValue']);
        const totalLfStoreAmount =  Number(parsedLfStoreSumAmount[0][0]['stringValue']);

        const parsedPzlAuctionAmount = JSON.parse(tempPzlAuctionAmount.body);
        const parsedPzlAuctionSumAmount = JSON.parse(tempPzlAuctionSumAmount.body);
        const parsedPzlStoreAmount = JSON.parse(tempPzlStoreAmount.body);
        const parsedPzlStoreSumAmount = JSON.parse(tempPzlStoreSumAmount.body);
        const totalPzlAuctionAmount =  Number(parsedPzlAuctionSumAmount[0][0]['stringValue']);
        const totalPzlStoreAmount =  Number(parsedPzlStoreSumAmount[0][0]['stringValue']);

        // JTCB
        let returnAuctionDataArray = [], dataAmountAuctionByRid = {};
        _.each(parsedJtcbAuctionAmount, function(data, index) {
            dataAmountAuctionByRid.rid = data[0]['stringValue'];
            dataAmountAuctionByRid.name = H.FormatNumStrHelper.unEscapeUnicode(data[1]['stringValue']);
            dataAmountAuctionByRid.numPmid = data[2]['longValue'];
            dataAmountAuctionByRid.numTx = data[3]['longValue'];
            dataAmountAuctionByRid.amount = Number(data[4]['stringValue']);
            dataAmountAuctionByRid.numRatio = (Number(data[4]['stringValue']) / totalAuctionAmount * 100).toFixed(2);
            returnAuctionDataArray.push(dataAmountAuctionByRid);
            dataAmountAuctionByRid = {};
        });

        let returnStoreDataArray = [], dataAmountStoreByRid = {};
        _.each(parsedJtcbStoreAmount, function(data, index) {
            dataAmountStoreByRid.rid = data[0]['stringValue'];
            dataAmountStoreByRid.name =  H.FormatNumStrHelper.unEscapeUnicode(data[1]['stringValue']);
            dataAmountStoreByRid.numPmid = data[2]['longValue'];
            dataAmountStoreByRid.numTx = data[3]['longValue'];
            dataAmountStoreByRid.amount = Number(data[4]['stringValue']);
            dataAmountStoreByRid.numRatio = (Number(data[4]['stringValue']) / totalStoreAmount * 100).toFixed(2);
            returnStoreDataArray.push(dataAmountStoreByRid);
            dataAmountStoreByRid = {};
        });

        // Lf
        let returnLfAuctionDataArray = [], dataLfAmountAuctionByRid = {};
        _.each(parsedLfAuctionAmount, function(data, index) {
            dataLfAmountAuctionByRid.rid = data[0]['stringValue'];
            dataLfAmountAuctionByRid.name = H.FormatNumStrHelper.unEscapeUnicode(data[1]['stringValue']);
            dataLfAmountAuctionByRid.numPmid = data[2]['longValue'];
            dataLfAmountAuctionByRid.numTx = data[3]['longValue'];
            dataLfAmountAuctionByRid.amount = Number(data[4]['stringValue']);
            dataLfAmountAuctionByRid.numRatio = (Number(data[4]['stringValue']) / totalLfAuctionAmount * 100).toFixed(2);
            returnLfAuctionDataArray.push(dataLfAmountAuctionByRid);
            dataLfAmountAuctionByRid = {};
        });

        let returnLfStoreDataArray = [], dataLfAmountStoreByRid = {};
        _.each(parsedLfStoreAmount, function(data, index) {
            dataLfAmountStoreByRid.rid = data[0]['stringValue'];
            dataLfAmountStoreByRid.name =  H.FormatNumStrHelper.unEscapeUnicode(data[1]['stringValue']);
            dataLfAmountStoreByRid.numPmid = data[2]['longValue'];
            dataLfAmountStoreByRid.numTx = data[3]['longValue'];
            dataLfAmountStoreByRid.amount = Number(data[4]['stringValue']);
            dataLfAmountStoreByRid.numRatio = (Number(data[4]['stringValue']) / totalLfStoreAmount * 100).toFixed(2);
            returnLfStoreDataArray.push(dataLfAmountStoreByRid);
            dataLfAmountStoreByRid = {};
        });

        // Puzzle
        let returnPzlAuctionDataArray = [], dataPzlAmountAuctionByRid = {};
        _.each(parsedPzlAuctionAmount, function(data, index) {
            dataPzlAmountAuctionByRid.rid = data[0]['stringValue'];
            dataPzlAmountAuctionByRid.name = H.FormatNumStrHelper.unEscapeUnicode(data[1]['stringValue']);
            dataPzlAmountAuctionByRid.numPmid = data[2]['longValue'];
            dataPzlAmountAuctionByRid.numTx = data[3]['longValue'];
            dataPzlAmountAuctionByRid.amount = Number(data[4]['stringValue']);
            dataPzlAmountAuctionByRid.numRatio = (Number(data[4]['stringValue']) / totalPzlAuctionAmount * 100).toFixed(2);
            returnPzlAuctionDataArray.push(dataPzlAmountAuctionByRid);
            dataPzlAmountAuctionByRid = {};
        });

        let returnPzlStoreDataArray = [], dataPzlAmountStoreByRid = {};
        _.each(parsedPzlStoreAmount, function(data, index) {
            dataPzlAmountStoreByRid.rid = data[0]['stringValue'];
            dataPzlAmountStoreByRid.name =  H.FormatNumStrHelper.unEscapeUnicode(data[1]['stringValue']);
            dataPzlAmountStoreByRid.numPmid = data[2]['longValue'];
            dataPzlAmountStoreByRid.numTx = data[3]['longValue'];
            dataPzlAmountStoreByRid.amount = Number(data[4]['stringValue']);
            dataPzlAmountStoreByRid.numRatio = (Number(data[4]['stringValue']) / totalPzlStoreAmount * 100).toFixed(2);
            returnPzlStoreDataArray.push(dataPzlAmountStoreByRid);
            dataPzlAmountStoreByRid = {};
        });

        const returningData = {
            jtcb: {
                auction: returnAuctionDataArray,
                store: returnStoreDataArray,  
            },
            luckyfarmer: {
                auction: returnLfAuctionDataArray,
                store: returnLfStoreDataArray,  
            },
            puzzle: {
                auction: returnPzlAuctionDataArray,
                store: returnPzlStoreDataArray,  
            },
        }
        yield put(setDataByRid(returningData));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByRid = false;
        yield put(setDataLoadingForBy(loadingUpdate2));

        // set data loaded flag
        const dataExist = yield select(dataLoaded);
        const dataAdd = { "nftdep-overview-camapign" : [dateFrom, dateTo] }
        const newData = _.merge(dataExist, dataAdd);
        yield put(setDataLoad(newData));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_OVERVIEW_DATA_BY_CAMPAIGN, getByRidData);
}
