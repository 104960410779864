import { DATA } from '../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    cohortFocused: '',
    cohortSize: 'day',
    cohortSizeSelected: {name: "日別", value:"day"},
    cohortMetric: 'retention',
    cohortRange: '7days',
    cohortRangeSelected: {},
    cohortCountryOptions: [
        {name: "全て", value:"all"},
    ],
    cohortCountrySelected: {name: "全て", value:"all"},
    cohortRidOptions: [
        {name: "全て", value:"all"},
    ],
    cohortRidSelected: {name: "全て", value:"all"},
    cohortData: [
        {
            id: 'nftdep',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'playmining-nft',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'jobtribes',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'puzzle',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'luckyfarmer',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'cookinburger',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'dragonramen',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'graffitiracer',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'lostarchiveplus',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'soulfusers',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'roguerollrulers',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'bouncybunny',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
    ],
    cohortNftData: [
        {
            id: 'nftdep',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'playmining-nft',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'jobtribes',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'puzzle',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'luckyfarmer',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'cookinburger',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'dragonramen',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'graffitiracer',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'lostarchiveplus',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'soulfusers',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'roguerollrulers',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
        {
            id: 'bouncybunny',
            size: 'day',
            metric: 'retention',
            range: [],
            data: [],
        },
    ],
    cohortDateRange: [startDate, endDate],
};

export default function cohortReducer(state = initialState, action) {
    switch (action.type) {
        case DATA.SET_COHORT_FOCUSED:
            return {
                ...state,
                cohortFocused: action.payload
            };
        case DATA.SET_COHORT_DATA:
            return {
                ...state,
                cohortData: action.payload
            };
        case DATA.SET_COHORT_NFT_DATA:
            return {
                ...state,
                cohortNftData: action.payload
            };
        case DATA.SET_COHORT_SIZE:
            return {
                ...state,
                cohortSize: action.payload
            };
        case DATA.SET_COHORT_SIZE_SELECTED:
            return {
                ...state,
                cohortSizeSelected: action.payload
            };
        case DATA.SET_COHORT_RANGE:
            return {
                ...state,
                cohortRange: action.payload
            };
        case DATA.SET_COHORT_RANGE_SELECTED:
            return {
                ...state,
                cohortRangeSelected: action.payload
            };
        case DATA.SET_COHORT_COUNTRY_OPTIONS:
            return {
                ...state,
                cohortCountryOptions: action.payload
            };
        case DATA.SET_COHORT_COUNTRY_SELECTED:
            return {
                ...state,
                cohortCountrySelected: action.payload
            };
        case DATA.SET_COHORT_RID_OPTIONS:
            return {
                ...state,
                cohortRidOptions: action.payload
            };
        case DATA.SET_COHORT_RID_SELECTED:
            return {
                ...state,
                cohortRidSelected: action.payload
            };
        case DATA.SET_COHORT_DATE_RANGE:
            return {
                ...state,
                cohortDateRange: action.payload
            };
        default:
            return state
    }
}