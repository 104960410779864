import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDataLoading, setAllDepSalesData, setAllTxCountData, setTodaysSalesData, setActiveWalletData,
setTxAuctionStatsData, setTxStoreStatsData, setTxFiatStatsData, setTxFirstTimePurchaseStatsData,
setDataLoadingForBy, setDataByCountry, setDataLoad } from 'actions';
import { getJwtToken, getDataFromRDS, getCurrentDepExchangeRate, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const depPrice = state => state.page.depPrice;
export const loading = state => state.nftDep.loading;
export const dataLoaded = state => state.data.dataLoaded;

export function* getSalesOverviewDataByGame(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.allDepSales = true;
        loadingUpdate.allTxCount = true;
        loadingUpdate.allDepFees = true;
        loadingUpdate.txAuction = true;
        loadingUpdate.txStore = true;
        loadingUpdate.activeWallet = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${dateFrom}T00:00:00.000Z`;
        const rangeEnd = `${dateTo}T23:59:59.999Z`;
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        // prev
        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');
        const rangeStartPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEndPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQuerySalesPrimaryInRange = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQuerySales2ndInRange = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQuerySalesPrimaryInRangePrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        const sqlQuerySales2ndInRangePrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        const sqlQueryMarketStatsPrimary = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQueryMarketStats2nd = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQueryMarketStatsPrimaryPrev = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        const sqlQueryMarketStats2ndPrev = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        const sqlQuerySalesFiatInRange = H.SqlHelper.getFiatSalesInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQuerySalesFiatInRangePrev = H.SqlHelper.getFiatSalesInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        const sqlQueryFiatStats = H.SqlHelper.getFiatStatsInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQueryFiatStatsPrev = H.SqlHelper.getFiatStatsInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        const sqlQueryFirstTimeBuy = H.SqlHelper.getFirstTimePurchaseGroupByEventType({table: 'SxiNftSalesProdFirstPurchaseV1', range: [rangeStart, rangeEnd], target:contentId});
        const sqlQueryFirstTimeBuyPrev = H.SqlHelper.getFirstTimePurchaseGroupByEventType({table: 'SxiNftSalesProdFirstPurchaseV1', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        const [
            dataPrimary,
            dataSedondary,
            dataPrimaryPrev,
            dataSedondaryPrev,
            dataPrimaryStats,
            dataSedondaryStats,
            dataPrimaryStatsPrev,
            dataSedondaryStatsPrev,
            dataFiat,
            dataFiatPrev,
            dataFiatStats,
            dataFiatStatsPrev,
            dataFirstTimeBuy,
            dataFisrtTimeBuyPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQuerySalesPrimaryInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySales2ndInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySalesPrimaryInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySales2ndInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStatsPrimary, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStats2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStatsPrimaryPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStats2ndPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySalesFiatInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySalesFiatInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFiatStats, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFiatStatsPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFirstTimeBuy, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFirstTimeBuyPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const data1stReturn = JSON.parse(dataPrimary.body);
        const data2ndReturn = JSON.parse(dataSedondary.body);
        const data1stPrevReturn = JSON.parse(dataPrimaryPrev.body);
        const data2ndPrevReturn = JSON.parse(dataSedondaryPrev.body);
        const stats1stReturn = JSON.parse(dataPrimaryStats.body);
        const stats2ndReturn = JSON.parse(dataSedondaryStats.body);
        const stats1stPrevReturn = JSON.parse(dataPrimaryStatsPrev.body);
        const stats2ndPrevReturn = JSON.parse(dataSedondaryStatsPrev.body);
        const dataFiatReturn = JSON.parse(dataFiat.body);
        const dataFiatReturnPrev = JSON.parse(dataFiatPrev.body);
        const statsFiatReturn = JSON.parse(dataFiatStats.body);
        const statsFiatPrevReturn = JSON.parse(dataFiatStatsPrev.body);
        const statsFirstTimeReturn = JSON.parse(dataFirstTimeBuy.body);
        const statsFirstPrevTimeReturn = JSON.parse(dataFisrtTimeBuyPrev.body);

        const data1stReturnValue = data1stReturn[0][0]['isNull'] ? 0 : Number(data1stReturn[0][0]['stringValue']);
        const data2ndReturnValue = data2ndReturn[0][0]['isNull'] ? 0 : Number(data2ndReturn[0][0]['stringValue']);
        const data1stPrevValue = data1stPrevReturn[0][0]['isNull'] ? 0 : Number(data1stPrevReturn[0][0]['stringValue']);
        const data2ndPrevValue = data2ndPrevReturn[0][0]['isNull'] ? 0 : Number(data2ndPrevReturn[0][0]['stringValue']);

        const allDepSalesValue = {
            total: data1stReturnValue + data2ndReturnValue,
            prev: data1stPrevValue + data2ndPrevValue,
            auction: data2ndReturnValue,
            store: data1stReturnValue,
            totalFiat: dataFiatReturn[0][1]['isNull'] ? 0 : Number(dataFiatReturn[0][1]['stringValue']),
            totalFiatPrev: dataFiatReturnPrev[0][1]['isNull'] ? 0 : Number(dataFiatReturnPrev[0][1]['stringValue']),
        };
        yield put(setAllDepSalesData(allDepSalesValue));

        // Count
        const allTxCountValue = {
            total: data1stReturn[0][1]['longValue']+data2ndReturn[0][1]['longValue']+dataFiatReturn[0][2]['longValue'],
            prev: data1stPrevReturn[0][1]['longValue']+data2ndPrevReturn[0][1]['longValue']+dataFiatReturnPrev[0][2]['longValue'],
            auction: data2ndReturn[0][1]['longValue'],
            store: data1stReturn[0][1]['longValue'],
            fiat: Number(dataFiatReturn[0][2]['longValue']),
        };
        yield put(setAllTxCountData(allTxCountValue));

        // auction stats
        const auctionTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: data2ndReturn[0][1]['longValue'],
                prev: data2ndPrevReturn[0][1]['longValue'],
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: data2ndReturn[0][2]['longValue'],
                prev: data2ndPrevReturn[0][2]['longValue'],
            },
            {
                title: "最大値",
                type: "max",
                current: stats2ndReturn[0][0]['longValue'],
                prev: stats2ndPrevReturn[0][0]['longValue'],
            },
            {
                title: "最小値",
                type: "min",
                current: stats2ndReturn[0][2]['longValue'],
                prev: stats2ndPrevReturn[0][2]['longValue'],
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(stats2ndReturn[0][1]['stringValue']),
                prev: Number(stats2ndPrevReturn[0][1]['stringValue']),
            },
        ];
        yield put(setTxAuctionStatsData(auctionTxStatsData));

        // store stats
        const storeTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: data1stReturn[0][1]['longValue'],
                prev: data1stPrevReturn[0][1]['longValue'],
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: data1stReturn[0][2]['longValue'],
                prev: data1stPrevReturn[0][2]['longValue'],
            },
            {
                title: "最大値",
                type: "max",
                current: stats1stReturn[0][0]['longValue'],
                prev: stats1stPrevReturn[0][0]['longValue'],
            },
            {
                title: "最小値",
                type: "min",
                current: stats1stReturn[0][2]['longValue'],
                prev: stats1stPrevReturn[0][2]['longValue'],
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(stats1stReturn[0][1]['stringValue']),
                prev: Number(stats1stPrevReturn[0][1]['stringValue']),
            },
        ];
        yield put(setTxStoreStatsData(storeTxStatsData));

        // fiat stats
        const fiatTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: dataFiatReturn[0][2]['longValue'],
                prev: dataFiatReturnPrev[0][2]['longValue'],
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: dataFiatReturn[0][3]['longValue'],
                prev: dataFiatReturnPrev[0][3]['longValue'],
            },
            {
                title: "最大値",
                type: "max",
                current: Number(statsFiatReturn[0][0]['stringValue']),
                prev: Number(statsFiatPrevReturn[0][0]['stringValue']),
            },
            {
                title: "最小値",
                type: "min",
                current: Number(statsFiatReturn[0][2]['stringValue']),
                prev: Number(statsFiatPrevReturn[0][2]['stringValue']),
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(statsFiatReturn[0][1]['stringValue']),
                prev: Number(statsFiatPrevReturn[0][1]['stringValue']),
            },
        ];        
        yield put(setTxFiatStatsData(fiatTxStatsData));

        // Active Wallet
        const activeWalletCountValue = {
            current : data1stReturn[0][2]['longValue']+data2ndReturn[0][2]['longValue']+dataFiatReturn[0][3]['longValue'],
            prev: data1stPrevReturn[0][2]['longValue']+data2ndPrevReturn[0][2]['longValue']+dataFiatReturnPrev[0][3]['longValue'],
            auction: data2ndReturn[0][2]['longValue'],
            store: data1stReturn[0][2]['longValue'],
            fiat: dataFiatReturn[0][3]['longValue'],
        };
        yield put(setActiveWalletData(activeWalletCountValue));

        let currentSum = 0, prevSum = 0, auctionSum = 0, storeSum = 0, fiatSum = 0;
        _.each(statsFirstTimeReturn, function(fData) {
            currentSum = currentSum + fData[1]['longValue'];
            switch(fData[0]['stringValue']) {
                case "REVENUE_1ST":
                    storeSum = fData[1]['longValue'];
                    break;
                case "TRADE_2ND":
                    auctionSum = fData[1]['longValue'];
                    break;
                case "CREDIT_PAYMENT_MARKET":
                    fiatSum = fData[1]['longValue'];
                    break;
                default:
            }
        });
        _.each(statsFirstPrevTimeReturn, function(pData) {
            prevSum = prevSum + pData[1]['longValue'];
        }); 
        // FirstTime Purhcase
        const firstTimePurchaseCountValue = {
            current : currentSum,
            prev: prevSum,
            auction: auctionSum,
            store: storeSum,
            fiat: fiatSum,
        };
        yield put(setTxFirstTimePurchaseStatsData(firstTimePurchaseCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.allDepSales = false;
        loadingUpdate2.allTxCount = false;
        loadingUpdate2.allDepFees = false;
        loadingUpdate2.activeWallet = false;
        loadingUpdate2.txAuction = false;
        loadingUpdate2.txStore = false;
        loadingUpdate2.activeWallet = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

export function* getByCountryGameData(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByCountry = true;
        yield put(setDataLoadingForBy(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        const gameTarget = action.payload;
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
        if (contentId === undefined) {
            return;
        }
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQueryTx1stAmountByCountry = H.SqlHelper.getNftTransactionAmountByCoutryBidb2(
            {table: 'SxiNftSalesProd1stV2', limit: 10, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], target:contentId}
        );
        const sqlQueryTx1stCountByCountry = H.SqlHelper.getNftTransactionCountByCoutryBidb2(
            {table: 'SxiNftSalesProd1stV2', limit: 10, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], target:contentId}
        );
        const sqlQueryTx2ndAmountByCountry = H.SqlHelper.getNftTransactionAmountByCoutryBidb2(
            {table: 'SxiNftSalesProd2ndV1', limit: 10, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], target:contentId}
        );
        const sqlQueryTx2ndCountByCountry = H.SqlHelper.getNftTransactionCountByCoutryBidb2(
            {table: 'SxiNftSalesProd2ndV1', limit: 10, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], target:contentId}
        );
        const sqlQueryTxFiatAmountByCountry = H.SqlHelper.getNftTransactionAmountByCoutryBidb2(
            {table: 'SxiNftSalesProdFiatV1', limit: 10, tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], target:contentId}
        );
        const sqlQueryTxFiatCountByCountry = H.SqlHelper.getNftTransactionCountByCoutryBidb2(
            {table: 'SxiNftSalesProdFiatV1', limit: 10, tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], target:contentId}
        );

        const [
            dataTx1stAmountByCountryTemp,
            dataTx1stCountByCountryTemp,
            dataTx2ndAmountByCountryTemp,
            dataTx2ndCountByCountryTemp,
            dataTxFiatAmountByCountryTemp,
            dataTxFiatCountByCountryTemp,
        ] = yield all([
            call(getDataFromSxi, sqlQueryTx1stAmountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx1stCountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx2ndAmountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx2ndCountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTxFiatAmountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTxFiatCountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataTx1stAmountByCountry = JSON.parse(dataTx1stAmountByCountryTemp.body);
        const dataTx1stCountByCountry = JSON.parse(dataTx1stCountByCountryTemp.body);
        const dataTx2ndAmountByCountry = JSON.parse(dataTx2ndAmountByCountryTemp.body);
        const dataTx2ndCountByCountry = JSON.parse(dataTx2ndCountByCountryTemp.body);
        const dataTxFiatAmountByCountry = JSON.parse(dataTxFiatAmountByCountryTemp.body);
        const dataTxFiatCountByCountry = JSON.parse(dataTxFiatCountByCountryTemp.body);

        // // Auction
        let returnAuctionDataArray = [], dataAmountAuctionByCountry = {};
        let returnAuctionCountDataArray = [], dataCountAuctionByCountry = {};

        // auction amount
        _.each(dataTx2ndAmountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnAuctionDataArray.length < 11) {
                dataAmountAuctionByCountry.country = data[1]['stringValue'];
                dataAmountAuctionByCountry.value = Number(data[0]['stringValue']);
                returnAuctionDataArray.push(dataAmountAuctionByCountry);
                dataAmountAuctionByCountry = {};
            }
        });

        // auction count
        _.each(dataTx2ndCountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnAuctionCountDataArray.length < 11) {
                dataCountAuctionByCountry.country = data[1]['stringValue'];
                dataCountAuctionByCountry.value = data[0]['longValue'];
                returnAuctionCountDataArray.push(dataCountAuctionByCountry);
                dataCountAuctionByCountry = {};
            }
        });

        // store amount
        let returnStoreDataArray = [], dataAmountStoreByCountry = {};
        let returnStoreCountDataArray = [], dataCountStoreByCountry = {};
        _.each(dataTx1stAmountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnStoreDataArray.length < 11) {
                dataAmountStoreByCountry.country = data[1]['stringValue'];
                dataAmountStoreByCountry.value = Number(data[0]['stringValue']);
                returnStoreDataArray.push(dataAmountStoreByCountry);
                dataAmountStoreByCountry = {};
            }
        });

        // store count
        _.each(dataTx1stCountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnStoreCountDataArray.length < 11) {
                dataCountStoreByCountry.country = data[1]['stringValue'];
                dataCountStoreByCountry.value = data[0]['longValue'];
                returnStoreCountDataArray.push(dataCountStoreByCountry);
                dataCountStoreByCountry = {};
            }
        });

        // Fiat amount
        let returnFiatDataArray = [], dataAmountFiatByCountry = {};
        let returnFiatCountDataArray = [], dataCountFiatByCountry = {};
        _.each(dataTxFiatAmountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnFiatDataArray.length < 11) {
                dataAmountFiatByCountry.country = data[1]['stringValue'];
                dataAmountFiatByCountry.value = Number(data[0]['stringValue']);
                returnFiatDataArray.push(dataAmountFiatByCountry);
                dataAmountFiatByCountry = {};
            }
        });

        // Fiat count
        _.each(dataTxFiatCountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnFiatCountDataArray.length < 11) {
                dataCountFiatByCountry.country = data[1]['stringValue'];
                dataCountFiatByCountry.value = data[0]['longValue'];
                returnFiatCountDataArray.push(dataCountFiatByCountry);
                dataCountFiatByCountry = {};
            }
        });

        const txAmountValue = {
            amount: {
                auction: returnAuctionDataArray,
                store: returnStoreDataArray,
                fiat: returnFiatDataArray,
            },
            count: {
                auction: returnAuctionCountDataArray,
                store: returnStoreCountDataArray,
                fiat: returnFiatCountDataArray,
            },
        }
        yield put(setDataByCountry(txAmountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByCountry = false;
        yield put(setDataLoadingForBy(loadingUpdate2));

        // set data loaded flag
        const dataExist = yield select(dataLoaded);
        const dataAdd = { "nftdep-overview-country" : [dateFrom, dateTo] }
        const newData = _.merge(dataExist, dataAdd);
        yield put(setDataLoad(newData));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    // yield takeEvery(NFTDEP.GET_TODAYS_SALES_DATA, getTodaysDepSales);
    yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA_BY_GAME, getSalesOverviewDataByGame);
    yield takeEvery(NFTDEP.GET_OVERVIEW_GAME_DATA_BY_COUNTRY, getByCountryGameData);
}
