import { put, delay, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setNftOwnTrendData, setNftOwnTrendDataLoading, setPageTopLoader } from 'actions';
import { getJwtToken, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const nftOwnTrendDateRange = state => state.nftOwnTrend.nftOwnTrendDateRange;
export const nftOwnTrendOptionSelected = state => state.nftOwnTrend.nftOwnTrendOptionSelected;

export function* getNftOwnTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setNftOwnTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(nftOwnTrendDateRange);

        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlGetNftOwnTrendTtl = H.SqlHelper.getNftOwnTrendInRange({status: 'HOLDING', range: [rangeStart, rangeEnd], unique: false});
        const sqlGetNftOwnTrendUnique = H.SqlHelper.getNftOwnTrendInRange({status: 'HOLDING', range: [rangeStart, rangeEnd], unique: true});
        const sqlGetNftOwnTrendPrice = H.SqlHelper.getNftOwnTrendPriceInRange({status: 'HOLDING', range: [rangeStart, rangeEnd]});

        // const sourceStaking = H.ElasticSearch.getNftOwnTrendData(rangeTarget);
        // const esQueryOwnTrend = {
        //     "es": "daa",
        //     "query": "simpleSearch",
        //     "index": "user_assets",
        //     "src": `${sourceStaking}`
        // };

        const [
            dataOwnTrendTtlTemp,
            dataOwnTrendUniqueTemp,
            dataOwnTrendPriceTemp,
        ] = yield all([
            call(getDataFromSxi, sqlGetNftOwnTrendTtl, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlGetNftOwnTrendUnique, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlGetNftOwnTrendPrice, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);

        const nftOwnTrendData = [
            {
                total: dataOwnTrendTtlTemp,
                unique: dataOwnTrendUniqueTemp,
                price: dataOwnTrendPriceTemp,
            }
        ];

        yield put(setNftOwnTrendData(nftOwnTrendData));
        yield put(setPageTopLoader(false));
        yield put(setNftOwnTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_NFT_OWN_TREND_DATA, getNftOwnTrendData);
}
