import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import { withStyles, makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import SelectDateRanges from 'components/Select/SelectDateRanges';
import * as Var from 'styles/variables';
import { setDateRange, setPrevDateRange, getHomePageData, setBtnStateDateFetch,
    setComparisonDateDiff, setComparisonDateDiffDisplay, setCampaignDateRange,
    setMauDateRange, setMauPrevDateRange, setWauDateRange, setWauPrevDateRange,
    getSalesOverviewData,getOverviewDataByCountry, getOverviewDataByPmid, getOverviewDataByGame, getOverviewDataByCampaign,
} from 'actions';

const useStyles = makeStyles((theme) =>
    createStyles({
        calendarWrapper: {
            paddingLeft: 8,
            flexGrow: 1,
        },
        root: {
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        container: {
            display: 'flex',
            width: 300,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            alignItems: 'baseline',
        },
        delimiter: {
            paddingTop: theme.spacing(1)
        },
        dataBtn: {
            marginTop: theme.spacing(1.2),
            marginLeft: theme.spacing(1),
            height: theme.spacing(4),
            color: Var.btnLabelColor,
        },
        grow: {
            flexGrow: 1,
        },
        sectionCalendar: {
            display:'flex',
        },
        sectionDateRange: {
            marginLeft: theme.spacing(0)
        }
    }),
);

const CalendarRangePicker = ({
    mDate,
    setDateRange,
    setCampaignDateRange,
    setPrevDateRange,
    setComparisonDateDiff,
    setComparisonDateDiffDisplay,
    setMauDateRange,
    setMauPrevDateRange,
    setWauDateRange,
    setWauPrevDateRange,
    getHomePageData,
    page,
    btnStateDataFetch,
    setBtnStateDateFetch,
    dateRange,
    mauDateRange,
    wauDateRange,
    campaignDateRange,
    currentPage,
    campaignPreDefinedDateRange,
    comparisonDateDiff,
    getSalesOverviewData,
    tabFocused,
    getOverviewDataByCountry, getOverviewDataByPmid, getOverviewDataByGame, getOverviewDataByCampaign,
}) => {
    const classes = useStyles();
    //const minDate = moment(mDate); // Puzzle launch, ToDo, update accordingly
    //const maxDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
    const [dateRangeValue, setDateRangeValue] = useState([null, null]);
    const [error, setError] = useState([null, null]);
    const [minDate, setMinDate] = useState(moment(mDate));
    const [maxDate, setMaxDate] = useState(moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD'));

    useEffect(() => {
        // Check daily default setting
        const currentJstDate = moment().tz("Asia/Tokyo").format('YYYY/MM/DD');

        if (page === 'campaignSummary') {
            handleCampaignCalendar(currentJstDate);
        } else {
            handleHomeCalendar(currentJstDate);
        }
    }, []);

    useEffect(() => {
        setDateRangeValue(dateRange);
    }, [dateRange]);

    useEffect(() => {
        const dayTo = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        const sgEndDateToFetch = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        const oneDayAgoCalForm = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        if (campaignPreDefinedDateRange === '') {
            setDateRangeValue([oneDayAgoCalForm, dayTo]);
            setDateRange(['2020-03-11', sgEndDateToFetch]);
        } else if (campaignPreDefinedDateRange === 'all'){
            setDateRangeValue(['2020/03/11', dayTo]);
            setDateRange(['2020-03-11', sgEndDateToFetch]);
        } else {
            const dayFrom = moment().tz("Asia/Singapore").subtract(campaignPreDefinedDateRange, 'days').format('YYYY/MM/DD');
            const sgStartDateToFetch = moment().tz("Asia/Singapore").subtract(campaignPreDefinedDateRange, 'days').format('YYYY-MM-DD');
            setDateRangeValue([dayFrom, dayTo]);
            setDateRange([sgStartDateToFetch, sgEndDateToFetch]);
        }
        setBtnStateDateFetch(true);
    }, [campaignPreDefinedDateRange]);

    const handleHomeCalendar = (currentJstDate) => {
        if (localStorage.getItem('dailyAccessDate') === null) {
            // none, set accessed JST dateTime
            setDefaultDate(currentJstDate, page);
        } else {
            if (dateRange.length === 0) {
                setDateRangeValue([moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')]);
                let dayDiff = getDayDiff(moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD'));
                setComparisonDateDiff(dayDiff);
                let prevDates = getPrevDates(moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD'), dayDiff);
                setPrevDateRange(prevDates);
            } else {
                setDateRangeValue(dateRange)
                let dayDiff = getDayDiff(dateRange[0], dateRange[1]) === 0 ? 1 : getDayDiff(dateRange[0], dateRange[1]);
                setComparisonDateDiff(dayDiff);
                let prevDates = getPrevDates(dateRange[0], dateRange[1], dayDiff);
                setPrevDateRange(prevDates);

                const mauPrevDateEnd = moment(mauDateRange[0]).subtract(1, 'days').format('YYYY/MM/DD');
                const mauPrevDateBegin = moment(mauPrevDateEnd).subtract(29, 'days').format('YYYY/MM/DD');

                setMauPrevDateRange([mauPrevDateBegin, mauPrevDateEnd]);

                const wauPrevDateEnd = moment(wauDateRange[0]).subtract(1, 'days').format('YYYY/MM/DD');
                const wauPrevDateBegin = moment(wauPrevDateEnd).subtract(6, 'days').format('YYYY/MM/DD');

                setWauPrevDateRange([wauPrevDateBegin, wauPrevDateEnd]);
            }
        }
    }

    const handleCampaignCalendar = (currentJstDate) => {
        if (localStorage.getItem('dailyAccessDate') === null) {
            // none, set accessed JST dateTime
            setCampaignDefaultDate(currentJstDate, page);
        } else {
            // exist, is it yesterday?
            let dayDifference = moment(currentJstDate).diff(moment(localStorage.getItem('dailyAccessDate')), 'days');
            if (dayDifference > 0) {
                // Set day before
                setCampaignDefaultDate(currentJstDate);
            } else {
                if (campaignDateRange.length === 0) {
                    setDateRangeValue([moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD')]);
                } else {
                    setDateRangeValue(campaignDateRange);
                }
            }
        }
    }

    const getDayDiff = (dayBegin, dayEnd) => {
        let diffDays = moment(dayEnd).diff(moment(dayBegin), 'days')+1;
        return diffDays
    }

    const getPrevDates = (dayBegin, dayEnd, dayDiff) => {
        let startPrevDate = moment(dayBegin).subtract(dayDiff, 'days').format('YYYY-MM-DD');
        let endPrevDate = moment(dayEnd).subtract(dayDiff, 'days').format('YYYY-MM-DD');
        return [startPrevDate, endPrevDate];
    }

    const setDefaultDate = (currentJstDate) => {
        const dayBefore = moment().tz("Asia/Tokyo").subtract(1, 'days').format('YYYY/MM/DD');
        const dayBeforeFormatted = moment().tz("Asia/Tokyo").subtract(1, 'days').format('YYYY-MM-DD');
        const dayPrevBefore = moment().tz("Asia/Tokyo").subtract(2, 'days').format('YYYY/MM/DD');
        setDateRange([dayBeforeFormatted, dayBeforeFormatted]);
        setDateRangeValue([dayBefore, dayBefore]);
        setComparisonDateDiff(1);
        setPrevDateRange([dayPrevBefore, dayPrevBefore]);
        localStorage.setItem('dailyAccessDate', currentJstDate);
    }

    const setCampaignDefaultDate = (currentJstDate) => {
        const dayFrom = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD');
        const dayTo = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        const sgStartDateToFetch = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
        const sgEndDateToFetch = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        setDateRangeValue([dayFrom, dayTo]);
        setDateRange([sgStartDateToFetch, sgEndDateToFetch]);
        localStorage.setItem('dailyAccessDate', currentJstDate);
    };

    const setRange = (newValue) => {
        // if (newValue[0] === null || newValue[1] === null) {
        //     setBtnStateDateFetch(false);
        //     return
        // }
        const startDate = moment(newValue[0]).format('YYYY-MM-DD');
        const endMaxDate = moment(newValue[0]).add(6, 'days').format('YYYY-MM-DD');
        const endDate = moment(newValue[0]).add(6, 'days').format('YYYY-MM-DD');//moment(newValue[1]).format('YYYY-MM-DD');
        setDateRange([startDate, endDate]);

        let dayDiff = getDayDiff(startDate, endDate);
        setComparisonDateDiff(dayDiff);

        let prevDates = getPrevDates(startDate, endDate, dayDiff);
        setPrevDateRange(prevDates);

        // MAU Dates: calculate last 30 days of MAU and set
        const mauBeginDate = moment(endDate).tz("Asia/Singapore").subtract(29, 'days').format('YYYY/MM/DD');
        setMauDateRange([mauBeginDate, endDate]);

        const mauPrevBeginDate = moment(mauBeginDate).tz("Asia/Singapore").subtract(30, 'days').format('YYYY/MM/DD');
        const mauPrevEndDate = moment(mauBeginDate).tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        setMauPrevDateRange([mauPrevBeginDate, mauPrevEndDate]);

        // WAU Dates: calculate last 7 days of WAU and set
        const wauBeginDate = moment(endDate).tz("Asia/Singapore").subtract(6, 'days').format('YYYY/MM/DD');
        setWauDateRange([wauBeginDate, endDate]);

        const wauPrevBeginDate = moment(wauBeginDate).tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD');
        const wauPrevEndDate = moment(wauBeginDate).tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        setWauPrevDateRange([wauPrevBeginDate, wauPrevEndDate]);

        setDateRangeValue([startDate, endDate]);
        setBtnStateDateFetch(true);
    }

    const fetchData = () => {
        setComparisonDateDiffDisplay(comparisonDateDiff);
        switch (currentPage) {
            case 'home':
                getHomePageData();
                break;
            case 'nftdep/overview':
                getSalesOverviewData();
                switch(tabFocused) {
                    case 'country':
                        getOverviewDataByCountry();
                        break;
                    case 'pmid':
                        getOverviewDataByPmid();
                        break;
                    case 'game':
                        getOverviewDataByGame();
                        break;
                    case 'campaign':
                        getOverviewDataByCampaign();
                        break;
                    default:
                }
                break;
            default:
        }
    }

    return (
        <div className={classes.calendarWrapper}>
            <div className={classes.root}>
                <div className={classes.sectionCalendar}>
                    <DateRangePicker
                        startText="Start"
                        endText="End"
                        disableHighlightToday={true}
                        showToolbar={false}
                        allowSameDateSelection={true}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={dateRangeValue}
                        onChange={(newValue) => setRange(newValue)}
                        onError={([startReason, endReason], [start, end]) => {
                            if (startReason === 'invalidRange') {
                                setError([null, null])
                                return;
                            }
                            setError([startReason, endReason])
                        }}
                        renderInput={(startProps, endProps) => (
                            <div className={classes.container}>
                                <TextField id="standard-basic" {...startProps} error={Boolean(error[0])} size="small" helperText="" />
                                <DateRangeDelimiter><span className={classes.delimiter}>~</span></DateRangeDelimiter>
                                <TextField id="standard-basic" {...endProps} error={Boolean(error[0])} size="small" helperText="" />
                            </div>
                        )}
                    />
                    <Button disabled={!btnStateDataFetch} className={classes.dataBtn} variant="contained" color="primary" onClick={() => fetchData()}>GO</Button>
                    {/* <div className={classes.sectionDateRange}>
                        <SelectDateRanges
                            page={page}
                            getDayDiff={getDayDiff}
                            setComparisonDateDiff={setComparisonDateDiff}
                            setPrevDateRange={setPrevDateRange}
                            getPrevDates={getPrevDates}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

CalendarRangePicker.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    btnStateDataFetch: state.page.btnStateDataFetch,
    dateRange: state.page.dateRange,
    mauDateRange: state.page.mauDateRange,
    wauDateRange: state.page.wauDateRange,
    campaignDateRange: state.campaign.campaignDateRange,
    currentPage: state.page.currentPage,
    campaignPreDefinedDateRange: state.campaign.campaignPreDefinedDateRange,
    comparisonDateDiff: state.page.comparisonDateDiff,
    tabFocused: state.nftDepOverview.tabFocused,
});

const mapDispatchToProps = (dispatch) => ({
    setDateRange: (param) => dispatch(setDateRange(param)),
    setCampaignDateRange: (param) => dispatch(setCampaignDateRange(param)),
    setPrevDateRange: (param) => dispatch(setPrevDateRange(param)),
    setComparisonDateDiff: (param) => dispatch(setComparisonDateDiff(param)),
    setComparisonDateDiffDisplay: (param) => dispatch(setComparisonDateDiffDisplay(param)),
    setMauDateRange: (param) => dispatch(setMauDateRange(param)),
    setMauPrevDateRange: (param) => dispatch(setMauPrevDateRange(param)),
    setWauDateRange: (param) => dispatch(setWauDateRange(param)),
    setWauPrevDateRange: (param) => dispatch(setWauPrevDateRange(param)),
    getHomePageData: () => dispatch(getHomePageData()),
    setBtnStateDateFetch: (param) => dispatch(setBtnStateDateFetch(param)),
    getSalesOverviewData: () => dispatch(getSalesOverviewData()),
    getOverviewDataByCountry: () => dispatch(getOverviewDataByCountry()),
    getOverviewDataByPmid: () => dispatch(getOverviewDataByPmid()),
    getOverviewDataByGame: () => dispatch(getOverviewDataByGame()),
    getOverviewDataByCampaign: () => dispatch(getOverviewDataByCampaign()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(CalendarRangePicker));
