import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import * as C from 'components';
import * as V from 'styles/variables';

const PuzzleUserEvents = ({classes, userEventData, acquisitionDataRegGender, acquisitionDataRegAgeGroup}) => {
    useEffect(() => {
        console.log('log userEventData:', userEventData);
    }, [userEventData]);

    const getGenderPieColour = (colour, data) => {
        let returnColourArray = [];
;       for (let i=0; i<data.length; i++) {
            let name = data[i]['name'];
            returnColourArray.push(colour[name]);
        }
        return returnColourArray
    }

    return(
        <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={10}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="ログイン回数"
                            value={userEventData.play}
                            prevValue={userEventData.playPrev}
                            color={'#FDBF6F'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="ステージ成功数"
                            value={userEventData.clear}
                            prevValue={userEventData.clearPrev}
                            color={V.successColor}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="ステージ失敗数"
                            value={userEventData.failed}
                            prevValue={userEventData.failedPrev}
                            color={V.failedColor}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="KESO獲得回数"
                            value={userEventData.kesoCount}
                            prevValue={userEventData.kesoCountPrev}
                            color={'#A6CEE3'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="KESO獲得総額"
                            value={userEventData.kesoTotal}
                            prevValue={userEventData.kesoTotalPrev}
                            color={'#CAB2D6'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item sm={12} md={12} lg={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                        <C.DoughnutChart
                            title="広告 vs Organic"
                            data={acquisitionDataRegGender}
                            color={getGenderPieColour(V.pieGender, acquisitionDataRegGender)}
                            legendRight={20}
                            legendTop={35}
                            marginLeft={'0%'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <C.DoughnutChart
                            title="新規 vs 既存"
                            data={acquisitionDataRegAgeGroup}
                            color={getGenderPieColour(V.pieAge, acquisitionDataRegAgeGroup)}
                            legendRight={20}
                            legendTop={0}
                            marginLeft={'0%'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <C.DoughnutChart
                            title="言語"
                            data={acquisitionDataRegAgeGroup}
                            color={getGenderPieColour(V.pieAge, acquisitionDataRegAgeGroup)}
                            legendRight={20}
                            legendTop={0}
                            marginLeft={'0%'}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    )
};

PuzzleUserEvents.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    userEventData: state.puzzle.userEventData,
    acquisitionDataRegGender: state.puzzle.acquisitionDataRegGender,
    acquisitionDataRegAgeGroup: state.puzzle.acquisitionDataRegAgeGroup,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(PuzzleUserEvents));