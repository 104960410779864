import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  TextField,
  Button,
  Grid,
  Chip,
  Breadcrumbs,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  setActiveUsersCountrySelected,
  setActiveUsersRidSelected,
  getKpiSalesData,
  setKpiSalesDataSizeSelected,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import * as H from "helper";
import ChartConfig from "utils/ChartConfig";
import ActiveUsersConfig from "utils/ActiveUsersConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBoxForUnique: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 110,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    filterSection: {
      display: "flex",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    activeUsersTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    lineChart: {},
    selectGameForUnique: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 110,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    flexContainerNotFix: {
      display: "flex",
      overflown: "hidden",
      width: "100%",
      flexFlow: "row wrap",
      marginLeft: 16,
      borderRight: "1px solid #dcdcdc",
    },
    flexItemLeftBox: {
      alignSelf: "center",
      paddingLeft: 8,
      paddingRight: 16,
      borderRight: "1px solid #dcdcdc",
    },
    flexItemLeftBoxTotal: {
      fontSize: 14,
      alignSelf: "top",
      paddingLeft: 0,
      paddingRight: 4,
    },
    monthPicker: {
      marginTop: 11,
      marginLeft: 16,
      width: 120,
    },
    cardTitle: {
      fontSize: 16,
    },
    itemRight: {
      marginRight: 32,
    },
    breadcrumbs: {
      marginTop: 16,
      marginLeft: 16,
      minHeight: 30,
    },
  })
);

const KpiByGame = ({
  pageTopLoader,
  setCurrentPage,
  activeUsersCountryOption,
  activeUsersRidOption,
  setActiveUsersCountrySelected,
  setActiveUsersRidSelected,
  countryLoading,
  ridLoading,
  getKpiSalesData,
  kpiSalesData,
  setKpiSalesDataSizeSelected,
  kpiSalesDataSizeSelected,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartState, setChartState] = useState(true);
  const [gameLabel, setGameLabel] = useState("");

  const [sizeOptions, setSizeOptions] = useState(
    [
        {name: "DAU", value:"dau"},
        {name: "WAU", value:"wau"},
        {name: "MAU", value:"mau"},
    ]
);

const handleChangeSize = (data) => {
    setKpiSalesDataSizeSelected(data);
}

  const handleChangeCountry = (data) => {
    setActiveUsersCountrySelected({ name: data.name, value: data.value });
  };

  const handleChangeRid = (data) => {
    setActiveUsersRidSelected({ name: data.name, value: data.value });
  };

  const getChartLegend = () => {
    const modifiedArray = ChartConfig.legendKpiSales.map(item => item === 'DAU' ? kpiSalesDataSizeSelected.name : item);
    return ['ARPU', 'ARPPU', 'WAU', 'ユニーク課金数'];
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getKpiSalesData(window.location.pathname);
    const game = window.location.pathname.split("/");
    setGameLabel(H.ContentsHelper.getContentsName(game[2]));
  }, [window.location.pathname]);

  const fetchData = () => {
    getKpiSalesData(window.location.pathname);
    //setChartState(true);
  };

  const getCalendarDisplay = () => {
    return (
      <Grid item>
        <C.CalendarRangeOnly
          setBtnState={setBtnState}
          page="kpi-sales"
          rangeMax={ActiveUsersConfig.dauMaxRange}
        />
      </Grid>
    );
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Breadcrumbs separator="›" className={classes.breadcrumbs}>
            <Typography>
              {gameLabel === undefined ? "PlayMining NFT" : gameLabel}
            </Typography>
            <Typography variant="body1">売上指標</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <div className={classes.filterSection}>
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container spacing={0} direction="row">
              <Grid item>
                <Autocomplete
                  id="select-size"
                  style={{ width: 100 }}
                  options={sizeOptions}
                  getOptionLabel={(option) => option.name}
                  defaultValue={sizeOptions[0]}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="サイズ" />
                  )}
                  onChange={(e, newValue) => {
                    handleChangeSize(newValue);
                  }}
                  className={classes.autoSelect}
                />
              </Grid>
              {/* <Grid item>
                <Autocomplete
                  id="select-country"
                  style={{ width: 180 }}
                  options={activeUsersCountryOption}
                  getOptionLabel={(option) => option.name}
                  defaultValue={activeUsersCountryOption[0]}
                  disableClearable={true}
                  loading={countryLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="国"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {countryLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleChangeCountry(newValue);
                  }}
                  className={classes.autoSelect}
                />
              </Grid> */}
              {/* <Grid item>
                <Autocomplete
                  id="select-rid"
                  style={{ width: 450 }}
                  options={activeUsersRidOption}
                  getOptionLabel={(option) => option.name}
                  defaultValue={activeUsersRidOption[0]}
                  disableClearable={true}
                  loading={ridLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="キャンペーン"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {ridLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleChangeRid(newValue);
                  }}
                  className={classes.autoSelect}
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="row"
              className={classes.itemRight}
            >
              <Grid item>{getCalendarDisplay()}</Grid>
              <Grid item>
                <Button
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <C.ContentWrapper>
        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            {pageTopLoader ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : chartState ? (
              kpiSalesData.length > 0 ? (
                <C.LineChartKpiSales
                  legend={ChartConfig.legendKpiSales}
                  lblSaveImage={""}
                  xAisData={kpiSalesData[0]["date"]}
                  seriesData={kpiSalesData}
                  chartType={"line"}
                />
              ) : (
                <div className={classes.selectGameTxt}>
                  <Chip
                    label="No Data"
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            ) : null}
          </Grid>
        </Grid>
      </C.ContentWrapper>
      <div className={classes.activeUsersTable}>
        <C.KpiSalesTableByGame game={gameLabel} />
      </div>
    </Fragment>
  );
};

KpiByGame.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  activeUsersCountryOption: state.activeUsers.activeUsersCountryOption,
  activeUsersRidOption: state.activeUsers.activeUsersRidOption,
  countryLoading: state.activeUsers.countryLoading,
  ridLoading: state.activeUsers.ridLoading,
  kpiSalesData: state.kpi.kpiSalesData,
  kpiSalesDataSizeSelected: state.kpi.kpiSalesDataSizeSelected,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  setActiveUsersCountrySelected: (param) =>
    dispatch(setActiveUsersCountrySelected(param)),
  setActiveUsersRidSelected: (param) =>
    dispatch(setActiveUsersRidSelected(param)),
  getKpiSalesData: (param) => dispatch(getKpiSalesData(param)),
  setKpiSalesDataSizeSelected: (param) => dispatch(setKpiSalesDataSizeSelected(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(KpiByGame));
