const itemLabel = {
    '1': '限界突破COMMONパック',
    '2': '限界突破RAREパック',
    '3': '限界突破EPICパック',
    '4': '限界突破LEGENDARYパック',
    '101': '育成COMMONパック',
    '102': '育成RAREパック',
    '103': '育成EPICパック',
    '104': '育成LEGENDARYパック',
    '1000': '迷宮ショップ_エリクシア',
    '1001': '迷宮ショップ_エリクシアトール',
    '1100': '迷宮ショップ_砂(各色10)',
    '1200': '迷宮ショップ_石(各色50)',
    '1300': '迷宮ショップ_セレクトチケット',
    '1400': '迷宮ショップ_DEAPcheck',
    '2000': '迷宮ショップ_NFT',
    '2001': '迷宮ショップ_NFT',
    '3000': '迷宮ショップ_NFTくじ',
    '1301': '迷宮ショップ_セレクトチケット',
    '1302': '迷宮ショップ_セレクトチケット',
    '1303': '迷宮ショップ_セレクトチケット',
    '1304': '迷宮ショップ_セレクトチケット',
    '1305': '迷宮ショップ_セレクトチケット',
    '1306': '迷宮ショップ_セレクトチケット',
    '1307': '迷宮ショップ_セレクトチケット',
    '1308': '迷宮ショップ_セレクトチケット',
};

const shopLabel = {
    '20':'master/shop_name.20',
    '1001':'スペシャルパック',
    '1002':'セレクトパック',
    '1003':'アミュレット交換所',
    '1004':'スペシャルパック',
    '1005':'アミュレット交換所',
    '1006':'アミュレット枠拡張チケット販売所',
    '1007':'コラボ記念スペシャルショップ',
    '1008':'スペシャルパック',
    '1009':'アミュレット交換所',
    '1010':'プレミアム求神チケットショップ',
    '1011':'スペシャルパック',
    '1012':'PvPチケット販売所',
    '1013':'アミュレット交換所',
    '1014':'スペシャルパック',
    '1015':'スペシャルパック',
    '1016':'アミュレット交換所',
    '1017':'仮名・パトレイバーコイン交換所',
    '1018':'スペシャルパック マッチ＆笛　fire',
    '1019':'スペシャルパック マッチ＆笛　water',
    '1020':'スペシャルパック マッチ＆笛　nature',
    '1021':'スペシャルパック マッチ＆笛　thunder',
    '1022':'スペシャルパック マッチ＆笛　poison',
    '1023':'スペシャルパック マッチ＆笛　earth',
    '1024':'アミュレット交換所',
    '1025':'アミュレット交換所',
    '1026':'イベント応援パック',
    '1027':'9/9【PVP】Nightmare CUP',
    '1028':'アミュレット交換所',
    '1029':'アミュレット交換所',
    '1030':'アミュレット交換所',
    '1031':'アミュレット交換所',
    '1032':'イベント応援パック',
    '1033':'アミュレット交換所',
    '1034':'スペシャルパック　先輩＆後輩　fire',
    '1035':'スペシャルパック　先輩＆後輩　water',
    '1036':'スペシャルパック　先輩＆後輩　nature',
    '1037':'スペシャルパック　先輩＆後輩　thunder',
    '1038':'スペシャルパック　先輩＆後輩　poison',
    '1039':'スペシャルパック　先輩＆後輩　earth',
    '1040':'イベント応援パック',
    '1041':'イベント応援パック',
    '1042':'イベント応援パック',
    '1043':'イベント応援パック',
    '1044':'イベント応援パック',
    '1045':'イベント応援パック',
    '1046':'イベント応援パック',
    '1047':'イベント応援パック',
    '1048':'イベント応援パック',
    '1049':'イベント応援パック',
    '1050':'アミュレット交換所',
    '1051':'アミュレット交換所',
    '1052':'アミュレット交換所',
    '1053':'アミュレット交換所',
    '1054':'アミュレット交換所',
    '1055':'アミュレット交換所',
    '1056':'アミュレット交換所',
    '1057':'アミュレット交換所',
    '1058':'アミュレット交換所',
    '1059':'アミュレット交換所',
    '1060':'アミュレット交換所',
    '20000':'【PvP】LEGENDARYアミュレット交換所',
    '20001':'【PvP】LEGENDARYアミュレット交換所',
    '20002':'【PvP】LEGENDARYアミュレット交換所',
    '20003':'【PvP】LEGENDARYアミュレット交換所',
    '20004':'【PvP】LEGENDARYアミュレット交換所',
    '20005':'【PvP】LEGENDARYアミュレット交換所',
    '20006':'【PvP】LEGENDARYアミュレット交換所',
    '20007':'【PvP】LEGENDARYアミュレット交換所',
    '20008':'【PvP】LEGENDARYアミュレット交換所',
    '20009':'【PvP】LEGENDARYアミュレット交換所',
    '20010':'【PvP】LEGENDARYアミュレット交換所',
    '20011':'【PvP】LEGENDARYアミュレット交換所',
    '20012':'【PvP】LEGENDARYアミュレット交換所',
    '20013':'【PvP】LEGENDARYアミュレット交換所',
    '20014':'【PvP】LEGENDARYアミュレット交換所',
    '20015':'【PvP】LEGENDARYアミュレット交換所',
    '20016':'【PvP】LEGENDARYアミュレット交換所',
    '20017':'【PvP】LEGENDARYアミュレット交換所',
    '20018':'【PvP】LEGENDARYアミュレット交換所',
    '20019':'【PvP】LEGENDARYアミュレット交換所',
    '20020':'【PvP】LEGENDARYアミュレット交換所',
    '20021':'【PvP】LEGENDARYアミュレット交換所',
    '20022':'【PvP】LEGENDARYアミュレット交換所',
    '20023':'【PvP】LEGENDARYアミュレット交換所',
    '21007':'【PvP】Nightmare CUPアミュレット交換所',
    '21000':'【PvP】EPICアミュレット交換所',
    '21001':'【PvP】EPICアミュレット交換所',
    '21002':'【PvP】EPICアミュレット交換所',
    '21003':'【PvP】EPICアミュレット交換所',
    '21004':'【PvP】EPICアミュレット交換所',
    '21005':'【PvP】EPICアミュレット交換所',
    '21006':'【PvP】EPICアミュレット交換所',
    '21007':'【PvP】EPICアミュレット交換所',
    '21008':'【PvP】EPICアミュレット交換所',
    '21009':'【PvP】EPICアミュレット交換所',
    '21010':'【PvP】EPICアミュレット交換所',
    '21011':'【PvP】アミュレット交換所',
    '21012':'【PvP】アミュレット交換所',
    '21013':'【PvP】アミュレット交換所',
    '21014':'【PvP】アミュレット交換所',
    '21015':'【PvP】アミュレット交換所',
    '21016':'【PvP】アミュレット交換所',
    '21017':'【PvP】アミュレット交換所',
    '21018':'【PvP】アミュレット交換所',
    '21019':'【PvP】アミュレット交換所',
    '21020':'【PvP】アミュレット交換所',
    '21021':'【PvP】アミュレット交換所',
    '21022':'【PvP】アミュレット交換所',
    '21023':'【PvP】アミュレット交換所',
    '30001':'【3rd Anniversary】アミュレット交換所',
    '30002':'【3.5-year anniversary】アミュレット交換所',
    '40001':'【イベントクエスト】金子一馬コラボ',
    '40002':'【イベントクエスト】ブレイバーズ',
    '40003':'【イベントクエスト】ハロウィン',
    '40004':'【イベントクエスト】3.5周年',
    '40005':'【イベントクエスト】年越し',
};

export default {
    itemLabel,
    shopLabel,
};
