import { put, delay, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setNftDepTrendPageDataCount, setNftDepTrendPageDataAmount, setNftDepTrendDataCountryOption, setNftDepTrendDataGameOption,
    setNftDepTrendLoading, setNftDepTrendCountryLoading, setNftDepTrendPageDataSummary  } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';
import NftDepConfig from 'utils/NftDepConfig';

export const trendOptionDateRange = state => state.nftDepTrend.nftDepTrendDateRange;
export const trendOptionSelected = state => state.nftDepTrend.trendOptionSelected;
export const trendDataCountryOption = state => state.nftDepTrend.trendDataCountryOption;
export const trendOptionCountrySelected = state => state.nftDepTrend.trendDataCountrySelected;
export const trendDataGameSelected = state => state.nftDepTrend.trendDataGameSelected;

export function* getNftDepTrendDataKinds() {
    try {
        yield put(setNftDepTrendLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);
        const countryTarget = yield select(trendOptionCountrySelected);

        // const sourceStaking = H.ElasticSearch.getNftDepTrendKind(rangeTarget);
        // const esQueryOwnTrend = {
        //     "es": "daa",
        //     "query": "simpleSearch",
        //     "index": "receipts-*",
        //     "src": `${sourceStaking}`
        // };
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const sqlQueryGetTransferType = H.SqlHelper.getTransferEventTypes({timeType: 'create_datetime', range: [rangeStart, rangeEnd]});

        // const [
        //     dataDepAuctionTrendTemp,
        // ] = yield all([
        //     call(getDataFromSxi, sqlQueryGetTransferType, ENDPOINT.GET_DATA_SXI, jwtToken),
        // ]);

        // // Set TX options
        // const nftDepAuctionTrendData = dataDepAuctionTrendTemp;
        // const bucketData = nftDepAuctionTrendData.aggregations.terms.buckets;
        // let txOptionArray = [], txOptionObj = {};
        // for (let i=0; i<NftDepConfig.trendOption.length; i++) {
        //     let index = bucketData.map(function(o) { return o.key; }).indexOf(NftDepConfig.trendOption[i]['value']);
        //     if (index !== -1) {
        //         txOptionObj.name = `${NftDepConfig.trendOption[i]['name']} (${bucketData[index].doc_count})`;
        //         txOptionObj.value = NftDepConfig.trendOption[i]['value'];
        //         txOptionArray.push(txOptionObj);
        //         txOptionObj = {};
        //     }
        // }
        // yield put(setNftDepTrendOption(txOptionArray));
        yield put(setNftDepTrendLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataCountryEs() {
    try {
        yield put(setNftDepTrendCountryLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);
        let kindsArray = [];
        for (let i=0; i<trendTarget.length; i++) {
            kindsArray.push(`"${trendTarget[i]['value']}"`);
        }

        const sourceStaking = H.ElasticSearch.getNftDepTrendCountry(rangeTarget, kindsArray.toString());
        const esQueryOwnTrend = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "receipts-*",
            "src": `${sourceStaking}`
        };

        const [
            dataDepTrendCountryTemp,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryOwnTrend, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);
        const fromCountires = dataDepTrendCountryTemp.aggregations.from_country_count.buckets;
        const toCountires = dataDepTrendCountryTemp.aggregations.to_country_count.buckets;

        let result = Object.values(fromCountires.concat(toCountires).reduce((acc, v) => {
            if (!acc[v.key]) {
                acc[v.key] = {name: v.key + " (" + v.doc_count + ")", value: 0};
            }
            acc[v.key].value = v.key;
            return acc;
         }, {}));

        yield put(setNftDepTrendDataCountryOption(result));
        yield put(setNftDepTrendCountryLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataSxi() {
    try {
        yield put(setNftDepTrendLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);
        const countryTarget = yield select(trendOptionCountrySelected);
        const gemeTarget = yield select(trendDataGameSelected);

        let kindsArray = [];
        for (let i=0; i<trendTarget.length; i++) {
            kindsArray.push(`"${trendTarget[i]['value']}"`);
        }
        let countryArray = [];
        if (countryTarget != undefined && countryTarget.length > 0) {
            for (let i=0; i<countryTarget.length; i++) {
                countryArray.push(`"${countryTarget[i]['value']}"`);
            }
        }
        let gameArray = [];
        if (gemeTarget != undefined && gemeTarget.length > 0) {
            for (let i=0; i<gemeTarget.length; i++) {
                gameArray.push(`"${gemeTarget[i]['value']}"`);
            }
        }
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        const sqlQueryGetSumByType1st = H.SqlHelper.getNftTransactionSumByType({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetSumByType2nd = H.SqlHelper.getNftTransactionSumByType({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetCountByType1st = H.SqlHelper.getNftTransactionCountByType({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetCountByType2nd = H.SqlHelper.getNftTransactionCountByType({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetUnique1st = H.SqlHelper.getNftTransactionUnique({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetUnique2nd = H.SqlHelper.getNftTransactionUnique({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetUniCountByType1st = H.SqlHelper.getNftTransactionUniqueCountByType({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetUniCountByType2nd = H.SqlHelper.getNftTransactionUniqueCountByType({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetActiveWalletCount = H.SqlHelper.getNftTransactionActiveWallet({range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetSumByTypeFiat = H.SqlHelper.getNftTransactionSumByType({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetCountByTypeFiat = H.SqlHelper.getNftTransactionCountByType({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        const sqlQueryGetUniqueFiat = H.SqlHelper.getNftTransactionUnique({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});
        //const sqlQueryGetUniCountByTypeFiat = H.SqlHelper.getNftTransactionUniqueCountByType({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], country: countryArray, game: gameArray});

        const [
            dataDepSumTemp1st,
            dataDepSumTemp2nd,
            dataDepCountTemp1st,
            dataDepCountTemp2nd,
            dataUniquetemp1st,
            dataUniquetemp2nd,
            dataDepUniCountTemp1st,
            dataDepUniCountTemp2nd,
            dataDepActiveWallet,
            dataDepSumTempFiat,
            dataDepCountTempFiat,
            dataUniquetempFiat,
            //dataDepUniCountTempFiat,
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetSumByType1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetSumByType2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetCountByType1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetCountByType2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetUniCountByType1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetUniCountByType2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetActiveWalletCount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetSumByTypeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetCountByTypeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetUniqueFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            // call(getDataFromRDS, sqlQueryGetUniCountByTypeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const sum1st = JSON.parse(dataDepSumTemp1st.body);
        const sum2nd = JSON.parse(dataDepSumTemp2nd.body);
        const count1st = JSON.parse(dataDepCountTemp1st.body);
        const count2nd = JSON.parse(dataDepCountTemp2nd.body);
        const uni1st = JSON.parse(dataUniquetemp1st.body);
        const uni2nd = JSON.parse(dataUniquetemp2nd.body);
        const countUni1st = JSON.parse(dataDepUniCountTemp1st.body);
        const countUni2nd = JSON.parse(dataDepUniCountTemp2nd.body);
        const activeWallet = JSON.parse(dataDepActiveWallet.body);
        const sumFiat = JSON.parse(dataDepSumTempFiat.body);
        const countFiat = JSON.parse(dataDepCountTempFiat.body);
        const uniFiat = JSON.parse(dataUniquetempFiat.body);
        // const countUniFiat = JSON.parse(dataDepUniCountTempFiat.body);

        let xAxisDate = [], dataArray = [], nftDepTrendCountData = [], nftDepTrendAmountData = [];
        let allQueryArray = [], trendTargetArray = [], zeroValueArray = [], numOfLoop = 0;

        // Set xAxisDate
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
        _.each(daysArray, function(data) {
            xAxisDate.push(data);             
        });

        // Sum
        _.each(sum1st, function(data) {
            dataArray.push(Number(data[1]['stringValue']));
        });
        const total1st = _.sum(dataArray);
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const data1 = {
            id: 'store-amount',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendAmountData.push(data1);

        // fiat amount
        dataArray = [];
        _.each(sumFiat, function(data) {
            dataArray.push(Number(data[1]['stringValue']));
        });
        const totalFiat = _.sum(dataArray);
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const dataFiat = {
            id: 'fiat-amount',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendAmountData.push(dataFiat);

        dataArray = [];
        _.each(sum2nd, function(data) {
            dataArray.push(Number(data[1]['stringValue']));
        });
        const total2nd = _.sum(dataArray);
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const data2 = {
            id: 'auction-amount',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendAmountData.push(data2);

        // Count
        dataArray = [];
        _.each(count1st, function(data) {
            dataArray.push(Number(data[1]['longValue']));
        });
        const countAll1st = _.sum(dataArray);
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const data3 = {
            id: 'store-count',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendCountData.push(data3);

        // fiat Count
        dataArray = [];
        _.each(countFiat, function(data) {
            dataArray.push(Number(data[1]['longValue']));
        });
        const countAllFiat = _.sum(dataArray);
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const dataFiatCount = {
            id: 'fiat-count',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendCountData.push(dataFiatCount);

        dataArray = [];
        _.each(count2nd, function(data) {
            dataArray.push(Number(data[1]['longValue']));
        });
        const countAll2nd = _.sum(dataArray);
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const data4 = {
            id: 'auction-count',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendCountData.push(data4);

        // Unique Count
        dataArray = [];
        _.each(activeWallet, function(data) {
            dataArray.push(Number(data[1]['longValue']));
        });
        if (dataArray.length < xAxisDate.length) {
            zeroValueArray = [];
            numOfLoop = xAxisDate.length - dataArray.length;
            for (let i=0; i<numOfLoop; i++) {
                zeroValueArray.push(0);
            }
            dataArray = zeroValueArray.concat(dataArray);
        }
        const data5 = {
            id: 'activeWallet',
            data: dataArray,
            date: xAxisDate,
        };
        nftDepTrendAmountData.push(data5);
        // dataArray = [];
        // _.each(countUni1st, function(data) {
        //     dataArray.push(Number(data[1]['longValue']));
        // });
        // if (dataArray.length < xAxisDate.length) {
        //     zeroValueArray = [];
        //     numOfLoop = xAxisDate.length - dataArray.length;
        //     for (let i=0; i<numOfLoop; i++) {
        //         zeroValueArray.push(0);
        //     }
        //     dataArray = zeroValueArray.concat(dataArray);
        // }
        // const data5 = {
        //     id: 'store-uni-count',
        //     data: dataArray,
        //     date: xAxisDate,
        // };
        // nftDepTrendCountData.push(data5);

        // dataArray = [];
        // _.each(countUni2nd, function(data) {
        //     dataArray.push(Number(data[1]['longValue']));
        // });
        // if (dataArray.length < xAxisDate.length) {
        //     zeroValueArray = [];
        //     numOfLoop = xAxisDate.length - dataArray.length;
        //     for (let i=0; i<numOfLoop; i++) {
        //         zeroValueArray.push(0);
        //     }
        //     dataArray = zeroValueArray.concat(dataArray);
        // }
        // const data6 = {
        //     id: 'auction-uni-count',
        //     data: dataArray,
        //     date: xAxisDate,
        // };
        // nftDepTrendCountData.push(data6);

        yield put(setNftDepTrendPageDataCount(nftDepTrendCountData));
        yield put(setNftDepTrendPageDataAmount(nftDepTrendAmountData));
        const summarydata = {
            total1st: total1st,
            total2nd: total2nd,
            totalFiat: totalFiat,
            count1st: countAll1st,
            count2nd: countAll2nd,
            countFiat: countAllFiat,
            unique1st: uni1st[0][0]['longValue'],
            unique2nd: uni2nd[0][0]['longValue'],
            uniqueFiat: uniFiat[0][0]['longValue'],
        }
        yield put(setNftDepTrendPageDataSummary(summarydata));
        yield put(setNftDepTrendLoading(false));
    } catch (err) {
        console.log(err);
    }
}


export function* getNftDepTrendData() {
    try {
        yield put(setNftDepTrendLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);
        const countryTarget = yield select(trendOptionCountrySelected);
        let kindsArray = [];
        for (let i=0; i<trendTarget.length; i++) {
            kindsArray.push(`"${trendTarget[i]['value']}"`);
        }
        let countryArray = [];
        if (countryTarget != undefined && countryTarget.length > 0) {
            for (let i=0; i<countryTarget.length; i++) {
                countryArray.push(`"${countryTarget[i]['value']}"`);
            }
        }

        // data call
        const sourceStaking = H.ElasticSearch.getNftDepTrendData(rangeTarget, kindsArray.toString(), countryArray.toString());
        const esQueryDepTrend = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "receipts-*",
            "src": `${sourceStaking}`
        };

        const [
            dataDepTrendTemp,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryDepTrend, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);
        const dataFromEs = dataDepTrendTemp.aggregations.tx_data.buckets;

        let xAxisDate = [], nftDepTrendData = [], nftDepTrendAmountData = [],
        dataArray1 = [], dataArray2 = [], dataArray3 = [], dataArray4 = [], dataArray5 = [],
        dataArray6 = [], dataArray7 = [], dataArray8 = [], dataArray9 = [], dataArray10 = [],
        dataAmount1 = [], dataAmount2 = [], dataAmount3 = [], dataAmount4 = [], dataAmount5 = [],
        dataAmount6 = [], dataAmount7 = [], dataAmount8 = [], dataAmount9 = [];

        // Set xAxisDate
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
        _.each(daysArray, function(data) {
            xAxisDate.push(data);             
        });

        for (let j=0; j<dataFromEs.length; j++) {
            for (let k=0; k < dataFromEs[j]['kind']['buckets'].length; k++) {
                switch(dataFromEs[j]['kind']['buckets'][k]['key']) {
                    case "auction":
                        dataArray1.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount1.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "store":
                        dataArray2.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount2.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "distribute":
                        dataArray3.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount3.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "distributions":
                        dataArray4.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount4.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "withdrawal":
                        dataArray5.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount5.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "staking_cancel":
                        dataArray6.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount6.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "staking_apply":
                        dataArray7.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount7.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "staking_interest":
                        dataArray8.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount8.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    case "airdrop":
                        dataArray9.push(dataFromEs[j]['kind']['buckets'][k]['doc_count']);
                        dataAmount9.push(dataFromEs[j]['kind']['buckets'][k]['amount']['value']);
                        break;
                    default:
                }
            }
        }
        if (dataArray1.length > 0) {
            let auctionDataObj1 = {
                id: 'auction',
                data: dataArray1,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj1);
            let amt1DataAmount = {
                id: 'auction-amount',
                data: dataAmount1,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt1DataAmount);
        }
        if (dataArray2.length > 0) {
            let auctionDataObj2 = {
                id: 'store',
                data: dataArray2,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj2);
            let amt2DataAmount = {
                id: 'store-amount',
                data: dataAmount2,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt2DataAmount);
        }
        if (dataArray3.length > 0) {
            let auctionDataObj3 = {
                id: 'distribute',
                data: dataArray3,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj3);
            let amt3DataAmount = {
                id: 'distribute-amount',
                data: dataAmount3,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt3DataAmount);
        }
        if (dataArray4.length > 0) {
            let auctionDataObj4 = {
                id: 'distributions',
                data: dataArray4,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj4);
            let amt4DataAmount = {
                id: 'distributions-amount',
                data: dataAmount4,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt4DataAmount);
        }
        if (dataArray5.length > 0) {
            let auctionDataObj5 = {
                id: 'withdrawal',
                data: dataArray5,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj5);
            let amt5DataAmount = {
                id: 'withdrawal-amount',
                data: dataAmount5,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt5DataAmount);
        }
        if (dataArray6.length > 0) {
            let auctionDataObj6 = {
                id: 'staking_cancel',
                data: dataArray6,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj6);
            let amt6DataAmount = {
                id: 'staking_cancel-amount',
                data: dataAmount6,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt6DataAmount);
        }
        if (dataArray7.length > 0) {
            let auctionDataObj7 = {
                id: 'staking_apply',
                data: dataArray7,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj7);
            let amt7DataAmount = {
                id: 'staking_apply-amount',
                data: dataAmount7,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt7DataAmount);
        }
        if (dataArray8.length > 0) {
            let auctionDataObj8 = {
                id: 'staking_interest',
                data: dataArray8,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj8);
            let amt8DataAmount = {
                id: 'staking_interest-amount',
                data: dataAmount8,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt8DataAmount);
        }
        if (dataArray9.length > 0) {
            let auctionDataObj9 = {
                id: 'airdrop',
                data: dataArray9,
                date: xAxisDate,
            }
            nftDepTrendData.push(auctionDataObj9);
            let amt9DataAmount = {
                id: 'airdrop-amount',
                data: dataAmount9,
                date: xAxisDate,
            }
            nftDepTrendAmountData.push(amt9DataAmount);
        }

        yield put(setNftDepTrendPageDataCount(nftDepTrendData));
        yield put(setNftDepTrendPageDataAmount(nftDepTrendAmountData));
        yield put(setNftDepTrendLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataCount() {
    try {
        yield put(setNftDepTrendLoading(true));
        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);
        const countryTarget = yield select(trendOptionCountrySelected);
        let xAxisDate = [], dataArray = [], nftDepTrendData = [];
        let allQueryArray = [], trendTargetArray = [], zeroValueArray = [], numOfLoop = 0;

        _.forEach(trendTarget, function(data, i) {
            let sqlQueryNftDepTrend = H.SqlHelper.getNftDepTrendDataCount(data.value, rangeTarget, countryTarget);
            allQueryArray.push(call(getDataFromRDS, sqlQueryNftDepTrend, ENDPOINT.GET_DATA_RDS, jwtToken));
            trendTargetArray.push(data.value);
        });

        // Fetch data
        const [dataTemp1, dataTemp2, dataTemp3] = yield all(allQueryArray);

        // Set xAxisDate
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
        _.each(daysArray, function(data) {
            xAxisDate.push(data);             
        });

        if (dataTemp1) {
            const dataReturned1 = JSON.parse(dataTemp1.body);
            _.each(dataReturned1, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned1, d => { return d[1]['longValue'] });
            const data1 = {
                id: trendTargetArray[0],
                data: dataArray,
                date: xAxisDate,
                avg: totalNum === 0 ? 0 : Number((totalNum / dataReturned1.length).toFixed(0))
            };
            nftDepTrendData.push(data1);
        }
        if (dataTemp2) {
            dataArray = [];
            const dataReturned2 = JSON.parse(dataTemp2.body);
            _.each(dataReturned2, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned2, d => { return d[1]['longValue'] });
            const data2 = {
                id: trendTargetArray[1],
                data: dataArray,
                date: xAxisDate,
                avg: totalNum === 0 ? 0 : Number((totalNum / dataReturned2.length).toFixed(0))
            };
            nftDepTrendData.push(data2);
        }
        if (dataTemp3) {
            dataArray = [];
            const dataReturned3 = JSON.parse(dataTemp3.body);
            _.each(dataReturned3, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned3, d => { return d[1]['longValue'] });
            const data3 = {
                id: trendTargetArray[2],
                data: dataArray,
                date: xAxisDate,
                avg: totalNum === 0 ? 0 : Number((totalNum / dataReturned3.length).toFixed(0))
            };
            nftDepTrendData.push(data3);
        }
        yield put(setNftDepTrendPageDataCount(nftDepTrendData));
        yield put(setNftDepTrendLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataAmount() {
    try {
        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);
        const countryTarget = yield select(trendOptionCountrySelected);
        let xAxisDate = [], dataArray = [], nftDepTrendData = [];
        let allQueryArray = [], trendTargetArray = [], zeroValueArray = [], numOfLoop = 0;

        // Amount
        _.forEach(trendTarget, function(data, i) {
            if (data.value !== "activeWallet") {
                let sqlQueryNftDepTrendAmount = H.SqlHelper.getNftDepTrendDataAmount(data.value, rangeTarget, countryTarget);
                allQueryArray.push(call(getDataFromRDS, sqlQueryNftDepTrendAmount, ENDPOINT.GET_DATA_RDS, jwtToken));
                trendTargetArray.push(data.value);
            }
        });

        // Fetch data
        const [dataTemp4, dataTemp5] = yield all(allQueryArray);

        // Set xAxisDate
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
        _.each(daysArray, function(data) {
            xAxisDate.push(data);             
        });

        if (dataTemp4) {
            dataArray = [];
            const dataReturned4 = JSON.parse(dataTemp4.body);
            _.each(dataReturned4, function(data) {
                dataArray.push(Number(data[1]['stringValue']));
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned4, d => { return Number(d[1]['stringValue']) });
            const data4 = {
                id: trendTargetArray[0]+"-amount",
                data: dataArray,
                date: xAxisDate,
                avg: totalNum === 0 ? 0 : Number((totalNum / dataReturned4.length).toFixed(0))
            };
            nftDepTrendData.push(data4);
        }

        if (dataTemp5) {
            dataArray = [];
            const dataReturned5 = JSON.parse(dataTemp5.body);
            _.each(dataReturned5, function(data) {
                dataArray.push(Number(data[1]['stringValue']));
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned5, d => { return Number(d[1]['stringValue']) });
            const data5 = {
                id: trendTargetArray[1]+"-amount",
                data: dataArray,
                date: xAxisDate,
                avg: totalNum === 0 ? 0 : Number((totalNum / dataReturned5.length).toFixed(0))
            };
            nftDepTrendData.push(data5);
        }
        yield put(setNftDepTrendPageDataAmount(nftDepTrendData));
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataCountry() {
    try {
        yield put(setNftDepTrendCountryLoading(true));
        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const trendTarget = yield select(trendOptionSelected);

        const dateFrom = moment(rangeTarget[0]).format('YYYY-MM-DD');
        const dateTo = moment(rangeTarget[1]).format('YYYY-MM-DD');
        const unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];

        let allCountryQueryArray = [], trendTargetCountryArray = [];
        _.forEach(trendTarget, function(data, i) {
            let sqlQueryNftDepTrendCountry = H.SqlHelper.getNftDepTrendCountry(data.value, unixStampTime);
            allCountryQueryArray.push(call(getDataFromRDS, sqlQueryNftDepTrendCountry, ENDPOINT.GET_DATA_RDS, jwtToken));
            trendTargetCountryArray.push(data.value);
        });
        const [dataTemp1, dataTemp2, dataTemp3] = yield all(allCountryQueryArray);

        yield put(setNftDepTrendCountryLoading(false));
        if (dataTemp1) {
            const dataReturned1 = JSON.parse(dataTemp1.body);
            let newOptionArray1 = [{name: "全て", value:"all"}];
            _.each(dataReturned1, function(data) {
                let countryObj = {
                    "name": data[0]['stringValue'],
                    "value": data[0]['stringValue'],
                    "num": data[1]['longValue'],
                }
                newOptionArray1.push(countryObj);
                countryObj = {};
            });
            yield put(setNftDepTrendDataCountryOption(newOptionArray1));
        }
        if (dataTemp2) {
            const dataReturned2 = JSON.parse(dataTemp2.body);
            let newOptionArray2 = yield select(trendDataCountryOption);
            _.each(dataReturned2, function(data) {
                let countryObj2 = {
                    "name": data[0]['stringValue'],
                    "value": data[0]['stringValue'],
                    "num": data[1]['longValue'],
                }
                newOptionArray2.push(countryObj2);
                countryObj2 = {};
            });
            let result = _(newOptionArray2)
                .groupBy(({ name, value }) => name + value)
                .map((g) => _.mergeWith(...g, (o, s, k) => k === 'num' ? o + s : o))
                .value();
            const option2ArraySort = _.orderBy(result, ['num'],['desc']);
            yield put(setNftDepTrendDataCountryOption(option2ArraySort));
        }
        if (dataTemp3) {
            const dataReturned3 = JSON.parse(dataTemp3.body);
            let newOptionArray3 = yield select(trendDataCountryOption);
            _.each(dataReturned3, function(data) {
                let countryObj3 = {
                    "name": data[0]['stringValue'],
                    "value": data[0]['stringValue'],
                    "num": data[1]['longValue'],
                }
                newOptionArray3.push(countryObj3);
                countryObj3 = {};
            });
            let result = _(newOptionArray3)
                .groupBy(({ name, value }) => name + value)
                .map((g) => _.mergeWith(...g, (o, s, k) => k === 'num' ? o + s : o))
                .value();
            const option3ArraySort = _.orderBy(result, ['num'],['desc']);
            yield put(setNftDepTrendDataCountryOption(option3ArraySort));
        }
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataCountrySxi() {
    try {
        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const gemeTarget = yield select(trendDataGameSelected);
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        let gameArray = [];
        if (gemeTarget != undefined && gemeTarget.length > 0) {
            for (let i=0; i<gemeTarget.length; i++) {
                gameArray.push(`"${gemeTarget[i]['value']}"`);
            }
        }
        const sqlQueryGetCountry = H.SqlHelper.getNftTransactionCountry2({range: [rangeStart, rangeEnd], game: gameArray});

        const [
            dataCountry,
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const countryData = JSON.parse(dataCountry.body);

        // Country set
        let newOptionArray1 = [];
        _.each(countryData, function(data) {
            let countryObj = {
                "name": `${data[0]['stringValue']} (${data[1]['longValue']})`,
                "value": data[0]['stringValue'],
                "num": data[1]['longValue'],
            }
            newOptionArray1.push(countryObj);
            countryObj = {};
        });
        yield put(setNftDepTrendDataCountryOption(newOptionArray1));
    } catch (err) {
        console.log(err);
    }
}

export function* getNftDepTrendDataGamesSxi() {
    try {
        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(trendOptionDateRange);
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const sqlQueryGetGame = H.SqlHelper.getNftTransactionGames2({range: [rangeStart, rangeEnd]});

        const [
            dataGames,
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const gameData = JSON.parse(dataGames.body);

        // Country set
        let newOptionArray1 = [];
        _.each(gameData, function(data) {
            let countryObj = {
                "name": `${H.ContentsHelper.getContentsNameById(data[0]['stringValue'])} (${data[1]['longValue']})`,
                "value": data[0]['stringValue'],
                "num": data[1]['longValue'],
            }
            newOptionArray1.push(countryObj);
            countryObj = {};
        });
        yield put(setNftDepTrendDataGameOption(newOptionArray1));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    // yield takeEvery(NFTDEP.GET_TREND_TX_KINDS, getNftDepTrendDataKinds);
    yield takeEvery(NFTDEP.GET_TREND_PAGE_DATA, getNftDepTrendDataSxi);
    // yield takeEvery(NFTDEP.GET_TREND_PAGE_DATA, getNftDepTrendDataCount);
    // yield takeEvery(NFTDEP.GET_TREND_PAGE_DATA, getNftDepTrendDataAmount);
    yield takeEvery(NFTDEP.GET_TREND_DATA_COUNTRY, getNftDepTrendDataCountrySxi);
    //yield takeEvery(NFTDEP.GET_TREND_DATA_COUNTRY, getNftDepTrendDataCountryEs);
    yield takeEvery(NFTDEP.GET_TREND_DATA_GAMES, getNftDepTrendDataGamesSxi);
}
