import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setLuckyFarmerGameTrendDataLoading,
  setLuckyFarmerGameTrendDataStamina,
  setLuckyFarmerGameTrendDataLand,
  setLuckyFarmerGameTrendDataUniqueCount
} from "actions";
import { getJwtToken, getDataFromRDS } from "api";
import { LUCKYFARMER, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = state => state.page.currentPage;
export const luckyFarmerGamePaidTrendDateRange = state => state.luckyFarmerGamePaidTrend.luckyFarmerGamePaidTrendDateRange;
export const startDate = state => state.luckyFarmerGamePaidTrend.luckyFarmerGamePaidTrendDateRange[0];
export const endDate = state => state.luckyFarmerGamePaidTrend.luckyFarmerGamePaidTrendDateRange[1];

export function* getLuckyFarmerNftTrendData() {
  try {
    yield put(setPageTopLoader(true));
    yield put(setLuckyFarmerGameTrendDataLoading(true));

    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(luckyFarmerGamePaidTrendDateRange);

    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    dateTo = moment(dateTo).format("YYYY-MM-DD");

    // DATA2.0
    const isDataNew = moment(dateFrom).isAfter('2023-06-24') && moment(dateTo).isAfter('2023-06-24') ? true : false;
    const unixStampTime = [
        moment(rangeTarget[0]+" 00:00:00+8:00").format('x'),
        moment(rangeTarget[1]+" 23:59:59+8:00").format('x')
    ];

    // Count
    const sqlQueryInGameCountByStamina = H.SqlHelper.getGameTransactionCountByGame({
      table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
      product: "recovery_stamina",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByLand = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "recovery_land",
        range: unixStampTime,
    });
    const sqlQueryInGameCountByShop = H.SqlHelper.getGameTransactionCountByGameLfShop({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "SHOP",
        range: unixStampTime,
    });
    const sqlQueryInGameCountByGacha = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "gatcha",
        range: unixStampTime,
    });

    // count unique
    const sqlQueryInGameCountByStaminaUni = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "recovery_stamina",
        range: unixStampTime,
        unique: true,
      });
    const sqlQueryInGameCountByLandUni = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "recovery_land",
        range: unixStampTime,
        unique: true,
    });
    const sqlQueryInGameCountByShopUni = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "SHOP",
        range: unixStampTime,
        unique: true,
    });
    const sqlQueryInGameCountByGachaUni = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "gatcha",
        range: unixStampTime,
        unique: true,
    });
    const sqlQueryInGameCountByAllUni = H.SqlHelper.getGameTransactionCountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "all",
        range: unixStampTime,
        unique: true,
    });

    // Amount
    const sqlQueryInGameAmountByStamina = H.SqlHelper.getGameTransactionAmountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "recovery_stamina",
        range: unixStampTime,
      });
    const sqlQueryInGameAmountByLand = H.SqlHelper.getGameTransactionAmountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "recovery_land",
        range: unixStampTime,
    });
    const sqlQueryInGameAmountByShop = H.SqlHelper.getGameTransactionAmountByGameLfShop({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "SHOP",
        range: unixStampTime,
    });
    const sqlQueryInGameAmountByGacha = H.SqlHelper.getGameTransactionAmountByGame({
        table: isDataNew ? "LuckyFarmerGameSalesPmEvtProd" : "LuckyFarmerGameSalesProd",
        product: "gatcha",
        range: unixStampTime,
    });

    const [byStaminaTemp, byLandTemp, byShopTemp, byGachaTemp,
        byAmtStaminaTemp, byAmtLandTemp, byAmtShopTemp, byAmtGachaTemp,
        byStaminaUniTemp, byLandUniTemp, byShopUniTemp, byGachaUniTemp, byAllCountTemp] = yield all([
      call(getDataFromRDS, sqlQueryInGameCountByStamina, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByLand, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByShop, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByGacha, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByStamina, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByLand, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByShop, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByGacha, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByStaminaUni, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByLandUni, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByShopUni, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByGachaUni, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByAllUni, ENDPOINT.GET_DATA_RDS, jwtToken),
    ]);

    let uniqueSta = 0, uniqueLand = 0, uniqueShop = 0, uniqueGacha = 0, uniqueAll = 0;
    _.forEach(JSON.parse(byStaminaUniTemp.body), function (d, i) {
        uniqueSta = uniqueSta+d[1]['longValue'];
    });
    _.forEach(JSON.parse(byLandUniTemp.body), function (d, i) {
        uniqueLand = uniqueLand+d[1]['longValue'];
    });
    _.forEach(JSON.parse(byShopUniTemp.body), function (d, i) {
        uniqueShop = uniqueShop+d[1]['longValue'];
    });
    _.forEach(JSON.parse(byGachaUniTemp.body), function (d, i) {
        uniqueGacha = uniqueGacha+d[1]['longValue'];
    });
    _.forEach(JSON.parse(byAllCountTemp.body), function (d, i) {
        uniqueAll = uniqueAll+d[1]['longValue'];
    });
    const uniqueCountData = [
      {"stamina": uniqueSta},      
      {"land": uniqueLand},
      {"shop": uniqueShop},
      {"gacha": uniqueGacha},
      {"all": uniqueAll}
    ];

    // calendar date range
    const range = moment.range(rangeTarget[0], rangeTarget[1]);
    const days = Array.from(range.by("days"));
    const dateArray = days.map((m) => m.format("YYYY-MM-DD"));

    let allDataCount = [], allDataAmount = [];
    let countArrayStamina = [], amountArrayStamina = [];
    let staminaDateArray = [], staminaCountArray = [], staminaAmountArray = [];
    _.forEach(JSON.parse(byStaminaTemp.body), function (data, i) {
        staminaDateArray.push(data[0]['stringValue'])
    });

    _.forEach(JSON.parse(byStaminaTemp.body), function (data, i) {
        countArrayStamina.push(data[1]['longValue'])
    });

    _.forEach(JSON.parse(byAmtStaminaTemp.body), function (data, i) {
        amountArrayStamina.push(Number(data[1]['stringValue']))
    });

    _.forEach(dateArray, function(date, i) {
        if (staminaDateArray.indexOf(date) !== -1) {
            const indexItem =  staminaDateArray.indexOf(date);
            staminaCountArray.push(countArrayStamina[indexItem]);
            staminaAmountArray.push(amountArrayStamina[indexItem])
        } else {
            staminaCountArray.push(0);
            staminaAmountArray.push(0)
        }
    });

    let countArrayLand = [], amountArrayLand = [];
    let landDateArray = [], landCountArray = [], landAmountArray = [];
    _.forEach(JSON.parse(byLandTemp.body), function (data, i) {
        landDateArray.push(data[0]['stringValue'])
    });
    _.forEach(JSON.parse(byLandTemp.body), function (data, i) {
        countArrayLand.push(data[1]['longValue'])
    });
    _.forEach(JSON.parse(byAmtLandTemp.body), function (data, i) {
        amountArrayLand.push(Number(data[1]['stringValue']))
    });

    _.forEach(dateArray, function(date, i) {
        if (landDateArray.indexOf(date) !== -1) {
            const indexItem =  landDateArray.indexOf(date);
            landCountArray.push(countArrayLand[indexItem]);
            landAmountArray.push(amountArrayLand[indexItem])
        } else {
            landCountArray.push(0);
            landAmountArray.push(0)
        }
    });

    let countArrayShop = [], amountArrayShop = [];
    let shopDateArray = [], shopCountArray = [], shopAmountArray = [];
    _.forEach(JSON.parse(byShopTemp.body), function (data, i) {
        shopDateArray.push(data[0]['stringValue'])
    });
    _.forEach(JSON.parse(byShopTemp.body), function (data, i) {
        countArrayShop.push(Number(data[1]['stringValue']))
    });
    _.forEach(JSON.parse(byAmtShopTemp.body), function (data, i) {
        amountArrayShop.push(Number(data[1]['stringValue']))
    });

    _.forEach(dateArray, function(date, i) {
        if (shopDateArray.indexOf(date) !== -1) {
            const indexItem =  shopDateArray.indexOf(date);
            shopCountArray.push(countArrayShop[indexItem]);
            shopAmountArray.push(amountArrayShop[indexItem])
        } else {
            shopCountArray.push(0);
            shopAmountArray.push(0)
        }
    });

    let countArrayGacha = [], amountArrayGacha = [];
    let gachaDateArray = [], gachaCountArray = [], gachaAmountArray = [];
    _.forEach(JSON.parse(byGachaTemp.body), function (data, i) {
        gachaDateArray.push(data[0]['stringValue'])
    });
    _.forEach(JSON.parse(byGachaTemp.body), function (data, i) {
        countArrayGacha.push(data[1]['longValue'])
    });
    _.forEach(JSON.parse(byAmtGachaTemp.body), function (data, i) {
        amountArrayGacha.push(Number(data[1]['stringValue']))
    });

    _.forEach(dateArray, function(date, i) {
        if (gachaDateArray.indexOf(date) !== -1) {
            const indexItem =  gachaDateArray.indexOf(date);
            gachaCountArray.push(countArrayGacha[indexItem]);
            gachaAmountArray.push(amountArrayGacha[indexItem])
        } else {
            gachaCountArray.push(0);
            gachaAmountArray.push(0)
        }
    });

    allDataCount.push(
        {
            id: "ingame-stamina-count",
            data: staminaCountArray,
            date: dateArray
        }
    );
    allDataCount.push(
        {
            id: "ingame-land-count",
            data: landCountArray,
            date: dateArray
        }
    );
    allDataCount.push(
        {
            id: "ingame-shop-count",
            data: shopCountArray,
            date: dateArray
        }
    );
    allDataCount.push(
        {
            id: "ingame-gacha-count",
            data: gachaCountArray,
            date: dateArray
        }
    );

    allDataAmount.push(
        {
            id: "ingame-stamina-amount",
            data: staminaAmountArray,
            date: dateArray
        }
    );
    allDataAmount.push(
        {
            id: "ingame-land-amount",
            data: landAmountArray, amountArrayLand,
            date: dateArray
        }
    );
    allDataAmount.push(
        {
            id: "ingame-shop-amount",
            data: shopAmountArray,
            date: dateArray
        }
    );
    allDataAmount.push(
        {
            id: "ingame-gacha-amount",
            data: gachaAmountArray,
            date: dateArray
        }
    );

    yield put(setLuckyFarmerGameTrendDataStamina(allDataCount));
    yield put(setLuckyFarmerGameTrendDataLand(allDataAmount));
    yield put(setLuckyFarmerGameTrendDataUniqueCount(uniqueCountData));
    yield put(setPageTopLoader(false));
    yield put(setLuckyFarmerGameTrendDataLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(
    LUCKYFARMER.GET_LUCKYFARMER_GAME_TREND_PAGE_DATA,
    getLuckyFarmerNftTrendData
  );
}
