// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from '@material-ui/core/styles';
import * as H from 'helper';

const RegCountry = ({classes, setCurrentModule, title, source, countryByAd, countryByOrg}) => {
    const [geoData, setGeoData] = useState([]);

    useEffect(() => {
        //console.log('yoyo');
    }, []);

    useEffect(() => {
        let dataSource = source === 'ad' ? countryByAd : countryByOrg;
        let totalNum = _.sumBy(dataSource, data => {
            return data.count;
        });
        let dataArray = [], dataObj = {};
        for (let i=0; i<dataSource.length; i++) {
            dataObj.no = i+1;
            dataObj.url = dataSource[i]['country'] === '(not set)' || dataSource[i]['country'] === 'n/a' ? false : H.CountryHelper.getCountryCode(dataSource[i]['country']);
            dataObj.country = dataSource[i]['country'];
            dataObj.num_reg = dataSource[i]['count'];
            dataObj.num_ratio = (dataSource[i]['count'] / totalNum * 100).toFixed(2);
            dataArray.push(dataObj);
            dataObj = {};
        }
        setGeoData(dataArray);
    }, [countryByAd, countryByOrg]);

    return(
        <MaterialTable
            style={{padding: 16, fontSize: 14}}
            title={<div style={{fontSize: 18, fontWeight: 500, color: '#000'}}>{title}</div>}
            columns={[
                { title: '#', field: 'no', width: 50, cellStyle: {textAlign:'center'}},
                { title: '国', field: 'country', width: 350,
                    render: rowData => <Fragment>
                                            <div style={{display:'flex'}}>
                                                <div>
                                                    {rowData.url &&
                                                    <img src={rowData.url} style={{paddingTop: 2, paddingRight: 8, width: 24}}/>
                                                    }
                                                </div>
                                                <div style={{paddingTop: 4}}>{rowData.country}</div>
                                            </div>
                                        </Fragment>
                },
                { title: '登録数', field: 'num_reg', width: 200, type: 'numeric', cellStyle: {textAlign:'center'},
                    render: rowData => <div>{H.FormatNumStrHelper.formatCommaThousand(rowData.num_reg)}</div>},
                { title: '全体比率',field: 'num_ratio', type: 'numeric', cellStyle: {textAlign:'left', marginLeft: 32},
                    render: rowData => <Fragment>
                                            <div style={{display:'flex', height: 16}}>
                                                <div style={{paddingRight: 16, width: 24}}>{rowData.num_ratio}%</div>
                                                <div style={{backgroundColor: '#65C9A5', width:rowData.num_ratio+'%', marginLeft: 20, marginTop:3}}></div>
                                            </div>
                                        </Fragment>
                },
            ]}
            data={geoData}
            options={{
                search: true,
                pageSize: 10,
                pageSizeOptions: [10, 20, 30],
                rowStyle: {
                    textAlign:'center',
                },
                headerStyle: {textAlign:'center'}
            }}
            components={{
                Toolbar: props => (
                     <div style={{ marginLeft: '-20px' }}>
                             <MTableToolbar {...props} />
                     </div>
                )
            }}
            localization={{
                pagination: {
                    labelDisplayedRows: '{from}-{to} 件　全{count}件',
                    labelRowsSelect: '行',
                    firstAriaLabel: '最初のページ',
                    firstTooltip: '最初のページ',
                    previousAriaLabel: '前ページ',
                    previousTooltip: '前ページ',
                    nextAriaLabel: '次ページ',
                    nextTooltip: '次ページ',
                    lastAriaLabel: '最後のページ',
                    lastTooltip: '最後のページ',
                },
                toolbar: {
                    nRowsSelected: '{0} 行を選択',
                    searchTooltip: '検索',
                    searchPlaceholder: '検索',
                },
            }}
        />
    )
};

RegCountry.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    countryByAd: state.puzzleGeo.countryByAd,
    countryByOrg: state.puzzleGeo.countryByOrg,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(RegCountry));