import { NFTDEP } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(6, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").format('YYYY-MM-DD');

const initialState  = {
    nftHoldersDataLoading: false,
    nftHoldersData: [],
    nftHoldersStatData: [{
        "totalHolders" : '--',
        "uniqueHolders" : '--',
        "totalDep" : '--'
    }],
    pageNumber: 1,
    holdersTotalCount: 0,
    //priceRange: [0, 10000],
    nftHoldersCsvData: [],
    nftHoldersCsvLoader: false,
};

export default function nftHoldersReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_NFT_HOLDERS_DATA_LOADING:
            return {
                ...state,
                nftHoldersDataLoading: action.payload
            };
        case NFTDEP.SET_NFT_HOLDERS_DATA:
            return {
                ...state,
                nftHoldersData: action.payload
            };
        case NFTDEP.SET_NFT_HOLDERS_STATS_DATA:
            return {
                ...state,
                nftHoldersStatData: action.payload
            };
        case NFTDEP.SET_NFT_HOLDERS_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            };
        case NFTDEP.SET_NFT_HOLDERS_CSV_DATA:
            return {
                ...state,
                nftHoldersCsvData: action.payload
            };
        case NFTDEP.SET_NFT_HOLDERS_CSV_DOWNLOADER:
            return {
                ...state,
                nftHoldersCsvLoader: action.payload
            };
        case NFTDEP.SET_NFT_HOLDERS_TOTAL_COUNT:
            return {
                ...state,
                holdersTotalCount: action.payload
            };
        default:
            return state
    }
};
