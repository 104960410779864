import { GRAFITTIRACER } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    graffitiRacerGamePaidTrendDateLoading: false,
    graffitiRacerGamePaidTrendDateRange: [startDate, endDate],
    gameTrendDataDepUse: [],
    gameTrendDataDepGet: [],
    gameTrendUnqiueCount: [],
    gameTrendUnqiueCountFiat: [],
    gameTrendDataDepUseCountDetails: [],
    gameTrendDataDepUseAmountDetails: [],
    gameTrendDataDepUseCountDetailsFiat: [],
    gameTrendDataDepUseAmountDetailsFiat: [],
};

export default function graffitiracerGamePaidTrendReducer(state = initialState, action) {
    switch (action.type) {
        case GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DATA_LOADING:
            return {
                ...state,
                graffitiRacerGamePaidTrendDateLoading: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DATE_RANGE:
            return {
                ...state,
                graffitiRacerGamePaidTrendDateRange: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DEP_USE:
            return {
                ...state,
                gameTrendDataDepUse: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_DEP_GET:
            return {
                ...state,
                gameTrendDataDepGet: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT:
            return {
                ...state,
                gameTrendUnqiueCount: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_GAME_TREND_UNIQUE_COUNT_FIAT:
            return {
                ...state,
                gameTrendUnqiueCountFiat: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS:
            return {
                ...state,
                gameTrendDataDepUseCountDetails: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS:
            return {
                ...state,
                gameTrendDataDepUseAmountDetails: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_COUNT_DETAILS_FIAT:
            return {
                ...state,
                gameTrendDataDepUseCountDetailsFiat: action.payload
            };
        case GRAFITTIRACER.SET_GRAFITTIRACER_DEP_USE_AMOUNT_DETAILS_FIAT:
            return {
                ...state,
                gameTrendDataDepUseAmountDetailsFiat: action.payload
            };
        default:
            return state
    }
};
