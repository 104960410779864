import { put, delay, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setActiveUsersPageData, setPageTopLoader, setActiveUsersCountryLoading, setActiveUsersCountryOption,
setActiveUsersRidLoading, setActiveUsersRidOption, setActiveUsersUniqueTotalData,
setActiveUsersPageDataByGame, setActiveUsersUniqueTotalPaidData } from 'actions';
import { getJwtToken, getDataFromRDS } from 'api';
import { DATA, ENDPOINT } from '../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const activeUsersGameOption = state => state.activeUsers.activeUsersGameOption;
export const activeUsersGameSelected = state => state.activeUsers.activeUsersGameSelected;
export const activeUsersSizeSelected = state => state.activeUsers.activeUsersSizeSelected;
export const activeUsersDateRange = state => state.activeUsers.activeUsersDateRange;
export const activeUsersCountryOption = state => state.activeUsers.activeUsersCountryOption;
export const activeUsersCountrySelected = state => state.activeUsers.activeUsersCountrySelected;
export const activeUsersRidSelected = state => state.activeUsers.activeUsersRidSelected;
export const activeUsersDateDiff = state => state.activeUsers.activeUsersDateDiff;
export const allPmMenuSelect = state => state.activeUsers.allPmMenuSelect;

export function* getActiveUsersData() {
    try {
        yield put(setPageTopLoader(true));
        const isAllPmSelected = yield select(allPmMenuSelect);
        const jwtToken = yield call(getJwtToken);
        const usersTarget = yield select(activeUsersGameSelected);
        const sizeTarget = yield select(activeUsersSizeSelected);
        const rangeTarget = yield select(activeUsersDateRange);
        const countryTarget = yield select(activeUsersCountrySelected);
        const ridTarget = yield select(activeUsersRidSelected);
        const dateDiffTarget = yield select(activeUsersDateDiff);
        let xAxisDate = [], dataArray = [], activeUsersData = [], activeUsersAllPmData = [], allPmDataArray = [], allPmXAxisDate = [];
        let allQueryArray = [], gameTargetArray = [], allPmActiveUsersData, zeroValueArray = [], numOfLoop = 0, xAxisDataSetIndex = 0;
        let allQueryArrayNew = [];

        // DATA2.0
        const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;
        const isDataOld = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isBefore('2023-07-01') ? true : false;
        const isDataMix = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;

        if (isDataOld) {
            _.forEach(usersTarget, function(data, i) {
                if (data.value !== 'PlayMining') {
                    let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersData(data.value, sizeTarget, rangeTarget, countryTarget, ridTarget, false);
                    allQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                    gameTargetArray.push(data.value);
                }
            });
        } else if (isDataNew) {
            _.forEach(usersTarget, function(data, i) {
                if (data.value !== 'PlayMining') {
                    let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersData(data.value, sizeTarget, rangeTarget, countryTarget, ridTarget, true);
                    allQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                    gameTargetArray.push(data.value);
                }
            });
        } else {
            const newRangeOld = [rangeTarget[0], '2023-06-30'];
            const newRangeNew = ['2023-07-01', rangeTarget[1]];
            // Old loop
            _.forEach(usersTarget, function(data, i) {
                if (data.value !== 'PlayMining') {
                    let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersData(data.value, sizeTarget, newRangeOld, countryTarget, ridTarget, false);
                    allQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                    gameTargetArray.push(data.value);
                }
            });
            // New loop
            _.forEach(usersTarget, function(data, i) {
                if (data.value !== 'PlayMining') {
                    let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersData(data.value, sizeTarget, newRangeNew, countryTarget, ridTarget, true);
                    allQueryArrayNew.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                }
            });
        }

        // Decide which index to set the date array
        if (gameTargetArray.length > 1) {
            _.forEach(gameTargetArray, function(game, index) {
                if (game !== "nftdep" && game !== "luckyfarmer" && game !== "cookinburger") {
                    xAxisDataSetIndex = index;
                }
            })
        };

        if (isAllPmSelected) {
            let sqlQueryAllPmActiveUsers = H.SqlHelper.getAllPmActiveUsersDAU(sizeTarget, rangeTarget, countryTarget);
            allPmActiveUsersData = yield call(getDataFromRDS, sqlQueryAllPmActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken);
            let allPmDauArrayData = JSON.parse(allPmActiveUsersData.body);
            _.each(allPmDauArrayData, function(data, index) {
                if (index > 0) {
                    if (sizeTarget.value === 'dau') {
                        allPmXAxisDate.push(data[0]['stringValue']);
                    }
                    allPmDataArray.push(Number(data[1]['longValue']));
                }
            });
            let totalNum = _.sumBy(allPmDauArrayData, d => { return Number(d[1]['longValue']) });
            const allPmData = {
                id: 'PlayMining',
                data: allPmDataArray,
                date: allPmXAxisDate,
                avg: Number((totalNum / allPmDauArrayData.length).toFixed(0))
            };
            activeUsersAllPmData.push(allPmData);
        }

        // Fetch data
        let [dataTemp1, dataTemp2, dataTemp3, dataTemp4, dataTemp5, dataTemp6, dataTemp7, dataTemp8] = yield all(allQueryArray);
        let [dataTempNew1, dataTempNew2, dataTempNew3, dataTempNew4, dataTempNew5, dataTempNew6, dataTempNew7, dataTempNew8] = yield all(allQueryArrayNew);

        // Set xAxisDate
        let dataTempForDate;
        if (gameTargetArray.length > 0) {
            dataTempForDate = JSON.parse(dataTemp1.body);
            if (dataTempNew1) {
                let newDataReturned = JSON.parse(dataTempNew1.body);
                dataTempForDate = [...dataTempForDate, ...newDataReturned];
            }
            // if (xAxisDataSetIndex === 0) {
            //     dataTempForDate = JSON.parse(dataTemp1.body);
            //     if (dataTempNew1) {
            //         let newDataReturned = JSON.parse(dataTempNew1.body);
            //         dataTempForDate = [...dataTempForDate, ...newDataReturned];
            //     }
            // } else if (xAxisDataSetIndex === 1) {
            //     dataTempForDate = JSON.parse(dataTemp2.body);
            //     if (dataTempNew2) {
            //         let newDataReturned2 = JSON.parse(dataTempNew2.body);
            //         dataTempForDate = [...dataTempForDate, ...newDataReturned2];
            //     }
            // } else if (xAxisDataSetIndex === 2) {
            //     dataTempForDate = JSON.parse(dataTemp3.body);
            //     if (dataTempNew3) {
            //         let newDataReturned3 = JSON.parse(dataTempNew3.body);
            //         dataTempForDate = [...dataTempForDate, ...newDataReturned3];
            //     }
            // } else if (xAxisDataSetIndex === 3) {
            //     dataTempForDate = JSON.parse(dataTemp4.body);
            //     if (dataTempNew4) {
            //         let newDataReturned4 = JSON.parse(dataTempNew4.body);
            //         dataTempForDate = [...dataTempForDate, ...newDataReturned4];
            //     }
            // } else if (xAxisDataSetIndex === 4) {
            //     dataTempForDate = JSON.parse(dataTemp5.body);
            //     if (dataTemp5) {
            //         let newDataReturned5 = JSON.parse(dataTemp5.body);
            //         dataTempForDate = [...dataTempForDate, ...newDataReturned5];
            //     }
            // } else {
            //     dataTempForDate = JSON.parse(dataTemp6.body);
            //     if (dataTemp6) {
            //         let newDataReturned6 = JSON.parse(dataTemp6.body);
            //         dataTempForDate = [...dataTempForDate, ...newDataReturned6];
            //     }
            // }
        }
        _.each(dataTempForDate, function(data) {
            if (sizeTarget.value === 'dau') {
                xAxisDate.push(data[0]['stringValue']);
            } else if (sizeTarget.value === 'wau') {
                xAxisDate.push(
                    moment(data[2]['longValue'].toString()).add(data[0]['longValue'], 'weeks').startOf('week').format('YYYY-MM-DD')+ ' ~ '
                     + moment(data[2]['longValue'].toString()).add(data[0]['longValue'], 'weeks').startOf('week').add(6, 'days').format('YYYY-MM-DD')
                )
            } else {
                xAxisDate.push(data[2]['stringValue']+'月');
            }
        });

        if (dataTemp1) {
            let dataReturned1 = JSON.parse(dataTemp1.body);
            if (dataTempNew1) {
                let newDataReturned = JSON.parse(dataTempNew1.body);
                dataReturned1 = [...dataReturned1, ...newDataReturned];
            }
            _.each(dataReturned1, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned1, d => { return d[1]['longValue'] });
            const data1 = {
                id: gameTargetArray[0],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned1.length).toFixed(0))
            };
            activeUsersData.push(data1);
        }
        if (dataTemp2) {
            dataArray = [];
            let dataReturned2 = JSON.parse(dataTemp2.body);
            if (dataTempNew2) {
                let newDataReturned = JSON.parse(dataTempNew2.body);
                dataReturned2 = [...dataReturned2, ...newDataReturned];
            }
            _.each(dataReturned2, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned2, d => { return d[1]['longValue'] });
            const data2 = {
                id: gameTargetArray[1],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned2.length).toFixed(0))
            };
            activeUsersData.push(data2);
        }
        if (dataTemp3) {
            dataArray = [];
            let dataReturned3 = JSON.parse(dataTemp3.body);
            if (dataTempNew3) {
                let newDataReturned = JSON.parse(dataTempNew3.body);
                dataReturned3 = [...dataReturned3, ...newDataReturned];
            }
            _.each(dataReturned3, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned3, d => { return d[1]['longValue'] });
            const data3 = {
                id: gameTargetArray[2],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned3.length).toFixed(0))
            };
            activeUsersData.push(data3);
        }
        if (dataTemp4) {
            dataArray = [];
            let dataReturned4 = JSON.parse(dataTemp4.body);
            if (dataTempNew4) {
                let newDataReturned = JSON.parse(dataTempNew4.body);
                dataReturned4 = [...dataReturned4, ...newDataReturned];
            }
            _.each(dataReturned4, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned4, d => { return d[1]['longValue'] });
            const data4 = {
                id: gameTargetArray[3],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned4.length).toFixed(0))
            };
            activeUsersData.push(data4);
        }
        if (dataTemp5) {
            dataArray = [];
            let dataReturned5 = JSON.parse(dataTemp5.body);
            if (dataTempNew5) {
                let newDataReturned = JSON.parse(dataTempNew5.body);
                dataReturned5 = [...dataReturned5, ...newDataReturned];
            }
            _.each(dataReturned5, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned5, d => { return d[1]['longValue'] });
            const data5 = {
                id: gameTargetArray[4],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned5.length).toFixed(0))
            };
            activeUsersData.push(data5);
        }
        if (dataTemp6) {
            dataArray = [];
            let dataReturned6 = JSON.parse(dataTemp6.body);
            if (dataTempNew6) {
                let newDataReturned = JSON.parse(dataTempNew6.body);
                dataReturned6 = [...dataReturned6, ...newDataReturned];
            }
            _.each(dataReturned6, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned6, d => { return d[1]['longValue'] });
            const data6 = {
                id: gameTargetArray[5],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned6.length).toFixed(0))
            };
            activeUsersData.push(data6);
        }
        if (dataTemp7) {
            dataArray = [];
            let dataReturned7 = JSON.parse(dataTemp7.body);
            if (dataTempNew7) {
                let newDataReturned = JSON.parse(dataTempNew7.body);
                dataReturned7 = [...dataReturned7, ...newDataReturned];
            }
            _.each(dataReturned7, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned7, d => { return d[1]['longValue'] });
            const data7 = {
                id: gameTargetArray[6],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned7.length).toFixed(0))
            };
            activeUsersData.push(data7);
        }

        // PM All unique users
        // console.log("LOG : new, old, mix", isDataNew, isDataOld, isDataMix);
        let dataReturnedAllPmUsers, dataReturnedAllNftDepUsers, dataReturnedAllJtcbUsers, dataReturnedAllLfUsers,
        dataReturnedAllCbUsers, dataReturnedAllDrUsers, dataReturnedAllGrUsers, dataReturnedAllLaUsers, dataReturnedAllSfUsers,
        dataReturnedAll3RUsers, dataReturnedAllBBUsers, dataReturnedAllPaidUsers;
        let allNonUniqueData, uniqueTotalData;

        if (dateDiffTarget < 32) {
            if (isDataOld || isDataNew) {
                const sqlQueryActiveAllPmUsers =  isDataOld ? H.SqlHelper.getAllPmActiveUsers(rangeTarget, countryTarget) : H.SqlHelper.getAllPmActiveUsersNew(rangeTarget, countryTarget);
                const sqlQueryActiveNftDep = H.SqlHelper.getAllEachActiveUsers(rangeTarget, isDataOld ? 'DaaMemberActionVisitProd' : 'DaaMemberActionVisitPmEvtProd', countryTarget);
                const sqlQueryActiveJtcb = H.SqlHelper.getAllEachActiveUsers(rangeTarget, isDataOld ? 'JtcbGamePlayStartByPmidProd' : 'JtcbGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveLf = H.SqlHelper.getAllEachActiveUsers(rangeTarget, isDataOld ? 'LuckyFarmerGamePlayStartProdV2' : 'LuckyFarmerGamePlayStartV2PmEvtProd', countryTarget);
                const sqlQueryActiveCb = H.SqlHelper.getAllEachActiveUsers(rangeTarget, isDataOld ? 'CookinBurgerGamePlayStartProd' : 'CookinBurgerGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveDr = H.SqlHelper.getAllEachActiveUsers(rangeTarget, isDataOld ? 'DragonRamenGamePlayStartProd' : 'DragonRamenGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveGr = H.SqlHelper.getAllEachActiveUsers(rangeTarget, isDataOld ? 'GraffitiGamePlayStartProd' : 'GraffitiGamePlayStartPmEvtDevProd', countryTarget);
                const sqlQueryActiveLa = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'LostArchivePlusGamePlayStartPmEvtProd', countryTarget);
                // const sqlQueryActiveSf = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'SoulFusersGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActive3R = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'RogueRollRulersGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveBB = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'BouncyBunnyGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveAllPaidUsers =  H.SqlHelper.getAllPaidUserCount(rangeTarget, countryTarget);
                const [tempAllUsers, tempAllUsers2, tempAllUsers3, tempAllUsers4, tempAllUsers5,
                       tempAllUsers6, tempAllUsers7,tempAllUsers8, tempAllUsers9, tempAllUsers10,
                       tempAllPaidUsers] = yield all([
                    call(getDataFromRDS, sqlQueryActiveAllPmUsers, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveNftDep, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveLf, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveCb, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveDr, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveGr, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveLa, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActive3R, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveBB, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveAllPaidUsers, ENDPOINT.GET_DATA_RDS, jwtToken),
                ]);

                dataReturnedAllPmUsers = JSON.parse(tempAllUsers.body);
                dataReturnedAllNftDepUsers = JSON.parse(tempAllUsers2.body);
                dataReturnedAllJtcbUsers = JSON.parse(tempAllUsers3.body);
                dataReturnedAllLfUsers = JSON.parse(tempAllUsers4.body);
                dataReturnedAllCbUsers = JSON.parse(tempAllUsers5.body);
                dataReturnedAllDrUsers = JSON.parse(tempAllUsers6.body);
                dataReturnedAllGrUsers = JSON.parse(tempAllUsers7.body);
                dataReturnedAllLaUsers = JSON.parse(tempAllUsers8.body);
                dataReturnedAll3RUsers = JSON.parse(tempAllUsers9.body);
                dataReturnedAllBBUsers = JSON.parse(tempAllUsers10.body);
                dataReturnedAllPaidUsers = JSON.parse(tempAllPaidUsers.body);

                allNonUniqueData = dataReturnedAllNftDepUsers[0][0]['longValue'] + dataReturnedAllJtcbUsers[0][0]['longValue']
                + dataReturnedAllLfUsers[0][0]['longValue'] + dataReturnedAllCbUsers[0][0]['longValue']
                + dataReturnedAllDrUsers[0][0]['longValue'] + dataReturnedAllGrUsers[0][0]['longValue']
                + dataReturnedAllLaUsers[0][0]['longValue'] + dataReturnedAll3RUsers[0][0]['longValue']
                + dataReturnedAllBBUsers[0][0]['longValue'];

                uniqueTotalData = [
                    {
                        id: 'playMining',
                        uniqueTotal: dataReturnedAllPmUsers[0][0]['longValue'],
                        total: allNonUniqueData,
                    },
                    {
                        id: 'nftdep',
                        uniqueTotal: dataReturnedAllNftDepUsers[0][0]['longValue'],
                    },
                    {
                        id: 'jobtribes',
                        uniqueTotal: dataReturnedAllJtcbUsers[0][0]['longValue'],
                    },
                    {
                        id: 'luckyfarmer',
                        uniqueTotal: dataReturnedAllLfUsers[0][0]['longValue'],
                    },
                    {
                        id: 'cookinburger',
                        uniqueTotal: dataReturnedAllCbUsers[0][0]['longValue'],
                    },
                    {
                        id: 'dragonramen',
                        uniqueTotal: dataReturnedAllDrUsers[0][0]['longValue'],
                    },
                    {
                        id: 'graffiti',
                        uniqueTotal: dataReturnedAllGrUsers[0][0]['longValue'],
                    },
                    {
                        id: 'lostarchiveplus',
                        uniqueTotal: dataReturnedAllLaUsers[0][0]['longValue'],
                    },
                    {
                        id: 'roguerollrulers',
                        uniqueTotal: dataReturnedAll3RUsers[0][0]['longValue'],
                    },
                    {
                        id: 'bouncybunny',
                        uniqueTotal: dataReturnedAllBBUsers[0][0]['longValue'],
                    },
                ];
            } else if (isDataMix) {
                const sqlQueryActiveAllPmUsers =  H.SqlHelper.getAllPmActiveUsers(rangeTarget, countryTarget);
                const sqlQueryActiveNftDep = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'DaaMemberActionVisitProd', 'DaaMemberActionVisitPmEvtProd', countryTarget);
                const sqlQueryActiveJtcb = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'JtcbGamePlayStartByPmidProd', 'JtcbGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveLf = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'LuckyFarmerGamePlayStartProdV2', 'LuckyFarmerGamePlayStartV2PmEvtProd', countryTarget);
                const sqlQueryActiveCb = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'CookinBurgerGamePlayStartProd', 'CookinBurgerGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveDr = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'DragonRamenGamePlayStartProd', 'DragonRamenGamePlayStartPmEvtProd', countryTarget);
                const sqlQueryActiveGr = H.SqlHelper.getAllEachActiveUsers(rangeTarget, 'GraffitiGamePlayStartProd', 'GraffitiGamePlayStartPmEvtDevProd', countryTarget);
                const [tempAllUsers, tempAllUsers2, tempAllUsers3, tempAllUsers4, tempAllUsers5, tempAllUsers6, tempAllUsers7] = yield all([
                    call(getDataFromRDS, sqlQueryActiveAllPmUsers, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveNftDep, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveLf, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveCb, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveDr, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryActiveGr, ENDPOINT.GET_DATA_RDS, jwtToken),
                ]);
                dataReturnedAllPmUsers = JSON.parse(tempAllUsers.body);
                dataReturnedAllNftDepUsers = JSON.parse(tempAllUsers2.body);
                dataReturnedAllJtcbUsers = JSON.parse(tempAllUsers3.body);
                dataReturnedAllLfUsers = JSON.parse(tempAllUsers4.body);
                dataReturnedAllCbUsers = JSON.parse(tempAllUsers5.body);
                dataReturnedAllDrUsers = JSON.parse(tempAllUsers6.body);
                dataReturnedAllGrUsers = JSON.parse(tempAllUsers7.body);

                allNonUniqueData = dataReturnedAllNftDepUsers[0][0]['longValue']
                + dataReturnedAllJtcbUsers[0][0]['longValue']
                + dataReturnedAllLfUsers[0][0]['longValue']
                + dataReturnedAllCbUsers[0][0]['longValue']
                + dataReturnedAllDrUsers[0][0]['longValue']
                + dataReturnedAllGrUsers[0][0]['longValue'];

                uniqueTotalData = [
                    {
                        id: 'playMining',
                        uniqueTotal: dataReturnedAllPmUsers[0][0]['longValue'],
                        total: allNonUniqueData,
                    },
                    {
                        id: 'nftdep',
                        uniqueTotal: dataReturnedAllNftDepUsers[0][0]['longValue'],
                    },
                    {
                        id: 'jobtribes',
                        uniqueTotal: dataReturnedAllJtcbUsers[0][0]['longValue'],
                    },
                    {
                        id: 'luckyfarmer',
                        uniqueTotal: dataReturnedAllLfUsers[0][0]['longValue'],
                    },
                    {
                        id: 'cookinburger',
                        uniqueTotal: dataReturnedAllCbUsers[0][0]['longValue'],
                    },
                    {
                        id: 'dragonramen',
                        uniqueTotal: dataReturnedAllDrUsers[0][0]['longValue'],
                    },
                    {
                        id: 'graffiti',
                        uniqueTotal: dataReturnedAllGrUsers[0][0]['longValue'],
                    },
                ];
            }
            yield put(setActiveUsersUniqueTotalData(uniqueTotalData));

            // unique paid users
            if (dataReturnedAllPaidUsers[0][0]['longValue']!== undefined) {
                yield put(setActiveUsersUniqueTotalPaidData(dataReturnedAllPaidUsers[0][0]['longValue']));
            }
        }
        yield put(setActiveUsersPageData(activeUsersAllPmData.length > 0 ? activeUsersAllPmData.concat(activeUsersData) : activeUsersData));
        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getActiveUsersCountries() {
    try {
        yield put(setActiveUsersCountryLoading(true));
        const jwtToken = yield call(getJwtToken);
        const usersTarget = yield select(activeUsersGameOption);
        const rangeTarget = yield select(activeUsersDateRange);
        const isAllPmSelected = yield select(allPmMenuSelect);

        let allCountryQueryArray = [], gameTargetCountryArray = [];
        if (!isAllPmSelected) {
            _.forEach(usersTarget, function(data, i) {
                let sqlQueryActiveUsersCountry = H.SqlHelper.getActiveUsersCountry(data.value, rangeTarget);
                allCountryQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsersCountry, ENDPOINT.GET_DATA_RDS, jwtToken));
                gameTargetCountryArray.push(data.value);
            });
            const [dataTemp1, dataTemp2, dataTemp3, dataTemp4] = yield all(allCountryQueryArray);
            yield put(setActiveUsersCountryLoading(false));
            if (dataTemp1) {
                const dataReturned1 = JSON.parse(dataTemp1.body);
                let newOptionArray1 = [{name: "全て", value:"all"}];
                _.each(dataReturned1, function(data) {
                    let countryObj = {
                        "name": data[0]['stringValue'],
                        "value": data[0]['stringValue'],
                        "num": data[1]['longValue'],
                    }
                    newOptionArray1.push(countryObj);
                    countryObj = {};
                });
                yield put(setActiveUsersCountryOption(newOptionArray1));
            }
            if (dataTemp2) {
                const dataReturned2 = JSON.parse(dataTemp2.body);
                let newOptionArray2 = yield select(activeUsersCountryOption);
                _.each(dataReturned2, function(data) {
                    let countryObj2 = {
                        "name": data[0]['stringValue'],
                        "value": data[0]['stringValue'],
                        "num": data[1]['longValue'],
                    }
                    newOptionArray2.push(countryObj2);
                    countryObj2 = {};
                });
                let result = _(newOptionArray2)
                    .groupBy(({ name, value }) => name + value)
                    .map((g) => _.mergeWith(...g, (o, s, k) => k === 'num' ? o + s : o))
                    .value();
                const option2ArraySort = _.orderBy(result, ['num'],['desc']);
                yield put(setActiveUsersCountryOption(option2ArraySort));
            }
            if (dataTemp3) {
                const dataReturned3 = JSON.parse(dataTemp3.body);
                let newOptionArray3 = yield select(activeUsersCountryOption);
                _.each(dataReturned3, function(data) {
                    let countryObj3 = {
                        "name": data[0]['stringValue'],
                        "value": data[0]['stringValue'],
                        "num": data[1]['longValue'],
                    }
                    newOptionArray3.push(countryObj3);
                    countryObj3 = {};
                });
                let result = _(newOptionArray3)
                    .groupBy(({ name, value }) => name + value)
                    .map((g) => _.mergeWith(...g, (o, s, k) => k === 'num' ? o + s : o))
                    .value();
                const option3ArraySort = _.orderBy(result, ['num'],['desc']);
                yield put(setActiveUsersCountryOption(option3ArraySort));
            }
            if (dataTemp4) {
                const dataReturned4 = JSON.parse(dataTemp4.body);
                let newOptionArray4 = yield select(activeUsersCountryOption);
                _.each(dataReturned4, function(data) {
                    let countryObj4 = {
                        "name": data[0]['stringValue'],
                        "value": data[0]['stringValue'],
                        "num": data[1]['longValue'],
                    }
                    newOptionArray4.push(countryObj4);
                    countryObj4 = {};
                });
                let result = _(newOptionArray4)
                    .groupBy(({ name, value }) => name + value)
                    .map((g) => _.mergeWith(...g, (o, s, k) => k === 'num' ? o + s : o))
                    .value();
                const option4ArraySort = _.orderBy(result, ['num'],['desc']);
                yield put(setActiveUsersCountryOption(option4ArraySort));
            }
        } else {
            let sqlQueryAllPmActiveUsersCountry = H.SqlHelper.getAllPmActiveUsersCountry(rangeTarget);
            const [allPmCountriesTemp] = yield all([
                call(getDataFromRDS, sqlQueryAllPmActiveUsersCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            const allPmCountriesDataReturned = JSON.parse(allPmCountriesTemp.body);
            let newOptionArray5 = yield select(activeUsersCountryOption);
            _.each(allPmCountriesDataReturned, function(data) {
                let countryObjAllPm = {
                    "name": data[0]['stringValue'],
                    "value": data[0]['stringValue'],
                    "num": Number(data[1]['stringValue']),
                }
                newOptionArray5.push(countryObjAllPm);
                countryObjAllPm = {};
            });
            yield put(setActiveUsersCountryOption(newOptionArray5));
            yield put(setActiveUsersCountryLoading(false));
        }
    } catch (err) {
        console.log(err);
    }
}

export function* getActiveUsersRid() {
    try {
        yield put(setActiveUsersRidOption([{name: "全て", value:"all"}]));
        const jwtToken = yield call(getJwtToken);
        const gameTarget = yield select(activeUsersGameOption);
        const rangeTarget = yield select(activeUsersDateRange);
        const isAllPmSelected = yield select(allPmMenuSelect);
        if (gameTarget.length === 1 && !isAllPmSelected) {
            // fetch rid option
            yield put(setActiveUsersRidLoading(true));
            const sqlQueryGetAllRid = H.SqlHelper.getAllRid(gameTarget[0]['value']);
            const sqlQueryActiveUsersRid = H.SqlHelper.getActiveUsersRid(gameTarget[0]['value'], rangeTarget);
            const [allRidTemp, activeUsersRidTemp] = yield all([
                call(getDataFromRDS, sqlQueryGetAllRid, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, sqlQueryActiveUsersRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            const allRidDataReturned = JSON.parse(allRidTemp.body);
            yield put(setActiveUsersRidLoading(false));
            let allRidArray = [], eachRidObj = {};
            _.forEach(allRidDataReturned, function(ridData) {
                eachRidObj.id = ridData[0]['stringValue'].slice(0, 7);
                eachRidObj.name = ridData[1]['stringValue'];
                allRidArray.push(eachRidObj);
                eachRidObj = {};
            });
            const ridDataReturned = JSON.parse(activeUsersRidTemp.body);
            let newRidOption = [{name: "全て", value:"all"}];
            _.forEach(ridDataReturned, function(data) {
                let cnameObj = _.find(allRidArray, { id : gameTarget[0]['value'] === 'jobtribes' ? data[0]["stringValue"] : data[1]["stringValue"].slice(0, 7) });
                let ridValue = gameTarget[0]['value'] === 'jobtribes' ? data[0]["stringValue"] : data[1]["stringValue"];
                if (cnameObj !== undefined) {
                    newRidOption.push(
                        {name: H.FormatNumStrHelper.unEscapeUnicode(cnameObj.name) + '  [ ' + ridValue + ' ]', value: ridValue}
                    )
                }
            });
            yield put(setActiveUsersRidOption(newRidOption));
        }
    } catch (err) {
        console.log(err);
    }
}

export function* getActiveUsersDataByGame(action) {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        let gameTarget = action.payload.split("/")[2];
        if (gameTarget === "active-users") {
            gameTarget = "playmining-nft";
        }
        const sizeTarget = yield select(activeUsersSizeSelected);
        const rangeTarget = yield select(activeUsersDateRange);
        const countryTarget = yield select(activeUsersCountrySelected);
        const ridTarget = yield select(activeUsersRidSelected);
        let xAxisDate = [], dataArray = [], activeUsersData = [];
        let allQueryArray = [], gameTargetArray = [], zeroValueArray = [], numOfLoop = 0, xAxisDataSetIndex = 0;
        let allQueryArrayNew = [];

        // DATA2.0
        const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;
        const isDataOld = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isBefore('2023-07-01') ? true : false;
        const isDataMix = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;

        if (isDataOld) {
            if (gameTarget !== 'PlayMining') {
                let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersDataByGame(gameTarget, sizeTarget, rangeTarget, countryTarget, ridTarget, false);
                allQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                gameTargetArray.push(gameTarget);
            }
        } else if (isDataNew) {
            if (gameTarget !== 'PlayMining') {
                let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersDataByGame(gameTarget, sizeTarget, rangeTarget, countryTarget, ridTarget, true);
                allQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                gameTargetArray.push(gameTarget);
            }
        } else {
            const newRangeOld = [rangeTarget[0], '2023-06-30'];
            const newRangeNew = ['2023-07-01', rangeTarget[1]];
            // Old loop
            if (gameTarget !== 'PlayMining') {
                let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersDataByGame(gameTarget, sizeTarget, newRangeOld, countryTarget, ridTarget, false);
                allQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
                gameTargetArray.push(gameTarget);
            }
            // New loop
            if (gameTarget !== 'PlayMining') {
                let sqlQueryActiveUsers = H.SqlHelper.getActiveUsersDataByGame(gameTarget, sizeTarget, newRangeNew, countryTarget, ridTarget, true);
                allQueryArrayNew.push(call(getDataFromRDS, sqlQueryActiveUsers, ENDPOINT.GET_DATA_RDS, jwtToken));
            }
        }

        // Decide which index to set the date array
        if (gameTargetArray.length > 1) {
            _.forEach(gameTargetArray, function(game, index) {
                if (game !== "nftdep" && game !== "luckyfarmer" && game !== "cookinburger") {
                    xAxisDataSetIndex = index;
                }
            })
        };

        // Fetch data
        let [dataTemp1] = yield all(allQueryArray);
        let [dataTempNew1] = yield all(allQueryArrayNew);

        // Set xAxisDate
        let dataTempForDate;
        if (gameTargetArray.length > 0) {
            dataTempForDate = JSON.parse(dataTemp1.body);
            if (dataTempNew1) {
                let newDataReturned = JSON.parse(dataTempNew1.body);
                dataTempForDate = [...dataTempForDate, ...newDataReturned];
            }
        }
        _.each(dataTempForDate, function(data) {
            if (sizeTarget.value === 'dau') {
                xAxisDate.push(data[0]['stringValue']);
            } else if (sizeTarget.value === 'wau') {
                xAxisDate.push(
                    moment(data[2]['longValue'].toString()).add(data[0]['longValue'], 'weeks').startOf('week').format('YYYY-MM-DD')+ ' ~ '
                     + moment(data[2]['longValue'].toString()).add(data[0]['longValue'], 'weeks').startOf('week').add(6, 'days').format('YYYY-MM-DD')
                )
            } else {
                xAxisDate.push(data[2]['stringValue']+'月');
            }
        });

        if (dataTemp1) {
            let dataReturned1 = JSON.parse(dataTemp1.body);
            if (dataTempNew1) {
                let newDataReturned = JSON.parse(dataTempNew1.body);
                dataReturned1 = [...dataReturned1, ...newDataReturned];
            }
            _.each(dataReturned1, function(data) {
                dataArray.push(data[1]['longValue']);
            });
            if (dataArray.length < xAxisDate.length) {
                zeroValueArray = [];
                numOfLoop = xAxisDate.length - dataArray.length;
                for (let i=0; i<numOfLoop; i++) {
                    zeroValueArray.push(0);
                }
                dataArray = zeroValueArray.concat(dataArray);
            }
            let totalNum = _.sumBy(dataReturned1, d => { return d[1]['longValue'] });
            const data1 = {
                id: gameTargetArray[0],
                data: dataArray,
                date: xAxisDate,
                avg: Number((totalNum / dataReturned1.length).toFixed(0))
            };
            activeUsersData.push(data1);
        }

        yield put(setActiveUsersPageDataByGame(activeUsersData));
        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getActiveUsersCountriesByGame(action) {
    try {
        yield put(setActiveUsersCountryLoading(true));
        const jwtToken = yield call(getJwtToken);
        let gameTarget = action.payload.split("/")[2];
        if (gameTarget === "active-users") {
            gameTarget = "playmining-nft";
        }
        const rangeTarget = yield select(activeUsersDateRange);

        // DATA2.0
        const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;

        let allCountryQueryArray = [];
        let sqlQueryActiveUsersCountry = H.SqlHelper.getActiveUsersCountry(gameTarget, rangeTarget, isDataNew);
        allCountryQueryArray.push(call(getDataFromRDS, sqlQueryActiveUsersCountry, ENDPOINT.GET_DATA_RDS, jwtToken));

        const [dataTemp1] = yield all(allCountryQueryArray);
        yield put(setActiveUsersCountryLoading(false));
        if (dataTemp1) {
            const dataReturned1 = JSON.parse(dataTemp1.body);
            let newOptionArray1 = [{name: "全て", value:"all"}];
            _.each(dataReturned1, function(data) {
                let countryObj = {
                    "name": data[0]['stringValue'] === 'n/a' ? '不明' : data[0]['stringValue'],
                    "value": data[0]['stringValue'],
                    "num": data[1]['longValue'],
                }
                newOptionArray1.push(countryObj);
                countryObj = {};
            });
            yield put(setActiveUsersCountryOption(newOptionArray1));
        }
    } catch (err) {
        console.log(err);
    }
}

export function* getActiveUsersRidByGame(action) {
    try {
        yield put(setActiveUsersRidOption([{name: "全て", value:"all"}]));
        const jwtToken = yield call(getJwtToken);
        let gameTarget = action.payload.split("/")[2];
        if (gameTarget === "active-users") {
            gameTarget = "playmining-nft";
        }
        const rangeTarget = yield select(activeUsersDateRange);

        // DATA2.0
        const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;

        // fetch rid option
        yield put(setActiveUsersRidLoading(true));
        const sqlQueryActiveUsersRid = H.SqlHelper.getActiveUsersRidByGame(gameTarget, rangeTarget, isDataNew);
        const [activeUsersRidTemp] = yield all([
            call(getDataFromRDS, sqlQueryActiveUsersRid, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        yield put(setActiveUsersRidLoading(false));
        const ridDataReturned = JSON.parse(activeUsersRidTemp.body);
        let newRidOption = [{name: "全て", value:"all"}];
        _.forEach(ridDataReturned, function(data) {
            // let cnameObj = _.find(allRidArray, { id : gameTarget[0]['value'] === 'jobtribes' ? data[0]["stringValue"] : data[1]["stringValue"].slice(0, 7) });
            // let ridValue = gameTarget[0]['value'] === 'jobtribes' ? data[0]["stringValue"] : data[1]["stringValue"];
            newRidOption.push(
                {
                    name: H.FormatNumStrHelper.unEscapeUnicode(data[2]['stringValue']) + '  [ ' + data[1]['stringValue'] + ' ]', value: data[1]['stringValue']
                }
            )
        });
        yield put(setActiveUsersRidOption(newRidOption));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(DATA.GET_ACTIVE_USERS_PAGE_DATA, getActiveUsersData);
    yield takeEvery(DATA.GET_ACTIVE_USERS_COUNTRIES, getActiveUsersCountries);
    yield takeEvery(DATA.SET_ACTIVE_USERS_GAME_OPTION, getActiveUsersRid);
    yield takeEvery(DATA.GET_ACTIVE_USERS_PAGE_DATA_BY_GAME, getActiveUsersDataByGame);
    yield takeEvery(DATA.GET_ACTIVE_USERS_COUNTRIES_BY_GAME, getActiveUsersCountriesByGame);
    yield takeEvery(DATA.GET_ACTIVE_USERS_RID_BY_GAME, getActiveUsersRidByGame);
}
