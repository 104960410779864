const metricLabel = {
  "imp": "インプレッション",
  "watch":  "試聴数",
  "watchRatio":  "試聴率",
  "watched":  "試聴完了数",
  "watchedRatio":  "試聴完了率",
  "quizAnswer":  "クイズ回答数",
  "quizCorrect": "クイズ正解数",
  "correctRatio": "正答率",
  "bannerClick": "バナークリック数",
  "bannerRatio": "バナークリック率",
  "hintClick": "ヒントクリック数",
  "hintRatio": "ヒントクリック率",
};

export default {
  metricLabel,
}