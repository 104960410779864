export default {
    items: [
        {
            value: '1',
            label: 'M',
            tip: 'Monday',
            checked: true
        },
        {
            value: '2',
            label: 'T',
            tip: 'Tuesday',
            checked: true
        },
        {
            value: '3',
            label: 'W',
            tip: 'Wednesday',
            checked: true
        },
        {
            value: '4',
            label: 'T',
            tip: 'Thursday',
            checked: true
        },
        {
            value: '5',
            label: 'F',
            tip: 'Friday',
            checked: true
        },
        {
            value: '6',
            label: 'S',
            tip: 'Saturday',
            checked: false
        },
        {
            value: '7',
            label: 'S',
            tip: 'Sunday',
            checked: false
        },
    ]
};