import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import * as H from "helper";
import * as C from "components";
import { TimePickerToolbar } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    padding: theme.spacing(1) * 2,
    "&:last-child": {
      paddingBottom: theme.spacing(1) * 2,
    },
  },
  icon: {
    boxShadow: "none",
    color: "white",
  },
  iconFloat: {
    position: "absolute",
    right: "15px",
    top: "50%",
    marginTop: "-20px",
    opacity: "0.2",
    transform: "rotate(-5deg)",
  },
  box: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRight: "1px solid #dcdcdc",
  },
  boxLast: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  title: {
    display: "block",
    height: 32,
    fontSize: 14,
  },
  value: {
    paddingTop: 24,
    paddingBottom: 8,
  },
  bar: {
    height: 6,
    width: "80%",
  },
  numSection: {
    display: "block",
  },
  circleProgress: {
    marginTop: 40,
    marginBottom: 8,
  },
  txtCountry: {
    marginTop: -16,
    fontSize: 14,
  },
  txtPrev: {
    marginTop: -8,
    fontSize: 13,
  },
  gainLossImg: {
    paddingTop: 8,
    marginRight: 4,
  },
  diffLbl: {
    color: "#7f898b",
  },
  lineChartLast: {
    marginTop: 16,
    width: "95%",
  },
  iconTooltip: {
    paddingTop: 0,
    paddingRight: 16,
    fontSize: 16,
  },
  currency: {
    fontSize: 14,
  },
}));

const StatBox = ({
  title,
  value,
  color,
  country,
  prevValue,
  lineChartData,
  tip,
  xAxisData,
  extra,
  prefix,
  lastItem,
  suffix,
}) => {
  const classes = useStyles();

  const getProgressCircle = (size) => {
    return (
      <div className={classes.circleProgress}>
        <CircularProgress size={size} />
      </div>
    );
  };

  const getCountryId = (countryName) => {
    return H.CountryHelper.getCountryCode(countryName);
  };

  const getPrevComponent = (value, prevValue) => {
    const imageName = value > prevValue ? "gain" : "loss";
    const diffValue = value - prevValue;
    const diffRatio = (diffValue / prevValue) * 100;
    // const diffLabel = value > prevValue ? '増' : '減';

    if (!prevValue) {
      return <div className={classes.txtPrev}>---</div>;
    }

    return (
      <div className={classes.txtPrev}>
        <img
          className={classes.gainLossImg}
          src={`/static/images/${imageName}.png`}
        />
        {H.FormatNumStrHelper.formatCommaThousand(diffValue)} (
        {Math.abs(diffRatio.toFixed(2))}%)
      </div>
    );
  };

  const getLineChartComponent = (data) => {
    return (
      <div className={classes.lineChartLast}>
        <C.LineChartThumb chartData={data} xAxisData={xAxisData} />
      </div>
    );
  };

  const getStatValue = (data, prefix, suffix) => {
    if (data === "-") {
      return "-";
    } else {
      if (prefix === undefined) {
        prefix = "";
      }
      return (
        <Typography variant="h6" className={classes.value}>
          <span className={classes.currency}>{prefix}</span>{" "}
          {H.FormatNumStrHelper.formatCommaThousand(data)} {suffix ? suffix : null}
        </Typography>
      );
    }
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"row"}
      justifyContent={"flex-start"}
    >
      <Grid item xs={12}>
        <div className={lastItem ? classes.boxLast : classes.box}>
          <Grid container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={11}>
              <Typography
                variant="caption"
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: title }}
              ></Typography>
            </Grid>
            <Grid item xs={1}>
              {country !== undefined ? (
                country === "globe" ? (
                  <img src={`/static/images/${country}.png`} />
                ) : (
                  <img src={`${getCountryId(country)}`} alt={`${country}`} />
                )
              ) : (
                ""
              )}
              {tip && (
                <Grid container justifyContent="flex-end">
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          <div dangerouslySetInnerHTML={{ __html: tip }}></div>
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon
                      className={classes.iconTooltip}
                      fontSize="small"
                    />
                  </HtmlTooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
          <div className={classes.numSection}>
            {value === "loading" && (
              <div className={classes.circleProgress}>
                <CircularProgress size={20} />
              </div>
            )}
            {value !== "loading" && typeof value !== "object" && (
              <Typography variant="caption" className={classes.value}>
                {value === "loading"
                  ? getProgressCircle(20)
                  : isNaN(value) ? "--" : getStatValue(value, prefix, suffix)}
              </Typography>
            )}
            {typeof value === "object" && (
              <Typography variant="caption" className={classes.value}>
                {H.FormatNumStrHelper.formatCommaThousand(value.count)}
              </Typography>
            )}
          </div>
          {prevValue !== undefined
            ? prevValue === "loading"
              ? getProgressCircle(12)
              : isNaN(prevValue)
                ? "--"
                : getPrevComponent(value, prevValue)
            : ""}
          {lineChartData !== undefined
            ? getLineChartComponent(lineChartData)
            : null}
          {color !== undefined ? (
            <div
              className={classes.bar}
              style={{ backgroundColor: color }}
            ></div>
          ) : (
            ""
          )}
          {country !== undefined ? (
            country !== "globe" ? (
              <div className={classes.txtCountry}>{country}</div>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {extra !== undefined ? (extra === "loading" ? null : extra) : null}
        </div>
      </Grid>
    </Grid>
  );
};

StatBox.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  color: PropTypes.string,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(StatBox));
