import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Checkbox,
  TextField,
  Button,
  Grid,
  Chip,
  Paper,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Autocomplete, Pagination } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  getPicTreeUserListData,
  getPicTreeUserData,
  setUserPageListDataFilter,
  setFilterDrawerState,
  getUserListDataByTerm,
  getUserCsvData,
  setNftTxCsvData,
  setPicTreeUserPageNumber,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        //borderBottom: 'unset',
      },
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    pageSection: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    table: {
      minHeight: "500px",
      overflowY: "scroll",
    },
    tableSlim: {
      minHeight: "20px",
    },
    filterBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
    },
    chipColorvolt: {
      marginTop: theme.spacing(1),
      backgroundColor: "#fd5842",
      color: "#fff",
    },
    chipColorampere: {
      marginTop: theme.spacing(1),
      backgroundColor: "#4ba0c1",
      color: "#fff",
    },
    chipColorwatt: {
      marginTop: theme.spacing(1),
      backgroundColor: "#6eb26e",
      color: "#fff",
    },
    titleDl: {
      marginRight: 16,
      color: "#000",
    },
  })
);

function setChipLabel(label, classes) {
  const colorKeyName =
    "chipColor" + label.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
  return (
    <Chip className={classes[colorKeyName]} size="small" label={label} />
  );
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="left" component="th" scope="row">
          {row.player_id}
        </TableCell>
        <TableCell align="center">{row.username}</TableCell>
        <TableCell align="center">{row.pmid}</TableCell>
        <TableCell align="center">{row.authentication_id}</TableCell>
        <TableCell align="center">{setChipLabel(row.team, classes)}</TableCell>
        <TableCell align="center">{row.status}</TableCell>
        <TableCell align="center">{row.reg_datetime}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const PicTreeUserList = ({
  setCurrentPage,
  currentPage,
  assetOption,
  getPicTreeUserListData,
  getPicTreeUserData,
  userStatData,
  setUserPageListDataFilter,
  pageTopLoader,
  setFilterDrawerState,
  filterDrawerState,
  assetSearchTerm,
  pageNumber,
  getUserListDataByTerm,
  getUserCsvData,
  setNftTxCsvData,
  userCsvData,
  userCsvLoader,
  setPicTreeUserPageNumber,
  userPageListData,
  userPageListDataFilter,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [assetOptions, setAssetOptions] = useState(assetOption);
  const [userId, setUserId] = useState({ name: "" });
  const [userRow, setUserRow] = useState([]);
  const [noData, setNodata] = useState(false);
  const [userFilter, setUserFilter] = useState([]);
  const [defaultDateRange, setDefaultDateRagne] = useState([
    moment().tz("Asia/Tokyo").subtract(6, "days").format("YYYY/MM/DD"),
    moment().tz("Asia/Tokyo").format("YYYY/MM/DD"),
  ]);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const csvLinkRef = useRef(null);

  const handleChangeOption = (data) => {
    setBtnState(false);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getPicTreeUserListData(pageNumber);
    // setNftTxCsvData([]);
  }, []);

  useEffect(() => {
    if (userCsvData.length > 0) {
      csvLinkRef.current.link.click();
      setNftTxCsvData([]);
    }
  }, [userCsvData]);

  useEffect(() => {
    setAssetOptions(assetOption);
  }, [assetOption]);

  useEffect(() => {
    if (userFilter.length > 0 && userFilter[0]["value"] !== "") {
      setBtnState(false);
    } else {
      setBtnState(true);
    }
  }, [userFilter]);

  useEffect(() => {
    if (userPageListData["userListData"] !== undefined &&
      userPageListData["userListData"].length > 0) {
      const ttlPageCount = userPageListData["total"] / 50;
      setTotalPageNum(Math.ceil(ttlPageCount));

      let dataRows = [];
      _.each(userPageListData["userListData"], function (dataRow) {
        let rowObj = {
          player_id: dataRow["player_id"],
          username: dataRow["username"],
          pmid: dataRow["pmid"],
          authentication_id: dataRow["authentication_id"],
          team: dataRow["team"],
          status: dataRow["status"],
          reg_datetime: dataRow["reg_datetime"],
        };
        dataRows.push(rowObj);
        rowObj = {};
      });
      setUserRow(dataRows);
      setNodata(false);
    } else {
      setNodata(true);
    }
  }, [userPageListData]);

  const handlePageChange = (event, value) => {
    setPicTreeUserPageNumber(value);
    if (assetSearchTerm.length > 0 && assetSearchTerm[0]["value"] !== "") {
      getUserListDataByTerm();
    } else {
      getPicTreeUserListData(value);
    }
  };

  const fetchData = () => {
    if (userFilter.length > 0 && userFilter[0]["value"] !== "") {
      getPicTreeUserData(userFilter);
    } else {
      getPicTreeUserListData(pageNumber);
    }
  };

  const handleInputChange = (data, type) => {
    switch (type) {
      case "user_id":
        setUserId({ name: data });
        break;
      default:
    }
    const termObject = {
      type: type,
      value: data.replace(/\s\s+/g, " "),
    };
    let cloneTerm = [...assetSearchTerm];

    const objIndex = cloneTerm.findIndex(
      (termObject) => termObject.type == type
    );
    if (objIndex === -1) {
      cloneTerm.push(termObject);
    } else {
      cloneTerm[objIndex].value = data;
    }
    setUserFilter(cloneTerm);
  };

  return (
    <C.ContentWrapper>
      <Grid
        container
        spacing={0}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          <Grid
            container
            spacing={1}
            className={classes.gridSection}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <div>総ユーザー数 : {userPageListData["total"] === undefined ? "--" : userPageListData["total"]}</div>
              <Grid container>
                <Grid item>
                  <Autocomplete
                    freeSolo
                    disablePortal
                    limitTags={3}
                    size="small"
                    id="select-game"
                    value={userId}
                    options={assetOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                        </React.Fragment>
                    )}
                    onChange={(e, newValue) => {
                        handleChangeOption(newValue);
                    }}
                    onInputChange={(e, newValue) => {
                        handleInputChange(newValue, 'user_id');
                    }}
                    style={{ minWidth: 400 }}
                    renderInput={(params) => (
                        <TextField {...params} label="ユーザーID検索" />
                    )}
                    className={classes.autoSelect}
                  />
                </Grid>
                <Grid item>
                  <Button
                    disabled={btnStateDataFetch}
                    className={classes.dataBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => fetchData()}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  <Button
                    disabled={!btnStateDataFetch}
                    className={classes.dataBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => fetchData()}
                  >
                    GO
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled
                    className={classes.filterBtn}
                    variant="contained"
                    color="default"
                    onClick={() => setFilterDrawerState(!filterDrawerState ? currentPage : false )}>
                    絞り込み
                  </Button>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              stickyHeader
              className={
                userRow.length < 11 && userRow.length !== 0
                ? classes.tableSlim
                : classes.table
              }
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">ユーザーID</TableCell>
                  <TableCell align="center">ユーザー名</TableCell>
                  <TableCell align="center">PMID</TableCell>
                  <TableCell align="center">発火ユーザーID</TableCell>
                  <TableCell align="center">チーム</TableCell>
                  <TableCell align="center">ステータス</TableCell>
                  <TableCell align="center">登録日時 (JST)</TableCell>
                  {/* <TableCell /> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {!noData &&
                  userRow.map((row, index) => (
                    <Row key={index} row={row} />
                  ))}
                {!pageTopLoader && noData && (
                  <TableRow style={{height: 16}}>
                    <TableCell align="center" colSpan={7}>
                      データがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {totalPageNum > 0 && userRow.length > 49 && (
        <Grid
          container
          spacing={1}
          className={classes.pageSection}
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            count={totalPageNum}
            page={pageNumber}
            onChange={handlePageChange}
          />
        </Grid>
      )}
    </C.ContentWrapper>
  );
};

PicTreeUserList.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  assetSearchLoading: state.nftDepTxHisotry.assetSearchLoading,
  assetOption: state.nftDepTxHisotry.assetOption,
  pageTopLoader: state.page.pageTopLoader,
  filterDrawerState: state.page.filterDrawerState,
  currentPage: state.page.currentPage,
  assetSearchTerm: state.nftDepTxHisotry.assetSearchTerm,
  pageNumber: state.pictree.userPageNumber,
  userStatData: state.users.userStatData,
  userPageListData: state.pictree.usersList,
  userPageListDataFilter: state.users.userPageListDataFilter,
  userCsvData: state.users.userCsvData,
  userCsvLoader: state.users.userCsvLoader,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getPicTreeUserListData: (param) => dispatch(getPicTreeUserListData(param)),
  getPicTreeUserData: (param) => dispatch(getPicTreeUserData(param)),
  setUserPageListDataFilter: (param) => dispatch(setUserPageListDataFilter(param)),
  setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
  getUserListDataByTerm: () => dispatch(getUserListDataByTerm()),
  getUserCsvData: () => dispatch(getUserCsvData()),
  setNftTxCsvData: (param) => dispatch(setNftTxCsvData(param)),
  setPicTreeUserPageNumber: (param) => dispatch(setPicTreeUserPageNumber(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PicTreeUserList));
