import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { setCampaignDateRange, setDateRange, setBtnStateDateFetch, setMauDateRange, setMauPrevDateRange, setWauDateRange, setWauPrevDateRange } from 'actions';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
}));

const preDefinedEndDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');

const SelectDateRanges = ({
    page,
    setCampaignDateRange,
    setDateRange,
    campaignDateRange,
    campaignPreDefinedDateRange,
    setMauDateRange,
    setWauDateRange,
    setBtnStateDateFetch,
    getDayDiff,
    setComparisonDateDiff,
    setPrevDateRange,
    setMauPrevDateRange,
    setWauPrevDateRange,
    getPrevDates,
    dateRange,
}) => {
    const classes = useStyles();
    const [range, setRange] = useState(campaignPreDefinedDateRange);

    useEffect(() => {
        if (page === 'campaignSummary') {
            setRange(7);
        }
    }, []);

    useEffect(() => {
        const isPreDefined = isCustomPredefined(dateRange);
        if (isPreDefined) {
            setRange(isPreDefined);
        } else {
            if (page === 'campaignSummary') {
                let rangeValue = dateRange[0] === "2020-03-11" ? "all" : "";
                setRange(rangeValue);
            } else {
                setRange('');
            }
        }
    }, [dateRange]);

    useEffect(() => {
        //console.log('campaignDateRangecampaignDateRange:', campaignDateRange);
        const isPreDefined = isCustomPredefined(campaignDateRange);
        if (isPreDefined) {
            setRange(isPreDefined);
        } else {
            setRange('');
        }
    }, [campaignDateRange]);

    const isCustomPredefined = (dateRangeSelected) => {
        const dateStart = dateRangeSelected[0].replace(/-/g, '/');
        const dateEnd = dateRangeSelected[1].replace(/-/g, '/');
        // 7, 14, 30, 90, 180
        let isMatched = false;
        const sgEndDateToFetch = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');

        let sgStartDateArray = [];
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(14, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(30, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(90, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(180, 'days').format('YYYY/MM/DD'));

        if (sgStartDateArray.indexOf(dateStart) !== -1) {
            if (dateEnd === sgEndDateToFetch) {
                switch(sgStartDateArray.indexOf(dateStart)) {
                    case 0 :
                        isMatched = 1;
                        break;
                    case 1 :
                        isMatched = 7;
                        break;
                    case 2 :
                        isMatched = 14;
                        break;
                    case 3 :
                        isMatched = 30;
                        break;
                    case 4 :
                        isMatched = 90;
                        break;
                    case 5 :
                        isMatched = 180;
                        break;
                    default:
                }
            }
        }
        return isMatched;
    }

    const handleChange = (event) => {
        setRange(event.target.value);
        let preDefinedStartDate = moment().tz("Asia/Singapore").subtract(event.target.value, 'days').format('YYYY/MM/DD');
        // console.log('ggg1:', preDefinedStartDate);
        // console.log('ggg2:', preDefinedEndDate);
        // console.log('ggg3:', page);
        if (page === 'campaignSummary') {
            if (event.target.value === 'all') {
                preDefinedStartDate = '2020/03/11';
            }
            setCampaignDateRange([preDefinedStartDate, preDefinedEndDate]);
        } else {
            const dateStart = preDefinedStartDate.replace(/-/g, '/');
            const dateEnd = preDefinedEndDate.replace(/-/g, '/');
            setDateRange([dateStart, dateEnd]);

            // MAU DATE RANGE: calculate last 30 days of MAU and set
            const mauBeginDate = moment(dateEnd).tz("Asia/Singapore").subtract(29, 'days').format('YYYY/MM/DD');
            setMauDateRange([mauBeginDate, dateEnd]);
            const mauPrevDateEnd = moment(mauBeginDate).subtract(1, 'days').format('YYYY/MM/DD');
            const mauPrevDateBegin = moment(mauPrevDateEnd).subtract(29, 'days').format('YYYY/MM/DD');
            setMauPrevDateRange([mauPrevDateBegin, mauPrevDateEnd]);

            // WAU DATE RANGE: calculate last 7 days of WAU and set
            const wauBeginDate = moment(dateEnd).tz("Asia/Singapore").subtract(6, 'days').format('YYYY/MM/DD');
            setWauDateRange([wauBeginDate, dateEnd]);
            const wauPrevDateEnd = moment(wauBeginDate).subtract(1, 'days').format('YYYY/MM/DD');
            const wauPrevDateBegin = moment(wauPrevDateEnd).subtract(6, 'days').format('YYYY/MM/DD');
            setMauPrevDateRange([wauPrevDateEnd, wauPrevDateBegin]);

            let dayDiff = getDayDiff(dateStart, dateEnd);
            setComparisonDateDiff(dayDiff);

            let prevDates = getPrevDates(dateStart, dateEnd, dayDiff);
            setPrevDateRange(prevDates);
        }
        setBtnStateDateFetch(true);
    };

    return (
        <div>
            <FormControl className={classes.formControl}>
                <Select
                    labelId="simple-select-label"
                    id="simple-select"
                    displayEmpty
                    value={range}
                    onChange={handleChange}
                >
                    <MenuItem value="" disabled>期間を選択する</MenuItem>
                    <MenuItem value={1}>昨日</MenuItem>
                    <MenuItem value={7}>直近の7日間</MenuItem>
                    <MenuItem value={14}>直近の14日間</MenuItem>
                    <MenuItem value={30}>直近の30日間</MenuItem>
                    <MenuItem value={90}>直近の90日間</MenuItem>
                    { page === 'campaignSummary' ? <MenuItem value={180}>直近の180日間</MenuItem> : null }
                    { page === 'campaignSummary' ? <MenuItem value='all'>全期間</MenuItem> : null }
                </Select>
            </FormControl>
        </div>
    )
}

SelectDateRanges.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    campaignDateRange: state.campaign.campaignDateRange,
    campaignPreDefinedDateRange: state.campaign.campaignPreDefinedDateRange,
    dateRange: state.page.dateRange
});

const mapDispatchToProps = (dispatch) => ({
    setCampaignDateRange: (param) => dispatch(setCampaignDateRange(param)),
    setDateRange: (param) => dispatch(setDateRange(param)),
    setBtnStateDateFetch: (param) => dispatch(setBtnStateDateFetch(param)),
    setMauDateRange: (param) => dispatch(setMauDateRange(param)),
    setMauPrevDateRange: (param) => dispatch(setMauPrevDateRange(param)),
    setWauDateRange: (param) => dispatch(setWauDateRange(param)),
    setWauPrevDateRange: (param) => dispatch(setWauPrevDateRange(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(SelectDateRanges));