import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Chart from "react-google-charts";
import { withStyles } from '@material-ui/core/styles';
import * as V from 'styles/variables';

const HomeCountryMap = ({classes, setCurrentModule, source, width, height, activeUserHomeGeoData }) => {
    const [geoMapData, setGeoMapData] = useState([]);

    useEffect(() => {
        let dataSource = [];
        if (source === 'jobtribes') {
            dataSource = activeUserHomeGeoData.jobTribes;
        } else if (source === 'puzzle') {
            dataSource = activeUserHomeGeoData.puzzle;
        } else if (source === 'depMarketplace') {
            dataSource = activeUserHomeGeoData.depMarketplace;
        } else if (source === 'luckyFarmer') {
            dataSource = activeUserHomeGeoData.luckyFarmer;
        } else if (source === 'cookinBurger') {
            dataSource = activeUserHomeGeoData.cookinBurger;
        } else if (source === 'dragonRamen') {
            dataSource = activeUserHomeGeoData.dragonRamen;
        } else if (source === 'graffiti') {
            dataSource = activeUserHomeGeoData.graffiti;
        } else if (source === 'lostArchive') {
            dataSource = activeUserHomeGeoData.lostArchive;
        } else {
            dataSource = activeUserHomeGeoData[source];
        }

        let dataArray = [], countryData = [];
        if (dataSource == undefined) {
            return;
        }
        for (let i=0; i<dataSource.length; i++) {
            countryData.push(dataSource[i]['country']);
            countryData.push(dataSource[i]['count']);
            dataArray.push(countryData);
            countryData = [];
        }
        dataArray.unshift(['Country', 'DAU']);
        setGeoMapData(dataArray);
    }, [activeUserHomeGeoData]);

    return (
        <Fragment>
            <Chart
                width={width}
                height={height}
                chartType="GeoChart"
                data={geoMapData}
                options={{
                    colorAxis: { colors: [V.mapPolygonMinColor, V.mapPolygonMaxColor] },
                    backgroundColor: '#fff',
                    // datalessRegionColor: '#f8bbd0',
                    //defaultColor: '#f5f5f5',
                }}
                mapsApiKey={'somekey'}
                rootProps={{ 'data-testid': '1' }}
            />
        </Fragment>
    )
};

HomeCountryMap.propTypes = {
    classes: PropTypes.object,
    activeUserHomeGeoData: PropTypes.object,
};

const mapStateToProps = state => ({
    activeUserHomeGeoData: state.home.activeUserHomeGeoData,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(HomeCountryMap));
