import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDataLoadingForBy, setDataByGame, setDataLoad } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.nftDepOverview.loading;
export const dataLoaded = state => state.data.dataLoaded;

// Not in use since REBUILD
export function* getByGameData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByGame = true;
        yield put(setDataLoadingForBy(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        const unixTs = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        // p0f6rMikuCer9C4r8trA : luckyFarmer
        // hFndVB8vMuQnO2ihBnzw : playmining nft
        // TKR0iDxTLXl42rAbjcoa : cookinburger
        // g3bq4ssqNmgS8yiQWuba : Kamui Verse
        // lK6knuQWsFqH8lZifcnF : Dragon Ramen
        // @resoruce https://stackoverflow.com/questions/52341966/nested-aggregation-elasticsearch-with-access-parent-field-for-sub-aggregation

        const jtcbTags = [
            "RARITY-JTCB_LEGENDARY",
            "RARITY-JTCB_EPIC",
            "RARITY-JTCB_RARE",
            "RARITY-JTCB_COMMON"
        ];

        const graffitiTags = [
            "ATTRIBUTE-GRAFFITI_Red",
            "ATTRIBUTE-GRAFFITI_Blue",
            "ATTRIBUTE-GRAFFITI_Yellow",
            "ATTRIBUTE-GRAFFITI_Green",
            "ATTRIBUTE-GRAFFITI_Purple",
            "ATTRIBUTE-GRAFFITI_Orange"
        ];

        const graffitiRarityTags = ["RARITY-GRAFFITI_SSS","RARITY-GRAFFITI_SS","RARITY-GRAFFITI_S","RARITY-GRAFFITI_A","RARITY-GRAFFITI_B","RARITY-GRAFFITI_C"];

        const sourceAucStoreJt = H.ElasticSearch.getAucStoreCountByGameTags(dateFrom, dateTo, jtcbTags);
        const esQueryAucStoreDataJt = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStoreJt}`};

        const sourceAucStoreLf = H.ElasticSearch.getAucStoreCountByGame(dateFrom, dateTo, "p0f6rMikuCer9C4r8trA");
        const esQueryAucStoreDataLf = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStoreLf}`};

        const sourceAucStorePn = H.ElasticSearch.getAucStoreCountByGame(dateFrom, dateTo, "hFndVB8vMuQnO2ihBnzw");
        const esQueryAucStoreDataPn = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStorePn}`};

        const sourceAucStoreCb = H.ElasticSearch.getAucStoreCountByGame(dateFrom, dateTo, "TKR0iDxTLXl42rAbjcoa");
        const esQueryAucStoreDataCb = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStoreCb}`};

        const sourceAucStoreKv = H.ElasticSearch.getAucStoreCountByGame(dateFrom, dateTo, "g3bq4ssqNmgS8yiQWuba");
        const esQueryAucStoreDataKv = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStoreKv}`};

        const sourceAucStoreDr = H.ElasticSearch.getAucStoreCountByGame(dateFrom, dateTo, "lK6knuQWsFqH8lZifcnF");
        const esQueryAucStoreDataDr = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStoreDr}`};

        const sourceAucStoreGr = H.ElasticSearch.getAucStoreCountByGameTags(dateFrom, dateTo, graffitiTags);
        const esQueryAucStoreDataGr = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAucStoreGr}`};

        const [
            dataAucStoreJt,
            dataAucStoreLf,
            dataAucStorePn,
            dataAucStoreCb,
            dataAucStoreKv,
            dataAucStoreDr,
            dataAucStoreGr,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryAucStoreDataJt, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataLf, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataPn, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataCb, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataKv, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataDr, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataGr, ENDPOINT.GET_DEP_SALES, jwtToken),
        ]);

        //getNftDepTxBy: function(metric, fromClause, whereClause, unixDateFrom, unixDateTo) {
        // TX Amount
        // const queryAuctionAmountTtl = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptAuctionProd', 'logDate', unixTs[0], unixTs[1]);
        // const queryAuctionAmountTtlByJtcb = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptAuctionProd s JOIN JtcbGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryAuctionAmountTtlByLf = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptAuctionProd s JOIN LfGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryAuctionAmountTtlByPuzzle = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptAuctionProd s JOIN PuzzleGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);

        // const queryStoreAmountTtl = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptStoreProd', 'logDate', unixTs[0], unixTs[1]);
        // const queryStoreAmountTtlByJtcb = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptStoreProd s JOIN JtcbGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryStoreAmountTtlByLf = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptStoreProd s JOIN LfGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryStoreAmountTtlByPuzzle = H.SqlHelper.getNftDepTxBy('SUM(amount)', 'NftDepReceiptStoreProd s JOIN PuzzleGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);

        // const [
        //     tempAuctionAmountTtl,
        //     tempAuctionAmountJtcb,
        //     tempAuctionAmountLf,
        //     tempAuctionAmountPuzzle,
        //     tempStoreAmountTtl,
        //     tempStoreAmountJtcb,
        //     tempStoreAmountLf,
        //     tempStoreAmountPuzzle,
        // ] = yield all([
        //     call(getDataFromRDS, queryAuctionAmountTtl, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionAmountTtlByJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionAmountTtlByLf, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionAmountTtlByPuzzle, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreAmountTtl, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreAmountTtlByJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreAmountTtlByLf, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreAmountTtlByPuzzle, ENDPOINT.GET_DATA_RDS, jwtToken),
        // ]);
        // const parsedAuctionAmountTtl = JSON.parse(tempAuctionAmountTtl.body);
        // const parsedAuctionAmountJtcb = JSON.parse(tempAuctionAmountJtcb.body);
        // const parsedAuctionAmountLf = JSON.parse(tempAuctionAmountLf.body);
        // const parsedAuctionAmountPuzzle = JSON.parse(tempAuctionAmountPuzzle.body);
        // const parsedStoreAmountTtl = JSON.parse(tempStoreAmountTtl.body);
        // const parsedStoreAmountJtcb = JSON.parse(tempStoreAmountJtcb.body);
        // const parsedStoreAmountLf = JSON.parse(tempStoreAmountLf.body);
        // const parsedStoreAmountPuzzle = JSON.parse(tempStoreAmountPuzzle.body);

        let amountAuctionArray = [
            { title: "JobTribes", value: dataAucStoreJt.kind.buckets[0]['dep_total_amount']['value'] },
            { title: "LuckyFarmer", value: dataAucStoreLf.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value'] },
            { title: "PlayMining NFT", value: dataAucStorePn.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "CookinBurger", value: dataAucStoreCb.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "Kamui Verse", value: dataAucStoreKv.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "Dragon Ramen", value: dataAucStoreDr.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "Graffiti Racer", value: dataAucStoreGr.kind.buckets.length > 0 ? dataAucStoreGr.kind.buckets[0]['dep_total_amount']['value'] : 0 },
        ];
        amountAuctionArray = _.orderBy(amountAuctionArray, ['value'],['desc']);
        let amountStoreArray = [
            { title: "JobTribes", value: dataAucStoreJt.kind.buckets[1]['dep_total_amount']['value'] },
            { title: "LuckyFarmer", value: dataAucStoreLf.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value'] },
            { title: "PlayMining NFT", value: dataAucStorePn.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "CookinBurger", value: dataAucStoreCb.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "Kamui Verse", value: dataAucStoreKv.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "Dragon Ramen", value: dataAucStoreDr.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['dep_total_amount']['value']  },
            { title: "Graffiti Racer", value: dataAucStoreGr.kind.buckets[1] == undefined ?
        0 : dataAucStoreGr.kind.buckets[1]['dep_total_amount']['value'] },
        ];
        amountStoreArray = _.orderBy(amountStoreArray, ['value'],['desc']);

        // TX Count
        // const queryAuctionCountTtl = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptAuctionProd', 'logDate', unixTs[0], unixTs[1]);
        // const queryAuctionCountTtlByJtcb = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptAuctionProd s JOIN JtcbGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryAuctionCountTtlByLf = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptAuctionProd s JOIN LfGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryAuctionCountTtlByPuzzle = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptAuctionProd s JOIN PuzzleGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);

        // const queryStoreCountTtl = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptStoreProd', 'logDate', unixTs[0], unixTs[1]);
        // const queryStoreCountTtlByJtcb = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptStoreProd s JOIN JtcbGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryStoreCountTtlByLf = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptStoreProd s JOIN LfGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryStoreCountTtlByPuzzle = H.SqlHelper.getNftDepTxBy('COUNT(*)', 'NftDepReceiptStoreProd s JOIN PuzzleGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);

        // const [
        //     tempAuctionCountTtl,
        //     tempAuctionCountJtcb,
        //     tempAuctionCountLf,
        //     tempAuctionCountPuzzle,
        //     tempStoreCountTtl,
        //     tempStoreCountJtcb,
        //     tempStoreCountLf,
        //     tempStoreCountPuzzle,
        // ] = yield all([
        //     call(getDataFromRDS, queryAuctionCountTtl, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionCountTtlByJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionCountTtlByLf, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionCountTtlByPuzzle, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtl, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtlByJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtlByLf, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtlByPuzzle, ENDPOINT.GET_DATA_RDS, jwtToken),
        // ]);

        // const parsedAuctionCountTtl = JSON.parse(tempAuctionCountTtl.body);
        // const parsedAuctionCountJtcb = JSON.parse(tempAuctionCountJtcb.body);
        // const parsedAuctionCounttLf = JSON.parse(tempAuctionCountLf.body);
        // const parsedAuctionCountPuzzle = JSON.parse(tempAuctionCountPuzzle.body);
        // const parsedStoreCountTtl = JSON.parse(tempStoreCountTtl.body);
        // const parsedStoreCountJtcb = JSON.parse(tempStoreCountJtcb.body);
        // const parsedStoreCountLf = JSON.parse(tempStoreCountLf.body);
        // const parsedStoreCountPuzzle = JSON.parse(tempStoreCountPuzzle.body);

        let countAuctionArray = [
            { title: "JobTribes", value: dataAucStoreJt.kind.buckets[0]['doc_count'] },
            { title: "LuckyFarmer", value: dataAucStoreLf.kind.buckets[0]['game']['filter_asset']['doc_count'] },
            { title: "PlayMining NFT", value: dataAucStorePn.kind.buckets[0]['game']['filter_asset']['doc_count'] },
            { title: "CookinBurger", value: dataAucStoreCb.kind.buckets[0]['game']['filter_asset']['doc_count'] },
            { title: "Kamui Verse", value: dataAucStoreKv.kind.buckets[0]['game']['filter_asset']['doc_count'] },
            { title: "Dragon Ramen", value: dataAucStoreDr.kind.buckets[0]['game']['filter_asset']['doc_count'] },
            { title: "Graffiti Racer", value: dataAucStoreGr.kind.buckets.length > 0 ? dataAucStoreGr.kind.buckets[0]['doc_count'] : 0 },
        ];
        countAuctionArray = _.orderBy(countAuctionArray, ['value'],['desc']);
        let countStoreArray = [
            { title: "JobTribes", value: dataAucStoreJt.kind.buckets[1]['doc_count'] },
            { title: "LuckyFarmer", value: dataAucStoreLf.kind.buckets[1]['game']['filter_asset']['doc_count'] },
            { title: "PlayMining NFT", value: dataAucStorePn.kind.buckets[1]['game']['filter_asset']['doc_count'] },
            { title: "CookinBurger", value: dataAucStoreCb.kind.buckets[1]['game']['filter_asset']['doc_count'] },
            { title: "Kamui Verse", value: dataAucStoreKv.kind.buckets[1]['game']['filter_asset']['doc_count'] },
            { title: "Dragon Ramen", value: dataAucStoreDr.kind.buckets[1]['game']['filter_asset']['doc_count'] },
            { title: "Graffiti Racer", value: dataAucStoreGr.kind.buckets[1] == undefined
        ? 0 : dataAucStoreGr.kind.buckets[1]['doc_count'] },
        ];
        countStoreArray = _.orderBy(countStoreArray, ['value'],['desc']);

        // TX Count Unique
        // const queryAuctionUniTtl = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT fromPmid)', 'NftDepReceiptAuctionProd', 'logDate', unixTs[0], unixTs[1]);
        // const queryAuctionUniTtlByJtcb = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT g.pmId)', 'NftDepReceiptAuctionProd s JOIN JtcbGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryAuctionUniTtlByLf = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT g.pmId)', 'NftDepReceiptAuctionProd s JOIN LfGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryAuctionUniTtlByPuzzle = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT g.pmId)', 'NftDepReceiptAuctionProd s JOIN PuzzleGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);

        // const queryStoreUniTtl = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT fromPmid)', 'NftDepReceiptStoreProd', 'logDate', unixTs[0], unixTs[1]);
        // const queryStoreCountTtlUniByJtcb = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT g.pmId)', 'NftDepReceiptStoreProd s JOIN JtcbGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryStoreCountTtlUniByLf = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT g.pmId)', 'NftDepReceiptStoreProd s JOIN LfGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);
        // const queryStoreCountTtlUniByPuzzle = H.SqlHelper.getNftDepTxBy('COUNT(DISTINCT g.pmId)', 'NftDepReceiptStoreProd s JOIN PuzzleGameUsersProd g ON s.fromPmid = g.pmId ', 's.logDate', unixTs[0], unixTs[1]);

        // const [
        //     tempAuctionUniTtl,
        //     tempAuctionUniJtcb,
        //     tempAuctionUniLf,
        //     tempAuctionUniPuzzle,
        //     tempStoreUniTtl,
        //     tempStoreUniJtcb,
        //     tempStoreUniLf,
        //     tempStoreUniPuzzle,
        // ] = yield all([
        //     call(getDataFromRDS, queryAuctionUniTtl, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionUniTtlByJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionUniTtlByLf, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryAuctionUniTtlByPuzzle, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreUniTtl, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtlUniByJtcb, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtlUniByLf, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, queryStoreCountTtlUniByPuzzle, ENDPOINT.GET_DATA_RDS, jwtToken),
        // ]);

        // const parsedAuctionUniTtl = JSON.parse(tempAuctionUniTtl.body);
        // const parsedAuctionUniJtcb = JSON.parse(tempAuctionUniJtcb.body);
        // const parsedAuctionUniLf = JSON.parse(tempAuctionUniLf.body);
        // const parsedAuctionUniPuzzle = JSON.parse(tempAuctionUniPuzzle.body);
        // const parsedStoreUniTtl = JSON.parse(tempStoreUniTtl.body);
        // const parsedStoreUniJtcb = JSON.parse(tempStoreUniJtcb.body);
        // const parsedStoreUniLf = JSON.parse(tempStoreUniLf.body);
        // const parsedStoreUniPuzzle = JSON.parse(tempStoreUniPuzzle.body);

        let UniAuctionArray = [
            { title: "JobTribes", value: dataAucStoreJt.kind.buckets[0]['unique_pmid_from_to']['value'] },
            { title: "LuckyFarmer", value: dataAucStoreLf.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "PlayMining NFT", value: dataAucStorePn.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "CookinBurger", value: dataAucStoreCb.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "Kamui Verse", value: dataAucStoreKv.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "Dragon Ramen", value: dataAucStoreDr.kind.buckets[0]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "Graffiti Racer", value: dataAucStoreGr.kind.buckets[0] === undefined ? 0 : dataAucStoreGr.kind.buckets[0]['unique_pmid_from_to']['value'] },
        ];
        UniAuctionArray = _.orderBy(UniAuctionArray, ['value'],['desc']);
        let UniStoreArray = [
            { title: "JobTribes", value: dataAucStoreJt.kind.buckets[1]['unique_pmid_from_to']['value'] },
            { title: "LuckyFarmer", value: dataAucStoreLf.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "PlayMining NFT", value: dataAucStorePn.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "CookinBurger", value: dataAucStoreCb.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "Kamui Verse", value: dataAucStoreKv.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "Dragon Ramen", value: dataAucStoreDr.kind.buckets[1]['game']['filter_asset']['parent_doc_price']['unique_pmid_from_to']['value'] },
            { title: "Graffiti Racer", value: dataAucStoreGr.kind.buckets[1] == undefined
            ? 0 : dataAucStoreGr.kind.buckets[1]['unique_pmid_from_to']['value'] },
        ];
        UniStoreArray = _.orderBy(UniStoreArray, ['value'],['desc']);

        const dataByGame = {
            amount: {
                auction: amountAuctionArray,
                store: amountStoreArray,
            },
            count: {
                auction: countAuctionArray,
                store: countStoreArray,
            },
            pmid: {
                auction: UniAuctionArray,
                store: UniStoreArray,
            }
        }
        yield put(setDataByGame(dataByGame));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByGame = false;
        yield put(setDataLoadingForBy(loadingUpdate2));

        // set data loaded flag
        const dataExist = yield select(dataLoaded);
        const dataAdd = { "nftdep-overview-game" : [dateFrom, dateTo] }
        const newData = _.merge(dataExist, dataAdd);
        yield put(setDataLoad(newData));
    } catch (err) {
        console.log(err);
    }
}

export function* getByGameDataSxi() {
    const jwtToken = yield call(getJwtToken);
    const loadingStateBegin = yield select(loading);
    let loadingUpdate = {...loadingStateBegin};
    loadingUpdate.ovByGame = true;
    yield put(setDataLoadingForBy(loadingUpdate));

    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    dateTo = moment(dateTo).format('YYYY-MM-DD');

    const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
    const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

    const sqlQueryTx1stAmountByGame = H.SqlHelper.getNftTransactionAmountByGameBidb2(
        {table: 'SxiNftSalesProd1stV2', limit: 15, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]}
    );
    const sqlQueryTx1stCountByGame= H.SqlHelper.getNftTransactionCountByGameBidb2(
        {table: 'SxiNftSalesProd1stV2', limit: 15, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]}
    );
    const sqlQueryTx2ndAmountByGame= H.SqlHelper.getNftTransactionAmountByGameBidb2(
        {table: 'SxiNftSalesProd2ndV1', limit: 15, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]}
    );
    const sqlQueryTx2ndCountByGame = H.SqlHelper.getNftTransactionCountByGameBidb2(
        {table: 'SxiNftSalesProd2ndV1', limit: 15, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]}
    );
    const sqlQueryTxFiatAmountByGame = H.SqlHelper.getNftTransactionAmountByGameBidb2(
        {table: 'SxiNftSalesProdFiatV1', limit: 15, tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd]}
    );
    const sqlQueryTxFiatCountByGame= H.SqlHelper.getNftTransactionCountByGameBidb2(
        {table: 'SxiNftSalesProdFiatV1', limit: 15, tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd]}
    );

    const [
        dataTx1stAmountByGameTemp,
        dataTx1stCountByGameTemp,
        dataTx2ndAmountByGameTemp,
        dataTx2ndCountByGameTemp,
        dataTxFiatAmountByGameTemp,
        dataTxFiatCountByGameTemp,
    ] = yield all([
        call(getDataFromRDS, sqlQueryTx1stAmountByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryTx1stCountByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryTx2ndAmountByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryTx2ndCountByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryTxFiatAmountByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryTxFiatCountByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
    ]);
    const dataTx1stAmountByGame = JSON.parse(dataTx1stAmountByGameTemp.body);
    const dataTx1stCountByGame = JSON.parse(dataTx1stCountByGameTemp.body);
    const dataTx2ndAmountByGame = JSON.parse(dataTx2ndAmountByGameTemp.body);
    const dataTx2ndCountByGame = JSON.parse(dataTx2ndCountByGameTemp.body);
    const dataTxFiatAmountByGame = JSON.parse(dataTxFiatAmountByGameTemp.body);
    const dataTxFiatCountByGame = JSON.parse(dataTxFiatCountByGameTemp.body);

    const gameMapping = {
        "cl8jxahuq0009l5rwgeh646m3": "JobTribes",
        "cl8jxahul0005l5rwa9bb6til": "Graffiti Racer",
        "cl8jxahuf0001l5rwewc19q7j": "Cookin\' Burger",
        "cl8jxanai07njl5rwgg80cxzc": "Dragon Ramen",
        "cl8jxahtw0000l5rwh6ncf2uw": "Lucky Farmer",
        "cl8jxanai07nkl5rweau5bhwa": "Puzzle",
        "cl8jxahuh0002l5rwf0eeezoz": "Kamui Verse",
        "cl8jxanaj07nnl5rwg9awchu1": "PlayMining NFT",
        "cl8jxanai07nll5rw1ahg3xrd": "PlayMining NFT",
        "cleo05zpl003sagpjfe3q5n6l": "Lost Archive+",
        "cl8jxanaj07npl5rw92qif08j": "PlaysiaTV",
        "clbk5yhj700c9inmt7ysd3g0e": "SOUL Fusers",
        "cl8px3n029saehrvwasxp1s01": "Rogue Roll Ruler\'s",
        "cl8px3n980123hrvw2pwljs63": "ISHI_to_HOSHI",
        "cl2135fe8776a34fb084e1ba02": "Bouncy Bunny",
        "cl88f51f50fd624393b57fda06": "PicTrée",
    }
    let amountAuctionArray = [], amountStoreArray = [], countAuctionArray = [], countStoreArray = [], eachData = {};
    let amountFiatArray = [], countFiatArray = [];
    _.each(dataTx1stAmountByGame, function(data, index) {
        eachData = {
            title : gameMapping[data[1]['stringValue']], value : Number(data[0]['stringValue'])
        };
        amountStoreArray.push(eachData);
        eachData = {};
    });

    _.each(dataTx2ndAmountByGame, function(data, index) {
        eachData = {
            title : gameMapping[data[1]['stringValue']], value : Number(data[0]['stringValue'])
        };
        amountAuctionArray.push(eachData);
        eachData = {};
    });

    _.each(dataTx1stCountByGame, function(data, index) {
        eachData = {
            title : gameMapping[data[1]['stringValue']], value : data[0]['longValue']
        };
        countStoreArray.push(eachData);
        eachData = {};
    });

    _.each(dataTx2ndCountByGame, function(data, index) {
        eachData = {
            title : gameMapping[data[1]['stringValue']], value : data[0]['longValue']
        };
        countAuctionArray.push(eachData);
        eachData = {};
    });

    // Fiat
    _.each(dataTxFiatAmountByGame, function(data, index) {
        eachData = {
            title : gameMapping[data[1]['stringValue']], value : data[0]['isNull'] ? 0 : Number(data[0]['stringValue'])
        };
        amountFiatArray.push(eachData);
        eachData = {};
    });

    _.each(dataTxFiatCountByGame, function(data, index) {
        eachData = {
            title : gameMapping[data[1]['stringValue']], value : data[0]['isNull'] ? 0 : data[0]['longValue']
        };
        countFiatArray.push(eachData);
        eachData = {};
    });

    const dataByGame = {
        amount: {
            store: amountStoreArray,
            auction: amountAuctionArray,
            fiat: amountFiatArray,
        },
        count: {
            store: countStoreArray,
            auction: countAuctionArray,
            fiat: countFiatArray,
        },
    }
    yield put(setDataByGame(dataByGame));


    // update the loading state
    const loadingStateEnd = yield select(loading);
    let loadingUpdate2 = {...loadingStateEnd};
    loadingUpdate2.ovByGame = false;
    yield put(setDataLoadingForBy(loadingUpdate2));

    // set data loaded flag
    const dataExist = yield select(dataLoaded);
    const dataAdd = { "nftdep-overview-game" : [dateFrom, dateTo] }
    const newData = _.merge(dataExist, dataAdd);
    yield put(setDataLoad(newData));
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_OVERVIEW_DATA_BY_GAME, getByGameDataSxi);
}
