import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import { setCampaignGame } from  'actions';

const styles = theme => ({
    title: {
        width: '100%',
        maxWidth: 500,
        paddingLeft: 8
    },
    areaWrapper: {
        display: 'block',
        width: '90%',
        paddingBottom: theme.spacing(2),
    },
    formControl: {
        marginLeft: theme.spacing(4),
    },
    selectWrapper: {
        margin: `8px 0`,
        width: 200,
    },
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
        marginTop: 16,
    },
    fontIconSpacing: {
        letterSpacing: '1px',
        width: '18px',
    }
});

const CampaignGame = ({classes, view, setCampaignGame, affiliatorGame}) => {
    const [game, setGame] = useState('');

    useEffect(() => {
        if (view) {
            setGame(affiliatorGame);
        }
    }, [affiliatorGame]);

    const handleChange = (event) => {
        setGame(event.target.value);
        setCampaignGame(event.target.value)
    };

    return (
        <div className={classes.areaWrapper}>
            <div>
                <Icon className={classNames('fa fa-gamepad fa-sm', classes.fontIconSpacing)} /><span className={classes.title}>対象</span>
            </div>
            <div className={classes.root}>
                <FormControl component="fieldset" variant="outlined" className={classes.formControl}>
                    {view !== true &&
                    <Select
                        defaultValue="JobTribes"
                        value={game}
                        onChange={handleChange}
                        renderValue={(game) => (
                            <div className={classes.chips}>
                                <Chip key={game} label={game} className={classes.chip} />
                            </div>
                        )}
                        className={classes.selectWrapper}
                    >
                        <MenuItem value='JobTribes'>JobTribes</MenuItem>
                        <MenuItem value='LuckyFarmer'>Lucky Farmer</MenuItem>
                        <MenuItem value='PM Portal'>PM Portal</MenuItem>
                        <MenuItem value='NFT:DEP'>PlayMining NFT</MenuItem>
                        <MenuItem value='CookinBurger'>Cookin Burger</MenuItem>
                        <MenuItem value='GraffitiRacer'>GraffitiRacer</MenuItem>
                        <MenuItem value='DragonRamen'>DragonRamen</MenuItem>
                        <MenuItem value='SoulFusers'>SoulFusers</MenuItem>
                        <MenuItem value='RogueRollRulers'>Rogue Roll Ruler's</MenuItem>
                        <MenuItem value='bouncybunny'>Bouncy Bunny</MenuItem>
                        <MenuItem value='LP Special'>LP Special</MenuItem>
                    </Select>
                    }
                    {view &&
                        <div className={classes.gameTxt}>{game}</div>
                    }
                </FormControl>
            </div>
        </div>
    );
}

CampaignGame.propTypes = {
    classes: PropTypes.object,
    setCampaignGame: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    affiliatorGame: state.affiliate.affiliatorGame
});

const mapDispatchToProps = dispatch => ({
    setCampaignGame: (param) => dispatch(setCampaignGame(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CampaignGame));