import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, Redirect } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Hidden } from '@material-ui/core';
import { Fab, Button, Icon, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SideBarStateStyles from './SideBarStateStyles';
import { setCreateStatus, setAffiliator, updateAffiliator } from 'actions';

const MenuLabels = [
    '名前', 'Eメール', 'タイプ', //'メディア',
];

const MenuIcons = [
    <Icon className={'fa fa-user fa-md'} />, <Icon className={'fa fa-envelope fa-md'} />,
    <Icon className={'fa fa-building fa-md'} />, //<Icon className={'fa fa-share-alt-square fa-md'} />
];

export class SideBarState extends Component {
    checkState(index) {
        if (index === 0) { // Name
            return !this.props.sidebarStatus.name;
        } else if (index === 1) { // Email
            return !this.props.sidebarStatus.email;
        } else if (index === 2) { // Type
            return !this.props.sidebarStatus.type;
        }
    }

    createAffiliatorBtnClicked() {
        this.props.setAffiliator();
    }

    updateAffiliatorBtnClicked() {
        this.props.updateAffiliator();
    }

    render() {
        const { classes } = this.props;
        console.log('this.props.updateAffiliate:', this.props.updateAffiliate);
        if (this.props.updateAffiliate)
            return <Redirect to='/affiliate' />

        return (
            <Grid item xs={6} sm={4} md={3} lg={2}>
                <Hidden xsDown>
                    <Drawer
                        className={classes.drawer}
                        variant="permanent"
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        <List>
                            <Fab
                                variant="extended"
                                size="medium"
                                color="primary"
                                aria-label="Add"
                                className={classes.btnCancelReport}
                                component={ Link }
                                to="/affiliate"
                            >
                                <Icon style={{color: "#fff", paddingRight: "8px" }}>clear</Icon>キャンセル
                            </Fab>
                        </List>
                        <List>
                        {MenuLabels.map((text, index) => (
                            <ListItem key={text}>
                                <ListItemIcon>{MenuIcons[index]}</ListItemIcon>
                                <ListItemText primary={text} />
                                <CheckCircleIcon
                                    className={this.checkState(index) ? classes.btnUnChecked : classes.btnChecked}
                                />
                            </ListItem>
                        ))}
                        </List>
                        <List component="div" className={classes.btnPreviewDiv}>
                            {this.props.currentPage === 'create' &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.props.createBtnState}
                                    className={classes.button}
                                    onClick={() => { this.createAffiliatorBtnClicked()}}>
                                    登録する
                                </Button>
                            }
                            {this.props.currentPage === 'view' &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={!this.props.createBtnState}
                                    className={classes.button}
                                    onClick={() => { this.updateAffiliatorBtnClicked()}}>
                                    更新する
                                </Button>
                            }
                        </List>
                    </Drawer>
                </Hidden>
            </Grid>
        );
    }
}

SideBarState.propTypes = {
    classes: PropTypes.object,
    currentPage: PropTypes.string,
    sidebarStatus: PropTypes.object,
    createBtnState: PropTypes.bool,
    updateAffiliate: PropTypes.bool
};

const mapStateToProps = state => ({
    sidebarStatus: state.affiliate.sidebarStatus,
    createBtnState: state.affiliate.createBtnState,
    currentPage: state.page.currentPage,
    updateAffiliate: state.affiliate.updateAffiliate,
});

const mapDispatchToProps = dispatch => ({
    setCreateStatus: (filter, value) => dispatch(setCreateStatus(filter, value)),
    setAffiliator: () => dispatch(setAffiliator()),
    updateAffiliator: () => dispatch(updateAffiliator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(SideBarStateStyles, { withTheme: true })(SideBarState));