import { JTCB } from '../../constants';
import moment from "moment";

const initialState  = {
    loading: false,
    amuletData: [
        {
            id: 'allAmulet',
            data: [],
            date: [],
        },
    ],
    loadingAmuletJob: false,
    amuletDataJob: [],
    amuletSelected: [{ name: '全アミュレット', value: 'all' }],
};

export default function scholarAmuletReducer(state = initialState, action) {
    switch (action.type) {
        case JTCB.SET_SCHOLAR_AMULET_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case JTCB.SET_JTCB_SCHOLAR_AMULET_DATA:
            return {
                ...state,
                amuletData: action.payload
            };
        case JTCB.SET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB:
            return {
                ...state,
                amuletDataJob: action.payload
            };
        case JTCB.SET_DATA_LOADING_AMULET_JOB:
            return {
                ...state,
                loadingAmuletJob: action.payload
            };
        case JTCB.SET_AMULET_SELECTED:
            return {
                ...state,
                amuletSelected: action.payload
            };
        default:
            return state
    }
};
