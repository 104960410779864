import CountryCode from 'utils/CountryCode';

const CountryHelper = {
    getCountryCode: function(countryName) {
        let countryCode = '';
        for (let i=0; i<CountryCode.items.length; i++) {
            if (CountryCode.items[i]['name'] === countryName) {
                countryCode = CountryCode.items[i]['code'].toLowerCase();
            }
        }
        //return 'https://flagcdn.com/w20/'+countryCode+'.png'; // original
        return 'https://flagcdn.com/24x18/'+countryCode+'.png'; // wave
        //return 'https://www.countryflags.io/'+countryCode+'/shiny/24.png';
    }
}

export default CountryHelper;