import { NFTDEP } from '../../constants';
import moment from "moment";

const startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
const endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    nftOwnTrendDateRange: [startDate, endDate],
    nftOwnTrendOption: [],
    nftOwnTrendOptionSelected: [],
    nftOwnTrendData: [],
    nftOwnTrendDataLoading: false,
};

export default function activeUsersReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_NFT_OWN_TREND_DATE_RANGE:
            return {
                ...state,
                nftOwnTrendDateRange: action.payload
            };
        case NFTDEP.SET_NFT_OWN_TREND_OPTION:
            return {
                ...state,
                nftOwnTrendOption: action.payload
            };
        case NFTDEP.SET_NFT_OWN_TREND_OPTION_SELECTED:
            return {
                ...state,
                nftOwnTrendOptionSelected: action.payload.length > 0 ? action.payload : []
            };
        case NFTDEP.SET_NFT_OWN_TREND_DATA:
            return {
                ...state,
                nftOwnTrendData: action.payload
            };
        case NFTDEP.SET_NFT_OWN_TREND_DATA_LOADING:
            return {
                ...state,
                nftOwnTrendDataLoading: action.payload
            };
        default:
            return state
    }
};
