// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import * as H from "helper";

const Top10TxCountByItem = ({
  title,
  source,
  loading,
  dateRange,
  dataInGameItemByItem,
  total,
  game,
}) => {
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    let dataSource = [];
    switch (source) {
      case "ingame-fiat":
        dataSource = dataInGameItemByItem.count.fiat;
        break;
      case "ingame-dep":
        dataSource = dataInGameItemByItem.count.dep;
        break;
      default:
    }
    let dataArray = [],
      dataObj = {};
    for (let i = 0; i < dataSource.length; i++) {
      dataObj.no = i + 1;
      dataObj.url =
        dataSource[i]["country"] === "(not set)" ||
        dataSource[i]["country"] === "n/a"
          ? false
          : H.CountryHelper.getCountryCode(dataSource[i]["country"]);
      dataObj.country = dataSource[i]["country"];
      dataObj.game = dataSource[i]["contents"];
      dataObj.num_reg = dataSource[i]["value"];
      dataObj.num_ratio = ((dataSource[i]["value"] / total) * 100).toFixed(
        2
      );
      dataArray.push(dataObj);
      dataObj = {};
    }
    setGeoData(dataArray);
  }, [dataInGameItemByItem, total]);

  const getColumns = (game) => {
    let columnArray = [];
    columnArray = [
      {
        title: "#",
        field: "no",
        width: 50,
        cellStyle: { textAlign: "center" },
      },
      {
        title: "アイテム",
        field: "country",
        width: 350,
        render: (rowData) => (
          <Fragment>
            <div style={{ display: "flex" }}>
              <div style={{ paddingTop: 4 }}>
                {rowData.country === "n/a" ? "不明" : rowData.country}
              </div>
            </div>
          </Fragment>
        ),
      },
      {
        title: "取引件数",
        field: "num_reg",
        width: 200,
        type: "numeric",
        cellStyle: { textAlign: "center" },
        render: (rowData) => (
          <div>
            {H.FormatNumStrHelper.formatCommaThousand(rowData.num_reg)}
          </div>
        ),
      },
      {
        title: "全体比率",
        field: "num_ratio",
        type: "numeric",
        cellStyle: { textAlign: "left", marginLeft: 32 },
        render: (rowData) => (
          <Fragment>
            <div style={{ display: "flex", height: 16 }}>
              <div style={{ paddingRight: 16, width: 24 }}>
                {rowData.num_ratio}%
              </div>
              <div
                style={{
                  backgroundColor: "#65C9A5",
                  width: rowData.num_ratio + "%",
                  marginLeft: 20,
                  marginTop: 3,
                }}
              ></div>
            </div>
          </Fragment>
        ),
      },
    ];
    if (game === "ingame-sale") {
      columnArray.splice(2, 0, 
        {
          title: "ゲーム",
          field: "game",
          width: 100,
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex" }}>
                <div style={{ paddingTop: 4 }}>
                  {rowData.game}
                </div>
              </div>
            </Fragment>
          ),
        },        
      )
    }
    return columnArray;
  };

  return (
    <MaterialTable
      isLoading={loading}
      style={{ padding: 16, fontSize: 14, minHeight: 280 }}
      title={
        <div style={{ fontSize: 18, fontWeight: 500, color: "#000" }}>
          {title}
        </div>
      }
      columns={getColumns(game)}
      data={geoData}
      options={{
        search: false,
        paging: false,
        sorting: false,
        exportButton: { csv: true },
        exportFileName: `${dateRange[0]}-${dateRange[1]} ${title}`,
        rowStyle: {
          textAlign: "center",
        },
        headerStyle: { textAlign: "center" },
      }}
      // components={{
      //     Toolbar: props => (
      //          <div style={{ marginLeft: '-20px' }}>
      //                  <MTableToolbar {...props} />
      //          </div>
      //     )
      // }}
      localization={{
        body: {
          emptyDataSourceMessage: "表示可能なデータがありません",
        },
      }}
    />
  );
};

Top10TxCountByItem.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  dataInGameItemByItem: state.inGameItemSales.dataByItem,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Top10TxCountByItem));
