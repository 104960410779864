import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import * as Var from 'styles/variables';
import { ContentWrapper } from 'components';
import { connect } from 'react-redux';
import { setCampaignMedia } from  'actions';

const styles = theme => ({
    title: {
        width: '100%',
        maxWidth: 500,
        color: Var.textColor,
        paddingTop: 8,
        paddingLeft: 8
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1),
        width: '80%',
    },
    areaWrapper: {
        display: 'block',
        width: '80%',
        paddingBottom: '25px'
    }
});

export class CampaignMedia extends Component {
    handleChange = title => event => {
        this.props.setCampaignMedia(event.target.value);
    };

    render() {
        const { classes } = this.props;

        return (
            <ContentWrapper>
                <div className={classes.areaWrapper}>
                    <div>
                        <Icon className={'fa fa-share-alt-square fa-lg'} /><span className={classes.title}>メディア</span>
                    </div>
                    <TextField
                        id="standard-name"
                        label="URL及びメディア名"
                        className={classes.textField}
                        value={this.props.affiliatorEmail}
                        onChange={this.handleChange('title')}
                        fullWidth
                        margin="normal"
                        variant="filled"
                    />
                </div>
            </ContentWrapper>
        );
    }
}

CampaignMedia.propTypes = {
    classes: PropTypes.object,
    setCampaignMedia: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    affiliatorMedia: state.affiliate.affiliatorMedia
});

const mapDispatchToProps = dispatch => ({
    setCampaignMedia: (param) => dispatch(setCampaignMedia(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CampaignMedia));