import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, getWalletBalanceData } from 'actions';
import PieChart from 'components/Charts/PieChart';
import ReactEcharts from 'echarts-for-react';
import * as H from 'helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        gridSection: {
            marginTop: theme.spacing(1),
        },
        pageTitle: {
            fontSize: 22,
            padding: 32,
        },
        statBox: {
            textAlign: "center",
            marginTop: 8,
            minHeight: 60,
            paddingLeft: 8,
            borderRight: '1px solid #dcdcdc',
        },
        statBoxLast: {
            textAlign: "center",
            marginTop: 8,
            minHeight: 60,
            paddingLeft: 8,
        },
        statTitle: {
            fontSize: 13,
        },
        statValue: {
            fontSize: 15,
            paddingTop: 8,
            fontWeight: 500,
        },
        titleSection: {
            marginTop: theme.spacing(3),
            paddingBottom: 16,
        },
        bottomValue: {
            fontSize: 14,
            paddingBottom: 32,
            fontWeight: 500,
            marginTop: 32,
            marginLeft: 16,
        },
        chartContainer: {
            marginTop: -16,
            width: '100%',
            display: 'block'
        },
        label: {
            marginTop: 8,
            textAlign: 'center',
            fontSize: 12,
        },
        address: {
            paddingBottom: 16,
        }
    }),
);

const gaugeData = [
    {
      value: 20,
      name: 'Perfect',
      title: {
        offsetCenter: ['0%', '-30%']
      },
      detail: {
        valueAnimation: false,
        offsetCenter: ['0%', '-20%']
      }
    },
    {
      value: 40,
      name: 'Good',
      title: {
        offsetCenter: ['0%', '0%']
      },
      detail: {
        valueAnimation: false,
        offsetCenter: ['0%', '10%']
      }
    },
    {
      value: 60,
      name: 'Commonly',
      title: {
        offsetCenter: ['0%', '30%']
      },
      detail: {
        valueAnimation: false,
        offsetCenter: ['0%', '40%']
      }
    }
];

const WalletBalance = ({
    setCurrentPage,
    getWalletBalanceData,
    walletBalanceData,
    // walletBalancePieData,
}) => {
    const classes = useStyles();

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        const game = window.location.pathname.split("/");
        getWalletBalanceData(game[2]);
    }, []);

    const getGaugeData = () => {
        return [
            {
                value: 100,
                name: '所持総額',
                title: {
                    show: false,
                    offsetCenter: ["0%", "-30%"],
                },
                detail: {
                    show: false,
                    valueAnimation: false,
                    offsetCenter: ["0%", "-20%"],
                },
                itemStyle: {
                    color: walletBalanceData[1]["check"][0]["total_amount"] === 0 ? "#d3d3d3" : "#93cb79",
                },
            },
            {
              value: walletBalanceData[1]["check"][0]["in_use"]/walletBalanceData[1]["check"][0]["total_amount"]*100,
              name: '使用中',
              title: {
                offsetCenter: ["0%", "24%"],
                fontSize: 18,
                lineHeight: 14,
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ["0%", "-15%"],
                fontSize: 30,
              },
            }
        ];
    }

    return (
        <>
        <div className={classes.pageTitle}>Wallet Balance</div>
        <Grid container spacing={2} className={classes.gridSection} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6} lg={5}>
                <Paper style={{ padding: 8, marginBottom: 8, borderRadius: 6, minHeight: 400}}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid container direction="row" className={classes.titleSection} justifyContent="center">
                            <Grid item>デポジット口座<br />
                            <div className={classes.label}>{walletBalanceData.length > 0 ? walletBalanceData[0]['deposit'][0]['label'] : ""}</div>
                            </Grid>
                        </Grid>
                        <Grid className={classes.statBoxLast} item xs={12} sm={6} md={4}>
                            <div className={classes.statTitle}>所持総額</div>
                            <div className={classes.statValue}>{walletBalanceData.length > 0
                                ? H.FormatNumStrHelper.formatCommaThousand(walletBalanceData[0]['deposit'][0]['total_amount']) 
                                : "--"} DEP</div>
                        </Grid>
                        <Grid container direction="row" justifyContent="center">
                            <Grid item>
                                <PieChart metric="DEP預入額" data={[]} />
                            </Grid>
                        </Grid>
                        <Grid container direction="column" className={classes.bottomValue} justifyContent="center" alignItems="center">
                            <div className={classes.address}>DEP Address: {
                                walletBalanceData.length > 0 ? walletBalanceData[0]['deposit'][0]['wallet_address'] : "--"}
                            </div>
                            <div>DAT Address: {walletBalanceData.length > 0 
                                ? H.ContentsHelper.getContentsDatAddess(walletBalanceData[0]['deposit'][0]["contents_id"], "deposit")
                                : "--"
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <Grid item xs={12} md={6} lg={5}>
                <Paper style={{ padding: 8, marginBottom: 8, borderRadius: 6}}>
                    <Grid container direction="row" justifyContent="flex-start">
                        <Grid container direction="row" className={classes.titleSection} justifyContent="center">
                            <Grid item>DEAPcheck支払口座<br />
                            <div className={classes.label}>{walletBalanceData.length > 0 ? walletBalanceData[1]['check'][0]['label'] : ""}</div>
                            </Grid>
                        </Grid>
                        <Grid className={classes.statBox} item xs={12} sm={6} md={4}>
                            <div className={classes.statTitle}>所持総額</div>
                            <div className={classes.statValue}>{walletBalanceData.length > 1
                            ? H.FormatNumStrHelper.formatCommaThousand(walletBalanceData[1]['check'][0]['total_amount']) : "--"} DEP</div>
                        </Grid>
                        <Grid className={classes.statBox} item xs={12} sm={6}  md={4}>
                            <div className={classes.statTitle}>出金中</div>
                            <div className={classes.statValue}>{walletBalanceData.length > 1
                            ? H.FormatNumStrHelper.formatCommaThousand(walletBalanceData[1]['check'][0]['in_withdrawal']) : "--"} DEP</div>
                        </Grid>
                        <Grid className={classes.statBoxLast} item xs={12} sm={6}  md={4}>
                            <div className={classes.statTitle}>使用中</div>
                            <div className={classes.statValue}>{walletBalanceData.length > 1
                            ? H.FormatNumStrHelper.formatCommaThousand(walletBalanceData[1]['check'][0]['in_use']) : "--"} DEP</div>
                        </Grid>
                        <Grid container direction="row"  justifyContent="center">
                            <Grid item>
                                {walletBalanceData.length < 2
                                ? <PieChart metric="" data={[]} />
                                :
                                <div className={classes.chartContainer}>
                                <ReactEcharts
                                    option={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        series: [{
                                            type: 'gauge',
                                            startAngle: 90, //90 87
                                            endAngle: -270,
                                            radius: "83%",
                                            pointer: {
                                                show: false
                                            },
                                            progress: {
                                                show: true,
                                                overlap: false,
                                                roundCap: true,
                                                clip: false,
                                                itemStyle: {
                                                borderWidth: 0,
                                                borderColor: '#464646'
                                                },
                                                width: 10,
                                            },
                                            axisLine: {
                                                lineStyle: {
                                                width: 20
                                                }
                                            },
                                            splitLine: {
                                                show: false,
                                                distance: 0,
                                                length: 10
                                            },
                                            axisTick: {
                                                show: false
                                            },
                                            axisLabel: {
                                                show: false,
                                                distance: 50
                                            },
                                            data: getGaugeData(),
                                            title: {
                                                fontSize: 14
                                            },
                                            detail: {
                                                width: 50,
                                                height: 14,
                                                fontSize: 14,
                                                color: 'auto',
                                                formatter: function (value) {
                                                  const label = isNaN(value) ? "--" : value;
                                                  return label === "--" ? "--" : `${label.toFixed(2)} %`;
                                                },
                                              }
                                        }],
                                    }}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{height: "300px", width: "300px"}}
                                />
                                </div>
                                }
                            </Grid>
                        </Grid>
                        <Grid container direction="column" className={classes.bottomValue} justifyContent="center" alignItems="center">
                            <div className={classes.address}>
                                DEP Address: {walletBalanceData.length > 1
                                ? walletBalanceData[1]['check'][0]['wallet_address']
                                : "--"
                                }
                            </div>
                            <div>DAT Address: {walletBalanceData.length > 1
                                ? H.ContentsHelper.getContentsDatAddess(walletBalanceData[1]['check'][0]["contents_id"], "check")
                                : "--"
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        </>
    );
};

WalletBalance.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    walletBalanceData: state.wallet.walletBalanceData,
    //walletBalancePieData: state.wallet.walletBalancePieData,
    pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getWalletBalanceData: (param) => dispatch(getWalletBalanceData(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(WalletBalance));
