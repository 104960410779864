import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import { setPageTopLoader, setPhotoShootDetailsData, setPictreeDataPhotoShootTrend,
  setPictreeDataPhotoDenchuKaisuTrend, setPhotoShootMapData } from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";
import seasonConfig from '../../utils/pictree/SeasonDataConfig';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;

const findSeason = (targetDate) => {
  // Convert the targetDate to a moment object for comparison
  const target = moment(targetDate, "YYYY-MM-DD");

  for (let config of seasonConfig.seasonConfig) {
    const startDate = moment(config.dateRange[0], "YYYY-MM-DD");
    const endDate = moment(config.dateRange[1], "YYYY-MM-DD");

    // Check if the target date falls within the date range
    if (target.isBetween(startDate, endDate, null, '[]')) {
      return typeof config.season !== "number" ? config.season : `Season${config.season}`;
    }
  }
  return null; // If no matching season is found
};

const findGameSpaceId = (seasonName) => {
  const config = seasonConfig.gameSpaceConfig.find(item => item.name === seasonName);
  return config ? config.game_space_id : null;
};

export function* getPictreeDataPhotoShootDetails(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlQueryCountPhotoDenchu = {
      query: `SELECT * FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
      ORDER BY logDate DESC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataPhotoDenchu] = yield all([
     call(getDataFromRDS, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const countPhotoDenchu = JSON.parse(dataPhotoDenchu.body);
    let recordArray = [], eachData = {};
    _.each(countPhotoDenchu, function(data, index) {
        eachData.user = data[2]["stringValue"];
        eachData.asset_id = data[4]["stringValue"];
        eachData.latitude = parseFloat(data[5]["stringValue"]);
        eachData.longitude = parseFloat(data[6]["stringValue"]);
        eachData.parts = data[7]["stringValue"];
        eachData.picCount = data[8]["longValue"];
        eachData.dateTime = moment(data[14]["longValue"]).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A');
        recordArray.push(eachData);
        eachData = {};
    });
    yield put(setPhotoShootDetailsData(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataPhootoShootTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    let sqlQueryDenchuPhotoShootTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(denchuId)
        FROM DenchuPhotoShootDenchuEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryDenchuPhotoShootTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(denchuId) AS denchu_count
        FROM
          DenchuPhotoShootDenchuEvtProd
        WHERE
          CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
          BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY
          HOUR
        ORDER BY
          HOUR ASC;
      `;
    };

    let sqlQueryMhPhotoShootTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT mhId) AS DAU
        FROM DenchuPhotoShootMhEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryMhPhotoShootTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(DISTINCT mhId) AS denchu_count
        FROM
          DenchuPhotoShootMhEvtProd
        WHERE
            CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
            BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY
            HOUR
        ORDER BY
            HOUR ASC;
      `;
    };

    let sqlQueryHhPhotoShootTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT hhId) AS DAU
        FROM DenchuPhotoShootHhEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryHhPhotoShootTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(DISTINCT hhId) AS denchu_count
        FROM
          DenchuPhotoShootHhEvtProd
        WHERE
            CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
            BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY
            HOUR
        ORDER BY
            HOUR ASC;
      `;
    };

    let sqlQueryPhotoShootUniqueTrend = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT uid) AS DAU
        FROM DenchuPhotoShootDenchuEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload === "hour") {
      sqlQueryPhotoShootUniqueTrend.query = `
        SELECT
          DATE_FORMAT(
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'),
              '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(DISTINCT uid) AS uid_count
        FROM
        DenchuPhotoShootDenchuEvtProd
        WHERE
            CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
            BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY
            HOUR
        ORDER BY
            HOUR ASC;
      `;
    };

    const [dataTempDenchuPhotoShootTrend, dataTempMhPhotoShootTrend, dataTempHhPhotoShootTrend, dataTempPhotoShootUniqueTrend] = yield all([
     call(getDataFromRDS, sqlQueryDenchuPhotoShootTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryMhPhotoShootTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryHhPhotoShootTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryPhotoShootUniqueTrend, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const dataDenchuPhotoShootTrend = JSON.parse(dataTempDenchuPhotoShootTrend.body);
    const dataMhPhotoShootTrend = JSON.parse(dataTempMhPhotoShootTrend.body);
    const dataHhPhotoShootTrend = JSON.parse(dataTempHhPhotoShootTrend.body);
    const dataPhotoShootUniqueTrend = JSON.parse(dataTempPhotoShootUniqueTrend.body);

    // Set xAxisDate
    let xAxisDate = [], dataDenchuArray = [], dataMhArray = [], dataHhArray = [], dataUniqueArray = [], recordArray = [];
    const daysArray = action.payload === "day"
      ? H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1])
      : H.FormatDateHelper.getHoursListBetweenDays(rangeTarget[0], rangeTarget[1]);
    _.each(daysArray, function(data) {
        xAxisDate.push(data);
    });

    xAxisDate.forEach(date => {
      const data = _.find(dataDenchuPhotoShootTrend, item => _.get(item, '[0].stringValue') === date);
      if (data) {
        dataDenchuArray.push(_.get(data, '[1].longValue'));
      } else {
        dataDenchuArray.push(0);
      }

      // MH data
      const mhData = _.find(dataMhPhotoShootTrend, item => _.get(item, '[0].stringValue') === date);
      if (mhData) {
        dataMhArray.push(_.get(mhData, '[1].longValue'));
      } else {
        dataMhArray.push(0);
      }

      // HH data
      const hhData = _.find(dataHhPhotoShootTrend, item => _.get(item, '[0].stringValue') === date);
      if (hhData) {
        dataHhArray.push(_.get(hhData, '[1].longValue'));
      } else {
        dataHhArray.push(0);
      }

      // Unique data
      const uniqueData = _.find(dataPhotoShootUniqueTrend, item => _.get(item, '[0].stringValue') === date);
      if (uniqueData) {
        dataUniqueArray.push(_.get(uniqueData, '[1].longValue'));
      } else {
        dataUniqueArray.push(0);
      }
    });

    const photoshootCountData = {
      date: xAxisDate,
      countDenchu: dataDenchuArray,
      countMh: dataMhArray,
      countHh: dataHhArray,
      unique: dataUniqueArray,
    };
    recordArray.push(photoshootCountData);

    yield put(setPictreeDataPhotoShootTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataPhootoShootKaisuTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    // 1st time
    let sqlQueryDenchuKaisu1Trend = {
      // query: `
      //   SELECT
      //     date_only AS date,
      //     SUM(CASE WHEN date_only = '2024-04-13' THEN 1 ELSE 0 END) + SUM(CASE WHEN date_only > '2024-04-13' THEN 1 ELSE 0 END) AS unique_denchuId_count
      //   FROM (
      //     SELECT
      //       DATE(CONVERT_TZ(FROM_UNIXTIME(min_logDate / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
      //       denchuId
      //     FROM (
      //       SELECT
      //       denchuId,
      //       MIN(logDate) AS min_logDate
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //           CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //           AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //     ) AS first_appearance_dates
      //     WHERE DATE(CONVERT_TZ(FROM_UNIXTIME(min_logDate / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '2024-04-13' AND '${rangeTarget[1]}'
      //     GROUP BY date_only, denchuId
      //   ) AS daily_unique_counts
      //   WHERE
      //     DATE(date_only) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY date_only
      //   ORDER BY date_only;
      // `,
      query: `
        SELECT
            DATE(CONVERT_TZ(FROM_UNIXTIME(min_logDate / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
            COUNT(denchuId) AS first_time_appearance_count
        FROM (
            SELECT
                denchuId,
                MIN(logDate) AS min_logDate
            FROM DenchuPhotoShootDenchuEvtProd
            WHERE
                CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
            GROUP BY denchuId
        ) AS first_appearance_dates
        WHERE DATE(CONVERT_TZ(FROM_UNIXTIME(min_logDate / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY date_only
        ORDER BY date_only;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload == "hour") {
      sqlQueryDenchuKaisu1Trend.query = `
        SELECT
          DATE_FORMAT(
            CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'),
            '%Y-%m-%d %H:00'
          ) AS HOUR,
          COUNT(*) AS first_time_appearance_count
        FROM (
            SELECT
                denchuId,
                MIN(logDate) AS first_occurrence_date
            FROM DenchuPhotoShootDenchuEvtProd
            WHERE
              CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
              AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
            GROUP BY denchuId
        ) AS first_occurrences
        WHERE
          DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY HOUR
        ORDER BY HOUR;
      `;
    };

    // 2nd time
    let sqlQueryDenchuKaisu2Trend = {
      // query: `
      //   SELECT
      //       DATE(CONVERT_TZ(FROM_UNIXTIME(second_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
      //       COUNT(*) AS second_time_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE WHEN COUNT(*) = 2 THEN MIN(logDate) ELSE NULL END AS second_occurrence_date
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //     second_occurrence_date IS NOT NULL AND
      //     DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY date_only
      //   ORDER BY date_only;
      // `,
      query: `
        SELECT
            DATE(CONVERT_TZ(FROM_UNIXTIME(second_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
            COUNT(*) AS second_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS second_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 2
        ) AS second_occurrences
        WHERE
            DATE(CONVERT_TZ(FROM_UNIXTIME(second_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY date_only
        ORDER BY date_only;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload == "hour") {
      // sqlQueryDenchuKaisu2Trend.query = `
      //   SELECT
      //       DATE_FORMAT(
      //         CONVERT_TZ(FROM_UNIXTIME(second_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'),
      //         '%Y-%m-%d %H:00'
      //       ) AS HOUR,
      //       COUNT(*) AS second_time_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE WHEN COUNT(*) = 2 THEN MIN(logDate) ELSE NULL END AS second_occurrence_date
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //     second_occurrence_date IS NOT NULL AND
      //     DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY HOUR
      //   ORDER BY HOUR;
      // `;
      sqlQueryDenchuKaisu2Trend.query = `
        SELECT
            DATE_FORMAT(CONVERT_TZ(FROM_UNIXTIME(second_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'), '%Y-%m-%d %H:00') AS hour_only,
            COUNT(*) AS second_time_appearance_count
        FROM (
            SELECT
                denchuId,
                MIN(second_logDate) AS second_occurrence_date
            FROM (
                SELECT
                    dp1.denchuId,
                    dp2.logDate AS second_logDate
                FROM
                    (SELECT
                        denchuId,
                        MIN(logDate) AS first_occurrence_date
                    FROM
                        DenchuPhotoShootDenchuEvtProd
                    WHERE
                        CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                        AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                    GROUP BY
                        denchuId) AS dp1
                JOIN DenchuPhotoShootDenchuEvtProd dp2
                ON dp1.denchuId = dp2.denchuId
                WHERE
                    dp2.logDate > dp1.first_occurrence_date
                GROUP BY
                    dp1.denchuId, dp2.logDate
                HAVING
                    COUNT(dp2.denchuId) = 2
            ) AS second_occurrences
            GROUP BY denchuId
        ) AS occurrences
        WHERE
            DATE(CONVERT_TZ(FROM_UNIXTIME(second_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY hour_only
        ORDER BY hour_only;
      `;
    };

    // 3 time
    const sqlQueryDenchuKaisu3Trend = {
      // query: `
      //   SELECT
      //       DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
      //       COUNT(*) AS third_time_appearance_count
      //   FROM (
      //       SELECT
      //           denchuId,
      //           MIN(logDate) AS first_occurrence_date,
      //           CASE
      //               WHEN COUNT(*) = 3 THEN MIN(logDate)
      //               ELSE NULL
      //           END AS third_occurrence_date
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //     third_occurrence_date IS NOT NULL
      //     AND DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY date_only
      //   ORDER BY date_only;
      // `,
      query: `
        SELECT
            DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
            COUNT(*) AS third_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS third_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 3
        ) AS third_occurrences
        WHERE
          DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY date_only
        ORDER BY date_only;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload == "hour") {
      // sqlQueryDenchuKaisu3Trend.query = `
      //   SELECT
      //       DATE_FORMAT(
      //         CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'),
      //         '%Y-%m-%d %H:00'
      //       ) AS HOUR,
      //       COUNT(*) AS second_time_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) = 3 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS third_occurrence_date
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //   third_occurrence_date IS NOT NULL AND
      //     DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY HOUR
      //   ORDER BY HOUR;
      // `;
      sqlQueryDenchuKaisu3Trend.query = `
        SELECT
            DATE_FORMAT(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'), '%Y-%m-%d %H:00') AS hour_only,
            COUNT(*) AS third_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS third_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 3
        ) AS third_occurrences
        WHERE
            DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY hour_only
        ORDER BY hour_only;
      `;
    };

    // 4 time
    const sqlQueryDenchuKaisu4Trend = {
      // query: `
      //   SELECT
      //       DATE(CONVERT_TZ(FROM_UNIXTIME(fourth_appearance_count / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
      //       COUNT(*) AS fourth_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) = 4 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS fourth_appearance_count
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //     fourth_appearance_count IS NOT NULL
      //     AND DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY date_only
      //   ORDER BY date_only;
      // `,
      query: `
        SELECT
          DATE(CONVERT_TZ(FROM_UNIXTIME(fourth_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
          COUNT(*) AS fourth_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS fourth_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 4
        ) AS fourth_occurrences
        WHERE
          DATE(CONVERT_TZ(FROM_UNIXTIME(fourth_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY date_only
        ORDER BY date_only;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload == "hour") {
      // sqlQueryDenchuKaisu4Trend.query = `
      //   SELECT
      //       DATE_FORMAT(
      //         CONVERT_TZ(FROM_UNIXTIME(fourth_appearance_count / 1000), 'UTC', 'Asia/Tokyo'),
      //         '%Y-%m-%d %H:00'
      //       ) AS HOUR,
      //       COUNT(*) AS second_time_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) = 4 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS fourth_appearance_count
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //   fourth_appearance_count IS NOT NULL AND
      //     DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY HOUR
      //   ORDER BY HOUR;
      // `;
      sqlQueryDenchuKaisu4Trend.query = `
        SELECT
            DATE_FORMAT(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'), '%Y-%m-%d %H:00') AS hour_only,
            COUNT(*) AS third_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS third_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 4
        ) AS third_occurrences
        WHERE
            DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY hour_only
        ORDER BY hour_only;
      `;
    };

    // 5 time
    const sqlQueryDenchuKaisu5Trend = {
      // query: `
      //   SELECT
      //       DATE(CONVERT_TZ(FROM_UNIXTIME(appearance_count / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
      //       COUNT(*) AS appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) = 5 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS appearance_count
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //     appearance_count IS NOT NULL
      //     AND DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY date_only
      //   ORDER BY date_only;
      // `,
      query: `
        SELECT
          DATE(CONVERT_TZ(FROM_UNIXTIME(nth_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
          COUNT(*) AS nth_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS nth_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 5
        ) AS fourth_occurrences
        WHERE
          DATE(CONVERT_TZ(FROM_UNIXTIME(nth_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY date_only
        ORDER BY date_only;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload == "hour") {
      // sqlQueryDenchuKaisu5Trend.query = `
      //   SELECT
      //       DATE_FORMAT(
      //         CONVERT_TZ(FROM_UNIXTIME(appearance_count / 1000), 'UTC', 'Asia/Tokyo'),
      //         '%Y-%m-%d %H:00'
      //       ) AS HOUR,
      //       COUNT(*) AS second_time_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) = 4 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS appearance_count
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //   appearance_count IS NOT NULL AND
      //     DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY HOUR
      //   ORDER BY HOUR;
      // `;
      sqlQueryDenchuKaisu5Trend.query = `
        SELECT
            DATE_FORMAT(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'), '%Y-%m-%d %H:00') AS hour_only,
            COUNT(*) AS third_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS third_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num = 5
        ) AS third_occurrences
        WHERE
            DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY hour_only
        ORDER BY hour_only;
      `;
    };

    // 6+ times
    const sqlQueryDenchuKaisu6Trend = {
      // query: `
      //   SELECT
      //       DATE(CONVERT_TZ(FROM_UNIXTIME(appearance_count / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
      //       COUNT(*) AS appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) > 5 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS appearance_count
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //     appearance_count IS NOT NULL
      //     AND DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY date_only
      //   ORDER BY date_only;
      // `,
      query: `
        SELECT
          DATE(CONVERT_TZ(FROM_UNIXTIME(nth_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) AS date_only,
          COUNT(*) AS nth_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS nth_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num > 5
        ) AS fourth_occurrences
        WHERE
          DATE(CONVERT_TZ(FROM_UNIXTIME(nth_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY date_only
        ORDER BY date_only;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    if (action.payload == "hour") {
      // sqlQueryDenchuKaisu6Trend.query = `
      //   SELECT
      //       DATE_FORMAT(
      //         CONVERT_TZ(FROM_UNIXTIME(appearance_count / 1000), 'UTC', 'Asia/Tokyo'),
      //         '%Y-%m-%d %H:00'
      //       ) AS HOUR,
      //       COUNT(*) AS second_time_appearance_count
      //   FROM (
      //       SELECT
      //         denchuId,
      //         MIN(logDate) AS first_occurrence_date,
      //         CASE
      //             WHEN COUNT(*) > 5 THEN MIN(logDate)
      //             ELSE NULL
      //         END AS appearance_count
      //       FROM DenchuPhotoShootDenchuEvtProd
      //       WHERE
      //         CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
      //         AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
      //       GROUP BY denchuId
      //   ) AS occurrences
      //   WHERE
      //   appearance_count IS NOT NULL AND
      //     DATE(CONVERT_TZ(FROM_UNIXTIME(first_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
      //   GROUP BY HOUR
      //   ORDER BY HOUR;
      // `;
      sqlQueryDenchuKaisu6Trend.query = `
        SELECT
            DATE_FORMAT(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo'), '%Y-%m-%d %H:00') AS hour_only,
            COUNT(*) AS third_time_appearance_count
        FROM (
            SELECT
                denchuId,
                logDate AS third_occurrence_date
            FROM (
                SELECT
                    denchuId,
                    logDate,
                    @row_num := IF(@current_denchu = denchuId, @row_num + 1, 1) AS row_num,
                    @current_denchu := denchuId
                FROM
                    DenchuPhotoShootDenchuEvtProd,
                    (SELECT @row_num := 0, @current_denchu := '') AS vars
                WHERE
                    CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
                    AND CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') <= '${rangeTarget[1]} 23:59:59'
                ORDER BY
                    denchuId, logDate
            ) AS ranked
            WHERE
                row_num > 5
        ) AS third_occurrences
        WHERE
            DATE(CONVERT_TZ(FROM_UNIXTIME(third_occurrence_date / 1000), 'UTC', 'Asia/Tokyo')) BETWEEN '${rangeTarget[0]}' AND '${rangeTarget[1]}'
        GROUP BY hour_only
        ORDER BY hour_only;
      `;
    };

    const [dataTempDenchuKaisu1, dataTempDenchuKaisu2, dataTempDenchuKaisu3, dataTempDenchuKaisu4,
           dataTempDenchuKaisu5, dataTempDenchuKaisu6,] = yield all([
      call(getDataFromRDS, sqlQueryDenchuKaisu1Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
      call(getDataFromRDS, sqlQueryDenchuKaisu2Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
      call(getDataFromRDS, sqlQueryDenchuKaisu3Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
      call(getDataFromRDS, sqlQueryDenchuKaisu4Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
      call(getDataFromRDS, sqlQueryDenchuKaisu5Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
      call(getDataFromRDS, sqlQueryDenchuKaisu6Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
     ]);
     const dataDenchuKaisuTrend1 = JSON.parse(dataTempDenchuKaisu1.body);
     const dataDenchuKaisuTrend2 = JSON.parse(dataTempDenchuKaisu2.body);
     const dataDenchuKaisuTrend3 = JSON.parse(dataTempDenchuKaisu3.body);
     const dataDenchuKaisuTrend4 = JSON.parse(dataTempDenchuKaisu4.body);
     const dataDenchuKaisuTrend5 = JSON.parse(dataTempDenchuKaisu5.body);
     const dataDenchuKaisuTrend6 = JSON.parse(dataTempDenchuKaisu6.body);

    // aggregate data
    // Set xAxisDate
    let xAxisDate = [], dataFirstArray = [], dataTwoArray = [], dataThreeArray = [], dataFourArray = [], dataFiveArray = [], dataSixArray = [];
    const daysArray = action.payload === "day"
      ? H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1])
      : H.FormatDateHelper.getHoursListBetweenDays(rangeTarget[0], rangeTarget[1]);
    _.each(daysArray, function(data) {
        xAxisDate.push(data);
    });

    xAxisDate.forEach(date => {
      // 1st time
      const data1st = _.find(dataDenchuKaisuTrend1, item => _.get(item, '[0].stringValue') === date);
      if (data1st) {
        dataFirstArray.push(_.get(data1st, '[1].longValue'));
      } else {
        dataFirstArray.push(0);
      }

      // 2 times
      const dataTwo = _.find(dataDenchuKaisuTrend2, item => _.get(item, '[0].stringValue') === date);
      if (dataTwo) {
        dataTwoArray.push(_.get(dataTwo, '[1].longValue'));
      } else {
        dataTwoArray.push(0);
      }

      // 3 times
      const dataThree = _.find(dataDenchuKaisuTrend3, item => _.get(item, '[0].stringValue') === date);
      if (dataThree) {
        dataThreeArray.push(_.get(dataThree, '[1].longValue'));
      } else {
        dataThreeArray.push(0);
      }

      // 4 times
      const dataFour = _.find(dataDenchuKaisuTrend4, item => _.get(item, '[0].stringValue') === date);
      if (dataFour) {
        dataFourArray.push(_.get(dataFour, '[1].longValue'));
      } else {
        dataFourArray.push(0);
      }

      // 5 times
      const dataFive = _.find(dataDenchuKaisuTrend5, item => _.get(item, '[0].stringValue') === date);
      if (dataFive) {
        dataFiveArray.push(_.get(dataFive, '[1].longValue'));
      } else {
        dataFiveArray.push(0);
      }

      // 6 times
      const dataSix = _.find(dataDenchuKaisuTrend6, item => _.get(item, '[0].stringValue') === date);
      if (dataSix) {
        dataSixArray.push(_.get(dataSix, '[1].longValue'));
      } else {
        dataSixArray.push(0);
      }
    });

    const photoshootCountData = {
      date: xAxisDate,
      series: [
        dataFirstArray,
        dataTwoArray,
        dataThreeArray,
        dataFourArray,
        dataFiveArray,
        dataSixArray
      ],
    };
    const recordArray = [photoshootCountData];
    yield put(setPictreeDataPhotoDenchuKaisuTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataPhotoShootMap(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const seasonName = findSeason(rangeTarget[1]);

    let sqlCountMapData = {
      query: `
        SELECT COUNT(*)
        FROM DenchuPhotoShootDenchuEvtProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59';`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataPhotoCount] = yield all([
      call(getDataFromRDS, sqlCountMapData, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const countPhoto = JSON.parse(dataPhotoCount.body);

    let recordArray = [], eachData = {};

    if (action.payload.filter === "taken") {
      const LIMIT = 1500;
      let offset = 0;

      while (offset < countPhoto[0][0]["longValue"]) {
        let sqlQueryCountPhotoDenchu = {
          query: `
          SELECT *
          FROM DenchuPhotoShootDenchuEvtProd
          WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          ORDER BY logDate DESC LIMIT ${LIMIT} OFFSET ${offset};`,
          database: "KpiDashboard",
          type: "list",
        };

        const [dataPhotoDenchu] = yield all([
          call(getDataFromRDS, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        const countPhotoDenchu = JSON.parse(dataPhotoDenchu.body);
        console.log("LOG data countPhotoDenchu:", countPhotoDenchu.length);
        _.each(countPhotoDenchu, function (data, index) {
          eachData.user = data[2]["stringValue"];
          eachData.asset_id = data[4]["stringValue"];
          eachData.latitude = parseFloat(data[5]["stringValue"]);
          eachData.longitude = parseFloat(data[6]["stringValue"]);
          eachData.parts = data[7]["stringValue"];
          eachData.picCount = data[8]["longValue"];
          eachData.dateTime = moment(data[14]["longValue"]).tz("Asia/Tokyo").format('YYYY/MM/DD hh:mm A');
          recordArray.push(eachData);
          eachData = {};
        });

        offset += LIMIT;
      }

    } else if (action.payload.filter === "untaken") {
      const gameSpaceId = findGameSpaceId(seasonName);
      const seasonId = gameSpaceId; //'cluqif2kh0001bkru8jw2dy2p';

      let sqlQueryCountPhotoDenchu = {
        query: `
          SELECT *
          FROM power_asset AS pa
          INNER JOIN power_asset_detail_of_tepco AS pad
              ON pa.id = pad.power_asset_id
          WHERE pa.id IN (
              SELECT power_asset_id
              FROM power_asset_state
              WHERE game_space_id = '${seasonId}'
              AND power_asset_id NOT IN (
                  SELECT DISTINCT power_asset_id
                  FROM review_request
                  WHERE game_space_id = '${seasonId}'
                  AND status = 'APPROVED'
              )
          );
        `,
        database: "KpiDashboard",
        type: "list",
      };

      const [tempMapUntaken] = yield all([
        call(getDataFromSxi, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      ]);

      _.each(tempMapUntaken, function (data, index) {
        eachData.user = "--";
        eachData.asset_id = data["power_asset_id"];
        eachData.latitude = parseFloat(data["latitude"]);
        eachData.longitude = parseFloat(data["longitude"]);
        eachData.parts = "0";
        eachData.picCount = 0;
        eachData.dateTime = "n/a";
        recordArray.push(eachData);
        eachData = {};
      });
    }

    yield put(setPhotoShootMapData(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_PHOTOSHOOT_DETAILS,getPictreeDataPhotoShootDetails);
  yield takeEvery(PICTREE.GET_PHOTOSHOOT_TREND, getPictreeDataPhootoShootTrend);
  yield takeEvery(PICTREE.GET_PHOTOSHOOT_DENCHU_KAISU_TREND, getPictreeDataPhootoShootKaisuTrend);
  yield takeEvery(PICTREE.GET_PHOTOSHOOT_MAP_DATA,getPictreeDataPhotoShootMap);
}
