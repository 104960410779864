import React, { useState, useEffect } from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Autocomplete } from '@material-ui/lab';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Checkbox, TextField, Button, Grid, Chip, Box, Collapse, IconButton, Typography, Paper } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getCampaingsByGame, setGameSelected, setCampaignSelected } from 'actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) =>
    createStyles({
        calendarWrapper: {
            paddingLeft: 8,
        },
        root: {
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        filterApplyBtn: {
            position: "fixed",
            bottom: theme.spacing(5),
            right: theme.spacing(6),
        },
        filterOption: {
            fontSize: 13
        },
    }),
);

const UsersByAdFilter = ({
    getCampaingsByGame,
    gameOptionLoader,
    campaignOption,
    setGameSelected,
    setCampaignSelected,
}) => {
    const classes = useStyles();
    const [applyBtnState, setApplyBtnState] = useState(true);
    const [game, setGame] = useState({name:""});
    const [campaignName, setCampaignName] = useState({name:""});
    const [gameOptions, setGameOptions] = useState(
        [
            { name: 'All', value: 'all' },
            { name: 'CookinBurger', value: 'cookinburger' },
            { name: 'DragonRamen', value: 'dragonramen' },
            { name: 'GraffitiRacer', value: 'graffiti' },
            { name: 'JobTribes', value: 'jobtribes' },
            { name: "Lucky Farmer", value:"luckyfarmer"},
        ]
    );
    const [byPmid, setByPmid] = useState({name:""});

    useEffect(() => {

    }, []);

    const handleChangeOption = (data) => {
        // let optionSelectedArray = [];
        // _.forEach(data, function(item, i) {
        //     optionSelectedArray.push(item.value)
        // });
        // setNftDepTrendOptionSelected(data);
        setApplyBtnState(false);
    }

    const handleInputChange = (data, type) => {
        switch(type) {
            case "game":
                setGame({name:data});
                getCampaingsByGame(data);
                setGameSelected(data);
                break;
            case "campaign":
                setCampaignName({name:data});
                let rid = "";
                if (data === "All") {
                    rid = "All"
                } else {
                    const ridSplit = data.split(" | ");
                    rid = ridSplit[1];
                }
                setCampaignSelected(rid);
                break;
            // case "pmid":
            //     setByPmid({name:data});
            //     break;
            default:
        }
    }

    // const applyFilter = () => {
    //     console.log("HEYHEY Apply Filter");
    // }

    return (
        <div className={classes.root}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <Autocomplete
                        size="small"
                        id="select-asset-id"
                        value={game}
                        options={gameOptions}
                        disableClearable
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            {/* <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            /> */}
                            {option.name}
                            </React.Fragment>
                        )}
                        // onChange={(e, newValue) => {
                        //     handleChangeOption(newValue);
                        // }}
                        onInputChange={(e, newValue) => {
                            handleInputChange(newValue, 'game');
                        }}
                        style={{ minWidth: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Game" placeholder={"All"} />
                        )}
                        className={classes.autoSelect}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        size="small"
                        id="select-asset"
                        value={campaignName}
                        options={campaignOption}
                        //disableCloseOnSelect
                        disableClearable
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            {/* <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            /> */}
                            <div className={classes.filterOption}>{option.name}</div>
                            </React.Fragment>
                        )}
                        onChange={(e, newValue) => {
                            handleChangeOption(newValue);
                        }}
                        onInputChange={(e, newValue) => {
                            handleInputChange(newValue, 'campaign');
                        }}
                        style={{ minWidth: 300 }}
                        renderInput={(params) => (
                            <TextField
                                {...params} variant="standard" label="Campaign"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <>
                                        {gameOptionLoader ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                    ),
                                }}
                                placeholder={"All"}
                            />
                        )}
                    />
                </Grid>
                {/* <Grid item>
                    <Autocomplete
                        size="small"
                        id="select-pmid"
                        value={byPmid}
                        options={assetOptions}
                        disableCloseOnSelect
                        disableClearable
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        onChange={(e, newValue) => {
                            handleChangeOption(newValue);
                        }}
                        onInputChange={(e, newValue) => {
                            handleInputChange(newValue, 'pmid');
                        }}
                        style={{ minWidth: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="PMID" />
                        )}
                        className={classes.filterOption}
                    />
                </Grid> */}
                {/* <Grid item>
                    <Button 
                        //disabled={btnStateDataFetch} 
                        className={classes.filterApplyBtn} 
                        variant="contained" 
                        color="default" 
                        onClick={() => applyFilter()}>
                        UPDATE
                    </Button>
                </Grid> */}
            </Grid>
        </div>
    );
}

UsersByAdFilter.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    gameOptionLoader: state.data.gameOptionLoader,
    campaignOption: state.data.campaignOption,
});

const mapDispatchToProps = (dispatch) => ({
    getCampaingsByGame: (param) => dispatch(getCampaingsByGame(param)),
    setGameSelected: (param) => dispatch(setGameSelected(param)),
    setCampaignSelected: (param) => dispatch(setCampaignSelected(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(UsersByAdFilter));
