import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, TextField, Button, Grid, Chip, Box, IconButton, Typography, Paper } from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, getStakingStatData } from 'actions';
import PieChart from 'components/Charts/PieChart';
import LocalCalendar from 'components/Calendar/LocalCalendar';
import * as V from 'styles/variables';
import * as H from 'helper';

const Staking = ({
    setCurrentPage,
    getStakingStatData,
    pageTopLoader,
    stakingStatData,
}) => {
    const classes = useStyles();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [defaultDateRange, setDefaultDateRagne] = useState([
        moment().tz("Asia/Singapore").subtract(6, 'days').format('YYYY/MM/DD'),
        moment().tz("Asia/Singapore").format('YYYY/MM/DD')
    ]);

    const handleChangeOption = (data) => {
        setBtnState(false);
    }

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        getStakingStatData();
    }, []);

    const setRange = (newValue) => {
        const startDate = moment(newValue[0]).format('YYYY-MM-DD');
        const endDate = moment(newValue[1]).format('YYYY-MM-DD');
        setBtnState(false);
    }

    const showData = (value) => {
        if (value === "--") {
            return value;
        }
        return H.FormatNumStrHelper.formatCommaThousand(value);
    }

    const fetchData = () => {
        //getNftAssetHistoryData(pageNumber);
        setBtnState(!btnStateDataFetch);
    }

    return (
        <>
        <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={10} lg={6}>
                <Grid container spacing={0} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        {/* <Autocomplete
                            freeSolo
                            disablePortal
                            limitTags={3}
                            size="small"
                            id="select-game"
                            value={assetName}
                            options={assetOptions}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                                </React.Fragment>
                            )}
                            onChange={(e, newValue) => {
                                handleChangeOption(newValue);
                            }}
                            onInputChange={(e, newValue) => {
                                handleInputChange(newValue, 'asset_name');
                            }}
                            style={{ minWidth: 400 }}
                            renderInput={(params) => (
                                <TextField {...params} label="PMID検索" placeholder={""} />
                            )}
                            className={classes.autoSelect}
                        /> */}
                    </Grid>
                    <Grid item>
                        {/* <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="center">
                            <Grid item>
                                <LocalCalendar
                                    setRange={setRange}
                                    setBtnState={setBtnState}
                                    defaultRange={defaultDateRange}
                                    newRange={[
                                        txHistoryDataFilter[0]['dateRange'][0],
                                        txHistoryDataFilter[0]['dateRange'][1],
                                    ]}
                                />
                            </Grid>
                            <Grid item>
                                <Button 
                                    //disabled={btnStateDataFetch} 
                                    className={classes.dataBtn} 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => fetchData()}>
                                    GO
                                </Button>
                            </Grid>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={10} lg={6}>
                    <div className={classes.pageTitle}>ステーキング</div>
                    <Paper style={{ padding: 8, marginBottom: 8, borderRadius: 6}}>
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <div>通算データ</div>
                            </Grid>
                            <Grid container direction="row" className={classes.titleSection} justifyContent="center">
                                <Grid item>DEP預入額</Grid>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4}>
                                <div className={classes.statTitle}>1ヶ月: 5.576%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 0
                                    ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[0]['dep']) 
                                    : "--"} DEP</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4}>
                                <div className={classes.statTitle}>3ヶ月: 8.523%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 0 
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[1]['dep']) : "--"} DEP</div>
                            </Grid>
                            <Grid className={classes.statBoxLast} item xs={12} sm={6}  md={4}>
                                <div className={classes.statTitle}>6ヶ月: 10.34%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 0
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[2]['dep']) : "--"} DEP</div>
                            </Grid>
                            <Grid container direction="row" className={classes.pieSection}  justifyContent="center">
                                <Grid item><PieChart metric="DEP預入額" data={stakingStatData} /></Grid>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="flex-start">
                            <Grid container direction="row" className={classes.titleSection} justifyContent="center">
                                <Grid item>預け入れPMID数</Grid>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4}>
                                <div className={classes.statTitle}>1ヶ月: 5.576%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 1
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[0]['count']) : "--"} </div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4}>
                                <div className={classes.statTitle}>3ヶ月: 8.523%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 1
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[1]['count']) : "--"}</div>
                            </Grid>
                            <Grid className={classes.statBoxLast} item xs={12} sm={6}  md={4}>
                                <div className={classes.statTitle}>6ヶ月: 10.34%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 1
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[2]['count']) : "--"}</div>
                            </Grid>
                            <Grid container direction="row" className={classes.pieSection}  justifyContent="center">
                                <Grid item><PieChart metric="預け入れPMID数" data={stakingStatData} /></Grid>
                            </Grid>
                        </Grid>

                        <Grid container direction="row" justifyContent="flex-start">
                            <Grid container direction="row" className={classes.titleSection} justifyContent="center">
                                <Grid item>支払利息総額</Grid>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4}>
                                <div className={classes.statTitle}>1ヶ月: 5.576%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 1
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[0]['paid']) : "--"} DEP</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4}>
                                <div className={classes.statTitle}>3ヶ月: 8.523%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 1
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[1]['paid']) : "--"} DEP</div>
                            </Grid>
                            <Grid className={classes.statBoxLast} item xs={12} sm={6}  md={4}>
                                <div className={classes.statTitle}>6ヶ月: 10.34%</div>
                                <div className={classes.statValue}>{stakingStatData.length > 1
                                ? H.FormatNumStrHelper.formatCommaThousand(stakingStatData[2]['paid']) : "--"} DEP</div>
                            </Grid>
                            <Grid container direction="row" className={classes.pieSection}  justifyContent="center">
                                <Grid item><PieChart metric="支払利息総額" data={stakingStatData} /></Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </Grid>
        </>
    );
};

Staking.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    stakingTotalData: state.staking.stakingTotalData,
    stakingStatData: state.staking.stakingStatData,
    pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getStakingStatData: (param) => dispatch(getStakingStatData(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Staking));

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& > *': {
              //borderBottom: 'unset',
            },
        },
        dataBtn: {
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        pageTitle: {
            fontSize: 22,
            paddingBottom: theme.spacing(1),
        },
        statBox: {
            textAlign: "center",
            marginTop: 8,
            minHeight: 60,
            paddingLeft: 8,
            borderRight: '1px solid #dcdcdc',
        },
        statBoxLast: {
            textAlign: "center",
            marginTop: 8,
            minHeight: 60,
            paddingLeft: 8,
        },
        pieSection: {
            marginTop: theme.spacing(3),
            paddingBottom: 40,
        },
        statTitle: {
            fontSize: 13,
        },
        statValue: {
            fontSize: 15,
            paddingTop: 8,
            fontWeight: 500,
        },
        titleSection: {
            marginTop: theme.spacing(3),
            paddingBottom: 16,
        },
    }),
);
