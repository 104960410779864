import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Grid, IconButton, Chip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import * as C from "components";
import * as H from "helper";
import TimeLineIcon from "@material-ui/icons/Timeline";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  setCurrentPage,
  getA2ECampaigns,
  setA2ECampaigns,
  setA2ECampaignSelect,
} from "actions";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        filterPaper: {
          width: "500px", // フィルターダイアログの幅を500pxに設定
        },
      },
    },
  });

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      position: "fixed",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      width: "100%",
    },
    alignLeft: {
      lineHeight: "18px",
      textAlign: "left",
    },
    summaryCenter: {
      textAlign: "center",
    },
    cvrHead: {
      textAlign: "center",
      fontSize: 12,
      fontWeight: "normal",
      borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    flexItemLeftBox: {
      marginTop: 28,
      alignSelf: "center",
      paddingLeft: 8,
      paddingRight: 8,
      borderRight: "1px solid #dcdcdc",
    },
  })
);

const CampaignList = ({
  setCurrentPage,
  tableRowSelected,
  campaigns,
  getA2ECampaigns,
  setA2ECampaignSelect,
}) => {
  const classes = useStyles();
  const [selectedCampaign, setSelectedCampaign] = useState([]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getA2ECampaigns();
  }, []);

  const getReportUrl = (cid) => {
    let url = "/a2e/report/" + cid;
    return url;
  };

  const getCampaignData = (data) => {
    if (data.length > 0) {
      let allDataArray = [],
        eachDataArray = [];
      for (let i = 0; i < data.length; i++) {
        eachDataArray.push(data[i]["status"]);
        eachDataArray.push(data[i]["clientName"]);
        eachDataArray.push(data[i]["name"]);
        eachDataArray.push(data[i]["campaignId"]);
        eachDataArray.push(data[i]["startDate"]);
        eachDataArray.push(data[i]["endDate"]);
        eachDataArray.push(data[i]["duration"]);
        eachDataArray.push(data[i]["remainDays"]);
        // eachDataArray.push(data[i]["imp"]);
        // eachDataArray.push(data[i]["viewNum"]);
        // eachDataArray.push(data[i]["viewRatio"]);
        // eachDataArray.push(data[i]["viewCompleteNum"]);
        // eachDataArray.push(data[i]["viewCompleteRatio"]);
        // eachDataArray.push(data[i]["quizAnsNum"]);
        // eachDataArray.push(data[i]["quizCorrectNum"]);
        // eachDataArray.push(data[i]["quizCorrectRatio"]);
        eachDataArray.push(data[i]["campaignId"]);
        // eachDataArray.push(
        //   H.FormatDateHelper.dateFormatter(data[i]["Timestamp"])
        // );
        allDataArray.push(eachDataArray);
        eachDataArray = [];
      }
      return allDataArray;
    }
  };

  const getColumns = () => {
    let returningColumn = columns;
    if (
      localStorage.getItem("access_key") !== process.env.REACT_APP_SUPERUSER
    ) {
      returningColumn.pop();
    }
    return returningColumn;
  };

  const options = {
    filterType: "dropdown",
    filter: true,
    pagination: true,
    search: true,
    print: false,
    download: true,
    downloadOptions: {
      filename: "キャンペーン一括データ",
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let cleanData = [],
        tempArray = [],
        tempObj = {};
      for (let i = 0; i < data.length; i++) {
        tempArray = [];
        for (let j = 0; j < data[i]["data"].length; j++) {
          if (j === 2) {
            tempArray.push(data[i]["data"][j]["props"]["label"]);
          } else if (j !== 11 && j !== 12) {
            tempArray.push(data[i]["data"][j]);
          }
        }
        tempObj.index = i;
        tempObj.data = tempArray;
        cleanData.push(tempObj);
        tempObj = {};
      }
      return "\uFEFF" + buildHead(columns) + buildBody(cleanData);
    },
    selectableRows: false,
    rowsPerPage: 10,
    rowsSelected: tableRowSelected,
    textLabels: {
      body: {
        noMatch: "データを取得中です。",
        toolTip: "",
      },
      toolbar: {
        search: "検索",
        viewColumns: "列の表示",
        downloadCsv: "CSVダウンロード",
        filterTable: "テーブルのフィルター",
      },
      viewColumns: {
        title: "表示する列",
        titleAria: "テーブルの列の表示/非表示",
      },
      pagination: {
        next: "次のページ",
        previous: "前のページ",
        rowsPerPage: "表示件数",
      },
      filter: {
        all: "全て",
        title: "フィルター",
        reset: "リセット",
      },
      selectedRows: {
        text: "レコードを選択",
        delete: "削除",
        deleteAria: "選択したレコードを削除する",
      },
    },
    rowsPerPageOptions: [10, 50, 100],
  };

  let columns = [
    {
      name: "ステータス",
      options: {
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Chip
              size="small"
              label={value}
              style={{
                backgroundColor:
                  value.indexOf("Active") !== -1 ? "#aeeab2" : "#f4a915",
              }}
            />
          );
        },
      },
    },
    {
      name: "広告主",
      options: {
        filter: true,
        customFilterListRender: (v) => `広告主: ${v}`,
        customBodyRender: (value) => {
          return <div className={classes.alignLeft}>{value}</div>;
        },
      },
    },
    {
      name: "キャンペーン名",
      options: {
        filter: false,
        customFilterListRender: (v) => `名前: ${v}`,
        customBodyRender: (value) => {
          return <div className={classes.alignLeft}>{value}</div>;
        },
      },
    },
    {
      name: "キャンペーンID",
      options: {
        filter: false,
        customFilterListRender: (v) => `名前: ${v}`,
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "開始日",
      options: {
        filter: false,
        customFilterListRender: (v) => `名前: ${v}`,
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "終了日",
      options: {
        filter: false,
        customFilterListRender: (v) => `名前: ${v}`,
        customBodyRender: (value) => {
          return <div>{value}</div>;
        },
      },
    },
    {
      name: "全日数",
      options: {
        filter: false,
        customFilterListRender: (v) => `名前: ${v}`,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{value}日</div>;
        },
      },
    },
    {
      name: "残り日数",
      options: {
        filter: false,
        customFilterListRender: (v) => `名前: ${v}`,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <div>{value}日</div>;
        },
      },
    },
    // {
    //   name: "インプレッション",
    //   options: {
    //     filter: true,
    //     customFilterListOptions: { render: (v) => `タイプ: ${v}` },
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <div className={classes.summaryCenter}>{value}</div>;
    //     },
    //   },
    // },
    // {
    //   name: "視聴数",
    //   options: {
    //     filter: false,
    //     customFilterListRender: (v) => `Destinations: ${v}`,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return (
    //         <div className={classes.summaryCenter}>
    //           {H.FormatNumStrHelper.formatCommaThousand(value)}
    //         </div>
    //       );
    //     },
    //   },
    // },
    // {
    //   name: "視聴率",
    //   options: {
    //     filter: false,
    //     customFilterListRender: (v) => `Destinations: ${v}`,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       let renderValue =
    //         value !== "--"
    //           ? H.FormatNumStrHelper.formatCommaThousand(value)
    //           : "--";
    //       return <div className={classes.summaryCenter}>{renderValue}</div>;
    //     },
    //   },
    // },
    // {
    //   name: "視聴完了数",
    //   options: {
    //     filter: false,
    //     customFilterListRender: (v) => `Destinations: ${v}`,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <div className={classes.summaryCenter}>{value}</div>;
    //     },
    //   },
    // },
    // {
    //   name: "視聴完了率",
    //   options: {
    //     filter: false,
    //     customFilterListRender: (v) => `CVR: ${v}`,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       value = value === "Infinity" ? "--" : value;
    //       return <div className={classes.summaryCenter}>{value}</div>;
    //     },
    //   },
    // },
    // {
    //   name: "クイズ回答数",
    //   options: {
    //     filter: false,
    //     customFilterListRender: (v) => `CVR: ${v}`,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return <div className={classes.summaryCenter}>{value}</div>;
    //     },
    //   },
    // },
    // {
    //   name: "クイズ正解数",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value) => {
    //       return <div className={classes.summaryCenter}>{value}</div>;
    //     },
    //   },
    // },
    // {
    //   name: "正答率",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value) => {
    //       return <div className={classes.summaryCenter}>{value}</div>;
    //     },
    //   },
    // },
    {
      name: "レポート",
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              to={getReportUrl(tableMeta.rowData[tableMeta.rowData.length - 1])}
              target="_blank"
            >
              <IconButton aria-label="詳細">
                <TimeLineIcon fontSize="small" />
              </IconButton>
            </Link>
          );
        },
      },
    },
  ];

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MUIDataTable
              title={`登録キャンペーン：${campaigns.length}`}
              data={getCampaignData(campaigns)}
              columns={getColumns()}
              options={options}
            />
          </Grid>
        </Grid>
      </C.ContentWrapper>
    </Fragment>
  );
};

CampaignList.propTypes = {
  classes: PropTypes.object,
  campaigns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

const mapStateToProps = (state) => ({
  tableRowSelected: state.page.tableRowSelected,
  campaigns: state.a2e.campaigns,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getA2ECampaigns: () => dispatch(getA2ECampaigns()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
