import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ChartStyle from 'styles/ChartStyle';
import { chartLineWidth } from 'styles/variables';
import Label from 'utils/Label';
import ChartConfig from 'utils/ChartConfig';

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        marginTop: 16,
        marginLeft: 16,
        width: '100%',
        display: 'block'
    }
}));

const LineChartActiveUsers = ({
    legend,
    lblSaveImage,
    xAisData,
    seriesData,
    chartType,
}) => {
    const classes = useStyles();
    const [seriesType, setSeriesType] = useState(chartType);
    const [chartData, setChartData] = useState([]);
    const [color, setColorArray] = useState([]);
    const [stackState, setStackState] = useState('');
    const [zoomStart, setZoomStart] = useState(0);
    const [zoomEnd, setZoomEnd] = useState(100);

    useEffect(() => {
        echarts.registerTheme('BarChartTheme', ChartStyle);
    }, []);

    useEffect(() => {
        let eachChartSeries = {}, seriesDataArray = [], colorArray = [];
        _.forOwn(seriesData, function(value, key) {
            let gameName = value.id;
            colorArray.push(ChartConfig.chartColorByGame[value.id]);
            _.forOwn(value, function(value2, key2) {
                if (key2 === 'data') {
                    eachChartSeries.name = Label.chartSeriesName[gameName];
                    eachChartSeries.type = seriesType;
                    eachChartSeries.data = value2;
                    eachChartSeries.lineStyle = {width: chartLineWidth};
                    eachChartSeries.symbolSize = seriesData[0]['date'].length > 60 ? 4 : 8;
                    //eachChartSeries.showSymbol = seriesData[0]['date'].length > 60 ? false : true;
                    eachChartSeries.barGap = 0;
                    eachChartSeries.barMaxWidth = 20;
                    seriesDataArray.push(eachChartSeries);
                    eachChartSeries = {};
                }
            });
        });
        setChartData(seriesDataArray);
        setColorArray(colorArray);
    }, [seriesData, seriesType]);

    const magicTypeChanged = (param) => {
        if (param.currentType === 'stack') {
            setStackState('__ec_magicType_stack__');
        } else if (param.currentType === 'tiled') {
            setStackState(null);
        } else {
            setSeriesType(param.currentType);
        }
    }

    const dataZoomChanged = (param) => {
        setZoomStart(param.batch[0]['start']);
        setZoomEnd(param.batch[0]['end']);
    }

    const onEvents = {
        'magictypechanged': magicTypeChanged,
        'datazoom': dataZoomChanged,
    }

    return (
        <div className={classes.chartContainer}>
            <ReactEcharts
                option={{
                    tooltip: {
                        trigger: "axis",
                        axisPointer : {
                            type : 'none'
                        },
                        order:'valueDesc',
                    },
                    grid: {
                        left: 64,
                        top: 48,
                        right: 48,
                        bottom: 32
                    },
                    legend: {
                        show: true,
                        data: legend
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            magicType : {
                                show: true,
                                type: ['bar', 'line'],
                                title: {
                                    line: "Line",
                                    bar: "Bar",
                                    stack: "Stack",
                                    tiled: "Side by Side",
                                },
                                icon: {
                                    line: 'image://../../static/images/font-icon/line-chart.svg',
                                    bar: 'image://../../static/images/font-icon/bar-chart.svg',
                                }
                            },
                            saveAsImage: {
                                show: true,
                                title: "Save",
                                name: lblSaveImage,
                                icon: 'image://../../static/images/font-icon/save-chart.svg'
                            }
                        }
                    },
                    xAxis: {
                        type: "category",
                        data: xAisData
                    },
                    yAxis: {
                        type: "value"
                    },
                    dataZoom: [
                        {
                            disabled: true,
                            type: 'inside',
                            start: zoomStart,
                            end: zoomEnd
                        },
                    ],
                    series: chartData,
                    color: color,
                }}
                theme='BarChartTheme'
                notMerge={true}
                lazyUpdate={true}
                onEvents={onEvents}
                style={{height: '350px', width: '98%'}}
            />
        </div>
    );
};

LineChartActiveUsers.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(LineChartActiveUsers));
