import _ from 'lodash';
import moment from 'moment-timezone';
import { put, delay, all, takeEvery, takeLatest, call, select } from 'redux-saga/effects';
import { setUserPageListData, setUserPageTableLoading, setUserPageTableTotal, setUsersFilter, setUserCountByFiltered, setUserFilterQuery, setUserFilterCampaigns,
    setUsersStatData, setUserCsvDownloader, setUserCsvData, setGameOptionLoader, setCampaignOption, setPageTopLoader } from 'actions';
import { getJwtToken, getHomeTotalUsers } from 'api';
import * as H from 'helper';
import { USERS, DATA, ENDPOINT } from '../constants';

export const currentPage = state => state.page.currentPage;
export const listDataFetchLoop = state => state.affiliate.listDataFetchLoop;
export const currentUserListPage = state => state.users.usersListPage;
export const handleUsersFilter = state => state.users.handleUsersFilter;
export const userFetchFilter = state => state.users.userFetchFilter;
export const userFetchFilterSelected = state => state.users.userFetchFilterSelected;
export const usersFilterQuery = state => state.users.usersFilterQuery;
export const userPageListDataFilter = state => state.users.userPageListDataFilter;
export const gameSelected = state => state.data.gameSelected;
export const campaignSelected = state => state.data.campaignSelected;

export function* getUserListData() {
    try {
        let currentListPage = yield select(currentUserListPage);
        let usersQuery = yield select(usersFilterQuery);

        yield put(setUserPageTableLoading(true));
        yield put(setUserPageListData([]));
        const jwtToken = yield call(getJwtToken);
        const offSetNumber = 20 * currentListPage - 20;
        // let query = `SELECT game, DaaPmUsersProd.pmId, tier, mailAddress, createdAt, CampaignStats.rid, CampaignStats.name, locale, country FROM DaaPmUsersProd JOIN RegistrationProd JOIN CampaignStats ON DaaPmUsersProd.pmId = RegistrationProd.pmId AND RegistrationProd.rid = CampaignStats.rid GROUP BY DaaPmUsersProd.pmId  ORDER BY createdAt DESC LIMIT 20 OFFSET ${offSetNumber}`;
        let query = `SELECT game, RegistrationProd.rid, CampaignStats.name, RegistrationProd.pmId, RegistrationProd.exist, RegistrationProd.regDate 
                    FROM RegistrationProd
                    LEFT JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid
                    WHERE RegistrationProd.rid != 'organic'
                    ORDER BY regDate DESC
                    LIMIT 20 OFFSET ${offSetNumber}`
        // if (usersQuery) {
        //     query = usersQuery + offSetNumber;
        // }
        let sqlQueryUserListData = {"type": "list", "query": query, "database": "KpiDashboard"};
        const [tempData] = yield all([
            call(getHomeTotalUsers, sqlQueryUserListData, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        let userListData = JSON.parse(tempData.body);

        let returnDataArray = [], userObj = {}, emptyDataArray = [], index = (currentListPage - 1) * 20 + 1;
        _.each(userListData, function(data) {
            userObj.id = index;
            userObj.pmid = data[3]['stringValue'];
            userObj.game = data[0]['stringValue'];
            userObj.rid = data[1]['stringValue'];
            userObj.cname = H.FormatNumStrHelper.unEscapeUnicode(data[2]['stringValue']);
            userObj.createdAt = moment.unix(data[5]['longValue']/1000).tz("Asia/Singapore").format('lll'); //moment(data[4]['longValue']).format('YYYY/MM/DD');
            returnDataArray.push(userObj);
            userObj = {};
            index++;
        });

        const emptyDataNum = currentListPage * 20 - 20;
        console.log('emptyDataNum:', emptyDataNum);
        if (emptyDataNum > 0) {
            for (let i=0; i<emptyDataNum; i++) {
                userObj.id = i;
                emptyDataArray.push(userObj);
                userObj = {};
            }
        }
        const returningData = emptyDataArray.length > 0 ? emptyDataArray.concat(returnDataArray) : returnDataArray;
        yield put(setUserPageListData(returningData));
        yield put(setUserPageTableLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getUserListData2(action) {
    try {
        let currentListPage = yield select(currentUserListPage);
        let usersListPageFilter = yield select(userPageListDataFilter);
        let usersQuery = yield select(usersFilterQuery);
        const gameQuery = yield select(gameSelected);
        const campaignQuery = yield select(campaignSelected);
        const unixTimestampArray = H.FormatDateHelper.getCustomUnixFromAndToTimestamp(usersListPageFilter[0]['dateRange']);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];

        // DATA2.0 & table handling
        const rangeTarget = usersListPageFilter[0]['dateRange'];
        const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;
        const regTable = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';

        yield put(setUserPageTableLoading(true));
        yield put(setPageTopLoader(true));
        yield put(setGameOptionLoader(true));
        yield put(setUserPageListData([]));
        const jwtToken = yield call(getJwtToken);
        const offSetNumber = (action.payload - 1) * 50;
        const gameClause = gameQuery !== "" && gameQuery !== "All" ? `AND ${regTable}.game = '${gameQuery}'` : ``
        const gameClauseNewCbAndLa = gameQuery !== "" && gameQuery !== "All" ? `AND ${regTable}.game = '${gameQuery}'` : ``
        const campaignClause = campaignQuery !== "" && campaignQuery !== "All" ? `AND ${regTable}.rid = '${campaignQuery}'` : ``

        const listDataInRangeQuery = `
        SELECT game, ${regTable}.rid, CampaignStats.name, ${regTable}.pmId, ${regTable}.exist, ${regTable}.regDate FROM ${regTable}
        LEFT JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
        WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND ${regTable}.rid != 'organic' ${gameClause} ${campaignClause}
        ORDER BY regDate DESC
        LIMIT 50 OFFSET ${offSetNumber}`
        const sqlQueryUserListData = {"type": "list", "query": listDataInRangeQuery, "database": "KpiDashboard"};

        const countInRnageQuery = `
        SELECT COUNT(DISTINCT pmId) AS COUNT
        FROM ${regTable}
        WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND ${regTable}.rid != 'organic' ${gameClause} ${campaignClause}`;
        const sqlQueryUserCountInRangeData = {"type": "list", "query": countInRnageQuery, "database": "KpiDashboard"};

        const ttlCountQuery = `
        SELECT COUNT(DISTINCT pmId) AS total_count
        FROM (
            SELECT pmId FROM RegistrationPmEvtProd WHERE RegistrationPmEvtProd.rid != 'organic'
            UNION
            SELECT pmId FROM RegistrationProd WHERE RegistrationProd.rid != 'organic'
        ) AS merged_data;`;
        const sqlQueryUserTotalCountData = {"type": "list", "query": ttlCountQuery, "database": "KpiDashboard"};

        // camapign
        const campaignDataInRangeQuery = `
        SELECT CampaignStats.name, ${regTable}.rid, count(${regTable}.rid) AS count
        FROM ${regTable}
        LEFT JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
        WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND ${regTable}.rid != 'organic' ${gameClause}
        GROUP BY rid
        ORDER BY count DESC`
        const sqlQueryCampaignListData = {"type": "list", "query": campaignDataInRangeQuery, "database": "KpiDashboard"};

        const [tempUserListData, tempCountInRange, tempTotalCount, tempCampaign] = yield all([
            call(getHomeTotalUsers, sqlQueryUserListData, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryUserCountInRangeData, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryUserTotalCountData, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryCampaignListData, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        const userListData = JSON.parse(tempUserListData.body);
        const userCountRange = JSON.parse(tempCountInRange.body);
        const userCountData = JSON.parse(tempTotalCount.body);
        const userCampaignData = JSON.parse(tempCampaign.body);

        // @todo: configure both CB and LA seconds timestamp
        let returnNewDataArrayForCbAndLa = [];
        if(isDataNew) {
            const listDataInRangeQueryShort = `
            SELECT game, RegistrationPmEvtProd.rid, CampaignStats.name, RegistrationPmEvtProd.pmId, RegistrationPmEvtProd.exist, RegistrationPmEvtProd.regDate FROM RegistrationPmEvtProd
            LEFT JOIN CampaignStats ON RegistrationPmEvtProd.rid = CampaignStats.rid
            WHERE regDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} AND RegistrationPmEvtProd.rid != 'organic' ${gameClause} ${campaignClause}
            ORDER BY regDate DESC
            LIMIT 50 OFFSET ${offSetNumber}`;
            const sqlQueryUserListDataShort = {"type": "list", "query": listDataInRangeQueryShort, "database": "KpiDashboard"};

            const countInRnageQueryShort = `
            SELECT COUNT(DISTINCT pmId) AS COUNT
            FROM RegistrationPmEvtProd
            WHERE regDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} AND RegistrationPmEvtProd.rid != 'organic' ${gameClause} ${campaignClause}`;
            const sqlQueryUserCountInRangeDataShort = {"type": "list", "query": countInRnageQueryShort, "database": "KpiDashboard"};

            const [tempUserListDataShort, tempCountInRangeShort] = yield all([
                call(getHomeTotalUsers, sqlQueryUserListDataShort, ENDPOINT.GET_DATA_USERS, jwtToken),
                call(getHomeTotalUsers, sqlQueryUserCountInRangeDataShort, ENDPOINT.GET_DATA_USERS, jwtToken),
            ]);
            // console.log("HEHE: tempUserListDataShort:", JSON.parse(tempUserListDataShort.body));
            // console.log("HEHE: tempCountInRangeShort:", JSON.parse(tempCountInRangeShort.body));
            if (JSON.parse(tempUserListDataShort.body).length > 0) {
                returnNewDataArrayForCbAndLa = JSON.parse(tempUserListDataShort.body);
            }
        }

        let returnDataArray = [], userObj = {}, emptyDataArray = [], index = (currentListPage - 1) * 20 + 1;
        _.each(userListData, function(data) {
            userObj.id = index;
            userObj.pmid = data[3]['stringValue'];
            userObj.game = data[0]['stringValue'];
            userObj.rid = data[1]['stringValue'];
            userObj.cname = H.FormatNumStrHelper.unEscapeUnicode(data[2]['stringValue']);
            userObj.createdAt = moment.unix(data[5]['longValue']/1000).tz("Asia/Singapore").format('lll');
            returnDataArray.push(userObj);
            userObj = {};
            index++;
        });
        const emptyDataNum = currentListPage * 20 - 20;
        if (emptyDataNum > 0) {
            for (let i=0; i<emptyDataNum; i++) {
                userObj.id = i;
                emptyDataArray.push(userObj);
                userObj = {};
            }
        }
        const returningData = emptyDataArray.length > 0 ? emptyDataArray.concat(returnDataArray) : returnDataArray;

        const statData = [{
            "totalAd": userCountData[0][0]['longValue'],
            "countInRange": userCountRange[0][0]['longValue'],
        }];

        // camapign { name: 'All', value: 'all' },
        let campaignSelectObj = {}, campaignSelectArray = [];
        campaignSelectArray.push({ name: 'All', value: 'all' });
        _.each(userCampaignData, function(data) {
            if (!data[0]['isNull']) {
                campaignSelectObj.name = H.FormatNumStrHelper.unEscapeUnicode(data[0]['stringValue']) + ' | '+ data[1]['stringValue'] +' | ' + data[2]['longValue'];
                campaignSelectObj.value = data[1]['stringValue'];
                campaignSelectArray.push(campaignSelectObj);
                campaignSelectObj = {};
            }
        });
        yield put(setCampaignOption(campaignSelectArray));
        yield put(setGameOptionLoader(false));

        yield put(setUserPageListData(returningData));
        yield put(setUsersStatData(statData));
        yield put(setUserPageTableLoading(false));
        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getUsersListTotalNum() {
    try {
        const jwtToken = yield call(getJwtToken);
        let countQuery = "SELECT count(*) from RegistrationProd WHERE rid != 'organic';";
        let sqlQueryUserCount = {"type": "list", "query": countQuery, "database": "KpiDashboard"};

        // users by campaign
        let selectUserFetchFilterSource = yield select(userFetchFilter);
        let countUsersByCampaignQuery = `SELECT count(DaaPmUsersProd.pmId) AS count, CampaignStats.rid, CampaignStats.name, locale, country FROM DaaPmUsersProd JOIN RegistrationProd JOIN CampaignStats ON DaaPmUsersProd.pmId = RegistrationProd.pmId AND RegistrationProd.rid = CampaignStats.rid GROUP BY CampaignStats.rid ORDER BY count DESC;`;
        let sqlQueryUserCountByCampaign = {"type": "list", "query": countUsersByCampaignQuery, "database": "KpiDashboard"};

        const [tempCountData, tempCampaignData] = yield all([
            call(getHomeTotalUsers, sqlQueryUserCount, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlQueryUserCountByCampaign, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        let totalUserNumber = JSON.parse(tempCountData.body);
        yield put(setUserPageTableTotal(totalUserNumber[0][0]['longValue']));

        let campaignsByUserReg = JSON.parse(tempCampaignData.body);
        let campaignSelectObj = {}, campaignSelectArray = [];
        campaignSelectArray.push({ name: 'All', value: 'all' });
        _.each(campaignsByUserReg, function(data) {
            campaignSelectObj.name = H.FormatNumStrHelper.unEscapeUnicode(data[2]['stringValue']) + ' (' + data[0]['longValue'] + ')';
            campaignSelectObj.value = data[1]['stringValue'];
            campaignSelectArray.push(campaignSelectObj);
            campaignSelectObj = {};
        });
        selectUserFetchFilterSource.campaign = campaignSelectArray;
        yield put(setUserFilterCampaigns(selectUserFetchFilterSource));
    } catch (err) {
        console.log(err);
    }
}

export function* constructUsersFilter() {
    try {
        let selectUserFetchFilterSelected = yield select(userFetchFilterSelected);
        // console.log('selectUserFetchFilterSelected:', selectUserFetchFilterSelected);
        let selectHandleUsersFilter = yield select(handleUsersFilter);
        // console.log('selectHandleUsersFilter:', selectHandleUsersFilter);

        let pmidValue = selectUserFetchFilterSelected.pmid, emailValue = selectUserFetchFilterSelected.email, 
            gameValue = selectUserFetchFilterSelected.game, tierValue = selectUserFetchFilterSelected.tier,
            campaignValue = selectUserFetchFilterSelected.campaign;

        switch(selectHandleUsersFilter.name) {
            case 'pmid':
                pmidValue = selectHandleUsersFilter.value
                break;
            case 'email':
                emailValue =  selectHandleUsersFilter.value;
                break;
            case 'game':
                gameValue =  selectHandleUsersFilter.value;
                break;
            case 'tier':
                tierValue = selectHandleUsersFilter.value;
                break;
            case 'campaign':
                campaignValue = selectHandleUsersFilter.value;
                break;
            default:
        }

        let usersNewFilter = {
            pmid: pmidValue,
            email: emailValue,
            game: gameValue,
            tier: tierValue,
            campaign: campaignValue,
        }
        yield put(setUsersFilter(usersNewFilter))

    } catch (err) {
        console.log(err);
    }
}

export function* getFilteredUsers() {
    try {
        yield put(setUserCountByFiltered(false));

        let currentListPage = yield select(currentUserListPage);
        let userFetchFilterData = yield select(userFetchFilter);
        let usersFilterObj = yield select(userFetchFilterSelected);
        console.log('usersFilterObj:', usersFilterObj);

        yield put(setUserPageTableLoading(true));
        yield put(setUserPageListData([]));
        const jwtToken = yield call(getJwtToken);
        const offSetNumber = 20 * currentListPage - 20;

        let whereQuery = '';
        let isPmid = false;
        let isEmail = false;
        let isGameFiltered = false;
        let isTierFiltered = false;
        let isCampaignFiltered = false;
        let query, sqlQueryUserListData, countQuery, sqlCountQueryUserListData; 
 
        // pmid
        if (usersFilterObj.pmid !== '') {
            isPmid = true;
            let pmidValue = usersFilterObj.pmid;
            if (isEmail || isGameFiltered || isTierFiltered || isCampaignFiltered) {
                whereQuery = whereQuery + ` AND DaaPmUsersProd.pmId REGEXP '` + pmidValue + `'`;
            } else {
                whereQuery = whereQuery + ` WHERE DaaPmUsersProd.pmId REGEXP '` + pmidValue + `'`;
            }
        }

        // email
        if (usersFilterObj.email !== '') {
            isEmail = true;
            let emailValue = usersFilterObj.email;
            if (isPmid || isGameFiltered || isTierFiltered || isCampaignFiltered) {
                whereQuery = whereQuery + ` AND DaaPmUsersProd.mailAddress REGEXP '` + emailValue + `'`;
            } else {
                whereQuery = whereQuery + ` WHERE DaaPmUsersProd.mailAddress REGEXP '` + emailValue + `'`;
            }
        }

        // game
        if (usersFilterObj.game.length > 0) {
            isGameFiltered = true;
            let gameValue = usersFilterObj.game[0]['value'];
            if (usersFilterObj.game.length > 1) {
                _.each(usersFilterObj.game, function(game) {
                    gameValue = gameValue + '|' + game.value;
                });
            }
            if (isPmid || isEmail || isTierFiltered || isCampaignFiltered) {
                whereQuery = whereQuery + ` AND RegistrationProd.game REGEXP '` + gameValue + `'`;
            } else {
                whereQuery = whereQuery + ` WHERE RegistrationProd.game REGEXP '` + gameValue + `'`;
            }
        }

        // campaign
        if (usersFilterObj.campaign.length > 0) {
            isCampaignFiltered = true;
            let campaignValue = usersFilterObj.campaign[0]['value'];
            if (usersFilterObj.campaign.length > 1) {
                _.each(usersFilterObj.campaign, function(campaign) {
                    campaignValue = campaignValue + '|' + campaign.value;
                });
            }
            if (isPmid || isEmail || isGameFiltered || isTierFiltered) {
                whereQuery = whereQuery + ` AND RegistrationProd.rid REGEXP '` + campaignValue + `'`
            } else {
                whereQuery = whereQuery + ` WHERE RegistrationProd.rid REGEXP '` + campaignValue + `'`
            }
        }

        if (isPmid || isEmail) {
            query = `SELECT game, RegistrationProd.rid, CampaignStats.name, RegistrationProd.pmId, RegistrationProd.exist, RegistrationProd.regDate 
                     FROM RegistrationProd ${whereQuery}`;
            yield put(setUserFilterQuery(`SELECT game, RegistrationProd.rid, CampaignStats.name, RegistrationProd.pmId, RegistrationProd.exist, RegistrationProd.regDate 
                                          FROM RegistrationProd ${whereQuery} 
                                          ORDER BY regDate DESC LIMIT 20 OFFSET `));

            countQuery = `SELECT COUNT(pmId) FROM RegistrationProd ${whereQuery}`;
        } else {
            query = `SELECT game, RegistrationProd.rid, CampaignStats.name, RegistrationProd.pmId, RegistrationProd.exist, RegistrationProd.regDate 
                     FROM RegistrationProd JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid ${whereQuery}
                     GROUP BY RegistrationProd.pmId ORDER BY regDate DESC LIMIT 20 OFFSET ${offSetNumber}`;
    
            yield put(setUserFilterQuery(`SELECT game, RegistrationProd.rid, CampaignStats.name, RegistrationProd.pmId, RegistrationProd.exist, RegistrationProd.regDate
                                          FROM RegistrationProd
                                          JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid ${whereQuery} 
                                          GROUP BY RegistrationProd.pmId ORDER BY regDate DESC LIMIT 20 OFFSET `));
    
            countQuery = `SELECT COUNT(pmId) FROM RegistrationProd JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid ${whereQuery} GROUP BY RegistrationProd.pmId`;
        }
        sqlQueryUserListData = {"type": "list", "query": query, "database": "KpiDashboard"};
        sqlCountQueryUserListData = {"type": "list", "query": countQuery, "database": "KpiDashboard"};
        
        const [tempData, countTempData] = yield all([
            call(getHomeTotalUsers, sqlQueryUserListData, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getHomeTotalUsers, sqlCountQueryUserListData, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        console.log('tempDatatempData:', tempData);
        let userListData = JSON.parse(tempData.body);
        let userListCountData = JSON.parse(countTempData.body);
        console.log(userListData);
        console.log('userListCountData:', userListCountData.length);
        let countUsersNum = userListCountData.length > 0 ? userListCountData.length : 0;

        let returnDataArray = [], userObj = {}, emptyDataArray = [], index = (currentListPage - 1) * 20 + 1;
        _.each(userListData, function(data) {
            userObj.id = index;
            userObj.pmid = data[1]['stringValue'];
            userObj.game = data[0]['stringValue'];
            userObj.tier = data[2]['longValue'];
            userObj.locale = data[7] !== undefined && data[7]['stringValue'] !== 'unknown' ? data[7]['stringValue'] : '--';
            userObj.country = data[8] !== undefined && data[8]['stringValue'] !== 'unknown' ? data[8]['stringValue'] : '--';
            userObj.mailAddress = data[3]['stringValue'];
            userObj.rid = data[5]['stringValue'];
            userObj.cname = (isPmid || isEmail) ? H.CampaignHelper.getCampaignNameByRid(data[5]['stringValue'], userFetchFilterData.campaign)
                                                : H.FormatNumStrHelper.unEscapeUnicode(data[6]['stringValue']);
            userObj.createdAt = moment.unix(data[4]['longValue']).tz("Asia/Singapore").format('lll'); //moment(data[4]['longValue']).format('YYYY/MM/DD');
            returnDataArray.push(userObj);
            userObj = {};
            index++;
        });

        const emptyDataNum = currentListPage * 20 - 20;
        console.log('emptyDataNum:', emptyDataNum);
        if (emptyDataNum > 0) {
            for (let i=0; i<emptyDataNum; i++) {
                userObj.id = i;
                emptyDataArray.push(userObj);
                userObj = {};
            }
        }
        const returningData = emptyDataArray.length > 0 ? emptyDataArray.concat(returnDataArray) : returnDataArray;
        yield put(setUserPageListData(returningData));
        yield put(setUserCountByFiltered(countUsersNum));
        yield put(setUserPageTableLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getUserCsvData(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setUserCsvDownloader(true));

        let usersListPageFilter = yield select(userPageListDataFilter);
        let usersQuery = yield select(usersFilterQuery);
        const gameQuery = yield select(gameSelected);
        const campaignQuery = yield select(campaignSelected);

        const unixTimestampArray = H.FormatDateHelper.getCustomUnixFromAndToTimestamp(usersListPageFilter[0]['dateRange']);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const gameClause = gameQuery !== "" && gameQuery !== "All" ? `AND RegistrationProd.game = '${gameQuery}'` : ``
        const campaignClause = campaignQuery !== "" && campaignQuery !== "All" ? `AND RegistrationProd.rid = '${campaignQuery}'` : ``

        const listDataInRangeQuery = `SELECT game, RegistrationProd.rid, CampaignStats.name, RegistrationProd.pmId, RegistrationProd.exist, RegistrationProd.regDate 
                                      FROM RegistrationProd
                                      LEFT JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid
                                      WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND RegistrationProd.rid != 'organic' ${gameClause} ${campaignClause}
                                      ORDER BY regDate DESC`
        const sqlQueryUserListData = {"type": "list", "query": listDataInRangeQuery, "database": "KpiDashboard"};

        const [
            dataUserDetailsTemp,
        ] = yield all([
            call(getHomeTotalUsers, sqlQueryUserListData, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        const returnData = JSON.parse(dataUserDetailsTemp.body);

        let csvData = [
            ["PMID", "GAME", "Ad Code", "Campaign Name", "DATE"],
        ], eachRowData = [];
        if (returnData.length > 0) {
            _.forEach(returnData, function(value) {
                const dateTimeUtc = moment.tz(value[5]['longValue'], 'UTC');
                const dateTimeSgt = moment(dateTimeUtc).tz('Asia/Singapore').format('YYYY/MM/DD HH:mm:ss');
                eachRowData = [
                    value[3]['stringValue'],
                    value[0]['stringValue'],
                    value[1]['stringValue'],
                    value[2]['stringValue'],
                    dateTimeSgt
                ];
                csvData.push(eachRowData);
                eachRowData = [];
            });
        }
        yield put(setUserCsvData(csvData));
        yield put(setUserCsvDownloader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getCampaignByGame(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setGameOptionLoader(true));

        let usersListPageFilter = yield select(userPageListDataFilter);
        const unixTimestampArray = H.FormatDateHelper.getCustomUnixFromAndToTimestamp(usersListPageFilter[0]['dateRange']);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const gameClause = action.payload !== "" && action.payload !== "All" ? `AND RegistrationProd.game = '${action.payload}'` : ``
        const campaignDataInRangeQuery = `SELECT CampaignStats.name, RegistrationProd.rid, count(RegistrationProd.rid) AS count
                                      FROM RegistrationProd
                                      LEFT JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid
                                      WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND RegistrationProd.rid != 'organic' ${gameClause}
                                      GROUP BY rid
                                      ORDER BY count DESC`
        const sqlQueryCampaignListData = {"type": "list", "query": campaignDataInRangeQuery, "database": "KpiDashboard"};

        const [
            dataCampaignListTemp,
        ] = yield all([
            call(getHomeTotalUsers, sqlQueryCampaignListData, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        const returnData = JSON.parse(dataCampaignListTemp.body);
        let campaignSelectObj = {}, campaignSelectArray = [];
        campaignSelectArray.push({ name: 'All', value: 'all' });
        _.each(returnData, function(data) {
            if (!data[0]['isNull']) {
                campaignSelectObj.name = H.FormatNumStrHelper.unEscapeUnicode(data[0]['stringValue']) + ' | '+ data[1]['stringValue'] +' | ' + data[2]['longValue'];
                campaignSelectObj.value = data[1]['stringValue'];
                campaignSelectArray.push(campaignSelectObj);
                campaignSelectObj = {};
            }
        });
        yield put(setCampaignOption(campaignSelectArray));
        yield put(setGameOptionLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeLatest(USERS.SET_USER_PAGE_LIST_PAGINATION, getUserListData);
    yield takeLatest(USERS.GET_USER_PAGE_LIST_TOTAL_NUM, getUsersListTotalNum)
    yield takeLatest(USERS.HANDLE_USERS_FILTER, constructUsersFilter)
    yield takeLatest(USERS.FETCH_USERS_BY_FILTER, getFilteredUsers)
    yield takeEvery(USERS.GET_USER_LIST_DATA, getUserListData2);
    yield takeEvery(USERS.GET_USER_CSV_DATA, getUserCsvData);
    yield takeEvery(DATA.GET_CAMPAIGN_BY_GAME, getCampaignByGame);
}
