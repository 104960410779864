import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as H from 'helper';
import * as C from 'components';

const NftOwnTrendTable = ({
  nftOwnTrendOptionSelected,
  nftOwnTrendData,
  loading,
  seriesData,
}) => {
  const classes = useStyles();

  useEffect(() => {
    console.log("LOG nftOwnTrendData:", nftOwnTrendData)
  }, [nftOwnTrendData]);

  const getTableColumnName = () => {
    let columnLabelArray = ["ユーザー保有NFT数", "NFTホルダーPMID"];
    return <Fragment>
            {columnLabelArray.map((label, i) =>
            <tr key={i}>
            <td className={classes.tTd}>
                <div className={classes.lblColumnName}>{label}</div>
            </td>
            </tr >
            )}
        </Fragment>
  }

  const getDataHeader = (nftOwnTrendData) => {
    return <Fragment>
            <tr className={classes._GAIv}>
            {nftOwnTrendData[0]['total'].map((item, i) => (
                <td className={classes.dataHeaderTd} key={i}>{moment(item['date']).tz("Asia/Singapore").format('M/D')}</td>
            ))}
            </tr >
          </Fragment>
  }

  const getDataTable = (seriesData) => {
    let dataAdjusted = [], nftOwnCount = [], nftOwnPmidUnique = [];
    _.forOwn(seriesData[0]['total'], function(data, index) {
        nftOwnCount.push(data['count']);
    });
    _.forOwn(seriesData[0]['unique'], function(data2, index2) {
      nftOwnPmidUnique.push(data2['count']);
  });
    dataAdjusted = [
        nftOwnCount, nftOwnPmidUnique
    ];

    const getDataNumUsers = (numUserData, allDataArray, index) => {
      const dataPassed = Number(numUserData);
      return dataPassed !== 0 ? H.FormatNumStrHelper.formatCommaThousand(numUserData) 
                              : index === allDataArray.indexOf(0) 
                                  ? ''
                                  : '';
    }

    return  <Fragment>
              {dataAdjusted.map((d, i) =>
              <tr key={i}>
                {d.map((k, j) =>
                  <td className={classes.dataCellTd} key={j}>
                  {getDataNumUsers(k, d, j)}
                  </td>
                )}
              </tr >
              )}
            </Fragment>
  }

  const getExportData = () => {
    let exportData = [];
    if (nftOwnTrendData && nftOwnTrendData.length > 0) {
        let headerArray = ["日付"];
        for (let j=0; j<nftOwnTrendData[0]['total'].length; j++) {
            headerArray.push(nftOwnTrendData[0]['total'][j]['date']);
        }
        exportData.push(headerArray);

        let eachDataArray = ["ユーザー保有NFT数"];
        for (let k=0; k<nftOwnTrendData[0]['total'].length; k++) {
            eachDataArray.push(nftOwnTrendData[0]['total'][k]['count']);
        }
        exportData.push(eachDataArray);

        let eachDataArray2 = ["NFTホルダーPMID"];
        for (let l=0; l<nftOwnTrendData[0]['unique'].length; l++) {
            eachDataArray2.push(nftOwnTrendData[0]['unique'][l]['count']);
        }
        exportData.push(eachDataArray2);
    }
    return exportData;
  }

    return (
        <Paper>
        <div className={classes.titleContainer}>
            <div className={classes.widgetTitle}>NFT保有トレンドデータ</div>
            <div className={classes.exportBtnContainer}>
            <CSVLink
                data={getExportData()}
                filename={"NFT保有トレンドデータ"}
            >
                <Button
                    variant="contained"
                    color="default"
                    className={classes.exportBtn}
                    startIcon={<GetApp />}
                >
                    CSV出力
                </Button>
            </CSVLink>
            </div>
        </div>
        <div className={classes.tableContainer}>
        {loading 
            ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
            : nftOwnTrendData.length > 0
                ?
                <Fragment>
                    <div className={classes.dateTableWrapper}>
                    <table className={classes.leftTable}>
                        <tbody>
                        <tr>
                            <td className={classes.tTdHeader}>
                            </td>
                        </tr >
                        {getTableColumnName()}
                        </tbody>
                    </table>
                    </div>

                    <div className={classes.dataTableWrapper}>
                    <table className={classes.dataTableBody}>
                    <tbody>
                        {getDataHeader(nftOwnTrendData)}
                        {getDataTable(seriesData)}
                        </tbody>
                    </table>
                    </div>
                </Fragment>
                : <div className={classes.loaderBox}><C.NoData text="No Data" /></div>
            }
        </div>
        </Paper>
    )
}

NftOwnTrendTable.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    nftOwnTrendOptionSelected: state.nftOwnTrend.nftOwnTrendOptionSelected,
    nftOwnTrendData: state.nftOwnTrend.nftOwnTrendData,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(NftOwnTrendTable);

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 200,
  },
  dateTableWrapper: {
    float: 'left',
  },
  dataTableWrapper: {
    float: 'left',
    overflow: 'scroll',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblColumnName: {
    color: '#000',
    fontSize: 13,
    minWidth: 200,
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0, 
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'auto',
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10, 
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  }
});
