import { DATA } from "../../constants";

const initialState = {
  loading: {
    chart: false,
    table: false,
  },
  typeSelected: "fiat",
  itemSelected: [],
  dataChartCount: [],
  dataChartCountUnique: [],
  dataChartAmount: [],
  dataChartCountDep: [],
  dataChartCountUniqueDep: [],
  dataChartAmountDep: [],
  dataChartCountByItem: [],
  dataChartAmountByItem: [],
  dataTable: [],
  dataItems: [],
};

export default function inGameItemSalesTrendwReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case DATA.SET_IN_GAME_SALES_TREND_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_TYPE:
      return {
        ...state,
        typeSelected: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_ITEM_SELECTED:
      return {
        ...state,
        itemSelected: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_DATA_CHART_COUNT:
      return {
        ...state,
        dataChartCount: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT:
      return {
        ...state,
        dataChartAmount: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_DATA_CHART_COUNT_UNIQUE:
      return {
        ...state,
        dataChartCountUnique: action.payload,
      };
    case DATA.SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT:
      return {
        ...state,
        dataChartCountDep: action.payload,
      };
    case DATA.SET_INGAME_DEP_SALES_TREND_DATA_CHART_AMOUNT:
      return {
        ...state,
        dataChartAmountDep: action.payload,
      };
    case DATA.SET_INGAME_DEP_SALES_TREND_DATA_CHART_COUNT_UNIQUE:
      return {
        ...state,
        dataChartCountUniqueDep: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_DATA_CHART_COUNT_BY_ITEM:
      return {
        ...state,
        dataChartCountByItem: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_DATA_CHART_AMOUNT_BY_ITEM:
      return {
        ...state,
        dataChartAmountByItem: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_DATA_ITEMS:
      return {
        ...state,
        dataTable: action.payload,
      };
    case DATA.SET_INGAME_SALES_TREND_ITEM_SELECTED:
      return {
        ...state,
        dataItems: action.payload,
      };
    default:
      return state;
  }
}
