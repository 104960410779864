import { MAP } from '../constants';

const initialState  = {
    position: {x: 0, y: 0},
    mapTheme: 'mapbox://styles/mapbox/streets-v9',
    filterOpen: false,
    poiSearchData: [0, 1, 2, 3],
    zoomLevel: [12],
    poiCoord: [],
    showPoiDataOnMap: [false, false, false, false],
    polygonData: false,
    showPoiPolygon: false,
    mapFitBounds: [],
};

export default function mapReducer(state = initialState, action) {
    switch (action.type) {
        case MAP.CHANGE_THEME:
            return {
                ...state,
                mapTheme: action.payload
            };
        case MAP.SET_FILTER_STATUS:
            return {
                ...state,
                filterOpen: !action.payload
            };
        case MAP.SET_POI_SEARCH_DATA:
            return {
                ...state,
                poiSearchData: state.poiSearchData.map(function(content, index) {
                    if (index === action.numLocation) {
                        return action.payload
                    } else {
                        return content
                    }
                })
            };
        case MAP.SET_SHOW_POI_DATA:
            return {
                ...state,
                showPoiDataOnMap: state.showPoiDataOnMap.map(function(content, index) {
                    if (index === action.numLocation) {
                        return action.payload
                    } else {
                        return content
                    }
                })
            };
        case MAP.RESET_POI_SEARCH_DATA:
            return {
                ...state,
                poiSearchData: state.poiSearchData.map(function(content, index) {
                    if (index === action.locIndex) {
                        return action.locIndex
                    } else {
                        return content
                    }
                })
            };
        case MAP.SET_ZOOM_LEVEL:
            const zoomArray = [action.payload];
            return {
                ...state,
                zoomLevel: zoomArray
            };
        case MAP.SET_POI_COORD:
            const poiArray = action.payload;
            return {
                ...state,
                poiCoord: poiArray
            };
        case MAP.SET_POLYGON:
            return {
                ...state,
                polygonData: action.payload
            };
        case MAP.CLEAR_POI_SEARCH_DATA:
            return {
                ...state,
                poiSearchData: [0, 1, 2, 3],
                showPoiDataOnMap: [false, false, false, false],
            };
        case MAP.SET_MAP_FITBOUNDS:
            return {
                ...state,
                mapFitBounds: action.payload
            };
        default:
            return state
    }
}