import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import * as C from 'components';

const useStyles = makeStyles((theme) => ({
    titlePaper: {
        paddingTop: 8,
        paddingRight: 8,
        marginBottom: 32,
        marginLeft: 8,
        fontSize: 18,
    },
    statsBox: {
        padding: 8,
    },
    bottomBoxArea: {
        fontSize: 14,
        marginTop: 16,
    },
}));

const ScholarStats = ({
    dateRange,
    depPrice,
    selectedCurrency,
    loading,
    dataOwner,
    dataScholar,
    dataPerOwner,
    dataDeapCheckAmount,
}) => {
    const classes = useStyles();

    const handleCurrencyConvert = (amount) => {
        let returnAmount = Number(amount);
        if (selectedCurrency === 'usd') {
            returnAmount = returnAmount * depPrice.usd;
        } else if (selectedCurrency === 'sgd') {
            returnAmount = returnAmount * depPrice.sgd;
        } else if (selectedCurrency === 'jpy') {
            returnAmount = returnAmount * depPrice.jpy;
        }
        return returnAmount;
    };

    const getPrefix = (selectedCurrency) => {
        if (selectedCurrency === 'usd') {
            return "US$";
        } else if (selectedCurrency === 'sgd') {
            return "S$";
        } else if (selectedCurrency === 'jpy') {
            return "¥"
        } else {
            return "";
        }
    }
  
    return (
        <Grid container direction="column" style={{ marginTop: -8, marginBottom: 8, width: '100%' }}>
            <Paper style={{ padding: 8, marginBottom: 8, width: '98.6%' }}>
                <Grid item className={classes.titlePaper}>
                    {dateRange[0].replace(/-/g,'/')} ~ {dateRange[1].replace(/-/g,'/')}
                </Grid>
                <Grid container spacing={0} direction="row" style={{ marginTop: -16, marginBottom: 8, width: '100%' }}>
                    <Grid item xs={12} sm={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="有効登録"
                            value1={loading.owner ? 'loading' : dataOwner.total}
                            value2={loading.owner ? 'loading' : dataScholar.total}
                            prevValue1={loading.owner ? 'loading' : dataOwner.totalPrev}
                            prevValue2={loading.owner ? 'loading' : dataScholar.totalPrev}
                            lblValue1="Owner"
                            lblValue2="Scholar"
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="新規"
                            value1={loading.owner ? 'loading' : dataOwner.new}
                            value2={loading.owner ? 'loading' : dataScholar.new}
                            prevValue1={loading.owner ? 'loading' : dataOwner.newPrev}
                            prevValue2={loading.owner ? 'loading' : dataScholar.newPrev}
                            lblValue1="Owner"
                            lblValue2="Scholar"
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="停止"
                            value1={loading.owner ? 'loading' : dataOwner.stop}
                            value2={loading.owner ? 'loading' : dataScholar.stop}
                            prevValue1={loading.owner ? 'loading' : dataOwner.stopPrev}
                            prevValue2={loading.owner ? 'loading' : dataScholar.stopPrev}
                            lblValue1="Owner"
                            lblValue2="Scholar"
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholarCarousel
                            title="Owner一人あたりのScholar数"
                            loading={loading.owner ? true : false}
                            data={dataPerOwner}
                            lgValue={4}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} lg={2} className={classes.statsBox}>
                        <C.StatBox
                            title="DEAPCheck総額"
                            value={loading.owner ? 'loading' : handleCurrencyConvert(dataDeapCheckAmount.current)}
                            prevValue={loading.owner ? 'loading' : handleCurrencyConvert(dataDeapCheckAmount.prev)}
                            prefix={getPrefix(selectedCurrency)}
                            lastItem={true}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

ScholarStats.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    comparisonDateDiff: state.page.comparisonDateDiff,
    dateRange: state.page.dateRange,
    depPrice: state.page.depPrice,
    selectedCurrency: state.page.selectedCurrency,
    loading: state.jtcbScholar.loading,
    dataOwner: state.cookinBurgerScholar.dataOwner,
    dataScholar: state.cookinBurgerScholar.dataScholar,
    dataPerOwner: state.cookinBurgerScholar.dataPerOwner,
    dataDeapCheckAmount: state.cookinBurgerScholar.dataDeapCheckAmount,
    selectedCurrency: state.page.selectedCurrency,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ScholarStats));
