import CountryCode from 'utils/CountryCode';

const ContenetsHelper = {
    getContentsNameById: function(id) {
        const contentId = id;
        const gameMapping = {
            "cl8jxahuq0009l5rwgeh646m3": "JobTribes",
            "cl8jxahul0005l5rwa9bb6til": "GraffitiRacer",
            "cl8jxahuf0001l5rwewc19q7j": "CookinBurger",
            "cl8jxanai07njl5rwgg80cxzc": "DragonRamen",
            "cl8jxahtw0000l5rwh6ncf2uw": "LuckyFarmer",
            "cl8jxanai07nkl5rweau5bhwa": "Puzzle",
            "cl8jxahuh0002l5rwf0eeezoz": "KamuiVerse",
            "cl8jxanaj07nnl5rwg9awchu1": "PlayMining NFT",
            "cl8jxanai07nll5rw1ahg3xrd": "PlayMining NFT",
            "cleo05zpl003sagpjfe3q5n6l": "LostArchive+",
            "cl8jxanaj07npl5rw92qif08j": "PlaysiaTV",
            "clbk5yhj700c9inmt7ysd3g0e": "SOUL Fusers",
            "cl8px3n029saehrvwasxp1s01": "Rogue Roll Ruler\'s",
            "cl8px3n980123hrvw2pwljs63": "ISHI to HOSHI",
            "cl2135fe8776a34fb084e1ba02": "Bouncy Bunny",
            "cl88f51f50fd624393b57fda06": "PicTrée",
        }
        return gameMapping[contentId]
    },

    getContentsIdByName: function(name) {
        const contentName = name;
        const gameMapping = {
            "jobtribes": "cl8jxahuq0009l5rwgeh646m3",
            "graffitiracer": "cl8jxahul0005l5rwa9bb6til",
            "cookinburger": "cl8jxahuf0001l5rwewc19q7j",
            "dragonramen": "cl8jxanai07njl5rwgg80cxzc",
            "luckyfarmer": "cl8jxahtw0000l5rwh6ncf2uw",
            "puzzle": "cl8jxanai07nkl5rweau5bhwa",
            "kamuiverse": "cl8jxahuh0002l5rwf0eeezoz",
            "playMininnft": "cl8jxanaj07nnl5rwg9awchu1",
            "lostarchiveplus": "cleo05zpl003sagpjfe3q5n6l",
            "playsiatv": "cl8jxanaj07npl5rw92qif08j",
            "soulfusers": "clbk5yhj700c9inmt7ysd3g0e",
            "roguerollrulers": "cl8px3n029saehrvwasxp1s01",
            "ISHI_to_HOSHI": "cl8px3n980123hrvw2pwljs63",
            "bouncybunny": "cl2135fe8776a34fb084e1ba02",
            "pictree": "cl88f51f50fd624393b57fda06",
            // "cl8jxanai07nll5rw1ahg3xrd": "PlayMining NFT",
        }
        return gameMapping[contentName]
    },

    getContentsName: function(name) {
        const contentName = name;
        const gameMapping = {
            "jobtribes": "JobTribes",
            "graffitiracer": "GraffitiRacer",
            "cookinburger": "Cookin' Burger",
            "dragonramen": "DragonRamen",
            "luckyfarmer": "LuckyFarmer",
            "puzzle": "Puzzle",
            "kamuiverse": "Kamui Verse",
            "playMininnft": "PlayMining NFT",
            "playmining": "PlayMining NFT",
            "lostarchiveplus": "Lost Archive+",
            "playsiatv": "PlaysiaTV",
            "soulfusers": "SOUL Fusers",
            "roguerollrulers": "Rogue Roll Ruler\'s",
            "ishitohoshi": "ISHI to HOSHI",
            "bouncybunny": "Bouncy Bunny",
            "pictree": "PicTrée",
            // "cl8jxanai07nll5rw1ahg3xrd": "PlayMining NFT",
        }
        return gameMapping[contentName]
    },

    getContentsDatAddess: function(contentId, type) {
        const gameMapping = {
            "cl8jxahuq0009l5rwgeh646m3": [],
            "cl8jxahul0005l5rwa9bb6til": [],
            "cl8jxahuf0001l5rwewc19q7j": [],
            "cl8jxanai07njl5rwgg80cxzc":  {
                "deposit": "0xdd9039001351c6509361889d32633e9c2a1f8ec1",
                "check": "0xdd9039001351c6509361889d32633e9c2a1f8ec1"
            },
            "cl8jxahtw0000l5rwh6ncf2uw": [],
            "cl8jxanai07nkl5rweau5bhwa": [],
            "cl8jxahuh0002l5rwf0eeezoz": [],
            "cl8jxanaj07nnl5rwg9awchu1": [],
            "cl8jxanai07nll5rw1ahg3xrd": [],
            "cleo05zpl003sagpjfe3q5n6l": {
                "deposit": "0x50989c7b51ea31cf033a90111930cd14a94f75aa",
                "check": "0xec3f933c73e2841a166ee621521455d86140c4d3"
            },
            "cl8jxanaj07npl5rw92qif08j": [],
            "clbk5yhj700c9inmt7ysd3g0e": [],
            "cl8px3n029saehrvwasxp1s01": {
                "deposit": "0x1300df31d0dd205bf952035f3b3e7440d0dd5498",
                "check": "0xc74d3104ef813e60eda493b15a801d0487b1cb39"
            },
            "cl2135fe8776a34fb084e1ba02": {
                "deposit": "0x403f921de1fa71a1b2e298f2d9a7236c0ac7e455",
                "check": "0xb091f07b6a2a9b9c1df152563ed8ec7f53999356"
            },
        }
        return gameMapping[contentId][type]
    }
}

export default ContenetsHelper;