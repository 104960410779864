import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import * as C from 'components';
import CampaignCreateStyles from 'pages/CampaignCreateStyles';
import { setCurrentPage, setAffiliator, setSubmitAffiliate, clearAffiliateFields } from 'actions';

export class CampaignCreate extends Component {
    componentDidMount() {
        this.props.setCurrentPage('create');
        this.props.clearAffiliateFields();
    }

    handleCampaignCreate() {
        this.props.setAffiliator();
    }

    handleClose = () => {
        this.props.clearAffiliateFields(); // clear create/update fields
        this.props.setSubmitAffiliate(false)
    };

    render() {
        const { classes } = this.props;
        //console.log('this.props.submitAffiliate::', this.props.submitAffiliate);

        return (
            <C.ContentWrapper>
                <Grid container spacing={8}>
                    {/* <C.SideBarState /> */}
                    <Grid item xs={12} sm={8} md={9} lg={9}>
                        <div className={classes.fieldWrapper}>
                            <C.CampaignName />
                            <C.CampaignEmail />
                            <C.CampaignType />
                            <C.CampaignGame />
                            <C.CampaignGroup />
                            {/* <C.CampaignMedia /> */}
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.props.createBtnState}
                                className={classes.regButton}
                                onClick={() => { this.handleCampaignCreate()}}>
                                登録する
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.cancelButton}
                                component={ Link }
                                to="/campaign">
                                キャンセル
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.props.submitAffiliate === undefined ? false : this.props.submitAffiliate}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Icon className={'fa fa-cogs fa-md'} style={{color: "#888888", paddingRight: "16px" }} />
                        <span className={classes.dialogTitle}>{"キャンペーンの登録"}</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            新たなキャンペーンが追加されました。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                        OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </C.ContentWrapper>
        );
    }
}

CampaignCreate.propTypes = {
    classes: PropTypes.object,
    submitAffiliate: PropTypes.bool,
    setAffiliator: PropTypes.func,
    clearAffiliateFields: PropTypes.func,
};

const mapStateToProps = state => ({
    submitAffiliate: state.affiliate.submitAffiliate,
    createBtnState: state.affiliate.createBtnState,
    accessKey: state.page.accessKey,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setAffiliator: () => dispatch(setAffiliator()),
    setSubmitAffiliate: () => dispatch(setSubmitAffiliate()),
    clearAffiliateFields: (param) => dispatch(clearAffiliateFields(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CampaignCreateStyles, { withTheme: true })(CampaignCreate));