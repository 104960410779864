import { JTCB } from '../../constants';

const initialState  = {
    loading: false,
    dataKogiGuild: {
        purchaseAmount: null,
        totalAmount: null,
        purchaseCount: null,
        totalCount: null,
        ownerDepGive: null,
        ttlDepGive: null,
    },
    dataKyogiGuild: {
        purchaseAmount: null,
        totalAmount: null,
        purchaseCount: null,
        totalCount: null,
        ownerDepGive: null,
        ttlDepGive: null,
    },
};

export default function scholarAmuletReducer(state = initialState, action) {
    switch (action.type) {
        case JTCB.SET_SCHOLAR_GUILD_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case JTCB.SET_KOGI_GUILD_DATA:
            return {
                ...state,
                dataKogiGuild: action.payload
            };
        case JTCB.SET_KYOGI_GUILD_DATA:
            return {
                ...state,
                dataKyogiGuild: action.payload
            };
        default:
            return state
    }
};
