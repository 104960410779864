import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import * as H from "helper";
import * as C from "components";
import LabelConfig from "utils/a2e/LabelConfig";

const useStyles = makeStyles({
  loaderBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: "flex",
    minHeight: 200,
  },
  dateTableWrapper: {
    float: "left",
  },
  dataTableWrapper: {
    float: "left",
    overflow: "scroll",
  },
  leftTable: {
    border: "1px solid #eee",
  },
  lblGameName: {
    color: "#000",
    fontSize: 13,
  },
  lblUniqueUser: {
    color: "#6f6969",
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: "right",
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: "1px solid #eee6e6",
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: "right",
    verticalAlign: "middle",
    border: "1px solid #eee6e6",
    height: "3em",
    overflowX: "auto",
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: "right",
    verticalAlign: "middle",
    borderBottom: "1px solid #eee6e6",
    borderRight: "1px solid #eee6e6",
    height: 45,
  },
  tTdHeader: {
    paddingLeft: "1rem",
    paddingRight: 19,
    fontWeight: "bold",
    height: 39,
  },
  tTd: {
    height: 44,
    paddingLeft: "1rem",
    borderBottom: "1px solid #eee",
    borderCollapse: "collapse",
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  widgetTitle: {
    flexBasis: "50%",
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: "auto",
    padding: 16,
  },
});

const A2EDataTable = ({
  title,
  dataByDate,
  loading,
  noDataText,
  exportTitle,
  legend,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(dataByDate);
  }, [dataByDate]);

  const getTableColumnName = (tableData) => {
    const columnName = [
      "インプレッション", "試聴数", "試聴率", "試聴完了数", "試聴完了率",
      "クイズ回答数", "クイズ正解数", "正答率",
      "ヒントクリック数", "ヒントクリック率", "バナークリック数", "バナークリック率"
    ];
    // const keys = Object.keys(tableData[0]);
    return (
      <Fragment>
        {columnName.map((data, i) => (
          <tr key={i}>
            <td className={classes.tTd}>
              <div className={classes.lblGameName}>
                {data}
              </div>
              <div className={classes.lblUniqueUser}></div>
            </td>
          </tr>
        ))}
        {/* {keys.map((key, i) => (
          key !== "date" &&
          <tr key={i}>
            <td className={classes.tTd}>
              <div className={classes.lblGameName}>
                {LabelConfig.metricLabel[key]}
              </div>
              <div className={classes.lblUniqueUser}></div>
            </td>
          </tr>
        ))} */}
      </Fragment>
    );
  };

  const getDataHeader = (dataByDate) => {
    return (
      <Fragment>
        <tr className={classes._GAIv}>
          {dataByDate[0]["date"].map((item, i) => (
            <td className={classes.dataHeaderTd} key={i}>
              {moment(item).format("M/D")}
            </td>
          ))}
        </tr>
      </Fragment>
    );
  };

  const getDataTable = (tableData) => {
    const keys = Object.keys(tableData[0]);
    // let dataAdjusted = [];
    // _.forEach(dataByDate, function (value) {
    //   dataAdjusted.push(value.data);
    // });

    // const getDataNumers = (numData, allDataArray, index) => {
    //   const dataPassed = Number(numData);
    //   return dataPassed > -1
    //     ? H.FormatNumStrHelper.formatCommaThousand(numData)
    //     : index === allDataArray.indexOf(0)
    //     ? ""
    //     : "";
    // };

    return (
      <>
        {keys.map((key, i) => (
          key !== "date" &&
          <tr key={i} style={{backgroundColor: key.indexOf("Ratio") !== -1 ? "#e9ebee" : "#FFF"}}>
            {tableData[0][key].map((value, index) => (
              <td key={index} className={classes.dataCellTd}>
                {value}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  const getExportData = (data) => {
    let exportData = [];
    if (data && data[0]["date"].length > 0) {
      const headerArray = [
        "日付", "インプレッション", "試聴数", "試聴率", "試聴完了数", "試聴完了率",
        "クイズ回答数", "クイズ正解数", "正答率",
        "ヒントクリック数", "ヒントクリック率", "バナークリック数", "バナークリック率"
      ];
      exportData.push(headerArray);

      let numOfHeaderData = data[0]["date"].length;
      let eachDataArray = [];
      for (let j = 0; j < numOfHeaderData; j++) {
        eachDataArray.push(data[0]["date"][j]);
        eachDataArray.push(data[0]["imp"][j]);
        eachDataArray.push(data[0]["watch"][j]);
        eachDataArray.push(`${data[0]["watch"][j] > 0 ? (data[0]["watch"][j]/data[0]["imp"][j]*100).toFixed(2) : "0"}%`);
        eachDataArray.push(data[0]["watched"][j]);
        eachDataArray.push(`${data[0]["watched"][j] > 0 ? (data[0]["watched"][j]/data[0]["watch"][j]*100).toFixed(2) : "0"}%`);
        eachDataArray.push(data[0]["quizAnswer"][j]);
        eachDataArray.push(data[0]["quizCorrect"][j]);
        eachDataArray.push(`${data[0]["quizCorrect"][j] > 0 ? (data[0]["quizCorrect"][j]/data[0]["quizAnswer"][j]*100).toFixed(2) : "0"}%`);
        eachDataArray.push(data[0]["hintClick"][j]);
        eachDataArray.push(`${data[0]["hintClick"][j] > 0 ? (data[0]["hintClick"][j]/data[0]["quizAnswer"][j]*100).toFixed(2) : "0"}%`);
        eachDataArray.push(data[0]["bannerClick"][j]);
        eachDataArray.push(`${data[0]["bannerClick"][j] > 0 ? (data[0]["bannerClick"][j]/data[0]["quizAnswer"][j]*100).toFixed(2) : "0"}%`);
        exportData.push(eachDataArray);
        eachDataArray = [];
      }
    }
    return exportData;
  };

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}>{title}</div>
        <div className={classes.exportBtnContainer}>
          {tableData[0] !== undefined && tableData[0]["date"].length > 0 && (
            <CSVLink data={getExportData(tableData)} filename={exportTitle}>
              <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
              >
                CSV出力
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <div className={classes.tableContainer}>
        {loading ? (
          <div className={classes.loaderBox}>
            <img
              className={classes.loaderBarImg}
              src={`/static/images/loader-bar.gif`}
            />
          </div>
        ) : tableData[0] && tableData[0]["date"].length > 0 ? (
          <Fragment>
            <div className={classes.dateTableWrapper}>
              <table className={classes.leftTable}>
                <tbody>
                  <tr>
                    <td className={classes.tTdHeader}></td>
                  </tr>
                  {getTableColumnName(tableData)}
                </tbody>
              </table>
            </div>

            <div className={classes.dataTableWrapper}>
              <table className={classes.dataTableBody}>
                <tbody>
                  {getDataHeader(tableData)}
                  {getDataTable(tableData)}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <div className={classes.loaderBox}>
            <C.NoData text={"データ取得中..."} />
          </div>
        )}
      </div>
    </Paper>
  );
};

A2EDataTable.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dataByDate: state.a2e.dataByDate,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(A2EDataTable);
