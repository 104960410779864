import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import { setPageNumber } from 'actions';

const useStyles = makeStyles((theme) =>
    createStyles({
        calendarWrapper: {
            paddingLeft: 8,
        },
        root: {
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        container: {
            display: 'flex',
            width: 300,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            alignItems: 'baseline',
        },
        delimiter: {
            paddingTop: theme.spacing(1)
        },
        sectionCalendar: {
            display:'flex',
        },
        resetBtn: {
            marginTop: 12,
        },
    }),
);

const LocalCalendar = ({
    setRange,
    setBtnState,
    defaultRange,
    newRange,
    mDate,
    setPageNumber,
}) => {
    const classes = useStyles();
    //const minDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD');
    const [dateRangeValue, setDateRangeValue] = useState(defaultRange);
    const [restBtnState, setResetBtnState] = useState(false);
    const [error, setError] = useState([null, null]);

    useEffect(() => {
        const newStartDate = moment(newRange[0]).format('YYYY/MM/DD');
        const newEndDate = moment(newRange[1]).format('YYYY/MM/DD');
        setDateRangeValue([newStartDate, newEndDate]);
    }, [newRange]);

    const resetCalendar = () => {
        setDateRangeValue(defaultRange);
        setRange(defaultRange);
        setBtnState(false);
        setResetBtnState(false);
    }

    return (
        <div className={classes.calendarWrapper}>
            <div className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item>
                <DateRangePicker
                    startText="Start"
                    endText="End"
                    disableHighlightToday={true}
                    showToolbar={false}
                    allowSameDateSelection={true}
                    minDate={mDate !== null ? mDate : null}
                    maxDate={dateRangeValue[1]}
                    value={dateRangeValue}
                    onChange={(newValue) => {
                        setRange(newValue);
                        setPageNumber(1);
                    }}
                    onError={([startReason, endReason], [start, end]) => {
                        if (startReason === 'invalidRange') {
                            setError([null, null])
                            return;
                        }
                        setError([startReason, endReason])
                    }}
                    renderInput={(startProps, endProps) => (
                        <div className={classes.container}>
                            <TextField id="standard-basic" {...startProps} error={Boolean(error[0])} size="small" helperText="" />
                            <DateRangeDelimiter><span className={classes.delimiter}>~</span></DateRangeDelimiter>
                            <TextField id="standard-basic" {...endProps} error={Boolean(error[0])} size="small" helperText="" />
                        </div>
                    )}
                />
                </Grid>
                <Grid item>
                <Tooltip title="カレンダーリセット">
                    <IconButton disabled={restBtnState} className={classes.resetBtn} onClick={() => resetCalendar()} size="small">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                </Grid>
            </Grid>
            </div>
        </div>
    );
}

LocalCalendar.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    setPageNumber: (param) => dispatch(setPageNumber(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(LocalCalendar));
