import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setPageTopLoader, setScholarDataLoading, setOverviewOwnerStatsData, setOverviewScholarStatsData, setLendingCountData,
    setPerOwnerData, setDeapCheckAmountData, setOwnerByCountryData, setScholarByCountryData, setUniqueOwnerCountByDateData, setUniqueScholarCountByDateData,
    setScholarCountByOwnerData, setScholarAmuletByOwnerData, setScholarDepByOwnerData, setScholarTotalAmuletByDateData, setScholarTotalDepByDateData,
    setNumOwnerData, setNumScholarData, setScholarDepByScholarData, setScholarTotalScholarDepByDateData, setJtcbScholarTrendData, setScholarTrendDataLoading,
    setScholarAmuletDataLoading, setJtcbScholarAmuletData, setJtcbScholarAmuletDataByJob, setScholarAmuletDataJobLoading, setAmuletSelected, setGuildDataLoading,
    setKogiGuildData, setKyogiGuildData } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch } from 'api';
import { JTCB, ENDPOINT } from '../../constants';
import * as H from 'helper';
import KyogiScholar from '../../utils/KyogiScholar';

export const scholarDateRange = state => state.page.dateRange;
export const scholarDateRangePrev = state => state.page.prevDateRange;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.jtcbScholar.loading;
export const amuletSelected = state => state.jtcbScholarAmulet.amuletSelected;
export const dataPerOwner = state => state.jtcbScholar.dataPerOwner;

// NOT IN USE: Slow performance
export function* getOwnerScholarStatsData() {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.owner = true;
        loadingUpdate.scholar = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const rangeTargetPrev = yield select(scholarDateRangePrev);
        const dayBeforeQuitDate = moment(rangeTarget[1]).tz("Asia/Singapore").subtract(1, "days").format('YYYY-MM-DD');
        const dayBeforeQuitDatePrev = moment(rangeTargetPrev[1]).tz("Asia/Singapore").subtract(1, "days").format('YYYY-MM-DD');

        const sqlQueryOwnerData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarOwnerProd', rangeTarget[1]);
        const sqlQueryScholarData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarScholarProd', rangeTarget[1]);
        const sqlQueryOwnerDataPrev = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarOwnerProd', rangeTargetPrev[1]);
        const sqlQueryScholarDataPrev = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarScholarProd', rangeTargetPrev[1]);
        const sqlQueryNewOwnerData = H.SqlHelper.getScholarNewReg('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryNewOwnerDataPrev = H.SqlHelper.getScholarNewReg('JtcbScholarOwnerProd', rangeTargetPrev[0], rangeTargetPrev[1]);
        const sqlQueryNewScholarData = H.SqlHelper.getScholarNewReg('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryNewScholarDataPrev = H.SqlHelper.getScholarNewReg('JtcbScholarScholarProd', rangeTargetPrev[0], rangeTargetPrev[1]);
        const sqlQueryQuitOwnerData = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarOwnerProd', dayBeforeQuitDate);
        const sqlQueryQuitOwnerDataPrev = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarOwnerProd', dayBeforeQuitDatePrev);
        const sqlQueryQuitScholarData = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarScholarProd', dayBeforeQuitDate);
        const sqlQueryQuitScholarDataPrev = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarScholarProd', dayBeforeQuitDatePrev);

        const [
            tempAllTimeOwner,
            tempAllTimeScholar,
            tempAllTimeOwnerPrev,
            tempAllTimeScholarPrev,
            tempOwnerNew,
            tempOwnerNewPrev,
            tempScholarNew,
            tempScholarNewPrev,
            tempOwnerQuit,
            tempOwnerQuitPrev,
            tempScholarQuit,
            tempScholarQuitPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQueryOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewOwnerDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewScholarDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitOwnerDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitScholarDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedOwner = JSON.parse(tempAllTimeOwner.body);
        const parsedScholar = JSON.parse(tempAllTimeScholar.body);
        const parsedOwnerPrev = JSON.parse(tempAllTimeOwnerPrev.body);
        const parsedScholarPrev = JSON.parse(tempAllTimeScholarPrev.body);
        const parsedNewOwner = JSON.parse(tempOwnerNew.body);
        const parsedNewOwnerPrev = JSON.parse(tempOwnerNewPrev.body);
        const parsedNewScholar = JSON.parse(tempScholarNew.body);
        const parsedNewScholarPrev = JSON.parse(tempScholarNewPrev.body);
        const parsedQuitOwner = JSON.parse(tempOwnerQuit.body);
        const parsedQuitOwnerPrev = JSON.parse(tempOwnerQuitPrev.body);
        const parsedQuitScholar = JSON.parse(tempScholarQuit.body);
        const parsedQuitScholarPrev = JSON.parse(tempScholarQuitPrev.body);

        const ownerData = {
            total: parsedOwner[0][0]['longValue'],
            totalPrev: parsedOwnerPrev[0][0]['longValue'],
            new: parsedNewOwner[0][0]['longValue'],
            newPrev: parsedNewOwnerPrev[0][0]['longValue'],
            stop: parsedQuitOwner[0][0]['longValue'],
            stopPrev: parsedQuitOwnerPrev[0][0]['longValue'],
        }
        const scholarData = {
            total: parsedScholar[0][0]['longValue'],
            totalPrev: parsedScholarPrev[0][0]['longValue'],
            new: parsedNewScholar[0][0]['longValue'],
            newPrev: parsedNewScholarPrev[0][0]['longValue'],
            stop: parsedQuitScholar[0][0]['longValue'],
            stopPrev: parsedQuitScholarPrev[0][0]['longValue']
        }
        yield put(setOverviewOwnerStatsData(ownerData));
        yield put(setOverviewScholarStatsData(scholarData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.owner = false;
        loadingUpdate2.scholar = false;
        yield put(setScholarDataLoading(loadingUpdate2));

        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getOwnerScholarStatsData2() {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.owner = true;
        loadingUpdate.scholar = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const rangeTargetPrev = yield select(scholarDateRangePrev);

        const sqlQueryOwnerData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarOwnerProd', rangeTarget[1]);
        const sqlQueryScholarData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarScholarProd', rangeTarget[1]);
        const sqlQueryOwnerDataPrev = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarOwnerProd', rangeTargetPrev[1]);
        const sqlQueryScholarDataPrev = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarScholarProd', rangeTargetPrev[1]);
        const sqlQueryNewOwnerData = H.SqlHelper.getScholarNewTrendData('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryNewOwnerDataPrev = H.SqlHelper.getScholarNewTrendData('JtcbScholarOwnerProd', rangeTargetPrev[0], rangeTargetPrev[1]);
        const sqlQueryNewScholarData = H.SqlHelper.getScholarNewTrendData('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryNewScholarDataPrev = H.SqlHelper.getScholarNewTrendData('JtcbScholarScholarProd', rangeTargetPrev[0], rangeTargetPrev[1]);
        const sqlQueryQuitOwnerData = H.SqlHelper.getScholarStopTrendData('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryQuitOwnerDataPrev = H.SqlHelper.getScholarStopTrendData('JtcbScholarOwnerProd', rangeTargetPrev[0], rangeTargetPrev[1]);
        const sqlQueryQuitScholarData = H.SqlHelper.getScholarStopTrendData('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryQuitScholarDataPrev = H.SqlHelper.getScholarStopTrendData('JtcbScholarScholarProd', rangeTargetPrev[0], rangeTargetPrev[1]);

        const [
            tempAllTimeOwner,
            tempAllTimeScholar,
            tempAllTimeOwnerPrev,
            tempAllTimeScholarPrev,
            tempOwnerNewData,
            tempOwnerNewPrev,
            tempScholarNew,
            tempScholarNewPrev,
            tempOwnerQuit,
            tempOwnerQuitPrev,
            tempScholarQuit,
            tempScholarQuitPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQueryOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewOwnerDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewScholarDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitOwnerDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryQuitScholarDataPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedOwner = JSON.parse(tempAllTimeOwner.body);
        const parsedScholar = JSON.parse(tempAllTimeScholar.body);
        const parsedOwnerPrev = JSON.parse(tempAllTimeOwnerPrev.body);
        const parsedScholarPrev = JSON.parse(tempAllTimeScholarPrev.body);
        const parsedOwnerNewData = JSON.parse(tempOwnerNewData.body);
        const parsedNewOwnerPrev = JSON.parse(tempOwnerNewPrev.body);
        const parsedNewScholar = JSON.parse(tempScholarNew.body);
        const parsedNewScholarPrev = JSON.parse(tempScholarNewPrev.body);
        const parsedQuitOwner = JSON.parse(tempOwnerQuit.body);
        const parsedQuitOwnerPrev = JSON.parse(tempOwnerQuitPrev.body);
        const parsedQuitScholar = JSON.parse(tempScholarQuit.body);
        const parsedQuitScholarPrev = JSON.parse(tempScholarQuitPrev.body);

        let totalNewRegNum = 0, totalNewRegNumPrev = 0,
        totalNewScholarReg = 0, totalNewScholarRegPrev = 0;
        for (let i=0; i<parsedOwnerNewData.length; i++) {
            totalNewRegNum = totalNewRegNum + parsedOwnerNewData[i][1]['longValue'];
        }
        for (let i=0; i<parsedNewOwnerPrev.length; i++) {
            totalNewRegNumPrev = totalNewRegNumPrev + parsedNewOwnerPrev[i][1]['longValue'];
        }
        for (let i=0; i<parsedNewScholar.length; i++) {
            totalNewScholarReg = totalNewScholarReg + parsedNewScholar[i][1]['longValue'];
        }
        for (let i=0; i<parsedNewScholarPrev.length; i++) {
            totalNewScholarRegPrev = totalNewScholarRegPrev + parsedNewScholarPrev[i][1]['longValue'];
        }

        // Cancel aggragation
        // Owner
        let ownerStopObj = {}, ownerStopObjPrev = {};
        _.each(parsedQuitOwner, function(data) {
            ownerStopObj[data[0]['stringValue']] = data[1]['longValue'];
        });

        _.each(parsedQuitOwnerPrev, function(data) {
            ownerStopObjPrev[data[0]['stringValue']] = data[1]['longValue'];
        });

        // Scholar
        let scholarStopObj = {}, scholarStopObjPrev = {};
        _.each(parsedQuitScholar, function(data) {
            scholarStopObj[data[0]['stringValue']] = data[1]['longValue'];
        });
        _.each(parsedQuitScholarPrev, function(data) {
            scholarStopObjPrev[data[0]['stringValue']] = data[1]['longValue'];
        });
        
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
        const daysArrayPrev = H.FormatDateHelper.getDaysListBetweenDays(rangeTargetPrev[0], rangeTargetPrev[1]);
        let dataOwnerStopNum = 0, dataOwnerStopNumPrev = 0,
        dataScholarStopNum = 0, dataScholarStopNumPrev = 0;
        _.each(daysArray, function(date, index) {
            // Owner Range
            if (ownerStopObj[date] === undefined || index === daysArray.length - 1) {
                dataOwnerStopNum = dataOwnerStopNum;
            } else {
                dataOwnerStopNum = dataOwnerStopNum + ownerStopObj[date]
            }

            // Scholar
            if (scholarStopObj[date] === undefined || index === daysArray.length - 1) {
                dataScholarStopNum = dataScholarStopNum;
            } else {
                dataScholarStopNum = dataScholarStopNum + scholarStopObj[date]
            }
        });

        // prev
        _.each(daysArrayPrev, function(date, index) {
            // Owner Prev
            if (ownerStopObjPrev[date] === undefined || index === daysArray.length - 1) {
                dataOwnerStopNumPrev = dataOwnerStopNumPrev;
            } else {
                dataOwnerStopNumPrev = dataOwnerStopNumPrev + ownerStopObjPrev[date]
            }

            // Scholar Prev
            if (scholarStopObjPrev[date] === undefined || index === daysArray.length - 1) {
                dataScholarStopNumPrev = dataScholarStopNumPrev;
            } else {
                dataScholarStopNumPrev = dataScholarStopNumPrev + scholarStopObjPrev[date]
            }
        });

        const ownerData = {
            total: parsedOwner[0][0]['longValue'],
            totalPrev: parsedOwnerPrev[0][0]['longValue'],
            new: totalNewRegNum,
            newPrev: totalNewRegNumPrev,
            stop: dataOwnerStopNum,
            stopPrev: dataOwnerStopNumPrev,
        }
        const scholarData = {
            total: parsedScholar[0][0]['longValue'],
            totalPrev: parsedScholarPrev[0][0]['longValue'],
            new: totalNewScholarReg,
            newPrev: totalNewScholarRegPrev,
            stop: dataScholarStopNum,
            stopPrev: dataScholarStopNumPrev
        }
        yield put(setOverviewOwnerStatsData(ownerData));
        yield put(setOverviewScholarStatsData(scholarData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.owner = false;
        loadingUpdate2.scholar = false;
        yield put(setScholarDataLoading(loadingUpdate2));

        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

// NOT IN USE: Due to the ES data available because only 3 months
export function* getOwnerScholarStatsDataFromEs() {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.owner = true;
        loadingUpdate.scholar = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const rangeTargetPrev = yield select(scholarDateRangePrev);
        const rangeFromBegin = ["2021-12-14", rangeTarget[1]]
        const dayBeforeQuitDatePrev = moment(rangeTargetPrev[1]).tz("Asia/Singapore").subtract(1, "days").format('YYYY-MM-DD');
        // console.log("KEKE rangeTarget:", rangeTarget);
        // console.log("KEKE rangeTargetPrev:", rangeTargetPrev);
        // console.log("KEKE rangeFromBegin:", rangeFromBegin);

        // Contract
        const sourceContract = H.ElasticSearch.getJtcbScholarContractCount("SCHOLAR_SHIP_CONTRACT", rangeTarget);
        const searchJtcbScholarContract = {"es": "jtcb", "query": "scholar", "index": "pro.log-jtcb-game-*", "src": `${sourceContract}`};
        const sourceContractPrev = H.ElasticSearch.getJtcbScholarContractCount("SCHOLAR_SHIP_CONTRACT", rangeTargetPrev);
        const searchJtcbScholarContractPrev = {"es": "jtcb", "query": "scholar", "index": "pro.log-jtcb-game-*", "src": `${sourceContractPrev}`};

        // Cancel
        const sourceCancel = H.ElasticSearch.getJtcbScholarContractCount("SCHOLAR_SHIP_CANCEL", rangeTarget);
        const searchJtcbScholarCancel = {"es": "jtcb", "query": "scholar", "index": "pro.log-jtcb-game-*", "src": `${sourceCancel}`};
        const sourceCancelPrev = H.ElasticSearch.getJtcbScholarContractCount("SCHOLAR_SHIP_CANCEL", rangeTargetPrev);
        const searchJtcbScholarCancelPrev = {"es": "jtcb", "query": "scholar", "index": "pro.log-jtcb-game-*", "src": `${sourceCancelPrev}`};

        // All Contract, Cancel
        const sourceAllContract = H.ElasticSearch.getJtcbScholarContractCount("SCHOLAR_SHIP_CONTRACT", rangeFromBegin);
        const sourceAllCancel = H.ElasticSearch.getJtcbScholarContractCount("SCHOLAR_SHIP_CANCEL", rangeFromBegin);
        const searchJtcbScholarAllContract = {"es": "jtcb", "query": "scholar", "index": "pro.log-jtcb-game-*", "src": `${sourceAllContract}`};
        const searchJtcbScholarAllCancel = {"es": "jtcb", "query": "scholar", "index": "pro.log-jtcb-game-*", "src": `${sourceAllCancel}`};

        const [dataTempContract, dataTempContractPrev, dataTempCancel, dataTempCancelPrev,
            dataTempAllContract, dataTempAllCancel ] = yield all([
            call(getDataFromElasticSearch, searchJtcbScholarContract, ENDPOINT.GET_DATA_JTCB_FROM_ES, jwtToken),
            call(getDataFromElasticSearch, searchJtcbScholarContractPrev, ENDPOINT.GET_DATA_JTCB_FROM_ES, jwtToken),
            call(getDataFromElasticSearch, searchJtcbScholarCancel, ENDPOINT.GET_DATA_JTCB_FROM_ES, jwtToken),
            call(getDataFromElasticSearch, searchJtcbScholarCancelPrev, ENDPOINT.GET_DATA_JTCB_FROM_ES, jwtToken),
            call(getDataFromElasticSearch, searchJtcbScholarAllContract, ENDPOINT.GET_DATA_JTCB_FROM_ES, jwtToken),
            call(getDataFromElasticSearch, searchJtcbScholarAllCancel, ENDPOINT.GET_DATA_JTCB_FROM_ES, jwtToken)
        ]);
        // console.log("YOYO dataTempContract:", dataTempContract.aggregations);
        // console.log("YOYO dataTempContractPrev:", dataTempContractPrev.aggregations);

        // console.log("YOYO dataTempCancel:", dataTempCancel.aggregations);
        // console.log("YOYO dataTempCancelPrev:", dataTempCancelPrev.aggregations);

        const ownerData = {
            total: 0,
            totalPrev: 0,
            new: dataTempContract.aggregations.owner.value,
            newPrev: dataTempContractPrev.aggregations.owner.value,
            stop: dataTempCancel.aggregations.owner.value,
            stopPrev: dataTempCancelPrev.aggregations.owner.value,
        }
        const scholarData = {
            total: 0,
            totalPrev: 0,
            new: dataTempContract.aggregations.scholar.value,
            newPrev: dataTempContractPrev.aggregations.scholar.value,
            stop: dataTempCancel.aggregations.scholar.value,
            stopPrev:  dataTempCancelPrev.aggregations.scholar.value
        }
        yield put(setOverviewOwnerStatsData(ownerData));
        yield put(setOverviewScholarStatsData(scholarData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.owner = false;
        loadingUpdate2.scholar = false;
        yield put(setScholarDataLoading(loadingUpdate2));

        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export function* getLendingStatsData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.amuletLending = true;
        yield put(setScholarDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('x'),
            moment(dateTo+" 23:59:59+8:00").format('x')
        ];
        let unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('x'),
            moment(datePrevTo+" 23:59:59+8:00").format('x')
        ];

        const sqlQueryLendingCount = H.SqlHelper.getUniqueCountByColumnWithRange('JtcbScholarLendingProd', 'token', unixStampTime[0], unixStampTime[1]);
        const sqlQueryLendingCountPrev = H.SqlHelper.getUniqueCountByColumnWithRange('JtcbScholarLendingProd', 'token', unixStampTimePrev[0], unixStampTimePrev[1]);
        
        const [
            tempLending,
            tempPrevLending,
        ] = yield all([
            call(getDataFromRDS, sqlQueryLendingCount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryLendingCountPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedLending = JSON.parse(tempLending.body);
        const parsedPrevLending = JSON.parse(tempPrevLending.body);

        const dataLending = {
            current: parsedLending[0][0]['longValue'],
            prev: parsedPrevLending[0][0]['longValue']
        }
        yield put(setLendingCountData(dataLending));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.amuletLending = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarPerOwnerData() {
    const jwtToken = yield call(getJwtToken);
    const loadingStateBegin = yield select(loading);
    let loadingUpdate = {...loadingStateBegin};
    loadingUpdate.perOwnerMaxMin = true;
    yield put(setScholarDataLoading(loadingUpdate));

    // Date Range
    const rangeTarget = yield select(scholarDateRange);
    const rangeTargetPrev = yield select(scholarDateRangePrev);
    let rangeTargetPrevMinus = moment(rangeTargetPrev[0]).tz('Asia/Singapore').subtract(1, "days").format('YYYY-MM-DD');
    rangeTargetPrevMinus = moment(rangeTargetPrevMinus).isBefore('2021-12-14') ? "2021-12-14" : rangeTargetPrevMinus;

    const sqlQueryPerOwnerMax = H.SqlHelper.getMaxMinData('JtcbScholarOwnerProd', 'MAX', 'scholarCount', "dateStr = '"+rangeTarget[1]+"'");
    const sqlQueryPerOwnerMin = H.SqlHelper.getMaxMinData('JtcbScholarOwnerProd', 'MIN', 'scholarCount', "dateStr = '"+rangeTarget[1]+"'");
    const sqlQueryPerOwnerMaxSubtract = H.SqlHelper.getMaxMinData('JtcbScholarOwnerProd', 'MAX', 'scholarCount', "dateStr = '"+rangeTargetPrevMinus+"'");
    const sqlQueryPerOwnerMinSubtract = H.SqlHelper.getMaxMinData('JtcbScholarOwnerProd', 'MIN', 'scholarCount', "dateStr = '"+rangeTargetPrevMinus+"'");
    const sqlQueryPerOwnerMedian = H.SqlHelper.getMedianData('JtcbScholarOwnerProd', 'scholarCount', "dateStr = '"+rangeTarget[1]+"'");
    const sqlQueryPerOwnerMedianSubtract = H.SqlHelper.getMedianData('JtcbScholarOwnerProd', 'scholarCount', "dateStr = '"+rangeTargetPrevMinus+"'");
    const sqlQueryPerOwnerMode = H.SqlHelper.getModeData('JtcbScholarOwnerProd', 'scholarCount', "dateStr = '"+rangeTarget[1]+"'");
    const sqlQueryPerOwnerModeSubtract = H.SqlHelper.getModeData('JtcbScholarOwnerProd', 'scholarCount', "dateStr = '"+rangeTargetPrevMinus+"'");
    const sqlQueryPerOwnerAvg = H.SqlHelper.getAvgData('JtcbScholarOwnerProd', 'scholarCount', "dateStr = '"+rangeTarget[1]+"'");
    const sqlQueryPerOwnerAvgSubtract = H.SqlHelper.getAvgData('JtcbScholarOwnerProd', 'scholarCount', "dateStr = '"+rangeTargetPrevMinus+"'");

    const [
        tempPerOwnerMax,
        tempPerOwnerMin,
        tempPerOwnerMaxMinus,
        tempPerOwnerMinMinus,
        tempPerOwnerMedian,
        tempPerOwnerMedianMinus,
        tempPerOwnerMode,
        tempPerOwnerModeMinus,
        tempPerOwnerAvg,
        tempPerOwnerAvgMinus,
    ] = yield all([
        call(getDataFromRDS, sqlQueryPerOwnerMax, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerMin, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerMaxSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerMinSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerMedian, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerMedianSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerMode, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerModeSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerAvg, ENDPOINT.GET_DATA_RDS, jwtToken),
        call(getDataFromRDS, sqlQueryPerOwnerAvgSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
    ]);

    const parsedPerOwnerMax = JSON.parse(tempPerOwnerMax.body);
    const parsedPerOwnerMin = JSON.parse(tempPerOwnerMin.body);
    const parsedPerOwnerPerOwnerMaxSub = JSON.parse(tempPerOwnerMaxMinus.body);
    const parsedPerOwnerPerOwnerMinSub = JSON.parse(tempPerOwnerMinMinus.body);
    const parsedPerOwnerMedian = JSON.parse(tempPerOwnerMedian.body);
    const parsedPerOwnerMedianSub = JSON.parse(tempPerOwnerMedianMinus.body);
    const parsedPerOwnerMode = JSON.parse(tempPerOwnerMode.body);
    const parsedPerOwnerModeSub = JSON.parse(tempPerOwnerModeMinus.body);
    const parsedPerOwnerAvg = JSON.parse(tempPerOwnerAvg.body);
    const parsedPerOwnerAvgSub = JSON.parse(tempPerOwnerAvgMinus.body);

    const dataMaxScholar = parsedPerOwnerMax[0][0]['longValue'];
    const dataMinScholar = parsedPerOwnerMin[0][0]['longValue'];
    const dataMaxScholarPrev = parsedPerOwnerPerOwnerMaxSub[0][0]['longValue'];
    const dataMinScholarPrev = parsedPerOwnerPerOwnerMinSub[0][0]['longValue'];
    const dataScholarMedian = Number(parsedPerOwnerMedian[0][0]['stringValue']);
    const dataScholarMedianPrev = Number(parsedPerOwnerMedianSub[0][0]['stringValue']);
    const dataScholarMode = Number(parsedPerOwnerMode[0][0]['longValue']);
    const dataScholarModePrev = Number(parsedPerOwnerModeSub[0][0]['longValue']);
    const dataScholarAvg = parsedPerOwnerAvg[0][0]['stringValue'];
    const dataScholarAvgPrev = parsedPerOwnerAvgSub[0][0]['stringValue'];

    const newDataPerOwner = [
        {
            title: "最大値",
            type: "max",
            current: dataMaxScholar,
            prev: dataMaxScholarPrev
        },
        {
            title: "平均値",
            type: "avg",
            current: Number(dataScholarAvg).toFixed(0),
            prev: Number(dataScholarAvgPrev).toFixed(0)
        },
        {
            title: "最小値",
            type: "min",
            current: dataMinScholar,
            prev: dataMinScholarPrev
        },
        {
            title: "中央値",
            type: "median",
            current: dataScholarMedian,
            prev: dataScholarMedianPrev
        },
        {
            title: "最頻値",
            type: "mode",
            current: dataScholarMode,
            prev: dataScholarModePrev
        },
    ];
    yield put(setPerOwnerData(newDataPerOwner));

    const loadingStateEnd = yield select(loading);
    let loadingUpdate2 = {...loadingStateEnd};
    loadingUpdate2.perOwnerMaxMin = false;
    yield put(setScholarDataLoading(loadingUpdate2));
}

export function* getDeapCheckAmountData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.deapCheck = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const rangeTargetPrev = yield select(scholarDateRangePrev);

        let rangeTargetPrevMinus = moment(rangeTargetPrev[0]).tz('Asia/Singapore').subtract(1, "days").format('YYYY-MM-DD');
        rangeTargetPrevMinus = moment(rangeTargetPrevMinus).isBefore('2021-12-14') ? "2021-12-14" : rangeTargetPrevMinus;

        const sqlQueryDeapCheck = H.SqlHelper.getDeapCheckAmount('JtcbScholarOwnerProd', 'totalDep', rangeTarget[1]);
        const sqlQueryDeapCheckPrev = H.SqlHelper.getDeapCheckAmount('JtcbScholarOwnerProd', 'totalDep', rangeTargetPrev[1] === '2021-12-26' ? '2021-12-27' : rangeTargetPrev[1]);
        const sqlQueryDeapCheckPrevMinus = H.SqlHelper.getDeapCheckAmount('JtcbScholarOwnerProd', 'totalDep', rangeTargetPrevMinus);

        const [
            tempDeapCheck,
            tempDeapCheckPrev,
            tempDeapCheckPrevMinus,
        ] = yield all([
            call(getDataFromRDS, sqlQueryDeapCheck, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryDeapCheckPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryDeapCheckPrevMinus, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedDeapCheck = JSON.parse(tempDeapCheck.body);
        const parsedDeapCheckPrev = JSON.parse(tempDeapCheckPrev.body);
        const parsedDeapCheckPrevMinus = JSON.parse(tempDeapCheckPrevMinus.body);

        const currentDepAmount = Number(parsedDeapCheck[0][0]['stringValue']);
        const prevDepAmount = Number(parsedDeapCheckPrev[0][0]['stringValue']);
        const prevDepAmountMinus = Number(parsedDeapCheckPrevMinus[0][0]['stringValue']);

        const dataDeapCheckAmount = {
            // current: currentDepAmount - prevDepAmount,
            // prev: prevDepAmount - prevDepAmountMinus,
            current: currentDepAmount,
            prev: prevDepAmount,
        }
        yield put(setDeapCheckAmountData(dataDeapCheckAmount));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.deapCheck = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getOwnerByCountry() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ownerByCountry = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const sqlQueryOwnerByCountry = H.SqlHelper.getOwerByCountry(rangeTarget[1], 10);
        const sqlQueryCountUniqueOwner = H.SqlHelper.getUniqueOwnerCount(rangeTarget[1]);

        const [
            tempOwnerByCountry,
            tempCountUniqueOwner,
        ] = yield all([
            call(getDataFromRDS, sqlQueryOwnerByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountUniqueOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedOwnerByCountry = JSON.parse(tempOwnerByCountry.body);
        const parsedUniqueOwnerCount = JSON.parse(tempCountUniqueOwner.body);

        let dataOwnerByCountry = [], eachDataObj = {};
        _.each(parsedOwnerByCountry, function(data) {
            eachDataObj.country = data[0]['stringValue'];
            eachDataObj.value = data[1]['longValue'];
            dataOwnerByCountry.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setOwnerByCountryData(dataOwnerByCountry));

        const uniqueOwnerCountData = {
            date: rangeTarget[1],
            count: parsedUniqueOwnerCount[0][0]['longValue']
        };
        yield put(setUniqueOwnerCountByDateData(uniqueOwnerCountData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ownerByCountry = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarByCountry() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.scholarByCountry = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const sqlQueryScholarByCountry = H.SqlHelper.getScholarByCountry(rangeTarget[1], 10);
        const sqlQueryCountUniqueScholar = H.SqlHelper.getUniqueScholarCount(rangeTarget[1]);

        const [
            tempScholarByCountry,
            tempCountUniqueScholar,
        ] = yield all([
            call(getDataFromRDS, sqlQueryScholarByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountUniqueScholar, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedScholarByCountry = JSON.parse(tempScholarByCountry.body);
        const parsedUniqueScholarCount = JSON.parse(tempCountUniqueScholar.body);

        let dataScholarByCountry = [], eachDataObj = {};
        _.each(parsedScholarByCountry, function(data) {
            eachDataObj.country = data[0]['stringValue'];
            eachDataObj.value = data[1]['longValue'];
            dataScholarByCountry.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarByCountryData(dataScholarByCountry));

        const uniqueScholarCountData = {
            date: rangeTarget[1],
            count: parsedUniqueScholarCount[0][0]['longValue']
        };
        yield put(setUniqueScholarCountByDateData(uniqueScholarCountData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.scholarByCountry = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getStatsByOwner() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.countStatsByOwner = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const sqlQueryScholarCountByOwner = H.SqlHelper.getTableDataByOwner(rangeTarget[1], 'scholarCount', 10);
        const sqlQueryCountAmuletByOwner = H.SqlHelper.getTableDataByOwner(rangeTarget[1], 'amuletCount', 10);
        const sqlQueryTotallDepByOwner = H.SqlHelper.getTableDataByOwnerRank(rangeTarget[1], 'totalDep', 100);

        const sqlQueryTotalAmulet = H.SqlHelper.getTableTotalDataByOwner(rangeTarget[1], 'amuletCount');
        const sqlQueryTotalDep = H.SqlHelper.getTableTotalDataByOwner(rangeTarget[1], 'totalDep');

        const sqlQueryTotallDepByScholar = H.SqlHelper.getTableDataByScholarRank(rangeTarget[1], 100);
        const sqlQueryTotalScholarDep = H.SqlHelper.getTableTotalDataByScholar(rangeTarget[1], 'totalDep');

        const [
            tempScholarCountByOwner,
            tempCountAmuletByOwner,
            tempTotalDepByOwner,
            tempAmuletTotal,
            tempDepTotal,
            tempTotalDepByScholar,
            tempScholarDepTotal,
        ] = yield all([
            call(getDataFromRDS, sqlQueryScholarCountByOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountAmuletByOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotallDepByOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalAmulet, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotallDepByScholar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalScholarDep, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedScholarCountByOwner = JSON.parse(tempScholarCountByOwner.body);
        const parsedCountAmuletByOwner = JSON.parse(tempCountAmuletByOwner.body);
        const parsedTotalDepByOwner = JSON.parse(tempTotalDepByOwner.body);
        const parsedTotalAmulet = JSON.parse(tempAmuletTotal.body);
        const parsedTotalDep = JSON.parse(tempDepTotal.body);
        const parsedTotalDepByScholar = JSON.parse(tempTotalDepByScholar.body);
        const parsedTotalScholarDep = JSON.parse(tempScholarDepTotal.body);

        let dataScholarStatsByOwner = [], eachDataObj = {};
        _.each(parsedScholarCountByOwner, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            dataScholarStatsByOwner.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarCountByOwnerData(dataScholarStatsByOwner));

        dataScholarStatsByOwner = [];
        _.each(parsedCountAmuletByOwner, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            dataScholarStatsByOwner.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarAmuletByOwnerData(dataScholarStatsByOwner));

        dataScholarStatsByOwner = [];
        _.each(parsedTotalDepByOwner, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            eachDataObj.numScholar = data[4]['longValue'];
            eachDataObj.numAmulet = data[5]['longValue'];
            dataScholarStatsByOwner.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarDepByOwnerData(dataScholarStatsByOwner));

        let dataScholarStatsByScholar = [];
        _.each(parsedTotalDepByScholar, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            eachDataObj.owner = data[4]['stringValue'];
            eachDataObj.numAmulet = data[5]['longValue'];
            dataScholarStatsByScholar.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarDepByScholarData(dataScholarStatsByScholar));

        const dataScholarTotalAmulet = {
            date: rangeTarget[1],
            count: Number(parsedTotalAmulet[0][0]['stringValue'])
        };
        const dataScholarTotalDep = {
            date: rangeTarget[1],
            count: Number(parsedTotalDep[0][0]['stringValue'])
        };
        const dataScholarTotalScholarDep = {
            date: rangeTarget[1],
            count: Number(parsedTotalScholarDep[0][0]['stringValue'])
        };
        yield put(setScholarTotalAmuletByDateData(dataScholarTotalAmulet));
        yield put(setScholarTotalDepByDateData(dataScholarTotalDep));
        yield put(setScholarTotalScholarDepByDateData(dataScholarTotalScholarDep));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.countStatsByOwner = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getAllTimeOwnerScholarData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const latestSgtDate = moment().tz("Asia/Singapore").subtract(1, "days").format('YYYY-MM-DD');
        const dayBeforeLastSgtDate = moment().tz("Asia/Singapore").subtract(2, "days").format('YYYY-MM-DD');
        const sqlQueryAllTimeOwnerData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarOwnerProd', latestSgtDate);
        const sqlQueryAllTimeScholarData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarScholarProd', latestSgtDate);
        const sqlQueryAllTimeQuitOwnerData = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarOwnerProd', dayBeforeLastSgtDate);
        const sqlQueryAllTimeQuitScholarData = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarScholarProd', dayBeforeLastSgtDate);
        const [
            tempAllTimeOwner,
            tempAllTimeScholar,
            tempAllTimeOwnerQuit,
            tempAllTimeScholarQuit,
        ] = yield all([
            call(getDataFromRDS, sqlQueryAllTimeOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAllTimeScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAllTimeQuitOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAllTimeQuitScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedAllTimeOwner = JSON.parse(tempAllTimeOwner.body);
        const parsedAllTimeScholar = JSON.parse(tempAllTimeScholar.body);
        const parsedAllTimeOwnerQuit = JSON.parse(tempAllTimeOwnerQuit.body);
        const parsedAllTimeScholarQuit = JSON.parse(tempAllTimeScholarQuit.body);

        const ownerDataObj = {
            stop: parsedAllTimeOwnerQuit[0][0]['longValue'],
            total: parsedAllTimeOwner[0][0]['longValue'],
        };
        const scholarDataObj = {
            stop: parsedAllTimeScholarQuit[0][0]['longValue'],
            total: parsedAllTimeScholar[0][0]['longValue'],
        };

        yield put(setNumOwnerData(ownerDataObj));
        yield put(setNumScholarData(scholarDataObj));

    } catch (err) {
        console.log(err);
    }
}

export function* getScholarTrendData() {
    try {
        const rangeTarget = yield select(scholarDateRange);
        const jwtToken = yield call(getJwtToken);
        const sqlQueryOwnerNewTrendData = H.SqlHelper.getScholarNewTrendData('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryOwnerStopTrendData = H.SqlHelper.getScholarStopTrendData('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryScholarNewTrendData = H.SqlHelper.getScholarNewTrendData('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryScholarStopTrendData = H.SqlHelper.getScholarStopTrendData('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        yield put(setScholarTrendDataLoading(true));

        const [
            tempOwnerNewData,
            tempOwnerStopData,
            tempScholarNewData,
            tempScholarStopData,
        ] = yield all([
            call(getDataFromRDS, sqlQueryOwnerNewTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerStopTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarNewTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarStopTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedOwnerNewData = JSON.parse(tempOwnerNewData.body);
        const parsedOwnerStopData = JSON.parse(tempOwnerStopData.body);
        const parsedScholarNewData = JSON.parse(tempScholarNewData.body);
        const parsedScholarStopData = JSON.parse(tempScholarStopData.body);

        // Owner
        let ownerNewObj = {}, ownerStopObj = {};
        _.each(parsedOwnerNewData, function(data) {
            ownerNewObj[data[0]['stringValue']] = data[1]['longValue'];
        });
        _.each(parsedOwnerStopData, function(data) {
            ownerStopObj[data[0]['stringValue']] = data[1]['longValue'];
        });

        // Scholar
        let scholarNewObj = {}, scholarStopObj = {};
        _.each(parsedScholarNewData, function(data) {
            scholarNewObj[data[0]['stringValue']] = data[1]['longValue'];
        });
        _.each(parsedScholarStopData, function(data) {
            scholarStopObj[data[0]['stringValue']] = data[1]['longValue'];
        });

        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);

        let dataOwnerNewArray = [], dataOwnerStopArray = [], dataScholarNewArray = [], dataScholarStopArray = [];
        _.each(daysArray, function(date, index) {
            if (ownerNewObj[date] === undefined) {
                dataOwnerNewArray.push(0);
            } else {
                dataOwnerNewArray.push(ownerNewObj[date]);
            }

            if (ownerStopObj[date] === undefined || index === daysArray.length - 1) {
                dataOwnerStopArray.push(0);
            } else {
                dataOwnerStopArray.push(ownerStopObj[date]);
            }

            // Scholar
            if (scholarNewObj[date] === undefined) {
                dataScholarNewArray.push(0);
            } else {
                dataScholarNewArray.push(scholarNewObj[date]);
            }

            if (scholarStopObj[date] === undefined || index === daysArray.length - 1) {
                dataScholarStopArray.push(0);
            } else {
                dataScholarStopArray.push(scholarStopObj[date]);
            }
        });

        const trendData = [
            {
                id: 'ownerNew',
                title: "Owner新規",
                data: dataOwnerNewArray,
                date: daysArray,
            },
            {
                id: 'ownerStop',
                title: "Owner停止",
                data: dataOwnerStopArray,
                date: daysArray,
            },
            {
                id: 'scholarNew',
                title: "scholar新規",
                data: dataScholarNewArray,
                date: daysArray,
            },
            {
                id: 'scholarStop',
                title: "Scholar停止",
                data: dataScholarStopArray,
                date: daysArray,
            },
        ];

        yield put(setJtcbScholarTrendData(trendData));
        yield put(setScholarTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarAmuletData() {
    try {
        yield put(setScholarAmuletDataLoading(true));
        const rangeTarget = yield select(scholarDateRange);
        const amuletTarget = yield select(amuletSelected);
        const jwtToken = yield call(getJwtToken);

        // Set date
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);

        let allAmuletDataByArray = [], amuletData = [];
        _.forEach(amuletTarget, function(data, i) {
            let sqlQueryAmuletDataBy = H.SqlHelper.getScholarAmuletDataBy('JtcbScholarLendingProd', rangeTarget, data.value);
            allAmuletDataByArray.push(call(getDataFromRDS, sqlQueryAmuletDataBy, ENDPOINT.GET_DATA_RDS, jwtToken));
        });
        const [dataTemp1, dataTemp2, dataTemp3, dataTemp4, dataTemp5] = yield all(allAmuletDataByArray);
        let dArray = [], dataFinalChart = {};
        if (dataTemp1) {
            const dataReturned1 = JSON.parse(dataTemp1.body);
            let dataObj1 = {};
            _.each(dataReturned1, function(data) {
                dataObj1[data[0]['stringValue']] = data[1]['longValue'];
            });
            _.each(daysArray, function(date, index) {
                let hasKey = _.has(dataObj1, date);
                if (hasKey) {
                    dArray.push(dataObj1[date]);
                } else {
                    dArray.push(0);
                }
            });
            dataFinalChart.id = amuletTarget[0]['value'] === "all" ? "allAmulet" : amuletTarget[0]['value'];
            dataFinalChart.title = amuletTarget[0]['value'] === "all" ? "allAmulet" : amuletTarget[0]['value'];
            dataFinalChart.date = daysArray;
            dataFinalChart.data = dArray;
            amuletData.push(dataFinalChart);
        }
        if (dataTemp2) {
            dArray = []; dataFinalChart = {};
            const dataReturned2 = JSON.parse(dataTemp2.body);
            let dataObj2 = {};
            _.each(dataReturned2, function(data) {
                dataObj2[data[0]['stringValue']] = data[1]['longValue'];
            });
            _.each(daysArray, function(date, index) {
                let hasKey = _.has(dataObj2, date);
                if (hasKey) {
                    dArray.push(dataObj2[date]);
                } else {
                    dArray.push(0);
                }
            });
            dataFinalChart.id = amuletTarget[1]['value'] === "all" ? "allAmulet" : amuletTarget[1]['value'];
            dataFinalChart.title = amuletTarget[1]['value'] === "all" ? "allAmulet" : amuletTarget[1]['value'];
            dataFinalChart.date = daysArray;
            dataFinalChart.data = dArray;
            amuletData.push(dataFinalChart);
        }
        if (dataTemp3) {
            dArray = []; dataFinalChart = {};
            const dataReturned3 = JSON.parse(dataTemp3.body);
            let dataObj3 = {};
            _.each(dataReturned3, function(data) {
                dataObj3[data[0]['stringValue']] = data[1]['longValue'];
            });
            _.each(daysArray, function(date, index) {
                let hasKey = _.has(dataObj3, date);
                if (hasKey) {
                    dArray.push(dataObj3[date]);
                } else {
                    dArray.push(0);
                }
            });
            dataFinalChart.id = amuletTarget[2]['value'] === "all" ? "allAmulet" : amuletTarget[2]['value'];
            dataFinalChart.title = amuletTarget[2]['value'] === "all" ? "allAmulet" : amuletTarget[2]['value'];
            dataFinalChart.date = daysArray;
            dataFinalChart.data = dArray;
            amuletData.push(dataFinalChart);
        }
        if (dataTemp4) {
            dArray = []; dataFinalChart = {};
            const dataReturned4 = JSON.parse(dataTemp4.body);
            let dataObj4 = {};
            _.each(dataReturned4, function(data) {
                dataObj4[data[0]['stringValue']] = data[1]['longValue'];
            });
            _.each(daysArray, function(date, index) {
                let hasKey = _.has(dataObj4, date);
                if (hasKey) {
                    dArray.push(dataObj4[date]);
                } else {
                    dArray.push(0);
                }
            });
            dataFinalChart.id = amuletTarget[3]['value'] === "all" ? "allAmulet" : amuletTarget[3]['value'];
            dataFinalChart.title = amuletTarget[3]['value'] === "all" ? "allAmulet" : amuletTarget[3]['value'];
            dataFinalChart.date = daysArray;
            dataFinalChart.data = dArray;
            amuletData.push(dataFinalChart);
        }
        if (dataTemp5) {
            dArray = []; dataFinalChart = {};
            const dataReturned5 = JSON.parse(dataTemp5.body);
            let dataObj5 = {};;
            _.each(dataReturned5, function(data) {
                dataObj5[data[0]['stringValue']] = data[1]['longValue'];
            });
            _.each(daysArray, function(date, index) {
                let hasKey = _.has(dataObj5, date);
                if (hasKey) {
                    dArray.push(dataObj5[date]);
                } else {
                    dArray.push(0);
                }
            });
            dataFinalChart.id = amuletTarget[4]['value'] === "all" ? "allAmulet" : amuletTarget[4]['value'];
            dataFinalChart.title = amuletTarget[4]['value'] === "all" ? "allAmulet" : amuletTarget[4]['value'];
            dataFinalChart.date = daysArray;
            dataFinalChart.data = dArray;
            amuletData.push(dataFinalChart);
        }
        yield put(setJtcbScholarAmuletData(amuletData));
        yield put(setScholarAmuletDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarAmuletDataByJob() {
    try {
        const rangeTarget = yield select(scholarDateRange);
        const jwtToken = yield call(getJwtToken);
        const sqlQueryData = H.SqlHelper.getScholarAmuletDataByJob('JtcbScholarLendingProd', rangeTarget);
        yield put(setScholarAmuletDataJobLoading(true));

        const [
            tempAmuletData,
        ] = yield all([
            call(getDataFromRDS, sqlQueryData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedAmuletwData = JSON.parse(tempAmuletData.body);
        let amuletAllObj = {}, dataAmuletAllArray = [];
        let totalNum = _.sumBy(parsedAmuletwData, d => {
            return d[1]['longValue'];
        });
        _.each(parsedAmuletwData, function(data, index) {
            amuletAllObj.rank = index+1;
            amuletAllObj.name = data[0]['stringValue'].replace(/�y/g,'').replace(/�z/g,' ');
            amuletAllObj.count = data[1]['longValue'];
            amuletAllObj.ratio = Number((data[1]['longValue'] / totalNum * 100).toFixed(2));
            dataAmuletAllArray.push(amuletAllObj);
            amuletAllObj = {};
        });

        yield put(setJtcbScholarAmuletDataByJob(dataAmuletAllArray));
        yield put(setScholarAmuletDataJobLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarDataByGuild() {
    try {
        const rangeTarget = yield select(scholarDateRange);
        const jwtToken = yield call(getJwtToken);
        yield put(setGuildDataLoading(true));
        yield put(setPageTopLoader(true));

        const sqlQueryData = H.SqlHelper.getScholarLenderPmId(rangeTarget[1]);
        const [
            tempKogiGuildData,
        ] = yield all([
            call(getDataFromRDS, sqlQueryData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        let parsedKogiGuildData = JSON.parse(tempKogiGuildData.body);

        let isSplitKogi = false, kogiGuildFirstHalf = [], kogiGuildSecondHalf = [], dataTempGuild2;
        if (parsedKogiGuildData.length > 175) {
            const half = Math.ceil(parsedKogiGuildData.length / 2);
            kogiGuildFirstHalf = parsedKogiGuildData.slice(0, half);
            kogiGuildSecondHalf = parsedKogiGuildData.slice(-half);
            isSplitKogi = true;
        }

        // Kogi Guild Pmids
        let guildOwnerIds = "", guildOwnerIdsSql = "";
        let guildOwnerIds2 = "", guildOwnerIdsSql2 = "", adjustedTtlDepByPmids2 = 0;
        if (isSplitKogi) {
            _.each(kogiGuildFirstHalf, function(data) {
                guildOwnerIds = guildOwnerIds + `"${data[0]['stringValue']}",`;
                guildOwnerIdsSql = guildOwnerIdsSql + `'${data[0]['stringValue']}',`;
            });
            _.each(kogiGuildSecondHalf, function(data) {
                guildOwnerIds2 = guildOwnerIds2 + `"${data[0]['stringValue']}",`;
                guildOwnerIdsSql2 = guildOwnerIdsSql2 + `'${data[0]['stringValue']}',`;
            });
        } else {
            _.each(parsedKogiGuildData, function(data) {
                guildOwnerIds = guildOwnerIds + `"${data[0]['stringValue']}",`;
                guildOwnerIdsSql = guildOwnerIdsSql + `'${data[0]['stringValue']}',`;
            });            
        }

        // Kyogi Guild Pmids
        let guildKyogiOwnerIds = "", guildKyogiOwnerIdsSql = "";
        _.each(KyogiScholar.item, function(data) {
            guildKyogiOwnerIds = guildKyogiOwnerIds + `"${data}",`;
            guildKyogiOwnerIdsSql = guildKyogiOwnerIdsSql + `'${data}',`;
        });

        // NFT purchase DEP / count
        const sourceKogiGuild = H.ElasticSearch.getTotalNftPurchaseByWideGuild(guildOwnerIds, rangeTarget);
        const searchJtcbKogiGuildPurchase = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceKogiGuild}`};

        const sourceAllGuild = H.ElasticSearch.getTotalNftPurchaseByAll(rangeTarget);
        const searchJtcbGuildAllPurchase = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAllGuild}`};

        const sourceKyogiGuild = H.ElasticSearch.getTotalNftPurchaseByWideGuild(guildKyogiOwnerIds, rangeTarget);
        const searchJtcbKyogiGuildPurchase = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceKyogiGuild}`};

        const [dataTempGuild, dataTempAll, dateTempKyogiGuild ] = yield all([
            call(getDataFromElasticSearch, searchJtcbKogiGuildPurchase, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromElasticSearch, searchJtcbGuildAllPurchase, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromElasticSearch, searchJtcbKyogiGuildPurchase, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);

        if (isSplitKogi) {
            const sourceKogiGuild2 = H.ElasticSearch.getTotalNftPurchaseByWideGuild(guildOwnerIds2, rangeTarget);
            const searchJtcbKogiGuildPurchase2 = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceKogiGuild2}`};
            dataTempGuild2 = yield call(getDataFromElasticSearch, searchJtcbKogiGuildPurchase2, ENDPOINT.GET_DATA_ES_DAA, jwtToken);
        }

        // totalDep
        const dayPrevCutoff = moment(rangeTarget[0]).subtract(1, "days").format('YYYY-MM-DD');
        const sqlQueryTtlDepScholar = H.SqlHelper.getScholarSumTotalDep('JtcbScholarScholarProd', rangeTarget[1]);
        const sqlQueryTtlDepScholarSubtract = H.SqlHelper.getScholarSumTotalDep('JtcbScholarScholarProd', dayPrevCutoff);
        const sqlQueryTtlDepOwner = H.SqlHelper.getScholarSumTotalDep('JtcbScholarOwnerProd', rangeTarget[1]);
        const sqlQueryTtlDepOwnerSubtract = H.SqlHelper.getScholarSumTotalDep('JtcbScholarOwnerProd', dayPrevCutoff);
        const [
            tempTtlDepKogiData,
            tempTtlDepKogiDataSubtract,
            tempTtlDepKogiOwnerData,
            tempTtlDepKogiOwnerDataSubtract,
        ] = yield all([
            call(getDataFromRDS, sqlQueryTtlDepScholar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepScholarSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepOwnerSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedKogiTtlDep = JSON.parse(tempTtlDepKogiData.body);
        const parsedKogiTtlDepSubtract = JSON.parse(tempTtlDepKogiDataSubtract.body);
        const endDateTotalDep = Number(parsedKogiTtlDep[0][0]['stringValue']);
        const prevDateTotalDep = Number(parsedKogiTtlDepSubtract[0][0]['stringValue']);
        const totalDepWithScholar = endDateTotalDep - prevDateTotalDep;

        const parsedKogiTtlDepOwner = JSON.parse(tempTtlDepKogiOwnerData.body);
        const parsedKogiTtlDepOwnerSubtract = JSON.parse(tempTtlDepKogiOwnerDataSubtract.body);
        const endDateTotalDepOwner = Number(parsedKogiTtlDepOwner[0][0]['stringValue']);
        const prevDateTotalDepOwner = Number(parsedKogiTtlDepOwnerSubtract[0][0]['stringValue']);
        const totalDepWithOwner = endDateTotalDepOwner - prevDateTotalDepOwner;

        // DEP give : Kogi
        const allPmIdsForSql = guildOwnerIdsSql.slice(0, -1);
        const sqlQueryTtlDepBySomeOwner= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql, rangeTarget[1]);
        const sqlQueryTtlDepBySomeOwnerPrev= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql, dayPrevCutoff);

        // DEP give : Kyogi
        const allKyogiPmIdsForSql = guildKyogiOwnerIdsSql.slice(0, -1);
        const sqlQueryTtlDepBySomeKyogiOwner= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allKyogiPmIdsForSql, rangeTarget[1]);
        const sqlQueryTtlDepBySomeKyogiOwnerPrev= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allKyogiPmIdsForSql, dayPrevCutoff);

        const [ tempKogiGuildDepTtlData, tempKogiGuildDepTtlDataSubtract,
            tempKyogiGuildDepTtlData, tempKyogiGuildDepTtlDataSubtract ] = yield all([
            call(getDataFromRDS, sqlQueryTtlDepBySomeOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepBySomeOwnerPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepBySomeKyogiOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepBySomeKyogiOwnerPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedKogiGuildDepTtlData = JSON.parse(tempKogiGuildDepTtlData.body);
        const parsedKogiGuildDepTtlDataSubtract = JSON.parse(tempKogiGuildDepTtlDataSubtract.body);
        const adjustedTtlDepByPmids = Number(parsedKogiGuildDepTtlData[0][0]['stringValue']) - Number(parsedKogiGuildDepTtlDataSubtract[0][0]['stringValue']);

        if (isSplitKogi) {
            const allPmIdsForSql2 = guildOwnerIdsSql2.slice(0, -1);
            const sqlQueryTtlDepBySomeOwner2= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql2, rangeTarget[1]);
            const sqlQueryTtlDepBySomeOwnerPrev2= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql2, dayPrevCutoff);

            const [ tempKogiGuildDepTtlData2, tempKogiGuildDepTtlDataSubtract2 ] = yield all([
                call(getDataFromRDS, sqlQueryTtlDepBySomeOwner2, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, sqlQueryTtlDepBySomeOwnerPrev2, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            const parsedKogiGuildDepTtlData2 = JSON.parse(tempKogiGuildDepTtlData2.body);
            const parsedKogiGuildDepTtlDataSubtract2 = JSON.parse(tempKogiGuildDepTtlDataSubtract2.body);
            adjustedTtlDepByPmids2 = Number(parsedKogiGuildDepTtlData2[0][0]['stringValue']) - Number(parsedKogiGuildDepTtlDataSubtract2[0][0]['stringValue']);
        }

        // Kyogi
        const parsedKyogiGuildDepTtlData = JSON.parse(tempKyogiGuildDepTtlData.body);
        const parsedKyogiGuildDepTtlDataSubtract = JSON.parse(tempKyogiGuildDepTtlDataSubtract.body);
        const adjustedTtlDepByKyogiPmids = Number(parsedKyogiGuildDepTtlData[0][0]['stringValue']) - Number(parsedKyogiGuildDepTtlDataSubtract[0][0]['stringValue']);

        const kogiDataObj = {
            ownerCount: parsedKogiGuildData.length+"名",
            purchaseAmount: isSplitKogi ? dataTempGuild.price.value + dataTempGuild2.price.value : dataTempGuild.price.value,
            totalAmount: dataTempAll.price.value,
            purchaseCount: isSplitKogi ? dataTempGuild.count.value + dataTempGuild2.count.value : dataTempGuild.count.value,
            totalCount: dataTempAll.count.value,
            ownerDepGive: adjustedTtlDepByPmids + adjustedTtlDepByPmids2,
            ttlDepGive: totalDepWithScholar + totalDepWithOwner,
        }

        const kyogiDataObj = {
            ownerCount: KyogiScholar.item.length+"名",
            purchaseAmount: dateTempKyogiGuild.price.value,
            totalAmount: dataTempAll.price.value,
            purchaseCount: dateTempKyogiGuild.count.value,
            totalCount: dataTempAll.count.value,
            ownerDepGive: adjustedTtlDepByKyogiPmids,
            ttlDepGive: totalDepWithScholar + totalDepWithOwner,
        }

        yield put(setKogiGuildData(kogiDataObj));
        yield put(setKyogiGuildData(kyogiDataObj));
        yield put(setGuildDataLoading(false));
        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getOwnerScholarStatsData2);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getLendingStatsData);
    //yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getScholarPerOwnerData);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getDeapCheckAmountData);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getOwnerByCountry);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getScholarByCountry);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getStatsByOwner);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getAllTimeOwnerScholarData);
    yield takeEvery(JTCB.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getScholarPerOwnerData);
    yield takeEvery(JTCB.GET_JTCB_SCHOLAR_TREND_DATA, getScholarTrendData);
    yield takeEvery(JTCB.GET_JTCB_SCHOLAR_AMULET_DATA, getScholarAmuletData);
    yield takeEvery(JTCB.GET_JTCB_SCHOLAR_AMULET_DATA_BY_JOB, getScholarAmuletDataByJob);
    yield takeEvery(JTCB.GET_JTCB_SCHOLAR_DATA_BY_GUILD, getScholarDataByGuild);
}
