import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import LineAffiliateTrendStyles from './LineAffiliateTrendStyles';
import { firstTimeColor, conversionColor, regColor, chartLineWidth } from 'styles/variables';

export class LineAffiliateTrend extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seriesType: 'line',
            stackState: '',
            labelColor: '#FFF',
            labelPosition: 'inside',
            zoomStart: 0,
            zoomEnd: 100,
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    async componentDidMount() {

    }

    magicTypeChanged = (param) => {
        console.log('param:', param);
        if (param.currentType === 'stack') {
            this.setState({ stackState: '__ec_magicType_stack__' });
        } else if (param.currentType === 'tiled') {
            this.setState({ stackState: null });
        } else {
            this.setState({ seriesType: param.currentType });
        }
    }

    // dataZoomChanged = (param) => {
    //     this.setState({ zoomStart: param.batch[0]['start'] });
    //     this.setState({ zoomEnd: param.batch[0]['end'] });
    // }

    getSeriesData = (trendData) => {
        let seriesData = [
            {
                name: '初アクセス',
                type: this.state.seriesType,
                //stack: this.state.stackState,
                data: trendData[0]['first'],
                lineStyle: {
                    width: chartLineWidth,
                    color: firstTimeColor
                },
                showSymbol: true,
                symbolSize: 8,
                showAllSymbol: false,
                barMaxWidth: 25,
                barGap: '8%',
            },
            {
                name: 'ゲーム遷移',
                type: this.state.seriesType,
                //stack: this.state.stackState,
                data: trendData[0]['conv'],
                lineStyle: {
                    width: chartLineWidth,
                    color: conversionColor
                },
                showSymbol: true,
                symbolSize: 8,
                showAllSymbol: false,
                barMaxWidth: 25,
                barGap: '8%',
            },
            {
                name: '登録',
                type: this.state.seriesType,
                //stack: this.state.stackState,
                data: trendData[0]['reg'],
                lineStyle: {
                    width: chartLineWidth,
                    color: regColor
                },
                showSymbol: true,
                symbolSize: 8,
                showAllSymbol: false,
                barMaxWidth: 25,
                barGap: '8%',
            },
        ]
        if (this.props.affiliatorType !== 'campaign_game') {
            return seriesData;
        } else {
            const newArray = seriesData.filter(function (eachElem, index) {
                if (index !== 1) {
                    return eachElem;
                }
            })
            return newArray;
        }
    }

    render () {
        const { classes } = this.props;
        let trendData = this.props.trendChartData;
        if (trendData.length < 1 && this.props.loadingTrendData !== true)
            return null;

        let onEvents = {
            'magictypechanged': this.magicTypeChanged,
            // 'datazoom': this.dataZoomChanged
        }

        return (
            <div className={classes.chartContainer}>
                {this.props.loadingTrendData ? (
                    <div className={classes.progress}><CircularProgress /></div>
                ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                            order:'valueDesc',
                        },
                        legend: {
                            data: this.props.affiliatorType !== 'campaign_game' ? ['初アクセス', 'ゲーム遷移', '登録'] : ['初アクセス', '登録']
                        },
                        toolbox: {
                            show: false,
                            feature: {
                                magicType : {
                                    type: ['line', 'bar', /*'stack'*/],
                                    title: {
                                        line: "Line",
                                        bar: "Bar",
                                        //stack: "Stack",
                                    },
                                    icon: {
                                        line: 'image://static/images/font-icon/line-chart.svg',
                                        bar: 'image://static/images/font-icon/bar-chart.svg',
                                    }
                                },
                            }
                        },
                        xAxis: {
                          type: "category",
                          data: trendData[0]['date']
                        },
                        yAxis: {
                          type: "value"
                        },
                        series: this.getSeriesData(trendData),
                        color:this.props.affiliatorType !== 'campaign_game' ? [firstTimeColor, conversionColor, regColor] : [firstTimeColor, regColor]
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                />
                )}
            </div>
        )
    }
}

LineAffiliateTrend.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    trendChartData: state.affiliate.trendChartData,
    noTrendData : state.affiliate.noTrendData,
    loadingTrendData : state.affiliate.loadingTrendData,
    affiliatorType: state.affiliate.affiliatorType,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(LineAffiliateTrendStyles, { withTheme: true })(LineAffiliateTrend));