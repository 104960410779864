import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import * as H from "helper";
import * as C from "components";

const useStyles = makeStyles({
  loaderBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: "flex",
    minHeight: 200,
  },
  dateTableWrapper: {
    float: "left",
  },
  dataTableWrapper: {
    float: "left",
    overflow: "scroll",
  },
  leftTable: {
    border: "1px solid #eee",
  },
  lblGameName: {
    color: "#000",
    fontSize: 13,
  },
  lblUniqueUser: {
    color: "#6f6969",
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: "right",
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: "1px solid #eee6e6",
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: "right",
    verticalAlign: "middle",
    border: "1px solid #eee6e6",
    height: "3em",
    overflowX: "auto",
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: "right",
    verticalAlign: "middle",
    borderBottom: "1px solid #eee6e6",
    borderRight: "1px solid #eee6e6",
    height: 45,
  },
  tTdHeader: {
    paddingLeft: "1rem",
    paddingRight: 19,
    fontWeight: "bold",
    height: 39,
  },
  tTd: {
    height: 44,
    paddingLeft: "1rem",
    borderBottom: "1px solid #eee",
    borderCollapse: "collapse",
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  widgetTitle: {
    flexBasis: "50%",
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: "auto",
    padding: 16,
  },
});

const InGameItemTrendTable = ({
  title,
  trendData,
  trendDataAmount,
  loading,
  noDataText,
  exportTitle,
  legend,
}) => {
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(trendData);
  }, [trendData, trendDataAmount]);

  const getTableColumnName = (tableData) => {
    return (
      <Fragment>
        {tableData.map((d, i) => (
          <tr key={i}>
            <td className={classes.tTd}>
              <div className={classes.lblGameName}>
                {legend[i]}
              </div>
              <div className={classes.lblUniqueUser}></div>
            </td>
          </tr>
        ))}
      </Fragment>
    );
  };

  const getDataHeader = (trendData) => {
    return (
      <Fragment>
        <tr className={classes._GAIv}>
          {trendData[0]["date"].map((item, i) => (
            <td className={classes.dataHeaderTd} key={i}>
              {moment(item).format("M/D")}
            </td>
          ))}
        </tr>
      </Fragment>
    );
  };

  const getDataTable = (trendData) => {
    let dataAdjusted = [];
    _.forEach(trendData, function (value) {
      dataAdjusted.push(value.data);
    });

    const getDataNumers = (numData, allDataArray, index) => {
      const dataPassed = Number(numData);
      return dataPassed > -1
        ? H.FormatNumStrHelper.formatCommaThousand(numData)
        : index === allDataArray.indexOf(0)
        ? ""
        : "";
    };

    return (
      <Fragment>
        {dataAdjusted.map((d, i) => (
          <tr key={i}>
            {d.map((k, j) => (
              <td className={classes.dataCellTd} key={j}>
                {getDataNumers(k, d, j)}
              </td>
            ))}
          </tr>
        ))}
      </Fragment>
    );
  };

  const getExportData = () => {
    let exportData = [];
    if (trendData && trendData[0]["data"].length > 0) {
      let headerArray = ["日付"];
      let numOfHeaderData = trendData[0]["date"].length;
      for (let j = 0; j < numOfHeaderData; j++) {
        headerArray.push(trendData[0]["date"][j]);
      }
      exportData.push(headerArray);
      for (let i = 0; i < trendData.length; i++) {
        let eachDataArray = [legend[i]];
        for (let k = 0; k < trendData[i]["data"].length; k++) {
          eachDataArray.push(trendData[i]["data"][k]);
        }
        exportData.push(eachDataArray);
        eachDataArray = [];
      }
    }
    return exportData;
  };

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}>{title}</div>
        <div className={classes.exportBtnContainer}>
          {tableData[0] !== undefined && tableData[0]["data"].length > 0 && (
            <CSVLink data={getExportData()} filename={exportTitle}>
              <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
              >
                CSV出力
              </Button>
            </CSVLink>
          )}
        </div>
      </div>
      <div className={classes.tableContainer}>
        {loading ? (
          <div className={classes.loaderBox}>
            <img
              className={classes.loaderBarImg}
              src={`/static/images/loader-bar.gif`}
            />
          </div>
        ) : tableData[0] && tableData[0]["data"].length > 0 ? (
          <Fragment>
            <div className={classes.dateTableWrapper}>
              <table className={classes.leftTable}>
                <tbody>
                  <tr>
                    <td className={classes.tTdHeader}></td>
                  </tr>
                  {getTableColumnName(tableData)}
                </tbody>
              </table>
            </div>

            <div className={classes.dataTableWrapper}>
              <table className={classes.dataTableBody}>
                <tbody>
                  {getDataHeader(tableData)}
                  {getDataTable(tableData)}
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <div className={classes.loaderBox}>
            <C.NoData text={noDataText} />
          </div>
        )}
      </div>
    </Paper>
  );
};

InGameItemTrendTable.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  trendDataAmount: state.nftDepTrend.trendDataAmount,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InGameItemTrendTable);
