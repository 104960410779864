// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import {
  setInGameItemSalesTrendItemSelected,
  setInGameItemSalesTrendType,
} from "actions";

const RankTableSelect = ({
  title,
  loading,
  tableData,
  columnData,
  height,
  search,
  type,
  setInGameItemSalesTrendItemSelected,
  inGameItemSalesitemSelected,
  setInGameItemSalesTrendType,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    setSelectedRows([]);
    setInGameItemSalesTrendType(type);
  }, [window.location.pathname]);

  // useEffect(() => {
  //   console.log("TODO inGameItemSalesitemSelected:", inGameItemSalesitemSelected);
  // }, [inGameItemSalesitemSelected]);

  const handleSelectionChange = (selectedRows) => {
    let rowsArray = [],
      itemArray = [];
    const rowsSource = selectedRows;
    _.each(rowsSource, function (row) {
      rowsArray.push(row.tableData.id);
      itemArray.push(row.item);
    });
    setSelectedRows(rowsArray);
    setInGameItemSalesTrendItemSelected(itemArray);
  };

  return (
    <MaterialTable
      isLoading={isLoading}
      style={{ padding: 16, fontSize: 14, height: height, overflow: "scroll" }}
      title={
        <div
          style={{
            fontSize: 14,
            fontWeight: 500,
            color: "#000",
            minWidth: 120,
          }}
        >
          {title}
        </div>
      }
      columns={columnData}
      data={tableData}
      onSelectionChange={handleSelectionChange}
      options={{
        search: search ? true : false,
        paging: false,
        sorting: false,
        rowStyle: {
          textAlign: "center",
        },
        headerStyle: { textAlign: "center" },
        selection: true,
        selectionProps: function (rowData) {
          return {
            disabled:
              selectedRows.length > 0
                ? selectedRows.includes(rowData.tableData.id)
                  ? false
                  : true
                : false,
            checked: selectedRows.includes(rowData.tableData.id)
              ? true
              : false
          };
        },
        showSelectAllCheckbox: false,
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "表示可能なデータがありません",
        },
        toolbar: {
          nRowsSelected: "選択上限1*暫定",
        },
      }}
    />
  );
};

RankTableSelect.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  inGameItemSalesitemSelected: state.inGameItemSalesTrend.itemSelected,
});

const mapDispatchToProps = (dispatch) => ({
  setInGameItemSalesTrendItemSelected: (param) =>
    dispatch(setInGameItemSalesTrendItemSelected(param)),
  setInGameItemSalesTrendType: (param) =>
    dispatch(setInGameItemSalesTrendType(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(RankTableSelect));
