import * as V from 'styles/variables';

const HomeStyles = theme => ({
    pageSectionTop: {
        marginTop: 40,
    },
    cardHeadSection: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleAction: {
        marginLeft: 'auto'
    },
    dataFetchDateLbl: {
        fontSize: 12
    },
    cardTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
    cardContentSection: {
        display: 'flex',
    },
    flexContainer: {
        display: 'flex',
        position: 'fixed',
        backgroundColor: '#fafafa',
        overflown: 'hidden',
        zIndex: 1000,
        width: '100%',
        flexFlow: 'row wrap',
    },
    flexContainerNotFix: {
        display: 'flex',
        backgroundColor: '#fafafa',
        overflown: 'hidden',
        width: '100%',
        flexFlow: 'row wrap',
    },
    flexItem: {
        alignSelf: 'center',
        marginRight: 16,
        paddingLeft: 18,
    },
    flexItemLeft: {
        alignSelf: 'center',
        paddingLeft: 8,
    },
    flexItemLeftBox: {
        alignSelf: 'center',
        paddingLeft: 8,
        paddingRight: 8,
        borderRight: '1px solid #dcdcdc',
    },
    flexItemRight: {
        alignSelf: 'center',
        marginRight: 16,
        paddingLeft: 18,
        paddingRight: 64,
        marginLeft: 'auto',
    },
    flexInnerContainer: {
        display: 'flex',
        backgroundColor: '#fafafa',
        flexWrap: 'wrap',
        //marginLeft: 8,
    },
    flexInnerTotal: {
        alignSelf: 'center',
        marginRight: 16,
        fontSize: 20,
    },
    flexInnerItem: {
        alignSelf: 'center',
        marginRight: 4,
        fontSize: 16,
    },
    sumStats: {
        alignSelf: 'center',
        paddingLeft: 8,
        paddingRight: 24,
        borderRight: '1px solid #dcdcdc',
    },
    fillRemailSpace: {
        flex: 'auto'
    },
    chipLabel: {
        fontSize: 15,
    },
    chipColorStatsTotal: {
        backgroundColor: V.statsTotal,
        color: '#fff',
    },
    chipColorStatsAds: {
        backgroundColor: V.statsAds,
        color: '#fff',
    },
    chipColorStatsOrg: {
        backgroundColor: V.statsOrg,
        color: '#fff',
    },
    chipColorStatsTier1: {
        backgroundColor: V.statsTier1,
        color: '#000',
    },
    chipColorStatsTier2: {
        backgroundColor: V.statsTier2,
        color: '#000',
    },
    chipColorStatsTier3: {
        backgroundColor: V.statsTier3,
        color: '#000',
    },
    iconTooltip: {
        paddingTop: 0,
        paddingLeft: 36,
        fontSize: 16,
        textAlign: 'right',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
        marginTop: 16,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
        marginTop: 16,
    },
    cardActionSection: {
        marginTop: -48,
    }
});

export default HomeStyles;