import { put, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setCookinBurgerGameTrendDataLoading,
  setCookinBurgerGameTrendDataSales,
  setCookinBurgerGameTrendDataUnique,
  setCookinBurgerGameTrendDataUniqueCount,
  setCookinBurgerGameTrendDataAmount
} from "actions";
import { getJwtToken, getDataFromRDS } from "api";
import { COOKINBURGER, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = state => state.page.currentPage;
export const cookinBurgerGamePaidTrendDateRange = state => state.cookinBurgerGamePaidTrend.cookinBurgerGamePaidTrendDateRange;
export const startDate = state => state.cookinBurgerGamePaidTrend.cookinBurgerGamePaidTrendDateRange[0];
export const endDate = state => state.cookinBurgerGamePaidTrend.cookinBurgerGamePaidTrendDateRange[1];

export function* getCookinBurgerNftTrendData() {
  try {
    yield put(setPageTopLoader(true));
    yield put(setCookinBurgerGameTrendDataLoading(true));

    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(cookinBurgerGamePaidTrendDateRange);

    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    dateTo = moment(dateTo).format("YYYY-MM-DD");

    // DATA2.0
    const isDataNew = moment(dateFrom).isAfter('2023-06-24') && moment(dateTo).isAfter('2023-06-24') ? true : false;
    const unixStampTime = [
        moment(rangeTarget[0]+" 00:00:00+8:00").format('X'),
        moment(rangeTarget[1]+" 23:59:59+8:00").format('X')
    ];

    // Count
    const sqlQueryInGameCount = H.SqlHelper.getGameTransactionCountByGameShortTime({
      table: isDataNew ? "CookinBurgerGameSalesPmEvtProd" : "CookinBurgerGameSalesProd",
      product: "repair_shop",
      range: unixStampTime,
    });

    // count unique
    const sqlQueryInGameCountUni = H.SqlHelper.getGameTransactionCountByGameShortTime({
      table: isDataNew ? "CookinBurgerGameSalesPmEvtProd" : "CookinBurgerGameSalesProd",
      product: "repair_shop",
      range: unixStampTime,
      unique: true,
    });

    // Amount
    const sqlQueryInGameAmountByStamina = H.SqlHelper.getGameTransactionAmountByGameShortTime({
      table: isDataNew ? "CookinBurgerGameSalesPmEvtProd" : "CookinBurgerGameSalesProd",
      product: "repair_shop",
      range: unixStampTime,
    });

    const [byCountTemp, byAmtTemp, byUniTemp] = yield all([
      call(getDataFromRDS, sqlQueryInGameCount, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByStamina, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountUni, ENDPOINT.GET_DATA_RDS, jwtToken),
    ]);

    let uniqueRepair = 0, uniqueAll = 0;
    _.forEach(JSON.parse(byUniTemp.body), function (d, i) {
      uniqueRepair = uniqueRepair+d[1]['longValue'];
    });
    const uniqueCountData = [
      {"repair_shop": uniqueRepair}
    ];

    // calendar date range
    const range = moment.range(rangeTarget[0], rangeTarget[1]);
    const days = Array.from(range.by("days"));
    const dateArray = days.map((m) => m.format("YYYY-MM-DD"));

    let allDataCount = [], allDataAmount = [];
    let countArrayStamina = [], amountArrayStamina = [];
    _.forEach(JSON.parse(byCountTemp.body), function (data, i) {
        countArrayStamina.push(data[1]['longValue'])
    });
    _.forEach(JSON.parse(byAmtTemp.body), function (data, i) {
        amountArrayStamina.push(Number(data[1]['doubleValue'].toFixed(0)))
    });

    allDataCount.push(
        {
            id: "ingame-repair-count",
            data: countArrayStamina,
            date: dateArray
        }
    );

    allDataAmount.push(
        {
            id: "ingame-repair-amount",
            data: amountArrayStamina,
            date: dateArray
        }
    );

    yield put(setCookinBurgerGameTrendDataSales(allDataCount));
    yield put(setCookinBurgerGameTrendDataUnique(uniqueCountData));
    yield put(setCookinBurgerGameTrendDataAmount(allDataAmount));
    yield put(setCookinBurgerGameTrendDataUniqueCount(uniqueCountData));
    yield put(setPageTopLoader(false));
    yield put(setCookinBurgerGameTrendDataLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(
    COOKINBURGER.GET_COOKINBURGER_GAME_TREND_PAGE_DATA,
    getCookinBurgerNftTrendData
  );
}
