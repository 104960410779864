// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from '@material-ui/core/styles';
import * as H from 'helper';

const Top10AmountByCampaign = ({
    classes,
    title,
    source,
    loading,
    dataByRid,
    selectedCurrency,
    depPrice,
    metric,
    metricLabel,
}) => {
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        //console.log('yoyo');
    }, []);

    useEffect(() => {
        let dataSource = dataByRid[metric][source];

        if (dataSource.length > 0) {
            let dataArray = [], dataObj = {};
            for (let i=0; i<dataSource.length; i++) {
                dataObj.no = i+1;
                dataObj.rid = dataSource[i]['rid'];
                dataObj.name = dataSource[i]['name'];
                dataObj.uNum = dataSource[i]['numPmid'];
                dataObj.tNum = dataSource[i]['numTx'];
                dataObj.num_amount = selectedCurrency === 'dep' ? dataSource[i]['amount'] : calcConvert(selectedCurrency, dataSource[i]['amount']);
                dataObj.numRatio = dataSource[i]['numRatio'];
                dataArray.push(dataObj);
                dataObj = {};
            }
            setTableData(dataArray);
        }
    }, [dataByRid, selectedCurrency]);

    const getCurrencyLbl = (selectedCurrency) => {
        let lblToReturn = metricLabel;
        if (selectedCurrency === 'usd') {
            lblToReturn = lblToReturn + " (US$)";
        } else if (selectedCurrency === 'sgd') {
            lblToReturn = lblToReturn + " (S$)";
        } else if (selectedCurrency === 'jpy') {
            lblToReturn = lblToReturn + " (¥)";
        }
        return lblToReturn;
    };

    const calcConvert = (currency, amount) => {
        let amtReturn = 0;
        if (currency === 'usd') {
            amtReturn = amount * depPrice.usd;
        } else if (currency === 'sgd') {
            amtReturn = amount * depPrice.sgd;
        } else if (currency === 'jpy') {
            amtReturn = amount * depPrice.jpy;
        }
        return amtReturn;
    };

    return(
        <MaterialTable
            isLoading={loading.ovByRid}
            style={{padding: 16, fontSize: 14}}
            title={<div style={{fontSize: 18, fontWeight: 500, color: '#000'}}>{title}</div>}
            columns={[
                { title: '#', field: 'no', width: 50, cellStyle: {textAlign:'center'}},
                { title: '広告名', field: 'name', width: 350, cellStyle: {textAlign:'left'} },
                { title: 'コード', field: 'rid', width: 70, cellStyle: {textAlign:'center'} },
                { title: 'PMID', field: 'uNum', width: 80, cellStyle: {textAlign:'center'}},
                { title: 'TX', field: 'tNum', width: 80, cellStyle: {textAlign:'center'}},
                { title: getCurrencyLbl(selectedCurrency), field: 'num_amount', width: 200, type: 'numeric', cellStyle: {textAlign:'center'},
                    render: rowData => <div>{H.FormatNumStrHelper.formatCommaThousand(rowData.num_amount)}</div>},
                { title: '全体比率',field: 'numRatio', type: 'numeric', cellStyle: {textAlign:'left', marginLeft: 32},
                    render: rowData => <Fragment>
                                            <div style={{display:'flex', height: 16}}>
                                                <div style={{paddingRight: 16, width: 24}}>{rowData.numRatio}%</div>
                                                <div style={{backgroundColor: '#65C9A5', width:rowData.numRatio+'%', marginLeft: 20, marginTop:3}}></div>
                                            </div>
                                        </Fragment>
                },
            ]}
            data={tableData}
            options={{
                search: false,
                paging: false,
                sorting: false,
                rowStyle: {
                    textAlign:'center',
                },
                headerStyle: {textAlign:'center'}
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: '表示可能なデータがありません',
                },
            }}
        />
    )
};

Top10AmountByCampaign.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    loading: state.nftDepOverview.loading,
    dataByRid: state.nftDepOverview.dataByRid,
    selectedCurrency: state.page.selectedCurrency,
    depPrice: state.page.depPrice,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Top10AmountByCampaign));
