import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, getJtcbScholarTrendData } from 'actions';
import * as C from 'components';
import * as V from 'styles/variables';
import ChartConfig from 'utils/ChartConfig';

const ScholarTrend = ({
    pageTopLoader,
    dateRange,
    trendData,
    setCurrentPage,
    getJtcbScholarTrendData,
    loading,
}) => {
    const classes = useStyles();
    const [chartState, setChartState] = useState(false);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        setCurrentPage("jobtribes/scholar/trend");
        getJtcbScholarTrendData();
    }, []);

    useEffect(() => {
        setChartData(trendData);
        if (trendData[0]['data'].length > 0) {
            setChartState(true);
        }
    }, [trendData]);

    return (
        <Fragment>
            <C.ContentWrapper>
            <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                {loading 
                    ? <div className={classes.loaderBox}>
                        <img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} />
                      </div> 
                    : chartState
                        ? chartData[0]['data'].length > 0
                          ? <C.LineChartNftDepTrend
                                legend={ChartConfig.legendScholarTrend}
                                lblSaveImage={"スカラートレンド"}
                                xAisData={chartData[0]['date']}
                                seriesData={chartData}
                                chartType={'line'}
                            />
                          : <div className={classes.selectGameTxt}>
                              <Chip label="No Data" style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                            </div>
                        : null
                }
                </Grid>
            </Grid>
            <div className={classes.dataTable}>
                <C.TrendTable
                    title="トレンドデータ"
                    noDataText="..."
                    trendData={trendData}
                    dateRange={dateRange}
                    loading={loading}
                    exportTitle="JTCB スカラーデータ"
                />
            </div>
            </C.ContentWrapper>
        </Fragment>
    );
};

ScholarTrend.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    pageTopLoader: state.page.pageTopLoader,
    dateRange: state.page.dateRange,
    trendData: state.jtcbScholarTrend.trendData,
    loading: state.jtcbScholarTrend.loading,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getJtcbScholarTrendData: () => dispatch(getJtcbScholarTrendData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ScholarTrend));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBoxForUnique: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 110,
        },
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        filterSection: {
            display: 'flex',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        dataTable: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        lineChart: {

        },
        selectGameForUnique: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 110,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        selectGameTxt: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 280,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        flexContainerNotFix: {
            display: 'flex',
            overflown: 'hidden',
            width: '100%',
            flexFlow: 'row wrap',
            marginLeft: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBox: {
            alignSelf: 'center',
            paddingLeft: 8,
            paddingRight: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBoxTotal: {
            fontSize: 14,
            alignSelf: 'top',
            paddingLeft: 0,
            paddingRight: 4,
        },
        monthPicker: {
            marginTop: 11,
            marginLeft: 16,
            width: 120,
        },
        cardTitle: {
            fontSize: 16,
        },
        progressFunnel: {
            marginTop: '160px',
            marginBottom: '160px',
        },
        progress4Funnel: {
            marginTop: '160px',
            marginLeft: '30%',
            marginBottom: '160px',
        },
        funnelLegendWrapper: {
            marginTop: '16px',
            marginBottom: '70px',
        },
        funnelLegendRow: {
            display: 'block',
        },
        funnelLegend: {
            width: '30px',
            height: '10px',
            float: 'left',
            clear: 'both',
            paddingBottom: '8px',
        },
        legendLabel: {
            float: 'left',
            marginLeft: '7px',
            fontSize: '13px',
        },
        legendValue: {
            float: 'right',
            fontSize: '13px',
        },
        colorFunnelTop: {
            backgroundColor: '#46aaee',
        },
        colorFunnelBottom: {
            backgroundColor: '#f53b78',
        },
    }),
);
