import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from "moment";
import * as C from 'components';

const ProductStats = ({activeUserHomeData, game, comparisonDateDiffDisplay, title, dateRange, tip, type, lastItem}) => {
    useEffect(() => {
        //console.log('yoyo');
    }, []);

    const getActiveUsersData = (game, type) => {
        let totalData = 0;
        let comparisonDateDiffParsed = comparisonDateDiffDisplay;
        if (game === "jobTribes") {
            if (type === 'dau') {
                totalData = activeUserHomeData["jobTribes"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["jobTribes"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["jobTribes"]["mau"];
            }
        } else if (game === "puzzle") {
            if (type === 'dau') {
                totalData = activeUserHomeData["puzzle"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["puzzle"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["puzzle"]["mau"];
            }
        } else if (game === "luckyFarmer") {
            if (type === 'dau') {
                totalData = activeUserHomeData["luckyFarmer"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["luckyFarmer"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["luckyFarmer"]["mau"];
            }
        } else if (game === "depMarketplace") {
            if (type === 'dau') {
                totalData = activeUserHomeData["depMarketplace"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["depMarketplace"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["depMarketplace"]["mau"];
            }
        } else if (game === "playMining") {
            if (type === 'dau') {
                totalData = activeUserHomeData["playMining"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["playMining"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["playMining"]["mau"];
            }
        } else if (game === "cookinBurger") {
            if (type === 'dau') {
                totalData = activeUserHomeData["cookinBurger"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["cookinBurger"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["cookinBurger"]["mau"];
            }
        } else if (game === "dragonRamen") {
            if (type === 'dau') {
                totalData = activeUserHomeData["dragonRamen"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["dragonRamen"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["dragonRamen"]["mau"];
            }
        } else if (game === "graffiti") {
            if (type === 'dau') {
                totalData = activeUserHomeData["graffiti"]["dau"];
            } else if (type === 'wau') {
                totalData = activeUserHomeData["graffiti"]["wau"];
            } else if (type === 'mau') {
                totalData = activeUserHomeData["graffiti"]["mau"];
            }
        } else if (game === "lostArchive") {
            if (activeUserHomeData["lostArchive"] !== undefined) {
                if (type === 'dau') {
                    totalData = activeUserHomeData["lostArchive"]["dau"];
                } else if (type === 'wau') {
                    totalData = activeUserHomeData["lostArchive"]["wau"];
                } else if (type === 'mau') {
                    totalData = activeUserHomeData["lostArchive"]["mau"];
                }
            }
        } else {
            if (activeUserHomeData[game] !== undefined) {
                if (type === 'dau') {
                    totalData = activeUserHomeData[game]["dau"];
                } else if (type === 'wau') {
                    totalData = activeUserHomeData[game]["wau"];
                } else if (type === 'mau') {
                    totalData = activeUserHomeData[game]["mau"];
                }
            }
        }
        if (game === 'depMarketplace') {
            let beginFrom = moment(dateRange[0]);
            let beginDaa = moment('2021-05-26');
            let daaDateDiff = beginDaa.diff(beginFrom, 'days');
            if (daaDateDiff > 0) {
                comparisonDateDiffParsed = comparisonDateDiffParsed - daaDateDiff;
            }
        }
        //return type === 'dau' ? Math.floor(totalData / comparisonDateDiffParsed) : totalData;
        return totalData;
    };

    const getActivePrevUsersData = (game, type) => {
        let totalPrevData = 0;
        if (game === "jobTribes") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["jobTribes"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["jobTribes"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["jobTribes"]["mauPrev"];
            }
        } else if (game === "puzzle") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["puzzle"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["puzzle"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["puzzle"]["mauPrev"];
            }
        } else if (game === "luckyFarmer") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["luckyFarmer"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["luckyFarmer"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["luckyFarmer"]["mauPrev"];
            }
        } else if (game === "depMarketplace") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["depMarketplace"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["depMarketplace"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["depMarketplace"]["mauPrev"];
            }
        } else if (game === "playMining") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["playMining"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["playMining"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["playMining"]["mauPrev"];
            }
        } else if (game === "cookinBurger") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["cookinBurger"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["cookinBurger"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["cookinBurger"]["mauPrev"];
            }
        } else if (game === "dragonRamen") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["dragonRamen"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["dragonRamen"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["dragonRamen"]["mauPrev"];
            }
        } else if (game === "graffiti") {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData["graffiti"]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData["graffiti"]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData["graffiti"]["mauPrev"];
            }
        } else {
            if (type === 'dau') {
                totalPrevData = activeUserHomeData[game]["dauPrev"];
            } else if (type === 'wau') {
                totalPrevData = activeUserHomeData[game]["wauPrev"];
            } else if (type === 'mau') {
                totalPrevData = activeUserHomeData[game]["mauPrev"];
            } 
        }

        return totalPrevData ? totalPrevData : false;
    };

    const getActiveUsersTrendData = (game, type) => {
        if (game === "jobTribes") {
            if (activeUserHomeData["jobTribes"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["jobTribes"]["dauLastSeven"]["data"];
            if (activeUserHomeData["jobTribes"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["jobTribes"]["wauLastSeven"]["data"];
            if (activeUserHomeData["jobTribes"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["jobTribes"]["mauLastThirty"]["data"];
        } else if (game === "puzzle") {
            if (activeUserHomeData["puzzle"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["puzzle"]["dauLastSeven"]["data"];
            if (activeUserHomeData["puzzle"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["puzzle"]["wauLastSeven"]["data"];
            if (activeUserHomeData["puzzle"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["puzzle"]["mauLastThirty"]["data"];
        } else if (game === "luckyFarmer") {
            if (activeUserHomeData["luckyFarmer"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["luckyFarmer"]["dauLastSeven"]["data"];
            if (activeUserHomeData["luckyFarmer"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["luckyFarmer"]["wauLastSeven"]["data"];
            if (activeUserHomeData["luckyFarmer"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["luckyFarmer"]["mauLastThirty"]["data"];
        } else if (game === "depMarketplace") {
            if (activeUserHomeData["depMarketplace"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["depMarketplace"]["dauLastSeven"]["data"];
            if (activeUserHomeData["depMarketplace"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["depMarketplace"]["wauLastSeven"]["data"];
            if (activeUserHomeData["depMarketplace"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["depMarketplace"]["mauLastThirty"]["data"];
        } else if (game === "playMining") {
            if (activeUserHomeData["playMining"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["playMining"]["dauLastSeven"]["data"];
            if (activeUserHomeData["playMining"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["playMining"]["wauLastSeven"]["data"];
            if (activeUserHomeData["playMining"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["playMining"]["mauLastThirty"]["data"];
        } else if (game === "cookinBurger") {
            if (activeUserHomeData["cookinBurger"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["cookinBurger"]["dauLastSeven"]["data"];
            if (activeUserHomeData["cookinBurger"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["cookinBurger"]["wauLastSeven"]["data"];
            if (activeUserHomeData["cookinBurger"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["cookinBurger"]["mauLastThirty"]["data"];
        } else if (game === "dragonRamen") {
            if (activeUserHomeData["dragonRamen"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["dragonRamen"]["dauLastSeven"]["data"];
            if (activeUserHomeData["dragonRamen"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["dragonRamen"]["wauLastSeven"]["data"];
            if (activeUserHomeData["dragonRamen"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["dragonRamen"]["mauLastThirty"]["data"];
        } else {
            if (activeUserHomeData[game]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData[game]["dauLastSeven"]["data"];
            if (activeUserHomeData[game]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData[game]["wauLastSeven"]["data"];
            if (activeUserHomeData[game]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData[game]["mauLastThirty"]["data"];
        }
    }

    const getLineAxisData = (game, type) => {
        if (game === "jobTribes") {
            if (activeUserHomeData["jobTribes"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["jobTribes"]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData["jobTribes"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["jobTribes"]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData["jobTribes"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["jobTribes"]["mauLastThirty"]["dateRange"];
        } else if (game === "puzzle") {
            if (activeUserHomeData["puzzle"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["puzzle"]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData["puzzle"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["puzzle"]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData["puzzle"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["puzzle"]["mauLastThirty"]["dateRange"];
        } else if (game === "luckyFarmer") {
            if (activeUserHomeData["luckyFarmer"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["luckyFarmer"]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData["luckyFarmer"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["luckyFarmer"]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData["luckyFarmer"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["luckyFarmer"]["mauLastThirty"]["dateRange"];
        } else if (game === "cookinBurger") {
            if (activeUserHomeData["cookinBurger"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["cookinBurger"]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData["cookinBurger"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["cookinBurger"]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData["cookinBurger"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["cookinBurger"]["mauLastThirty"]["dateRange"];
        } else if (game === "depMarketplace") {
            if (activeUserHomeData["depMarketplace"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["depMarketplace"]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData["depMarketplace"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["depMarketplace"]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData["depMarketplace"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["depMarketplace"]["mauLastThirty"]["dateRange"];
        } else if (game === "playMining") {
            if (activeUserHomeData["playMining"]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData["playMining"]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData["playMining"]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData["playMining"]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData["playMining"]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData["playMining"]["mauLastThirty"]["dateRange"];
        } else {
            //return false;
            if (activeUserHomeData[game]["dauLastSeven"] !== undefined && type === 'dau')
                return activeUserHomeData[game]["dauLastSeven"]["dateRange"];
            if (activeUserHomeData[game]["wauLastSeven"] !== undefined && type === 'wau')
                return activeUserHomeData[game]["wauLastSeven"]["dateRange"];
            if (activeUserHomeData[game]["mauLastThirty"] !== undefined && type === 'mau')
                return activeUserHomeData[game]["mauLastThirty"]["dateRange"];
        }
    }

    return(
        <C.StatBox
            title={title}
            value={getActiveUsersData(game, type)}
            prevValue={getActivePrevUsersData(game, type)}
            lineChartData={getActiveUsersTrendData(game, type)}
            divideDayDiff={true}
            tip={tip}
            xAxisData={getLineAxisData(game, type)}
            lastItem={lastItem}
        />
    )
};

ProductStats.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    activeUserHomeData: state.home.activeUserHomeData,
    comparisonDateDiffDisplay: state.page.comparisonDateDiffDisplay,
    dateRange: state.page.dateRange,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ProductStats));