import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getHomePictreeUser,
  getHomePictreeCheckIn,
  getHomePictreePhotoShoot,
  getSalesOverviewDataPicTree,
  getPointOverviewDataPicTree,
} from "actions";
import * as C from "components";
import Users from "components/PicTree/Home/Users";
import SalesStats from "components/PicTree/Kakin/SalesStats"
import PointStats from "components/PicTree/Kakin/PointStats"
import CheckIn from "components/PicTree/Home/CheckIn";
import PhotoShoot from "components/PicTree/Home/PhotoShoot";

const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
  })
);

const PicTreeHome = ({
  setCurrentPage,
  getHomePictreeUser,
  getHomePictreeCheckIn,
  getHomePictreePhotoShoot,
  getSalesOverviewDataPicTree,
  getPointOverviewDataPicTree,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getHomePictreeUser();
    getHomePictreeCheckIn();
    getHomePictreePhotoShoot();
    getSalesOverviewDataPicTree();
    getPointOverviewDataPicTree();
  }, []);

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">ホーム</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree/home" pictree />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              ユーザー
            </Typography>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <Users />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: 8, marginBottom: 8 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom　style={{ marginBottom: 8 }}>
              売上
            </Typography>
            <div className={classes.cardContentSection}>
              <SalesStats />
              <PointStats />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: 8, marginBottom: 8 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              チェックイン
            </Typography>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <CheckIn />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginTop: 8, marginBottom: 8 }}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              撮影物
            </Typography>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <PhotoShoot />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeHome.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  totalRegStats: state.home.totalRegStats,
  dateRange: state.page.dateRange,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomePictreeUser: () => dispatch(getHomePictreeUser()),
  getHomePictreeCheckIn: () => dispatch(getHomePictreeCheckIn()),
  getHomePictreePhotoShoot: () => dispatch(getHomePictreePhotoShoot()),
  getSalesOverviewDataPicTree: () => dispatch(getSalesOverviewDataPicTree()),
  getPointOverviewDataPicTree: () => dispatch(getPointOverviewDataPicTree()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeHome));
