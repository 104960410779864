import * as H from 'helper';
import moment from "moment";

const getTableName = function(target, isDataNew) {
    let tableNameToReturn = '';
    switch (target) {
        case 'nftdep':
        case 'playmining-nft':
            tableNameToReturn = isDataNew ? "DaaMemberActionVisitPmEvtProd" : "DaaMemberActionVisitProd";
            break;
        case 'jobtribes':
            tableNameToReturn = isDataNew ? "JtcbGamePlayStartPmEvtProd" : "JtcbGamePlayStartByPmidProd";
            break;
        case 'luckyfarmer':
            tableNameToReturn = isDataNew ? "LuckyFarmerGamePlayStartV2PmEvtProd" : "LuckyFarmerGamePlayStartProdV2";
            break;
        case 'cookinburger':
            tableNameToReturn = isDataNew ? "CookinBurgerGamePlayStartPmEvtProd" : "CookinBurgerGamePlayStartProd";
            break;
        case 'dragonramen':
            tableNameToReturn = isDataNew ? "DragonRamenGamePlayStartPmEvtProd" : "DragonRamenGamePlayStartProd";
            break;
        case 'graffiti':
        case 'graffitiracer':
            tableNameToReturn = isDataNew ? "GraffitiGamePlayStartPmEvtDevProd" : "GraffitiGamePlayStartProd";
            break;
        case 'lostArchive':
        case 'lostarchiveplus':
            tableNameToReturn = "LostArchivePlusGamePlayStartPmEvtProd";
            break;
        case 'soulfusers':
            tableNameToReturn = "SoulFusersGamePlayStartPmEvtProd";
            break;
        case 'roguerollrulers':
            tableNameToReturn = "RogueRollRulersGamePlayStartPmEvtProd";
            break;
        case 'bouncybunny':
            tableNameToReturn = "BouncyBunnyGamePlayStartPmEvtProd";
            break;
        case 'pictree':
            tableNameToReturn = "DenchuGamePlayStartPmEvtProd";
            break;
        case 'auction':
            tableNameToReturn = "NftDepReceiptAuctionProd";
            break;
        case 'store':
            tableNameToReturn = "NftDepReceiptStoreProd";
            break;
        case 'activeWallet':
            tableNameToReturn = "NftDepReceiptProd";
            break;
        default:
    }
    return tableNameToReturn;
};

const getGameColumnName = function(target) {
    let gameColumValue = '';
    switch (target) {
        case 'nftdep':
        case 'playmining-nft':
            gameColumValue = "nftdep";
            break;
        case 'jobtribes':
            gameColumValue = "JobTribes";
            break;
        case 'puzzle':
            gameColumValue = "Puzzle";
            break;
        case 'luckyfarmer':
            gameColumValue = "luckyfarmer";
            break;
        case 'cookinburger':
            gameColumValue = "cookinburger";
            break;
        case 'dragonramen':
            gameColumValue = "dragonramen";
            break;
        case 'graffiti':
        case 'graffitiracer':
            gameColumValue = "GraffitiRacer";
            break;
        case 'lostArchive':
        case 'lostarchiveplus':
            gameColumValue = "LostArchivePlus";
            break;
        case 'soulfusers':
            gameColumValue = "SoulFusers";
            break;
        case 'roguerollrulers':
            gameColumValue = "RogueRollRulers";
            break;
        case 'bouncybunny':
            gameColumValue = "BouncyBunny";
            break;
        case 'portal':
            gameColumValue = "Portal";
            break;
        default:
    }
    return gameColumValue;
}

const getUnixFromAndToTimestamp = function(size, range) {
    let unixStampToReturn = [];
    const dateFromSubtractArray = H.FormatDateHelper.getSubtractDays(range);
    let dateTo = moment().subtract(1, 'days').format('YYYY/MM/DD');
    let dateFrom = moment().subtract(dateFromSubtractArray[0], dateFromSubtractArray[1]).format('YYYY/MM/DD');
    if (size === 'month') {
        let monthRangeValue = 1;
        if (range === "2month") {
            monthRangeValue = 2;
        } else if (range === "3month") {
            monthRangeValue = 3;
        } else if (range === "6month") {
            monthRangeValue = 6;
        } else if (range === "12month") {
            monthRangeValue = 12;
        }
        dateFrom = moment(dateTo).subtract(monthRangeValue, 'months').startOf('month').format('YYYY/MM/DD');
        dateTo = moment(dateTo).subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
    } else if (size === 'week') {
        let weekRangeValue = 1;
        if (range === "2weeks") {
            weekRangeValue = 2;
        } else if (range === "3weeks") {
            weekRangeValue = 3;
        } else if (range === "6weeks") {
            weekRangeValue = 6;
        } else if (range === "9weeks") {
            weekRangeValue = 9;
        }　else if (range === "12weeks") {
            weekRangeValue = 12;
        }
        dateFrom = moment(dateTo).subtract(weekRangeValue, 'weeks').startOf('week').format('YYYY/MM/DD');
        dateTo = moment(dateTo).subtract(1, 'weeks').endOf('week').format('YYYY/MM/DD');
    }

    return unixStampToReturn = [
        moment(dateFrom+" 00:00:00+8:00").format('x'),
        moment(dateTo+" 23:59:59+8:00").format('x')
    ];
};

const getCustomUnixFromAndToTimestamp = function(dateRange) {
    let unixStampToReturn = [];
    let dateFrom = moment(dateRange[0]).format('YYYY/MM/DD');
    let dateTo = moment(dateRange[1]).format('YYYY/MM/DD');

    return unixStampToReturn = [
        moment(dateFrom+" 00:00:00+8:00").format('x'),
        moment(dateTo+" 23:59:59+8:00").format('x')
    ];
}

const getCustomUnixFromAndToTimestampShort = function(dateRange) {
    let unixStampToReturn = [];
    let dateFrom = moment(dateRange[0]).format('YYYY/MM/DD');
    let dateTo = moment(dateRange[1]).format('YYYY/MM/DD');
    return unixStampToReturn = [
        moment(dateFrom+" 00:00:00+8:00").format('X'),
        moment(dateTo+" 23:59:59+8:00").format('X')
    ];
}

const getFirstTimeNftPurchaseDateRange = function(size, range, cohortDateRange) {
    const dateFromSubtractArray = H.FormatDateHelper.getSubtractDays(range);
    //let dateFrom = size === 'custom' ? range[0] : moment(range[0]).subtract(1, 'days').format('YYYY/MM/DD');
    let dateFrom = moment().subtract(dateFromSubtractArray[0], dateFromSubtractArray[1]).format('YYYY/MM/DD');
    //moment().subtract(dateFromSubtractArray[0], dateFromSubtractArray[1]).format('YYYY/MM/DD');
    let dateTo = size === 'custom' ? cohortDateRange[1] : moment(cohortDateRange[1]).subtract(1, 'days').format('YYYY/MM/DD')
    //moment().subtract(1, 'days').format('YYYY/MM/DD');
    if (size === 'month') {
        let monthRangeValue = 1;
        if (range === "2month") {
            monthRangeValue = 2;
        } else if (range === "3month") {
            monthRangeValue = 3;
        } else if (range === "6month") {
            monthRangeValue = 6;
        } else if (range === "12month") {
            monthRangeValue = 12;
        }
        dateFrom = moment(dateTo).subtract(monthRangeValue, 'months').startOf('month').format('YYYY/MM/DD');
        dateTo = moment(dateTo).subtract(1, 'months').endOf('month').format('YYYY/MM/DD');
    } else if (size === 'week') {
        let weekRangeValue = 1;
        if (range === "2weeks") {
            weekRangeValue = 2;
        } else if (range === "3weeks") {
            weekRangeValue = 3;
        } else if (range === "6weeks") {
            weekRangeValue = 6;
        } else if (range === "9weeks") {
            weekRangeValue = 9;
        }　else if (range === "12weeks") {
            weekRangeValue = 12;
        }
        dateFrom = moment(dateTo).subtract(weekRangeValue, 'weeks').startOf('week').format('YYYY/MM/DD');
        dateTo = moment(dateTo).subtract(1, 'weeks').endOf('week').format('YYYY/MM/DD');
    }
    return [`${dateFrom} 16:00:00`, `${dateTo} 15:59:59`];
}

const SqlHelper = {
    getCohortRid: function(target, size, range, cohortDateRange, isDataNew) {
        // Set Table
        let sqlQueryGetCohortRid = {};
        let tableName = getTableName(target, isDataNew);

        const unixTimestampArray = range === 'custom' ? getCustomUnixFromAndToTimestamp(cohortDateRange) : getUnixFromAndToTimestamp(size, range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        if (isDataNew && target === "lostarchiveplus" ||
            isDataNew && target === "cookinburger") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        }

        const regTable = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';

        if (target !== 'jobtribes' && target !== 'luckyfarmer' && target !== 'cookinburger' && target !== 'dragonramen') {
            const tableRid = tableName+'.rid';
            sqlQueryGetCohortRid = {
                "query": `SELECT COUNT(*) as count, ${tableRid}, CampaignStats.name AS rid FROM ${tableName} JOIN CampaignStats ON ${tableRid} = CampaignStats.rid WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} GROUP BY ${tableRid} ORDER BY count DESC;`,
                "database": "KpiDashboard",
                "type": "list"
            };
        } else {
            sqlQueryGetCohortRid = {
                "query": `SELECT COUNT(*) as count, ${regTable}.rid AS rid, CampaignStats.name AS name
                            FROM ${tableName}
                            JOIN ${regTable} ON ${tableName}.pmId = ${regTable}.pmId
                            JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND CampaignStats.property = '${target}'
                            GROUP BY ${regTable}.rid
                            ORDER BY count DESC;`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }

        return sqlQueryGetCohortRid;
    },

    getCohortCountry: function(target, size, range, cohortDateRange, isDataNew) {
        // Set Table
        let tableName = getTableName(target, isDataNew);

        const unixTimestampArray = range === 'custom' ? getCustomUnixFromAndToTimestamp(cohortDateRange) : getUnixFromAndToTimestamp(size, range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        if (isDataNew && target === "lostarchiveplus") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        }

        if (isDataNew && target === "cookinburger") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        }

        let sqlQueryGetCohortCountry = {
            "query": `SELECT COUNT(*) as count, country AS name FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} GROUP BY country ORDER BY count DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };

        return sqlQueryGetCohortCountry;
    },

    getCohortRegQuery: function (target, size, range, country, rid, cohortDateRange, isDataNew) {
        // Set Table
        let tableName = getTableName(target, isDataNew);
        let gameColumnName = getGameColumnName(target);

        const unixTimestampArray = range === 'custom' ? getCustomUnixFromAndToTimestamp(cohortDateRange) : getUnixFromAndToTimestamp(size, range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        let regDateDivide = 'regDate/1000';
        let logDateDivide = 'logDate/1000';

        if (isDataNew && target === "lostarchiveplus" ||
            isDataNew && target === "cookinburger") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
            regDateDivide = 'regDate';
            logDateDivide = 'logDate';
        }

        let countryQuery = country !== 'all' ? ` AND country = '${country}'` : ``;

        let ridQuery = ``;
        if (target === 'jobtribes' && rid !== 'all') {
            ridQuery = ` AND rid = '${rid.slice(0, -1)}'`
        } else {
            ridQuery = rid !== 'all' ? ` AND rid = '${rid}'` : ``
        }

        const baseWhereClauseGame = `WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}`;
        const baseWhereClauseReg = `WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND game = '${gameColumnName}'`;
        const baseWhereClauseFirstTimeBuy = `WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND contents_id = 'cl8jxanai07njl5rwgg80cxzc'`;
        const whereClauseGameTable = target === 'luckyfarmer' || target === 'cookinburger' || target === 'dragonramen' || target === 'graffitiracer' ? baseWhereClauseGame : baseWhereClauseGame + ` ${ridQuery}`
        const whereClauseRegTable = target === 'luckyfarmer' || target === 'cookinburger' || target === 'dragonramen' || target === 'graffitiracer' ? baseWhereClauseReg + ` ${ridQuery}` : baseWhereClauseReg;
        const regTable = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';
        let loopDays = 7;
        switch (range) {
            case '7days':
                loopDays = 7;
                break;
            case '14days':
                loopDays = 14;
                break;
            case '21days':
                loopDays = 21;
                break;
            case '30days':
                loopDays = 30;
                break;
            case 'custom':
                let endDay = moment(cohortDateRange[1]);
                let startDay = moment(cohortDateRange[0]);
                loopDays = endDay.diff(startDay, 'days')+1;
                break;
            default:
        }
        let sqlQueryGetCohortData = {};
        switch(size) {
            case 'day':
                const groupQueryAdjust = target === 'cookinburger'
                ? ``
                : `, DATE(convert_tz(FROM_UNIXTIME(regDate), 'UTC', 'Asia/Singapore'))`
                sqlQueryGetCohortData = {
                    "query": `SELECT COUNT(DISTINCT a.pmId) as count, first_date
                        FROM (SELECT
                            pmId,
                            DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS login_date
                            FROM ${tableName}
                            ${whereClauseGameTable}
                            GROUP BY pmId,DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) a,
                        (SELECT
                            pmId,
                            min(DATE(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore'))) AS first_date
                            FROM ${regTable}
                            ${whereClauseRegTable}
                            GROUP BY pmId ${groupQueryAdjust}) b
                    WHERE a.pmId=b.pmId
                    GROUP BY first_date
                    ORDER BY first_date;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'week':
                const groupQueryAdjustWeek = target === 'cookinburger'
                ? ``
                : `, week(DATE(convert_tz(FROM_UNIXTIME(regDate), 'UTC', 'Asia/Singapore')))`
                sqlQueryGetCohortData = {
                    "query": `SELECT COUNT(DISTINCT a.pmId) as count, first_week, YEAR
                        FROM
                        (SELECT
                            pmId,
                            week(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) AS login_week
                            FROM ${tableName}
                            ${whereClauseGameTable}
                            GROUP BY pmId, week(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')))) a,
                        (SELECT
                            pmId,
                            min(week(DATE(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore')))) AS first_week,
                            YEAR(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore')) AS YEAR
                            FROM ${regTable}
                            ${whereClauseRegTable}
                            GROUP BY pmId ${groupQueryAdjustWeek}) b
                    WHERE a.pmId=b.pmId
                    GROUP BY first_week
                    ORDER BY first_week`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'month':
                const groupQueryAdjustMonth = target === 'cookinburger'
                ? ``
                : `, month(DATE(convert_tz(FROM_UNIXTIME(regDate), 'UTC', 'Asia/Singapore')))`
                sqlQueryGetCohortData = {
                    "query": `SELECT COUNT(DISTINCT a.pmId) as count, first_month
                        FROM (SELECT
                            pmId,
                            month(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) AS login_week
                            FROM ${tableName}
                            ${whereClauseGameTable}
                            GROUP BY pmId, month(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')))) a,
                        (SELECT
                            pmId,
                            min(month(DATE(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore')))) AS first_month
                            FROM ${regTable}
                            ${whereClauseRegTable}
                            GROUP BY pmId ${groupQueryAdjustMonth}) b
                    WHERE a.pmId=b.pmId
                    GROUP BY first_month
                    ORDER BY first_month`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;
            default:
        }
        return sqlQueryGetCohortData;
    },

    getCohortQuery: function(target, size, range, country, rid, cohortDateRange, isDataNew) {
        // Set Table
        let tableName = getTableName(target, isDataNew);
        let gameColumnName = getGameColumnName(target);

        const unixTimestampArray = range === 'custom' ? getCustomUnixFromAndToTimestamp(cohortDateRange) : getUnixFromAndToTimestamp(size, range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        let regDateDivide = 'regDate/1000';
        let logDateDivide = 'logDate/1000';

        if (isDataNew && target === "lostarchiveplus" ||
            isDataNew && target === "cookinburger") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
            regDateDivide = 'regDate';
            logDateDivide = 'logDate';
        }

        let countryQuery = country !== 'all' ? ` AND country = '${country}'` : ``;

        // Adjust Rid
        // JobTribes is limited 7 length in DB value
        // Need to remove the last char if target is JobTribes
        let ridQuery = ``;
        if (target === 'jobtribes' && rid !== 'all') {
            ridQuery = ` AND rid = '${rid.slice(0, -1)}'`
        } else {
            ridQuery = rid !== 'all' ? ` AND rid = '${rid}'` : ``
        }

        const baseWhereClauseGame = `WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}`;
        const baseWhereClauseReg = `WHERE regDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND game = '${gameColumnName}'`;
        const whereClauseGameTable = target === 'luckyfarmer' || target === 'cookinburger' || target === 'dragonramen' || target === 'graffitiracer' ? baseWhereClauseGame : baseWhereClauseGame + ` ${ridQuery}`
        const whereClauseRegTable = target === 'luckyfarmer' || target === 'cookinburger' || target === 'dragonramen' || target === 'graffitiracer' ? baseWhereClauseReg + ` ${ridQuery}` : baseWhereClauseReg;
        const regTable = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';
        let loopDays = 7;
        switch (range) {
            case '7days':
                loopDays = 7;
                break;
            case '14days':
                loopDays = 14;
                break;
            case '21days':
                loopDays = 21;
                break;
            case '30days':
                loopDays = 30;
                break;
            case 'custom':
                let endDay = moment(cohortDateRange[1]);
                let startDay = moment(cohortDateRange[0]);
                loopDays = endDay.diff(startDay, 'days')+1;
                break;
            default:
        }
        let sumQuery = ``;
        let sqlQueryGetCohortData = {};
        switch(size) {
            case 'day':
                for (let i=0; i<loopDays; i++) {
                    sumQuery = sumQuery + `SUM(CASE WHEN date_number = ${i} THEN 1 ELSE 0 END) AS date_${i},`
                }
                const queryDays = sumQuery.replace(/,\s*$/, "");
                const groupQueryAdjust = target === 'cookinburger'
                    ? ``
                    : `, DATE(convert_tz(FROM_UNIXTIME(regDate), 'UTC', 'Asia/Singapore'))`
                sqlQueryGetCohortData = {
                    "query": `SELECT first_date,
                    ${queryDays}
                    FROM  (
                        SELECT a.pmId,a.login_date,b.first_date AS first_date, DATEDIFF(a.login_date, first_date) AS date_number FROM (SELECT
                                pmId,
                                DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS login_date
                                FROM ${tableName}
                                ${whereClauseGameTable}
                                GROUP BY pmId,DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) a,(SELECT
                                pmId,
                                min(DATE(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore'))) AS first_date
                                FROM ${regTable}
                                ${whereClauseRegTable}
                                GROUP BY pmId ${groupQueryAdjust}) b
                        WHERE a.pmId=b.pmId
                    ) AS with_date_number
                    GROUP BY first_date
                    ORDER BY first_date;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'week':
                const groupQueryAdjustWeek = target === 'cookinburger'
                ? ``
                : `, week(DATE(convert_tz(FROM_UNIXTIME(regDate), 'UTC', 'Asia/Singapore')))`
                sqlQueryGetCohortData = {
                    "query": `SELECT first_week,
                    SUM(CASE WHEN week_number = 0 THEN 1 ELSE 0 END) AS week_0,
                    SUM(CASE WHEN week_number = 1 THEN 1 ELSE 0 END) AS week_1,
                    SUM(CASE WHEN week_number = 2 THEN 1 ELSE 0 END) AS week_2,
                    SUM(CASE WHEN week_number = 3 THEN 1 ELSE 0 END) AS week_3,
                    SUM(CASE WHEN week_number = 4 THEN 1 ELSE 0 END) AS week_4,
                    SUM(CASE WHEN week_number = 5 THEN 1 ELSE 0 END) AS week_5,
                    SUM(CASE WHEN week_number = 6 THEN 1 ELSE 0 END) AS week_6,
                    SUM(CASE WHEN week_number = 7 THEN 1 ELSE 0 END) AS week_7,
                    SUM(CASE WHEN week_number = 8 THEN 1 ELSE 0 END) AS week_8,
                    SUM(CASE WHEN week_number = 9 THEN 1 ELSE 0 END) AS week_9,
                    SUM(CASE WHEN week_number = 10 THEN 1 ELSE 0 END) AS week_10,
                    SUM(CASE WHEN week_number = 11 THEN 1 ELSE 0 END) AS week_11,
                    SUM(CASE WHEN week_number = 12 THEN 1 ELSE 0 END) AS week_12,
                    year_number
                    FROM  (
                        SELECT a.pmId,a.login_week,b.first_week AS first_week,a.login_week-first_week AS week_number, year_number FROM (SELECT
                                pmId,
                                week(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) AS login_week
                                FROM ${tableName}
                                ${whereClauseGameTable}
                                GROUP BY pmId,week(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')))) a,(SELECT
                                pmId,
                                min(week(DATE(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore')))) AS first_week,
                                YEAR(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore')) AS year_number
                                FROM ${regTable}
                                ${whereClauseRegTable}
                                GROUP BY pmId ${groupQueryAdjustWeek}) b
                        WHERE a.pmId=b.pmId
                    ) AS with_week_number
                    GROUP BY first_week
                    ORDER BY first_week;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'month':
                const groupQueryAdjustMonth = target === 'cookinburger'
                ? ``
                : `, month(DATE(convert_tz(FROM_UNIXTIME(regDate), 'UTC', 'Asia/Singapore')))`
                sqlQueryGetCohortData = {
                    "query": `SELECT first_month,
                    SUM(CASE WHEN month_number = 0 THEN 1 ELSE 0 END) AS month_0,
                    SUM(CASE WHEN month_number = 1 THEN 1 ELSE 0 END) AS month_1,
                    SUM(CASE WHEN month_number = 2 THEN 1 ELSE 0 END) AS month_2,
                    SUM(CASE WHEN month_number = 3 THEN 1 ELSE 0 END) AS month_3,
                    SUM(CASE WHEN month_number = 4 THEN 1 ELSE 0 END) AS month_4,
                    SUM(CASE WHEN month_number = 5 THEN 1 ELSE 0 END) AS month_5,
                    SUM(CASE WHEN month_number = 6 THEN 1 ELSE 0 END) AS month_6,
                    SUM(CASE WHEN month_number = 7 THEN 1 ELSE 0 END) AS month_7,
                    SUM(CASE WHEN month_number = 8 THEN 1 ELSE 0 END) AS month_8,
                    SUM(CASE WHEN month_number = 9 THEN 1 ELSE 0 END) AS month_9,
                    SUM(CASE WHEN month_number = 10 THEN 1 ELSE 0 END) AS month_10,
                    SUM(CASE WHEN month_number = 11 THEN 1 ELSE 0 END) AS month_11,
                    SUM(CASE WHEN month_number = 12 THEN 1 ELSE 0 END) AS month_12
                    FROM  (
                        SELECT a.pmId,a.login_month,b.first_month AS first_month,a.login_month-first_month AS month_number FROM (SELECT
                                pmId,
                                month(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) AS login_month
                                FROM ${tableName}
                                ${whereClauseGameTable}
                                GROUP BY pmId,month(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')))) a,(SELECT
                                pmId,
                                min(month(DATE(convert_tz(FROM_UNIXTIME(${regDateDivide}), 'UTC', 'Asia/Singapore')))) AS first_month
                                FROM ${regTable}
                                ${whereClauseRegTable}
                                GROUP BY pmId ${groupQueryAdjustMonth}) b
                        WHERE a.pmId=b.pmId
                    ) AS with_month_number
                    GROUP BY first_month
                    ORDER BY first_month;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;
            default:
        }
        return sqlQueryGetCohortData;
    },

    getCohortNftFirstTimeQuery: function (target, size, range, country, rid, cohortDateRange, isDataNew) {
        // Set Table
        let tableName = getTableName(target, isDataNew);

        const unixTimestampArray = range === 'custom' ? getCustomUnixFromAndToTimestamp(cohortDateRange) : getUnixFromAndToTimestamp(size, range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        let regDateDivide = 'regDate/1000';
        let logDateDivide = 'logDate/1000';

        if (isDataNew && target === "lostarchiveplus" ||
            isDataNew && target === "cookinburger") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
            regDateDivide = 'regDate';
            logDateDivide = 'logDate';
        }

        // nft purchase date
        const purchaseRange = getFirstTimeNftPurchaseDateRange(size, range, cohortDateRange)
        const purchaseStartDate = range === 'custom' ? cohortDateRange[0] : moment(purchaseRange[0]).format('YYYY/MM/DD');
        const purchaseEndDate = range === 'custom' ? cohortDateRange[1] : moment(purchaseRange[1]).format('YYYY/MM/DD');

        let countryQuery = country !== 'all' ? ` AND country = '${country}'` : ``;
        let countryNftQuery = country !== 'all' ? ` AND from_country = '${country}'` : ``;

        let ridQuery = ``;
        if (target === 'jobtribes' && rid !== 'all') {
            ridQuery = ` AND rid = '${rid.slice(0, -1)}'`
        } else {
            ridQuery = rid !== 'all' ? ` AND rid = '${rid}'` : ``
        }
        const contentId = H.ContentsHelper.getContentsIdByName(target);
        const baseWhereClauseGame = `WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}`;
        const whereClauseGameTable = target === 'luckyfarmer' || target === 'cookinburger' || target === 'dragonramen' || target === 'graffitiracer' ? baseWhereClauseGame : baseWhereClauseGame + ` ${ridQuery}`
        const baseWhereClauseFirstTimeBuy = `WHERE logDate BETWEEN '${purchaseStartDate} 16:00:00' AND '${purchaseEndDate} 15:59:59' AND contents_id = '${contentId}' ${countryNftQuery}`;
        let loopDays = 7;
        switch (range) {
            case '7days':
                loopDays = 7;
                break;
            case '14days':
                loopDays = 14;
                break;
            case '21days':
                loopDays = 21;
                break;
            case '30days':
                loopDays = 30;
                break;
            case 'custom':
                let endDay = moment(cohortDateRange[1]);
                let startDay = moment(cohortDateRange[0]);
                loopDays = endDay.diff(startDay, 'days')+1;
                break;
            default:
        }
        let sqlQueryGetCohortData = {};
        switch(size) {
            case 'day':
                sqlQueryGetCohortData = {
                    "query": `SELECT COUNT(DISTINCT a.pmId) as count, first_date
                        FROM (SELECT
                            pmId,
                            DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS login_date
                            FROM ${tableName}
                            ${whereClauseGameTable}
                            GROUP BY pmId,DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) a,
                        (SELECT
                            from_pmid,
                            min(DATE(logDate)) AS first_date
                            FROM SxiNftSalesProdFirstPurchaseV1
                            ${baseWhereClauseFirstTimeBuy}
                            GROUP BY from_pmid) b
                    WHERE a.pmId=b.from_pmid
                    GROUP BY first_date
                    ORDER BY first_date;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'week':
                sqlQueryGetCohortData = {
                    "query": `SELECT COUNT(DISTINCT a.pmId) as count, first_week
                        FROM (SELECT
                            pmId,
                            week(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) AS login_week
                            FROM ${tableName}
                            ${whereClauseGameTable}
                            GROUP BY pmId,DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) a,
                        (SELECT
                            from_pmid,
                            min(week(DATE(logDate))) AS first_week
                            FROM SxiNftSalesProdFirstPurchaseV1
                            ${baseWhereClauseFirstTimeBuy}
                            GROUP BY from_pmid) b
                    WHERE a.pmId=b.from_pmid
                    GROUP BY first_week
                    ORDER BY first_week`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'month':
                sqlQueryGetCohortData = {
                    "query": `SELECT COUNT(DISTINCT a.pmId) as count, first_month
                        FROM (SELECT
                            pmId,
                            month(DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) AS login_week
                            FROM ${tableName}
                            ${whereClauseGameTable}
                            GROUP BY pmId,DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) a,
                        (SELECT
                            from_pmid,
                            min(month(DATE(logDate))) AS first_month
                            FROM SxiNftSalesProdFirstPurchaseV1
                            ${baseWhereClauseFirstTimeBuy}
                            GROUP BY from_pmid) b
                    WHERE a.pmId=b.from_pmid
                    GROUP BY first_month
                    ORDER BY first_month`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;
            default:
        }
        return sqlQueryGetCohortData;
    },

    getCohortNftQuery: function(target, size, range, country, rid, cohortDateRange, isDataNew) {
        // Set Table
        let tableName = getTableName(target, isDataNew);

        const unixTimestampArray = range === 'custom' ? getCustomUnixFromAndToTimestamp(cohortDateRange) : getUnixFromAndToTimestamp(size, range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        let logDateDivide = 'logDate/1000';

        if (isDataNew && target === "lostarchiveplus" ||
            isDataNew && target === "cookinburger") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
            logDateDivide = 'logDate';
        }

        // nft purchase date
        const purchaseRange = getFirstTimeNftPurchaseDateRange(size, range, cohortDateRange)
        const purchaseStartDate = range === 'custom' ? cohortDateRange[0] : moment(purchaseRange[0]).format('YYYY/MM/DD');
        const purchaseEndDate = range === 'custom' ? cohortDateRange[1] : moment(purchaseRange[1]).format('YYYY/MM/DD');

        let countryQuery = country !== 'all' ? ` AND country = '${country}'` : ``;
        let countryNftQuery = country !== 'all' ? ` AND from_country = '${country}'` : ``;

        // Adjust Rid
        // JobTribes is limited 7 length in DB value
        // Need to remove the last char if target is JobTribes
        let ridQuery = ``;
        if (target === 'jobtribes' && rid !== 'all') {
            ridQuery = ` AND rid = '${rid.slice(0, -1)}'`
        } else {
            ridQuery = rid !== 'all' ? ` AND rid = '${rid}'` : ``
        }
        const contentId = H.ContentsHelper.getContentsIdByName(target);
        const baseWhereClauseGame = `WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}`;
        const whereClauseGameTable = target === 'luckyfarmer' || target === 'cookinburger' || target === 'dragonramen' || target === 'graffitiracer' ? baseWhereClauseGame : baseWhereClauseGame + ` ${ridQuery}`
        const baseWhereClauseFirstTimeBuy = `WHERE logDate BETWEEN '${purchaseStartDate} 16:00:00' AND '${purchaseEndDate} 15:59:59' AND contents_id = '${contentId}' ${countryNftQuery}`;
        let loopDays = 7;
        switch (range) {
            case '7days':
                loopDays = 7;
                break;
            case '14days':
                loopDays = 14;
                break;
            case '21days':
                loopDays = 21;
                break;
            case '30days':
                loopDays = 30;
                break;
            case 'custom':
                let endDay = moment(cohortDateRange[1]);
                let startDay = moment(cohortDateRange[0]);
                loopDays = endDay.diff(startDay, 'days')+1;
                break;
            default:
        }
        let sumQuery = ``;
        let sqlQueryGetCohortData = {};
        switch(size) {
            case 'day':
                for (let i=0; i<loopDays; i++) {
                    sumQuery = sumQuery + `SUM(CASE WHEN date_number = ${i} THEN 1 ELSE 0 END) AS date_${i},`
                }
                const queryDays = sumQuery.replace(/,\s*$/, "");
                sqlQueryGetCohortData = {
                    "query": `SELECT first_date,
                    ${queryDays}
                    FROM  (
                        SELECT a.pmId,a.login_date,b.first_date AS first_date, DATEDIFF(a.login_date, first_date) AS date_number FROM (SELECT
                                pmId,
                                DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS login_date
                                FROM ${tableName}
                                ${whereClauseGameTable}
                                GROUP BY pmId,DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))) a,(SELECT
                                from_pmid,
                                min(DATE(logDate)) AS first_date
                                FROM SxiNftSalesProdFirstPurchaseV1
                                ${baseWhereClauseFirstTimeBuy}
                                GROUP BY from_pmid) b
                        WHERE a.pmId=b.from_pmid
                    ) AS with_date_number
                    GROUP BY first_date
                    ORDER BY first_date;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'week':
                sqlQueryGetCohortData = {
                    "query": `SELECT first_week,
                    SUM(CASE WHEN week_number = 0 THEN 1 ELSE 0 END) AS week_0,
                    SUM(CASE WHEN week_number = 1 THEN 1 ELSE 0 END) AS week_1,
                    SUM(CASE WHEN week_number = 2 THEN 1 ELSE 0 END) AS week_2,
                    SUM(CASE WHEN week_number = 3 THEN 1 ELSE 0 END) AS week_3,
                    SUM(CASE WHEN week_number = 4 THEN 1 ELSE 0 END) AS week_4,
                    SUM(CASE WHEN week_number = 5 THEN 1 ELSE 0 END) AS week_5,
                    SUM(CASE WHEN week_number = 6 THEN 1 ELSE 0 END) AS week_6,
                    SUM(CASE WHEN week_number = 7 THEN 1 ELSE 0 END) AS week_7,
                    SUM(CASE WHEN week_number = 8 THEN 1 ELSE 0 END) AS week_8,
                    SUM(CASE WHEN week_number = 9 THEN 1 ELSE 0 END) AS week_9,
                    SUM(CASE WHEN week_number = 10 THEN 1 ELSE 0 END) AS week_10,
                    SUM(CASE WHEN week_number = 11 THEN 1 ELSE 0 END) AS week_11,
                    SUM(CASE WHEN week_number = 12 THEN 1 ELSE 0 END) AS week_12
                    FROM  (
                        SELECT a.pmId,a.login_week,b.first_week AS first_week,a.login_week-first_week AS week_number FROM (SELECT
                                pmId,
                                week(DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))) AS login_week
                                FROM ${tableName}
                                ${whereClauseGameTable}
                                GROUP BY pmId,week(DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')))) a,(SELECT
                                    from_pmid,
                                min(week(DATE(logDate))) AS first_week
                                FROM SxiNftSalesProdFirstPurchaseV1
                                ${baseWhereClauseFirstTimeBuy}
                                GROUP BY from_pmid) b
                        WHERE a.pmId=b.from_pmid
                    ) AS with_week_number
                    GROUP BY first_week
                    ORDER BY first_week;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;

            case 'month':
                sqlQueryGetCohortData = {
                    "query": `SELECT first_month,
                    SUM(CASE WHEN month_number = 0 THEN 1 ELSE 0 END) AS month_0,
                    SUM(CASE WHEN month_number = 1 THEN 1 ELSE 0 END) AS month_1,
                    SUM(CASE WHEN month_number = 2 THEN 1 ELSE 0 END) AS month_2,
                    SUM(CASE WHEN month_number = 3 THEN 1 ELSE 0 END) AS month_3,
                    SUM(CASE WHEN month_number = 4 THEN 1 ELSE 0 END) AS month_4,
                    SUM(CASE WHEN month_number = 5 THEN 1 ELSE 0 END) AS month_5,
                    SUM(CASE WHEN month_number = 6 THEN 1 ELSE 0 END) AS month_6,
                    SUM(CASE WHEN month_number = 7 THEN 1 ELSE 0 END) AS month_7,
                    SUM(CASE WHEN month_number = 8 THEN 1 ELSE 0 END) AS month_8,
                    SUM(CASE WHEN month_number = 9 THEN 1 ELSE 0 END) AS month_9,
                    SUM(CASE WHEN month_number = 10 THEN 1 ELSE 0 END) AS month_10,
                    SUM(CASE WHEN month_number = 11 THEN 1 ELSE 0 END) AS month_11,
                    SUM(CASE WHEN month_number = 12 THEN 1 ELSE 0 END) AS month_12
                    FROM  (
                        SELECT a.pmId,a.login_month,b.first_month AS first_month,a.login_month-first_month AS month_number FROM (SELECT
                                pmId,
                                month(DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))) AS login_month
                                FROM ${tableName}
                                ${whereClauseGameTable}
                                GROUP BY pmId,month(DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')))) a,(SELECT
                                    from_pmid,
                                min(month(DATE(logDate))) AS first_month
                                FROM SxiNftSalesProdFirstPurchaseV1
                                ${baseWhereClauseFirstTimeBuy}
                                GROUP BY from_pmid) b
                        WHERE a.pmId=b.from_pmid
                    ) AS with_month_number
                    GROUP BY first_month
                    ORDER BY first_month;`,
                    "database": "KpiDashboard",
                    "type": "list"
                };
                break;
            default:
        }
        return sqlQueryGetCohortData;
    },

    getAllPmActiveUsers: function(range, country) {
        let countryQuery = ``;
        if (country.value !== undefined) {
            countryQuery = country.value !== 'all' ? `AND country = '${country.value}'` : ``;
        }
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const sqlQueryAllPmUniqueUsers = {
            "query": `SELECT COUNT(DISTINCT pmId) as count
            FROM (
                SELECT pmId
                FROM LuckyFarmerGamePlayStartProdV2
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                UNION
                SELECT pmId
                FROM CookinBurgerGamePlayStartProd
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                UNION
                SELECT pmId
                FROM DragonRamenGamePlayStartProd
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                UNION
                SELECT pmId
                FROM GraffitiGamePlayStartProd
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                UNION
                SELECT pmId
                FROM DaaMemberActionVisitProd
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                UNION
                SELECT pmId
                FROM JtcbGamePlayStartByPmidProd
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}) tables;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryAllPmUniqueUsers;
    },

    getAllPmActiveUsersNew: function(range, country) {
        let countryQuery = ``;
        if (country.value !== undefined) {
            countryQuery = country.value !== 'all' ? `AND country = '${country.value}'` : ``;
        }
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const sqlQueryAllPmUniqueUsers = {
            "query": `SELECT COUNT(DISTINCT pmId) as count
                        FROM (
                            SELECT pmId
                            FROM LuckyFarmerGamePlayStartV2PmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM CookinBurgerGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM DragonRamenGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM GraffitiGamePlayStartPmEvtDevProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM DaaMemberActionVisitPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM LostArchivePlusGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM BouncyBunnyGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM RogueRollRulersGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM LostArchivePlusGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM JtcbGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}) tables;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryAllPmUniqueUsers;
    },

    getAllEachActiveUsers: function(range, tableName, country) {
        let countryQuery = ``;
        if (country.value !== undefined) {
            countryQuery = country.value !== 'all' ? `AND country = '${country.value}'` : ``;
        }
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];

        if (tableName === 'CookinBurgerGamePlayStartPmEvtProd' ||
            tableName === 'LostArchivePlusGamePlayStartPmEvtProd') {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        }
        const sqlQueryAllPmUniqueUsers = {
            "query": `SELECT COUNT(DISTINCT pmId) as count
            FROM (
                SELECT pmId
                FROM ${tableName}
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}) tables;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryAllPmUniqueUsers;
    },

    getAllEachActiveUsersMixOldNew: function(range, tableName, tableName2, country) {
        let countryQuery = ``;
        if (country.value !== undefined) {
            countryQuery = country.value !== 'all' ? `AND country = '${country.value}'` : ``;
        }
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];
        if (tableName === 'CookinBurgerGamePlayStartPmEvtProd') {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        } else {
            unixDateFrom = unixDateFrom;
            unixDateTo = unixDateTo;
        }
        const sqlQueryAllPmUniqueUsers = {
            "query": `SELECT COUNT(DISTINCT pmId) as count
            FROM (
                SELECT pmId
                FROM ${tableName}
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                UNION
                SELECT pmId
                FROM ${tableName2}
                WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}) tables;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryAllPmUniqueUsers;
    },

    getAllPaidUserCount: function(range, country) {
        let countryQuery = ``;
        if (country.value !== undefined) {
            countryQuery = country.value !== 'all' ? `AND country = '${country.value}'` : ``;
        }
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const sqlQueryAllPmUniqueBuyer = {
            "query": `SELECT COUNT(DISTINCT pmId) as distinct_id_count
                        FROM (
                            SELECT from_pmid as pmId
                            FROM SxiNftSalesProd1stV2
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT from_pmid as pmId
                            FROM SxiNftSalesProd2ndV1
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            AND from_pmid != 'n/a'
                            UNION
                            SELECT from_pmid as pmId
                            FROM SxiNftSalesProdFiatV1
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT from_pmid as pmId
                            FROM SxiNftSalesProdFirstPurchaseV1
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM SxiInGameDepSalesProd
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM SxiInGameFiatSalesProd
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM SxiInGameFiatSalesProd2
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM SxiInGameJtcbShopSalesProd
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT from_pmid as pmId
                            FROM SxiNftSalesProd2
                            WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${range[0]}' AND '${range[1]}' ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM CookinBurgerGameSalesPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM LuckyFarmerGameSalesPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM GraffitiRacerGameSalesCryptoPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM GraffitiRacerGameSalesCryptoProdPmEvtDev
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM GraffitiRacerGameSalesSheetPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId
                            FROM GraffitiRacerGameSalesSheetProdPmEvtDev
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}) tables;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryAllPmUniqueBuyer;
    },

    getNftDepTrendDataCount: function(target, range, country) {
        const unixTimestampArray = getCustomUnixFromAndToTimestampShort(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const tableName = getTableName(target);
        let sqlQueryGetNftTrend = {};
        const whereCountryClause = country.length !== 0 ? country.value !== 'all' ? `AND '${country.value}' IN(fromCountry, toCountry)` : `` : ``;
        if (target !== "activeWallet"){
            sqlQueryGetNftTrend = {
                "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(amount) AS COUNT
                        FROM ${tableName}
                WHERE kind = '${target}' ${whereCountryClause} AND logDate BETWEEN ${unixDateFrom} AND ${unixDateTo}
                GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore'))`,
                "database": "KpiDashboard",
                "type": "list"
            }
        } else {
            sqlQueryGetNftTrend = {
                "query": `SELECT
                            DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore')) AS DAY,
                            COUNT(DISTINCT id) AS COUNT
                        FROM (
                            SELECT fromPmid AS id, logDate FROM ${tableName}  WHERE kind REGEXP 'auction|store' ${whereCountryClause} AND logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} UNION ALL
                            SELECT toPmid AS id, logDate FROM ${tableName}  WHERE kind REGEXP 'auction|store' ${whereCountryClause} AND logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND toPmid != 'none'
                        ) AS TX
                        GROUP BY DAY;`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryGetNftTrend;
    },

    getNftDepTrendDataAmount: function(target, range, country) {
        const unixTimestampArray = getCustomUnixFromAndToTimestampShort(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];
        const tableName = getTableName(target);
        let sqlQueryGetNftDepTrendAmount = {};
        const whereCountryClause = country.length !== 0 ? country.value !== 'all' ? `AND '${country.value}' IN(fromCountry, toCountry)` : `` : ``;
        if (target !== "activeWallet"){
            sqlQueryGetNftDepTrendAmount = {
                "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore')) AS DAY, SUM(amount) AS SUM
                        FROM ${tableName}
                WHERE kind = '${target}' ${whereCountryClause} AND logDate BETWEEN ${unixDateFrom} AND ${unixDateTo}
                GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore'))`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryGetNftDepTrendAmount;
    },

    // dau, wau, mau
    getActiveUsersDataByGame: function(target, size, range, country, rid, isDataNew) {
        const tableName = getTableName(target, isDataNew);
        const tblRegName = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';

        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];
        let logDateDivide = 'logDate/1000';

        if (target === "lostarchiveplus") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
            logDateDivide = 'logDate';
        }

        const whereCountryClause = country.length !== 0 ? country.value !== 'all' ? `AND country = '${country.value}'` : `` : ``;
        const whereRidClause = rid.length !== 0 ? rid.value !== 'all' ? `AND rid = '${rid.value}'` : `` : ``;
        const lfCountryWhereClause = country.length !== 0 ? country.value !== 'all' ? `AND T1.country = '${country.value}'` : `` : ``;
        let sqlQueryGetActiveUsers;
        const tz = target === "pictree" ? 'Asia/Tokyo' : 'Asia/Singapore';
        const uid = target === "pictree" ? 'uid' : 'pmId'

        if (size.value === 'dau') {
            sqlQueryGetActiveUsers = {
                "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}')) AS DAY, COUNT(DISTINCT(${uid})) AS DAU
                          FROM ${tableName}
                          WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause} ${whereRidClause}
                          GROUP BY DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}'))`,
                "database": "KpiDashboard",
                "type": "list"
            };
    
            if (target === 'luckyfarmer') {
                let selectQuery = `SELECT DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS DAU
                                   FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause}
                                   GROUP BY DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT DATE(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(T1.pmId)) AS DAU
                        FROM LuckyFarmerGamePlayStartProdV2 AS T1
                        LEFT JOIN ${tblRegName} AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY DATE(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore'));
                        `
                }
                sqlQueryGetActiveUsers = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            } else if (target === 'cookinburger') {
                const cookinUnixFrom = isDataNew ? unixDateFrom/1000 : unixDateFrom;
                const cookinUnixTo = isDataNew ? unixDateTo/1000 : unixDateTo;
                const logDateMil = isDataNew ? 'logDate' : 'logDate/1000';
                const t1logDate = isDataNew ? 'T1.logDate' : 'T1.logDate/1000';
                const tblName = isDataNew ? 'CookinBurgerGamePlayStartPmEvtProd' : 'CookinBurgerGamePlayStartProd';
                let selectQuery = `SELECT DATE(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS DAU
                                   FROM ${tableName} WHERE logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} ${whereCountryClause}
                                   GROUP BY DATE(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore'))`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT DATE(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(T1.pmId)) AS DAU
                        FROM ${tblName} AS T1
                        LEFT JOIN ${tblRegName} AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY DATE(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore'));
                        `
                }
                sqlQueryGetActiveUsers = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            }
        } else if (size.value === 'wau') {
            sqlQueryGetActiveUsers = {
                "query": `SELECT
                            WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}')) AS WEEK,
                            COUNT(DISTINCT(${uid})) AS WAU,
                            YEAR(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}')) AS YEAR,
                            DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                          FROM ${tableName}
                          WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause} ${whereRidClause}
                          GROUP BY WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}'))
                          ORDER BY YEAR_AND_MONTH_AND_DATE;`,
                "database": "KpiDashboard",
                "type": "list"
            }
            if (target === 'luckyfarmer') {
                let selectQuery = `SELECT
                                    WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS WEEK,
                                    COUNT(DISTINCT(pmId)) AS WAU,
                                    YEAR(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS YEAR,
                                    DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                                   FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause}
                                   GROUP BY WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))
                                   ORDER BY YEAR_AND_MONTH_AND_DATE;`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT
                            WEEK(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore')) AS WEEK,
                            COUNT(DISTINCT(T1.pmId)) AS WAU,
                            YEAR(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS YEAR,
                            DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                        FROM LuckyFarmerGamePlayStartProdV2 AS T1
                        LEFT JOIN RegistrationProd AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY WEEK(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore'))
                        ORDER BY YEAR_AND_MONTH_AND_DATE;
                        `
                }
                sqlQueryGetActiveUsers = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            } else if (target === 'cookinburger') {
                const cookinUnixFrom = isDataNew ? unixDateFrom/1000 : unixDateFrom;
                const cookinUnixTo = isDataNew ? unixDateTo/1000 : unixDateTo;
                const logDateMil = isDataNew ? 'logDate' : 'logDate/1000';
                const t1logDate = isDataNew ? 'T1.logDate' : 'T1.logDate/1000';
                const tblName = isDataNew ? 'CookinBurgerGamePlayStartPmEvtProd' : 'CookinBurgerGamePlayStartProd';
                let selectQuery = `SELECT WEEK(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore')) AS WEEK,
                                   COUNT(DISTINCT(pmId)) AS WAU,
                                   YEAR(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore')) AS YEAR,
                                   DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                                   FROM ${tableName} WHERE logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} ${whereCountryClause}
                                   GROUP BY WEEK(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore'))
                                   ORDER BY YEAR_AND_MONTH_AND_DATE;`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT WEEK(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore')) AS WEEK,
                        COUNT(DISTINCT(T1.pmId)) AS WAU,
                        YEAR(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore')) AS YEAR,
                        DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                        FROM ${tblName} AS T1
                        LEFT JOIN ${tblRegName} AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY WEEK(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore'))
                        ORDER BY YEAR_AND_MONTH_AND_DATE;`
                }
                sqlQueryGetActiveUsers = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };                
            } 
        } else if (size.value === 'mau') {
            sqlQueryGetActiveUsers = {
                "query": `SELECT
                            MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}')) AS MONTH,
                            COUNT(DISTINCT(${uid})) AS MAU,
                            DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}'), '%Y-%m') AS YEAR_AND_MONTH
                          FROM ${tableName}
                          WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause} ${whereRidClause}
                          GROUP BY MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', '${tz}'))
                          ORDER BY YEAR_AND_MONTH;`,
                "database": "KpiDashboard",
                "type": "list"
            }
            if (target === 'luckyfarmer') {
                let selectQuery = `SELECT
                                    MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS MONTH,
                                    COUNT(DISTINCT(pmId)) AS MAU,
                                    DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m') AS YEAR_AND_MONTH
                                   FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause}
                                   GROUP BY MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))
                                   ORDER BY YEAR_AND_MONTH;`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT
                        MONTH(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore')) AS MONTH,
                        COUNT(DISTINCT(T1.pmId)) AS DAU,
                        DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m') AS YEAR_AND_MONTH
                        FROM LuckyFarmerGamePlayStartProdV2 AS T1
                        LEFT JOIN RegistrationProd AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY MONTH(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore'))
                        ORDER BY YEAR_AND_MONTH;
                        `
                }
                sqlQueryGetActiveUsers = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            } else if (target === 'cookinburger') {
                const cookinUnixFrom = isDataNew ? unixDateFrom/1000 : unixDateFrom;
                const cookinUnixTo = isDataNew ? unixDateTo/1000 : unixDateTo;
                const logDateMil = isDataNew ? 'logDate' : 'logDate/1000';
                const t1logDate = isDataNew ? 'T1.logDate' : 'T1.logDate/1000';
                const tblName = isDataNew ? 'CookinBurgerGamePlayStartPmEvtProd' : 'CookinBurgerGamePlayStartProd';
                let selectQuery = `SELECT MONTH(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore')) AS MONTH,
                                   COUNT(DISTINCT(pmId)) AS MAU,
                                   DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH
                                   FROM ${tableName} AS T1 WHERE logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} ${whereCountryClause}
                                   GROUP BY MONTH(convert_tz(FROM_UNIXTIME(T1.${logDateMil}), 'UTC', 'Asia/Singapore'))
                                   ORDER BY YEAR_AND_MONTH;`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT MONTH(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore')) AS MONTH,
                        COUNT(DISTINCT(T1.pmId)) AS MAU,
                        DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH
                        FROM ${tblName} AS T1
                        LEFT JOIN ${tblRegName} AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY MONTH(convert_tz(FROM_UNIXTIME(T1.${t1logDate}), 'UTC', 'Asia/Singapore'))
                        ORDER BY YEAR_AND_MONTH;`;
                }
                sqlQueryGetActiveUsers = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };   
            }
        }
        return sqlQueryGetActiveUsers;
    },

    // ref here
    getActiveUsersData: function(target, size, range, country, rid, isDataNew) {
        const tableName = getTableName(target, isDataNew);
        const tblRegName = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];
        let logDateDivide = 'logDate/1000';

        if (target === "lostarchiveplus") {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
            logDateDivide = 'logDate';
        }

        const whereCountryClause = country.length !== 0 ? country.value !== 'all' ? `AND country = '${country.value}'` : `` : ``;
        const whereRidClause = rid.length !== 0 ? rid.value !== 'all' ? `AND rid = '${rid.value}'` : `` : ``;
        const lfCountryWhereClause = country.length !== 0 ? country.value !== 'all' ? `AND T1.country = '${country.value}'` : `` : ``;
        if (size.value === 'dau') {
            let sqlQueryGetActiveUsersDAU = {
                "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS DAU
                          FROM ${tableName}
                          WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause} ${whereRidClause}
                          GROUP BY DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))`,
                "database": "KpiDashboard",
                "type": "list"
            };
            if (target === 'luckyfarmer') {
                let selectQuery = `SELECT DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS DAU
                                   FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause}
                                   GROUP BY DATE(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT DATE(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(T1.pmId)) AS DAU
                        FROM LuckyFarmerGamePlayStartProdV2 AS T1
                        LEFT JOIN ${tblRegName} AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY DATE(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore'));
                        `
                }
                sqlQueryGetActiveUsersDAU = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            }
            if (target === 'cookinburger') {
                const cookinUnixFrom = isDataNew ? unixDateFrom/1000 : unixDateFrom;
                const cookinUnixTo = isDataNew ? unixDateTo/1000 : unixDateTo;
                const logDateMil = isDataNew ? 'logDate' : 'logDate/1000';
                const t1logDate = isDataNew ? 'T1.logDate' : 'T1.logDate/1000';
                const tblName = isDataNew ? 'CookinBurgerGamePlayStartPmEvtProd' : 'CookinBurgerGamePlayStartProd';
                let selectQuery = `SELECT DATE(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS DAU
                                   FROM ${tableName} WHERE logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} ${whereCountryClause}
                                   GROUP BY DATE(convert_tz(FROM_UNIXTIME(${logDateMil}), 'UTC', 'Asia/Singapore'))`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT DATE(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(T1.pmId)) AS DAU
                        FROM ${tblName} AS T1
                        LEFT JOIN ${tblRegName} AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${cookinUnixFrom} AND ${cookinUnixTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY DATE(convert_tz(FROM_UNIXTIME(${t1logDate}), 'UTC', 'Asia/Singapore'));
                        `
                }
                sqlQueryGetActiveUsersDAU = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            }
            return sqlQueryGetActiveUsersDAU;
        } else if (size.value === 'wau') {
            let sqlQueryGetActiveUsersWAU = {
                "query": `SELECT
                            WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS WEEK,
                            COUNT(DISTINCT(pmId)) AS WAU,
                            YEAR(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS YEAR,
                            DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                          FROM ${tableName}
                          WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause} ${whereRidClause}
                          GROUP BY WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))
                          ORDER BY YEAR_AND_MONTH_AND_DATE;`,
                "database": "KpiDashboard",
                "type": "list"
            }
            if (target === 'luckyfarmer') {
                let selectQuery = `SELECT
                                    WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS WEEK,
                                    COUNT(DISTINCT(pmId)) AS WAU,
                                    YEAR(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS YEAR,
                                    DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                                   FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause}
                                   GROUP BY WEEK(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))
                                   ORDER BY YEAR_AND_MONTH_AND_DATE;`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT
                            WEEK(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore')) AS WEEK,
                            COUNT(DISTINCT(T1.pmId)) AS WAU,
                            YEAR(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS YEAR,
                            DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m-%d') AS YEAR_AND_MONTH_AND_DATE
                        FROM LuckyFarmerGamePlayStartProdV2 AS T1
                        LEFT JOIN RegistrationProd AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY WEEK(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore'))
                        ORDER BY YEAR_AND_MONTH_AND_DATE;
                        `
                }
                sqlQueryGetActiveUsersWAU = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            }
            return sqlQueryGetActiveUsersWAU;
        } else if (size.value === 'mau') {
            let sqlQueryGetActiveUsersMAU = {
                "query": `SELECT
                            MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS MONTH,
                            COUNT(DISTINCT(pmId)) AS MAU,
                            DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m') AS YEAR_AND_MONTH
                          FROM ${tableName}
                          WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause} ${whereRidClause}
                          GROUP BY MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))
                          ORDER BY YEAR_AND_MONTH;`,
                "database": "KpiDashboard",
                "type": "list"
            }
            if (target === 'luckyfarmer') {
                let selectQuery = `SELECT
                                    MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY,
                                    COUNT(DISTINCT(pmId)) AS DAU,
                                    DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m') AS YEAR_AND_MONTH
                                   FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${whereCountryClause}
                                   GROUP BY MONTH(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'))
                                   ORDER BY YEAR_AND_MONTH;`;
                if (whereRidClause !== "") {
                    selectQuery = `
                        SELECT
                        　MONTH(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore')) AS DAY,
                        COUNT(DISTINCT(T1.pmId)) AS DAU,
                        DATE_FORMAT(convert_tz(FROM_UNIXTIME(${logDateDivide}), 'UTC', 'Asia/Singapore'), '%Y-%m') AS YEAR_AND_MONTH
                        FROM LuckyFarmerGamePlayStartProdV2 AS T1
                        LEFT JOIN RegistrationProd AS T2 ON T1.pmId = T2.pmId
                        WHERE T1.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND T2.rid = '${rid.value}' ${lfCountryWhereClause}
                        GROUP BY MONTH(convert_tz(FROM_UNIXTIME(T1.${logDateDivide}), 'UTC', 'Asia/Singapore'))
                        ORDER BY YEAR_AND_MONTH;
                        `
                }
                sqlQueryGetActiveUsersMAU = {
                    "query": selectQuery,
                    "database": "KpiDashboard",
                    "type": "list"
                };
            }
            return sqlQueryGetActiveUsersMAU;
        }
    },

    getAllPmActiveUsersCountry: function(range) {
        const sqlQueryGetAllPmActiveUsersCountry = {
            "query": `SELECT country, SUM(total) AS total
                      FROM PlayMiningDauProd
                      WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') BETWEEN STR_TO_DATE('${range[0]}', '%Y-%m-%d') AND STR_TO_DATE('${range[1]}', '%Y-%m-%d')
                      GROUP BY country
                      ORDER BY total DESC LIMIT 20`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryGetAllPmActiveUsersCountry;
    },

    getAllPmActiveUsersDAU: function(size, range, country) {
        let countryQuery = ``;
        if (country.value !== undefined) {
            countryQuery = country.value !== 'all' ? `AND country = '${country.value}'` : ``;
        }
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];

        const sqlQueryAllPmUniqueUsersDAU = {
            // "query": `SELECT dateStr AS DATE, SUM(total) AS COUNT
            //           FROM PlayMiningDauProd
            //           WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') BETWEEN STR_TO_DATE('${range[0]}', '%Y-%m-%d') AND STR_TO_DATE('${range[1]}', '%Y-%m-%d')
            //           ${countryQuery}
            //           GROUP BY dateStr`,
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(DISTINCT(pmId)) AS DAU
                        FROM (
                            SELECT pmId, logDate
                            FROM LuckyFarmerGamePlayStartV2PmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM LuckyFarmerGamePlayStartProdV2
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate*1000 AS logDate
                            FROM CookinBurgerGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM CookinBurgerGamePlayStartProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM DragonRamenGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM DragonRamenGamePlayStartProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM GraffitiGamePlayStartPmEvtDevProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM GraffitiGamePlayStartProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM DaaMemberActionVisitPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM DaaMemberActionVisitProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM JtcbGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM JtcbGamePlayStartByPmidProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                            UNION
                            SELECT pmId, logDate*1000 AS logDate
                            FROM LostArchivePlusGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom/1000} AND ${unixDateTo/1000} ${countryQuery}
                            UNION
                            SELECT pmId, logDate
                            FROM BouncyBunnyGamePlayStartPmEvtProd
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${countryQuery}
                        ) AS Ts
                        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))`,
            "database": "KpiDashboard",
            "type": "list"
            //
        }
        return sqlQueryAllPmUniqueUsersDAU;
    },

    getActiveUsersCountry: function(target, range, isDataNew) {
        const tableName = getTableName(target, isDataNew);
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];
        if (target === 'cookinburger' && isDataNew ||
            target === 'lostarchiveplus' && isDataNew) {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        }
        const sqlQueryGetActiveUsersCountry = {
            "query": `SELECT country, COUNT(DISTINCT(pmId)) AS count
                      FROM ${tableName}
                      WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo}
                      GROUP BY country
                      ORDER BY count DESC LIMIT 20`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryGetActiveUsersCountry;
    },

    getNftDepTrendCountry: function(target, range) {
        const tableName = getTableName(target);
        const unixDateFrom = range[0];
        const unixDateTo = range[1];
        const sqlQueryGetNftDepTrendCountry = {
            "query": `SELECT fromCountry, count(fromCountry) AS count
                      FROM ${tableName}
                      WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND fromCountry != 'n/a'
                      GROUP BY fromCountry ORDER BY count DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryGetNftDepTrendCountry;
    },

    getActiveUsersRid: function(target, range) {
        // Set Table
        let sqlQueryGetActiveUsersRid = {};
        const tableName = getTableName(target);
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        const unixDateFrom = unixTimestampArray[0];
        const unixDateTo = unixTimestampArray[1];

        if (target === 'puzzle' || target === 'nftdep' || target === 'playmining-nft') {
            const tableRid = tableName+'.rid';
            sqlQueryGetActiveUsersRid = {
                "query": `SELECT COUNT(*) as count, ${tableRid}, CampaignStats.name AS rid FROM ${tableName} JOIN CampaignStats ON ${tableRid} = CampaignStats.rid WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} GROUP BY ${tableRid} ORDER BY count DESC;`,
                "database": "KpiDashboard",
                "type": "list"
            };
        } else if (target === 'jobtribes')  {
            sqlQueryGetActiveUsersRid = {
                "query": `SELECT rid, COUNT(rid) AS count FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND rid != 'organic' GROUP BY rid ORDER BY count DESC;`,
                "database": "KpiDashboard",
                "type": "list"
            }
        } else if (target === 'luckyfarmer' || target === 'cookinburger') {
            sqlQueryGetActiveUsersRid = {
                "query": `SELECT COUNT(*) as count, RegistrationProd.rid AS rid, CampaignStats.name AS name
                            FROM ${tableName}
                            JOIN RegistrationProd ON ${tableName}.pmId = RegistrationProd.pmId
                            JOIN CampaignStats ON RegistrationProd.rid = CampaignStats.rid
                            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND CampaignStats.property = '${target}'
                            GROUP BY RegistrationProd.rid
                            ORDER BY count DESC;`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryGetActiveUsersRid;
    },

    getActiveUsersRidByGame: function(target, range, isDataNew) {
        // Set Table
        let sqlQueryGetActiveUsersRid = {};
        const tableName = getTableName(target, isDataNew);
        const unixTimestampArray = getCustomUnixFromAndToTimestamp(range);
        let unixDateFrom = unixTimestampArray[0];
        let unixDateTo = unixTimestampArray[1];
        if (target === 'cookinburger' && isDataNew ||
            target === 'lostarchiveplus' && isDataNew) {
            unixDateFrom = unixDateFrom/1000;
            unixDateTo = unixDateTo/1000;
        }

        let query = `
            select count(*) as count, ${tableName}.rid, CampaignStats.name AS name
            FROM ${tableName}
            JOIN CampaignStats ON ${tableName}.rid = CampaignStats.rid
            WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} and ${tableName}.rid != 'organic'
            group by ${tableName}.rid order by count desc;
        `;

        const regTableName = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';
        if (target === 'cookinburger') {
            query = `
                SELECT COUNT(*) as count, ${regTableName}.rid AS rid, CampaignStats.name AS name
                FROM CookinBurgerGamePlayStartPmEvtProd
                JOIN ${regTableName} ON CookinBurgerGamePlayStartPmEvtProd.pmId = ${regTableName}.pmId
                JOIN CampaignStats ON ${regTableName}.rid = CampaignStats.rid
                WHERE logDate BETWEEN 1690387200 AND 1690991999 AND ${regTableName}.rid != 'organic'
                GROUP BY ${regTableName}.rid
                ORDER BY count DESC;
            `
        }

        sqlQueryGetActiveUsersRid = {
            "query": query,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryGetActiveUsersRid;
    },

    getAllRid: function(target) {
        let sqlQueryGetAllRid = {
            "query": `SELECT rid, name FROM CampaignStats WHERE property = '${target}';`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryGetAllRid;
    },

    // Unique Active Users count by countries
    getUniqueUserCountByCountry: function(target, unixDateFrom, unixDateTo, limit, isDataNew) {
        const tableName = getTableName(target, isDataNew);
        const unixDateFromCast = isDataNew && target === "cookinburger" ? unixDateFrom/1000 : unixDateFrom;
        const unixDateToCast = isDataNew && target === "cookinburger" ? unixDateTo/1000 : unixDateTo;
        let sqlQueryGetUuCountByCountry = {
            "query": `SELECT country, COUNT(DISTINCT pmId) as count
            FROM ${tableName}
            WHERE logDate BETWEEN ${unixDateFromCast} AND ${unixDateToCast}
            GROUP BY country
            ORDER BY count DESC LIMIT ${limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryGetUuCountByCountry;
    },

    // JTCB scholar
    getUniqueCountByColumnWithRange: function(tableName, columnName, unixDateFrom, unixDateTo) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT ${columnName}) as count FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getUniqueCountScholar: function(tableName, columnName, dateStr) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(${columnName}) FROM ${tableName} WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getDeapCheckAmount: function(tableName, columnName, dateStr) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${columnName}) FROM ${tableName} WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getOwerByCountry: function(dateStr, limit) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT country, COUNT(DISTINCT(JtcbScholarOwnerProd.pmId)) AS count FROM JtcbScholarOwnerProd JOIN JtcbGameUsersProd ON JtcbScholarOwnerProd.pmId = JtcbGameUsersProd.pmId WHERE STR_TO_DATE(JtcbScholarOwnerProd.dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d') GROUP BY country ORDER BY count DESC LIMIT ${limit}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarByCountry: function(dateStr, limit) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT country, COUNT(DISTINCT(JtcbScholarScholarProd.pmId)) AS count FROM JtcbScholarScholarProd JOIN JtcbGameUsersProd ON JtcbScholarScholarProd.pmId = JtcbGameUsersProd.pmId WHERE STR_TO_DATE(JtcbScholarScholarProd.dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d') GROUP BY country ORDER BY count DESC LIMIT ${limit}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getUniqueOwnerCount: function(dateStr) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT(ownerId)) AS count FROM JtcbScholarOwnerProd WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d');`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getUniqueScholarCount: function(dateStr) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT(pmId)) AS count FROM JtcbScholarScholarProd WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d');`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getTableDataByOwner: function(dateStr, columnName, limit) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT userName, JtcbScholarOwnerProd.pmId, country, ${columnName} FROM JtcbScholarOwnerProd INNER JOIN JtcbGameUsersProd ON JtcbScholarOwnerProd.pmId = JtcbGameUsersProd.pmId WHERE STR_TO_DATE(JtcbScholarOwnerProd.dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d') GROUP BY userName, JtcbScholarOwnerProd.pmId, ${columnName} ORDER BY ${columnName} DESC LIMIT ${limit};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getTableDataByOwnerRank: function(dateStr, columnName, limit) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT userName, JtcbScholarOwnerProd.pmId, country, ${columnName}, scholarCount, amuletCount FROM JtcbScholarOwnerProd INNER JOIN JtcbGameUsersProd ON JtcbScholarOwnerProd.pmId = JtcbGameUsersProd.pmId WHERE STR_TO_DATE(JtcbScholarOwnerProd.dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d') GROUP BY userName, JtcbScholarOwnerProd.pmId, ${columnName} ORDER BY ${columnName} DESC LIMIT ${limit};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getTableTotalDataByOwner: function(dateStr, columnName) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${columnName}) FROM JtcbScholarOwnerProd WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d');`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getTableDataByScholarRank: function(dateStr, limit) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT JtcbScholarScholarProd.userName, JtcbScholarScholarProd.pmId, country, JtcbScholarScholarProd.totalDep, JtcbScholarOwnerProd.userName, JtcbScholarScholarProd.amuletCount FROM JtcbScholarScholarProd JOIN JtcbGameUsersProd ON JtcbScholarScholarProd.pmId = JtcbGameUsersProd.pmId JOIN JtcbScholarOwnerProd ON JtcbScholarScholarProd.ownerId = JtcbScholarOwnerProd.ownerId WHERE STR_TO_DATE(JtcbScholarScholarProd.dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d') GROUP BY JtcbScholarScholarProd.userName, JtcbScholarScholarProd.pmId, JtcbScholarScholarProd.totalDep ORDER BY JtcbScholarScholarProd.totalDep DESC LIMIT ${limit};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getTableTotalDataByScholar: function(dateStr, columnName) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${columnName}) FROM JtcbScholarScholarProd WHERE STR_TO_DATE(dateStr, '%Y-%m-%d') = STR_TO_DATE('${dateStr}', '%Y-%m-%d');`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarAllTimeNumber: function(tableName, dateStr) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(pmId) AS count FROM ${tableName} WHERE dateStr = '${dateStr}'`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarNewReg: function(tableName, dateStrBegin, dateStrEnd) {
        dateStrBegin = dateStrBegin.replace(/\//g, '-');
        dateStrEnd = dateStrEnd.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(t1.userName) AS count FROM ${tableName} t1 WHERE t1.dateStr = (SELECT MIN(t2.dateStr) FROM ${tableName} t2 WHERE t2.userName = t1.userName) AND STR_TO_DATE(t1.dateStr, '%Y-%m-%d') BETWEEN STR_TO_DATE('${dateStrBegin}', '%Y-%m-%d') AND STR_TO_DATE('${dateStrEnd}', '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarAllTimeQuit: function(tableName, dateStr) {
        dateStr = dateStr.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(t1.userName) AS count FROM ${tableName} t1 WHERE t1.dateStr = (SELECT MAX(t2.dateStr) FROM ${tableName} t2 WHERE t2.userName = t1.userName) AND STR_TO_DATE(t1.dateStr, '%Y-%m-%d') BETWEEN STR_TO_DATE('2021-12-14', '%Y-%m-%d') AND STR_TO_DATE('${dateStr}', '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarNewTrendData: function(tableName, dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT t.first_date, COUNT(*) AS count FROM (SELECT userName, MIN(dateStr) AS first_date FROM ${tableName} GROUP BY userName) t WHERE t.first_date BETWEEN STR_TO_DATE('${dateFrom}', '%Y-%m-%d') AND STR_TO_DATE('${dateTo}', '%Y-%m-%d') GROUP BY t.first_date`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarStopTrendData: function(tableName, dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const sqlQueryToReturn = {
            "query": `SELECT t.first_date, COUNT(*) AS count FROM (SELECT userName, MAX(dateStr) AS first_date FROM ${tableName} GROUP BY userName) t WHERE t.first_date BETWEEN STR_TO_DATE('${dateFrom}', '%Y-%m-%d') AND STR_TO_DATE('${dateTo}', '%Y-%m-%d') GROUP BY t.first_date`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarAmuletData: function(tableName, range) {
        const unixDateFrom = moment(range[0]+" 00:00:00+8:00").format('x');
        const unixDateTo = moment(range[1]+" 23:59:59+8:00").format('x');
        return {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(*) AS count FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getScholarAmuletDataBy: function(tableName, range, target) {
        const unixDateFrom = moment(range[0]+" 00:00:00+8:00").format('x');
        const unixDateTo = moment(range[1]+" 23:59:59+8:00").format('x');
        const likeQuery = target !== "all" ? `AND job LIKE '%${target}%'` : ``;
        return {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(*) AS count FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} ${likeQuery} GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getScholarAmuletDataByJob: function(tableName, range) {
        const unixDateFrom = moment(range[0]+" 00:00:00+8:00").format('x');
        const unixDateTo = moment(range[1]+" 23:59:59+8:00").format('x');
        return {
            "query": `SELECT job, COUNT(*) AS count FROM ${tableName} WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} GROUP BY job ORDER BY count DESC`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getMaxMinData: function(tableName, metric, column, range) {
        const order = metric === "MAX" ? "DESC" : "ASC";
        return {
            "query": `SELECT ${column} FROM ${tableName} WHERE ${range} ORDER BY ${column} ${order} LIMIT 1`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getMedianData: function(tableName, column, range) {
        return {
            "query": `SELECT AVG(middle_values) AS 'median' FROM (SELECT t1.${column} AS 'middle_values' FROM(SELECT @row:=@row+1 as \`row\`, x.${column} FROM ${tableName} AS x, (SELECT @row:=0) AS r WHERE ${range} ORDER BY x.${column}) AS t1,(SELECT COUNT(*) as 'count' FROM ${tableName} x WHERE ${range}) AS t2 WHERE t1.row >= t2.count/2 and t1.row <= ((t2.count/2) +1)) AS t3;`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getModeData: function(tableName, column, range) {
        return {
            "query": `SELECT ${column}, count(*) as count FROM ${tableName} WHERE ${range} GROUP BY ${column} HAVING count(*) >= (SELECT MAX(CNT) FROM (SELECT COUNT(*) AS CNT FROM ${tableName} WHERE ${range} GROUP BY ${column}) as C)`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getAvgData: function(tableName, column, range) {
        return {
            "query": `SELECT AVG(${column}) FROM ${tableName} WHERE ${range}`,
            "database": "KpiDashboard",
            "type": "list"
        };
    },

    getNftDepTxBy: function(metric, fromClause, whereClause, unixDateFrom, unixDateTo) {
        return {
            "query": `SELECT ${metric} FROM ${fromClause} WHERE ${whereClause} BETWEEN ${unixDateFrom} AND ${unixDateTo}`,
            "database": "KpiDashboard",
            "type": "list"
        }
    },

    getNftDepTxByRid: function(fromTable, metricTable, metric, limit, unixDateFrom, unixDateTo) {
        return {
            "query": `SELECT g.rid, c.name, COUNT(DISTINCT s.fromPmid) as numPmid, COUNT(g.rid) as countTx, SUM(s.amount) as dep
            FROM ${fromTable} s
            JOIN ${metricTable} g ON s.fromPmid = g.pmId
            JOIN CampaignStats c ON g.rid = c.rid
            WHERE s.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND c.property = '${metric}'
            GROUP BY g.rid
            ORDER BY dep DESC
            LIMIT ${limit};`,
            "database": "KpiDashboard",
            "type": "list"
        }
    },

    getNftDepTxSumByRid: function(fromTable, metricTable, metric, unixDateFrom, unixDateTo) {
        return {
            "query": `SELECT SUM(s.amount) as dep
            FROM ${fromTable} s
            JOIN ${metricTable} g ON s.fromPmid = g.pmId
            JOIN CampaignStats c ON g.rid = c.rid
            WHERE s.logDate BETWEEN ${unixDateFrom} AND ${unixDateTo} AND c.property = '${metric}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
    },

    getScholarLenderPmId: function(dateStr) {
        return {
            //"query": `SELECT DISTINCT ownerPmId from JtcbScholarLendingProd WHERE logDate BETWEEN ${unixDateFrom} AND ${unixDateTo};`,
            "query": `SELECT DISTINCT pmId from JtcbScholarOwnerProd WHERE dateStr = '${dateStr}';`,
            "database": "KpiDashboard",
            "type": "list"
        }
    },

    getScholarSumTotalDep: function(fromTable, dateStr) {
        return {
            "query": `SELECT SUM(totalDep) FROM ${fromTable} WHERE dateStr = '${dateStr}';`,
            "database": "KpiDashboard",
            "type": "list"
        }
    },

    getScholarSomeSumTotalDep: function(fromTable, pmidStr, dateStr) {
        const inClause = `(${pmidStr})`
        return {
            "query": `SELECT SUM(totalDep) FROM ${fromTable} WHERE pmId in ${inClause} AND dateStr = '${dateStr}';`,
            "database": "KpiDashboard",
            "type": "list"
        }
    },

    // CookinBurger Scholar
    getScholarAllTimeCreateCancelFromGa: function(tableName) {
        const sqlQueryToReturn = {
            "query": `select CONCAT(\`ownerId\`, '-', \`scholarId\`) as contractId, type, COUNT(*) as count from ${tableName} group by contractId order by count DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarAllTimeCreateFromGa: function(tableName, type) {
        const sqlQueryToReturn = {
            "query": `select CONCAT(\`ownerId\`, '-', \`scholarId\`) as contractId, type, COUNT(*) as count from ${tableName} where type = '${type}' group by contractId having count(contractId) MOD 2 = 1 order by count DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarTotalDepFromGa: function(tableName, column) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${column}) FROM ${tableName}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarRangeCreateCancelFromGa: function(tableName, ts) {
        const sqlQueryToReturn = {
            "query": `select CONCAT(\`ownerId\`, '-', \`scholarId\`) as contractId, type, COUNT(*) as count from ${tableName} where logDate BETWEEN ${ts[0]} AND ${ts[1]} group by contractId order by count DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarRangeCreateFromGa: function(tableName, type, ts) {
        const sqlQueryToReturn = {
            "query": `select CONCAT(\`ownerId\`, '-', \`scholarId\`) as contractId, type, COUNT(*) as count from ${tableName} where type = '${type}' AND logDate BETWEEN ${ts[0]} AND ${ts[1]} group by contractId having count(contractId) MOD 2 = 1 order by count DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarRangeTotalDepFromGa: function(tableName, column, ts) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${column}) FROM ${tableName} WHERE logDate BETWEEN ${ts[0]} AND ${ts[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarAllTimeNumberFromGa: function(tableName, column, type, unixStampTime) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT ${column}) AS count FROM ${tableName} WHERE type = '${type}' AND logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarNewOwnerRegFromGa: function(tableName, ts) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(t1.ownerId) AS count FROM ${tableName} t1 WHERE t1.type = 'create' AND t1.logDate = (SELECT MIN(t2.logDate) FROM ${tableName} t2 WHERE t2.ownerId = t1.ownerId) AND t1.logDate BETWEEN ${ts[0]} AND ${ts[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarNewScholarRegFromGa: function(tableName, ts) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(t1.scholarId) AS count FROM ${tableName} t1 WHERE t1.type = 'create' AND t1.logDate = (SELECT MIN(t2.logDate) FROM ${tableName} t2 WHERE t2.scholarId = t1.scholarId) AND t1.logDate BETWEEN ${ts[0]} AND ${ts[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarNewOwnerCancelFromGa: function(tableName, ts) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(t1.ownerId) AS count FROM ${tableName} t1 WHERE t1.type = 'cancel' AND t1.logDate = (SELECT MAX(t2.logDate) FROM ${tableName} t2 WHERE t2.ownerId = t1.ownerId) AND t1.logDate BETWEEN ${ts[0]} AND ${ts[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getScholarNewScholarCancelFromGa: function(tableName, ts) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(t1.scholarId) AS count FROM ${tableName} t1 WHERE t1.type = 'cancel' AND t1.logDate = (SELECT MAX(t2.logDate) FROM ${tableName} t2 WHERE t2.scholarId = t1.scholarId) AND t1.logDate BETWEEN ${ts[0]} AND ${ts[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    // SXI
    getTodaysDepSales: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(order_price) as orderPrice, SUM(commission) as commission, count(*) as count FROM market_order JOIN market ON market_order.market_id = market.id
                      WHERE market_order.order_status = "MATCHED"
                        AND market_order.trade_type = "SELL"
                        AND market.exhibitor_type = "${params.type}"
                        AND market_order.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getDepSalesInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS orderPrice
                      FROM transfer
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.type}' AND transfer.status = 'COMPLETED'`
        }
        return sqlQueryToReturn;
    },

    getMarketStatsInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT MAX(order_price) as max, AVG(order_price) as avg, MIN(order_price) as min FROM market_order JOIN market ON market_order.market_id = market.id
                      WHERE market_order.order_status = "MATCHED"
                        AND market_order.trade_type = "SELL"
                        AND market.exhibitor_type = "${params.type}"
                        AND market_order.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getRegByXDate: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT tier, COUNT(*) as count FROM user WHERE create_datetime <= '${params.range[0]}' GROUP BY tier ORDER BY tier`
        }
        return sqlQueryToReturn;
    },

    getMarketWithDrawalFeesInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(amount) as SUM FROM transfer
                      WHERE event_type = "WITHDRAWAL"
                        AND create_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getMarketWithDrawalFeesInRange2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(amount) as SUM FROM SxiWithdrawalsFeeProd
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    // Not in Use: 2022/12/29
    getMarketUniquePmidCountWithPrimarySales: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT market_order.account_id) as COUNT FROM market_order JOIN market ON market_order.market_id = market.id
            WHERE market_order.order_status = "MATCHED"
            AND market_order.trade_type = "BUY"
            AND market.exhibitor_type = 'OFFICIAL_STORE'
            AND market_order.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    // Not in Use: 2022/12/29
    getMarketUniquePmidCountWithSecondarySales: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT market_order.account_id) as COUNT FROM market_order JOIN market ON market_order.market_id = market.id
            WHERE market_order.order_status = "MATCHED"
            AND market.exhibitor_type = 'USERS'
            AND market_order.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getMarketCountByTxType: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT market_order.account_id) as COUNT FROM market_order JOIN market ON market_order.market_id = market.id
            WHERE market_order.order_status = "MATCHED"
            AND market.market_type = "${params.marketType}"
            AND market_order.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getStakingCountByStatus: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT count(*) as COUNT FROM staking WHERE status = "${params.status}"
            AND ${params.timeType} BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getStakingDepAmountByStatus: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(amount) as SUM FROM staking WHERE status = "${params.status}"
            AND ${params.timeType} BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getTransferEventTypes: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DISTINCT(event_type) FROM transfer WHERE ${params.timeType} BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionSumByType: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price'
        let countryWhereClause = ``;
        if (params.country.length > 0) {
            let commaSeparated = params.country.join();
            commaSeparated = commaSeparated.replace(/"/g, "'")
            countryWhereClause = `AND from_country IN (${commaSeparated})`
        }
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(logDate, '%Y-%m-%d') AS DATE, SUM(${sumColumn}) AS SUM FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' AND event_type = '${params.tradeType}'
                      ${countryWhereClause} ${gameWhereClause}
                      GROUP BY DATE_FORMAT(convert_tz(logDate, 'UTC', 'Asia/Singapore'), '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionUnique: function(params) {
        let countryWhereClause = ``;
        if (params.country.length > 0) {
            let commaSeparated = params.country.join();
            commaSeparated = commaSeparated.replace(/"/g, "'")
            countryWhereClause = `AND from_country IN (${commaSeparated})`
        }
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT from_pmid) AS COUNT FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' AND event_type = '${params.tradeType}'
                      ${countryWhereClause} ${gameWhereClause}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByType: function(params) {
        let countryWhereClause = ``;
        if (params.country.length > 0) {
            let commaSeparated = params.country.join();
            commaSeparated = commaSeparated.replace(/"/g, "'")
            countryWhereClause = `AND from_country IN (${commaSeparated})`
        }
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(logDate, '%Y-%m-%d') AS DATE, COUNT(*) AS COUNT FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' AND event_type = '${params.tradeType}'
                      ${countryWhereClause} ${gameWhereClause}
                      GROUP BY DATE_FORMAT(convert_tz(logDate, 'UTC', 'Asia/Singapore'), '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionUniqueCountByType: function(params) {
        let countryWhereClause = ``;
        if (params.country.length > 0) {
            let commaSeparated = params.country.join();
            commaSeparated = commaSeparated.replace(/"/g, "'")
            countryWhereClause = `AND from_country IN (${commaSeparated})`
        }
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(logDate, '%Y-%m-%d') AS DATE, COUNT(DISTINCT from_pmid) AS COUNT FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' AND event_type = '${params.tradeType}'
                      ${countryWhereClause} ${gameWhereClause}
                      GROUP BY DATE_FORMAT(convert_tz(logDate, 'UTC', 'Asia/Singapore'), '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionActiveWallet: function(params) {
        let countryWhereClause = ``;
        if (params.country.length > 0) {
            let commaSeparated = params.country.join();
            commaSeparated = commaSeparated.replace(/"/g, "'")
            countryWhereClause = `AND from_country IN (${commaSeparated})`
        }
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(logDate, '%Y-%m-%d') AS DATE, COUNT(DISTINCT from_pmid) AS COUNT FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${countryWhereClause} ${gameWhereClause}
                      GROUP BY DATE_FORMAT(convert_tz(logDate, 'UTC', 'Asia/Singapore'), '%Y-%m-%d')`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountry: function(params) {
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT from_country, COUNT(*) AS COUNT FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${gameWhereClause}
                      GROUP BY from_country ORDER BY COUNT DESC`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountry2: function(params) {
        let gameWhereClause = ``;
        if (params.game.length > 0) {
            let gameSeparated = params.game.join();
            gameSeparated = gameSeparated.replace(/"/g, "'")
            gameWhereClause = `AND contents_id IN (${gameSeparated})`
        }
        const sqlQueryToReturn = {
            "query": `SELECT TC.from_country, TC.count
                      FROM (
                          SELECT Ts.from_country, count(*) AS count
                          FROM (
                              SELECT from_country FROM SxiNftSalesProd1stV2 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' AND amount > 1
                              UNION ALL
                              SELECT from_country FROM SxiNftSalesProd2ndV1 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' AND from_country != 'n/a'
                              UNION ALL
                              SELECT from_country FROM SxiNftSalesProdFiatV1 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                          ) AS Ts
                          GROUP BY Ts.from_country
                      ) AS TC
                      GROUP BY TC.from_country
                      ORDER BY TC.count DESC`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionGames: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT contents_id, COUNT(*) AS COUNT FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      GROUP BY contents_id ORDER BY COUNT DESC`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionGames2: function(params) {
      const sqlQueryToReturn = {
        "query": `SELECT TC.contents_id, TC.count
                  FROM (
                      SELECT Ts.contents_id, count(*) AS count
                      FROM (
                          SELECT contents_id FROM SxiNftSalesProd1stV2 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                          UNION ALL
                          SELECT contents_id FROM SxiNftSalesProd2ndV1 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                          UNION ALL
                          SELECT contents_id FROM SxiNftSalesProdFiatV1 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ) AS Ts
                      GROUP BY Ts.contents_id
                  ) AS TC
                  GROUP BY TC.contents_id
                  ORDER BY TC.count DESC`,
          "database": "KpiDashboard",
          "type": "list"
      }
      return sqlQueryToReturn;
  },

    // cl8jxahul0005l5rwa9bb6til GR 'TRADE_1ST'
    // cl8jxahuf0001l5rwewc19q7j CB
    getNftTransactonByGame: function(params) {
        // DATE_FORMAT(convert_tz(transfer.create_datetime, 'UTC', 'Asia/Singapore'), '%Y-%m-%d')
        // AND transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(transfer.create_datetime, '%Y-%m-%d') AS DATE, COUNT(*) as COUNT, SUM(reference_price) AS PRICE FROM transfer
                    JOIN token ON transfer.token_id = token.id
                    JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                    WHERE transfer.symbol = '${params.symbol}'
                    AND transfer.amount > 0
                    AND transfer.event_type = '${params.tradeType}'
                    AND transfer.status = 'COMPLETED'
                    AND asset_contents_bind.contents_id = '${params.contentId}'
                    AND transfer.create_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    GROUP BY DATE(transfer.create_datetime)
                    ORDER BY transfer.create_datetime`
        }
        return sqlQueryToReturn;
    },

    // src
    getNftTransactionByGame3: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(t1.logDate, '%Y-%m-%d') AS DATE, COUNT(*) as COUNT, SUM(t1.${sumColumn}) AS PRICE, COUNT(distinct t1.from_pmid) as uni
                    FROM ${params.table} t1
                    ${leftOnQuery}
                    WHERE t1.contents_id = '${params.contentId}'
                    AND t1.event_type = '${params.tradeType}'
                    AND t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    ${excludeQuery}
                    GROUP BY DATE(t1.logDate)
                    ORDER BY t1.logDate`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactonByGameAndRarity: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(transfer.create_datetime, '%Y-%m-%d') AS DATE, COUNT(*) as COUNT, SUM(reference_price) AS PRICE FROM transfer
                    JOIN token ON transfer.token_id = token.id
                    JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                    JOIN asset_tag ON token.asset_id = asset_tag.asset_id
                    WHERE transfer.symbol = '${params.symbol}'
                    AND transfer.amount > 0
                    AND transfer.event_type = '${params.tradeType}'
                    AND transfer.status = 'COMPLETED'
                    AND asset_contents_bind.contents_id = '${params.contentId}'
                    AND asset_tag.tag = '${params.rarity}'
                    AND transfer.create_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    GROUP BY DATE(transfer.create_datetime)
                    ORDER BY transfer.create_datetime`
        }
        return sqlQueryToReturn;
    },

    getNftTransactonByGameAndRarityAll: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(t1.logDate, '%Y-%m-%d') AS DATE, COUNT(*) as COUNT, SUM(t1.${sumColumn}) AS PRICE
                    FROM ${params.table} t1
                    ${leftOnQuery}
                    WHERE t1.contents_id = '${params.contentId}'
                    AND t1.event_type = '${params.tradeType}'
                    AND t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    ${excludeQuery}
                    ORDER BY t1.logDate`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionByGameAndUniqueByDate: function(params) {
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(t1.logDate, '%Y-%m-%d') AS DATE, COUNT(DISTINCT t1.from_pmid) AS count
                    FROM ${params.table} t1
                    ${leftOnQuery}
                    WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    AND t1.event_type = '${params.tradeType}'
                    AND t1.contents_id = '${params.contentId}'
                    ${excludeQuery}
                    GROUP BY DATE(t1.logDate)
                    ORDER BY t1.logDate`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getNftTransactionByGameAndUniqueByRange: function(params) {
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(DISTINCT t1.from_pmid) AS count
                    FROM ${params.table} t1
                    ${leftOnQuery}
                    WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    AND t1.event_type = '${params.tradeType}'
                    AND t1.contents_id = '${params.contentId}'
                    ${excludeQuery}
                    ORDER BY t1.logDate`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getNftTransactonByGameAndRarityBIT: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(t1.logDate, '%Y-%m-%d') AS DATE, COUNT(*) as COUNT, SUM(t1.${sumColumn}) AS PRICE 
                    FROM ${params.table} t1
                    ${leftOnQuery}
                    WHERE t1.contents_id = '${params.contentId}'
                    AND t1.event_type = '${params.tradeType}'
                    AND t1.tag = '${params.rarity}'
                    AND t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    ${excludeQuery}
                    GROUP BY DATE(t1.logDate)
                    ORDER BY t1.logDate`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactonByGameAndAllRarity: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(logDate, '%Y-%m-%d') AS DATE, COUNT(*) as COUNT, SUM(${sumColumn}) AS PRICE FROM ${params.table}
                    WHERE contents_id = '${params.contentId}'
                    AND event_type = '${params.tradeType}'
                    AND logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    GROUP BY DATE(logDate)
                    ORDER BY logDate`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByCoutry: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE, user.country
                      FROM transfer
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY user.country ORDER BY PRICE DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByCoutry: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, user.country
                      FROM transfer
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY user.country ORDER BY COUNT DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByCoutryBidb: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price'
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${sumColumn}) AS PRICE, from_country
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      GROUP BY from_country ORDER BY PRICE DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    //
    getNftTransactionAmountByCoutryBidb2: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price'
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(t1.${sumColumn}) AS PRICE, t1.from_country
                      FROM ${params.table} t1
                      ${leftOnQuery}
                      WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${excludeQuery}
                      AND t1.event_type = '${params.tradeType}' ${contentsQuery}
                      GROUP BY t1.from_country ORDER BY PRICE DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByCoutryBidb: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, from_country
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      GROUP BY from_country ORDER BY COUNT DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByCoutryBidb2: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const leftOnQuery = params.tradeType === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.tradeType === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, t1.from_country
                      FROM ${params.table} t1
                      ${leftOnQuery}
                      WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${excludeQuery}
                      AND t1.event_type = '${params.tradeType}' ${contentsQuery}
                      GROUP BY t1.from_country ORDER BY COUNT DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByGame: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE, asset_contents_bind.contents_id FROM transfer
                      JOIN token ON transfer.token_id = token.id
                      JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY asset_contents_bind.contents_id ORDER BY PRICE DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByGame: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, asset_contents_bind.contents_id FROM transfer
                      JOIN token ON transfer.token_id = token.id
                      JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY asset_contents_bind.contents_id ORDER BY COUNT DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByGameBidb: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const isNullCheck = params.tradeType === "CREDIT_PAYMENT_MARKET" ? `AND amount IS NOT NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${sumColumn}) AS PRICE, contents_id FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      ${isNullCheck}
                      GROUP BY contents_id ORDER BY PRICE DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByGameBidb2: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const isNullCheck = params.tradeType === "CREDIT_PAYMENT_MARKET" ? `AND amount IS NOT NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${sumColumn}) AS PRICE, contents_id FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      ${isNullCheck}
                      GROUP BY contents_id ORDER BY PRICE DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByGameBidb: function(params) {
        const isNullCheck = params.tradeType === "CREDIT_PAYMENT_MARKET" ? `AND amount IS NOT NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, contents_id FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      ${isNullCheck}
                      GROUP BY contents_id ORDER BY COUNT DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByGameBidb2: function(params) {
        const isNullCheck = params.tradeType === "CREDIT_PAYMENT_MARKET" ? `AND amount IS NOT NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, contents_id FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      ${isNullCheck}
                      GROUP BY contents_id ORDER BY COUNT DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByPmid: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE, COUNT(*) AS COUNT, user.firebase_auth_id, user.name, user.mail_address, user.country
                      FROM transfer
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY user.firebase_auth_id ORDER BY PRICE DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByPmid: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, COUNT(*) AS COUNT, user.firebase_auth_id, user.name, user.mail_address, user.country
                      FROM transfer
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY user.firebase_auth_id ORDER BY COUNT DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByPmidBidb: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE, COUNT(*) AS COUNT, from_pmid, from_country
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      GROUP BY from_pmid ORDER BY PRICE DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAmountByPmidBidb2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE, COUNT(*) AS COUNT, from_pmid, from_country
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND from_pmid != 'n/a'
                      AND event_type = '${params.tradeType}'
                      GROUP BY from_pmid ORDER BY PRICE DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByPmidBidb: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, COUNT(*) AS COUNT, from_pmid, from_country
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND from_pmid != 'n/a'
                      AND event_type = '${params.tradeType}'
                      GROUP BY from_pmid ORDER BY COUNT DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionCountByPmidBidb2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, COUNT(*) AS COUNT, from_pmid, from_country
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'
                      GROUP BY from_pmid ORDER BY COUNT DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionTotalAmountInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE
                      FROM transfer
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionTotalCountInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) AS COUNT
                      FROM transfer
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionTotalAmountInRangeBidb: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionTotalAmountInRangeBidb2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS PRICE
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND from_pmid != 'n/a'
                      AND event_type = '${params.tradeType}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionTotalCountInRangeBidb: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) AS COUNT
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.tradeType}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionTotalCountInRangeBidb2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) AS COUNT
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND from_pmid != 'n/a'
                      AND event_type = '${params.tradeType}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionAssets: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(*) as COUNT, asset_contents_bind.contents_id FROM transfer
                      JOIN token ON transfer.token_id = token.id
                      JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 AND transfer.event_type = '${params.tradeType}' AND transfer.status = 'COMPLETED'
                      GROUP BY asset_contents_bind.contents_id ORDER BY COUNT DESC LIMIT ${params.limit}`
        }
        return sqlQueryToReturn;
    },

    // SXI Direct call. Not In Use
    // getMarketplaceSalesByGame: function(params) {
    //     const eventType = params.tradeType !== undefined ? `AND transfer.event_type = '${params.tradeType}'` : '';
    //     let contentType = ``;
    //     if (params.contentType !== undefined) {
    //         const gameName = params.contentType.split("/")[2];
    //         if (gameName !== "transaction-history") {
    //             const contentId = H.ContentsHelper.getContentsIdByName(gameName);
    //             contentType = `AND asset_contents_bind.contents_id = '${contentId}'`
    //         }
    //     }
    //     // const sqlQueryToReturn = {
    //     //     "query": `SELECT SUM(order_price) as orderPrice, SUM(commission) as commission, count(*) as count
    //     //                 FROM market_order
    //     //                 JOIN market ON market_order.market_id = market.id
    //     //                 JOIN asset_contents_bind ON market.asset_id = asset_contents_bind.asset_id
    //     //                 WHERE market_order.order_status = "MATCHED"
    //     //                 AND market_order.trade_type = "SELL"
    //     //                 AND market.exhibitor_type = "${params.type}" ${contentType}
    //     //                 AND market_order.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'`
    //     // }
    //     // const sqlQueryToReturn = {
    //     //     "query": `SELECT SUM(reference_price) as orderPrice, count(distinct transfer.source_id) as count
    //     //                 FROM transfer
    //     //                 JOIN token ON transfer.token_id = token.id
    //     //                 JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
    //     //                 WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
    //     //                 AND transfer.symbol = 'DAT' AND transfer.amount > 0
    //     //                 AND transfer.status = 'COMPLETED' ${contentType} ${eventType}`
    //     // }
    //     const sqlQueryToReturn = {
    //         "query": `SELECT SUM(transfer.reference_price) as orderPrice, count(distinct transfer.source_id) as count
    //                     FROM transfer
    //                     JOIN token ON transfer.token_id = token.id
    //                     JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
    //                     JOIN asset_ex_info ON token.asset_id = asset_ex_info.asset_id
    //                     JOIN asset ON asset_ex_info.asset_id = asset.id
    //                     JOIN user ON transfer.create_user = user.id
    //                     WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
    //                     AND transfer.symbol = 'DAT' AND transfer.amount > 0 ${eventType} AND transfer.status = 'COMPLETED' ${contentType}`
    //     }
    //     return sqlQueryToReturn;
    // },

    getMarketplaceSalesByGame2: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const isNullCheck = params.tradeType === "CREDIT_PAYMENT_MARKET" ? `AND amount IS NOT NULL` : ``;
        // const isAmountCheck = params.tradeType === "REVENUE_1ST" ? `AND amount > 1` : ``;
        const eventType = params.tradeType !== undefined ? `AND event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND contents_id = '${contentId}'`
            }
        }
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${sumColumn}) as orderPrice, count(distinct tid) as count
                        FROM ${params.table}
                        WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${eventType} ${contentType} ${isNullCheck}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionByGame: function(params) {
        const eventType = params.tradeType !== undefined ? `AND transfer.event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND asset_contents_bind.contents_id = '${contentId}'`
            }
        }

        const sqlQueryToReturn = {
            "query": `SELECT asset_ex_info.name, asset.thumbnail_url, asset_ex_info.asset_id, transfer.token_id, reference_price, asset_contents_bind.contents_id, transfer.event_type, user.firebase_auth_id
                      AS from_pmid, transfer.transfer_target_account_id AS to_pmid, transfer.transfer_target_account_id, transfer.fixed_datetime, user.country
                      FROM transfer
                      JOIN token ON transfer.token_id = token.id
                      JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                      JOIN asset_ex_info ON token.asset_id = asset_ex_info.asset_id
                      JOIN asset ON asset_ex_info.asset_id = asset.id
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 ${eventType} AND transfer.status = 'COMPLETED' ${contentType}
                      GROUP BY transfer.source_id
                      ORDER BY transfer.fixed_datetime DESC LIMIT ${params.limit} OFFSET ${params.offset}`
        }
        return sqlQueryToReturn;
    },

    getMarketplaceSalesGroupByType: function(params) {
        const getContentsQuery = (tValue) => {
            let returningValue = ``;
            const cId = H.ContentsHelper.getContentsIdByName(params.contentType.split("/")[2]);
            if (cId !== undefined && cId !== "marketplace-sale") {
                returningValue = `AND ${tValue}.contents_id = '${cId}'`;
            }
            return returningValue;
        };

        let searchTermQuery = ``;
        const getTermQuery = (tValue) => {
            if (params.term !== undefined && params.term[0]['value'] !== '') {
                searchTermQuery = `AND ${tValue}.name LIKE '%${params.term[0]['value']}%'`
            }
            return searchTermQuery;
        }

        const sqlQueryToReturn = {
            "query": `
                      SELECT TC.event_type, SUM(TC.reference_price) as dep, SUM(TC.amount) as usd, count(*) as count
                      FROM (
                          SELECT TS.*
                          FROM (
                                SELECT t1.*
                                FROM SxiNftSalesProd1stV2 t1
                                LEFT JOIN SxiNftSalesProdFiatV1 t3 ON t1.token_id = t3.token_id AND t1.from_pmid = t3.from_pmid AND t1.logDate = t3.logDate
                                WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t1")} AND t3.token_id IS NULL AND t3.from_pmid IS NULL AND t3.logDate IS NULL ${getTermQuery("t1")}
                                UNION ALL
                                SELECT t2.*
                                FROM SxiNftSalesProd2ndV1 t2
                                WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t2")} ${getTermQuery("t2")}
                                UNION ALL
                                SELECT  t3.*
                                FROM SxiNftSalesProdFiatV1 t3
                                WHERE t3.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t3")} ${getTermQuery("t3")}
                          ) AS TS
                      ) AS TC
                      GROUP BY TC.event_type
                      ORDER BY TC.event_type`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getMarketplaceSalesGroupByType: function(params) {
        const getContentsQuery = (tValue) => {
            let returningValue = ``;
            const cId = H.ContentsHelper.getContentsIdByName(params.contentType.split("/")[2]);
            if (cId !== undefined && cId !== "marketplace-sale") {
                returningValue = `AND ${tValue}.contents_id = '${cId}'`;
            }
            return returningValue;
        };

        let searchTermQuery = ``;
        const getTermQuery = (tValue) => {
            if (params.term !== undefined && params.term[0]['value'] !== '') {
                searchTermQuery = `AND ${tValue}.name LIKE '%${params.term[0]['value']}%'`
            }
            return searchTermQuery;
        }

        const sqlQueryToReturn = {
            "query": `
                      SELECT TC.event_type, SUM(TC.reference_price) as dep, SUM(TC.amount) as usd, count(*) as count
                      FROM (
                          SELECT TS.*
                          FROM (
                                SELECT t1.*
                                FROM SxiNftSalesProd1stV2 t1
                                LEFT JOIN SxiNftSalesProdFiatV1 t3 ON t1.token_id = t3.token_id AND t1.from_pmid = t3.from_pmid AND t1.logDate = t3.logDate
                                WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t1")} AND t3.token_id IS NULL AND t3.from_pmid IS NULL AND t3.logDate IS NULL ${getTermQuery("t1")}
                                UNION ALL
                                SELECT t2.*
                                FROM SxiNftSalesProd2ndV1 t2
                                WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t2")} ${getTermQuery("t2")}
                                UNION ALL
                                SELECT  t3.*
                                FROM SxiNftSalesProdFiatV1 t3
                                WHERE t3.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t3")} ${getTermQuery("t3")}
                          ) AS TS
                      ) AS TC
                      GROUP BY TC.event_type
                      ORDER BY TC.event_type`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionByGame2: function(params) {
        const eventType = params.tradeType !== undefined ? `AND event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND contents_id = '${contentId}'`
            }
        }

        const getContentsQuery = (tValue) => {
            let returningValue = ``;
            const cId = H.ContentsHelper.getContentsIdByName(params.contentType.split("/")[2]);
            if (cId !== undefined && cId !== "marketplace-sale") {
                returningValue = `AND ${tValue}.contents_id = '${cId}'`;
            }
            return returningValue;
        };

        let searchTermQuery = ``;
        const getTermQuery = (tValue) => {
            if (params.term !== undefined && params.term[0]['value'] !== '') {
                searchTermQuery = `AND ${tValue}.name LIKE '%${params.term[0]['value']}%'`
            }
            return searchTermQuery;
        }

        const sqlQueryToReturn = {
            // "query": `SELECT name, thumbnail_url, asset_id, token_id, reference_price, contents_id, event_type, from_pmid, to_pmid, logDate, from_country, to_country, amount
            //           FROM SxiNftSalesProd2
            //           WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
            //           ${eventType} ${contentType}
            //           ORDER BY logDate DESC LIMIT ${params.limit} OFFSET ${params.offset}`,
                "query": `
                          SELECT TC.name, TC.thumbnail_url, TC.asset_id, TC.token_id, TC.reference_price, TC.contents_id, TC.event_type, TC.from_pmid, TC.to_pmid, TC.logDate, TC.from_country, TC.to_country, TC.amount
                          FROM (
                              SELECT TS.name, TS.thumbnail_url, TS.asset_id, TS.token_id, TS.reference_price, TS.contents_id, TS.event_type, TS.from_pmid, TS.to_pmid, TS.logDate, TS.from_country, TS.to_country, TS.amount
                              FROM (
                                    SELECT t1.*
                                    FROM SxiNftSalesProd1stV2 t1
                                    LEFT JOIN SxiNftSalesProdFiatV1 t3 ON t1.token_id = t3.token_id AND t1.from_pmid = t3.from_pmid AND t1.logDate = t3.logDate
                                    WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t1")} AND t3.token_id IS NULL AND t3.from_pmid IS NULL AND t3.logDate IS NULL ${getTermQuery("t1")}
                                    UNION ALL
                                    SELECT t2.*
                                    FROM SxiNftSalesProd2ndV1 t2
                                    WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t2")} ${getTermQuery("t2")}
                                    UNION ALL
                                    SELECT  t3.*
                                    FROM SxiNftSalesProdFiatV1 t3
                                    WHERE t3.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t3")} ${getTermQuery("t3")}
                              ) AS TS
                          ) AS TC
                          ORDER BY TC.logDate DESC LIMIT ${params.limit} OFFSET ${params.offset}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionByGameNoLimit: function(params) {
        const eventType = params.tradeType !== undefined ? `AND transfer.event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND asset_contents_bind.contents_id = '${contentId}'`
            }
        }

        const sqlQueryToReturn = {
            "query": `SELECT asset_ex_info.name, asset.thumbnail_url, asset_ex_info.asset_id, transfer.token_id, reference_price, asset_contents_bind.contents_id, transfer.event_type, user.firebase_auth_id
                      AS from_pmid, transfer.transfer_target_account_id AS to_pmid, transfer.transfer_target_account_id, transfer.fixed_datetime, user.country
                      FROM transfer
                      JOIN token ON transfer.token_id = token.id
                      JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                      JOIN asset_ex_info ON token.asset_id = asset_ex_info.asset_id
                      JOIN asset ON asset_ex_info.asset_id = asset.id
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 ${eventType} AND transfer.status = 'COMPLETED' ${contentType}
                      GROUP BY transfer.source_id
                      ORDER BY transfer.fixed_datetime DESC`
        }
        return sqlQueryToReturn;
    },

    getNftTransactionByGameNoLimit2: function(params) {
        // const eventType = params.tradeType !== undefined ? `AND event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND contents_id = '${contentId}'`
            }
        }

        const getContentsQuery = (tValue) => {
            let returningValue = ``;
            const cId = H.ContentsHelper.getContentsIdByName(params.contentType.split("/")[2]);
            if (cId !== undefined && cId !== "marketplace-sale") {
                returningValue = `AND ${tValue}.contents_id = '${cId}'`;
            }
            return returningValue;
        };

        let searchTermQuery = ``;
        const getTermQuery = (tValue) => {
            if (params.term !== undefined && params.term.length > 0 && params.term[0]['value'] !== '') {
                searchTermQuery = `AND ${tValue}.name LIKE '%${params.term[0]['value']}%'`
            }
            return searchTermQuery;
        }

        const sqlQueryToReturn = {
            "query": `
                      SELECT TC.name, TC.thumbnail_url, TC.asset_id, TC.token_id, TC.reference_price, TC.contents_id, TC.event_type, TC.from_pmid, TC.to_pmid, TC.logDate, TC.from_country, TC.to_country, TC.amount
                      FROM (
                          SELECT TS.name, TS.thumbnail_url, TS.asset_id, TS.token_id, TS.reference_price, TS.contents_id, TS.event_type, TS.from_pmid, TS.to_pmid, TS.logDate, TS.from_country, TS.to_country, TS.amount
                          FROM (
                                SELECT t1.*
                                FROM SxiNftSalesProd1stV2 t1
                                LEFT JOIN SxiNftSalesProdFiatV1 t3 ON t1.token_id = t3.token_id AND t1.from_pmid = t3.from_pmid AND t1.logDate = t3.logDate
                                WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t1")} AND t3.token_id IS NULL AND t3.from_pmid IS NULL AND t3.logDate IS NULL ${getTermQuery("t1")}
                                UNION ALL
                                SELECT t2.*
                                FROM SxiNftSalesProd2ndV1 t2
                                WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t2")} ${getTermQuery("t2")}
                                UNION ALL
                                SELECT t3.*
                                FROM SxiNftSalesProdFiatV1 t3
                                WHERE t3.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${getContentsQuery("t3")} ${getTermQuery("t3")}
                          ) AS TS
                      ) AS TC
                      ORDER BY TC.logDate DESC`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getMarketplaceSalesByGameAndTerm: function(params) {
        const eventType = params.tradeType !== undefined ? `AND transfer.event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND asset_contents_bind.contents_id = '${contentId}'`
            }
        }

        let searchTermQuery = ``;
        if (params.term[0]['value'] !== undefined && params.term[0]['value'] !== '') {
            searchTermQuery = `AND asset_ex_info.name LIKE '%${params.term[0]['value']}%'`
        }
        const sqlQueryToReturn = {
            "query": `SELECT SUM(transfer.reference_price) as orderPrice, count(distinct transfer.source_id) as count
                        FROM transfer
                        JOIN token ON transfer.token_id = token.id
                        JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                        JOIN asset_ex_info ON token.asset_id = asset_ex_info.asset_id
                        JOIN asset ON asset_ex_info.asset_id = asset.id
                        JOIN user ON transfer.create_user = user.id
                        WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        AND transfer.symbol = 'DAT' AND transfer.amount > 0 ${eventType} AND transfer.status = 'COMPLETED' ${contentType} ${searchTermQuery}`
        }
        return sqlQueryToReturn;
    },

    getMarketplaceSalesByGameAndTerm2: function(params) {
        const sumColumn = params.tradeType === "CREDIT_PAYMENT_MARKET" ? 'amount' : 'reference_price';
        const isNullCheck = params.tradeType === "CREDIT_PAYMENT_MARKET" ? `AND amount IS NOT NULL` : ``;
        // const isAmountCheck = params.tradeType === "REVENUE_1ST" ? `AND amount > 1` : ``;
        const eventType = params.tradeType !== undefined ? `AND event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND contents_id = '${contentId}'`
            }
        }

        let searchTermQuery = ``;
        if (params.term[0]['value'] !== undefined && params.term[0]['value'] !== '') {
            searchTermQuery = `AND name LIKE '%${params.term[0]['value']}%'`
        }
        const sqlQueryToReturn = {
            "query": `SELECT SUM(${sumColumn}) as orderPrice, count(distinct tid) as count
                        FROM ${params.table}
                        WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${eventType} ${contentType} ${searchTermQuery} ${isNullCheck}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getNftTransactionByGameAndTerm: function(params) {
        const eventType = params.tradeType !== undefined ? `AND transfer.event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND asset_contents_bind.contents_id = '${contentId}'`
            }
        }

        let searchTermQuery = ``;
        if (params.term[0]['value'] !== undefined && params.term[0]['value'] !== '') {
            searchTermQuery = `AND asset_ex_info.name LIKE '%${params.term[0]['value']}%'`
        }

        const sqlQueryToReturn = {
            "query": `SELECT asset_ex_info.name, asset.thumbnail_url, asset_ex_info.asset_id, transfer.token_id, reference_price, asset_contents_bind.contents_id, transfer.event_type, user.firebase_auth_id
                      AS from_pmid, transfer.transfer_target_account_id AS to_pmid, transfer.transfer_target_account_id, transfer.fixed_datetime, user.country
                      FROM transfer
                      JOIN token ON transfer.token_id = token.id
                      JOIN asset_contents_bind ON token.asset_id = asset_contents_bind.asset_id
                      JOIN asset_ex_info ON token.asset_id = asset_ex_info.asset_id
                      JOIN asset ON asset_ex_info.asset_id = asset.id
                      JOIN user ON transfer.create_user = user.id
                      WHERE transfer.fixed_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND transfer.symbol = 'DAT' AND transfer.amount > 0 ${eventType} AND transfer.status = 'COMPLETED' ${contentType} ${searchTermQuery}
                      GROUP BY transfer.source_id
                      ORDER BY transfer.fixed_datetime DESC LIMIT ${params.limit} OFFSET ${params.offset}`
        }

        return sqlQueryToReturn;
    },

    getNftTransactionByGameAndTerm2: function(params) {
        const eventType = params.tradeType !== undefined ? `AND event_type = '${params.tradeType}'` : '';
        let contentType = ``;
        if (params.contentType !== undefined) {
            const gameName = params.contentType.split("/")[2];
            if (gameName !== "marketplace-sale") {
                const contentId = H.ContentsHelper.getContentsIdByName(gameName);
                contentType = `AND contents_id = '${contentId}'`
            }
        }

        let searchTermQuery = ``;
        if (params.term[0]['value'] !== undefined && params.term[0]['value'] !== '') {
            searchTermQuery = `AND name LIKE '%${params.term[0]['value']}%'`
        }

        const sqlQueryToReturn = {
            // "query": `SELECT name, thumbnail_url, asset_id, token_id, reference_price, contents_id, event_type, from_pmid, to_pmid, logDate, from_country, to_country, amount
            //           FROM SxiNftSalesProd2
            //           WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
            //           ${eventType} ${contentType} ${searchTermQuery}
            //           ORDER BY logDate DESC LIMIT ${params.limit} OFFSET ${params.offset}`,
            "query": `
            SELECT TC.name, TC.thumbnail_url, TC.asset_id, TC.token_id, TC.reference_price, TC.contents_id, TC.event_type, TC.from_pmid, TC.to_pmid, TC.logDate, TC.from_country, TC.to_country, TC.amount
            FROM (
                SELECT TS.name, TS.thumbnail_url, TS.asset_id, TS.token_id, TS.reference_price, TS.contents_id, TS.event_type, TS.from_pmid, TS.to_pmid, TS.logDate, TS.from_country, TS.to_country, TS.amount
                FROM (
                      SELECT name, thumbnail_url, asset_id, token_id, reference_price, contents_id, event_type, from_pmid, to_pmid, logDate, from_country, to_country, amount
                      FROM SxiNftSalesProd1stV2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${eventType} ${contentType} ${searchTermQuery} AND amount > 1
                      UNION ALL
                      SELECT name, thumbnail_url, asset_id, token_id, reference_price, contents_id, event_type, from_pmid, to_pmid, logDate, from_country, to_country, amount
                      FROM SxiNftSalesProd2ndV1
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${eventType} ${contentType} ${searchTermQuery}
                      UNION ALL
                      SELECT  name, thumbnail_url, asset_id, token_id, reference_price, contents_id, event_type, from_pmid, to_pmid, logDate, from_country, to_country, amount
                      FROM SxiNftSalesProdFiatV1
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${eventType} ${contentType} ${searchTermQuery}
                ) AS TS
            ) AS TC
            ORDER BY TC.logDate DESC LIMIT ${params.limit} OFFSET ${params.offset}`,
            "database": "KpiDashboard",
            "type": "list"
        }

        return sqlQueryToReturn;
    },


    // AND token_holder.price > 0
    getNftOwnTrendInRange: function(params) {
        const distinctQuery = params.unique ? "DISTINCT hold_account_id" : "hold_account_id"
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(DATE(convert_tz(token_holder.update_datetime, 'UTC', 'Asia/Singapore')), "%Y/%m/%d") AS date, COUNT(${distinctQuery}) AS count
                      FROM token_holder
                      WHERE token_holder.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND token_holder.status = '${params.status}'
                      GROUP BY DATE(convert_tz(token_holder.update_datetime, 'UTC', 'Asia/Singapore'))
                      ORDER BY DATE(convert_tz(token_holder.update_datetime, 'UTC', 'Asia/Singapore')) ASC`
        }
        return sqlQueryToReturn;
    },

    getNftOwnTrendPriceInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE_FORMAT(DATE(convert_tz(token_holder.update_datetime, 'UTC', 'Asia/Singapore')), "%Y/%m/%d") AS date, SUM(price) AS price
                      FROM token_holder
                      WHERE token_holder.update_datetime BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND token_holder.status = '${params.status}'
                      GROUP BY DATE(convert_tz(token_holder.update_datetime, 'UTC', 'Asia/Singapore'))
                      ORDER BY DATE(convert_tz(token_holder.update_datetime, 'UTC', 'Asia/Singapore')) ASC`
        }
        return sqlQueryToReturn;
    },

    getGameTransactionCountByGame: function(params) {
        const count = params.unique ?  "COUNT(DISTINCT pmId)" : "COUNT(*)"
        const product = params.product !== "all" ? `product = '${params.product}' AND` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, ${count} AS count
                    FROM ${params.table}
                    WHERE ${product} logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountByGameLfShop: function(params) {
        const count = params.unique ?  "COUNT(DISTINCT pmId)" : "SUM(amount)"
        const product = params.product !== "all" ? `product = '${params.product}' AND` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, SUM(amount) AS count
                    FROM ${params.table}
                    WHERE ${product} logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionAmountByGame: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, SUM(dep) AS dep
                    FROM ${params.table}
                    WHERE product = '${params.product}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    // amount x dep working query
    // SELECT
    //     date AS sales_date,
    //     SUM(dep) AS total_sales_amount,
    //     SUM(amount) AS total_sales_count
    // FROM (
    //     SELECT
    //         DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date,
    //         dep*amount AS dep,
    //         amount
    //     FROM LuckyFarmerGameSalesProd
    //     WHERE product = 'SHOP' AND logDate BETWEEN 1682524800000 AND 1682611199000
    // ) AS subquery
    // GROUP BY
    //     date

    getGameTransactionAmountByGameLfShop: function(params) {
        const sqlQueryToReturn = {
            "query": `
                    SELECT
                        date AS date,
                        SUM(dep) AS dep
                    FROM (
                        SELECT
                            DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date,
                            dep*amount AS dep,
                            amount
                        FROM ${params.table}
                        WHERE product = 'SHOP' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    ) AS subquery
                    GROUP BY date ASC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountByGameShortTime: function(params) {
        const count = params.unique ?  "COUNT(DISTINCT pmId)" : "COUNT(*)"
        const product = params.product !== "all" ? `product = '${params.product}' AND` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore')) AS date, ${count} AS count
                    FROM ${params.table}
                    WHERE ${product} logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionAmountByGameShortTime: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore')) AS date, SUM(dep) AS dep
                    FROM ${params.table}
                    WHERE product = '${params.product}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountOnGraffiti: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, COUNT(*) AS count
                    FROM ${params.table}
                    WHERE type = '${params.type}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountOnGraffitiByProduct: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, COUNT(*) AS count
                    FROM ${params.table}
                    WHERE type = '${params.type}' AND product = '${params.product}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountOnGraffitiUnique: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, COUNT(DISTINCT pmId) AS count
                    FROM ${params.table}
                    WHERE type = '${params.type}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountOnGraffitiUniqueByProduct: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, COUNT(DISTINCT pmId) AS count
                    FROM ${params.table}
                    WHERE type = '${params.type}' AND product = '${params.product}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountGraffitiBySales: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, COUNT(*) AS count
                    FROM ${params.table}
                    WHERE logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionCountGraffitiBySalesUnique: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, COUNT(DISTINCT pmId) AS count
                    FROM ${params.table}
                    WHERE logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionAmountOnGraffiti: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, SUM(dep) AS dep
                    FROM ${params.table}
                    WHERE type = '${params.type}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionAmountOnGraffitiByProduct: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, SUM(dep) AS dep
                    FROM ${params.table}
                    WHERE type = '${params.type}' AND product = '${params.product}' AND logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getGameTransactionAmountGraffitiBySales: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore')) AS date, SUM(dep) AS dep
                    FROM ${params.table}
                    WHERE logDate BETWEEN ${params.range[0]} AND ${params.range[1]}
                    GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate/1000), 'UTC', 'Asia/Singapore'))
                    ORDER BY date ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };
        return sqlQueryToReturn;
    },

    getDepSalesInRange2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS orderPrice, COUNT(id) AS count, COUNT(DISTINCT from_pmid) AS uni
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.type}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    // here ref
    getDepSalesInRange3: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const leftOnQuery = params.type === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.type === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(t1.reference_price) AS orderPrice, COUNT(*) AS count, COUNT(DISTINCT t1.from_pmid) AS uni
                      FROM ${params.table} t1
                      ${leftOnQuery}
                      WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${excludeQuery}
                      AND t1.event_type = '${params.type}' ${contentsQuery}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getMarketStatsInRange2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT MAX(reference_price) as max, AVG(reference_price) as avg, MIN(reference_price) as min
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.type}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getMarketStatsInRange3: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const leftOnQuery = params.type === "REVENUE_1ST" ? `LEFT JOIN SxiNftSalesProdFiatV1 t2 ON t1.token_id = t2.token_id AND t1.from_pmid = t2.from_pmid AND t1.logDate = t2.logDate` : ``;
        const excludeQuery = params.type === "REVENUE_1ST" ? `AND t2.token_id IS NULL AND t2.from_pmid IS NULL AND t2.logDate IS NULL` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT MAX(t1.reference_price) as max, AVG(t1.reference_price) as avg, MIN(t1.reference_price) as min
                      FROM ${params.table} t1
                      ${leftOnQuery}
                      WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${excludeQuery}
                      AND t1.event_type = '${params.type}' ${contentsQuery}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getFiatSalesInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS dep, SUM(amount) AS price, COUNT(id) AS count, COUNT(DISTINCT from_pmid) AS uni
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.type}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getFiatSalesInRange2: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(reference_price) AS dep, SUM(amount) AS price, COUNT(id) AS count, COUNT(DISTINCT from_pmid) AS uni
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.type}' ${contentsQuery}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getFiatStatsInRange: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT MAX(amount) as max, AVG(amount) as avg, MIN(amount) as min
                      FROM SxiNftSalesProd2
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.type}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getFiatStatsInRange2: function(params) {
        const sqlQueryToReturn = {
            "query": `SELECT MAX(amount) as max, AVG(amount) as avg, MIN(amount) as min
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      AND event_type = '${params.type}'`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getFirstTimePurchaseGroupByEventType: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT event_type, count(*)
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${contentsQuery}
                      GROUP BY event_type
                      ORDER BY event_type`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameSalesInRange: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(total_amount) AS totalAmount, COUNT(id) AS count, COUNT(DISTINCT pmId) AS uni
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getJtcbInGameDepSalesInRange: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `
            SELECT SUM(TC.total_amount) AS totalAmount, COUNT(TC.id) AS count, COUNT(DISTINCT TC.pmId) AS uni
            FROM (
                SELECT t1.total_amount, t1.id, t1.pmId
                FROM SxiInGameDepSalesProd t1
                WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                AND t1.contents_id = 'cl8jxahuq0009l5rwgeh646m3'
                UNION ALL
                SELECT t2.total_amount, t2.id, t2.pmId
                FROM SxiInGameJtcbShopSalesProd t2
                WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
            ) AS TC        
            `,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameSalesStatsInRange: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT MAX(total_amount) as max, AVG(total_amount) as avg, MIN(total_amount) as min
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getJtcbInGameSalesStatsInRange: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `
            SELECT MAX(TC.total_amount) AS max, AVG(TC.total_amount) AS avg, MIN(TC.total_amount) AS min
            FROM (
                SELECT t1.total_amount
                FROM SxiInGameDepSalesProd t1
                WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                ${contentsQuery}
                UNION ALL
                SELECT t2.total_amount
                FROM SxiInGameJtcbShopSalesProd t2
                WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
            ) AS TC    
            `,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameSalesFirstTimePurchase: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT COUNT(pmId) AS first_time_purchase_count
                      FROM (
                        SELECT pmId, MIN(logDate) AS first_purchase_date
                        FROM ${params.table}
                        WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${contentsQuery}
                        GROUP BY pmId
                        HAVING COUNT(*) = 1
                      ) AS first_time_purchases`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getJtcbInGameSalesFirstTimePurchase: function(params) {
        const contentsQuery = params.target !== undefined ? `WHERE contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `
            SELECT COUNT(DISTINCT pmId) AS first_time_purchase_count
            FROM (
                SELECT pmId, MIN(logDate) AS first_purchase_date
                FROM (
                    SELECT pmId, logDate FROM SxiInGameDepSalesProd
                    ${contentsQuery}
                    UNION ALL
                    SELECT pmId, logDate FROM SxiInGameJtcbShopSalesProd
                ) AS combined_sales
                GROUP BY pmId
                HAVING MIN(logDate) BETWEEN '${params.range[0]}' AND '${params.range[1]}'
            ) AS first_time_purchases;`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesByCoutry: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, country
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY country ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    // jtcb or playmining nft inGame Dep
    getJtcbInGameItemSalesByCoutry: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `
                SELECT ${params.data} AS DATA, TC.country
                FROM (
                    SELECT t1.total_amount, t1.id, t1.country
                    FROM SxiInGameDepSalesProd t1
                    WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                    ${contentsQuery}
                    UNION ALL
                    SELECT t2.total_amount, t2.id, t2.country
                    FROM SxiInGameJtcbShopSalesProd t2
                    WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                ) AS TC
                GROUP BY TC.country ORDER BY DATA DESC LIMIT ${params.limit}            
            `,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesByItem: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, target_name, contents_id
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY target_name ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getJtcbInGameItemSalesByItem: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, item_id, contents_id
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY item_id ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameSalesByFiatAndDep: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, target_name, contents_id
                      FROM (
                        SELECT total_amount, target_name, contents_id FROM SxiInGameFiatSalesProd2
                        WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery}
                        UNION ALL
                        SELECT total_amount, target_name, contents_id FROM SxiInGameDepSalesProd
                        WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery}
                      ) AS combined_tables
                      GROUP BY target_name ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;        
    },

    getInGameItemSalesBy: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, ${params.column}, country, count(*)
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY ${params.column} ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getJtcbInGameItemSalesBy: function(params) {
        const contentsQuery = params.target !== undefined ? `AND t1.contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, ${params.column}, TC.country, count(*)
                        FROM (
                            SELECT t1.total_amount, t1.id, t1.pmId, t1.country
                            FROM SxiInGameDepSalesProd t1
                            WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                            ${contentsQuery}
                            UNION ALL
                            SELECT t2.total_amount, t2.id, t2.pmId, t2.country
                            FROM SxiInGameJtcbShopSalesProd t2
                            WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ) AS TC
                        GROUP BY ${params.column} ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesByGame: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, contents_id, count(*)
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY contents_id ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemDepSalesByGame: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT ${params.data} AS DATA, TC.contents_id, count(*)
                      FROM (
                        SELECT t1.total_amount, t1.contents_id
                        FROM ${params.table} t1
                        WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery}
                        UNION ALL
                        SELECT t2.total_amount, t2.contents_id
                        FROM SxiInGameJtcbShopSalesProd t2
                        WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ) AS TC
                      GROUP BY TC.contents_id ORDER BY DATA DESC LIMIT ${params.limit}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesCountTrend: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const selectQuery = params.unique ? `COUNT(DISTINCT pmId)` : `COUNT(*)`;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(logDate, 'UTC', 'Asia/Singapore')) AS DAY, ${selectQuery} AS COUNT
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY DAY`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesAmountTrend: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(logDate, 'UTC', 'Asia/Singapore')) AS DAY, SUM(total_amount) AS AMOUNT
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery}
                      GROUP BY DAY`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesCountTrendByItem: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined ? `AND target_name IN (${params.item})` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(logDate, 'UTC', 'Asia/Singapore')) AS DAY, COUNT(*) AS COUNT
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery} ${itemQuery}
                      GROUP BY DAY`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesCountTrendByItemFiatAndDep: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined ? `AND target_name IN (${params.item})` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(logDate, 'UTC', 'Asia/Singapore')) AS DAY, COUNT(*) AS COUNT
                      FROM (
                        SELECT *
                        FROM SxiInGameFiatSalesProd2 t1
                        WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery} ${itemQuery}
                        UNION ALL
                        SELECT *
                        FROM SxiInGameDepSalesProd t2
                        WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery} ${itemQuery}
                      ) AS combined_data
                      GROUP BY DAY`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesAmountTrendByItem: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined ? `AND target_name IN (${params.item})` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(logDate, 'UTC', 'Asia/Singapore')) AS DAY, SUM(total_amount) AS AMOUNT
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery} ${itemQuery}
                      GROUP BY DAY`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesAmountTrendByItemFiatAndDep: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined ? `AND target_name IN (${params.item})` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT DATE(convert_tz(logDate, 'UTC', 'Asia/Singapore')) AS DAY, SUM(total_amount) AS AMOUNT
                      FROM (
                        SELECT *
                        FROM SxiInGameFiatSalesProd2 t1
                        WHERE t1.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery} ${itemQuery}
                        UNION ALL
                        SELECT *
                        FROM SxiInGameDepSalesProd t2
                        WHERE t2.logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                        ${contentsQuery} ${itemQuery}
                      ) AS combined_data
                      GROUP BY DAY`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalesCountAndAmount: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined ? `AND target_name IN (${params.item})` : ``;
        const pmIdQuery = params.pmid !== undefined && params.pmid.length > 0 ? `AND pmId IN ('${params.pmid[0]}')` : ``;
        const sqlQueryToReturn = {
            "query": `SELECT SUM(total_amount) AS AMOUNT, COUNT(*) AS COUNT
                      FROM ${params.table}
                      WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                      ${contentsQuery} ${itemQuery} ${pmIdQuery}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        return sqlQueryToReturn;
    },

    getInGameItemSalseTransactionByGame: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined && params.item ? `AND target_name IN (${params.item})` : ``;
        const pmIdQuery = params.pmid !== undefined && params.pmid.length > 0 ? `AND pmId IN ('${params.pmid[0]}')` : ``;
        let sqlQueryToReturn = {
            "query": `SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                        FROM (
                            SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                            FROM SxiInGameFiatSalesProd2
                            WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                            ${contentsQuery} ${itemQuery} ${pmIdQuery}
                            UNION
                            SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                            FROM SxiInGameDepSalesProd
                            WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                            ${contentsQuery} ${itemQuery} ${pmIdQuery}
                        ) tables
                        ORDER BY logDate DESC LIMIT ${params.limit} OFFSET ${params.offset}`,
            "database": "KpiDashboard",
            "type": "list"
        }
        if (params.payment !== "both") {
            const tableName = params.payment === "fiat" ? "SxiInGameFiatSalesProd2" : "SxiInGameDepSalesProd"
            sqlQueryToReturn = {
                "query": `SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                          FROM ${tableName}
                          WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                          ${contentsQuery} ${itemQuery} ${pmIdQuery}
                          ORDER BY logDate DESC LIMIT ${params.limit} OFFSET ${params.offset}`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryToReturn;
    },

    getInGameItemTxByGameNoLimit: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined && params.item ? `AND target_name IN (${params.item})` : ``;
        const pmIdQuery = params.pmid !== undefined && params.pmid.length > 0 ? `AND pmId IN ('${params.pmid[0]}')` : ``;
        let sqlQueryToReturn = {
            "query": `SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                        FROM (
                            SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                            FROM SxiInGameFiatSalesProd2
                            WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                            ${contentsQuery} ${itemQuery} ${pmIdQuery}
                            UNION
                            SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                            FROM SxiInGameDepSalesProd
                            WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                            ${contentsQuery} ${itemQuery} ${pmIdQuery}
                        ) tables
                        ORDER BY logDate DESC`,
            "database": "KpiDashboard",
            "type": "list"
        }
        if (params.payment !== "both") {
            const tableName = params.payment === "fiat" ? "SxiInGameFiatSalesProd2" : "SxiInGameDepSalesProd"
            sqlQueryToReturn = {
                "query": `SELECT receipt_id, account_id, contents_id, total_amount, target_name, payment_method, unit_price, quantity, pmId, country, logDate
                          FROM ${tableName}
                          WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                          ${contentsQuery} ${itemQuery} ${pmIdQuery}
                          ORDER BY logDate DESC`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryToReturn;
    },

    getIngameItemOptionData: function(params) {
        const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        const itemQuery = params.item !== undefined && params.item ? `AND target_name IN (${params.item})` : ``;
        const pmIdQuery = params.pmid !== undefined && params.pmid.length > 0 ? `AND pmId IN ('${params.pmid[0]}')` : ``;
        let sqlQueryToReturn = {
            "query": `
            SELECT ${params.column}, COUNT(*) AS total_count
            FROM (
                SELECT ${params.column} FROM SxiInGameFiatSalesProd2 WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${contentsQuery} ${itemQuery} ${pmIdQuery}
                UNION ALL
                SELECT ${params.column} FROM SxiInGameDepSalesProd WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}' ${contentsQuery} ${itemQuery} ${pmIdQuery}
            ) AS combined_tables
            GROUP BY ${params.column}
            ORDER BY total_count DESC`,
            "database": "KpiDashboard",
            "type": "list"
        }
        if (params.payment !== "both") {
            const tableName = params.payment === "fiat" ? "SxiInGameFiatSalesProd2" : "SxiInGameDepSalesProd"
            sqlQueryToReturn = {
                "query": `SELECT ${params.column}, count(*) as count
                          FROM ${tableName}
                          WHERE logDate BETWEEN '${params.range[0]}' AND '${params.range[1]}'
                          ${contentsQuery} ${itemQuery} ${pmIdQuery}
                          GROUP BY ${params.column}
                          ORDER BY count DESC`,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryToReturn;
    },

    getDailySalesByGame: function(gameTarget, contentId, range, countryTarget, ridTarget, size) {
        // const contentsQuery = params.target !== undefined ? `AND contents_id = '${params.target}'` : ``;
        // const itemQuery = params.item !== undefined && params.item ? `AND target_name IN (${params.item})` : ``;
        const startDate = `${range[0]} 00:00:00`;
        const endDate = `${range[1]} 23:59:59`;
        let sqlQueryToReturn = {};
        if (size.value === "dau") {
            sqlQueryToReturn = {
                "query": `
                SELECT
                    date_only,
                    COUNT(DISTINCT pmId) as paid_unique_user,
                    SUM(total_paid),
                    ROUND(SUM(total_paid) / COUNT(DISTINCT pmId), 2) AS average_revenue_per_paid_user
                FROM (
                    SELECT from_pmid as pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProd1stV2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProd2ndV1
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}'
                    AND from_pmid != 'n/a'
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProdFiatV1
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameDepSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameFiatSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameFiatSalesProd2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProd2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}'
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, DATE(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS date_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameJtcbShopSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                ) tables
                GROUP BY date_only;
                `,
                "database": "KpiDashboard",
                "type": "list"
            }
        } else if (size.value === "wau") {
            sqlQueryToReturn = {
                "query": `
                SELECT
                    week_only,
                    COUNT(DISTINCT pmId) as paid_unique_user,
                    SUM(total_paid),
                    ROUND(SUM(total_paid) / COUNT(DISTINCT pmId), 2) AS average_revenue_per_paid_user
                FROM (
                    SELECT from_pmid as pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProd1stV2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProd2ndV1
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}'
                    AND from_pmid != 'n/a'
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProdFiatV1
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameDepSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameFiatSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameFiatSalesProd2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(reference_price, 2) as total_paid
                    FROM SxiNftSalesProd2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}'
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, WEEK(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS week_only, ROUND(total_amount, 2) as total_paid
                    FROM SxiInGameJtcbShopSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                ) tables
                GROUP BY week_only;
                `,
                "database": "KpiDashboard",
                "type": "list"
            }
        } else if (size.value === "mau") {
            sqlQueryToReturn = {
                "query": `
                SELECT
                    DATE_FORMAT(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore'), '%Y-%m') AS ym,
                    COUNT(DISTINCT pmId) as paid_unique_user,
                    SUM(total_paid),
                    ROUND(SUM(total_paid) / COUNT(DISTINCT pmId), 2) AS average_revenue_per_paid_user
                FROM (
                    SELECT from_pmid as pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(reference_price, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiNftSalesProd1stV2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(reference_price, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiNftSalesProd2ndV1
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}'
                    AND from_pmid != 'n/a'
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(reference_price, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiNftSalesProdFiatV1
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(total_amount, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiInGameDepSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(total_amount, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiInGameFiatSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(total_amount, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiInGameFiatSalesProd2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT from_pmid as pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(reference_price, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiNftSalesProd2
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}'
                    AND contents_id = '${contentId}'
                    UNION
                    SELECT pmId, MONTH(CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore')) AS month_only, ROUND(total_amount, 2) as total_paid,
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') AS logDate
                    FROM SxiInGameJtcbShopSalesProd
                    WHERE CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') BETWEEN '${startDate}' AND '${endDate}' 
                    AND contents_id = '${contentId}'
                ) tables
                GROUP BY month_only
                ORDER BY ym asc;
                `,
                "database": "KpiDashboard",
                "type": "list"
            }
        }
        return sqlQueryToReturn;
    },
}

// SELECT DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore')) AS DAY, COUNT(amount) AS COUNT
// FROM ${tableName}
// WHERE kind = '${target}' ${whereCountryClause} AND logDate BETWEEN ${unixDateFrom} AND ${unixDateTo}
// GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate), 'UTC', 'Asia/Singapore'))

export default SqlHelper;
