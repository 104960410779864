import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, getCohortData, setCohortFocused, setCohortSize, setCohortRange, setCohortCountrySelected, setCohortRidSelected } from 'actions';
import * as C from 'components';
import * as V from 'styles/variables';

const Cohort = ({
    pageTopLoader,
    setCurrentPage,
    setCohortFocused,
    setCohortSize,
    cohortFocused,
    cohortSize,
    cohortSizeSelected,
    setCohortRange,
    getCohortData,
    cohortRange,
    cohortRangeSelected,
    cohortCountryOptions,
    cohortRidOptions,
    setCohortCountrySelected,
    setCohortRidSelected,
    cohortData,
    cohortNftData,
    cohortDateRange,
}) => {
    const classes = useStyles();
    const [sizeOptions, setSizeOptions] = useState(
        [
            {name: "日別", value:"day"},
            {name: "週別", value:"week"},
            {name: "月別", value:"month"},
        ]
    );
    const [sizeOption, setSizeOption] = useState(cohortSizeSelected);
    const [rangeOptions, setRangeOptions] = useState(
        [
            {name: "過去7日", value:"7days"},
            {name: "過去14日", value:"14days"},
            {name: "過去21日", value:"21days"},
            {name: "過去30日", value:"30days"},
            {name: "カスタム", value:"custom"},
        ]
    );
    const [rangeOption, setRangeOption] = useState(cohortRangeSelected); // {name: "過去7日", value:"7"}
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [state, setState] = useState({
        checkedFirstNft: true,
    });

    const handleChangeSize = (data) => {
        let value = data.value;
        if (value === 'day') {
            setSizeOption(
                {name: "日別", value:"day"},
            )
            setCohortRange('7days');
        } else if (value === 'week') {
            setSizeOption(
                {name: "週別", value:"week"},
            )
            setCohortRange('1week');
        } else if (value === 'month') {
            setSizeOption(
                {name: "月別", value:"month"},
            )
            setCohortRange('1month');
        }
        setCohortSize(value);
        setBtnState(false);
    }

    const handleChangeRange = (data) => {
        let value = data.value;
        if (value === '7days') {
            setRangeOption({name: "過去7日", value:"7days"})
        } else if (value === '14days') {
            setRangeOption({name: "過去14日", value:"14days"})
        } else if (value === '21days') {
            setRangeOption({name: "過去21日", value:"21days"})
        } else if (value === '30days') {
            setRangeOption({name: "過去30日", value:"30days"})
        } else if (value === '1week') {
            setRangeOption({name: "先週", value:"1week"})
        } else if (value === '2weeks') {
            setRangeOption({name: "過去2週間", value:"2weeks"})
        } else if (value === '3weeks') {
            setRangeOption({name: "過去3週間", value:"3weeks"})
        } else if (value === '6weeks') {
            setRangeOption({name: "過去6週間", value:"6weeks"})
        } else if (value === '9weeks') {
            setRangeOption({name: "過去9週間", value:"9weeks"})
        } else if (value === '12weeks') {
            setRangeOption({name: "過去12週間", value:"12weeks"})
        } else if (value === '1month') {
            setRangeOption({name: "今月", value:"1month"})
        } else if (value === '2month') {
            setRangeOption({name: "過去2ヶ月", value:"2month"})
        } else if (value === '3month') {
            setRangeOption({name: "過去3ヶ月", value:"3month"})
        } else if (value === '6month') {
            setRangeOption({name: "過去6ヶ月", value:"6month"})
        } else if (value === '12month') {
            setRangeOption({name: "過去12ヶ月", value:"12month"})
        } else if (value === 'custom') {
            setRangeOption({name: "カスタム", value:"custom"})
        }
        setCohortRange(value);
        setBtnState(false);
    }

    const handleChangeCountry = (data) => {
        setCohortCountrySelected({name: data.name, value: data.value});
        setBtnState(false);
    }

    const handleChangeRid = (data) => {
        setCohortRidSelected({name: data.name, value: data.value});
        setBtnState(false);
    }
    
    useEffect(() => {
        if (sizeOption.value === 'day') {
            let dayOptions = [
                {name: "過去7日", value:"7days"},
                {name: "過去14日", value:"14days"},
                {name: "過去21日", value:"21days"},
                {name: "過去30日", value:"30days"},
                {name: "カスタム", value:"custom"},
            ];
            setRangeOptions(dayOptions);
            let defaultDayOptionValue = {};
            if (_.isEmpty(cohortRangeSelected)) {
                defaultDayOptionValue = {name: "過去7日", value:"7days"};
            } else {
                if (cohortRangeSelected.value.indexOf(sizeOption.value) !== -1) {
                    defaultDayOptionValue = cohortRangeSelected
                } else {
                    if (cohortRangeSelected.value === 'custom') {
                        defaultDayOptionValue = {name: "カスタム", value:"custom"};
                    } else {
                        defaultDayOptionValue = {name: "過去7日", value:"7days"};
                    }
                }
            }
            setRangeOption(defaultDayOptionValue);
        } else if (sizeOption.value === 'week') {
            let weekOptions = [
                {name: "先週", value:"1week"},
                {name: "過去2週間", value:"2weeks"},
                {name: "過去3週間", value:"3weeks"},
                {name: "過去6週間", value:"6weeks"},
                {name: "過去9週間", value:"9weeks"},
                {name: "過去12週間", value:"12weeks"},
            ];
            setRangeOptions(weekOptions);
            let defaultWeekOptionValue = {};
            if (_.isEmpty(cohortRangeSelected)) {
                defaultWeekOptionValue = {name: "先週", value:"1week"};
            } else {
                if (cohortRangeSelected.value.indexOf(sizeOption.value) !== -1) {
                    defaultWeekOptionValue = cohortRangeSelected
                } else {
                    defaultWeekOptionValue = {name: "先週", value:"1week"};
                }
            }
            setRangeOption(defaultWeekOptionValue);
        } else if (sizeOption.value === 'month') {
            let monthOptions = [
                {name: "先月", value:"1month"},
                {name: "過去2ヶ月", value:"2month"},
                {name: "過去3ヶ月", value:"3month"},
                {name: "過去6ヶ月", value:"6month"},
                {name: "過去12ヶ月", value:"12month"},
            ];
            setRangeOptions(monthOptions);
            let defaultMonthOptionValue = {};
            if (_.isEmpty(cohortRangeSelected)) {
                defaultMonthOptionValue = {name: "先月", value:"1month"};
            } else {
                if (cohortRangeSelected.value.indexOf(sizeOption.value) !== -1) {
                    defaultMonthOptionValue = cohortRangeSelected
                } else {
                    defaultMonthOptionValue = {name: "先月", value:"1month"};
                }
            }
            setRangeOption(defaultMonthOptionValue);
        }
    }, [sizeOption]);

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        let pathArray = window.location.pathname.split("/");
        const cohortFocused = pathArray.length < 4 ? pathArray[1] : pathArray[2];
        setCohortFocused(cohortFocused);

        // maintain size
        if (cohortSizeSelected.value === 'day') {
            setSizeOption(
                {name: "日別", value:"day"},
            )
        } else if (cohortSizeSelected.value === 'week') {
            setSizeOption(
                {name: "週別", value:"week"},
            )
        } else if (cohortSizeSelected.value === 'month') {
            setSizeOption(
                {name: "月別", value:"month"},
            )
        }

        // maintain range
        if (cohortRangeSelected.value === '7days') {
            setRangeOption({name: "過去7日", value:"7days"})
        } else if (cohortRangeSelected.value === '14days') {
            setRangeOption({name: "過去14日", value:"14days"})
        } else if (cohortRangeSelected.value === '21days') {
            setRangeOption({name: "過去21日", value:"21days"})
        } else if (cohortRangeSelected.value === '30days') {
            setRangeOption({name: "過去30日", value:"30days"})
        } else if (cohortRangeSelected.value === '1week') {
            setRangeOption({name: "先週", value:"1week"})
        } else if (cohortRangeSelected.value === '2weeks') {
            setRangeOption({name: "過去2週間", value:"2weeks"})
        } else if (cohortRangeSelected.value === '3weeks') {
            setRangeOption({name: "過去3週間", value:"3weeks"})
        } else if (cohortRangeSelected.value === '6weeks') {
            setRangeOption({name: "過去6週間", value:"6weeks"})
        } else if (cohortRangeSelected.value === '9weeks') {
            setRangeOption({name: "過去9週間", value:"9weeks"})
        } else if (cohortRangeSelected.value === '12weeks') {
            setRangeOption({name: "過去12週間", value:"12weeks"})
        } else if (cohortRangeSelected.value === '1month') {
            setRangeOption({name: "今月", value:"1month"})
        } else if (cohortRangeSelected.value === '2month') {
            setRangeOption({name: "過去2ヶ月", value:"2month"})
        } else if (cohortRangeSelected.value === '3month') {
            setRangeOption({name: "過去3ヶ月", value:"3month"})
        } else if (cohortRangeSelected.value === '6month') {
            setRangeOption({name: "過去6ヶ月", value:"6month"})
        } else if (cohortRangeSelected.value === '12month') {
            setRangeOption({name: "過去12ヶ月", value:"12month"})
        }
        getCohortData();
    }, []);

    const fetchData = () => {
        setBtnState(!btnStateDataFetch);
        getCohortData();
    }

    const getCohortChartXaxisData = (size, range) => {
        if (range.value === undefined) {
            return [];
        }
        const sizeSelected = size.value;
        const rangeSelected = range.value;
        let returnArray = [];
        if (sizeSelected === 'day') {
            let numOfDays;
            if (rangeSelected === 'custom') {
                let endDay = moment(cohortDateRange[1]);
                let startDay = moment(cohortDateRange[0]);
                numOfDays = endDay.diff(startDay, 'days')+1;
            } else {
                numOfDays = rangeSelected.replace('days','');
            }
            _.range(0, Number(numOfDays)).forEach((current, index, range) => {
                returnArray.push('Day '+index);
            });
        } else if (sizeSelected === 'week') {
            if (rangeSelected === '1week') {
                _.range(0, 2).forEach((current, index, range) => {
                    returnArray.push('Week'+index);
                })
            } else if (rangeSelected === '2weeks') {
                _.range(0, 3).forEach((current, index, range) => {
                    returnArray.push('Week '+index);
                });
            } else if (rangeSelected === '3weeks') {
                _.range(0, 4).forEach((current, index, range) => {
                    returnArray.push('Week '+index);
                });
            } else if (rangeSelected === '6weeks') {
                _.range(0, 7).forEach((current, index, range) => {
                    returnArray.push('Week '+index);
                });
            } else if (rangeSelected === '9weeks') {
                _.range(0, 10).forEach((current, index, range) => {
                    returnArray.push('Week '+index);
                });
            } else if (rangeSelected === '12weeks') {
                _.range(0, 13).forEach((current, index, range) => {
                    returnArray.push('Week '+index);
                });
            }
        } else if (sizeSelected === 'month') {
            if (rangeSelected === '1month') {
                _.range(0, 2).forEach((current, index, range) => {
                    returnArray.push('Month '+index);
                })
            } else if (rangeSelected === '2month') {
                _.range(0, 3).forEach((current, index, range) => {
                    returnArray.push('Month '+index);
                });
            } else if (rangeSelected === '3month') {
                _.range(0, 4).forEach((current, index, range) => {
                    returnArray.push('Month '+index);
                });
            } else if (rangeSelected === '6month') {
                _.range(0, 7).forEach((current, index, range) => {
                    returnArray.push('Month '+index);
                });
            } else if (rangeSelected === '12month') {
                _.range(0, 13).forEach((current, index, range) => {
                    returnArray.push('Month '+index);
                });
            }
        }
        return returnArray;
    }

    const getCohortChartData = (cohortSelected, cohortData) => {
        let retunDataArray = [];
        let dataArrayTemp = _.find(cohortData, function(o) {
            return o.id === cohortSelected;
        });
        let dataNftArrayTemp = _.find(cohortNftData, function(o) {
            return o.id === cohortSelected;
        });
        let ratioReturnArray = [100], ratioReturnNftArray = [100], eachRatio;
        if (dataArrayTemp !== undefined) {
            for (let i = 0; i < dataArrayTemp.data[0].length; i++) {
                if (i > 1) {
                    eachRatio = Number((dataArrayTemp.data[0][i] / dataArrayTemp.data[0][1] * 100).toFixed(2));
                    ratioReturnArray.push(eachRatio);
                }
            }
            if (ratioReturnArray.length > 1) {
                retunDataArray.push(ratioReturnArray)
            } else {
                retunDataArray.push([]);
            }
        }
        if (dataNftArrayTemp !== undefined) {
            for (let i = 0; i < dataNftArrayTemp.data[0].length; i++) {
                if (i > 1) {
                    eachRatio = Number((dataNftArrayTemp.data[0][i] / dataNftArrayTemp.data[0][1] * 100).toFixed(2));
                    ratioReturnNftArray.push(eachRatio);
                }
            }
            if (ratioReturnNftArray.length > 1) {
                retunDataArray.push(ratioReturnNftArray)
            } else {
                retunDataArray.push([]);
            }
        }
        return retunDataArray;
    }

    const getCohortChartRowData = (cohortSelected, cohortData) => {
        let dataArrayTemp = _.find(cohortData, function(o) {
            return o.id === cohortSelected;
        });
        let ratioReturnArray2 = [];
        if (dataArrayTemp !== undefined) {
            for (let i = 0; i < dataArrayTemp.data[0].length; i++) {
                if (typeof dataArrayTemp.data[0][i] === 'number') {
                    ratioReturnArray2.push(dataArrayTemp.data[0][i]);
                }
            }
            return ratioReturnArray2.length === 0 ? [] : ratioReturnArray2;
        }
    }

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <Fragment>
            <div className={classes.filterSection}>
                <Autocomplete
                    id="select=-size"
                    style={{ width: 80 }}
                    options={sizeOptions}
                    getOptionLabel={(option) => option.name}
                    value={sizeOption}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="サイズ" />
                    )}
                    onChange={(e, newValue) => {
                        handleChangeSize(newValue);
                    }}
                    className={classes.autoSelect}
                />
                <Autocomplete
                    id="select=-range"
                    style={{ width: 120 }}
                    options={rangeOptions}
                    getOptionLabel={function (option){
                        return option.name;
                    }}
                    value={rangeOption}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="期間" />
                    )}
                    onChange={(e, newValue) => {
                        handleChangeRange(newValue);
                    }}
                    className={classes.autoSelect}
                />
                {cohortRange === 'custom' &&
                    <C.CalendarRangeOnly setBtnState={setBtnState} page="cohort" />
                }
                <Autocomplete
                    id="select=-country"
                    style={{ width: 200 }}
                    options={cohortCountryOptions}
                    getOptionLabel={(option) => option.name}
                    defaultValue={cohortCountryOptions[0]}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="国" />
                    )}
                    onChange={(e, newValue) => {
                        handleChangeCountry(newValue);
                    }}
                    className={classes.autoSelect}
                />
                <Autocomplete
                    id="select-rid"
                    style={{ width: 350 }}
                    options={cohortRidOptions}
                    getOptionLabel={(option) => option.name}
                    defaultValue={cohortRidOptions[0]}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="キャンペーン" />
                    )}
                    onChange={(e, newValue) => {
                        handleChangeRid(newValue);
                    }}
                    className={classes.autoSelect}
                    disabled={state.checkedFirstNft ? true : false}
                />
                <FormControlLabel
                    className={classes.nftCohortCheckbox}
                    control={<Checkbox checked={state.checkedFirstNft} onChange={handleChange} name="checkedFirstNft" />}
                    label="NFT初購入コホート"
                />
                <Button disabled={btnStateDataFetch} className={classes.dataBtn} variant="contained" color="primary" onClick={() => fetchData()}>GO</Button>
            </div>
            <div>
            {pageTopLoader 
                ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                : <C.CohortLineChart
                    legend={false}
                    color={V.acquisitionPallette}
                    lblSaveImage={"コホートチャート"}
                    xAisData={getCohortChartXaxisData(cohortSizeSelected, cohortRangeSelected)}
                    seriesData={getCohortChartData(cohortFocused, cohortData)}
                    seriesRowData={getCohortChartRowData(cohortFocused, cohortData)}
                    seriesRowNftData={getCohortChartRowData(cohortFocused, cohortNftData)}
                    chartType={'line'}
                />
            }
            </div>
            <div className={classes.cohortTable}>
                <C.CohortPureTable />
            </div>
        </Fragment>
    );
};

Cohort.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    pageTopLoader: state.page.pageTopLoader,
    cohortFocused: state.cohort.cohortFocused,
    cohortSize: state.cohort.cohortSize,
    cohortSizeSelected: state.cohort.cohortSizeSelected,
    cohortRange: state.cohort.cohortRange,
    cohortRangeSelected: state.cohort.cohortRangeSelected,
    cohortCountryOptions: state.cohort.cohortCountryOptions,
    cohortRidOptions: state.cohort.cohortRidOptions,
    cohortData: state.cohort.cohortData,
    cohortNftData: state.cohort.cohortNftData,
    cohortDateRange: state.cohort.cohortDateRange,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setCohortFocused: (param) => dispatch(setCohortFocused(param)),
    getCohortData: () => dispatch(getCohortData()),
    setCohortSize: (param) => dispatch(setCohortSize(param)),
    setCohortRange: (param) => dispatch(setCohortRange(param)),
    setCohortCountrySelected: (param) => dispatch(setCohortCountrySelected(param)),
    setCohortRidSelected: (param) => dispatch(setCohortRidSelected(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Cohort));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        filterSection: {
            display: 'flex',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        cohortTable: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        nftCohortCheckbox: {
            marginLeft: 8,
            marginTop: 10,
            paddingRight: 0,
        }
    }),
);