import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { DataGrid, jaJP, useGridSlotComponentProps, GridToolbarContainer, GridFilterToolbarButton } from '@material-ui/data-grid';
import { makeStyles, createStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';

import * as C from 'components';
import * as H from 'helper';
import * as V from 'styles/variables';
import { setCurrentPage, getUserPageListData, getUserPageListTotalNum, setUserPageListPagination, handleUsersFilter, fetchUsersByFilter } from 'actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const columns = [
    { field: 'id', headerName: '#', width: 60 },
    { field: 'pmid', headerName: 'PMID', width: 290 },
    { field: 'game', headerName: 'Game', width: 130 },
    { field: 'rid', headerName: 'Ad Code', width: 120 },
    { field: 'cname', headerName: 'Campaign Name', width: 350 },
    { field: 'createdAt', headerName: 'Created At', width: 300 },
];

const theme = createTheme(
    {
      palette: {
        primary: { main: V.primeColour },
      },
    },
    jaJP,
);

function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();
    const params = useParams();

    return (
      <Pagination
        className={classes.root}
        color="primary"
        count={500}
        page={params.page !== undefined ? Number(params.page) : state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
}

const Users = ({
    setCurrentPage,
    getUserPageListData,
    getUserPageListTotalNum,
    setUserPageListPagination,
    userPageListData,
    userPageTableLoading,
    userPageTableTotal,
    userFetchFilter,
    userFetchFilterSelected,
    handleUsersFilter,
    fetchUsersByFilter,
    userCountByFiltered,
    usersFilterQuery,
}) => {
    const classes = useStyles();
    const params = useParams();
    // const history = useHistory();
    const [rows, setRows] = useState(userPageListData);
    const [selected, setSelected] = useState([]);
    const [filterState, setFiller] = useState(false);
    const [maxWidth, setMaxWidth] = useState('md');
    const [values, setValues] = useState({
        pmid: '',
        email: '',
    });
    const [game, setGame] = useState([
        {name: 'JobTribes', value: 'JobTribes' },
        {name: 'Puzzle', value: 'Puzzle' },
        {name: 'LuckyFarmer', value: 'luckyfarmer' },
        {name: 'Portal', value: 'Portal' },
    ]);
    const [gameAll, setGameAll] = useState(true);
    const [tier, setTier] = useState([
        {name: '1', value: '1' },
        {name: '2', value: '2' },
        {name: '3', value: '3' },
    ]);
    const [tierAll, setTierAll] = useState(true);
    const [campaign, setCampaign] = useState([]);
    const [campaignAll, setCampaignAll] = useState(true);

    useEffect(() => {
        setCurrentPage('users');
        getUserPageListTotalNum();
        // getUserPageListData();
        const pageNumber = params.page !== undefined ? Number(params.page) : 1;
        setUserPageListPagination(pageNumber);
        // console.log('params:', params);
    }, []);
    
    useEffect(() => {
        //console.log('DATA CHANGEED:', userPageListData);
        setRows(userPageListData);
    }, [userPageListData]);
    
    useEffect(() => {
        console.log('DATA CHANGEED:', selected);
    }, [selected]);

    const pageChange = (params) => {
        console.log('params.page:', params.page+1);
        setUserPageListPagination(params.page+1);
        //history.push(`/users/${params.page+1}`)
    };

    const rowSelected = (rowData) => {
        console.log('rowData:', rowData);
        let currentRowData = selected;
        currentRowData.push(rowData); 
        setSelected(currentRowData);
        console.log('selected:', selected)
    }

    const handleClickOpen = () => {
        setCampaign(userFetchFilter.campaign);
        setFiller(true);
        // if (usersFilterQuery) {
        //     console.log('filterrrrr query : ', userFetchFilterSelected)
        // }
        setValues({
            pmid: userFetchFilterSelected.pmid !== '' ? userFetchFilterSelected.pmid : '',
            email: userFetchFilterSelected.email !== '' ? userFetchFilterSelected.email : '',
        });
      };
    
    const handleClose = () => {
        setFiller(false);
    };

    const handleDataFetch = () => {
        setFiller(false); // close modal
        fetchUsersByFilter();
    };

    const handleChange = (prop) => (event) => {
        console.log('props:', prop);
        console.log('event:', event.target.value);
        setValues({ ...values, [prop]: event.target.value });
        handleUsersFilter({name:prop, value: event.target.value});
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <div className={classes.totalPmidDisplay}>検索可能ADコード付きPMID総数：{userPageTableTotal !== '--' ? H.FormatNumStrHelper.formatCommaThousand(userPageTableTotal) : '--'}</div>
                <div className={classes.sectionNewBtn}>
                    <Button
                        color="primary" 
                        variant="contained"
                        className={classes.button}
                        startIcon={<FilterListIcon />}
                        onClick={handleClickOpen}
                    >
                        フィルター
                    </Button>   
                </div>
                {userCountByFiltered &&
                    <div className={classes.sectionFilterHit}>検索ヒット数： {H.FormatNumStrHelper.formatCommaThousand(userCountByFiltered)}</div>
                }
            </div>
            <C.ContentWrapper>
                <Grid container spacing={3} className={classes.pageSectionTop} justifyContent="center">
                    <Grid item sm={12} md={12} lg={10}>
                    <div style={{ height: 800 }}>
                        <ThemeProvider theme={theme}>
                            <DataGrid
                                // https://github.com/mui-org/material-ui-x/blob/HEAD/packages/grid/_modules_/grid/constants/localeTextConstants.ts
                                localeText={{
                                    noRowsLabel: 'データがありません',
                                }}
                                rows={rows}
                                rowCount={userCountByFiltered ? userCountByFiltered : userPageTableTotal}
                                columns={columns}
                                pageSize={20}
                                //checkboxSelection
                                loading={userPageTableLoading}
                                onPageChange={pageChange}
                                disableColumnMenu={true}
                                //onRowSelected={e => rowSelected(e.data)}
                                // components={{
                                //     Pagination: CustomPagination,
                                // }}
                            />
                        </ThemeProvider>
                    </div>
                    </Grid>
                </Grid>
            </C.ContentWrapper>

            <Dialog
                fullWidth={true}
                maxWidth={maxWidth}
                open={filterState}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">フィルター</DialogTitle>
                <DialogContent style={{height:'400px'}}>
                    <form className={classes.form} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={5} lg={5}>
                        <TextField
                            id="standard-name"
                            className={classes.textField}
                            label="PMID"
                            value={values.pmid}
                            onChange={handleChange('pmid')}
                            margin="normal"
                        />

                        {/* GAME */}
                        <div className={classes.selectWrapper}>
                            <div className={classes.selectLabel}>Game</div>
                            <Autocomplete
                                multiple
                                id="checkboxes-game"
                                options={game}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                    </React.Fragment>
                                )}
                                renderInput={(params) => {
                                    return (
                                        <TextField {...params} variant="outlined" placeholder={gameAll ? "全て" : ""} />
                                    )
                                }}
                                value={userFetchFilterSelected.game.length > 0 ? userFetchFilterSelected.game : []}
                                onChange={(e, newValue) => {
                                    console.log('newValue:', newValue);
                                    if (newValue.length > 0) {
                                        setGameAll(false);
                                        handleUsersFilter({name:'game', value: newValue});
                                    } else {
                                        setGameAll(true);
                                        handleUsersFilter({name:'game', value: []});
                                    }
                                }}
                            />
                        </div>
                        </Grid>

                        {/* Campaign */}
                        <Grid item sm={12} md={7} lg={7}>
                        <div className={classes.selectWrapper}>
                            <div className={classes.selectLabel}>Campaign</div>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-campaign"
                                    options={campaign}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder={campaignAll ? "全て" : ""} size="small" />
                                    )}
                                    value={userFetchFilterSelected.campaign.length > 0 ? userFetchFilterSelected.campaign : []}
                                    onChange={(e, newValue) => {
                                        if (newValue.length > 0) {
                                            setCampaignAll(false);
                                            handleUsersFilter({name:'campaign', value: newValue});
                                        } else {
                                            setCampaignAll(true);
                                            handleUsersFilter({name:'campaign', value: []});
                                        }
                                    }}
                                />
                        </div>
                        </Grid>
                    </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    キャンセル
                </Button>
                <Button onClick={handleDataFetch} color="primary">
                    絞り込む
                </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
};

Users.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    userPageListData: state.users.userPageListData,
    userPageTableLoading: state.users.userPageTableLoading,
    userPageTableTotal: state.users.userPageTableTotal,
    userCountByFiltered: state.users.userCountByFiltered,
    userFetchFilter: state.users.userFetchFilter,
    userFetchFilterSelected: state.users.userFetchFilterSelected,
    userCountByFiltered: state.users.userCountByFiltered,
    usersFilterQuery: state.users.usersFilterQuery,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getUserPageListData: () => dispatch(getUserPageListData()),
    getUserPageListTotalNum: () => dispatch(getUserPageListTotalNum()),
    setUserPageListPagination: (param) => dispatch(setUserPageListPagination(param)),
    handleUsersFilter: (param) => dispatch(handleUsersFilter(param)),
    fetchUsersByFilter: () => dispatch(fetchUsersByFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
            position: 'fixed',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            width: '100%',
        },
        sectionContent: {
            display:'flex',
        },
        totalPmidDisplay: {
            minWidth: 220,
            margin: 16,
        },
        button: {
            marginTop: theme.spacing(1.2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        pageSectionTop: {
            marginTop: 32,
        },
        sectionNewBtn: {
            marginRight: 24
        },
        sectionFilterHit: {
            marginTop: theme.spacing(2),
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            //width: 'fit-content',
        },
        textField: {
            marginLeft: theme.spacing(1),
            width: '95%',
        },
        selectSection: {
            marginTop: theme.spacing(2),
        },
        areaWrapper: {
            display: 'block',
            width: '90%',
            paddingBottom: '50px'
        },
        selectWrapper: {
            marginTop: theme.spacing(3),
            marginLeft: theme.spacing(1),
            display: 'block',
            width: '95%'
        },
        selectLabel: {
            fontSize: 12,
            marginBottom: theme.spacing(1/2),
        }
    }),
);