const DaaStyles = theme => ({
    fieldWrapper: {
        paddingTop: 20
    },
    regButton: {
        marginLeft: 24,
        color: '#fff'
    },
    cancelButton: {
        marginLeft: 16,
        backgroundColor: '#fff',
    }
});

export default DaaStyles;