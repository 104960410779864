import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import 'index.css';
import App from 'App';
import AppProvider from 'components/AppProvider';
import { LocalizationProvider } from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';
import moment, { Moment } from "moment";
//import "moment/locale/ja";

//import ReactGA from 'react-ga';
import config from 'aws-exports';
Amplify.configure(config);
//import * as serviceWorker from 'serviceWorker';

//window.LOG_LEVEL='DEBUG';

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
//     debug: false,
//     titleCase: false,
// });

Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        //identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
    },
    Storage: {
        region: process.env.REACT_APP_COGNITO_REGION,
        bucket: process.env.REACT_APP_S3_BUCKET,
        level: "protected",
        identityPoolId: process.env.REACT_APP_S3_IDENTITY_POOL_ID,
    }
});

// moment.locale("ja");

ReactDOM.render(
    <AppProvider>
        <BrowserRouter>
            <LocalizationProvider dateLibInstance={moment} dateAdapter={MomentUtils} locale={'en'}>
                <App />
            </LocalizationProvider>
        </BrowserRouter>
    </AppProvider>
    ,document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
