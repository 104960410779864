import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setAverageRevenueDataByGame, setAverageRevenueDataByCountry, setAverageRevenueDataByRid, setPageTopLoader,
    setAverageRevenueDataByGamePaid, setAverageRevenueDataByCountryPaid, setAverageRevenueDataByRidPaid,
    setAverageRevenueGameOption, setAverageRevenueCountryOption, setAverageRevenueRidOption, setPaidPmidCsvData, setAllPmidCsvData } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch } from 'api';
import { KPI, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const startDate = state => state.kpi.avgRevenueDateRange[0];
export const endDate = state => state.kpi.avgRevenueDateRange[1];
export const gameSelected = state => state.kpi.avgRevenueGameSelected;
export const countrySelected = state => state.kpi.avgRevenueCountrySelected;
export const ridSelected = state => state.kpi.avgRevenueRidSelected;

export function* getAverageRevenueData() {
    try {
        // note for data migration:
        // registration data was used 2023/6/30 with GA data: table 
        // purchase data was used ES until 2022/10/19: table: AggDaaAuctionStoreReceipt
        // after this date, moved to SXI new table: SxiNftSalesProd2ndV1
        const jwtToken = yield call(getJwtToken);
        yield put(setPageTopLoader(true));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        // DATA2.0
        const isDataNew = moment(dateFrom).isAfter('2023-06-30') && moment(dateTo).isAfter('2023-06-30') ? true : false;
        const isDataOld = moment(dateFrom).isBefore('2023-07-01') && moment(dateTo).isBefore('2023-07-01') ? true : false;
        const isDataMix = moment(dateFrom).isBefore('2023-07-01') && moment(dateTo).isAfter('2023-06-30') ? true : false;

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('x'),
            moment(dateTo+" 23:59:59+8:00").format('x')
        ];

        const regTable = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';

        const gameValues = yield select(gameSelected);
        const countryValues = yield select(countrySelected);
        const ridValues = yield select(ridSelected);

        let gameClause = '';
        if (gameValues.length > 0 && gameValues[0].length > 0) {
            let gameValueArray = gameValues[0].map((data, index) => {
                return `'${data.value}'`
            });
            gameClause = `AND ${regTable}.game IN (${gameValueArray.join()})`
        }

        let countryClause = '';
        if (countryValues.length > 0 && countryValues[0].length > 0) {
            let countryValueArray = countryValues[0].map((data, index) => {
                return `'${data.value}'`
            });
            countryClause = `AND country IN (${countryValueArray.join()})`
        }

        let ridClause = '';
        if (ridValues.length > 0 && ridValues[0].length > 0) {
            let ridValueArray = ridValues[0].map((data, index) => {
                return `'${data.value}'`
            });
            ridClause = `AND ${regTable}.rid IN (${ridValueArray.join()})`
        }

        // With DEP purchase
        const sqlQueryByGame = {
            "query": `SELECT game, COUNT(DISTINCT pmId) as count, SUM(amount) as dep
            FROM ${regTable} INNER JOIN SxiNftSalesProd2ndV1 ON ${regTable}.pmId = SxiNftSalesProd2ndV1.from_pmid
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.game ORDER BY dep ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryByCountry = {
            "query": `SELECT country, COUNT(DISTINCT pmId) as count, SUM(amount) as dep
            FROM ${regTable}
              INNER JOIN SxiNftSalesProd2ndV1 ON ${regTable}.pmId = SxiNftSalesProd2ndV1.from_pmid
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY country ORDER BY dep ASC;`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryByRid = {
            "query": `SELECT ${regTable}.rid, CampaignStats.name AS cname, COUNT(DISTINCT pmId) as count, SUM(amount) as dep
            FROM ${regTable} 
              INNER JOIN SxiNftSalesProd2ndV1 ON ${regTable}.pmId = SxiNftSalesProd2ndV1.from_pmid
              LEFT OUTER JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.rid ORDER BY dep ASC;`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryByPmid = {
            "query": `SELECT pmId, ${regTable}.game, from_country, ${regTable}.rid, CampaignStats.name AS cname, SUM(amount) as dep, ${regTable}.regDate
            FROM ${regTable} 
            INNER JOIN SxiNftSalesProd2ndV1 ON ${regTable}.pmId = SxiNftSalesProd2ndV1.from_pmid
            LEFT OUTER JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY pmId ORDER BY dep DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryByPmidJustReg = {
            "query": `SELECT pmId, ${regTable}.game, country, ${regTable}.rid, CampaignStats.name AS cname, ${regTable}.regDate
            FROM ${regTable} 
            LEFT OUTER JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY pmId ORDER BY regDate DESC;`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const [
            tempByGame,
            tempByCountry,
            tempByRid,
            tempByPmid,
            tempByAllPmid,
        ] = yield all([
            call(getDataFromRDS, sqlQueryByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryByPmid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryByPmidJustReg, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedByGame = JSON.parse(tempByGame.body);
        const parsedByCountry = JSON.parse(tempByCountry.body);
        const parsedByRid = JSON.parse(tempByRid.body);
        const parsedByPmid = JSON.parse(tempByPmid.body);
        const parsedByAllPmid = JSON.parse(tempByAllPmid.body);

        let csvData = [
            ["pmid", "game", "country", "rid", "name", "dep", "created_at"],
        ], eachRowData = [];

        if (parsedByPmid.length > 0) {
            _.each(parsedByPmid, function(pmidPaidData) {
                eachRowData = [
                    pmidPaidData[0]['stringValue'],
                    pmidPaidData[1]['stringValue'],
                    pmidPaidData[2]['stringValue'],
                    pmidPaidData[3]['stringValue'],
                    pmidPaidData[4]['isNull'] ? "Organic" : pmidPaidData[4]['stringValue'],
                    Number(pmidPaidData[5]['stringValue']),
                    pmidPaidData[6]['longValue'],
                ];
                csvData.push(eachRowData);
                eachRowData = [];
            });
        } else {
            csvData = [];
        }
        yield put(setPaidPmidCsvData(csvData));

        let csvAllPmidData = [
            ["pmid", "game", "country", "rid", "name", "created_at"],
        ], eachAllPmidRowData = [];
        if (parsedByAllPmid.length > 0) {
            _.each(parsedByAllPmid, function(pmidData) {
                eachAllPmidRowData = [
                    pmidData[0]['stringValue'],
                    pmidData[1]['stringValue'],
                    pmidData[2]['stringValue'],
                    pmidData[3]['stringValue'],
                    pmidData[4]['isNull'] ? "Organic" : pmidData[4]['stringValue'],
                    pmidData[5]['longValue'],
                ];
                csvAllPmidData.push(eachAllPmidRowData);
                eachAllPmidRowData = [];
            });
        } else {
            csvAllPmidData = [];
        }
        yield put(setAllPmidCsvData(csvAllPmidData));

        let byGamePaidData = [], byGamePaidName = [], byGamePaidNewReg = [], byGamePaidNewDep = [];
        _.each(parsedByGame, function(gamePaidData) {
            byGamePaidName.push(gamePaidData[0]['stringValue']);
            byGamePaidNewReg.push(gamePaidData[1]['longValue']);
            byGamePaidNewDep.push(Number(gamePaidData[2]['stringValue']));
        });
        byGamePaidData = [byGamePaidName, byGamePaidNewReg, byGamePaidNewDep];
        yield put(setAverageRevenueDataByGamePaid(byGamePaidData));

        let  byCountryPaidData = [], byCountryPaidName = [], byCountryPaidNewReg = [], byCountryPaidNewDep = [];
        _.each(parsedByCountry, function(countryPaidData) {
            byCountryPaidName.push(countryPaidData[0]['stringValue'].replace("United Arab Emirates", "UAE"));
            byCountryPaidNewReg.push(countryPaidData[1]['longValue']);
            byCountryPaidNewDep.push(Number(countryPaidData[2]['stringValue']));
        });
        byCountryPaidData = [byCountryPaidName, byCountryPaidNewReg, byCountryPaidNewDep];
        yield put(setAverageRevenueDataByCountryPaid(byCountryPaidData));

        let  byRidDataPaid = [], byRidNamePaid = [], byRidNewRegPaid = [], byRidNewDepPaid = [], byRidNewDisplay = [];
        _.each(parsedByRid, function(ridPaidData) {
            byRidNamePaid.push(ridPaidData[0]['stringValue'].replace("organic", "Organic"));
            byRidNewRegPaid.push(ridPaidData[2]['longValue']);
            byRidNewDepPaid.push(Number(ridPaidData[3]['stringValue']));
            byRidNewDisplay.push(ridPaidData[1]['stringValue'])
        });
        byRidDataPaid = [byRidNamePaid, byRidNewRegPaid, byRidNewDepPaid, byRidNewDisplay];
        yield put(setAverageRevenueDataByRidPaid(byRidDataPaid));

        // pure just pmid registration with 'exist'
        // const sqlQueryExistByGame = {
        //     "query": `SELECT property, COUNT(DISTINCT pmId) as count
        //     FROM ${regTable}
        //     WHERE ${regTable}.exist = 'exist' AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
        //     GROUP BY ${regTable}.game ORDER BY count DESC`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // };

        // const sqlQueryExistByCountry = {
        //     "query": `SELECT country, COUNT(DISTINCT pmId) as count
        //     FROM ${regTable}
        //     WHERE ${regTable}.exist = 'exist' AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
        //     GROUP BY country ORDER BY count DESC;`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // };
        // const sqlQueryExistByRid = {
        //     "query": `SELECT ${regTable}.rid, CampaignStats.name AS cname, COUNT(DISTINCT pmId) as count
        //     FROM ${regTable} 
        //       LEFT OUTER JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
        //     WHERE ${regTable}.exist = 'exist' AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
        //     GROUP BY ${regTable}.rid ORDER BY count DESC;`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // }

        // const [
        //     tempExistByGame,
        //     tempExistByCountry,
        //     tempExistByRid,
        // ] = yield all([
        //     call(getDataFromRDS, sqlQueryExistByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, sqlQueryExistByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, sqlQueryExistByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
        // ]);
        // const parsedExistByGame = JSON.parse(tempExistByGame.body);
        // const parsedExistByCountry = JSON.parse(tempExistByCountry.body);
        // const parsedExistByRid = JSON.parse(tempExistByRid.body);
        // console.log("YOYO Ex parsedExistByGame:", parsedExistByGame);
        // console.log("YOYO Ex parsedExistByCountry:", parsedExistByCountry);
        // console.log("YOYO Ex parsedExistByRid:", parsedExistByRid);

        // pure just pmid registration with 'new'
        const sqlQueryNewByGame = {
            "query": `SELECT game, COUNT(DISTINCT pmId) as count
            FROM ${regTable}
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.game ORDER BY count ASC`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryNewByCountry = {
            "query": `SELECT country, COUNT(DISTINCT pmId) as count
            FROM ${regTable}
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY country ORDER BY count ASC;`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryNewByRid = {
            "query": `SELECT ${regTable}.rid, CampaignStats.name AS cname, COUNT(DISTINCT pmId) as count
            FROM ${regTable} 
                LEFT OUTER JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
            WHERE ${regTable}.exist = 'new' ${gameClause} ${countryClause} ${ridClause} AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.rid ORDER BY count ASC;`,
            "database": "KpiDashboard",
            "type": "list"
        }

        const [
            tempNewByGame,
            tempNewByCountry,
            tempNewByRid,
        ] = yield all([
            call(getDataFromRDS, sqlQueryNewByGame, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNewByRid, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedNewByGame = JSON.parse(tempNewByGame.body);
        const parsedNewByCountry = JSON.parse(tempNewByCountry.body);
        const parsedNewByRid = JSON.parse(tempNewByRid.body);

        let  byGameData = [], byGameName = [], byGameNewReg = [];
        _.each(parsedNewByGame, function(gameData) {
            byGameName.push(gameData[0]['stringValue']);
            byGameNewReg.push(gameData[1]['longValue']);
        });
        byGameData = [byGameName, byGameNewReg];
        yield put(setAverageRevenueDataByGame(byGameData));

        let  byCountryData = [], byCountryName = [], byCountryNewReg = [];
        _.each(parsedNewByCountry, function(data) {
            byCountryName.push(data[0]['stringValue'].replace("United Arab Emirates", "UAE"));
            byCountryNewReg.push(data[1]['longValue']);
        });
        byCountryData = [byCountryName, byCountryNewReg];
        yield put(setAverageRevenueDataByCountry(byCountryData));

        let  byRidData = [], byRidName = [], byRidNewReg = [], byRidDisplay = [];
        _.each(parsedNewByRid, function(ridData) {
            let ridNameCleaned = ridData[0]['stringValue'].replace("organic", "Organic");
            ridNameCleaned = ridNameCleaned.replace("undefined", "不明");
            byRidName.push(ridNameCleaned.slice(0, 8));
            byRidNewReg.push(ridData[2]['longValue']);
            byRidDisplay.push(ridData[1]['stringValue'])
        });
        byRidData = [byRidName, byRidNewReg, byRidDisplay];
        yield put(setAverageRevenueDataByRid(byRidData));

        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getAverageRevenueFilterOption() {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setPageTopLoader(true));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('x'),
            moment(dateTo+" 23:59:59+8:00").format('x')
        ];

        // DATA2.0
        const isDataNew = moment(dateFrom).isAfter('2023-06-30') && moment(dateTo).isAfter('2023-06-30') ? true : false;
        const isDataOld = moment(dateFrom).isBefore('2023-07-01') && moment(dateTo).isBefore('2023-07-01') ? true : false;
        const isDataMix = moment(dateFrom).isBefore('2023-07-01') && moment(dateTo).isAfter('2023-06-30') ? true : false;

        const regTable = isDataNew ? 'RegistrationPmEvtProd' : 'RegistrationProd';

        const sqlQueryGameOption = {
            "query": `SELECT game, COUNT(DISTINCT pmId) as count
            FROM ${regTable}
            WHERE ${regTable}.exist = 'new' AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.game ORDER BY count DESC`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountryOption = {
            "query": `SELECT country, COUNT(DISTINCT pmId) as count
            FROM ${regTable}
            WHERE ${regTable}.exist = 'new' AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.country ORDER BY count DESC`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryRidOption = {
            "query": `SELECT ${regTable}.rid, COUNT(DISTINCT ${regTable}.pmId) as count, CampaignStats.name AS cname
            FROM ${regTable}
            LEFT OUTER JOIN CampaignStats ON ${regTable}.rid = CampaignStats.rid
            WHERE ${regTable}.exist = 'new' AND ${regTable}.regDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} 
            GROUP BY ${regTable}.rid ORDER BY count DESC`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const [
            tempGameOption,
            tempCountryOption,
            tempRidOption,
        ] = yield all([
            call(getDataFromRDS, sqlQueryGameOption, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountryOption, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryRidOption, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedGameOption = JSON.parse(tempGameOption.body);
        const parsedCountryOption = JSON.parse(tempCountryOption.body);
        const parsedRidOption = JSON.parse(tempRidOption.body);

        let gameOption = [], eachOption = {};
        _.each(parsedGameOption, function(gameData) {
            eachOption.name = `${gameData[0]['stringValue']} (${gameData[1]['longValue']})`;
            eachOption.value = gameData[0]['stringValue'];
            gameOption.push(eachOption);
            eachOption = {};
        });
        yield put(setAverageRevenueGameOption(gameOption));

        let countryOption = [];
        _.each(parsedCountryOption, function(countryData) {
            eachOption.name = `${countryData[0]['stringValue']} (${countryData[1]['longValue']})`;
            eachOption.value = countryData[0]['stringValue'];
            countryOption.push(eachOption);
            eachOption = {};
        });
        yield put(setAverageRevenueCountryOption(countryOption));

        let ridOption = [];
        _.each(parsedRidOption, function(ridData) {
            let cname = ridData[2]['isNull'] ? "" : H.FormatNumStrHelper.unEscapeUnicode(ridData[2]['stringValue']);
            eachOption.name = `${cname} ${ridData[0]['stringValue']} (${ridData[1]['longValue']})`;
            eachOption.value = ridData[0]['stringValue'];
            ridOption.push(eachOption);
            eachOption = {};
        });
        yield put(setAverageRevenueRidOption(ridOption));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(KPI.GET_AVG_REVENUE_PAGE_DATA, getAverageRevenueData);
    yield takeEvery(KPI.GET_AVG_REVENUE_FILTER_OPTION, getAverageRevenueFilterOption);
}
