import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ChartStyle from 'styles/ChartStyle';
import { chartLineWidth } from 'styles/variables';
import Label from 'utils/Label';
import ChartConfig from 'utils/ChartConfig';
import * as V from 'styles/variables';

const useStyles = makeStyles((theme) => ({
    chartContainer: {
        marginTop: 16,
        marginLeft: 16,
        width: '100%',
        display: 'block'
    }
}));

const LineChartTrend = ({
    legend,
    lblSaveImage,
    xAisData,
    seriesData,
    chartType,
    height,
}) => {
    const classes = useStyles();
    const [chartData, setChartData] = useState([]);
    const [color, setColorArray] = useState([]);
    const [zoomStart, setZoomStart] = useState(0);
    const [zoomEnd, setZoomEnd] = useState(100);

    useEffect(() => {
        echarts.registerTheme('BarChartTheme', ChartStyle);
    }, []);

    useEffect(() => {
        let eachChartSeries = {}, seriesDataArray = [];
        _.forOwn(seriesData, function(value, index) {
            _.forOwn(value, function(value2, key2) {
                if (key2 === 'data') {
                    eachChartSeries.name = legend[index];
                    eachChartSeries.type = chartType;
                    eachChartSeries.data = value2;
                    eachChartSeries.lineStyle = {width: chartLineWidth};
                    eachChartSeries.symbolSize = seriesData[0]['date'].length > 60 ? 4 : 8;
                    eachChartSeries.barGap = 0;
                    eachChartSeries.barMaxWidth = 20;
                    seriesDataArray.push(eachChartSeries);
                    eachChartSeries = {};
                }
            });
        });
        setChartData(seriesDataArray);
        setColorArray(V.genericColorPallette);
    }, [seriesData]);

    const dataZoomChanged = (param) => {
        setZoomStart(param.batch[0]['start']);
        setZoomEnd(param.batch[0]['end']);
    }

    const onEvents = {
        'datazoom': dataZoomChanged,
    }

    return (
        <div className={classes.chartContainer}>
            <ReactEcharts
                option={{
                    tooltip: {
                        trigger: "axis",
                        axisPointer : {
                            type : 'none'
                        },
                        order:'valueDesc',
                    },
                    grid: {
                        left: 64,
                        top: 48,
                        right: 48,
                        bottom: 32
                    },
                    legend: {
                        show: true,
                        data: legend
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            saveAsImage: {
                                show: false,
                                title: "Save",
                                name: lblSaveImage,
                                icon: 'image://../../static/images/font-icon/save-chart.svg'
                            },
                        }
                    },
                    xAxis: {
                        type: "category",
                        data: xAisData
                    },
                    yAxis: [
                        {
                            type: "value",
                            name: '件数',
                            minInterval: 1,
                        },
                    ],
                    dataZoom: [
                        {
                            disabled: true,
                            type: 'inside',
                            start: zoomStart,
                            end: zoomEnd
                        },
                    ],
                    series: chartData,
                    color: color,
                }}
                theme='BarChartTheme'
                notMerge={true}
                lazyUpdate={true}
                onEvents={onEvents}
                style={{height: height, width: '98%'}}
            />
        </div>
    );
};

LineChartTrend.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(LineChartTrend));
