import React, { Fragment, useState, useEffect } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginLeft: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectLabel: {
    fontSize: 12,
    marginTop: 8,
    marginLeft: 8,
  }
}));

export default function SingleSelect(props) {
  const classes = useStyles();
  // const [state, setState] = useState({
  //   [props.name]: '',
  //   name: 'hai',
  // });

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   console.log('tttarget name:', event.target.name);
  //   console.log('tttarget value:', event.target.value);
  //   setState({
  //     ...state,
  //     [name]: event.target.value,
  //   });
  // };

  return (
    <div>
      <div className={classes.selectLabel}>{props.helperText}</div>
      <FormControl variant="standard" className={classes.formControl}>
        <Select
          native
          //value={state[props.name]}
          value={props.selected.value}
          onChange={props.handleChange}
          inputProps={{
            name: props.name,
          }}
          disabled={props.disabled ? true : false}
        >
          {props.options.map(({ value, name }, index) => <option value={value} key={index}>{name}</option>)}
        </Select>
      </FormControl>
    </div>
  );
}
