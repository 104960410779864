import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { DateRangePicker } from "materialui-daterange-picker";
import { setActiveUsersDateRange } from 'actions';

const useStyles = makeStyles((theme) =>
    createStyles({
        calendarWrapper: {
            paddingLeft: 8,
        },
        root: {
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        container: {
            display: 'flex',
            width: 300,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            alignItems: 'baseline',
        },
        delimiter: {
            paddingTop: theme.spacing(1)
        },
        sectionCalendar: {
            display:'flex',
        },
        resetBtn: {
            marginTop: 12,
            marginLeft: 6,
            height: theme.spacing(4),
            color: "#000",
        },
    }),
);

const ActiveUsersRangePicker = ({
    setActiveUsersDateRange,
    cohortDateRange,
    setBtnState,
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState({});
    const toggle = () => setOpen(!open);

    useEffect(() => {
        const currentJstDate = moment().tz("Asia/Tokyo").format('YYYY/MM/DD');
    }, []);

    return (
        <DateRangePicker
            open={true}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
        />
    );
}

ActiveUsersRangePicker.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    cohortDateRange: state.cohort.cohortDateRange,
});

const mapDispatchToProps = (dispatch) => ({
    setActiveUsersDateRange: (param) => dispatch(setActiveUsersDateRange(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(ActiveUsersRangePicker));