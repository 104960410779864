// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from '@material-ui/core/styles';
import * as H from 'helper';

const Top10ByCountryTable = ({
    title,
    loading,
    dataByCountry,
    selectedCurrency,
    depPrice,
    columLbl,
    totalCount,
}) => {
    const [geoData, setGeoData] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        let totalNum = totalCount;
        let dataArray = [], dataObj = {};
        for (let i=0; i<dataByCountry.length; i++) {
            dataObj.no = i+1;
            dataObj.url = dataByCountry[i]['country'] === '(not set)' ? false : H.CountryHelper.getCountryCode(dataByCountry[i]['country']);
            dataObj.country = dataByCountry[i]['country'];
            dataObj.num_amount = dataByCountry[i]['value'];
            dataObj.num_ratio = (dataByCountry[i]['value'] / totalNum * 100).toFixed(2);
            dataArray.push(dataObj);
            dataObj = {};
        }
        setGeoData(dataArray);
    }, [dataByCountry, totalCount]);

    useEffect(() => {
        setLoading(loading);
    }, [loading]);

    return(
        <MaterialTable
            isLoading={isLoading}
            style={{padding: 16, fontSize: 14, minHeight: 580}}
            title={<div style={{fontSize: 18, fontWeight: 500, color: '#000'}}>{title}</div>}
            columns={[
                { title: '#', field: 'no', width: 50, cellStyle: {textAlign:'center'}},
                { title: '国', field: 'country', width: 350,
                    render: rowData => <Fragment>
                                            <div style={{display:'flex'}}>
                                                <div>
                                                    {rowData.url &&
                                                    <img src={rowData.url} style={{paddingTop: 2, paddingRight: 8, width: 24}}/>
                                                    }
                                                </div>
                                                <div style={{paddingTop: 4}}>{rowData.country}</div>
                                            </div>
                                        </Fragment>
                },
                { title: columLbl, field: 'num_amount', width: 200, type: 'numeric', cellStyle: {textAlign:'center'},
                    render: rowData => <div>{H.FormatNumStrHelper.formatCommaThousand(rowData.num_amount)}</div>},
                { title: '全体比率',field: 'num_ratio', type: 'numeric', cellStyle: {textAlign:'left', marginLeft: 32},
                    render: rowData => <Fragment>
                                            <div style={{display:'flex', height: 16}}>
                                                <div style={{paddingRight: 16, width: 24}}>{rowData.num_ratio}%</div>
                                                <div style={{backgroundColor: '#65C9A5', width:rowData.num_ratio+'%', marginLeft: 20, marginTop:3}}></div>
                                            </div>
                                        </Fragment>
                },
            ]}
            data={geoData}
            options={{
                search: false,
                paging: false,
                sorting: false,
                rowStyle: {
                    textAlign:'center',
                },
                headerStyle: {textAlign:'center'}
            }}
            localization={{
                body: {
                    emptyDataSourceMessage: '表示可能なデータがありません',
                },
            }}
        />
    )
};

Top10ByCountryTable.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    selectedCurrency: state.page.selectedCurrency,
    depPrice: state.page.depPrice,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Top10ByCountryTable));
