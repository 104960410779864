import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import ScholarGuild from 'components/JobTribes/ScholarGuild';
import { setCurrentPage, setFocusTabIndex, getScholarOwnerScholarStatsData } from 'actions';
import * as C from 'components';
import * as H from 'helper';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={1}>
            <Typography component={'span'}>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        position: '-webkit-sticky',
        position: 'sticky',
        top: 0,
        zIndex: 1000,
    },
    titleTopSummary: {
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: 12,
    },
    fillRemailSpace: {
        flex: 'auto'
    },
    sectionTop: {
        marginTop: -16,
        marginBottom: 8,
        [theme.breakpoints.down('sm')]: {
            marginTop: 8
        },
    },
    flexContainerNotFix: {
        display: 'flex',
        backgroundColor: '#fafafa',
        overflown: 'hidden',
        width: '100%',
        flexFlow: 'row wrap',
        fontSize: 14,
    },
    flexItemLeftBox: {
        alignSelf: 'center',
        paddingLeft: 8,
        paddingRight: 8,
        paddingBottom: 8,
        borderRight: '1px solid #dcdcdc',
    },
}));

const Overview = ({
    dateRange,
    selectedCurrency,
    setCurrentPage,
    focusTabIndex,
    loading,
    setFocusTabIndex,
    getScholarOwnerScholarStatsData,
    dataUniqueOwnerCountByDate,
    dataUniqueScholarCountByDate,
    dataOwnerByCountry,
    dataScholarByCountry,
    dataCountScholarByOwner,
    dataCountAmuletByOwner,
    dataCountDepByOwner,
    dataCountDepByScholar,
    dataTotalAmuletCountByDate,
    dataTotalDepCountByDate,
    dataTotalScholarDepCountByDate,
    dataNumOwner,
    dataNumScholar,
}) => {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setFocusTabIndex(newValue);
    };

    useEffect(() => {
        switch(tabValue) {
            case 0 :
                getScholarOwnerScholarStatsData();
                setCurrentPage("jobtribes/scholar/overview");
                break;
            case 1 :
                setCurrentPage("jobtribes/scholar/guildkpi");
                break;
            case 2 :
                setCurrentPage("jobtribes/scholar/amulet");
                break;
            default:
        }
    }, [tabValue]);

    useEffect(() => {
        setCurrentPage("jobtribes/scholar/overview");
        getScholarOwnerScholarStatsData();
    }, []);

    const getPrefix = (selectedCurrency) => {
        if (selectedCurrency === 'usd') {
            return "USD";
        } else if (selectedCurrency === 'sgd') {
            return "SGD";
        } else if (selectedCurrency === 'jpy') {
            return "JPY"
        } else {
            return "DEP";
        }
    }

    return (
        <C.ContentWrapper>
            <Grid container direction="row" className={classes.sectionTop} justifyContent="flex-end">
                <Grid item xs={12} md={12} lg={6} xl={7}>
                    <div className={classes.titleTopSummary}>通算データ 2021/12/14 ~ {moment().tz("Asia/Singapore").subtract(1, "days").format('YYYY/MM/DD')}</div>
                    <div className={classes.flexContainerNotFix}>
                        <div className={classes.flexItemLeftBox}>Owner有効登録 : {H.FormatNumStrHelper.formatCommaThousand(dataNumOwner.total)}
                        &nbsp;停止数: {H.FormatNumStrHelper.formatCommaThousand(dataNumOwner.stop)} </div>
                        <div className={classes.flexItemLeftBox}>Scholar有効登録:&nbsp;{H.FormatNumStrHelper.formatCommaThousand(dataNumScholar.total)}&nbsp;停止数: {H.FormatNumStrHelper.formatCommaThousand(dataNumScholar.stop)}</div>
                        <div className={classes.flexItemLeftBox}>貸出アミュレット数: {H.FormatNumStrHelper.formatCommaThousand(dataTotalAmuletCountByDate.count)}</div>
                        <div className={classes.flexItemLeftBox}>DEAPCheck額: {H.FormatNumStrHelper.formatCommaThousand(dataTotalDepCountByDate.count)} DEP</div>
                    </div>
                </Grid>
                <Grid item className={classes.fillRemailSpace}></Grid>
                <Grid item>
                    <C.CalendarRangePicker
                        mDate={'2021/12/14'}
                    />
                </Grid>
            </Grid>
            <div className={classes.root}>
                <AppBar position="sticky">
                    <Tabs
                        value={tabValue}
                        onChange={handleChange}
                        TabIndicatorProps={{style: {background:'#65c9a5', height: 4}}}
                        variant="fullWidth"
                    >
                        <Tab label="概要" {...a11yProps(0)} />
                        <Tab label="広義・狭義ギルドKPI" {...a11yProps(1)} />
                        <Tab label="トレンド" {...a11yProps(2)} />
                        <Tab label="アミュレット" {...a11yProps(3)} />
                        <Tab label="獲得DEP Owner ランキング" {...a11yProps(4)} />
                        <Tab label="獲得DEP Scholar ランキング" {...a11yProps(5)} />
                    </Tabs>
                </AppBar>
            </div>
            <TabPanel value={tabValue} index={0}>
                <C.JtcbScholarStats />
                <Grid container spacing={3}>
                    <Grid item sm={12} lg={6}>
                        <C.Top10ByCountryTable
                            title={"TOP10 国別Owner数 ("+moment(dateRange[1]).format('YYYY/MM/DD')+"時点)"}
                            dataByCountry={dataOwnerByCountry}
                            loading={loading.ownerByCountry}
                            columLbl="登録数"
                            totalCount={dataUniqueOwnerCountByDate.count}
                        />
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <C.Top10ByCountryTable
                            title={"TOP10 国別Scholar数 ("+moment(dateRange[1]).format('YYYY/MM/DD')+"時点)"}
                            dataByCountry={dataScholarByCountry}
                            loading={loading.scholarByCountry}
                            columLbl="登録数"
                            totalCount={dataUniqueScholarCountByDate.count}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={12} lg={6}>
                        <C.Top10ByOwnerTable
                            title={"TOP10 Owner別獲得Scholar数 ("+moment(dateRange[1]).format('YYYY/MM/DD')+"時点)"}
                            dataByCountry={dataCountScholarByOwner}
                            loading={loading.countStatsByOwner}
                            columLbl="獲得数"
                            totalCount={dataUniqueScholarCountByDate.count} 
                        />
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <C.Top10ByOwnerTable
                            title={"TOP10 Owner別貸し出しアミュレット数 ("+moment(dateRange[1]).format('YYYY/MM/DD')+"時点)"}
                            dataByCountry={dataCountAmuletByOwner}
                            loading={loading.countStatsByOwner}
                            columLbl="貸出数"
                            totalCount={dataTotalAmuletCountByDate.count} 
                        />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ScholarGuild />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <C.JtcbScholarTrend />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                <C.JtcbScholarAmulet />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item sm={12} lg={10}>
                        <C.Top10ByOwnerTable
                            title={"TOP100 Owner別DEP獲得ランキング ("+moment(dateRange[1]).format('YYYY/MM/DD')+"時点)"}
                            dataByCountry={dataCountDepByOwner}
                            loading={loading.countStatsByOwner}
                            columLbl={getPrefix(selectedCurrency)}
                            totalCount={dataTotalDepCountByDate.count}
                            usernNameWidth={200}
                            pmIdWidth={200}
                            currency
                            is100Rank
                        />
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={5}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item sm={12} lg={10}>
                        <C.Top10ByScholarTable
                            title={"TOP100 Scholar別DEP獲得ランキング ("+moment(dateRange[1]).format('YYYY/MM/DD')+"時点)"}
                            dataByCountry={dataCountDepByScholar}
                            loading={loading.countStatsByOwner}
                            columLbl={getPrefix(selectedCurrency)}
                            totalCount={dataTotalScholarDepCountByDate.count}
                            usernNameWidth={200}
                            pmIdWidth={200}
                            currency
                        />
                    </Grid>
                </Grid>
            </TabPanel>
        </C.ContentWrapper>
    );
};

Overview.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    selectedCurrency: state.page.selectedCurrency,
    focusTabIndex: state.nftDep.focusTabIndex,
    loading: state.jtcbScholar.loading,
    dataUniqueOwnerCountByDate: state.jtcbScholar.dataUniqueOwnerCountByDate,
    dataUniqueScholarCountByDate: state.jtcbScholar.dataUniqueScholarCountByDate,
    dataOwnerByCountry: state.jtcbScholar.dataOwnerByCountry,
    dataScholarByCountry: state.jtcbScholar.dataScholarByCountry,
    dataCountScholarByOwner: state.jtcbScholar.dataCountScholarByOwner,
    dataCountAmuletByOwner: state.jtcbScholar.dataCountAmuletByOwner,
    dataCountDepByOwner: state.jtcbScholar.dataCountDepByOwner,
    dataCountDepByScholar: state.jtcbScholar.dataCountDepByScholar,
    dataTotalAmuletCountByDate: state.jtcbScholar.dataTotalAmuletCountByDate,
    dataTotalDepCountByDate: state.jtcbScholar.dataTotalDepCountByDate,
    dataTotalScholarDepCountByDate: state.jtcbScholar.dataTotalScholarDepCountByDate,
    dataNumOwner: state.jtcbScholar.dataNumOwner,
    dataNumScholar: state.jtcbScholar.dataNumScholar,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setFocusTabIndex: (param) => dispatch(setFocusTabIndex(param)),
    getScholarOwnerScholarStatsData: () => dispatch(getScholarOwnerScholarStatsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Overview));
