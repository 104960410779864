import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import { chartLineWidth } from 'styles/variables';
import * as V from 'styles/variables';
import Label from 'utils/Label';

const useStyles = makeStyles((theme) => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
    },
    chartContainer: {
        marginTop: -16,
        width: '100%',
        display: 'block'
    }
}));

const LineChart2 = ({
    legend, color, lblSaveImage, xAisData, dataLoading, seriesData, chartType, comparisonDateDiff
}) => {
    const classes = useStyles();
    const [seriesType, setSeriesType] = useState(chartType);
    const [chartData, setChartData] = useState([]);
    const [stackState, setStackState] = useState('');
    const [zoomStart, setZoomStart] = useState(0);
    const [zoomEnd, setZoomEnd] = useState(100);

    useEffect(() => {
        echarts.registerTheme('BarChartTheme', ChartStyle);
    }, []);

    useEffect(() => {
        let eachChartSeries = {}, seriesDataArray = [];
        _.forOwn(seriesData, function(value, key) {
            if (key !== 'date') {
                eachChartSeries.name = Label.chartSeriesName[key];
                eachChartSeries.type = seriesType;
                eachChartSeries.data = value;
                eachChartSeries.lineStyle = {width: chartLineWidth};
                eachChartSeries.symbolSize = 8;
                eachChartSeries.barGap = 0;
                eachChartSeries.barMaxWidth = 20;
                seriesDataArray.push(eachChartSeries);
                eachChartSeries = {};
            }
        });
        setChartData(seriesDataArray);
    }, [seriesData, seriesType]);

    const magicTypeChanged = (param) => {
        console.log('param:', param.currentType);
        if (param.currentType === 'stack') {
            setStackState('__ec_magicType_stack__');
        } else if (param.currentType === 'tiled') {
            setStackState(null);
        } else {
            setSeriesType(param.currentType);
        }
    }

    const dataZoomChanged = (param) => {
        setZoomStart(param.batch[0]['start']);
        setZoomEnd(param.batch[0]['end']);
    }

    const onEvents = {
        'magictypechanged': magicTypeChanged,
        'datazoom': dataZoomChanged,
    }

    return (
        <div className={classes.chartContainer}>
            {dataLoading === true ? (
                <div className={classes.progress}><CircularProgress /></div>
            ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                        },
                        grid: {
                            left: 48,
                            top: 48,
                            right: 48,
                            bottom: 32
                        },
                        legend: {
                            show: legend ? true : false,
                            data: legend
                        },
                        toolbox: {
                            show: true,
                            feature: {
                                magicType : {
                                    show: true,
                                    type: ['bar', 'line'],
                                    title: {
                                        line: "Line",
                                        bar: "Bar",
                                        stack: "Stack",
                                        tiled: "Side by Side",
                                    },
                                    icon: {
                                        line: 'image://../../static/images/font-icon/line-chart.svg',
                                        bar: 'image://../../static/images/font-icon/bar-chart.svg',
                                    }
                                },
                                saveAsImage: {
                                    show: true,
                                    title: "Save",
                                    name: lblSaveImage,
                                    icon: 'image://../../static/images/font-icon/save-chart.svg'
                                }
                            }
                        },
                        xAxis: {
                          type: "category",
                          data: xAisData
                        },
                        yAxis: {
                          type: "value"
                        },
                        dataZoom: [
                            {
                                disabled: comparisonDateDiff < 31 ? true : false,
                                type: 'inside',
                                start: zoomStart,
                                end: zoomEnd
                            },
                        ],
                        series: chartData,
                        color: color,
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{height: '350px', width: '100%'}}
                />
            )}
        </div>
    );
};

LineChart2.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dataLoading: state.puzzle.dataLoading,
    comparisonDateDiff: state.page.comparisonDateDiff,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(LineChart2));