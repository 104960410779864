import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import LineChartStyles from './LineChartStyles';
import { firstTimeColor, conversionColor, regColor, chartLineWidth } from 'styles/variables';
import * as V from 'styles/variables';
import { TramOutlined } from "@material-ui/icons";

export class LineChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seriesType: 'bar',
            stackState: '',
            zoomStart: 0,
            zoomEnd: 100,
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    async componentDidMount() {

    }

    magicTypeChanged = (param) => {
        if (param.currentType === 'stack') {
            this.setState({ stackState: '__ec_magicType_stack__' });
        } else if (param.currentType === 'tiled') {
            this.setState({ stackState: null });
        } else {
            this.setState({ seriesType: param.currentType });
        }
    }

    dataZoomChanged = (param) => {
        this.setState({ zoomStart: param.batch[0]['start'] });
        this.setState({ zoomEnd: param.batch[0]['end'] });
    }

    render () {
        const { classes, legend, color, comparisonDateDiff } = this.props;
        let { seriesType, stackState, zoomStart, zoomEnd } = this.state;

        let onEvents = {
            'magictypechanged': this.magicTypeChanged,
            'datazoom': this.dataZoomChanged,
        }

        return (
            <div className={classes.chartContainer}>
                {this.props.dataLoading === true ? (
                    <div className={classes.progress}><CircularProgress /></div>
                ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                            order:'valueDesc',
                        },
                        grid: {
                            left: 48,
                            top: 48,
                            right: 48,
                            bottom: 32
                        },
                        legend: {
                            data: legend
                        },
                        toolbox: {
                            show: true,
                            feature: {
                                magicType : {
                                    show: TramOutlined,
                                    type: ['bar', 'line' /*, 'stack', 'tiled'*/],
                                    title: {
                                        line: "Line",
                                        bar: "Bar",
                                        stack: "Stack",
                                        tiled: "Side by Side",
                                    },
                                    icon: {
                                        line: 'image://../../static/images/font-icon/line-chart.svg',
                                        bar: 'image://../../static/images/font-icon/bar-chart.svg',
                                        // stack: 'image://../../static/images/font-icon/stack.svg',
                                        // tiled: 'image://../../static/images/font-icon/tile-chart.svg',
                                    }
                                },
                                saveAsImage: {
                                    show: true,
                                    title: "Save",
                                    name: "Puzzle 集客トレンド",
                                    icon: 'image://../../static/images/font-icon/save-chart.svg'
                                }
                            }
                        },
                        xAxis: {
                          type: "category",
                          data: this.props.acquisitionDataRegTrend.date
                        },
                        yAxis: {
                          type: "value"
                        },
                        dataZoom: [
                            {
                                disabled: comparisonDateDiff < 31 ? true : false,
                                type: 'inside',
                                start: zoomStart,
                                end: zoomEnd
                            },
                        ],
                        series: [
                            {
                                name: 'PMID登録者数',
                                type: seriesType,
                                stack: stackState,
                                data: this.props.acquisitionDataRegTrend.pmidRegNumTotal,
                                lineStyle: {
                                    width: chartLineWidth,
                                },
                                symbolSize: 8,
                                barGap: 0,
                                barMaxWidth: 30,
                            },
                            {
                                name: '広告経由PMID登録者数',
                                type: seriesType,
                                stack: stackState,
                                data: this.props.acquisitionDataRegTrend.pmidRegNumAd,
                                lineStyle: {
                                    width: chartLineWidth,
                                },
                                symbolSize: 8,
                                barGap: 0,
                                barMaxWidth: 30,
                            },
                            {
                                name: 'Organic PMID登録者数',
                                type: seriesType,
                                stack: stackState,
                                data: this.props.acquisitionDataRegTrend.pmidRegNumOrg,
                                lineStyle: {
                                    width: chartLineWidth,
                                },
                                symbolSize: 8,
                                barGap: 0,
                                barMaxWidth: 30,
                            },
                            {
                                name: 'PMID新規登録者数',
                                type: seriesType,
                                stack: stackState,
                                data: this.props.acquisitionDataRegTrend.pmidRegExist,
                                lineStyle: {
                                    width: chartLineWidth,

                                },
                                symbolSize: 8,
                                barGap: 0,
                                barMaxWidth: 30,
                            },
                            {
                                name: 'PMID既存登録者数',
                                type: seriesType,
                                stack: stackState,
                                data: this.props.acquisitionDataRegTrend.pmidRegNew,
                                lineStyle: {
                                    width: chartLineWidth,
                                },
                                symbolSize: 8,
                                barGap: 0,
                                barMaxWidth: 30,
                            }
                        ],
                        color: color,
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{height: '350px', width: '100%'}}
                />
                )}
            </div>
        )
    }
}

LineChart.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    acquisitionDataRegTrend: state.puzzle.acquisitionDataRegTrend,
    dataLoading: state.puzzle.dataLoading,
    comparisonDateDiff: state.page.comparisonDateDiff,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(LineChartStyles, { withTheme: true })(LineChart));