import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, TextField, Button, Grid, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, setNftOwnTrendOption, setNftOwnTrendOptionSelected, getNftOwnTrendData, setNftOwnTrendDateRange } from 'actions';
import NftOwnTrendTable from 'components/Table/NftOwnTrendTable';
import * as C from 'components';
import * as V from 'styles/variables';
import ChartConfig from 'utils/ChartConfig';
import NftDepConfig from 'utils/NftDepConfig';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NftOwnTrend = ({
    nftOwnTrendOption,
    nftOwnTrendOptionSelected,
    nftOwnTrendData,
    setCurrentPage,
    setNftOwnTrendOption,
    setNftOwnTrendOptionSelected,
    getNftOwnTrendData,
    nftOwnTrendDataLoading,
    setNftOwnTrendDateRange,
}) => {
    const classes = useStyles();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [chartState, setChartState] = useState(false);
    const [kindOptions, setKindOptions] = useState(NftDepConfig.nftOwnTrendOption);
    const [chartData, setChartData] = useState([]);

    const handleChangeOption = (data) => {
        let optionSelectedArray = [];
        _.forEach(data, function(item, i) {
            optionSelectedArray.push(item.value)
        });
        setNftOwnTrendOptionSelected(data);
        setBtnState(false);
    }

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        setNftOwnTrendOption(kindOptions);
        const startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
        const endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        setNftOwnTrendDateRange([startDate, endDate]);
        getNftOwnTrendData();
    }, []);

    // useEffect(() => {
    //     if (nftOwnTrendOptionSelected.length == 0) {
    //         setBtnState(true);
    //     } else {
    //         setBtnState(false);
    //     }
    // }, [nftOwnTrendOptionSelected]);

    useEffect(() => {
        setChartData(nftOwnTrendData);
        if (nftOwnTrendData.length > 0) {
            setChartState(true);
        }
    }, [nftOwnTrendData]);

    const fetchData = () => {
        getNftOwnTrendData();
        setChartState(true);
        setBtnState(!btnStateDataFetch);
    }

    return (
        <C.ContentWrapper>
            <div className={classes.filterSection}>
            <Grid container spacing={0} direction="row" justifyContent="space-between">
                <Grid item>
                    {/* <Autocomplete
                        multiple
                        limitTags={3}
                        size="small"
                        id="select-game"
                        options={kindOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        onChange={(e, newValue) => {
                            handleChangeOption(newValue);
                        }}
                        style={{ minWidth: 400 }}
                        renderInput={(params) => (
                            <TextField {...params} label="ステータス" placeholder={nftOwnTrendOption.length === 0 ? "選択してください" : ""} />
                        )}
                        className={classes.autoSelect}
                    /> */}
                </Grid>
                <Grid item>
                    <Grid container spacing={0} direction="row">
                        <Grid item>
                            <C.CalendarRangeOnly
                                setBtnState={setBtnState}
                                page="nftOwnTrend"
                            />
                        </Grid>
                        <Grid item>
                            <Button 
                                //disabled={btnStateDataFetch} 
                                className={classes.dataBtn} 
                                variant="contained" 
                                color="primary" 
                                onClick={() => fetchData()}>
                                GO
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </div>

            <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                {nftOwnTrendDataLoading 
                    ? <div className={classes.loaderBox}>
                        <img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} />
                      </div> 
                    : chartState
                        ? chartData.length > 0
                          ? <C.LineChartNftOwnTrend
                                legend={ChartConfig.legendNftOwnTrend}
                                lblSaveImage={"NFT保有トレンド"}
                                seriesData={chartData}
                                chartType={'line'}
                            />
                          : null
                        : <div className={classes.selectGameTxt}>
                            <Chip label="ステータスを選択してください" style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                          </div>
                }
                </Grid>
            </Grid>
            <div className={classes.tableData}>
                <NftOwnTrendTable loading={nftOwnTrendDataLoading} data={nftOwnTrendData} seriesData={chartData} />
            </div>
        </C.ContentWrapper>
    );
};

NftOwnTrend.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    nftOwnTrendOption: state.nftOwnTrend.nftOwnTrendOption,
    nftOwnTrendOptionSelected: state.nftOwnTrend.nftOwnTrendOptionSelected,
    nftOwnTrendData: state.nftOwnTrend.nftOwnTrendData,
    nftOwnTrendDataLoading: state.nftOwnTrend.nftOwnTrendDataLoading,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setNftOwnTrendOption: (param) => dispatch(setNftOwnTrendOption(param)),
    setNftOwnTrendOptionSelected: (param) => dispatch(setNftOwnTrendOptionSelected(param)),
    getNftOwnTrendData: () => dispatch(getNftOwnTrendData()),
    setNftOwnTrendDateRange: (param) => dispatch(setNftOwnTrendDateRange(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(NftOwnTrend));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBoxForUnique: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 110,
        },
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        filterSection: {
            display: 'flex',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        tableData: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        selectGameTxt: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 280,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        legendLabel: {
            float: 'left',
            marginLeft: '7px',
            fontSize: '13px',
        },
        legendValue: {
            float: 'right',
            fontSize: '13px',
        },
    }),
);
