import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import * as C from 'components';
import * as H from 'helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        totalStatsBox: {
            marginTop: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontSize: 14,
            borderRight: '1px solid #dcdcdc',
        },
        tsTitle: {
            fontFamily: "Roboto",
            fontSize: 14,
            height: theme.spacing(3),
            fontWeight: 400,
        },
        tsPrimeContent: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            width: '95%',
            fontSize: 20,
            fontWeight: 500,
        },
        tsContent: {
            marginBottom: theme.spacing(0.3),
            display: 'flex',
            width: '95%',
            borderBottom: '1px dashed #dcdcdc'
        },
        tsPrimeLabel: {
            height: theme.spacing(3),
            fontWeight: 500,
            flex: 1,
            textAlign: 'left'
        },
        tsLabel0: {
            flexGrow: 2,
            textAlign: 'left',
        },
        tsLabel: {
            flexGrow: 3,
            textAlign: 'left',
        },
        lblPercent: {
            flexGrow: 1,
            textAlign: 'right',
            fontSize: 13,
        },
    }),
);

const PmExpand = ({
    totalRegStatsByXDate,
    acquisitionHomeDataReg,
    acquisitionHomeDataRegPrev,
    acquisitionHomeDataRegLast,
    activeUserHomeData,
    dateRange,
    wauDateRange,
    mauDateRange,
}) => {
    const classes = useStyles();
    useEffect(() => {
    }, []);

    const getTierPieColour = (colour, data) => {
        let returnColourArray = [];
        for (let i=0; i<data.length; i++) {
            let name = data[i]['name'];
            returnColourArray.push(colour[name]);
        }
        return returnColourArray
    }

    const getStatValue = (data) => {
        if (data === 'loading' || data === '-') {
            return 'Loading..';
        } else {
            return H.FormatNumStrHelper.formatCommaThousand(data);
        }
    }

    const getRatioAdOrg = (num) => {
        if (num ===  '-' || num === 'loading')
            return '--%';
        return (num / totalRegStatsByXDate.gamePmid * 100).toFixed(2)+'%';
    }

    const getRatioTier = (num) => {
        if (num ===  '-' || num === 'loading')
            return '--%';
        const totalTiers = totalRegStatsByXDate.tier1 + totalRegStatsByXDate.tier2 + totalRegStatsByXDate.tier3;
        return (num / totalTiers * 100).toFixed(2)+'%';
    }

    return(
        <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={12}>
                <Grid container spacing={0}>
                    <Grid item sm={12} md={12} lg={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className={classes.totalStatsBox}>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={12} lg={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.ProductStats
                                    game="playMining"
                                    title={"PlayMining全体 <br />DAU ("+moment(dateRange[1]).format("M/D")+")"}
                                    tip={"DAU算出日 : " + moment(dateRange[1]).format("M/D")}
                                    type="dau"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.ProductStats
                                    game="playMining"
                                    title={"PlayMining全体 <br />WAU ("+ moment(wauDateRange[0]).format("M/D") + " - " + moment(wauDateRange[1]).format("M/D")+")"}
                                    tip={"WAU算出の7日 : " + moment(wauDateRange[0]).format("M/D") + " - " + moment(wauDateRange[1]).format("M/D")}
                                    type="wau"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.ProductStats
                                    game="playMining"
                                    title={"PlayMining全体 <br />MAU ("+ moment(mauDateRange[0]).format("M/D") + " - " + moment(mauDateRange[1]).format("M/D")+")"}
                                    tip={"MAU算出の30日 : " + moment(mauDateRange[0]).format("M/D") + " - " + moment(mauDateRange[1]).format("M/D")}
                                    type="mau"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

PmExpand.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    totalRegStatsByXDate: state.home.totalRegStatsByXDate,
    acquisitionHomeDataReg: state.home.acquisitionHomeDataReg,
    acquisitionHomeDataRegPrev: state.home.acquisitionHomeDataRegPrev,
    acquisitionHomeDataRegLast: state.home.acquisitionHomeDataRegLast,
    activeUserHomeData: state.home.activeUserHomeData,
    dateRange: state.page.dateRange,
    wauDateRange: state.page.wauDateRange,
    mauDateRange: state.page.mauDateRange,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(PmExpand));