import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getOverviewDataByCampaign } from 'actions';
import * as C from 'components';
import * as H from 'helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: '#fafafa',
        },
    }),
);

const ByCampaign = ({
    dateRange,
    dateLoaded,
    setOverviewTab,
    getOverviewDataByCampaign,
}) => {
    const classes = useStyles();

    useEffect(() => {
        setOverviewTab("campaign");
        if (dateLoaded['nftdep-overview-camapign'] !== undefined) {
            const formatDateRagne = H.FormatDateHelper.formatDateRangeWithDash(dateRange);
            if (!_.isEqual(formatDateRagne, dateLoaded['nftdep-overview-camapign'])) {
                getOverviewDataByCampaign();
            }
        } else {
            getOverviewDataByCampaign();
        }
    }, []);

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByCampaign
                        title="広告別オークション取引額: JobTribes"
                        source="auction"
                        metric="jtcb"
                        metricLabel="支払額"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByCampaign
                        title="広告別ストア取引額: JobTribes"
                        source="store"
                        metric="jtcb"
                        metricLabel="支払額"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByCampaign
                        title="広告別オークション取引額: LuckyFarmer"
                        source="auction"
                        metric="luckyfarmer"
                        metricLabel="支払額"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByCampaign
                        title="広告別ストア取引額: LuckyFarmer"
                        source="store"
                        metric="luckyfarmer"
                        metricLabel="支払額"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByCampaign
                        title="広告別オークション取引額: Puzzle"
                        source="auction"
                        metric="puzzle"
                        metricLabel="支払額"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByCampaign
                        title="広告別ストア取引額: Puzzle"
                        source="store"
                        metric="puzzle"
                        metricLabel="支払額"
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
};

ByCampaign.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    dateLoaded: state.data.dataLoaded,
});

const mapDispatchToProps = dispatch => ({
    setOverviewTab: (param) => dispatch(setOverviewTab(param)),
    getOverviewDataByCampaign: () => dispatch(getOverviewDataByCampaign()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ByCampaign));
