import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDataLoadingForBy, setDataByPmid, setDataLoad } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.nftDepOverview.loading;
export const dataLoaded = state => state.data.dataLoaded;

export function* getByPmidData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByPmid = true;
        yield put(setDataLoadingForBy(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQueryTx1stAmountByPmid = H.SqlHelper.getNftTransactionAmountByPmidBidb2(
            {table: 'SxiNftSalesProd1stV2', limit: 10, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTx1stCountByPmid  = H.SqlHelper.getNftTransactionCountByPmidBidb2(
            {table: 'SxiNftSalesProd1stV2', limit: 10, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTx2ndAmountByPmid = H.SqlHelper.getNftTransactionAmountByPmidBidb2(
            {table: 'SxiNftSalesProd2ndV1', limit: 10, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTx2ndCountByPmid = H.SqlHelper.getNftTransactionCountByPmidBidb2(
            {table: 'SxiNftSalesProd2ndV1', limit: 10, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]}
        );
        const sqlGetTotal1stAmountInRange = H.SqlHelper.getNftTransactionTotalAmountInRangeBidb2({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]});
        const sqlGetTotal2ndAmountInRange = H.SqlHelper.getNftTransactionTotalAmountInRangeBidb2({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]});
        const sqlGetTotal1stCountInRange = H.SqlHelper.getNftTransactionTotalCountInRangeBidb2({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]});
        const sqlGetTotal2ndCountInRange = H.SqlHelper.getNftTransactionTotalCountInRangeBidb2({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]});

        const [
            dataTx1stAmountByPmidTemp,
            //dataTx1stCountByPmidTemp,
            dataTx2ndAmountByPmidTemp,
            //dataTx2ndCountByPmidTemp,
            dataTx1stAmountTotalTemp,
            dataTx2ndAmountTotalTemp,
            dataTx1stCountTotalTemp,
            dataTx2ndCountTotalTemp,
        ] = yield all([
            call(getDataFromSxi, sqlQueryTx1stAmountByPmid, ENDPOINT.GET_DATA_RDS, jwtToken),
            //call(getDataFromSxi, sqlQueryTx1stCountByPmid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx2ndAmountByPmid, ENDPOINT.GET_DATA_RDS, jwtToken),
            //call(getDataFromSxi, sqlQueryTx2ndCountByPmid, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlGetTotal1stAmountInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlGetTotal2ndAmountInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlGetTotal1stCountInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlGetTotal2ndCountInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const dataTx1stAmountTotal = JSON.parse(dataTx1stAmountTotalTemp.body);
        const dataTx2ndAmountTotal = JSON.parse(dataTx2ndAmountTotalTemp.body);
        const dataTx1stCountTotal = JSON.parse(dataTx1stCountTotalTemp.body);
        const dataTx2ndCountTotal = JSON.parse(dataTx2ndCountTotalTemp.body);

        let returnAuctionDataArray = [], dataAmountAuctionByPmid = {};
        const dataTx2ndAmountByPmid = JSON.parse(dataTx2ndAmountByPmidTemp.body);
        _.each(dataTx2ndAmountByPmid, function(data, index) {
            dataAmountAuctionByPmid.pmid = data[2]['stringValue'];
            dataAmountAuctionByPmid.tx = data[1]['longValue'];
            dataAmountAuctionByPmid.name = 'n/a';
            dataAmountAuctionByPmid.email = 'n/a';
            dataAmountAuctionByPmid.value = Number(data[0]['stringValue']);
            dataAmountAuctionByPmid.country = data[3]['stringValue'];
            returnAuctionDataArray.push(dataAmountAuctionByPmid);
            dataAmountAuctionByPmid = {};
        })

        let returnStoreDataArray = [], dataAmountStoreByPmid = {};
        const dataTx1stAmountByPmid = JSON.parse(dataTx1stAmountByPmidTemp.body);
        _.each(dataTx1stAmountByPmid, function(data, index) {
            dataAmountStoreByPmid.pmid = data[2]['stringValue'];
            dataAmountStoreByPmid.tx = data[1]['longValue'];
            dataAmountStoreByPmid.name = 'n/a';
            dataAmountStoreByPmid.email = 'n/a';
            dataAmountStoreByPmid.value =  Number(data[0]['stringValue']);
            dataAmountStoreByPmid.country = data[3]['stringValue'];
            returnStoreDataArray.push(dataAmountStoreByPmid);
            dataAmountStoreByPmid = {};
        });

        const txAmountValue = {
            amount: {
                auction: returnAuctionDataArray,
                store: returnStoreDataArray,  
            },
            // count: {
            //     auction: returnAuctionCountDataArray,
            //     store: returnStoreCountDataArray,  
            // },
            auction_ttl_amount: Number(dataTx2ndAmountTotal[0][0]['stringValue']),
            auction_ttl_count: dataTx2ndCountTotal[0][0]['longValue'],
            store_ttl_amount: Number(dataTx1stAmountTotal[0][0]['stringValue']),
            store_ttl_count: dataTx1stCountTotal[0][0]['longValue'],
        }
        yield put(setDataByPmid(txAmountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByPmid = false;
        yield put(setDataLoadingForBy(loadingUpdate2));

        // set data loaded flag
        const dataExist = yield select(dataLoaded);
        const dataAdd = { "nftdep-overview-pmid" : [dateFrom, dateTo] }
        const newData = _.merge(dataExist, dataAdd);
        yield put(setDataLoad(newData));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_OVERVIEW_DATA_BY_PMID, getByPmidData);
}
