import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setSalesOverviewDataPicTree,
  setSalesTrendDataPicTree,
  setSalesTableDataPicTree,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;
export const startPrevDate = (state) => state.page.prevDateRangePicTree[0];
export const endPrevDate = (state) => state.page.prevDateRangePicTree[1];

export function* getPictreeDataSalesOverview(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const datePrevFrom = yield select(startPrevDate);
    const datePrevTo = yield select(endPrevDate);

    // 1000コイン   = 100円
    // 5500コイン = 500円
    // 12000コイン = 1000円
    // 25000コイン = 2000円
    // 52000コイン = 4000円
    // 145000コイン = 10000円

    const coin_unit_to_yen = {
      1000: 100,
      5500: 500,
      12000: 1000,
      25000: 2000,
      52000: 4000,
      145000: 10000,
    };

    const sqlQueryCoinSumAllTime = {
      query: `SELECT SUM(bceop.unit_amount * bceop.quantity) AS total_sales
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') > '2024-04-13 00:00:00'`,
      database: "KpiDashboard",
      type: "list",
    };

    // WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
    const sqlQueryCoinSum = {
      query: `SELECT SUM(bceop.unit_amount * bceop.quantity) AS sales_in_range
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryCoinSumPrev = {
      query: `SELECT SUM(bceop.unit_amount * bceop.quantity) AS sales_in_range_prev
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    // Tx Count
    const sqlQueryCountTx = {
      query: `SELECT COUNT(*) AS sales_count
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryCountTxPrev = {
      query: `SELECT COUNT(*) AS sales_count_prev
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    // Currency
    const sqlQueryCurrencySum = {
      query: `
        SELECT bceop.unit_amount AS unit, SUM(bceop.unit_amount * bceop.quantity) AS coin_sales_by_unit
        FROM balance_change_event_of_purchased_chargeable_coin bceop
        JOIN balance_change_event bce ON bceop.id = bce.id
        WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY bceop.unit_amount ORDER BY bceop.unit_amount DESC;
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryCurrencySumPrev = {
      query: `
        SELECT bceop.unit_amount AS unit, SUM(bceop.unit_amount * bceop.quantity) AS coin_sales_by_unit
        FROM balance_change_event_of_purchased_chargeable_coin bceop
        JOIN balance_change_event bce ON bceop.id = bce.id
        WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        GROUP BY bceop.unit_amount ORDER BY bceop.unit_amount DESC;
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryCurrencySumTotal = {
      query: `
        SELECT bceop.unit_amount AS unit, SUM(bceop.unit_amount * bceop.quantity) AS coin_sales_by_unit
        FROM balance_change_event_of_purchased_chargeable_coin bceop
        JOIN balance_change_event bce ON bceop.id = bce.id
        WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') > '2024-04-13 00:00:00'
        GROUP BY bceop.unit_amount ORDER BY bceop.unit_amount DESC;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Unique Count
    const sqlQueryCountUnique = {
      query: `SELECT COUNT(DISTINCT bce.player_id) AS count_unique_player
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryCountUniquePrev = {
      query: `SELECT COUNT(DISTINCT bce.player_id) AS count_unique_player_prev
              FROM balance_change_event_of_purchased_chargeable_coin bceop
              JOIN balance_change_event bce ON bceop.id = bce.id
              WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    // first time
    const sqlQueryCountFirst = {
      query: `
        SELECT COUNT(DISTINCT first_purchases.player_id) AS count_first_purchase_player
        FROM (
            SELECT bce.player_id, MIN(bce.occurred_datetime) AS first_purchase_date
            FROM balance_change_event_of_purchased_chargeable_coin bceop
            JOIN balance_change_event bce ON bceop.id = bce.id
            WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
            GROUP BY bce.player_id
            HAVING first_purchase_date BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        ) AS first_purchases
        JOIN (
            SELECT DISTINCT bce.player_id
            FROM balance_change_event_of_purchased_chargeable_coin bceop
            JOIN balance_change_event bce ON bceop.id = bce.id
            WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        ) AS recent_purchases
        ON first_purchases.player_id = recent_purchases.player_id
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const sqlQueryCountFirstPrev = {
      query: `
        SELECT COUNT(DISTINCT first_purchases.player_id) AS count_first_purchase_player_prev
        FROM (
            SELECT bce.player_id, MIN(bce.occurred_datetime) AS first_purchase_date
            FROM balance_change_event_of_purchased_chargeable_coin bceop
            JOIN balance_change_event bce ON bceop.id = bce.id
            WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') >= '2024-04-13 00:00:00'
            GROUP BY bce.player_id
            HAVING first_purchase_date BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        ) AS first_purchases
        JOIN (
            SELECT DISTINCT bce.player_id
            FROM balance_change_event_of_purchased_chargeable_coin bceop
            JOIN balance_change_event bce ON bceop.id = bce.id
            WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        ) AS recent_purchases
        ON first_purchases.player_id = recent_purchases.player_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [tempCoinAmount, tempCoinAmountPrev, tempCountTx, tempCountTxPrev, tempCurrencySumTtl, tempCurrencySum, tempCurrencySumPrev,
           tempCountUni, tempCountUniPrev, tempCountFirst, tempCountFirstPrev, tempCoinAmountAt ] = yield all([
      call(getDataFromSxi, sqlQueryCoinSum, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCoinSumPrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCountTx, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCountTxPrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCurrencySumTotal, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCurrencySum, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCurrencySumPrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCountUnique, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCountUniquePrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCountFirst, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCountFirstPrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      call(getDataFromSxi, sqlQueryCoinSumAllTime, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);

    // Aggregate Yen conversion
    let currencySumValue = 0;
    if (tempCurrencySum.length > 0) {
      _.forEach(tempCurrencySum, function(sales, i) {
        currencySumValue = currencySumValue + (sales.coin_sales_by_unit / sales.unit * coin_unit_to_yen[sales.unit]);
      });
    }
    let currencySumValuePrev = 0;
    if (tempCurrencySumPrev.length > 0) {
      _.forEach(tempCurrencySumPrev, function(sales, i) {
        currencySumValuePrev = currencySumValuePrev + (sales.coin_sales_by_unit / sales.unit * coin_unit_to_yen[sales.unit]);
      });
    }
    let currencySumValueTtl = 0;
    if (tempCurrencySumTtl.length > 0) {
      _.forEach(tempCurrencySumTtl, function(sales, i) {
        currencySumValueTtl = currencySumValueTtl + (sales.coin_sales_by_unit / sales.unit * coin_unit_to_yen[sales.unit]);
      });
    }

    const dataCoinAmount = tempCoinAmount[0]["sales_in_range"] !== null ? tempCoinAmount[0]["sales_in_range"] : 0;
    const dataCoinAmountPrev = tempCoinAmountPrev[0]["sales_in_range_prev"] !== null ? tempCoinAmountPrev[0]["sales_in_range_prev"] : 0;
    const dataCountTx = tempCountTx[0]["sales_count"];
    const dataCountTxPrev = tempCountTxPrev[0]["sales_count_prev"];
    const dataCountUnique = tempCountUni[0]["count_unique_player"];
    const dataCountUniquePrev = tempCountUniPrev[0]["count_unique_player_prev"];
    const dataCountFirst = tempCountFirst[0]["count_first_purchase_player"];
    const dataCountFirstPrev = tempCountFirstPrev[0]["count_first_purchase_player_prev"];
    const dataCoinAmountAt = tempCoinAmountAt[0]["total_sales"];

    // NFT sales data fetch
    let dateFrom = rangeTarget[0];
    let dateTo = rangeTarget[1];
    dateFrom = moment(dateFrom).format('YYYY-MM-DD');
    dateTo = moment(dateTo).format('YYYY-MM-DD');
    const rangeStart = `${dateFrom} 00:00:00`;
    const rangeEnd = `${dateTo} 23:59:59`;
    const rangeStartPrev = `${datePrevFrom} 00:00:00`;
    const rangeEndPrev = `${datePrevTo} 23:59:59`;
    const contentId = H.ContentsHelper.getContentsIdByName("pictree");

    const sqlQuery1st = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStart, rangeEnd], target:contentId});
    const sqlQuery1stPrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStartPrev, rangeEndPrev], target:contentId});
    const sqlQuery2nd = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStart, rangeEnd], target:contentId});
    const sqlQuery2ndPrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStartPrev, rangeEndPrev], target:contentId});

    // API
    const sqlQueryApi = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProdApiV1', type: 'TRADE_1ST_OF_AGENT_PURCHASE', range: [rangeStart, rangeEnd], target:contentId});
    const sqlQueryApiPrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProdApiV1', type: 'TRADE_1ST_OF_AGENT_PURCHASE', range: [rangeStartPrev, rangeEndPrev], target:contentId});

    // AllTime
    const sqlQuery1stAllTime = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [`2024-04-13 00:00:00`, rangeEnd], target:contentId});
    const sqlQuery2ndAllTime = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [`2024-04-13 00:00:00`, rangeEnd], target:contentId});
    const sqlQueryApiAllTime = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProdApiV1', type: 'TRADE_1ST_OF_AGENT_PURCHASE', range: [`2024-04-13 00:00:00`, rangeEnd], target:contentId});

    const [tempNft1st, tempNft1stPrev, tempNft2nd, tempNft2ndPrev, tempNftApi, tempNftApiPrev,
    tempNft1stAT, tempNft2ndAT, tempNftApiAT] = yield all(
      [
        call(getDataFromRDS, sqlQuery1st, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQuery1stPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQuery2nd, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQuery2ndPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryApi, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryApiPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQuery1stAllTime, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQuery2ndAllTime, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryApiAllTime, ENDPOINT.GET_DATA_USERS, jwtToken),
      ]
    );
    const dataNft1st = JSON.parse(tempNft1st.body);
    const dataNft1stPrev = JSON.parse(tempNft1stPrev.body);
    const dataNft2nd = JSON.parse(tempNft2nd.body);
    const dataNft2ndPrev = JSON.parse(tempNft2ndPrev.body);
    const dataNftApi = JSON.parse(tempNftApi.body);
    const dataNftApiPrev = JSON.parse(tempNftApiPrev.body);

    const dataNft1stAT = JSON.parse(tempNft1stAT.body);
    const dataNft2ndAT = JSON.parse(tempNft2ndAT.body);
    const dataNftApiAT = JSON.parse(tempNftApiAT.body);

    const nft1stAmount = dataNft1st[0][0]['isNull'] ? 0 : parseFloat(dataNft1st[0][0]["stringValue"]);
    const nft1stAmountPrev = dataNft1stPrev[0][0]['isNull'] ? 0 : parseFloat(dataNft1stPrev[0][0]["stringValue"]);
    const nft2ndAmount = dataNft2nd[0][0]['isNull'] ? 0 : parseFloat(dataNft2nd[0][0]["stringValue"]);
    const nft2ndAmountPrev = dataNft2ndPrev[0][0]['isNull'] ? 0 : parseFloat(dataNft2ndPrev[0][0]["stringValue"]);
    const nftApiAmount = dataNftApi[0][0]['isNull'] ? 0 : parseFloat(dataNftApi[0][0]["stringValue"]);
    const nftApiAmountPrev = dataNftApiPrev[0][0]['isNull'] ? 0 : parseFloat(dataNftApiPrev[0][0]["stringValue"]);

    const nft1stAmountAt = dataNft1stAT[0][0]['isNull'] ? 0 : parseFloat(dataNft1stAT[0][0]["stringValue"]);
    const nft2ndAmountAt = dataNft2ndAT[0][0]['isNull'] ? 0 : parseFloat(dataNft2ndAT[0][0]["stringValue"]);
    const nftApiAmountAt = dataNftApiAT[0][0]['isNull'] ? 0 : parseFloat(dataNftApiAT[0][0]["stringValue"]);

    // Data setting
    const overviewData = {
      totalCoin: dataCoinAmount,
      totalCoinPrev: dataCoinAmountPrev,
      countTx: dataCountTx,
      countTxPrev: dataCountTxPrev,
      currencySum: currencySumValue,
      currencySumPrev: currencySumValuePrev,
      currencyTtl: currencySumValueTtl,
      countUnique: dataCountUnique,
      countUniquePrev: dataCountUniquePrev,
      countFirst: dataCountFirst,
      countFirstPrev: dataCountFirstPrev,
      nft1stAmount: nft1stAmount,
      nft1stCount: dataNft1st[0][0]['isNull'] ? 0 : dataNft1st[0][1]["longValue"],
      nft1stAmountPrev: nft1stAmountPrev,
      nft1stCountPrev: dataNft1stPrev[0][0]['isNull'] ? 0 : dataNft1stPrev[0][1]["longValue"],
      nft2ndAmount: nft2ndAmount,
      nft2ndCount: dataNft2nd[0][0]['isNull'] ? 0 : dataNft2nd[0][1]["longValue"],
      nft2ndAmountPrev: nft2ndAmountPrev,
      nft2ndCountPrev: dataNft2ndPrev[0][0]['isNull'] ? 0 : dataNft2ndPrev[0][1]["longValue"],
      nftApiAmount: nftApiAmount,
      nftApiCount: dataNftApi[0][0]['isNull'] ? 0 : dataNftApi[0][1]["longValue"],
      nftApiAmountPrev: nftApiAmountPrev,
      nftApiCountPrev: dataNftApiPrev[0][0]['isNull'] ? 0 : dataNftApiPrev[0][1]["longValue"],
      totalCoinAllTime: dataCoinAmountAt,
      nft1stAmountAllTime: nft1stAmountAt,
      nft2ndAmountAllTime: nft2ndAmountAt,
      nftApiAmountAllTime: nftApiAmountAt,
    };

    yield put(setSalesOverviewDataPicTree([overviewData]));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataSalesTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlQueryDenchuSalesTrend = {
      query: `
        SELECT
          DATE_FORMAT(CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
          SUM(bceop.unit_amount * bceop.quantity) AS sales_amount
        FROM balance_change_event_of_purchased_chargeable_coin bceop
        JOIN balance_change_event bce ON bceop.id = bce.id
        WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE_FORMAT(CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d')
        ORDER BY DAY ASC;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryDenchuSalesTrendTx = {
      query: `
        SELECT
          DATE_FORMAT(CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
          COUNT(*) AS sales_count
        FROM balance_change_event_of_purchased_chargeable_coin bceop
        JOIN balance_change_event bce ON bceop.id = bce.id
        WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE_FORMAT(CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d')
        ORDER BY DAY ASC;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryDenchuSalesTrendUnique = {
      query: `
        SELECT
          DATE_FORMAT(CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
          COUNT(DISTINCT bce.player_id) AS sales_unique_count
        FROM balance_change_event_of_purchased_chargeable_coin bceop
        JOIN balance_change_event bce ON bceop.id = bce.id
        WHERE CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE_FORMAT(CONVERT_TZ(bce.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d')
        ORDER BY DAY ASC;
      `,
      database: "KpiDashboard",
      type: "list",
    };
    // call(getDataFromSxi, sqlQueryCountUniquePrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    const [
      dataTempDenchuSalesTrend,
      dataTempDenchuSalesTrendCount,
      dataTempDenchuSalesTrendUnique,
    ] = yield all([
      call(
        getDataFromSxi,
        sqlQueryDenchuSalesTrend,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
      call(
        getDataFromSxi,
        sqlQueryDenchuSalesTrendTx,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
      call(
        getDataFromSxi,
        sqlQueryDenchuSalesTrendUnique,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
    ]);

    const dataDenchuSalesAmountTrend = dataTempDenchuSalesTrend;
    const dataDenchuSalesTrendCount = dataTempDenchuSalesTrendCount;
    const dataSalesUniqueTrend = dataTempDenchuSalesTrendUnique;

    // Set xAxisDate
    let xAxisDate = [],
      dataSalesAmountArray = [],
      dataSalesCountArray = [],
      dataUniqueArray = [],
      recordArray = [];
    const daysArray = H.FormatDateHelper.getDaysListBetweenDays(
      rangeTarget[0],
      rangeTarget[1]
    );
    _.each(daysArray, function (data) {
      xAxisDate.push(data);
    });

    xAxisDate.forEach((date) => {
      const amountData = _.find(
        dataDenchuSalesAmountTrend,
        (item) => _.get(item, "DAY") === date
      );
      if (amountData) {
        dataSalesAmountArray.push(parseFloat(_.get(amountData, "sales_amount")));
      } else {
        dataSalesAmountArray.push(0);
      }

      // TX Count data
      const countData = _.find(
        dataDenchuSalesTrendCount,
        (item) => _.get(item, "DAY") === date
      );
      if (countData) {
        dataSalesCountArray.push(_.get(countData, "sales_count"));
      } else {
        dataSalesCountArray.push(0);
      }

      // Unique data
      const uniqueData = _.find(
        dataSalesUniqueTrend,
        (item) => _.get(item, "DAY") === date
      );
      if (uniqueData) {
        dataUniqueArray.push(_.get(uniqueData, "sales_unique_count"));
      } else {
        dataUniqueArray.push(0);
      }
    });

    const photoshootCountData = {
      date: xAxisDate,
      amountCoins: dataSalesAmountArray,
      countTx: dataSalesCountArray,
      unique: dataUniqueArray,
    };
    recordArray.push(photoshootCountData);

    yield put(setSalesTrendDataPicTree(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_SALES_OVERVIEW, getPictreeDataSalesOverview);
  yield takeEvery(PICTREE.GET_SALES_TREND, getPictreeDataSalesTrend);
}
