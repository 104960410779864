import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import * as C from 'components';
import * as V from 'styles/variables';

const PuzzleActiveUsers = ({classes, activeUsersData, acquisitionDataRegGender, acquisitionDataRegAgeGroup}) => {
    useEffect(() => {
        console.log('log activeUsersData:', activeUsersData);
    }, [activeUsersData]);

    const getGenderPieColour = (colour, data) => {
        let returnColourArray = [];
;       for (let i=0; i<data.length; i++) {
            let name = data[i]['name'];
            returnColourArray.push(colour[name]);
        }
        return returnColourArray
    }

    return(
        <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <C.StatBox
                            title="昨日"
                            value={activeUsersData.yesterday}
                            prevValue={activeUsersData.yesterdayPrev}
                            color={null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <C.StatBox
                            title="直近7日"
                            value={activeUsersData.last7}
                            prevValue={activeUsersData.last7Prev}
                            color={null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <C.StatBox
                            title="直近14日"
                            value={activeUsersData.last14}
                            prevValue={activeUsersData.last14Prev}
                            color={null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <C.StatBox
                            title="直近30日"
                            value={activeUsersData.last1mo}
                            prevValue={activeUsersData.last1moPrev}
                            color={null}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid item sm={12} md={12} lg={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4}>
                        <C.DoughnutChart
                            title="広告 vs Organic"
                            data={acquisitionDataRegGender}
                            color={getGenderPieColour(V.pieGender, acquisitionDataRegGender)}
                            legendRight={20}
                            legendTop={35}
                            marginLeft={'0%'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <C.DoughnutChart
                            title="新規 vs 既存"
                            data={acquisitionDataRegAgeGroup}
                            color={getGenderPieColour(V.pieAge, acquisitionDataRegAgeGroup)}
                            legendRight={20}
                            legendTop={0}
                            marginLeft={'0%'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <C.DoughnutChart
                            title="言語"
                            data={acquisitionDataRegAgeGroup}
                            color={getGenderPieColour(V.pieAge, acquisitionDataRegAgeGroup)}
                            legendRight={20}
                            legendTop={0}
                            marginLeft={'0%'}
                        />
                    </Grid>
                </Grid>
            </Grid> */}
        </Grid>
    )
};

PuzzleActiveUsers.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    activeUsersData: state.puzzle.activeUsersData,
    acquisitionDataRegGender: state.puzzle.acquisitionDataRegGender,
    acquisitionDataRegAgeGroup: state.puzzle.acquisitionDataRegAgeGroup,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(PuzzleActiveUsers));