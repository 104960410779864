import { HOME } from '../constants';

const initialState  = {
    totalRegStats: {
        gamePmid: '-',
        ads: '-',
        org: '-',
        tier1: '-',
        tier2: '-',
        tier3: '-',
        fakeReg: '-',
    },
    totalRegStatsByXDate: {
        gamePmid: '-',
        ads: '-',
        org: '-',
        tier1: '-',
        tier2: '-',
        tier3: '-',
    },
    acquisitionHomeDataReg: {
        pmidRegTotal: '-',
        pmidRegAdTotal: '-',
        pmidRegOrgTotal: '-',
    },
    acquisitionHomeDataRegPrev: {
        pmidRegTotal: '-',
        pmidRegAdTotal: '-',
        pmidRegOrgTotal: '-',
    },
    acquisitionHomeDataRegLast: {
        dateRange: '-',
        allReg: '-',
        byAds: '-',
        byOrg: '-'
    },
    homeDataTier: {
        tier1: '-',
        tier2: '-',
        tier3: '-',
    },
    homeDataTierPrev: {
        tier1: '-',
        tier2: '-',
        tier3: '-',
    },
    homeDataTierTrend: {
        dateRange: '-',
        tier1: '-',
        tier2: '-',
        tier3: '-',
    },
    activeUserHomeData: {
        playMining: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        jobTribes: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        puzzle: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        depMarketplace: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        luckyFarmer: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        cookinBurger: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        dragonRamen: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        graffiti: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        lostArchive: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        soulfusers: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        roguerollrulers: {
            dau: '-',
            wau: '-',
            mau: '-',
        },
        bouncybunny: {
            dau: '-',
            wau: '-',
            mau: '-',
        }
    },
    activeUserHomeGeoData: {
        jobTribes: [],
        puzzle: [],
        depMarketplace: [],
        luckyFarmer: [],
        cookinBurger: [],
        dragonRamen: [],
        graffiti: [],
        lostArchive: [],
        soulfusers: [],
        roguerollrulers: [],
        bouncybunny: [],
    },
    homeActiveGeoChartData: {
        jobTribes: [], // index0: yAxis, index1: series
        puzzle: [],
        depMarketplace: [],
        luckyFarmer: [],
        cookinBurger: [],
        dragonRamen: [],
        graffiti: [],
        lostArchive: [],
        soulfusers: [],
        roguerollrulers: [],
        bouncybunny: [],
    },
    nftHoldersData: null,
};

export default function pageReducer(state = initialState, action) {
    switch (action.type) {
        case HOME.SET_HOME_TOTAL_STATS_DATA:
            return {
                ...state,
                totalRegStats: action.payload
            };
        case HOME.SET_HOME_TOTAL_STATS_BY_X_DATE:
            return {
                ...state,
                totalRegStatsByXDate: action.payload
            };
        case HOME.SET_HOME_ACQUISITION_DATA:
            return {
                ...state,
                acquisitionHomeDataReg: action.payload
            };
        case HOME.SET_HOME_ACQUISITION_PREV_DATA:
            return {
                ...state,
                acquisitionHomeDataRegPrev: action.payload
            };
        case HOME.SET_HOME_ACQUISITION_LAST_TREND_DATA:
            return {
                ...state,
                acquisitionHomeDataRegLast: action.payload
            };
        case HOME.SET_HOME_ACTIVE_USERS_DATA:
            return {
                ...state,
                activeUserHomeData: action.payload
            };
        case HOME.SET_HOME_ACTIVE_USERS_GEO_DATA:
            return {
                ...state,
                activeUserHomeGeoData: action.payload
            };
        case HOME.SET_HOME_ACTIVE_USERS_GEO_CHART_DATA:
            return {
                ...state,
                homeActiveGeoChartData: action.payload
            };
        case HOME.SET_HOME_TIER_DATA:
            return {
                ...state,
                homeDataTier: action.payload
            };
        case HOME.SET_HOME_TIER_PREV_DATA:
            return {
                ...state,
                homeDataTierPrev: action.payload
            };
        case HOME.SET_HOME_TIER_TREND_DATA:
            return {
                ...state,
                homeDataTierTrend: action.payload
            };
        case HOME.SET_HOME_NFT_HOLDER_DATA:
            return {
                ...state,
                nftHoldersData: action.payload
            };
        default:
            return state
    }
}