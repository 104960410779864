import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircle from "@material-ui/icons/AccountCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MoreIcon from "@material-ui/icons/MoreVert";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { getDepPrice, setCurrency } from "actions";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: theme.spacing(0.2),
    marginRight: theme.spacing(1),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      marginRight: theme.spacing(2),
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    boxShadow: "none",
    position: "relative",
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    backgroundColor: "#fff",
    borderBottomWidth: "1px",
    borderBottom: "solid #d4d4d4",
  },
  toolBar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  branding: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    lineHeight: "50px",
    padding: `20px 100px 0 0`,
    marginLeft: "5px",
  },
  logo: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px",
    },
  },
  a: {
    display: "inlineBlock",
    marginRight: theme.spacing(2),
    fontSize: 14,
    verticalAlign: "middle",
    color: "#000",
  },
  pageRightContent: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
    fontSize: 14,
    color: "#000",
  },
  b: {
    display: "inlineBlock",
    marginRight: theme.spacing(1),
    fontSize: 14,
    verticalAlign: "middle",
    color: "#000",
  },
  gainLossImg: {
    paddingTop: 8,
    marginRight: 4,
  },
  currencyButton: {
    paddingTop: 4,
    marginRight: 16,
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonSelected: {
    backgroundColor: "#7f898b",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#7f898b",
    },
  },
  currButton: {
    borderRadius: 4,
  },
}));

const Header2 = ({
  logo,
  logoAltText,
  toggleDrawer,
  handleLogout,
  currentPage,
  pageTopLoader,
  depPrice,
  getDepPrice,
  setCurrency,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [currencySelected, setCurrencySelect] = React.useState("dep");
  const [isPicTree, setPicTreePage] = React.useState(false);

  useEffect(() => {
    const pagePath = window.location.pathname;
    if (pagePath.indexOf("pictree") !== -1) {
      setPicTreePage(true);
    } else {
      setPicTreePage(false);
    }
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const updateDepPrice = () => {
    getDepPrice();
  };

  const setCurrencyType = (currency) => {
    setCurrencySelect(currency);
    setCurrency(currency);
  };

  const getFxConversion = (currency, value) => {
    if (value) {
      if (currency === "usd") {
        return "$" + depPrice.usd.toFixed(4) + "";
      } else if (currency === "sgd") {
        return "$" + depPrice.sgd.toFixed(4) + "";
      } else if (currency === "jpy") {
        return "¥" + depPrice.jpy.toFixed(2) + "";
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const isGain = (price) => {
    if (price === undefined) {
      return;
    }
    return price.indexOf("+") !== -1 ? true : false;
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>
          <ListItemIcon>
          <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="アカウント" />
        </MenuItem> */}
      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="ログアウト" />
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleMobileMenuClose}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="My アカウント" />
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="ログアウト" />
      </MenuItem>
    </Menu>
  );

  const getDisplayTimeZone = () => {
    const pagePath = window.location.pathname;
    if (pagePath.indexOf("pictree") !== -1) {
      return (
        <div className={classes.pageRightContent}>
        表示データ期間(JST)：~{" "}
        {moment()
          .tz("Asia/Tokyo")
          .format("YYYY年M月D日")}
        </div>
      )
    } else {
      return (
        <div className={classes.pageRightContent}>
        表示データ期間(SGT)：~{" "}
        {moment()
          .tz("Asia/Singapore")
          .subtract(1, "days")
          .format("YYYY年M月D日")}
        </div>   
      )
    }
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Grid container spacing={1} direction="row">
            <Hidden smDown>
              <Grid item>
                <span className={classes.a}>
                  <img src={logo} alt={logoAltText} className={classes.logo} />
                </span>
              </Grid>
            </Hidden>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={0}>
                <Grid item>
                  {!isPicTree &&
                  <span className={classes.a}>
                    現在のSGT：{" "}
                    {moment().tz("Asia/Singapore").format("YYYY年M月D日 HH:mm")}
                  </span>
                  }
                </Grid>
                {!isPicTree &&
                <Grid item>
                  <span className={classes.b}>
                    現在のDEP価格：
                    {depPrice.loading && "Loading.."}
                    {!depPrice.loading && depPrice.price + " USDT | Last24: "}
                    {!depPrice.loading && isGain(depPrice.last24) ? (
                      <img
                        className={classes.gainLossImg}
                        src={`/static/images/gain.png`}
                      />
                    ) : (
                      <img
                        className={classes.gainLossImg}
                        src={`/static/images/loss.png`}
                      />
                    )}
                    {!depPrice.loading && depPrice.last24}
                  </span>
                  {!depPrice.loading && (
                    <IconButton onClick={updateDepPrice} style={{ padding: 3 }}>
                      <RefreshIcon style={{ fontSize: 16, paddingTop: 4 }} />
                    </IconButton>
                  )}
                </Grid>
                }
              </Grid>
            </Grid>
            <Grid item></Grid>
            <Grid item>
              <Grid container spacing={1} direction="column">
                <Hidden smDown>
                  {getDisplayTimeZone()}
                </Hidden>
                {!isPicTree &&
                <ButtonGroup size="small" className={classes.currencyButton}>
                  <Button
                    onClick={() => {
                      setCurrencyType("dep");
                    }}
                    className={
                      currencySelected === "dep" ? classes.buttonSelected : null
                    }
                  >
                    DEP
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrencyType("usd");
                    }}
                    className={
                      currencySelected === "usd" ? classes.buttonSelected : null
                    }
                  >
                    USD {getFxConversion("usd", depPrice.usd)}
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrencyType("sgd");
                    }}
                    className={
                      currencySelected === "sgd" ? classes.buttonSelected : null
                    }
                  >
                    SGD {getFxConversion("sgd", depPrice.sgd)}
                  </Button>
                  <Button
                    onClick={() => {
                      setCurrencyType("jpy");
                    }}
                    className={
                      currencySelected === "jpy" ? classes.buttonSelected : null
                    }
                  >
                    JPY {getFxConversion("jpy", depPrice.jpy)}
                  </Button>
                </ButtonGroup>
                }
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
        {pageTopLoader ? <LinearProgress /> : null}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

Header2.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  currentPage: state.page.currentPage,
  pageTopLoader: state.page.pageTopLoader,
  depPrice: state.page.depPrice,
});

const mapDispatchToProps = (dispatch) => ({
  getDepPrice: () => dispatch(getDepPrice()),
  setCurrency: (param) => dispatch(setCurrency(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Header2));
