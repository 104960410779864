import { firstTimeColor, conversionColor, regColor, chartLineWidth } from 'styles/variables';

const ChatStyle = {
    pieChartTitle: {
        fontSize: 15,
        marginLeft: '16%',
    },
    tooltip: {
        //backgroundColor: "rgba(0,0,0,0.8)",
        axisPointer: {
            type: "line",
            lineStyle: {
                color: "#888",
                type: "dashed"
            },
            crossStyle: {
                color: "#888"
            },
            shadowStyle: {
                color: "rgba(200,200,200,0.3)"
            }
        },
        borderRadius: 0,
        textStyle: {
            fontFamily: "Roboto, sans-serif"
        }
    },
    toolbox: {
        x: 'right',
        itemGap: 15
    },
    dataZoom: {
        backgroundColor: 'rgba(133,207,224,0.2)',
        fillerColor: "rgba(133,207,224,0.2)",
        handleColor: 'rgba(133,207,224, 0.8)',
    },
    grid: {
        borderWidth: 0
    },
    categoryAxis: {
        axisLine: {
            lineStyle: {
                color: "#888"
            }
        },
        splitLine: {
            lineStyle: {
                color: ["#eee"]
            }
        }
    },
    valueAxis: {
        axisLine: {
            lineStyle: {
                color: "#888"
            }
        },
        splitArea: {
            show: !0,
            areaStyle: {
                color: ["rgba(238,238,238,0.1)", "rgba(200,200,200,0.1)"]
            }
        },
        splitLine: {
            lineStyle: {
                color: ["#eee"]
            }
        }
    },
    textStyle: {
        fontFamily: "Roboto, Verdana, sans-serif"
    },
    circleProgress: {
        marginTop: 40,
        marginLeft: '15%',
        marginBottom: 8,
        width: 300,
    },
};

export default ChatStyle;