import React, { Component } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import { firstTimeColor, conversionColor, regColor, chartLineWidth } from 'styles/variables';

export class DoughnutChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelColor: '#FFF',
            legendData : [],
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    componentDidMount() {

    }

    magicTypeChanged = (param) => {
        console.log('param:', param);
    }

    getLegendData = (data) => {
        let dataTorReturn = [];
        for (let i=0; i<data.length; i++) {
            dataTorReturn.push(data[i]['name']);
        }
        return dataTorReturn;
    }

    render () {
        const { classes, title, data, color, legendTop, legendRight, marginLeft, height='150px', show=true } = this.props;

        let onEvents = {
            'magictypechanged': this.magicTypeChanged,
        }

        return (
            <div style={{marginLeft: marginLeft}}>
                <div className={classes.pieChartTitle}>{title}</div>
                {data[0] === 'loading' ? (
                    <div className={classes.circleProgress}><CircularProgress /></div>
                ) : (
                <ReactEcharts
                    option={{
                        // title: {
                        //     text: title,
                        //     textStyle: {
                        //         fontSize: 15,
                        //         fontWeight: 500
                        //     },
                        //     itemGap: 30,
                        //     top: 0,
                        //     left: 40,
                        // },
                        grid: {
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: '{a} <br/>{b}: {c} ({d}%)',
                            position: ['41%', '30%'],
                            show: show,
                        },
                        legend: {
                            orient: 'vertical',
                            right: legendRight,
                            top: legendTop,
                            data: this.getLegendData(data),
                            formatter: function (lname) {
                                let totalNum = _.sumBy(data, d => {
                                    return d.value;
                                });
                                let dataLbl;
                                data.filter((obj) => {
                                    if (obj.name === lname) {
                                        dataLbl = obj.value + ' ('+(obj.value / totalNum * 100).toFixed(2)+'%)';
                                    }
                                });
                                return lname + ': ' + dataLbl;
                            },
                            itemGap: 8,
                            itemHeight: 12,
                            selectedMode: true,
                        },
                        series: [
                            {
                                name: title,
                                type: 'pie',
                                radius: ['50%', '65%'],
                                left: 0,
                                top: 0,
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '14',
                                        fontWeight: 'normal'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: data,
                                hoverOffset: 3,
                                center: ['20%', '40%']
                            }
                        ],
                        color: color,
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{height: height, width: '300px'}}
                />
                )}
            </div>
        )
    }
}

DoughnutChart.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ChartStyle, { withTheme: true })(DoughnutChart));