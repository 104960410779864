import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Chip,
  Collapse,
} from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  getCookinBurgerGameTrendPageData,
} from "actions";
import * as C from "components";
import JtcbNftTrendTable from "components/Table/JtcbNftTrendTable";
import * as V from "styles/variables";
import * as H from "helper";
import ChartConfig from "utils/ChartConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBoxForUnique: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 110,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    filterSection: {
      display: "flex",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    activeUsersTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    grandSection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      fontSize: 16,
    },
    summarySection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      fontSize: 14,
    },
    summaryBox: {
      paddingLeft: 16,
      borderRight: "1px solid #dcdcdc",
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    expand: {
      marginLeft: "auto",
      marginRight: 32,
    },
    title: {
      fontSize: 18,
      marginLeft: 36,
      marginTop: 16,
    },
    rareTitle: {
      marginBottom: 8,
    },
  })
);

const CookinBurgerGamePaidTrend = ({
  setCurrentPage,
  getCookinBurgerGameTrendPageData,
  trendDataLoading,
  gameTrendData,
  gameTrendDataUnique,
  gameTrendDataAmount,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [grandTotalDep, setGranTotalDep] = useState(0);
  const [grandTotalCount, setGranTotalCount] = useState(0);
  const [uniqueAllCount, setUniqueAllCount] = useState(0);

  // expand
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getCookinBurgerGameTrendPageData();
  }, []);

  useEffect(() => {
    const dataCombined = gameTrendData.concat(gameTrendDataAmount);
    setChartData(dataCombined);
    let grandTotalCount = 0, grandTotalDep = 0;
    if (gameTrendData.length > 0) {
      _.each(gameTrendData, function(data, index) {
        grandTotalCount = grandTotalCount + _.sum(data["data"]);
      });
      setGranTotalCount(Number(grandTotalCount));
    }
    if (gameTrendDataAmount.length > 0) {
      _.each(gameTrendDataAmount, function(data, index) {
        grandTotalDep = grandTotalDep + _.sum(data["data"]);
      });
      setGranTotalDep(Number(grandTotalDep));
    }
  }, [gameTrendData, gameTrendDataAmount]);
  
  useEffect(() => {
    if (gameTrendDataUnique.length > 0) {
      setUniqueAllCount(gameTrendDataUnique[0]['repair_shop'])
    }
  }, [gameTrendDataUnique]);

  const getSalesSummaryData = (type) => {
    if (gameTrendData.length < 1) {
      return;
    }
    let countValuel = 0,
      totalDepValue = 0,
      avgDepValue = 0,
      uniqueValue = 0;
    switch (type) {
      case "repair_shop":
        countValuel = _.sum(gameTrendData[0]["data"]);
        totalDepValue = 0;
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = gameTrendDataUnique[0]["repair_shop"];
        break;
      default:
        break;
    }
    return (
      <>
      <div>
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        DEP：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)}{"　|　"}
        DEP平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(avgDepValue) : 0}
      </div>
      <div style={{marginTop: 8}}>
        ユニーク：{H.FormatNumStrHelper.formatCommaThousand(uniqueValue)} {"　|　"}
        ユニークDEP平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(totalDepValue/uniqueValue) : 0}
      </div>      
      </>
    );
  };

  const fetchData = () => {
    getCookinBurgerGameTrendPageData();
    setBtnState(!btnStateDataFetch);
  };

  return (
    <C.ContentWrapper>
      <div className={classes.filterSection}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <div className={classes.title}>CookinBurger In Game 取引トレンド</div>
          </Grid>
          <Grid item>
            <Grid container spacing={0} direction="row">
              <Grid item>
                <C.CalendarRangeOnly
                  setBtnState={setBtnState}
                  page="cookinBurgerGameTrend"
                />
              </Grid>
              <Grid>
                <Button
                  //disabled={btnStateDataFetch}
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.grandSection}
      >
        <Grid item>Total DEP: {trendDataLoading ? "--" : H.FormatNumStrHelper.formatCommaThousand(grandTotalDep)} &nbsp;&nbsp;|</Grid>
        <Grid item>Total Count: {trendDataLoading ? "--" : H.FormatNumStrHelper.formatCommaThousand(grandTotalCount)} &nbsp;&nbsp;|</Grid>
        <Grid item>ユニーク PMID: {trendDataLoading ? "--" : H.FormatNumStrHelper.formatCommaThousand(uniqueAllCount)} &nbsp;&nbsp;|</Grid>
        <Grid item>ユニーク PMID平均: {trendDataLoading ? "--" : H.FormatNumStrHelper.formatCommaThousand(grandTotalDep/uniqueAllCount)}</Grid>
      </Grid>

      {/* <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="Repair Shop"
              style={{
                color: "#000",
                backgroundColor: V.genericColorPallette[0],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryData("repair_shop")}
        </Grid>
      </Grid> */}

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              //legend={ChartConfig.InGameTrendLuckyFarmer}
              lblSaveImage={"DEP取引種別"}
              xAisData={chartData[0]["date"]}
              seriesData={chartData}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="CookinBurger In Game 取引データ"
            dataCount={gameTrendData}
            dataAmount={gameTrendDataAmount}
          />
        </div>
      </Collapse>
    </C.ContentWrapper>
  );
};

CookinBurgerGamePaidTrend.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  trendDataLoading: state.cookinBurgerGamePaidTrend.cookinBurgerGamePaidTrendDateLoading,
  trendData: state.cookinBurgerGamePaidTrend.trendData,
  gameTrendDataDep: state.cookinBurgerGamePaidTrend.trendData,
  gameTrendData: state.cookinBurgerGamePaidTrend.gameTrendData,
  gameTrendDataUnique: state.cookinBurgerGamePaidTrend.gameTrendDataUnique,
  gameTrendDataAmount: state.cookinBurgerGamePaidTrend.gameTrendDataAmount,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getCookinBurgerGameTrendPageData: () => dispatch(getCookinBurgerGameTrendPageData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(CookinBurgerGamePaidTrend));
