const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
const _ = require("lodash")

const FormatDataHelper = {
    formatTrendChartData: function(dataPassed, dataReg) {
        let dataStored = dataPassed, FtArray = [], ConvArray = [], RegArray = [], dateArray = [], trendData = [], trendObj = {};

        for (let i=0; i<dataStored.length; i++) {
            if (dataStored[i]['FT'] === 1) {
                FtArray.push(moment.utc(dataStored[i]['Timestamp']).format('MM/DD/YY'));
            }
            if (dataStored[i]['Conv'] === 1) {
                ConvArray.push(moment.utc(dataStored[i]['Timestamp']).format('MM/DD/YY'));
            }
        }

        for (let j=0; j<dataReg.length; j++) {
            RegArray.push(moment.utc(dataReg[j]['Timestamp']).format('MM/DD/YY'));
        }

        // Calendar
        let dateStart = dataStored[0]['Timestamp'] < dataReg[0]['Timestamp'] ? moment.utc(dataStored[0]['Timestamp']).format('MM/DD/YY')
                                                                             : moment.utc(dataReg[0]['Timestamp']).format('MM/DD/YY')
        let dateEnd = moment.utc().format('MM/DD/YY');

        const range = moment.range(dateStart, dateEnd);
        const days = Array.from(range.by('days'));
        dateArray = days.map(m => m.format('MM/DD/YY'));

        // FT,Conv, Reg
        let ftNum, convNum, regNum, dataFtArray = [], dataConvArray = [], dataRegArray = [];
        for (let j=0; j<dateArray.length; j++) {
            ftNum = FtArray.filter(i => i === dateArray[j]).length;
            convNum = ConvArray.filter(i => i === dateArray[j]).length;
            regNum = RegArray.filter(i => i === dateArray[j]).length;
            dataFtArray.push(ftNum);
            dataConvArray.push(convNum);
            dataRegArray.push(regNum);
        }

        trendObj.date = dateArray;
        trendObj.first = dataFtArray;
        trendObj.conv = dataConvArray;
        trendObj.reg = dataRegArray;
        trendData.push(trendObj)
        return trendData;
    },

    formatCampaignTrendChartData: function(dataFirstTime, dataRegByRid, dataGameVisits) {
        let trendDataToReturn = [], allDateArr = [];
        // creates all date range array
        if (dataGameVisits) {
            allDateArr = dataFirstTime.data.getCampaignLogByRidAllDateTime.concat(
                dataGameVisits.data.getCampaignLogByRidAllDateTime,
                dataRegByRid.data.getCampaignLogRegByRidAllDateTime
            );
        } else {
            allDateArr = dataFirstTime.data.getCampaignLogByRidAllDateTime.concat(
                dataRegByRid.data.getCampaignLogRegByRidAllDateTime
            );
        }

        const uniqueDate = _.uniq(_.map(allDateArr, 'date'));
        const range = moment.range(_.min(uniqueDate), _.max(uniqueDate));
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        let ftVal, gameVal, regVal, dataFtArray = [], dataGameVArray = [], dataRegArray = [], trendObj = {};
        for (let j=0; j<dateArray.length; j++) {
            ftVal = _.find(dataFirstTime.data.getCampaignLogByRidAllDateTime, {date: dateArray[j]});
            ftVal ? dataFtArray.push(ftVal.count) : dataFtArray.push(0);

            if (dataGameVisits) {
                gameVal = _.find(dataGameVisits.data.getCampaignLogByRidAllDateTime, {date: dateArray[j]});
                gameVal ? dataGameVArray.push(gameVal.count) : dataGameVArray.push(0);
            }

            regVal = _.find(dataRegByRid.data.getCampaignLogRegByRidAllDateTime, {date: dateArray[j]});
            regVal ? dataRegArray.push(regVal.count) : dataRegArray.push(0);
        }
        trendObj.date = dateArray;
        trendObj.first = dataFtArray;
        trendObj.conv = dataGameVArray;
        trendObj.reg = dataRegArray;
        trendDataToReturn.push(trendObj);
        return trendDataToReturn;
    }
}

export default FormatDataHelper;