import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Chip,
  Collapse,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ViewListIcon from "@material-ui/icons/ViewList";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  getKamuiVerseNftTrendPageData,
} from "actions";
import * as C from "components";
import JtcbNftTrendTable from "components/Table/JtcbNftTrendTable";
import * as V from "styles/variables";
import * as H from "helper";
import ChartConfig from "utils/ChartConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBoxForUnique: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 110,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    filterSection: {
      display: "flex",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    activeUsersTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    summarySection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      fontSize: 14,
    },
    summaryBox: {
      paddingLeft: 16,
      borderRight: "1px solid #dcdcdc",
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    expand: {
      marginLeft: "auto",
      marginRight: 32,
    },
    title: {
      fontSize: 18,
      marginLeft: 36,
      marginTop: 16,
    },
    rareTitle: {
      marginBottom: 8,
    },
  })
);

const KamuiVerseNftTrend = ({
  setCurrentPage,
  getKamuiVerseNftTrendPageData,
  trendDataLoading,
  trendDataCountAuction,
  trendDataAmountAuction,
  trendDataCountStore,
  trendDataAmountStore,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartState, setChartState] = useState(false);
  const [kindOption, setKindOption] = useState([
    { name: "オークション販売", value: "auction" },
    { name: "ストア販売", value: "stire" },
  ]);
  const [chartData, setChartData] = useState([]);
  const [storeData, setStoreData] = useState([]);

  // expand
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getKamuiVerseNftTrendPageData();
  }, []);

  useEffect(() => {
    // combine data
    const dataCombined = trendDataCountAuction.concat(trendDataAmountAuction);
    setChartData(dataCombined);
  }, [trendDataCountAuction, trendDataAmountAuction]);

  useEffect(() => {
    // combine data
    const dataCombinedStore = trendDataCountStore.concat(trendDataAmountStore);
    // console.log("YOYO dataCombinedStore:", dataCombinedStore);
    setStoreData(dataCombinedStore);
  }, [trendDataCountStore, trendDataAmountStore]);

  const getSummaryData = (kind) => {
    if (trendDataCountAuction.length < 1) {
      return;
    }
    if (trendDataCountStore.length < 1) {
      return;
    }
    let countValuel = 0,
      totalDepValue = 0,
      avgDepValue = 0;
      countValuel = kind === "auction" 
      ? _.sum(trendDataCountAuction[0]["data"])
      : _.sum(trendDataCountStore[0]["data"]);
      totalDepValue = kind === "auction"
      ? trendDataAmountAuction[0]["data"] === undefined ? 0 : _.sum(trendDataAmountAuction[0]["data"])
      : trendDataAmountStore[0]["data"] === undefined ? 0 : _.sum(trendDataAmountStore[0]["data"]);
      avgDepValue = (totalDepValue / countValuel).toFixed(0);
    return (
      <div>
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        総額：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)} DEP{"　|　"}
        平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(avgDepValue) : 0}
      </div>
    );
  };

  const fetchData = () => {
    getKamuiVerseNftTrendPageData();
    setChartState(true);
    setBtnState(!btnStateDataFetch);
  };

  return (
    <C.ContentWrapper>
      <div className={classes.filterSection}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <div className={classes.title}>Kamui Verse NFT取引トレンド</div>
          </Grid>
          <Grid item>
            <Grid container spacing={0} direction="row">
              <Grid item>
                <C.CalendarRangeOnly
                  setBtnState={setBtnState}
                  page="kamuiVerseNftTrend"
                />
              </Grid>
              <Grid>
                <Button
                  //disabled={btnStateDataFetch}
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="オークション"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[0],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSummaryData("auction")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              title="オークション販売"
              legend={ChartConfig.cookinBurgerNftTrend}
              lblSaveImage={"DEP取引種別"}
              xAisData={chartData[0]["date"]}
              seriesData={chartData}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="オークション販売"
            dataCount={trendDataCountAuction}
            dataAmount={trendDataAmountAuction}
          />
        </div>
      </Collapse>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="ストア"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[1],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSummaryData("store")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : storeData.length > 0 ? (
            <C.LineChartNftDepTrend
              title="ストア販売"
              legend={ChartConfig.cookinBurgerNftTrend}
              lblSaveImage={"DEP取引種別"}
              xAisData={storeData[0]["date"]}
              seriesData={storeData}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="ストア販売"
            dataCount={trendDataCountStore}
            dataAmount={trendDataAmountStore}
          />
        </div>
      </Collapse>
    </C.ContentWrapper>
  );
};

KamuiVerseNftTrend.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  trendDataLoading: state.kamuiVerseNftTrend.kamuiVerseNftTrendDateLoading,
  trendData: state.kamuiVerseNftTrend.trendData,
  trendDataCountAuction: state.kamuiVerseNftTrend.nftTrendDataCountAuction,
  trendDataAmountAuction: state.kamuiVerseNftTrend.nftTrendDataAmountAuction,
  trendDataCountStore: state.kamuiVerseNftTrend.nftTrendDataCountStore,
  trendDataAmountStore: state.kamuiVerseNftTrend.nftTrendDataAmountStore,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getKamuiVerseNftTrendPageData: () => dispatch(getKamuiVerseNftTrendPageData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(KamuiVerseNftTrend));
