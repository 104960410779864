const gameColor = {
    "PlayMining": "#cf363c",
    "Axie": "#A6CEE3",
    "Decentraland": "#4cb992",
    "Sandbox": "#FB9A99",
    "GALA": "#bebada",
    "WAX": "#f1e059",
    "illuvium": "#6e54a6",
    "yieldguild": "#3b5998",
    "captsuba": "#FDBF6F",
    "digidaigaku": "#2fb7ed",
    "stepn": "#f669b4",
    "splinterlands": "#b86d19",
}

// const allGames = [ "PlayMining", "Axie", "Decentraland", "Sandbox", "GALA", "WAX", "illuvium", "yieldguild"  ];

const trafficData = {
    pmTotal: 1042900,
    jtcbCountry : [
        { country: "Indonesia", value: 0.3859 },
        { country: "Philippines", value: 0.1953 },
        { country: "Japan", value: 0.0992 },
        { country: "Taiwan", value: 0.077 },
        { country: "India", value: 0.0335 }
    ],
    jtcbSource : [
        { source: "Direct", value: 0.8221 },
        { source: "Mail", value: 0.0128 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.0518 },
        { source: "Search", value: 0.0707 },
        { source: "Social", value: 0.0423 }
    ],
    dlTotal: 5860000,
    decentralandCountry : [
        { country: "United States", value:  0.2166 },
        { country: "India", value:  0.0515 },
        { country: "Iran", value:  0.0409 },
        { country: "China", value:  0.039 },
        { country: "Canada", value:  0.0388 }
    ],
    decentralandSource : [
        { source: "Direct", value: 0.3606 },
        { source: "Mail", value: 0.0077 },
        { source: "Paid Referrals", value: 0.0006 },
        { source: "Referrals", value: 0.1292 },
        { source: "Search", value: 0.4567 },
        { source: "Social", value: 0.0448 }
    ],
    sbTotal: 7591400,
    sandboxCountry : [
        { country: "United States", value:  0.1877 },
        { country: "Thailand", value:  0.0448 },
        { country: "India", value:  0.043 },
        { country: "Canada", value:  0.0387 },
        { country: "Brazil", value:  0.0349 }
    ],
    sandboxSource : [
        { source: "Direct", value: 0.3514 },
        { source: "Mail", value: 0.0079 },
        { source: "Paid Referrals", value: 0.0337 },
        { source: "Referrals", value: 0.0924 },
        { source: "Search", value: 0.4486 },
        { source: "Social", value: 0.0657 }
    ],
    axTotal: 26593000,
    axieCountry : [
        { country: "Philippines", value:  0.3753 },
        { country: "United States", value:  0.0648 },
        { country: "Venezuela", value:  0.0555 },
        { country: "Argentina", value:  0.0542 },
        { country: "Brazil", value:  0.0431 }
    ],
    axieSource : [
        { source: "Direct", value: 0.7701 },
        { source: "Mail", value: 0.0059 },
        { source: "Paid Referrals", value: 0.0004 },
        { source: "Referrals", value: 0.0349 },
        { source: "Search", value: 0.142 },
        { source: "Social", value: 0.0464 }
    ],
    glTotal: 5062100,
    glCountry : [
        { country: "United States", value:  0.237 },
        { country: "Korea", value:  0.0672 },
        { country: "Thailand", value:  0.0641 },
        { country: "Russia", value:  0.0463 },
        { country: "Canada", value:  0.0397 }
    ],
    glSource : [
        { source: "Direct", value: 0.708 },
        { source: "Mail", value: 0.0054 },
        { source: "Paid Referrals", value: 0.0013 },
        { source: "Referrals", value: 0.0501 },
        { source: "Search", value: 0.1922 },
        { source: "Social", value: 0.0426 }
    ],
    waxTotal: 10146000,
    waxCountry : [
        { country: "Russia", value:  0.1434 },
        { country: "Thailand", value:  0.107 },
        { country: "United States", value:  0.0815 },
        { country: "Philippines", value:  0.0638 },
        { country: "Brazil", value:  0.0495 }
    ],
    waxSource : [
        { source: "Direct", value: 0.2222 },
        { source: "Mail", value: 0.0062 },
        { source: "Paid Referrals", value: 0.0005 },
        { source: "Referrals", value: 0.7007 },
        { source: "Search", value: 0.0431 },
        { source: "Social", value: 0.0269 }
    ],
    illvTotal: 1386500,
    illvCountry : [
        { country: "United States", value:  0.1323 },
        { country: "Brazil", value:  0.0912 },
        { country: "Philippines", value:  0.0477 },
        { country: "France", value:  0.043 },
        { country: "Thailand", value:  0.0371 }
    ],
    illvSource : [
        { source: "Direct", value: 0.4784 },
        { source: "Mail", value: 0.003 },
        { source: "Paid Referrals", value: 0.0002 },
        { source: "Referrals", value: 0.0414 },
        { source: "Search", value: 0.3905 },
        { source: "Social", value: 0.0863 }
    ],
    gldTotal: 124780,
    gldCountry : [
        { country: "United States", value:  0.1332 },
        { country: "Brazil", value:  0.0488 },
        { country: "Philippines", value:  0.048 },
        { country: "Thailand", value:  0.0354 },
        { country: "Russia", value:  0.0344 }
    ],
    gldSource : [
        { source: "Direct", value: 0.2302 },
        { source: "Mail", value: 0.0013 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.3486 },
        { source: "Search", value: 0.4014 },
        { source: "Social", value: 0.0183 }
    ],
};

const trafficData202212 = {
    pmTotal: 642244,
    jtcbCountry : [
        { country: "Philippines", value: 0.3593 },
        { country: "Japan", value: 0.2939 },
        { country: "Indonesia", value: 0.2084 },
        { country: "United States", value: 0.0616 },
        { country: "Seychelles", value: 0.0600 }
    ],
    jtcbSource : [
        { source: "Direct", value: 0.9010 },
        { source: "Mail", value: 0.0043 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.0314 },
        { source: "Search", value: 0.0350 },
        { source: "Social", value: 0.0280 }
    ],
    dlTotal: 672887,
    decentralandCountry : [
        { country: "United States", value:  0.1492 },
        { country: "Iran", value:  0.0818 },
        { country: "Ukraine", value:  0.0783 },
        { country: "Mexico", value:  0.0581 },
        { country: "Canada", value:  0.0547 }
    ],
    decentralandSource : [
        { source: "Direct", value: 0.3606 },
        { source: "Mail", value: 0.0060 },
        { source: "Paid Referrals", value: 0.0065 },
        { source: "Referrals", value: 0.1029 },
        { source: "Search", value: 0.2305 },
        { source: "Social", value: 0.0440 }
    ],
    sbTotal: 1676099,
    sandboxCountry : [
        { country: "United States", value:  0.1231 },
        { country: "Russia", value:  0.0886 },
        { country: "Brazil", value:  0.0819 },
        { country: "United Kingdom", value:  0.0529 },
        { country: "India", value:  0.0508 }
    ],
    sandboxSource : [
        { source: "Direct", value: 0.3514 },
        { source: "Mail", value: 0.0075 },
        { source: "Paid Referrals", value: 0.0463 },
        { source: "Referrals", value: 0.0319 },
        { source: "Search", value: 0.1968 },
        { source: "Social", value: 0.0662 }
    ],
    axTotal: 2267225,
    axieCountry : [
        { country: "Philippines", value:  0.2525 },
        { country: "United States", value:  0.0926 },
        { country: "Colombia", value:  0.0835 },
        { country: "Thailand", value:  0.0645 },
        { country: "Venezuela", value:  0.0559 }
    ],
    axieSource : [
        { source: "Direct", value: 0.8116 },
        { source: "Mail", value: 0.0055 },
        { source: "Paid Referrals", value: 0.0084 },
        { source: "Referrals", value: 0.0315 },
        { source: "Search", value: 0.1098 },
        { source: "Social", value: 0.0328 }
    ],
    glTotal: 849347,
    glCountry : [
        { country: "United States", value:  0.2286 },
        { country: "Russia", value:  0.0969 },
        { country: "United Kingdom", value:  0.0668 },
        { country: "Australia", value:  0.0630 },
        { country: "Philippines", value: 0.0583 }
    ],
    glSource : [
        { source: "Direct", value: 0.825 },
        { source: "Mail", value: 0.0041 },
        { source: "Paid Referrals", value: 0.0033 },
        { source: "Referrals", value: 0.0763 },
        { source: "Search", value: 0.0794 },
        { source: "Social", value: 0.0116 }
    ],
    waxTotal: 2283436,
    waxCountry : [
        { country: "United States", value:  0.1662 },
        { country: "Ukraine", value:  0.0797 },
        { country: "United Kingdom", value:  0.0754 },
        { country: "Germany", value:  0.0565 },
        { country: "Latvia", value:  0.0525 }
    ],
    waxSource : [
        { source: "Direct", value: 0.1523 },
        { source: "Mail", value: 0.0217 },
        { source: "Paid Referrals", value: 0.0005 },
        { source: "Referrals", value: 0.779 },
        { source: "Search", value: 0.0215 },
        { source: "Social", value: 0.0246 }
    ],
    illvTotal: 505137,
    illvCountry : [
        { country: "United States", value:  0.1545 },
        { country: "France", value:  0.0772 },
        { country: "Russia", value:  0.0592 },
        { country: "Philippines", value:  0.0554 },
        { country: "United Kingdom", value:  0.0529 }
    ],
    illvSource : [
        { source: "Direct", value: 0.5682 },
        { source: "Mail", value: 0.0364 },
        { source: "Paid Referrals", value: 0.0065 },
        { source: "Referrals", value: 0.0896 },
        { source: "Search", value: 0.2184 },
        { source: "Social", value: 0.0806 }
    ],
    gldTotal: 17957,
    gldCountry : [
        { country: "United States", value:  0.1577 },
        { country: "Russia", value:  0.0986 },
        { country: "Belarus", value:  0.0885 },
        { country: "France", value:  0.0781 },
        { country: "Poland", value:  0.0627 }
    ],
    gldSource : [
        { source: "Direct", value: 0.3959 },
        { source: "Mail", value: 0.0 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.3342 },
        { source: "Search", value: 0.2533 },
        { source: "Social", value: 0.0165 }
    ],
    captsubaTotal: 150483,
    captsubaCountry : [
        { country: "Japan", value:  0.5514 },
        { country: "Taiwan", value:  0.1677 },
        { country: "Singapore", value:  0.0548 },
        { country: "Hong Kong", value:  0.0394 },
        { country: "China", value:  0.0289 }
    ],
    captsubaSource : [
        { source: "Direct", value: 0.6882 },
        { source: "Mail", value: 0.0 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.1157 },
        { source: "Search", value: 0.2533 },
        { source: "Social", value: 0.1318 }
    ],
    digidaigakuTotal: 12576,
    digidaigakuCountry : [
        { country: "United States", value: 0.2755 },
        { country: "Japan", value: 0.1495 },
        { country: "Ukraine", value: 0.1490 },
        { country: "South Korea", value: 0.05957 },
        { country: "Romania", value:  0.0516 }
    ],
    digidaigakuSource : [
        { source: "Direct", value: 0.4092 },
        { source: "Mail", value: 0.0 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.132 },
        { source: "Search", value: 0.1287 },
        { source: "Social", value: 0.1318 }
    ],
    stepnTotal: 860491,
    stepnCountry : [
        { country: "United States", value: 0.1296 },
        { country: "Japan", value: 0.0958 },
        { country: "Vietnam", value: 0.08 },
        { country: "Russia", value: 0.0649 },
        { country: "India", value:  0.0552 }
    ],
    stepnSource : [
        { source: "Direct", value: 0.4676 },
        { source: "Mail", value: 0.0126 },
        { source: "Paid Referrals", value: 0.0 },
        { source: "Referrals", value: 0.0981 },
        { source: "Search", value: 0.3806 },
        { source: "Social", value: 0.0408 }
    ],
    splinterlandsTotal: 1634364,
    splinterlandsCountry : [
        { country: "United States", value: 0.1698 },
        { country: "Philippines", value: 0.136 },
        { country: "United Kingdom", value: 0.1263 },
        { country: "France", value: 0.0634 },
        { country: "Canada", value:  0.0618 }
    ],
    splinterlandsSource : [
        { source: "Direct", value: 0.8571 },
        { source: "Mail", value: 0.0517 },
        { source: "Paid Referrals", value: 0.0001 },
        { source: "Referrals", value: 0.0098 },
        { source: "Search", value: 0.0318 },
        { source: "Social", value: 0.0492 }
    ],
};

export {
    gameColor,
    trafficData,
    trafficData202212,
}
