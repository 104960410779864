import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Checkbox, TextField, Button, Grid, Chip, Card, CardContent } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, getActiveUsersPageData, setActiveUsersSizeSelected,
    setActiveUsersGameSelected, setActiveUsersCountrySelected, setActiveUsersRidSelected,
    setActiveUsersGameOption, setActiveUsersSizeOption, resetActiveUsersGameSelected, getActiveUsersCountries, setAllPmMenuSelect } from 'actions';
import * as C from 'components';
import * as V from 'styles/variables';
import * as H from 'helper';
import ChartConfig from 'utils/ChartConfig';
import ActiveUsersConfig from 'utils/ActiveUsersConfig';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ActiveUsers = ({
    pageTopLoader,
    setCurrentPage,
    activeUsersGameOption,
    setActiveUsersSizeOption,
    setActiveUsersSizeSelected,
    activeUsersSizeOption,
    activeUsersSizeSelected,
    getActiveUsersPageData,
    activeUsersCountryOption,
    activeUsersRidOption,
    setActiveUsersGameOption,
    setActiveUsersGameSelected,
    setActiveUsersCountrySelected,
    setActiveUsersRidSelected,
    activeUsersData,
    activeUsersUniqueTotalData,
    activeUsersUniquePaidData,
    resetActiveUsersGameSelected,
    countryLoading,
    getActiveUsersCountries,
    ridLoading,
    activeUsersDateDiff,
    setAllPmMenuSelect,
    allPmMenuSelect,
}) => {
    const classes = useStyles();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [chartState, setChartState] = useState(false);
    const [gameOptions, setGameOptions] = useState(
        [
            { name: 'PlayMining全体', value: 'PlayMining' },
            { name: "PlayMining NFT", value:"nftdep"},
            { name: 'Bouncy Bunny', value: 'bouncybunny' },
            { name: 'Cookin\' Burger', value: 'cookinburger' },
            { name: 'Dragon Ramen', value: 'dragonramen' },
            { name: 'Graffiti Racer', value: 'graffiti' },
            { name: 'JobTribes', value: 'jobtribes' },
            { name: "Lost Archive+", value:"lostarchiveplus"},
            { name: "Lucky Farmer", value:"luckyfarmer"},
            { name: "Rogue Roll Ruler's", value:"roguerollrulers"},
        ]
    );
    const [sizeOptions, setSizeOptions] = useState(
        [
            {name: "DAU", value:"dau"},
            {name: "WAU", value:"wau"},
            {name: "MAU", value:"mau"},
        ]
    );

    const handleChangeGame = (data) => {
        let gameSelectedArray = [];
        _.forEach(data, function(game, i) {
            gameSelectedArray.push(game.value)
        });
        if (gameSelectedArray.indexOf("PlayMining") !== -1) {
            setAllPmMenuSelect(true);
        } else {
            setAllPmMenuSelect(false);
        }

        setActiveUsersGameOption(data);
        getActiveUsersCountries(data);
        setBtnState(false);
    }

    const handleChangeSize = (data) => {
        setActiveUsersSizeOption(data);
        if (activeUsersGameOption.length > 0) {
            setBtnState(false);
        }
    }

    const handleChangeCountry = (data) => {
        setActiveUsersCountrySelected({name: data.name, value: data.value});
        setBtnState(false);
    }

    const handleChangeRid = (data) => {
        setActiveUsersRidSelected({name: data.name, value: data.value});
        setBtnState(false);
    }

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        resetActiveUsersGameSelected();
    }, []);

    useEffect(() => {
        if (activeUsersGameOption.length === 0) {
            setBtnState(true);
        } else if (activeUsersGameOption.length > 1) {
            setActiveUsersRidSelected([]);
        }
    }, [activeUsersGameOption])

    const fetchData = () => {
        setActiveUsersSizeSelected(activeUsersSizeOption);
        setActiveUsersGameSelected(activeUsersGameOption);
        setBtnState(!btnStateDataFetch);
        getActiveUsersPageData();
        setChartState(true);
    }

    const getTotalDisplay = (data, size) => {
        let funnelData = [
            { label: H.FormatNumStrHelper.formatCommaThousand(data[0]['total']).toString(), value: data[0]['total'] },
            { label: H.FormatNumStrHelper.formatCommaThousand(data[0]['uniqueTotal']).toString(), value: data[0]['uniqueTotal'] },
        ];
        if (activeUsersUniquePaidData > 0) {
            funnelData.push(
                { label: H.FormatNumStrHelper.formatCommaThousand(activeUsersUniquePaidData).toString(), value: activeUsersUniquePaidData },
            )
        }
        const uniqueRatio = (data[0]['uniqueTotal'] / data[0]['total'] * 100).toFixed(2);
        const uniqueKakinRatio = (activeUsersUniquePaidData / data[0]['uniqueTotal'] * 100).toFixed(2);
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={7} lg={7}>
                    <div className={classNames(classes.funnelLegendWrapper)}>
                        <div className={classes.funnelLegendRow}>
                            <span className={classNames(classes.funnelLegend, classes.colorFunnelTop)}></span>
                            <span className={classes.legendLabel}>
                                PlayMining全体 (重複あり): 100%
                            </span>
                        </div>
                        <div className={classes.funnelLegendRow}>
                            <span className={classNames(classes.funnelLegend, classes.colorFunnelBottom)}></span>
                            <span className={classes.legendLabel}>
                                PlayMining完全ユニーク: {uniqueRatio}%
                            </span>
                        </div>
                        <div className={classes.funnelLegendRow}>
                            <span className={classNames(classes.funnelLegend, classes.colorFunnelKakin)}></span>
                            <span className={classes.legendLabel}>
                                PlayMining完全ユニーク内のユニーク課金: {uniqueKakinRatio}%
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                    <C.Funnel data={funnelData} />
                </Grid>
            </Grid>
        )
    }

    const getTotalByGameDisplay = (data, size) => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="space-around"
            >
                <C.BarChart2
                    legend={ChartConfig.legendActiveUsersAllGame}
                    xAisData={ChartConfig.legendActiveUsersAllGame}
                    seriesData={[
                        data[0]['uniqueTotal'],
                        data[1]['uniqueTotal'],
                        data[2]['uniqueTotal'],
                        data[3]['uniqueTotal'],
                        data[4]['uniqueTotal'],
                        data[5]['uniqueTotal'],
                        data[6]['uniqueTotal'],
                        data[7]['uniqueTotal'],
                        data[8]['uniqueTotal'],
                        data[9]['uniqueTotal'],
                    ]}
                    chartType={'bar'}
                />
            </Grid>
        )
    }

    const getAvgDisplay = (data, size) => {
        return (
            <div className={classes.flexContainerNotFix}>
                {data.map((item) => (
                    <div className={classes.flexItemLeftBox} key={item.id}>
                        {H.FormatNumStrHelper.formatGameKeyToName(item.id)} {size.name} 平均　{H.FormatNumStrHelper.formatCommaThousand(item.avg)}
                    </div>
                ))}
            </div>
        )
    }

    const getCalendarDisplay = (sizeOption) => {
        return (
            <Grid item>
                <C.CalendarRangeOnly
                setBtnState={setBtnState}
                page="activeUsers"
                rangeMax={ActiveUsersConfig.dauMaxRange}
            />
            </Grid>
        )
    }

    return (
        <Fragment>
            <div className={classes.filterSection}>
            <Grid container spacing={0} direction="row">
                <Grid item>
                <Autocomplete
                    multiple
                    limitTags={3}
                    size="small"
                    id="select-game"
                    options={gameOptions}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                        <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                        </React.Fragment>
                    )}
                    onChange={(e, newValue) => {
                        handleChangeGame(newValue);
                    }}
                    style={{ minWidth: 200 }}
                    renderInput={(params) => (
                        <TextField {...params} label="ゲーム" placeholder={activeUsersGameOption.length === 0 ? "選択してください" : ""} />
                    )}
                    className={classes.autoSelect}
                />
                </Grid>
                <Grid item>
                <Autocomplete
                    id="select-size"
                    style={{ width: 100 }}
                    options={sizeOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) => option === sizeOptions[1] && allPmMenuSelect || option === sizeOptions[2] && allPmMenuSelect}
                    value={activeUsersSizeOption}
                    disableClearable={true}
                    renderInput={(params) => (
                        <TextField {...params} variant="standard" label="サイズ" />
                    )}
                    onChange={(e, newValue) => {
                        handleChangeSize(newValue);
                    }}
                    className={classes.autoSelect}
                />
                </Grid>
                {getCalendarDisplay(activeUsersSizeOption)}
                {activeUsersGameOption.length > 0 &&
                    <Grid item>
                        <Autocomplete
                        id="select-country"
                        style={{ width: 180 }}
                        options={activeUsersCountryOption}
                        getOptionLabel={(option) => option.name}
                        defaultValue={activeUsersCountryOption[0]}
                        disableClearable={true}
                        loading={countryLoading}
                        renderInput={(params) => (
                            <TextField
                                {...params} variant="standard" label="国"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <Fragment>
                                        {countryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                    ),
                                }}
                            />
                        )}
                        onChange={(e, newValue) => {
                            handleChangeCountry(newValue);
                        }}
                        className={classes.autoSelect}
                        />
                    </Grid>
                }
                {activeUsersGameOption.length === 1 &&
                 !allPmMenuSelect &&
                    <Grid item>
                        <Autocomplete
                        id="select-rid"
                        style={{ width: 450 }}
                        options={activeUsersRidOption}
                        getOptionLabel={(option) => option.name}
                        defaultValue={activeUsersRidOption[0]}
                        disableClearable={true}
                        loading={ridLoading}
                        renderInput={(params) => (
                            <TextField {...params} variant="standard" label="キャンペーン"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <Fragment>
                                        {ridLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </Fragment>
                                    ),
                                }}
                            />
                        )}
                        onChange={(e, newValue) => {
                            handleChangeRid(newValue);
                        }}
                        className={classes.autoSelect}
                        />
                    </Grid>
                }
                <Grid item>
                <Button
                    disabled={btnStateDataFetch}
                    className={classes.dataBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => fetchData()}>
                    GO
                </Button>
                </Grid>
            </Grid>
            </div>
            <C.ContentWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={4}>
                <Card>
                    <CardContent className={classes.cardContentTop}>
                        <div className={classes.cardHeadSection}>
                            <div className={classes.cardTitle}>
                                PlayMining全体のファネル：
                            </div>
                        </div>
                        <div className={classes.cardContentSection}>
                        {activeUsersDateDiff < 32
                        ? pageTopLoader
                            ? <div className={classes.loaderBoxForUnique}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
                            : chartState
                                ? activeUsersData[0]['data'].length > 0
                                    ? getTotalDisplay(activeUsersUniqueTotalData, activeUsersSizeSelected)
                                    : <div className={classes.selectGameForUnique}><Chip label="No Data" style={{color:V.textColor, backgroundColor:V.placeholderColor}} /></div>
                                : <div className={classes.selectGameForUnique}>
                                    <Chip label="MAXで31日（1ヶ月）の期間を設定してください" style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                                  </div>
                        : <div className={classes.selectGameForUnique}><Chip label="サイズの期間がMAX31日を超えています" style={{color:V.textColor, backgroundColor:V.placeholderColor}} /></div>
                        }
                        </div>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                <Card>
                    <CardContent>
                        <div className={classes.cardHeadSection}>
                            <div className={classes.cardTitle}>
                                PlayMining完全ユニーク vs 各ゲームのユニーク：
                            </div>
                        </div>
                        <div className={classes.cardContentSection}>
                        {activeUsersDateDiff < 32
                        ? pageTopLoader
                            ? <div className={classes.loaderBoxForUnique}>
                                <img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} />
                              </div>
                            : chartState
                                ? activeUsersData[0]['data'].length > 0
                                    ? getTotalByGameDisplay(activeUsersUniqueTotalData, activeUsersSizeSelected)
                                    : <div className={classes.selectGameForUnique}><Chip label="No Data" style={{color:V.textColor, backgroundColor:V.placeholderColor}} /></div>
                                : <div className={classes.selectGameForUnique}>
                                    <Chip label="MAXで31日（1ヶ月）の期間を設定してください" style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                                  </div>
                        : <div className={classes.selectGameForUnique}><Chip label="サイズの期間がMAX31日を超えています" style={{color:V.textColor, backgroundColor:V.placeholderColor}} /></div>
                        }
                        </div>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                {pageTopLoader
                    ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
                    : chartState
                        ? activeUsersData[0]['data'].length > 0
                          ? <C.LineChartActiveUsers
                                legend={ChartConfig.legendActiveUsers}
                                lblSaveImage={"ゲーム別アクティブユーザー"}
                                xAisData={activeUsersData[0]['date']}
                                seriesData={activeUsersData}
                                chartType={activeUsersSizeSelected.value !== 'mau' ? 'line' : 'bar'}
                            />
                          : <div className={classes.selectGameTxt}><Chip label="No Data" style={{color:V.textColor, backgroundColor:V.placeholderColor}} /></div>
                        : <div className={classes.selectGameTxt}>
                            <Chip label="ゲームとサイズを選択してください" style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                          </div>
                }
                </Grid>
            </Grid>
            </C.ContentWrapper>
            <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                    {!pageTopLoader && chartState && activeUsersData[0]['data'].length > 0
                        ? getAvgDisplay(activeUsersData, activeUsersSizeSelected)
                        : null
                    }
                </Grid>
            </Grid>
            <div className={classes.activeUsersTable}>
                <C.ActiveUsersTable />
            </div>
        </Fragment>
    );
};

ActiveUsers.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    pageTopLoader: state.page.pageTopLoader,
    activeUsersGameOption: state.activeUsers.activeUsersGameOption,
    activeUsersSize: state.activeUsers.activeUsersSize,
    activeUsersSizeOption: state.activeUsers.activeUsersSizeOption,
    activeUsersSizeSelected: state.activeUsers.activeUsersSizeSelected,
    activeUsersCountryOption: state.activeUsers.activeUsersCountryOption,
    activeUsersRidOption: state.activeUsers.activeUsersRidOption,
    activeUsersData: state.activeUsers.activeUsersData,
    activeUsersUniqueTotalData: state.activeUsers.activeUsersUniqueTotalData,
    activeUsersUniquePaidData: state.activeUsers.activeUsersUniquePaidData,
    countryLoading: state.activeUsers.countryLoading,
    ridLoading: state.activeUsers.ridLoading,
    activeUsersDateDiff: state.activeUsers.activeUsersDateDiff,
    allPmMenuSelect: state.activeUsers.allPmMenuSelect,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getActiveUsersPageData: () => dispatch(getActiveUsersPageData()),
    getActiveUsersCountries: () => dispatch(getActiveUsersCountries()),
    setActiveUsersGameOption: (param) => dispatch(setActiveUsersGameOption(param)),
    setActiveUsersGameSelected: (param) => dispatch(setActiveUsersGameSelected(param)),
    setActiveUsersSizeOption: (param) => dispatch(setActiveUsersSizeOption(param)),
    setActiveUsersSizeSelected: (param) => dispatch(setActiveUsersSizeSelected(param)),
    setActiveUsersCountrySelected: (param) => dispatch(setActiveUsersCountrySelected(param)),
    setActiveUsersRidSelected: (param) => dispatch(setActiveUsersRidSelected(param)),
    resetActiveUsersGameSelected: () => dispatch(resetActiveUsersGameSelected()),
    setAllPmMenuSelect: (param) => dispatch(setAllPmMenuSelect(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ActiveUsers));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBoxForUnique: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 110,
        },
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        filterSection: {
            display: 'flex',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        activeUsersTable: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        lineChart: {

        },
        selectGameForUnique: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 110,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        selectGameTxt: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 280,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        flexContainerNotFix: {
            display: 'flex',
            overflown: 'hidden',
            width: '100%',
            flexFlow: 'row wrap',
            marginLeft: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBox: {
            alignSelf: 'center',
            paddingLeft: 8,
            paddingRight: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBoxTotal: {
            fontSize: 14,
            alignSelf: 'top',
            paddingLeft: 0,
            paddingRight: 4,
        },
        monthPicker: {
            marginTop: 11,
            marginLeft: 16,
            width: 120,
        },
        cardTitle: {
            fontSize: 16,
        },
        progressFunnel: {
            marginTop: '160px',
            marginBottom: '160px',
        },
        progress4Funnel: {
            marginTop: '160px',
            marginLeft: '30%',
            marginBottom: '160px',
        },
        funnelLegendWrapper: {
            marginTop: '16px',
            marginBottom: '70px',
        },
        funnelLegendRow: {
            display: 'flex',
            marginBottom: 8,
        },
        funnelLegend: {
            width: '30px',
            height: '10px',
            float: 'left',
            clear: 'both',
            paddingBottom: '8px',
        },
        legendLabel: {
            float: 'left',
            marginLeft: '7px',
            fontSize: '13px',
        },
        legendValue: {
            float: 'right',
            fontSize: '13px',
        },
        colorFunnelTop: {
            backgroundColor: '#46aaee',
            width: 8,
        },
        colorFunnelBottom: {
            backgroundColor: '#f53b78',
            width: 8,
        },
        colorFunnelKakin: {
            backgroundColor: '#93e399',
            width: 8,
        },
        cardContentTop: {
            paddingBottom: 4,
        }
    }),
);
