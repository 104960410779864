// Icons Material can be defined here, not fontAwesome
import HomeIcon from "@material-ui/icons/Home";

import {
  ActiveUsersByGame,
  Cohort,
  PicTreeHome,
  PicTreeSalesOverview,
  PicTreeSalesTrend,
  PicTreeSalesDetails,
  PicTreeRegistration,
  PicTreeCheckIn,
  PicTreeMap,
  PicTreeReviewSummary,
  PicTreeReviewDetails,
  PicTreeReviewAppearance,
} from "pages";

export default {
  items: [
    {
      path: "/game/pictree/home",
      name: "ホーム",
      type: "link",
      icon: HomeIcon,
      component: PicTreeHome,
      sideBar: true,
      divider: true,
    },
    {
      path: "/game/pictree/sales",
      name: "売上概要",
      type: "link",
      icon: false,
      category: "課金",
      component: PicTreeSalesOverview,
      sideBar: true,
      divider: false,
      disabled: true,
    },
    // {
    //   path: "/game/pictree/sales/trend",
    //   name: "NFT取引トレンド",
    //   type: "link",
    //   icon: false,
    //   component: PicTreeSalesTrend,
    //   sideBar: true,
    //   divider: false,
    //   disabled: true,
    // },
    // {
    //   path: "/game/pictree/sales/details",
    //   name: "NFT取引詳細",
    //   type: "link",
    //   icon: false,
    //   component: PicTreeSalesDetails,
    //   sideBar: true,
    //   divider: false,
    //   disabled: true,
    // },
    // {
    //   path: "/game/pictree/point",
    //   name: "ポイント交換",
    //   type: "link",
    //   icon: false,
    //   component: ActiveUsersByGame,
    //   sideBar: true,
    //   divider: false,
    //   disabled: true,
    // },
    {
      path: "/game/pictree/registration",
      name: "集客",
      type: "link",
      icon: false,
      category: "GAME",
      component: PicTreeRegistration,
      sideBar: true,
      divider: false,
      disabled: true,
    },
    {
      path: "/game/pictree/active-users",
      name: "アクティブユーザー",
      icon: null,
      component: ActiveUsersByGame,
    },
    // {
    //   path: "/game/pictree/cohort",
    //   name: "維持率",
    //   type: "link",
    //   icon: false,
    //   component: Cohort,
    //   sideBar: true,
    //   divider: false,
    //   disabled: true,
    // },
    {
      path: "/game/pictree/checkin",
      name: "チェックイン",
      type: "link",
      icon: false,
      component: PicTreeCheckIn,
      sideBar: true,
      divider: false,
    },
    {
      path: "/game/pictree/photos",
      name: "撮影物",
      type: "submenu",
      sideBar: true,
      children: [
          {
            path: "/overview",
            name: "概要",
            icon: null,
            component: PicTreeReviewSummary,
          },
          {
            path: "/map",
            name: "地図表示",
            icon: null,
            component: PicTreeMap,
            new: true,
          },
          {
            path: "/denchu/appearance",
            name: "出現回数 - 電柱",
            icon: null,
            component: PicTreeReviewAppearance,
          },
        ]
      },
  ],
};
