import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
//import { setCurrentModule } from 'actions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 768,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

const ComingSoon = ({setCurrentModule}) => {
    const [tabFocused, setTabFocused] = useState('map');
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Chip color="primary" label="Coming Soon" style={{color: '#fff'}} />
        </div>
    );
};

ComingSoon.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ComingSoon));