import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
} from "actions";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    gridSection: {
      marginTop: theme.spacing(1),
    },
    statsSection: {
      minHeight: 60,
      padding: theme.spacing(2),
      borderRadius: 4,
    },
    statBox: {
      marginTop: 0,
      minHeight: 60,
      paddingLeft: 8,
      borderRight: "1px solid #dcdcdc",
      "&:last-child": {
        borderRight: "none",
      },
    },
    summary: {
      marginRight: 60,
    },
    statTitle: {
      fontSize: 13,
      paddingBottom: 8,
    },
    dataBox: {
      minWidth: 130,
      marginRight: 12,
      marginBottom: 16,
      borderRight: "solid 1px #ccc"
    },
    dataTitle: {
      fontSize: 14,
      color: "#757575",
    },
    dataValue: {
      marginTop: 8,
      fontSize: 16,
    },
    rightBox: {

    },
  })
);

const CampaignSummary = ({
  setCurrentPage,
  pageTopLoader,
  campaignSelect,
  campaignMeta,
  dataSummary,
}) => {
  const classes = useStyles();

  return (
      <Grid
        container
        spacing={0}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          <Paper className={classes.statsSection}>
            <Grid
              container
              direction="row"
              className={classes.gridSection}
              justifyContent="flex-start"
            >
              <Grid
                className={classes.statBox}
                item
                xs={12}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.gridSection}
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} md={4} lg={2}>
                    <Grid
                      container
                      direction="column"
                      className={classes.summary}
                      justifyContent="flex-start"
                    >
                      <Grid item>
                        <div className={classes.statTitle}>広告主: {campaignMeta.client ? campaignMeta.client : "--"}</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.statTitle}>期間:　
                        {campaignMeta.startDate ? campaignMeta.startDate : "--"} ~
                        {campaignMeta.endDate ? campaignMeta.endDate : "--"}</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.statTitle}>予算: --</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.statTitle}>総再生時間: --</div>
                      </Grid>
                      <Grid item>
                        <div className={classes.statTitle}>総再生回数: --回</div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} lg={8}>
                    <Grid
                      container
                      direction="row"
                      className={classes.summary}
                      justifyContent="flex-start"
                    >
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>インプレッション</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["imp"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["imp"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>試聴数</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["watch"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["watch"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>試聴率</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["watch"] !== undefined
                              ? (dataSummary[0]["watch"] / dataSummary[0]["imp"]*100).toFixed(1)+`%`
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>試聴完了数</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["watched"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["watched"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>試聴完了率</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["watch"] !== undefined
                              ? (dataSummary[0]["watched"] / dataSummary[0]["watch"]*100).toFixed(1)+`%`
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>クイズ回答数</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["answer_count"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["answer_count"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>クイズ正解数</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["correct_answer_count"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["correct_answer_count"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>正答率</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["answer_count"] !== undefined
                              ? (dataSummary[0]["correct_answer_count"] / dataSummary[0]["answer_count"]*100).toFixed(1)+`%`
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>

                      {/* @todo split the view from client */}
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>ヒントクリック数</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["click_hint_count"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["click_hint_count"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>ヒントクリック率</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["click_hint_count"] !== undefined
                              ? (dataSummary[0]["click_hint_count"] / dataSummary[0]["answer_count"]*100).toFixed(1)+`%`
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>バナークリック数</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["click_bunner_count"] !== undefined
                              ? H.FormatNumStrHelper.formatCommaThousand(dataSummary[0]["click_bunner_count"])
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                      <Grid item>
                        <div className={classes.dataBox}>
                          <div className={classes.dataTitle}>バナークリック率</div>
                          <div className={classes.dataValue}>
                            {dataSummary.length > 0 && dataSummary[0]["click_bunner_count"] !== undefined
                              ? (dataSummary[0]["click_bunner_count"] / dataSummary[0]["answer_count"]*100).toFixed(1)+`%`
                              : "--"
                            }
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Progress bar */}
                  <Grid item xs={12} md={6} lg={2}>
                    <Grid
                      container
                      direction="row"
                      className={classes.summary}
                      justifyContent="flex-start"
                    >
                      <Grid item>
                        <div className={classes.rightBox}>
                          {/* <div>プログレス</div> */}
                          {/* <div className={classes.dataValue}>0.8%</div> */}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>
  );
};

CampaignSummary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  campaignSelect: state.a2e.campaignSelect,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(CampaignSummary));
