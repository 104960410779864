import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setTxHistorySearchLoading, setTxHistorySearchOption, setDataByPmid, setDataLoad, 
setTxHistoryDataLoading, setTxHistoryData, setTxHistoryStatData, setPageTopLoader, setLowTxHistoryData, setLowTxHistoryStatData,
setLowTxCsvData, setCsvDownloader, setNftTxCsvDownloader, setNftTxCsvData } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const dataLoaded = state => state.data.dataLoaded;
export const loading = state => state.nftDepTxHisotry.assetSearchLoading;
export const nftSearchTerm = state => state.nftDepTxHisotry.assetSearchTerm;
export const fitler = state => state.nftDepTxHisotry.txHistoryDataFilter;
export const pageNum = state => state.nftDepTxHisotry.pageNumber;
export const lowTxDepRange = state => state.nftDepTxHisotry.lowTxDepRange;

export function* getTxByTermSxi2() {
    try {
        const jwtToken = yield call(getJwtToken);
        const searchKeyword = yield select(nftSearchTerm);
        yield put(setPageTopLoader(true));
        const currPage = yield select(currentPage);

        const dataFilter = yield select(fitler);
        const dateRange = dataFilter[0]['dateRange'];
        const pageNumber = yield select(pageNum);
        const offsetNum = (pageNumber - 1) * 20;

        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
        const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;

        if (searchKeyword.length > 0) {
            yield put(setTxHistorySearchLoading(true));
            yield put(setPageTopLoader(true));

            // const sqlQuerySalesPrimaryByQuery = H.SqlHelper.getMarketplaceSalesByGameAndTerm2({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], contentType: currPage, term: searchKeyword});
            // const sqlQuerySalesFiatByQuery = H.SqlHelper.getMarketplaceSalesByGameAndTerm2({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], contentType: currPage, term: searchKeyword});
            // const sqlQuerySales2ndByQuery = H.SqlHelper.getMarketplaceSalesByGameAndTerm2({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], contentType: currPage, term: searchKeyword});
            //const sqlQueryAssetDetailByQuery = H.SqlHelper.getNftTransactionByGameAndTerm2({range: [rangeStart, rangeEnd], limit: 20, offset: offsetNum, contentType: currPage, term: searchKeyword});
            const sqlQuerySalesInRangeGroupByEvent = H.SqlHelper.getMarketplaceSalesGroupByType({range: [rangeStart, rangeEnd], contentType: currPage, term: searchKeyword});
            const sqlQueryAssetDetailByQuery = H.SqlHelper.getNftTransactionByGame2({range: [rangeStart, rangeEnd], limit: 20, offset: offsetNum, contentType: currPage, term: searchKeyword});
    
            const [
                // dataAssetTempStoreTerm,
                // dataAssetTempFiatTerm,
                // dataAssetTempAucTerm,
                dataAssetTermByEventTemp,
                dataAssetDetailsTerm,
            ] = yield all([
                // call(getDataFromRDS, sqlQuerySalesPrimaryByQuery, ENDPOINT.GET_DATA_RDS, jwtToken),
                // call(getDataFromRDS, sqlQuerySalesFiatByQuery, ENDPOINT.GET_DATA_RDS, jwtToken),
                // call(getDataFromRDS, sqlQuerySales2ndByQuery, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, sqlQuerySalesInRangeGroupByEvent, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, sqlQueryAssetDetailByQuery, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);

            // const dataStoreTerm = JSON.parse(dataAssetTempStoreTerm.body);
            // const dataFiatTerm = JSON.parse(dataAssetTempFiatTerm.body);
            // const dataAucTerm = JSON.parse(dataAssetTempAucTerm.body);
            const dataAssetTermByEvent= JSON.parse(dataAssetTermByEventTemp.body);
            const dataTxDetail = JSON.parse(dataAssetDetailsTerm.body);

            let totalDep1stTx = 0, totalFiatTx = 0, total2ndTx = 0, totalDep1stAmt = 0, totalFiatAmt = 0, total2ndAmt = 0, totalFiat = 0;
            // const totalDep1st = dataStoreTerm[0][1]['longValue'] < 1 ? 0 : Number(dataStoreTerm[0][0]['stringValue']);
            // const totalFiat1st = dataFiatTerm[0][1]['longValue'] < 1 ? 0 : Number(dataFiatTerm[0][0]['stringValue']);
            // const totalDep2nd = dataAucTerm[0][1]['longValue'] < 1 ? 0 : Number(dataAucTerm[0][0]['stringValue']);
            if (dataAssetTermByEvent.length > 0) {
                _.forEach(dataAssetTermByEvent, function(value) {
                    switch(value[0]["stringValue"]) {
                        case "REVENUE_1ST" :
                            totalDep1stTx = Number(value[3]["longValue"]);
                            totalDep1stAmt = Number(value[1]["stringValue"]);
                            break;
                        case "CREDIT_PAYMENT_MARKET" :
                            totalFiatTx = Number(value[3]["longValue"]);
                            totalFiatAmt = Number(value[2]["stringValue"]);
                            totalFiat = Number(value[1]["stringValue"]);
                            break;
                        case "TRADE_2ND" :
                            total2ndTx = Number(value[3]["longValue"]);
                            total2ndAmt = Number(value[1]["stringValue"]);
                            break;
                        default:
                    }
                });                
            }

            const statData = [{
                "totalTx": totalDep1stTx + totalFiatTx + total2ndTx,
                "totalDep": totalDep1stAmt + totalFiatAmt + total2ndAmt,
                "totalFiat": totalFiat,
                "totalPrimeTx": totalDep1stTx,
                "totalPrimeDep" : totalDep1stAmt,
                "totalFiatTx": totalFiatTx,
                "totalFiatAmount" : totalFiatAmt,
                "totalSecondTx": total2ndTx,
                "totalSecondDep" : total2ndAmt
            }];
    

            yield put(setTxHistoryData(dataTxDetail));
            yield put(setTxHistoryStatData(statData));
            yield put(setPageTopLoader(false));
        }
    } catch (err) {
        console.log(err);
    }
}

export function* getNftTxHisotryData(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setPageTopLoader(true));

        const dataFilter = yield select(fitler);
        const dateRange = dataFilter[0]['dateRange'];
        const offsetNum = (action.payload - 1) * 20;

        // Asset details
        const matchArray = `{ "terms": { "kind": ["auction","store"] } }`;
        const sourceTxHistoryData = H.ElasticSearch.getNftTxHisotryData(matchArray, offsetNum, dateRange);
        const esQueryAsset = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "asset_transaction_history",
            "src": `${sourceTxHistoryData}`
        };

        // Asset details [Store]
        const matchArrayStore = `{ "terms": { "kind": ["store"] } }`;
        const sourceTxHistoryDataStore = H.ElasticSearch.getNftTxHisotryData(matchArrayStore, offsetNum, dateRange);
        const esQueryAssetStore = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "asset_transaction_history",
            "src": `${sourceTxHistoryDataStore}`
        };

        // Asset details [Acution]
        const matchArrayAuc = `{ "terms": { "kind": ["auction"] } }`;
        const sourceTxHistoryDataAuc = H.ElasticSearch.getNftTxHisotryData(matchArrayAuc, offsetNum, dateRange);
        const esQueryAssetAuc = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "asset_transaction_history",
            "src": `${sourceTxHistoryDataAuc}`
        };

        // Count
        // const sourceTxHistoryCountData = H.ElasticSearch.getNftTxHisotryCountData(matchArray, dateRange);
        // const esQueryAssetCount = {
        //     "es": "daa",
        //     "query": "count",
        //     "index": "asset_transaction_history",
        //     "src": `${sourceTxHistoryCountData}`
        // };

        // One Token Count
        // const sourceTxOneTokenData = H.ElasticSearch.getEsQueryTest(matchArray);
        // const esQueryOneTokenAsset = {
        //     "es": "daa",
        //     "query": "simpleSearch",
        //     "index": "asset_transaction_history",
        //     "src": `${sourceTxOneTokenData}`
        // };

        const [
            dataAssetTemp,
            dataAssetTempStore,
            dataAssetTempAuc,
            //dateTempCount,
            //dataOneTokenTemp,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryAsset, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromElasticSearch, esQueryAssetStore, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromElasticSearch, esQueryAssetAuc, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            //call(getDataFromElasticSearch, esQueryAssetCount, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            //call(getDataFromElasticSearch, esQueryOneTokenAsset, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);

        const statData = [{
            "totalTx": dataAssetTemp.hits.total.value.toFixed(0),
            "totalDep": dataAssetTemp.aggregations.price.value.toFixed(2),
            "totalPrimeTx": dataAssetTempStore.hits.total.value.toFixed(0),
            "totalPrimeDep" : dataAssetTempStore.aggregations.price.value.toFixed(2),
            "totalSecondTx": dataAssetTempAuc.hits.total.value.toFixed(0),
            "totalSecondDep" : dataAssetTempAuc.aggregations.price.value.toFixed(2),
        }];

        yield put(setTxHistoryData(dataAssetTemp.hits.hits));
        yield put(setTxHistoryStatData(statData));
        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export function* getNftTxHisotryDataSxi2(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setPageTopLoader(true));
        const currPage = yield select(currentPage);

        const dataFilter = yield select(fitler);
        const dateRange = dataFilter[0]['dateRange'];
        const offsetNum = (action.payload - 1) * 20;

        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
        const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;
        // const sqlQuerySalesPrimaryInRange = H.SqlHelper.getMarketplaceSalesByGame2({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd], contentType: currPage});
        // const sqlQuerySalesFiatInRange = H.SqlHelper.getMarketplaceSalesByGame2({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd], contentType: currPage});
        // const sqlQuerySales2ndInRange = H.SqlHelper.getMarketplaceSalesByGame2({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd], contentType: currPage});
        const sqlQueryAssetDetailInRange = H.SqlHelper.getNftTransactionByGame2({range: [rangeStart, rangeEnd], limit: 20, offset: offsetNum, contentType: currPage});
        const sqlQuerySalesInRangeGroupByEvent = H.SqlHelper.getMarketplaceSalesGroupByType({range: [rangeStart, rangeEnd], contentType: currPage});

        const [
            dataAssetByEventTemp,
            dataAssetDetailsTemp,
        ] = yield all([
            call(getDataFromRDS, sqlQuerySalesInRangeGroupByEvent, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAssetDetailInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const dataAssetByEvent= JSON.parse(dataAssetByEventTemp.body);
        const dataAllTxDetail = JSON.parse(dataAssetDetailsTemp.body);

        let totalDep1stTx = 0, totalFiatTx = 0, total2ndTx = 0, totalDep1stAmt = 0, totalFiatAmt = 0, total2ndAmt = 0, totalFiat = 0;
        if (dataAssetByEvent.length > 0) {
            _.forEach(dataAssetByEvent, function(value) {
                switch(value[0]["stringValue"]) {
                    case "REVENUE_1ST" :
                        totalDep1stTx = Number(value[3]["longValue"]);
                        totalDep1stAmt = Number(value[1]["stringValue"]);
                        break;
                    case "CREDIT_PAYMENT_MARKET" :
                        totalFiatTx = Number(value[3]["longValue"]);
                        totalFiatAmt = Number(value[2]["stringValue"]);
                        totalFiat = Number(value[1]["stringValue"]);
                        break;
                    case "TRADE_2ND" :
                        total2ndTx = Number(value[3]["longValue"]);
                        total2ndAmt = Number(value[1]["stringValue"]);
                        break;
                    default:
                }
            });                
        }

        const statData = [{
            "totalTx": totalDep1stTx + totalFiatTx + total2ndTx,
            "totalDep": totalDep1stAmt + totalFiatAmt + total2ndAmt,
            "totalFiat": totalFiat,
            "totalPrimeTx": totalDep1stTx,
            "totalPrimeDep" : totalDep1stAmt,
            "totalFiatTx": totalFiatTx,
            "totalFiatAmount" : totalFiatAmt,
            "totalSecondTx": total2ndTx,
            "totalSecondDep" : total2ndAmt
        }];

        yield put(setTxHistoryData(dataAllTxDetail));
        yield put(setTxHistoryStatData(statData));
        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export function* getNftTxCsvData2(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setNftTxCsvDownloader(true));
        const currPage = yield select(currentPage);
        const dataFilter = yield select(fitler);
        const searchKeyword = yield select(nftSearchTerm);
        const dateRange = dataFilter[0]['dateRange'];

        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
        const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;

        const sqlQueryAssetDetailInRange = H.SqlHelper.getNftTransactionByGameNoLimit2({range: [rangeStart, rangeEnd], contentType: currPage, term: searchKeyword});

        const [
            dataAssetDetailsTemp,
        ] = yield all([
            call(getDataFromRDS, sqlQueryAssetDetailInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const returnData = JSON.parse(dataAssetDetailsTemp.body);
        let csvData = [
            ["asset_name", "asset_id", "token_id", "content", "dep/usd", "type", "buyer_pmid", "buyer_country", "seller_pmid", "seller_country", "created_at"],
        ], eachRowData = [];
        if (returnData.length > 0) {
            _.forEach(returnData, function(value) {
                eachRowData = [
                    value[0]['stringValue'],
                    value[2]['stringValue'],
                    value[3]['stringValue'],
                    H.ContentsHelper.getContentsNameById(value[5]['stringValue']),
                    value[6]['stringValue'] === "CREDIT_PAYMENT_MARKET" ? Number(value[12]['stringValue']) : value[4]['longValue'],
                    value[6]['stringValue'] === 'TRADE_2ND' ? "2次" : value[6]['stringValue'] === "CREDIT_PAYMENT_MARKET" ? "Fiat" : "1次",
                    value[7]['stringValue'],
                    value[10]['stringValue'],
                    value[8]['stringValue'],
                    value[11]['stringValue'],
                    value[9]['stringValue']
                ];
                csvData.push(eachRowData);
                eachRowData = [];
            });
        }

        yield put(setNftTxCsvData(csvData));
        yield put(setNftTxCsvDownloader(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_NFT_TX_HISTORY_DATA, getNftTxHisotryDataSxi2);
    yield takeEvery(NFTDEP.SET_TX_HISTORY_SEARCH_TERM, getTxByTermSxi2);
    yield takeEvery(NFTDEP.GET_NFT_TX_CSV_DATA, getNftTxCsvData2);
}
