import { put, delay, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setJtcbNftTrendDataLoading, setPageTopLoader,
    setJtcbNftTrendDataCountAuction, setJtcbNftTrendDataAmountAuction, setJtcbNftTrendDataCountStore, setJtcbNftTrendDataAmountStore, setJtcbNftTrendDataCountStoreFiat, setJtcbNftTrendDataAmountStoreFiat,
    setDragonRamenNftTrendDataCountStoreFiat, setDragonRamenNftTrendDataAmountStoreFiat, setDragonRamenNftTrendDataStoreAllFiat,
    setCookinBurgerNftTrendDataCountStoreFiat, setCookinBurgerNftTrendDataAmountStoreFiat, setCookinBurgerNftTrendStoreAllFiat,
    setGraffitiRacerNftTrendDataCountStoreFiat, setGraffitiRacerNftTrendDataAmountStoreFiat, setGraffitiRacerNftTrendDataStoreAllFiat,
    setLuckyFarmerNftTrendDataCountStoreFiat, setLuckyFarmerNftTrendDataAmountStoreFiat, setLuckyFarmerNftTrendStoreAllFiat,
    setLostArchiveNftTrendDataCountStoreFiat, setLostArchiveNftTrendDataAmountStoreFiat, setLostArchiveNftTrendStoreAllFiat, 
    setCookinBurgerNftTrendDataLoading, setCookinBurgerNftTrendDataCountAuction, setCookinBurgerNftTrendDataAmountAuction, setCookinBurgerNftTrendDataCountStore, setCookinBurgerNftTrendDataAmountStore,
    setKamuiVerseNftTrendDataLoading, setKamuiVerseNftTrendDataCountAuction, setKamuiVerseNftTrendDataAmountAuction, setKamuiVerseNftTrendDataCountStore, setKamuiVerseNftTrendDataAmountStore,
    setGraffitiRacerNftTrendDataLoading,setGraffitiRacerNftTrendDataCountAuction,setGraffitiRacerNftTrendDataAmountAuction,setGraffitiRacerNftTrendDataCountStore,setGraffitiRacerNftTrendDataAmountStore,
    setDragonRamenNftTrendDataCountAuction, setDragonRamenNftTrendDataAmountAuction, setDragonRamenNftTrendDataCountStore, setDragonRamenNftTrendDataAmountStore, setDragonRamenNftTrendDataLoading,
    setDragonRamenNftTrendDataAuctionAll, setDragonRamenNftTrendDataStoreAll, setGraffitiRacerNftTrendDataAuctionAll, setGraffitiRacerNftTrendDataStoreAll, setCookinBurgerNftTrendAuctionAll,
    setCookinBurgerNftTrendStoreAll, setJtcbNftTrendAuctionAll, setJtcbNftTrendStoreAll, setJtcbNftTrendStoreAllFiat, setLuckyFarmerNftTrendAuctionAll, setLuckyFarmerNftTrendStoreAll,
    setLuckyFarmerNftTrendDataLoading, setLuckyFarmerNftTrendDataCountAuction, setLuckyFarmerNftTrendDataAmountAuction, setLuckyFarmerNftTrendDataCountStore, setLuckyFarmerNftTrendDataAmountStore,
    setLostArchiveNftTrendDataLoading, setLostArchiveNftTrendDataCountAuction, setLostArchiveNftTrendDataAmountAuction, setLostArchiveNftTrendDataCountStore, setLostArchiveNftTrendDataAmountStore, setLostArchiveNftTrendStoreAll, setLostArchiveNftTrendAuctionAll,
    setSoulFusersNftTrendDataLoading, setSoulFusersNftTrendStoreAllFiat, setSoulFusersNftTrendDataCountAuction, setSoulFusersNftTrendDataAmountAuction,
    setSoulFusersNftTrendDataCountStore, setSoulFusersNftTrendDataAmountStore,setSoulFusersNftTrendDataCountStoreFiat, setSoulFusersNftTrendDataAmountStoreFiat, setSoulFusersNftTrendStoreAll, setSoulFusersNftTrendAuctionAll,
    setGameNftTrendDataLoading,setGameNftTrendStoreAll,setGameNftTrendAuctionAll,setGameNftTrendStoreAllFiat,
    setGameNftTrendDataCountAuction,setGameNftTrendDataAmountAuction,setGameNftTrendDataCountStore,setGameNftTrendDataAmountStore,
    setGameNftTrendDataCountStoreFiat, setGameNftTrendDataAmountStoreFiat
 } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromSxi } from 'api';
import { COOKINBURGER, JTCB, GRAFITTIRACER, DRAGONRAMEN, KAMUIVERSE, LUCKYFARMER, LOSTARCHIVE, SOULFUSERS, DATA, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const nftJtcbNftTrendDateRange = state => state.jtcbNftTrend.jtcbNftTrendDateRange;
export const nftTrendKindSelected = state => state.jtcbNftTrend.trendKindSelected;
export const nftTrendRareSelected = state => state.jtcbNftTrend.trendRareSelected;
export const cookinBurgerNftTrendDateRange = state => state.cookinBurgerNftTrend.cookinBurgerNftTrendDateRange;
export const dragonRamenNftTrendDateRange = state => state.dragonRamenNftTrend.dragonRamenNftTrendDateRange;
export const graffitiRacerNftTrendDateRange = state => state.graffitiRacerNftTrend.graffitiRacerNftTrendDateRange;
export const kamuiVerseNftTrendDateRange = state => state.kamuiVerseNftTrend.kamuiVerseNftTrendDateRange;
export const luckyFarmerNftTrendDateRange = state => state.luckyFarmerNftTrend.luckyFarmerNftTrendDateRange;
export const lostArchiveNftTrendDateRange = state => state.lostArchiveNftTrend.lostArchiveNftTrendDateRange;
export const soulFusersNftTrendDateRange = state => state.soulfusers.soulFusersNftTrendDateRange;
export const gameNftTrendDateRange = state => state.gameNftTrend.gameNftTrendDateRange;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];

export function* getJtcbNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setJtcbNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(nftJtcbNftTrendDateRange);
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        const allRarity = [
            "RARITY-JTCB_LEGENDARY",
            "RARITY-JTCB_EPIC",
            "RARITY-JTCB_RARE",
            "RARITY-JTCB_COMMON"
        ];
        const sqlQueryGet1stRarityLeg = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_LEGENDARY', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityLeg = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_LEGENDARY', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityEpi = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_EPIC', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityEpi = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_EPIC', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityRar = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: "RARITY-JTCB_RARE", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityRar = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: "RARITY-JTCB_RARE", range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityCom = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_COMMON', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityCom = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_COMMON', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuq0009l5rwgeh646m3', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd2ndV1',tradeType: 'TRADE_2ND', contentId: 'cl8jxahuq0009l5rwgeh646m3', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuq0009l5rwgeh646m3', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2ndRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd2ndV1',tradeType: 'TRADE_2ND', contentId: 'cl8jxahuq0009l5rwgeh646m3', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiatLeg = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_LEGENDARY', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatEpi = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_EPIC', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatRar = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_RARE', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatCom = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuq0009l5rwgeh646m3', rarity: 'RARITY-JTCB_COMMON', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuq0009l5rwgeh646m3', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuq0009l5rwgeh646m3', range: [rangeStart, rangeEnd]});

        const [
            // dataAllRarityTrendTemp,
            dataLegendTrendTemp,
            dataLegendTrend2ndTemp,
            dataEpicTrendTemp,
            dataEpicTrend2ndTemp,
            dataRareTrendTemp,
            dataRareTrend2ndTemp,
            dataCommonTrendTemp,
            dataCommonTrend2ndTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataUniqueCount1stRangeTemp,
            dataUniqueCount2ndRangeTemp,
            dataLegendFiatTrendTemp,
            dataEpicFiatTrendTemp,
            dataRareFiatTrendTemp,
            dataCommonFiatTrendTemp,
            dataUniCnt1stFiatTemp,
            dataUniqueCount1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGet1stRarityLeg, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityLeg, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityEpi, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityEpi, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityRar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityRar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityCom, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityCom, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2ndRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatLeg, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatEpi, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatRar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatCom, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        // rarity result
        const dataLegendTrend1st = JSON.parse(dataLegendTrendTemp.body);
        const dataLegendTrend2nd = JSON.parse(dataLegendTrend2ndTemp.body);
        const dataEpicTrend1st = JSON.parse(dataEpicTrendTemp.body);
        const dataEpicTrend2nd = JSON.parse(dataEpicTrend2ndTemp.body);
        const dataRareTrend1st = JSON.parse(dataRareTrendTemp.body);
        const dataRareTrend2nd = JSON.parse(dataRareTrend2ndTemp.body);
        const dataCommonTrend1st = JSON.parse(dataCommonTrendTemp.body);
        const dataCommonTrend2nd = JSON.parse(dataCommonTrend2ndTemp.body);
        const dataUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);
        const dataUniCntRange1st = JSON.parse(dataUniqueCount1stRangeTemp.body);
        const dataUniCntRange2nd = JSON.parse(dataUniqueCount2ndRangeTemp.body);
        const dataLegendFiatTrend = JSON.parse(dataLegendFiatTrendTemp.body);
        const dataEpicFiatTrend = JSON.parse(dataEpicFiatTrendTemp.body);
        const dataRareFiatTrend = JSON.parse(dataRareFiatTrendTemp.body);
        const dataCommonFiatTrend = JSON.parse(dataCommonFiatTrendTemp.body);
        const dataUniCnt1stFiat = JSON.parse(dataUniCnt1stFiatTemp.body);
        const dataUniCntRange1stFiat = JSON.parse(dataUniqueCount1stRangeFiatTemp.body);
        // console.log("KOKO dataUniCnt1stFiat:", dataUniCnt1stFiat);
        // console.log("KOKO dataUniCntRange1stFiat:", dataUniCntRange1stFiat);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];
        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];

        const storeUniDateArray = dataUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        // FIAT
        const storeFiatUniDateArray = dataUniCnt1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        // legend begin
        const storeLegDateArray = dataLegendTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucLegDateArray = dataLegendTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatLegDateArray = dataLegendFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        let legendCountArrayStore = [], legendAmountArrayStore = [];
        let legendCountArray = [], legendAmountArray = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];
        //
        let legendCountArrayStoreFiat = [], legendAmountArrayStoreFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store DEP
            if (storeLegDateArray.indexOf(date) !== -1) {
                const indexStoreSR =  storeLegDateArray.indexOf(date);
                legendCountArrayStore.push(dataLegendTrend1st[indexStoreSR][1]['longValue']);
                legendAmountArrayStore.push(Number(dataLegendTrend1st[indexStoreSR][2]['stringValue']))
            } else {
                legendCountArrayStore.push(0);
                legendAmountArrayStore.push(0)
            }
            // auction
            if (aucLegDateArray.indexOf(date) !== -1) {
                const indexAucSR =  aucLegDateArray.indexOf(date);
                legendCountArray.push(dataLegendTrend2nd[indexAucSR][1]['longValue']);
                legendAmountArray.push(Number(dataLegendTrend2nd[indexAucSR][2]['stringValue']))
            } else {
                legendCountArray.push(0);
                legendAmountArray.push(0)
            }

            // store FIAT
            if (storeFiatLegDateArray.indexOf(date) !== -1 && dataLegendFiatTrend.length > 0) {
                const indexStore =  storeFiatLegDateArray.indexOf(date);
                legendCountArrayStoreFiat.push(dataLegendFiatTrend[indexStore][1]['longValue']);
                legendAmountArrayStoreFiat.push(Number(dataLegendFiatTrend[indexStore][2]['stringValue']))
            } else {
                legendCountArrayStoreFiat.push(0);
                legendAmountArrayStoreFiat.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataUniCnt1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // All stats
        allCountAuc.push(_.sum(legendCountArray));
        allCountStore.push(_.sum(legendCountArrayStore));
        allDepAuc.push(_.sum(legendAmountArray));
        allDepStore.push(_.sum(legendAmountArrayStore));
        allCountStoreFiat.push(_.sum(legendCountArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(legendAmountArrayStoreFiat));

        // Unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStoreFiat,
                date: dateArray
            }
        ); 

        allDataCountAuction.push(
            {
                id: "auction-legendary-count",
                data: legendCountArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-legendary-amount",
                data: legendAmountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-legendary-count",
                data: legendCountArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-legendary-amount",
                data: legendAmountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-legendary-count-fiat",
                data: legendCountArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-legendary-amount-fiat",
                data: legendAmountArrayStoreFiat,
                date: dateArray
            }
        );
        // legend end

        // Epic begin
        const storeEpiDateArray = dataEpicTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucEpiDateArray = dataEpicTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatEpiDateArray = dataEpicFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let epicCountArrayStore = [], epicAmountArrayStore = [];
        let epicCountArrayStoreFiat = [], epicAmountArrayStoreFiat = [];
        let epicCountArray = [], epicAmountArray = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeEpiDateArray.indexOf(date) !== -1) {
                const indexStoreEpic =  storeEpiDateArray.indexOf(date);
                epicCountArrayStore.push(dataEpicTrend1st[indexStoreEpic][1]['longValue']);
                epicAmountArrayStore.push(Number(dataEpicTrend1st[indexStoreEpic][2]['stringValue']))
            } else {
                epicCountArrayStore.push(0);
                epicAmountArrayStore.push(0)
            }
            // auction
            if (aucEpiDateArray.indexOf(date) !== -1) {
                const indexAucEpic =  aucEpiDateArray.indexOf(date);
                epicCountArray.push(dataEpicTrend2nd[indexAucEpic][1]['longValue']);
                epicAmountArray.push(Number(dataEpicTrend2nd[indexAucEpic][2]['stringValue']))
            } else {
                epicCountArray.push(0);
                epicAmountArray.push(0)
            }
            // store FIAT
            if (storeFiatEpiDateArray.indexOf(date) !== -1 && dataEpicFiatTrend.length > 0) {
                const indexStore =  storeFiatEpiDateArray.indexOf(date);
                epicCountArrayStoreFiat.push(dataEpicFiatTrend[indexStore][1]['longValue']);
                epicAmountArrayStoreFiat.push(Number(dataEpicFiatTrend[indexStore][2]['stringValue']))
            } else {
                epicCountArrayStoreFiat.push(0);
                epicAmountArrayStoreFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(epicCountArray));
        allCountStore.push(_.sum(epicCountArrayStore));
        allDepAuc.push(_.sum(epicAmountArray));
        allDepStore.push(_.sum(epicAmountArrayStore));
        allCountStoreFiat.push(_.sum(epicCountArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(epicAmountArrayStoreFiat));

        // Epic end
        allDataCountAuction.push(
            {
                id: "auction-epic-count",
                data: epicCountArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-epic-amount",
                data: epicAmountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-epic-count",
                data: epicCountArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-epic-amount",
                data: epicAmountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-epic-count-fiat",
                data: epicCountArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-epic-amount-fiat",
                data: epicAmountArrayStoreFiat,
                date: dateArray
            }
        );

        // Rare begin
        const storeRarDateArray = dataRareTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucRarDateArray = dataRareTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatRarDateArray = dataRareFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let rareCountArrayStore = [], rareAmountArrayStore = [];
        let rareCountArrayStoreFiat = [], rareAmountArrayStoreFiat = [];
        let rareCountArray = [], rareAmountArray = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeRarDateArray.indexOf(date) !== -1) {
                const indexStoreRare =  storeRarDateArray.indexOf(date);
                rareCountArrayStore.push(dataRareTrend1st[indexStoreRare][1]['longValue']);
                rareAmountArrayStore.push(Number(dataRareTrend1st[indexStoreRare][2]['stringValue']))
            } else {
                rareCountArrayStore.push(0);
                rareAmountArrayStore.push(0)
            }
            // auction
            if (aucRarDateArray.indexOf(date) !== -1) {
                const indexAucRare =  aucRarDateArray.indexOf(date);
                rareCountArray.push(dataRareTrend2nd[indexAucRare][1]['longValue']);
                rareAmountArray.push(Number(dataRareTrend2nd[indexAucRare][2]['stringValue']))
            } else {
                rareCountArray.push(0);
                rareAmountArray.push(0)
            }
            // store FIAT
            if (storeFiatRarDateArray.indexOf(date) !== -1 && dataRareFiatTrend.length > 0) {
                const indexStore =  storeFiatRarDateArray.indexOf(date);
                rareCountArrayStoreFiat.push(dataRareFiatTrend[indexStore][1]['longValue']);
                rareAmountArrayStoreFiat.push(Number(dataRareFiatTrend[indexStore][2]['stringValue']))
            } else {
                rareCountArrayStoreFiat.push(0);
                rareAmountArrayStoreFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(rareCountArray));
        allCountStore.push(_.sum(rareCountArrayStore));
        allDepAuc.push(_.sum(rareAmountArray));
        allDepStore.push(_.sum(rareAmountArrayStore));
        allCountStoreFiat.push(_.sum(rareCountArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(rareAmountArrayStoreFiat));

        // Rare end
        allDataCountAuction.push(
            { 
                id: "auction-rare-count",
                data: rareCountArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            { 
                id: "auction-rare-amount",
                data: rareAmountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-rare-count",
                data: rareCountArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-rare-amount",
                data: rareAmountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-rare-count-fiat",
                data: rareCountArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-rare-amount-fiat",
                data: rareAmountArrayStoreFiat,
                date: dateArray
            }
        );

        // Common begin
        const storeComDateArray = dataCommonTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucComDateArray = dataCommonTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatComDateArray = dataCommonFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let commonCountArrayStore = [], commonAmountArrayStore = [];
        let commonCountArrayStoreFiat = [], commonAmountArrayStoreFiat = [];
        let commonCountArray = [], commonAmountArray = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeComDateArray.indexOf(date) !== -1) {
                const indexStoreRare =  storeComDateArray.indexOf(date);
                commonCountArrayStore.push(dataCommonTrend1st[indexStoreRare][1]['longValue']);
                commonAmountArrayStore.push(Number(dataCommonTrend1st[indexStoreRare][2]['stringValue']))
            } else {
                commonCountArrayStore.push(0);
                commonAmountArrayStore.push(0)
            }
            // auction
            if (aucComDateArray.indexOf(date) !== -1) {
                const indexAucRare =  aucComDateArray.indexOf(date);
                commonCountArray.push(dataCommonTrend2nd[indexAucRare][1]['longValue']);
                commonAmountArray.push(Number(dataCommonTrend2nd[indexAucRare][2]['stringValue']))
            } else {
                commonCountArray.push(0);
                commonAmountArray.push(0)
            }
            // store FIAT
            if (storeFiatComDateArray.indexOf(date) !== -1 && dataCommonFiatTrend.length > 0) {
                const indexStore =  storeFiatComDateArray.indexOf(date);
                commonCountArrayStoreFiat.push(dataCommonFiatTrend[indexStore][1]['longValue']);
                commonAmountArrayStoreFiat.push(Number(dataCommonFiatTrend[indexStore][2]['stringValue']))
            } else {
                commonCountArrayStoreFiat.push(0);
                commonAmountArrayStoreFiat.push(0)
            }
        });
        // Common end

        // All stats
        allCountAuc.push(_.sum(commonCountArray));
        allCountStore.push(_.sum(commonCountArrayStore));
        allDepAuc.push(_.sum(commonAmountArray));
        allDepStore.push(_.sum(commonAmountArrayStore));
        allCountStoreFiat.push(_.sum(commonCountArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(commonAmountArrayStoreFiat));

        allDataCountAuction.push(
            {
                id: "auction-common-count",
                data: commonCountArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-common-amount",
                data: commonAmountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-common-count",
                data: commonCountArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-common-amount",
                data: commonAmountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-common-count-fiat",
                data: commonCountArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-common-amount-fiat",
                data: commonAmountArrayStoreFiat,
                date: dateArray
            }
        );
        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1st[0][0]['longValue'])
        };
        yield put(setJtcbNftTrendStoreAll(dataAllStore));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1stFiat[0][0]['longValue'])
        };
        yield put(setJtcbNftTrendStoreAllFiat(dataAllStoreFiat));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange2nd[0][0]['longValue'])
        };
        yield put(setJtcbNftTrendAuctionAll(dataAllAuction));

        yield put(setJtcbNftTrendDataCountAuction(allDataCountAuction));
        yield put(setJtcbNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setJtcbNftTrendDataCountStore(allDataCountStore));
        yield put(setJtcbNftTrendDataAmountStore(allDataAmountStore));
        yield put(setJtcbNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setJtcbNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setJtcbNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getCookinBurgerNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setCookinBurgerNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(cookinBurgerNftTrendDateRange);

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;

        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2ndRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiat = H.SqlHelper.getNftTransactonByGameAndAllRarity({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahuf0001l5rwewc19q7j', range: [rangeStart, rangeEnd]});
        const [
            dataCb1stTemp,
            dataCb2ndTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataUniqueCount1stRangeTemp,
            dataUniqueCount2ndRangeTemp,
            dataGet1stFiatTemp,
            dataNftCountUnique1stFiatTemp,
            dataNftCountUnique1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2ndRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataCbAll1st = JSON.parse(dataCb1stTemp.body);
        const dataCbAll2nd = JSON.parse(dataCb2ndTemp.body);
        const dataDrUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataDrUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);
        const dataUniCntRange1st = JSON.parse(dataUniqueCount1stRangeTemp.body);
        const dataUniCntRange2nd = JSON.parse(dataUniqueCount2ndRangeTemp.body);
        const dataGet1stFiat = JSON.parse(dataGet1stFiatTemp.body);
        const dataNftCountUnique1stFiat = JSON.parse(dataNftCountUnique1stFiatTemp.body);
        const dataNftCountUnique1stRangeFiat = JSON.parse(dataNftCountUnique1stRangeFiatTemp.body);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        const storeDateArray = dataCbAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataCbAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataDrUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataDrUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        // FIAT
        const storeFiatDateArray = dataGet1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        const storeFiatUniDateArray = dataNftCountUnique1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];
        let countArrayStoreFiat = [], amountArrayStoreFiat = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];
        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];
        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataCbAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataCbAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataCbAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataCbAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataDrUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataDrUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // store FIAT
            if (storeFiatDateArray.indexOf(date) !== -1 && dataGet1stFiat.length > 0) {
                const indexStore =  storeFiatDateArray.indexOf(date);
                countArrayStoreFiat.push(dataGet1stFiat[indexStore][1]['longValue']);
                amountArrayStoreFiat.push(Number(dataGet1stFiat[indexStore][2]['stringValue']))
            } else {
                countArrayStoreFiat.push(0);
                amountArrayStoreFiat.push(0)
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataNftCountUnique1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArray));
        allCountStore.push(_.sum(countArrayStore));
        allDepAuc.push(_.sum(amountArray));
        allDepStore.push(_.sum(amountArrayStore));
        allCountStoreFiat.push(_.sum(countArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreFiat));

        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );
        allDataCountAuction.push(
            {
                id: "auction-cookinburger-count",
                data: countArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-cookinburger-amount",
                data: amountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-cookinburger-count",
                data: countArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-cookinburger-amount",
                data: amountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-cookinburger-count-fiat",
                data: countArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-cookinburger-amount-fiat",
                data: amountArrayStoreFiat,
                date: dateArray
            }
        );

        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1st[0][0]['longValue'])
        };
        yield put(setCookinBurgerNftTrendStoreAll(dataAllStore));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange2nd[0][0]['longValue'])
        };
        yield put(setCookinBurgerNftTrendAuctionAll(dataAllAuction));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataNftCountUnique1stRangeFiat[0][0]['longValue'])
        };
        yield put(setCookinBurgerNftTrendStoreAllFiat(dataAllStoreFiat));

        yield put(setCookinBurgerNftTrendDataCountAuction(allDataCountAuction));
        yield put(setCookinBurgerNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setCookinBurgerNftTrendDataCountStore(allDataCountStore));
        yield put(setCookinBurgerNftTrendDataAmountStore(allDataAmountStore));
        yield put(setCookinBurgerNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setCookinBurgerNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setCookinBurgerNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getDragonRamenNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setDragonRamenNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(dragonRamenNftTrendDateRange);
        const allRarity = ["RARITY-MDR_UR","RARITY-MDR_N","RARITY-MDR_SR","RARITY-MDR_SSR","RARITY-MDR_R"];

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;

        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactonByGameAndRarityAll({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactonByGameAndRarityAll({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityUR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_UR', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityUR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_UR', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityN = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_N', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityN = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_N', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRaritySR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: "RARITY-MDR_SR", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRaritySR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: "RARITY-MDR_SR", range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRaritySSR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: "RARITY-MDR_SSR", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRaritySSR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: "RARITY-MDR_SSR", range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: "RARITY-MDR_R", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: "RARITY-MDR_R", range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxanai07njl5rwgg80cxzc', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxanai07njl5rwgg80cxzc', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiatUR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_UR', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatN = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_N', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatSR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_SR', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatSSR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_SSR', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatR = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', rarity: 'RARITY-MDR_R', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxanai07njl5rwgg80cxzc', range: [rangeStart, rangeEnd]});

        const [
            dataCb1stTemp,
            dataCb2ndTemp,
            dataDr1stTemp1,
            dataDr2ndTemp1,
            dataDr1stTemp2,
            dataDr2ndTemp2,
            dataDr1stTemp3,
            dataDr2ndTemp3,
            dataDr1stTemp4,
            dataDr2ndTemp4,
            dataDr1stTemp5,
            dataDr2ndTemp5,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataURFiatTrendTemp,
            dataNFiatTrendTemp,
            dataSRFiatTrendTemp,
            dataSSRFiatTrendTemp,
            dataRFiatTrendTemp,
            dataUniCnt1stFiatTemp,
            dataUniqueCount1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityUR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityUR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityN, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityN, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRaritySR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRaritySR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRaritySSR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRaritySSR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatUR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatN, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatSR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatSSR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatR, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        // rarity result
        const dataDrAll1st = JSON.parse(dataCb1stTemp.body);
        const dataDrAll2nd = JSON.parse(dataCb2ndTemp.body);
        const dataDr1st = JSON.parse(dataDr1stTemp1.body);
        const dataDr2nd = JSON.parse(dataDr2ndTemp1.body);
        const dataDr1st2 = JSON.parse(dataDr1stTemp2.body);
        const dataDr2nd2 = JSON.parse(dataDr2ndTemp2.body);
        const dataDr1st3 = JSON.parse(dataDr1stTemp3.body);
        const dataDr2nd3 = JSON.parse(dataDr2ndTemp3.body);
        const dataDr1st4 = JSON.parse(dataDr1stTemp4.body);
        const dataDr2nd4 = JSON.parse(dataDr2ndTemp4.body);
        const dataDr1st5 = JSON.parse(dataDr1stTemp5.body);
        const dataDr2nd5 = JSON.parse(dataDr2ndTemp5.body);
        const dataDrUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataDrUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);

        const dataURFiatTrend = JSON.parse(dataURFiatTrendTemp.body);
        const dataNFiatTrend = JSON.parse(dataNFiatTrendTemp.body);
        const dataSRFiatTrend = JSON.parse(dataSRFiatTrendTemp.body);
        const dataSSRFiatTrend = JSON.parse(dataSSRFiatTrendTemp.body);
        const dataRFiatTrend = JSON.parse(dataRFiatTrendTemp.body);
        const dataUniCnt1stFiat = JSON.parse(dataUniCnt1stFiatTemp.body);
        const dataUniqueCount1stRangeFiat = JSON.parse(dataUniqueCount1stRangeFiatTemp.body);
        // console.log("KEKE dataUniCnt1stFiat:", dataUniCnt1stFiat);
        // console.log("KEKE dataUniqueCount1stRangeFiat:", dataUniqueCount1stRangeFiat);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];

        const storeDateArray = dataDrAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataDrAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataDrUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataDrUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        // FIAT
        const storeFiatUniDateArray = dataUniCnt1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];

        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];

        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataDrAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataDrAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataDrAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataDrAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataDrUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataDrUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataUniCnt1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStoreFiat,
                date: dateArray
            }
        );

        // By rarity UR begin
        const storeURDateArray = dataDr1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucURDateArray = dataDr2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatUrDateArray = dataURFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreUR = [], amountArrayStoreUR = [], countArrayAucUR = [], amountArrayAucUR = [];
        let countArrayStoreURFiat = [], amountArrayStoreURFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeURDateArray.indexOf(date) !== -1) {
                const indexStore =  storeURDateArray.indexOf(date);
                countArrayStoreUR.push(dataDr1st[indexStore][1]['longValue']);
                amountArrayStoreUR.push(Number(dataDr1st[indexStore][2]['stringValue']))
            } else {
                countArrayStoreUR.push(0);
                amountArrayStoreUR.push(0)
            }
            // auction
            if (aucURDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucURDateArray.indexOf(date);
                countArrayAucUR.push(dataDr2nd[indexAuc][1]['longValue']);
                amountArrayAucUR.push(Number(dataDr2nd[indexAuc][2]['stringValue']))
            } else {
                countArrayAucUR.push(0);
                amountArrayAucUR.push(0)
            }
            // store FIAT
            if (storeFiatUrDateArray.indexOf(date) !== -1 && dataURFiatTrend.length > 0) {
                const indexStore =  storeFiatUrDateArray.indexOf(date);
                countArrayStoreURFiat.push(dataURFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreURFiat.push(Number(dataURFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreURFiat.push(0);
                amountArrayStoreURFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucUR));
        allCountStore.push(_.sum(countArrayStoreUR));
        allDepAuc.push(_.sum(amountArrayAucUR));
        allDepStore.push(_.sum(amountArrayStoreUR));
        allCountStoreFiat.push(_.sum(countArrayStoreURFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreURFiat));

        allDataCountAuction.push(
            {
                id: "auction-ur-count",
                data: countArrayAucUR,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-ur-amount",
                data: amountArrayAucUR,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-ur-count",
                data: countArrayStoreUR,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-ur-amount",
                data: amountArrayStoreUR,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-ur-count-fiat",
                data: countArrayStoreURFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-ur-amount-fiat",
                data: amountArrayStoreURFiat,
                date: dateArray
            }
        );
        // UR end

        // By rarity N begin
        const storeNDateArray = dataDr1st2.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucNDateArray = dataDr2nd2.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatNDateArray = dataNFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreN = [], amountArrayStoreN = [], countArrayStoreNFiat = [], amountArrayStoreNFiat = [], 
        countArrayAucN = [], amountArrayAucN = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeNDateArray.indexOf(date) !== -1) {
                const indexStoreN =  storeNDateArray.indexOf(date);
                countArrayStoreN.push(dataDr1st2[indexStoreN][1]['longValue']);
                amountArrayStoreN.push(Number(dataDr1st2[indexStoreN][2]['stringValue']))
            } else {
                countArrayStoreN.push(0);
                amountArrayStoreN.push(0)
            }
            // auction
            if (aucNDateArray.indexOf(date) !== -1) {
                const indexAucN =  aucNDateArray.indexOf(date);
                countArrayAucN.push(dataDr2nd2[indexAucN][1]['longValue']);
                amountArrayAucN.push(Number(dataDr2nd2[indexAucN][2]['stringValue']))
            } else {
                countArrayAucN.push(0);
                amountArrayAucN.push(0)
            }
            // store FIAT
            if (storeFiatNDateArray.indexOf(date) !== -1 && dataNFiatTrend.length > 0) {
                const indexStore =  storeFiatNDateArray.indexOf(date);
                countArrayStoreNFiat.push(dataNFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreNFiat.push(Number(dataNFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreNFiat.push(0);
                amountArrayStoreNFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucN));
        allCountStore.push(_.sum(countArrayStoreN));
        allDepAuc.push(_.sum(amountArrayAucN));
        allDepStore.push(_.sum(amountArrayStoreN));
        allCountStoreFiat.push(_.sum(countArrayStoreNFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreNFiat));

        allDataCountAuction.push(
            {
                id: "auction-n-count",
                data: countArrayAucN,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-n-amount",
                data: amountArrayAucN,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-n-count",
                data: countArrayStoreN,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-n-amount",
                data: amountArrayStoreN,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-n-count-fiat",
                data: countArrayStoreNFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-n-amount-fiat",
                data: amountArrayStoreNFiat,
                date: dateArray
            }
        );
        // N end

        // By rarity SR begin
        const storeSRDateArray = dataDr1st3.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucSRDateArray = dataDr2nd3.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatSRDateArray = dataSRFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreSR = [], amountArrayStoreSR = [], countArrayStoreSRFiat = [], amountArrayStoreSRFiat = [],
        countArrayAucSR = [], amountArrayAucSR = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeSRDateArray.indexOf(date) !== -1) {
                const indexStoreSR =  storeSRDateArray.indexOf(date);
                countArrayStoreSR.push(dataDr1st3[indexStoreSR][1]['longValue']);
                amountArrayStoreSR.push(Number(dataDr1st3[indexStoreSR][2]['stringValue']))
            } else {
                countArrayStoreSR.push(0);
                amountArrayStoreSR.push(0)
            }
            // auction
            if (aucSRDateArray.indexOf(date) !== -1) {
                const indexAucSR =  aucSRDateArray.indexOf(date);
                countArrayAucSR.push(dataDr2nd3[indexAucSR][1]['longValue']);
                amountArrayAucSR.push(Number(dataDr2nd3[indexAucSR][2]['stringValue']))
            } else {
                countArrayAucSR.push(0);
                amountArrayAucSR.push(0)
            }
            // store FIAT
            if (storeFiatSRDateArray.indexOf(date) !== -1 && dataSRFiatTrend.length > 0) {
                const indexStore =  storeFiatSRDateArray.indexOf(date);
                countArrayStoreSRFiat.push(dataSRFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreSRFiat.push(Number(dataSRFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreSRFiat.push(0);
                amountArrayStoreSRFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucSR));
        allCountStore.push(_.sum(countArrayStoreSR));
        allDepAuc.push(_.sum(amountArrayAucSR));
        allDepStore.push(_.sum(amountArrayStoreSR));
        allCountStoreFiat.push(_.sum(countArrayStoreSRFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreSRFiat));

        allDataCountAuction.push(
            {
                id: "auction-sr-count",
                data: countArrayAucSR,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-sr-amount",
                data: amountArrayAucSR,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-sr-count",
                data: countArrayStoreSR,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-sr-amount",
                data: amountArrayStoreSR,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-sr-count-fiat",
                data: countArrayStoreSRFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-sr-amount-fiat",
                data: amountArrayStoreSRFiat,
                date: dateArray
            }
        );
        // SR end

        // By rarity SSR begin
        const storeSSRDateArray = dataDr1st4.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucSSRDateArray = dataDr2nd4.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatSSRDateArray = dataSSRFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreSSR = [], amountArrayStoreSSR = [], countArrayStoreSSRFiat = [], amountArrayStoreSSRFiat = [],
        countArrayAucSSR = [], amountArrayAucSSR = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeSSRDateArray.indexOf(date) !== -1) {
                const indexStoreSSR =  storeSSRDateArray.indexOf(date);
                countArrayStoreSSR.push(dataDr1st4[indexStoreSSR][1]['longValue']);
                amountArrayStoreSSR.push(Number(dataDr1st4[indexStoreSSR][2]['stringValue']))
            } else {
                countArrayStoreSSR.push(0);
                amountArrayStoreSSR.push(0)
            }
            // auction
            if (aucSSRDateArray.indexOf(date) !== -1) {
                const indexAucSSR =  aucSSRDateArray.indexOf(date);
                countArrayAucSSR.push(dataDr2nd4[indexAucSSR][1]['longValue']);
                amountArrayAucSSR.push(Number(dataDr2nd4[indexAucSSR][2]['stringValue']))
            } else {
                countArrayAucSSR.push(0);
                amountArrayAucSSR.push(0)
            }
            // store FIAT
            if (storeFiatSSRDateArray.indexOf(date) !== -1 && dataSSRFiatTrend.length > 0) {
                const indexStore =  storeFiatSSRDateArray.indexOf(date);
                countArrayStoreSSRFiat.push(dataSSRFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreSSRFiat.push(Number(dataSSRFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreSSRFiat.push(0);
                amountArrayStoreSSRFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucSSR));
        allCountStore.push(_.sum(countArrayStoreSSR));
        allDepAuc.push(_.sum(amountArrayAucSSR));
        allDepStore.push(_.sum(amountArrayStoreSSR));
        allCountStoreFiat.push(_.sum(countArrayStoreSSRFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreSSRFiat));

        allDataCountAuction.push(
            {
                id: "auction-ssr-count",
                data: countArrayAucSSR,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-ssr-amount",
                data: amountArrayAucSSR,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-ssr-count",
                data: countArrayStoreSSR,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-ssr-amount",
                data: amountArrayStoreSSR,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-ssr-count-fiat",
                data: countArrayStoreSSRFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-ssr-amount-fiat",
                data: amountArrayStoreSSRFiat,
                date: dateArray
            }
        );
        // SSR end

        // By rarity R begin
        const storeRDateArray = dataDr1st5.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucRDateArray = dataDr2nd5.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatRDateArray = dataRFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreR = [], amountArrayStoreR = [], countArrayStoreRFiat = [], amountArrayStoreRFiat = [],
        countArrayAucR = [], amountArrayAucR = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeRDateArray.indexOf(date) !== -1) {
                const indexStoreR =  storeRDateArray.indexOf(date);
                countArrayStoreR.push(dataDr1st5[indexStoreR][1]['longValue']);
                amountArrayStoreR.push(Number(dataDr1st5[indexStoreR][2]['stringValue']))
            } else {
                countArrayStoreR.push(0);
                amountArrayStoreR.push(0)
            }
            // auction
            if (aucRDateArray.indexOf(date) !== -1) {
                const indexAucR =  aucRDateArray.indexOf(date);
                countArrayAucR.push(dataDr2nd5[indexAucR][1]['longValue']);
                amountArrayAucR.push(Number(dataDr2nd5[indexAucR][2]['stringValue']))
            } else {
                countArrayAucR.push(0);
                amountArrayAucR.push(0)
            }
            // store FIAT
            if (storeFiatRDateArray.indexOf(date) !== -1 && dataSSRFiatTrend.length > 0) {
                const indexStore =  storeFiatRDateArray.indexOf(date);
                countArrayStoreRFiat.push(dataSSRFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreRFiat.push(Number(dataSSRFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreRFiat.push(0);
                amountArrayStoreRFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucR));
        allCountStore.push(_.sum(countArrayStoreR));
        allDepAuc.push(_.sum(amountArrayAucR));
        allDepStore.push(_.sum(amountArrayStoreR));
        allCountStoreFiat.push(_.sum(countArrayStoreRFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreRFiat));

        allDataCountAuction.push(
            {
                id: "auction-r-count",
                data: countArrayAucR,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-r-amount",
                data: amountArrayAucR,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-r-count",
                data: countArrayStoreR,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-r-amount",
                data: amountArrayStoreR,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-r-count-fiat",
                data: countArrayStoreRFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-r-amount-fiat",
                data: amountArrayStoreRFiat,
                date: dateArray
            }
        );
        // R end

        // all store
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(countArrayStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(amountArrayStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(_.sum(countUniArrayStore))
        };
        yield put(setDragonRamenNftTrendDataStoreAll(dataAllStore));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniqueCount1stRangeFiat[0][0]['longValue'])
        };
        yield put(setDragonRamenNftTrendDataStoreAllFiat(dataAllStoreFiat));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(countArray)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(amountArray)),
            unique: H.FormatNumStrHelper.formatCommaThousand(_.sum(countUniArrayAuc))
        };
        yield put(setDragonRamenNftTrendDataAuctionAll(dataAllAuction));

        yield put(setDragonRamenNftTrendDataCountAuction(allDataCountAuction));
        yield put(setDragonRamenNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setDragonRamenNftTrendDataCountStore(allDataCountStore));
        yield put(setDragonRamenNftTrendDataAmountStore(allDataAmountStore));
        yield put(setDragonRamenNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setDragonRamenNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setDragonRamenNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}


export function* getKamuiVerseNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setKamuiVerseNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(kamuiVerseNftTrendDateRange);

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        // const sourceAucStoreCb = H.ElasticSearch.getNftTrendDataByGame(rangeTarget, "g3bq4ssqNmgS8yiQWuba");
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuh0002l5rwf0eeezoz', range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuh0002l5rwf0eeezoz', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahuh0002l5rwf0eeezoz', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahuh0002l5rwf0eeezoz', range: [rangeStart, rangeEnd]});

        const [
            dataKv1stTemp,
            dataKv2ndTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataKvAll1st = JSON.parse(dataKv1stTemp.body);
        const dataKvAll2nd = JSON.parse(dataKv2ndTemp.body);
        const dataUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        const storeDateArray = dataKvAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataKvAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let countUniArrayStore = [], countUniArrayAuc = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];
        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataKvAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataKvAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataKvAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataKvAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }
        });
        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );

        allDataCountAuction.push(
            {
                id: "auction-kamuiverse-count",
                data: countArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-kamuiverse-amount",
                data: amountArray,
                date: dateArray
            }
        );

        // store
        allDataCountStore.push(
            {
                id: "store-kamuiverse-count",
                data: countArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-kamuiverse-amount",
                data: amountArrayStore,
                date: dateArray
            }
        );

        yield put(setKamuiVerseNftTrendDataCountAuction(allDataCountAuction));
        yield put(setKamuiVerseNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setKamuiVerseNftTrendDataCountStore(allDataCountStore));
        yield put(setKamuiVerseNftTrendDataAmountStore(allDataAmountStore));
        yield put(setPageTopLoader(false));
        yield put(setKamuiVerseNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getGraffitiRacerNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setGraffitiRacerNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(graffitiRacerNftTrendDateRange);
        const allRarity = ["RARITY-GRAFFITI_SSS","RARITY-GRAFFITI_SS","RARITY-GRAFFITI_S","RARITY-GRAFFITI_A","RARITY-GRAFFITI_B","RARITY-GRAFFITI_C"];
        const sourceAllRarirty = H.ElasticSearch.getJtcbNftTrendDataByRare(rangeTarget, allRarity);
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        // const sqlQueryGetTrade1stByRarity = H.SqlHelper.getNftTransactonByGameAndRarity({symbol: 'DAT', tradeType: 'TRADE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: allRarity, range: [rangeStart, rangeEnd]});
        // const sqlQueryGetTrade2ndByRarity = H.SqlHelper.getNftTransactonByGameAndRarity({symbol: 'DAT', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: allRarity, range: [rangeStart, rangeEnd]});

        const sqlQueryGet1stRaritySSS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_SSS', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRaritySSS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_SSS', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRaritySS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_SS', range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRaritySS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_SS', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_S", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_S", range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityA = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_A", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityA = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_A", range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityB = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_B", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityB = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_B", range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stRarityC = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_C", range: [rangeStart, rangeEnd]});
        const sqlQueryGet2ndRarityC = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: "RARITY-GRAFFITI_C", range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'cl8jxahul0005l5rwa9bb6til', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2ndRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'cl8jxahul0005l5rwa9bb6til', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiatSSS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_SSS', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatSS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_SS', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatS = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_S', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatA = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_A', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatB = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_B', range: [rangeStart, rangeEnd]});
        const sqlQueryGet1stFiatC = H.SqlHelper.getNftTransactonByGameAndRarityBIT({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', rarity: 'RARITY-GRAFFITI_C', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahul0005l5rwa9bb6til', range: [rangeStart, rangeEnd]});

        const [
            dataSSSTrendStoreTemp,
            dataSSTrendStoreTemp,
            dataSTrendStoreTemp,
            dataATrendStoreTemp,
            dataBTrendStoreTemp,
            dataCTrendStoreTemp,
            dataSSSTrendTemp,
            dataSSTrendTemp,
            dataSTrendTemp,
            dataATrendTemp,
            dataBTrendTemp,
            dataCTrendTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataUniqueCount1stRangeTemp,
            dataUniqueCount2ndRangeTemp,
            dataSSSFiatTrendTemp,
            dataSSFiatTrendTemp,
            dataSFiatTrendTemp,
            dataAFiatTrendTemp,
            dataBFiatTrendTemp,
            dataCFiatTrendTemp,
            dataUniCnt1stFiatTemp,
            dataUniqueCount1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGet1stRaritySSS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRaritySS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityA, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityB, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stRarityC, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRaritySSS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRaritySS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityA, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityB, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet2ndRarityC, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2ndRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatSSS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatSS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatS, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatA, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatB, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiatC, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        // rarity result
        const dataSSSTrend1st = JSON.parse(dataSSSTrendStoreTemp.body);
        const dataSSTrend1st = JSON.parse(dataSSTrendStoreTemp.body);
        const dataSTrend1st = JSON.parse(dataSTrendStoreTemp.body);
        const dataATrend1st = JSON.parse(dataATrendStoreTemp.body);
        const dataBTrend1st = JSON.parse(dataBTrendStoreTemp.body);
        const dataCTrend1st = JSON.parse(dataCTrendStoreTemp.body);
        const dataSSSTrend2nd = JSON.parse(dataSSSTrendTemp.body);
        const dataSSTrend2nd = JSON.parse(dataSSTrendTemp.body);
        const dataSTrend2nd = JSON.parse(dataSTrendTemp.body);
        const dataATrend2nd = JSON.parse(dataATrendTemp.body);
        const dataBTrend2nd = JSON.parse(dataBTrendTemp.body);
        const dataCTrend2nd = JSON.parse(dataCTrendTemp.body);
        const dataDrUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataDrUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);
        const dataUniCntRange1st = JSON.parse(dataUniqueCount1stRangeTemp.body);
        const dataUniCntRange2nd = JSON.parse(dataUniqueCount2ndRangeTemp.body);

        // FITA result
        const dataSSSFiatTrend = JSON.parse(dataSSSFiatTrendTemp.body);
        const dataSSFiatTrend = JSON.parse(dataSSFiatTrendTemp.body);
        const dataSFiatTrend = JSON.parse(dataSFiatTrendTemp.body);
        const dataAFiatTrend = JSON.parse(dataAFiatTrendTemp.body);
        const dataBFiatTrend = JSON.parse(dataBFiatTrendTemp.body);
        const dataCFiatTrend = JSON.parse(dataCFiatTrendTemp.body);
        const dataUniCnt1stFiat = JSON.parse(dataUniCnt1stFiatTemp.body);
        const dataUniqueCount1stRangeFiat = JSON.parse(dataUniqueCount1stRangeFiatTemp.body);        

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];

        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];

        const storeUniDateArray = dataDrUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataDrUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        // FIAT
        const storeFiatUniDateArray = dataUniCnt1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        _.forEach(dateArray, function(date, i) {
            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataDrUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataDrUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataUniCnt1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });
        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStoreFiat,
                date: dateArray
            }
        );

        // sss begin
        const storeSRDateArray = dataSSSTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucSRDateArray = dataSSSTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatSSSDateArray = dataSSSFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreSSS = [], amountArrayStoreSSS = [], countArrayAucSSS = [], amountArrayAucSSS = [];
        let countArrayStoreSSSFiat = [], amountArrayStoreSSSFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeSRDateArray.indexOf(date) !== -1) {
                const indexStoreSR =  storeSRDateArray.indexOf(date);
                countArrayStoreSSS.push(dataSSSTrend1st[indexStoreSR][1]['longValue']);
                amountArrayStoreSSS.push(Number(dataSSSTrend1st[indexStoreSR][2]['stringValue']))
            } else {
                countArrayStoreSSS.push(0);
                amountArrayStoreSSS.push(0)
            }
            // auction
            if (aucSRDateArray.indexOf(date) !== -1) {
                const indexAucSR =  aucSRDateArray.indexOf(date);
                countArrayAucSSS.push(dataSSSTrend2nd[indexAucSR][1]['longValue']);
                amountArrayAucSSS.push(Number(dataSSSTrend2nd[indexAucSR][2]['stringValue']))
            } else {
                countArrayAucSSS.push(0);
                amountArrayAucSSS.push(0)
            }
            // store FIAT
            if (storeFiatSSSDateArray.indexOf(date) !== -1 && dataSSSFiatTrend.length > 0) {
                const indexStore =  storeFiatSSSDateArray.indexOf(date);
                countArrayStoreSSSFiat.push(dataSSSFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreSSSFiat.push(Number(dataSSSFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreSSSFiat.push(0);
                amountArrayStoreSSSFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucSSS));
        allCountStore.push(_.sum(countArrayStoreSSS));
        allDepAuc.push(_.sum(amountArrayAucSSS));
        allDepStore.push(_.sum(amountArrayStoreSSS));
        allCountStoreFiat.push(_.sum(countArrayStoreSSSFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreSSSFiat));
        
        allDataCountAuction.push(
            {
                id: "auction-sss-count",
                data: countArrayAucSSS,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-sss-amount",
                data: amountArrayAucSSS,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-sss-count",
                data: countArrayStoreSSS,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-sss-amount",
                data: amountArrayStoreSSS,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-sss-count",
                data: countArrayStoreSSSFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-sss-amount-fiat",
                data: amountArrayStoreSSSFiat,
                date: dateArray
            }
        );
        // sss end

        // ss begin
        const storeSSDateArray = dataSSTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucSSDateArray = dataSSTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatSSDateArray = dataSSFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreSS = [], amountArrayStoreSS = [], countArrayAucSS = [], amountArrayAucSS = [];
        let countArrayStoreSSFiat = [], amountArrayStoreSSFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeSSDateArray.indexOf(date) !== -1) {
                const indexStoreSS =  storeSSDateArray.indexOf(date);
                countArrayStoreSS.push(dataSSTrend1st[indexStoreSS][1]['longValue']);
                amountArrayStoreSS.push(Number(dataSSTrend1st[indexStoreSS][2]['stringValue']))
            } else {
                countArrayStoreSS.push(0);
                amountArrayStoreSS.push(0)
            }
            // auction
            if (aucSSDateArray.indexOf(date) !== -1) {
                const indexAucSS =  aucSSDateArray.indexOf(date);
                countArrayAucSS.push(dataSSTrend2nd[indexAucSS][1]['longValue']);
                amountArrayAucSS.push(Number(dataSSTrend2nd[indexAucSS][2]['stringValue']))
            } else {
                countArrayAucSS.push(0);
                amountArrayAucSS.push(0)
            }
            // store FIAT
            if (storeFiatSSDateArray.indexOf(date) !== -1 && dataSSFiatTrend.length > 0) {
                const indexStore =  storeFiatSSDateArray.indexOf(date);
                countArrayStoreSSFiat.push(dataSSFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreSSFiat.push(Number(dataSSFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreSSFiat.push(0);
                amountArrayStoreSSFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucSS));
        allCountStore.push(_.sum(countArrayStoreSS));
        allDepAuc.push(_.sum(amountArrayAucSS));
        allDepStore.push(_.sum(amountArrayStoreSS));
        allCountStoreFiat.push(_.sum(countArrayStoreSSFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreSSFiat));

        allDataCountAuction.push(
            {
                id: "auction-ss-count",
                data: countArrayAucSS,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-ss-amount",
                data: amountArrayAucSS,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-ss-count",
                data: countArrayStoreSS,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-ss-amount",
                data: amountArrayStoreSS,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-ss-count",
                data: countArrayStoreSSFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-ss-amount-fiat",
                data: amountArrayStoreSSFiat,
                date: dateArray
            }
        );
        // ss end

        // s begin
        const storeSDateArray = dataSTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucSDateArray = dataSTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatSDateArray = dataSFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreS = [], amountArrayStoreS = [], countArrayAucS = [], amountArrayAucS = [];
        let countArrayStoreSFiat = [], amountArrayStoreSFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeSDateArray.indexOf(date) !== -1) {
                const indexStoreS =  storeSDateArray.indexOf(date);
                countArrayStoreS.push(dataSTrend1st[indexStoreS][1]['longValue']);
                amountArrayStoreS.push(Number(dataSTrend1st[indexStoreS][2]['stringValue']))
            } else {
                countArrayStoreS.push(0);
                amountArrayStoreS.push(0)
            }
            // auction
            if (aucSDateArray.indexOf(date) !== -1) {
                const indexAucS =  aucSDateArray.indexOf(date);
                countArrayAucS.push(dataSTrend2nd[indexAucS][1]['longValue']);
                amountArrayAucS.push(Number(dataSTrend2nd[indexAucS][2]['stringValue']))
            } else {
                countArrayAucS.push(0);
                amountArrayAucS.push(0)
            }
            // store FIAT
            if (storeFiatSDateArray.indexOf(date) !== -1 && dataSFiatTrend.length > 0) {
                const indexStore =  storeFiatSDateArray.indexOf(date);
                countArrayStoreSFiat.push(dataSFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreSFiat.push(Number(dataSFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreSFiat.push(0);
                amountArrayStoreSFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucS));
        allCountStore.push(_.sum(countArrayStoreS));
        allDepAuc.push(_.sum(amountArrayAucS));
        allDepStore.push(_.sum(amountArrayStoreS));
        allCountStoreFiat.push(_.sum(countArrayStoreSFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreSFiat));

        allDataCountAuction.push(
            {
                id: "auction-s-count",
                data: countArrayAucS,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-s-amount",
                data: amountArrayAucS,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-s-count",
                data: countArrayStoreS,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-s-amount",
                data: amountArrayStoreS,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-s-count",
                data: countArrayStoreSFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-s-amount-fiat",
                data: amountArrayStoreSFiat,
                date: dateArray
            }
        );
        // s end

        // A begin
        const storeADateArray = dataATrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucADateArray = dataATrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatADateArray = dataAFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreA = [], amountArrayStoreA = [], countArrayAucA = [], amountArrayAucA = [];
        let countArrayStoreAFiat = [], amountArrayStoreAFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeADateArray.indexOf(date) !== -1) {
                const indexStoreA =  storeADateArray.indexOf(date);
                countArrayStoreA.push(dataATrend1st[indexStoreA][1]['longValue']);
                amountArrayStoreA.push(Number(dataATrend1st[indexStoreA][2]['stringValue']))
            } else {
                countArrayStoreA.push(0);
                amountArrayStoreA.push(0)
            }
            // auction
            if (aucADateArray.indexOf(date) !== -1) {
                const indexAucA =  aucADateArray.indexOf(date);
                countArrayAucA.push(dataATrend2nd[indexAucA][1]['longValue']);
                amountArrayAucA.push(Number(dataATrend2nd[indexAucA][2]['stringValue']))
            } else {
                countArrayAucA.push(0);
                amountArrayAucA.push(0)
            }
            // store FIAT
            if (storeFiatADateArray.indexOf(date) !== -1 && dataAFiatTrend.length > 0) {
                const indexStore =  storeFiatADateArray.indexOf(date);
                countArrayStoreAFiat.push(dataAFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreAFiat.push(Number(dataAFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreAFiat.push(0);
                amountArrayStoreAFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucA));
        allCountStore.push(_.sum(countArrayStoreA));
        allDepAuc.push(_.sum(amountArrayAucA));
        allDepStore.push(_.sum(amountArrayStoreA));
        allCountStoreFiat.push(_.sum(countArrayStoreAFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreAFiat));

        allDataCountAuction.push(
            {
                id: "auction-a-count",
                data: countArrayAucA,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-a-amount",
                data: amountArrayAucA,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-a-count",
                data: countArrayStoreA,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-a-amount",
                data: amountArrayStoreA,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-a-count",
                data: countArrayStoreAFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-a-amount-fiat",
                data: amountArrayStoreAFiat,
                date: dateArray
            }
        );
        // A end

        // B begin
        const storeBDateArray = dataBTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucBDateArray = dataBTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatBDateArray = dataBFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreB = [], amountArrayStoreB = [], countArrayAucB = [], amountArrayAucB = [];
        let countArrayStoreBFiat = [], amountArrayStoreBFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeBDateArray.indexOf(date) !== -1) {
                const indexStoreB =  storeBDateArray.indexOf(date);
                countArrayStoreB.push(dataBTrend1st[indexStoreB][1]['longValue']);
                amountArrayStoreB.push(Number(dataBTrend1st[indexStoreB][2]['stringValue']))
            } else {
                countArrayStoreB.push(0);
                amountArrayStoreB.push(0)
            }
            // auction
            if (aucBDateArray.indexOf(date) !== -1) {
                const indexAucB =  aucBDateArray.indexOf(date);
                countArrayAucB.push(dataBTrend2nd[indexAucB][1]['longValue']);
                amountArrayAucB.push(Number(dataBTrend2nd[indexAucB][2]['stringValue']))
            } else {
                countArrayAucB.push(0);
                amountArrayAucB.push(0)
            }
            // store FIAT
            if (storeFiatBDateArray.indexOf(date) !== -1 && dataBFiatTrend.length > 0) {
                const indexStore =  storeFiatBDateArray.indexOf(date);
                countArrayStoreBFiat.push(dataBFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreBFiat.push(Number(dataBFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreBFiat.push(0);
                amountArrayStoreBFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucB));
        allCountStore.push(_.sum(countArrayStoreB));
        allDepAuc.push(_.sum(amountArrayAucB));
        allDepStore.push(_.sum(amountArrayStoreB));
        allCountStoreFiat.push(_.sum(countArrayStoreBFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreBFiat));

        allDataCountAuction.push(
            {
                id: "auction-b-count",
                data: countArrayAucB,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-b-amount",
                data: amountArrayAucB,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-b-count",
                data: countArrayStoreB,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-b-amount",
                data: amountArrayStoreB,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-b-count",
                data: countArrayStoreBFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-b-amount-fiat",
                data: amountArrayStoreBFiat,
                date: dateArray
            }
        );
        // B end

        // C begin
        const storeCDateArray = dataCTrend1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucCDateArray = dataCTrend2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatCDateArray = dataCFiatTrend.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let countArrayStoreC = [], amountArrayStoreC = [], countArrayAucC = [], amountArrayAucC = [];
        let countArrayStoreCFiat = [], amountArrayStoreCFiat = [];
        _.forEach(dateArray, function(date, i) {
            // store
            if (storeCDateArray.indexOf(date) !== -1) {
                const indexStoreC =  storeCDateArray.indexOf(date);
                countArrayStoreC.push(dataBTrend1st[indexStoreC][1]['longValue']);
                amountArrayStoreC.push(Number(dataBTrend1st[indexStoreC][2]['stringValue']))
            } else {
                countArrayStoreC.push(0);
                amountArrayStoreC.push(0)
            }
            // auction
            if (aucCDateArray.indexOf(date) !== -1) {
                const indexAucC =  aucCDateArray.indexOf(date);
                countArrayAucC.push(dataCTrend2nd[indexAucC][1]['longValue']);
                amountArrayAucC.push(Number(dataCTrend2nd[indexAucC][2]['stringValue']))
            } else {
                countArrayAucC.push(0);
                amountArrayAucC.push(0)
            }
            // store FIAT
            if (storeFiatCDateArray.indexOf(date) !== -1 && dataCFiatTrend.length > 0) {
                const indexStore =  storeFiatCDateArray.indexOf(date);
                countArrayStoreCFiat.push(dataCFiatTrend[indexStore][1]['longValue']);
                amountArrayStoreCFiat.push(Number(dataCFiatTrend[indexStore][2]['stringValue']))
            } else {
                countArrayStoreCFiat.push(0);
                amountArrayStoreCFiat.push(0)
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArrayAucC));
        allCountStore.push(_.sum(countArrayStoreC));
        allDepAuc.push(_.sum(amountArrayAucC));
        allDepStore.push(_.sum(amountArrayStoreC));
        allCountStoreFiat.push(_.sum(countArrayStoreCFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreCFiat));

        allDataCountAuction.push(
            {
                id: "auction-c-count",
                data: countArrayAucC,
                date: dateArray
            }
        );
        allDataAmountAuction.push(
            {
                id: "auction-c-amount",
                data: amountArrayAucC,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-c-count",
                data: countArrayStoreC,
                date: dateArray
            }
        );
        allDataAmountStore.push(
            {
                id: "store-c-amount",
                data: amountArrayStoreC,
                date: dateArray
            }
        );
        allDataCountStoreFiat.push(
            {
                id: "store-c-count",
                data: countArrayStoreCFiat,
                date: dateArray
            }
        );
        allDataAmountStoreFiat.push(
            {
                id: "store-c-amount-fiat",
                data: countArrayStoreCFiat,
                date: dateArray
            }
        );
        // C end

        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1st[0][0]['longValue'])
        };
        yield put(setGraffitiRacerNftTrendDataStoreAll(dataAllStore));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniqueCount1stRangeFiat[0][0]['longValue'])
        };
        yield put(setGraffitiRacerNftTrendDataStoreAllFiat(dataAllStoreFiat));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange2nd[0][0]['longValue'])
        };
        yield put(setGraffitiRacerNftTrendDataAuctionAll(dataAllAuction));

        yield put(setGraffitiRacerNftTrendDataCountAuction(allDataCountAuction));
        yield put(setGraffitiRacerNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setGraffitiRacerNftTrendDataCountStore(allDataCountStore));
        yield put(setGraffitiRacerNftTrendDataAmountStore(allDataAmountStore));
        yield put(setGraffitiRacerNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setGraffitiRacerNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setGraffitiRacerNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getLuckyFarmerNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setLuckyFarmerNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(luckyFarmerNftTrendDateRange);

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${rangeTarget[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactionByGame3({table: "SxiNftSalesProd1stV2", tradeType: 'REVENUE_1ST', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactionByGame3({table: "SxiNftSalesProd2ndV1", tradeType: 'TRADE_2ND', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: "SxiNftSalesProd1stV2", tradeType: 'REVENUE_1ST', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: "SxiNftSalesProd2ndV1",tradeType: 'TRADE_2ND', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: "SxiNftSalesProd1stV2", tradeType: 'REVENUE_1ST', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2ndRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: "SxiNftSalesProd2ndV1", tradeType: 'TRADE_2ND', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiat = H.SqlHelper.getNftTransactonByGameAndAllRarity({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cl8jxahtw0000l5rwh6ncf2uw', range: [rangeStart, rangeEnd]});

        const [
            dataLf1stTemp,
            dataLf2ndTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataUniqueCount1stRangeTemp,
            dataUniqueCount2ndRangeTemp,
            dataGet1stFiatTemp,
            dataNftCountUnique1stFiatTemp,
            dataNftCountUnique1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2ndRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const dataLfAll1st = JSON.parse(dataLf1stTemp.body);
        const dataLfAll2nd = JSON.parse(dataLf2ndTemp.body);
        const dataUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);
        const dataUniCntRange1st = JSON.parse(dataUniqueCount1stRangeTemp.body);
        const dataUniCntRange2nd = JSON.parse(dataUniqueCount2ndRangeTemp.body);
        const dataGet1stFiat = JSON.parse(dataGet1stFiatTemp.body);
        const dataNftCountUnique1stFiat = JSON.parse(dataNftCountUnique1stFiatTemp.body);
        const dataNftCountUnique1stRangeFiat = JSON.parse(dataNftCountUnique1stRangeFiatTemp.body);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        const storeDateArray = dataLfAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataLfAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        // FIAT
        const storeFiatDateArray = dataGet1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        const storeFiatUniDateArray = dataNftCountUnique1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];
        let countArrayStoreFiat = [], amountArrayStoreFiat = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];
        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];
        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataLfAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataLfAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataLfAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataLfAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // store FIAT
            if (storeFiatDateArray.indexOf(date) !== -1 && dataGet1stFiat.length > 0) {
                const indexStore =  storeFiatDateArray.indexOf(date);
                countArrayStoreFiat.push(dataGet1stFiat[indexStore][1]['longValue']);
                amountArrayStoreFiat.push(Number(dataGet1stFiat[indexStore][2]['stringValue']))
            } else {
                countArrayStoreFiat.push(0);
                amountArrayStoreFiat.push(0)
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataNftCountUnique1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArray));
        allCountStore.push(_.sum(countArrayStore));
        allDepAuc.push(_.sum(amountArray));
        allDepStore.push(_.sum(amountArrayStore));
        allCountStoreFiat.push(_.sum(countArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreFiat));

        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );

        allDataCountAuction.push(
            {
                id: "auction-cookinburger-count",
                data: countArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-cookinburger-amount",
                data: amountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-cookinburger-count",
                data: countArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-cookinburger-amount",
                data: amountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-cookinburger-count-fiat",
                data: countArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-cookinburger-amount-fiat",
                data: amountArrayStoreFiat,
                date: dateArray
            }
        );

        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1st[0][0]['longValue'])
        };
        yield put(setLuckyFarmerNftTrendStoreAll(dataAllStore));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange2nd[0][0]['longValue'])
        };
        yield put(setLuckyFarmerNftTrendAuctionAll(dataAllAuction));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataNftCountUnique1stRangeFiat[0][0]['longValue'])
        };
        yield put(setLuckyFarmerNftTrendStoreAllFiat(dataAllStoreFiat));

        yield put(setLuckyFarmerNftTrendDataCountAuction(allDataCountAuction));
        yield put(setLuckyFarmerNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setLuckyFarmerNftTrendDataCountStore(allDataCountStore));
        yield put(setLuckyFarmerNftTrendDataAmountStore(allDataAmountStore));
        yield put(setLuckyFarmerNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setLuckyFarmerNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setLuckyFarmerNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getLostArchiveNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setLostArchiveNftTrendDataLoading(true));
        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(lostArchiveNftTrendDateRange);
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        // cleo05zpl003sagpjfe3q5n6l
        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactionByGame3({table: "SxiNftSalesProd1stV2", tradeType: 'REVENUE_1ST', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactionByGame3({table: "SxiNftSalesProd2ndV1", tradeType: 'TRADE_2ND', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: "SxiNftSalesProd1stV2", tradeType: 'REVENUE_1ST', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: "SxiNftSalesProd2ndV1",tradeType: 'TRADE_2ND', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: "SxiNftSalesProd1stV2", tradeType: 'REVENUE_1ST', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2ndRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: "SxiNftSalesProd2ndV1", tradeType: 'TRADE_2ND', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiat = H.SqlHelper.getNftTransactonByGameAndAllRarity({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'cleo05zpl003sagpjfe3q5n6l', range: [rangeStart, rangeEnd]});

        const [
            dataLf1stTemp,
            dataLf2ndTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataUniqueCount1stRangeTemp,
            dataUniqueCount2ndRangeTemp,
            dataGet1stFiatTemp,
            dataNftCountUnique1stFiatTemp,
            dataNftCountUnique1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2ndRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const dataLfAll1st = JSON.parse(dataLf1stTemp.body);
        const dataLfAll2nd = JSON.parse(dataLf2ndTemp.body);
        const dataUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);
        const dataUniCntRange1st = JSON.parse(dataUniqueCount1stRangeTemp.body);
        const dataUniCntRange2nd = JSON.parse(dataUniqueCount2ndRangeTemp.body);
        const dataGet1stFiat = JSON.parse(dataGet1stFiatTemp.body);
        const dataNftCountUnique1stFiat = JSON.parse(dataNftCountUnique1stFiatTemp.body);
        const dataNftCountUnique1stRangeFiat = JSON.parse(dataNftCountUnique1stRangeFiatTemp.body);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        const storeDateArray = dataLfAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataLfAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        // FIAT
        const storeFiatDateArray = dataGet1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        const storeFiatUniDateArray = dataNftCountUnique1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];
        let countArrayStoreFiat = [], amountArrayStoreFiat = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];
        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];
        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataLfAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataLfAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataLfAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataLfAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // store FIAT
            if (storeFiatDateArray.indexOf(date) !== -1 && dataGet1stFiat.length > 0) {
                const indexStore =  storeFiatDateArray.indexOf(date);
                countArrayStoreFiat.push(dataGet1stFiat[indexStore][1]['longValue']);
                amountArrayStoreFiat.push(Number(dataGet1stFiat[indexStore][2]['stringValue']))
            } else {
                countArrayStoreFiat.push(0);
                amountArrayStoreFiat.push(0)
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataNftCountUnique1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArray));
        allCountStore.push(_.sum(countArrayStore));
        allDepAuc.push(_.sum(amountArray));
        allDepStore.push(_.sum(amountArrayStore));
        allCountStoreFiat.push(_.sum(countArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreFiat));

        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );

        allDataCountAuction.push(
            {
                id: "auction-cookinburger-count",
                data: countArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-cookinburger-amount",
                data: amountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-cookinburger-count",
                data: countArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-cookinburger-amount",
                data: amountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-cookinburger-count-fiat",
                data: countArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-cookinburger-amount-fiat",
                data: amountArrayStoreFiat,
                date: dateArray
            }
        );

        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1st[0][0]['longValue'])
        };
        yield put(setLostArchiveNftTrendStoreAll(dataAllStore));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange2nd[0][0]['longValue'])
        };
        yield put(setLostArchiveNftTrendAuctionAll(dataAllAuction));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataNftCountUnique1stRangeFiat[0][0]['longValue'])
        };
        yield put(setLostArchiveNftTrendStoreAllFiat(dataAllStoreFiat));

        yield put(setLostArchiveNftTrendDataCountAuction(allDataCountAuction));
        yield put(setLostArchiveNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setLostArchiveNftTrendDataCountStore(allDataCountStore));
        yield put(setLostArchiveNftTrendDataAmountStore(allDataAmountStore));
        yield put(setLostArchiveNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setLostArchiveNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setLostArchiveNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getSoulFusersNftTrendData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setSoulFusersNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(soulFusersNftTrendDateRange);

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;

        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1st = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2nd = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique2ndRange = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiat = H.SqlHelper.getNftTransactonByGameAndAllRarity({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByDate({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const sqlQueryNftCountUnique1stRangeFiat = H.SqlHelper.getNftTransactionByGameAndUniqueByRange({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: 'clbk5yhj700c9inmt7ysd3g0e', range: [rangeStart, rangeEnd]});
        const [
            data1stTemp,
            data2ndTemp,
            dataUniqueCount1stTemp,
            dataUniqueCount2ndTemp,
            dataUniqueCount1stRangeTemp,
            dataUniqueCount2ndRangeTemp,
            dataGet1stFiatTemp,
            dataNftCountUnique1stFiatTemp,
            dataNftCountUnique1stRangeFiatTemp
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique2ndRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryNftCountUnique1stRangeFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataAll1st = JSON.parse(data1stTemp.body);
        const dataAll2nd = JSON.parse(data2ndTemp.body);
        const dataUniCnt1st = JSON.parse(dataUniqueCount1stTemp.body);
        const dataUniCnt2nd = JSON.parse(dataUniqueCount2ndTemp.body);
        const dataUniCntRange1st = JSON.parse(dataUniqueCount1stRangeTemp.body);
        const dataUniCntRange2nd = JSON.parse(dataUniqueCount2ndRangeTemp.body);
        const dataGet1stFiat = JSON.parse(dataGet1stFiatTemp.body);
        const dataNftCountUnique1stFiat = JSON.parse(dataNftCountUnique1stFiatTemp.body);
        const dataNftCountUnique1stRangeFiat = JSON.parse(dataNftCountUnique1stRangeFiatTemp.body);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        const storeDateArray = dataAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataUniCnt1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataUniCnt2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        // FIAT
        const storeFiatDateArray = dataGet1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        const storeFiatUniDateArray = dataNftCountUnique1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];
        let countArrayStoreFiat = [], amountArrayStoreFiat = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];
        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];
        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataUniCnt2nd[indexAucUni][1]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataUniCnt1st[indexStoreUni][1]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // store FIAT
            if (storeFiatDateArray.indexOf(date) !== -1 && dataGet1stFiat.length > 0) {
                const indexStore =  storeFiatDateArray.indexOf(date);
                countArrayStoreFiat.push(dataGet1stFiat[indexStore][1]['longValue']);
                amountArrayStoreFiat.push(Number(dataGet1stFiat[indexStore][2]['stringValue']))
            } else {
                countArrayStoreFiat.push(0);
                amountArrayStoreFiat.push(0)
            }

            // unique store FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataNftCountUnique1stFiat[indexStoreFiat][1]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArray));
        allCountStore.push(_.sum(countArrayStore));
        allDepAuc.push(_.sum(amountArray));
        allDepStore.push(_.sum(amountArrayStore));
        allCountStoreFiat.push(_.sum(countArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreFiat));

        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );
        allDataCountAuction.push(
            {
                id: "auction-soulfusers-count",
                data: countArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-soulfusers-amount",
                data: amountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-soulfusers-count",
                data: countArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-soulfusers-amount",
                data: amountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-soulfusers-count-fiat",
                data: countArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-soulfusers-amount-fiat",
                data: amountArrayStoreFiat,
                date: dateArray
            }
        );

        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange1st[0][0]['longValue'])
        };
        yield put(setSoulFusersNftTrendStoreAll(dataAllStore));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataUniCntRange2nd[0][0]['longValue'])
        };
        yield put(setSoulFusersNftTrendAuctionAll(dataAllAuction));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(dataNftCountUnique1stRangeFiat[0][0]['longValue'])
        };
        yield put(setSoulFusersNftTrendStoreAllFiat(dataAllStoreFiat));

        yield put(setSoulFusersNftTrendDataCountAuction(allDataCountAuction));
        yield put(setSoulFusersNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setSoulFusersNftTrendDataCountStore(allDataCountStore));
        yield put(setSoulFusersNftTrendDataAmountStore(allDataAmountStore));
        yield put(setSoulFusersNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        //yield put(setSoulFusersNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setSoulFusersNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getGameNftTrendData(action) {
    try {
        const gameTarget = action.payload;
        yield put(setPageTopLoader(true));
        yield put(setGameNftTrendDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(gameNftTrendDateRange);

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        const rangeStart = `${rangeTarget[0]}T00:00:00.000Z`;
        const rangeEnd = `${rangeTarget[1]}T23:59:59.999Z`;
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        const sqlQueryGetTrade1st = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd1stV2', tradeType: 'REVENUE_1ST', contentId: contentId, range: [rangeStart, rangeEnd]});
        const sqlQueryGetTrade2nd = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProd2ndV1', tradeType: 'TRADE_2ND', contentId: contentId, range: [rangeStart, rangeEnd]});

        // FIAT
        const sqlQueryGet1stFiat = H.SqlHelper.getNftTransactionByGame3({table: 'SxiNftSalesProdFiatV1', tradeType: 'CREDIT_PAYMENT_MARKET', contentId: contentId, range: [rangeStart, rangeEnd]});

        const [
            data1stTemp,
            data2ndTemp,
            dataGet1stFiatTemp,
        ] = yield all([
            call(getDataFromRDS, sqlQueryGetTrade1st, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetTrade2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGet1stFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataAll1st = JSON.parse(data1stTemp.body);
        const dataAll2nd = JSON.parse(data2ndTemp.body);
        const dataGet1stFiat = JSON.parse(dataGet1stFiatTemp.body);

        // calendar date range
        const range = moment.range(rangeTarget[0], rangeTarget[1]);
        const days = Array.from(range.by('days'));
        const dateArray = days.map(m => m.format('YYYY-MM-DD'));

        const storeDateArray = dataAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucDateArray = dataAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeUniDateArray = dataAll1st.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const aucUniDateArray = dataAll2nd.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        // FIAT
        const storeFiatDateArray = dataGet1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });
        const storeFiatUniDateArray = dataGet1stFiat.map(function(item, i) {
            return item[0]['stringValue']; 
        });

        let allDataCountAuction = [], allDataAmountAuction = [];
        let allDataCountStore = [], allDataAmountStore = [];
        let allDataCountStoreFiat = [], allDataAmountStoreFiat = [];

        let countArray = [], amountArray = [];
        let countArrayStore = [], amountArrayStore = [];
        let countArrayStoreFiat = [], amountArrayStoreFiat = [];
        let countUniArrayStore = [], countUniArrayAuc = [], countUniArrayStoreFiat = [];
        let allCountAuc = [], allCountStore = [], allCountStoreFiat = [];
        let allDepAuc = [], allDepStore = [], allUsdStoreFiat = [];
        _.forEach(dateArray, function(date, i) {
            // auction
            if (aucDateArray.indexOf(date) !== -1) {
                const indexAuc =  aucDateArray.indexOf(date);
                countArray.push(dataAll2nd[indexAuc][1]['longValue']);
                amountArray.push(Number(dataAll2nd[indexAuc][2]['stringValue']))
            } else {
                countArray.push(0);
                amountArray.push(0)
            }

            // store
            if (storeDateArray.indexOf(date) !== -1) {
                const indexStore =  storeDateArray.indexOf(date);
                countArrayStore.push(dataAll1st[indexStore][1]['longValue']);
                amountArrayStore.push(Number(dataAll1st[indexStore][2]['stringValue']))
            } else {
                countArrayStore.push(0);
                amountArrayStore.push(0)
            }

            // unique auction
            if (aucUniDateArray.indexOf(date) !== -1) {
                const indexAucUni =  aucUniDateArray.indexOf(date);
                countUniArrayAuc.push(dataAll2nd[indexAucUni][3]['longValue']);
            } else {
                countUniArrayAuc.push(0);
            }

            // unique store
            if (storeUniDateArray.indexOf(date) !== -1) {
                const indexStoreUni =  storeUniDateArray.indexOf(date);
                countUniArrayStore.push(dataAll1st[indexStoreUni][3]['longValue']);
            } else {
                countUniArrayStore.push(0);
            }

            // FIAT
            if (storeFiatDateArray.indexOf(date) !== -1 && dataGet1stFiat.length > 0) {
                const indexStore =  storeFiatDateArray.indexOf(date);
                countArrayStoreFiat.push(dataGet1stFiat[indexStore][1]['longValue']);
                amountArrayStoreFiat.push(Number(dataGet1stFiat[indexStore][2]['stringValue']))
            } else {
                countArrayStoreFiat.push(0);
                amountArrayStoreFiat.push(0)
            }

            // unique FIAT
            if (storeFiatUniDateArray.indexOf(date) !== -1) {
                const indexStoreFiat =  storeFiatUniDateArray.indexOf(date);
                countUniArrayStoreFiat.push(dataGet1stFiat[indexStoreFiat][3]['longValue']);
            } else {
                countUniArrayStoreFiat.push(0);
            }
        });

        // All stats
        allCountAuc.push(_.sum(countArray));
        allCountStore.push(_.sum(countArrayStore));
        allDepAuc.push(_.sum(amountArray));
        allDepStore.push(_.sum(amountArrayStore));
        allCountStoreFiat.push(_.sum(countArrayStoreFiat));
        allUsdStoreFiat.push(_.sum(amountArrayStoreFiat));

        // unique
        allDataCountAuction.push(
            {
                id: "auction-nft-unique",
                data: countUniArrayAuc,
                date: dateArray
            }
        );
        allDataCountStore.push(
            {
                id: "store-nft-unique",
                data: countUniArrayStore,
                date: dateArray
            }
        );
        allDataCountAuction.push(
            {
                id: "auction-soulfusers-count",
                data: countArray,
                date: dateArray
            }
        );

        allDataAmountAuction.push(
            {
                id: "auction-soulfusers-amount",
                data: amountArray,
                date: dateArray
            }
        );

        allDataCountStore.push(
            {
                id: "store-soulfusers-count",
                data: countArrayStore,
                date: dateArray
            }
        );

        allDataAmountStore.push(
            {
                id: "store-soulfusers-amount",
                data: amountArrayStore,
                date: dateArray
            }
        );

        allDataCountStoreFiat.push(
            {
                id: "store-soulfusers-count-fiat",
                data: countArrayStoreFiat,
                date: dateArray
            }
        );

        allDataAmountStoreFiat.push(
            {
                id: "store-soulfusers-amount-fiat",
                data: amountArrayStoreFiat,
                date: dateArray
            }
        );

        // all stats
        const dataAllStore = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStore)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepStore)),
            unique: H.FormatNumStrHelper.formatCommaThousand(_.sum(countUniArrayStore))
        };
        yield put(setGameNftTrendStoreAll(dataAllStore));

        const dataAllAuction = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountAuc)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allDepAuc)),
            unique: H.FormatNumStrHelper.formatCommaThousand(_.sum(countUniArrayAuc)),
        };
        yield put(setGameNftTrendAuctionAll(dataAllAuction));

        // fiat
        const dataAllStoreFiat = {
            count: H.FormatNumStrHelper.formatCommaThousand(_.sum(allCountStoreFiat)),
            amount: H.FormatNumStrHelper.formatCommaThousand(_.sum(allUsdStoreFiat)),
            unique: H.FormatNumStrHelper.formatCommaThousand(_.sum(countUniArrayStoreFiat)),
        };
        yield put(setGameNftTrendStoreAllFiat(dataAllStoreFiat));

        yield put(setGameNftTrendDataCountAuction(allDataCountAuction));
        yield put(setGameNftTrendDataAmountAuction(allDataAmountAuction));
        yield put(setGameNftTrendDataCountStore(allDataCountStore));
        yield put(setGameNftTrendDataAmountStore(allDataAmountStore));
        yield put(setGameNftTrendDataCountStoreFiat(allDataCountStoreFiat));
        yield put(setGameNftTrendDataAmountStoreFiat(allDataAmountStoreFiat));
        yield put(setPageTopLoader(false));
        yield put(setGameNftTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(JTCB.GET_JTCB_NFT_TREND_PAGE_DATA, getJtcbNftTrendData);
    yield takeEvery(LUCKYFARMER.GET_LUCKYFARMER_NFT_TREND_PAGE_DATA, getLuckyFarmerNftTrendData);
    yield takeEvery(COOKINBURGER.GET_COOKINBURGER_NFT_TREND_PAGE_DATA, getCookinBurgerNftTrendData);
    yield takeEvery(KAMUIVERSE.GET_KAMUIVERSE_NFT_TREND_PAGE_DATA, getKamuiVerseNftTrendData);
    yield takeEvery(GRAFITTIRACER.GET_GRAFITTIRACER_NFT_TREND_PAGE_DATA, getGraffitiRacerNftTrendData);
    yield takeEvery(DRAGONRAMEN.GET_DRAGONRAMEN_NFT_TREND_PAGE_DATA, getDragonRamenNftTrendData);
    yield takeEvery(LOSTARCHIVE.GET_LOSTARCHIVE_NFT_TREND_PAGE_DATA, getLostArchiveNftTrendData);
    yield takeEvery(SOULFUSERS.GET_SOULFUSERS_NFT_TREND_PAGE_DATA, getSoulFusersNftTrendData);
    yield takeEvery(DATA.GET_GAME_NFT_TREND_PAGE_DATA, getGameNftTrendData);
}
