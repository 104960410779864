import React, { Fragment } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as H from 'helper';

const columns = [
    { id: 'rank', label: '#', maxWidth: 50, align: 'left' },
    { id: 'stage', label: 'ステージ', maxWidth: 50, align: 'center' },
    {
        id: 'numPlay',
        label: 'プレイ回数',
        minWidth: 150,
        maxWidth: 200,
        align: 'center',
        format: (value) => H.FormatNumStrHelper.formatCommaThousand(value),
    },
    {
        id: 'sizeLabel',
        label: '全体割合(%)',
        minWidth: 170,
        align: 'center',
        format: (value) => value,
    },
    {
        id: 'size',
        label: '',
        minWidth: 170,
        align: 'center',
        chart: true,
        format: (value) => value,
    },
];

const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: 16,
    },
    container: {
        maxHeight: 440,
        display: 'flex',
        justifyContent: 'center',
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 455,
    }
});

const PuzzleStage = ({
        barColour, data, total, loading
    }) => {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createStageData = (stageData, total) => {
        if (data.length > 0) {
            let returnDataArray = [], rank = 1, stageObj = {};
            _.each(stageData, function(data) {
                stageObj.rank = rank;
                stageObj.stage = data.stage;
                stageObj.numPlay = data.count;
                stageObj.sizeLabel = (data.count / total * 100).toFixed(2);
                stageObj.size = data.count / total * 500;
                returnDataArray.push(stageObj);
                stageObj = {};
                rank++;
            });
            return returnDataArray;
        } else {
            return [];
        }
    };

    const rowsData = data !== undefined ? createStageData(data, total) : [];

    return (
        <Paper className={classes.root}>
        {loading === true ? (
            <div className={classes.progress}><CircularProgress /></div>
        ) : (
            <Fragment>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    {columns.map((column) => (
                        <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                        >
                        {column.label}
                        </TableCell>
                    ))}
                    </TableRow>
                </TableHead>
                    <TableBody>
                        {rowsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.size}>
                                {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ paddingLeft: 8 }}
                                        >
                                        {column.chart ? (
                                            <div style={{
                                                width: column.format(value)+'%',
                                                height: 16,
                                                backgroundColor: barColour,
                                                display:'block'}}
                                                key={value}
                                            >
                                            </div>
                                        ) : (
                                            column.format && typeof value === 'number' ? column.format(value) : value
                                        )}
                                    </TableCell>
                                    );
                                })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={rowsData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="1ページの表示数"
                labelDisplayedRows={({ from, to, count }) => from+' - '+to +'件 ' +'全'+count+'件'}
            />
            </Fragment>
        )}
        </Paper>
    );
}

PuzzleStage.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PuzzleStage);
