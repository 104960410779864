import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import TextField from '@material-ui/core/TextField';
import * as Var from 'styles/variables';
import { connect } from 'react-redux';
import { setCampaignEmail } from  'actions';

const styles = theme => ({
    title: {
        width: '100%',
        maxWidth: 500,
        color: Var.textColor,
        paddingTop: 8,
        paddingLeft: 8
    },
    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1),
        width: '80%',
    },
    areaWrapper: {
        display: 'block',
        width: '80%',
        paddingBottom: theme.spacing(2),
    }
});

export class CampaignEmail extends Component {
    handleChange = title => event => {
        this.props.setCampaignEmail(event.target.value);
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.areaWrapper}>
                <div>
                    <Icon className={'fa fa-envelope fa-sm'} /><span className={classes.title}>Eメール</span>
                </div>
                <TextField
                    id="standard-name"
                    className={classes.textField}
                    value={this.props.affiliatorEmail}
                    onChange={this.handleChange('title')}
                    margin="normal"
                />
            </div>
        );
    }
}

CampaignEmail.propTypes = {
    classes: PropTypes.object,
    setCampaignEmail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    affiliatorEmail: state.affiliate.affiliatorEmail
});

const mapDispatchToProps = dispatch => ({
    setCampaignEmail: (param) => dispatch(setCampaignEmail(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CampaignEmail));