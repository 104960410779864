/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCustomers = /* GraphQL */ `
  query GetCustomers($id: Int!) {
    getCustomers(id: $id) {
      id
      name
      phone
      email
      balance
    }
  }
`;
export const listCustomerss = /* GraphQL */ `
  query ListCustomerss {
    listCustomerss {
      id
      name
      phone
      email
      balance
    }
  }
`;
export const getTotalBalance = /* GraphQL */ `
  query GetTotalBalance($amount: Int!) {
    getTotalBalance(amount: $amount) {
      id
      name
      phone
      email
      balance
    }
  }
`;
export const getRegistration = /* GraphQL */ `
  query GetRegistration($pmId: String!) {
    getRegistration(pmId: $pmId) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const listRegistrations = /* GraphQL */ `
  query ListRegistrations($dateFrom: Float!, $dateTo: Float!) {
    listRegistrations(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const getRegistrationProd = /* GraphQL */ `
  query GetRegistrationProd($pmId: String!) {
    getRegistrationProd(pmId: $pmId) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const listRegistrationProds = /* GraphQL */ `
  query ListRegistrationProds($dateFrom: Float!, $dateTo: Float!) {
    listRegistrationProds(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const listByRidRegistrationProds = /* GraphQL */ `
  query ListByRidRegistrationProds(
    $dateFrom: Float!
    $dateTo: Float!
    $rid: String!
  ) {
    listByRidRegistrationProds(
      dateFrom: $dateFrom
      dateTo: $dateTo
      rid: $rid
    ) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const listByAdsRegistrationProds = /* GraphQL */ `
  query ListByAdsRegistrationProds($dateFrom: Float!, $dateTo: Float!) {
    listByAdsRegistrationProds(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const getCountPmRegistration = /* GraphQL */ `
  query GetCountPmRegistration($dateFrom: Float!, $dateTo: Float!) {
    getCountPmRegistration(dateFrom: $dateFrom, dateTo: $dateTo) {
      count
    }
  }
`;
export const getCountAdRegistration = /* GraphQL */ `
  query GetCountAdRegistration($dateFrom: Float!, $dateTo: Float!) {
    getCountAdRegistration(dateFrom: $dateFrom, dateTo: $dateTo) {
      count
    }
  }
`;
export const getCountOrgRegistration = /* GraphQL */ `
  query GetCountOrgRegistration($dateFrom: Float!, $dateTo: Float!) {
    getCountOrgRegistration(dateFrom: $dateFrom, dateTo: $dateTo) {
      count
    }
  }
`;
export const getPuzzleGamePlayStart = /* GraphQL */ `
  query GetPuzzleGamePlayStart($pmId: String!) {
    getPuzzleGamePlayStart(pmId: $pmId) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const listPuzzleGamePlayStarts = /* GraphQL */ `
  query ListPuzzleGamePlayStarts {
    listPuzzleGamePlayStarts {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const getPuzzleGamePlayStartProd = /* GraphQL */ `
  query GetPuzzleGamePlayStartProd($pmId: String!) {
    getPuzzleGamePlayStartProd(pmId: $pmId) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const listPuzzleGamePlayStartProds = /* GraphQL */ `
  query ListPuzzleGamePlayStartProds($dateFrom: Float!, $dateTo: Float!) {
    listPuzzleGamePlayStartProds(dateFrom: $dateFrom, dateTo: $dateTo) {
      logDate
      pmId
    }
  }
`;
export const getCountUniqueGamePlayPuzzle = /* GraphQL */ `
  query GetCountUniqueGamePlayPuzzle($dateFrom: Float!, $dateTo: Float!) {
    getCountUniqueGamePlayPuzzle(dateFrom: $dateFrom, dateTo: $dateTo) {
      count
    }
  }
`;
export const listJobTribesDauProds = /* GraphQL */ `
  query ListJobTribesDauProds($dateFrom: Float!, $dateTo: Float!) {
    listJobTribesDauProds(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      total
      ja
      en
      ind
      tw
      logDate
      dateStr
    }
  }
`;
export const getCountUniqueUsersJobTribes = /* GraphQL */ `
  query GetCountUniqueUsersJobTribes($dateFrom: Float!, $dateTo: Float!) {
    getCountUniqueUsersJobTribes(dateFrom: $dateFrom, dateTo: $dateTo) {
      count
    }
  }
`;
export const getCountActiveUsersByDatePuzzle = /* GraphQL */ `
  query GetCountActiveUsersByDatePuzzle($dateFrom: Float!, $dateTo: Float!) {
    getCountActiveUsersByDatePuzzle(dateFrom: $dateFrom, dateTo: $dateTo) {
      date
      count
    }
  }
`;
export const getCountUserEventsByDatePuzzle = /* GraphQL */ `
  query GetCountUserEventsByDatePuzzle(
    $table: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getCountUserEventsByDatePuzzle(
      table: $table
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      count
    }
  }
`;
export const getTotalKesoAmountByDatePuzzle = /* GraphQL */ `
  query GetTotalKesoAmountByDatePuzzle(
    $table: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getTotalKesoAmountByDatePuzzle(
      table: $table
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      total
    }
  }
`;
export const getCountActiveUsersByDateGame = /* GraphQL */ `
  query GetCountActiveUsersByDateGame(
    $table: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getCountActiveUsersByDateGame(
      table: $table
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      count
    }
  }
`;
export const getUniqueCountOfColumnNoLimit = /* GraphQL */ `
  query GetUniqueCountOfColumnNoLimit(
    $column: String!
    $table: String!
    $group: String!
    $sort: String!
  ) {
    getUniqueCountOfColumnNoLimit(
      column: $column
      table: $table
      group: $group
      sort: $sort
    ) {
      stage
      count
    }
  }
`;
export const getUniqueCountOfColumnLimit = /* GraphQL */ `
  query GetUniqueCountOfColumnLimit(
    $column: String!
    $table: String!
    $group: String!
    $sort: String!
    $limit: Int
  ) {
    getUniqueCountOfColumnLimit(
      column: $column
      table: $table
      group: $group
      sort: $sort
      limit: $limit
    ) {
      stage
      count
    }
  }
`;
export const getCampaignsStats = /* GraphQL */ `
  query GetCampaignsStats(
    $accessKey: String!
    $type: String
    $rid: String
    $property: String
  ) {
    getCampaignsStats(
      accessKey: $accessKey
      type: $type
      rid: $rid
      property: $property
    ) {
      id
      affiliateId
      rid
      name
      property
      type
      accessKey
      firstTime
      gameVisit
      registered
      createdDate
    }
  }
`;
export const getCampaignsStatsById = /* GraphQL */ `
  query GetCampaignsStatsById($aid: Int!) {
    getCampaignsStatsById(aid: $aid) {
      id
      affiliateId
      rid
      name
      property
      type
      accessKey
      firstTime
      gameVisit
      registered
      createdDate
    }
  }
`;
export const getCampaignLogByRidAllDateTime = /* GraphQL */ `
  query GetCampaignLogByRidAllDateTime($table: String!, $rid: String!) {
    getCampaignLogByRidAllDateTime(table: $table, rid: $rid) {
      date
      count
    }
  }
`;
export const getCampaignLogByRidWithDateTime = /* GraphQL */ `
  query GetCampaignLogByRidWithDateTime(
    $table: String!
    $rid: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getCampaignLogByRidWithDateTime(
      table: $table
      rid: $rid
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      count
    }
  }
`;
export const getCampaignLogRegByRidAllDateTime = /* GraphQL */ `
  query GetCampaignLogRegByRidAllDateTime($table: String!, $rid: String!) {
    getCampaignLogRegByRidAllDateTime(table: $table, rid: $rid) {
      date
      count
    }
  }
`;
export const getCampaignLogRegByRidWithDateTime = /* GraphQL */ `
  query GetCampaignLogRegByRidWithDateTime(
    $table: String!
    $rid: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getCampaignLogRegByRidWithDateTime(
      table: $table
      rid: $rid
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      date
      count
    }
  }
`;
export const getCampaignSummaryAllRidAndAllTime = /* GraphQL */ `
  query GetCampaignSummaryAllRidAndAllTime($table: String!) {
    getCampaignSummaryAllRidAndAllTime(table: $table) {
      rid
      count
    }
  }
`;
export const getCampaignSummaryAllRidAndWithDateTime = /* GraphQL */ `
  query GetCampaignSummaryAllRidAndWithDateTime(
    $table: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getCampaignSummaryAllRidAndWithDateTime(
      table: $table
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      rid
      count
    }
  }
`;
export const getCampaignRegSummaryAllRidAndAllTime = /* GraphQL */ `
  query GetCampaignRegSummaryAllRidAndAllTime($table: String!) {
    getCampaignRegSummaryAllRidAndAllTime(table: $table) {
      rid
      count
    }
  }
`;
export const getCampaignRegSummaryAllRidAndWithDateTime = /* GraphQL */ `
  query GetCampaignRegSummaryAllRidAndWithDateTime(
    $table: String!
    $dateFrom: Float!
    $dateTo: Float!
  ) {
    getCampaignRegSummaryAllRidAndWithDateTime(
      table: $table
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) {
      rid
      count
    }
  }
`;
export const getPuzzleRegData = /* GraphQL */ `
  query GetPuzzleRegData($id: ID!) {
    getPuzzleRegData(id: $id) {
      id
      dateFrom
      dateTo
      dataset
      name
      createdAt
      updatedAt
    }
  }
`;
export const listPuzzleRegDatas = /* GraphQL */ `
  query ListPuzzleRegDatas(
    $filter: ModelPuzzleRegDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPuzzleRegDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dateFrom
        dateTo
        dataset
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
