import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Chip from '@material-ui/core/Chip';
import { connect } from 'react-redux';
import { getCampaignGroup, setCampaignGroup, getCampaignGroupSelected, setCampaignGroupSelected} from  'actions';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = theme => ({
    title: {
        width: '100%',
        maxWidth: 500,
        paddingLeft: 8
    },
    areaWrapper: {
        display: 'block',
        width: '90%',
        paddingBottom: theme.spacing(8)
    },
    formControl: {
        marginLeft: theme.spacing(4),
    },
    group: {
        margin: `8px 0`,
    },
    root: {
        width: 500,
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
        marginTop: 16,
    },
});

export class CampaignGroup extends Component {
    componentDidMount() {
        this.props.getCampaignGroup();
        if (this.props.view) {
            const affiliateId = window.location.pathname.split("/").pop();
            this.props.getCampaignGroupSelected(affiliateId);
        }
    }

    render() {
        const { classes, view, affiliatorGroup, affiliatorGroupSelected } = this.props;
        return (
            <div className={classes.areaWrapper}>
                <div>
                    <Icon className={'fa fa-lock fa-sm'} /><span className={classes.title}>アクセスグループ</span>
                </div>
                <div className={classes.root}>
                    <FormControl component="fieldset" className={classes.formControl}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        disableClearable
                        style={{ width: 500 }}
                        options={affiliatorGroup}
                        getOptionDisabled={(option) => option.name === 'DEA'} // THIS IS Fxxxcking Important!
                        getOptionSelected={(option, value) => option.accessKey === value.accessKey} // THIS IS Fxxxcking Important!
                        getOptionLabel={(option) => option.name}
                        value={affiliatorGroupSelected}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined"  placeholder="" />
                        )}
                        renderOption={function(option, { selected }) {
                            return(
                                <Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </Fragment>
                            )
                        }}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                    disabled={option.name.indexOf('DEA') !== -1}
                                />
                            ))
                        }
                        onChange={(e, newValue) => {
                            this.props.setCampaignGroupSelected(newValue) // THIS IS Fxxxcking Important!
                        }}
                    />
                    </FormControl>
                </div>
            </div>
        );
    }
}

CampaignGroup.propTypes = {
    classes: PropTypes.object,
    getCampaignGroup: PropTypes.func.isRequired,
    setCampaignGroup: PropTypes.func.isRequired,
    getCampaignGroupSelected: PropTypes.func.isRequired,
    setCampaignGroupSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    affiliatorGroup: state.affiliate.affiliatorGroup,
    affiliatorGroupSelected: state.affiliate.affiliatorGroupSelected,
    allBIUsers: state.affiliate.allBIUsers,
});

const mapDispatchToProps = dispatch => ({
    getCampaignGroup: () => dispatch(getCampaignGroup()),
    setCampaignGroup: (param) => dispatch(setCampaignGroup(param)),
    getCampaignGroupSelected: (param) => dispatch(getCampaignGroupSelected(param)),
    setCampaignGroupSelected: (param) => dispatch(setCampaignGroupSelected(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CampaignGroup));