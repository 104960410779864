import { DATA_LOAD, DATA } from "../../constants";

const initialState = {
  nftSalesByOccurrence: [],
  nftSalesByOccurrenceType: "store",
  nftSalesByOccurrenceGame: "",
};

export default function nftSalesReducer(state = initialState, action) {
  switch (action.type) {
    case DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE:
      return {
        ...state,
        nftSalesByOccurrence: action.payload,
      };
    case DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE_TYPE:
      return {
        ...state,
        nftSalesByOccurrenceType: action.payload,
      };
    case DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE_GAME:
      return {
        ...state,
        nftSalesByOccurrenceGame: action.payload,
      };
    default:
      return state;
  }
}
