import { DATA } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    itemSearchTerm: [],
    itemSearchLoading: false,
    txHistoryDataLoading: false,
    txHistoryData: [],
    txHistoryStatData: [{
        "totalTx" : '--',
        "totalDep" : '--',
        "totalFiat": '--',
        "totalFiatTx": '--',
        "totalFiatAmount": '--',
        "totalDepTx" : '--',
        "totalDepAmount" : '--'
    }],
    txHistoryDataFilter: [{
        "dateRange": [startDate, endDate],
    }],
    filter: [],
    pageNumber: 1,
    itemTxCsvData: [],
    itemTxCsvLoader: false,
    itemOptionLoading: false,
    itemOption: [],
    itemOptionSelected: [],
    itemPaymentSelected: "both",
    itemPmidOption: [],
    itemPmidOptionLoading: false,
    itemPmidSelected: [],
};

export default function inGameItemSalesHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case DATA.SET_INGAME_ITEM_TX_HISTORY_SEARCH_TERM:
            return {
                ...state,
                itemSearchTerm: action.payload
            };
        case DATA.SET_INGAME_ITEM_TX_HISTORY_SEARCH_LOADING:
            return {
                ...state,
                itemSearchLoading: action.payload
            };
        case DATA.SET_INGAME_ITEM_TX_HISTORY_DATA_LOADING:
            return {
                ...state,
                txHistoryDataLoading: action.payload
            };
        case DATA.SET_INGAME_ITEM_TX_HISTORY_DATA:
            return {
                ...state,
                txHistoryData: action.payload
            };
        case DATA.SET_INGAME_ITEM_TX_HISTORY_STAT_DATA:
            return {
                ...state,
                txHistoryStatData: action.payload
            };
        // case DATA.SET_INGAME_ITEM_TX_HISTORY_DATA_FILTER:
        //     return {
        //         ...state,
        //         txHistoryDataFilter: action.payload
        //     };
        // case DATA.SET_FILTER:
        //     return {
        //         ...state,
        //         filter: action.payload
        //     };
        case DATA.SET_INGAME_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_CSV:
            return {
                ...state,
                itemTxCsvData: action.payload
            };
        case DATA.SET_INGAME_ITEM_OPTION_DATA_LOADING:
            return {
                ...state,
                itemOptionLoading: action.payload
            };
        case DATA.SET_INGAME_ITEM_OPTION_DATA:
            return {
                ...state,
                itemOption: action.payload
            };
        case DATA.SET_INGAME_ITEM_OPTION_SELECTED:
            return {
                ...state,
                itemOptionSelected: action.payload
            };
        case DATA.SET_INGAME_PAYMENT_OPTION_SELECTED:
            return {
                ...state,
                itemPaymentSelected: action.payload
            };
        case DATA.SET_INGAME_PMID_OPTION_DATA:
            return {
                ...state,
                itemPmidOption: action.payload
            };
        case DATA.SET_INGAME_PMID_OPTION_DATA_LOADING:
            return {
                ...state,
                itemPmidOptionLoading: action.payload
            };
        case DATA.SET_INGAME_PMID_OPTION_SELECTED:
            return {
                ...state,
                itemPmidSelected: action.payload
            };
        default:
            return state
    }
};
