import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import * as C from 'components';
import * as H from 'helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        totalStatsBox: {
            marginTop: theme.spacing(2),
            paddingRight: theme.spacing(1),
            fontSize: 14,
            borderRight: '1px solid #dcdcdc',
        },
        tsTitle: {
            fontFamily: "Roboto",
            fontSize: 14,
            height: theme.spacing(3),
            fontWeight: 400,
        },
        tsPrimeContent: {
            marginBottom: theme.spacing(2),
            display: 'flex',
            width: '95%',
            fontSize: 20,
            fontWeight: 500,
        },
        tsContent: {
            marginBottom: theme.spacing(0.3),
            display: 'flex',
            width: '95%',
            borderBottom: '1px dashed #dcdcdc'
        },
        tsPrimeLabel: {
            height: theme.spacing(3),
            fontWeight: 500,
            flex: 1,
            textAlign: 'left'
        },
        tsLabel0: {
            flexGrow: 2,
            textAlign: 'left',
        },
        tsLabel: {
            flexGrow: 3,
            textAlign: 'left',
        },
        lblPercent: {
            flexGrow: 1,
            textAlign: 'right',
            fontSize: 13,
        },
    }),
);

const PmAcquisition = ({
    totalRegStatsByXDate,
    acquisitionHomeDataReg,
    acquisitionHomeDataRegPrev,
    acquisitionHomeDataRegLast,
    homeDataTier,
    homeDataTierPrev,
    homeDataTierTrend,
    dateRange,
}) => {
    const classes = useStyles();
    useEffect(() => {
        //console.log('yoyo');
    }, []);

    const getTierPieColour = (colour, data) => {
        let returnColourArray = [];
        for (let i=0; i<data.length; i++) {
            let name = data[i]['name'];
            returnColourArray.push(colour[name]);
        }
        return returnColourArray
    }

    const getStatValue = (data) => {
        if (data === 'loading' || data === '-') {
            return 'Loading..';
        } else {
            return H.FormatNumStrHelper.formatCommaThousand(data);
        }
    }

    const getRatioAdOrg = (num) => {
        if (num ===  '-' || num === 'loading')
            return '--%';
        return (num / totalRegStatsByXDate.gamePmid * 100).toFixed(2)+'%';
    }

    const getRatioTier = (num) => {
        if (num ===  '-' || num === 'loading')
            return '--%';
        const totalTiers = totalRegStatsByXDate.tier1 + totalRegStatsByXDate.tier2 + totalRegStatsByXDate.tier3;
        return (num / totalTiers * 100).toFixed(2)+'%';
    }

    return(
        <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={12}>
                <Grid container spacing={0}>
                    <Grid item sm={12} md={12} lg={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <div className={classes.totalStatsBox}>
                                    <div className={classes.tsTitle}>{dateRange[1]} 時点の合計</div>
                                    <div className={classes.tsPrimeContent}>
                                        {getStatValue(totalRegStatsByXDate.gamePmid)}
                                    </div>
                                    <div className={classes.tsContent}>
                                        <span className={classes.tsLabel0}>広告経由 :</span>
                                        <span className={classes.lblPercent}>{getStatValue(totalRegStatsByXDate.ads)}</span>
                                        <span className={classes.lblPercent}>({getRatioAdOrg(totalRegStatsByXDate.ads)})</span>
                                    </div>
                                    <div className={classes.tsContent}>
                                        <span className={classes.tsLabel0}>Organic :</span>
                                        <span className={classes.lblPercent}>{getStatValue(totalRegStatsByXDate.org)}</span>
                                        <span className={classes.lblPercent}>({getRatioAdOrg(totalRegStatsByXDate.org)})</span>
                                    </div>
                                    <div className={classes.tsContent}>
                                        <span className={classes.tsLabel}>Tier 1 :</span>
                                        <span className={classes.lblPercent}>{getStatValue(totalRegStatsByXDate.tier1)}</span>
                                        <span className={classes.lblPercent}>({getRatioTier(totalRegStatsByXDate.tier1)})</span>
                                    </div>
                                    <div className={classes.tsContent}>
                                        <span className={classes.tsLabel}>Tier 2 :</span>
                                        <span className={classes.lblPercent}>{getStatValue(totalRegStatsByXDate.tier2)}</span>
                                        <span className={classes.lblPercent}>({getRatioTier(totalRegStatsByXDate.tier2)})</span>
                                    </div>
                                    <div className={classes.tsContent}>
                                        <span className={classes.tsLabel}>Tier 3 :</span>
                                        <span className={classes.lblPercent}>{getStatValue(totalRegStatsByXDate.tier3)}</span>
                                        <span className={classes.lblPercent}>({getRatioTier(totalRegStatsByXDate.tier3)})</span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={12} lg={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.StatBox
                                    title="GAME経由PMID<br />新規登録数"
                                    value={acquisitionHomeDataReg.pmidRegTotal}
                                    prevValue={acquisitionHomeDataRegPrev.pmidRegTotal}
                                    lineChartData={acquisitionHomeDataRegLast.allReg}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.StatBox
                                    title="PMID広告経由<br />登録数"
                                    value={acquisitionHomeDataReg.pmidRegAdTotal}
                                    prevValue={acquisitionHomeDataRegPrev.pmidRegAdTotal}
                                    lineChartData={acquisitionHomeDataRegLast.byAds}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.StatBox
                                    title="PMID Organic<br />登録数"
                                    value={acquisitionHomeDataReg.pmidRegOrgTotal}
                                    prevValue={acquisitionHomeDataRegPrev.pmidRegOrgTotal}
                                    lineChartData={acquisitionHomeDataRegLast.byOrg}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={12} lg={5}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.StatBox
                                    title="Tier1登録数"
                                    value={homeDataTier.tier1}
                                    prevValue={homeDataTierPrev.tier1}
                                    //lineChartData={homeDataTierTrend.tier1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.StatBox
                                    title="Tier2登録数"
                                    value={homeDataTier.tier2}
                                    prevValue={homeDataTierPrev.tier2}
                                    //lineChartData={homeDataTierTrend.tier2}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <C.StatBox
                                    title="Tier3登録数"
                                    value={homeDataTier.tier3}
                                    prevValue={homeDataTierPrev.tier3}
                                    //lineChartData={homeDataTierTrend.tier3}
                                    lastItem={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
};

PmAcquisition.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    totalRegStatsByXDate: state.home.totalRegStatsByXDate,
    acquisitionHomeDataReg: state.home.acquisitionHomeDataReg,
    acquisitionHomeDataRegPrev: state.home.acquisitionHomeDataRegPrev,
    acquisitionHomeDataRegLast: state.home.acquisitionHomeDataRegLast,
    homeDataTier: state.home.homeDataTier,
    homeDataTierPrev: state.home.homeDataTierPrev,
    homeDataTierTrend: state.home.homeDataTierTrend,
    dateRange: state.page.dateRange,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(PmAcquisition));