const ResetPasswordStyles = theme => ({
    session: {
      position: 'relative',
      zIndex: 4000,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column'
    },
    background: {
      backgroundColor: '#e9ebee'
    },
    content: {
      padding: `40px ${(theme.spacing())}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '100%',
      textAlign: 'center'
    },
    wrapper: {
      flex: 'none',
      maxWidth: '400px',
      width: '100%',
      margin: '0 auto'
    },
    fullWidth: {
      width: '100%'
    },
    submitButton: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      borderRadius: '0px',
      boxShadow: 'none',
      marginTop: '40px',
      marginBottom: '20px',
      fontSize: '16px'
    },
    branding: {
      marginTop: '20px',
      marginLeft: '20px',
    },
    productName: {
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    errorMessageDiv: {
      paddingBottom: '5px'
    },
    errorMessage: {
      color: 'red'
    },
    formMessage: {
      marginTop: '20px',
      marginBottom: '20px',
      fontSize: '14px',
      textAlign: 'center',
      lineHeight: '140%'
    },
    formMessageLeft: {
      marginTop: '20px',
      marginBottom: '20px',
      marginLeft: '10px',
      fontSize: '14px',
      textAlign: 'left',
      lineHeight: '140%'
    },
    pwTextField: {
      marginBottom: '20px'
    }
});

export default ResetPasswordStyles;