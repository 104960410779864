import moment from "moment";

const ElasticSearch = {
    getSourceFromDaaES: function(dateFrom, dateTo) {
        let querySrc = `{"aggs":{"2":{"terms":{"field":"tier","size":5,"order":{"_count":"desc"}}}},"size":0,"_source":{"excludes":[]},"stored_fields":["*"],"script_fields":{},"docvalue_fields":[{"field":"created_at","format":"date_time"},{"field":"information_read_history.created_at","format":"date_time"},{"field":"status_history.created_at","format":"date_time"},{"field":"updated_at","format":"date_time"}],"query":{"bool":{"must":[{"range":{"created_at":{"format":"strict_date_optional_time","gte":"${dateFrom}","lte":"${dateTo}","time_zone":"+08:00"}}}],"filter":[{"match_all":{}},{"match_all":{}}],"should":[],"must_not":[]}},"timeout":"30000ms"}`

        return querySrc;
    },

    getTierTrendDataByDate: function(dateFrom, dateTo) {
        // const querySrc = `{"aggs":{"2":{"date_histogram":{"field":"created_at","interval":"1d","time_zone":"America/Toronto","min_doc_count":1},"aggs":{"3":{"terms":{"field":"tier","size":5,"order":{"_count":"desc"}}}}}},"size":0,"_source":{"excludes":[]},"stored_fields":["*"],"script_fields":{},"docvalue_fields":[{"field":"created_at","format":"date_time"}],"query":{"bool":{"must":[{"range":{"created_at":{"format":"strict_date_optional_time","gte":"${dateFrom}T05:00:00.000Z","lte":"${dateTo}T04:59:59.999Z"}}}],"filter":[{"match_all":{}},{"match_all":{}}],"should":[],"must_not":[]}},"timeout":"30000ms"}`;
        const querySrc = `{"aggs":{"2":{"date_histogram":{"field":"created_at","interval":"1d","min_doc_count":1},"aggs":{"3":{"terms":{"field":"tier","size":5,"order":{"_count":"desc"}}}}}},"size":0,"_source":{"excludes":[]},"stored_fields":["*"],"script_fields":{},"docvalue_fields":[{"field":"created_at","format":"date_time"}],"query":{"bool":{"must":[{"range":{"created_at":{"format":"strict_date_optional_time","gte":"${dateFrom}T00:00:00.000Z","lte":"${dateTo}T23:59:59.999Z"}}}],"filter":[{"match_all":{}},{"match_all":{}}],"should":[],"must_not":[]}},"timeout":"30000ms"}`;

        return querySrc;
    },

    // Not in Use: Used at homeSagaLegacy
    getJtcbUUCountFromJtcbES: function(dateFrom, dateTo) {
        const querySrc = `{"timeout":"60000ms","aggs":{"2":{"date_histogram":{"field":"time_stamp","calendar_interval":"1M","time_zone":"UTC","min_doc_count":0},"aggs":{"4":{"cardinality":{"field":"user_deap_id.keyword"}}}}},"size":0,"_source":{"excludes":[]},"stored_fields":["*"],"script_fields":{},"docvalue_fields":[{"field":"deap_ticket_expired_at","format":"date_time"},{"field":"first_login_at","format":"date_time"},{"field":"time_stamp","format":"date_time"},{"field":"user_created_at","format":"date_time"},{"field":"user_last_login_at","format":"date_time"},{"field":"user_stamina_update_at","format":"date_time"}],"query":{"bool":{"must":[],"filter":[{"match_all":{}},{"match_phrase":{"action_name.keyword":{"query":"API"}}},{"range":{"time_stamp":{"format":"strict_date_optional_time","gte":"${dateFrom}","lte":"${dateTo}","time_zone":"+08:00"}}}],"should":[],"must_not":[]}}}`;

        return querySrc;
    },

    getTodaysDepSales: function() {
        const querySrc = `{"aggs":{"2":{"date_histogram":{"field":"created_at","calendar_interval":"1d","min_doc_count":1},"aggs":{"3":{"terms":{"field":"kind","order":{"1":"desc"},"size":100},"aggs":{"1":{"sum":{"field":"amount"}}}}}}},"size":0,"stored_fields":["*"],"script_fields":{},"docvalue_fields":[{"field":"created_at","format":"date_time"}],"_source":{"excludes":[]},"query":{"bool":{"must":[],"filter":[{"match_all":{}},{"bool":{"should":[{"match_phrase":{"kind":"auction"}},{"match_phrase":{"kind":"store"}}],"minimum_should_match":1}},{"range":{"created_at":{"gte":"now/d","lte":"now","time_zone":"+08:00"}}}],"should":[],"must_not":[]}}}
        `;
        return querySrc;
    },

    getJtcbScholarOwnerScholarStats: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{"timeout":"60000ms","aggs":{"3":{"date_histogram":{"field":"time_stamp","calendar_interval":"1d","min_doc_count":1},"aggs":{"4":{"filters":{"filters":{"総数":{"bool":{"must":[{"match_all":{}}],"filter":[],"should":[],"must_not":[]}},"新規数":{"bool":{"must":[{"query_string":{"query":"action_id:60000"}}],"filter":[],"should":[],"must_not":[]}},"停止数":{"bool":{"must":[{"query_string":{"query":"action_id:60001"}}],"filter":[],"should":[],"must_not":[]}}}},"aggs":{"1":{"cardinality":{"field":"owner_id"}},"2":{"cardinality":{"field":"scholar_id"}}}}}}},"size":0,"_source":{"excludes":[]},"stored_fields":["*"],"script_fields":{},"docvalue_fields":[{"field":"deap_ticket_expired_at","format":"date_time"},{"field":"first_login_at","format":"date_time"},{"field":"time_stamp","format":"date_time"},{"field":"user_created_at","format":"date_time"},{"field":"user_last_login_at","format":"date_time"},{"field":"user_stamina_update_at","format":"date_time"}],"query":{"bool":{"must":[{"match_all":{}},{"match_all":{}}],"filter":[{"bool":{"should":[{"match_phrase":{"action_name":"SCHOLAR_SHIP_CONTRACT"}},{"match_phrase":{"action_name":"SCHOLAR_SHIP_CANCEL"}}],"minimum_should_match":1}},{"range":{"time_stamp":{"format":"strict_date_optional_time","gte":"${dateFrom}T00:00:00.000Z","lte":"${dateTo}T23:59:59.999Z","time_zone": "+08:00"}}}],"should":[],"must_not":[]}}}`;
        return querySrc;
    },

    getCountOfWinningBid: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{"query":{"bool":{"must":[{"match":{"type":2}}],"filter":[{"match_all":{}},{"range":{"created_at":{"gte":"${dateFrom}T00:00:00.000Z","lte":"${dateTo}T23:59:59.999Z","format":"strict_date_optional_time","time_zone":"+08:00"}}}]}}}`;
        return querySrc;
    },

    getAuctionBlindAndOtherTx: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{"query":{"bool":{"must":[{"terms":{"type":[1,2]}},{"match":{"status":2}},{"match":{"kind":"user"}}],"filter":[{"match_all":{}},{"range":{"created_at":{"gte":"${dateFrom}T00:00:00.000Z","lte":"${dateTo}T23:59:59.999Z","format":"strict_date_optional_time","time_zone":"+08:00"}}}]}},"size":0,"aggs":{"terms":{"terms":{"field":"type","size":2}}}}`
        return querySrc;
    },

    getStakingCountAndDepAmount: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{"query":{"bool":{"must":[{"terms":{"status":[0,1,2]}}],"filter":[{"match_all":{}},{"range":{"created_at":{"gte":"${dateFrom}T00:00:00.000Z","lte":"${dateTo}T23:59:59.999Z","format":"strict_date_optional_time","time_zone":"+08:00"}}}]}},"size":0,"aggs":{"terms":{"terms":{"field":"status","size":3},"aggs":{"dep_amount":{"sum":{"script":"Float.parseFloat(doc['deposit_amount'].value)"}}}}}}`
        return querySrc;
    },

    getAucStoreCount: function(dateFrom, dateTo) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `{
        "query": {
          "bool": {
            "must": { "terms": { "kind": ["auction","store"] } },
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
          }
        },
        "aggs": {
          "total_dep": {
            "sum": {
              "field": "amount"
            }
          },
          "active_wallet_count": {
            "cardinality": {
              "script":{
                "source":"return [doc['from'], doc['to']]"
              }
            }
          },
          "kind": {
            "terms": { "field": "kind" },
            "aggs": {
              "dep_total_amount": {
                "sum": {
                  "field": "amount"
                }
              },
              "tx_count": {
                "value_count": {
                  "field": "from"
                }
              },
              "unique_pmid_from": {
                "cardinality": {
                  "field": "from"
                }
              },
              "unique_pmid_from_to": {
                "cardinality": {
                  "script":{
                    "source":"return [doc['from'], doc['to']]"
                  }
                }
              },
              "max_price": {
                "max": { 
                  "field": "amount"
                } 
              },
              "avg_price": {
                "avg": { 
                  "field": "amount"
                } 
              },
              "min_price": {
                "min": { 
                  "field": "amount"
                } 
              },
              "median_price": {
                "percentiles": {
                    "field": "amount",
                    "percents": [50]
                }
              }
            }
          }
        },
        "size": 0
      }`
      return querySrc;
    },

    getStoreTxCount: function(dateFrom, dateTo) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `{
          "query": {
            "bool": {
              "must": [
                {
                  "match_all": {}
                }
              ],
              "filter": [
                {
                    "term": {
                      "kind": "store"
                    }
                },
                {
                  "term": {
                    "from_type": "user"
                  }
                },
                {
                  "term": {
                    "to_type": "owner"
                  }
                },
                {
                    "range": {
                        "created_at": {
                            "gte": "${dateFrom}T00:00:00+08:00",
                            "lte": "${dateTo}T23:59:59+08:00",
                            "format": "strict_date_optional_time"
                        }
                    }
                }
              ]
            }
          },
          "size": 0,
          "aggs": {
            "dep_total_amount": {
              "sum": {
                "field": "amount"
              }
            },
            "tx_count": {
              "value_count": {
                "field": "from"
              }
            },
            "unique_pmid_count": {
              "cardinality": {
                "field": "from"
              }
            }
          }
      }`
      return querySrc;
    },

    getCountAuctionTx: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{
            "query": {
              "bool": {
                  "must": [
                    {
                      "match_all": {}
                    }
                  ],
                  "filter": [
                      {
                          "term": {
                            "kind": "auction"
                          }
                      },
                      {
                        "term": {
                          "from_type": "user"
                        }
                      },
                      {
                        "term": {
                          "to_type": "user"
                        }
                      },
                      {
                          "range": {
                              "created_at": {
                                  "gte": "${dateFrom}T00:00:00+08:00",
                                  "lte": "${dateTo}T23:59:59+08:00",
                                  "format": "strict_date_optional_time"
                              }
                          }
                      }
                  ],
                  "should": [],
                  "must_not": []
              }
            }
        }`
        return querySrc;
    },

    getCountStoreTx: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{
            "query": {
              "bool": {
                  "must": [
                    {
                      "match_all": {}
                    }
                  ],
                  "filter": [
                      {
                          "term": {
                            "kind": "store"
                          }
                      },
                      {
                        "term": {
                          "from_type": "user"
                        }
                      },
                      {
                        "term": {
                          "to_type": "owner"
                        }
                      },
                      {
                          "range": {
                              "created_at": {
                                  "gte": "${dateFrom}T00:00:00+08:00",
                                  "lte": "${dateTo}T23:59:59+08:00",
                                  "format": "strict_date_optional_time"
                              }
                          }
                      }
                  ],
                  "should": [],
                  "must_not": []
              }
            }
        }`
        return querySrc;
    },

    getTotalDepAuctionTx: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{
            "query": {
              "bool": {
                "must": [
                  {
                    "match_all": {}
                  }
                ],
                "filter": [
                  {
                      "term": {
                        "kind": "auction"
                      }
                  },
                  {
                    "term": {
                      "from_type": "user"
                    }
                  },
                  {
                    "term": {
                      "to_type": "user"
                    }
                  },
                  {
                      "range": {
                          "created_at": {
                              "gte": "${dateFrom}T00:00:00+08:00",
                              "lte": "${dateTo}T23:59:59+08:00",
                              "format": "strict_date_optional_time"
                          }
                      }
                  }
                ]
              }
            },
            "size": 0,
            "aggs": {
              "dep_total_amount": {
                "sum": {
                  "field": "amount"
                }
              }
            }
        }`
        return querySrc;
    },

    getTotalDepStoreTx: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{
            "query": {
              "bool": {
                "must": [
                  {
                    "match_all": {}
                  }
                ],
                "filter": [
                  {
                      "term": {
                        "kind": "store"
                      }
                  },
                  {
                    "term": {
                      "from_type": "user"
                    }
                  },
                  {
                    "term": {
                      "to_type": "owner"
                    }
                  },
                  {
                      "range": {
                          "created_at": {
                              "gte": "${dateFrom}T00:00:00+08:00",
                              "lte": "${dateTo}T23:59:59+08:00",
                              "format": "strict_date_optional_time"
                          }
                      }
                  }
                ]
              }
            },
            "size": 0,
            "aggs": {
              "dep_total_amount": {
                "sum": {
                  "field": "amount"
                }
              }
            }
        }`
        return querySrc;
    },

    // Not in use but how to fetch data by game can be useful
    // getTotalDepWithdrawFeeNew: function(dateFrom, dateTo, range) {
    //   dateFrom = dateFrom.replace(/\//g, '-');
    //   dateTo = dateTo.replace(/\//g, '-');
    //   let rangeValue = `
    //     "gte": "${dateFrom}T00:00:00+08:00",
    //     "lte": "2022-06-07T14:59:59+09:00",`
    //   if (range > 0) {
    //     rangeValue = `
    //     "gte": "2022-06-07T15:00:00+09:00",
    //     "lte": "${dateTo}T23:59:59+08:00",
    //     `
    //   }

    //   const querySrc = `{
    //     "_source": ["asset_doc_id", "price", "from", "kind", "fee", "status", "created_at", "asset.names", "asset.asset_id"],
    //     "query": {
    //         "bool": {
    //             "must": [
    //                 { "terms": { "kind": ["auction"] } },
    //                 {
    //                     "nested": {
    //                         "path": "asset",
    //                         "query": {
    //                             "terms": {
    //                                 "asset.contents_list": ["g3bq4ssqNmgS8yiQWuba"]
    //                             }
    //                         }
    //                     }
    //                 }
    //             ],
    //             "filter": [
    //               {
    //                   "range": {
    //                       "created_at": {
    //                           ${rangeValue}
    //                           "format": "strict_date_optional_time"
    //                       }
    //                   }
    //               }
    //             ]
    //           }
    //         },
    //         "aggs": {
    //             "total_price": { 
    //                 "sum": { 
    //                     "script": "Float.parseFloat(doc['price'].value)"
    //                 } 
    //             },
    //             "kind": {
    //                 "terms": {
    //                     "field": "kind",
    //                     "size": 2,
    //                     "order": {
    //                       "_key": "asc"
    //                     }
    //                 },
    //                 "aggs": {
    //                     "sum_total_price": {
    //                         "sum": { 
    //                             "script": "Float.parseFloat(doc['price'].value)"
    //                         } 
    //                     }
    //                 }
    //             }
    //         },
    //         "size": 0
    // }`
    //   return querySrc;
    // },

    getTotalDepWithdrawFeesNew: function(dateFrom, dateTo) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `{
        "_source": ["asset_doc_id", "price", "from", "kind", "fee", "status", "created_at", "asset.names", "asset.asset_id"],
        "query": {
            "bool": {
                "must": [
                    { "terms": { "kind": ["auction"] } }
                ],
                "filter": [
                  {
                      "range": {
                          "created_at": {
                              "gte": "${dateFrom}T00:00:00+08:00",
                              "lte": "${dateTo}T23:59:59+08:00",
                              "format": "strict_date_optional_time"
                          }
                      }
                  }
                ]
              }
            },
            "aggs": {
                "total_price": { 
                    "sum": { 
                        "script": "Float.parseFloat(doc['price'].value)"
                    } 
                },
                "kind": {
                    "terms": {
                        "field": "kind",
                        "size": 2,
                        "order": {
                          "_key": "asc"
                        }
                    },
                    "aggs": {
                        "sum_total_price": {
                            "sum": { 
                                "script": "Float.parseFloat(doc['price'].value)"
                            } 
                        }
                    }
                }
            },
            "size": 0
    }`
      return querySrc;
    },

    getTotalDepFeesWithChange: function(dateFrom, dateTo, range) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      let rangeValue = `
        "gte": "${dateFrom}T00:00:00+08:00",
        "lte": "2022-06-07T14:59:59+09:00",`
      if (range > 0) {
        rangeValue = `
        "gte": "2022-06-07T15:00:00+09:00",
        "lte": "${dateTo}T23:59:59+08:00",
        `
      }

      const querySrc = `{
        "_source": ["asset_doc_id", "price", "from", "kind", "fee", "status", "created_at", "asset.names", "asset.asset_id"],
        "query": {
            "bool": {
                "must": [
                    { "terms": { "kind": ["auction"] } }
                ],
                "filter": [
                  {
                      "range": {
                          "created_at": {
                              ${rangeValue}
                              "format": "strict_date_optional_time"
                          }
                      }
                  }
                ]
              }
            },
            "aggs": {
                "total_price": { 
                    "sum": { 
                        "script": "Float.parseFloat(doc['price'].value)"
                    } 
                },
                "kind": {
                    "terms": {
                        "field": "kind",
                        "size": 2,
                        "order": {
                          "_key": "asc"
                        }
                    },
                    "aggs": {
                        "sum_total_price": {
                            "sum": { 
                                "script": "Float.parseFloat(doc['price'].value)"
                            } 
                        }
                    }
                }
            },
            "size": 0
    }`
      return querySrc;
    },

    getTotalDepWithdrawFees: function(dateFrom, dateTo) {
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        const querySrc = `{
            "query": {
              "bool": {
                "must": [
                  {
                    "match_all": {}
                  }
                ],
                "filter": [
                  {
                      "term": {
                        "kind": "withdrawal"
                      }
                  },
                  {
                    "term": {
                      "from_type": "user"
                    }
                  },
                  {
                      "range": {
                          "created_at": {
                              "gte": "${dateFrom}T00:00:00+08:00",
                              "lte": "${dateTo}T23:59:59+08:00",
                              "format": "strict_date_optional_time"
                          }
                      }
                  }
                ]
              }
            },
            "size": 0,
            "aggs": {
              "dep_total_amount": {
                "sum": {
                  "field": "amount"
                }
              }
            }
        }`
        return querySrc;
    },
    
    getAucStoreCountByCountry: function(dateFrom, dateTo) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `{
        "query": {
          "bool": {
            "must": { "terms": { "kind": ["auction","store"] } },
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
          }
        },
        "aggs": {
          "kind": {
            "terms": { "field": "kind" },
            "aggs": {
              "country": {
                "terms": { "field": "from_country" },
                "aggs": {
                  "dep_total_amount": {
                    "sum": {
                      "field": "amount"
                    }
                  },
                  "tx_count": {
                    "value_count": {
                      "field": "from"
                    }
                  },
                  "unique_pmid_from": {
                    "cardinality": {
                      "field": "from"
                    }
                  },
                  "unique_pmid_from_to": {
                    "cardinality": {
                      "script":{
                        "source":"return [doc['from'], doc['to']]"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "size": 10
      }`
      return querySrc;
    },

    getAucStoreCountByGameTags: function(dateFrom, dateTo, collectionId) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `
      {
        "_source": ["asset_doc_id", "asset.asset_id", "asset.contents_list", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image"],
        "query": {
          "bool": {
              "must": [
                { "terms": { "kind": ["store", "auction"] } },
                {
                  "nested": {
                    "path": "asset",
                    "query": {
                      "terms": {
                          "asset.tags": ${JSON.stringify(collectionId)}
                      }
                    }
                  }
                }
              ],
              "filter": [
                {
                    "range": {
                        "created_at": {
                            "gte": "${dateFrom}T00:00:00+08:00",
                            "lte": "${dateTo}T23:59:59+08:00",
                            "format": "strict_date_optional_time"
                        }
                    }
                }
              ]
          }
        },
        "aggs": {
          "kind": {
            "terms": { "field": "kind" },
            "aggs": {
              "dep_total_amount": {
                "sum": {
                  "script": "Float.parseFloat(doc['price'].value)"
                }
              },
              "tx_count": {
                "value_count": {
                  "field": "from"
                }
              },
              "unique_pmid_from": {
                "cardinality": {
                  "field": "from"
                }
              },
              "unique_pmid_from_to": {
                "cardinality": {
                  "script":{
                    "source":"return [doc['from'], doc['to']]"
                  }
                }
              }
            }
          }
        }
      }`
      return querySrc;
    },

    getAucStoreCountByGame: function(dateFrom, dateTo, collectionId) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `
      {
        "_source": ["asset_doc_id", "asset.asset_id", "asset.contents_list", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image"],
        "query": {
          "bool": {
              "must": [
                { "terms": { "kind": ["store", "auction"] } },
                {
                  "nested": {
                    "path": "asset",
                    "query": {
                      "terms": {
                          "asset.contents_list": [
                            "hFndVB8vMuQnO2ihBnzw",
                            "p0f6rMikuCer9C4r8trA",
                            "TKR0iDxTLXl42rAbjcoa",
                            "g3bq4ssqNmgS8yiQWuba",
                            "lK6knuQWsFqH8lZifcnF"
                        ]
                      }
                    }
                  }
                }
              ],
              "filter": [
                {
                    "range": {
                        "created_at": {
                            "gte": "${dateFrom}T00:00:00+08:00",
                            "lte": "${dateTo}T23:59:59+08:00",
                            "format": "strict_date_optional_time"
                        }
                    }
                }
              ]
          }
        },
        "aggs": {
          "kind": {
            "terms": { 
              "field": "kind",
              "order": {
                "_key": "asc"
              }
            },
            "aggs": {
              "game": {
                "nested": {
                  "path": "asset"
                },
                "aggs":{
                  "filter_asset": {
                    "filter": {
                      "bool": {
                        "filter": [
                          {
                            "term": {
                              "asset.contents_list": "${collectionId}"
                            }
                          }
                        ]
                      }
                    },
                    "aggs": {
                      "parent_doc_price": {
                        "reverse_nested": {},
                        "aggs": {
                          "dep_total_amount": {
                            "sum": {
                              "script": "Float.parseFloat(doc['price'].value)"
                            }
                          },
                          "tx_count": {
                            "value_count": {
                              "field": "from"
                            }
                          },
                          "unique_pmid_from": {
                            "cardinality": {
                              "field": "from"
                            }
                          },
                          "unique_pmid_from_to": {
                            "cardinality": {
                              "script":{
                                "source":"return [doc['from'], doc['to']]"
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "size": 10
      }`
      return querySrc;
    },

    getAucStoreCountByPmid: function(dateFrom, dateTo) {
      dateFrom = dateFrom.replace(/\//g, '-');
      dateTo = dateTo.replace(/\//g, '-');
      const querySrc = `{
        "query": {
          "bool": {
            "must": { "terms": { "kind": ["auction","store"] } },
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
          }
        },
        "aggs": {
          "kind": {
            "terms": { "field": "kind" },
            "aggs": {
              "dep_total_amount": {
                "sum": {
                  "field": "amount"
                }
              },
              "pmid_from": {
                "terms": { "field": "from", "size": 100 },
                "aggs": {
                  "dep_total_amount": {
                    "sum": {
                      "field": "amount"
                    }
                  },
                  "from_country": {
                    "terms" : {
                      "field": "from_country"
                    }
                  },
                  "tx_count": {
                    "value_count": {
                      "field": "from"
                    }
                  },
                  "unique_pmid_from_to": {
                    "cardinality": {
                      "script":{
                        "source":"return [doc['from'], doc['to']]"
                      }
                    }
                  },
                  "max_price": {
                    "max": { 
                      "field": "amount"
                    } 
                  },
                  "avg_price": {
                    "avg": { 
                      "field": "amount"
                    } 
                  },
                  "min_price": {
                    "min": { 
                      "field": "amount"
                    } 
                  },
                  "median_price": {
                    "percentiles": {
                        "field": "amount",
                        "percents": [50]
                    }
                  }
                }
              }
            }
          }
        },
        "sort" : [
          {"amount" : {"order" : "desc"}}
        ]
      }`
      return querySrc;
    },

    getAssetByKeyword: function(keyword, offset, dateRange, matchArray=false) {
      const dateFrom = dateRange[0].replace(/\//g, '-');
      const dateTo = dateRange[1].replace(/\//g, '-');

      // let termShouldArray = [], termObj = {};
      // if (matchArray) {
      //   termShouldArray.push(matchArray);
      // }
      // for (let i=0; i<keyword.length; i++) {
      //   if (keyword[i]['type'] === 'asset_name') {
      //     termObj = `
      //     {
      //       "nested": {
      //         "path": "asset.names",
      //         "query": {
      //           "query_string": {
      //             "fields": ["asset.names.value"], 
      //             "query": "*${keyword[i]['value']}*",
      //             "boost": 1
      //           }
      //         }
      //       }
      //     }`
      //     termShouldArray.push(termObj);
      //   }
      // }

      const querySrc = `{
        "_source": ["asset_doc_id", "asset.asset_id", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image", "from", "to"],
        "query": {
          "bool": {
            "must_not": [
              {
                "match": {
                    "kind": "nft_manual_collect"
                }
              }
            ],
            "must": [
              {
                "nested": {
                    "path": "asset.names",
                    "query": {
                        "wildcard": {
                            "asset.names.value": "*${keyword[0]['value']}*"
                        }
                    }
                }
              }
            ],
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
          }
        },
        "aggs": {
          "price": { 
            "sum": { 
              "script": "Float.parseFloat(doc['price'].value)"
            } 
          }
        },
        "size": 20,
        "from": ${offset},
        "sort" : [
          {"created_at" : {"order" : "desc"}}
        ]
      }`
      return querySrc;
  },

  getAssetByKeywordStore: function(keyword, offset, dateRange) {
    const dateFrom = dateRange[0].replace(/\//g, '-');
    const dateTo = dateRange[1].replace(/\//g, '-');

    const querySrc = `{
      "_source": ["asset_doc_id", "price", "from", "kind", "fee", "status", "created_at", "asset.names", "asset.asset_id"],
      "query": {
          "bool": {
            "must": [
              {
                  "match": {
                      "kind": "store"
                  }
              },
              {
                "nested": {
                    "path": "asset.names",
                    "query": {
                        "wildcard": {
                            "asset.names.value": "*${keyword[0]['value']}*"
                        }
                    }
                }
              }
            ],
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
          }
        },
        "aggs": {
          "price": { 
            "sum": { 
              "script": "Float.parseFloat(doc['price'].value)"
            } 
          }
        },
        "size": 20,
        "from": ${offset},
        "sort" : [
          {"created_at" : {"order" : "desc"}}
        ]
    }`
    return querySrc;
  },

  getAssetByKeywordAuction: function(keyword, offset, dateRange) {
    const dateFrom = dateRange[0].replace(/\//g, '-');
    const dateTo = dateRange[1].replace(/\//g, '-');

    const querySrc = `{
      "_source": ["asset_doc_id", "price", "from", "kind", "fee", "status", "created_at", "asset.names", "asset.asset_id"],
      "query": {
          "bool": {
            "must": [
              {
                  "match": {
                      "kind": "auction"
                  }
              },
              {
                "nested": {
                    "path": "asset.names",
                    "query": {
                        "wildcard": {
                            "asset.names.value": "*${keyword[0]['value']}*"
                        }
                    }
                }
              }
            ],
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
          }
        },
        "aggs": {
          "price": { 
            "sum": { 
              "script": "Float.parseFloat(doc['price'].value)"
            } 
          }
        },
        "size": 20,
        "from": ${offset},
        "sort" : [
          {"created_at" : {"order" : "desc"}}
        ]
    }`
    return querySrc;
  },

  // "_source": ["asset_doc_id", "from_type", "to_type", "price", "fee", "status", "created_at"],
  // "_source": ["asset_doc_id", "from_type", "to_type", "price", "fee", "status", "created_at", "asset.names", "asset.asset_id"],
  // "_source": ["asset_doc_id", "asset.asset_id", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image"],
  getNftTxHisotryData: function(matchArray, offset, dateRange) {
    const dateFrom = dateRange[0].replace(/\//g, '-');
    const dateTo = dateRange[1].replace(/\//g, '-');
    const querySrc = `{
      "_source": ["asset_doc_id", "asset.asset_id", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image", "from", "to"],
      "query": {
        "bool": {
            "must": ${matchArray},
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
        }
      },
      "aggs": {
        "price": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "primary": {
          "terms": {
            "field": "token_id",
            "size": 10000
          },
          "aggs": {
            "1st_count": {
              "bucket_selector": {
                "buckets_path": {
                  "doc_count": "_count"
                },
                "script": {
                  "source": "params.doc_count == 1"
                }
              }
            },
            "price": {
              "sum": { 
                "script": "Float.parseFloat(doc['price'].value)"
              } 
            }
          }
        }
      },
      "size": 20,
      "from": ${offset},
      "sort" : [
        {"created_at" : {"order" : "desc"}}
      ]
    }`
    return querySrc;
  },

  getNftLowTxHisotryData: function(matchArray, offset, dateRange, size) {
    const dateFrom = dateRange[0].replace(/\//g, '-');
    const dateTo = dateRange[1].replace(/\//g, '-');
    const querySrc = `{
      "_source": ["asset_doc_id", "asset.asset_id", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image", "from", "to"],
      "query": {
        "bool": {
            "must": ${matchArray},
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
        }
      },
      "aggs": {
        "price": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "primary": {
          "terms": {
            "field": "token_id",
            "size": 10000
          },
          "aggs": {
            "1st_count": {
              "bucket_selector": {
                "buckets_path": {
                  "doc_count": "_count"
                },
                "script": {
                  "source": "params.doc_count == 1"
                }
              }
            },
            "price": {
              "sum": { 
                "script": "Float.parseFloat(doc['price'].value)"
              } 
            }
          }
        }
      },
      "size": ${size},
      "from": ${offset},
      "sort" : [
        {"created_at" : {"order" : "desc"}}
      ]
    }`
    return querySrc;
  },

  getNftTxOneTokenIdData: function(matchArray) {
    const querySrc = `{
      "_source": ["token_id", "price", "fee", "kind", "created_at"],
      "query": {
        "bool": {
          "must": { "terms": { "kind": ["auction","store"] } }
        }
      },
      "aggs": {
        "price": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "primary": {
          "terms": {
            "field": "token_id"
          },
          "aggs": {
            "1st_count": {
              "bucket_selector": {
                "buckets_path": {
                  "doc_count": "_count"
                },
                "script": {
                  "source": "params.doc_count == 1"
                }
              }
            },
            "price": {
              "sum": { 
                "script": "Float.parseFloat(doc['price'].value)"
              } 
            }
          }
        }
      },
      "size": 10000
    }`
    return querySrc;
  },

  getEsQueryTest: function() {
    const querySrc = `{
      "_source": ["token_id", "price", "fee", "created_at"],
      "query": {
        "bool": {
          "must": { "terms": { "kind": ["auction","store"] } }
        }
      },
      "aggs": {
        "counts": {
          "terms": {
            "field": "token_id"
          },
          "aggs": {
            "1st_count": {
              "bucket_selector": {
                "buckets_path": {
                  "doc_count": "_count"
                },
                "script": {
                  "source": "params.doc_count == 1"
                }
              }
            },
            "price": {
              "sum": { 
                "script": "Float.parseFloat(doc['price'].value)"
              } 
            }
          }
        }
      },
      "size": 0
    }`
    return querySrc;
  },

  getNftTxHisotryCountData: function(matchArray, dateRange) {
    const dateFrom = dateRange[0].replace(/\//g, '-');
    const dateTo = dateRange[1].replace(/\//g, '-');
    const querySrc = `{
      "query": {
        "bool": {
          "must": ${matchArray},
          "filter": [
            {
                "range": {
                    "created_at": {
                      "gte": "${dateFrom}T00:00:00+08:00",
                      "lte": "${dateTo}T23:59:59+08:00",
                        "format": "strict_date_optional_time"
                    }
                }
            }
          ]
        }
      }
    }`
    return querySrc;
  },

  // NOT IN USE
  getJtcbScholarContractCount: function(actonName, dateRange) {
    const querySrc = `{
      "query":{
        "bool":{
          "must":[
            {
              "match_all":{}
            }
          ],
          "filter":[
            {
              "bool":{
                "must":[
                  {
                    "match":{
                      "action_name":"${actonName}"
                    }
                  }
                ]
              }
            },
            {
              "range":{
                "time_stamp":{
                  "format":"strict_date_optional_time",
                  "gte":"${dateRange[0]}T00:00:00.000Z",
                  "lte":"${dateRange[1]}T23:59:59.000Z"
                }
              }
            }
          ],
          "should":[],
          "must_not":[]
        }
      },
      "size": 0,
      "aggs": {
        "owner":{
          "cardinality":{
            "field":"owner_id"
          }
        },
        "scholar":{
          "cardinality":{
            "field":"scholar_id"
          }
        }
      }
    }`
    return querySrc;
  },

  getTotalNftPurchaseByWideGuild: function(allPmidStr, dateRange) {
    const allPmIds = allPmidStr.slice(0, -1);
    const test = '"VzIkgmro25eCI61D5JIp5VUWFPu2","Cza2PD0g2EOJBURMWMvIeRAqvRz2"'
    const querySrc = `{
      "query": {
        "bool": {
          "must": [
            { 
                "terms": { 
                    "to": [
                      ${allPmIds}
                    ] 
                } 
            },
            {
                "terms": {
                    "kind": ["store", "auction"]
                }
            }
          ],
          "filter": [
                {
                  "range": {
                    "created_at": {
                        "gte": "${dateRange[0]}T00:00:00.000Z",
                        "lte": "${dateRange[1]}T23:59:59.999Z",
                        "format": "strict_date_optional_time",
                        "time_zone":"+08:00"
                      }
                    }
                }
            ]
        }
      },
      "aggs": {
        "price": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "count": {
          "cardinality": {
            "field": "_id"
          }
        }
      },
      "size": 0    
    }`;
    return querySrc;
  },

  getTotalNftPurchaseByAll: function(dateRange) {
    const querySrc = `{
      "query": {
        "bool": {
          "must": [
            {
                "terms": {
                    "kind": ["store", "auction"]
                }
            }
          ],
          "filter": [
                {
                  "range": {
                    "created_at": {
                        "gte": "${dateRange[0]}T00:00:00.000Z",
                        "lte": "${dateRange[1]}T23:59:59.999Z",
                        "format": "strict_date_optional_time",
                        "time_zone":"+08:00"
                      }
                    }
                }
            ]
        }
      },
      "aggs": {
        "price": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "count": {
          "cardinality": {
            "field": "_id"
          }
        }
      },
      "size": 0    
    }`;
    return querySrc;
  },

  getNftHolderCount: function() {
    const querySrc = `{
      "size": 0, 
      "aggs": {
        "uid_count": {
          "value_count" : {
            "field": "uid"
          }
        },
        "price": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "unique_count": {
          "cardinality": {
            "field": "uid"
          }
        }
      }
    }`;
    return querySrc;
  },

  getStakingStatData: function() {
    const querySrc = `{
      "query": {
        "bool": {
          "must": [
            { 
                "terms": { 
                  "status": [0] 
                } 
            }
          ],
          "filter": [
            {
                "match_all": {}
            }
          ]
        }
      },
      "size": 0,
      "aggs": {
        "terms": {
          "terms": {
            "field": "annual_interest",
            "size": 3
          },
          "aggs": {
            "dep_amount": {
              "sum": {
                "script": "Float.parseFloat(doc['deposit_amount'].value)"
              } 
            },
            "interest_paid": {
              "sum": {
                "script": "Float.parseFloat(doc['interest'].value)"
              } 
            }
          }
        }
      }
    }`;
    return querySrc;
  },

  getNftOwnTrendData: function(dateRange) {
    const querySrc = `{
      "query": {
        "bool": {
          "must": [
            { 
              "terms": { 
                "status": [-1, 0, 1, 2, 3, 4, 5, 6]
              } 
            }
          ],
          "filter": [
            {
                "range": {
                    "created_at": {
                        "gte": "${dateRange[0]}T00:00:00+08:00",
                        "lte": "${dateRange[1]}T23:59:59+08:00",
                        "format": "strict_date_optional_time"
                    }
                }
            }
          ]
        }
      },
      "aggs": {
        "nft_own": {
          "date_histogram": {
              "field": "created_at",
              "calendar_interval": "1d",
              "time_zone": "Asia/Singapore",
              "min_doc_count": 0,
              "order": {
                "_key": "asc"
              }
          },
          "aggs": {
            "count_pmid": {
              "value_count" : {
                "field": "uid"
              }
            },
            "unique_pmid": {
              "cardinality": {
                "field": "uid"
              }
            },
            "status_count": {
              "terms" : {
                "field": "status",
                "order": {
                  "_key": "asc"
                }
              }
            },
            "price": { 
              "sum": { 
                "script": "Float.parseFloat(doc['price'].value)"
              } 
            }
          }
        }
      },
      "size": 0
    }`;
    return querySrc;
  },

  getNftDepTrendKind: function(dateRange) {
    const querySrc = `{
      "query": {
        "bool": {
          "must": [
            {
              "match_all": {}
            }
          ],
          "filter": [
            {
                "range": {
                    "created_at": {
                        "gte": "${dateRange[0]}T00:00:00+08:00",
                        "lte": "${dateRange[1]}T23:59:59+08:00",
                        "format": "strict_date_optional_time"
                    }
                }
            }
          ]
        }
      },
      "aggs": {
        "terms": {
          "terms": {
            "field": "kind",
            "size": 10,
            "order": {
              "_key": "asc"
              }
          }
        }
      },
      "size": 0
    }`;
    return querySrc;
  },

  getNftDepTrendCountry: function(dateRange, kind) {
    const querySrc = `{
      "query": {
        "bool": {
          "must": [
            {
              "match_all": {}
            }
          ],
          "filter": [
            {
              "terms": {
                "kind": [${kind}]
              }
            },
            {
                "range": {
                    "created_at": {
                        "gte": "${dateRange[0]}T00:00:00+08:00",
                        "lte": "${dateRange[1]}T23:59:59+08:00",
                        "format": "strict_date_optional_time"
                    }
                }
            }
          ]
        }
      },
      "aggs": {
        "from_country_count": {
          "terms" : {
            "field": "from_country",
            "order": {
              "_count": "desc"
            }
          }
        },
        "to_country_count": {
          "terms" : {
            "field": "to_country",
            "order": {
              "_count": "desc"
            }
          }
        }
      },
      "size": 0
    }`;
    return querySrc;
  },

  getNftDepTrendData: function(dateRange, kind, coutry) {
    let countryQuery = ``;
    if (coutry !== "") {
      countryQuery = `{
        "terms": {
          "from_country": [${coutry}]
        }
      },
      `
    }
    const querySrc = `
    {
      "query": {
        "bool": {
          "must": [
            {
              "match_all": {}
            }
          ],
          "filter": [
            {
              "terms": {
                "kind": [${kind}]
              }
            },
            ${countryQuery}
            {
              "range": {
                  "created_at": {
                      "gte": "${dateRange[0]}T00:00:00+08:00",
                      "lte": "${dateRange[1]}T23:59:59+08:00",
                      "format": "strict_date_optional_time"
                  }
              }
            }
          ]
        }
      },
      "aggs": {
        "tx_data": {
          "date_histogram": {
              "field": "created_at",
              "calendar_interval": "1d",
              "time_zone": "Asia/Singapore",
              "min_doc_count": 0,
              "order": {
                "_key": "asc"
              }
          },
          "aggs": {
            "kind": {
              "terms" : {
                "field": "kind",
                "order": {
                  "_count": "desc"
                }
              },
              "aggs": {
                "amount": { 
                  "sum": { 
                    "field": "amount"
                  } 
                }
              }
            },
            "from_country_count": {
              "terms" : {
                "field": "from_country",
                "order": {
                  "_count": "desc"
                }
              }
            },
            "to_country_count": {
              "terms" : {
                "field": "to_country",
                "order": {
                  "_count": "desc"
                }
              }
            },
            "dep": { 
              "sum": { 
                "field": "amount"
              } 
            }
          }
        }
      },
      "size": 0
    }
    `;
    return querySrc;
  },

  // get trend data by game
  getNftTrendDataByGameStats: function(dateFrom, dateTo, collectionId) {
    dateFrom = dateFrom.replace(/\//g, '-');
    dateTo = dateTo.replace(/\//g, '-');
    const unixstamp = [
      moment(dateFrom+" 00:00:00+8:00").format('x'),
      moment(dateTo+" 23:59:59+8:00").format('x')
    ];
    const querySrc = `
    {
      "_source": ["asset_doc_id", "asset.asset_id", "asset.contents_list", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image"],
      "query": {
        "bool": {
            "must": [
              { "terms": { "kind": ["store", "auction"] } },
              {
                "nested": {
                  "path": "asset",
                  "query": {
                    "terms": {
                        "asset.contents_list": [
                          "hFndVB8vMuQnO2ihBnzw",
                          "p0f6rMikuCer9C4r8trA",
                          "TKR0iDxTLXl42rAbjcoa",
                          "g3bq4ssqNmgS8yiQWuba",
                          "lK6knuQWsFqH8lZifcnF"
                      ]
                    }
                  }
                }
              }
            ],
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateFrom}T00:00:00+08:00",
                          "lte": "${dateTo}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
        }
      },
      "aggs": {
        "kind": {
          "terms": { 
            "field": "kind",
            "order": {
              "_key": "asc"
            }
          },
          "aggs": {
            "game": {
              "nested": {
                "path": "asset"
              },
              "aggs":{
                "filter_asset": {
                  "filter": {
                    "bool": {
                      "filter": [
                        {
                          "term": {
                            "asset.contents_list": "${collectionId}"
                          }
                        }
                      ]
                    }
                  },
                  "aggs": {
                    "parent_doc_price": {
                      "reverse_nested": {},
                      "aggs": {
                        "dep_total_amount": {
                          "sum": {
                            "script": "Float.parseFloat(doc['price'].value)"
                          }
                        },
                        "tx_count": {
                          "value_count": {
                            "field": "from"
                          }
                        },
                        "unique_pmid_from": {
                          "cardinality": {
                            "field": "from"
                          }
                        },
                        "unique_pmid_from_to": {
                          "cardinality": {
                            "script":{
                              "source":"return [doc['from'], doc['to']]"
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      },
      "size": 1000
    }`
    return querySrc;
  },

  // p0f6rMikuCer9C4r8trA : luckyFarmer
  // hFndVB8vMuQnO2ihBnzw : playmining nft
  // TKR0iDxTLXl42rAbjcoa : cookinburger
  // g3bq4ssqNmgS8yiQWuba : Kamui Verse
  // lK6knuQWsFqH8lZifcnF : Dragon Ramen
  getNftTrendDataByGame: function(dateRange, collectionId) {
    const unixstamp = [
      moment(dateRange[0]+" 00:00:00+8:00").format('x'),
      moment(dateRange[1]+" 23:59:59+8:00").format('x')
    ];
    const querySrc = `{
      "_source": ["asset_doc_id", "asset.asset_id", "asset.contents_list", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image"],
      "query": {
        "bool": {
            "must": [
              { "terms": { "kind": ["store", "auction"] } },
              {
                "nested": {
                  "path": "asset",
                  "query": {
                    "terms": {
                        "asset.contents_list": [
                          "${collectionId}"
                      ]
                    }
                  }
                }
              }
            ],
            "filter": [
              {
                  "range": {
                      "created_at": {
                          "gte": "${dateRange[0]}T00:00:00+08:00",
                          "lte": "${dateRange[1]}T23:59:59+08:00",
                          "format": "strict_date_optional_time"
                      }
                  }
              }
            ]
        }
      },
      "aggs": {
        "kind": {
          "terms": {
            "field": "kind",
            "order": {
              "_key": "asc"
            }
          },
          "aggs": {
            "nft_own": {
              "date_histogram": {
                "min_doc_count": 0,
                "field": "created_at",
                "calendar_interval": "1d",
                "time_zone": "Asia/Singapore",
                "format": "yyyy-MM-dd",
                "order": {
                  "_key": "asc"
                },
                "extended_bounds": {
                  "min": ${unixstamp[0]}, 
                  "max": ${unixstamp[1]}
                }
              },
              "aggs": {
                "summary": {
                  "terms" : {
                    "field": "kind",
                    "order": {
                      "_count": "desc"
                    }
                  },
                  "aggs": {
                    "amount": { 
                      "sum": {
                        "script": "Float.parseFloat(doc['price'].value)"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`;
    return querySrc;
  },

  // JTCB nft trend by rarity
  getJtcbNftTrendDataByRare: function(dateRange, rarity) {
    const unixstamp = [
      moment(dateRange[0]+" 00:00:00+8:00").format('x'),
      moment(dateRange[1]+" 23:59:59+8:00").format('x')
    ];
    const querySrc = `{
      "_source": ["asset_doc_id", "asset.asset_id", "asset.contents_list", "token_id", "price", "fee", "kind", "created_at", "asset.names", "asset.card_image", "to"],
      "query": {
        "bool": {
          "must": [
            { "terms": { "kind": ["store", "auction"] } },
            {
              "nested": {
                "path": "asset",
                "query": {
                  "terms": {
                      "asset.tags": ${JSON.stringify(rarity)}
                  }
                }
              }
            }
          ],
          "filter": [
            {
                "range": {
                    "created_at": {
                        "gte": "${dateRange[0]}T00:00:00+08:00",
                        "lte": "${dateRange[1]}T23:59:59+08:00",
                        "format": "strict_date_optional_time"
                    }
                }
            }
          ]
        }
      },
      "aggs": {
        "kind": {
          "terms": { 
            "field": "kind",
            "order": {
              "_key": "asc"
            }
          },
          "aggs": {
            "nft_own": {
              "date_histogram": {
                "min_doc_count": 0,
                "field": "created_at",
                "calendar_interval": "1d",
                "time_zone": "Asia/Singapore",
                "format": "yyyy-MM-dd",
                "order": {
                  "_key": "asc"
                },
                "extended_bounds": {
                  "min": ${unixstamp[0]}, 
                  "max": ${unixstamp[1]}
                }
              },
              "aggs": {
                "rarerity": {
                  "nested": {
                    "path": "asset"
                  },
                  "aggs":{
                    "filter_asset": {
                      "filter": {
                        "bool": {
                          "filter": [
                            {
                              "terms": {
                                "asset.tags": ${JSON.stringify(rarity)}
                              }
                            }
                          ]
                        }
                      },
                      "aggs": {
                        "parent_doc_price": {
                          "reverse_nested": {},
                          "aggs": {
                            "dep_total_amount": {
                              "sum": {
                                "script": "Float.parseFloat(doc['price'].value)"
                              }
                            },
                            "unique_pmid_from": {
                              "cardinality": {
                                "field": "from"
                              }
                            },
                            "unique_pmid_from_to": {
                              "cardinality": {
                                "script":{
                                  "source":"return [doc['from'], doc['to']]"
                                }
                              }
                            },
                            "to_country": {
                              "terms" : {
                                "field": "to_country"
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  }`;
    return querySrc;
  },

  getNftHoldersOffsetData: function(contentListId, minDate, offset, size) {
    // const dateFrom = dateRange[0].replace(/\//g, '-');
    // const dateTo = dateRange[1].replace(/\//g, '-');
    const querySrc = `{
      "_source": [
        "uid", "asset_doc_id", "asset.asset_id", "token_id", "price", "created_at", 
        "asset.names", "asset.card_image", "asset.thumbnail", "updated_at"
      ],
      "query": {
        "bool": {
            "must": [
                    {
                        "match": {
                            "status": 0
                        }
                    },
                    {
                    "nested": {
                        "path": "asset",
                        "query": {
                            "terms": {
                                "asset.contents_list": ${JSON.stringify(contentListId)}
                            }
                        }
                    }
                }
            ],
            "filter": [
                {
                    "range": {
                        "created_at": {
                            "gte": "${minDate}T00:00:00+08:00",
                            "format": "strict_date_optional_time"
                        }
                    }
                }
            ]
        }
      },
      "from": ${offset},
      "size": ${size},
      "sort" : [
        {"updated_at" : {"order" : "desc"}}
      ]
    }`
    return querySrc;
  },

  getNftHoldersStatsData: function(contentListId, minDate) {
    const querySrc = `{
      "_source": [
        "uid", "asset_doc_id", "asset.asset_id", "token_id", "price", "created_at", 
        "asset.names", "asset.card_image", "asset.thumbnail"
      ],
      "query": {
        "bool": {
            "must": [
                    {
                        "match": {
                            "status": 0
                        }
                    },
                    {
                    "nested": {
                        "path": "asset",
                        "query": {
                            "terms": {
                                "asset.contents_list": ${JSON.stringify(contentListId)}
                            }
                        }
                    }
                }
            ],
            "filter": [
                {
                    "range": {
                        "created_at": {
                            "gte": "${minDate}T00:00:00+08:00",
                            "format": "strict_date_optional_time"
                        }
                    }
                }
            ]
        }
      },
      "aggs": {
        "holder_count": {
          "value_count" : {
            "field": "uid"
          }
        },
        "price_total": { 
          "sum": { 
            "script": "Float.parseFloat(doc['price'].value)"
          } 
        },
        "unique_holder_count": {
          "cardinality": {
            "field": "uid"
          }
        }
      },
      "size": 0
    }`
    return querySrc;
  },

  getNftHoldersStatsDataByDate: function(contentListId, minDate) {
    const querySrc = `{
      "_source": [
        "uid", "asset_doc_id", "asset.asset_id", "token_id", "price", "created_at", 
        "asset.names", "asset.card_image", "asset.thumbnail"
      ],
      "query": {
        "bool": {
            "must": [
                    {
                        "match": {
                            "status": 0
                        }
                    },
                    {
                    "nested": {
                        "path": "asset",
                        "query": {
                            "terms": {
                                "asset.contents_list": ${JSON.stringify(contentListId)}
                            }
                        }
                    }
                }
            ],
            "filter": [
                {
                    "range": {
                        "created_at": {
                            "gte": "${minDate}T00:00:00+08:00",
                            "format": "strict_date_optional_time"
                        }
                    }
                }
            ]
        }
      },
      "aggs": {
        "nft_own": {
          "date_histogram": {
              "field": "created_at",
              "calendar_interval": "1d",
              "time_zone": "Asia/Singapore",
              "min_doc_count": 0,
              "order": {
                "_key": "desc"
              }
          },
          "aggs": {
            "uid_count": {
              "value_count" : {
                "field": "uid"
              }
            },
            "price": { 
              "sum": { 
                "script": "Float.parseFloat(doc['price'].value)"
              } 
            },
            "unique_pmid": {
              "cardinality": {
                "field": "uid"
              }
            }
          }
        }
      },
      "sort" : [
        {"created_at" : {"order" : "desc"}}
      ]
    }`
    return querySrc;
  },

  getNftHoldersPmidData: function(pmIdArray, size) {
    const querySrc = `{
      "query": {
          "terms": {
              "_id":  ${JSON.stringify(pmIdArray)}
          }
      },
      "size" : ${size}
    }`
    return querySrc;
  },
}

export default ElasticSearch;