import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setKpiSalesData,
} from "actions";
import { getJwtToken, getDataFromRDS } from "api";
import { KPI, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = (state) => state.page.currentPage;
export const kpiSalesDateRange = (state) => state.kpi.kpiSalesDateRange;
export const kpiSalesDataSizeSelected = state => state.kpi.kpiSalesDataSizeSelected;

export function* getKpiSalesDataByGame(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    let gameTarget = action.payload.split("/")[2];
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

    const rangeTarget = yield select(kpiSalesDateRange);
    const sizeTarget = yield select(kpiSalesDataSizeSelected);
    const countryTarget = []; //yield select(activeUsersCountrySelected);
    const ridTarget = []; //yield select(activeUsersRidSelected);
    let xAxisDate = [],
      dataDauArray = [],
      dataSalesVolumeArray = [],
      dataArpuArray = [],
      dataPaidUniqueArray = [],
      dataArppuArray = [];

    // data 1: DAU by Date
    const sqlQueryActiveUsers = H.SqlHelper.getActiveUsersDataByGame(
      gameTarget,
      sizeTarget,
      rangeTarget,
      countryTarget,
      ridTarget,
      true
    );

    // data2: All sales amount by Date
    const sqlQueryDailySalesByGame = H.SqlHelper.getDailySalesByGame(
      gameTarget,
      contentId,
      rangeTarget,
      countryTarget,
      ridTarget,
      sizeTarget,
    );

    // Fetch data
    const [dataDau, dataSales] = yield all([
      call(
        getDataFromRDS,
        sqlQueryActiveUsers,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        sqlQueryDailySalesByGame,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ]);

    const dataDauReturn = JSON.parse(dataDau.body);
    const dataSalesReturn = JSON.parse(dataSales.body);

    // Set xAxisDate
    _.each(dataDauReturn, function (data, index) {
      if (sizeTarget.value === 'dau') {
        xAxisDate.push(data[0]['stringValue']);
      } else if (sizeTarget.value === 'wau') {
          xAxisDate.push(
              moment(data[2]['longValue'].toString()).add(data[0]['longValue'], 'weeks').startOf('week').format('YYYY-MM-DD')+ ' ~ '
              + moment(data[2]['longValue'].toString()).add(data[0]['longValue'], 'weeks').startOf('week').add(6, 'days').format('YYYY-MM-DD')
          )
      } else {
          xAxisDate.push(dataSalesReturn[index][0]['stringValue']+'月');
      }
    });

    // set data array
    if (sizeTarget.value === 'dau') {
      _.each(dataDauReturn, function (data, index) {
        dataDauArray.push(data[1]["longValue"]);
        const idx = dataSalesReturn.findIndex(innerArray => innerArray.some(obj => obj.stringValue === data[0]["stringValue"]));
  
        if (idx === -1) {
          dataSalesVolumeArray.push(0);
          dataPaidUniqueArray.push(0);
          dataArpuArray.push(0);
          dataArppuArray.push(0);
        } else {
          dataSalesVolumeArray.push(parseInt(dataSalesReturn[idx][2]["stringValue"]));
          dataPaidUniqueArray.push(dataSalesReturn[idx][1]["longValue"]);
          dataArpuArray.push(Math.round(parseInt(dataSalesReturn[idx][2]["stringValue"]) / data[1]["longValue"]));
          dataArppuArray.push(parseInt(dataSalesReturn[idx][3]["stringValue"]));
        }
      });
    } else {
      _.each(dataDauReturn, function (data, index) {
        dataDauArray.push(data[1]["longValue"]);
        dataSalesVolumeArray.push(
          parseInt(dataSalesReturn[index][2]["stringValue"])
        );
        dataPaidUniqueArray.push(dataSalesReturn[index][1]["longValue"]);
        dataArpuArray.push(
          Math.round(
            parseInt(dataSalesReturn[index][2]["stringValue"]) /
              data[1]["longValue"]
          )
        );
        dataArppuArray.push(parseInt(dataSalesReturn[index][3]["stringValue"]));
      });
    }

    const kpiSalesData = {
        date: xAxisDate,
        arpu: dataArpuArray,
        arppu: dataArppuArray,
        dau: dataDauArray,
        paidUnique: dataPaidUniqueArray,
        salesVolume: dataSalesVolumeArray,
    };

    yield put(setKpiSalesData([kpiSalesData]));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(KPI.GET_KPI_SALES_DATA, getKpiSalesDataByGame);
}
