import { A2E } from "../../constants";
import moment from "moment";

const initialState = {
  campaignTotal: 0,
  campaigns: [],
  campaignData: [],
  campaignSelect: [],
  customDateRange: [],
  alias: "",
  reportTab: "byDate",
  dataSummary: [],
  dataByDate: [],
  dataByCreative: [],
  dataByQuiz: [],
  tabDataLoader: false,
};

export default function a2eReducer(state = initialState, action) {
  switch (action.type) {
    case A2E.SET_CAMPAIGN_TOTAL:
      return {
        ...state,
        campaignTotal: action.payload,
      };
    case A2E.SET_A2E_CAMPAIGNS:
      return {
        ...state,
        campaigns: action.payload,
      };
    case A2E.SET_A2E_CAMPAIGN_SELECT:
      return {
        ...state,
        campaignSelect: action.payload,
      };
    case A2E.SET_CAMPAIGN_DATA:
      return {
        ...state,
        campaignData: action.payload,
      };
    case A2E.SET_CUSTOM_DATE_RANGE:
      return {
        ...state,
        customDateRange: action.payload,
      };
    case A2E.SET_ALIAS:
      return {
        ...state,
        alias: action.payload,
      };
    case A2E.SET_REPORT_TAB:
      return {
        ...state,
        reportTab: action.payload,
      };
    case A2E.SET_A2E_DATA_SUMMARY:
      return {
        ...state,
        dataSummary: action.payload,
      };
    case A2E.SET_A2E_DATA_BY_DATE:
      return {
        ...state,
        dataByDate: action.payload,
      };
    case A2E.SET_A2E_DATA_BY_CREATIVE:
      return {
        ...state,
        dataByCreative: action.payload,
      };
    case A2E.SET_A2E_DATA_BY_QUIZ:
      return {
        ...state,
        dataByQuiz: action.payload,
      };
    case A2E.SET_TAB_DATA_LOADING:
      return {
        ...state,
        tabDataLoader: action.payload,
      };
    default:
      return state;
  }
}
