const trendOption = [
    { name: 'ストア販売', value: 'store' },
    { name: "オークション取引", value:"auction"},
    // { name: "アクティブWallet", value:"activeWallet"},
    { name: '分配金', value: 'distributions' },
    { name: "配布DEP", value:"distribute"},
    { name: "出金手数料", value:"withdrawal"},
    { name: 'エアードロップ', value: 'airdrop' },
    { name: "ステーキング", value:"staking"},
    { name: "ステーキング預け入れ", value:"staking_apply"},
    { name: "ステーキング利息", value:"staking_interest"},
    { name: "ステーキング解約", value:"staking_cancel"},
];

const nftOwnTrendOption = [
    { name: '全て', value: 'all' },
    { name: '利用可能', value: 0 },
    { name: "ロック中", value: 1},
    { name: 'BANKAPIへのリクエスト中', value: 3 },
    { name: 'BANKAPIへのリクエスト中にエラー発生', value: 4 },
    { name: 'コード販売から入手', value: 5 },
    { name: 'ゲーム側での貸し出し中', value: 6 }
];

export default {
    trendOption,
    nftOwnTrendOption,
};
