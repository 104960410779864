import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import * as H from 'helper';
import * as V from 'styles/variables';

const useStyles = makeStyles((theme) => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 120,
    },
    chartContainer: {
        marginTop: -8,
        width: '100%',
        display: 'block'
    }
}));

const BarChart2 = ({
    legend, xAisData, dataLoading, seriesData, chartType, comparisonDateDiff
}) => {
    const classes = useStyles();

    const labelOption = {
        show: true,
        position: 'top',
        distance: 5,
        formatter: function (obj) {
            const percentValue = obj.name === 'PlayMining' ? 100 : (obj.data.value/seriesData[0] * 100).toFixed(2);
            return H.FormatNumStrHelper.formatCommaThousand(obj.data.value) + ` (${percentValue}%)`;
        },
        fontSize: 14,
        color: '#4b4b4c',
    }

    useEffect(() => {
        echarts.registerTheme('BarChartTheme', ChartStyle);
    }, []);

    return (
        <div className={classes.chartContainer}>
            {dataLoading === true ? (
                <div className={classes.progress}><CircularProgress /></div>
            ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            show: false,
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                        },
                        grid: {
                            top: 42,
                            right: 8,
                            bottom: 20,
                            left: 8,
                        },
                        legend: {
                            show: false,
                            data: legend
                        },
                        xAxis: {
                            type: "category",
                            data: xAisData,
                            axisTick: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                            axisLabel: {
                                interval: 0,
                            }
                        },
                        yAxis: {
                            show: false,
                            type: 'value',
                            splitLine: {
                                show: false,
                            },
                        },
                        series: [
                            {
                                type: 'bar',
                                label: labelOption,
                                barMaxWidth: 25,
                                barGap: '8%',
                                labelLine: {
                                    show: false
                                },
                                data: [
                                    {
                                        value: seriesData[0],
                                        itemStyle: {
                                            color: V.playmining,
                                        }
                                    },
                                    {
                                        value: seriesData[1],
                                        itemStyle: {
                                            color: V.nftdep,
                                        }
                                    },
                                    {
                                        value: seriesData[4],
                                        itemStyle: {
                                            color: V.cookinburger,
                                        }
                                    },
                                    {
                                        value: seriesData[5],
                                        itemStyle: {
                                            color: V.dragonramen,
                                        }
                                    },
                                    {
                                        value: seriesData[6],
                                        itemStyle: {
                                            color: V.graffitiracer,
                                        }
                                    },
                                    {
                                        value: seriesData[2],
                                        itemStyle: {
                                            color: V.jobtribes,
                                        }
                                    },
                                    {
                                        value: seriesData[7],
                                        itemStyle: {
                                            color: V.lostarchvie,
                                        }
                                    },
                                    {
                                        value: seriesData[3],
                                        itemStyle: {
                                            color: V.luckyfarmer,
                                        }
                                    },
                                    {
                                        value: seriesData[8],
                                        itemStyle: {
                                            color: V.roguerollrulers,
                                        }
                                    },
                                    {
                                        value: seriesData[9],
                                        itemStyle: {
                                            color: V.bouncybunny,
                                        }
                                    },
                                ]
                            },
                        ],
                    }}
                    theme='BarChartTheme'
                    style={{height: '190px', width: '100%'}}
                />
            )}
        </div>
    );
};

BarChart2.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    comparisonDateDiff: state.page.comparisonDateDiff,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(BarChart2));
