import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid, Chip, Typography, Tabs, Tab, Box } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {
  setCurrentPage,
  getInGameItemSalesTrendData,
  getInGameItemSalesTrendItemsData,
  setInGameItemSalesTrendType,
  setInGameItemSalesTrendItemSelected,
} from "actions";
import * as C from "components";
import * as H from "helper";
import * as V from "styles/variables";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const InGameSalesTrend = ({
  pageTopLoader,
  dateRange,
  loading,
  typeSelected,
  itemSelected,
  dataChartCount,
  dataChartAmount,
  dataChartCountUnique,
  dataChartCountDep,
  dataChartAmountDep,
  dataChartCountUniqueDep,
  dataTable,
  dataItems,
  setCurrentPage,
  getInGameItemSalesTrendData,
  getInGameItemSalesTrendItemsData,
  setInGameItemSalesTrendItemSelected,
  dataChartCountByItem,
  dataChartAmountByItem,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [chartDataDep, setChartDataDep] = useState([]);
  const [chartDataByItem, setChartDataByItem] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [gameLabel, setGameLabel] = useState("");
  const [itemTableData, setItemTableData] = useState([]);
  const [grandTotalCount, setGranTotalCount] = useState(0);
  const [grandTotalCountByDep, setGranTotalCountByDep] = useState(0);
  const [grandTotalDep, setGranTotalDep] = useState(0);
  const [grandTotalDepByDep, setGranTotalDepByDep] = useState(0);
  const [legendData, setLegendData] = useState([]);
  const [value, setValue] = React.useState(0);
  const [itemRankTable, setItemRankTable] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    const game = window.location.pathname.split("/");
    getInGameItemSalesTrendData(game[2]);
    getInGameItemSalesTrendItemsData(game[2]);
  }, []);

  useEffect(() => {
    const game = window.location.pathname.split("/");
    getInGameItemSalesTrendData(game[2]);
    getInGameItemSalesTrendItemsData(game[2]);
    setGameLabel(game[2] === "ingame-sale"
      ? "PlayMining NFT"
      : H.ContentsHelper.getContentsName(game[2])
    );
    setInGameItemSalesTrendItemSelected([]);
  }, [window.location.pathname]);

  useEffect(() => {
    // combine fiat data
    let dataCombined = [];
    if (dataChartCount.length === dataChartAmount.length) {
      _.each(dataChartCount, function(countData, index) {
        dataCombined.push(countData);
        dataCombined.push(dataChartAmount[index]);
      });
      if (dataChartCountUnique.length > 0) {
        dataCombined.splice(1, 0, dataChartCountUnique[0]);
      }
      setChartData(dataCombined);
    }

    let grandTotalCount = 0, grandTotalDep = 0;
    if (dataChartCount.length > 0) {
      _.each(dataChartCount, function(data, index) {
        grandTotalCount = grandTotalCount + _.sum(data["data"]);
      });
      setGranTotalCount(Number(grandTotalCount));
    }
    if (dataChartAmount.length > 0) {
      _.each(dataChartAmount, function(data, index) {
        grandTotalDep = grandTotalDep + _.sum(data["data"]);
      });
      setGranTotalDep(Number(grandTotalDep));
    }

    if (dataCombined !== undefined && dataCombined.length > 0) {
      setChartState(true);
    }
  }, [dataChartCount, dataChartAmount, dataChartCountUnique])

  useEffect(() => {
    // combine dep data
    let dataCombined = [];
    if (dataChartCountDep.length === dataChartAmountDep.length) {
      _.each(dataChartCountDep, function(countData, index) {
        dataCombined.push(countData);
        dataCombined.push(dataChartAmountDep[index]);
      });
      if (dataChartCountUniqueDep.length > 0) {
        dataCombined.splice(1, 0, dataChartCountUniqueDep[0]);
      }
      setChartDataDep(dataCombined);
    }

    let grandTotalCount = 0, grandTotalDep = 0;
    if (dataChartCountDep.length > 0) {
      _.each(dataChartCountDep, function(data, index) {
        grandTotalCount = grandTotalCount + _.sum(data["data"]);
      });
      setGranTotalCountByDep(Number(grandTotalCount));
    }
    if (dataChartAmountDep.length > 0) {
      _.each(dataChartAmountDep, function(data, index) {
        grandTotalDep = grandTotalDep + _.sum(data["data"]);
      });
      setGranTotalDepByDep(Number(grandTotalDep));
    }

    // set legend
    let legendArray = ["FIAT 件数", "FIAT ユニークユーザー", "FIAT DEP総額", "DEP 件数", "DEP ユニークユーザー", "DEP DEP総額"];
    setLegendData(legendArray);
  }, [dataChartCountDep, dataChartAmountDep, dataChartCountUniqueDep])

  useEffect(() => {
    // combine dep data
    let dataCombined = [];
    if (dataChartCountByItem.length === dataChartAmountByItem.length && dataChartCountByItem.length > 0) {
      _.each(dataChartCountByItem, function(countData, index) {
        dataCombined.push(countData);
        dataCombined.push(dataChartAmountByItem[index]);
      });
      setChartDataByItem(dataCombined);

      // set item legend
      let legendArray = legendData;
      if (itemSelected.length > 0) {
        _.each(itemSelected, function (item) {
          legendArray.push(`${item} 件数`);
          legendArray.push(`${item} DEP額`);
        });
      }
      setLegendData(legendData.concat(legendArray));
    } else {
      setChartDataByItem([]);
    }
  }, [dataChartCountByItem, dataChartAmountByItem])

  useEffect(() => {
    if (dataTable !== undefined && dataTable.amount !== undefined) {
      if (value === 0) {
        setItemTableData(dataTable.amount.fiat);
      } else if (value === 1) {
        setItemTableData(dataTable.amount.dep);
      } else {
        setItemTableData(dataTable.amount.all);
      }
    }
  }, [dataTable, value]);

  useEffect(() => {
    setItemRankTable(
      [
        {
          title: "#",
          field: "rank",
          width: 50,
          cellStyle: { textAlign: "center" },
        },
        {
          title: "アイテム",
          field: "item",
          width: 150,
          cellStyle: { textAlign: "left" },
        },
        {
          title: "総額",
          field: "value",
          width: 200,
          type: "numeric",
          cellStyle: { textAlign: "center" },
          render: (rowData) => (
            <div>{H.FormatNumStrHelper.formatCommaThousand(rowData.value)}</div>
          ),
        },
        {
          title: "全体比率",
          field: "ratio",
          type: "numeric",
          cellStyle: { textAlign: "left", marginLeft: 32 },
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex", height: 16 }}>
                <div style={{ paddingRight: 16, width: 24 }}>{rowData.ratio}%</div>
                <div
                  style={{
                    backgroundColor: "#65C9A5",
                    width: rowData.ratio + "%",
                    marginLeft: 20,
                    marginTop: 3,
                  }}
                ></div>
              </div>
            </Fragment>
          ),
        },
      ]
    )
  }, [typeSelected]);

  const getSalesSummaryData = () => {
    if (grandTotalCount === 0) {
      return;
    }
    let countValuel = grandTotalCount,
      totalDepValue = grandTotalDep,
      avgDepValue = (totalDepValue / countValuel).toFixed(0),
      uniqueValue = 0;
      // avgDepValue = (totalDepValue / countValuel).toFixed(0);
    let countValueByDep = grandTotalCountByDep,
    totalDepValueByDep = grandTotalDepByDep,
    avgDepValueByDep = (totalDepValueByDep / countValueByDep).toFixed(0),
    uniqueValueByDep = 0;
    return (
      <div style={{fontSize: 13, marginLeft: 32}}>
        FIAT課金: DEP額：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)}{"　|　"}
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        平均（件）：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(avgDepValue) : 0}{"　|　"}
        {/* ユニーク：{H.FormatNumStrHelper.formatCommaThousand(uniqueValue)} {"　|　"}
        ユニークDEP平均：{avgDepValue !== "NaN" ? uniqueValue === 0 ? 0 : H.FormatNumStrHelper.formatCommaThousand(totalDepValue/uniqueValue) : 0} */}
        DEP課金: DEP額：{H.FormatNumStrHelper.formatCommaThousand(totalDepValueByDep)}{"　|　"}
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValueByDep)} {"　|　"}
        平均（件）：{avgDepValueByDep !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(avgDepValueByDep) : 0}{"　|　"}
      </div>
    );
  };

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          direction="row"
          className={classes.sectionTop}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>{gameLabel}</Typography>
              <Typography>InGame 売上</Typography>
              <Typography variant="subtitle1">取引トレンド</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2023/03/27"} />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          className={classes.gridSection}
        >
          <Grid item xs={12} md={12} lg={8}>
            {loading.chart ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : chartState ? (
              chartData[0]["data"].length > 0 ? (
                <Fragment>
                  {getSalesSummaryData()}
                  <C.LineChartCountDepTrend
                    legend={legendData}
                    lblSaveImage={"InGame 売上 取引トレンド"}
                    xAisData={chartData[0]["date"]}
                    seriesData={chartDataByItem.length < 1
                        ? chartData.concat(chartDataDep)
                        : [].concat.apply([], [chartData, chartDataDep, chartDataByItem])}
                    chartType={"line"}
                    height={350}
                  />
                </Fragment>
              ) : (
                <div className={classes.selectTxt}>
                  <Chip
                    label="No Data"
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            ) : null}
            <C.InGameItemTrendTable
              title=""
              noDataText={pageTopLoader ? "ロード中.." : "表示可能なデータがありません"}
              trendData={chartDataByItem.length < 1
                ? chartData.concat(chartDataDep)
                : [].concat.apply([], [chartData, chartDataDep, chartDataByItem])}
              dateRange={dateRange}
              loading={loading.chart}
              exportTitle={`InGame売上 取引トレンド - ${gameLabel}`}
              legend={legendData}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="FIAT課金" {...a11yProps(0)} />
              <Tab label="DEP課金" {...a11yProps(1)} />
              <Tab label="全課金" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <C.RankTableSelect
                title="FIAT課金 アイテム"
                tableData={itemTableData}
                columnData={itemRankTable}
                loading={loading.table}
                height={650}
                search={true}
                type="fiat"
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <C.RankTableSelect
                title="DEP課金 アイテム"
                tableData={itemTableData}
                columnData={itemRankTable}
                loading={loading.table}
                height={650}
                search={true}
                type="dep"
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <C.RankTableSelect
                title="全課金 アイテム"
                tableData={itemTableData}
                columnData={itemRankTable}
                loading={loading.table}
                height={650}
                search={true}
                type="all"
              />
            </TabPanel>
          </Grid>
        </Grid>
      </C.ContentWrapper>
    </Fragment>
  );
};

InGameSalesTrend.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  dateRange: state.page.dateRange,
  loading: state.inGameItemSalesTrend.loading,
  dataChartCount: state.inGameItemSalesTrend.dataChartCount,
  dataChartAmount: state.inGameItemSalesTrend.dataChartAmount,
  dataChartCountUnique: state.inGameItemSalesTrend.dataChartCountUnique,
  dataChartCountDep: state.inGameItemSalesTrend.dataChartCountDep,
  dataChartAmountDep: state.inGameItemSalesTrend.dataChartAmountDep,
  dataChartCountByItem: state.inGameItemSalesTrend.dataChartCountByItem,
  dataChartAmountByItem: state.inGameItemSalesTrend.dataChartAmountByItem,
  dataChartCountUniqueDep: state.inGameItemSalesTrend.dataChartCountUniqueDep,
  typeSelected: state.inGameItemSalesTrend.typeSelected,
  itemSelected: state.inGameItemSalesTrend.itemSelected,
  dataTable: state.inGameItemSalesTrend.dataTable,
  dataItems: state.inGameItemSalesTrend.dataItems,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getInGameItemSalesTrendData: (param) =>
    dispatch(getInGameItemSalesTrendData(param)),
  getInGameItemSalesTrendItemsData: (param) =>
    dispatch(getInGameItemSalesTrendItemsData(param)),
  setInGameItemSalesTrendItemSelected: (param) =>
    dispatch(setInGameItemSalesTrendItemSelected(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(InGameSalesTrend));
