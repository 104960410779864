const LineAffiliateTrendStyles = theme => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
    },
    chartContainer: {
        marginTop: -16,
    }
});

export default LineAffiliateTrendStyles;