import { USERS } from '../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(6, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").format('YYYY-MM-DD');

const initialState  = {
    usersListData: [],
    usersListPage: 1,
    usersRegListData: [],
    usersGameDirListData: [],
    noUserData: false,
    userPageListTotalNum: '--',
    userPageListData: [],
    userPageTableLoading: false,
    userPageTableTotal: '--',
    handleUsersFilter: false,
    userCountByFiltered: false,
    usersFilterQuery : false,
    userFetchFilter: {
        pmid: '',
        email: '',
        game: [
            {name: 'JobTribes', value: 'JobTribes' },
            {name: 'Puzzle', value: 'Puzzle' },
            {name: 'LuckyFarmer', value: 'luckyfarmer' },
            {name: 'Portal', value: 'Portal' },
        ],
        tier: [
            {name: '1', value: 1 },
            {name: '2', value: 2},
            {name: '3', value: 3 },
        ],
        campaign: [],
    },
    userFetchFilterSelected: {
        pmid: '',
        email: '',
        game: [],
        tier: [],
        campaign: [],
    },
    pageNumber: 1,
    userStatData: [{
        "totalAd" : '--',
        "countInRange": '--'
    }],
    userPageListDataFilter: [{
        "dateRange": [startDate, endDate],
    }],
    userCsvData: [],
    userCsvLoader: false,
};

export default function usersReducer(state = initialState, action) {
    switch (action.type) {
        case USERS.SET_USER_PAGE_LIST_DATA:
            return {
                ...state,
                userPageListData: action.payload
            };
        case USERS.SET_USER_PAGE_LIST_TOTAL_NUM:
            return {
                ...state,
                userPageListTotalNum: action.payload
            };
        case USERS.SET_USER_PAGE_LIST_PAGINATION:
            return {
                ...state,
                usersListPage: action.payload
            };
        case USERS.SET_USER_PAGE_TABLE_LOADING:
            return {
                ...state,
                userPageTableLoading: action.payload
            };
        case USERS.SET_USER_PAGE_TABLE_TOTAL:
            return {
                ...state,
                userPageTableTotal: action.payload
            };
        case USERS.SET_USERS_LIST_DATA:
            return {
                ...state,
                usersListData: action.payload
            };
        case USERS.SET_USERS_REG_LIST_DATA:
            return {
                ...state,
                usersRegListData: action.payload
            };
        case USERS.SET_USERS_GAME_DIRECT_LIST_DATA:
            return {
                ...state,
                usersGameDirListData: action.payload
            };
        case USERS.CLEAR_USERS_DATA_STORED:
            return {
                ...state,
                usersListData: []
            };
        case USERS.CLEAR_USERS_REG_LIST_DATA:
            return {
                ...state,
                usersRegListData: []
            };
        case USERS.CLEAR_USERS_GAME_DIRECT_LIST_DATA:
            return {
                ...state,
                usersGameDirListData: []
            };
        case USERS.SET_NO_USER_DATA:
            return {
                ...state,
                noUserData: action.payload
            };
        case USERS.HANDLE_USERS_FILTER:
            return {
                ...state,
                handleUsersFilter: action.payload
            };
        case USERS.SET_USERS_FILTER:
            return {
                ...state,
                userFetchFilterSelected: action.payload
            };
        case USERS.SET_USER_COUNT_FILTERED:
            return {
                ...state,
                userCountByFiltered: action.payload
            };
        case USERS.SET_USER_FILTER_QUERY:
            return {
                ...state,
                usersFilterQuery: action.payload
            };
        case USERS.SET_USER_FILTER_CAMPAIGNS:
            return {
                ...state,
                userFetchFilter: action.payload
            };
        case USERS.SET_USER_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            };
        case USERS.SET_USERS_STAT_DATA:
            return {
                ...state,
                userStatData: action.payload
            };
        case USERS.SET_USER_PAGE_LIST_DATA_FILTER:
            return {
                ...state,
                userPageListDataFilter: action.payload
            };
        case USERS.SET_USER_CSV_DATA:
            return {
                ...state,
                userCsvData: action.payload
            };
        case USERS.SET_USER_CSV_DOWNLOADER:
            return {
                ...state,
                userCsvLoader: action.payload
            };
        default:
        return state
    }
}