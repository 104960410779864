import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import { createPuzzleRegData } from 'graphql/mutations'
import { put, call, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import { setAcquisitionGeoDataReg, setAcquisitionCountryByAd, setAcquisitionCountryByOrg, setAcquisitionDataRegAdVsOrganic, setAcquisitionDataRegNewVsExist  } from 'actions';
import { PUZZLE_GEO, PAGE, ENDPOINT } from '../constants';
import { getJwtToken, fetchNodeGaEventData } from 'api';

export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];

export function* handlePuzzleAcquisitionGeoData() {
    try {
        let acquisitionGeoDataReg = {}, returnedRegGeoData;
        const jwtToken = yield call(getJwtToken);
        const storedDateRange = JSON.parse(localStorage.getItem("fetchDate_range"));
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        let labelArray = [];
        const graphQLdataCheck = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegOrgByCountry'+dateFrom+dateTo}));
        console.log('graphQLdataCheck:', graphQLdataCheck.data.getPuzzleRegData);
        const isGraphQlData = graphQLdataCheck.data.getPuzzleRegData === null ? false : true;

        if (storedDateRange !== null && storedDateRange[0] === dateFrom && storedDateRange[1] === dateTo && isGraphQlData) {
            // fetch from GraphQL
            console.log('geo from GraphQL');
            //returnedRegGeoData = JSON.parse(localStorage.getItem("puzzle_data_returnedRegGeoData"));
            const dataRegAdVsOrganic = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegAdVsOrganic'+dateFrom+dateTo}));
            console.log('dataRegAdVsOrganic:', dataRegAdVsOrganic.data.getPuzzleRegData.dataset);
            yield put(setAcquisitionDataRegAdVsOrganic(JSON.parse(dataRegAdVsOrganic.data.getPuzzleRegData.dataset)));

            const dataRegNewVsExist = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegNewVsExist'+dateFrom+dateTo}));
            yield put(setAcquisitionDataRegNewVsExist(JSON.parse(dataRegNewVsExist.data.getPuzzleRegData.dataset)));

            const dataRegCountryByAd = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegCountryByAd'+dateFrom+dateTo}));
            yield put(setAcquisitionCountryByAd(JSON.parse(dataRegCountryByAd.data.getPuzzleRegData.dataset)));

            const dataRegOrgByCountry = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegOrgByCountry'+dateFrom+dateTo}));
            yield put(setAcquisitionCountryByOrg(JSON.parse(dataRegOrgByCountry.data.getPuzzleRegData.dataset)));

            const dataGeoDataReg = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataGeoDataReg'+dateFrom+dateTo}));
            yield put(setAcquisitionGeoDataReg(JSON.parse(dataGeoDataReg.data.getPuzzleRegData.dataset)));
        } else {
            console.log('geo from API');
            localStorage.setItem("fetchDate_range", JSON.stringify([dateFrom, dateTo]));
            let queryData = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:country"},{"name": "ga:eventLabel"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dimensionFilterClauses": [
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                    "dimensionName":"ga:eventCategory",
                                    "operator":"EXACT",
                                    "expressions":'Registration'
                                    }
                                ]
                            }
                        ],
                        "dateRanges": [{"startDate": dateFrom, "endDate": dateTo}],
                        "viewId": "227581633",
                        'pageSize': 100000
                    }
                ]
            };
            returnedRegGeoData = yield call(fetchNodeGaEventData, queryData, ENDPOINT.GET_DATA_FROM_GA, jwtToken);
            // localStorage.setItem("puzzle_data_returnedRegGeoData", JSON.stringify(returnedRegGeoData));
            // console.log('returnedRegGeoData:', returnedRegGeoData);

            let unixTimeStamp = 0;
            let totalRegCountryArray = [], totalRegCountryAdArray = [], totalRegCountryOrgArray = [],
                totalRegNewCountryArray = [], totalRegExistCountryArray = [];
            for (let i=0; i<returnedRegGeoData.length; i++) {
                labelArray = returnedRegGeoData[i]['dimensions'][1].split(' | ');
                unixTimeStamp = Number(labelArray[labelArray.length -1]);

                // Ad or Organic
                if (labelArray[1] !== 'organic') {
                    totalRegCountryAdArray.push(returnedRegGeoData[i]['dimensions'][0]);
                } else {
                    totalRegCountryOrgArray.push(returnedRegGeoData[i]['dimensions'][0]);
                }
                // new or exist
                if (labelArray[3] !== 'new') {
                    totalRegExistCountryArray.push(returnedRegGeoData[i]['dimensions'][0]);
                } else {
                    totalRegNewCountryArray.push(returnedRegGeoData[i]['dimensions'][0]);
                }
                totalRegCountryArray.push(returnedRegGeoData[i]['dimensions'][0]);
            }

            // //console.log('totalRegDateArray:', totalRegDateArray);
            let countRegByCountry = _.values(_.groupBy(totalRegCountryArray)).map(d => ({country: d[0], count: d.length}));
            let countRegAdByCountry = _.values(_.groupBy(totalRegCountryAdArray)).map(d => ({country: d[0], count: d.length}));
            let countRegOrgByCountry = _.values(_.groupBy(totalRegCountryOrgArray)).map(d => ({country: d[0], count: d.length}));
            let countRegNewByCountry = _.values(_.groupBy(totalRegNewCountryArray)).map(d => ({country: d[0], count: d.length}));
            let countRegExistByCountry = _.values(_.groupBy(totalRegExistCountryArray)).map(d => ({country: d[0], count: d.length}));

            countRegByCountry = _.orderBy(countRegByCountry, ['count'],['desc']);
            countRegAdByCountry = _.orderBy(countRegAdByCountry, ['count'],['desc']);
            countRegOrgByCountry = _.orderBy(countRegOrgByCountry, ['count'],['desc']);
            countRegNewByCountry = _.orderBy(countRegNewByCountry, ['count'],['desc']);
            countRegExistByCountry = _.orderBy(countRegExistByCountry, ['count'],['desc']);

            // Data Set
            yield put(setAcquisitionDataRegAdVsOrganic([
                {value: totalRegCountryAdArray.length, name: '広告'},
                {value: totalRegCountryOrgArray.length, name: 'Organic'},
            ]));
            yield put(setAcquisitionDataRegNewVsExist([
                {value: totalRegNewCountryArray.length, name: '新規'},
                {value: totalRegExistCountryArray.length, name: '既存'},
            ]));

            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataRegAdVsOrganic'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify([
                    {value: totalRegCountryAdArray.length, name: '広告'},
                    {value: totalRegCountryOrgArray.length, name: 'Organic'},
                ]),
                name: 'puzzle_data_reg_ad_vs_organic'
            }}));

            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataRegNewVsExist'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify([
                    {value: totalRegNewCountryArray.length, name: '新規'},
                    {value: totalRegExistCountryArray.length, name: '既存'},
                ]),
                name: 'puzzle_data_reg_new_vs_exist'
            }}));

            //console.log('TODO: convert this data to GraphQL countRegAdByCountry:', countRegAdByCountry);
            //console.log('TODO: convert this data to GraphQL countRegOrgByCountry:', countRegOrgByCountry);
            yield put(setAcquisitionCountryByAd(countRegAdByCountry));
            yield put(setAcquisitionCountryByOrg(countRegOrgByCountry));

            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataRegCountryByAd'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify(countRegAdByCountry),
                name: 'puzzle_data_reg_country_by_ad'
            }}));

            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataRegOrgByCountry'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify(countRegOrgByCountry),
                name: 'puzzle_data_reg_org_by_country'
            }}));

            acquisitionGeoDataReg = {};
            acquisitionGeoDataReg.pmidRegNumCountry = countRegByCountry.length;
            acquisitionGeoDataReg.pmidRegNumCountryAd = countRegAdByCountry[0];
            acquisitionGeoDataReg.pmidRegNumCountryOrg = countRegOrgByCountry[0];
            acquisitionGeoDataReg.pmidRegNewCountry = countRegNewByCountry[0];
            acquisitionGeoDataReg.pmidRegExistCountry = countRegExistByCountry[0];
            //console.log('TODO: convert this data to GraphQL acquisitionGeoDataReg:', acquisitionGeoDataReg)
            yield put(setAcquisitionGeoDataReg(acquisitionGeoDataReg));
            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataGeoDataReg'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify(acquisitionGeoDataReg),
                name: 'puzzle_data_reg_org_by_country'
            }}));
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchMapSettings() {
    yield takeEvery(PUZZLE_GEO.GET_ACQUISITION_GEO_DATA_REG, handlePuzzleAcquisitionGeoData);
    yield takeEvery(PAGE.GET_PUZZLE_PAGE_DATA, handlePuzzleAcquisitionGeoData);
}
