import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, Paper } from "@material-ui/core";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getJtcbScholarDataByGuild } from 'actions';
import ScholarGuildPie from 'components/Charts/ScholarGuildPie';
import * as H from 'helper';

const useStyles = makeStyles((theme) => ({
    titlePaper: {
        paddingTop: 8,
        paddingRight: 8,
        marginBottom: 32,
        marginLeft: 8,
        fontSize: 18,
    },
    guildTitle: {
        display: 'block',
        fontSize: 15,
        color: "#7f898b",
        paddingLeft: 16,
        paddingBottom: 16,
    },
    title: {
        display: 'block',
        height: 32,
        fontSize: 14,
        paddingLeft: 16,
    },
    box: {
        borderRight: '1px solid #dcdcdc',
        minHeight: 120,
    },
    dataPie: {
        height: 130,
    },
    dataValue: {
        fontSize: 18,
        fontWeight: 500,
    }
}));

const ScholarGuild = ({
    dateRange,
    depPrice,
    selectedCurrency,
    loading,
    getJtcbScholarDataByGuild,
    dataKogiGuild,
    dataKyogiGuild,
}) => {
    const classes = useStyles();

    useEffect(() => {
        getJtcbScholarDataByGuild();
    }, []);

    const getPieChartData = (data, type, guild) => {
        let returningData = [];
        if (data.purchaseAmount !== null && type === 'amount') {
            const totalAmountAdjusted = data.totalAmount - data.purchaseAmount;
            returningData = [
                { name: guild ? '広義購入額' : '狭義購入額', value: Number(data.purchaseAmount.toFixed(2)) },
                { name: guild ? '広義外購入額' : '狭義外購入額', value: Number(totalAmountAdjusted.toFixed(2)) }
            ];
        }
        if (data.purchaseCount !== null && type === 'count') {
            const totalCountAdjusted = data.totalCount - data.purchaseCount;
            returningData = [
                { name: guild ? '広義購入件数' : '狭義購入件数', value: data.purchaseCount },
                { name: guild ? '広義外購入件数' : '狭義外購入件数', value: totalCountAdjusted }
            ];
        }
        if (data.ownerDepGive !== null && type === 'give') {
            const totalDepAdjusted = data.ttlDepGive - data.ownerDepGive;
            returningData = [
                { name: guild ? '広義DEP獲得額' : '狭義DEP獲得額', value: data.ownerDepGive },
                { name: guild ? '広義外DEP獲得額' : '狭義外DEP獲得額', value: totalDepAdjusted }
            ];
        }
        return returningData;
    }

    const getPieCenterLbl = (data, type) => {
        let returningData = "";
        if (data.purchaseAmount !== null && type === 'amount') {
            returningData = (data.purchaseAmount / data.totalAmount * 100).toFixed(2)+"%";
        }
        if (data.purchaseCount !== null && type === 'count') {
            returningData = (data.purchaseCount / data.totalCount * 100).toFixed(2)+"%";
        }
        if (data.ownerDepGive !== null && type === 'give') {
            returningData = (data.ownerDepGive / data.ttlDepGive * 100).toFixed(2)+"%";
        }
        return returningData;
    }
  
    return (
        <>
        {/* 広義 */}
        <Grid container direction="row" style={{ marginTop:24, marginBottom:8 }} justifyContent="center">
            <Grid item xs={12} md={10} lg={6}>
            <Paper style={{ padding: 8, marginBottom: 8, borderRadius: 6}}>
                <Grid item className={classes.titlePaper}>
                    {dateRange[0].replace(/-/g,'/')} ~ {dateRange[1].replace(/-/g,'/')}
                </Grid>
                <Grid container spacing={0} direction="row" style={{ marginTop: -16, marginBottom: 8, width: '100%' }}>
                    <Grid item xs={12}>
                        <div className={classes.guildTitle}>広義ギルド : {dataKogiGuild.ownerCount}</div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} className={classes.box}>
                        <Grid container alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} justifyContent={"flex-start"}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{__html: "NFT購入総額"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    <div className={classes.dataPie}>
                                        <ScholarGuildPie
                                            data={getPieChartData(dataKogiGuild, 'amount', 'kogi')}
                                            suffix="DEP"
                                            tooltipPosition={['22%', '22%']}
                                            lblPercent={getPieCenterLbl(dataKogiGuild, 'amount', 'kogi')}
                                        />
                                    </div>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    {dataKogiGuild.purchaseAmount !== null &&
                                        <div className={classes.dataValue}>{
                                            H.FormatNumStrHelper.formatCommaThousand(dataKogiGuild.purchaseAmount)
                                        } DEP</div>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} className={classes.box}>
                        <Grid container alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} justifyContent={"flex-start"}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{__html: "NFT購入件数"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    <div className={classes.dataPie}>
                                        <ScholarGuildPie
                                            data={getPieChartData(dataKogiGuild, 'count', 'kogi')}
                                            suffix=""
                                            tooltipPosition={['28%', '22%']}
                                            lblPercent={getPieCenterLbl(dataKogiGuild, 'count', 'kogi')}
                                        />
                                    </div>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                {dataKogiGuild.purchaseCount !== null &&
                                    <div className={classes.dataValue}>
                                        {H.FormatNumStrHelper.formatCommaThousand(dataKogiGuild.purchaseCount)}件
                                    </div>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Grid container alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} justifyContent={"flex-start"}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{__html: "獲得DEP％"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    <div className={classes.dataPie}>
                                        <ScholarGuildPie
                                            data={getPieChartData(dataKogiGuild, 'give', 'kogi')}
                                            suffix=""
                                            tooltipPosition={['22%', '22%']}
                                            lblPercent={getPieCenterLbl(dataKogiGuild, 'give', 'kogi')}
                                        />
                                    </div>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                {dataKogiGuild.ownerDepGive !== null &&
                                    <div className={classes.dataValue}>
                                        {H.FormatNumStrHelper.formatCommaThousand(dataKogiGuild.ownerDepGive)} DEP
                                    </div>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            </Grid>
            <Grid container direction="row" style={{ marginTop:24, marginBottom:8 }} justifyContent="center">
                <Grid item xs={12} md={10} lg={6}>
                <Paper style={{ padding: 8, marginBottom: 8, borderRadius: 6}}>
                <Grid item className={classes.titlePaper}>
                    {dateRange[0].replace(/-/g,'/')} ~ {dateRange[1].replace(/-/g,'/')}
                </Grid>
                <Grid container spacing={0} direction="row" style={{ marginTop: -16, marginBottom: 8, width: '100%' }}>
                    <Grid item xs={12}>
                        <div className={classes.guildTitle}>狭義ギルド　: {dataKyogiGuild.ownerCount}</div>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} className={classes.box}>
                        <Grid container alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} justifyContent={"flex-start"}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{__html: "NFT購入総額"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    <div className={classes.dataPie}>
                                        <ScholarGuildPie
                                            data={getPieChartData(dataKyogiGuild, 'amount')}
                                            suffix="DEP"
                                            tooltipPosition={['22%', '22%']}
                                            lblPercent={getPieCenterLbl(dataKyogiGuild, 'amount')}
                                        />
                                    </div>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    {dataKyogiGuild.purchaseAmount !== null &&
                                        <div className={classes.dataValue}>{
                                            H.FormatNumStrHelper.formatCommaThousand(dataKyogiGuild.purchaseAmount)
                                        } DEP</div>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} className={classes.box}>
                        <Grid container alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} justifyContent={"flex-start"}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{__html: "NFT購入件数"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    <div className={classes.dataPie}>
                                        <ScholarGuildPie
                                            data={getPieChartData(dataKyogiGuild, 'count')}
                                            suffix=""
                                            tooltipPosition={['28%', '22%']}
                                            lblPercent={getPieCenterLbl(dataKyogiGuild, 'count')}
                                        />
                                    </div>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                {dataKyogiGuild.purchaseCount !== null &&
                                    <div className={classes.dataValue}>
                                        {H.FormatNumStrHelper.formatCommaThousand(dataKyogiGuild.purchaseCount)}件
                                    </div>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <Grid container alignItems={"center"} direction={"row"} justifyContent={"flex-start"}>
                            <Grid item xs={12}>
                                <Grid container direction={"row"} justifyContent={"flex-start"}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.title}
                                            dangerouslySetInnerHTML={{__html: "獲得DEP％"}}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                    <div className={classes.dataPie}>
                                        <ScholarGuildPie
                                            data={getPieChartData(dataKyogiGuild, 'give')}
                                            suffix=""
                                            tooltipPosition={['22%', '22%']}
                                            lblPercent={getPieCenterLbl(dataKyogiGuild, 'give')}
                                        />
                                    </div>
                                </Grid>
                                <Grid container direction={"row"} justifyContent={"space-evenly"}>
                                {dataKyogiGuild.ownerDepGive !== null &&
                                    <div className={classes.dataValue}>
                                        {H.FormatNumStrHelper.formatCommaThousand(dataKyogiGuild.ownerDepGive)} DEP
                                    </div>
                                }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </Paper>
                </Grid>
            </Grid>
        </Grid>
        </>
    );
};

ScholarGuild.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    dateRange: state.page.dateRange,
    depPrice: state.page.depPrice,
    selectedCurrency: state.page.selectedCurrency,
    loading: state.jtcbScholarGuild.loading,
    dataKogiGuild: state.jtcbScholarGuild.dataKogiGuild,
    dataKyogiGuild: state.jtcbScholarGuild.dataKyogiGuild,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
    getJtcbScholarDataByGuild: () => dispatch(getJtcbScholarDataByGuild()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ScholarGuild));
