import { put, delay, takeEvery, takeLatest, call, select, all } from 'redux-saga/effects';
import { setSummaryDataFetchState, setUpdateAffiliatorState, setTrendChartData, getAffiliateListDataByKey, setAccessKey, setAllBIListUsers,
handleUsersFilter, setUserFilterQuery, setUserCountByFiltered, setDepPrice } from 'actions';
import { getCurrentDepPrice, getCurrentDepExchangeRate, getJwtToken, getAllBIListUsers, getCurrentUserData } from 'api';
import { PAGE, ENDPOINT } from '../constants';
import moment from 'moment';

export const currentPage = state => state.page.currentPage;
export const listDataFetchLoop = state => state.affiliate.listDataFetchLoop;
export const depPriceState = state => state.page.depPrice;

export function* handleCurrentPage(param) {
    try {
        const jwtToken = yield call(getJwtToken);
        const stateDepPrice = yield select(depPriceState);

        // fetch DEP price from OKEx
        const currentTimestamp = moment().format('X');
        const storedTimestamp = stateDepPrice.lastFetchTs;
        const tsDiff = Number(currentTimestamp) - storedTimestamp;
        if (tsDiff > 300) {
            let dep = {loading: true};
            yield put(setDepPrice(dep));
            const [
                dataDepPrice,
                dataDepRates,
            ] = yield all([
                // call(getCurrentDepPrice, ENDPOINT.GET_DEP_PRICE, jwtToken),
                call(getCurrentDepPrice),
                call(getCurrentDepExchangeRate),
            ]);

            // let diff = Number(dataDepPrice.last) - Number(dataDepPrice.open_24h);
            // let diffPercent = Math.abs(diff / Number(dataDepPrice.open_24h) * 100).toFixed(2);
            let diffPercent = dataDepPrice.deapcoin.usd_24h_change.toFixed(2);
            let gainLoss = diffPercent > 0 ? "+" : "";
            dep = {
                loading: false,
                price: Number(dataDepPrice.deapcoin.usd.toFixed(4)),
                usd: dataDepRates.deapcoin.usd,
                jpy: dataDepRates.deapcoin.jpy,
                sgd: dataDepRates.deapcoin.sgd,
                open_24h: Number(dataDepPrice.open_24h),
                open_utc0: Number(dataDepPrice.open_utc0),
                low_24h: Number(dataDepPrice.low_24h),
                last24: gainLoss+diffPercent+"%",
                lastFetchTs: Number(moment().format('X')),
            }
            yield put(setDepPrice(dep));
        }

        if (param.payload !== 'users') {
            yield put(setUserCountByFiltered(false));
            yield put(handleUsersFilter(false));
            yield put(setUserFilterQuery(false));
        }
        if (param.payload === 'campaignSummary') {
            const accessKeyStored = localStorage.getItem("access_key") !== null ? localStorage.getItem("access_key") : yield call(getCurrentUserData);
            yield put(setAccessKey(accessKeyStored));

            if (process.env.REACT_APP_SUPERUSER === accessKeyStored) {
                let request = yield call(getAllBIListUsers, ENDPOINT.GET_ALL_BI_LIST_USERS, jwtToken);
                if (request) {
                    // console.log('request:', request);
                    const userListData = request.Users;
                    let listUsersArray = [], userObj = {};
                    for (let i=0; i<userListData.length; i++) {
                        for(let j=0; j<userListData[i]['Attributes'].length; j++) {
                            if (userListData[i]['Attributes'][j]['Name'] === 'custom:access_key') {
                                userObj.key = userListData[i]['Attributes'][j]['Value'];
                            }
                            if (userListData[i]['Attributes'][j]['Name'] === 'custom:client_name') {
                                userObj.value = userListData[i]['Attributes'][j]['Value'];
                            }
                        }
                        listUsersArray.push(userObj);
                        userObj = {};
                    }
                    localStorage.setItem("allBIUsers", JSON.stringify(listUsersArray));
                    yield put(setAllBIListUsers(listUsersArray));
                }
            }
            yield put(setUpdateAffiliatorState(false));
            yield put(setSummaryDataFetchState(true));
            //yield put(getAffiliateListDataByKey());
            //yield delay(600 * 1000); // 600sec, every 10 min
            yield put(setTrendChartData([]));
        } else {
            yield put(setSummaryDataFetchState(false));
            //break;
        }
    } catch (err) {
        console.log(err);
    }
}

export function* handleCalendarRange(param) {
    try {
        console.log('calendar date is set');
    } catch (err) {
        console.log(err);
    }
}

export function* getDepPrice() {
    try {
        const jwtToken = yield call(getJwtToken);
        let dep = {loading: true};
        yield put(setDepPrice(dep));

        const [
            dataDepPrice,
            dataDepRates,
        ] = yield all([
            //call(getCurrentDepPrice, ENDPOINT.GET_DEP_PRICE, jwtToken),
            call(getCurrentDepPrice),
            call(getCurrentDepExchangeRate),
        ]);

        //let diff = Number(dataDepPrice.last) - Number(dataDepPrice.open_24h);
        //Math.abs(diff / Number(dataDepPrice.open_24h) * 100).toFixed(2);
        let diffPercent = dataDepPrice.deapcoin.usd_24h_change.toFixed(2);
        let gainLoss = diffPercent > 0 ? "+" : "";

        dep = {
            loading: false,
            price: Number(dataDepPrice.deapcoin.usd.toFixed(4)),
            usd: dataDepRates.deapcoin.usd,
            jpy: dataDepRates.deapcoin.jpy,
            sgd: dataDepRates.deapcoin.sgd,
            open_24h: Number(dataDepPrice.open_24h),
            open_utc0: Number(dataDepPrice.open_utc0),
            low_24h: Number(dataDepPrice.low_24h),
            last24: gainLoss+diffPercent+"%",
            lastFetchTs: Number(moment().format('X')),
        }
        yield put(setDepPrice(dep));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeLatest(PAGE.SET_CURRENT_PAGE, handleCurrentPage);
    yield takeEvery(PAGE.GET_DEP_PRICE, getDepPrice);
}
