import * as V from 'styles/variables';

const legendPuzzleAcquisition = ['PMID登録者数', '広告経由PMID登録者数', 'Organic PMID登録者数', 'PMID新規登録者数', 'PMID既存登録者数'];
const legendPuzzleUserEvents = ['ログイン回数', 'ステージ成功数', 'ステージ失敗数', 'KESO獲得回数', 'KESO獲得総額'];
const legendActiveUsers = ['PlayMining', 'PlayMining NFT', 'JobTribes', 'CookinBurger', 'DragonRamen', 'GraffitiRacer', 'LostArchive+', 'Lucky Farmer', 'Rogue Roll Ruler\'s', 'Bouncy Bunny', 'PicTree'];
const chartColorByGame = {
    "PlayMining": V.playmining,
    "nftdep": V.nftdep,
    "playmining-nft": V.nftdep,
    "jobtribes": V.jobtribes,
    "puzzle": V.puzzle,
    "luckyfarmer": V.luckyfarmer,
    "cookinburger": V.cookinburger,
    "dragonramen": V.dragonramen,
    "graffiti": V.graffitiracer,
    "graffitiracer": V.graffitiracer,
    "lostarchiveplus": V.lostarchvie,
    "soulfusers": V.soulfusers,
    "roguerollrulers": V.roguerollrulers,
    "bouncybunny": V.bouncybunny,
    "pictree": V.pictree,
}
const legendActiveUsersAllGame = ['PlayMining', 'PlayMining NFT', 'CookinBurger', 'DragonRamen', 'GraffitiRacer', 'JobTribes', 'LostArchive+', 'Lucky Farmer', 'RogueRollRuler\'s', 'Bouncy Bunny'];

// Trend
// const legendNftDepTrend = [ 'オークション販売数', 'オークション販売額', 'ストア販売数', 'ストア販売額',  /*'アクティブWallet',*/
//     '配布DEP件数', '配布DEP額', '分配金件数', '分配金額', '出金手数料件数', '出金手数料額', 'エアードロップ件数', 'エアードロップ額',
//     'ステーキング解約件数', 'ステーキング解約額', 'ステーキング預入件数', 'ステーキング預入額', 'ステーキング利息件数', 'ステーキング利息額'
// ];
const legendNftDepTrend = [ '1次DEP件数','1次Fiat件数','2次件数', '1次DEP額','1次Fiat US$','2次販売額','ユニークWallet'];

const chartColorByType = {
    "store-count": V.genericColorPallette[0],
    "store-amount": V.genericColorPallette[0],
    "auction-count": V.genericColorPallette[1],
    "auction-amount": V.genericColorPallette[1],
    "activeWallet": V.genericColorPallette[2],
    "fiat-count": V.genericColorPallette[4],
    "fiat-amount": V.genericColorPallette[4],

    "distribute": V.genericColorPallette[5],
    "distribute-amount": V.genericColorPallette[5],
    "distributions": V.genericColorPallette[6],
    "distributions-amount": V.genericColorPallette[6],
    "withdrawal": V.genericColorPallette[7],
    "withdrawal-amount": V.genericColorPallette[7],
    "airdrop": "#bf67ce",
    "airdrop-amount": "#bf67ce",
    "staking_cancel": "#f778a2",
    "staking_cancel-amount": "#f778a2",
    "staking_apply": "#a2ce8e",
    "staking_apply-amount": "#a2ce8e",
    "staking_interest": "#f2b44d",
    "staking_interest-amount": "#f2b44d",

    "ownerNew": V.genericColorPallette[0],
    "ownerStop": V.genericColorPallette[1],
    "scholarNew": V.genericColorPallette[2],
    "scholarStop": V.genericColorPallette[3],
    "allAmulet": V.genericColorPallette[0],

    "auction-nft-unique": "#f669b4",
    "store-nft-unique": "#f669b4",

    "auction-legendary-count": V.genericColorPallette[0],
    "auction-legendary-amount": V.genericColorPallette[0],
    "auction-epic-count": V.genericColorPallette[1],
    "auction-epic-amount": V.genericColorPallette[1],
    "auction-rare-count": V.genericColorPallette[2],
    "auction-rare-amount": V.genericColorPallette[2],
    "auction-common-count": V.genericColorPallette[3],
    "auction-common-amount": V.genericColorPallette[3],

    "store-legendary-count": V.genericColorPallette[0],
    "store-legendary-amount": V.genericColorPallette[0],
    "store-epic-count": V.genericColorPallette[1],
    "store-epic-amount": V.genericColorPallette[1],
    "store-rare-count": V.genericColorPallette[2],
    "store-rare-amount": V.genericColorPallette[2],
    "store-common-count": V.genericColorPallette[3],
    "store-common-amount": V.genericColorPallette[3],

    "store-legendary-count-fiat": V.genericColorPallette[0],
    "store-legendary-amount-fiat": V.genericColorPallette[0],
    "store-epic-count-fiat": V.genericColorPallette[1],
    "store-epic-amount-fiat": V.genericColorPallette[1],
    "store-rare-count-fiat": V.genericColorPallette[2],
    "store-rare-amount-fiat": V.genericColorPallette[2],
    "store-common-count-fiat": V.genericColorPallette[3],
    "store-common-amount-fiat": V.genericColorPallette[3],

    // //cookinburger
    // "auction-luckyfarmer-count": V.genericColorPallette[0],
    // "auction-luckyfarmer-amount": V.genericColorPallette[0],
    // "store-luckyfarmer-count": V.genericColorPallette[1],
    // "store-luckyfarmer-amount": V.genericColorPallette[1],

    //cookinburger
    "auction-cookinburger-count": V.genericColorPallette[0],
    "auction-cookinburger-amount": V.genericColorPallette[0],
    "store-cookinburger-count": V.genericColorPallette[1],
    "store-cookinburger-amount": V.genericColorPallette[1],
    "store-cookinburger-count-fiat": V.genericColorPallette[1],
    "store-cookinburger-amount-fiat": V.genericColorPallette[1],
    "ingame-repair-count": V.genericColorPallette[0],
    "ingame-repair-amount": V.genericColorPallette[0],

    //soul fusers
    "auction-soulfusers-count": V.genericColorPallette[0],
    "auction-soulfusers-amount": V.genericColorPallette[0],
    "store-soulfusers-count": V.genericColorPallette[1],
    "store-soulfusers-amount": V.genericColorPallette[1],
    "store-soulfusers-count-fiat": V.genericColorPallette[1],
    "store-soulfusers-amount-fiat": V.genericColorPallette[1],

    //dragonRamen
    "auction-dragonramen-count": V.genericColorPallette[0],
    "auction-dragonramen-amount": V.genericColorPallette[0],
    "auction-ur-count": V.genericColorPallette[1],
    "auction-ur-amount": V.genericColorPallette[1],
    "auction-n-count": V.genericColorPallette[2],
    "auction-n-amount": V.genericColorPallette[2],
    "auction-sr-count": V.genericColorPallette[3],
    "auction-sr-amount": V.genericColorPallette[3],
    "auction-ssr-count": V.genericColorPallette[4],
    "auction-ssr-amount": V.genericColorPallette[4],
    "auction-r-count": V.genericColorPallette[5],
    "auction-r-amount": V.genericColorPallette[5],

    "store-dragonramen-count": V.genericColorPallette[0],
    "store-dragonramen-amount": V.genericColorPallette[0],
    "store-ur-count": V.genericColorPallette[1],
    "store-ur-amount": V.genericColorPallette[1],
    "store-n-count": V.genericColorPallette[2],
    "store-n-amount": V.genericColorPallette[2],
    "store-sr-count": V.genericColorPallette[3],
    "store-sr-amount": V.genericColorPallette[3],
    "store-ssr-count": V.genericColorPallette[4],
    "store-ssr-amount": V.genericColorPallette[4],
    "store-r-count": V.genericColorPallette[5],
    "store-r-amount": V.genericColorPallette[5],

    "store-ur-count-fiat": V.genericColorPallette[1],
    "store-ur-amount-fiat": V.genericColorPallette[1],
    "store-n-count-fiat": V.genericColorPallette[2],
    "store-n-amount-fiat": V.genericColorPallette[2],
    "store-sr-count-fiat": V.genericColorPallette[3],
    "store-sr-amount-fiat": V.genericColorPallette[3],
    "store-ssr-count-fiat": V.genericColorPallette[4],
    "store-ssr-amount-fiat": V.genericColorPallette[4],
    "store-r-count-fiat": V.genericColorPallette[5],
    "store-r-amount-fiat": V.genericColorPallette[5],

    //graffitiracer
    "auction-sss-count": V.genericColorPallette[0],
    "auction-sss-amount": V.genericColorPallette[0],
    "auction-ss-count": V.genericColorPallette[1],
    "auction-ss-amount": V.genericColorPallette[1],
    "auction-s-count": V.genericColorPallette[2],
    "auction-s-amount": V.genericColorPallette[2],
    "auction-a-count": V.genericColorPallette[3],
    "auction-a-amount": V.genericColorPallette[3],
    "auction-b-count": V.genericColorPallette[4],
    "auction-b-amount": V.genericColorPallette[4],
    "auction-c-count": V.genericColorPallette[5],
    "auction-c-amount": V.genericColorPallette[5],

    "store-sss-count": V.genericColorPallette[0],
    "store-sss-amount": V.genericColorPallette[0],
    "store-ss-count": V.genericColorPallette[1],
    "store-ss-amount": V.genericColorPallette[1],
    "store-s-count": V.genericColorPallette[2],
    "store-s-amount": V.genericColorPallette[2],
    "store-a-count": V.genericColorPallette[3],
    "store-a-amount": V.genericColorPallette[3],
    "store-b-count": V.genericColorPallette[4],
    "store-b-amount": V.genericColorPallette[4],
    "store-c-count": V.genericColorPallette[5],
    "store-c-amount": V.genericColorPallette[5],

    "store-sss-amount-fiat": V.genericColorPallette[0],
    "store-ss-amount-fiat": V.genericColorPallette[1],
    "store-s-amount-fiat": V.genericColorPallette[2],
    "store-a-amount-fiat": V.genericColorPallette[3],
    "store-b-amount-fiat": V.genericColorPallette[4],
    "store-c-amount-fiat": V.genericColorPallette[5],

    //kamuiverse
    "auction-kamuiverse-count": V.genericColorPallette[0],
    "auction-kamuiverse-amount": V.genericColorPallette[0],
    "store-kamuiverse-count": V.genericColorPallette[1],
    "store-kamuiverse-amount": V.genericColorPallette[1],

    // LuckyFarmer InGame
    "ingame-stamina-count": V.genericColorPallette[0],
    "ingame-stamina-amount": V.genericColorPallette[0],
    "ingame-land-count": V.genericColorPallette[1],
    "ingame-land-amount": V.genericColorPallette[1],
    "ingame-shop-count": V.genericColorPallette[2],
    "ingame-shop-amount": V.genericColorPallette[2],
    "ingame-gacha-count": V.genericColorPallette[3],
    "ingame-gacha-amount": V.genericColorPallette[3],

    //GraffitiRacer
    "ingame-dep-get-count": V.genericColorPallette[0],
    "ingame-dep-get-count-unique": V.genericColorPallette[0],
    "ingame-dep-get-amount": V.genericColorPallette[0],
    "ingame-dep-use-count": V.genericColorPallette[1],
    "ingame-dep-use-count-unique": V.genericColorPallette[1],
    "ingame-dep-use-amount": V.genericColorPallette[1],
    "ingame-fiat-use-count": V.fiatColor,
    "ingame-fiat-use-count-unique": V.fiatColor,
    "ingame-fiat-use-amount": V.fiatColor,

    "ingame-dep-use-count1": V.genericColorPallette[2],
    "ingame-dep-use-amount1": V.genericColorPallette[2],
    "ingame-dep-use-count2": V.genericColorPallette[3],
    "ingame-dep-use-amount2": V.genericColorPallette[3],
    "ingame-dep-use-count3": V.genericColorPallette[4],
    "ingame-dep-use-amount3": V.genericColorPallette[4],
    "ingame-dep-use-count4": V.genericColorPallette[5],
    "ingame-dep-use-amount4": V.genericColorPallette[5],
    "ingame-dep-use-count5": V.genericColorPallette[6],
    "ingame-dep-use-amount5": V.genericColorPallette[6],
    "ingame-dep-use-count6": V.genericColorPallette[7],
    "ingame-dep-use-amount6": V.genericColorPallette[7],
    "ingame-dep-use-count7": V.genericColorPallette[8],
    "ingame-dep-use-amount7": V.genericColorPallette[8],
    "ingame-sheet-count": V.genericColorPallette[9],
    "ingame-sheet-amount": V.genericColorPallette[9],

    // InGame Item
    "ingame-item-fiat-count1" : V.genericColorPallette[0],
    "ingame-item-fiat-amount1" : V.genericColorPallette[0],
    "ingame-item-fiat-count2" : V.genericColorPallette[1],
    "ingame-item-fiat-amount2" : V.genericColorPallette[1],
    "ingame-item-fiat-count3" : V.genericColorPallette[2],
    "ingame-item-fiat-amount3" : V.genericColorPallette[2],
    "ingame-item-fiat-count4" : V.genericColorPallette[3],
    "ingame-item-fiat-amount4" : V.genericColorPallette[3],
    "ingame-item-fiat-count5" : V.genericColorPallette[4],
    "ingame-item-fiat-amount5" : V.genericColorPallette[4],
    "ingame-item-fiat-count6" : V.genericColorPallette[5],
    "ingame-item-fiat-amount6" : V.genericColorPallette[5],
    "ingame-item-fiat-count7" : V.genericColorPallette[6],
    "ingame-item-fiat-amount7" : V.genericColorPallette[6],
    "ingame-item-fiat-count-unique" : "#f9a348",

    "ingame-item-dep-count1" : V.genericColorPallette[7],
    "ingame-item-dep-amount1" : V.genericColorPallette[7],
    "ingame-item-dep-count2" : V.genericColorPallette[8],
    "ingame-item-dep-amount2" : V.genericColorPallette[8],
    "ingame-item-dep-count3" : V.genericColorPallette[9],
    "ingame-item-dep-amount3" : V.genericColorPallette[9],
    "ingame-item-dep-count4" : V.genericColorPallette[10],
    "ingame-item-dep-amount4" : V.genericColorPallette[10],
    "ingame-item-dep-count5" : V.genericColorPallette[11],
    "ingame-item-dep-amount5" : V.genericColorPallette[11],
    "ingame-item-dep-count6" : V.genericColorPallette[12],
    "ingame-item-dep-amount6" : V.genericColorPallette[12],
    "ingame-item-dep-count7" : V.genericColorPallette[13],
    "ingame-item-dep-amount7" : V.genericColorPallette[13],
    "ingame-item-dep-count-unique" : "#da1717",

    "checkin-count" : V.genericColorPallette[0],
    "checkin-unique-count" : V.genericColorPallette[1],
}

// NFT Own Trend
const legendNftOwnTrend = ['ユーザー保有NFT数', 'NFTホルダーPMID'];

const legendScholarTrend = ['Owner新規', 'Owner停止', 'Scholar新規', 'Scholar停止'];
const legendScholarAmulet = ['全アミュレット'];

// JTCB rarity trend
const legendJtcbNftTrend = [
    'LEGENDARY 件数', 'LEGENDARY DEP額',
    'EPIC 件数', 'EPIC DEP額',
    'RARE 件数', 'RARE DEP額',
    'COMMON 件数', 'COMMON DEP額', 'ユニークユーザー'
];
const legendJtcbNftTrendFiat = [
    'LEGENDARY 件数', 'LEGENDARY USD額',
    'EPIC 件数', 'EPIC USD額',
    'RARE 件数', 'RARE USD額',
    'COMMON 件数', 'COMMON USD額', 'ユニークユーザー'
];

const cookinBurgerNftTrend = [
    '件数', 'DEP額', 'ユニークユーザー'
];
const cookinBurgerNftTrendFiat = [
    '件数', 'USD額', 'ユニークユーザー'
];

const kamuiVerseNftTrend = [
    '件数', 'DEP額',
];

const legendDragonRamenNftTrend = [
    'UR 件数', 'UR DEP額',
    'N 件数', 'N DEP額',
    'SR 件数', 'SR DEP額',
    'SSR 件数', 'SSR DEP額',
    'R 件数', 'R DEP額',
    'ユニークユーザー'
];

const legendDragonRamenNftTrendFiat = [
    'UR 件数', 'UR USD額',
    'N 件数', 'N USD額',
    'SR 件数', 'SR USD額',
    'SSR 件数', 'SSR USD額',
    'R 件数', 'R USD額',
    'ユニークユーザー'
];

const legendGraffitiNftTrend = [
    'SSS 件数', 'SSS DEP額',
    'SS 件数', 'SS DEP額',
    'S 件数', 'S DEP額',
    'A 件数', 'A DEP額',
    'B 件数', 'B DEP額',
    'C 件数', 'C DEP額',
    'ユニークユーザー'
];

const legendGraffitiNftTrendFiat = [
    'SSS 件数', 'SSS USD額',
    'SS 件数', 'SS USD額',
    'S 件数', 'S USD額',
    'A 件数', 'A USD額',
    'B 件数', 'B USD額',
    'C 件数', 'C USD額',
    'ユニークユーザー'
];

const soulFusersNftTrend = [
    '件数', 'DEP額', 'ユニークユーザー'
];

// KPI Sales
const chartLabelForKpiSales = {
    "salesVolume": "売上",
    "arpu": "ARPU",
    "arppu": "ARPPU",
    "dau": "DAU",
    "paidUnique": "ユニーク課金数",
};

const legendKpiSales = ['ARPU', 'ARPPU', 'DAU', 'ユニーク課金数'];
const kpiSalesLegendColor = [V.genericColorPallette[0], V.genericColorPallette[1], V.genericColorPallette[2], V.genericColorPallette[3]];

// PicTree
const legendPicTreeRegTrend = ['新規登録', 'PMID連携'];
const chartLabelPicTreeReg = {
    "reg": "新規登録",
    "pmid": "PMID連携",
};
const chartLabelPicTreeCheckIn = {
    "count": "チェックイン数",
    "unique": "ユーザー数",
};
const legendPicTreeTrend = ['チェックイン数', 'ユーザー数'];

const chartLabelPicTreePhotoShoot = {
    "countDenchu": "電柱撮影数",
    "countMh": "MH撮影数",
    "countHh": "HH撮影数",
    "unique": "ユーザー数",
};
const legendPicTreePhotoShootTrend = ['電柱撮影数', 'MH撮影数', 'HH撮影数', 'ユーザー数'];

const chartLabelPicTreeSalesTrend= {
    "amountCoins": "コイン額",
    "countTx": "取引件数",
    "unique": "ユーザー数",
};
const legendPicTreeSalesTrend = ['コイン額', '取引件数', 'ユーザー数'];

const chartLabelPicTreePointTrend= {
    "depPoint": "DEP交換額",
    "depCount": "DEP交換件数",
    "amazonPoint": "Amazonギフト交換額",
    "amazonCount": "Amazonギフト交換件数",
    "uniqueCount": "ユーザー数",
};
const legendPicTreePointTrend = ['DEP交換額', 'DEP交換件数', 'Amazonギフト交換額', 'Amazonギフト交換件数', 'ユーザー数'];

export default {
    legendPuzzleAcquisition,
    legendPuzzleUserEvents,
    legendActiveUsers,
    chartColorByGame,
    legendActiveUsersAllGame,
    legendNftDepTrend,
    legendNftOwnTrend,
    chartColorByType,
    legendScholarTrend,
    legendScholarAmulet,
    legendJtcbNftTrend,
    legendJtcbNftTrendFiat,
    cookinBurgerNftTrend,
    cookinBurgerNftTrendFiat,
    kamuiVerseNftTrend,
    legendDragonRamenNftTrend,
    legendDragonRamenNftTrendFiat,
    legendGraffitiNftTrend,
    legendGraffitiNftTrendFiat,
    soulFusersNftTrend,
    chartLabelForKpiSales,
    legendKpiSales,
    kpiSalesLegendColor,
    legendPicTreeRegTrend,
    chartLabelPicTreeReg,
    chartLabelPicTreeCheckIn,
    legendPicTreeTrend,
    chartLabelPicTreePhotoShoot,
    legendPicTreePhotoShootTrend,
    chartLabelPicTreeSalesTrend,
    legendPicTreeSalesTrend,
    chartLabelPicTreePointTrend,
    legendPicTreePointTrend,
};
