import { put, call, takeEvery, select } from 'redux-saga/effects';
import { setShowPoiData, updateSelectedPoiData } from 'actions';
import { MAP, ENDPOINT } from '../constants';
import { fetchPoiSearchData } from 'api';
import * as H from 'helper';

export const locationQuery = state => state.location.locationQuery;
export const currentZoomLevel = state => state.map.zoomLevel;
export const currentPoiCoord = state => state.map.poiCoord;

export function* handleShowPoiDataOnMap(param) {
    console.log('map saga param:', param);
    const locIndex = parseInt(param.payload) - 1;
    yield put(setShowPoiData(true, locIndex));
}

export function* handleCustomPolygonQuery(param) {
    const numLocation = H.QueryHelper.getUrlQueryParam('location');
    let polygonJson = {
        "type": "FeatureCollection",
                "features": [
                    {
                        "type": "Feature",
                        "properties": {
                            "id": param.payload[0]['id'],
                            "name": "Custom Polygon"
                        },
                        "geometry": param.payload[0]['geometry']
                    }
                ]
    }
    console.log('polygonJson:', polygonJson);
    yield put(updateSelectedPoiData(polygonJson, "polygon", Number(numLocation)))
}

export default function* watchMapSettings() {
    yield takeEvery(MAP.SHOW_POI_DATA_ON_MAP, handleShowPoiDataOnMap);
    yield takeEvery(MAP.SET_POLYGON, handleCustomPolygonQuery);
}