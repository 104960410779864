import { JTCB } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    loading: false,
    scholarDateRange: [startDate, endDate],
    trendData: [
        {
            id: 'ownerNew',
            data: [],
            date: [],
        },
        {
            id: 'ownerStop',
            data: [],
            date: [],
        },
        {
            id: 'scholarNew',
            data: [],
            date: [],
        },
        {
            id: 'scholarStop',
            data: [],
            date: [],
        },
        {
            id: 'amulet',
            data: [],
            date: [],
            avg: 0,
        },
    ],
};

export default function scholarTrendReducer(state = initialState, action) {
    switch (action.type) {
        case JTCB.SET_SCHOLAR_TREND_DATA_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case JTCB.SET_TREND_DATE_RANGE:
            return {
                ...state,
                scholarDateRange: action.payload
            };
        case JTCB.SET_JTCB_SCHOLAR_TREND_DATA:
            return {
                ...state,
                trendData: action.payload
            };
        default:
            return state
    }
};
