import { Auth } from "aws-amplify";
import ReactGA from 'react-ga';

const TrackingHelper = {
    trackUserSetOwnPassword: function(userName) {
        ReactGA.event({
            category: 'ACCOUNT | ' + userName,
            action: 'OWN PASSWORD SET | ' + userName,
            label: 'Username : ' + userName
        });
    },

    trackUserSignedIn: function(userName) {
        ReactGA.event({
            category: 'ACCOUNT | ' + userName,
            action: 'SIGNED IN | ' + userName,
            label: 'Username : ' + userName
        });
    },

    reportPreviewed: function(reportTitle, reportType) {
        Auth.currentAuthenticatedUser().then(function(user) {
            ReactGA.event({
                category: 'REPORT | ' + user.username,
                action: 'PREVIEWED | ' + user.username,
                label: 'REPORT PREVIEWED | ' + reportTitle + ' (' + reportType + ')'
            });
        }, function(err) {
            console.log(err)
        });
    }
}

export default TrackingHelper;