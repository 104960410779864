import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import moment from 'moment-timezone';
import { CAMPAIGN, ENDPOINT } from '../constants';
import { setCampaignListData, setCampaignDataLoadById, setCampaignStatById,
         setCampaignTrendDataLoadByRid, setTrendFirstTimeByRid, setTrendGameVisitByRid, setTrendRegistByRid,
         setCampaignTrendChartData, setPageTopLoader, setCampaignViewStats, setPreDefinedCampaignDateRange
} from 'actions';
import { getJwtToken, getDataFromRDS } from 'api';
import * as H from 'helper';

export const campaignIdToFetch = state => state.campaign.campaignIdToFetch;
export const campaignRidToFetch = state => state.campaign.campaignTrendFetchRid;
export const campaignDateRange = state => state.campaign.campaignDateRange;
export const campaignPredefinedDateRange = state => state.campaign.campaignPreDefinedDateRange;

const unescapeUnicode = function(str) {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function(m0, m1) {
      return String.fromCharCode(parseInt(m1, 16));
    });
};

export function* getCampaignListDataByKey() {
    try {
        let campaignStatsList = [];
        let eachCampaignData = {};
        let rdsData4 = yield API.graphql(graphqlOperation(queries.getCampaignsStats, { accessKey: process.env.REACT_APP_SUPERUSER }));
        _.each(rdsData4.data.getCampaignsStats, function(data) {
            eachCampaignData.Timestamp = data.createdDate;
            eachCampaignData.Reg = data.registered;
            eachCampaignData.AffiliateId = data.affiliateId;
            eachCampaignData.Status = 'active';
            eachCampaignData.Conv = data.gameVisit;
            eachCampaignData.Game = data.property;
            eachCampaignData.Type = data.type;
            eachCampaignData.Rid = data.rid;
            eachCampaignData.FT = data.firstTime;
            eachCampaignData.Name = unescapeUnicode(data.name);
            campaignStatsList.push(eachCampaignData);
            eachCampaignData = {};
        });
        yield put(setCampaignListData(campaignStatsList));
    } catch (error) {
        console.log(error);
    }
}

export function* getCampaignDataById() {
    try {
        yield put(setCampaignDataLoadById(true));
        const campaignId = yield select(campaignIdToFetch);
        let rdsData4 = yield API.graphql(graphqlOperation(queries.getCampaignsStatsById, { aid: campaignId }));
        yield put(setCampaignStatById(rdsData4.data.getCampaignsStatsById));

        const ridToFetch = rdsData4.data.getCampaignsStatsById[0]['rid'];
        const cpType = rdsData4.data.getCampaignsStatsById[0]['type'];
        
        const calendarDateRange = yield select(campaignDateRange);
        const isDataNew = moment(calendarDateRange[0]).isAfter('2023-06-30') && moment(calendarDateRange[1]).isAfter('2023-06-30') ? true : false;
        const tableName = isDataNew ? "RegistrationPmEvtProd" : "RegistrationProd";

        let campaignViewStats = {};
        if (cpType === 'campaign') {
            const [firstTime, gameVisits, regByRid] = yield all([
                API.graphql(graphqlOperation(queries.getCampaignLogByRidAllDateTime, { table: 'CampaignLogFirstTime', rid: ridToFetch })),
                API.graphql(graphqlOperation(queries.getCampaignLogByRidAllDateTime, { table: 'CampaignLogGameVisit', rid: ridToFetch })),
                API.graphql(graphqlOperation(queries.getCampaignLogRegByRidAllDateTime, { table: tableName, rid: ridToFetch }))
            ]);

            let fSum = firstTime.data.getCampaignLogByRidAllDateTime.reduce(function (total, currentValue) {
                return total + currentValue.count;
            }, 0);

            let vSum = gameVisits.data.getCampaignLogByRidAllDateTime.reduce(function (total, currentValue) {
                return total + currentValue.count;
            }, 0);

            let rSum =  regByRid.data.getCampaignLogRegByRidAllDateTime.reduce(function (total, currentValue) {
                return total + currentValue.count;
            }, 0);
            campaignViewStats = {firstTime: fSum, gameVisit: vSum, regist: rSum};
        } else if (cpType === 'campaign_game') {
            const [gameDirFirstTime, gameDirRegByRid] = yield all([
                API.graphql(graphqlOperation(queries.getCampaignLogByRidAllDateTime, { table: 'CampaignLogFirstTimeGameDirProd', rid: ridToFetch })),
                API.graphql(graphqlOperation(queries.getCampaignLogRegByRidAllDateTime, { table: tableName, rid: ridToFetch }))
            ]);

            // FirstTime
            let fSum = gameDirFirstTime.data.getCampaignLogByRidAllDateTime.reduce(function (total, currentValue) {
                return total + currentValue.count;
            }, 0);

            // Registration
            let rSum =  gameDirRegByRid.data.getCampaignLogRegByRidAllDateTime.reduce(function (total, currentValue) {
                return total + currentValue.count;
            }, 0);
            campaignViewStats = {firstTime: fSum, gameVisit: '--', regist: rSum};
        }
        yield put(setCampaignViewStats(campaignViewStats));
        yield put(setCampaignDataLoadById(false));
    } catch (error) {
        console.log(error);
    }
}

export function* getCampaignTrendDataByRid() {
    try {
        yield put(setCampaignTrendDataLoadByRid(true));
        const campaignId = yield select(campaignIdToFetch);
        const campaignData = yield API.graphql(graphqlOperation(queries.getCampaignsStatsById, { aid: campaignId }));
        const campaignRid = yield select(campaignRidToFetch);
        const calendarDateRange = yield select(campaignDateRange);
        const isDataNew = moment(calendarDateRange[0]).isAfter('2023-06-30') && moment(calendarDateRange[1]).isAfter('2023-06-30') ? true : false;
        const tableName = isDataNew ? "RegistrationPmEvtProd" : "RegistrationProd";

        if (campaignData.data.getCampaignsStatsById[0]['type'] === 'campaign') {
            const [dataFirstTime, dataGameVisits, dataRegByRid] = yield all([
                API.graphql(graphqlOperation(queries.getCampaignLogByRidAllDateTime, { table: 'CampaignLogFirstTime', rid: campaignRid })),
                API.graphql(graphqlOperation(queries.getCampaignLogByRidAllDateTime, { table: 'CampaignLogGameVisit', rid: campaignRid })),
                API.graphql(graphqlOperation(queries.getCampaignLogRegByRidAllDateTime, { table: tableName, rid: campaignRid }))
            ]);
            yield put(setTrendFirstTimeByRid(dataFirstTime.data.getCampaignLogByRidAllDateTime));
            yield put(setTrendGameVisitByRid(dataGameVisits.data.getCampaignLogByRidAllDateTime));
            yield put(setTrendRegistByRid(dataRegByRid.data.getCampaignLogRegByRidAllDateTime));

            let trendDataFormatted = H.FormatDataHelper.formatCampaignTrendChartData(dataFirstTime, dataRegByRid, dataGameVisits);
            yield put(setCampaignTrendChartData(trendDataFormatted));

        } else if (campaignData.data.getCampaignsStatsById[0]['type'] === 'campaign_game') {
            const [dataGameDirFirstTime, dataGameDirRegByRid] = yield all([
                API.graphql(graphqlOperation(queries.getCampaignLogByRidAllDateTime, { table: 'CampaignLogFirstTimeGameDirProd', rid: campaignRid })),
                API.graphql(graphqlOperation(queries.getCampaignLogRegByRidAllDateTime, { table: tableName, rid: campaignRid }))
            ]);
            yield put(setTrendFirstTimeByRid(dataGameDirFirstTime.data.getCampaignLogByRidAllDateTime));
            yield put(setTrendRegistByRid(dataGameDirRegByRid.data.getCampaignLogRegByRidAllDateTime));

            let trendDataFormatted = H.FormatDataHelper.formatCampaignTrendChartData(dataGameDirFirstTime, dataGameDirRegByRid, false);
            yield put(setCampaignTrendChartData(trendDataFormatted));
        }
        yield put(setCampaignTrendDataLoadByRid(false));
    } catch (error) {
        console.log(error);
    }
}

export function* getCampaignListDataByDates() {
    try {
        yield put(setPageTopLoader(true));
        let activeCampaignList = [], allCampaignDataArray = [], newAllCampaignDataArray = [], campaignsStatsData;
        const calendarDateRange = yield select(campaignDateRange);
        const isDataNew = moment(calendarDateRange[0]).isAfter('2023-06-30') && moment(calendarDateRange[1]).isAfter('2023-06-30') ? true : false;
        const tableName = isDataNew ? "RegistrationPmEvtProd" : "RegistrationProd";
        if (localStorage.getItem('access_key') !== process.env.REACT_APP_SUPERUSER && localStorage.getItem('access_key') !== 'DBFFFBA3AA5AA8394EE342993B973') {
            const jwtToken = yield call(getJwtToken);
            const akValue = localStorage.getItem('access_key');
            const geoCampaignDataByAccessKey = {
                "query": "SELECT * FROM CampaignStats WHERE status='active' AND accessKey LIKE '%"+akValue+"%' ORDER BY affiliateId DESC",
                "database": "KpiDashboard",
                "type": "list"
            };
            campaignsStatsData = yield call(getDataFromRDS, geoCampaignDataByAccessKey, ENDPOINT.GET_DATA_USERS, jwtToken);
            let statsArrayData = JSON.parse(campaignsStatsData.body);
            let eachObj = {};
            _.each(statsArrayData, function(data) {
                eachObj.accessKey = data[7]['stringValue'];
                eachObj.affiliateId = data[1]['longValue'];
                eachObj.createdDate = data[12]['longValue'];
                eachObj.firstTime = data[9]['longValue'];
                eachObj.gameVisit = data[10]['longValue'];
                eachObj.id = data[0]['longValue'];
                eachObj.name = data[3]['stringValue'];
                eachObj.property = data[5]['stringValue'];
                eachObj.registered = data[11]['longValue'];
                eachObj.rid = data[2]['stringValue'];
                eachObj.type = data[6]['stringValue'];
                allCampaignDataArray.push(eachObj);
                activeCampaignList.push(eachObj.rid);
                eachObj = {}
            });
        } else {
            campaignsStatsData = yield API.graphql(graphqlOperation(queries.getCampaignsStats, { accessKey: process.env.REACT_APP_SUPERUSER }));
            _.each(campaignsStatsData.data.getCampaignsStats, function(data) {
                allCampaignDataArray.push(data);
                activeCampaignList.push(data.rid);
            });
        }

        // const [dataFirstTimeGameDir, dataFirstTime, dataGameConv, dataGameReg] = yield all([
        //     API.graphql(graphqlOperation(queries.getCampaignSummaryAllRidAndAllTime, { table: 'CampaignLogFirstTimeGameDirProd'})),
        //     API.graphql(graphqlOperation(queries.getCampaignSummaryAllRidAndAllTime, { table: 'CampaignLogFirstTime'})),
        //     API.graphql(graphqlOperation(queries.getCampaignSummaryAllRidAndAllTime, { table: 'CampaignLogGameVisit'})),
        //     API.graphql(graphqlOperation(queries.getCampaignRegSummaryAllRidAndAllTime, { table: 'RegistrationPmEvtProd'})),
        // ]);
        // console.log('dataFirstTimeGameDir:', dataFirstTimeGameDir.data.getCampaignSummaryAllRidAndAllTime);
        // console.log('dataFirstTime', dataFirstTime.data.getCampaignSummaryAllRidAndAllTime);
        // console.log('dataGameConv', dataGameConv.data.getCampaignSummaryAllRidAndAllTime);
        // console.log('dataGameReg', dataGameReg.data.getCampaignRegSummaryAllRidAndAllTime);

        const sgStartDateToFetch = moment(calendarDateRange[0]).format('YYYY/MM/DD');
        const sgEndDateToFetch = moment(calendarDateRange[1]).format('YYYY/MM/DD');

        // const isDateFromDst = moment(sgStartDateToFetch).isDST();
        // const isDateToDst = moment(sgEndDateToFetch).isDST();
        // const subSecForDateFrom = isDateFromDst ? 43200 : 46800;
        // const subSecForDateTo = isDateToDst ? 43200 : 46800;
        // const startTs = moment(sgStartDateToFetch+" 00:00:00").subtract(subSecForDateFrom, 'seconds').format('x'); // was 28800
        // const endTs = moment(sgEndDateToFetch+" 23:59:59").subtract(subSecForDateTo, 'seconds').format('x');
        const startTs = moment(sgStartDateToFetch+" 00:00:00+8:00").format('x'); // was 28800
        const endTs = moment(sgEndDateToFetch+" 23:59:59+8:00").format('x');

        const [dataFirstTimeGameDirRange, dataFirstTimeRange, dataGameConvRange, dataGameRegRange] = yield all([
            API.graphql(graphqlOperation(queries.getCampaignSummaryAllRidAndWithDateTime, { table: 'CampaignLogFirstTimeGameDirProd', dateFrom: startTs, dateTo: endTs})),
            API.graphql(graphqlOperation(queries.getCampaignSummaryAllRidAndWithDateTime, { table: 'CampaignLogFirstTime', dateFrom: startTs, dateTo: endTs})),
            API.graphql(graphqlOperation(queries.getCampaignSummaryAllRidAndWithDateTime, { table: 'CampaignLogGameVisit', dateFrom: startTs, dateTo: endTs})),
            API.graphql(graphqlOperation(queries.getCampaignRegSummaryAllRidAndWithDateTime, { table: tableName, dateFrom: startTs, dateTo: endTs})),
        ]);
        // console.log('hehe dataFirstTimeGameDirRange:', dataFirstTimeGameDirRange.data.getCampaignSummaryAllRidAndWithDateTime);
        // console.log('hehe dataFirstTime', dataFirstTimeRange.data.getCampaignSummaryAllRidAndWithDateTime);
        // console.log('hehe dataGameConv', dataGameConvRange.data.getCampaignSummaryAllRidAndWithDateTime);
        // console.log('hehe dataGameReg', dataGameRegRange.data.getCampaignRegSummaryAllRidAndWithDateTime);

        let gameDirFirstTimeArray = dataFirstTimeGameDirRange.data.getCampaignSummaryAllRidAndWithDateTime;
        let firstTimeArray = dataFirstTimeRange.data.getCampaignSummaryAllRidAndWithDateTime;
        let gameVisitArray = dataGameConvRange.data.getCampaignSummaryAllRidAndWithDateTime;
        let registArray = dataGameRegRange.data.getCampaignRegSummaryAllRidAndWithDateTime;
        //console.log('firstTimeArray:', firstTimeArray);

        let firstTimeMerged = gameDirFirstTimeArray.concat(firstTimeArray);
        //console.log('hehe dataFirstTime', firstTimeMerged);

        _.each(allCampaignDataArray, function(cData) {
            let pushToArray = false;
            if (cData.id > 438) { // this is where lucky farmer separated
                pushToArray = true;
            }
            let ridVal = _.find(firstTimeMerged, {rid: cData.rid});
            if (ridVal) {
                pushToArray = true;
                cData.firstTime = ridVal.count;
            }

            let gameVisitVal = _.find(gameVisitArray, {rid: cData.rid});
            if (gameVisitVal) {
                pushToArray = true;
                cData.gameVisit = gameVisitVal.count;
            } else {
                cData.gameVisit = 0;
            }

            let regVal = _.find(registArray, {rid: cData.rid});
            if (regVal) {
                pushToArray = true;
                cData.registered = regVal.count;
            } else {
                cData.registered = 0;
            }

            if (pushToArray) {
                newAllCampaignDataArray.push(cData);
            }
        });

        // Sort by registration
        //newAllCampaignDataArray = _.orderBy(newAllCampaignDataArray, 'registered', 'desc');

        let eachCampaignData = {}, campaignStatsList = [];
        _.each(newAllCampaignDataArray, function(data) {
            eachCampaignData.Timestamp = data.createdDate;
            eachCampaignData.Reg = data.registered;
            eachCampaignData.AffiliateId = data.affiliateId;
            eachCampaignData.Status = 'active';
            eachCampaignData.Conv = data.gameVisit;
            eachCampaignData.Game = data.property;
            eachCampaignData.Type = data.type;
            eachCampaignData.Rid = data.rid;
            eachCampaignData.FT = data.firstTime;
            eachCampaignData.Name = unescapeUnicode(data.name);
            campaignStatsList.push(eachCampaignData);
            eachCampaignData = {};
        });
        yield put(setCampaignListData(campaignStatsList));
        localStorage.setItem('affiliatorsList', JSON.stringify(campaignStatsList));
        yield put(setPageTopLoader(false));
    } catch (error) {
        console.log(error);
    }
}

export function* getCampaignListDataByDefinedDates() {
    try {
        const dateRangeSelected = yield select(campaignDateRange);
        const dateStart = dateRangeSelected[0].replace(/-/g, '/');
        const dateEnd = dateRangeSelected[1].replace(/-/g, '/');
        const sgEndDateToFetch = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');

        // 1, 7, 14, 30, 90, 180
        let preSetDateValue = '';//moment().tz("Asia/Singapore").diff(moment('2020/03/11'), 'days');
        let sgStartDateArray = [];
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(14, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(30, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(90, 'days').format('YYYY/MM/DD'));
        sgStartDateArray.push(moment().tz("Asia/Singapore").subtract(180, 'days').format('YYYY/MM/DD'));

        if (sgStartDateArray.indexOf(dateStart) !== -1) {
            if (dateEnd === sgEndDateToFetch) {
                switch(sgStartDateArray.indexOf(dateStart)) {
                    case 0 :
                        preSetDateValue = 1;
                        break;
                    case 1 :
                        preSetDateValue = 7;
                        break;
                    case 2 :
                        preSetDateValue = 14;
                        break;
                    case 3 :
                        preSetDateValue = 30;
                        break;
                    case 4 :
                        preSetDateValue = 90;
                        break;
                    case 5 :
                        preSetDateValue = 180;
                        break;
                    default:
                }
                yield put(setPreDefinedCampaignDateRange(preSetDateValue));
            } else {
                yield put(setPreDefinedCampaignDateRange(''));
            };
        }

        if (dateStart === '2020/03/11') {
            yield put(setPreDefinedCampaignDateRange('all'));
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchReportSettings() {
    yield takeEvery(CAMPAIGN.GET_CAMPAIGN_LIST_DATA_BY_KEY, getCampaignListDataByKey);
    yield takeEvery(CAMPAIGN.SET_CAMPAIGN_ID_TO_FETCH, getCampaignDataById);
    yield takeEvery(CAMPAIGN.SET_CAMPAIGN_TREND_FETCH_RID, getCampaignTrendDataByRid);
    yield takeEvery(CAMPAIGN.GET_CAMPAIGN_SUMMARY_DATA_BY_DATES, getCampaignListDataByDates);
    yield takeEvery(CAMPAIGN.SET_CAMPAIGN_DATE_RANGE, getCampaignListDataByDefinedDates);
}
