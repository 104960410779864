import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Chip,
  Collapse,
} from "@material-ui/core";
import ViewListIcon from "@material-ui/icons/ViewList";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  getGraffitiRacerGameTrendPageData,
} from "actions";
import * as C from "components";
import JtcbNftTrendTable from "components/Table/JtcbNftTrendTable";
import * as V from "styles/variables";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBoxForUnique: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 110,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    filterSection: {
      display: "flex",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    activeUsersTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    grandSection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      fontSize: 16,
    },
    summarySection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      fontSize: 14,
    },
    summarySection2: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(5),
      fontSize: 14,
    },
    summaryBox: {
      paddingLeft: 16,
      borderRight: "1px solid #dcdcdc",
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    gridSectionBottom: {
      marginTop: theme.spacing(3),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    expand: {
      marginLeft: "auto",
      marginRight: 32,
    },
    title: {
      fontSize: 18,
      marginLeft: 36,
      marginTop: 16,
    },
    rareTitle: {
      marginBottom: 8,
    },
  })
);

const GraffitiRacerGamePaidTrend = ({
  setCurrentPage,
  getGraffitiRacerGameTrendPageData,
  trendDataLoading,
  gameTrendDataDepUse,
  gameTrendDataDepGet,
  gameTrendUnqiueCount,
  gameTrendDataDepUseCountDetails,
  gameTrendDataDepUseAmountDetails,
  gameTrendDataDepUseCountDetailsFiat,
  gameTrendDataDepUseAmountDetailsFiat,
  gameTrendUnqiueCountFiat,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [chartDataFiat, setChartDataFiat] = useState([]);
  const [grandTotalDep, setGranTotalDep] = useState(0);
  const [grandTotalDep2, setGranTotalDep2] = useState(0);
  const [grandTotalCount, setGranTotalCount] = useState(0);
  const [grandTotalCount2, setGranTotalCount2] = useState(0);
  const [grandTotalDepFiat, setGranTotalDepFiat] = useState(0);
  const [grandTotalCountFiat, setGranTotalCountFiat] = useState(0);

  // expand
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getGraffitiRacerGameTrendPageData();
  }, []);

  useEffect(() => {
    // combine data
    const dataCombined = gameTrendDataDepUse.concat(gameTrendDataDepGet);
    setChartData(dataCombined);
    let grandTotalCount = 0, grandTotalDep = 0;
    if (gameTrendDataDepUse.length > 0) {
      _.each(gameTrendDataDepUse, function(data, index) {
        grandTotalCount = grandTotalCount + _.sum(data["data"]);
      });
      setGranTotalCount(Number(grandTotalCount));
    }
    if (gameTrendDataDepGet.length > 0) {
      _.each(gameTrendDataDepGet, function(data, index) {
        grandTotalDep = grandTotalDep + _.sum(data["data"]);
      });
      setGranTotalDep(Number(grandTotalDep));
    }
  }, [gameTrendDataDepUse, gameTrendDataDepGet]);

  useEffect(() => {
    // combine data
    const dataCombined2 = gameTrendDataDepUseCountDetails.concat(gameTrendDataDepUseAmountDetails);
    setChartData2(dataCombined2);
    let grandTotalCount2 = 0, grandTotalDep2 = 0;
    if (gameTrendDataDepUseCountDetails.length > 0) {
      _.each(gameTrendDataDepUseCountDetails, function(data, index) {
        grandTotalCount2 = grandTotalCount2 + _.sum(data["data"]);
      });
      setGranTotalCount2(Number(grandTotalCount2));
    }
    if (gameTrendDataDepUseAmountDetails.length > 0) {
      _.each(gameTrendDataDepUseAmountDetails, function(data, index) {
        grandTotalDep2 = grandTotalDep2 + _.sum(data["data"]);
      });
      setGranTotalDep2(Number(grandTotalDep2));
    }
  }, [gameTrendDataDepUseCountDetails, gameTrendDataDepUseAmountDetails]);

  useEffect(() => {
    // combine data
    const dataCombinedFiat = gameTrendDataDepUseCountDetailsFiat.concat(gameTrendDataDepUseAmountDetailsFiat);
    setChartDataFiat(dataCombinedFiat);
    let grandTotalCountFiat = 0, grandTotalDep2 = 0;
    if (gameTrendDataDepUseCountDetailsFiat.length > 0) {
      _.each(gameTrendDataDepUseCountDetails, function(data, index) {
        grandTotalCountFiat = grandTotalCountFiat + _.sum(data["data"]);
      });
      setGranTotalCountFiat(Number(grandTotalCountFiat));
    }
    if (gameTrendDataDepUseAmountDetailsFiat.length > 0) {
      _.each(gameTrendDataDepUseAmountDetailsFiat, function(data, index) {
        grandTotalDep2 = grandTotalDep2 + _.sum(data["data"]);
      });
      setGranTotalDepFiat(Number(grandTotalDep2));
    }
  }, [gameTrendDataDepUseCountDetailsFiat, gameTrendDataDepUseAmountDetailsFiat]);

  const getSalesSummaryData = (type) => {
    if (gameTrendDataDepUse.length < 1) {
      return;
    }
    let countValuel = 0,
      totalDepValue = 0,
      avgDepValue = 0,
      uniqueValue = 0;
    switch (type) {
      case "use":
        countValuel = _.sum(gameTrendDataDepUse[0]["data"])
        totalDepValue = _.sum(gameTrendDataDepGet[0]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = gameTrendUnqiueCount[0]["use"];
        break;
      case "get":
        countValuel = _.sum(gameTrendDataDepUse[1]["data"])
        totalDepValue = _.sum(gameTrendDataDepGet[1]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = gameTrendUnqiueCount[1]["get"];
        break;
      case "fiat":
        countValuel = _.sum(gameTrendDataDepUse[2]["data"])
        totalDepValue = _.sum(gameTrendDataDepGet[2]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = gameTrendUnqiueCount[10]["fiatUse"];
        break;
      default:
        break;
    }
    return (
      <>
      <div>
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        DEP：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)}{"　|　"}
        DEP平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(avgDepValue) : 0}
      </div>
      <div style={{marginTop: 8}}>
        ユニーク：{H.FormatNumStrHelper.formatCommaThousand(uniqueValue)} {"　|　"}
        ユニークDEP平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(totalDepValue/uniqueValue) : 0}
      </div>
      </>
    );
  };

  const getSalesSummaryDataDetails = (type, isFiat) => {
    if (gameTrendDataDepUse.length < 1) {
      return;
    }
    let countValuel = 0,
      totalDepValue = 0,
      avgDepValue = 0,
      uniqueValue = 0;
    switch (type) {
      case "film":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[0]["data"]) : _.sum(gameTrendDataDepUseCountDetails[0]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[0]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[0]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[0]["p1"] : gameTrendUnqiueCount[2]["p1"];
        break;
      case "coating":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[1]["data"]) : _.sum(gameTrendDataDepUseCountDetails[1]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[1]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[1]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[1]["p2"] : gameTrendUnqiueCount[3]["p2"];
        break;
      case "breed":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[2]["data"]) : _.sum(gameTrendDataDepUseCountDetails[2]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[2]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[2]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[2]["p3"] : gameTrendUnqiueCount[4]["p3"];
        break;
      case "regular":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[3]["data"]) : _.sum(gameTrendDataDepUseCountDetails[3]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[3]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[3]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[3]["p4"] : gameTrendUnqiueCount[5]["p4"];
        break;
      case "ability":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[4]["data"]) : _.sum(gameTrendDataDepUseCountDetails[4]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[4]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[4]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[4]["p5"] : gameTrendUnqiueCount[6]["p5"];
        break;
      case "premium":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[5]["data"]) : _.sum(gameTrendDataDepUseCountDetails[5]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[5]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[5]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[5]["p6"] : gameTrendUnqiueCount[7]["p6"];
        break;
      case "coloring":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[6]["data"]) : _.sum(gameTrendDataDepUseCountDetails[6]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[6]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[6]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? gameTrendUnqiueCountFiat[6]["p7"] :gameTrendUnqiueCount[8]["p7"];
        break;
      case "sheet":
        countValuel = isFiat ? _.sum(gameTrendDataDepUseCountDetailsFiat[7]["data"]) : _.sum(gameTrendDataDepUseCountDetails[7]["data"])
        totalDepValue = isFiat ? _.sum(gameTrendDataDepUseAmountDetailsFiat[7]["data"]) : _.sum(gameTrendDataDepUseAmountDetails[7]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        uniqueValue = isFiat ? 0 : gameTrendUnqiueCount[9]["sheet"];
        break;
      default:
        break;
    }
    return (
      <>
      <div>
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        DEP：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)}{"　|　"}
        DEP平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(avgDepValue) : 0}
      </div>
      <div style={{marginTop: 8}}>
        ユニーク：{H.FormatNumStrHelper.formatCommaThousand(uniqueValue)} {"　|　"}
        ユニークDEP平均：{avgDepValue !== "NaN" ? H.FormatNumStrHelper.formatCommaThousand(totalDepValue/uniqueValue) : 0}
      </div>
      </>
    );
  };


  const fetchData = () => {
    getGraffitiRacerGameTrendPageData();
    setBtnState(!btnStateDataFetch);
  };

  return (
    <C.ContentWrapper>
      <div className={classes.filterSection}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <div className={classes.title}>GraffitiRacer In Game 取引トレンド</div>
          </Grid>
          <Grid item>
            <Grid container spacing={0} direction="row">
              <Grid item>
                <C.CalendarRangeOnly
                  setBtnState={setBtnState}
                  page="graffitiRacerGameTrend"
                />
              </Grid>
              <Grid>
                <Button
                  //disabled={btnStateDataFetch}
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item sm={6} md={4} lg={4} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="DEP Get"
              style={{
                color: "#000",
                backgroundColor: V.genericColorPallette[0],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryData("get")}
        </Grid>
        <Grid item sm={6} md={4} lg={4} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="DEP Use"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[1],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryData("use")}
        </Grid>
        <Grid item sm={6} md={4} lg={4} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="FIAT Use"
              style={{
                color: "#fff",
                backgroundColor: V.fiatColor,
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryData("fiat")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              //legend={ChartConfig.InGameTrendLuckyFarmer}
              lblSaveImage={"DEP取引種別"}
              xAisData={chartData[0]["date"]}
              seriesData={chartData}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="GraffitiRacer In Game 取引データ"
            dataCount={gameTrendDataDepUse}
            dataAmount={gameTrendDataDepGet}
          />
        </div>
      </Collapse>

      <Grid item>
        <div className={classes.title}>In Game DEP Use 詳細</div>
      </Grid>
      {/* DEP Use details */}
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection2}
      >
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="フィルム購入"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[2],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("film")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="コーティング"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[3],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("coating")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="ブリード"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[4],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("breed")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="レギュラーシートパック購入"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[5],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("regular")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="アビリティ因子解析"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[6],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("ability")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="プレミアムシートパック購入"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[7],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("premium")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="ぬり絵（復元"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[8],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("coloring")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSectionBottom}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              lblSaveImage={"DEP取引種別"}
              xAisData={chartData2[0]["date"]}
              seriesData={chartData2}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="GraffitiRacer In Game DEP Use 詳細"
            dataCount={gameTrendDataDepUseCountDetails}
            dataAmount={gameTrendDataDepUseAmountDetails}
          />
        </div>
      </Collapse>

      {/* FIAT Use details */}
      <Grid item>
        <div className={classes.title}>In Game FIAT Use 詳細</div>
      </Grid>
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection2}
      >
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="フィルム購入"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[2],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("film", true)}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="コーティング"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[3],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("coating", true)}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="ブリード"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[4],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("breed", "fiat")}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="レギュラーシートパック購入"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[5],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("regular", true)}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="アビリティ因子解析"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[6],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("ability", true)}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="プレミアムシートパック購入"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[7],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("premium", true)}
        </Grid>
        <Grid item sm={6} md={4} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="ぬり絵（復元"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[8],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSalesSummaryDataDetails("coloring", true)}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSectionBottom}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              lblSaveImage={"DEP取引種別"}
              xAisData={chartDataFiat[0]["date"]}
              seriesData={chartDataFiat}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="GraffitiRacer In Game DEP Use 詳細"
            dataCount={gameTrendDataDepUseCountDetailsFiat}
            dataAmount={gameTrendDataDepUseAmountDetailsFiat}
          />
        </div>
      </Collapse>

    </C.ContentWrapper>
  );
};

GraffitiRacerGamePaidTrend.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  trendDataLoading: state.graffitiRacerGamePaidTrend.graffitiRacerGamePaidTrendDateLoading,
  gameTrendDataDepUse: state.graffitiRacerGamePaidTrend.gameTrendDataDepUse,
  gameTrendDataDepGet: state.graffitiRacerGamePaidTrend.gameTrendDataDepGet,
  gameTrendUnqiueCount: state.graffitiRacerGamePaidTrend.gameTrendUnqiueCount,
  gameTrendDataDepUseCountDetails: state.graffitiRacerGamePaidTrend.gameTrendDataDepUseCountDetails,
  gameTrendDataDepUseAmountDetails: state.graffitiRacerGamePaidTrend.gameTrendDataDepUseAmountDetails,
  gameTrendDataDepUseCountDetailsFiat: state.graffitiRacerGamePaidTrend.gameTrendDataDepUseCountDetailsFiat,
  gameTrendDataDepUseAmountDetailsFiat: state.graffitiRacerGamePaidTrend.gameTrendDataDepUseAmountDetailsFiat,
  gameTrendUnqiueCountFiat: state.graffitiRacerGamePaidTrend.gameTrendUnqiueCountFiat,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getGraffitiRacerGameTrendPageData: () => dispatch(getGraffitiRacerGameTrendPageData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(GraffitiRacerGamePaidTrend));
