const BarChartStyles = theme => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
    },
    chartContainer: {
        marginTop: -16,
        width: '100%',
        display: 'block'
    }
});

export default BarChartStyles;