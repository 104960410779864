import { PUZZLE_GEO } from '../constants';

const initialState  = {
    acquisitionGeoDataReg: {
        pmidRegNumCountry: '-',
        pmidRegNumCountryAd: '-',
        pmidRegNumCountryOrg: '-',
        pmidRegNewCountry: '-',
        pmidRegExistCountry: '-',
    },
    pmidRegAdVsOrg: [],
    pmidRegNewVsExist: [],
    countryByAd: [],
    countryByOrg: [],
};

export default function puzzleGeoReducer(state = initialState, action) {
    switch (action.type) {
        case PUZZLE_GEO.SET_ACQUISITION_GEO_DATA_REG:
            return {
                ...state,
                acquisitionGeoDataReg: action.payload
            };
        case PUZZLE_GEO.SET_ACQUISITION_GEO_DATA_REG_ADORG:
            return {
                ...state,
                pmidRegAdVsOrg: action.payload
            };
        case PUZZLE_GEO.SET_ACQUISITION_GEO_DATA_REG_NEWEXIST:
            return {
                ...state,
                pmidRegNewVsExist: action.payload
            };
        case PUZZLE_GEO.SET_ACQUISITION_COUNTRY_BY_AD:
            return {
                ...state,
                countryByAd: action.payload
            };
        case PUZZLE_GEO.SET_ACQUISITION_COUNTRY_BY_ORGANIC:
            return {
                ...state,
                countryByOrg: action.payload
            };
        default:
            return state
    }
};
