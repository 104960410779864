import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as C from 'components';

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 200,
  },
  dateTableWrapper: {
    float: 'left',
  },
  dataTableWrapper: {
    float: 'left',
    overflow: 'scroll',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblGameName: {
    color: '#000',
    fontSize: 13,
    display: 'inline-block',
    marginRight: "16px",
  },
  iconTooltip: {
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'auto',
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    width: 250,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  }
});

const KpiSalesTableByGame = ({
  kpiSalesData,
  kpiSalesDataSizeSelected,
  pageTopLoader,
  game
}) => {
  const classes = useStyles();
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  const getTableLabelColumn = () => {
    let tipBySize = `Daily Active Userの略<br />1日のユニークでの利用者数`;
    if (kpiSalesDataSizeSelected.name === "WAU") {
      tipBySize = `Weekly Active Userの略<br />1週あたりの利用者数`;
    } else if (kpiSalesDataSizeSelected.name === "MAU") {
      tipBySize = `Monthly Active Userの略<br />1月あたりの利用者数`;
    }

    return  <>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>課金率</div>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div>課金率 = ユニーク課金数 ÷ {kpiSalesDataSizeSelected.name}
                          <br /><hr />課金率という指標は {kpiSalesDataSizeSelected.name}の大小に影響されない、ゲームのマネタイズ力を見る指標の1つとして機能します。
                        </div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon className={classes.iconTooltip} fontSize="small" />
                  </HtmlTooltip>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>ARPU</div>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div>ARPU = 該当期間のDEP売上 ÷ アクティブユーザー数
                          <br /><hr />Average Revenue Per Userの略で、DEP売上を<b>課金あり・なしを含むユニークのユーザー数</b>で割った数値
                        </div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon className={classes.iconTooltip} fontSize="small" />
                  </HtmlTooltip>
                </td>
              </tr >
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>ARPPU</div>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div>ARPPU = 該当期間のDEP売上 ÷ ユニーク課金者数
                        <br /><hr />Average Revenue Per Paid Userの略で、該当期間で、DEP売上を<b>課金者のみ</b>で売上を割った数値
                        </div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon className={classes.iconTooltip} fontSize="small" />
                  </HtmlTooltip>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>{kpiSalesDataSizeSelected.name}</div>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div dangerouslySetInnerHTML={{ __html: tipBySize }}></div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon className={classes.iconTooltip} fontSize="small" />
                  </HtmlTooltip>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>ユニーク課金数</div>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div>英語では、Paid Unique User (PUU)</div>
                      </React.Fragment>
                    }
                  >
                    <HelpIcon className={classes.iconTooltip} fontSize="small" />
                  </HtmlTooltip>
                </td>
              </tr>
            </>
  }

  const getDataTable = (kpiSalesData) => {
    return  <>
              <tr>
              {kpiSalesData[0]["paidUnique"].map((d, i) =>
                <td className={classes.dataCellTd} key={i} style={{fontWeight:800}}>{(d/kpiSalesData[0]["dau"][i]*100).toFixed(2)}%</td>
              )}
              </tr >
              <tr>
              {kpiSalesData[0]["arpu"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {kpiSalesData[0]["arppu"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {kpiSalesData[0]["dau"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {kpiSalesData[0]["paidUnique"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
            </>
  }

  const getExportData = (kpiSalesData) => {
    let exportData = [];
    if (kpiSalesData.length > 0) {
      let headerArray = ["売上指標データ"];
      let numOfHeaderData = kpiSalesData[0]['date'].length;
      for (let j=0; j<numOfHeaderData; j++) {
        headerArray.push(kpiSalesData[0]['date'][j]);
      }
      exportData.push(headerArray);

      // 課金率
      let kakinRatioArray = ["課金率"];
      for (let j=0; j<numOfHeaderData; j++) {
        const ratio = (kpiSalesData[0]['paidUnique'][j]/kpiSalesData[0]["dau"][j]*100).toFixed(2);
        kakinRatioArray.push(`${ratio}%`);
      }
      exportData.push(kakinRatioArray);

      // ARPU
      let arpuArray = ["APRU"];
      for (let j=0; j<numOfHeaderData; j++) {
        arpuArray.push(kpiSalesData[0]['arpu'][j]);
      }
      exportData.push(arpuArray);

      // ARPU
      let arppuArray = ["ARPPU"];
      for (let j=0; j<numOfHeaderData; j++) {
        arppuArray.push(kpiSalesData[0]['arppu'][j]);
      }
      exportData.push(arppuArray);

      // DAU
      let dauArray = [kpiSalesDataSizeSelected.name];
      for (let j=0; j<numOfHeaderData; j++) {
        dauArray.push(kpiSalesData[0]['dau'][j]);
      }
      exportData.push(dauArray);

      // Unique Paid Users
      let upuArray = ["ユニーク課金数"];
      for (let j=0; j<numOfHeaderData; j++) {
        upuArray.push(kpiSalesData[0]['paidUnique'][j]);
      }
      exportData.push(upuArray);
    }
    return exportData;
  }

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}></div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getExportData(kpiSalesData)}
              filename={`売上指標データ ${game}`}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
                CSV出力
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className={classes.tableContainer}>
      {pageTopLoader
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
        : kpiSalesData.length > 0 ?
            kpiSalesData.length > 0 ?
              <>
                    <div className={classes.dateTableWrapper}>
                    <table className={classes.leftTable}>
                        <tbody>
                        <tr>
                            <td className={classes.tTdHeader}>
                            </td>
                        </tr >
                        {getTableLabelColumn(kpiSalesData)}
                        </tbody>
                    </table>
                    </div>

                    <div className={classes.dataTableWrapper}>
                    <table className={classes.dataTableBody}>
                      <tbody>
                        <tr className={classes._GAIv}>
                        {kpiSalesData[0]['date'].map((item, i) => (
                          <td className={classes.dataHeaderTd} key={i}>
                            {
                              kpiSalesDataSizeSelected.value === "dau" ?
                              moment(item).format('M/D')
                              : item
                            }
                          </td>
                        ))}
                        </tr >
                          {getDataTable(kpiSalesData)}
                      </tbody>
                    </table>
                    </div>
                </>
              : <div className={classes.loaderBox}><C.NoData text="No Data" /></div>
            : null
        }
      </div>
    </Paper>
  )
}

KpiSalesTableByGame.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
  kpiSalesData: state.kpi.kpiSalesData,
  kpiSalesDataSizeSelected: state.kpi.kpiSalesDataSizeSelected,
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(KpiSalesTableByGame);
