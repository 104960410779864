import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setGraffitiRacerGameTrendDataLoading,
  setGraffitiRacerGameTrendDataDepUse,
  setGraffitiRacerGameTrendDataDepGet,
  setGraffitiRacerGameTrendDataUniqueCount,
  setGraffitiRacerGameTrendDataDepUseCntDetails,
  setGraffitiRacerGameTrendDataDepUseAmtDetails,
  setGraffitiRacerGameTrendDataDepUseCntDetailsFiat,
  setGraffitiRacerGameTrendDataDepUseAmtDetailsFiat,
  setGraffitiRacerGameTrendDataUniqueCountFiat
} from "actions";
import { getJwtToken, getDataFromRDS } from "api";
import { GRAFITTIRACER, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = state => state.page.currentPage;
export const graffitiRacerGamePaidTrendDateRange = state => state.graffitiRacerGamePaidTrend.graffitiRacerGamePaidTrendDateRange;
export const startDate = state => state.graffitiRacerGamePaidTrend.graffitiRacerGamePaidTrendDateRange[0];
export const endDate = state => state.graffitiRacerGamePaidTrend.graffitiRacerGamePaidTrendDateRange[1];

export function* getGraffitiRacerNftTrendData() {
  try {
    yield put(setPageTopLoader(true));
    yield put(setGraffitiRacerGameTrendDataLoading(true));

    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(graffitiRacerGamePaidTrendDateRange);

    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    dateTo = moment(dateTo).format("YYYY-MM-DD");

    // DATA2.0 switch date: 2023-06-25
    //const isDataNew = moment(dateFrom).isAfter('2023-06-24') ? true : false;
    let tableName = "GraffitiRacerGameSalesCryptoPmEvtProd", newRangeOld, newRangeNew, unixStampTimeOld, unixStampTimeNew;
    const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;
    const isDataOld = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isBefore('2023-07-01') ? true : false;
    const isDataMix = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;

    let unixStampTime = [
      moment(rangeTarget[0]+" 00:00:00+8:00").format('x'),
      moment(rangeTarget[1]+" 23:59:59+8:00").format('x')
    ];

    // DATA2.0 table configuring
    if (isDataOld) {
      tableName = "GraffitiRacerGameSalesCryptoProd";
    } else if(isDataMix) {
      // newRangeOld = [rangeTarget[0], '2023-06-30'];
      // newRangeNew = ['2023-07-01', rangeTarget[1]];
      // // old
      // tableName = "GraffitiRacerGameSalesCryptoProd";
      // unixStampTime = [
      //   moment(rangeTarget[0]+" 00:00:00+8:00").format('x'),
      //   moment("2023-06-30 23:59:59+8:00").format('x')
      // ];
      // unixStampTimeNew = [
      //   moment("2023-07-01 00:00:00+8:00").format('x'),
      //   moment(rangeTarget[1]+" 23:59:59+8:00").format('x')
      // ];
    }
    console.log("LOG : new, old, mix", isDataNew, isDataOld, isDataMix);

    // Count
    const sqlQueryInGameCountByUse = H.SqlHelper.getGameTransactionCountOnGraffiti({
      table: tableName,
      type: "DEP Use",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByGet = H.SqlHelper.getGameTransactionCountOnGraffiti({
      table: tableName,
      type: "DEP Get",
      range: unixStampTime,
    });
    // const sqlQueryInGameCountBySheet = H.SqlHelper.getGameTransactionCountGraffitiBySales({
    //   table: isDataNew ? "GraffitiRacerGameSalesSheetPmEvtProd" : "GraffitiRacerGameSalesSheetProd",
    //   range: unixStampTime,
    // });

    // Amount
    const sqlQueryInGameAmountByUse = H.SqlHelper.getGameTransactionAmountOnGraffiti({
      table: tableName,
      type: "DEP Use",
      range: unixStampTime,
    });
    const sqlQueryInGameAmountByGet = H.SqlHelper.getGameTransactionAmountOnGraffiti({
      table: tableName,
      type: "DEP Get",
      range: unixStampTime,
    });
    // const sqlQueryInGameAmountBySheet = H.SqlHelper.getGameTransactionAmountGraffitiBySales({
    //   table: tableName,
    //   range: unixStampTime,
    // });

    // Unique
    const sqlQueryInGameCountByUseUnique = H.SqlHelper.getGameTransactionCountOnGraffitiUnique({
      table: tableName,
      type: "DEP Use",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByGetUnique = H.SqlHelper.getGameTransactionCountOnGraffitiUnique({
      table: tableName,
      type: "DEP Get",
      range: unixStampTime,
    });
    // const sqlQueryInGameCountBySheetUnique = H.SqlHelper.getGameTransactionCountGraffitiBySalesUnique({
    //   table: isDataNew ? "GraffitiRacerGameSalesSheetPmEvtProd" : "GraffitiRacerGameSalesSheetProd",
    //   range: unixStampTime,
    // });

    // FIAT
    // Count
    const sqlQueryInGameCountByUseFiat = H.SqlHelper.getGameTransactionCountOnGraffiti({
      table: tableName,
      type: "FIAT Use",
      range: unixStampTime,
    });
    const sqlQueryInGameAmountByUseFiat = H.SqlHelper.getGameTransactionAmountOnGraffiti({
      table: tableName,
      type: "FIAT Use",
      range: unixStampTime,
    });
    // Unique
    const sqlQueryInGameCountByUseUniqueFiat = H.SqlHelper.getGameTransactionCountOnGraffitiUnique({
      table: tableName,
      type: "FIAT Use",
      range: unixStampTime,
    });

    const [useCountTemp, getCountTemp, /*sheetCountTemp,*/ useAmountTemp, getAmountTemp, /*sheetAmountTemp,*/
           useCountUniqueTemp, getCountUniqueTemp, /*sheetCountUniqueTemp,*/
           fiatUseCountTemp, fiatUseAmountTemp, fiatUseCountUniqueTemp] = yield all([
      call(getDataFromRDS, sqlQueryInGameCountByUse, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByGet, ENDPOINT.GET_DATA_RDS, jwtToken),
      //call(getDataFromRDS, sqlQueryInGameCountBySheet, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByUse, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByGet, ENDPOINT.GET_DATA_RDS, jwtToken),
      //call(getDataFromRDS, sqlQueryInGameAmountBySheet, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByGetUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
      //call(getDataFromRDS, sqlQueryInGameCountBySheetUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByUseFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
    ]);

    // Unique
    let uniqueUse = 0, uniqueGet = 0, uniqueSheet = 0,
        uniqueCountArrayUse = [], uniqueCountArrayGet = [];
    let fiatUnique = 0, fiatUniqueCountArrayUse = [];
    _.forEach(JSON.parse(useCountUniqueTemp.body), function (d, i) {
      uniqueUse = uniqueUse+d[1]['longValue'];
      uniqueCountArrayUse.push(d[1]['longValue']);
    });
    _.forEach(JSON.parse(getCountUniqueTemp.body), function (d, i) {
      uniqueGet = uniqueGet+d[1]['longValue'];
      uniqueCountArrayGet.push(d[1]['longValue']);
    });
    // _.forEach(JSON.parse(sheetCountUniqueTemp.body), function (d, i) {
    //   uniqueSheet = uniqueSheet+d[1]['longValue'];
    // });
    uniqueUse = uniqueUse + uniqueSheet;
    let uniqueCountData = [
      {"use": uniqueUse},      
      {"get": uniqueGet},
    ];
    let uniqueCountDataFiat = [];

    // calendar date range
    const range = moment.range(rangeTarget[0], rangeTarget[1]);
    const days = Array.from(range.by("days"));
    const dateArray = days.map((m) => m.format("YYYY-MM-DD"));

    let allDataCount = [], allDataAmount = [];

    let countArrayUse = [], amountArrayUse = [];
    _.forEach(JSON.parse(useCountTemp.body), function (data, i) {
        countArrayUse.push(data[1]['longValue'])
    });
    _.forEach(JSON.parse(useAmountTemp.body), function (data, i) {
      isDataNew
      ? amountArrayUse.push(Number(data[1]['doubleValue'].toFixed(2)))
      : amountArrayUse.push(Number(data[1]['stringValue']))
    });

    let countArrayGet = [], amountArrayGet = [];
    _.forEach(JSON.parse(getCountTemp.body), function (data, i) {
        countArrayGet.push(data[1]['longValue'])
    });
    _.forEach(JSON.parse(getAmountTemp.body), function (data, i) {
      isDataNew
      ? amountArrayGet.push(Number(data[1]['doubleValue'].toFixed(2)))
      : amountArrayGet.push(Number(data[1]['stringValue']))
    });

    // NOT IN USE
    // let countArraySheet = [], amountArraySheet = [];
    // _.forEach(JSON.parse(sheetCountTemp.body), function (data, i) {
    //   countArraySheet.push(data[1]['longValue'])
    // });
    // _.forEach(JSON.parse(sheetAmountTemp.body), function (data, i) {
    //   isDataNew
    //   ? amountArraySheet.push(Number(data[1]['doubleValue'].toFixed(2)))
    //   : amountArraySheet.push(Number(data[1]['stringValue']))
    // });

    // FIAT
    const fiatUseCountData = JSON.parse(fiatUseCountTemp.body);
    const fiatUseAmountData = JSON.parse(fiatUseAmountTemp.body);
    const fiatUseCountDataUni = JSON.parse(fiatUseCountUniqueTemp.body)
    let countArrayFiatUse = [], amountArrayFiatUse = [];
    let foundIndex = -1;
    _.forEach(dateArray, function (date, i) {
      const exists = fiatUseCountData.some(subArray => subArray.some(item => item.stringValue === date));
      if (exists && fiatUseCountData[i] !== undefined) {
        fiatUseCountData.some((subArray, subIndex) => {
          const index = subArray.findIndex(item => item.stringValue === date);
          if (index !== -1) {
            foundIndex = [subIndex, index];
            return true;
          }
          return false;
        });
        countArrayFiatUse.push(fiatUseCountData[foundIndex[0]][1]['longValue']);
        fiatUniqueCountArrayUse.push(fiatUseCountDataUni[foundIndex[0]][1]['longValue']);
      } else {
        countArrayFiatUse.push(0);
        fiatUniqueCountArrayUse.push(0);
      }

      if (fiatUseAmountData[i] !== undefined && exists) {
        if (isDataNew) {
          amountArrayFiatUse.push(Number(fiatUseAmountData[foundIndex[0]][1]['doubleValue'].toFixed(2)));
        } else {
          amountArrayFiatUse.push(Number(fiatUseAmountData[foundIndex[0]][1]['stringValue']));
        }
      } else {
        amountArrayFiatUse.push(0);
      }
    });

    // _.forEach(JSON.parse(fiatUseCountTemp.body), function (data, i) {
    //   console.log("KEKE datadata:", data[0]['stringValue']);
    //   countArrayFiatUse.push(data[1]['longValue'])
    // });
    // _.forEach(JSON.parse(fiatUseAmountTemp.body), function (data, i) {
    //   isDataNew
    //   ? amountArrayFiatUse.push(Number(data[1]['doubleValue'].toFixed(2)))
    //   : amountArrayFiatUse.push(Number(data[1]['stringValue']))
    // });

    // combine sheet pack sale to Dep Use: NOT IN USE
    // const combinedCountUse = _.unzipWith([countArrayUse, countArraySheet], _.add);
    // const combinedAmountUse = _.unzipWith([amountArrayUse, amountArraySheet], _.add);

    allDataCount.push(
      {
          id: "ingame-dep-use-count",
          data: countArrayUse,
          date: dateArray
      }
    );
    allDataCount.push(
      {
          id: "ingame-dep-use-count-unique",
          data: uniqueCountArrayUse,
          date: dateArray
      }
    );
    allDataCount.push(
      {
          id: "ingame-dep-get-count",
          data: countArrayGet,
          date: dateArray
      }
    );
    allDataCount.push(
      {
          id: "ingame-dep-get-count-unique",
          data: uniqueCountArrayGet,
          date: dateArray
      }
    );

    allDataAmount.push(
        {
            id: "ingame-dep-use-amount",
            data: amountArrayUse,
            date: dateArray
        }
    );
    allDataAmount.push(
        {
            id: "ingame-dep-get-amount",
            data: amountArrayGet,
            date: dateArray
        }
    );

    // FIAT
    allDataCount.push(
      {
          id: "ingame-fiat-use-count",
          data: countArrayFiatUse,
          date: dateArray
      }
    );
    allDataCount.push(
      {
          id: "ingame-fiat-use-count-unique",
          data: fiatUniqueCountArrayUse,
          date: dateArray
      }
    );
    allDataAmount.push(
      {
          id: "ingame-fiat-use-amount",
          data: amountArrayFiatUse,
          date: dateArray
      }
    );

    // Split Dep Use by Type
    const sqlQueryInGameCountByUseProduct1 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "フィルム購入",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByUseUniqueByProduct1 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "フィルム購入",
      range: unixStampTime,
    });
    const sqlQueryInGameAmountByProduct1 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "フィルム購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct2 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "コーティング",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByUseUniqueByProduct2 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "コーティング",
      range: unixStampTime,
    });
    const sqlQueryInGameAmountByProduct2 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "コーティング",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct3 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "ブリード",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByUseUniqueByProduct3 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "ブリード",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct3 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "ブリード",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct4 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "レギュラーシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct4 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "レギュラーシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct4 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "レギュラーシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct5 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "アビリティ因子解析",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct5 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "アビリティ因子解析",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct5 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "アビリティ因子解析",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct6 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "プレミアムシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct6 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "プレミアムシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct6 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "プレミアムシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct7 = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "ぬり絵（復元）",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct7 = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "DEP Use",
      product: "ぬり絵（復元）",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct7 = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "DEP Use",
      product: "ぬり絵（復元）",
      range: unixStampTime,
    });

    // FIAT by Product
    const sqlQueryInGameCountByUseProduct1Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "フィルム購入",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByUseUniqueByProduct1Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "フィルム購入",
      range: unixStampTime,
    });
    const sqlQueryInGameAmountByProduct1Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "フィルム購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct2Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "コーティング",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByUseUniqueByProduct2Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "コーティング",
      range: unixStampTime,
    });
    const sqlQueryInGameAmountByProduct2Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "コーティング",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct3Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "ブリード",
      range: unixStampTime,
    });
    const sqlQueryInGameCountByUseUniqueByProduct3Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "ブリード",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct3Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "ブリード",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct4Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "レギュラーシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct4Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "レギュラーシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct4Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "レギュラーシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct5Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "アビリティ因子解析",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct5Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "アビリティ因子解析",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct5Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "アビリティ因子解析",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct6Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "プレミアムシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct6Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "プレミアムシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct6Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "プレミアムシートパック購入",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseProduct7Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "ぬり絵（復元）",
      range: unixStampTime,
    });

    const sqlQueryInGameCountByUseUniqueByProduct7Fiat = H.SqlHelper.getGameTransactionCountOnGraffitiUniqueByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "ぬり絵（復元）",
      range: unixStampTime,
    });

    const sqlQueryInGameAmountByProduct7Fiat = H.SqlHelper.getGameTransactionAmountOnGraffitiByProduct({
      table: tableName,
      type: "FIAT Use",
      product: "ぬり絵（復元）",
      range: unixStampTime,
    });

    const [productTemp1, productUniTemp1, productAmtTemp1, productTemp2, productUniTemp2, productAmtTemp2, 
           productTemp3, productUniTemp3, productAmtTemp3, productTemp4, productUniTemp4, productAmtTemp4, 
           productTemp5, productUniTemp5, productAmtTemp5, productTemp6, productUniTemp6, productAmtTemp6,
           productTemp7, productUniTemp7, productAmtTemp7,
           productTemp1Fiat, productUniTemp1Fiat, productAmtTemp1Fiat, productTemp2Fiat, productUniTemp2Fiat, productAmtTemp2Fiat, 
           productTemp3Fiat, productUniTemp3Fiat, productAmtTemp3Fiat, productTemp4Fiat, productUniTemp4Fiat, productAmtTemp4Fiat, 
           productTemp5Fiat, productUniTemp5Fiat, productAmtTemp5Fiat, productTemp6Fiat, productUniTemp6Fiat, productAmtTemp6Fiat,
           productTemp7Fiat, productUniTemp7Fiat, productAmtTemp7Fiat ] = yield all([
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct1, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct1, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct1, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct2, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct2, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct2, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct3, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct3, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct3, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct4, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct4, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct4, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct5, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct5, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct5, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct6, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct6, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct6, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct7, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct7, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct7, ENDPOINT.GET_DATA_RDS, jwtToken),
      // FIAT
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct1Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct1Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct1Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct2Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct2Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct2Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct3Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct3Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct3Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct4Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct4Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct4Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct5Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct5Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct5Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct6Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct6Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct6Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseProduct7Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameCountByUseUniqueByProduct7Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
      call(getDataFromRDS, sqlQueryInGameAmountByProduct7Fiat, ENDPOINT.GET_DATA_RDS, jwtToken),
    ]);
    let allDataCountDetails = [], allDataAmountDetails = [];
    let allDataCountDetailsFiat = [], allDataAmountDetailsFiat = [];
    // P1
    let countArrayP1 = [], amountArrayP1 = [], countArrayUniP1 = [], uniqueP1 = 0;
    const parsedDataCnt1 = JSON.parse(productTemp1.body);
    const parsedDataAmt1 = JSON.parse(productAmtTemp1.body);
    let parsedData1Date = [];
    _.forEach(parsedDataCnt1, function(d, i) {
      parsedData1Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData1Date.indexOf(date) !== -1) {
        countArrayP1.push(Number(parsedDataCnt1[parsedData1Date.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP1.push(Number(parsedDataAmt1[parsedData1Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP1.push(Number(parsedDataAmt1[parsedData1Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP1.push(0);
        amountArrayP1.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp1.body), function (data, i) {
      countArrayUniP1.push(Number(data[1]['stringValue']));
      uniqueP1 = uniqueP1+data[1]['longValue'];
    });
    uniqueCountData.push({"p1": uniqueP1});

    // P1 FIAT
    let countArrayP1Fiat = [], amountArrayP1Fiat = [], countArrayUniP1Fiat = [], uniqueP1Fiat = 0;
    const parsedDataCnt1Fiat = JSON.parse(productTemp1Fiat.body);
    const parsedDataAmt1Fiat = JSON.parse(productAmtTemp1Fiat.body);
    let parsedData1DateFiat = [];
    _.forEach(parsedDataAmt1Fiat, function(d, i) {
      parsedData1DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData1DateFiat.indexOf(date) !== -1) {
        countArrayP1Fiat.push(Number(parsedDataCnt1Fiat[parsedData1DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP1Fiat.push(Number(parsedDataAmt1Fiat[parsedData1DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP1Fiat.push(Number(parsedDataAmt1Fiat[parsedData1DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP1Fiat.push(0);
        amountArrayP1Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp1Fiat.body), function (data, i) {
      countArrayUniP1Fiat.push(Number(data[1]['stringValue']));
      uniqueP1Fiat = uniqueP1Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p1": uniqueP1Fiat});

    // P2
    let countArrayP2 = [], amountArrayP2 = [], countArrayUniP2 = [], uniqueP2 = 0;
    const parsedDataCnt2 = JSON.parse(productTemp2.body);
    const parsedDataAmt2 = JSON.parse(productAmtTemp2.body);
    let parsedData2Date = [];
    _.forEach(parsedDataAmt2, function(d, i) {
      parsedData2Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData2Date.indexOf(date) !== -1) {
        countArrayP2.push(Number(parsedDataCnt2[parsedData2Date.indexOf(date)][1]['longValue']));
        isDataNew
        ? amountArrayP2.push(Number(parsedDataAmt2[parsedData2Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP2.push(Number(parsedDataAmt2[parsedData2Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP2.push(0);
        amountArrayP2.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp2.body), function (data, i) {
      countArrayUniP2.push(Number(data[1]['stringValue']));
      uniqueP2 = uniqueP2+data[1]['longValue'];
    });
    uniqueCountData.push({"p2": uniqueP2});

    // P2 FIAT
    let countArrayP2Fiat = [], amountArrayP2Fiat = [], countArrayUniP2Fiat = [], uniqueP2Fiat = 0;
    const parsedDataCnt2Fiat = JSON.parse(productTemp2Fiat.body);
    const parsedDataAmt2Fiat = JSON.parse(productAmtTemp2Fiat.body);
    let parsedData2DateFiat = [];
    _.forEach(parsedDataAmt2Fiat, function(d, i) {
      parsedData2DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData2DateFiat.indexOf(date) !== -1) {
        countArrayP2Fiat.push(Number(parsedDataCnt2Fiat[parsedData2DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP2Fiat.push(Number(parsedDataAmt2Fiat[parsedData2DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP2Fiat.push(Number(parsedDataAmt2Fiat[parsedData2DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP2Fiat.push(0);
        amountArrayP2Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp2Fiat.body), function (data, i) {
      countArrayUniP2Fiat.push(Number(data[1]['stringValue']));
      uniqueP2Fiat = uniqueP2Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p2": uniqueP2Fiat});

    // P3
    let countArrayP3 = [],  amountArrayP3 = [], countArrayUniP3 = [], uniqueP3 = 0;
    const parsedDataCnt3 = JSON.parse(productTemp3.body);
    const parsedDataAmt3 = JSON.parse(productAmtTemp3.body);
    let parsedData3Date = [];
    _.forEach(parsedDataCnt3, function(d, i) {
      parsedData3Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData3Date.indexOf(date) !== -1) {
        countArrayP3.push(Number(parsedDataCnt3[parsedData3Date.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP3.push(Number(parsedDataAmt3[parsedData3Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP3.push(Number(parsedDataAmt3[parsedData3Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP3.push(0);
        amountArrayP3.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp3.body), function (data, i) {
      countArrayUniP3.push(Number(data[1]['stringValue']))
      uniqueP3 = uniqueP3+data[1]['longValue'];
    });
    uniqueCountData.push({"p3": uniqueP3});

    // P3 FIAT
    let countArrayP3Fiat = [], amountArrayP3Fiat = [], countArrayUniP3Fiat = [], uniqueP3Fiat = 0;
    const parsedDataCnt3Fiat = JSON.parse(productTemp3Fiat.body);
    const parsedDataAmt3Fiat = JSON.parse(productAmtTemp3Fiat.body);
    let parsedData3DateFiat = [];
    _.forEach(parsedDataAmt3Fiat, function(d, i) {
      parsedData3DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData3DateFiat.indexOf(date) !== -1) {
        countArrayP3Fiat.push(Number(parsedDataCnt3Fiat[parsedData3DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP3Fiat.push(Number(parsedDataAmt3Fiat[parsedData3DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP3Fiat.push(Number(parsedDataAmt3Fiat[parsedData3DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP3Fiat.push(0);
        amountArrayP3Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp3Fiat.body), function (data, i) {
      countArrayUniP3Fiat.push(Number(data[1]['stringValue']));
      uniqueP3Fiat = uniqueP3Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p3": uniqueP3Fiat});

    // P4
    let countArrayP4 = [], amountArrayP4 = [], countArrayUniP4 = [], uniqueP4 = 0;
    const parsedDataCnt4 = JSON.parse(productTemp4.body);
    const parsedDataAmt4 = JSON.parse(productAmtTemp4.body);
    let parsedData4Date = [];
    _.forEach(parsedDataCnt4, function(d, i) {
      parsedData4Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData4Date.indexOf(date) !== -1) {
        countArrayP4.push(Number(parsedDataCnt4[parsedData4Date.indexOf(date)][1]['longValue']));
        isDataNew
        ? amountArrayP4.push(Number(parsedDataAmt4[parsedData4Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP4.push(Number(parsedDataAmt4[parsedData4Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP4.push(0);
        amountArrayP4.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp4.body), function (data, i) {
      countArrayUniP4.push(Number(data[1]['stringValue']))
      uniqueP4 = uniqueP4+data[1]['longValue'];
    });
    uniqueCountData.push({"p4": uniqueP4});

    // P4 FIAT
    let countArrayP4Fiat = [], amountArrayP4Fiat = [], countArrayUniP4Fiat = [], uniqueP4Fiat = 0;
    const parsedDataCnt4Fiat = JSON.parse(productTemp4Fiat.body);
    const parsedDataAmt4Fiat = JSON.parse(productAmtTemp4Fiat.body);
    let parsedData4DateFiat = [];
    _.forEach(parsedDataAmt4Fiat, function(d, i) {
      parsedData4DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData4DateFiat.indexOf(date) !== -1) {
        countArrayP4Fiat.push(Number(parsedDataCnt4Fiat[parsedData4DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP4Fiat.push(Number(parsedDataAmt4Fiat[parsedData4DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP4Fiat.push(Number(parsedDataAmt4Fiat[parsedData4DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP4Fiat.push(0);
        amountArrayP4Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp4Fiat.body), function (data, i) {
      countArrayUniP4Fiat.push(Number(data[1]['stringValue']));
      uniqueP4Fiat = uniqueP4Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p4": uniqueP4Fiat});

    // P5
    let countArrayP5 = [], amountArrayP5 = [], countArrayUniP5 = [], uniqueP5 = 0;
    const parsedDataCnt5 = JSON.parse(productTemp5.body);
    const parsedDataAmt5 = JSON.parse(productAmtTemp5.body);
    let parsedData5Date = [];
    _.forEach(parsedDataCnt5, function(d, i) {
      parsedData5Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData5Date.indexOf(date) !== -1) {
        countArrayP5.push(Number(parsedDataCnt5[parsedData5Date.indexOf(date)][1]['longValue']));
        isDataNew
        ? amountArrayP5.push(Number(parsedDataAmt5[parsedData5Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP5.push(Number(parsedDataAmt5[parsedData5Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP5.push(0);
        amountArrayP5.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp5.body), function (data, i) {
      countArrayUniP5.push(Number(data[1]['stringValue']));
      uniqueP5 = uniqueP5+data[1]['longValue'];
    });
    uniqueCountData.push({"p5": uniqueP5});

    // P5 FIAT
    let countArrayP5Fiat = [], amountArrayP5Fiat = [], countArrayUniP5Fiat = [], uniqueP5Fiat = 0;
    const parsedDataCnt5Fiat = JSON.parse(productTemp5Fiat.body);
    const parsedDataAmt5Fiat = JSON.parse(productAmtTemp5Fiat.body);
    let parsedData5DateFiat = [];
    _.forEach(parsedDataAmt5Fiat, function(d, i) {
      parsedData5DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData5DateFiat.indexOf(date) !== -1) {
        countArrayP5Fiat.push(Number(parsedDataCnt5Fiat[parsedData5DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP5Fiat.push(Number(parsedDataAmt5Fiat[parsedData5DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP5Fiat.push(Number(parsedDataAmt5Fiat[parsedData5DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP5Fiat.push(0);
        amountArrayP5Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp5Fiat.body), function (data, i) {
      countArrayUniP5Fiat.push(Number(data[1]['stringValue']));
      uniqueP5Fiat = uniqueP5Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p5": uniqueP5Fiat});

    // P6
    let countArrayP6 = [], amountArrayP6 = [], countArrayUniP6 = [], uniqueP6 = 0;
    const parsedDataCnt6 = JSON.parse(productTemp6.body);
    const parsedDataAmt6 = JSON.parse(productAmtTemp6.body);
    let parsedData6Date = [];
    _.forEach(parsedDataCnt6, function(d, i) {
      parsedData6Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData6Date.indexOf(date) !== -1) {
        countArrayP6.push(Number(parsedDataCnt6[parsedData6Date.indexOf(date)][1]['longValue']));
        isDataNew
        ? amountArrayP6.push(Number(parsedDataAmt6[parsedData6Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP6.push(Number(parsedDataAmt6[parsedData6Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP6.push(0);
        amountArrayP6.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp6.body), function (data, i) {
      countArrayUniP6.push(Number(data[1]['stringValue']));
      uniqueP6 = uniqueP6+data[1]['longValue'];
    });
    uniqueCountData.push({"p6": uniqueP6});

    // P6 FIAT
    let countArrayP6Fiat = [], amountArrayP6Fiat = [], countArrayUniP6Fiat = [], uniqueP6Fiat = 0;
    const parsedDataCnt6Fiat = JSON.parse(productTemp6Fiat.body);
    const parsedDataAmt6Fiat = JSON.parse(productAmtTemp6Fiat.body);
    let parsedData6DateFiat = [];
    _.forEach(parsedDataAmt6Fiat, function(d, i) {
      parsedData6DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData6DateFiat.indexOf(date) !== -1) {
        countArrayP6Fiat.push(Number(parsedDataCnt6Fiat[parsedData6DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP6Fiat.push(Number(parsedDataAmt6Fiat[parsedData6DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP6Fiat.push(Number(parsedDataAmt6Fiat[parsedData6DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP6Fiat.push(0);
        amountArrayP6Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp6Fiat.body), function (data, i) {
      countArrayUniP6Fiat.push(Number(data[1]['stringValue']));
      uniqueP6Fiat = uniqueP6Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p6": uniqueP6Fiat});

    // P7
    let countArrayP7 = [], amountArrayP7 = [], countArrayUniP7 = [], uniqueP7 = 0;
    const parsedDataCnt7 = JSON.parse(productTemp7.body);
    const parsedDataAmt7 = JSON.parse(productAmtTemp7.body);
    let parsedData7Date = [];
    _.forEach(parsedDataCnt7, function(d, i) {
      parsedData7Date.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData7Date.indexOf(date) !== -1) {
        countArrayP7.push(Number(parsedDataCnt7[parsedData7Date.indexOf(date)][1]['longValue']));
        isDataNew
        ? amountArrayP7.push(Number(parsedDataAmt7[parsedData7Date.indexOf(date)][1]['doubleValue']))
        : amountArrayP7.push(Number(parsedDataAmt7[parsedData7Date.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP7.push(0);
        amountArrayP7.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp7.body), function (data, i) {
      countArrayUniP7.push(Number(data[1]['stringValue']));
      uniqueP7 = uniqueP7+data[1]['longValue'];
    });
    uniqueCountData.push({"p7": uniqueP7});
    uniqueCountData.push({"sheet": uniqueSheet})
    uniqueCountData.push({"fiatUse": fiatUnique});

    // P7 FIAT
    let countArrayP7Fiat = [], amountArrayP7Fiat = [], countArrayUniP7Fiat = [], uniqueP7Fiat = 0;
    const parsedDataCnt7Fiat = JSON.parse(productTemp7Fiat.body);
    const parsedDataAmt7Fiat = JSON.parse(productAmtTemp7Fiat.body);
    let parsedData7DateFiat = [];
    _.forEach(parsedDataAmt7Fiat, function(d, i) {
      parsedData7DateFiat.push(d[0]['stringValue']);
    });
    _.forEach(dateArray, function(date, i) {
      if (parsedData7DateFiat.indexOf(date) !== -1) {
        countArrayP7Fiat.push(Number(parsedDataCnt7Fiat[parsedData7DateFiat.indexOf(date)][1]['longValue']));
        isDataNew 
        ? amountArrayP7Fiat.push(Number(parsedDataAmt7Fiat[parsedData7DateFiat.indexOf(date)][1]['doubleValue']))
        : amountArrayP7Fiat.push(Number(parsedDataAmt7Fiat[parsedData7DateFiat.indexOf(date)][1]['stringValue']));
      } else {
        countArrayP7Fiat.push(0);
        amountArrayP7Fiat.push(0);
      }
    });
    _.forEach(JSON.parse(productUniTemp7Fiat.body), function (data, i) {
      countArrayUniP7Fiat.push(Number(data[1]['stringValue']));
      uniqueP7Fiat = uniqueP7Fiat+data[1]['longValue'];
    });
    uniqueCountDataFiat.push({"p7": uniqueP7Fiat});

    // Count
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count1",
          data: countArrayP1,
          date: dateArray
      }
    );
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count2",
          data: countArrayP2,
          date: dateArray
      }
    );
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count3",
          data: countArrayP3,
          date: dateArray
      }
    );
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count4",
          data: countArrayP4,
          date: dateArray
      }
    );
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count5",
          data: countArrayP5,
          date: dateArray
      }
    );
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count6",
          data: countArrayP6,
          date: dateArray
      }
    );
    allDataCountDetails.push(
      {
          id: "ingame-dep-use-count7",
          data: countArrayP7,
          date: dateArray
      }
    );
    // allDataCountDetails.push(
    //   {
    //       id: "ingame-sheet-count",
    //       data: countArraySheet,
    //       date: dateArray
    //   }
    // );

    // Amount
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount1",
          data: amountArrayP1,
          date: dateArray
      }
    );
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount2",
          data: amountArrayP2,
          date: dateArray
      }
    );
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount3",
          data: amountArrayP3,
          date: dateArray
      }
    );
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount4",
          data: amountArrayP4,
          date: dateArray
      }
    );
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount5",
          data: amountArrayP5,
          date: dateArray
      }
    );
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount6",
          data: amountArrayP6,
          date: dateArray
      }
    );
    allDataAmountDetails.push(
      {
          id: "ingame-dep-use-amount7",
          data: amountArrayP7,
          date: dateArray
      }
    );
    // allDataAmountDetails.push(
    //   {
    //       id: "ingame-sheet-amount",
    //       data: amountArraySheet,
    //       date: dateArray
    //   }
    // );

    // FIAT
    // Count
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count1",
          data: countArrayP1Fiat,
          date: dateArray
      }
    );
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count2",
          data: countArrayP2Fiat,
          date: dateArray
      }
    );
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count3",
          data: countArrayP3Fiat,
          date: dateArray
      }
    );
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count4",
          data: countArrayP4Fiat,
          date: dateArray
      }
    );
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count5",
          data: countArrayP5Fiat,
          date: dateArray
      }
    );
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count6",
          data: countArrayP6Fiat,
          date: dateArray
      }
    );
    allDataCountDetailsFiat.push(
      {
          id: "ingame-dep-use-count7",
          data: countArrayP7Fiat,
          date: dateArray
      }
    );
    // allDataCountDetailsFiat.push(
    //   {
    //       id: "ingame-sheet-count",
    //       data: countArraySheet,
    //       date: dateArray
    //   }
    // );

    // Amount
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount1",
          data: amountArrayP1Fiat,
          date: dateArray
      }
    );
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount2",
          data: amountArrayP2Fiat,
          date: dateArray
      }
    );
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount3",
          data: amountArrayP3Fiat,
          date: dateArray
      }
    );
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount4",
          data: amountArrayP4Fiat,
          date: dateArray
      }
    );
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount5",
          data: amountArrayP5Fiat,
          date: dateArray
      }
    );
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount6",
          data: amountArrayP6Fiat,
          date: dateArray
      }
    );
    allDataAmountDetailsFiat.push(
      {
          id: "ingame-dep-use-amount7",
          data: amountArrayP7Fiat,
          date: dateArray
      }
    );
    // allDataAmountDetailsFiat.push(
    //   {
    //       id: "ingame-sheet-amount",
    //       data: amountArraySheet,
    //       date: dateArray
    //   }
    // );

    yield put(setGraffitiRacerGameTrendDataDepUse(allDataCount));
    yield put(setGraffitiRacerGameTrendDataDepGet(allDataAmount));
    yield put(setGraffitiRacerGameTrendDataUniqueCount(uniqueCountData));
    yield put(setGraffitiRacerGameTrendDataDepUseCntDetails(allDataCountDetails));
    yield put(setGraffitiRacerGameTrendDataDepUseAmtDetails(allDataAmountDetails));
    yield put(setGraffitiRacerGameTrendDataDepUseCntDetailsFiat(allDataCountDetailsFiat));
    yield put(setGraffitiRacerGameTrendDataDepUseAmtDetailsFiat(allDataAmountDetailsFiat));
    yield put(setGraffitiRacerGameTrendDataUniqueCountFiat(uniqueCountDataFiat));
    yield put(setPageTopLoader(false));
    yield put(setGraffitiRacerGameTrendDataLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(
    GRAFITTIRACER.GET_GRAFITTIRACER_GAME_TREND_PAGE_DATA, getGraffitiRacerNftTrendData,
  );
}
