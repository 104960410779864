import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getOverviewGameDataByCountry } from "actions";
import * as C from "components";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#fafafa",
    },
  })
);

const GameByCountry = ({
  dateRange,
  dateLoaded,
  setOverviewTab,
  getOverviewGameDataByCountry,
  game,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setOverviewTab("country");
    // if (dateLoaded['nftdep-overview-country'] !== undefined) {
    //     const formatDateRagne = H.FormatDateHelper.formatDateRangeWithDash(dateRange);
    //     if (!_.isEqual(formatDateRagne, dateLoaded['nftdep-overview-country'])) {
    //         getOverviewDataByCountry();
    //     }
    // } else {
    // const game = window.location.pathname.split("/");
    getOverviewGameDataByCountry(game);
    //}
  }, [window.location.pathname]);

  useEffect(() => {
    setOverviewTab("country");
    const game = window.location.pathname.split("/");
    getOverviewGameDataByCountry(game);
  }, [game]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別オークション取引支払総額"
            source="auction"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別オークション取引件数"
            source="auction"
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別DEP ストア取引支払総額"
            source="store"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別DEP ストア取引件数"
            source="store"
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別FIAT ストア取引支払総額"
            source="fiat"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別FIAT ストア取引件数"
            source="fiat"
            loading={loading}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

GameByCountry.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  dateLoaded: state.data.dataLoaded,
  loading: state.nftDepOverview.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setOverviewTab: (param) => dispatch(setOverviewTab(param)),
  getOverviewGameDataByCountry: (param) =>
    dispatch(getOverviewGameDataByCountry(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(GameByCountry));
