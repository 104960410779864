import { NFTDEP } from '../../constants';

const initialState  = {
    walletDataLoading: false,
    walletBalanceData: [],
};

export default function walletReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_WALLET_BALANCE_LOADING:
            return {
                ...state,
                walletDataLoading: action.payload
            };
        case NFTDEP.SET_WALLET_BALANCE_DATA:
            return {
                ...state,
                walletBalanceData: action.payload
            };
        default:
            return state
    }
};
