import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { connect } from 'react-redux';
import { setCampaignType } from  'actions';

const styles = theme => ({
    title: {
        width: '100%',
        maxWidth: 500,
        paddingLeft: 8
    },
    areaWrapper: {
        display: 'block',
        width: '90%',
        paddingBottom: theme.spacing(2),
    },
    root: {
        display: 'flex',
    },
    formControl: {
        marginLeft: theme.spacing(9),
    },
    group: {
        margin: `8px 0`,
    },
    fileIcon: {
        marginTop: 3,
        marginRight: 40
    },
});

export class CampaignType extends Component {
    handleChange = event => {
        this.props.setCampaignType(event.target.value, 'type');
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.areaWrapper}>
                <div>
                    <Icon className={'fa fa-building fa-sm'} /><span className={classes.title}>タイプ</span>
                </div>
                <div className={classes.root}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <RadioGroup
                            aria-label="タイプ"
                            name="type"
                            className={classes.group}
                            value={this.props.affiliatorType}
                            onChange={this.handleChange}
                            row
                        >
                            <FormControlLabel value="individual" control={<Radio />} label="個人" />
                            <FormControlLabel value="organization" control={<Radio />} label="団体・企業" />
                            {/* <FormControlLabel value="media" control={<Radio />} label="メディア（媒体名）" /> */}
                            <FormControlLabel value="campaign" control={<Radio />} label="LP経由" />
                            <FormControlLabel value="campaign_game" control={<Radio />} label="直アクセス" />
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
        );
    }
}

CampaignType.propTypes = {
    classes: PropTypes.object,
    setCampaignType: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    affiliatorType: state.affiliate.affiliatorType
});

const mapDispatchToProps = dispatch => ({
    setCampaignType: (param, filter) => dispatch(setCampaignType(param, filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(CampaignType));