import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
//import { setCurrentModule } from 'actions';
import * as C from 'components';

const GameLuckyFarmer = ({classes, setCurrentModule}) => {
    const [tabFocused, setTabFocused] = useState('map');

    return (
        <div className="">
            <C.ComingSoon />
        </div>
    );
};

GameLuckyFarmer.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(GameLuckyFarmer));