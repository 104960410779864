import React, { useState, useEffect } from "react";
import _ from 'lodash';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import * as C from 'components';
import * as H from 'helper';

const useStyles = makeStyles((theme) => ({
    titlePaper: {
        paddingTop: 8,
        paddingRight: 8,
        marginBottom: 32,
        marginLeft: 8,
        fontSize: 18,
    },
    statsBox: {
        padding: 8,
    },
    bottomBoxArea: {
        fontSize: 14,
        marginTop: 16,
    },
    expandBtn: {
        textAlign: "center",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        marginRight: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
        marginTop: 16,
    },
    expandOpen: {
        transform: 'rotate(180deg)',
        marginTop: 16,
    },
}));

const SalesStats = ({
    dateRange,
    loading,
    allDepSales,
    allDepFees,
    allTxCount,
    txAuctionStats,
    txStoreStats,
    txFiatStats,
    activeWallet,
    txFirstTimePurchaseStats,
    txUniqueAndNew,
    txStoreUniqueAndNew,
    nftDepOverviewData,
    depPrice,
    selectedCurrency,
    hideWithdrawl,
}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const getSalesExtraComponent = (allDepSales) => {
        const auctionRatio = (allDepSales.auction / allDepSales.total * 100).toFixed(2);
        let storeRatio = (allDepSales.store / allDepSales.total * 100).toFixed(2);
        if (isNaN(storeRatio)) {
            storeRatio = 0.00;
        }
        return (
            <Grid container spacing={0} className={classes.bottomBoxArea}>
                {allDepSales.auction !== "-" &&
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography gutterBottom variant="caption">
                                オークション:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                DEP ストア:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(handleCurrencyConvert(allDepSales.auction))} ({auctionRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {isNaN(allDepSales.store)
                                    ? ""
                                    : H.FormatNumStrHelper.formatCommaThousand(handleCurrencyConvert(allDepSales.store))} ({storeRatio}%)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const getFeesExtraComponent = (allDepSales) => {
        const auctionRatio = (allDepFees.auction / allDepFees.total * 100).toFixed(2);
        const withdrawRatio = (allDepFees.withdraw / allDepFees.total * 100).toFixed(2);
        return (
            <Grid container spacing={0} className={classes.bottomBoxArea}>
                {allDepSales.auction !== "-" &&
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography gutterBottom variant="caption">
                                オークション:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                出金手数料:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(handleCurrencyConvert(allDepFees.auction))} ({auctionRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(handleCurrencyConvert(allDepFees.withdraw))} ({withdrawRatio}%)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const getTxCountExtraComponent = (allTxCount) => {
        const auctionRatio = (allTxCount.auction / allTxCount.total * 100).toFixed(2);
        const storeRatio = (allTxCount.store / allTxCount.total * 100).toFixed(2);
        const fiatRatio = (allTxCount.fiat / allTxCount.total * 100).toFixed(2);
        return (
            <Grid container spacing={0} className={classes.bottomBoxArea}>
                {allTxCount.auction !== "-" &&
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography gutterBottom variant="caption">
                                オークション:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                DEP ストア:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                FIAT ストア:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(allTxCount.auction)} ({auctionRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(allTxCount.store)} ({storeRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(allTxCount.fiat)} ({fiatRatio}%)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const getMaxTxExtraComponent = (maxTx) => {
        return (
            <Grid container spacing={0} className={classes.bottomBoxArea}>
                {allTxCount.auction !== "-" &&
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography gutterBottom variant="caption">
                                オークション:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                ストア:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(handleCurrencyConvert(maxTx.auction.current))}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(handleCurrencyConvert(maxTx.store.current))}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const getActiveWalletExtraComponent = (activeWalletData) => {
        const auctionRatio = (activeWalletData.auction / activeWalletData.current * 100).toFixed(2);
        const storeRatio = (activeWalletData.store / activeWalletData.current * 100).toFixed(2);
        const fiatRatio = (activeWalletData.fiat / activeWalletData.current * 100).toFixed(2);
        return (
            <Grid container spacing={0} className={classes.bottomBoxArea}>
                {allTxCount.auction !== "-" &&
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography gutterBottom variant="caption">
                                オークション:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                DEP ストア:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                FIAT ストア:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(activeWalletData.auction)} ({auctionRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(activeWalletData.store)} ({storeRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(activeWalletData.fiat)} ({fiatRatio}%)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const getFirstTimePurchaseComponent = (txFirstTimePurchaseStats) => {
        const auctionRatio = (txFirstTimePurchaseStats.auction / txFirstTimePurchaseStats.current * 100).toFixed(2);
        const storeRatio = (txFirstTimePurchaseStats.store / txFirstTimePurchaseStats.current * 100).toFixed(2);
        const fiatRatio = (txFirstTimePurchaseStats.fiat / txFirstTimePurchaseStats.current * 100).toFixed(2);
        return (
            <Grid container spacing={0} className={classes.bottomBoxArea}>
                {allTxCount.auction !== "-" &&
                <Grid item xs={12} sm container>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography gutterBottom variant="caption">
                                オークション:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                DEP ストア:
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="caption" gutterBottom>
                                FIAT ストア:
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs container direction="column" spacing={1}>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(txFirstTimePurchaseStats.auction)} ({auctionRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(txFirstTimePurchaseStats.store)} ({storeRatio}%)
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2">
                                {H.FormatNumStrHelper.formatCommaThousand(txFirstTimePurchaseStats.fiat)} ({fiatRatio}%)
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                }
            </Grid>
        )
    }

    const handleCurrencyConvert = (amount) => {
        let returnAmount = Number(amount);
        if (selectedCurrency === 'usd') {
            returnAmount = returnAmount * depPrice.usd;
        } else if (selectedCurrency === 'sgd') {
            returnAmount = returnAmount * depPrice.sgd;
        } else if (selectedCurrency === 'jpy') {
            returnAmount = returnAmount * depPrice.jpy;
        }
        return returnAmount;
    };

    const getPrefix = (selectedCurrency) => {
        if (selectedCurrency === 'usd') {
            return "US$";
        } else if (selectedCurrency === 'sgd') {
            return "S$";
        } else if (selectedCurrency === 'jpy') {
            return "¥"
        } else {
            return "";
        }
    }

    return (
        <Grid container direction="column" style={{ marginTop: -8, marginBottom: 8, width: '100%' }}>
            <Paper style={{ padding: 8, marginBottom: 8, width: '98.6%' }}>
                <Grid item className={classes.titlePaper}>
                    売上データ：{dateRange[0].replace(/-/g,'/')} ~ {dateRange[1].replace(/-/g,'/')}
                </Grid>
                <Grid container spacing={0} direction="row" style={{ marginTop: -16, marginBottom: 8, width: '100%' }}>
                    <Grid item xs={12} sm={6} md={6} lg={2}  className={classes.statsBox}>
                        <C.StatBox
                            title="DEP売上総額"
                            value={loading.allDepSales ? 'loading' : handleCurrencyConvert(allDepSales.total)}
                            prevValue={loading.allDepSales ? 'loading' : isNaN(allDepSales.prev) ? "--" : handleCurrencyConvert(allDepSales.prev)}
                            extra={loading.allDepSales ? 'loading' : getSalesExtraComponent(allDepSales)}
                            prefix={getPrefix(selectedCurrency)}
                            tip={"手数料を含む総DEP流動数"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2}  className={classes.statsBox}>
                        <C.StatBox
                            title="FIAT売上総額"
                            value={loading.allDepSales ? 'loading' : allDepSales.totalFiat}
                            prevValue={loading.allDepSales ? 'loading' : allDepSales.totalFiatPrev}
                            //extra={loading.allDepSales ? 'loading' : getSalesExtraComponent(allDepSales)}
                            prefix={"US$"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBox
                            title="取引件数"
                            value={loading.allTxCount ? 'loading' : allTxCount.total}
                            prevValue={loading.allTxCount ? 'loading' : allTxCount.prev}
                            extra={loading.allTxCount ? 'loading' : getTxCountExtraComponent(allTxCount)}
                            tip={"手数料取引は除外"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxCarousel
                            title="オークション販売"
                            loading={loading.txAuction ? true : false}
                            data={txAuctionStats}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxCarousel
                            title="DEP ストア販売"
                            loading={loading.txStore ? true : false}
                            data={txStoreStats}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxCarousel
                            title="FIAT ストア販売"
                            loading={loading.txStore ? true : false}
                            data={txFiatStats}
                            noConvert
                            prefix="us$"
                        />
                    </Grid>
                </Grid>
                <div className={classes.expandBtn}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        size="small"
                    >
                        <ExpandMoreIcon fontSize="small" />
                    </IconButton>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Grid container spacing={0} direction="row" style={{ marginTop: -16, marginBottom: 8, width: '100%' }}>
                    {!hideWithdrawl &&
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBox
                            title="DEP手数料総額"
                            value={loading.allDepFees ? 'loading' : handleCurrencyConvert(allDepFees.total)}
                            prevValue={loading.allDepFees ? 'loading' : handleCurrencyConvert(allDepFees.prev)}
                            extra={loading.allDepSales ? 'loading' : getFeesExtraComponent(allDepSales)}
                            prefix={getPrefix(selectedCurrency)}
                        />
                    </Grid>
                    }
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBox
                            title="アクティブWallet数"
                            value={loading.activeWallet ? 'loading' : activeWallet.current}
                            prevValue={loading.activeWallet ? 'loading' : activeWallet.prev}
                            extra={loading.activeWallet ? 'loading' : getActiveWalletExtraComponent(activeWallet)}
                            tip={`オークション・ストア・FIAT販売から<br />それぞれ重複を除いたユニーク数`}
                            lastItem={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBox
                            title="初購入件数"
                            value={loading.activeWallet ? 'loading' : txFirstTimePurchaseStats.current}
                            prevValue={loading.activeWallet ? 'loading' : txFirstTimePurchaseStats.prev}
                            extra={loading.activeWallet ? 'loading' : getFirstTimePurchaseComponent(txFirstTimePurchaseStats)}
                            lastItem={true}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="オークション出品件数"
                            lblValue1="新規"
                            lblValue2="リピート"
                            // value1={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueSellNew.current}
                            // value2={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueSellRepeat.current}
                            // prevValue1={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueSellNew.prev}
                            // prevValue2={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueSellRepeat.prev}
                            value1={'-'}
                            value2={'-'}
                            prevValue1={'n/a'}
                            prevValue2={'n/a'}
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="オークション入札/落札件数"
                            lblValue1="新規"
                            lblValue2="リピート"
                            // value1={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueBuyNew.current}
                            // value2={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueBuyRepeat.current}
                            // prevValue1={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueBuyNew.prev}
                            // prevValue2={loading.txUniqueNew ? 'loading' : txUniqueAndNew.uniqueSellRepeat.prev}
                            value1={'-'}
                            value2={'-'}
                            prevValue1={'n/a'}
                            prevValue2={'n/a'}
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="オークション内訳件数"
                            lblValue1="ブラインド購入"
                            lblValue2="即決購入"
                            // value1={nftDepOverviewData.loading.txBlindSnap ? 'loading' : nftDepOverviewData.dataTxBlind.count.current}
                            // value2={nftDepOverviewData.loading.txBlindSnap ? 'loading' : nftDepOverviewData.dataTxSnap.count.current}
                            // prevValue1={nftDepOverviewData.loading.txBlindSnap ? 'loading' : nftDepOverviewData.dataTxBlind.count.prev}
                            // prevValue2={nftDepOverviewData.loading.txBlindSnap ? 'loading' : nftDepOverviewData.dataTxSnap.count.prev}
                            value1={'-'}
                            value2={'-'}
                            prevValue1={'n/a'}
                            prevValue2={'n/a'}
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxScholar
                            title="ストア購入内訳件数"
                            lblValue1="新規"
                            lblValue2="リピート"
                            // value1={loading.txUniqueNewStore ? 'loading' : txStoreUniqueAndNew.uniqueStoreNew.current}
                            // value2={loading.txUniqueNewStore ? 'loading' : txStoreUniqueAndNew.uniqueStoreRepeat.current}
                            // prevValue1={loading.txUniqueNewStore ? 'loading' : txStoreUniqueAndNew.uniqueStoreNew.prev}
                            // prevValue2={loading.txUniqueNewStore ? 'loading' : txStoreUniqueAndNew.uniqueStoreRepeat.prev}
                            value1={'-'}
                            value2={'-'}
                            prevValue1={'n/a'}
                            prevValue2={'n/a'}
                            lgValue={6}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxCarousel
                            title="ステーキング件数"
                            //loading={loading.txStore ? true : false}
                            data={nftDepOverviewData.dataStakingOvCount}
                            isCount={true}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
                        <C.StatBoxCarousel
                            title="ステーキングDEP額"
                            //loading={loading.txStore ? true : false}
                            data={nftDepOverviewData.dataStakingOvDep}
                        />
                    </Grid> */}
                </Grid>
                </Collapse>
            </Paper>
        </Grid>
    );
};

SalesStats.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    comparisonDateDiff: state.page.comparisonDateDiff,
    loading: state.nftDep.loading,
    dateRange: state.page.dateRange,
    allDepSales: state.nftDep.allDepSales,
    allDepFees: state.nftDep.allDepFees,
    allTxCount: state.nftDep.allTxCount,
    txAuctionStats: state.nftDep.txAuctionStats,
    txStoreStats: state.nftDep.txStoreStats,
    txFiatStats: state.nftDep.txFiatStats,
    activeWallet: state.nftDep.activeWallet,
    txFirstTimePurchaseStats: state.nftDep.txFirstTimePurchaseStats,
    txUniqueAndNew: state.nftDep.txUniqueAndNew,
    txStoreUniqueAndNew: state.nftDep.txStore,
    nftDepOverviewData: state.nftDepOverview,
    depPrice: state.page.depPrice,
    selectedCurrency: state.page.selectedCurrency,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(SalesStats));
