import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getOverviewDataByPmid } from "actions";
import * as C from "components";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#fafafa",
    },
  })
);

const ByPmid = ({
  dateRange,
  dateLoaded,
  setOverviewTab,
  getOverviewDataByPmid,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setOverviewTab("pmid");
    if (dateLoaded["nftdep-overview-pmid"] !== undefined) {
      const formatDateRagne =
        H.FormatDateHelper.formatDateRangeWithDash(dateRange);
      if (!_.isEqual(formatDateRagne, dateLoaded["nftdep-overview-pmid"])) {
        getOverviewDataByPmid();
      }
    } else {
      getOverviewDataByPmid();
    }
  }, []);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByPmid
            title="上位PMID別オークション取引支払総額"
            source="auction"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByPmid
            title="上位PMID別ストア取引支払総額"
            source="store"
            loading={loading}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10TxByPmid title="TOP10 PMID別オークション取引件数" source="auction" />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10TxByPmid title="TOP10 PMID別ストア取引件数" source="store" />
                </Grid>
            </Grid> */}
    </Fragment>
  );
};

ByPmid.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  dateLoaded: state.data.dataLoaded,
  loading: state.nftDepOverview.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setOverviewTab: (param) => dispatch(setOverviewTab(param)),
  getOverviewDataByPmid: () => dispatch(getOverviewDataByPmid()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(ByPmid));
