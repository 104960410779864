import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { TextField, Button, Grid, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  getActiveUsersPageDataByGame,
  setActiveUsersSizeSelected,
  setActiveUsersGameSelected,
  setActiveUsersCountrySelected,
  setActiveUsersRidSelected,
  setActiveUsersGameOption,
  setActiveUsersSizeOption,
  resetActiveUsersGameSelected,
  getActiveUsersCountriesByGame,
  getActiveUsersRidByGame,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import * as H from "helper";
import ChartConfig from "utils/ChartConfig";
import ActiveUsersConfig from "utils/ActiveUsersConfig";

const ActiveUsersByGame = ({
  pageTopLoader,
  setCurrentPage,
  activeUsersGameOption,
  setActiveUsersSizeOption,
  setActiveUsersSizeSelected,
  activeUsersSizeOption,
  activeUsersSizeSelected,
  getActiveUsersPageDataByGame,
  activeUsersCountryOption,
  activeUsersRidOption,
  setActiveUsersGameOption,
  setActiveUsersGameSelected,
  setActiveUsersCountrySelected,
  setActiveUsersRidSelected,
  activeUsersData,
  activeUsersDataByGame,
  activeUsersUniqueTotalData,
  resetActiveUsersGameSelected,
  countryLoading,
  getActiveUsersCountriesByGame,
  getActiveUsersRidByGame,
  ridLoading,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartState, setChartState] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([
    { name: "DAU", value: "dau" },
    { name: "WAU", value: "wau" },
    { name: "MAU", value: "mau" },
  ]);

  const handleChangeSize = (data) => {
    setActiveUsersSizeSelected(data);
    if (activeUsersGameOption.length > 0) {
      setBtnState(false);
    }
  };

  const handleChangeCountry = (data) => {
    setActiveUsersCountrySelected({ name: data.name, value: data.value });
  };

  const handleChangeRid = (data) => {
    setActiveUsersRidSelected({ name: data.name, value: data.value });
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    resetActiveUsersGameSelected();
    getActiveUsersPageDataByGame(window.location.pathname);
    getActiveUsersCountriesByGame(window.location.pathname);
    getActiveUsersRidByGame(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    if (activeUsersDataByGame.length > 0) {
      setChartState(true);
    }
  }, [activeUsersDataByGame]);

  const fetchData = () => {
    setActiveUsersGameSelected(activeUsersGameOption);
    getActiveUsersPageDataByGame(window.location.pathname);
    getActiveUsersCountriesByGame(window.location.pathname);
    getActiveUsersRidByGame(window.location.pathname);
    setChartState(true);
  };

  const getAvgDisplay = (data, size) => {
    return (
      <div className={classes.flexContainerNotFix}>
        {data.map((item) => (
          <div className={classes.flexItemLeftBox} key={item.id}>
            {H.FormatNumStrHelper.formatGameKeyToName(item.id)} {size.name}{" "}
            平均　{H.FormatNumStrHelper.formatCommaThousand(item.avg)}
          </div>
        ))}
      </div>
    );
  };

  const getCalendarDisplay = (sizeOption) => {
    return (
      <Grid item>
        <C.CalendarRangeOnly
          setBtnState={setBtnState}
          page="activeUsers"
          rangeMax={ActiveUsersConfig.dauMaxRange}
        />
      </Grid>
    );
  };

  return (
    <Fragment>
      <div className={classes.filterSection}>
        <Grid
          container
          spacing={4}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container spacing={0} direction="row">
              <Grid item>
                <Autocomplete
                  id="select-size"
                  style={{ width: 100 }}
                  options={sizeOptions}
                  getOptionLabel={(option) => option.name}
                  defaultValue={sizeOptions[0]}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" label="サイズ" />
                  )}
                  onChange={(e, newValue) => {
                    handleChangeSize(newValue);
                  }}
                  className={classes.autoSelect}
                />
              </Grid>
              {window.location.pathname.indexOf("pictree") === -1 &&
              <Grid item>
                <Autocomplete
                  id="select-country"
                  style={{ width: 180 }}
                  options={activeUsersCountryOption}
                  getOptionLabel={(option) => option.name}
                  defaultValue={activeUsersCountryOption[0]}
                  disableClearable={true}
                  loading={countryLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="国"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {countryLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleChangeCountry(newValue);
                  }}
                  className={classes.autoSelect}
                />
              </Grid>
              }
              {window.location.pathname.indexOf("pictree") === -1 &&
              <Grid item>
                <Autocomplete
                  id="select-rid"
                  style={{ width: 450 }}
                  options={activeUsersRidOption}
                  getOptionLabel={(option) => option.name}
                  defaultValue={activeUsersRidOption[0]}
                  disableClearable={true}
                  loading={ridLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="キャンペーン"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <Fragment>
                            {ridLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </Fragment>
                        ),
                      }}
                    />
                  )}
                  onChange={(e, newValue) => {
                    handleChangeRid(newValue);
                  }}
                  className={classes.autoSelect}
                />
              </Grid>
              }
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="row"
              className={classes.itemRight}
            >
              <Grid item>{getCalendarDisplay(activeUsersSizeOption)}</Grid>
              <Grid item>
                <Button
                  //disabled={btnStateDataFetch}
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <C.ContentWrapper>
        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            {pageTopLoader ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : chartState ? (
              activeUsersDataByGame[0]["data"].length > 0 ? (
                <C.LineChartActiveUsers
                  legend={ChartConfig.legendActiveUsers}
                  lblSaveImage={"アクティブユーザー"}
                  xAisData={activeUsersDataByGame[0]["date"]}
                  seriesData={activeUsersDataByGame}
                  chartType={
                    activeUsersSizeSelected.value !== "mau" ? "line" : "bar"
                  }
                />
              ) : (
                <div className={classes.selectGameTxt}>
                  <Chip
                    label="No Data"
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            ) : (
              <div className={classes.selectGameTxt}>
                <Chip
                  label="サイズを選択してください"
                  style={{
                    color: V.textColor,
                    backgroundColor: V.placeholderColor,
                  }}
                />
              </div>
            )}
          </Grid>
        </Grid>
      </C.ContentWrapper>
      {/* <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                    {!pageTopLoader && chartState && activeUsersData[0]['data'].length > 0
                        ? getAvgDisplay(activeUsersData, activeUsersSizeSelected)
                        : null
                    }
                </Grid>
            </Grid> */}
      <div className={classes.activeUsersTable}>
        <C.ActiveUsersTableByGame />
      </div>
    </Fragment>
  );
};

ActiveUsersByGame.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  activeUsersGameOption: state.activeUsers.activeUsersGameOption,
  activeUsersSize: state.activeUsers.activeUsersSize,
  activeUsersSizeOption: state.activeUsers.activeUsersSizeOption,
  activeUsersSizeSelected: state.activeUsers.activeUsersSizeSelected,
  activeUsersCountryOption: state.activeUsers.activeUsersCountryOption,
  activeUsersRidOption: state.activeUsers.activeUsersRidOption,
  activeUsersData: state.activeUsers.activeUsersData,
  activeUsersDataByGame: state.activeUsers.activeUsersDataByGame,
  activeUsersUniqueTotalData: state.activeUsers.activeUsersUniqueTotalData,
  countryLoading: state.activeUsers.countryLoading,
  ridLoading: state.activeUsers.ridLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getActiveUsersPageDataByGame: (param) =>
    dispatch(getActiveUsersPageDataByGame(param)),
  getActiveUsersCountriesByGame: (param) =>
    dispatch(getActiveUsersCountriesByGame(param)),
  getActiveUsersRidByGame: (param) => dispatch(getActiveUsersRidByGame(param)),
  setActiveUsersGameOption: (param) =>
    dispatch(setActiveUsersGameOption(param)),
  setActiveUsersGameSelected: (param) =>
    dispatch(setActiveUsersGameSelected(param)),
  setActiveUsersSizeOption: (param) =>
    dispatch(setActiveUsersSizeOption(param)),
  setActiveUsersSizeSelected: (param) =>
    dispatch(setActiveUsersSizeSelected(param)),
  setActiveUsersCountrySelected: (param) =>
    dispatch(setActiveUsersCountrySelected(param)),
  setActiveUsersRidSelected: (param) =>
    dispatch(setActiveUsersRidSelected(param)),
  resetActiveUsersGameSelected: () => dispatch(resetActiveUsersGameSelected()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(ActiveUsersByGame));

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBoxForUnique: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 110,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    filterSection: {
      display: "flex",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    activeUsersTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    lineChart: {},
    selectGameForUnique: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 110,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    flexContainerNotFix: {
      display: "flex",
      overflown: "hidden",
      width: "100%",
      flexFlow: "row wrap",
      marginLeft: 16,
      borderRight: "1px solid #dcdcdc",
    },
    flexItemLeftBox: {
      alignSelf: "center",
      paddingLeft: 8,
      paddingRight: 16,
      borderRight: "1px solid #dcdcdc",
    },
    flexItemLeftBoxTotal: {
      fontSize: 14,
      alignSelf: "top",
      paddingLeft: 0,
      paddingRight: 4,
    },
    monthPicker: {
      marginTop: 11,
      marginLeft: 16,
      width: 120,
    },
    cardTitle: {
      fontSize: 16,
    },
    itemRight: {
      marginRight: 32,
    },
  })
);
