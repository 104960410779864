import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import * as C from "components";
import * as H from "helper";

const useStyles = makeStyles((theme) => ({
  titlePaper: {
    paddingTop: 8,
    paddingRight: 8,
    marginBottom: 32,
    marginLeft: 8,
    fontSize: 18,
  },
  statsBox: {
    padding: 8,
  },
  bottomBoxArea: {
    fontSize: 14,
    marginTop: 16,
  },
}));

const ItemSalesStats = ({
  dateRange,
  loading,
  allDepSales,
  allTxCount,
  txAuctionStats,
  activeWallet,
  txFirstTimePurchaseStats,
  depPrice,
  selectedCurrency,
  dataOverview,
}) => {
  const classes = useStyles();

  const getSalesExtraComponent = (dataOverview) => {
    const fiatRatio = dataOverview.fiatAmount === 0 ? 0 : (
      (dataOverview.fiatAmount / dataOverview.amountTotal) *
      100
    ).toFixed(2);
    let depRatio = ((dataOverview.depAmount / dataOverview.amountTotal) * 100).toFixed(2);
    if (isNaN(depRatio)) {
      depRatio = 0.0;
    }
    return (
      <Grid container spacing={0} className={classes.bottomBoxArea}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography gutterBottom variant="caption">
                FIAT課金:
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                DEP課金:
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography variant="body2">
                {H.FormatNumStrHelper.formatCommaThousand(
                  handleCurrencyConvert(dataOverview.fiatAmount)
                )}{" "}
                ({fiatRatio}%)
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                {isNaN(dataOverview.depAmount)
                  ? ""
                  : H.FormatNumStrHelper.formatCommaThousand(
                      handleCurrencyConvert(dataOverview.depAmount)
                    )}{" "}
                ({depRatio}%)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getTxCountExtraComponent = (dataOverview) => {
    const fiatRatio = dataOverview.fiatCount === 0 ? 0 : ((dataOverview.fiatCount / dataOverview.countTotal) * 100).toFixed(2);
    const depRatio = dataOverview.depCount === 0 ? 0 : ((dataOverview.depCount / dataOverview.countTotal) * 100).toFixed(2);
    return (
      <Grid container spacing={0} className={classes.bottomBoxArea}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                FIAT課金:
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                DEP課金:
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography variant="body2">
                {H.FormatNumStrHelper.formatCommaThousand(dataOverview.fiatCount)} (
                {fiatRatio}%)
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
                {H.FormatNumStrHelper.formatCommaThousand(dataOverview.depCount)} (
                {depRatio}%)
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getActiveWalletExtraComponent = (dataOverview) => {
    const fiatRatio = (
      (dataOverview.fiatUnique / (dataOverview.fiatUnique + dataOverview.depUnique) ) *
      100
    ).toFixed(2);
    const depRatio = (
      (dataOverview.depUnique / (dataOverview.fiatUnique + dataOverview.depUnique) ) *
      100
    ).toFixed(2);
    return (
      <Grid container spacing={0} className={classes.bottomBoxArea}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                <Typography variant="caption" gutterBottom>
                  FIAT課金:
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="caption" gutterBottom>
                  DEP課金:
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs container direction="column" spacing={1}>
              <Grid item xs>
                <Typography variant="body2">
                  {H.FormatNumStrHelper.formatCommaThousand(
                    dataOverview.fiatUnique
                  )}{" "}
                  ({isNaN(fiatRatio)? 0 : fiatRatio}%)
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="body2">
                  {H.FormatNumStrHelper.formatCommaThousand(
                    dataOverview.depUnique
                  )}{" "}
                  ({depRatio}%)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
    );
  };

  const getFirstTimePurchaseComponent = (txFirstTimePurchaseStats) => {
    const depRatio = (
      (txFirstTimePurchaseStats.dep / txFirstTimePurchaseStats.current) *
      100
    ).toFixed(2);
    const fiatRatio = (
      (txFirstTimePurchaseStats.fiat / txFirstTimePurchaseStats.current) *
      100
    ).toFixed(2);
    return (
      <Grid container spacing={0} className={classes.bottomBoxArea}>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                FIAT課金:
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="caption" gutterBottom>
                DEP課金:
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container direction="column" spacing={1}>
            <Grid item xs>
              <Typography variant="body2">
                {H.FormatNumStrHelper.formatCommaThousand(
                  txFirstTimePurchaseStats.fiat
                )}{" "}
                ({fiatRatio}%)
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body2">
              <Typography variant="body2">
                {H.FormatNumStrHelper.formatCommaThousand(
                  txFirstTimePurchaseStats.dep
                )}{" "}
                ({depRatio}%)
              </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleCurrencyConvert = (amount) => {
    let returnAmount = Number(amount);
    if (selectedCurrency === "usd") {
      returnAmount = returnAmount * depPrice.usd;
    } else if (selectedCurrency === "sgd") {
      returnAmount = returnAmount * depPrice.sgd;
    } else if (selectedCurrency === "jpy") {
      returnAmount = returnAmount * depPrice.jpy;
    }
    return returnAmount;
  };

  const getPrefix = (selectedCurrency) => {
    if (selectedCurrency === "usd") {
      return "US$";
    } else if (selectedCurrency === "sgd") {
      return "S$";
    } else if (selectedCurrency === "jpy") {
      return "¥";
    } else {
      return "";
    }
  };

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: -8, marginBottom: 8, width: "100%" }}
    >
      <Paper style={{ padding: 8, marginBottom: 8, width: "98.6%" }}>
        <Grid item className={classes.titlePaper}>
          InGame アイテム売上：{dateRange[0].replace(/-/g, "/")} ~{" "}
          {dateRange[1].replace(/-/g, "/")}
        </Grid>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8, width: "100%" }}
        >
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <C.StatBox
              title="DEP売上総額"
              value={
                loading.ov
                  ? "loading"
                  : handleCurrencyConvert(dataOverview.amountTotal)
              }
              prevValue={
                loading.ov
                  ? "loading"
                  : isNaN(dataOverview.amountTotalPrev)
                  ? "--"
                  : handleCurrencyConvert(dataOverview.amountTotalPrev)
              }
              extra={
                loading.ov
                  ? "loading"
                  : getSalesExtraComponent(dataOverview)
              }
              prefix={getPrefix(selectedCurrency)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <C.StatBox
              title="取引件数"
              value={loading.ov ? "loading" : dataOverview.countTotal}
              prevValue={loading.ov ? "loading" : dataOverview.countTotalPrev}
              extra={
                loading.ov
                  ? "loading"
                  : getTxCountExtraComponent(dataOverview)
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <C.StatBoxCarousel
              title="FIAT課金"
              loading={loading.ov ? true : false}
              data={dataOverview.fiatTxStats}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <C.StatBoxCarousel
              title="DEP課金"
              loading={loading.ov ? true : false}
              data={dataOverview.depTxStats}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <C.StatBox
              title="アクティブWallet数"
              value={loading.ov ? "loading" : dataOverview.fiatUnique + dataOverview.depUnique}
              prevValue={loading.ov ? "loading" : dataOverview.fiatUniquePrev + dataOverview.depUniquePrev}
              extra={
                loading.ov
                  ? "loading"
                  : getActiveWalletExtraComponent(dataOverview)
              }
              // tip={`DEP・FIAT課金から<br />それぞれ重複を除いたユニーク数`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <C.StatBox
              title="初購入者数"
              value={
                loading.ov
                  ? "loading"
                  : dataOverview.firstTimePurchase !== undefined
                    ? dataOverview.firstTimePurchase.current
                    : "--"
              }
              prevValue={
                loading.ov ? "loading" : dataOverview.firstTimePurchase !== undefined
                  ? dataOverview.firstTimePurchase.prev
                  : "--"
              }
              extra={
                loading.ov
                  ? "loading"
                  : dataOverview.firstTimePurchase !== undefined
                    ? getFirstTimePurchaseComponent(dataOverview.firstTimePurchase)
                    : "--"
              }
              lastItem={true}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

ItemSalesStats.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  comparisonDateDiff: state.page.comparisonDateDiff,
  loading: state.inGameItemSales.loading,
  dateRange: state.page.dateRange,
  allDepSales: state.nftDep.allDepSales,
  allTxCount: state.nftDep.allTxCount,
  txAuctionStats: state.nftDep.txAuctionStats,
  activeWallet: state.nftDep.activeWallet,
  txFirstTimePurchaseStats: state.nftDep.txFirstTimePurchaseStats,
  depPrice: state.page.depPrice,
  selectedCurrency: state.page.selectedCurrency,
  dataOverview: state.inGameItemSales.dataOverview,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(ItemSalesStats));
