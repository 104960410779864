import { NFTDEP } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    assetSearchTerm: [],
    assetSearchLoading: false,
    assetOption: [],
    assetOptionSelected: [],
    txHistoryDataLoading: false,
    txHistoryData: [],
    txHistoryStatData: [{
        "totalTx" : '--',
        "totalDep" : '--',
        "totalFiat": '--',
        "totalPrimeTx" : '--',
        "totalPrimeDep" : '--',
        "totalFiatTx": '--',
        "totalFiatAmount": '--',
        "totalSecondTx" : '--',
        "totalSecondDep" : '--'
    }],
    lowTxHistoryData: [],
    lowTxHistoryStatData: [{
        "totalTx" : '--',
        "totalDep" : '--',
        "totalPrimeTx" : '--',
        "totalPrimeDep" : '--',
        "totalSecondTx" : '--',
        "totalSecondDep" : '--'
    }],
    txHistoryDataFilter: [{
        "dateRange": [startDate, endDate],
    }],
    filter: [],
    pageNumber: 1,
    lowTxDepRange: [0, 10000],
    lowTxCsvData: [],
    lowTxCsvLoader: false,
    nftTxCsvData: [],
    nftTxCsvLoader: false,
};

export default function nftTxHistoryReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_TX_HISTORY_SEARCH_TERM:
            return {
                ...state,
                assetSearchTerm: action.payload
            };
        case NFTDEP.SET_TX_HISTORY_SEARCH_LOADING:
            return {
                ...state,
                assetSearchLoading: action.payload
            };
        case NFTDEP.SET_TX_HISTORY_SEARCH_OPTION:
            return {
                ...state,
                assetOption: action.payload
            };
        case NFTDEP.SET_TX_HISTORY_DATA_LOADING:
            return {
                ...state,
                txHistoryDataLoading: action.payload
            };
        case NFTDEP.SET_TX_HISTORY_DATA:
            return {
                ...state,
                txHistoryData: action.payload
            };
        case NFTDEP.SET_TX_HISTORY_STAT_DATA:
            return {
                ...state,
                txHistoryStatData: action.payload
            };
        case NFTDEP.SET_TX_HISTORY_DATA_FILTER:
            return {
                ...state,
                txHistoryDataFilter: action.payload
            };
        case NFTDEP.SET_LOW_TX_HISTORY_DATA:
            return {
                ...state,
                lowTxHistoryData: action.payload
            };
        case NFTDEP.SET_LOW_TX_HISTORY_STAT_DATA:
            return {
                ...state,
                lowTxHistoryStatData: action.payload
            };
        case NFTDEP.SET_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case NFTDEP.SET_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.payload
            };
        case NFTDEP.SET_LOW_TX_DEP_RANGE:
            return {
                ...state,
                lowTxDepRange: action.payload
            };
        case NFTDEP.SET_LOW_TX_CSV_DATA:
            return {
                ...state,
                lowTxCsvData: action.payload
            };
        case NFTDEP.SET_LOW_CSV_DOWNLOADER:
            return {
                ...state,
                lowTxCsvLoader: action.payload
            };
        case NFTDEP.SET_NFT_TX_CSV_DATA:
            return {
                ...state,
                nftTxCsvData: action.payload
            };
        case NFTDEP.SET_NFT_CSV_DOWNLOADER:
            return {
                ...state,
                nftTxCsvLoader: action.payload
            };
        default:
            return state
    }
};
