import { put, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  getInGameItemHistoryData,
  setInGameItemTxHistoryStatData,
  setInGameItemTxHistoryData,
  setPageTopLoader,
  setCsvDownloader,
  setNftTxCsvDownloader,
  setInGameItemHistoryCsvData,
  setInGameItemItemOptionDataLoading,
  setInGameItemOptionData,
  setInGamePmidOptionDataLoading,
  setInGamePmidOptionData,
} from "actions";
import { getJwtToken, getDataFromRDS } from "api";
import { DATA, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = (state) => state.page.currentPage;
export const startDate = (state) => state.page.dateRange[0];
export const endDate = (state) => state.page.dateRange[1];
export const dataLoaded = (state) => state.data.dataLoaded;
export const loading = (state) => state.nftDepTxHisotry.assetSearchLoading;
export const itemSearchTerm = (state) => state.inGameItemSalesHistory.itemSearchTerm;
export const selectedItem = (state) => state.inGameItemSalesHistory.itemOptionSelected;
export const selectedPayment = (state) => state.inGameItemSalesHistory.itemPaymentSelected;
export const selectedPmid = (state) => state.inGameItemSalesHistory.itemPmidSelected;
export const fitler = (state) => state.nftDepTxHisotry.txHistoryDataFilter;

export function* getNftTxHisotryDataSxi(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    yield put(setPageTopLoader(true));
    const currPage = yield select(currentPage);
    const gameTarget = action.payload.target;
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
    // const selectItem = action.payload.item ? "'" + action.payload.item.join("', '") + "'"  : false;
    let selectItem = yield select(selectedItem);
    selectItem = selectItem.length > 0 ? "'" + selectItem.join("', '") + "'"  : false;
    const selectPayment = yield select(selectedPayment);
    const selectPmid = yield select(selectedPmid);

    const dataFilter = yield select(fitler);
    const dateRange = dataFilter[0]["dateRange"];
    const offsetNum = (action.payload.page - 1) * 20;

    // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[0]}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
    // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateRange[1]}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
    const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
    const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;
    const sqlQuerySalesFiatInRange = H.SqlHelper.getInGameItemSalesCountAndAmount({
      table: "SxiInGameFiatSalesProd2",
      range: [rangeStart, rangeEnd],
      target: contentId,
      item: selectItem,
      pmid: selectPmid,
    });
    const sqlQuerySalesDepInRange = H.SqlHelper.getInGameItemSalesCountAndAmount({
      table: "SxiInGameDepSalesProd",
      range: [rangeStart, rangeEnd],
      target: contentId,
      item: selectItem,
      pmid: selectPmid,
    });
    const sqlQueryAssetDetailInRange = H.SqlHelper.getInGameItemSalseTransactionByGame({
      table: "SxiInGameFiatSalesProd2",
      range: [rangeStart, rangeEnd],
      target: contentId,
      limit: 20,
      offset: offsetNum,
      item: selectItem,
      payment: selectPayment,
      pmid: selectPmid,
    });

    const [
      dataItemTempFiat,
      dataItemTempDep,
      dataItemDetailsTemp,
    ] = yield all([
      call(
        getDataFromRDS,
        sqlQuerySalesFiatInRange,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        sqlQuerySalesDepInRange,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        sqlQueryAssetDetailInRange,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ]);
    const dataFiat = JSON.parse(dataItemTempFiat.body);
    const dataDep = JSON.parse(dataItemTempDep.body);
    const dataAllTxDetail = JSON.parse(dataItemDetailsTemp.body);

    let totalTxValue = dataFiat[0][1]["longValue"] + dataDep[0][1]["longValue"];
    if (selectPayment === "fiat") {
      totalTxValue = dataFiat[0][1]["longValue"];
    } else if (selectPayment === "dep") {
      totalTxValue = dataDep[0][1]["longValue"];
    }

    let totalDepValue = Number(dataFiat[0][0]["stringValue"]) + Number(dataDep[0][0]["stringValue"]);
    if (selectPayment === "fiat") {
      totalDepValue = Number(dataFiat[0][0]["stringValue"]);
    } else if (selectPayment === "dep") {
      totalDepValue = Number(dataDep[0][0]["stringValue"]);
    }

    const statData = [
      {
        totalTx: totalTxValue,
        totalDep: totalDepValue, 
        totalFiatTx: selectPayment === "both" || selectPayment === "fiat" ? dataFiat[0][1]["longValue"] : "--",
        totalFiatAmount: selectPayment === "both" || selectPayment === "fiat" ?  Number(dataFiat[0][0]["stringValue"]) : "--",
        totalDepTx: selectPayment === "both" || selectPayment === "dep" ? dataDep[0][1]["longValue"] : "--",
        totalDepAmount: selectPayment === "both" || selectPayment === "dep" ? Number(dataDep[0][0]["stringValue"]) : "--",
      },
    ];

    yield put(setInGameItemTxHistoryData(dataAllTxDetail));
    yield put(setInGameItemTxHistoryStatData(statData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getTxByTermSxi(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    const searchKeyword = yield select(itemSearchTerm);
    yield put(setPageTopLoader(true));

    const gameTarget = action.payload.target;
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
    //const selectItem = action.payload.item ? "'" + action.payload.item.join("', '") + "'"  : false;
    let selectItem = yield select(selectedItem);
    selectItem = selectItem.length > 0 ? "'" + selectItem.join("', '") + "'"  : false;
    const selectPmid = yield select(selectedPmid);

    const dataFilter = yield select(fitler);
    const dateRange = dataFilter[0]["dateRange"];
    const offsetNum = (action.payload.page - 1) * 20;

    const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
    const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;

    if (searchKeyword.length > 0) {
      yield put(setPageTopLoader(true));

      const sqlQuerySalesFiatTermInRange = H.SqlHelper.getInGameItemSalesCountAndAmount({
        table: "SxiInGameFiatSalesProd2",
        range: [rangeStart, rangeEnd],
        target: contentId,
        item: selectItem,
        pmid: selectPmid,
      });
      const sqlQuerySalesDepTermInRange = H.SqlHelper.getInGameItemSalesCountAndAmount({
        table: "SxiInGameDepSalesProd",
        range: [rangeStart, rangeEnd],
        target: contentId,
        item: searchKeyword,
        pmid: selectPmid,
      });
      const sqlQueryAssetDetailTermInRange = H.SqlHelper.getInGameItemSalseTransactionByGame({
        table: "SxiInGameFiatSalesProd2",
        range: [rangeStart, rangeEnd],
        target: contentId,
        limit: 20,
        offset: offsetNum,
        item: selectItem,
        pmid: selectPmid,
      });

      const [
        dataItemTempFiatTerm,
        dataItemTempDepTerm,
        dataAssetDetailsTerm,
      ] = yield all([
        call(
          getDataFromRDS,
          sqlQuerySalesFiatTermInRange,
          ENDPOINT.GET_DATA_RDS,
          jwtToken
        ),
        call(
          getDataFromRDS,
          sqlQuerySalesDepTermInRange,
          ENDPOINT.GET_DATA_RDS,
          jwtToken
        ),
        call(
          getDataFromRDS,
          sqlQueryAssetDetailTermInRange,
          ENDPOINT.GET_DATA_RDS,
          jwtToken
        ),
      ]);

      const dataFiatTerm = JSON.parse(dataItemTempFiatTerm.body);
      const dataDepTerm = JSON.parse(dataItemTempDepTerm.body);
      const dataAllTxDetail = JSON.parse(dataAssetDetailsTerm.body);

      // const totalFiatAmount =
      //   dataStoreTerm[0][1]["longValue"] < 1
      //     ? 0
      //     : Number(dataStoreTerm[0][0]["stringValue"]);
      // const totalDepAmount =
      //   dataDepTerm[0][1]["longValue"] < 1
      //     ? 0
      //     : Number(dataDepTerm[0][0]["stringValue"]);

      const statData = [
        {
          totalTx: dataFiatTerm[0][1]["longValue"] + dataDepTerm[0][1]["longValue"],
          totalDep:
            Number(dataFiatTerm[0][0]["stringValue"]) + Number(dataDepTerm[0][0]["stringValue"]),
          totalFiatTx: dataFiatTerm[0][1]["longValue"],
          totalFiatAmount: Number(dataFiatTerm[0][0]["stringValue"]),
          totalDepTx: dataDepTerm[0][1]["longValue"],
          totalDepAmount: Number(dataDepTerm[0][0]["stringValue"]),
        },
      ];

      yield put(setInGameItemTxHistoryData(dataAllTxDetail));
      yield put(setInGameItemTxHistoryStatData(statData));
      yield put(setPageTopLoader(false));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getIngameTxCsvData(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    yield put(setNftTxCsvDownloader(true));
    const gameTarget = action.payload;
    const dataFilter = yield select(fitler);
    const dateRange = dataFilter[0]["dateRange"];
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
    let selectItem = yield select(selectedItem);
    selectItem = selectItem.length > 0 ? "'" + selectItem.join("', '") + "'"  : false;
    const selectPayment = yield select(selectedPayment);
    const selectPmid = yield select(selectedPmid);

    const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
    const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;
    const sqlQueryInGameDetailInRange =
      H.SqlHelper.getInGameItemTxByGameNoLimit({
        table: "SxiInGameFiatSalesProd2",
        range: [rangeStart, rangeEnd],
        target: contentId,
        item: selectItem,
        payment: selectPayment,
        pmid: selectPmid,
      });

    const [dataItemDetailsTemp] = yield all([
      call(
        getDataFromRDS,
        sqlQueryInGameDetailInRange,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ]);
    const returnData = JSON.parse(dataItemDetailsTemp.body);
    let csvData = [
        [
          "item_name",
          "game",
          "receipt_id",
          "dep",
          "price",
          "quantity",
          "buyer_pmid",
          "buyer_country",
          "created_at",
        ],
      ],
      eachRowData = [];
    if (returnData.length > 0) {
      _.forEach(returnData, function (value) {
        eachRowData = [
          value[4]["stringValue"],
          H.ContentsHelper.getContentsNameById(value[2]["stringValue"]),
          value[0]["stringValue"],
          Number(value[3]["stringValue"]),
          Number(value[6]["stringValue"]),
          Number(value[7]["stringValue"]),
          value[8]["stringValue"],
          value[9]["stringValue"],
          value[10]["stringValue"],
        ];
        csvData.push(eachRowData);
        eachRowData = [];
      });
    }
    yield put(setInGameItemHistoryCsvData(csvData));
    yield put(setNftTxCsvDownloader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getIngameItemOptionData(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    yield put(setInGameItemItemOptionDataLoading(true));
    const gameTarget = action.payload;
    const dataFilter = yield select(fitler);
    const dateRange = dataFilter[0]["dateRange"];
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
    const selectPayment = yield select(selectedPayment);
    let selectItem = yield select(selectedItem);
    selectItem = selectItem.length > 0 ? "'" + selectItem.join("', '") + "'"  : false;
    const selectPmid = yield select(selectedPmid);
    const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
    const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;

    const sqlQueryInGameOptionItem =
      H.SqlHelper.getIngameItemOptionData({
        range: [rangeStart, rangeEnd],
        target: contentId,
        column: "target_name",
        payment: selectPayment,
        pmid: selectPmid,
      });

    const [dataItemTargetTemp] = yield all([
      call(
        getDataFromRDS,
        sqlQueryInGameOptionItem,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ]);
    const itemData = JSON.parse(dataItemTargetTemp.body);

    // item
    let itemDataArray = [];
    if (itemData.length > 0) {
      _.forEach(itemData, function (value) {
        itemDataArray.push({
          name: value[0]["stringValue"],
          count: value[1]["longValue"],
        });
      });
    }

    let optionData = [
      {"item": itemDataArray},
    ]

    yield put(setInGameItemOptionData(optionData));
    yield put(setInGameItemItemOptionDataLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getIngamePmidOptionData(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    yield put(setInGamePmidOptionDataLoading(true));
    const gameTarget = action.payload;
    const dataFilter = yield select(fitler);
    const dateRange = dataFilter[0]["dateRange"];
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
    const selectPayment = yield select(selectedPayment);
    let selectItem = yield select(selectedItem);
    selectItem = selectItem.length > 0 ? "'" + selectItem.join("', '") + "'"  : false;
    const selectPmid = yield select(selectedPmid);
    const rangeStart = `${dateRange[0]}T00:00:00.000Z`;
    const rangeEnd = `${dateRange[1]}T23:59:59.999Z`;

    const sqlQueryInGameOptionPmid =
      H.SqlHelper.getIngameItemOptionData({
        range: [rangeStart, rangeEnd],
        target: contentId,
        column: "pmid",
        payment: selectPayment,
        item: selectItem,
        pmid: selectPmid,
    });

    const [dataItemPmidTemp] = yield all([
      call(
        getDataFromRDS,
        sqlQueryInGameOptionPmid,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ]);
    const itemDataPmid = JSON.parse(dataItemPmidTemp.body);

    let pmidDataArray = [];
    if (itemDataPmid.length > 0) {
      _.forEach(itemDataPmid, function (value) {
        pmidDataArray.push({
          pmid: value[0]["stringValue"],
          count: value[1]["longValue"],
        });
      });
    }
    let optionData = [
      {"pmid": pmidDataArray},
    ];
    yield put(setInGamePmidOptionData(optionData));
    yield put(setInGamePmidOptionDataLoading(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(DATA.GET_INGAME_ITEM_TX_HISTORY_DATA, getNftTxHisotryDataSxi);
  yield takeEvery(DATA.GET_INGAME_ITEM_TX_HISTORY_DATA_BY_TERM, getTxByTermSxi);
  yield takeEvery(DATA.GET_INGAME_SALES_DATA_CSV, getIngameTxCsvData);
  yield takeEvery(DATA.GET_INGAME_ITEM_OPTION_DATA, getIngameItemOptionData);
  yield takeEvery(DATA.GET_INGAME_PMID_OPTION_DATA, getIngamePmidOptionData);
}
