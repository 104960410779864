import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from "@material-ui/core/TextField";
import Tooltip from '@material-ui/core/Tooltip';
import { setCohortDateRange, setActiveUsersDateRange, setActiveUsersDateDiff, setNftDepTrendDateRange, setNftOwnTrendDateRange,
    setAverageRevenueDateRange, setJtcbNftTrendDateRange, setCookinBurgerNftTrendDateRange, setKamuiVerseNftTrendDateRange,
    setGraffitiRacerNftTrendDateRange, setDragonRamenNftTrendDateRange, setLuckyFarmerNftTrendDateRange,
    setLuckyFarmeGameTrendDateRange, setGraffitiRacerGameTrendDateRange, setCookinBurgerGameTrendDateRange, setLostArchiveNftTrendDateRange,
    setSoulFusersNftTrendDateRange, setGameNftTrendDateRange, setKpiSalesDateRange,
} from 'actions';

const useStyles = makeStyles((theme) =>
    createStyles({
        calendarWrapper: {
            paddingLeft: 8,
        },
        root: {
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        container: {
            display: 'flex',
            width: 300,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            alignItems: 'baseline',
        },
        delimiter: {
            paddingTop: theme.spacing(1)
        },
        sectionCalendar: {
            display:'flex',
        },
        resetBtn: {
            marginTop: 12,
        },
    }),
);

const CalendarRangeOnly = ({
    setCohortDateRange,
    cohortDateRange,
    nftDepTrendDateRange,
    nfOwnTrendDateRange,
    trendOptionSelected,
    nftOwnTrendOptionSelected,
    setActiveUsersDateRange,
    setActiveUsersDateDiff,
    activeUsersDateRange,
    activeUsersGameOption,
    setNftDepTrendDateRange,
    setNftOwnTrendDateRange,
    setBtnState,
    page,
    rangeMax,
    setAverageRevenueDateRange,
    setJtcbNftTrendDateRange,
    setCookinBurgerNftTrendDateRange,
    setDragonRamenNftTrendDateRange,
    setKamuiVerseNftTrendDateRange,
    setGraffitiRacerNftTrendDateRange,
    setLuckyFarmerNftTrendDateRange,
    setLuckyFarmeGameTrendDateRange,
    setGraffitiRacerGameTrendDateRange,
    setCookinBurgerGameTrendDateRange,
    setLostArchiveNftTrendDateRange,
    setSoulFusersNftTrendDateRange,
    setGameNftTrendDateRange,
    setKpiSalesDateRange,
}) => {
    const classes = useStyles();
    let minDate = page === "cohort" ? moment().tz("Asia/Singapore").subtract(90, 'days').format('YYYY/MM/DD')
                                      : moment().tz("Asia/Singapore").subtract(12, 'months').format('YYYY/MM/DD');
    minDate = page === "average-revenue" ? "2023/07/01" : minDate;
    minDate = page === "luckyFarmerGameTrend" ? "2022/11/14" : minDate;
    minDate = page.indexOf("graffitiRacer") !== -1 ? "2022/10/31" : minDate;
    minDate = page === "nftDepTrend" ? "2022/10/17" : minDate;
    minDate = page === "cookinBurgerGameTrend" ? "2022/12/05" : minDate;
    const [dateRangeValue, setDateRangeValue] = useState([null, null]);
    const [maxDate, setMaxDate] = useState(moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD'));
    const [defaultMaxDate, setDefaultMaxDate] = useState(moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD'));
    const [restBtnState, setResetBtnState] = useState(false);
    const [error, setError] = useState([null, null]);

    useEffect(() => {
        const currentJstDate = moment().tz("Asia/Singapore").format('YYYY/MM/DD');
        handleHomeCalendar(currentJstDate);
    }, []);

    useEffect(() => {
        if (page === 'cohort') {
            setDateRangeValue(cohortDateRange);
        } else if (page === 'activeUsers') {
            setDateRangeValue(activeUsersDateRange);
        } else if (page === 'nftDepTrend') {
            setNftDepTrendDateRange(nftDepTrendDateRange);
        }
    }, [cohortDateRange, activeUsersDateRange, nftDepTrendDateRange]);

    const handleHomeCalendar = (currentJstDate) => {
        if (localStorage.getItem('dailyAccessDate') === null) {
            setDefaultDate(currentJstDate);
        } else {
            // console.log('LOG: cohortDateRange: ', cohortDateRange);
            if (cohortDateRange.length === 0) {
                setDateRangeValue([moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')]);
            } else {
                setDateRangeValue(cohortDateRange)
            }
        }
    }

    const setDefaultDate = (currentJstDate) => {
        const dayBefore = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        const dayBeforeFormatted = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        if (page === "cohort") {
            setCohortDateRange([dayBeforeFormatted, dayBeforeFormatted]);
        } else if (page === "activeUsers") {
            setActiveUsersDateRange([dayBeforeFormatted, dayBeforeFormatted]);
        }
        setDateRangeValue([dayBefore, dayBefore]);
        localStorage.setItem('dailyAccessDate', currentJstDate);
    }

    const setRange = (newValue) => {
        const defaultMaxDateFormatted = moment(defaultMaxDate).format('YYYY-MM-DD');
        const startDate = moment(newValue[0]).format('YYYY-MM-DD');
        const endDate = moment(newValue[1]).format('YYYY-MM-DD');
        const newMaxDate = moment(startDate).tz("Asia/Singapore").add(30, 'days').format('YYYY-MM-DD');
        const newMaxDateActiveUsers = moment(startDate).tz("Asia/Singapore").add(rangeMax, 'days').format('YYYY-MM-DD');

        let isEndDateAfterDefault = moment(endDate).isAfter(defaultMaxDateFormatted);
        let isNewMaxDateAfterDefault = moment(newMaxDate).isBefore(defaultMaxDateFormatted);
        let isNewMaxDateAfterDefaultActiveUsers = moment(newMaxDateActiveUsers).isBefore(defaultMaxDateFormatted);

        if (page === "cohort") {
            setCohortDateRange([startDate, isEndDateAfterDefault ? defaultMaxDate : endDate]);
            setMaxDate(isEndDateAfterDefault ? newMaxDate : isNewMaxDateAfterDefault ? newMaxDate : defaultMaxDate);
        } else if (page === "activeUsers") {
            setActiveUsersDateRange([startDate, isEndDateAfterDefault ? defaultMaxDate : endDate]);
            setMaxDate(isEndDateAfterDefault ? newMaxDateActiveUsers : isNewMaxDateAfterDefaultActiveUsers ? newMaxDateActiveUsers : defaultMaxDate);
            const diffDays = moment(endDate).diff(moment(startDate), 'days')+1;
            setActiveUsersDateDiff(diffDays);
        } else if (page === "nftDepTrend") {
            setNftDepTrendDateRange([startDate, endDate]);
        } else if (page === "nftOwnTrend") {
            setNftOwnTrendDateRange([startDate, endDate]);
        } else if (page === "average-revenue") {
            setAverageRevenueDateRange([startDate, endDate]);
        } else if (page === "jtcbNftTrend") {
            setJtcbNftTrendDateRange([startDate, endDate]);
        } else if (page === "cookinBurgerNftTrend") {
            setCookinBurgerNftTrendDateRange([startDate, endDate]);
        } else if (page === "luckyFarmerNftTrend") {
            setLuckyFarmerNftTrendDateRange([startDate, endDate]);
        } else if (page === "dragonRamenNftTrend") {
            setDragonRamenNftTrendDateRange([startDate, endDate]);
        } else if (page === "graffitiRacerNftTrend") {
            setGraffitiRacerNftTrendDateRange([startDate, endDate]);
        } else if (page === "kamuiVerseNftTrend") {
            setKamuiVerseNftTrendDateRange([startDate, endDate]);
        } else if (page === "luckyFarmerGameTrend") {
            setLuckyFarmeGameTrendDateRange([startDate, endDate]);
        } else if (page === "graffitiRacerGameTrend") {
            setGraffitiRacerGameTrendDateRange([startDate, endDate]);
        } else if (page === "cookinBurgerGameTrend") {
            setCookinBurgerGameTrendDateRange([startDate, endDate]);
        } else if (page === "lostArchiveNftTrend") {
            setLostArchiveNftTrendDateRange([startDate, endDate]);
        } else if (page === "soulFusersNftTrend") {
            setSoulFusersNftTrendDateRange([startDate, endDate]);
        } else {
            setGameNftTrendDateRange([startDate, endDate]);
            setKpiSalesDateRange([startDate, endDate]);
        }
        setDateRangeValue([startDate, isEndDateAfterDefault ? defaultMaxDate : endDate]);

        if (page === "activeUsers") {
            if (activeUsersGameOption.length > 0) {
                setBtnState(false);
                setResetBtnState(false);
            }
        } else if (page === "nftDepTrend") {
            if (trendOptionSelected.length > 0) {
                setBtnState(false);
                setResetBtnState(false);
            }
        } else if (page === "nftOwnTrend") {
            if (nftOwnTrendOptionSelected.length > 0) {
                setBtnState(false);
                setResetBtnState(false);
            }
        } else {
            setBtnState(false);
            setResetBtnState(false);
        }
    }

    const resetCalendar = () => {
        setMaxDate(defaultMaxDate);
        const defaultStart = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
        const defaultEnd = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        setDateRangeValue([defaultStart, defaultEnd]);
        if (page === "cohort") {
            setCohortDateRange([defaultStart, defaultEnd]);
        } else if (page === "activeUsers") {
            setActiveUsersDateRange([defaultStart, defaultEnd]);
            setActiveUsersDateDiff(7);
        } else if (page === "nftDepTrend") {
            setNftDepTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "average-revenue") {
            setAverageRevenueDateRange([defaultStart, defaultEnd]);
        } else if (page === "jtcbNftTrend") {
            setJtcbNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "cookinBurgerNftTrend") {
            setCookinBurgerNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "luckyFarmerNftTrend") {
            setLuckyFarmerNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "dragonRamenNftTrend") {
            setDragonRamenNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "graffitiRacerNftTrend") {
            setGraffitiRacerNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "kamuiVerseNftTrend") {
            setKamuiVerseNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "luckyFarmerGameTrend") {
            setLuckyFarmeGameTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "graffitiRacerGameTrend") {
            setGraffitiRacerGameTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "cookinBurgerGameTrend") {
            setCookinBurgerGameTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "lostArchiveNftTrend") {
            setLostArchiveNftTrendDateRange([defaultStart, defaultEnd]);
        } else if (page === "soulFusersNftTrend") {
            setSoulFusersNftTrendDateRange([defaultStart, defaultEnd]);
        } else {
            setGameNftTrendDateRange([defaultStart, defaultEnd]);
        }

        if (page === "activeUsers") {
            if (activeUsersGameOption.length > 0) {
                setBtnState(false);
                setResetBtnState(false);
            }
        } else {
            setBtnState(false);
            setResetBtnState(false);
        }
    }

    return (
        <div className={classes.calendarWrapper}>
            <div className={classes.root}>
            <Grid container spacing={1} direction="row">
                <Grid item>
                <DateRangePicker
                    startText="Start"
                    endText="End"
                    disableHighlightToday={true}
                    showToolbar={false}
                    allowSameDateSelection={true}
                    minDate={minDate}
                    maxDate={maxDate}
                    value={dateRangeValue}
                    onChange={(newValue) => setRange(newValue)}
                    onError={([startReason, endReason], [start, end]) => {
                        if (startReason === 'invalidRange') {
                            setError([null, null])
                            return;
                        }
                        setError([startReason, endReason])
                    }}
                    renderInput={(startProps, endProps) => (
                        <div className={classes.container}>
                            <TextField id="standard-basic" {...startProps} error={Boolean(error[0])} size="small" helperText="" />
                            <DateRangeDelimiter><span className={classes.delimiter}>~</span></DateRangeDelimiter>
                            <TextField id="standard-basic" {...endProps} error={Boolean(error[0])} size="small" helperText="" />
                        </div>
                    )}
                />
                </Grid>
                <Grid item>
                <Tooltip title="カレンダーリセット">
                    <IconButton disabled={restBtnState} className={classes.resetBtn} onClick={() => resetCalendar()} size="small">
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
                </Grid>
            </Grid>
            </div>
        </div>
    );
}

CalendarRangeOnly.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    cohortDateRange: state.cohort.cohortDateRange,
    activeUsersDateRange: state.activeUsers.activeUsersDateRange,
    activeUsersGameOption: state.activeUsers.activeUsersGameOption,
    nftDepTrendDateRange: state.nftDepTrend.nftDepTrendDateRange,
    trendOptionSelected: state.nftDepTrend.trendOptionSelected,
    nftOwnTrendOptionSelected: state.nftOwnTrend.nftOwnTrendOptionSelected,
    nfOwnTrendDateRange: state.nftOwnTrend.nfOwnTrendDateRange,
});

const mapDispatchToProps = (dispatch) => ({
    setCohortDateRange: (param) => dispatch(setCohortDateRange(param)),
    setActiveUsersDateRange: (param) => dispatch(setActiveUsersDateRange(param)),
    setActiveUsersDateDiff: (param) => dispatch(setActiveUsersDateDiff(param)),
    setNftDepTrendDateRange: (param) => dispatch(setNftDepTrendDateRange(param)),
    setNftOwnTrendDateRange: (param) => dispatch(setNftOwnTrendDateRange(param)),
    setAverageRevenueDateRange: (param) => dispatch(setAverageRevenueDateRange(param)),
    setJtcbNftTrendDateRange: (param) => dispatch(setJtcbNftTrendDateRange(param)),
    setCookinBurgerNftTrendDateRange: (param) => dispatch(setCookinBurgerNftTrendDateRange(param)),
    setDragonRamenNftTrendDateRange: (param) => dispatch(setDragonRamenNftTrendDateRange(param)),
    setKamuiVerseNftTrendDateRange: (param) => dispatch(setKamuiVerseNftTrendDateRange(param)),
    setGraffitiRacerNftTrendDateRange: (param) => dispatch(setGraffitiRacerNftTrendDateRange(param)),
    setLuckyFarmerNftTrendDateRange: (param) => dispatch(setLuckyFarmerNftTrendDateRange(param)),
    setLuckyFarmeGameTrendDateRange: (param) => dispatch(setLuckyFarmeGameTrendDateRange(param)),
    setGraffitiRacerGameTrendDateRange: (param) => dispatch(setGraffitiRacerGameTrendDateRange(param)),
    setCookinBurgerGameTrendDateRange: (param) => dispatch(setCookinBurgerGameTrendDateRange(param)),
    setLostArchiveNftTrendDateRange: (param) => dispatch(setLostArchiveNftTrendDateRange(param)),
    setSoulFusersNftTrendDateRange: (param) => dispatch(setSoulFusersNftTrendDateRange(param)),
    setGameNftTrendDateRange: (param) => dispatch(setGameNftTrendDateRange(param)),
    setKpiSalesDateRange: (param) => dispatch(setKpiSalesDateRange(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(CalendarRangeOnly));
