import React, { Fragment, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import * as H from 'helper';

const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        padding: theme.spacing(1) * 2,
        "&:last-child": {
        paddingBottom: theme.spacing(1) * 2,
        },
    },
    box: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        borderRight: '1px solid #dcdcdc',
    },
    title: {
        display: 'block',
        height: 32,
        fontSize: 14,
    },
    value: {
        paddingTop: 16,
    },
    numSection: {
        display: 'block',
        paddingTop: 16,
        height: 85,
    },
    circleProgress: {
        marginTop: 40,
        marginBottom: 8,
    },
    txtPrev: {
        fontSize: 13,
    },
    gainLossImg: {
        paddingTop: 8,
        marginRight: 4,
    },
    dotContainer: {
        marginTop: 8,
        marginRight: 16,
        paddingTop: 16,
        textAlign: "center",
    },
    dot: {
        borderRadius: 8,
        display: "inline-block",
        height: 12,
        width: 12,
        marginRight: 6,
        cursor: "pointer",
    },
}));

const StatBoxScholarCarousel = ({
    title,
    loading,
    data,
    lgValue,
}) => {
    const classes = useStyles();
    const [dotValue, setDotValue] = useState(0);

    const getPrevComponent = (value, prevValue, title) => {            
        const diffValue = value - prevValue;
        const diffRatio = diffValue / prevValue * 100;

        let imageName = "";
        if (title === "停止" && diffValue !== 0) {
            imageName = value > prevValue ? 'loss' : 'gain';
        } else if (diffValue !== 0) {
            imageName = value > prevValue ? 'gain' : 'loss';
        }

        if (!prevValue) {
            return <div className={classes.txtPrev}>---</div>
        }

        return(
            <div className={classes.txtPrev}>
                {imageName !== "" &&
                    <img className={classes.gainLossImg} src={`/static/images/${imageName}.png`} />
                }
                {H.FormatNumStrHelper.formatCommaThousand(diffValue)} ({Math.abs(diffRatio.toFixed(2))}%)
            </div>
        )
    }

    const handleDotClick = (value) => {
        setDotValue(value);
    }

    return (
        <Grid
            container
            alignItems={"center"}
            direction={"row"}
            justifyContent={"flex-start"}
        >
            <Grid item xs={12}>
                <div className={classes.box}>
                <Grid
                    container
                    direction={"row"}
                    justifyContent={"flex-start"}
                >
                    <Grid item xs={12}>
                        <Typography
                            variant="caption"
                            className={classes.title}
                            dangerouslySetInnerHTML={{__html: title}}
                        >
                        </Typography>
                    </Grid>
                </Grid>
                    <div className={classes.numSection}>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"space-evenly"}
                    >
                    {loading &&
                        <div className={classes.circleProgress}><CircularProgress size={20} /></div>
                    }
                    {!loading && data.length > 0 && dotValue === 0 &&
                        <Fragment>
                        <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{data[0]['title']}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {<Typography variant="h6">{H.FormatNumStrHelper.formatCommaThousand(data[0]['current'])}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {getPrevComponent(data[0]['current'], data[0]['prev'], data[0]['title'])}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{data[1]['title']}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {<Typography variant="h6">{H.FormatNumStrHelper.formatCommaThousand(data[1]['current'])}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {getPrevComponent(data[1]['current'], data[1]['prev'], data[1]['title'])}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{data[2]['title']}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {<Typography variant="h6">{H.FormatNumStrHelper.formatCommaThousand(data[2]['current'])}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {getPrevComponent(data[2]['current'], data[2]['prev'], data[2]['title'])}
                                </Grid>
                            </Grid>
                        </Grid>
                        </Fragment>
                    }
                    {!loading && data.length > 0 && dotValue === 1 &&
                        <Fragment>
                        <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{data[3]['title']}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {<Typography variant="h6">{H.FormatNumStrHelper.formatCommaThousand(data[3]['current'])}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {getPrevComponent(data[3]['current'], data[3]['prev'], data[3]['title'])}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{data[4]['title']}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {<Typography variant="h6">{data[4]['current']}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {getPrevComponent(data[4]['current'], data[4]['prev'], data[4]['title'])}
                                </Grid>
                            </Grid>
                        </Grid>
                        </Fragment>
                    }
                    </Grid>                      
                    </div>
                    {!loading && data.length > 0 &&
                    <div className={classes.dotContainer}>
                        <span onClick={() => {handleDotClick(0)}} className={classes.dot} style={{ backgroundColor: dotValue === 0 ? "#494949" : "#afafaf" }}></span>
                        <span onClick={() => {handleDotClick(1)}} className={classes.dot} style={{ backgroundColor: dotValue === 1 ? "#494949" : "#afafaf" }}></span>
                    </div>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

StatBoxScholarCarousel.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    color: PropTypes.string,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(StatBoxScholarCarousel));
