import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import BarChartStyles from './BarChartStyles';
import * as H from 'helper';

export class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelColor: '#FFF',
            labelPosition: 'inside',
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    async componentDidMount() {

    }

    magicTypeChanged = (param) => {
        console.log('param:', param);
    }

    render () {
        const { classes, color, game, homeActiveGeoChartData } = this.props;

        let onEvents = {
            'magictypechanged': this.magicTypeChanged,
        }

        return (
            <div className={classes.chartContainer}>
                {this.props.homeActiveGeoChartData[game][0] === 'loading' ? (
                    <div className={classes.progress}><CircularProgress /></div>
                ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                            formatter: function (data) {
                                return `${data[0]['axisValue']} : ${H.FormatNumStrHelper.formatCommaThousand(data[0]['data'])} ( ${homeActiveGeoChartData[game][1][data[0]['dataIndex']]}% )`;
                            },
                            order:'valueDesc',
                        },
                        grid: {
                            left: 80,
                            top: 48,
                            right: 16,
                            bottom: 32
                        },
                        xAxis: {
                            type: "value",
                            axisLabel : {
                                fontFamily: "Roboto",
                                color:"#000",
                                formatter: function (value, index) {
                                    if (index % 2 !== 0) {
                                        return H.FormatNumStrHelper.formatCommaThousand(value);
                                    }
                                }
                            }
                        },
                        yAxis: {
                            type: 'category',
                            data: homeActiveGeoChartData[game][0],
                            axisLabel : {
                                fontFamily: "Roboto",
                                color:"#000",
                            }
                        },
                        series: [
                            {
                                name: 'DAU',
                                type: 'bar',
                                data: homeActiveGeoChartData[game][2],
                                barMaxWidth: 16,
                            },
                        ],
                        color: color,
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{height: '350px', width: '100%'}}
                />
                )}
            </div>
        )
    }
}

BarChart.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    homeActiveGeoChartData: state.home.homeActiveGeoChartData,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(BarChartStyles, { withTheme: true })(BarChart));
