import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GamePuzzleStyles from 'pages/GamePuzzleStyles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as C from 'components';
import * as V from 'styles/variables';
import ChartConfig from 'utils/ChartConfig';
import { setCurrentPage, getAcquisitionData } from 'actions';

const GamePuzzleAcquisition = ({classes, setCurrentPage, getAcquisitionData}) => {
    const [tabFocused, setTabFocused] = useState('map');

    useEffect(() => {
        setCurrentPage('puzzle');
        getAcquisitionData();
    }, []);

    return (
        <Fragment>
        <div className={classes.root}>
            <C.CalendarRangePicker
                mDate={'2020/09/28'}
                page="puzzle"
            />
        </div>
        <C.ContentWrapper>
            {/* Acquisition Panel */}
            <Grid container spacing={3} className={classes.pageSectionTop}>
                <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <div className={classes.cardHeadSection}>
                            <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                Puzzle 集客データ
                            </Typography>
                            <div className={classes.titleAction}>
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.cardContentSection}>
                            <C.Acquisition />
                        </div>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>

            {/* Acquisition Chart */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <div className={classes.cardHeadSection}>
                                <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                    Puzzle 集客トレンド
                                </Typography>
                                <div className={classes.titleAction}>
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.cardContentSectionChart}>
                                <C.LineChart
                                    legend={ChartConfig.legendPuzzleAcquisition}
                                    color={V.acquisitionPallette}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* GEO Panel */}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                <Card>
                    <CardContent>
                        <div className={classes.cardHeadSection}>
                            <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                Puzzle GEO データ
                            </Typography>
                            <div className={classes.titleAction}>
                                <IconButton aria-label="settings">
                                    <MoreVertIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={classes.cardContentSection}>
                            <C.Geo />
                        </div>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>

            {/* GEO Table */}
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.RegCountry title="国別PMID登録数 広告経由" source="ad" />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.RegCountry title="国別PMID登録数 Organic" source="organic" />
                </Grid>
            </Grid>

            {/* GEO Map */}
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <Card>
                        <CardContent>
                            <div className={classes.cardHeadSection}>
                                <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                    国別PMID登録数 広告経由
                                </Typography>
                                <div className={classes.titleAction}>
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.cardContentSection}>
                                <C.RegCountryMap source="ad" width="100%" height="400px" />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={12} lg={6}>
                    <Card>
                        <CardContent>
                            <div className={classes.cardHeadSection}>
                                <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                    国別PMID登録数 Organic
                                </Typography>
                                <div className={classes.titleAction}>
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.cardContentSection}>
                                <C.RegCountryMap source="organic" width="100%" height="400px" />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </C.ContentWrapper>
        </Fragment>
    );
};

GamePuzzleAcquisition.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getAcquisitionData: () => dispatch(getAcquisitionData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(GamePuzzleStyles, { withTheme: true })(GamePuzzleAcquisition));