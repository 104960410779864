import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth } from "aws-amplify";
//import { Link } from 'react-router-dom';
import { loadCSS } from 'fg-loadcss/src/loadCSS';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import HeaderStyles from 'components/Header/HeaderStyles';

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            username: '',
        };
    }

  handleSettingsToggle = event => {
      this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = () => {
      this.setState({ anchorEl: null });
  };

  handleDrawerToggle = () => {
      this.props.toggleDrawer();
  }

  componentDidMount() {
      Auth.currentAuthenticatedUser()
      .then(user => this.setState({ username: user.attributes.email }))
      .catch(err => console.log(err));

      loadCSS(
          'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
          document.querySelector('#insertion-point-jss'),
      );
  }

  render() {
    const { classes, logo, logoAltText } = this.props;
    const { anchorEl } = this.state;

    return (
        <Fragment>
            <AppBar
                position="static"
                className={classes.appBar}>
                <Toolbar className={classes.toolBar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.handleDrawerToggle}
                            >
                        <MenuIcon />
                    </IconButton>
                    <div className={classes.branding}>
                        <img src={logo} alt={logoAltText} className={classes.logo} />
                    </div>

                    <Hidden smUp>
                        <span className="flexSpacer" />
                    </Hidden>

                    <div className={classes.rightMenuArea}>
                        <div onClick={this.handleSettingsToggle}>
                            <Hidden smDown>
                                <span className={classes.username}>{this.state.username}</span>
                            </Hidden>
                            <Avatar className={classes.avatar}><AccountCircle className={classes.iconWhite} /></Avatar>
                        </div>
                    </div>

                    <Menu
                        id="user-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleCloseMenu}
                        PaperProps={{
                            style: {
                                marginTop: 40,
                                marginLeft: 80
                            }
                        }}
                    >
                        <MenuItem onClick={this.props.handleLogout}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary="ログアウト" />
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {this.props.pageTopLoader &&
            <LinearProgress />
            }
      </Fragment>
    )
  }
}

HeaderStyles.prototypes = {
    classes: PropTypes.object.isRequired,
    logo: PropTypes.string,
    logoAltText: PropTypes.string
};

const mapStateToProps = state => ({
    pageTopLoader: state.page.pageTopLoader
});

export default connect(mapStateToProps, null)(withStyles(HeaderStyles, { withTheme: true })(Header));