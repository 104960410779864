const chartSeriesName = {
    "direct_mail" : ["total_impressions", "average_impressions_before_visit", "impressions_over_time"],
    "play": "ログイン回数",
    "clear": "ステージ成功数",
    "failed": "ステージ失敗数",
    "keso": "KESO獲得回数",
    "activeUsersTrendData": "アクティブユーザー",
    "PlayMining": "PlayMining",
    "nftdep": "PlayMining NFT",
    "jobtribes": "JobTribes",
    "puzzle": "Puzzle",
    "luckyfarmer": "Lucky Farmer",
    "graffiti": "GraffitiRacer",
    "cookinburger": "CookinBurger",
    "dragonramen": "DragonRamen",
    "lostarchiveplus": "LostArchive+",
    "soulfusers": "SoulFusers",
    "roguerollrulers": "Rogue Roll Ruler's",
    "bouncybunny": "Bouncy Bunny",
    "pictree": "PicTree", // PicTrée
    "store-count": "1次DEP件数",
    "store-amount": "1次DEP額",
    "auction-count": "2次件数",
    "activeWallet": "ユニークWallet",
    "auction-amount": "2次販売額",
    "fiat-count": "1次FIAT件数",
    "fiat-amount": "1次FIAT US$",

    "distribute": "配布DEP件数",
    "distribute-amount": "配布DEP額",
    "distributions": "分配金件数",
    "distributions-amount": "分配金額",
    "withdrawal": "出金手数料件数",
    "withdrawal-amount": "出金手数料額",
    "airdrop": "エアードロップ件数",
    "airdrop-amount": "エアードロップ額",
    "staking_cancel": "ステーキング解約件数",
    "staking_cancel-amount": "ステーキング解約額",
    "staking_apply": "ステーキング預入件数",
    "staking_apply-amount": "ステーキング預入額",
    "staking_interest": "ステーキング利息件数",
    "staking_interest-amount": "ステーキング利息額",
// '分配金件数', '分配金額', '出金手数料件数', '出金手数料額',
//'ステーキング解約件数', 'ステーキング解約額', 'ステーキング預入件数', 'ステーキング預入額', 'ステーキング利息件数', 'ステーキング利息額
    "ownerNew": "Owner新規",
    "ownerStop": "Owner停止",
    "scholarNew": "Scholar新規",
    "scholarStop": "Scholar停止",
    "allAmulet": "全アミュレット",

    "auction-nft-unique": "ユニークユーザー",
    "store-nft-unique": "ユニークユーザー",

    "auction-legendary-count": "LEGENDARY 件数",
    "auction-legendary-amount": "LEGENDARY DEP額",
    "auction-epic-count": "EPIC 件数",
    "auction-epic-amount": "EPIC DEP額",
    "auction-rare-count": "RARE 件数",
    "auction-rare-amount": "RARE DEP額",
    "auction-common-count": "COMMON 件数",
    "auction-common-amount": "COMMON DEP額",

    "store-legendary-count": "LEGENDARY 件数",
    "store-legendary-amount": "LEGENDARY DEP額",
    "store-epic-count": "EPIC 件数",
    "store-epic-amount": "EPIC DEP額",
    "store-rare-count": "RARE 件数",
    "store-rare-amount": "RARE DEP額",
    "store-common-count": "COMMON 件数",
    "store-common-amount": "COMMON DEP額",

    "store-legendary-count-fiat": "LEGENDARY 件数",
    "store-legendary-amount-fiat": "LEGENDARY USD額",
    "store-epic-count-fiat": "EPIC 件数",
    "store-epic-amount-fiat": "EPIC USD額",
    "store-rare-count-fiat": "RARE 件数",
    "store-rare-amount-fiat": "RARE USD額",
    "store-common-count-fiat": "COMMON 件数",
    "store-common-amount-fiat": "COMMON USD額",

    "auction-cookinburger-count": "件数",
    "auction-cookinburger-amount": "DEP額",
    "store-cookinburger-count": "件数",
    "store-cookinburger-amount": "DEP額",
    "store-cookinburger-count-fiat": "件数",
    "store-cookinburger-amount-fiat": "USD額",

    "auction-soulfusers-count": "件数",
    "auction-soulfusers-amount": "DEP額",
    "store-soulfusers-count": "件数",
    "store-soulfusers-amount": "DEP額",
    "store-soulfusers-count-fiat": "件数",
    "store-soulfusers-amount-fiat": "USD額",

    "auction-dragonramen-count": "件数",
    "auction-dragonramen-amount": "DEP額",
    "store-dragonramen-count": "件数",
    "store-dragonramen-amount": "DEP額",

    "auction-sss-count": "SSS 件数",
    "auction-sss-amount": "SSS DEP額",
    "auction-ss-count": "SS 件数",
    "auction-ss-amount": "SS DEP額",
    "auction-s-count": "S 件数",
    "auction-s-amount": "S DEP額",
    "auction-a-count": "A 件数",
    "auction-a-amount": "A DEP額",
    "auction-b-count": "B 件数",
    "auction-b-amount": "B DEP額",
    "auction-c-count": "C 件数",
    "auction-c-amount": "C DEP額",

    "store-sss-count": "SSS 件数",
    "store-sss-amount": "SSS DEP額",
    "store-ss-count": "SS 件数",
    "store-ss-amount": "SS DEP額",
    "store-s-count": "S 件数",
    "store-s-amount": "S DEP額",
    "store-a-count": "A 件数",
    "store-a-amount": "A DEP額",
    "store-b-count": "B 件数",
    "store-b-amount": "B DEP額",
    "store-c-count": "C 件数",
    "store-c-amount": "C DEP額",

    "store-sss-amount-fiat": "SSS USD額",
    "store-ss-amount-fiat": "SS USD額",
    "store-s-amount-fiat": "S USD額",
    "store-a-amount-fiat": "A USD額",
    "store-b-amount-fiat": "B USD額",
    "store-c-amount-fiat": "C USD額",

    "auction-kamuiverse-count": "件数",
    "auction-kamuiverse-amount": "DEP額",
    "store-kamuiverse-count": "件数",
    "store-kamuiverse-amount": "DEP額",

    "ingame-stamina-count": "Recovery Stamina 件数",
    "ingame-stamina-amount": "Recovery Stamina DEP",
    "ingame-land-count": "Recovery Land 件数",
    "ingame-land-amount": "Recovery Land DEP",
    "ingame-shop-count": "SHOP 件数",
    "ingame-shop-amount": "SHOP DEP",
    "ingame-gacha-count": "ガチャ 件数",
    "ingame-gacha-amount": "ガチャ DEP",

    "ingame-dep-use-count": "DEP USE 件数",
    "ingame-dep-use-count-unique": "DEP USE ユニーク",
    "ingame-dep-use-amount": "DEP USE額",
    "ingame-dep-get-count": "GET 件数",
    "ingame-dep-get-count-unique": "GET ユニーク",
    "ingame-dep-get-amount": "DEP GET額",
    "ingame-sheet-count": "Sheet Pack 件数",
    "ingame-sheet-amount": "Sheet Pack DEP",
    "ingame-fiat-use-count": "FIAT USE 件数",
    "ingame-fiat-use-count-unique": "FIAT USE ユニーク",
    "ingame-fiat-use-amount": "FIAT USE額",

    "ingame-dep-use-count1": "フィルム購入 件数",
    "ingame-dep-use-amount1": "フィルム購入 DEP",
    "ingame-dep-use-count2": "コーティング 件数",
    "ingame-dep-use-amount2": "コーティング DEP",
    "ingame-dep-use-count3": "ブリード 件数",
    "ingame-dep-use-amount3": "ブリード DEP",
    "ingame-dep-use-count4": "レギュラーシートパック購入 件数",
    "ingame-dep-use-amount4": "レギュラーシートパック購入 DEP",
    "ingame-dep-use-count5": "アビリティ因子解析 件数",
    "ingame-dep-use-amount5": "アビリティ因子解析 DEP",
    "ingame-dep-use-count6": "プレミアムシートパック購入 件数",
    "ingame-dep-use-amount6": "プレミアムシートパック購入 DEP",
    "ingame-dep-use-count7": "ぬり絵（復元） 件数",
    "ingame-dep-use-amount7": "ぬり絵（復元） DEP",

    "auction-ur-count": "UR 件数",
    "auction-ur-amount": "UR DEP額",
    "auction-n-count": "N 件数",
    "auction-n-amount": "N DEP額",
    "auction-sr-count": "SR 件数",
    "auction-sr-amount": "SR DEP額",
    "auction-ssr-count": "SSR 件数",
    "auction-ssr-amount": "SSR DEP額",
    "auction-r-count": "R 件数",
    "auction-r-amount": "R DEP額",

    "store-ur-count": "UR 件数",
    "store-ur-amount": "UR DEP額",
    "store-n-count": "N 件数",
    "store-n-amount": "N DEP額",
    "store-sr-count": "SR 件数",
    "store-sr-amount": "SR DEP額",
    "store-ssr-count": "SSR 件数",
    "store-ssr-amount": "SSR DEP額",
    "store-r-count": "R 件数",
    "store-r-amount": "R DEP額",

    "store-ur-count-fiat": "UR 件数",
    "store-ur-amount-fiat": "UR USD額",
    "store-n-count-fiat": "N 件数",
    "store-n-amount-fiat": "N USD額",
    "store-sr-count-fiat": "SR 件数",
    "store-sr-amount-fiat": "SR USD額",
    "store-ssr-count-fiat": "SSR 件数",
    "store-ssr-amount-fiat": "SSR USD額",
    "store-r-count-fiat": "R 件数",
    "store-r-amount-fiat": "R USD額",

    "ingame-repair-count": "Repair Shop 件数",
    "ingame-repair-amount": "Repair Shop DEP",

    // InGame Item
    // "ingame-item-fiat-count1" : "件数",
    // "ingame-item-fiat-amount1" : "DEP額",
};

const screenshotImageName = {
    "home" : "Homes | KPI Dashboard",
    "puzzle.acquisition" : "Puzzle - 集客 | KPI Dashboard",
    "puzzle.events" : "Puzzle - 行動 | KPI Dashboard",
};

export default {
    chartSeriesName,
    screenshotImageName
};

/*
    { name: '分配金', value: 'distributions' },
    { name: "配布DEP", value:"distribute"},
    { name: "出金手数料", value:"withdrawal"},
    { name: 'エアードロップ', value: 'airdrop' },
    { name: "ステーキング", value:"staking"},
    { name: "ステーキング預け入れ", value:"staking_apply"},
    { name: "ステーキング利息", value:"staking_interest"},
    { name: "ステーキング解約", value:"staking_cancel"},
*/