import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from "moment";
import * as H from 'helper';
import ChartStyle from 'styles/ChartStyle';
import LineChartStyles from './LineChartStyles';

export class LineChartThumb extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seriesType: 'line',
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    async componentDidMount() {

    }

    render () {
        const { classes, chartData, acquisitionHomeDataRegLast, xAxisData } = this.props;

        return (
            <div className={classes.chartContainer}>
                {this.props.dataLoading === true ? (
                    <div className={classes.progress}><CircularProgress /></div>
                ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            show: true,
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                            formatter: function (lblData) {
                                return moment(lblData[0]['axisValueLabel']).format("M/D") + " : " + H.FormatNumStrHelper.formatCommaThousand(Number(lblData[0]['value']));
                            },
                        },
                        grid: {
                            left: 8,
                            top: 16,
                            right: 16,
                            bottom: 0
                        },
                        xAxis: {
                          show: false,
                          type: "category",
                          data: !xAxisData ? acquisitionHomeDataRegLast.dateRange : xAxisData,
                          boundaryGap: false,
                        },
                        yAxis: {
                          type: "value",
                          axisLine: {
                            show: false,
                          },
                          axisTick: {
                            show: false,
                          },
                          splitLine: {
                            show: false
                          },
                        },
                        series: [
                            {
                                name: '',
                                type: 'line',
                                data: chartData,
                                lineStyle: {
                                    color: '#428dc7',
                                    width: 2,
                                },
                                areaStyle: {
                                    color: '#e1f6f4',
                                    opacity: .8
                                },
                                symbol: 'none',
                            },
                        ],
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    style={{height: '40px', width: '98%'}}
                />
                )}
            </div>
        )
    }
}

LineChartThumb.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    acquisitionHomeDataRegLast: state.home.acquisitionHomeDataRegLast,
    // dauDateRange: state.home.activeUserHomeData.puzzle.dauLastSeven.dateRange,
    // mauDateRange: state.home.activeUserHomeData.puzzle.mauLastThirty.dateRange,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(LineChartStyles, { withTheme: true })(LineChartThumb));