import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getInGameItemSalesByGame } from "actions";
import * as C from "components";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#fafafa",
    },
  })
);

const InGameItemByGame = ({
  setOverviewTab,
  getInGameItemSalesByGame,
  game,
  loading,
  overviewData,
}) => {
  useEffect(() => {
    setOverviewTab("game");
    const game = window.location.pathname.split("/");
    getInGameItemSalesByGame(game[2]);
  }, [window.location.pathname]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByGame
            title="ゲーム別FIAT課金取引額"
            source="ingame-fiat"
            loading={loading.ovByGame}
            total={overviewData.fiatAmount}
            game={game}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxCountByGame
            title="ゲーム別FIAT課金取引件数"
            source="ingame-fiat"
            loading={loading.ovByGame}
            total={overviewData.fiatCount}
            game={game}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByGame
            title="ゲーム別DEP課金取引額"
            source="ingame-dep"
            loading={loading.ovByGame}
            total={overviewData.depAmount}
            game={game}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxCountByGame
            title="ゲーム別DEP課金取引件数"
            source="ingame-dep"
            loading={loading.ovByGame}
            total={overviewData.depCount}
            game={game}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

InGameItemByGame.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  dateLoaded: state.data.dataLoaded,
  loading: state.inGameItemSales.loading,
  overviewData: state.inGameItemSales.dataOverview
});

const mapDispatchToProps = (dispatch) => ({
  setOverviewTab: (param) => dispatch(setOverviewTab(param)),
  getInGameItemSalesByGame: (param) =>
    dispatch(getInGameItemSalesByGame(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(InGameItemByGame));
