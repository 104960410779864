// Query
// SELECT * FROM answer_target_material atm
// JOIN alias_of_answer_target_material aatm ON atm.id = aatm.answer_target_material_id
// JOIN answer_target_material_version atmv ON atm.id = atmv.answer_target_material_id
// ORDER BY atm.create_datetime DESC
const campaignsData = [
    {
      "id": "clxobfrne0001gmn5deu7gfrm",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-21T06:40:57.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-21T06:40:57.000Z",
      "update_datetime": "2024-06-21T06:40:57.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxobfrne0000gmn5e1rm4khh",
      "answer_target_material_version_id": "clxobfrne0001gmn5deu7gfrm",
      "alias": "テストJA_ad20240620"
    },
    {
      "id": "clxoay0v100016cn59ws46qdi",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-21T06:25:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-21T06:25:27.000Z",
      "update_datetime": "2024-06-21T06:25:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxoay0v000006cn55bu71982",
      "answer_target_material_version_id": "clxoay0v100016cn59ws46qdi",
      "alias": "テストJA_ad20240620"
    },
    {
      "id": "clxndu5tj000wotkbdhek8hbd",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T15:00:54.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T15:00:54.000Z",
      "update_datetime": "2024-06-20T15:00:54.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxndu5tj000votkbh3fjb6t9",
      "answer_target_material_version_id": "clxndu5tj000wotkbdhek8hbd",
      "alias": "テストJA_adonly4_20240620"
    },
    {
      "id": "clxndu5tj000notkbe9ka8ruf",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T15:00:54.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T15:00:54.000Z",
      "update_datetime": "2024-06-20T15:00:54.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxndu5tj000motkb7kf93h6w",
      "answer_target_material_version_id": "clxndu5tj000notkbe9ka8ruf",
      "alias": "テストJA_adonly3_20240620"
    },
    {
      "id": "clxndu5ti000cotkb53ea6lrr",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T15:00:54.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T15:00:54.000Z",
      "update_datetime": "2024-06-20T15:00:54.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxndu5ti000botkbdloo6lpo",
      "answer_target_material_version_id": "clxndu5ti000cotkb53ea6lrr",
      "alias": "テストJA_adonly2_20240620"
    },
    {
      "id": "clxndu5ti0001otkbcmfp3odm",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T15:00:54.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T15:00:54.000Z",
      "update_datetime": "2024-06-20T15:00:54.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxndu5ti0000otkbe42eagqv",
      "answer_target_material_version_id": "clxndu5ti0001otkbcmfp3odm",
      "alias": "テストJA_adonly1_20240620"
    },
    {
      "id": "clxnag0iv0027kokbde2kbvxy",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T13:25:22.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T13:25:22.000Z",
      "update_datetime": "2024-06-20T13:25:22.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxnag0iv0026kokbeh8g96zk",
      "answer_target_material_version_id": "clxnag0iv0027kokbde2kbvxy",
      "alias": "テストJA_quizwithad20240620"
    },
    {
      "id": "clxnag0it0001kokb2pxjf3x6",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T13:25:22.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T13:25:22.000Z",
      "update_datetime": "2024-06-20T13:25:22.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxnag0it0000kokb7ayzcunw",
      "answer_target_material_version_id": "clxnag0it0001kokb2pxjf3x6",
      "alias": "テストJA_adonly20240620"
    },
    {
      "id": "clxmyjlen002706kb0na92qrf",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T07:51:20.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T07:51:20.000Z",
      "update_datetime": "2024-06-20T07:51:20.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxmyjlen002606kb0dr96rgm",
      "answer_target_material_version_id": "clxmyjlen002706kb0na92qrf",
      "alias": "テストJA_ad20240620"
    },
    {
      "id": "clxmyjlel000106kb5du1aw62",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-06-19T15:00:00.000Z",
      "end_distribution_datetime": "2024-07-19T15:00:00.000Z",
      "registered_datetime": "2024-06-20T07:51:20.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-20T07:51:20.000Z",
      "update_datetime": "2024-06-20T07:51:20.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clxmyjlel000006kb7qb0d3m1",
      "answer_target_material_version_id": "clxmyjlel000106kb5du1aw62",
      "alias": "テストJA_ad20240620"
    },
    {
      "id": "clukq4t0w000t8cpk20y30c91",
      "can_distribute": 0,
      "answer_producer_id": "clujfy3yz000408jo1gja3ku7",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-08-31T00:00:00.000Z",
      "registered_datetime": "2024-06-12T12:20:15.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-12T12:20:15.000Z",
      "update_datetime": "2024-06-12T12:20:15.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clukq4t0w000s8cpk931n8af9",
      "answer_target_material_version_id": "clukq4t0w000t8cpk20y30c91",
      "alias": "リーフ・パブリケーションズ様（Sake World NFT）_20240403_stg2"
    },
    {
      "id": "clukq4t11001z8cpkdjk5bpiv",
      "can_distribute": 0,
      "answer_producer_id": "clujfy3yz000408jo1gja3ku7",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-08-31T00:00:00.000Z",
      "registered_datetime": "2024-06-12T12:20:15.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-12T12:20:15.000Z",
      "update_datetime": "2024-06-12T12:20:15.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clukq4t11001y8cpk6sib2554",
      "answer_target_material_version_id": "clukq4t11001z8cpkdjk5bpiv",
      "alias": "リーフ・パブリケーションズ様（Sake World NFT）_20240403_stg2"
    },
    {
      "id": "clukq4t0u00018cpk940v1xuq",
      "can_distribute": 0,
      "answer_producer_id": "clujfy3yz000408jo1gja3ku7",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-08-31T00:00:00.000Z",
      "registered_datetime": "2024-06-12T12:20:15.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-12T12:20:15.000Z",
      "update_datetime": "2024-06-12T12:20:15.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clukq4t0t00008cpk4nou28u5",
      "answer_target_material_version_id": "clukq4t0u00018cpk940v1xuq",
      "alias": "リーフ・パブリケーションズ様（Sake World NFT）_20240403_stg2"
    },
    {
      "id": "clukxsjzb0001lhpk80tr4gln",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-08-31T00:00:00.000Z",
      "registered_datetime": "2024-06-12T12:20:12.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-12T12:20:12.000Z",
      "update_datetime": "2024-06-12T12:20:12.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clukxsjzb0000lhpk99gq3jzj",
      "answer_target_material_version_id": "clukxsjzb0001lhpk80tr4gln",
      "alias": "フライペンギン様（麺屋ドラゴンラーメン）2_20240403_stg2"
    },
    {
      "id": "clukv1wsb0001arpkalsnbywx",
      "can_distribute": 0,
      "answer_producer_id": "clq7zgwee008ryho8cndwa8g5",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-08-31T00:00:00.000Z",
      "registered_datetime": "2024-06-12T12:20:09.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-12T12:20:09.000Z",
      "update_datetime": "2024-06-12T12:20:09.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clukv1wsb0000arpk4y213van",
      "answer_target_material_version_id": "clukv1wsb0001arpkalsnbywx",
      "alias": "Sun Asterisk様（BouncyBunny）2_20240403_stg2"
    },
    {
      "id": "clukr4u5v00012dpk9jxta2gl",
      "can_distribute": 0,
      "answer_producer_id": "clujfyeek000608jo0u45042j",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-08-31T00:00:00.000Z",
      "registered_datetime": "2024-06-12T12:20:03.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-06-12T12:20:03.000Z",
      "update_datetime": "2024-06-12T12:20:03.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clukr4u5v00002dpkgdls5mqp",
      "answer_target_material_version_id": "clukr4u5v00012dpk9jxta2gl",
      "alias": "Crypto Beer Punks様_20240403_stg2"
    },
    {
      "id": "clw8wumkd0001iko7dvtv5eji",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-05-17T09:00:00.000Z",
      "end_distribution_datetime": "2024-06-13T09:00:00.000Z",
      "registered_datetime": "2024-05-16T07:30:44.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-05-16T07:30:44.000Z",
      "update_datetime": "2024-05-16T07:30:44.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clw8wumkc0000iko7h9r7959m",
      "answer_target_material_version_id": "clw8wumkd0001iko7dvtv5eji",
      "alias": "WAVEE_A2Eクイズ2"
    },
    {
      "id": "clw8wh8x30001woo7a14r4xi9",
      "can_distribute": 0,
      "answer_producer_id": "cluqt2lbo000208l81xdqfqpn",
      "start_distribution_datetime": "2024-05-17T09:00:00.000Z",
      "end_distribution_datetime": "2024-06-07T09:00:00.000Z",
      "registered_datetime": "2024-05-16T07:23:55.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-05-16T07:23:55.000Z",
      "update_datetime": "2024-05-16T07:23:55.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clw8wh8x30000woo75d8t6u91",
      "answer_target_material_version_id": "clw8wh8x30001woo7a14r4xi9",
      "alias": "OKCoinJapan_コラボキャンペーン_240515"
    },
    {
      "id": "clvdvwfn10001iomvdj275ldq",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-25T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-25T09:00:00.000Z",
      "registered_datetime": "2024-04-25T02:27:38.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-25T02:27:38.000Z",
      "update_datetime": "2024-04-25T02:27:38.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clvdvwfn10000iomv5ttsgqcn",
      "answer_target_material_version_id": "clvdvwfn10001iomvdj275ldq",
      "alias": "CryptoGames_A2Eクイズ_240423"
    },
    {
      "id": "clv69n52h0001ogpp2xj47vkf",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-19T09:00:00.000Z",
      "registered_datetime": "2024-04-19T06:25:24.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-19T06:25:24.000Z",
      "update_datetime": "2024-04-19T06:25:24.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clv69n52h0000ogpph67pc92b",
      "answer_target_material_version_id": "clv69n52h0001ogpp2xj47vkf",
      "alias": "チューリンガム_A2Eクイズ_240419"
    },
    {
      "id": "clv69fi4n000wn7pp7hzf6rp1",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-19T09:00:00.000Z",
      "registered_datetime": "2024-04-19T06:22:19.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-19T06:22:19.000Z",
      "update_datetime": "2024-04-19T06:22:19.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clv69fi4n000vn7ppepribley",
      "answer_target_material_version_id": "clv69fi4n000wn7pp7hzf6rp1",
      "alias": "NERO_A2Eクイズ_240419"
    },
    {
      "id": "clv69fi4m0001n7ppfam5dn49",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-19T09:00:00.000Z",
      "registered_datetime": "2024-04-19T06:22:19.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-19T06:22:19.000Z",
      "update_datetime": "2024-04-19T06:22:19.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clv69fi4l0000n7ppcka05vxr",
      "answer_target_material_version_id": "clv69fi4m0001n7ppfam5dn49",
      "alias": "NERO_A2Eクイズ_240419"
    },
    {
      "id": "clv69fi4n000ln7pp2x901ma0",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-19T09:00:00.000Z",
      "registered_datetime": "2024-04-19T06:22:19.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-19T06:22:19.000Z",
      "update_datetime": "2024-04-19T06:22:19.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clv69fi4n000kn7pp3gpa74y3",
      "answer_target_material_version_id": "clv69fi4n000ln7pp2x901ma0",
      "alias": "NERO_A2Eクイズ_240419"
    },
    {
      "id": "clv698k8z0001q7pp7ohmcvm4",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-19T09:00:00.000Z",
      "registered_datetime": "2024-04-19T06:18:59.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-19T06:18:59.000Z",
      "update_datetime": "2024-04-19T06:18:59.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clv698k8z0000q7pp6klw1fpq",
      "answer_target_material_version_id": "clv698k8z0001q7pp7ohmcvm4",
      "alias": "JPYC_A2Eクイズ_240419"
    },
    {
      "id": "clusasvbb0001s4lgbjbxg3zp",
      "can_distribute": 0,
      "answer_producer_id": "cluqt2lbo000208l81xdqfqpn",
      "start_distribution_datetime": "2024-04-10T09:00:00.000Z",
      "end_distribution_datetime": "2024-04-17T07:00:00.000Z",
      "registered_datetime": "2024-04-09T12:10:42.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-09T12:10:42.000Z",
      "update_datetime": "2024-04-09T12:10:42.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clusasvbb0000s4lgblttedii",
      "answer_target_material_version_id": "clusasvbb0001s4lgbjbxg3zp",
      "alias": "OKCoinJapan_コラボキャンペーン_240410"
    },
    {
      "id": "clum5mjli0001dlon0jdn9vmz",
      "can_distribute": 0,
      "answer_producer_id": "clujfy7t4000508joc6tud781",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:26:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:26:27.000Z",
      "update_datetime": "2024-04-05T05:26:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum5mjli0000dlon648pdzcg",
      "answer_target_material_version_id": "clum5mjli0001dlon0jdn9vmz",
      "alias": "リーフ・パブリケーションズ様（Sake World NFT）_20240403"
    },
    {
      "id": "clum5mjlk000tdlonca9c9jpk",
      "can_distribute": 0,
      "answer_producer_id": "clujfy7t4000508joc6tud781",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:26:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:26:27.000Z",
      "update_datetime": "2024-04-05T05:26:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum5mjlk000sdlongb6j05eb",
      "answer_target_material_version_id": "clum5mjlk000tdlonca9c9jpk",
      "alias": "リーフ・パブリケーションズ様（Sake World NFT）_20240403"
    },
    {
      "id": "clum5mjlq001zdlonhfctc4lq",
      "can_distribute": 0,
      "answer_producer_id": "clujfy7t4000508joc6tud781",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:26:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:26:27.000Z",
      "update_datetime": "2024-04-05T05:26:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum5mjlq001ydlon7e0m7mr8",
      "answer_target_material_version_id": "clum5mjlq001zdlonhfctc4lq",
      "alias": "リーフ・パブリケーションズ様（Sake World NFT）_20240403"
    },
    {
      "id": "clum5f0u60001enon095e65c5",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:22:16.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:22:16.000Z",
      "update_datetime": "2024-04-05T05:22:16.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum5f0u60000enonhh6j27zh",
      "answer_target_material_version_id": "clum5f0u60001enon095e65c5",
      "alias": "フライペンギン様（麺屋ドラゴンラーメン）2_20240403"
    },
    {
      "id": "clum57q6b0001f7on3uilcc42",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:18:25.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:18:25.000Z",
      "update_datetime": "2024-04-05T05:18:25.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum57q6b0000f7on79qghfvk",
      "answer_target_material_version_id": "clum57q6b0001f7on3uilcc42",
      "alias": "クロスゲームズ様（Rogue Roll Ruler's）2_20240403"
    },
    {
      "id": "clum4y4wl00137gon37tiew6c",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:12:07.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:12:07.000Z",
      "update_datetime": "2024-04-05T05:12:07.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum4y4wl00127gon7hrygknt",
      "answer_target_material_version_id": "clum4y4wl00137gon37tiew6c",
      "alias": "エッグフォワード様（WAVEE）_20240403"
    },
    {
      "id": "clum4y4wi00017gonevzv71kb",
      "can_distribute": 0,
      "answer_producer_id": "clujfxz3t000308jobymw5cs6",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:12:07.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:12:07.000Z",
      "update_datetime": "2024-04-05T05:12:07.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum4y4wi00007gondgt708ia",
      "answer_target_material_version_id": "clum4y4wi00017gonevzv71kb",
      "alias": "エッグフォワード様（WAVEE）_20240403"
    },
    {
      "id": "clum5sjqz00016kon6dhec8e0",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:08:29.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:08:29.000Z",
      "update_datetime": "2024-04-05T05:08:29.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum5sjqy00006konbucp1eo2",
      "answer_target_material_version_id": "clum5sjqz00016kon6dhec8e0",
      "alias": "Sun Asterisk様（BouncyBunny）2_20240403"
    },
    {
      "id": "clum4qvtj00018oon9zpk91fu",
      "can_distribute": 0,
      "answer_producer_id": "clujfyk7m000708jo4cpo743c",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:04:52.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:04:52.000Z",
      "update_datetime": "2024-04-05T05:04:52.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum4qvtj00008oon1ztgfw8d",
      "answer_target_material_version_id": "clum4qvtj00018oon9zpk91fu",
      "alias": "Crypto Beer Punks様_20240403"
    },
    {
      "id": "clum4hwtl000q37onew7e5htl",
      "can_distribute": 0,
      "answer_producer_id": "clqffrpve003x0750qs3ap12s",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:00:40.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:00:40.000Z",
      "update_datetime": "2024-04-05T05:00:40.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum4hwtl000p37onexboeh9q",
      "answer_target_material_version_id": "clum4hwtl000q37onew7e5htl",
      "alias": "CA gamefi様(TOKYO BEAST)_20240403"
    },
    {
      "id": "clum4hwtf000137on7irm6bqd",
      "can_distribute": 0,
      "answer_producer_id": "clqffrpve003x0750qs3ap12s",
      "start_distribution_datetime": "2024-04-05T09:00:00.000Z",
      "end_distribution_datetime": "2024-05-05T09:00:00.000Z",
      "registered_datetime": "2024-04-05T05:00:40.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-04-05T05:00:40.000Z",
      "update_datetime": "2024-04-05T05:00:40.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clum4hwtf000037ongx422ibq",
      "answer_target_material_version_id": "clum4hwtf000137on7irm6bqd",
      "alias": "CA gamefi様(TOKYO BEAST)_20240403"
    },
    {
      "id": "cltxt1w9f0099ugonharh1j4r",
      "can_distribute": 0,
      "answer_producer_id": "cltwju0og000208lcft574zis",
      "start_distribution_datetime": "2024-03-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "registered_datetime": "2024-03-19T03:55:28.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-03-19T03:55:28.000Z",
      "update_datetime": "2024-03-19T03:55:28.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "cltxt1w9f0098ugon3yy02u53",
      "answer_target_material_version_id": "cltxt1w9f0099ugonharh1j4r",
      "alias": "αU market_コラボキャンペーン_240319"
    },
    {
      "id": "cltxt1w9d007sugonfu36bfv6",
      "can_distribute": 0,
      "answer_producer_id": "cltwju0og000208lcft574zis",
      "start_distribution_datetime": "2024-03-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "registered_datetime": "2024-03-19T03:55:28.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-03-19T03:55:28.000Z",
      "update_datetime": "2024-03-19T03:55:28.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "cltxt1w9d007rugone6yq8wj7",
      "answer_target_material_version_id": "cltxt1w9d007sugonfu36bfv6",
      "alias": "αU market_コラボキャンペーン_240319"
    },
    {
      "id": "cltxt1w9b005dugon8ucsesxa",
      "can_distribute": 0,
      "answer_producer_id": "cltwju0og000208lcft574zis",
      "start_distribution_datetime": "2024-03-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "registered_datetime": "2024-03-19T03:55:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-03-19T03:55:27.000Z",
      "update_datetime": "2024-03-19T03:55:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "cltxt1w9b005cugon62lx6y5s",
      "answer_target_material_version_id": "cltxt1w9b005dugon8ucsesxa",
      "alias": "αU market_コラボキャンペーン_240319"
    },
    {
      "id": "cltxt1w8t0001ugondl5720oi",
      "can_distribute": 0,
      "answer_producer_id": "cltwju0og000208lcft574zis",
      "start_distribution_datetime": "2024-03-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "registered_datetime": "2024-03-19T03:55:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-03-19T03:55:27.000Z",
      "update_datetime": "2024-03-19T03:55:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "cltxt1w8s0000ugon4bcsd96u",
      "answer_target_material_version_id": "cltxt1w8t0001ugondl5720oi",
      "alias": "αU market_コラボキャンペーン_240319"
    },
    {
      "id": "cltxt1w94002lugon2y4p22xu",
      "can_distribute": 0,
      "answer_producer_id": "cltwju0og000208lcft574zis",
      "start_distribution_datetime": "2024-03-19T09:00:00.000Z",
      "end_distribution_datetime": "2024-04-19T09:00:00.000Z",
      "registered_datetime": "2024-03-19T03:55:27.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-03-19T03:55:27.000Z",
      "update_datetime": "2024-03-19T03:55:27.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "cltxt1w94002kugonfrta6pvw",
      "answer_target_material_version_id": "cltxt1w94002lugon2y4p22xu",
      "alias": "αU market_コラボキャンペーン_240319"
    },
    {
      "id": "clsv5bpay0026oqpdguveeb28",
      "can_distribute": 0,
      "answer_producer_id": "clqffrpve003x0750qs3ap12s",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-21T02:06:19.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-21T02:06:19.000Z",
      "update_datetime": "2024-02-21T02:06:19.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv5bpay0025oqpd5nkwauhf",
      "answer_target_material_version_id": "clsv5bpay0026oqpdguveeb28",
      "alias": "TOKYOBEAST_βテスト_240222"
    },
    {
      "id": "clsv5bpau000roqpd33fd7umc",
      "can_distribute": 0,
      "answer_producer_id": "clqffrpve003x0750qs3ap12s",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-21T02:06:19.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-21T02:06:19.000Z",
      "update_datetime": "2024-02-21T02:06:19.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv5bpau000qoqpd05fw77aa",
      "answer_target_material_version_id": "clsv5bpau000roqpd33fd7umc",
      "alias": "TOKYOBEAST_βテスト_240222"
    },
    {
      "id": "clsv5bpas0001oqpdax3tf0bj",
      "can_distribute": 0,
      "answer_producer_id": "clqffrpve003x0750qs3ap12s",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-21T02:06:19.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-21T02:06:19.000Z",
      "update_datetime": "2024-02-21T02:06:19.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv5bpas0000oqpd4kijgwl7",
      "answer_target_material_version_id": "clsv5bpas0001oqpdax3tf0bj",
      "alias": "TOKYOBEAST_βテスト_240222"
    },
    {
      "id": "clsv3kxar0001sspdcj4g9iu3",
      "can_distribute": 0,
      "answer_producer_id": "clqffrh9p003v0750ruqqd551",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-21T01:51:51.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-21T01:51:51.000Z",
      "update_datetime": "2024-02-21T01:51:51.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv3kxar0000sspdfnc35wd6",
      "answer_target_material_version_id": "clsv3kxar0001sspdcj4g9iu3",
      "alias": "コインムスメ_βテスト_240222"
    },
    {
      "id": "clsv282060001dzpd5tbyhye9",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-21T00:39:42.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-21T00:39:42.000Z",
      "update_datetime": "2024-02-21T00:39:42.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv282050000dzpdd2zj86p0",
      "answer_target_material_version_id": "clsv282060001dzpd5tbyhye9",
      "alias": "Rogue Roll Ruler's_βテスト_240222"
    },
    {
      "id": "clsv1tqen0001ofpd3rer09qe",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-21T00:27:16.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-21T00:27:16.000Z",
      "update_datetime": "2024-02-21T00:27:16.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv1tqen0000ofpdcdsm6x25",
      "answer_target_material_version_id": "clsv1tqen0001ofpd3rer09qe",
      "alias": "カムイバース_βテスト_240222"
    },
    {
      "id": "clsv0qlb900015mpdhvozenc7",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-20T23:57:13.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-20T23:57:13.000Z",
      "update_datetime": "2024-02-20T23:57:13.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv0qlb900005mpd7zyy4crb",
      "answer_target_material_version_id": "clsv0qlb900015mpdhvozenc7",
      "alias": "BouncyBunny_βテスト_240222"
    },
    {
      "id": "clsv0qlbc001n5mpd2s1oh3r7",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-20T23:57:13.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-20T23:57:13.000Z",
      "update_datetime": "2024-02-20T23:57:13.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsv0qlbc001m5mpda22b9k1h",
      "answer_target_material_version_id": "clsv0qlbc001n5mpd2s1oh3r7",
      "alias": "BouncyBunny_βテスト_240222"
    },
    {
      "id": "clsuzalyh00016ppdavq6duzv",
      "can_distribute": 0,
      "answer_producer_id": "clqffr009003t0750j040irgn",
      "start_distribution_datetime": "2024-02-21T03:00:00.000Z",
      "end_distribution_datetime": "2024-03-22T03:00:00.000Z",
      "registered_datetime": "2024-02-20T23:17:12.000Z",
      "version": 0,
      "deleted": 0,
      "create_datetime": "2024-02-20T23:17:12.000Z",
      "update_datetime": "2024-02-20T23:17:12.000Z",
      "create_user": "initial",
      "update_user": "initial",
      "answer_target_material_id": "clsuzalyh00006ppd2768biu4",
      "answer_target_material_version_id": "clsuzalyh00016ppdavq6duzv",
      "alias": "麺屋ドラゴンラーメン_βテスト_240222"
    }
]

export {
  campaignsData,
}
