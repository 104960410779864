import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Chip } from '@material-ui/core';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts'
import ChartStyle from 'styles/ChartStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage } from 'actions';
import * as C from 'components';
import * as V from 'styles/variables';
import * as H from 'helper';

const Lab = ({
    pageTopLoader,
    setCurrentPage,
}) => {
    const classes = useStyles();

    const data = [
        [
            [75, 1557821, '6.23 メールマガジン', 'JobTribes', 1],
            [8, 175100, '6.16 メールマガジン', 'JobTribes', 2],
            [462, 151951, 'Twitter_Game_Indonesia / en', 'JobTribes', 22],
            [57, 116701, 'DV360_Ranking Battle_Fiat_Indonesia / en', 'JobTribes', 1],
            [39, 63252, 'Facebook_Anime_Philippines / en', 'JobTribes', 4],
            [25, 60525,  'Facebook_Crypto_Philippines,India / en', 'JobTribes', 5],
            [6, 30500, 'Cogent_Crytocurrency media_coindaily_202008 / id', 'JobTribes', 1],
            [173, 26351, 'Facebook_Game_Indonesia / en', 'JobTribes', 8],
            [66, 25844, 'Facebook_Game_Philippines / en', 'JobTribes', 14],
            [5, 25120, 'Facebook_Game_Philippines,India / en', 'JobTribes', 4]
        ],
        [
            [748, 809604, '[Lucky Farmer用] PlayMiningポータルサイト', 'LuckyFarmer', 165],
            [269, 319355, 'Facebook_Game_Philippines', 'LuckyFarmer', 77],
            [25, 10860, 'facebook_CV extension_Philippines', 'LuckyFarmer', 15],
            [54, 10460, 'Facebook_Casino_Philippines', 'LuckyFarmer', 8],
            [9, 5660, 'Facebook_Crypto_Indonesia', 'LuckyFarmer', 8],
            [10, 4680, 'Cogent_KOL_Detective Aldo_202106-2 / id', 'LuckyFarmer', 3],
            [2, 1120, 'Cogent_KOL_Hirotada_202106-2 / id', 'LuckyFarmer', 2],
            [2, 1120, 'Cogent_KOL_Hirotada_202107 / id', 'LuckyFarmer', 2],
            [3, 960, 'Cogent_KOL_Yuvi Phan_202107 / id', 'LuckyFarmer', 1],
            [1, 960, 'Facebook_Crypto_Philippines', 'LuckyFarmer', 1]
        ],
        [
            [50, 16180, 'Cogent_KOL_Detective Aldo_202010 / id', 'Puzzle', 7],
            [22, 14357, 'Cogent_Cyber Cafe_2_Puzzle_202010 / id', 'Puzzle', 2],
            [38, 7860, 'Cogent_KOL_Hirotada_202101 / id', 'Puzzle', 4],
            [5, 6480, 'Cogent_KOL_Kelvin Gaming_202010 / id', 'Puzzle', 2],
            [15, 6160, 'Cogent_KOL_Dhani Design_202103 / id', 'Puzzle', 10],
            [5, 6000, 'Cogent_KOL_Hirotada Radifan_202010 / id', 'Puzzle', 1],
            [15, 4977, 'メールマガジン（JTパズルゲーム）', 'Puzzle', 5],
            [12, 2380, 'Deri_Article/id', 'Puzzle', 2],
            [11, 2200, 'Cogent_Facebook (regular)_202012 / id', 'Puzzle', 3],
            [14, 1680, 'Cogent_KOL_Kelvin Gaming_202011 / id', 'Puzzle', 4]
        ]
    ];
    const option = {
        title: {
          text: '広告別ストア販売DEP売上   ~ 2022.2.23',
          left: '5%',
          top: '3%'
        },
        legend: {
          right: '10%',
          top: '3%',
          data: ['JotTribes', 'LuckyFarmer', 'Puzzle']
        },
        grid: {
          left: '10%',
          top: '12%'
        },
        xAxis: {
          name: "取引件数",
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        yAxis: {
          name: "DEP",
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          scale: true
        },
        series: [
          {
            name: 'JotTribes',
            data: data[0],
            type: 'scatter',
            symbolSize: function (data) {
              return Math.sqrt(data[1]) / 15;
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                align: 'left',
                lineHeight: 17,
                position: ['50%', '0%'],
                formatter: function (param) {
                  let depAmt = H.FormatNumStrHelper.formatCommaThousand(param.data[1]);
                  return param.data[2]+"\nDEP額: "+depAmt+"\n取引件数: "+param.data[0]+"\nPMID数: "+param.data[4];
                },
                color: "#3a3a3a",
                position: 'top'
              }
            },
            itemStyle: {
                color: "#c2b466"
            }
          },
          {
            name: 'LuckyFarmer',
            data: data[1],
            type: 'scatter',
            symbolSize: function (data) {
              return Math.sqrt(data[1]) / 15;
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                align: 'left',
                lineHeight: 17,
                position: ['50%', '50%'],
                formatter: function (param) {
                    let depAmt = H.FormatNumStrHelper.formatCommaThousand(param.data[1]);
                    return param.data[2]+"\nDEP額: "+depAmt+"\n取引件数: "+param.data[0]+"\nPMID数: "+param.data[4];
                  },
                color: "#3a3a3a",
                position: 'top'
              }
            },
            itemStyle: {
              color: "#6aa31e",
            }
          },
          {
            name: 'Puzzle',
            data: data[2],
            type: 'scatter',
            symbolSize: function (data) {
              return Math.sqrt(data[1]) / 15;
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                align: 'left',
                lineHeight: 17,
                formatter: function (param) {
                    let depAmt = H.FormatNumStrHelper.formatCommaThousand(param.data[1]);
                    return param.data[2]+"\nDEP額: "+depAmt+"\n取引件数: "+param.data[0]+"\nPMID数: "+param.data[4];
                  },
                color: "#3a3a3a",
                position: 'top'
              }
            },
            itemStyle: {
              color: "#843893",
            }
          }
        ]
    };

    useEffect(() => {
        setCurrentPage(window.location.pathname);
    }, []);

    return (
        <Fragment>
            <C.ContentWrapper>
            <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                {pageTopLoader 
                    ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                    : <ReactECharts
                        option={option}
                        style={{ height: 800 }}
                      />
                }
                </Grid>
            </Grid>
            </C.ContentWrapper>
        </Fragment>
    );
};

Lab.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    pageTopLoader: state.page.pageTopLoader,
    activeUsersData: state.activeUsers.activeUsersData,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Lab));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBoxForUnique: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 110,
        },
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        filterSection: {
            display: 'flex',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        activeUsersTable: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        lineChart: {

        },
        selectGameForUnique: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 110,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        selectGameTxt: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 280,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        flexContainerNotFix: {
            display: 'flex',
            overflown: 'hidden',
            width: '100%',
            flexFlow: 'row wrap',
            marginLeft: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBox: {
            alignSelf: 'center',
            paddingLeft: 8,
            paddingRight: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBoxTotal: {
            fontSize: 14,
            alignSelf: 'top',
            paddingLeft: 0,
            paddingRight: 4,
        },
        monthPicker: {
            marginTop: 11,
            marginLeft: 16,
            width: 120,
        },
        cardTitle: {
            fontSize: 16,
        },
        progressFunnel: {
            marginTop: '160px',
            marginBottom: '160px',
        },
        progress4Funnel: {
            marginTop: '160px',
            marginLeft: '30%',
            marginBottom: '160px',
        },
        funnelLegendWrapper: {
            marginTop: '16px',
            marginBottom: '70px',
        },
        funnelLegendRow: {
            display: 'block',
        },
        funnelLegend: {
            width: '30px',
            height: '10px',
            float: 'left',
            clear: 'both',
            paddingBottom: '8px',
        },
        legendLabel: {
            float: 'left',
            marginLeft: '7px',
            fontSize: '13px',
        },
        legendValue: {
            float: 'right',
            fontSize: '13px',
        },
        colorFunnelTop: {
            backgroundColor: '#46aaee',
        },
        colorFunnelBottom: {
            backgroundColor: '#f53b78',
        },
        cardContentTop: {
            //height: 130
        }
    }),
);
