import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getInGameItemSalesByCountry } from "actions";
import * as C from "components";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#fafafa",
    },
  })
);

const InGameItemByCountry = ({
  setOverviewTab,
  getInGameItemSalesByCountry,
  game,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setOverviewTab("country");
    const game = window.location.pathname.split("/");
    getInGameItemSalesByCountry(game[2]);
  }, [window.location.pathname]);

  // useEffect(() => {
  //   setOverviewTab("country");
  //   const game = window.location.pathname.split("/");
  //   getInGameItemSalesByCountry(game[2]);
  // }, [game]);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別FIAT課金取引額"
            source="ingame-fiat"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別FIAT課金取引件数"
            source="ingame-fiat"
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別DEP課金取引額"
            source="ingame-dep"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別DEP課金取引件数"
            source="ingame-dep"
            loading={loading}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

InGameItemByCountry.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  dateLoaded: state.data.dataLoaded,
  loading: state.inGameItemSales.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setOverviewTab: (param) => dispatch(setOverviewTab(param)),
  getInGameItemSalesByCountry: (param) =>
    dispatch(getInGameItemSalesByCountry(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(InGameItemByCountry));
