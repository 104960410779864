import { put, delay, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setNftHoldersData, setNftHoldersDataLoading, setPageTopLoader, setNftHoldersTotalCount,
    setNftHoldersStatsData } from 'actions';
import { getJwtToken, getDataFromElasticSearch, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const nftOwnTrendDateRange = state => state.nftOwnTrend.nftOwnTrendDateRange;
export const nftOwnTrendOptionSelected = state => state.nftOwnTrend.nftOwnTrendOptionSelected;
export const pageNumber = state => state.nftHolders.pageNumber;

export function* getNftHoldersData() {
    try {
        yield put(setPageTopLoader(true));
        yield put(setNftHoldersDataLoading(true));

        const jwtToken = yield call(getJwtToken);
        const rangeTarget = yield select(nftOwnTrendDateRange);
        const pageNum = yield select(pageNumber);

        const matchArray = ["g3bq4ssqNmgS8yiQWuba"];
        const offsetNum = pageNum - 1;
        const sourceNftHoldersData = H.ElasticSearch.getNftHoldersOffsetData(matchArray, "2022-04-01", offsetNum, 20);
        const esQueryHolderOffset = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "user_assets",
            "src": `${sourceNftHoldersData}`
        };

        const sourceNftHoldersStats = H.ElasticSearch.getNftHoldersStatsData(matchArray, "2022-04-01");
        const esQueryHolderStats = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "user_assets",
            "src": `${sourceNftHoldersStats}`
        };

        const sqlAllAssetsCount = {"query": "select COUNT(*) from user_assets" }; 
        const sqlUniqueAssetsCount = {"query": "select COUNT(DISTINCT uid) from user_assets" };
        //const sqlQueryTotalUsers = {"query": `SELECT COUNT(*) as count FROM user`}

        const [
            dataHolderOffsetTemp,
            dataHolderStatsTemp,
        ] = yield all([
            //call(getDataFromElasticSearch, esQueryHolderOffset, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            //call(getDataFromElasticSearch, esQueryHolderStats, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromSxi, sqlAllAssetsCount, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlUniqueAssetsCount, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);
        const initialData = dataHolderOffsetTemp[0];
        const statsData = dataHolderStatsTemp[0];
        const nftHoldersStatData = [{
            "totalHolders" : statsData.holder_count.value,
            "uniqueHolders" : statsData.unique_holder_count.value,
            "totalDep" : Number(statsData.price_total.value.toFixed(2))
        }];
        yield put(setNftHoldersStatsData(nftHoldersStatData));

        let outcomeArray = [], dataObj = {}, uidArray = [];
        for (let i=0; i<initialData.hits.hits.length; i++) {
            uidArray.push(initialData.hits.hits[i]['_source']['uid']);
            dataObj.asset_doc_id = initialData.hits.hits[i]['_source']['asset_doc_id'];
            dataObj.asset_id = initialData.hits.hits[i]['_source']['asset']['asset_id'];
            dataObj.asset_image = initialData.hits.hits[i]['_source']['asset']['thumbnail'];
            dataObj.asset_name = initialData.hits.hits[i]['_source']['asset']['names'][0]['value'];
            dataObj.pmid = initialData.hits.hits[i]['_source']['uid'];
            dataObj.price = Number(initialData.hits.hits[i]['_source']['price']);
            dataObj.datetime = initialData.hits.hits[i]['_source']['updated_at'];
            outcomeArray.push(dataObj);
            dataObj = {};
        }
        // console.log("OUTCOME:", uidArray);
        // console.log("YOYO OUTCOME:", outcomeArray);

        // fetch users meta data
        const sourcePmidData = H.ElasticSearch.getNftHoldersPmidData(uidArray, 20);
        const esQueryPmid = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "users",
            "src": `${sourcePmidData}`
        };

        // Fetch PMID user data
        const [
            dataPmidTemp,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryPmid, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);

        const pmIdData = dataPmidTemp.hits.hits;
        let userObj = {}, idCountryArray = [];
        _.forEach(pmIdData, function(item, i) {
            userObj.pmid = item._id;
            userObj.country = item._source.country;
            idCountryArray.push(userObj);
            userObj = {};
        });
        // console.log("YOYO idCountryArray:", idCountryArray);

        _.forEach(outcomeArray, function(user, i) {
            _.filter(idCountryArray, function(o) {
                if (o.pmid == user.pmid) {
                    user.country = o.country;
                    //console.log("YOYO ", o.country)
                }
            });
        });
        // console.log("YOYO outcomeArray:", outcomeArray);

        yield put(setNftHoldersData(outcomeArray));
        yield put(setNftHoldersTotalCount(initialData.hits.total.value));
        yield put(setPageTopLoader(false));
        yield put(setNftHoldersDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_NFT_HOLDERS_DATA, getNftHoldersData);
}
