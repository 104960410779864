import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import D3Funnel from 'd3-funnel';

const useStyles = makeStyles((theme) => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
    },
    chartContainer: {
        marginTop: -16,
        width: '100%',
        display: 'block',
        textAlign: 'center',
    },
}));

const Funnel = ({
    data
}) => {
    const classes = useStyles();

    useEffect(() => {
        const dataPassed = data;
        const options = {
            chart: {
                width: 150,
                height: 200,
                bottomPinch: 0,
                animate: 0,
            },
            block: {
                dynamicHeight: false,
                hoverEffects: false,
                fill : {
                    scale: ['#46aaee', '#f53b78', '#93e399']
                },
                highlight: true,
            },
            label: {
                format: function(label, value) {
                    let displayValue = label;
                    return displayValue;
                },
                fontFamily: 'Roboto',
                fill: "#000"
            },
        };
        const chart = new D3Funnel('#funnel');
        chart.draw(dataPassed, options);
    }, []);

    return (
        <div className={classes.chartContainer}>
            <div id="funnel"></div>
        </div>
    );
};

Funnel.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Funnel));
