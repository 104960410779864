import { LUCKYFARMER } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    luckyFarmerGamePaidTrendDateLoading: false,
    luckyFarmerGamePaidTrendDateRange: [startDate, endDate],
    trendData: [],
    gameTrendDataStamina: [],
    gameTrendDataLand: [],
    gameTrendDataShop: {},
    gameTrendDataGacha: {},
    gameTrendDataUnique: [],
};

export default function luckyFarmerGamePaidTrendReducer(state = initialState, action) {
    switch (action.type) {
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_DATA_LOADING:
            return {
                ...state,
                luckyFarmerGamePaidTrendDateLoading: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_DATE_RANGE:
            return {
                ...state,
                luckyFarmerGamePaidTrendDateRange: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_PAGE_DATA:
            return {
                ...state,
                trendData: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_STAMINA:
            return {
                ...state,
                gameTrendDataStamina: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_LAND:
            return {
                ...state,
                gameTrendDataLand: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_SHOP:
            return {
                ...state,
                gameTrendDataShop: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_GACHA:
            return {
                ...state,
                gameTrendDataGacha: action.payload
            };
        case LUCKYFARMER.SET_LUCKYFARMER_GAME_TREND_UNIQUE:
            return {
                ...state,
                gameTrendDataUnique: action.payload
            };
        default:
            return state
    }
};
