import React, { Fragment, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as H from 'helper';
import * as C from 'components';

const CohortPureTable = ({
  cohortFocused,
  cohortSizeSelected,
  cohortData,
  cohortNftData,
  pageTopLoader,
}) => {
  const classes = useStyles();
  const [cohortRange, setCohortRange] = useState([]);
  const [cohortNftRange, setCohortNftRange] = useState([]);

  useEffect(() => {
    const cohortDataPassed = _.result(
      _.find(cohortData, {
          id: cohortFocused,
      }),
      'data'
    );
    if (cohortDataPassed !== undefined) {
      setCohortRange(cohortDataPassed);
    }
    // NFT
    const cohortNftDataPassed = _.result(
      _.find(cohortNftData, {
          id: cohortFocused,
      }),
      'data'
    );
    if (cohortNftDataPassed !== undefined) {
      setCohortNftRange(cohortNftDataPassed);
    }
  }, [cohortData, cohortNftData]);

  const getTotalUserInRange = (cohortRangeData) => {
    let totalUser = cohortRangeData.map((data, i) =>
      i > 0 ? Number(data[1]) : 0
    );
    return H.FormatNumStrHelper.formatCommaThousand(_.sum(totalUser));
  }

  const getRangeTable = (cohortRangeData, type) => {
    const baseLbl = type === 'reg' ? "新規登録" : "新規購入"
    return  <Fragment>
              {cohortRangeData.map((d, i) =>
              <tr key={i}>
                <td className={classes.tTd}>
                  <div className={classes.lblAllUser}>{d[0]}</div>
                  <div className={classes.lblUniqueUser}>
                    {/* 初アクセス: {H.FormatNumStrHelper.formatCommaThousand(_.sum(_.tail(d)))} */}
                    {baseLbl}: {H.FormatNumStrHelper.formatCommaThousand(d[1])}
                  </div>
                </td>
              </tr >
              )}
            </Fragment>
  }

  const getDataHeader = (size, cohortRangeData) => {
    let rangeLabel = '';
    switch(size.value) {
      case 'day':
        rangeLabel = 'Day';
        break;
      case 'week' : 
        rangeLabel = 'Week';
        break;
      case 'month' :
        rangeLabel = 'Month';
        break;
      default:
    }
    return <Fragment>
            <tr className={classes._GAIv}>
            {[...Array(cohortRangeData[0].length-1)].map((x, i) =>
              <td className={classes.dataHeaderTd} key={i}>{rangeLabel} {i}</td>
            )}
            </tr >
          </Fragment>
  }

  const getDataPercent = (cellData, allDataArray, index) => {
    return cellData !== 0 ? (cellData / allDataArray[0] * 100).toFixed(2)+'%' : '';
  }

  const getPercentColour = (cellData, allDataArray, index) => {
    if (cellData === 0) {
      return null;
    }
    if (index === 0) {
      return { backgroundColor: "#11643F", color: "#FFF" }
    } else {
      const percentData = (cellData / allDataArray[0] * 100).toFixed(2);
      if (percentData > 90) {
        return { backgroundColor: "#11643F", color: "#FFF" }
      } else if (percentData <= 90 && percentData > 80) {
        return { backgroundColor: "#2C7554", color: "#FFF" }
      } else if (percentData <= 80 && percentData > 70) {
        return { backgroundColor: "#46876A", color: "#FFF" }
      } else if (percentData <= 70 && percentData > 60) {
        return { backgroundColor: "#548F75", color: "#FFF" }
      } else if (percentData <= 60 && percentData > 50) {
        return { backgroundColor: "#61987F", color: "#FFF" }
      } else if (percentData <= 50 && percentData > 40) {
        return { backgroundColor: "#89B29F", color: "#FFF" }
      } else if (percentData <= 40 && percentData > 30) {
        return { backgroundColor: "#A4C3B5", color: "#434343" }
      } else if (percentData <= 30 && percentData > 20) {
        return { backgroundColor: "#BFD4CA", color: "#434343" }
      } else if (percentData <= 20 && percentData > 10) {
        return { backgroundColor: "#D9E6E0", color: "#434343" }
      } else if (percentData <= 10 && percentData > 0) {
        return { backgroundColor: "#E7EEEA", color: "#434343" }
      }
    }
  }

  const getTrRowColour = (index) => {
    if (index === 0) {
      return { fontWeight: 'bold' };
    }
  }

  const getDataTable = (cohortRangeData) => {
    let dataAdjusted = [], eachDayArray = [];
    _.forEach(cohortRangeData, function(value) {
      _.forEach(value, function(value2, index) {
        if (index > 0) {
          eachDayArray.push(Number(value2));
        }
      });
      dataAdjusted.push(eachDayArray);
      eachDayArray = [];
    });

    const getDataNumUsers = (numUserData, allDataArray, index) => {
      const dataPassed = Number(numUserData);
      return dataPassed !== 0 ? H.FormatNumStrHelper.formatCommaThousand(numUserData) 
                              : index === allDataArray.indexOf(0) 
                                  ? ''
                                  : '';
    }

    return  <Fragment>
              {dataAdjusted.map((d, i) =>
              <tr style={getTrRowColour(i)} key={i}>
                {d.map((k, j) =>
                  <td style={getPercentColour(d[j], d, j)} className={classes.dataCellTd} key={j}>
                  <span className={classes.tableNumberData}>{getDataNumUsers(d[j], d, j)}</span><br />
                  {getDataPercent(d[j], d, j)}
                  </td>
                )}
              </tr >
              )}
            </Fragment>
  }

  const getCohortTitle = (focus) => {
    if (focus === 'jobtribes') {
        return "JobTribes";
    } else if (focus === 'nftdep') {
        return "PlayMining NFT";
    } else if (focus === 'puzzle') {
        return "Puzzle";
    } else if (focus === 'luckyfarmer') {
        return "Lucky Farmer";
    }
  }

  const getCohortData = () => {
    let exportData = [];
    let cohortExportData = _.result(
      _.find(cohortData, {
          id: cohortFocused,
      }),
      'data'
    );
    if (cohortExportData && cohortExportData.length > 0) {
      let headerArray = [""], k;
      let numOfHeaderData = cohortExportData[0].length;
      if (cohortSizeSelected.value === 'day') {
        for (let j=0; j<numOfHeaderData; j++) {
          if (j>0) {
            k = j-1;
            headerArray.push('Day'+k);
          }
        }
      } else if (cohortSizeSelected.value === 'week') {
        for (let j=0; j<numOfHeaderData; j++) {
          if (j>0) {
            k = j-1;
            headerArray.push('Week'+k);
          }
        }
      } else {
        for (let j=0; j<numOfHeaderData; j++) {
          if (j>0) {
            k = j-1;
            headerArray.push('Month'+k);
          }
        }
      }
      exportData.push(headerArray);
      for (let i=0; i<cohortExportData.length; i++) {
        exportData.push(cohortExportData[i]);
      }
    }
    return exportData;
  }

  const getCohortNftData = () => {
    let exportData = [];
    let cohortExportData = _.result(
      _.find(cohortNftData, {
          id: cohortFocused,
      }),
      'data'
    );
    if (cohortExportData && cohortExportData.length > 0) {
      let headerArray = [""], k;
      let numOfHeaderData = cohortExportData[0].length;
      if (cohortSizeSelected.value === 'day') {
        for (let j=0; j<numOfHeaderData; j++) {
          if (j>0) {
            k = j-1;
            headerArray.push('Day'+k);
          }
        }
      } else if (cohortSizeSelected.value === 'week') {
        for (let j=0; j<numOfHeaderData; j++) {
          if (j>0) {
            k = j-1;
            headerArray.push('Week'+k);
          }
        }
      } else {
        for (let j=0; j<numOfHeaderData; j++) {
          if (j>0) {
            k = j-1;
            headerArray.push('Month'+k);
          }
        }
      }
      exportData.push(headerArray);
      for (let i=0; i<cohortExportData.length; i++) {
        exportData.push(cohortExportData[i]);
      }
    }
    return exportData;
  }

  return (
    <>
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}>
          コホート分析（新規登録） {getCohortTitle(cohortFocused)}
        </div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getCohortData()}
              filename={"Cohort Data "+cohortFocused + " " + cohortSizeSelected.name}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
                CSV出力
            </Button>
          </CSVLink>          
        </div>
      </div>
      <div className={classes.tableContainer}>
      {pageTopLoader 
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
        : cohortRange.length > 1 ?
          <Fragment>
            <div className={classes.dateTableWrapper}>
              <table className={classes.leftTable}>
                <tbody>
                  <tr>
                    <td className={classes.tTdHeader}>
                      {/* <div className={classes.lblAllUser}>新規登録合計</div>
                      <div className={classes.lblUniqueUser}>
                        {getTotalUserInRange(cohortRange)}
                      </div> */}
                    </td>
                  </tr >
                  {getRangeTable(cohortRange, 'reg')}
                </tbody>
              </table>
            </div>

            <div className={classes.dataTableWrapper}>
              <table className={classes.dataTableBody}>
              <tbody>
                {getDataHeader(cohortSizeSelected, cohortRange)}
                {getDataTable(cohortRange)}
                </tbody>
              </table>
            </div>
          </Fragment>
          : <div className={classes.loaderBox}><C.NoData /></div>
        }
      </div>
    </Paper>
    <Paper className={classes.cohortTableBottom}>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}>
          コホート分析（NFT初購入） {getCohortTitle(cohortFocused)}
        </div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getCohortNftData()}
              filename={"Cohort Data "+cohortFocused + " " + cohortSizeSelected.name}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
                CSV出力
            </Button>
          </CSVLink>          
        </div>
      </div>
      <div className={classes.tableContainer}>
      {pageTopLoader 
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
        : cohortNftRange.length > 1 ?
          <Fragment>
            <div className={classes.dateTableWrapper}>
              <table className={classes.leftTable}>
                <tbody>
                  <tr>
                    <td className={classes.tTdHeader}></td>
                  </tr >
                  {getRangeTable(cohortNftRange, 'nft')}
                </tbody>
              </table>
            </div>

            <div className={classes.dataTableWrapper}>
              <table className={classes.dataTableBody}>
              <tbody>
                {getDataHeader(cohortSizeSelected, cohortNftRange)}
                {getDataTable(cohortNftRange)}
                </tbody>
              </table>
            </div>
          </Fragment>
          : <div className={classes.loaderBox}><C.NoData /></div>
        }
      </div>
    </Paper>   
    </>
  )
}

CohortPureTable.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
  cohortFocused: state.cohort.cohortFocused,
  cohortSizeSelected: state.cohort.cohortSizeSelected,
  cohortData: state.cohort.cohortData,
  cohortNftData: state.cohort.cohortNftData,
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(CohortPureTable);

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 400,
  },
  dateTableWrapper: {
    float: 'left',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblAllUser: {
    color: '#000',
    fontSize: 13,
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableWrapper: {
    display: 'block',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0, 
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'scroll',
  },
  dataCellTd: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0, 
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  },
  cohortTableBottom: {
    marginTop: 16,
  }
});