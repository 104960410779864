import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash';
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, TextField, Button, Grid, Chip, Box, Collapse, IconButton, Typography, Paper, Tooltip } from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { setCurrentPage, getNftHoldersData, setFilterDrawerState, setNftHoldersPageNumber,
    getLowTxCsvData, setLowTxCsvData } from 'actions';
import LocalCalendar from 'components/Calendar/LocalCalendar';
import * as C from 'components';
import * as V from 'styles/variables';
import * as H from 'helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        dataBtn: {
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        pageSection: {
            marginTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        statsSection: {
            minHeight: 60,
            padding: theme.spacing(2),
            borderRadius: 4,
        },
        statBox: {
            marginTop: 0,
            minHeight: 60,
            paddingLeft: 8,
            borderRight: '1px solid #dcdcdc',
            "&:last-child": {
                borderRight: 'none',
            },
        },
        statTitle: {
            fontSize: 13,
        },
        statValue: {
            fontSize: 15,
            paddingTop: 8,
            fontWeight: 500,
        },
        filterBtn: {
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
        },
        summaryCenter: {
            textAlign: 'center',
        },
        titleDl: {
            marginRight: 16,
            color: "#000",
        },
        tableHead: {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'normal',
            minWidth: 80,
            //height: 40,
        },
        tableBodyLeft: {
            textAlign: 'left',
        },
    }),
);

const NftHolders = ({
    setCurrentPage,
    currentPage,
    getNftHoldersData,
    nftHoldersData,
    holdersTotalCount,
    nftHoldersStatData,
    txHistoryDataFilter,
    pageTopLoader,
    setFilterDrawerState,
    filterDrawerState,
    setNftHoldersPageNumber,
    pageNumber,
    getLowTxCsvData,
    lowTxCsvData,
    lowTxCsvLoader,
    setLowTxCsvData,
}) => {
    const classes = useStyles();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [txRowHisotryData, setTxRowHisotryData] = useState([]);
    const [defaultDateRange, setDefaultDateRagne] = useState([
        moment().tz("Asia/Singapore").subtract(6, 'days').format('YYYY/MM/DD'),
        moment().tz("Asia/Singapore").format('YYYY/MM/DD')
    ]);
    const [totalPageNum, setTotalPageNum] = useState(0);
    const [isDownload, setIsDownload] = useState(false);
    const csvLinkRef = useRef(null);

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        getNftHoldersData(pageNumber);
    }, []);

    useEffect(() => {
        if (lowTxCsvData.length > 0) {
            csvLinkRef.current.link.click();
        }
    }, [lowTxCsvData]);

    useEffect(() => {
        const pageCount = holdersTotalCount / 20;
        setTotalPageNum(Math.ceil(pageCount));
    }, [holdersTotalCount]);

    useEffect(() => {
        if (nftHoldersData.length > 0) {
            let dataRows = [];
            _.each(nftHoldersData, function(dataRow) {
                let rowObj = {
                    "img_src": dataRow["asset_image"],
                    "name": dataRow["asset_name"],
                    "asset_id": dataRow["asset_id"],
                    "price": dataRow["price"],
                    "pmid": dataRow["pmid"],
                    "country": dataRow["country"],
                    "datetime": moment.unix(dataRow["datetime"]).tz("Asia/Singapore").format('lll')
                }
                dataRows.push(rowObj);
                rowObj = {};
            });
            setTxRowHisotryData(dataRows);
        }
    }, [nftHoldersData]);

    const handlePageChange = (event, value) => {
        setNftHoldersPageNumber(value);
        getNftHoldersData(value);
    };

    // const setRange = (newValue) => {
    //     const startDate = moment(newValue[0]).format('YYYY-MM-DD');
    //     const endDate = moment(newValue[1]).format('YYYY-MM-DD');
    //     let newFilter = [ ... txHistoryDataFilter ];
    //     newFilter[0]['dateRange'] = [startDate, endDate];
    //     setBtnState(false);
    // }

    const showData = (value) => {
        if (value === "--") {
            return value;
        }
        return H.FormatNumStrHelper.formatCommaThousand(value);
    }

    // const fetchData = () => {
    //     getNftHoldersData(pageNumber);
    //     setBtnState(!btnStateDataFetch);
    //     setLowTxCsvData([]);
    // }

    const options = {
        filterType: 'dropdown',
        filter: false,
        pagination: false,
        search: false,
        searchAlwaysOpen: false,
        selectableRowsHeader: false,
        selectableRowsHideCheckboxes: true,
        viewColumns: false,
        print: false,
        download: false,
        downloadOptions: {
            filename: 'ユーザーBANデータ',
            filterOptions: {
                useDisplayedColumnsOnly: true
            }
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            let cleanData = [], tempArray = [], tempObj = {};
            for (let i=0; i<data.length; i++) {
                tempArray = [];
                for (let j=0; j<data[i]['data'].length; j++) {
                    tempArray.push(data[i]['data'][j]);
                }
                tempObj.index = i;
                tempObj.data = tempArray;
                cleanData.push(tempObj);
                tempObj = {};
            }
            return "\uFEFF" + buildHead(columns) + buildBody(cleanData);
        },
        selectableRows: localStorage.getItem('access_key') !== process.env.REACT_APP_SUPERUSER ? false : 'multiple',
        rowsPerPage: 20,
        textLabels: {
            body: {
              noMatch: "データを取得中です。。",
              toolTip: "",
            },
            toolbar: {
                search: "検索",
                viewColumns: "列の表示",
                downloadCsv: "CSVダウンロード",
                filterTable: "テーブルのフィルター",
            },
            viewColumns: {
                title: "表示する列",
                titleAria: "テーブルの列の表示/非表示",
            },
            pagination: {
                next: "次のページ",
                previous: "前のページ",
                rowsPerPage: "表示件数",
            },
            filter: {
                all: "全て",
                title: "フィルター",
                reset: "リセット",
            },
            selectedRows: {
                text: "レコードを選択",
                delete: "削除",
                deleteAria: "選択したレコードを削除する",
            }
        },
        rowsPerPageOptions: [20,30,50,100]
    };

    let columns = [
        {
            name: "画像",
            options: {
                filter: false,
                sort: false,
                download: false,
                customHeadLabelRender: ({index, ...column}) => (
                    <th key={index} className={classes.tableHead}></th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <img src={value} width="80" />
                    );
                },
            }
        },
        {
            name: "アセット名",
            options: {
                filter: true,
                customHeadLabelRender: ({index, ...column}) => (
                    <th key={index} className={classes.tableHead}>アセット名</th>
                ),
                customFilterListRender: v => `アセット名: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.tableBodyLeft}>{value}</div>
                    );
                },
            }
        },
        {
            name: "アセットID",
            options: {
                filter: true,
                customHeadLabelRender: ({index, ...column}) => (
                    <th key={index} className={classes.tableHead}>アセットID</th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "価格 (DEP)",
            options: {
                filter: true,
                customFilterListOptions: {render: v => `価格 (DEP): ${v}`},
                customHeadLabelRender: ({index, ...column}) => (
                    <th key={index} className={classes.tableHead}>価格 (DEP)</th>
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{H.FormatNumStrHelper.formatCommaThousand(value)}</div>
                    );
                },
            }
        },
        {
            name: "PMID",
            options: {
                filter: true,
                sort: true,
                //setCellHeaderProps: () => ({ style: {display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse'}}),
                setCellHeaderProps: () => ({ style: {textAlign: 'center'}}),
                customFilterListRender: v => `Destinations: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
        {
            name: "国",
            options: {
                filter: true,
                sort: true,
                //setCellHeaderProps: () => ({ style: {display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse'}}),
                setCellHeaderProps: () => ({ style: {textAlign: 'center'}}),
                customFilterListRender: v => `Destinations: ${v}`,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={classes.summaryCenter}>
                            <Tooltip title={value}>
                                <img src={H.CountryHelper.getCountryCode(value)} />
                            </Tooltip>
                        </div>
                    );
                },
            }
        },
        {
            name: "保有日",
            options: {
                filter: false,
                setCellHeaderProps: () => ({ style: {display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse'}}),
                customBodyRender: (value) => {
                    return (
                        <div className={classes.summaryCenter}>{value}</div>
                    );
                },
            }
        },
    ];

    const getColumns = () => {
        let returningColumn = columns;
        if (localStorage.getItem('access_key') !== process.env.REACT_APP_SUPERUSER) {
            returningColumn.pop();
        }
        return returningColumn;
    }

    const getTableData = (data) => {
        if (data.length > 0) {
            let allDataArray = [], eachDataArray = [];
            for (let i=0; i<data.length; i++) {
                eachDataArray.push(data[i]['img_src']);
                eachDataArray.push(data[i]['name']);
                eachDataArray.push(data[i]['asset_id']);
                eachDataArray.push(data[i]['price']);
                eachDataArray.push(data[i]['pmid']);
                eachDataArray.push(data[i]['country']);
                eachDataArray.push(data[i]['datetime']);
                allDataArray.push(eachDataArray);
                eachDataArray = [];
            }
            return allDataArray;
        }
    };

    return (
        <C.ContentWrapper>
            {/* <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="flex-end">
                        <Grid item>
                            <LocalCalendar
                                setRange={setRange}
                                setBtnState={setBtnState}
                                defaultRange={defaultDateRange}
                                newRange={[
                                    txHistoryDataFilter[0]['dateRange'][0],
                                    txHistoryDataFilter[0]['dateRange'][1],
                                ]}
                            />
                        </Grid>
                        <Grid item>
                            <Button 
                                //disabled={btnStateDataFetch} 
                                className={classes.dataBtn} 
                                variant="contained" 
                                color="primary" 
                                onClick={() => fetchData()}>
                                GO
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button 
                                //disabled={btnStateDataFetch} 
                                className={classes.filterBtn} 
                                variant="contained" 
                                color="default" 
                                onClick={() => setFilterDrawerState(!filterDrawerState ? currentPage : false )}>
                                絞り込み
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}

            <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <Paper className={classes.statsSection}>
                        <Grid container direction="row" className={classes.gridSection} justifyContent="flex-start">
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4} lg={2}>
                                <Grid container direction="row" className={classes.gridSection} justifyContent="space-between">
                                    <Grid item>
                                        <div className={classes.statTitle}>合計件数:</div>
                                    </Grid>
                                    {/* <Grid item>
                                        {lowTxCsvLoader && 
                                            <CircularProgress className={classes.titleDl} size={20} />
                                        }
                                        {!lowTxCsvLoader &&
                                            <>
                                            <IconButton onClick={() => getLowTxCsvData()} className={classes.titleDl} size="small" aria-label="download csv">
                                                <GetAppIcon />
                                            </IconButton>
                                            </>
                                        }
                                        <CSVLink
                                            data={lowTxCsvData}
                                            filename='KamuiVerse NFT保有者データ.csv'
                                            className='hidden'
                                            ref={csvLinkRef}
                                            target='_blank'
                                        />                                        
                                    </Grid> */}
                                </Grid>                                
                                <div className={classes.statValue}>{showData(nftHoldersStatData[0]['totalHolders'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>保有ユニークPMID数:</div>
                                <div className={classes.statValue}>{showData(nftHoldersStatData[0]['uniqueHolders'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>DEP総額:</div>
                                <div className={classes.statValue}>{showData(nftHoldersStatData[0]['totalDep'])}</div>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <MUIDataTable
                        data={getTableData(txRowHisotryData)}
                        columns={getColumns()}
                        options={options}
                    />
                </Grid>
            </Grid>

            {totalPageNum > 0 &&
            <Grid container spacing={1} className={classes.pageSection} alignItems="center" justifyContent="center">
                <Pagination count={totalPageNum} page={pageNumber} onChange={handlePageChange} />
            </Grid>    
            }
        </C.ContentWrapper>
    );
};

NftHolders.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    pageNumber: state.nftHolders.pageNumber,
    nftHoldersData: state.nftHolders.nftHoldersData,
    holdersTotalCount: state.nftHolders.holdersTotalCount,
    nftHoldersStatData: state.nftHolders.nftHoldersStatData,

    txHistoryDataFilter: state.nftDepTxHisotry.txHistoryDataFilter,
    pageTopLoader: state.page.pageTopLoader,
    filterDrawerState: state.page.filterDrawerState,
    currentPage: state.page.currentPage,
    lowTxCsvData: state.nftDepTxHisotry.lowTxCsvData,
    lowTxCsvLoader: state.nftDepTxHisotry.lowTxCsvLoader,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getNftHoldersData: (param) => dispatch(getNftHoldersData(param)),
    setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
    setNftHoldersPageNumber: (param) => dispatch(setNftHoldersPageNumber(param)),
    getLowTxCsvData: () => dispatch(getLowTxCsvData()),
    setLowTxCsvData: (param) => dispatch(setLowTxCsvData(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(NftHolders));
