import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getOverviewDataByGame } from 'actions';
import * as C from 'components';
import * as H from 'helper';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: '#fafafa',
        },
    }),
);

const ByGame = ({
    dateRange,
    dateLoaded,
    setOverviewTab,
    getOverviewDataByGame,
}) => {
    const classes = useStyles();

    useEffect(() => {
        setOverviewTab("game");
        if (dateLoaded['nftdep-overview-game'] !== undefined) {
            const formatDateRagne = H.FormatDateHelper.formatDateRangeWithDash(dateRange);
            if (!_.isEqual(formatDateRagne, dateLoaded['nftdep-overview-game'])) {
                getOverviewDataByGame();
            }
        } else {
            getOverviewDataByGame();
        }
    }, []);

    return (
        <Fragment>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別オークション取引総額"
                        source="auction"
                        metric="amount"
                        metricLabel="取引総額"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別オークション取引件数"
                        source="auction"
                        metric="count"
                        metricLabel="取引件数"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別DEP ストア取引総額"
                        source="store"
                        metric="amount"
                        metricLabel="取引総額"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別DEP ストア取引件数"
                        source="store"
                        metric="count"
                        metricLabel="取引件数"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別FIAT ストア取引総額"
                        source="fiat"
                        metric="amount"
                        metricLabel="取引総額"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別FIAT ストア取引件数"
                        source="fiat"
                        metric="count"
                        metricLabel="取引件数"
                    />
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別ユニークPMIDオークション取引件数"
                        source="auction"
                        metric="pmid"
                        metricLabel="PMID数"
                    />
                </Grid>
                <Grid item sm={12} lg={6}>
                    <C.Top10AmountByGame
                        title="ゲーム別ユニークPMIDストア取引件数"
                        source="store"
                        metric="pmid"
                        metricLabel="PMID数"
                    />
                </Grid>
            </Grid> */}
        </Fragment>
    );
};

ByGame.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    dateLoaded: state.data.dataLoaded,
});

const mapDispatchToProps = dispatch => ({
    setOverviewTab: (param) => dispatch(setOverviewTab(param)),
    getOverviewDataByGame: () => dispatch(getOverviewDataByGame()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ByGame));
