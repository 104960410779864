import { CAMPAIGN } from '../constants';
import moment from "moment";

const rangeStartCpDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD');
const rangeEndCpDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');

const initialState  = {
    campaignCode: false,
    campaignName: '',
    campaignEmail: '',
    campaignType: '',
    campaignGame: '',
    campaignGroup: [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}],
    campaignGroupSelected: [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}],
    campaignMedia: [],
    campaignFT: 0,
    campaignReg: 0,
    campaignConv: 0,
    campaignListData: [],
    campaignDataLoadingById: null,
    campaignIdToFetch: 0,
    campaignStatsById: [],
    campaignTrendFetchRid: null,
    campaignFirstTimeDataByRid: [],
    campaignGameVisitDataByRid: [],
    campaignRegistDataByRid: [],
    trendDataLoading: false,
    trendChartData: [],
    campaignListDataLoading: false,
    campaignDateRange: [rangeStartCpDate, rangeEndCpDate],
    campaignPreDefinedDateRange: 7,
    campaignViewStats: {
        firstTime: '--',
        gameVisit: '--',
        regist: '--'
    },
    campaignSelectedStats:[],
};

export default function campaignReducer(state = initialState, action) {
    switch (action.type) {
        case CAMPAIGN.GET_CAMPAIGN_LIST_DATA_BY_KEY:
            return {
                ...state,
                allBIUsers: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_LIST_DATA:
            return {
                ...state,
                campaignListData: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_DATA_LOAD_BY_ID:
            return {
                ...state,
                campaignDataLoadingById: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_ID_TO_FETCH:
            return {
                ...state,
                campaignIdToFetch: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_STAT_BY_ID:
            return {
                ...state,
                campaignStatsById: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_TREND_FETCH_RID:
            return {
                ...state,
                campaignTrendFetchRid: action.payload
            };
        case CAMPAIGN.SET_TREND_FIRST_TIME_BY_RID:
            return {
                ...state,
                campaignFirstTimeDataByRid: action.payload
            };
        case CAMPAIGN.SET_TREND_GAME_VISIT_BY_RID:
            return {
                ...state,
                campaignGameVisitDataByRid: action.payload
            };
        case CAMPAIGN.SET_TREND_REGIST_BY_RID:
            return {
                ...state,
                campaignRegistDataByRid: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_TREND_DATA_LOADING_BY_RID:
            return {
                ...state,
                trendDataLoading: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_TREND_CHART_DATA:
            return {
                ...state,
                trendChartData: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_DATE_RANGE:
            return {
                ...state,
                campaignDateRange: action.payload
            };
        case CAMPAIGN.SET_PREDEFINED_CAMPAIGN_DATE_RANGE:
            return {
                ...state,
                campaignPreDefinedDateRange: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_VIEW_STATS:
            return {
                ...state,
                campaignViewStats: action.payload
            };
        case CAMPAIGN.SET_CAMPAIGN_SELECTED_STATS:
            return {
                ...state,
                campaignSelectedStats: action.payload
            };
        default:
            return state
    }
};