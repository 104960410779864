import { NFTDEP } from '../../constants';
import moment from "moment";

const initialState  = {
    loading: {
        ovByCountry: false,
        ovByPmid: false,
        ovByGame: false,
        ovByRid: false,
    },
    tabFocused: "country",
    focusTabIndex: 0,
    dataByCountry: {
        amount: {
            auction: [],
            store: [],
            fiat: [],
        },
        count: {
            auction: [],
            store: [],
            fiat: [],
        },
    },
    dataByPmid: {
        amount: {
            auction: [],
            store: [],
            fiat: [], 
        },
        count: {
            auction: [],
            store: [],
            fiat: [],
        },
    },
    dataByGame: {
        amount: {
            auction: [],
            store: [],
            fiat: [], 
        },
        count: {
            auction: [],
            store: [],
            fiat: [],
        }
    },
    dataByRid: {
        jtcb: {
            auction: [],
            store: [],
            fiat: [],
        },
        luckyfarmer: {
            auction: [],
            store: [],
            fiat: [],
        },
        puzzle: {
            auction: [],
            store: [],
            fiat: [],
        }
    },
    dataTxBlind: {
        count: {
            current: '-',
            prev: '-'
        },
        price: {
            current: '-',
            prev: ''
        },
        fees: {
            current: '-',
            prev: ''
        }
    },
    dataTxSnap: {
        count: {
            current: '-',
            prev: '-'
        },
        price: {
            current: '-',
            prev: ''
        },
        fees: {
            current: '-',
            prev: ''
        }
    },
    dataStakingOvCount: [],
    dataStakingOvDep: [],
};

export default function nftDepReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_DATA_LOADING_FOR_BY:
            return {
                ...state,
                loading: action.payload
            };
        case NFTDEP.SET_OVERVIEW_TAB:
            return {
                ...state,
                tabFocused: action.payload
            };
        case NFTDEP.SET_FOCUS_TAB_INDEX:
            return {
                ...state,
                focusTabIndex: action.payload
            };
        case NFTDEP.SET_DATA_BY_COUNTRY:
            return {
                ...state,
                dataByCountry: action.payload
            };
        case NFTDEP.SET_DATA_BY_PMID:
            return {
                ...state,
                dataByPmid: action.payload
            };
        case NFTDEP.SET_DATA_BY_GAME:
            return {
                ...state,
                dataByGame: action.payload
            };
        case NFTDEP.SET_DATA_BY_RID:
            return {
                ...state,
                dataByRid: action.payload
            };
        case NFTDEP.SET_DATA_TX_BLIND:
            return {
                ...state,
                dataTxBlind: action.payload
            };
        case NFTDEP.SET_DATA_TX_SNAP:
            return {
                ...state,
                dataTxSnap: action.payload
            };
        case NFTDEP.SET_DATA_STAKING_OV_COUNT:
            return {
                ...state,
                dataStakingOvCount: action.payload
            };
        case NFTDEP.SET_DATA_STAKING_OV_DEP:
            return {
                ...state,
                dataStakingOvDep: action.payload
            };
        default:
            return state
    }
}
