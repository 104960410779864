// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import * as H from "helper";

const Top10AmountByPmid = ({
  title,
  source,
  loading,
  dataByPmid,
  selectedCurrency,
  depPrice,
  dateRange,
  dataInGameItemByPmid,
}) => {
  const [pmidData, setPmidData] = useState([]);

  useEffect(() => {
    let dataSource = [], totalNum = 0;
    switch (source) {
        case "auction":
            dataSource = dataByPmid.amount.auction;
            break;
        case "store":
            dataSource = dataByPmid.amount.store;
            break;
        case "fiat":
            dataSource = dataByPmid.amount.fiat;
            break;
        case "ingame-fiat":
            dataSource = dataInGameItemByPmid.amount.fiat;
            break;
        case "ingame-dep":
            dataSource = dataInGameItemByPmid.amount.dep;
            break;
      default:
    }

    switch (source) {
        case "auction":
            totalNum = dataByPmid.auction_ttl_amount;
            break;
        case "store":
            totalNum = dataByPmid.store_ttl_amount;
            break;
        // case "fiat":
        //     totalNum = dataByPmid.amount.fiat;
        //     break;
        case "ingame-fiat":
            totalNum = _.sumBy(dataSource, (data) => {
                return data.value;
            });
            break;
        case "ingame-dep":
            totalNum = _.sumBy(dataSource, (data) => {
              return data.value;
            });
            break;
      default:
    }

    let dataArray = [],
      dataObj = {};
    for (let i = 0; i < dataSource.length; i++) {
      dataObj.no = i + 1;
      dataObj.url =
        dataSource[i]["country"] === "(not set)" ||
        dataSource[i]["country"] === "n/a"
          ? false
          : H.CountryHelper.getCountryCode(dataSource[i]["country"]);
      dataObj.pmid = dataSource[i]["pmid"];
      dataObj.country = dataSource[i]["country"];
      dataObj.tx_count = dataSource[i]["tx"];
      dataObj.num_amount =
        selectedCurrency === "dep"
          ? dataSource[i]["value"]
          : calcConvert(selectedCurrency, dataSource[i]["value"]);
      dataObj.num_ratio = ((dataSource[i]["value"] / totalNum) * 100).toFixed(
        2
      );
      dataArray.push(dataObj);
      dataObj = {};
    }
    setPmidData(dataArray);
  }, [dataByPmid, selectedCurrency, dataInGameItemByPmid]);

  const getCurrencyLbl = (selectedCurrency) => {
    let lblToReturn = "DEP";
    if (selectedCurrency === "usd") {
      lblToReturn = lblToReturn + " (US$)";
    } else if (selectedCurrency === "sgd") {
      lblToReturn = lblToReturn + " (S$)";
    } else if (selectedCurrency === "jpy") {
      lblToReturn = lblToReturn + " (¥)";
    }
    return lblToReturn;
  };

  const calcConvert = (currency, amount) => {
    let amtReturn = 0;
    if (currency === "usd") {
      amtReturn = amount * depPrice.usd;
    } else if (currency === "sgd") {
      amtReturn = amount * depPrice.sgd;
    } else if (currency === "jpy") {
      amtReturn = amount * depPrice.jpy;
    }
    return amtReturn;
  };

  return (
    <MaterialTable
      isLoading={loading !== undefined ? loading.ovByPmid : false}
      style={{ padding: 16, fontSize: 14 }}
      title={
        <div style={{ fontSize: 18, fontWeight: 500, color: "#000" }}>
          {title}
        </div>
      }
      columns={[
        {
          title: "#",
          field: "no",
          width: 50,
          cellStyle: { textAlign: "center" },
        },
        {
          title: "PMID",
          field: "pmid",
          width: 100,
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex" }}>
                <div>
                  {rowData.url && (
                    <Tooltip title={rowData.country}>
                      <img
                        src={rowData.url}
                        style={{ paddingTop: 2, paddingRight: 8, width: 24 }}
                      />
                    </Tooltip>
                  )}
                </div>
                <div style={{ paddingTop: 2 }}>{rowData.pmid}</div>
              </div>
            </Fragment>
          ),
        },
        {
          title: "件",
          field: "tx_count",
          width: 80,
          type: "numeric",
          cellStyle: { textAlign: "center" },
          render: (rowData) => (
            <div>
              {H.FormatNumStrHelper.formatCommaThousand(rowData.tx_count)}
            </div>
          ),
        },
        {
          title: getCurrencyLbl(selectedCurrency),
          field: "num_amount",
          width: 200,
          type: "numeric",
          cellStyle: { textAlign: "center" },
          render: (rowData) => (
            <div>
              {H.FormatNumStrHelper.formatCommaThousand(rowData.num_amount)}
            </div>
          ),
        },
        {
          title: "全体比率",
          field: "num_ratio",
          type: "numeric",
          cellStyle: { textAlign: "left", marginLeft: 32 },
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex", height: 16 }}>
                <div style={{ paddingRight: 16, width: 24 }}>
                  {rowData.num_ratio}%
                </div>
                <div
                  style={{
                    backgroundColor: "#65C9A5",
                    width: rowData.num_ratio + "%",
                    marginLeft: 20,
                    marginTop: 3,
                  }}
                ></div>
              </div>
            </Fragment>
          ),
        },
      ]}
      data={pmidData}
      options={{
        search: false,
        paging: false,
        sorting: true,
        exportButton: { csv: true },
        exportFileName:
          source === "auction"
            ? `${dateRange[0]}-${dateRange[1]} ${title}`
            : `${dateRange[0]}-${dateRange[1]} ${title}`,
        rowStyle: {
          textAlign: "center",
        },
        headerStyle: { textAlign: "center" },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "表示可能なデータがありません",
        },
      }}
    />
  );
};

Top10AmountByPmid.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  // loading: state.nftDepOverview.loading,
  dataByPmid: state.nftDepOverview.dataByPmid,
  selectedCurrency: state.page.selectedCurrency,
  depPrice: state.page.depPrice,
  dateRange: state.page.dateRange,
  dataInGameItemByPmid: state.inGameItemSales.dataByPmid,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Top10AmountByPmid));
