import CountryCode from "utils/CountryCode";

const DataHelper = {
  getMedianValue: function (dataArray) {
    const sorted = Array.from(dataArray).sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);

    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }

    return sorted[middle];
  },

  getModeValue: function (array) {
    let frequency = []; // array of frequency.
    let maxFreq = 0; // holds the max frequency.
    let modes = [];

    for (let i in array) {
      frequency[array[i]] = (frequency[array[i]] || 0) + 1; // increment frequency.

      if (frequency[array[i]] > maxFreq) {
        // is this frequency > max so far ?
        maxFreq = frequency[array[i]]; // update max.
      }
    }

    for (let k in frequency) {
      if (frequency[k] == maxFreq) {
        modes.push(k);
      }
    }

    return modes;
  },
};

export default DataHelper;
