import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Card, CardContent, CardHeader } from '@material-ui/core';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import ChartStyle from 'styles/ChartStyle';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage } from 'actions';
import * as C from 'components';
import { trafficData, gameColor, trafficData202212 } from 'utils/chartData';

// games
const allGames = [ "PlayMining", "Axie", "Decentraland", "Sandbox", "GALA", "WAX", "illuvium", "yieldguild" ];
const allGames2 = [ "PlayMining", "Axie", "Decentraland", "Sandbox", "GALA", "WAX", "illuvium", "yieldguild", 
                    "captsuba", "digidaigaku", "stepn", "splinterlands" ];

const Lab2 = ({
    pageTopLoader,
    setCurrentPage,
}) => {
  const classes = useStyles();
  const [countryDisplay, setCountryDisplay] = useState([]);
  const [countryDisplay2, setCountryDisplay2] = useState([]);
  const [sourceDisplay, setSourceDisplay] = useState([]);
  const [sourceDisplay2, setSourceDisplay2] = useState([]);
  const [legendState, setLegendState] = useState({
    PlayMining: true, Axie: true, Decentraland: true, Sandbox: true,
    GALA: true, WAX: true, illuvium: true, yieldguild: true
  });
  const [legendStateSource, setLegendStateSource] = useState({
    PlayMining: true, Axie: true, Decentraland: true, Sandbox: true,
    GALA: true, WAX: true, illuvium: true, yieldguild: true
  });
  const [legendState2, setLegendState2] = useState({
    PlayMining: true, Axie: true, Decentraland: true, Sandbox: true,
    GALA: true, WAX: true, illuvium: true, yieldguild: true,
    captsuba: true, digidaigaku: true, stepn: true, splinterlands: true,
  });
  const [legendStateSource2, setLegendStateSource2] = useState({
    PlayMining: true, Axie: true, Decentraland: true, Sandbox: true,
    GALA: true, WAX: true, illuvium: true, yieldguild: true,
    captsuba: true, digidaigaku: true, stepn: true, splinterlands: true,
  });

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getAllCountryDisplay();
    getAllCountryDisplay2();
    getAllSourceDisplay();
    getAllSourceDisplay2();
    echarts.registerTheme('BarChartTheme', ChartStyle);
  }, []);

  useEffect(() => {
    getAllCountryDisplay();
  }, [legendState]);

  useEffect(() => {
    getAllCountryDisplay2();
  }, [legendState2]);

  useEffect(() => {
    getAllSourceDisplay();
  }, [legendStateSource]);

  useEffect(() => {
    getAllSourceDisplay2();
  }, [legendStateSource2]);

  const convertTraffaicShareToNumber = (totalNumber, dataArray) => {
    let returnArray = [];
    _.forEach(dataArray, function(value) {
      let eachObj = {
        country: value.country,
        value: Number((value.value * totalNumber).toFixed(0))
      }
      returnArray.push(eachObj);
    });
    return returnArray;
  };

  const convertTraffaicSourceToNumber = (totalNumber, dataArray) => {
    let returnArray = [];
    _.forEach(dataArray, function(value) {
      let eachObj = {
        source: value.source,
        value: Number((value.value * totalNumber).toFixed(0))
      }
      returnArray.push(eachObj);
    });
    return returnArray;
  };

  const getAllCountryOrder = (allTrafficData) => {
    let returnArray = [];
    _.each(allTrafficData, function(value, key) {
      if (returnArray.indexOf(value.country === -1))
        returnArray.push(value.country);
    });
    return returnArray.reverse();
  }

  const getAllSourceOrder = (allTrafficData) => {
    let returnArray = [];
    _.each(allTrafficData, function(value, key) {
      if (returnArray.indexOf(value.source === -1))
        returnArray.push(value.source);
    });
    return returnArray.reverse();
  }

  const getPmCountryDisplay = (yyyymm) => {
    const pmTotalData = yyyymm === 202212 ? trafficData202212.pmTotal : trafficData.pmTotal;
    const pmCountryData = yyyymm === 202212 ? trafficData202212.jtcbCountry : trafficData.jtcbCountry;
    const pmCountryNum = convertTraffaicShareToNumber(pmTotalData, pmCountryData);
    const dataAllDappsTrafficByCountry = _(pmCountryNum)
      .groupBy('country')
      .map((country, value) => ({
        country: value,
        value: _.sumBy(country, 'value')
      }))
      .orderBy("value", ['desc'])
    .value();
    const countryArray = getAllCountryOrder(dataAllDappsTrafficByCountry);
    return countryArray;
  };

  const getAllCountryDisplay = () => {
    const pmCountryNum = legendState["PlayMining"] ? convertTraffaicShareToNumber(trafficData.pmTotal, trafficData.jtcbCountry) : [];
    const dcCountryNum = legendState["Decentraland"] ? convertTraffaicShareToNumber(trafficData.dlTotal, trafficData.decentralandCountry) : [];
    const sbCountryNum = legendState["Sandbox"] ? convertTraffaicShareToNumber(trafficData.sbTotal, trafficData.sandboxCountry) : [];
    const axieCountryNum = legendState["Axie"] ? convertTraffaicShareToNumber(trafficData.axTotal, trafficData.axieCountry) : [];
    const glCountryNum = legendState["GALA"] ? convertTraffaicShareToNumber(trafficData.glTotal, trafficData.glCountry) : [];
    const waxCountryNum = legendState["WAX"] ? convertTraffaicShareToNumber(trafficData.waxTotal, trafficData.waxCountry) : [];
    const illvCountryNum = legendState["illuvium"] ? convertTraffaicShareToNumber(trafficData.illvTotal, trafficData.illvCountry) : [];
    const gldCountryNum = legendState["yieldguild"] ? convertTraffaicShareToNumber(trafficData.gldTotal, trafficData.gldCountry) : [];

    const unionArray = _.union(
      pmCountryNum, dcCountryNum, sbCountryNum, axieCountryNum,
      glCountryNum, waxCountryNum, illvCountryNum, gldCountryNum
    );
  
    const dataAllDappsTrafficByCountry = _(unionArray)
      .groupBy('country')
      .map((country, value) => ({
        country: value,
        value: _.sumBy(country, 'value')
      }))
      .orderBy("value", ['desc'])
    .value();
    const countryArray = getAllCountryOrder(dataAllDappsTrafficByCountry);
    setCountryDisplay(countryArray);
  };

  const getAllCountryDisplay2 = () => {
    const pmCountryNum = legendState["PlayMining"] ? convertTraffaicShareToNumber(trafficData202212.pmTotal, trafficData202212.jtcbCountry) : [];
    const dcCountryNum = legendState["Decentraland"] ? convertTraffaicShareToNumber(trafficData202212.dlTotal, trafficData202212.decentralandCountry) : [];
    const sbCountryNum = legendState["Sandbox"] ? convertTraffaicShareToNumber(trafficData202212.sbTotal, trafficData202212.sandboxCountry) : [];
    const axieCountryNum = legendState["Axie"] ? convertTraffaicShareToNumber(trafficData202212.axTotal, trafficData202212.axieCountry) : [];
    const glCountryNum = legendState["GALA"] ? convertTraffaicShareToNumber(trafficData202212.glTotal, trafficData202212.glCountry) : [];
    const waxCountryNum = legendState["WAX"] ? convertTraffaicShareToNumber(trafficData202212.waxTotal, trafficData202212.waxCountry) : [];
    const illvCountryNum = legendState["illuvium"] ? convertTraffaicShareToNumber(trafficData202212.illvTotal, trafficData202212.illvCountry) : [];
    const gldCountryNum = legendState["yieldguild"] ? convertTraffaicShareToNumber(trafficData202212.gldTotal, trafficData202212.gldCountry) : [];
    const captsubaCountryNum = legendState["captsuba"] ? convertTraffaicShareToNumber(trafficData202212.captsubaTotal, trafficData202212.captsubaCountry) : [];
    const digidaigakuCountryNum = legendState["digidaigaku"] ? convertTraffaicShareToNumber(trafficData202212.digidaigakuTotal, trafficData202212.digidaigakuCountry) : [];
    const stepnCountryNum = legendState["stepn"] ? convertTraffaicShareToNumber(trafficData202212.stepnTotal, trafficData202212.stepnCountry) : [];
    const splinterlandsCountryNum = legendState["splinterlands"] ? convertTraffaicShareToNumber(trafficData202212.splinterlandsTotal, trafficData202212.splinterlandsCountry) : [];

    const unionArray = _.union(
      pmCountryNum, dcCountryNum, sbCountryNum, axieCountryNum,
      glCountryNum, waxCountryNum, illvCountryNum, gldCountryNum,
      captsubaCountryNum, digidaigakuCountryNum, stepnCountryNum, splinterlandsCountryNum,
    );
  
    const dataAllDappsTrafficByCountry = _(unionArray)
      .groupBy('country')
      .map((country, value) => ({
        country: value,
        value: _.sumBy(country, 'value')
      }))
      .orderBy("value", ['desc'])
    .value();
    const countryArray = getAllCountryOrder(dataAllDappsTrafficByCountry);
    setCountryDisplay2(countryArray);
  };
  
  const getAllSourceDisplay = () => {
    const pmSourceNum = legendStateSource["PlayMining"] ? convertTraffaicSourceToNumber(trafficData.pmTotal, trafficData.jtcbSource ) : [];
    const dcSourceNum = legendStateSource["Decentraland"] ? convertTraffaicSourceToNumber(trafficData.dlTotal, trafficData.decentralandSource ) : [];
    const sbSourceNum = legendStateSource["Sandbox"] ? convertTraffaicSourceToNumber(trafficData.sbTotal, trafficData.sandboxSource ) : [];
    const axieSourceNum = legendStateSource["Axie"] ? convertTraffaicSourceToNumber(trafficData.axTotal, trafficData.axieSource ) : [];
    const glSourceNum = legendStateSource["GALA"] ? convertTraffaicSourceToNumber(trafficData.glTotal, trafficData.glSource) : [];
    const waxSourceNum = legendStateSource["WAX"] ? convertTraffaicSourceToNumber(trafficData.waxTotal, trafficData.waxSource) : [];
    const illvSourceNum = legendStateSource["illuvium"] ? convertTraffaicSourceToNumber(trafficData.illvTotal, trafficData.illvSource) : [];
    const gldSourceNum = legendStateSource["yieldguild"] ? convertTraffaicSourceToNumber(trafficData.gldTotal, trafficData.gldSource) : [];

    const unionArray = _.union(
      pmSourceNum, dcSourceNum, sbSourceNum, axieSourceNum,
      glSourceNum, waxSourceNum, illvSourceNum, gldSourceNum
    );
  
    const dataAllDappsTrafficSource = _(unionArray)
      .groupBy('source')
      .map((source, value) => ({
        source: value,
        value: _.sumBy(source, 'value')
      }))
      .orderBy("value", ['desc'])
    .value();
    const sourceArray = getAllSourceOrder(dataAllDappsTrafficSource);
    setSourceDisplay(sourceArray);
  };

  const getAllSourceDisplay2 = () => {
    const pmSourceNum = legendStateSource["PlayMining"] ? convertTraffaicSourceToNumber(trafficData202212.pmTotal, trafficData202212.jtcbSource ) : [];
    const dcSourceNum = legendStateSource["Decentraland"] ? convertTraffaicSourceToNumber(trafficData202212.dlTotal, trafficData202212.decentralandSource ) : [];
    const sbSourceNum = legendStateSource["Sandbox"] ? convertTraffaicSourceToNumber(trafficData202212.sbTotal, trafficData202212.sandboxSource ) : [];
    const axieSourceNum = legendStateSource["Axie"] ? convertTraffaicSourceToNumber(trafficData202212.axTotal, trafficData202212.axieSource ) : [];
    const glSourceNum = legendStateSource["GALA"] ? convertTraffaicSourceToNumber(trafficData202212.glTotal, trafficData202212.glSource) : [];
    const waxSourceNum = legendStateSource["WAX"] ? convertTraffaicSourceToNumber(trafficData202212.waxTotal, trafficData202212.waxSource) : [];
    const illvSourceNum = legendStateSource["illuvium"] ? convertTraffaicSourceToNumber(trafficData202212.illvTotal, trafficData202212.illvSource) : [];
    const gldSourceNum = legendStateSource["yieldguild"] ? convertTraffaicSourceToNumber(trafficData202212.gldTotal, trafficData202212.gldSource) : [];
    const captsubaSourceNum = legendStateSource["captsuba"] ? convertTraffaicSourceToNumber(trafficData202212.captsubaTotal, trafficData202212.captsubaSource) : [];
    const digidaigakuSourceNum= legendStateSource["digidaigaku"] ? convertTraffaicSourceToNumber(trafficData202212.digidaigakuTotal, trafficData202212.digidaigakuSource) : [];
    const stepnSourceNum = legendStateSource["stepn"] ? convertTraffaicSourceToNumber(trafficData202212.stepnTotal, trafficData202212.stepnSource) : [];
    const splinterlandsSourceNum = legendStateSource["splinterlands"] ? convertTraffaicSourceToNumber(trafficData202212.splinterlandsTotal, trafficData202212.splinterlandsSource) : [];

    const unionArray = _.union(
      pmSourceNum, dcSourceNum, sbSourceNum, axieSourceNum,
      glSourceNum, waxSourceNum, illvSourceNum, gldSourceNum,
      captsubaSourceNum, digidaigakuSourceNum, stepnSourceNum, splinterlandsSourceNum
    );
  
    const dataAllDappsTrafficSource = _(unionArray)
      .groupBy('source')
      .map((source, value) => ({
        source: value,
        value: _.sumBy(source, 'value')
      }))
      .orderBy("value", ['desc'])
    .value();
    const sourceArray = getAllSourceOrder(dataAllDappsTrafficSource);
    setSourceDisplay2(sourceArray);
  };
  
  const getEachGameData = (countryNumData, game, yyyymm) => {
    let dataToReturn = [];
    const legendToUse = yyyymm === 202212 ? legendState2 : legendState;
    const dataToLoop = yyyymm === 202212 ? countryDisplay2 : countryDisplay;
    _.each(dataToLoop, function(value) {
      if(_.find(countryNumData, {country: value})) {
        let visitNum = _.find(countryNumData, {country: value}).value;
        if (legendToUse[game]) {
          dataToReturn.push(visitNum);
        } else {
          dataToReturn.push(0);
        }
      } else {
        dataToReturn.push(0);
      }
    });
    return dataToReturn;
  };

  const getEachGameSourceData = (countryNumData, game, yyyymm) => {
    let dataToReturn = [];
    const legendToUse = yyyymm === 202212 ? legendState2 : legendStateSource;
    const dataToLoop = yyyymm === 202212 ? sourceDisplay2 : sourceDisplay;
    _.each(dataToLoop, function(value) {
      if(_.find(countryNumData, {source: value})) {
        let visitNum = _.find(countryNumData, {source: value}).value;
        if (legendToUse[game]) {
          dataToReturn.push(visitNum);
        } else {
          dataToReturn.push(0);
        }
      } else {
        dataToReturn.push(0);
      }
    });
    return dataToReturn;
  };

  const getTrafficDataByGame = (game, yyyymm) => {
    let dataToReturn = [];
    const dataToRead = yyyymm === 202212 ? trafficData202212 : trafficData;
    switch(game) {
      case "PlayMining" :
        const pmCountryNum = convertTraffaicShareToNumber(dataToRead.pmTotal, dataToRead.jtcbCountry);
        dataToReturn = getEachGameData(pmCountryNum, game, yyyymm);
        break;
      case "Decentraland" :
        const dcCountryNum = convertTraffaicShareToNumber(dataToRead.dlTotal, dataToRead.decentralandCountry);
        dataToReturn = getEachGameData(dcCountryNum, game, yyyymm);
        break;
      case "Sandbox" :
        const sbCountryNum = convertTraffaicShareToNumber(dataToRead.sbTotal, dataToRead.sandboxCountry);
        dataToReturn = getEachGameData(sbCountryNum, game, yyyymm);
        break;
      case "Axie" :
        const axieCountryNum = convertTraffaicShareToNumber(dataToRead.axTotal, dataToRead.axieCountry);
        dataToReturn = getEachGameData(axieCountryNum, game, yyyymm);
        break;
      case "GALA" :
        const glCountryNum = convertTraffaicShareToNumber(dataToRead.glTotal, dataToRead.glCountry);
        dataToReturn = getEachGameData(glCountryNum, game, yyyymm);
        break;
      case "WAX" :
        const waxCountryNum = convertTraffaicShareToNumber(dataToRead.waxTotal, dataToRead.waxCountry);
        dataToReturn = getEachGameData(waxCountryNum, game, yyyymm);
        break;
      case "illuvium" :
        const illvCountryNum = convertTraffaicShareToNumber(dataToRead.illvTotal, dataToRead.illvCountry);
        dataToReturn = getEachGameData(illvCountryNum, game, yyyymm);
        break;
      case "yieldguild" :
        const gldCountryNum = convertTraffaicShareToNumber(dataToRead.gldTotal, dataToRead.gldCountry);
        dataToReturn = getEachGameData(gldCountryNum, game, yyyymm);
        break;
      case "captsuba" :
        const captsubaCountryNum = convertTraffaicShareToNumber(dataToRead.captsubaTotal, dataToRead.captsubaCountry);
        dataToReturn = getEachGameData(captsubaCountryNum, game, yyyymm);
        break;
      case "digidaigaku" :
        const digidaigakuCountryNum = convertTraffaicShareToNumber(dataToRead.digidaigakuTotal, dataToRead.digidaigakuCountry);
        dataToReturn = getEachGameData(digidaigakuCountryNum, game, yyyymm);
        break;
      case "stepn" :
        const stepnCountryNum = convertTraffaicShareToNumber(dataToRead.stepnTotal, dataToRead.stepnCountry);
        dataToReturn = getEachGameData(stepnCountryNum, game, yyyymm);
        break;
      case "splinterlands" :
        const splinterlandsCountryNum = convertTraffaicShareToNumber(dataToRead.splinterlandsTotal, dataToRead.splinterlandsCountry);
        dataToReturn = getEachGameData(splinterlandsCountryNum, game, yyyymm);
        break;
        default:
    }
    return dataToReturn;
  };

  const getTrafficSourceDataByGame = (game, yyyymm) => {
    let dataToReturn = [];
    const dataToRead = yyyymm === 202212 ? trafficData202212 : trafficData;
    switch(game) {
      case "PlayMining" :
        const pmCountryNum = convertTraffaicSourceToNumber(dataToRead.pmTotal, dataToRead.jtcbSource);
        dataToReturn = getEachGameSourceData(pmCountryNum, game, yyyymm);
        break;
      case "Decentraland" :
        const dcCountryNum = convertTraffaicSourceToNumber(dataToRead.dlTotal, dataToRead.decentralandSource);
        dataToReturn = getEachGameSourceData(dcCountryNum, game, yyyymm);
        break;
      case "Sandbox" :
        const sbCountryNum = convertTraffaicSourceToNumber(dataToRead.sbTotal, dataToRead.sandboxSource);
        dataToReturn = getEachGameSourceData(sbCountryNum, game, yyyymm);
        break;
      case "Axie" :
        const axieCountryNum = convertTraffaicSourceToNumber(dataToRead.axTotal, dataToRead.axieSource);
        dataToReturn = getEachGameSourceData(axieCountryNum, game, yyyymm);
        break;
      case "GALA" :
        const glCountryNum = convertTraffaicSourceToNumber(dataToRead.glTotal, dataToRead.glSource);
        dataToReturn = getEachGameSourceData(glCountryNum, game, yyyymm);
        break;
      case "WAX" :
        const waxCountryNum = convertTraffaicSourceToNumber(dataToRead.waxTotal, dataToRead.waxSource);
        dataToReturn = getEachGameSourceData(waxCountryNum, game, yyyymm);
        break;
      case "illuvium" :
        const illvCountryNum = convertTraffaicSourceToNumber(dataToRead.illvTotal, dataToRead.illvSource);
        dataToReturn = getEachGameSourceData(illvCountryNum, game, yyyymm);
        break;
      case "yieldguild" :
        const gldCountryNum = convertTraffaicSourceToNumber(dataToRead.gldTotal, dataToRead.gldSource);
        dataToReturn = getEachGameSourceData(gldCountryNum, game, yyyymm);
        break;
      case "captsuba" :
        const captsubaCountryNum = convertTraffaicSourceToNumber(dataToRead.captsubaTotal, dataToRead.captsubaSource);
        dataToReturn = getEachGameSourceData(captsubaCountryNum, game, yyyymm);
        break;
      case "digidaigaku" :
        const digidaigakuCountryNum = convertTraffaicSourceToNumber(dataToRead.digidaigakuTotal, dataToRead.digidaigakuSource);
        dataToReturn = getEachGameSourceData(digidaigakuCountryNum, game, yyyymm);
        break;
      case "stepn" :
        const stepnCountryNum = convertTraffaicSourceToNumber(dataToRead.stepnTotal, dataToRead.stepnSource);
        dataToReturn = getEachGameSourceData(stepnCountryNum, game, yyyymm);
        break;
      case "splinterlands" :
        const splinterlandsCountryNum = convertTraffaicSourceToNumber(dataToRead.splinterlandsTotal, dataToRead.splinterlandsSource);
        dataToReturn = getEachGameSourceData(splinterlandsCountryNum, game, yyyymm);
        break;
        default:
    }
    return dataToReturn;
  };

  const getChartColor = (game) => {
    let dataToReturn = gameColor[game];
    return dataToReturn;
  }

  const getCountryDisplay = (yyyymm) => {
    const dataToRead = yyyymm === 202212 ? trafficData202212 : trafficData;
    const pmCountryNum = legendState["PlayMining"] ? convertTraffaicShareToNumber(dataToRead.pmTotal, dataToRead.jtcbCountry) : [];
    const dcCountryNum = legendState["Decentraland"] ? convertTraffaicShareToNumber(dataToRead.dlTotal, dataToRead.decentralandCountry) : [];
    const sbCountryNum = legendState["Sandbox"] ? convertTraffaicShareToNumber(dataToRead.sbTotal, dataToRead.sandboxCountry) : [];
    const axieCountryNum = legendState["Axie"] ? convertTraffaicShareToNumber(dataToRead.axTotal, dataToRead.axieCountry) : [];
    const glCountryNum = legendState["GALA"] ? convertTraffaicShareToNumber(dataToRead.glTotal, dataToRead.glCountry) : [];
    const waxCountryNum = legendState["WAX"] ? convertTraffaicShareToNumber(dataToRead.waxTotal, dataToRead.waxCountry) : [];
    const illvCountryNum = legendState["illuvium"] ? convertTraffaicShareToNumber(dataToRead.illvTotal, dataToRead.illvCountry) : [];
    const gldCountryNum = legendState["yieldguild"] ? convertTraffaicShareToNumber(dataToRead.gldTotal, dataToRead.gldCountry) : [];

    const unionArray = _.union(
      pmCountryNum, dcCountryNum, sbCountryNum, axieCountryNum,
      glCountryNum, waxCountryNum, illvCountryNum, gldCountryNum
    );
  
    const dataAllDappsTrafficByCountry = _(unionArray)
      .groupBy('country')
      .map((country, value) => ({
        country: value,
        value: _.sumBy(country, 'value')
      }))
      .orderBy("value", ['desc'])
    .value();
    const countryArray = getAllCountryOrder(dataAllDappsTrafficByCountry);
    return countryArray;
  };

  const getGameDataSeries = (yyyymm) => {
    let dataToReturn = [];
    const keys = Object.keys(legendState);
    const gamesToLoop = yyyymm === 202212 ? allGames2 : allGames;
    _.each(gamesToLoop, function(value) {
      let gameDataObj = {
          name: value,
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series'
          },
          data: getTrafficDataByGame(value, yyyymm),
          itemStyle: {
            color: getChartColor(value),
          },
          barMaxWidth: 25,
      }
      dataToReturn.push(gameDataObj);
      gameDataObj = {};
    });
    console.log("TOTAL DATA:", dataToReturn);
    return dataToReturn;
  };

  const getGameSourceDataSeries = (yyyymm) => {
    let dataToReturn = [];
    const keys = Object.keys(legendState);
    const gamesToLoop = yyyymm === 202212 ? allGames2 : allGames;
    _.each(gamesToLoop, function(value) {
      let gameDataObj = {
          name: value,
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series'
          },
          data: getTrafficSourceDataByGame(value, yyyymm),
          itemStyle: {
            color: getChartColor(value),
          },
          barMaxWidth: 25,
      }
      dataToReturn.push(gameDataObj);
      gameDataObj = {};
    });
    console.log("TOTAL SOURCE DATA:", dataToReturn);
    return dataToReturn;
  };

  const getOptionbyCountry = (yyyymm) => {
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        order:'valueDesc'
      },
      legend: {
        data: yyyymm === 202212 ? allGames2 : allGames,
        selected: yyyymm === 202212 ? legendState2 : legendState,
        align: 'left',
        right: '5%',
        top: '0px'
      },
      grid: {
        top: '50',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        // data: yyyymm == 202212 ? getCountryDisplay(yyyymm) : countryDisplay,
        data: getCountryDisplay(yyyymm),
        axisLabel: {
          fontSize: 13,
          formatter: function (label) {
              var labelInArray = label.split(' ');
              if (labelInArray.length > 2) {
                  let splitIndex = Math.round(labelInArray.length / 2);
                  let charBefore = labelInArray[splitIndex - 1];
                  label = label.replace(charBefore, charBefore+'\n');
              }
              return label;
          },
          align: 'right',
          padding: [0, 0, 0, 20],
          lineHeight: 14,
          color: "#5d5d5d",
        }
      },
      series: getGameDataSeries(yyyymm)
    };
    return option;
  };

  const getOptionbySource = (yyyymm) => {
    const optionSource = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none'
        },
        order:'valueDesc'
      },
      legend: {
        data: yyyymm === 202212 ? allGames2 : allGames,
        selected: legendStateSource,
        align: 'left',
        right: '5%',
        top: '0'
      },
      grid: {
        top: '50',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: sourceDisplay
      },
      series: getGameSourceDataSeries(yyyymm)
    };
    return optionSource;
  };

  const optionSource = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      },
      order:'valueDesc'
    },
    legend: {
      data: allGames,
      selected: legendStateSource,
      align: 'left',
      right: '5%',
      top: '0'
    },
    grid: {
      top: '50',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'value'
    },
    yAxis: {
      type: 'category',
      data: sourceDisplay
    },
    series: getGameSourceDataSeries()
  };

  const getOptionByPm = (yyyymm) => {
    const optionPm = {
      tooltip: {
        //backgroundColor: "rgba(0,0,0,0.8)",
        axisPointer: {
          type: "line",
          lineStyle: {
              color: "#888",
              type: "dashed"
          },
          crossStyle: {
              color: "#888"
          },
          shadowStyle: {
              color: "rgba(200,200,200,0.3)"
          }
        },
        borderRadius: 0,
        textStyle: {
            fontFamily: "Roboto, sans-serif"
        }
      },
      legend: {
        show: false,
        data: ["PlayMining"],
        align: 'left',
        right: '5%',
        top: '0'
      },
      grid: {
        top: '0',
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value'
      },
      yAxis: {
        type: 'category',
        data: getPmCountryDisplay(yyyymm),
        axisLabel: {
          fontSize: 13,
          formatter: function (label) {
              var labelInArray = label.split(' ');
              if (labelInArray.length > 2) {
                  let splitIndex = Math.round(labelInArray.length / 2);
                  let charBefore = labelInArray[splitIndex - 1];
                  label = label.replace(charBefore, charBefore+'\n');
              }
              return label;
          },
          align: 'right',
          padding: [0, 0, 0, 20],
          lineHeight: 14,
          color: "#5d5d5d",
        }
      },
      series: [
        {
          name: "PlayMining",
          type: 'bar',
          stack: 'total',
          label: {
            show: false
          },
          emphasis: {
            focus: 'series'
          },
          data: yyyymm === 202212 ? [38535, 39562, 133844, 188756, 230758] : [34937, 80303, 103456, 203678, 402455],
          itemStyle: {
            color: getChartColor("PlayMining"),
          },
          barMaxWidth: 25,
        }
      ]
    };
    return optionPm;
  };

  const onChartLegendselectchanged = (param) => {
    setLegendState(param.selected);
  };

  const onChartLegendselectchanged2 = (param) => {
    setLegendStateSource(param.selected);
  };

  const onEvents = {
    'legendselectchanged': onChartLegendselectchanged
  }

  const onEvents2 = {
    'legendselectchanged': onChartLegendselectchanged2
  }

  return (
      <Fragment>
        <C.ContentWrapper>
          <Grid container spacing={3} className={classes.gridSection} justifyContent="center">
              <Grid item xs={12} md={10} lg={10} spacing={2}>
                <Grid container spacing={3} className={classes.gridSection} justifyContent="center">
                  <Grid item xs={12} md={6} spacing={2}>
                    <Card id='top-locations-chart'>
                      <CardHeader
                          title="PlayToEarn Dapps 国別アクセス"
                          subheader="2022年1月"
                          className={classes.subTitleText}
                      />
                      <CardContent className={classes.cardContent}>
                        {pageTopLoader 
                          ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                          : <ReactEcharts
                              option={getOptionbyCountry(202201)}
                              onEvents={onEvents}
                              theme='BarChartTheme'
                              style={{ height: 500 }}
                            />
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} spacing={2}>
                    <Card id='top-locations-chart'>
                      <CardHeader
                          title="PlayToEarn Dapps 国別アクセス"
                          subheader="2022年12月"
                          className={classes.subTitleText}
                      />
                      <CardContent className={classes.cardContent}>
                        {pageTopLoader 
                          ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                          : <ReactEcharts
                              option={getOptionbyCountry(202212)}
                              onEvents={onEvents}
                              theme='BarChartTheme'
                              style={{ height: 500 }}
                            />
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={10} lg={10} spacing={2}>
                <Grid container spacing={3} className={classes.gridSection} justifyContent="center">
                  <Grid item xs={12} md={6} spacing={2}>
                    <Card id='top-locations-chart'>
                      <CardHeader
                          title="PlayMining 国別アクセス"
                          subheader="2022年1月"
                      />
                      <CardContent className={classes.cardContent}>
                      {pageTopLoader 
                        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                        : <ReactEcharts
                            option={getOptionByPm(202201)}
                            theme='BarChartTheme'
                            style={{ height: 300 }}
                          />
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} spacing={2}>
                    <Card id='top-locations-chart'>
                      <CardHeader
                          title="PlayMining 国別アクセス"
                          subheader="2022年12月"
                      />
                      <CardContent className={classes.cardContent}>
                      {pageTopLoader 
                        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                        : <ReactEcharts
                            option={getOptionByPm(202212)}
                            theme='BarChartTheme'
                            style={{ height: 300 }}
                          />
                        }
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={10} spacing={2}>
                <Grid container spacing={3} className={classes.gridSection} justifyContent="center">
                  <Grid item xs={12} md={6} spacing={2}>
                    <Card id='top-locations-chart'>
                    <CardHeader
                        title="PlayToEarn Dapps アクセスソース"
                        subheader="2022年1月"
                    />
                    <CardContent className={classes.cardContent}>
                    {pageTopLoader 
                        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                        : <ReactEcharts
                            option={getOptionbySource()}
                            onEvents={onEvents2}
                            theme='BarChartTheme'
                            style={{ height: 400 }}
                          />
                    }
                    </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6} spacing={2}>
                    <Card id='top-locations-chart'>
                    <CardHeader
                        title="PlayToEarn Dapps アクセスソース"
                        subheader="2022年12月"
                    />
                    <CardContent className={classes.cardContent}>
                    {pageTopLoader 
                        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div> 
                        : <ReactEcharts
                            option={getOptionbySource(202212)}
                            onEvents={onEvents2}
                            theme='BarChartTheme'
                            style={{ height: 400 }}
                          />
                    }
                    </CardContent>
                    </Card>
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
        </C.ContentWrapper>
      </Fragment>
  );
};

Lab2.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    pageTopLoader: state.page.pageTopLoader,
    activeUsersData: state.activeUsers.activeUsersData,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Lab2));

const useStyles = makeStyles((theme) =>
    createStyles({
      loaderBoxForUnique: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 110,
      },
      subTitleText: {
        marginLeft: 16,
      }
    }),
);
