import { NFTDEP } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(6, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").format('YYYY-MM-DD');

const initialState  = {
    stakingDataLoading: false,
    stakingTotalData: {},
    stakingStatData: [],
};

export default function stakingReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_STAKING_DATA_LOADING:
            return {
                ...state,
                stakingDataLoading: action.payload
            };
        case NFTDEP.SET_STAKING_TOTAL_DATA:
            return {
                ...state,
                stakingTotalData: action.payload
            };
        case NFTDEP.SET_STAKING_STAT_DATA:
            return {
                ...state,
                stakingStatData: action.payload
            };
        default:
            return state
    }
};
