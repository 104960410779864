import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles, createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import * as V from 'styles/variables';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);

const NoData = ({setCurrentModule, text}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Chip label={text ? text : "No Data"} style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
        </div>
    );
};

NoData.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(NoData));