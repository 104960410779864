// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import * as H from "helper";

const Top10TxCountByPmid = ({
  classes,
  title,
  source,
  loading,
  dataByPmid,
  dateRange,
  dataInGameItemByPmid,
}) => {
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    let dataSource = [];
    switch (source) {
        case "auction":
            dataSource = dataByPmid.count.auction;
            break;
        case "store":
            dataSource = dataByPmid.count.store;
            break;
        case "fiat":
            dataSource = dataByPmid.count.fiat;
            break;
        case "ingame-fiat":
            dataSource = dataInGameItemByPmid.count.fiat;
            break;
        case "ingame-dep":
            dataSource = dataInGameItemByPmid.count.dep;
            break;
        default:
    }
    let totalNum = _.sumBy(dataSource, (data) => {
      return data.value;
    });

    let dataArray = [],
      dataObj = {};
    for (let i = 0; i < dataSource.length; i++) {
      dataObj.no = i + 1;
      dataObj.url =
        dataSource[i]["country"] === "(not set)" ||
        dataSource[i]["country"] === "n/a"
          ? false
          : H.CountryHelper.getCountryCode(dataSource[i]["country"]);
      dataObj.country = dataSource[i]["country"];
      dataObj.pmid = dataSource[i]["pmid"];
      dataObj.num_reg = dataSource[i]["value"];
      dataObj.num_ratio = ((dataSource[i]["value"] / totalNum) * 100).toFixed(
        2
      );
      dataArray.push(dataObj);
      dataObj = {};
    }
    setGeoData(dataArray);
  }, [dataByPmid, dataInGameItemByPmid]);

  return (
    <MaterialTable
      isLoading={loading !== undefined ? loading.ovByPmid : false}
      style={{ padding: 16, fontSize: 14}}
      title={
        <div style={{ fontSize: 18, fontWeight: 500, color: "#000" }}>
          {title}
        </div>
      }
      columns={[
        {
          title: "#",
          field: "no",
          width: 50,
          cellStyle: { textAlign: "center" },
        },
        {
          title: "PMID",
          field: "pmid",
          width: 350,
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex" }}>
                <div>
                  {rowData.url && (
                    <img
                      src={rowData.url}
                      style={{ paddingTop: 2, paddingRight: 8, width: 24 }}
                    />
                  )}
                </div>
                <div style={{ paddingTop: 4 }}>{rowData.pmid}</div>
              </div>
            </Fragment>
          ),
        },
        {
          title: "取引件数",
          field: "num_reg",
          width: 200,
          type: "numeric",
          cellStyle: { textAlign: "center" },
          render: (rowData) => (
            <div>
              {H.FormatNumStrHelper.formatCommaThousand(rowData.num_reg)}
            </div>
          ),
        },
        {
          title: "全体比率",
          field: "num_ratio",
          type: "numeric",
          cellStyle: { textAlign: "left", marginLeft: 32 },
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex", height: 16 }}>
                <div style={{ paddingRight: 16, width: 24 }}>
                  {rowData.num_ratio}%
                </div>
                <div
                  style={{
                    backgroundColor: "#65C9A5",
                    width: rowData.num_ratio + "%",
                    marginLeft: 20,
                    marginTop: 3,
                  }}
                ></div>
              </div>
            </Fragment>
          ),
        },
      ]}
      data={geoData}
      options={{
        search: false,
        paging: false,
        sorting: false,
        exportButton: { csv: true },
        exportFileName: `${dateRange[0]}-${dateRange[1]} ${title}`,
        rowStyle: {
          textAlign: "center",
        },
        headerStyle: { textAlign: "center" },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "表示可能なデータがありません",
        },
      }}
    />
  );
};

Top10TxCountByPmid.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  // loading: state.nftDepOverview.loading,
  dateRange: state.page.dateRange,
  dataByPmid: state.nftDepOverview.dataByPmid,
  dataInGameItemByPmid: state.inGameItemSales.dataByPmid,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Top10TxCountByPmid));
