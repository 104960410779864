import { JTCB } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    jtcbNftTrendDateLoading: false,
    jtcbNftTrendDateRange: [startDate, endDate],
    trendKindSelected: [],
    trendRareSelected: [],
    trendData: [],
    rarityTrendDataCountAuction: [],
    rarityTrendDataAmountAuction: [],
    rarityTrendDataCountStore: [],
    rarityTrendDataAmountStore: [],
    rarityTrendDataCountStoreFiat: [],
    rarityTrendDataAmountStoreFiat: [],
    nftTrendDataAuctionAll: {
        count: '-',
        amount: '-',
        unique: '-',
    },
    nftTrendDataStoreAll:  {
        count: '-',
        amount: '-',
        unique: '-',
    },
    nftTrendDataStoreAllFiat:  {
        count: '-',
        amount: '-',
        unique: '-',
    },
};

export default function jtcbNftTrendReducer(state = initialState, action) {
    switch (action.type) {
        case JTCB.SET_JTCB_NFT_TREND_DATA_LOADING:
            return {
                ...state,
                jtcbNftTrendDateLoading: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATE_RANGE:
            return {
                ...state,
                jtcbNftTrendDateRange: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_KIND_SELECTED:
            return {
                ...state,
                trendKindSelected: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_RARE_SELECTED:
            return {
                ...state,
                trendRareSelected: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_PAGE_DATA:
            return {
                ...state,
                trendData: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATA_COUNT_AUCTION:
            return {
                ...state,
                rarityTrendDataCountAuction: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATA_AMOUNT_AUCTION:
            return {
                ...state,
                rarityTrendDataAmountAuction: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATA_COUNT_STORE:
            return {
                ...state,
                rarityTrendDataCountStore: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE:
            return {
                ...state,
                rarityTrendDataAmountStore: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATA_COUNT_STORE_FIAT:
            return {
                ...state,
                rarityTrendDataCountStoreFiat: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_DATA_AMOUNT_STORE_FIAT:
            return {
                ...state,
                rarityTrendDataAmountStoreFiat: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_AUCTION_ALL:
            return {
                ...state,
                nftTrendDataAuctionAll: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_STORE_ALL:
            return {
                ...state,
                nftTrendDataStoreAll: action.payload
            };
        case JTCB.SET_JTCB_NFT_TREND_STORE_ALL_FIAT:
            return {
                ...state,
                nftTrendDataStoreAllFiat: action.payload
            };
        default:
            return state
    }
};
