import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Chip,
  Collapse,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ViewListIcon from "@material-ui/icons/ViewList";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  setJtcbNftTrendKindSelected,
  setJtcbNftTrendRareSelected,
  getJtcbNftTrendPageData,
} from "actions";
import * as C from "components";
import JtcbNftTrendTable from "components/Table/JtcbNftTrendTable";
import * as V from "styles/variables";
import * as H from "helper";
import ChartConfig from "utils/ChartConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBoxForUnique: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 110,
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    filterSection: {
      display: "flex",
      backgroundColor: "#fafafa",
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    activeUsersTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    totalBox: {
      fontSize: 16,
    },
    summarySection: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      fontSize: 14,
    },
    summaryBox: {
      paddingLeft: 16,
      borderRight: "1px solid #dcdcdc",
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    expand: {
      marginLeft: "auto",
      marginRight: 32,
    },
    title: {
      fontSize: 18,
      marginLeft: 36,
      marginTop: 16,
    },
    rareTitle: {
      marginBottom: 8,
    },
  })
);

const JtcbNftTrend = ({
  trendKindSelected,
  trendRareSelected,
  trendData,
  setCurrentPage,
  setJtcbNftTrendRareSelected,
  getJtcbNftTrendPageData,
  trendDataLoading,
  trendDataCountAuction,
  trendDataAmountAuction,
  trendDataCountStore,
  trendDataAmountStore,
  trendDataCountStoreFiat,
  trendDataAmountStoreFiat,
  nftTrendDataAuctionAll,
  nftTrendDataStoreAll,
  nftTrendDataStoreAllFiat,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartState, setChartState] = useState(false);
  const [kindOption, setKindOption] = useState([
    { name: "オークション販売", value: "auction" },
    { name: "ストア販売", value: "stire" },
  ]);
  const [rareOption, setRareOption] = useState([
    { name: "LEGENDARY", value: "RARITY-JTCB_LEGENDARY" },
    { name: "EPIC", value: "RARITY-JTCB_EPIC" },
    { name: "RARE", value: "RARITY-JTCB_RARE" },
    { name: "COMMON", value: "RARITY-JTCB_COMMON" },
  ]);
  const [chartData, setChartData] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [storeDataFiat, setStoreDataFiat] = useState([]);

  // expand
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChangeKindOption = (data) => {
    let optionSelectedArray = [];
    _.forEach(data, function (item, i) {
      optionSelectedArray.push(item.value);
    });
    setJtcbNftTrendKindSelected(data);
    setBtnState(false);
  };

  const handleChangeOption = (data) => {
    let optionSelectedArray = [];
    _.forEach(data, function (item, i) {
      optionSelectedArray.push(item.value);
    });
    setJtcbNftTrendRareSelected(data);
    setBtnState(false);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getJtcbNftTrendPageData();
  }, []);

  useEffect(() => {
    // combine data
    const dataCombined = trendDataCountAuction.concat(trendDataAmountAuction);
    setChartData(dataCombined);
  }, [trendDataCountAuction, trendDataAmountAuction]);

  useEffect(() => {
    // combine data
    const dataCombinedStore = trendDataCountStore.concat(trendDataAmountStore);
    setStoreData(dataCombinedStore);
  }, [trendDataCountStore, trendDataAmountStore]);

  useEffect(() => {
    // combine data
    const dataCombinedStoreFiat = trendDataCountStoreFiat.concat(trendDataAmountStoreFiat);
    setStoreDataFiat(dataCombinedStoreFiat);
  }, [trendDataCountStoreFiat, trendDataAmountStoreFiat]);

  const getAuctionSummaryData = (type, kind) => {
    if (trendDataCountAuction.length < 1) {
      return;
    }
    let countValuel = 0,
      totalDepValue = 0,
      avgDepValue = 0;
    switch (type) {
      case "legendary":
        countValuel = kind === "auction" 
        ? _.sum(trendDataCountAuction[1]["data"])
        : _.sum(trendDataCountStore[1]["data"]);
        totalDepValue = kind === "auction"
        ? _.sum(trendDataAmountAuction[0]["data"])
        : _.sum(trendDataAmountStore[0]["data"]);
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      case "epic":
        countValuel = kind === "auction"
        ? _.sum(trendDataCountAuction[2]["data"])
        : _.sum(trendDataCountStore[2]["data"]);
        totalDepValue = kind === "auction" 
        ? _.sum(trendDataAmountAuction[1]["data"])
        : _.sum(trendDataAmountStore[1]["data"]);
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      case "rare":
        countValuel = kind === "auction"
        ? _.sum(trendDataCountAuction[3]["data"])
        : _.sum(trendDataCountStore[3]["data"])
        totalDepValue = kind === "auction"
        ? _.sum(trendDataAmountAuction[2]["data"])
        : _.sum(trendDataAmountStore[2]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      case "common":
        countValuel = kind === "auction"
        ? _.sum(trendDataCountAuction[4]["data"])
        : _.sum(trendDataCountStore[4]["data"])
        totalDepValue = kind === "auction"
        ? _.sum(trendDataAmountAuction[3]["data"])
        : _.sum(trendDataAmountStore[3]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      default:
        break;
    }
    return (
      <div>
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        DEP総額：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)}{"　|　"}
        平均：{H.FormatNumStrHelper.formatCommaThousand(avgDepValue)}
      </div>
    );
  };

  const getSummaryDataFiat = (type) => {
    if (trendDataCountStoreFiat.length < 1) {
      return;
    }
    let countValuel = 0,
      totalDepValue = 0,
      avgDepValue = 0;
    switch (type) {
      case "legendary":
        countValuel = _.sum(trendDataCountStoreFiat[1]["data"])
        totalDepValue = _.sum(trendDataAmountStoreFiat[0]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      case "epic":
        countValuel = _.sum(trendDataCountStoreFiat[2]["data"]);
        totalDepValue = _.sum(trendDataAmountStoreFiat[1]["data"]);
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      case "rare":
        countValuel = _.sum(trendDataCountStoreFiat[3]["data"])
        totalDepValue = _.sum(trendDataAmountStoreFiat[2]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      case "common":
        countValuel = _.sum(trendDataCountStoreFiat[4]["data"])
        totalDepValue = _.sum(trendDataAmountStoreFiat[3]["data"])
        avgDepValue = (totalDepValue / countValuel).toFixed(0);
        break;
      default:
        break;
    }
    return (
      <div>
        件数：{H.FormatNumStrHelper.formatCommaThousand(countValuel)} {"　|　"}
        USD総額：{H.FormatNumStrHelper.formatCommaThousand(totalDepValue)}{"　|　"}
        USD平均：{countValuel > 0 ? H.FormatNumStrHelper.formatCommaThousand(avgDepValue) : 0}
      </div>
    );
  };


  const getAveValue = (amount, count) => {
    let dataToReturn = parseFloat(amount.replace(/,/g, ''))/parseFloat(count.replace(/,/g, ''))
    dataToReturn = dataToReturn.toFixed(0);
    return H.FormatNumStrHelper.formatCommaThousand(dataToReturn);
  };

  const fetchData = () => {
    getJtcbNftTrendPageData();
    setChartState(true);
    setBtnState(!btnStateDataFetch);
  };

  return (
    <C.ContentWrapper>
      <div className={classes.filterSection}>
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <div className={classes.title}>JobTribes レアリティ別取引</div>
          </Grid>
          <Grid item>
            <Grid container spacing={0} direction="row">
              <Grid item>
                <C.CalendarRangeOnly
                  setBtnState={setBtnState}
                  page="jtcbNftTrend"
                />
              </Grid>
              <Grid>
                <Button
                  //disabled={btnStateDataFetch}
                  className={classes.dataBtn}
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                >
                  GO
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} className={classes.totalBox}>
        オークション取引　件数: {nftTrendDataAuctionAll.count}&nbsp;&nbsp;|
        &nbsp;&nbsp;DEP: {nftTrendDataAuctionAll.amount}&nbsp;&nbsp;|
        &nbsp;&nbsp;ユニーク: {nftTrendDataAuctionAll.unique}&nbsp;&nbsp;|
        &nbsp;&nbsp;ユニークDEP平均: {nftTrendDataAuctionAll.unique === '-' ? "-" : getAveValue(nftTrendDataAuctionAll.amount, nftTrendDataAuctionAll.unique)}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="LEGENDARY"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[0],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("legendary", "auction")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="EPIC"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[1],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("epic", "auction")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="RARE"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[2],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("rare", "auction")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
           <Chip
              size="small"
              label="COMMON"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[3],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("common", "auction")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              legend={ChartConfig.legendJtcbNftTrend}
              lblSaveImage={"DEP取引種別"}
              xAisData={chartData[0]["date"]}
              seriesData={chartData}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="JobTribes レアリティ別取引 - オークション販売"
            dataCount={trendDataCountAuction}
            dataAmount={trendDataAmountAuction}
          />
        </div>
      </Collapse>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} className={classes.totalBox}>
        ストアDEP取引　件数: {nftTrendDataStoreAll.count}&nbsp;&nbsp;|&nbsp;&nbsp;
        DEP: {nftTrendDataStoreAll.amount}&nbsp;&nbsp;|&nbsp;&nbsp;
        ユニーク: {nftTrendDataStoreAll.unique}&nbsp;&nbsp;|&nbsp;&nbsp;
        &nbsp;&nbsp;ユニークDEP平均: {nftTrendDataStoreAll.unique === '-' ? "-" : getAveValue(nftTrendDataStoreAll.amount, nftTrendDataStoreAll.unique)}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="LEGENDARY"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[0],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("legendary", "store")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="EPIC"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[1],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("epic", "store")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="RARE"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[2],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("rare", "store")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
           <Chip
              size="small"
              label="COMMON"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[3],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getAuctionSummaryData("common", "store")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              legend={ChartConfig.legendJtcbNftTrend}
              lblSaveImage={"DEP取引種別"}
              xAisData={storeData[0]["date"]}
              seriesData={storeData}
              chartType={"line"}
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="JobTribes レアリティ別取引 - ストアDEP販売"
            dataCount={trendDataCountStore}
            dataAmount={trendDataAmountStore}
          />
        </div>
      </Collapse>

      {/* FIAT */}
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} className={classes.totalBox}>
        ストアFIAT取引　件数: {nftTrendDataStoreAllFiat.count}&nbsp;&nbsp;|&nbsp;&nbsp;
        USD: {nftTrendDataStoreAllFiat.amount}&nbsp;&nbsp;|&nbsp;&nbsp;
        ユニーク: {nftTrendDataStoreAllFiat.unique}&nbsp;&nbsp;|&nbsp;&nbsp;
        &nbsp;&nbsp;ユニークUSD平均: {nftTrendDataStoreAllFiat.unique === '-' ? "-" : getAveValue(nftTrendDataStoreAllFiat.amount, nftTrendDataStoreAllFiat.unique)}
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.summarySection}
      >
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="LEGENDARY"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[0],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSummaryDataFiat("legendary")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="EPIC"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[1],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSummaryDataFiat("epic")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
            <Chip
              size="small"
              label="RARE"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[2],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSummaryDataFiat("rare")}
        </Grid>
        <Grid item xs={12} md={6} lg={3} className={classes.summaryBox}>
          <div className={classes.rareTitle}>
           <Chip
              size="small"
              label="COMMON"
              style={{
                color: "#fff",
                backgroundColor: V.genericColorPallette[3],
              }}
            />
          </div>
          {trendDataLoading ? "--" : getSummaryDataFiat("common")}
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={12} lg={12}>
          {trendDataLoading ? (
            <div className={classes.loaderBox}>
              <img
                className={classes.loaderBarImg}
                src={`/static/images/loader-bar.gif`}
              />
            </div>
          ) : chartData.length > 0 ? (
            <C.LineChartNftDepTrend
              legend={ChartConfig.legendJtcbNftTrendFiat}
              lblSaveImage={"DEP取引種別"}
              xAisData={storeDataFiat[0]["date"]}
              seriesData={storeDataFiat}
              chartType={"line"}
              currency="USD"
            />
          ) : (
            <div className={classes.selectGameTxt}>
              <Chip
                label="No Data"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} className={classes.gridSection}>
        <Button
          className={classes.expand}
          onClick={handleExpandClick}
          startIcon={<ViewListIcon />}
          size="small"
        >
          表データを{expanded ? "非表示にする" : "表示する"}
        </Button>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div className={classes.activeUsersTable}>
          <JtcbNftTrendTable
            title="JobTribes レアリティ別取引 - ストアFIAT販売"
            dataCount={trendDataCountStoreFiat}
            dataAmount={trendDataAmountStoreFiat}
          />
        </div>
      </Collapse>
    </C.ContentWrapper>
  );
};

JtcbNftTrend.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  trendDataLoading: state.jtcbNftTrend.jtcbNftTrendDateLoading,
  trendKindSelected: state.jtcbNftTrend.trendKindSelected,
  trendRareSelected: state.jtcbNftTrend.trendRareSelected,
  trendData: state.jtcbNftTrend.trendData,
  trendDataCountAuction: state.jtcbNftTrend.rarityTrendDataCountAuction,
  trendDataAmountAuction: state.jtcbNftTrend.rarityTrendDataAmountAuction,
  trendDataCountStore: state.jtcbNftTrend.rarityTrendDataCountStore,
  trendDataAmountStore: state.jtcbNftTrend.rarityTrendDataAmountStore,
  trendDataCountStoreFiat: state.jtcbNftTrend.rarityTrendDataCountStoreFiat,
  trendDataAmountStoreFiat: state.jtcbNftTrend.rarityTrendDataAmountStoreFiat,
  nftTrendDataAuctionAll: state.jtcbNftTrend.nftTrendDataAuctionAll,
  nftTrendDataStoreAll: state.jtcbNftTrend.nftTrendDataStoreAll,
  nftTrendDataStoreAllFiat: state.jtcbNftTrend.nftTrendDataStoreAllFiat,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  setJtcbNftTrendKindSelected: (param) =>
    dispatch(setJtcbNftTrendKindSelected(param)),
  setJtcbNftTrendRareSelected: (param) =>
    dispatch(setJtcbNftTrendRareSelected(param)),
  getJtcbNftTrendPageData: () => dispatch(getJtcbNftTrendPageData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(JtcbNftTrend));
