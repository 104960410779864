import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import * as C from 'components';
import * as V from 'styles/variables';

const Acquisition = ({classes, setCurrentModule, acquisitionDataReg, acquisitionDataRegGender, acquisitionDataRegAgeGroup}) => {
    useEffect(() => {
        //console.log('yoyo');
    }, []);

    // useEffect(() => {
    //     console.log('yoyo:', acquisitionDataReg.pmidRegNumTotal);
    //     setData(acquisitionDataReg);
    // }, [acquisitionDataReg]);

    const getGenderPieColour = (colour, data) => {
        let returnColourArray = [];
;       for (let i=0; i<data.length; i++) {
            let name = data[i]['name'];
            returnColourArray.push(colour[name]);
        }
        return returnColourArray
    }

    return(
        <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={8}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="新規登録<br />合計"
                            value={acquisitionDataReg.pmidRegNumTotal}
                            color={'#FDBF6F'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="PMID広告経由<br />登録"
                            value={acquisitionDataReg.pmidRegNumAd}
                            color={'#FB9A99'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="PMID Organic<br />登録"
                            value={acquisitionDataReg.pmidRegNumOrg}
                            color={'#33A02C'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="PMID新規登録"
                            value={acquisitionDataReg.pmidRegNew}
                            color={'#A6CEE3'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="PMID既存登録"
                            value={acquisitionDataReg.pmidRegExist}
                            color={'#CAB2D6'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={4}>
                <Grid container spacing={2}>
                    <div style={{display:'flex', height: 140}}>
                        <C.DoughnutChart
                            title="性別"
                            data={acquisitionDataRegGender}
                            color={getGenderPieColour(V.pieGender, acquisitionDataRegGender)}
                            legendRight={20}
                            legendTop={35}
                            marginLeft={'-20%'}
                        />
                        <C.DoughnutChart
                            title="年齢"
                            data={acquisitionDataRegAgeGroup}
                            color={getGenderPieColour(V.pieAge, acquisitionDataRegAgeGroup)}
                            legendRight={20}
                            legendTop={0}
                            marginLeft={'0%'}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
};

Acquisition.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    acquisitionDataReg: state.puzzle.acquisitionDataReg,
    acquisitionDataRegGender: state.puzzle.acquisitionDataRegGender,
    acquisitionDataRegAgeGroup: state.puzzle.acquisitionDataRegAgeGroup,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Acquisition));