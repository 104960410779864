import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import {
  Checkbox,
  TextField,
  Button,
  Grid,
  Chip,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import ReactEcharts from "echarts-for-react";
import {
  setCurrentPage,
  getAverageRevenuePageData,
  getAverageRevenueRid,
  getAverageRevenueFilterOption,
  setAverageRevenueGameSelected,
  setAverageRevenueCountrySelected,
  setAverageRevenueRidSelected
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import * as H from 'helper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    noDataBox: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 350,
    },
    loaderBarImg: {
      width: 40,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    dataTable: {
      padding: 16,
      borderSpacing: 0,
    },
    autoSelect: {
      marginTop: 8,
      marginRight: 16,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    cardContent: {
        overflow: 'scroll',
        overflowX: 'hidden',
    },
    cardContentLong: {
        height: '350px',
        overflow: 'scroll',
        overflowX: 'hidden'    
    },
    downloadButton: {
      minWidth: 180,
    }
  })
);

const AverageRevenue = ({
  pageTopLoader,
  setCurrentPage,
  getAverageRevenuePageData,
  avgRevenueGameOption,
  avgRevenueCountryOption,
  avgRevenueRidOption,
  avgRevenueGameSelected,
  avgRevenueCountrySelected,
  avgRevenueRidSelected,
  countryLoading,
  getAverageRevenueFilterOption,
  ridLoading,
  avgRevenueDataByGame,
  avgRevenueDataByCountry,
  avgRevenueDataByRid,
  avgRevenueDataByGamePaid,
  avgRevenueDataByCountryPaid,
  avgRevenueDataByRidPaid,
  setAverageRevenueGameSelected,
  setAverageRevenueCountrySelected,
  setAverageRevenueRidSelected,
  csvDataPaidPmid,
  csvDataAllPmid,
  avgRevenueDateRange
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [gameValue, setGameValue] = useState(avgRevenueGameOption);
  const [countryValue, setCountryValue] = useState(avgRevenueCountryOption);
  const [ridValue, setRidValue] = useState(avgRevenueRidOption);
  const [open, setOpen] = useState(false);
  const [copyAxis, setCopyAxis] = useState("");

  const gameOptions = [
    { name: "PlayMining NFT", value: "PlayMiningNFT" },
    { name: "JobTribes", value: "jobtribes" },
    { name: "Lucky Farmer", value: "luckyfarmer" },
    { name: "ポータル", value: "Portal" },
  ];
  const handleChangeGame = (data) => {
    setAverageRevenueGameSelected(data.length === 0 ? [] : [data]);
    setGameValue(data);
    setBtnState(false);
  };

  const handleChangeCountry = (data) => {
    setAverageRevenueCountrySelected(data.length === 0 ? [] : [data]);
    setCountryValue(data);
    setBtnState(false);
  };

  const handleChangeRid = (data) => {
    setAverageRevenueRidSelected(data.length === 0 ? [] : [data]);
    setRidValue(data);
    setBtnState(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getOption = (yAxisData, xAxisData, label, color, isCampaign = false) => {
    return {
        animation: false,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            },
            order:'valueDesc',
            formatter: function (params) {
              let tLabel = "", axisValue = `${params[0]['axisValueLabel']}<br />${params[0].marker} ${params[0].seriesName} : ${H.FormatNumStrHelper.formatCommaThousand(params[0].data)}`;
              if (isCampaign) {
                let campaginName = params[0]['axisValueLabel'] === "Organic"
                ? params[0]['axisValueLabel']
                : avgRevenueDataByRid[2][params[0]['dataIndex']];
                axisValue = `${campaginName}<br />${params[0].marker} ${params[0].seriesName} : ${H.FormatNumStrHelper.formatCommaThousand(params[0].data)}`
              }
              tLabel = axisValue;
              return tLabel
            }
        },
        legend: {
            show: false,
            align: 'left',
            left: '0%',
        },
        grid: {
            top: '6',
            left: '0%',
            right: '10%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          data: yAxisData,
          triggerEvent: true,
        },
        series: [
            {
                name: label,
                type: "bar",
                stack: "total",
                label: {
                  show: true,
                  position: 'right',
                },
                emphasis: {
                  focus: "series",
                },
                data: xAxisData,
                barMaxWidth: 15,
                barGap: '30%',
                itemStyle : {
                    normal: {
                        borderWidth: 0.5,
                        color: color,
                    },
                },
            },
        ],
      };
  }
  
  const onChartClick = (params) => {
    setCopyAxis(params.value);
  };

  const onEvents = {
    click: onChartClick,
  }

  useEffect(() => {
    if (copyAxis !== "") {
      navigator.clipboard.writeText(copyAxis).then(function() {
        console.log('Copied to clipboard successfully!');
        setOpen(true);
      });
    }
  }, [copyAxis]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getAverageRevenuePageData();
    getAverageRevenueFilterOption();
  }, []);

  const fetchData = () => {
    setBtnState(!btnStateDataFetch);
    getAverageRevenuePageData();
    getAverageRevenueFilterOption();
  };

  return (
    <C.ContentWrapper>
      <Grid
        container
        spacing={0}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item>
                <Autocomplete
                    value={gameValue}
                    multiple
                    limitTags={2}
                    disableCloseOnSelect
                    size="small"
                    id="select-game"
                    options={avgRevenueGameOption}
                    defaultValue={avgRevenueGameOption[0]}
                    getOptionLabel={(option) => option.name}
                    renderOption={(option, { selected }) => (
                        <Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                        </Fragment>
                    )}
                    onChange={(e, newValue) => {
                        handleChangeGame(newValue);
                    }}
                    style={{ minWidth: 240 }}
                    fullWidth
                    renderInput={(params) => (
                        <TextField {...params} label="ゲーム" placeholder={avgRevenueGameSelected.length > 0 ? "" : "全て"} />
                    )}
                    className={classes.autoSelect}
                />
            </Grid>
            <Grid item>
              <Autocomplete
                value={countryValue}
                multiple
                limitTags={2}
                disableCloseOnSelect
                id="select-country"
                size="small"
                style={{ minWidth: 220 }}
                fullWidth
                options={avgRevenueCountryOption}
                getOptionLabel={(option) => option.name}
                defaultValue={avgRevenueCountryOption[0]}
                loading={countryLoading}
                renderOption={(option, { selected }) => (
                    <Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                    </Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="国" placeholder={avgRevenueCountrySelected.length > 0 ? "" : "全て"} />
                )}
                onChange={(e, newValue) => {
                    handleChangeCountry(newValue);
                }}
                className={classes.autoSelect}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                value={ridValue}
                multiple
                limitTags={2}
                disableCloseOnSelect
                id="select-rid"
                size="small"
                style={{ minWidth: 450 }}
                fullWidth
                options={avgRevenueRidOption}
                getOptionLabel={(option) => option.name}
                defaultValue={avgRevenueRidOption[0]}
                loading={ridLoading}
                renderOption={(option, { selected }) => (
                  <Fragment>
                  <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                  />
                  {option.name}
                  </Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} label="キャンペーン" placeholder={avgRevenueRidSelected.length > 0 ? "" : "全て"} />
                )}
                onChange={(e, newValue) => {
                    handleChangeRid(newValue);
                }}
                className={classes.autoSelect}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="flex-start"
          >
            <Grid item>
              <C.CalendarRangeOnly
                setBtnState={setBtnState}
                page="average-revenue"
              />
            </Grid>
            <Grid item>
              <Button
                disabled={btnStateDataFetch}
                className={classes.dataBtn}
                variant="contained"
                color="primary"
                onClick={() => fetchData()}
              >
                GO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} direction="row" justifyContent="flex-end">
      <Grid item>
          <CSVLink
              data={csvDataAllPmid}
              filename={`新規登録者 ${avgRevenueDateRange[0]} - ${avgRevenueDateRange[1]}`}
          >
              <Button
                  variant="contained"
                  color="default"
                  className={classes.downloadButton}
                  startIcon={pageTopLoader ? <CircularProgress size="1rem" /> : <GetApp />}
                  disabled={csvDataAllPmid.length > 0 ? false : true}
              >
                  {pageTopLoader ? "" : "新規登録者 CSV"}
              </Button>
          </CSVLink>
        </Grid>
        <Grid item>
          <CSVLink
              data={csvDataPaidPmid}
              filename={`新規課金者 ${avgRevenueDateRange[0]} - ${avgRevenueDateRange[1]}`}
          >
              <Button
                  variant="contained"
                  color="default"
                  className={classes.downloadButton}
                  startIcon={pageTopLoader ? <CircularProgress size="1rem" /> : <GetApp />}
                  disabled={csvDataPaidPmid.length > 0 ? false : true}
              >
                  {pageTopLoader ? "" : "新規課金者 CSV"}
              </Button>
          </CSVLink>
        </Grid>
      </Grid>

      <Grid container spacing={1} className={classes.gridSection}>
        <Grid item xs={12} md={6} lg={4}>
          <Card id="top-locations-chart">
            <CardHeader
              title={`GAME別新規登録 : 合計 ${avgRevenueDataByGame.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByGame[1]))} 人`}
              titleTypographyProps={{variant:'caption'}}
            />
            <CardContent className={classes.cardContent}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                <ReactEcharts
                  option={getOption(avgRevenueDataByGame[0], avgRevenueDataByGame[1], '新規登録', "#105375")}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card id="top-locations-chart">
            <CardHeader
              title={`GAME別新規課金者数 : 合計 ${avgRevenueDataByGamePaid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByGamePaid[1]))} 人`}
              titleTypographyProps={{variant:'caption'}}
            />
            <CardContent className={classes.cardContent}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                avgRevenueDataByGamePaid[1] !== undefined &&
                avgRevenueDataByGamePaid[1].length > 0 ? (
                <ReactEcharts
                  option={getOption(avgRevenueDataByGamePaid[0], avgRevenueDataByGamePaid[1], '新規登録', "#61cdbb")}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
                ) : (
                <div className={classes.noDataBox}>データがありません</div>
                )
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <Card id="top-locations-chart">
            <CardHeader
              title={`GAME別新規課金DEP額 : 合計 ${avgRevenueDataByGamePaid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByGamePaid[2]))} DEP`}
              titleTypographyProps={{variant:'caption'}}
            />
            <CardContent className={classes.cardContent}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                avgRevenueDataByGamePaid[2] !== undefined &&
                avgRevenueDataByGamePaid[2].length > 0 ? (
                <ReactEcharts
                  option={getOption(avgRevenueDataByGamePaid[0], avgRevenueDataByGamePaid[2], '使用DEP額', "#fdb462")}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
                ) : (
                <div className={classes.noDataBox}>データがありません</div>
                )
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
        <Card id="top-locations-chart">
            <CardHeader
              title={`国別新規登録 : 合計 ${avgRevenueDataByCountry.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByCountry[1]))} 人`}
            />
            <CardContent className={classes.cardContentLong}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                <div>
                <ReactEcharts
                  option={getOption(avgRevenueDataByCountry[0], avgRevenueDataByCountry[1], '新規登録', "#105375")}
                  theme="BarChartTheme"
                  style={{ height: avgRevenueDataByCountry[1] !== undefined ? avgRevenueDataByCountry[1].length * 30 + 350 : 350 }}
                  onEvents={onEvents}
                />
                </div>
              )}
            </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
        <Card id="top-locations-chart">
            <CardHeader
              title={`国別新規課金者数 : 合計 ${avgRevenueDataByCountryPaid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByCountryPaid[1]))} 人`}
            />
            <CardContent className={classes.cardContentLong}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                avgRevenueDataByCountryPaid[1] !== undefined &&
                avgRevenueDataByCountryPaid[1].length > 0 ? (
                <ReactEcharts
                  option={getOption(avgRevenueDataByCountryPaid[0], avgRevenueDataByCountryPaid[1], '新規登録＋課金', "#61cdbb")}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
                ) : (
                <div className={classes.noDataBox}>データがありません</div>
                )
            )}
            </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
        <Card id="top-locations-chart">
            <CardHeader
              title={`国別新規課金DEP額 : 合計 ${avgRevenueDataByCountryPaid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByCountryPaid[2]))} DEP`}
            />
            <CardContent className={classes.cardContentLong}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                avgRevenueDataByCountryPaid[2] !== undefined &&
                avgRevenueDataByCountryPaid[2].length > 0 ? (
                <ReactEcharts
                  option={getOption(avgRevenueDataByCountryPaid[0], avgRevenueDataByCountryPaid[2], '使用DEP額', "#fdb462")}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
                ) : (
                <div className={classes.noDataBox}>データがありません</div>
                )
              )}
            </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
        <Card id="top-locations-chart">
            <CardHeader
              title={`広告別新規登録 : 合計 ${avgRevenueDataByRid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByRid[1]))} 人`}
            />
            <CardContent className={classes.cardContentLong}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                <ReactEcharts
                  option={getOption(avgRevenueDataByRid[0], avgRevenueDataByRid[1], '新規登録', "#105375", true)}
                  theme="BarChartTheme"
                  style={{ height: avgRevenueDataByRid[1] !== undefined ? avgRevenueDataByRid[1].length * 30 + 350 : 350 }}
                  onEvents={onEvents}
                />
              )}
            </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
        <Card id="top-locations-chart">
            <CardHeader
              title={`広告別新規課金者数 : 合計 ${avgRevenueDataByRidPaid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByRidPaid[1]))} 人`}
            />
            <CardContent className={classes.cardContentLong}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                avgRevenueDataByRidPaid[1] !== undefined &&
                avgRevenueDataByRidPaid[1].length > 0 ? (
                <ReactEcharts
                  option={getOption(avgRevenueDataByRidPaid[0], avgRevenueDataByRidPaid[1], '新規登録＋課金', "#61cdbb", true)}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
                ) : (
                <div className={classes.noDataBox}>データがありません</div>
                )
              )}
            </CardContent>
        </Card>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
        <Card id="top-locations-chart">
            <CardHeader
              title={`広告別新規課金DEP額 : 合計 ${avgRevenueDataByRidPaid.length == 0 ? "--"
              : H.FormatNumStrHelper.formatCommaThousand(_.sum(avgRevenueDataByRidPaid[2]))} DEP`}
            />
            <CardContent className={classes.cardContent}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : (
                avgRevenueDataByRidPaid[2] !== undefined &&
                avgRevenueDataByRidPaid[2].length > 0 ? (
                <ReactEcharts
                  option={getOption(avgRevenueDataByRidPaid[0], avgRevenueDataByRidPaid[2], '使用DEP額', "#fdb462", true)}
                  theme="BarChartTheme"
                  style={{ height: 350 }}
                  onEvents={onEvents}
                />
                ) : (
                <div className={classes.noDataBox}>データがありません</div>
                )
              )}
            </CardContent>
        </Card>
        </Grid>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            {`${copyAxis} がコピーされました`}
          </Alert>
        </Snackbar>
      </Grid>
    </C.ContentWrapper>
  );
};

AverageRevenue.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  avgRevenueGameOption: state.kpi.avgRevenueGameOption,
  avgRevenueGameSelected: state.kpi.avgRevenueGameSelected,
  avgRevenueCountryOption: state.kpi.avgRevenueCountryOption,
  avgRevenueCountrySelected: state.kpi.avgRevenueCountrySelected,
  avgRevenueRidOption: state.kpi.avgRevenueRidOption,
  avgRevenueRidSelected: state.kpi.avgRevenueRidSelected,
  avgRevenueDataByGame: state.kpi.avgRevenueDataByGame,
  avgRevenueDataByCountry: state.kpi.avgRevenueDataByCountry,
  avgRevenueDataByRid: state.kpi.avgRevenueDataByRid,
  avgRevenueDataByGamePaid: state.kpi.avgRevenueDataByGamePaid,
  avgRevenueDataByCountryPaid: state.kpi.avgRevenueDataByCountryPaid,
  avgRevenueDataByRidPaid: state.kpi.avgRevenueDataByRidPaid,
  countryLoading: state.kpi.countryLoading,
  ridLoading: state.kpi.ridLoading,
  csvDataPaidPmid: state.kpi.csvDataPaidPmid,
  csvDataAllPmid: state.kpi.csvDataAllPmid,
  avgRevenueDateRange: state.kpi.avgRevenueDateRange,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getAverageRevenuePageData: () => dispatch(getAverageRevenuePageData()),
  getAverageRevenueFilterOption: () => dispatch(getAverageRevenueFilterOption()),
  getAverageRevenueRid: () => dispatch(getAverageRevenueRid()),
  setAverageRevenueGameSelected: (param) =>
    dispatch(setAverageRevenueGameSelected(param)),
  setAverageRevenueCountrySelected: (param) =>
    dispatch(setAverageRevenueCountrySelected(param)),
  setAverageRevenueRidSelected: (param) =>
    dispatch(setAverageRevenueRidSelected(param)),    
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(AverageRevenue));
