import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import { firstTimeColor, conversionColor, regColor, chartLineWidth } from 'styles/variables';

const LineChartCampaignRidTrend = ({
    trendDataLoading,
    campaignFirstTimeDataByRid,
    campaignGameVisitDataByRid,
    campaignRegistDataByRid,
    trendChartData,
    campaignData,
}) => {
    const classes = useStyles();
    const [seriesType, setSeriesType] = React.useState('line');
    const [stackState, setStackState] = React.useState(null);
    const [zoomStart, setZoomStart] = React.useState(0);
    const [zoomEnd, setZoomEnd] = React.useState(100);

    useEffect(() => {
        echarts.registerTheme('BarChartTheme', ChartStyle);
        console.log('H.campaignData:', campaignData);
    }, []);

    useEffect(() => {
        console.log('campaignRegistDataByRid in effect: ', campaignRegistDataByRid)
    }, [campaignRegistDataByRid]);

    const magicTypeChanged = (param) => {
        // if (param.currentType === 'stack') {
        //     this.setState({ stackState: '__ec_magicType_stack__' });
        // } else if (param.currentType === 'tiled') {
        //     this.setState({ stackState: null });
        // } else {
        //     this.setState({ seriesType: param.currentType });
        // }
    }

    const getSeriesData = (trendChartData) => {
        if (trendChartData.length === 0) {
            return [];
        }
        let seriesData = [
            {
                name: '初アクセス',
                type: 'line',
                //stack: this.state.stackState,
                data: trendChartData[0]['first'],
                lineStyle: {
                    width: chartLineWidth,
                    color: firstTimeColor
                },
                showSymbol: true,
                symbolSize: 8,
                showAllSymbol: false,
                barMaxWidth: 25,
                barGap: '8%',
            },
            {
                name: 'ゲーム遷移',
                type: 'line',
                //stack: this.state.stackState,
                data: trendChartData[0]['conv'],
                lineStyle: {
                    width: chartLineWidth,
                    color: conversionColor
                },
                showSymbol: true,
                symbolSize: 8,
                showAllSymbol: false,
                barMaxWidth: 25,
                barGap: '8%',
            },
            {
                name: '登録',
                type: 'line',
                //stack: this.state.stackState,
                data: trendChartData[0]['reg'],
                lineStyle: {
                    width: chartLineWidth,
                    color: regColor
                },
                showSymbol: true,
                symbolSize: 8,
                showAllSymbol: false,
                barMaxWidth: 25,
                barGap: '8%',
            },
        ]
        return seriesData;
        // if (this.props.affiliatorType !== 'campaign_game') {
        //     return seriesData;
        // } else {
        //     const newArray = seriesData.filter(function (eachElem, index) {
        //         if (index !== 1) {
        //             return eachElem;
        //         }
        //     })
        //     return newArray;
        // }
    }

    const onEvents = {
        'magictypechanged': magicTypeChanged,
        // 'datazoom': this.dataZoomChanged
    }

    return (
        <div className={classes.chartContainer}>
            {trendDataLoading ? (
                <div className={classes.progress}><CircularProgress /></div>
            ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                            order:'valueDesc',
                        },
                        legend: {
                            data: ['初アクセス', 'ゲーム遷移', '登録'] //campaignType !== 'campaign_game' ? ['初アクセス', 'ゲーム遷移', '登録'] : ['初アクセス', '登録']
                        },
                        toolbox: {
                            show: false,
                            feature: {
                                magicType : {
                                    type: ['line', 'bar', /*'stack'*/],
                                    title: {
                                        line: "Line",
                                        bar: "Bar",
                                        //stack: "Stack",
                                    },
                                    icon: {
                                        line: 'image://static/images/font-icon/line-chart.svg',
                                        bar: 'image://static/images/font-icon/bar-chart.svg',
                                    }
                                },
                            }
                        },
                        xAxis: {
                          type: "category",
                          data: trendChartData.length > 0 ? trendChartData[0]['date'] : []
                        },
                        yAxis: {
                          type: "value"
                        },
                        series: getSeriesData(trendChartData),
                        color:[firstTimeColor, conversionColor, regColor] //campaignType !== 'campaign_game' ? [firstTimeColor, conversionColor, regColor] : [firstTimeColor, regColor]
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                />
            )}
        </div>
    )
}

LineChartCampaignRidTrend.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    trendDataLoading: state.campaign.trendDataLoading,
    campaignFirstTimeDataByRid: state.campaign.campaignFirstTimeDataByRid,
    campaignGameVisitDataByRid: state.campaign.campaignGameVisitDataByRid,
    campaignRegistDataByRid: state.campaign.campaignRegistDataByRid,
    trendChartData: state.campaign.trendChartData,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(LineChartCampaignRidTrend);

const useStyles = makeStyles((theme) =>
    createStyles({
        progress: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
        },
        chartContainer: {
            marginTop: -16,
            width: '100%',
            display: 'block'
        }
    }),
);