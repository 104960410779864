import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ChartStyle from "styles/ChartStyle";
import { chartLineWidth } from "styles/variables";
import ChartConfig from "utils/ChartConfig";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    marginTop: 16,
    marginLeft: 16,
    width: "100%",
    display: "block",
  },
}));

const LineChartPictreeCheckIn = ({
  legend,
  lblSaveImage,
  xAisData,
  seriesData,
  chartType,
  kpiSalesDataSizeSelected,
}) => {
  const classes = useStyles();
  const [seriesType, setSeriesType] = useState(chartType);
  const [chartData, setChartData] = useState([]);
  const [color, setColorArray] = useState([]);
  const [stackState, setStackState] = useState("");

  useEffect(() => {
    echarts.registerTheme("BarChartTheme", ChartStyle);
  }, []);

  useEffect(() => {
    let eachChartSeries = {},
      seriesDataArray = [],
      colorArray = ChartConfig.kpiSalesLegendColor;
    _.forOwn(seriesData, function (value, key) {
      _.forOwn(value, function (value2, key2) {
        if (key2 !== "date") {
          eachChartSeries.name = ChartConfig.chartLabelPicTreeCheckIn[key2];
          eachChartSeries.type = key2 === "count" ? "line" : "bar";
          if (key2 === "unique") {
            eachChartSeries.yAxisIndex = 1;
          }
          eachChartSeries.data = value2;
          eachChartSeries.lineStyle = { width: chartLineWidth };
          eachChartSeries.symbolSize =
            seriesData[0]["date"].length > 60 ? 4 : 8;
          eachChartSeries.barGap = 0;
          eachChartSeries.barMaxWidth = 20;
          seriesDataArray.push(eachChartSeries);
          eachChartSeries = {};
        }
      });
    });
    setChartData(seriesDataArray);
    setColorArray(colorArray);
  }, [seriesData, seriesType]);

  const magicTypeChanged = (param) => {
    if (param.currentType === "stack") {
      setStackState("__ec_magicType_stack__");
    } else if (param.currentType === "tiled") {
      setStackState(null);
    } else {
      setSeriesType(param.currentType);
    }
  };

  const onEvents = {
    magictypechanged: magicTypeChanged,
  };

  return (
    <div className={classes.chartContainer}>
      <ReactEcharts
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "none",
            },
          },
          grid: {
            left: 64,
            top: 48,
            right: 48,
            bottom: 32,
          },
          legend: {
            show: true,
            data: legend,
          },
          toolbox: {
            show: false,
            feature: {
              magicType: {
                show: false,
                type: ["bar", "line"],
                title: {
                  line: "Line",
                  bar: "Bar",
                  stack: "Stack",
                  tiled: "Side by Side",
                },
                icon: {
                  line: "image://../../static/images/font-icon/line-chart.svg",
                  bar: "image://../../static/images/font-icon/bar-chart.svg",
                },
              },
              saveAsImage: {
                show: true,
                title: "Save",
                name: lblSaveImage,
                icon: "image://../../static/images/font-icon/save-chart.svg",
              },
            },
          },
          xAxis: {
            type: "category",
            data: xAisData,
          },
          yAxis: [
            {
              type: 'value',
              name: 'チェックイン数',
              alignTicks: true,
              axisLine: {
                show: true,
              },
              axisLabel: {
                formatter: '{value}'
              }
            },
            {
              type: 'value',
              name: 'ユーザー数',
              alignTicks: true,
              axisLine: {
                show: true,
              },
              axisLabel: {
                formatter: '{value}'
              }
            },
          ],
          series: chartData,
          color: color,
        }}
        theme="BarChartTheme"
        notMerge={true}
        lazyUpdate={true}
        onEvents={onEvents}
        style={{ height: "350px", width: "98%" }}
      />
    </div>
  );
};

LineChartPictreeCheckIn.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  kpiSalesDataSizeSelected: state.kpi.kpiSalesDataSizeSelected,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(LineChartPictreeCheckIn));
