import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Chart from "react-google-charts";
import { withStyles } from '@material-ui/core/styles';
import * as V from 'styles/variables';

const RegCountryMap = ({classes, setCurrentModule, source, countryByAd, countryByOrg, width, height }) => {
    const [geoMapData, setGeoMapData] = useState([]);
    useEffect(() => {
        //console.log('yoyo');
    }, []);

    useEffect(() => {
        let dataSource = source === 'ad' ? countryByAd : countryByOrg;
        let dataArray = [], countryData = [], dataObj = {};
        for (let i=0; i<dataSource.length; i++) {
            countryData.push(dataSource[i]['country']);
            countryData.push(dataSource[i]['count']);
            dataArray.push(countryData);
            countryData = [];
        }
        dataArray.unshift(['Country', '登録数']);
        setGeoMapData(dataArray);
    }, [countryByAd, countryByOrg]);

    return (
        <Fragment>
            <Chart
                width={width}
                height={height}
                chartType="GeoChart"
                data={geoMapData}
                options={{
                    colorAxis: { colors: [V.mapPolygonMinColor, V.mapPolygonMaxColor] },
                    backgroundColor: '#fff',
                    // datalessRegionColor: '#f8bbd0',
                    //defaultColor: '#f5f5f5',
                }}
                mapsApiKey={'somekey'}
                rootProps={{ 'data-testid': '1' }}
            />
        </Fragment>
    )
};

RegCountryMap.propTypes = {
    classes: PropTypes.object,
    countryByAd: PropTypes.array,
    countryByOrg:  PropTypes.array,
};

const mapStateToProps = state => ({
    countryByAd: state.puzzleGeo.countryByAd,
    countryByOrg: state.puzzleGeo.countryByOrg,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(RegCountryMap));