import React, { useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import { withStyles, makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import SelectDateRanges from 'components/Select/SelectDateRanges';
import SelectDateRangesPicTree from 'components/Select/SelectDateRangesPicTree';
import * as Var from 'styles/variables';
import { setDateRange, setDateRangePicTree, setPrevDateRange, setPrevDateRangePicTree, getHomePageData, setBtnStateDateFetch,
    setComparisonDateDiff, setComparisonDateDiffDisplay, getCampaignSummaryDataByDates, setCampaignDateRange,
    getPuzzlePageData, getActiveUsersData, setMauDateRange, setMauPrevDateRange, setWauDateRange, setWauPrevDateRange,
    getSalesOverviewData, getScholarOwnerScholarStatsData, getJtcbScholarDataByGuild, getJtcbScholarTrendData, getJtcbScholarAmuletData, getJtcbScholarAmuletDataByJob,
    getOverviewDataByCountry, getOverviewDataByPmid, getOverviewDataByGame, getOverviewDataByCampaign, getCbScholarOwnerScholarStatsData,
    getSalesOverviewDataByGame, getOverviewGameDataByCountry, getInGameItemSalesOverviewData, getInGameItemSalesByCountry, getInGameItemSalesByItem, getInGameItemSalesByPmid,
    getInGameItemSalesTrendData, getInGameItemSalesTrendItemsData, setPageNumber, getHomePictreeUser, getHomePictreeCheckIn, getHomePictreePhotoShoot, getPhotoShootDetailsData, getPictreeDataCheckInTrend,
    getPictreeDataRegistTrend, getPictreeDataPhotoShootTrend, getSalesOverviewDataPicTree, getSalesTrendDataPicTree,
    getPointOverviewDataPicTree, getPointTrendDataPicTree, getPictreeDataPhotoDenchuKaisuTrend,
    getInGameItemSalesByOccurrence, getPhotoShootMapData, getNftSalesByOccurrence
} from 'actions';

const useStyles = makeStyles((theme) =>
    createStyles({
        calendarWrapper: {
            paddingLeft: 8,
            flexGrow: 1,
        },
        root: {
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        container: {
            display: 'flex',
            width: 300,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            alignItems: 'baseline',
        },
        delimiter: {
            paddingTop: theme.spacing(1)
        },
        dataBtn: {
            marginTop: theme.spacing(1.2),
            marginLeft: theme.spacing(1),
            height: theme.spacing(4),
            color: Var.btnLabelColor,
        },
        grow: {
            flexGrow: 1,
        },
        sectionCalendar: {
            display:'flex',
            marginRight: theme.spacing(1),
        },
        sectionDateRange: {
            marginLeft: theme.spacing(0)
        }
    }),
);

const CalendarRangePicker = ({
    mDate,
    setDateRange,
    setDateRangePicTree,
    setCampaignDateRange,
    setPrevDateRange,
    setPrevDateRangePicTree,
    setComparisonDateDiff,
    setComparisonDateDiffDisplay,
    setMauDateRange,
    setMauPrevDateRange,
    setWauDateRange,
    setWauPrevDateRange,
    getHomePageData,
    getPuzzlePageData,
    getActiveUsersData,
    getCampaignSummaryDataByDates,
    page,
    pictree,
    btnStateDataFetch,
    setBtnStateDateFetch,
    dateRange,
    dateRangePicTree,
    mauDateRange,
    wauDateRange,
    campaignDateRange,
    currentPage,
    campaignPreDefinedDateRange,
    comparisonDateDiff,
    getSalesOverviewData,
    getScholarOwnerScholarStatsData,
    getJtcbScholarDataByGuild,
    getJtcbScholarTrendData,
    getJtcbScholarAmuletData,
    getJtcbScholarAmuletDataByJob,
    tabFocused,
    getOverviewDataByCountry, getOverviewDataByPmid, getOverviewDataByGame, getOverviewDataByCampaign,
    getCbScholarOwnerScholarStatsData,
    getSalesOverviewDataByGame,
    getOverviewGameDataByCountry,
    getInGameItemSalesOverviewData,
    getInGameItemSalesByCountry,
    getInGameItemSalesByItem,
    getInGameItemSalesByPmid,
    getInGameItemSalesTrendData,
    getInGameItemSalesTrendItemsData,
    setPageNumber,
    getHomePictreeUser,
    getHomePictreeCheckIn,
    getHomePictreePhotoShoot,
    getPhotoShootDetailsData,
    getPictreeDataRegistTrend,
    getPictreeDataCheckInTrend,
    pictreeView,
    getPictreeDataPhotoShootTrend,
    getSalesOverviewDataPicTree,
    getSalesTrendDataPicTree,
    pictreeChartSize,
    getPointOverviewDataPicTree,
    getPointTrendDataPicTree,
    getPictreeDataPhotoDenchuKaisuTrend,
    getInGameItemSalesByOccurrence,
    getPhotoShootMapData,
    pictreeMapType,
    getNftSalesByOccurrence,
}) => {
    const classes = useStyles();
    const minDate = moment(mDate);
    const maxDate = pictree
    ? moment().tz("Asia/Tokyo").format('YYYY/MM/DD')
    : moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
    const [dateRangeValue, setDateRangeValue] = useState([null, null]);
    const [error, setError] = useState([null, null]);

    useEffect(() => {
        // Check daily default setting
        const currentJstDate = moment().tz("Asia/Tokyo").format('YYYY/MM/DD');
        if (page === 'campaignSummary') {
            handleCampaignCalendar(currentJstDate);
        } else {
            handleHomeCalendar(currentJstDate);
        }
    }, []);

    useEffect(() => {
        setDateRangeValue(pictree ? dateRangePicTree : dateRange)
    }, [dateRange, dateRangePicTree]);

    useEffect(() => {
        const dayTo = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        const sgEndDateToFetch = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        const oneDayAgoCalForm = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        if (campaignPreDefinedDateRange === '') {
            setDateRangeValue([oneDayAgoCalForm, dayTo]);
            setDateRange(['2020-03-11', sgEndDateToFetch]);
        } else if (campaignPreDefinedDateRange === 'all'){
            setDateRangeValue(['2020/03/11', dayTo]);
            setDateRange(['2020-03-11', sgEndDateToFetch]);
        } else {
            const dayFrom = moment().tz("Asia/Singapore").subtract(campaignPreDefinedDateRange, 'days').format('YYYY/MM/DD');
            const sgStartDateToFetch = moment().tz("Asia/Singapore").subtract(campaignPreDefinedDateRange, 'days').format('YYYY-MM-DD');
            setDateRangeValue([dayFrom, dayTo]);
            setDateRange([sgStartDateToFetch, sgEndDateToFetch]);
        }
        setBtnStateDateFetch(true);
    }, [campaignPreDefinedDateRange]);

    const handleHomeCalendar = (currentJstDate) => {
        if (localStorage.getItem('dailyAccessDate') === null) {
            setDefaultDate(currentJstDate, page);
        } else {
            if (dateRange.length === 0) {
                setDateRangeValue([moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD')]);
                let dayDiff = getDayDiff(moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD'));
                setComparisonDateDiff(dayDiff);
                let prevDates = getPrevDates(moment().subtract(1, 'days').format('YYYY/MM/DD'), moment().format('YYYY/MM/DD'), dayDiff);
                setPrevDateRange(prevDates);
            } else if (pictree) {
                setDateRangeValue(dateRangePicTree);

                let dayDiff = getDayDiff(dateRangePicTree[0], dateRangePicTree[1]) === 0 ? 1 : getDayDiff(dateRangePicTree[0], dateRangePicTree[1]);
                setComparisonDateDiff(dayDiff);

                // DAY
                let prevDates = getPrevDates(dateRangePicTree[0], dateRangePicTree[1], dayDiff);
                setPrevDateRangePicTree(prevDates);
            } else {
                setDateRangeValue(dateRange)
                let dayDiff = getDayDiff(dateRange[0], dateRange[1]) === 0 ? 1 : getDayDiff(dateRange[0], dateRange[1]);
                setComparisonDateDiff(dayDiff);
                let prevDates = getPrevDates(dateRange[0], dateRange[1], dayDiff);
                setPrevDateRange(prevDates);

                const mauPrevDateEnd = moment(mauDateRange[0]).subtract(1, 'days').format('YYYY/MM/DD');
                const mauPrevDateBegin = moment(mauPrevDateEnd).subtract(29, 'days').format('YYYY/MM/DD');
                setMauPrevDateRange([mauPrevDateBegin, mauPrevDateEnd]);

                const wauPrevDateEnd = moment(wauDateRange[0]).subtract(1, 'days').format('YYYY/MM/DD');
                const wauPrevDateBegin = moment(wauPrevDateEnd).subtract(6, 'days').format('YYYY/MM/DD');
                setWauPrevDateRange([wauPrevDateBegin, wauPrevDateEnd]);
            }
        }
    }

    const handleCampaignCalendar = (currentJstDate) => {
        if (localStorage.getItem('dailyAccessDate') === null) {
            setCampaignDefaultDate(currentJstDate, page);
        } else {
            // exist, is it yesterday?
            let dayDifference = moment(currentJstDate).diff(moment(localStorage.getItem('dailyAccessDate')), 'days');
            if (dayDifference > 0) {
                // Set day before
                setCampaignDefaultDate(currentJstDate);
            } else {
                if (campaignDateRange.length === 0) {
                    setDateRangeValue([moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD')]);
                } else {
                    setDateRangeValue(campaignDateRange);
                }
            }
        }
    }

    const getDayDiff = (dayBegin, dayEnd) => {
        let diffDays = moment(dayEnd).diff(moment(dayBegin), 'days')+1;
        return diffDays
    }

    const getPrevDates = (dayBegin, dayEnd, dayDiff) => {
        let startPrevDate = moment(dayBegin).subtract(dayDiff, 'days').format('YYYY-MM-DD');
        let endPrevDate = moment(dayEnd).subtract(dayDiff, 'days').format('YYYY-MM-DD');
        return [startPrevDate, endPrevDate];
    }

    const setDefaultDate = (currentJstDate) => {
        const dayBefore = moment().tz("Asia/Tokyo").subtract(1, 'days').format('YYYY/MM/DD');
        const dayBeforeFormatted = moment().tz("Asia/Tokyo").subtract(1, 'days').format('YYYY-MM-DD');
        const dayPrevBefore = moment().tz("Asia/Tokyo").subtract(2, 'days').format('YYYY/MM/DD');
        setDateRange([dayBeforeFormatted, dayBeforeFormatted]);
        setDateRangeValue([dayBefore, dayBefore]);
        setComparisonDateDiff(1);
        setPrevDateRange([dayPrevBefore, dayPrevBefore]);
        localStorage.setItem('dailyAccessDate', currentJstDate);
    }

    const setCampaignDefaultDate = (currentJstDate) => {
        const dayFrom = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD');
        const dayTo = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
        const sgStartDateToFetch = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
        const sgEndDateToFetch = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        setDateRangeValue([dayFrom, dayTo]);
        setDateRange([sgStartDateToFetch, sgEndDateToFetch]);
        localStorage.setItem('dailyAccessDate', currentJstDate);
    };

    const setRange = (newValue) => {
        if (newValue[0] === null || newValue[1] === null) {
            setBtnStateDateFetch(false);
            return
        }
        const startDate = moment(newValue[0]).format('YYYY-MM-DD');
        const endDate = moment(newValue[1]).format('YYYY-MM-DD');

        if (page === 'campaignSummary') {
            setCampaignDateRange([startDate, endDate]);
        } else if (pictree) {
            setDateRangePicTree([startDate, endDate]);
            let dayDiff = getDayDiff(startDate, endDate);
            setComparisonDateDiff(dayDiff);
            let prevDates = getPrevDates(startDate, endDate, dayDiff);
            setPrevDateRangePicTree(prevDates);

            //@todo: set week, month prev dates
        } else {
            setDateRange([startDate, endDate]);

            let dayDiff = getDayDiff(startDate, endDate);
            setComparisonDateDiff(dayDiff);

            let prevDates = getPrevDates(startDate, endDate, dayDiff);
            setPrevDateRange(prevDates);

            // MAU Dates: calculate last 30 days of MAU and set
            const mauBeginDate = moment(endDate).tz("Asia/Singapore").subtract(29, 'days').format('YYYY/MM/DD');
            setMauDateRange([mauBeginDate, endDate]);

            const mauPrevBeginDate = moment(mauBeginDate).tz("Asia/Singapore").subtract(30, 'days').format('YYYY/MM/DD');
            const mauPrevEndDate = moment(mauBeginDate).tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
            setMauPrevDateRange([mauPrevBeginDate, mauPrevEndDate]);

            // WAU Dates: calculate last 7 days of WAU and set
            const wauBeginDate = moment(endDate).tz("Asia/Singapore").subtract(6, 'days').format('YYYY/MM/DD');
            setWauDateRange([wauBeginDate, endDate]);

            const wauPrevBeginDate = moment(wauBeginDate).tz("Asia/Singapore").subtract(7, 'days').format('YYYY/MM/DD');
            const wauPrevEndDate = moment(wauBeginDate).tz("Asia/Singapore").subtract(1, 'days').format('YYYY/MM/DD');
            setWauPrevDateRange([wauPrevBeginDate, wauPrevEndDate]);
        }
        setDateRangeValue([startDate, endDate]);
        setBtnStateDateFetch(true);
    }

    const fetchData = () => {
        setPageNumber(1);
        setComparisonDateDiffDisplay(comparisonDateDiff);
        switch (currentPage) {
            case 'home':
                getHomePageData();
                break;
            case 'nftdep/overview':
                getSalesOverviewData();
                switch(tabFocused) {
                    case 'country':
                        getOverviewDataByCountry();
                        break;
                    case 'pmid':
                        getOverviewDataByPmid();
                        break;
                    case 'game':
                        getOverviewDataByGame();
                        break;
                    case 'campaign':
                        getOverviewDataByCampaign();
                        break;
                    default:
                }
                break;
            case 'campaignSummary':
                getCampaignSummaryDataByDates();
                break;
            case 'jobtribes/scholar/overview':
                getScholarOwnerScholarStatsData();
                break;
            case 'jobtribes/scholar/guildkpi':
                getJtcbScholarDataByGuild();
                break;
            case 'jobtribes/scholar/trend':
                getJtcbScholarTrendData();
                break;
            case 'jobtribes/scholar/amulet':
                getJtcbScholarAmuletData();
                getJtcbScholarAmuletDataByJob();
                break;
            case 'cookinburger/scholar/overview':
                getCbScholarOwnerScholarStatsData();
                break;
            case 'puzzle':
                getPuzzlePageData();
                getActiveUsersData();
                break;
            default:
        }
        // Sales summary by game data fetch
        const game = window.location.pathname.split("/");
        if (currentPage.indexOf("game") !== -1 &&
            currentPage.indexOf("overview") !== -1 &&
            currentPage.indexOf("ingame-sale") === -1) {
                getSalesOverviewDataByGame(game[2]);
                getOverviewGameDataByCountry(game[2]);
        } else if (currentPage.indexOf("ingame-sale/overview") !== -1) {
            getInGameItemSalesOverviewData(game[2]);
            switch(tabFocused) {
                case 'country':
                    getInGameItemSalesByCountry(game[2]);
                    break;
                case 'item':
                    getInGameItemSalesByItem(game[2]);
                    break;
                case 'pmid':
                    getInGameItemSalesByPmid(game[2]);
                    break;
                default:
            }
        } else if (currentPage.indexOf("ingame-sale/trend") !== -1) {
            getInGameItemSalesTrendData(game[2]);
            getInGameItemSalesTrendItemsData(game[2]);
        } else if (currentPage.indexOf("ingame-sale/appearance") !== -1) {
            getInGameItemSalesByOccurrence(game[2]);
        } else if (currentPage.indexOf("marketplace-sale/appearance") !== -1) {
            getNftSalesByOccurrence(game[2]);
        }

        // PicTree
        if (currentPage === "/game/pictree/home") {
            getHomePictreeUser();
            getSalesOverviewDataPicTree();
            getHomePictreeCheckIn();
            getHomePictreePhotoShoot();
            getPointOverviewDataPicTree();
        } else if (currentPage === "/game/pictree/sales") {
            getSalesOverviewDataPicTree();
            if (pictreeView === "sales/chart") {
                getSalesTrendDataPicTree();
            }
        } else if (currentPage === "/game/pictree/registration") {
            getHomePictreeUser();
            if (pictreeView === "registration/chart") {
                getPictreeDataRegistTrend();
            }
        } else if (currentPage === "/game/pictree/checkin") {
            getHomePictreeCheckIn();
            if (pictreeView === "checkin/chart") {
                getPictreeDataCheckInTrend(pictreeChartSize);
            }
        } else if (currentPage === "/game/pictree/photos/overview") {
            getHomePictreePhotoShoot();
            if (pictreeView === "photo/chart") {
                getPictreeDataPhotoShootTrend(pictreeChartSize);
            } else if (pictreeView === "photo/map") {
                getPhotoShootDetailsData();
            }
        } else if (currentPage === "/game/pictree/photos/denchu/appearance") {
            //getHomePictreePhotoShoot();
            if (pictreeView === "photo/chart") {
                getPictreeDataPhotoDenchuKaisuTrend(pictreeChartSize);
            } else if (pictreeView === "photo/map") {
                //getPhotoShootDetailsData();
            }
        } else if (currentPage === "/game/pictree/point") {
            getPointOverviewDataPicTree();
            if (pictreeView === "sales/point/chart") {
                getPointTrendDataPicTree();
            } else if (pictreeView === "sales/point/table") {

            }
        } else if (currentPage === "/game/pictree/photos/map") {
            getPhotoShootMapData({type: "denchu", filter: pictreeMapType});
        }
    }

    return (
        <div className={classes.calendarWrapper}>
            <div className={classes.root}>
                <div className={classes.sectionCalendar}>
                    <DateRangePicker
                        startText="Start"
                        endText="End"
                        disableHighlightToday={true}
                        showToolbar={false}
                        allowSameDateSelection={true}
                        minDate={minDate}
                        maxDate={maxDate}
                        value={dateRangeValue}
                        onChange={(newValue) => setRange(newValue)}
                        onError={([startReason, endReason], [start, end]) => {
                            if (startReason === 'invalidRange') {
                                setError([null, null])
                                return;
                            }
                            setError([startReason, endReason])
                        }}
                        renderInput={(startProps, endProps) => (
                            <div className={classes.container}>
                                <TextField id="standard-basic" {...startProps} error={Boolean(error[0])} size="small" disabled={page === "pictree-map" ? true : false} helperText="" />
                                <DateRangeDelimiter><span className={classes.delimiter}>~</span></DateRangeDelimiter>
                                <TextField id="standard-basic" {...endProps} error={Boolean(error[0])} size="small" disabled={page === "pictree-map" ? true : false} helperText="" />
                            </div>
                        )}
                    />
                    <Button disabled={!btnStateDataFetch} className={classes.dataBtn} variant="contained" color="primary" onClick={() => fetchData()}>GO</Button>
                        <div className={classes.sectionDateRange}>
                        {!pictree
                        ?
                            <SelectDateRanges
                                page={page}
                                getDayDiff={getDayDiff}
                                setComparisonDateDiff={setComparisonDateDiff}
                                setPrevDateRange={setPrevDateRange}
                                getPrevDates={getPrevDates}
                            />
                        :
                            <SelectDateRangesPicTree
                                page={page}
                                getDayDiff={getDayDiff}
                                setComparisonDateDiff={setComparisonDateDiff}
                                setPrevDateRange={setPrevDateRange}
                                getPrevDates={getPrevDates}
                            />
                        }
                        </div>
                </div>
            </div>
        </div>
    );
}

CalendarRangePicker.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    btnStateDataFetch: state.page.btnStateDataFetch,
    dateRange: state.page.dateRange,
    dateRangePicTree: state.page.dateRangePicTree,
    mauDateRange: state.page.mauDateRange,
    wauDateRange: state.page.wauDateRange,
    campaignDateRange: state.campaign.campaignDateRange,
    currentPage: state.page.currentPage,
    campaignPreDefinedDateRange: state.campaign.campaignPreDefinedDateRange,
    comparisonDateDiff: state.page.comparisonDateDiff,
    tabFocused: state.nftDepOverview.tabFocused,
    pictreeView: state.pictree.pictreeView,
    pictreeChartSize: state.pictree.chartSize,
    pictreeMapType: state.pictree.mapType,
});

const mapDispatchToProps = (dispatch) => ({
    setDateRange: (param) => dispatch(setDateRange(param)),
    setDateRangePicTree: (param) => dispatch(setDateRangePicTree(param)),
    setCampaignDateRange: (param) => dispatch(setCampaignDateRange(param)),
    setPrevDateRange: (param) => dispatch(setPrevDateRange(param)),
    setPrevDateRangePicTree: (param) => dispatch(setPrevDateRangePicTree(param)),
    setComparisonDateDiff: (param) => dispatch(setComparisonDateDiff(param)),
    setComparisonDateDiffDisplay: (param) => dispatch(setComparisonDateDiffDisplay(param)),
    setMauDateRange: (param) => dispatch(setMauDateRange(param)),
    setMauPrevDateRange: (param) => dispatch(setMauPrevDateRange(param)),
    setWauDateRange: (param) => dispatch(setWauDateRange(param)),
    setWauPrevDateRange: (param) => dispatch(setWauPrevDateRange(param)),
    getHomePageData: () => dispatch(getHomePageData()),
    getCampaignSummaryDataByDates: () => dispatch(getCampaignSummaryDataByDates()),
    setBtnStateDateFetch: (param) => dispatch(setBtnStateDateFetch(param)),
    getPuzzlePageData: () => dispatch(getPuzzlePageData()),
    getActiveUsersData: () => dispatch(getActiveUsersData()),
    getSalesOverviewData: () => dispatch(getSalesOverviewData()),
    getScholarOwnerScholarStatsData: () => dispatch(getScholarOwnerScholarStatsData()),
    getJtcbScholarDataByGuild: () => dispatch(getJtcbScholarDataByGuild()),
    getJtcbScholarTrendData: () => dispatch(getJtcbScholarTrendData()),
    getJtcbScholarAmuletData: () => dispatch(getJtcbScholarAmuletData()),
    getJtcbScholarAmuletDataByJob: () => dispatch(getJtcbScholarAmuletDataByJob()),
    getOverviewDataByCountry: () => dispatch(getOverviewDataByCountry()),
    getOverviewDataByPmid: () => dispatch(getOverviewDataByPmid()),
    getOverviewDataByGame: () => dispatch(getOverviewDataByGame()),
    getOverviewDataByCampaign: () => dispatch(getOverviewDataByCampaign()),
    getCbScholarOwnerScholarStatsData: () => dispatch(getCbScholarOwnerScholarStatsData()),
    getSalesOverviewDataByGame: (param) => dispatch(getSalesOverviewDataByGame(param)),
    getOverviewGameDataByCountry: (param) => dispatch(getOverviewGameDataByCountry(param)),
    getInGameItemSalesOverviewData: (param) => dispatch(getInGameItemSalesOverviewData(param)),
    getInGameItemSalesByCountry: (param) => dispatch(getInGameItemSalesByCountry(param)),
    getInGameItemSalesByItem: (param) => dispatch(getInGameItemSalesByItem(param)),
    getInGameItemSalesByPmid: (param) => dispatch(getInGameItemSalesByPmid(param)),
    getInGameItemSalesTrendData: (param) => dispatch(getInGameItemSalesTrendData(param)),
    getInGameItemSalesTrendItemsData: (param) => dispatch(getInGameItemSalesTrendItemsData(param)),
    setPageNumber: (param) => dispatch(setPageNumber(param)),
    getHomePictreeUser: (param) => dispatch(getHomePictreeUser(param)),
    getHomePictreeCheckIn: (param) => dispatch(getHomePictreeCheckIn(param)),
    getHomePictreePhotoShoot: (param) => dispatch(getHomePictreePhotoShoot(param)),
    getPhotoShootDetailsData: (param) => dispatch(getPhotoShootDetailsData(param)),
    getPictreeDataRegistTrend: () => dispatch(getPictreeDataRegistTrend()),
    getPictreeDataCheckInTrend: (param) => dispatch(getPictreeDataCheckInTrend(param)),
    getPictreeDataPhotoShootTrend: (param) => dispatch(getPictreeDataPhotoShootTrend(param)),
    getPictreeDataPhotoDenchuKaisuTrend: (param) => dispatch(getPictreeDataPhotoDenchuKaisuTrend(param)),
    getSalesOverviewDataPicTree: () => dispatch(getSalesOverviewDataPicTree()),
    getSalesTrendDataPicTree: () => dispatch(getSalesTrendDataPicTree()),
    getPointOverviewDataPicTree: () => dispatch(getPointOverviewDataPicTree()),
    getPointTrendDataPicTree: () => dispatch(getPointTrendDataPicTree()),
    getInGameItemSalesByOccurrence: (param) => dispatch(getInGameItemSalesByOccurrence(param)),
    getPhotoShootMapData: (param) => dispatch(getPhotoShootMapData(param)),
    getNftSalesByOccurrence: (param) => dispatch(getNftSalesByOccurrence(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(CalendarRangePicker));
