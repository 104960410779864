import { AFFILIATE } from '../constants';

const initialState  = {
    affiliatorCode: false,
    affiliatorName: '',
    affiliatorEmail: '',
    affiliatorType: '',
    affiliatorGame: '',
    affiliatorGroup: [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}],
    affiliatorGroupSelected: [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}],
    affiliatorMedia: [],
    affiliatorFT: 0,
    affiliatorReg: 0,
    affiliatorConv: 0,
    sidebarStatus: {
        name: false,
        email: false,
        type: false,
        game: false,
    },
    createBtnState: false,
    affiliateListData: [],
    activeAffiliateNumber: 0,
    affiliateIdToUpdate: '',
    submitAffiliate: false,
    listDataFetchLoop: false,
    updateAffiliate: false,
    lastUpdateDateTime: null,
    trendChartData: [],
    loadingTrendData: false,
    noTrendData: false,
    allBIUsers: [],
};

export default function affiliateReducer(state = initialState, action) {
    switch (action.type) {
        case AFFILIATE.SET_CREATE_STATUS:
            return {
                ...state,
                sidebarStatus: { ...state.sidebarStatus, [action.filter]: action.value }
            };
        case AFFILIATE.SET_AFFILIATOR_NAME:
            return {
                ...state,
                affiliatorName: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_EMAIL:
            return {
                ...state,
                affiliatorEmail: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_TYPE:
            return {
                ...state,
                affiliatorType: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_GAME:
            return {
                ...state,
                affiliatorGame: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_GROUP:
            return {
                ...state,
                affiliatorGroup: action.payload
            };
        case AFFILIATE.GET_AFFILIATOR_GROUP_SELECTED:
            const affiliatorsList = JSON.parse(localStorage.getItem('affiliatorsList'));
            let selectedGroups = [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}];
            let clientObj = {};
            for (let i = 0; i<affiliatorsList.length; i++) {
                if (Number(action.payload) === affiliatorsList[i]['AffiliateId'] && affiliatorsList[i]['AccessKey']) {
                    if (affiliatorsList[i]['AccessKey'].indexOf(",") !== -1) {
                        selectedGroups = [];
                        let groupArray = affiliatorsList[i]['AccessKey'].split(",");
                        const allBiList = JSON.parse(localStorage.getItem('allBIUsers'));
                        for (let j=0; j<groupArray.length; j++) {
                            console.log(allBiList.find(function(obj){ return obj.key===groupArray[j] }).value)
                            clientObj.name = groupArray[j] === "DeRKXQzmsg9YMY4NrDZ8aqxp4yJh" ? "DEA" : allBiList.find(function(obj){ return obj.key===groupArray[j] }).value;
                            clientObj.accessKey = groupArray[j];
                            selectedGroups.push(clientObj);
                            clientObj = {};
                        }
                    } else {
                        if (affiliatorsList[i]['AccessKey'] && affiliatorsList[i]['AccessKey'] !== process.env.REACT_APP_SUPERUSER) {
                            clientObj.name = affiliatorsList[i]['Name'];
                            clientObj.accessKey = affiliatorsList[i]['AccessKey'];
                            selectedGroups.push(clientObj);
                            clientObj = {};
                        }
                    }
                }
            }
            // console.log('heyhey selectedGroups:', selectedGroups);
            return {
                ...state,
                affiliatorGroupSelected: selectedGroups
            };
        case AFFILIATE.SET_AFFILIATOR_GROUP_SELECTED:
            return {
                ...state,
                affiliatorGroupSelected: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_FT:
            return {
                ...state,
                affiliatorFT: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_REG:
            return {
                ...state,
                affiliatorReg: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_CONV:
            return {
                ...state,
                affiliatorConv: action.payload
            };
        case AFFILIATE.SET_AFFILIATOR_MEDIA:
            let newMediaArray = [];
            newMediaArray.push(action.payload);
            return {
                ...state,
                affiliatorMedia: newMediaArray
            };
        case AFFILIATE.SET_AFFILIATOR_CODE:
            return {
                ...state,
                affiliatorCode: action.payload
            };
        case AFFILIATE.SET_CREATE_BTN_STATE:
            return {
                ...state,
                createBtnState: action.payload
            };
        case AFFILIATE.SET_AFFILIATE_LIST_DATA:
            return {
                ...state,
                affiliateListData: action.payload
            };
        case AFFILIATE.SET_AFFILIATE_ID_TO_UPDATE:
            return {
                ...state,
                affiliateIdToUpdate: action.payload
            };
        case AFFILIATE.SET_SUBMIT_AFFILIATE:
            return {
                ...state,
                submitAffiliate: action.payload
            };
        case AFFILIATE.SET_SUMMARY_DATA_FETCH_STATE:
            return {
                ...state,
                listDataFetchLoop: action.payload
            }
        case AFFILIATE.SET_UPDATE_AFFILIATE_STATE:
            return {
                ...state,
                updateAffiliate: action.payload
            }
        case AFFILIATE.SET_ACTIVE_AFFILIATE_NUMBER:
            return {
                ...state,
                activeAffiliateNumber: action.payload
            };
        case AFFILIATE.SET_LAST_UPDATE_DATE_TIME:
            return {
                ...state,
                lastUpdateDateTime: action.payload
            };
        case AFFILIATE.SET_TREND_CHART_DATA:
            return {
                ...state,
                trendChartData: action.payload
            };
        case AFFILIATE.SET_NO_TREND_DATA:
            return {
                ...state,
                noTrendData: action.payload
            };
        case AFFILIATE.SET_LOADING_TREND_DATA:
            return {
                ...state,
                loadingTrendData: action.payload
            };
        case AFFILIATE.SET_ALL_BI_USERS_LIST:
            return {
                ...state,
                allBIUsers: action.payload
            };
        default:
            return state
    }
}