import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setOverviewTab, getOverviewDataByCountry } from "actions";
import * as C from "components";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: "#fafafa",
    },
  })
);

const ByCountry = ({
  dateRange,
  dateLoaded,
  loading,
  setOverviewTab,
  getOverviewDataByCountry,
}) => {
  const classes = useStyles();

  useEffect(() => {
    setOverviewTab("country");
    if (dateLoaded["nftdep-overview-country"] !== undefined) {
      const formatDateRagne =
        H.FormatDateHelper.formatDateRangeWithDash(dateRange);
      if (!_.isEqual(formatDateRagne, dateLoaded["nftdep-overview-country"])) {
        getOverviewDataByCountry();
      }
    } else {
      getOverviewDataByCountry();
    }
  }, []);

  return (
    <Fragment>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別オークション取引支払総額"
            source="auction"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別オークション取引件数"
            source="auction"
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別DEP ストア取引支払総額"
            source="store"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別DEP ストア取引件数"
            source="store"
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item sm={12} lg={6}>
          <C.Top10AmountByCountry
            title="国別FIAT ストア取引支払総額"
            source="fiat"
            loading={loading}
          />
        </Grid>
        <Grid item sm={12} lg={6}>
          <C.Top10TxByCountry
            title="国別FIAT ストア取引件数"
            source="fiat"
            loading={loading}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

ByCountry.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  dateRange: state.page.dateRange,
  dateLoaded: state.data.dataLoaded,
  loading: state.nftDepOverview.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setOverviewTab: (param) => dispatch(setOverviewTab(param)),
  getOverviewDataByCountry: () => dispatch(getOverviewDataByCountry()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(ByCountry));
