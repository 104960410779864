import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import * as H from "helper";
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    boxLast: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    title: {
      display: "block",
      height: 32,
      fontSize: 14,
    },
    value: {
      paddingTop: 24,
      paddingBottom: 8,
      fontSize: 14,
      marginTop: 24,
    },
  })
);

const CheckIn = ({
  homeDataTierPrev,
  homeDataCheckIn,
}) => {
  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={12} lg={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <StatBoxSlim
              title="チェックイン回数"
              value={homeDataCheckIn.checkInCount !== undefined ? homeDataCheckIn.checkInCount : "-"}
              prevValue={homeDataCheckIn.checkInCountPrev !== undefined ? homeDataCheckIn.checkInCountPrev : "-"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatBoxSlim
              title="チェックイン基数"
              value={homeDataCheckIn.checkInCountKisu !== undefined ? homeDataCheckIn.checkInCountKisu : "-"}
              prevValue={homeDataCheckIn.checkInCountKisuPrev !== undefined ? homeDataCheckIn.checkInCountKisuPrev : "-"}
              tip={"チェッックインされたユニークの基数"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatBoxSlim
              title="ユニークユーザー数"
              value={homeDataCheckIn.checkInCountUniqueUser !== undefined ? homeDataCheckIn.checkInCountUniqueUser : "-"}
              prevValue={homeDataCheckIn.checkInCountUniqueUserPrev !== undefined ? homeDataCheckIn.checkInCountUniqueUserPrev : "-"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={12} lg={6}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={4}>
            <StatBoxSlim
              title="チェックイン率"
              value={homeDataCheckIn.checkInRatio !== undefined ? homeDataCheckIn.checkInRatio : "-"}
              prevValue={homeDataTierPrev.tier1}
              suffix="%"
              tip={"ユニークのチェックイン人数 ÷ <br />ユニークのアクティブユーザー数 x 100"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              alignItems={"center"}
              direction={"row"}
              justifyContent={"flex-start"}
            >
              <Grid item xs={12}>
                <div className={classes.box}>
                  <Grid container direction={"row"} justifyContent={"flex-start"}>
                    <Grid item xs={11}>                      
                      <Typography
                        variant="caption"
                        className={classes.title}
                      >最多基</Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.numSection}>
                    <Typography variant="caption" className={classes.value}>
                      {homeDataCheckIn.checkInMost !== undefined ? homeDataCheckIn.checkInMost : "-"}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Grid
              container
              alignItems={"center"}
              direction={"row"}
              justifyContent={"flex-start"}
            >
              <Grid item xs={12}>
                <div className={classes.boxLast}>
                  <Grid container direction={"row"} justifyContent={"flex-start"}>
                    <Grid item xs={11}>                      
                      <Typography
                        variant="caption"
                        className={classes.title}
                      >最多ユーザー</Typography>
                    </Grid>
                  </Grid>
                  <div className={classes.numSection}>
                    <Typography variant="caption" className={classes.value}>
                      {homeDataCheckIn.checkInMostUser !== undefined ? homeDataCheckIn.checkInMostUser : "-"}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

CheckIn.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  homeDataTierPrev: state.home.homeDataTierPrev,
  homeDataCheckIn: state.pictree.homeDataCheckIn,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(CheckIn));
