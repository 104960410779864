import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { getTodaysSalesData } from 'actions';
import * as H from 'helper';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -16,
        marginBottom: 8,
        fontSize: 14,
    },
    itemLbl: {
        minWidth: 125,
        paddingTop: 8,
        paddingRight: 8,
        textAlign: 'right',
    },
    itemDep: {
        minWidth: 177,
        paddingTop: 8,
        paddingRight: 8
    },
    item: {
        minWidth: 115,
        paddingTop: 8,
        paddingRight: 8
    }
}));

const TodaysSales = ({
    getTodaysSalesData,
    todaysDepSales,
}) => {
    const classes = useStyles();

    useEffect(() => {
        getTodaysSalesData();
    }, []);

    return (
        <Fragment>
            <Grid container spacing={0} direction="row" className={classes.root}>
                <Grid item style={{ paddingTop: 16, paddingRight: 8 }}>
                    <Grid container spacing={0} direction="row" style={{ marginTop: -16, marginBottom: 8 }}>
                        <Grid item className={classes.itemLbl}>
                            オークション:
                        </Grid>
                        <Grid item className={classes.itemDep}>
                            DEP {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.auction.dep)} ({todaysDepSales.auction.count} 件)
                        </Grid>
                        {todaysDepSales.auction.dep !== "-" &&
                        <Fragment>
                            <Grid item className={classes.item}>
                                US$ {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.auction.usd, 2)}
                            </Grid>
                            <Grid item className={classes.item}>
                                S$ {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.auction.sgd, 2)}
                            </Grid>
                            <Grid item className={classes.item}>
                                JPY {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.auction.jpy)}
                            </Grid>
                        </Fragment>
                        }
                    </Grid>
                    <Grid container spacing={0} direction="row" style={{ marginTop: -8, marginBottom: 8 }}>
                        <Grid item className={classes.itemLbl}>
                            ストア:
                        </Grid>
                        <Grid item className={classes.itemDep}>
                            DEP {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.store.dep)} ({todaysDepSales.store.count} 件)
                        </Grid>
                        {todaysDepSales.store.dep !== '-' &&
                        <Fragment>
                            <Grid item className={classes.item}>
                                US$ {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.store.usd, 2)}
                            </Grid>
                            <Grid item className={classes.item}>
                                S$ {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.store.sgd, 2)}
                            </Grid>
                            <Grid item className={classes.item}>
                                JPY {H.FormatNumStrHelper.formatCommaThousand(todaysDepSales.store.jpy)}
                            </Grid>
                        </Fragment>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
};

TodaysSales.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    todaysDepSales: state.nftDep.todaysDepSales,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
    getTodaysSalesData: () => dispatch(getTodaysSalesData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(TodaysSales));
