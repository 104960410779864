const SideBarStateStyles = theme => ({
    button: {
        color: '#fff',
        width: 150
    },
    editButton: {
        color: '#fff',
        width: 150,
        marginBottom: 20
    },
    drawerPaper: {
        width: 220
    },
    btnCancelReport: {
        color: '#fff',
        marginTop: 85,
        marginLeft: 40
    },
    btnPreviewDiv: {
        width: 220,
        fontSize: '13px',
        position: 'absolute',
        marginLeft: 40,
        bottom: '20px',
    },
    btnChecked: {
        color: theme.palette.primary.main
    },
    btnUnChecked: {
        color: '#e0e0e0'
    }
});

export default SideBarStateStyles;