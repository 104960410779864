import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setPageTopLoader, setScholarDataLoading, setCbOverviewOwnerStatsData, setCbOverviewScholarStatsData,
    setCbPerOwnerData, setCbDeapCheckAmountData, setOwnerByCountryData, setScholarByCountryData, setUniqueOwnerCountByDateData, setUniqueScholarCountByDateData,
    setScholarCountByOwnerData, setScholarDepByOwnerData, setScholarTotalDepByDateData,
    setCbNumOwnerData, setCbNumScholarData, setScholarDepByScholarData, setScholarTotalScholarDepByDateData, setJtcbScholarTrendData, setScholarTrendDataLoading,
    setGuildDataLoading, setKogiGuildData, setKyogiGuildData, setCbScholarTotalDepData } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch } from 'api';
import { COOKINBURGER, ENDPOINT } from '../../constants';
import * as H from 'helper';
import KyogiScholar from '../../utils/KyogiScholar';

export const scholarDateRange = state => state.page.dateRange;
export const scholarDateRangePrev = state => state.page.prevDateRange;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.jtcbScholar.loading;
export const dataPerOwner = state => state.jtcbScholar.dataPerOwner;

// NOT IN USE: Slow performance
export function* getOwnerScholarStatsData() {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.owner = true;
        loadingUpdate.scholar = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const rangeTargetPrev = yield select(scholarDateRangePrev);

        // DATA2.0
        const isDataNew = moment(rangeTarget[0]).isAfter('2023-06-30') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;
        const isDataOld = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isBefore('2023-07-01') ? true : false;
        const isDataMix = moment(rangeTarget[0]).isBefore('2023-07-01') && moment(rangeTarget[1]).isAfter('2023-06-30') ? true : false;

        const allTimeUnixStampTime = [
            moment("2022-08-29 00:00:00+8:00").format('x'),
            moment(rangeTarget[1]+" 23:59:59+8:00").format('x')
        ];

        const unixStampTime = [
            moment(rangeTarget[0]+" 00:00:00+8:00").format('x'),
            moment(rangeTarget[1]+" 23:59:59+8:00").format('x')
        ];

        const unixStampTimePrev = [
            moment(rangeTargetPrev[0]+" 00:00:00+8:00").format('x'),
            moment(rangeTargetPrev[1]+" 23:59:59+8:00").format('x')
        ];

        const scholarContractTable = isDataNew ? 'CbScholarContractPmEvtProd' : 'CbScholarContractProd';
        const scholarDepTable = isDataNew ? 'CbScholarDepPmEvtProd' : 'CbScholarDepProd';

        const sqlQueryCreateCancelAllTime = H.SqlHelper.getScholarAllTimeCreateCancelFromGa('CbScholarContractProd');
        const sqlQueryCreateAllTime = H.SqlHelper.getScholarAllTimeCreateFromGa('CbScholarContractProd', 'create');
        const sqlQueryGetScholarTotalDep = H.SqlHelper.getScholarTotalDepFromGa('CbScholarDepProd', 'totalDep');
        const sqlQueryCreateInRange = H.SqlHelper.getScholarRangeCreateFromGa(scholarContractTable, 'create', unixStampTime);
        const sqlQueryCreateInRangePrev = H.SqlHelper.getScholarRangeCreateFromGa(scholarContractTable, 'create', unixStampTimePrev);
        const sqlQueryTotalDepInRange = H.SqlHelper.getScholarRangeTotalDepFromGa(scholarDepTable, 'totalDep', unixStampTime);
        const sqlQueryTotalDepInRangePrev = H.SqlHelper.getScholarRangeTotalDepFromGa(scholarDepTable, 'totalDep', unixStampTimePrev);
        const sqlQueryCreateCancelAllTimeNew = H.SqlHelper.getScholarAllTimeCreateCancelFromGa('CbScholarContractPmEvtProd');
        const sqlQueryCreateAllTimeNew = H.SqlHelper.getScholarAllTimeCreateFromGa('CbScholarContractPmEvtProd', 'create');
        const sqlQueryGetScholarTotalDepNew = H.SqlHelper.getScholarTotalDepFromGa('CbScholarDepPmEvtProd', 'totalDep');

        // New Reg
        const sqlQueryOwnerNewInRange = H.SqlHelper.getScholarNewOwnerRegFromGa(scholarContractTable, unixStampTime);
        const sqlQueryOwnerNewInRangePrev = H.SqlHelper.getScholarNewOwnerRegFromGa(scholarContractTable, unixStampTimePrev);
        const sqlQueryScholarNewInRange = H.SqlHelper.getScholarNewScholarRegFromGa(scholarContractTable, unixStampTime);
        const sqlQueryScholarNewInRangePrev = H.SqlHelper.getScholarNewScholarRegFromGa(scholarContractTable, unixStampTimePrev);

        // New Cancel
        const sqlQueryOwnerCancelInRange = H.SqlHelper.getScholarNewOwnerCancelFromGa(scholarContractTable, unixStampTime);
        const sqlQueryOwnerCancelInRangePrev = H.SqlHelper.getScholarNewOwnerCancelFromGa(scholarContractTable, unixStampTimePrev);
        const sqlQueryScholarCancelInRange = H.SqlHelper.getScholarNewScholarCancelFromGa(scholarContractTable, unixStampTime);
        const sqlQueryScholarCancelInRangePrev = H.SqlHelper.getScholarNewScholarCancelFromGa(scholarContractTable, unixStampTimePrev);

        const sqlQueryOwnerData = H.SqlHelper.getScholarAllTimeNumberFromGa(scholarContractTable, 'ownerId', 'create', allTimeUnixStampTime);
        const sqlQueryScholarData = H.SqlHelper.getScholarAllTimeNumberFromGa(scholarContractTable, 'scholarId', 'create', allTimeUnixStampTime);
        const sqlQueryOwnerCancel = H.SqlHelper.getScholarAllTimeNumberFromGa(scholarContractTable, 'ownerId', 'cancel', allTimeUnixStampTime);
        const sqlQueryScholarCancel = H.SqlHelper.getScholarAllTimeNumberFromGa(scholarContractTable, 'scholarId', 'cancel', allTimeUnixStampTime);

        const [
            tempAllTimeCreateCancel,
            tempAllTimeCreate,
            tempAllTimeTotalDep,
            tempRangeCreate,
            tempRangeCreatePrev,
            tempRangeTotalDep,
            tempRangeTotalDepPrev,
            tempRangeOwnerNew,
            tempRangeOwnerNewPrev,
            tempRangeScholarNew,
            tempRangeScholarNewPrev,
            tempRangeOwnerCancel,
            tempRangeOwnerCancelPrev,
            tempRangeScholarCancel,
            tempRangeScholarCancelPrev,
            // tempAllTimeOwner,
            // tempAllTimeScholar,
            // tempAllTimeOwnerCancel,
            // tempAllTimeScholarCancel,
            tempAllTimeCreateCancelNew,
            tempAllTimeCreateNew,
            tempAllTimeTotalDepNew,
        ] = yield all([
            call(getDataFromRDS, sqlQueryCreateCancelAllTime, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCreateAllTime, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetScholarTotalDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCreateInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCreateInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalDepInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalDepInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerNewInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerNewInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarNewInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarNewInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerCancelInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerCancelInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarCancelInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarCancelInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            // call(getDataFromRDS, sqlQueryOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            // call(getDataFromRDS, sqlQueryScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            // call(getDataFromRDS, sqlQueryOwnerCancel, ENDPOINT.GET_DATA_RDS, jwtToken),
            // call(getDataFromRDS, sqlQueryScholarCancel, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCreateCancelAllTimeNew, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCreateAllTimeNew, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryGetScholarTotalDepNew, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedAllTimeCreateCancel = JSON.parse(tempAllTimeCreateCancel.body);
        const parsedAllTimeCreate = JSON.parse(tempAllTimeCreate.body);
        const parsedAllTimeTotalDep = JSON.parse(tempAllTimeTotalDep.body);
        const parsedRangeCreate = JSON.parse(tempRangeCreate.body);
        const parsedRangeCreatePrev = JSON.parse(tempRangeCreatePrev.body);
        const parsedRangeTotalDep = JSON.parse(tempRangeTotalDep.body);
        const parsedRangeTotalDepPrev = JSON.parse(tempRangeTotalDepPrev.body);
        const parsedRangeOwnerNew = JSON.parse(tempRangeOwnerNew.body);
        const parsedRangeOwnerNewPrev = JSON.parse(tempRangeOwnerNewPrev.body);
        const parsedRangeScholarNew = JSON.parse(tempRangeScholarNew.body);
        const parsedRangeScholarNewPrev = JSON.parse(tempRangeScholarNewPrev.body);
        const parsedRangeOwneCancel = JSON.parse(tempRangeOwnerCancel.body);
        const parsedRangeOwnerCancelPrev = JSON.parse(tempRangeOwnerCancelPrev.body);
        const parsedRangeScholarCancel = JSON.parse(tempRangeScholarCancel.body);
        const parsedRangeScholarCancelPrev = JSON.parse(tempRangeScholarCancelPrev.body);
        // const parsedOwner = JSON.parse(tempAllTimeOwner.body);
        // const parsedScholar = JSON.parse(tempAllTimeScholar.body);
        // const parsedOwnerCancel = JSON.parse(tempAllTimeOwnerCancel.body);
        // const parsedScholarCancel = JSON.parse(tempAllTimeScholarCancel.body);
        const parsedAllTimeCreateCancelNew = JSON.parse(tempAllTimeCreateCancelNew.body);
        const parsedAllTimeCreateNew = JSON.parse(tempAllTimeCreateNew.body);
        const parsedAllTimeTotalDepNew = JSON.parse(tempAllTimeTotalDepNew.body);

        let ownerUniqueArray = [], scholarUniqueArray = [];
        const allTimeConcatArray = parsedAllTimeCreate.concat(parsedAllTimeCreateNew);
        for (let i=0; i<allTimeConcatArray.length; i++) {
            let ownerScholarIdArray = allTimeConcatArray[i][0]['stringValue'].split("-");
            if (ownerUniqueArray.indexOf(ownerScholarIdArray[0]) === -1) {
                ownerUniqueArray.push(ownerScholarIdArray[0]);
            }
            if (scholarUniqueArray.indexOf(ownerScholarIdArray[1]) === -1) {
                scholarUniqueArray.push(ownerScholarIdArray[1]);
            }
        }

        const ownerDataObj = {
            stop: '--',
            total: ownerUniqueArray.length,
        };
        const scholarDataObj = {
            stop: '--',
            total: scholarUniqueArray.length,
        };

        yield put(setCbNumOwnerData(ownerDataObj));
        yield put(setCbNumScholarData(scholarDataObj));

        // DEAPCheck amount
        const allTimeTotalDep = Number(parsedAllTimeTotalDep[0][0]['doubleValue']) + parsedAllTimeTotalDepNew[0][0]['doubleValue'];
        yield put(setCbScholarTotalDepData(allTimeTotalDep));

        let rangeOwnerUniqueArray = [], rangeScholarUniqueArray = [];
        let rangeOnwerAllArray = [];
        for (let i=0; i<parsedRangeCreate.length; i++) {
            let rangeOwnerScholarIdArray = parsedRangeCreate[i][0]['stringValue'].split("-");
            if (rangeOwnerUniqueArray.indexOf(rangeOwnerScholarIdArray[0]) === -1) {
                rangeOwnerUniqueArray.push(rangeOwnerScholarIdArray[0]);
            }
            if (rangeScholarUniqueArray.indexOf(rangeOwnerScholarIdArray[1]) === -1) {
                rangeScholarUniqueArray.push(rangeOwnerScholarIdArray[1]);
            }
            rangeOnwerAllArray.push(rangeOwnerScholarIdArray[0]);
        }
        const ownerOccurrences = _.countBy(rangeOnwerAllArray);
        const countOnlyArray = [];
        const ownerSorted = _.chain(ownerOccurrences).map(function(cnt) {
                                countOnlyArray.push(cnt);
                                return {count: cnt}
                            }).sortBy('count').value();
        const ownerWithMaxScholar = ownerSorted[ownerSorted.length - 1];
        const ownerWithMinScholar = ownerSorted[0];
        const totalNum = _.sumBy(ownerSorted, data => {
            return data.count;
        });
        const avgSholar = (totalNum/ownerSorted.length).toFixed(1);
        const medianScholar = H.DataHelper.getMedianValue(countOnlyArray);
        const modeScholar = H.DataHelper.getModeValue(countOnlyArray);

        let rangeOwnerUniqueArrayPrev = [], rangeScholarUniqueArrayPrev = [];
        let rangeOnwerAllArrayPrev = [];
        for (let i=0; i<parsedRangeCreatePrev.length; i++) {
            let rangeOwnerScholarIdArrayPrev = parsedRangeCreatePrev[i][0]['stringValue'].split("-");
            if (rangeOwnerUniqueArrayPrev.indexOf(rangeOwnerScholarIdArrayPrev[0]) === -1) {
                rangeOwnerUniqueArrayPrev.push(rangeOwnerScholarIdArrayPrev[0]);
            }
            if (rangeScholarUniqueArrayPrev.indexOf(rangeOwnerScholarIdArrayPrev[1]) === -1) {
                rangeScholarUniqueArrayPrev.push(rangeOwnerScholarIdArrayPrev[1]);
            }
            rangeOnwerAllArrayPrev.push(rangeOwnerScholarIdArrayPrev[0]);
        }
        const ownerOccurrencesPrev = _.countBy(rangeOnwerAllArrayPrev);
        const countOnlyArrayPrev = [];
        const ownerSortedPrev = _.chain(ownerOccurrencesPrev).map(function(cnt) {
                                countOnlyArrayPrev.push(cnt);
                                return {count: cnt}
                            }).sortBy('count').value();
        const ownerWithMaxScholarPrev = ownerSortedPrev[ownerSortedPrev.length - 1];
        const ownerWithMinScholarPrev = ownerSortedPrev[0];
        const totalNumPrev = _.sumBy(ownerSortedPrev, data => {
            return data.count;
        });
        const avgSholarPrev = (totalNumPrev/ownerSortedPrev.length).toFixed(1);
        const medianScholarPrev = H.DataHelper.getMedianValue(countOnlyArrayPrev);
        const modeScholarPrev = H.DataHelper.getModeValue(countOnlyArrayPrev);

        const ownerData = {
            total: rangeOwnerUniqueArray.length,
            totalPrev: rangeOwnerUniqueArrayPrev.length,
            new: parsedRangeOwnerNew[0][0]['longValue'],
            newPrev: parsedRangeOwnerNewPrev[0][0]['longValue'],
            stop: parsedRangeOwneCancel[0][0]['longValue'],
            stopPrev: parsedRangeOwnerCancelPrev[0][0]['longValue'],
        }
        const scholarData = {
            total: rangeScholarUniqueArray.length,
            totalPrev: rangeScholarUniqueArrayPrev.length,
            new: parsedRangeScholarNew[0][0]['longValue'],
            newPrev: parsedRangeScholarNewPrev[0][0]['longValue'],
            stop: parsedRangeScholarCancel[0][0]['longValue'],
            stopPrev: parsedRangeScholarCancelPrev[0][0]['longValue']
        }
        yield put(setCbOverviewOwnerStatsData(ownerData));
        yield put(setCbOverviewScholarStatsData(scholarData));
        
        const dataDeapCheckAmount = {
            current: isDataNew ? Number(parsedRangeTotalDep[0][0]['doubleValue']) : Number(parsedRangeTotalDep[0][0]['stringValue']),
            prev: isDataNew ? Number(parsedRangeTotalDepPrev[0][0]['doubleValue']) : Number(parsedRangeTotalDepPrev[0][0]['stringValue']),
        }
        yield put(setCbDeapCheckAmountData(dataDeapCheckAmount));

        const newDataPerOwner = [
            {
                title: "最大値",
                type: "max",
                current: ownerWithMaxScholar['count'],
                prev: ownerWithMaxScholarPrev !== undefined ? ownerWithMaxScholarPrev['count'] : undefined
            },
            {
                title: "平均値",
                type: "avg",
                current: avgSholar,
                prev: avgSholarPrev !== 'NaN' ? avgSholarPrev : undefined,
            },
            {
                title: "最小値",
                type: "min",
                current: ownerWithMinScholar['count'],
                prev: ownerWithMinScholarPrev !== undefined ? ownerWithMinScholarPrev['count'] : undefined
            },
            {
                title: "中央値",
                type: "median",
                current: medianScholar,
                prev: medianScholarPrev !== NaN ? medianScholarPrev : undefined,
            },
            {
                title: "最頻値",
                type: "mode",
                current: modeScholar,
                prev: modeScholarPrev && modeScholarPrev.length > 0 ? modeScholarPrev : undefined,
            },
        ];
        yield put(setCbPerOwnerData(newDataPerOwner));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.owner = false;
        loadingUpdate2.scholar = false;
        yield put(setScholarDataLoading(loadingUpdate2));

        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getDeapCheckAmountData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.deapCheck = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const rangeTargetPrev = yield select(scholarDateRangePrev);

        let rangeTargetPrevMinus = moment(rangeTargetPrev[0]).tz('Asia/Singapore').subtract(1, "days").format('YYYY-MM-DD');
        rangeTargetPrevMinus = moment(rangeTargetPrevMinus).isBefore('2021-12-14') ? "2021-12-14" : rangeTargetPrevMinus;

        const sqlQueryDeapCheck = H.SqlHelper.getDeapCheckAmount('JtcbScholarOwnerProd', 'totalDep', rangeTarget[1]);
        const sqlQueryDeapCheckPrev = H.SqlHelper.getDeapCheckAmount('JtcbScholarOwnerProd', 'totalDep', rangeTargetPrev[1] === '2021-12-26' ? '2021-12-27' : rangeTargetPrev[1]);
        const sqlQueryDeapCheckPrevMinus = H.SqlHelper.getDeapCheckAmount('JtcbScholarOwnerProd', 'totalDep', rangeTargetPrevMinus);

        const [
            tempDeapCheck,
            tempDeapCheckPrev,
            tempDeapCheckPrevMinus,
        ] = yield all([
            call(getDataFromRDS, sqlQueryDeapCheck, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryDeapCheckPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryDeapCheckPrevMinus, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedDeapCheck = JSON.parse(tempDeapCheck.body);
        const parsedDeapCheckPrev = JSON.parse(tempDeapCheckPrev.body);
        const parsedDeapCheckPrevMinus = JSON.parse(tempDeapCheckPrevMinus.body);

        const currentDepAmount = Number(parsedDeapCheck[0][0]['stringValue']);
        const prevDepAmount = Number(parsedDeapCheckPrev[0][0]['stringValue']);
        const prevDepAmountMinus = Number(parsedDeapCheckPrevMinus[0][0]['stringValue']);

        const dataDeapCheckAmount = {
            // current: currentDepAmount - prevDepAmount,
            // prev: prevDepAmount - prevDepAmountMinus,
            current: currentDepAmount,
            prev: prevDepAmount,
        }
        yield put(setCbDeapCheckAmountData(dataDeapCheckAmount));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.deapCheck = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getOwnerByCountry() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ownerByCountry = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const sqlQueryOwnerByCountry = H.SqlHelper.getOwerByCountry(rangeTarget[1], 10);
        const sqlQueryCountUniqueOwner = H.SqlHelper.getUniqueOwnerCount(rangeTarget[1]);

        const [
            tempOwnerByCountry,
            tempCountUniqueOwner,
        ] = yield all([
            call(getDataFromRDS, sqlQueryOwnerByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountUniqueOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedOwnerByCountry = JSON.parse(tempOwnerByCountry.body);
        const parsedUniqueOwnerCount = JSON.parse(tempCountUniqueOwner.body);

        let dataOwnerByCountry = [], eachDataObj = {};
        _.each(parsedOwnerByCountry, function(data) {
            eachDataObj.country = data[0]['stringValue'];
            eachDataObj.value = data[1]['longValue'];
            dataOwnerByCountry.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setOwnerByCountryData(dataOwnerByCountry));

        const uniqueOwnerCountData = {
            date: rangeTarget[1],
            count: parsedUniqueOwnerCount[0][0]['longValue']
        };
        yield put(setUniqueOwnerCountByDateData(uniqueOwnerCountData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ownerByCountry = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarByCountry() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.scholarByCountry = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const sqlQueryScholarByCountry = H.SqlHelper.getScholarByCountry(rangeTarget[1], 10);
        const sqlQueryCountUniqueScholar = H.SqlHelper.getUniqueScholarCount(rangeTarget[1]);

        const [
            tempScholarByCountry,
            tempCountUniqueScholar,
        ] = yield all([
            call(getDataFromRDS, sqlQueryScholarByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountUniqueScholar, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedScholarByCountry = JSON.parse(tempScholarByCountry.body);
        const parsedUniqueScholarCount = JSON.parse(tempCountUniqueScholar.body);

        let dataScholarByCountry = [], eachDataObj = {};
        _.each(parsedScholarByCountry, function(data) {
            eachDataObj.country = data[0]['stringValue'];
            eachDataObj.value = data[1]['longValue'];
            dataScholarByCountry.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarByCountryData(dataScholarByCountry));

        const uniqueScholarCountData = {
            date: rangeTarget[1],
            count: parsedUniqueScholarCount[0][0]['longValue']
        };
        yield put(setUniqueScholarCountByDateData(uniqueScholarCountData));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.scholarByCountry = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getStatsByOwner() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.countStatsByOwner = true;
        yield put(setScholarDataLoading(loadingUpdate));

        const rangeTarget = yield select(scholarDateRange);
        const sqlQueryScholarCountByOwner = H.SqlHelper.getTableDataByOwner(rangeTarget[1], 'scholarCount', 10);
        const sqlQueryTotallDepByOwner = H.SqlHelper.getTableDataByOwnerRank(rangeTarget[1], 'totalDep', 100);
        const sqlQueryTotalDep = H.SqlHelper.getTableTotalDataByOwner(rangeTarget[1], 'totalDep');
        const sqlQueryTotallDepByScholar = H.SqlHelper.getTableDataByScholarRank(rangeTarget[1], 100);
        const sqlQueryTotalScholarDep = H.SqlHelper.getTableTotalDataByScholar(rangeTarget[1], 'totalDep');

        const [
            tempScholarCountByOwner,
            tempTotalDepByOwner,
            tempDepTotal,
            tempTotalDepByScholar,
            tempScholarDepTotal,
        ] = yield all([
            call(getDataFromRDS, sqlQueryScholarCountByOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotallDepByOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotallDepByScholar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTotalScholarDep, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedScholarCountByOwner = JSON.parse(tempScholarCountByOwner.body);
        const parsedTotalDepByOwner = JSON.parse(tempTotalDepByOwner.body);
        const parsedTotalDep = JSON.parse(tempDepTotal.body);
        const parsedTotalDepByScholar = JSON.parse(tempTotalDepByScholar.body);
        const parsedTotalScholarDep = JSON.parse(tempScholarDepTotal.body);

        let dataScholarStatsByOwner = [], eachDataObj = {};
        _.each(parsedScholarCountByOwner, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            dataScholarStatsByOwner.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarCountByOwnerData(dataScholarStatsByOwner));

        dataScholarStatsByOwner = [];
        _.each(parsedTotalDepByOwner, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            eachDataObj.numScholar = data[4]['longValue'];
            dataScholarStatsByOwner.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarDepByOwnerData(dataScholarStatsByOwner));

        let dataScholarStatsByScholar = [];
        _.each(parsedTotalDepByScholar, function(data) {
            eachDataObj.username = data[0]['stringValue'];
            eachDataObj.pmid = data[1]['stringValue'];
            eachDataObj.country = data[2]['stringValue'];
            eachDataObj.value = data[3]['longValue'];
            eachDataObj.owner = data[4]['stringValue'];
            dataScholarStatsByScholar.push(eachDataObj);
            eachDataObj = {};
        });
        yield put(setScholarDepByScholarData(dataScholarStatsByScholar));

        const dataScholarTotalDep = {
            date: rangeTarget[1],
            count: Number(parsedTotalDep[0][0]['stringValue'])
        };
        const dataScholarTotalScholarDep = {
            date: rangeTarget[1],
            count: Number(parsedTotalScholarDep[0][0]['stringValue'])
        };
        yield put(setScholarTotalDepByDateData(dataScholarTotalDep));
        yield put(setScholarTotalScholarDepByDateData(dataScholarTotalScholarDep));

        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.countStatsByOwner = false;
        yield put(setScholarDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getAllTimeOwnerScholarData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const latestSgtDate = moment().tz("Asia/Singapore").subtract(1, "days").format('YYYY-MM-DD');
        const dayBeforeLastSgtDate = moment().tz("Asia/Singapore").subtract(2, "days").format('YYYY-MM-DD');
        const sqlQueryAllTimeOwnerData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarOwnerProd', latestSgtDate);
        const sqlQueryAllTimeScholarData = H.SqlHelper.getScholarAllTimeNumber('JtcbScholarScholarProd', latestSgtDate);
        const sqlQueryAllTimeQuitOwnerData = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarOwnerProd', dayBeforeLastSgtDate);
        const sqlQueryAllTimeQuitScholarData = H.SqlHelper.getScholarAllTimeQuit('JtcbScholarScholarProd', dayBeforeLastSgtDate);
        const [
            tempAllTimeOwner,
            tempAllTimeScholar,
            tempAllTimeOwnerQuit,
            tempAllTimeScholarQuit,
        ] = yield all([
            call(getDataFromRDS, sqlQueryAllTimeOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAllTimeScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAllTimeQuitOwnerData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAllTimeQuitScholarData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedAllTimeOwner = JSON.parse(tempAllTimeOwner.body);
        const parsedAllTimeScholar = JSON.parse(tempAllTimeScholar.body);
        const parsedAllTimeOwnerQuit = JSON.parse(tempAllTimeOwnerQuit.body);
        const parsedAllTimeScholarQuit = JSON.parse(tempAllTimeScholarQuit.body);

        const ownerDataObj = {
            stop: parsedAllTimeOwnerQuit[0][0]['longValue'],
            total: parsedAllTimeOwner[0][0]['longValue'],
        };
        const scholarDataObj = {
            stop: parsedAllTimeScholarQuit[0][0]['longValue'],
            total: parsedAllTimeScholar[0][0]['longValue'],
        };

        yield put(setCbNumOwnerData(ownerDataObj));
        yield put(setCbNumScholarData(scholarDataObj));

    } catch (err) {
        console.log(err);
    }
}

export function* getScholarTrendData() {
    try {
        const rangeTarget = yield select(scholarDateRange);
        const jwtToken = yield call(getJwtToken);
        const sqlQueryOwnerNewTrendData = H.SqlHelper.getScholarNewTrendData('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryOwnerStopTrendData = H.SqlHelper.getScholarStopTrendData('JtcbScholarOwnerProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryScholarNewTrendData = H.SqlHelper.getScholarNewTrendData('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        const sqlQueryScholarStopTrendData = H.SqlHelper.getScholarStopTrendData('JtcbScholarScholarProd', rangeTarget[0], rangeTarget[1]);
        yield put(setScholarTrendDataLoading(true));

        const [
            tempOwnerNewData,
            tempOwnerStopData,
            tempScholarNewData,
            tempScholarStopData,
        ] = yield all([
            call(getDataFromRDS, sqlQueryOwnerNewTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryOwnerStopTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarNewTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryScholarStopTrendData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedOwnerNewData = JSON.parse(tempOwnerNewData.body);
        const parsedOwnerStopData = JSON.parse(tempOwnerStopData.body);
        const parsedScholarNewData = JSON.parse(tempScholarNewData.body);
        const parsedScholarStopData = JSON.parse(tempScholarStopData.body);

        // Owner
        let ownerNewObj = {}, ownerStopObj = {};
        _.each(parsedOwnerNewData, function(data) {
            ownerNewObj[data[0]['stringValue']] = data[1]['longValue'];
        });
        _.each(parsedOwnerStopData, function(data) {
            ownerStopObj[data[0]['stringValue']] = data[1]['longValue'];
        });

        // Scholar
        let scholarNewObj = {}, scholarStopObj = {};
        _.each(parsedScholarNewData, function(data) {
            scholarNewObj[data[0]['stringValue']] = data[1]['longValue'];
        });
        _.each(parsedScholarStopData, function(data) {
            scholarStopObj[data[0]['stringValue']] = data[1]['longValue'];
        });

        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);

        let dataOwnerNewArray = [], dataOwnerStopArray = [], dataScholarNewArray = [], dataScholarStopArray = [];
        _.each(daysArray, function(date, index) {
            if (ownerNewObj[date] === undefined) {
                dataOwnerNewArray.push(0);
            } else {
                dataOwnerNewArray.push(ownerNewObj[date]);
            }

            if (ownerStopObj[date] === undefined || index === daysArray.length - 1) {
                dataOwnerStopArray.push(0);
            } else {
                dataOwnerStopArray.push(ownerStopObj[date]);
            }

            // Scholar
            if (scholarNewObj[date] === undefined) {
                dataScholarNewArray.push(0);
            } else {
                dataScholarNewArray.push(scholarNewObj[date]);
            }

            if (scholarStopObj[date] === undefined || index === daysArray.length - 1) {
                dataScholarStopArray.push(0);
            } else {
                dataScholarStopArray.push(scholarStopObj[date]);
            }
        });

        const trendData = [
            {
                id: 'ownerNew',
                title: "Owner新規",
                data: dataOwnerNewArray,
                date: daysArray,
            },
            {
                id: 'ownerStop',
                title: "Owner停止",
                data: dataOwnerStopArray,
                date: daysArray,
            },
            {
                id: 'scholarNew',
                title: "scholar新規",
                data: dataScholarNewArray,
                date: daysArray,
            },
            {
                id: 'scholarStop',
                title: "Scholar停止",
                data: dataScholarStopArray,
                date: daysArray,
            },
        ];

        yield put(setJtcbScholarTrendData(trendData));
        yield put(setScholarTrendDataLoading(false));
    } catch (err) {
        console.log(err);
    }
}

export function* getScholarDataByGuild() {
    try {
        const rangeTarget = yield select(scholarDateRange);
        const jwtToken = yield call(getJwtToken);
        yield put(setGuildDataLoading(true));
        yield put(setPageTopLoader(true));

        const sqlQueryData = H.SqlHelper.getScholarLenderPmId(rangeTarget[1]);
        const [
            tempKogiGuildData,
        ] = yield all([
            call(getDataFromRDS, sqlQueryData, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        let parsedKogiGuildData = JSON.parse(tempKogiGuildData.body);

        let isSplitKogi = false, kogiGuildFirstHalf = [], kogiGuildSecondHalf = [], dataTempGuild2;
        if (parsedKogiGuildData.length > 175) {
            const half = Math.ceil(parsedKogiGuildData.length / 2);
            kogiGuildFirstHalf = parsedKogiGuildData.slice(0, half);
            kogiGuildSecondHalf = parsedKogiGuildData.slice(-half);
            isSplitKogi = true;
        }

        // Kogi Guild Pmids
        let guildOwnerIds = "", guildOwnerIdsSql = "";
        let guildOwnerIds2 = "", guildOwnerIdsSql2 = "", adjustedTtlDepByPmids2 = 0;
        if (isSplitKogi) {
            _.each(kogiGuildFirstHalf, function(data) {
                guildOwnerIds = guildOwnerIds + `"${data[0]['stringValue']}",`;
                guildOwnerIdsSql = guildOwnerIdsSql + `'${data[0]['stringValue']}',`;
            });
            _.each(kogiGuildSecondHalf, function(data) {
                guildOwnerIds2 = guildOwnerIds2 + `"${data[0]['stringValue']}",`;
                guildOwnerIdsSql2 = guildOwnerIdsSql2 + `'${data[0]['stringValue']}',`;
            });
        } else {
            _.each(parsedKogiGuildData, function(data) {
                guildOwnerIds = guildOwnerIds + `"${data[0]['stringValue']}",`;
                guildOwnerIdsSql = guildOwnerIdsSql + `'${data[0]['stringValue']}',`;
            });            
        }

        // Kyogi Guild Pmids
        let guildKyogiOwnerIds = "", guildKyogiOwnerIdsSql = "";
        _.each(KyogiScholar.item, function(data) {
            guildKyogiOwnerIds = guildKyogiOwnerIds + `"${data}",`;
            guildKyogiOwnerIdsSql = guildKyogiOwnerIdsSql + `'${data}',`;
        });

        // NFT purchase DEP / count
        const sourceKogiGuild = H.ElasticSearch.getTotalNftPurchaseByWideGuild(guildOwnerIds, rangeTarget);
        const searchJtcbKogiGuildPurchase = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceKogiGuild}`};

        const sourceAllGuild = H.ElasticSearch.getTotalNftPurchaseByAll(rangeTarget);
        const searchJtcbGuildAllPurchase = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceAllGuild}`};

        const sourceKyogiGuild = H.ElasticSearch.getTotalNftPurchaseByWideGuild(guildKyogiOwnerIds, rangeTarget);
        const searchJtcbKyogiGuildPurchase = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceKyogiGuild}`};

        const [dataTempGuild, dataTempAll, dateTempKyogiGuild ] = yield all([
            call(getDataFromElasticSearch, searchJtcbKogiGuildPurchase, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromElasticSearch, searchJtcbGuildAllPurchase, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getDataFromElasticSearch, searchJtcbKyogiGuildPurchase, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);

        if (isSplitKogi) {
            const sourceKogiGuild2 = H.ElasticSearch.getTotalNftPurchaseByWideGuild(guildOwnerIds2, rangeTarget);
            const searchJtcbKogiGuildPurchase2 = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceKogiGuild2}`};
            dataTempGuild2 = yield call(getDataFromElasticSearch, searchJtcbKogiGuildPurchase2, ENDPOINT.GET_DATA_ES_DAA, jwtToken);
        }

        // totalDep
        const dayPrevCutoff = moment(rangeTarget[0]).subtract(1, "days").format('YYYY-MM-DD');
        const sqlQueryTtlDepScholar = H.SqlHelper.getScholarSumTotalDep('JtcbScholarScholarProd', rangeTarget[1]);
        const sqlQueryTtlDepScholarSubtract = H.SqlHelper.getScholarSumTotalDep('JtcbScholarScholarProd', dayPrevCutoff);
        const sqlQueryTtlDepOwner = H.SqlHelper.getScholarSumTotalDep('JtcbScholarOwnerProd', rangeTarget[1]);
        const sqlQueryTtlDepOwnerSubtract = H.SqlHelper.getScholarSumTotalDep('JtcbScholarOwnerProd', dayPrevCutoff);
        const [
            tempTtlDepKogiData,
            tempTtlDepKogiDataSubtract,
            tempTtlDepKogiOwnerData,
            tempTtlDepKogiOwnerDataSubtract,
        ] = yield all([
            call(getDataFromRDS, sqlQueryTtlDepScholar, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepScholarSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepOwnerSubtract, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedKogiTtlDep = JSON.parse(tempTtlDepKogiData.body);
        const parsedKogiTtlDepSubtract = JSON.parse(tempTtlDepKogiDataSubtract.body);
        const endDateTotalDep = Number(parsedKogiTtlDep[0][0]['stringValue']);
        const prevDateTotalDep = Number(parsedKogiTtlDepSubtract[0][0]['stringValue']);
        const totalDepWithScholar = endDateTotalDep - prevDateTotalDep;

        const parsedKogiTtlDepOwner = JSON.parse(tempTtlDepKogiOwnerData.body);
        const parsedKogiTtlDepOwnerSubtract = JSON.parse(tempTtlDepKogiOwnerDataSubtract.body);
        const endDateTotalDepOwner = Number(parsedKogiTtlDepOwner[0][0]['stringValue']);
        const prevDateTotalDepOwner = Number(parsedKogiTtlDepOwnerSubtract[0][0]['stringValue']);
        const totalDepWithOwner = endDateTotalDepOwner - prevDateTotalDepOwner;

        // DEP give : Kogi
        const allPmIdsForSql = guildOwnerIdsSql.slice(0, -1);
        const sqlQueryTtlDepBySomeOwner= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql, rangeTarget[1]);
        const sqlQueryTtlDepBySomeOwnerPrev= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql, dayPrevCutoff);

        // DEP give : Kyogi
        const allKyogiPmIdsForSql = guildKyogiOwnerIdsSql.slice(0, -1);
        const sqlQueryTtlDepBySomeKyogiOwner= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allKyogiPmIdsForSql, rangeTarget[1]);
        const sqlQueryTtlDepBySomeKyogiOwnerPrev= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allKyogiPmIdsForSql, dayPrevCutoff);

        const [ tempKogiGuildDepTtlData, tempKogiGuildDepTtlDataSubtract,
            tempKyogiGuildDepTtlData, tempKyogiGuildDepTtlDataSubtract ] = yield all([
            call(getDataFromRDS, sqlQueryTtlDepBySomeOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepBySomeOwnerPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepBySomeKyogiOwner, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryTtlDepBySomeKyogiOwnerPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedKogiGuildDepTtlData = JSON.parse(tempKogiGuildDepTtlData.body);
        const parsedKogiGuildDepTtlDataSubtract = JSON.parse(tempKogiGuildDepTtlDataSubtract.body);
        const adjustedTtlDepByPmids = Number(parsedKogiGuildDepTtlData[0][0]['stringValue']) - Number(parsedKogiGuildDepTtlDataSubtract[0][0]['stringValue']);

        if (isSplitKogi) {
            const allPmIdsForSql2 = guildOwnerIdsSql2.slice(0, -1);
            const sqlQueryTtlDepBySomeOwner2= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql2, rangeTarget[1]);
            const sqlQueryTtlDepBySomeOwnerPrev2= H.SqlHelper.getScholarSomeSumTotalDep('JtcbScholarOwnerProd', allPmIdsForSql2, dayPrevCutoff);

            const [ tempKogiGuildDepTtlData2, tempKogiGuildDepTtlDataSubtract2 ] = yield all([
                call(getDataFromRDS, sqlQueryTtlDepBySomeOwner2, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, sqlQueryTtlDepBySomeOwnerPrev2, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            const parsedKogiGuildDepTtlData2 = JSON.parse(tempKogiGuildDepTtlData2.body);
            const parsedKogiGuildDepTtlDataSubtract2 = JSON.parse(tempKogiGuildDepTtlDataSubtract2.body);
            adjustedTtlDepByPmids2 = Number(parsedKogiGuildDepTtlData2[0][0]['stringValue']) - Number(parsedKogiGuildDepTtlDataSubtract2[0][0]['stringValue']);
        }

        // Kyogi
        const parsedKyogiGuildDepTtlData = JSON.parse(tempKyogiGuildDepTtlData.body);
        const parsedKyogiGuildDepTtlDataSubtract = JSON.parse(tempKyogiGuildDepTtlDataSubtract.body);
        const adjustedTtlDepByKyogiPmids = Number(parsedKyogiGuildDepTtlData[0][0]['stringValue']) - Number(parsedKyogiGuildDepTtlDataSubtract[0][0]['stringValue']);

        const kogiDataObj = {
            ownerCount: parsedKogiGuildData.length+"名",
            purchaseAmount: isSplitKogi ? dataTempGuild.price.value + dataTempGuild2.price.value : dataTempGuild.price.value,
            totalAmount: dataTempAll.price.value,
            purchaseCount: isSplitKogi ? dataTempGuild.count.value + dataTempGuild2.count.value : dataTempGuild.count.value,
            totalCount: dataTempAll.count.value,
            ownerDepGive: adjustedTtlDepByPmids + adjustedTtlDepByPmids2,
            ttlDepGive: totalDepWithScholar + totalDepWithOwner,
        }

        const kyogiDataObj = {
            ownerCount: KyogiScholar.item.length+"名",
            purchaseAmount: dateTempKyogiGuild.price.value,
            totalAmount: dataTempAll.price.value,
            purchaseCount: dateTempKyogiGuild.count.value,
            totalCount: dataTempAll.count.value,
            ownerDepGive: adjustedTtlDepByKyogiPmids,
            ttlDepGive: totalDepWithScholar + totalDepWithOwner,
        }

        yield put(setKogiGuildData(kogiDataObj));
        yield put(setKyogiGuildData(kyogiDataObj));
        yield put(setGuildDataLoading(false));
        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA_CB, getOwnerScholarStatsData);
    // yield takeEvery(COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getDeapCheckAmountData);
    // yield takeEvery(COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getOwnerByCountry);
    // yield takeEvery(COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getScholarByCountry);
    // yield takeEvery(COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getStatsByOwner);
    // yield takeEvery(COOKINBURGER.GET_SCHOLAR_OWNER_SCHOLAR_STATS_DATA, getAllTimeOwnerScholarData);
    // yield takeEvery(COOKINBURGER.GET_JTCB_SCHOLAR_TREND_DATA, getScholarTrendData);
    // yield takeEvery(COOKINBURGER.GET_JTCB_SCHOLAR_DATA_BY_GUILD, getScholarDataByGuild);
}
