import { NFTDEP } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    nftDepTrendDateRange: [startDate, endDate],
    trendOption: [],
    trendOptionSelected: [],
    trendDataCountryOption: [],
    trendDataCountrySelected: [],
    trendDataGameOption: [],
    trendDataGameSelected: [],
    trendDataSummary: {
        total1st: '-',
        total2nd: '-',
        totalFiat: '-',
        count1st: '-',
        count2nd: '-',
        countFiat: '-',
        unique1st: '-',
        unique2nd: '-',
        uniqueFiat: '-'
    },
    trendData: [
        {
            id: 'store',
            data: [],
            date: [],
            avg: 0,
        },
        {
            id: 'auction',
            data: [],
            date: [],
            avg: 0,
        },
        {
            id: 'activeWallet',
            data: [],
            date: [],
            avg: 0,
        },
    ],
    trendDataAmount: [
        {
            id: 'store-amount',
            data: [],
            date: [],
            avg: 0,
        },
        {
            id: 'auction-amount',
            data: [],
            date: [],
            avg: 0,
        },
    ],
    trendDataLoading: false,
    countryLoading: false,
};

export default function activeUsersReducer(state = initialState, action) {
    switch (action.type) {
        case NFTDEP.SET_TREND_DATE_RANGE:
            return {
                ...state,
                nftDepTrendDateRange: action.payload
            };
        case NFTDEP.SET_TREND_OPTION:
            return {
                ...state,
                trendOption: action.payload
            };
        case NFTDEP.SET_TREND_OPTION_SELECTED:
            return {
                ...state,
                trendOptionSelected: action.payload.length > 0 ? action.payload : []
            };
        case NFTDEP.SET_TREND_PAGE_DATA_COUNT:
            return {
                ...state,
                trendData: action.payload
            };
        case NFTDEP.SET_TREND_PAGE_DATA_AMOUNT:
            return {
                ...state,
                trendDataAmount: action.payload
            };
        case NFTDEP.SET_TREND_PAGE_DATA_SUMMARY:
            return {
                ...state,
                trendDataSummary: action.payload
            };
        case NFTDEP.SET_TREND_DATA_COUNTRY_OPTION:
            return {
                ...state,
                trendDataCountryOption: action.payload
            };
        case NFTDEP.SET_TREND_DATA_COUNTRY_SELECTED:
            return {
                ...state,
                trendDataCountrySelected: action.payload
            };
        case NFTDEP.SET_TREND_DATA_LOADING:
            return {
                ...state,
                trendDataLoading: action.payload
            };
        case NFTDEP.SET_TREND_DATA_COUNTRY_LOADING:
            return {
                ...state,
                countryLoading: action.payload
            };
        case NFTDEP.SET_TREND_DATA_GAME_OPTION:
            return {
                ...state,
                trendDataGameOption: action.payload
            };
        case NFTDEP.SET_TREND_DATA_GAME_SELECTED:
            return {
                ...state,
                trendDataGameSelected: action.payload
            };
        default:
            return state
    }
};
