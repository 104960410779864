import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ContentWrapperStyles from './ContentWrapperStyles';

const ContentWrapper = (props) => {
    const { classes, children, padding } = props;

    return (
        <div className={padding ? classes.root : null}>
            { children }
        </div>
    );
}

ContentWrapper.prototypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    padding: PropTypes.bool
};

ContentWrapper.defaultProps = {
    padding: true
};

export default withStyles(ContentWrapperStyles)(ContentWrapper);