import React from 'react'
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { useTable } from 'react-table'

const Styles = styled.div`
  padding: 1rem;

  .user {
    background-color: #fff;
    color: white;
  }

  table {
    border-spacing: 0;
    border: 1px solid #eee;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #eee;
      border-right: 1px solid #eee;

      :last-child {
        border-right: 0;
      }
    }
  }
`

// Create a default prop getter
const defaultPropGetter = () => ({})

// Expose some prop getters for headers, rows and cells, or more if you want!
function Table({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                // Return an array of prop objects and react-table will merge them appropriately
                {...column.getHeaderProps([
                  {
                    className: column.className,
                    style: column.style,
                  },
                  getColumnProps(column),
                  getHeaderProps(column),
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // Merge user row props in
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map(cell => {
                return (
                  <td
                    // Return an array of prop objects and react-table will merge them appropriately
                    {...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      getColumnProps(cell.column),
                      getCellProps(cell),
                    ])}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

//function CohortTable() {
const CohortTable = ({
    }) => {
    const columns = React.useMemo(
    () => [
      {
        Header: '総ユーザー',
        columns: [
          {
            Header: '合計',
            accessor: 'firstName',
            className: 'user',
            style: {
              fontWeight: 'bolder',
            },
          },
        ],
      },
      {
        Header: '期間',
        columns: [
          {
            Header: 'Day 1',
            accessor: 'col1',
          },
          {
            Header: 'Day 2',
            accessor: 'col2',
          },
          {
            Header: 'Day 3',
            accessor: 'score2',
          },
          {
            Header: 'Day 4',
            accessor: 'score3',
          },
          {
            Header: 'Day 5',
            accessor: 'score4',
          },
          {
            Header: 'Day 6',
            accessor: 'score5',
          },
          {
            Header: 'Day 7',
            accessor: 'score6',
          },
          {
            Header: 'Day 8',
          },
          {
            Header: 'Day 9',
          },
          {
            Header: 'Day 10',
          },
          {
            Header: 'Day 11',
          },
          {
            Header: 'Day 12',
          },
        ],
      },
    ],
    []
  )

  const data = React.useMemo(
    () => [
      {
        col1: 'Hello',
        col2: 'World',
      },
      {
        col1: 'react-table',
        col2: 'rocks',
      },
      {
        col1: 'whatever',
        col2: 'you want',
      },
    ],
    []
  )

  return (
    <Styles>
      <Table
        columns={columns}
        data={data}
        getHeaderProps={column => ({
          onClick: () => alert('Header!'),
        })}
        getColumnProps={column => ({
          onClick: () => alert('Column!'),
        })}
        getRowProps={row => ({
          style: {
            background: row.index % 2 === 0 ? 'rgba(0,0,0,.1)' : 'white',
          },
        })}
        getCellProps={cellInfo => ({
          style: {
            backgroundColor: `hsl(${120 * ((120 - cellInfo.value) / 120) * -1 +
              120}, 100%, 67%)`,
          },
        })}
      />
    </Styles>
  )
}

CohortTable.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});


// export default CohortTable
export default connect(mapStateToProps, mapDispatchToProps)(CohortTable);
