import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import { createPuzzleRegData } from 'graphql/mutations'
import { put, call, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import { setAcquisitionDataReg, setAcquisitionDataRegTrend, setBtnStateDateFetch, setAcquisitionDataRegGender, setAcquisitionDataRegAgeGroup, getAcquisitionGeoDataReg, setAcquisitionGeoDataReg, setAcquisitionDataLoading, setAcquisitionDataRegAdVsOrganic, setAcquisitionDataRegNewVsExist } from 'actions';
import { PUZZLE, PAGE, ENDPOINT } from '../constants';
import { getJwtToken, fetchNodeGaEventData } from 'api';
import moment from 'moment-timezone';
import * as H from 'helper';

export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];

export function* handlePuzzleAcquisitionData() {
    try {
        let acquisitionDataReg = {}, returnedRegData, acquisitionDataRegTrend = {}, acquisitionGeoDataReg = {},
            acquisitionDataRegAdVsOrg = ['loading'], acquisitionDataRegNewVsExist = ['loading'];
        let loading = 'loading';
        yield put(setBtnStateDateFetch(false));
        acquisitionDataReg.pmidRegNumTotal = loading;
        acquisitionDataReg.pmidRegNumAd = loading;
        acquisitionDataReg.pmidRegNumOrg = loading;
        acquisitionDataReg.pmidRegNew = loading;
        acquisitionDataReg.pmidRegExist = loading;
        yield put(setAcquisitionDataReg(acquisitionDataReg));

        yield put(setAcquisitionDataLoading(true));
        yield put(setAcquisitionDataRegAdVsOrganic(acquisitionDataRegAdVsOrg));
        yield put(setAcquisitionDataRegNewVsExist(acquisitionDataRegNewVsExist));

        acquisitionGeoDataReg.pmidRegNumCountry = loading;
        acquisitionGeoDataReg.pmidRegNumCountryAd = loading;
        acquisitionGeoDataReg.pmidRegNumCountryOrg = loading;
        acquisitionGeoDataReg.pmidRegNewCountry = loading;
        acquisitionGeoDataReg.pmidRegExistCountry = loading;
        yield put(setAcquisitionGeoDataReg(acquisitionGeoDataReg));

        const jwtToken = yield call(getJwtToken);
        const storedDateRange = JSON.parse(localStorage.getItem("fetchDate_range"));
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');
        let labelArray = [], pmidRegNumAd = 0, pmidRegNumOrg = 0, pmidRegNew = 0, pmidRegExist = 0, dateArray = [];

        // Get the date range caldner
        const range = moment.range(dateFrom, dateTo);
        const days = Array.from(range.by('days'));
        dateArray = days.map(m => m.format('MM/DD/YY'));
        console.log('dateArray range:', dateArray);

        const graphQLdataCheck = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegStats'+dateFrom+dateTo}));
        console.log('graphQLdataCheck:', graphQLdataCheck.data.getPuzzleRegData);
        const isGraphQlData = graphQLdataCheck.data.getPuzzleRegData === null ? false : true;

        if (storedDateRange !== null && storedDateRange[0] === dateFrom && storedDateRange[1] === dateTo && isGraphQlData) {
            // fetch from GraphQL
            console.log('stats from GraphQL');
            // const apiData = yield API.graphql({ query: queries.listPuzzleRegDatas });
            // const graphQlListData = apiData.data.listPuzzleRegDatas.items;
            // console.log('graphQlListData:', graphQlListData);
            const dataRegStats = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegStats'+dateFrom+dateTo}));
            const dataRegTrend = yield API.graphql(graphqlOperation(queries.getPuzzleRegData, { id: 'puzzleDataRegTrend'+dateFrom+dateTo}));
            console.log('graphQl dataRegStats:', JSON.parse(dataRegStats.data.getPuzzleRegData.dataset));
            console.log('graphQl dataRegTrend:', JSON.parse(dataRegTrend.data.getPuzzleRegData.dataset));
            yield put(setAcquisitionDataReg(JSON.parse(dataRegStats.data.getPuzzleRegData.dataset)));
            yield put(setAcquisitionDataRegTrend(JSON.parse(dataRegTrend.data.getPuzzleRegData.dataset)));
        } else {
            // fetch from API
            console.log('stats from API');
            localStorage.setItem("fetchDate_range", JSON.stringify([dateFrom, dateTo]));
            let queryData = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:dateHourMinute"},{"name": "ga:country"},
                                       {"name": "ga:city"},{"name": "ga:language"},{"name": "ga:eventLabel"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dimensionFilterClauses": [
                            // {
                            //     "operator":"AND",
                            //     "filters":[
                            //         {
                            //             "dimensionName":"ga:eventLabel",
                            //             "operator":"PARTIAL",
                            //             "expressions":"kluoIrkn",
                            //         }
                            //     ]
                            // },
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                    "dimensionName":"ga:eventCategory",
                                    "operator":"EXACT",
                                    "expressions":'Registration'
                                    }
                                ]
                            }
                        ],
                        "dateRanges": [{"startDate": dateFrom, "endDate": dateTo}],
                        "viewId": "227581633",
                        'pageSize': 100000
                    }
                ]
            };
            returnedRegData = yield call(fetchNodeGaEventData, queryData, ENDPOINT.GET_DATA_FROM_GA, jwtToken);

            let unixTimeStamp = 0;
            let totalRegDateArray = [], totalRegAdDateArray = [], totalRegOrgDateArray = [],
                totalRegNewDateArray = [], totalRegExistDateArray = [];
            for (let i=0; i<returnedRegData.length; i++) {
                labelArray = returnedRegData[i]['dimensions'][4].split(' | ');
                unixTimeStamp = Number(labelArray[labelArray.length -1]);
                //console.log(moment(unixTimeStamp).format("MM/DD/YY"));

                // Ad or Organic
                if (labelArray[1] !== 'organic') {
                    pmidRegNumAd = pmidRegNumAd + 1;
                    totalRegAdDateArray.push(moment(unixTimeStamp).tz("Asia/Singapore").format("MM/DD/YY"));
                } else {
                    pmidRegNumOrg = pmidRegNumOrg + 1;
                    totalRegOrgDateArray.push(moment(unixTimeStamp).tz("Asia/Singapore").format("MM/DD/YY"));
                }
                // new or exist
                if (labelArray[3] === 'new') {
                    pmidRegNew = pmidRegNew + 1;
                    totalRegExistDateArray.push(moment(unixTimeStamp).tz("Asia/Singapore").format("MM/DD/YY"));
                } else {
                    pmidRegExist = pmidRegExist + 1;
                    totalRegNewDateArray.push(moment(unixTimeStamp).tz("Asia/Singapore").format("MM/DD/YY"));
                }
                totalRegDateArray.push(moment(unixTimeStamp).tz("Asia/Singapore").format("MM/DD/YY"));
            }
            acquisitionDataReg = {};
            acquisitionDataReg.pmidRegNumTotal = returnedRegData.length;
            acquisitionDataReg.pmidRegNumAd = pmidRegNumAd;
            acquisitionDataReg.pmidRegNumOrg = pmidRegNumOrg;
            acquisitionDataReg.pmidRegNew = pmidRegNew;
            acquisitionDataReg.pmidRegExist = pmidRegExist;
            console.log('acquisitionDataReg:', acquisitionDataReg)
            yield put(setAcquisitionDataReg(acquisitionDataReg));
            //localStorage.setItem("puzzle_data_reg_stats", JSON.stringify(acquisitionDataReg));
            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataRegStats'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify(acquisitionDataReg),
                name: 'puzzle_data_reg_stats'
            }}));
            console.log('graphql dataset puzzle_data_reg_stats successfully created!')

            let countRegByDate = _.values(_.groupBy(totalRegDateArray)).map(d => ({date: d[0], count: d.length}));
            let countRegAdByDate = _.values(_.groupBy(totalRegAdDateArray)).map(d => ({date: d[0], count: d.length}));
            let countRegOrgByDate = _.values(_.groupBy(totalRegOrgDateArray)).map(d => ({date: d[0], count: d.length}));
            let countRegExistByDate = _.values(_.groupBy(totalRegExistDateArray)).map(d => ({date: d[0], count: d.length}));
            let countRegNewByDate = _.values(_.groupBy(totalRegNewDateArray)).map(d => ({date: d[0], count: d.length}));
            console.log('countRegByDate:', countRegByDate);

            // Set trend line chart data
            let dataCountRegByDate = [];
            let dataCountRegAdByDate = [];
            let dataCountRegOrgByDate = [];
            let dataCountRegExistByDate = [];
            let dataCountRegNewByDate = [];
            for (let i=0; i < dateArray.length; i++) {
                countRegByDate.filter((obj) => {
                    if (obj.date === dateArray[i]) {
                        dataCountRegByDate.push(obj.count)
                    }
                });
                countRegAdByDate.filter((obj) => {
                    if (obj.date === dateArray[i]) {
                        dataCountRegAdByDate.push(obj.count)
                    }
                });
                countRegOrgByDate.filter((obj) => {
                    if (obj.date === dateArray[i]) {
                        dataCountRegOrgByDate.push(obj.count)
                    }
                });
                countRegExistByDate.filter((obj) => {
                    if (obj.date === dateArray[i]) {
                        dataCountRegExistByDate.push(obj.count)
                    }
                });
                countRegNewByDate.filter((obj) => {
                    if (obj.date === dateArray[i]) {
                        dataCountRegNewByDate.push(obj.count)
                    }
                });
            }

            acquisitionDataRegTrend.pmidRegNumTotal = dataCountRegByDate;
            acquisitionDataRegTrend.pmidRegNumAd = dataCountRegAdByDate;
            acquisitionDataRegTrend.pmidRegNumOrg = dataCountRegOrgByDate;
            acquisitionDataRegTrend.pmidRegExist = dataCountRegExistByDate;
            acquisitionDataRegTrend.pmidRegNew = dataCountRegNewByDate;
            acquisitionDataRegTrend.date = dateArray;
            console.log('acquisitionDataRegTrend:', acquisitionDataRegTrend);
            yield put(setAcquisitionDataRegTrend(acquisitionDataRegTrend));
            //localStorage.setItem("puzzle_data_reg_trend", JSON.stringify(acquisitionDataRegTrend));
            API.graphql(graphqlOperation(createPuzzleRegData, {input: {
                id: 'puzzleDataRegTrend'+dateFrom+dateTo,
                dateFrom: dateFrom,
                dateTo: dateTo,
                dataset: JSON.stringify(acquisitionDataRegTrend),
                name: 'puzzle_data_reg_trend'
            }}));
            console.log('graphql dataset puzzle_data_reg_trend successfully created!')
        }
        yield put(setAcquisitionDataLoading(false));
        yield put(getAcquisitionGeoDataReg()); // Call Geo Data Fetch Saga
    } catch (error) {
        console.log(error);
    }
}

export function* handlePuzzleAcquisitionDataGender() {
    try {
        let acquisitionDataRegGender = ['loading'], returnedRegDataGender = [];
        yield put(setAcquisitionDataRegGender(acquisitionDataRegGender));

        const jwtToken = yield call(getJwtToken);
        const storedDateRange = JSON.parse(localStorage.getItem("fetchDate_range"));
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');

        if (storedDateRange !== null && storedDateRange[0] === dateFrom && storedDateRange[1] === dateTo) {
            returnedRegDataGender = JSON.parse(localStorage.getItem("puzzle_data_returnedRegDataGender")); // fetch from LS
            console.log('gender from LS');
        } else {
            console.log('gender from API');
            let queryData = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:userGender"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dimensionFilterClauses": [
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                    "dimensionName":"ga:eventCategory",
                                    "operator":"EXACT",
                                    "expressions":'Registration'
                                    }
                                ]
                            }
                        ],
                        "dateRanges": [{"startDate": dateFrom, "endDate": dateTo}],
                        "viewId": "227581633",
                        'pageSize': 100
                    }
                ]
            };
            returnedRegDataGender = yield call(fetchNodeGaEventData, queryData, ENDPOINT.GET_DATA_FROM_GA, jwtToken);
            localStorage.setItem("puzzle_data_returnedRegDataGender", JSON.stringify(returnedRegDataGender));
        }
        // [{value: 365, name: 'Male'},{value: 99, name: 'Female'}]
        console.log('returnedRegDataGender:', returnedRegDataGender);
        acquisitionDataRegGender = [];
        let genderDataObj = {};
        for (let i=0; i<returnedRegDataGender.length;i++) {
            genderDataObj.name = H.FormatNumStrHelper.capitalize(returnedRegDataGender[i]['dimensions'][0]);
            genderDataObj.value = Number(returnedRegDataGender[i]['metrics'][0]['values']);
            acquisitionDataRegGender.push(genderDataObj);
            genderDataObj = {};
        }
        acquisitionDataRegGender = _.orderBy(acquisitionDataRegGender, ['value'],['desc']);
        yield put(setAcquisitionDataRegGender(acquisitionDataRegGender));
    } catch (error) {
        console.log(error);
    }
}

export function* handlePuzzleAcquisitionDataAgeGroup() {
    try {
        let acquisitionDataRegAgeGroup = ['loading'], returnedRegDataAgeGroup = [];
        yield put(setAcquisitionDataRegAgeGroup(acquisitionDataRegAgeGroup));

        const jwtToken = yield call(getJwtToken);
        const storedDateRange = JSON.parse(localStorage.getItem("fetchDate_range"));
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = dateFrom.replace(/\//g, '-');
        dateTo = dateTo.replace(/\//g, '-');

        if (storedDateRange !== null && storedDateRange[0] === dateFrom && storedDateRange[1] === dateTo) {
            returnedRegDataAgeGroup = JSON.parse(localStorage.getItem("puzzle_data_returnedRegDataAgeGroup")); // fetch from LS
            console.log('Age from LS');
        } else {
            console.log('Age from API');
            let queryData = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:userAgeBracket"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dimensionFilterClauses": [
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                    "dimensionName":"ga:eventCategory",
                                    "operator":"EXACT",
                                    "expressions":'Registration'
                                    }
                                ]
                            }
                        ],
                        "dateRanges": [{"startDate": dateFrom, "endDate": dateTo}],
                        "viewId": "227581633",
                        'pageSize': 100
                    }
                ]
            };
            returnedRegDataAgeGroup = yield call(fetchNodeGaEventData, queryData, ENDPOINT.GET_DATA_FROM_GA, jwtToken);
            localStorage.setItem("puzzle_data_returnedRegDataAgeGroup", JSON.stringify(returnedRegDataAgeGroup));
        }
        console.log('returnedRegDataAgeGroup:', returnedRegDataAgeGroup);
        acquisitionDataRegAgeGroup = [];
        let ageGroupDataObj = {};
        for (let i=0; i<returnedRegDataAgeGroup.length;i++) {
            ageGroupDataObj.name = H.FormatNumStrHelper.capitalize(returnedRegDataAgeGroup[i]['dimensions'][0]);
            ageGroupDataObj.value = Number(returnedRegDataAgeGroup[i]['metrics'][0]['values']);
            acquisitionDataRegAgeGroup.push(ageGroupDataObj);
            ageGroupDataObj = {};
        }
        acquisitionDataRegAgeGroup = _.orderBy(acquisitionDataRegAgeGroup, ['value'],['desc']);
        yield put(setAcquisitionDataRegAgeGroup(acquisitionDataRegAgeGroup));
    } catch (error) {
        console.log(error);
    }
}

export default function* watchMapSettings() {
    yield takeEvery(PUZZLE.GET_ACQUISITION_DATA, handlePuzzleAcquisitionData);
    yield takeEvery(PUZZLE.GET_ACQUISITION_DATA, handlePuzzleAcquisitionDataGender);
    yield takeEvery(PUZZLE.GET_ACQUISITION_DATA, handlePuzzleAcquisitionDataAgeGroup);
    yield takeEvery(PAGE.GET_PUZZLE_PAGE_DATA, handlePuzzleAcquisitionData);
    yield takeEvery(PAGE.GET_PUZZLE_PAGE_DATA, handlePuzzleAcquisitionDataGender);
    yield takeEvery(PAGE.GET_PUZZLE_PAGE_DATA, handlePuzzleAcquisitionDataAgeGroup);
}