import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDataLoadingForBy, setDataByCountry, setDataLoad } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.nftDepOverview.loading;
export const dataLoaded = state => state.data.dataLoaded;

export function* getByCountryData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByCountry = true;
        yield put(setDataLoadingForBy(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQueryTx1stAmountByCountry = H.SqlHelper.getNftTransactionAmountByCoutryBidb2(
            {table: 'SxiNftSalesProd1stV2', limit: 10, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTx1stCountByCountry = H.SqlHelper.getNftTransactionCountByCoutryBidb2(
            {table: 'SxiNftSalesProd1stV2', limit: 10, tradeType: 'REVENUE_1ST', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTx2ndAmountByCountry = H.SqlHelper.getNftTransactionAmountByCoutryBidb2(
            {table: 'SxiNftSalesProd2ndV1', limit: 10, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTx2ndCountByCountry = H.SqlHelper.getNftTransactionCountByCoutryBidb2(
            {table: 'SxiNftSalesProd2ndV1', limit: 10, tradeType: 'TRADE_2ND', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTxFiatAmountByCountry = H.SqlHelper.getNftTransactionAmountByCoutryBidb2(
            {table: 'SxiNftSalesProdFiatV1', limit: 10, tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd]}
        );
        const sqlQueryTxFiatCountByCountry = H.SqlHelper.getNftTransactionCountByCoutryBidb2(
            {table: 'SxiNftSalesProdFiatV1', limit: 10, tradeType: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd]}
        );

        const [
            dataTx1stAmountByCountryTemp,
            dataTx1stCountByCountryTemp,
            dataTx2ndAmountByCountryTemp,
            dataTx2ndCountByCountryTemp,
            dataTxFiatAmountByCountryTemp,
            dataTxFiatCountByCountryTemp,
        ] = yield all([
            call(getDataFromSxi, sqlQueryTx1stAmountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx1stCountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx2ndAmountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTx2ndCountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTxFiatAmountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromSxi, sqlQueryTxFiatCountByCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataTx1stAmountByCountry = JSON.parse(dataTx1stAmountByCountryTemp.body);
        const dataTx1stCountByCountry = JSON.parse(dataTx1stCountByCountryTemp.body);
        const dataTx2ndAmountByCountry = JSON.parse(dataTx2ndAmountByCountryTemp.body);
        const dataTx2ndCountByCountry = JSON.parse(dataTx2ndCountByCountryTemp.body);
        const dataTxFiatAmountByCountry = JSON.parse(dataTxFiatAmountByCountryTemp.body);
        const dataTxFiatCountByCountry = JSON.parse(dataTxFiatCountByCountryTemp.body);

        // // Auction
        let returnAuctionDataArray = [], dataAmountAuctionByCountry = {};
        let returnAuctionCountDataArray = [], dataCountAuctionByCountry = {};

        // auction amount
        _.each(dataTx2ndAmountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnAuctionDataArray.length < 11) {
                dataAmountAuctionByCountry.country = data[1]['stringValue'];
                dataAmountAuctionByCountry.value = Number(data[0]['stringValue']);
                returnAuctionDataArray.push(dataAmountAuctionByCountry);
                dataAmountAuctionByCountry = {};
            }
        });

        // auction count
        _.each(dataTx2ndCountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnAuctionCountDataArray.length < 11) {
                dataCountAuctionByCountry.country = data[1]['stringValue'];
                dataCountAuctionByCountry.value = data[0]['longValue'];
                returnAuctionCountDataArray.push(dataCountAuctionByCountry);
                dataCountAuctionByCountry = {};
            }
        });

        // store amount
        let returnStoreDataArray = [], dataAmountStoreByCountry = {};
        let returnStoreCountDataArray = [], dataCountStoreByCountry = {};
        _.each(dataTx1stAmountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnStoreDataArray.length < 11) {
                dataAmountStoreByCountry.country = data[1]['stringValue'];
                dataAmountStoreByCountry.value = Number(data[0]['stringValue']);
                returnStoreDataArray.push(dataAmountStoreByCountry);
                dataAmountStoreByCountry = {};
            }
        });

        // store count
        _.each(dataTx1stCountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnStoreCountDataArray.length < 11) {
                dataCountStoreByCountry.country = data[1]['stringValue'];
                dataCountStoreByCountry.value = data[0]['longValue'];
                returnStoreCountDataArray.push(dataCountStoreByCountry);
                dataCountStoreByCountry = {};
            }
        });

        // Fiat amount
        let returnFiatDataArray = [], dataAmountFiatByCountry = {};
        let returnFiatCountDataArray = [], dataCountFiatByCountry = {};
        _.each(dataTxFiatAmountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnFiatDataArray.length < 11) {
                dataAmountFiatByCountry.country = data[1]['stringValue'];
                dataAmountFiatByCountry.value = Number(data[0]['stringValue']);
                returnFiatDataArray.push(dataAmountFiatByCountry);
                dataAmountFiatByCountry = {};
            }
        });

        // Fiat count
        _.each(dataTxFiatCountByCountry, function(data, index) {
            if (data['country'] !== null && data['country'] !== "" && returnFiatCountDataArray.length < 11) {
                dataCountFiatByCountry.country = data[1]['stringValue'];
                dataCountFiatByCountry.value = data[0]['longValue'];
                returnFiatCountDataArray.push(dataCountFiatByCountry);
                dataCountFiatByCountry = {};
            }
        });

        // const sqlQueryDepAuctionAmount = {
        //     // "query": `SELECT SUM(amount) FROM NftDepReceiptProd WHERE kind in ("auction") AND logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
        //     "query": `SELECT fromCountry AS country, SUM(amount) AS count FROM NftDepReceiptProd WHERE kind = 'auction' AND 
        //     logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} GROUP BY fromCountry ORDER BY count DESC LIMIT 10;`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // };
        // const sqlQueryDepStoreAmount = {
        //     "query": `SELECT fromCountry AS country, SUM(amount) AS count FROM NftDepReceiptProd WHERE kind = 'store' AND 
        //     logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} GROUP BY fromCountry ORDER BY count DESC LIMIT 10;`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // };
        // const sqlQueryDepAuctionCount = {
        //     "query": `SELECT fromCountry AS country, COUNT(*) AS count FROM NftDepReceiptAuctionProd WHERE kind = 'auction' AND 
        //     logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} GROUP BY fromCountry ORDER BY count DESC LIMIT 10;`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // };
        // const sqlQueryDepStoreCount = {
        //     "query": `SELECT fromCountry AS country, COUNT(*) AS count FROM NftDepReceiptStoreProd WHERE kind = 'store' AND 
        //     logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} GROUP BY fromCountry ORDER BY count DESC LIMIT 10;`,
        //     "database": "KpiDashboard",
        //     "type": "list"
        // };

        // const [
        //     tempDepAuctionAmount,
        //     tempDepStoreAmount,
        //     tempDepAuctionCount,
        //     tempDepStoreCount,
        // ] = yield all([
        //     call(getDataFromRDS, sqlQueryDepAuctionAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, sqlQueryDepStoreAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, sqlQueryDepAuctionCount, ENDPOINT.GET_DATA_RDS, jwtToken),
        //     call(getDataFromRDS, sqlQueryDepStoreCount, ENDPOINT.GET_DATA_RDS, jwtToken),
        // ]);
        //const parsedAuctionAmount = JSON.parse(tempDepAuctionAmount.body);
        // const parsedStoreAmount = JSON.parse(tempDepStoreAmount.body);
        // const parsedAuctionCount = JSON.parse(tempDepAuctionCount.body);
        // const parsedStoreCount = JSON.parse(tempDepStoreCount.body);

        // Amount
        // let returnAuctionDataArray = [], dataAmountAuctionByCountry = {};
        // _.each(parsedAuctionAmount, function(data, index) {
        //     dataAmountAuctionByCountry.country = data[0]['stringValue'];
        //     dataAmountAuctionByCountry.value =  Number(data[1]['stringValue']);
        //     returnAuctionDataArray.push(dataAmountAuctionByCountry);
        //     dataAmountAuctionByCountry = {};
        // });

        // let returnStoreDataArray = [], dataAmountStoreByCountry = {};
        // _.each(parsedStoreAmount, function(data, index) {
        //     dataAmountStoreByCountry.country = data[0]['stringValue'];
        //     dataAmountStoreByCountry.value =  Number(data[1]['stringValue']);
        //     returnStoreDataArray.push(dataAmountStoreByCountry);
        //     dataAmountStoreByCountry = {};
        // });

        // Count
        // let returnAuctionCountDataArray = [], dataCountAuctionByCountry = {};
        // _.each(parsedAuctionCount, function(data, index) {
        //     dataCountAuctionByCountry.country = data[0]['stringValue'];
        //     dataCountAuctionByCountry.value =  data[1]['longValue'];
        //     returnAuctionCountDataArray.push(dataCountAuctionByCountry);
        //     dataCountAuctionByCountry = {};
        // });

        // let returnStoreCountDataArray = [], dataCountStoreByCountry = {};
        // _.each(parsedStoreCount, function(data, index) {
        //     dataCountStoreByCountry.country = data[0]['stringValue'];
        //     dataCountStoreByCountry.value =  data[1]['longValue'];
        //     returnStoreCountDataArray.push(dataCountStoreByCountry);
        //     dataCountStoreByCountry = {};
        // });

        const txAmountValue = {
            amount: {
                auction: returnAuctionDataArray,
                store: returnStoreDataArray,
                fiat: returnFiatDataArray,
            },
            count: {
                auction: returnAuctionCountDataArray,
                store: returnStoreCountDataArray,
                fiat: returnFiatCountDataArray,
            },
        }
        yield put(setDataByCountry(txAmountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByCountry = false;
        yield put(setDataLoadingForBy(loadingUpdate2));

        // set data loaded flag
        const dataExist = yield select(dataLoaded);
        const dataAdd = { "nftdep-overview-country" : [dateFrom, dateTo] }
        const newData = _.merge(dataExist, dataAdd);
        yield put(setDataLoad(newData));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_OVERVIEW_DATA_BY_COUNTRY, getByCountryData);
}
