import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { GetApp } from "@material-ui/icons";
import { CSVLink } from "react-csv";
import * as C from "components";

const useStyles = makeStyles({
  loaderBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: "flex",
    minHeight: 200,
  },
  dateTableWrapper: {
    float: "left",
  },
  dataTableWrapper: {
    float: "left",
    overflow: "scroll",
  },
  leftTable: {
    border: "1px solid #eee",
  },
  lblUniqueUser: {
    color: "#6f6969",
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: "right",
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: "1px solid #eee6e6",
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: "right",
    verticalAlign: "middle",
    border: "1px solid #eee6e6",
    height: "3em",
    overflowX: "auto",
  },
  tTdHeader: {
    paddingLeft: "1rem",
    paddingRight: 19,
    fontWeight: "bold",
    height: 39,
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  widgetTitle: {
    flexBasis: "50%",
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: "auto",
    padding: 16,
  },
});

const PicTreeTable = ({
  pageTopLoader,
  game,
  dataLabel,
  data,
  tableKey,
  csvFileName,
  getExportData,
  getDataTable,
  getTableLabelColumn,
  chartSize,
}) => {
  const classes = useStyles();

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}></div>
        <div className={classes.exportBtnContainer}>
          <CSVLink data={getExportData(data)} filename={csvFileName}>
            <Button
              variant="contained"
              color="default"
              className={classes.exportBtn}
              startIcon={<GetApp />}
            >
              CSV出力
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className={classes.tableContainer}>
        {pageTopLoader ? (
          <div className={classes.loaderBox}>
            <img
              className={classes.loaderBarImg}
              src={`/static/images/loader-bar.gif`}
            />
          </div>
        ) : data.length > 0 ? (
          data.length > 0 ? (
            <>
              <div className={classes.dateTableWrapper}>
                <table className={classes.leftTable}>
                  <tbody>
                    <tr>
                      <td className={classes.tTdHeader}></td>
                    </tr>
                    {getTableLabelColumn(data)}
                  </tbody>
                </table>
              </div>

              <div className={classes.dataTableWrapper}>
                <table className={classes.dataTableBody}>
                  <tbody>
                    <tr className={classes._GAIv}>
                      {data[0]["date"].map((item, i) => (
                        <td className={classes.dataHeaderTd} key={i}>
                          {chartSize !== "hour"
                            ? moment(item).format('M/D')
                            : moment(item).format('M/D HH:mm')}
                        </td>
                      ))}
                    </tr>
                    {getDataTable(data)}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className={classes.loaderBox}>
              <C.NoData text="No Data" />
            </div>
          )
        ) : null}
      </div>
    </Paper>
  );
};

PicTreeTable.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  chartSize: state.pictree.chartSize,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PicTreeTable);
