import { put, call, takeEvery, select, all } from 'redux-saga/effects';
import _ from 'lodash';
import validator from 'email-validator';
import moment from 'moment';
import { setCreateStatus, setCreateBtnState, setAffiliatorsData, setCampaignName, setCampaignEmail, setCampaignType, setCampaignGame, setCampaignGroup, setCampaignGroupSelected, setCampaignMedia, setSubmitAffiliate, setUpdateAffiliatorState, updateAffiliateListData, setAffiliatorFT, setAffiliatorReg, setAffiliatorConv, setAffiliatorCode, setUsersListData, setActiveAffiliateNumber, setLastUpdateDateTime, setNoUsersData, setTrendChartData, setNoTrendData, setLoadingTrendData, setUsersRegListData, setUsersGameDirecListData } from 'actions';
import { createAffiliator, fetchAffiliators, updateAffiliator, deleteAffiliator, fetchUsersDataByAid, fetchTrendDataByAid, fetchCampaignGroup, getJwtToken, fetchTrendDataByAidGA, fetchNodeGaEventData } from 'api';
import { AFFILIATE, USERS, ENDPOINT } from '../constants';
import * as H from 'helper';

export const affiliateName = state => state.affiliate.affiliatorName;
export const affiliateEmail = state => state.affiliate.affiliatorEmail;
export const affiliateType = state => state.affiliate.affiliatorType;
export const affiliateGroup = state => state.affiliate.affiliatorGroup;
export const affiliateGame = state => state.affiliate.affiliatorGame;
export const affiliateGroupSelected = state => state.affiliate.affiliatorGroupSelected;
export const sidebarStatus = state => state.affiliate.sidebarStatus;
export const affiliateIdToUpdate = state => state.affiliate.affiliateIdToUpdate;
export const affiliateCode = state => state.affiliate.affiliatorCode;
export const accessKey = state => state.page.accessKey;

export function* handleAffiliateName(param) {
    try {
        let name = yield select(affiliateName);
        if (name.length > 1) {
            yield put(setCreateStatus('name', true));
        } else {
            yield put(setCreateStatus('name', false));
        }
    } catch (error) {
        yield all([])
    }
}

export function* handleAffiliateEmail(param) {
    try {
        let email = yield select(affiliateEmail);
        if (validator.validate(email)) {
            yield put(setCreateStatus('email', true));
        } else {
            yield put(setCreateStatus('email', false));
        }
    } catch (error) {
        yield all([])
    }
}

export function* handleAffiliateType(param) {
    try {
        let type = yield select(affiliateType);
        if (type !== '') {
            yield put(setCreateStatus('type', true));
        } else {
            yield put(setCreateStatus('type', false));
        }
    } catch (error) {
        yield all([])
    }
}

export function* handleAffiliateGame(param) {
    try {
        let type = yield select(affiliateGame);
        if (type !== '') {
            yield put(setCreateStatus('game', true));
        } else {
            yield put(setCreateStatus('game', false));
        }
    } catch (error) {
        yield all([])
    }
}

export function* handleCreateButtonState(param) {
    const sideBarStatus = yield select(sidebarStatus);
    const stateValue = Object.values(sideBarStatus);
    if (stateValue.includes(false)) {
        yield put(setCreateBtnState(false));
    } else {
        yield put(setCreateBtnState(true));
    }
}

export function* handleSetAffiliator(param) {
    try {
        let accessKeyValue = [];
        yield put(setSubmitAffiliate(true));
        let name = yield select(affiliateName);
        let email = yield select(affiliateEmail);
        let type = yield select(affiliateType);
        let game = yield select(affiliateGame);
        let group = yield select(affiliateGroupSelected);
        for (let i=0; i<group.length; i++) {
            accessKeyValue.push(group[i]['accessKey']);
        }
        accessKeyValue = accessKeyValue.join();
        let queryData = {
            "Name": name,
            "Email": email,
            "Type": type,
            "Game": game,
            "AccessKey": accessKeyValue,
        };

        const jwtToken = yield call(getJwtToken);
        let request = yield call(createAffiliator, queryData, ENDPOINT.CREATE_AFFILIATOR, jwtToken);
        if (request) {
            yield put(updateAffiliateListData());
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleUpdateAffiliator(param) {
    try {
        yield put(setUpdateAffiliatorState(true));
        let aid = yield select(affiliateIdToUpdate)
        let name = yield select(affiliateName);
        let email = yield select(affiliateEmail);
        let type = yield select(affiliateType);
        let game = yield select(affiliateGame);
        let selectedGroup = yield select(affiliateGroupSelected);
        let accessKeyArry = [];
        for (let i=0; i<selectedGroup.length; i++) {
            accessKeyArry.push(selectedGroup[i]['accessKey']);
        }
        let queryData = {
            "id": aid,
            "Name": name,
            "Email": email,
            "Type": type,
            "Game": game,
            "AccessKey": accessKeyArry.join(),
        };
        const jwtToken = yield call(getJwtToken);
        let request = yield call(updateAffiliator, queryData, ENDPOINT.UPDATE_AFFILIATOR, jwtToken);
        if (request.status) {
            yield put(updateAffiliateListData());
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleDeleteAffiliate(param) {
    try {
        let affiliatorsList = JSON.parse(localStorage.getItem('affiliatorsList'));
        let newAffiliatorsList = [], deleteIds = [];
        let deletedArray = param.payload;
        for (let i=0; i<affiliatorsList.length; i++) {
            if (deletedArray.indexOf(i) === -1) {
                newAffiliatorsList.push(affiliatorsList[i]);
            } else {
                deleteIds.push(affiliatorsList[i]['AffiliateId']);
            }
        }
        let queryData = {
            "id": deleteIds
        }
        const jwtToken = yield call(getJwtToken);
        let request = yield call(deleteAffiliator, queryData, ENDPOINT.DELETE_AFFILIATOR, jwtToken);
        if (request.status) {
            yield put(setActiveAffiliateNumber(newAffiliatorsList.length));
            yield put(setAffiliatorsData(newAffiliatorsList));
            localStorage.setItem('affiliatorsList', JSON.stringify(newAffiliatorsList));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* getAffiliateListDataByKey() {
    try {
        // get affiliate data by accessKey
        let now = new Date().getTime().toString();
        let ak = yield select(accessKey); //yield take('SET_ACCESS_KEY'); //yield select(accessKey); //localStorage.getItem('access_key')
        ak = ak === null ? localStorage.getItem('access_key') : ak;
        console.log('diffMin', moment.duration(now - localStorage.getItem('dt_all')).minutes());

        let affiliatorsList, formattedGaData = [];
        if (localStorage.getItem('dt_all') !== null &&
            moment.duration(now - localStorage.getItem('dt_all')).minutes() < 5) {
            console.log('load from LS');
            affiliatorsList = JSON.parse(localStorage.getItem('affiliatorsList'));
            formattedGaData = JSON.parse(localStorage.getItem('allEventDataGA'));
        } else {
            console.log('load from API');
            const jwtToken = yield call(getJwtToken);
            // GET from AWS
            affiliatorsList = yield call(fetchAffiliators, ENDPOINT.AFFILIATOR_LIST_BY_KEY+ak, jwtToken);

            // GET from GA: JobTribes
            let endDate = new Date().toISOString().slice(0,10);
            let queryData = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:eventLabel"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dateRanges": [{"startDate": "2020-08-01","endDate": endDate}], // 2020-11-09
                        "dimensionFilterClauses":[
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                        "dimensionName":"ga:eventCategory",
                                        "operator":"PARTIAL",
                                        "expressions":["Registration"]
                                    }
                                ]
                            },
                        ],
                        "viewId": "226075302",
                        'pageSize': 100000
                    }
                ]
            };
            const dataRowsJT = yield call(fetchNodeGaEventData, queryData, ENDPOINT.GET_DATA_FROM_GA, jwtToken);
            console.log('dataRowsJT len:', dataRowsJT.length);
            let eachRowObj = {}, labelArray = [], allRidArray = [];
            for (let i=0; i<dataRowsJT.length; i++) {
                labelArray = dataRowsJT[i]['dimensions'][0].split(' | ');
                eachRowObj.game = labelArray[0];
                eachRowObj.pmid = localStorage.getItem('access_key') === process.env.REACT_APP_SUPERUSER ? labelArray[2] : 'No Data';
                eachRowObj.rid = labelArray[1];
                eachRowObj.timestamp = labelArray[3];
                formattedGaData.push(eachRowObj);
                allRidArray.push(labelArray[1]);
                eachRowObj = {};
                labelArray = [];
            }
            let ridOccurrence = _.values(_.groupBy(allRidArray)).map(d => ({rid: d[0], count: d.length}));

            // Update AWS Reg data
            for (let i=0; i<affiliatorsList.length; i++) {
                let obj = ridOccurrence.find(o => o.rid === affiliatorsList[i]['Rid']);
                if (obj) {
                    affiliatorsList[i]['Reg'] = affiliatorsList[i]['Reg'] + obj.count;
                }
            }

            // GET from GA: Puzzle
            let queryDataPuzzle = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:eventLabel"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dateRanges": [{"startDate": "2020-10-01","endDate": endDate}],
                        "dimensionFilterClauses":[
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                        "dimensionName":"ga:eventCategory",
                                        "operator":"EXACT",
                                        "expressions":'Registration'
                                    }
                                ]
                            },
                        ],
                        "viewId": "227581633",
                        'pageSize': 100000
                    }
                ]
            };

            const dataRowsPuzzle = yield call(fetchNodeGaEventData, queryDataPuzzle, ENDPOINT.GET_DATA_FROM_GA, jwtToken);
            console.log('dataRowsPuzzle:', dataRowsPuzzle.length);
            eachRowObj = {}; labelArray = []; allRidArray = [];
            for (let i=0; i<dataRowsPuzzle.length; i++) {
                labelArray = dataRowsPuzzle[i]['dimensions'][0].split(' | ');
                eachRowObj.game = labelArray[0];
                eachRowObj.pmid = localStorage.getItem('access_key') === process.env.REACT_APP_SUPERUSER ? labelArray[2] : 'No Data';
                eachRowObj.rid = labelArray[1];
                eachRowObj.timestamp = labelArray[3];
                formattedGaData.push(eachRowObj);
                allRidArray.push(labelArray[1]);
                eachRowObj = {};
                labelArray = [];
            }

            let ridOccurrence2 = _.values(_.groupBy(allRidArray)).map(d => ({rid: d[0], count: d.length}));

            // Update AWS Reg data
            for (let i=0; i<affiliatorsList.length; i++) {
                let obj = ridOccurrence2.find(o => o.rid === affiliatorsList[i]['Rid']);
                if (obj) {
                    affiliatorsList[i]['Reg'] = affiliatorsList[i]['Reg'] + obj.count;
                }
            }
            localStorage.setItem('dt_all', now);
            yield put(setLastUpdateDateTime(now));
        }
        yield put(setAffiliatorsData(affiliatorsList));
        console.log('formattedGaData:', formattedGaData);
        localStorage.setItem('affiliatorsList', JSON.stringify(affiliatorsList));
        localStorage.setItem('allEventDataGA', JSON.stringify(formattedGaData));
    } catch (error) {
        console.log(error);
    }
}

export function* handleUpdateAffiliateListData() {
    try {
        const jwtToken = yield call(getJwtToken);
        let affiliatorsList = yield call(fetchAffiliators, ENDPOINT.AFFILIATOR_LIST, jwtToken);
        yield put(setAffiliatorsData(affiliatorsList));
        localStorage.setItem('affiliatorsList', JSON.stringify(affiliatorsList));
        localStorage.setItem('dt_all', new Date().getTime().toString());
        yield put(setLastUpdateDateTime(new Date().getTime().toString()));
    } catch (error) {
        console.log('error:', error);
    }
}

export function* handleGetAffiliateDataById() {
    try {
        let affiliatorData;
        let aid = yield select(affiliateIdToUpdate), aName, aEmail, aType, aGame, aCode;
        let campaignAccessGroup = [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}];
        const jwtToken = yield call(getJwtToken);
        affiliatorData = yield call(fetchAffiliators, ENDPOINT.AFFILIATOR_BY_ID+aid, jwtToken);

        aName = affiliatorData.Name;
        aEmail = affiliatorData.Email;
        aType = affiliatorData.Type;
        aGame = affiliatorData.Game === undefined ? 'JobTribes' : affiliatorData.Game;
        aCode = affiliatorData.Rid;
        if (affiliatorData.AccessKey) {
            let accessKeyParsed = affiliatorData.AccessKey;
            if (affiliatorData.AccessKey.indexOf(",") !== -1) {
                let accessKeyArray = affiliatorData.AccessKey.split(',');
                accessKeyParsed = accessKeyArray[1];
            }
            const allBiList = JSON.parse(localStorage.getItem('allBIUsers'));
            //console.log('Log campaign name mapping :', allBiList.find(function(obj){ return obj.key===affiliatorData.AccessKey }).value)
            if (accessKeyParsed !== process.env.REACT_APP_SUPERUSER) {
                campaignAccessGroup.push(
                    {
                        name: allBiList.find(function(obj){ return obj.key===accessKeyParsed }).value,
                        accessKey: accessKeyParsed
                    }
                )
            }
        }
        yield put(setCampaignName(aName));
        yield put(setCampaignEmail(aEmail));
        yield put(setCampaignType(aType));
        yield put(setCampaignGame(aGame));
        yield put(setAffiliatorCode(aCode));
        yield put(setCampaignGroupSelected(campaignAccessGroup));
    } catch (error) {
        console.log(error);
    }
}

export function* handleClearAffiliateFields() {
    try {
        // get all affiliate data
        yield put(setCampaignName(''));
        yield put(setCampaignEmail(''));
        yield put(setCampaignType(''));
        yield put(setCampaignGame(''));
        yield put(setCampaignGroup([{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}]));
        yield put(setCampaignGroupSelected([{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}]));
        yield put(setCampaignMedia([]));
        yield put(setAffiliatorCode(''));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetUsersDataByAid() {
    try {
        const jwtToken = yield call(getJwtToken);
        let usersData;
        let rid = yield select(affiliateCode);
        if (!rid) {
            let aid = yield select(affiliateIdToUpdate);
            let affiliatorData = yield call(fetchAffiliators, ENDPOINT.AFFILIATOR_BY_ID+aid, jwtToken);
            rid = affiliatorData.Rid;
        }
        yield put(setNoUsersData(false));
        usersData = yield call(fetchUsersDataByAid, ENDPOINT.USERS_DATA_BY_AID+rid, jwtToken);
        //console.log('usersData:', usersData);
        yield put(setUsersListData(usersData));
        if (usersData.length === 0) {
            yield put(setNoUsersData(true));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetTrendDataByAid() {
    try {
        let type = yield select(affiliateType);
        const jwtToken = yield call(getJwtToken);
        yield put(setLoadingTrendData(true));
        yield put(setNoUsersData(false));
        let trendData, trendDataFormatted = [], trendDataReg;
        let rid = yield select(affiliateCode);
        let game = yield select(affiliateGame);
        if (!rid) {
            let aid = yield select(affiliateIdToUpdate);
            let affiliatorData = yield call(fetchAffiliators, ENDPOINT.AFFILIATOR_BY_ID+aid, jwtToken);
            rid = affiliatorData.Rid;
            type =  affiliatorData.Type;
            game = affiliatorData.Game;
        }
        let endDate = new Date().toISOString().slice(0,10);
        if (type === 'campaign_game') {
            let queryDataReg = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:dateHourMinute"},{"name": "ga:country"},
                                       {"name": "ga:city"},{"name": "ga:language"},{"name": "ga:eventLabel"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dimensionFilterClauses": [
                            {
                                "operator":"AND",
                                "filters":[{
                                    "dimensionName":"ga:eventLabel",
                                    "operator":"PARTIAL",
                                    "expressions":rid
                                }]
                            },
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                    "dimensionName":"ga:eventCategory",
                                    "operator":"PARTIAL",
                                    "expressions":'Registration'
                                    }
                                ]
                            }
                        ],
                        "dateRanges": [{"startDate": "2020-08-01","endDate": endDate}],
                        "viewId": game === 'Puzzle' ? '227581633' : "226075302"
                    }
                ]
            }
            trendDataReg = yield call(fetchNodeGaEventData, queryDataReg, ENDPOINT.TREND_DATA_BY_AID_GA+rid, jwtToken);
            console.log('hehe2')
            //console.log('trendDataReg:', trendDataReg);

            let queryData = {
                "query": [
                    {
                        "dimensions": [{"name": "ga:campaign"},{"name": "ga:dateHourMinute"},{"name": "ga:country"},
                                       {"name": "ga:city"},{"name": "ga:language"},{"name": "ga:referralPath"}],
                        "metrics": [{"expression": "ga:newUsers"}],
                        "dimensionFilterClauses": [{
                                            "operator":"AND",
                                            "filters":[{
                                                "dimensionName":"ga:campaign",
                                                "operator":"PARTIAL",
                                                "expressions":rid}]}],
                        "dateRanges": [{"startDate": "2020-09-10","endDate": endDate}],
                        "viewId": game === 'Puzzle' ? '227581633' : "226075302"
                    }
                ]
            };
            trendData = yield call(fetchTrendDataByAidGA, queryData, ENDPOINT.TREND_DATA_BY_AID_GA+rid, jwtToken);
            //console.log('trendData:', trendData);

            let regArray = [], regObj = {}, regTimestamp;
            if (trendDataReg !== null) {
                if (trendDataReg.length > 0) {
                    for (let i=0; i<trendDataReg.length; i++) {
                        regTimestamp = trendDataReg[i]['dimensions'][4].split(" | ");
                        regObj.Timestamp = Number(regTimestamp[regTimestamp.length - 1]);
                        regArray.push(regObj);
                        regObj = {};
                    }
                }
            }

            if (trendData.length > 0) {
                trendDataFormatted = H.FormatDataHelper.formatTrendChartData(trendData, regArray);
            }
            yield put(setTrendChartData(trendDataFormatted));
            yield put(setUsersGameDirecListData(trendData));
            yield put(setLoadingTrendData(false));
        } else {
            // via LP
            let queryData = {
                "query": [
                    {
                        // "dimensions": [{"name": "ga:dateHourMinute"},{"name": "ga:country"},
                        //                {"name": "ga:city"},{"name": "ga:language"},{"name": "ga:eventLabel"}],
                        "dimensions": [{"name": "ga:eventLabel"}],
                        "metrics": [{"expression": "ga:uniqueEvents"}],
                        "dimensionFilterClauses": [
                            {
                                "operator":"AND",
                                "filters":[{
                                    "dimensionName":"ga:eventLabel",
                                    "operator":"PARTIAL",
                                    "expressions":rid
                                }]
                            },
                            {
                                "operator":"AND",
                                "filters":[
                                    {
                                    "dimensionName":"ga:eventCategory",
                                    "operator":"PARTIAL",
                                    "expressions":'Registration'
                                    }
                                ]
                            }
                        ],
                        "dateRanges": [{"startDate": "2020-08-01","endDate": endDate}],
                        "viewId": game === 'Puzzle' ? '227581633' : "226075302",
                        'pageSize': 100000
                    }
                ]
            };
            trendDataReg = yield call(fetchNodeGaEventData, queryData, ENDPOINT.TREND_DATA_BY_AID_GA+rid, jwtToken);
            console.log('hehe3')
            trendData = yield call(fetchTrendDataByAid, ENDPOINT.TREND_DATA_BY_AID+rid, jwtToken);

            let regArray = [], regObj = {}, regTimestamp;
            if (trendDataReg !== null) {
                if (trendDataReg.length > 0) {
                    for (let i=0; i<trendDataReg.length; i++) {
                        regTimestamp = trendDataReg[i]['dimensions'][0].split(" | ");
                        regObj.Timestamp = Number(regTimestamp[regTimestamp.length - 1]);
                        regArray.push(regObj);
                        regObj = {};
                    }
                }
            }
            trendDataFormatted = H.FormatDataHelper.formatTrendChartData(trendData, regArray);
            yield put(setTrendChartData(trendDataFormatted));
            yield put(setLoadingTrendData(false));
        }
        if (trendData.length === 0) {
            yield put(setNoTrendData(true));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetCampaignGroup() {
    try {
        const jwtToken = yield call(getJwtToken);

        let groupData = [{name: 'DEA', accessKey: process.env.REACT_APP_SUPERUSER}], groupObj = {};
        let dataReturned = yield call(fetchCampaignGroup, ENDPOINT.AFFILIATOR_GROUP, jwtToken);
        for (let i=0; i<dataReturned.length; i++) {
            if (process.env.REACT_APP_SUPERUSER !== dataReturned[i]['Attributes'].find(function(obj){ return obj.Name === "custom:access_key" }).Value) {
                groupObj.name = dataReturned[i]['Attributes'].find(function(obj){ return obj.Name === "custom:client_name" }).Value;
                groupObj.accessKey = dataReturned[i]['Attributes'].find(function(obj){ return obj.Name === "custom:access_key" }).Value;
                if (groupObj.accessKey !== "DBFFFBA3AA5AA8394EE342993B973" && groupObj.accessKey !== "DeRKXQzmsg9YMY4NrDZ8aqxp4yJh") {
                    groupData.push(groupObj);
                }
                groupObj = {};
            }
        }
        yield put(setCampaignGroup(groupData));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetRegDataByAid() {
    try {
        const jwtToken = yield call(getJwtToken);
        let rid = yield select(affiliateCode);
        let game = yield select(affiliateGame);
        if (!rid) {
            let aid = yield select(affiliateIdToUpdate);
            let affiliatorData = yield call(fetchAffiliators, ENDPOINT.AFFILIATOR_BY_ID+aid, jwtToken);
            rid = affiliatorData.Rid;
            game = affiliatorData.Game;
        }
        let endDate = new Date().toISOString().slice(0,10);
        let queryData = {
            "query": [
                {
                    "dimensions": [{"name": "ga:dateHourMinute"},{"name": "ga:country"},
                                   {"name": "ga:city"},{"name": "ga:language"},{"name": "ga:eventLabel"}],
                    "metrics": [{"expression": "ga:uniqueEvents"}],
                    "dimensionFilterClauses": [
                                    {
                                        "operator":"AND",
                                        "filters":[
                                            {
                                            "dimensionName":"ga:eventLabel",
                                            "operator":"PARTIAL",
                                            "expressions":rid
                                            }
                                        ]
                                    },
                                    {
                                        "operator":"AND",
                                        "filters":[
                                            {
                                            "dimensionName":"ga:eventCategory",
                                            "operator":"PARTIAL",
                                            "expressions":'Registration'
                                            }
                                        ]
                                    }
                                ],
                    "dateRanges": [{"startDate": "2020-08-01","endDate": endDate}],
                    "viewId": game === 'Puzzle' ? '227581633' : "226075302"
                }
            ]
        };
        let returnedData = yield call(fetchNodeGaEventData, queryData, ENDPOINT.TREND_DATA_BY_AID_GA+rid, jwtToken);
        console.log('hehe4')
        if (returnedData === null) {
            yield put(setUsersRegListData([]));
        } else if (returnedData.length === "0") {
            yield put(setUsersRegListData([]));
        } else {
            let eachRegUser = {}, labelArray = [], allRegUsers = [];
            for (let i=0; i<returnedData.length; i++) {
                labelArray = returnedData[i]['dimensions'][4].split(' | ');
                eachRegUser.pmid = localStorage.getItem('access_key') === process.env.REACT_APP_SUPERUSER ? labelArray[2] : 'No Data';
                eachRegUser.rid = labelArray[1];
                eachRegUser.country = returnedData[i]['dimensions'][1];
                eachRegUser.city = returnedData[i]['dimensions'][2];
                eachRegUser.language = returnedData[i]['dimensions'][3];
                eachRegUser.timestamp = Number(labelArray[3]);
                allRegUsers.push(eachRegUser);
                eachRegUser = {};
            }
            allRegUsers = allRegUsers.sort(function(a, b) { return b.timestamp - a.timestamp});
            yield put(setUsersRegListData(allRegUsers));
        }
    } catch (error) {
        console.log(error);
    }
}

export default function* watchReportSettings() {
    yield takeEvery(AFFILIATE.SET_AFFILIATOR_NAME, handleAffiliateName);
    yield takeEvery(AFFILIATE.SET_AFFILIATOR_EMAIL, handleAffiliateEmail);
    yield takeEvery(AFFILIATE.SET_AFFILIATOR_TYPE, handleAffiliateType);
    yield takeEvery(AFFILIATE.SET_AFFILIATOR_GAME, handleAffiliateGame);
    yield takeEvery(AFFILIATE.SET_CREATE_STATUS, handleCreateButtonState);
    yield takeEvery(AFFILIATE.SET_AFFILIATOR, handleSetAffiliator);
    yield takeEvery(AFFILIATE.UPDATE_AFFILIATOR, handleUpdateAffiliator);
    yield takeEvery(AFFILIATE.DELETE_AFFILIATE, handleDeleteAffiliate);
    yield takeEvery(AFFILIATE.GET_AFFILIATE_LIST_DATA_BY_KEY, getAffiliateListDataByKey);
    yield takeEvery(AFFILIATE.UPDATE_AFFILIATE_LIST_DATA, handleUpdateAffiliateListData);
    yield takeEvery(AFFILIATE.SET_AFFILIATE_ID_TO_UPDATE, handleGetAffiliateDataById);
    yield takeEvery(AFFILIATE.CLEAR_AFFILIATE_FIELDS, handleClearAffiliateFields);
    yield takeEvery(AFFILIATE.GET_TREND_DATA_BY_AID, handleGetTrendDataByAid);
    yield takeEvery(USERS.GET_USERS_DATA_BY_AID, handleGetUsersDataByAid);
    yield takeEvery(AFFILIATE.GET_AFFILIATOR_GROUP, handleGetCampaignGroup);
    yield takeEvery(USERS.GET_REG_DATA_BY_AID, handleGetRegDataByAid);
}
