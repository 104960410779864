import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import * as H from 'helper';

const useStyles = makeStyles((theme) => ({
    content: {
        position: "relative",
        padding: theme.spacing(1) * 2,
        "&:last-child": {
        paddingBottom: theme.spacing(1) * 2,
        },
    },
    icon: {
        boxShadow: "none",
        color: "white",
    },
    iconFloat: {
        position: "absolute",
        right: "15px",
        top: "50%",
        marginTop: "-20px",
        opacity: "0.2",
        transform: "rotate(-5deg)",
    },
    box: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        borderRight: '1px solid #dcdcdc',
    },
    title: {
        display: 'block',
        height: 32,
        fontSize: 14,
    },
    value: {
        paddingTop: 16,
    },
    bar: {
        height: 6,
        width: '80%'
    },
    numSection: {
        display: 'block',
        paddingTop: 16,
    },
    circleProgress: {
        marginTop: 40,
        marginBottom: 8,
    },
    txtCountry: {
        marginTop: -16,
        fontSize: 14,
    },
    txtPrev: {
        fontSize: 13,
    },
    gainLossImg: {
        paddingTop: 8,
        marginRight: 4,
    },
    diffLbl: {
        color: '#7f898b'
    },
    lineChartLast: {
        marginTop: 16,
    },
    iconTooltip: {
        paddingTop: 0,
        paddingRight: 16,
        fontSize: 16,
    },
    currency: {
        fontSize: 14,
    },
}));

const StatBoxSholar = ({
    title,
    value1,
    value2,
    value3,
    color,
    prevValue1,
    prevValue2,
    prevValue3,
    lblValue1,
    lblValue2,
    lblValue3,
    tip,
    lgValue,
}) => {
    const classes = useStyles();

    const getProgressCircle = (size) => {
        return <div><CircularProgress size={size} /></div>
    }

    const getPrevComponent = (value, prevValue, title) => {            
        const diffValue = value - prevValue;
        const diffRatio = diffValue / prevValue * 100;

        let imageName = "";
        if (title === "停止" && diffValue !== 0) {
            imageName = value > prevValue ? 'loss' : 'gain';
        } else if (diffValue !== 0) {
            imageName = value > prevValue ? 'gain' : 'loss';
        }

        if (!prevValue) {
            return <div className={classes.txtPrev}>---</div>
        }

        return(
            <div className={classes.txtPrev}>
                {imageName !== "" &&
                    <img className={classes.gainLossImg} src={`/static/images/${imageName}.png`} />
                }
                {H.FormatNumStrHelper.formatCommaThousand(diffValue)} ({Math.abs(diffRatio.toFixed(2))}%)
            </div>
        )
    }

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
    }))(Tooltip);

    return (
            <Grid
                container
                alignItems={"center"}
                direction={"row"}
                justifyContent={"flex-start"}
            >
                <Grid item xs={12}>
                    <div className={classes.box}>
                    <Grid
                        container
                        direction={"row"}
                        justifyContent={"flex-start"}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="caption"
                                className={classes.title}
                                dangerouslySetInnerHTML={{__html: title}}
                            >
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            {tip &&
                                <Grid container justifyContent="flex-end">
                                    <HtmlTooltip
                                        title={
                                        <React.Fragment>
                                            <Typography color="inherit"><div dangerouslySetInnerHTML={{__html: tip}}></div></Typography>
                                        </React.Fragment>
                                        }
                                    >
                                        <HelpIcon className={classes.iconTooltip} fontSize="small" />
                                    </HtmlTooltip>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                        <div className={classes.numSection}>
                        <Grid
                            container
                            direction={"row"}
                            justifyContent={"space-evenly"}
                        >
                        {value1 === 'loading' &&
                            <div className={classes.circleProgress}><CircularProgress size={20} /></div>
                        }
                        {value1 !== 'loading' &&
                            typeof value1 !== 'object' &&
                            <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{lblValue1}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {value1 === 'loading' ? getProgressCircle(20) : <Typography variant="h6">{value1}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {prevValue1 !== undefined ? prevValue1 === 'loading' || isNaN(prevValue1)
                                    ? prevValue1 !== 'n/a' ? getProgressCircle(12) : "-" : getPrevComponent(value1, prevValue1, title) : "" }
                                </Grid>
                            </Grid>
                            </Grid>
                        }
                        {value2 !== 'loading' &&
                            typeof value2 !== 'object' &&
                            <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{lblValue2}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {value2 === 'loading' ? getProgressCircle(20) : <Typography variant="h6">{value2}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {prevValue2 !== undefined ? prevValue2 === 'loading' || isNaN(prevValue2)
                                    ? prevValue2 !== 'n/a' ? getProgressCircle(12) : "-" : getPrevComponent(value2, prevValue2, title) : "" }
                                </Grid>
                            </Grid>
                            </Grid>
                        }
                        {value3 !== 'loading' &&
                            typeof value3 !== 'object' &&
                            <Grid item xs={4} lg={lgValue}>
                            <Typography variant="caption" className={classes.value}>{lblValue3}</Typography>
                            <Grid container spacing={0} direction="column" style={{ marginBottom: 8, width: '100%' }}>
                                <Grid item xs={4} sm={6}>
                                    {value3 === 'loading' ? getProgressCircle(20) : <Typography variant="h6">{value3}</Typography>}
                                </Grid>
                                <Grid item xs={8} sm={6}>
                                    {prevValue3 !== undefined ? prevValue3 === 'loading' || isNaN(prevValue3)
                                    ? getProgressCircle(12) : getPrevComponent(value3, prevValue3, title) : "" }
                                </Grid>
                            </Grid>
                            </Grid>
                        }
                        </Grid>
                        </div>
                        {color !== undefined ? <div className={classes.bar} style={{backgroundColor: color}}></div> : ""}
                    </div>
                </Grid>
            </Grid>
    );
};

StatBoxSholar.propTypes = {
    title: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
    ]),
    color: PropTypes.string,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(StatBoxSholar));
