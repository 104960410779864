import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Icon } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import * as C from 'components';
import CampaignUpdateStyles from 'pages/CampaignUpdateStyles';
import { setCurrentPage, setAffiliateIdToUpdate, updateAffiliator, setUpdateAffiliatorState } from 'actions';

export class CampaignUpdate extends Component {
    componentDidMount() {
        this.props.setCurrentPage('update');
        const {aid} = this.props.match.params;
        this.props.setAffiliateIdToUpdate(aid);
    }

    updateAffiliatorBtnClicked() {
        this.props.updateAffiliator();
    }

    handleClose = () => {
        this.props.setUpdateAffiliatorState(false)
    };

    render() {
        const { classes } = this.props;

        return (
            <C.ContentWrapper>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={8} md={9} lg={9}>
                        <div className={classes.fieldWrapper}>
                            <C.CampaignName view={true} />
                            <C.CampaignEmail view={true}  />
                            <C.CampaignType view={true}  />
                            <C.CampaignGame view={true}  />
                            <C.CampaignGroup view={true} />
                            <Button
                                variant="contained"
                                color="primary"
                                //disabled={!this.props.createBtnState}
                                className={classes.updateButton}
                                onClick={() => { this.updateAffiliatorBtnClicked()}}>
                                更新する
                            </Button>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.cancelButton}
                                component={ Link }
                                to="/campaign">
                                キャンセル
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <Dialog
                    open={this.props.updateAffiliate === undefined ? false : this.props.updateAffiliate}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <Icon className={'fa fa-cogs fa-md'} style={{color: "#888888", paddingRight: "16px" }} />
                        <span className={classes.dialogTitle}>{"キャンペーンの更新"}</span>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            キャンペーンの情報が更新されました。
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                        OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </C.ContentWrapper>
        );
    }
}

CampaignUpdate.propTypes = {
    classes: PropTypes.object,
    setCurrentPage: PropTypes.func,
    setAffiliateIdToUpdate: PropTypes.func,
    updateAffiliator: PropTypes.func,
    setUpdateAffiliatorState: PropTypes.func,
};

const mapStateToProps = state => ({
    updateAffiliate: state.affiliate.updateAffiliate,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setAffiliateIdToUpdate: (param) => dispatch(setAffiliateIdToUpdate(param)),
    updateAffiliator: () => dispatch(updateAffiliator()),
    setUpdateAffiliatorState: (param) => dispatch(setUpdateAffiliatorState(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(CampaignUpdateStyles, { withTheme: true })(CampaignUpdate));