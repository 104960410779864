import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import OverviewStyles from "pages/NftDep/Auction/OverviewStyles";
import {
  setCurrentPage,
  setFocusTabIndex,
  getInGameItemSalesOverviewData,
} from "actions";
import * as C from "components";
import * as H from "helper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  titleTodaysSales: {
    paddingTop: 8,
    paddingBottom: 16,
    fontSize: 12,
  },
  fillRemailSpace: {
    flex: "auto",
  },
  sectionTop: {
    marginTop: -16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  breadcrumbs: {
    marginTop: 16,
    minHeight: 30,
  },
}));

const InGameSalesOverview = ({
  setCurrentPage,
  focusTabIndex,
  setFocusTabIndex,
  getInGameItemSalesOverviewData,
  loading,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [gameName, setGameName] = React.useState(null);
  const [gameLabel, setGameLabel] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setFocusTabIndex(newValue);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    const game = window.location.pathname.split("/");
    getInGameItemSalesOverviewData(game[2]);
    setGameName(game[2]);
    setGameLabel(H.ContentsHelper.getContentsName(game[2]));
  }, [window.location.pathname]);

  const orderIndex = gameName !== "ingame-sale" ? 1 : 2

  return (
    <C.ContentWrapper>
      <Grid
        container
        direction="row"
        className={classes.sectionTop}
        justifyContent="space-between"
      >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>{gameLabel === undefined ? "PlayMining NFT" : gameLabel}</Typography>
              <Typography>InGame 売上</Typography>
              <Typography variant="subtitle1">売上概要</Typography>
            </Breadcrumbs>
          </Grid>
        <Grid item className={classes.fillRemailSpace}></Grid>
        <Grid item>
          <C.CalendarRangePicker mDate={"2023/03/27"} />
        </Grid>
      </Grid>
      <C.ItemSalesStats />
      <div className={classes.root}>
        <AppBar position="sticky">
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { background: "#65c9a5", height: 4 } }}
          >
            <Tab label="国別" {...a11yProps(0)} />
            {gameName === "ingame-sale" &&
              <Tab label="ゲーム別" {...a11yProps(1)} />
            }
            <Tab label="アイテム別" {...a11yProps(orderIndex)} />
            <Tab label="PMID別" {...a11yProps(orderIndex+1)} />
            <Tab disabled label="金額別" {...a11yProps(orderIndex+2)} />
            <Tab disabled label="広告別" {...a11yProps(orderIndex+3)} />
          </Tabs>
        </AppBar>
      </div>
      <TabPanel value={value} index={0}>
        <C.InGameItemByCountry game={gameName} />
      </TabPanel>
      {gameName === "ingame-sale" &&
        <TabPanel value={value} index={1}>
          <C.InGameItemByGame game={gameName} />
        </TabPanel>
      }
      <TabPanel value={value} index={orderIndex}>
        <C.InGameItemByItem game={gameName} />
      </TabPanel>
      <TabPanel value={value} index={orderIndex+1}>
        <C.InGameItemByPmid game={gameName} />
      </TabPanel>
      {/* <TabPanel value={value} index={orderIndex+1}>
        <C.ByCampaign />
      </TabPanel> */}
    </C.ContentWrapper>
  );
};

InGameSalesOverview.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  focusTabIndex: state.nftDep.focusTabIndex,
  loading: state.nftDep.loading,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  setFocusTabIndex: (param) => dispatch(setFocusTabIndex(param)),
  getInGameItemSalesOverviewData: (param) =>
    dispatch(getInGameItemSalesOverviewData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(OverviewStyles, { withTheme: true })(InGameSalesOverview));
