// Icons Material can be defined here, not fontAwesome
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import {
    Home,
    AverageRevenue,
    Users,
    KpiByGame,
    ActiveUsers,
    ActiveUsersByGame,
    UsersByAdCode,
    Daa,
    GameJobTribes,
    GamePuzzle,
    GamePuzzleAcquisition,
    GamePuzzleEvents,
    GameLuckyFarmer,
    CampaignCreate,
    CampaignUpdate,
    CampaignSummary2,
    CampaignView2,
    Cohort,
    SalesOverview,
    InGameSalesOverview,
    InGameSalesTrend,
    InGameSalesTxHistory,
    NftDepAuctionOverview,
    NftDepTrend,
    NftOwnTrend,
    NftDepTxHistory,
    UserBan,
    Staking,
    GraffitiRacerNftTrend,
    GraffitiRacerGamePaidTrend,
    JtcbNftTrend,
    JtcbScholar,
    CookinBurgerNftTrend,
    DragonRamenNftTrend,
    CookinBurgerScholar,
    KamuiVerseNftTrend,
    LuckyFarmerNftTrend,
    SoulFusersNftTrend,
    GameNftTrend,
    LuckyFarmerGamePaidTrend,
    CookinBurgerGamePaidTrend,
    Lab,
    Lab2,
    NftHolders,
    WalletBalance,
    LostArchiveNftTrend,
    gameWalletBalance,
    PicTreeHome,
    PicTreeSalesOverview,
    PicTreeSalesTrend,
    PicTreeSalesDetails,
    PicTreePointExchange,
    PicTreeRegistration,
    PicTreeCheckIn,
    PicTreeMap,
    PicTreeReviewSummary,
    PicTreeReviewDetails,
    PicTreeReviewAppearance,
    SalesAppearance,
    PicTreeUserList,
    CampaignList,
    CampaignReport,
} from 'pages';

export default {
    items: [
        {
            path: '/home',
            name: 'ホーム',
            type: 'link',
            icon: HomeIcon,
            component: Home,
            sideBar: true,
        },
        {
            path: '/kpi',
            name: '主要KPI',
            type: 'submenu',
            icon: AssessmentIcon,
            component: Users,
            sideBar: true,
            divider: false,
            children: [
                {
                    path: '/average-revenue',
                    name: '新規登録＆課金',
                    icon: 'activeUsers',
                    component: AverageRevenue
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: 'activeUsers',
                    component: ActiveUsers
                },
            ],
        },
        {
            path: '/users',
            name: 'ユーザー',
            type: 'submenu',
            icon: 'audience',
            component: Users,
            sideBar: true,
            divider: false,
            children: [
                {
                    path: '/list-by-adcode',
                    name: 'Ad Code別',
                    icon: 'fa users',
                    component: UsersByAdCode
                }
            ],
        },
        {
            path: '/playmining-nft',
            name: 'PlayMining NFT',
            type: 'submenu',
            icon: 'fas store',
            component: Daa,
            sideBar: true,
            children: [
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: NftDepAuctionOverview
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: NftDepTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    icon: null,
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
        },
        {
            path: '/a2e',
            name: 'Answer To Earn',
            type: 'submenu',
            icon: ContactSupportIcon,
            component: Users,
            sideBar: true,
            divider: true,
            children: [
                {
                    path: '/campaigns',
                    name: 'キャンペーン',
                    icon: null,
                    component: CampaignList
                },
            ],
        },
        {
            path: '/a2e/report/:cid',
            name: 'キャンペーンレポート',
            type: 'link',
            icon: null,
            component: CampaignReport,
            sideBar: false,
            agent: true,
        },
        {
            path: '/game/bouncybunny',
            name: 'Bouncy Bunny',
            type: 'submenu',
            icon: 'bouncybunny',
            component: GamePuzzle,
            sideBar: true,
            category: 'GAME',
            children: [
                {
                    path: '/wallet-balance',
                    name: 'Wallet Balance',
                    icon: null,
                    component: gameWalletBalance
                },
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                            new: true,
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: GameNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend,
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            vendor: "lUF2uNRqBJO7EUv9zNnA3wloxxPF",
        },
        {
            path: '/game/cookinburger',
            name: 'Cookin\' Burger',
            type: 'submenu',
            icon: 'cookinburger',
            component: GameJobTribes,
            sideBar: true,
            children: [
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                            new: true,
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: CookinBurgerNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend,
                        },
                        {
                            path: '/game-transaction-trend',
                            name: 'Game取引トレンド',
                            icon: null,
                            component: CookinBurgerGamePaidTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/scholar',
                    name: 'スカラー',
                    icon: null,
                    component: CookinBurgerScholar
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
        },
        {
            path: '/game/dragonramen',
            name: 'Dragon Ramen',
            type: 'submenu',
            icon: 'dragonRamen',
            sideBar: true,
            children: [
                {
                    path: '/wallet-balance',
                    name: 'Wallet Balance',
                    icon: null,
                    component: WalletBalance
                },
                {
                    path: '/overview',
                    name: 'マケプレ売上概要',
                    icon: null,
                    component: SalesOverview
                },
                {
                    path: '/nft-transaction-trend',
                    name: 'NFT取引トレンド',
                    icon: null,
                    component: DragonRamenNftTrend
                },
                {
                    path: '/transaction-history',
                    name: 'NFT取引履歴',
                    icon: null,
                    component: NftDepTxHistory
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            vendor: "r6pjc6qlvnw4znji7nzkwjx2wag8",
        },
        {
            path: '/game/graffitiracer',
            name: 'Graffiti Racer',
            type: 'submenu',
            icon: 'graffitiracer',
            component: GameJobTribes,
            sideBar: true,
            children: [
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: GraffitiRacerNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    icon: null,
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend,
                        },
                        {
                            path: '/game-transaction-trend',
                            name: 'Game取引トレンド',
                            icon: null,
                            component: GraffitiRacerGamePaidTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            vendor: "EqOsAVMYK4pqtSnVDgz67zVKUXFy",
        },
        {
            path: '/game/jobtribes',
            name: 'JobTribes',
            type: 'submenu',
            icon: 'fa gamepad',
            component: GameJobTribes,
            sideBar: true,
            children: [
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                            new: true,
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: JtcbNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/scholar',
                    name: 'スカラー',
                    icon: null,
                    component: JtcbScholar
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
        },
        {
            path: '/game/kamuiverse',
            name: 'Kamui Verse',
            type: 'submenu',
            icon: 'kamuiVerse',
            component: GamePuzzle,
            sideBar: true,
            children: [
                {
                    path: '/overview',
                    name: 'マケプレ売上概要',
                    icon: null,
                    component: SalesOverview
                },
                {
                    path: '/nft-transaction-trend',
                    name: 'NFT取引トレンド',
                    icon: null,
                    component: KamuiVerseNftTrend
                },
                {
                    path: '/transaction-history',
                    name: 'NFT取引履歴',
                    icon: null,
                    component: NftDepTxHistory
                },
                {
                  path: '/nft-holders',
                  name: 'NFT保有者',
                  icon: 'fa user-plus',
                  component: NftHolders
                },
            ],
        },
        {
            path: '/game/lostarchiveplus',
            name: 'LOST ARCHIVE+',
            type: 'submenu',
            icon: 'fa gamepad',
            component: GameLuckyFarmer,
            sideBar: true,
            children: [
                {
                    path: '/wallet-balance',
                    name: 'Wallet Balance',
                    icon: null,
                    component: gameWalletBalance
                },
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: LostArchiveNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    icon: null,
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            vendor: "qjoDw9xh16E5giTPmhhByywmaLfN",
            divider: false,
        },
        {
            path: '/game/luckyfarmer',
            name: 'Lucky Farmer',
            type: 'submenu',
            icon: 'luckyfarmer',
            component: GameLuckyFarmer,
            sideBar: true,
            children: [
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                            new: true,
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: LuckyFarmerNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend,
                        },
                        {
                            path: '/game-transaction-trend',
                            name: 'Game取引トレンド',
                            icon: null,
                            component: LuckyFarmerGamePaidTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            vendor: "WPZssCHh3jDp8bwIKhrwA7Rk89PN",
            divider: false,
        },
        {
            path: '/game/pictree',
            name: 'PicTrée',
            type: 'submenu',
            icon: CameraAltIcon,
            component: null,
            sideBar: true,
            children: [
                {
                    path: "/home",
                    name: "ホーム",
                    type: "link",
                    icon: HomeIcon,
                    component: PicTreeHome,
                    sideBar: true,
                    divider: true,
                  },
                  {
                    path: "/sales",
                    name: "売上概要",
                    type: "link",
                    icon: false,
                    category: "課金",
                    component: PicTreeSalesOverview,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: "/sales/trend",
                    name: "NFT取引トレンド",
                    type: "link",
                    icon: false,
                    component: GameNftTrend,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: "/sales/details",
                    name: "NFT取引詳細",
                    type: "link",
                    icon: false,
                    component: NftDepTxHistory,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: "/point",
                    name: "ポイント交換",
                    type: "link",
                    icon: false,
                    component: PicTreePointExchange,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: "/registration",
                    name: "集客",
                    type: "link",
                    icon: false,
                    category: "GAME",
                    component: PicTreeRegistration,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: '/users',
                    name: '登録ユーザー',
                    type: "link",
                    icon: false,
                    component: PicTreeUserList,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                  },
                //   {
                //     path: "/cohort",
                //     name: "維持率",
                //     type: "link",
                //     icon: false,
                //     component: Users,
                //     sideBar: true,
                //     divider: false,
                //     disabled: true,
                //   },
                  {
                    path: "/checkin",
                    name: "チェックイン",
                    type: "link",
                    icon: false,
                    component: PicTreeCheckIn,
                    sideBar: true,
                    divider: false,
                  },
                  {
                    path: "/photos",
                    name: "撮影物",
                    type: "submenu",
                    sideBar: true,
                    children: [
                      {
                        path: "/overview",
                        name: "概要",
                        icon: null,
                        component: PicTreeReviewSummary,
                      },
                      {
                        path: "/map",
                        name: "地図表示",
                        icon: null,
                        component: PicTreeMap,
                        new: true,
                      },
                      {
                        path: "/denchu/appearance",
                        name: "出現回数 - 電柱",
                        icon: null,
                        component: PicTreeReviewAppearance,
                      },
                    //   {
                    //     path: "/details",
                    //     name: "詳細",
                    //     icon: null,
                    //     component: PicTreeReviewDetails,
                    //   },
                    ],
                  },
                //   {
                //     path: "/users",
                //     name: "概要",
                //     type: "link",
                //     icon: false,
                //     component: CampaignSummary2,
                //     sideBar: true,
                //     category: "ユーザー属性",
                //     disabled: true,
                //   },
                // //   {
                //     path: "/advertisement",
                //     name: "効果測定指標",
                //     type: "link",
                //     icon: false,
                //     component: CampaignSummary2,
                //     sideBar: true,
                //     category: "広告",
                //     disabled: true,
                //   },
            ],
            vendor: "MHoNNpheuCs67M80p6IB7MFCe9i8",
            divider: false,
        },
        {
            path: '/game/roguerollrulers',
            name: 'Rogue Roll Ruler\'s',
            type: 'submenu',
            icon: 'roguerollrulers',
            component: GamePuzzle,
            sideBar: true,
            children: [
                {
                    path: '/wallet-balance',
                    name: 'Wallet Balance',
                    icon: null,
                    component: gameWalletBalance
                },
                {
                    path: '/kpi',
                    name: '売上指標',
                    icon: null,
                    component: KpiByGame,
                },
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                            new: true,
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: GameNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: "/appearance",
                            name: "購入回数",
                            icon: null,
                            component: SalesAppearance,
                            new: true,
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            vendor: "cRaANwUa68M3s0N0oF4U6kiADDli",
        },
        {
            path: '/game/soulfusers',
            name: 'SOUL Fusers',
            type: 'submenu',
            icon: 'soulfusers',
            component: GamePuzzle,
            sideBar: true,
            children: [
                {
                    path: '/marketplace-sale',
                    name: 'マケプレ売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: SalesOverview
                        },
                        {
                            path: '/rarity-transaction-trend',
                            name: 'NFT取引トレンド',
                            icon: null,
                            component: SoulFusersNftTrend
                        },
                        {
                            path: '/transaction-history',
                            name: 'NFT取引履歴',
                            icon: null,
                            component: NftDepTxHistory
                        },
                    ],
                },
                {
                    path: '/ingame-sale',
                    name: 'InGame 売上',
                    type: 'submenu',
                    component: GameJobTribes,
                    sideBar: true,
                    children: [
                        {
                            path: '/overview',
                            name: '売上概要',
                            icon: null,
                            component: InGameSalesOverview
                        },
                        {
                            path: '/trend',
                            name: '取引トレンド',
                            icon: null,
                            component: InGameSalesTrend
                        },
                        {
                            path: '/transaction-history',
                            name: '取引詳細',
                            icon: null,
                            component:InGameSalesTxHistory,
                        },
                    ],
                },
                {
                    path: '/active-users',
                    name: 'アクティブユーザー',
                    icon: null,
                    component: ActiveUsersByGame
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
        },
        {
            path: '/game/puzzle',
            name: 'Puzzle',
            type: 'submenu',
            icon: 'fa puzzle',
            component: GamePuzzle,
            sideBar: true,
            children: [
                {
                    path: '/transaction-history',
                    name: 'NFT取引履歴',
                    icon: null,
                    component: NftDepTxHistory
                },
                {
                  path: '/acquisition',
                  name: '集客',
                  icon: 'fa user-plus',
                  component: GamePuzzleAcquisition
                },
                {
                  path: '/events',
                  name: '行動',
                  icon: 'fa chart-line',
                  component: GamePuzzleEvents
                },
                {
                    path: '/cohort',
                    name: 'コホート分析',
                    icon: null,
                    component: Cohort
                }
            ],
            divider: true,
        },
        {
            path: '/campaign',
            name: 'Campaign',
            type: 'link',
            icon: 'fa ad',
            component: CampaignSummary2,
            sideBar: true,
            category: 'MARKETING',
            agent: true,
        },
        {
            path: '/campaign/create',
            name: 'キャンペーン登録',
            type: 'link',
            icon: CreateIcon,
            component: CampaignCreate,
            sideBar: false,
        },
        {
            path: '/campaign/update/:aid',
            name: 'キャンペーン編集',
            type: 'link',
            icon: HomeIcon,
            component: CampaignUpdate,
            sideBar: false,
        },
        {
            path: '/campaign/view/:aid/:rid',
            name: 'キャンペーン詳細',
            type: 'link',
            icon: HomeIcon,
            component: CampaignView2,
            sideBar: false,
            agent: true,
        },
        {
            path: '/lab',
            name: 'LAB',
            type: 'submenu',
            icon: ScatterPlotIcon,
            sideBar: true,
            children: [
                {
                    path: '/data1',
                    name: 'DATA 1',
                    icon: null,
                    component: Lab
                  },
                {
                    path: '/data2',
                    name: 'P2E 国別アクセス',
                    icon: null,
                    component: Lab2
                }
            ],
        },
    ]
  };
