import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthenticatedRoute from "components/Auth/AuthenticatedRoute";
import UnauthenticatedRoute from "components/Auth/UnauthenticatedRoute";
import DashboardContainer from 'containers/DashboardContainer';
import { SignIn, ResetPassword } from 'pages';

export default ({ childProps, isPicTree }) =>
    <Switch>
        <Route exact path="/" render={() => (<Redirect to={isPicTree ? `/game/pictree/home` : `/home`} />)} />
        <AuthenticatedRoute path="/home" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/kpi/average-revenue" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/kpi/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/users/list-by-adcode" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/playmining-nft/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/playmining-nft/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/nft-own-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/playmining-nft/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/playmining-nft/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/a2e/campaigns" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/a2e/report/:cid" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/wallet-balance" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/bouncybunny/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/marketplace-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/bouncybunny/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/bouncybunny/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/ingame-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/bouncybunny/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/marketplace-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/nft-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/ingame-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/ingame-sale/game-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/game-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/scholar" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/cookinburger/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/cookinburger/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/dragonramen/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/dragonramen/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/dragonramen/wallet-balance" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/dragonramen/nft-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/dragonramen/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/dragonramen/scholar" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/dragonramen/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/dragonramen/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/nft-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/ingame-sale/game-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/game-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/graffitiracer/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/graffitiracer/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/jobtribes" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/marketplace-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/jobtribes/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/jobtribes/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/jobtribes/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/jobtribes/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/ingame-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/scholar" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/jobtribes/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/jobtribes/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/kamuiverse/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/kamuiverse/nft-transaction-trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/kamuiverse/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/kamuiverse/nft-holders" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/puzzle" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/puzzle/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/puzzle/acquisition" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/puzzle/events" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/puzzle/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/lostarchiveplus" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/lostarchiveplus/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/lostarchiveplus/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/wallet-balance" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/lostarchiveplus/nft-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/lostarchiveplus/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/lostarchiveplus/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/lostarchiveplus/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/marketplace-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/nft-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/ingame-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/ingame-sale/game-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/game-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/luckyfarmer/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/luckyfarmer/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/home" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/sales" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/marketplace-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/sales/trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/sales/details" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/point" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/registration" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/cohort" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/checkin" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/photos" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/photos/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/photos/map" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/photos/denchu/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/pictree/photos/details" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/users" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/users/list" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/pictree/advertisement" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/roguerollrulers/kpi" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/marketplace-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/roguerollrulers/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/roguerollrulers/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/ingame-sale/appearance" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/ingame-sale/transaction-history" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/wallet-balance" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/roguerollrulers/nft-transaction-trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/roguerollrulers/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/roguerollrulers/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/soulfusers/marketplace-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/soulfusers/marketplace-sale/rarity-transaction-trend" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/soulfusers/marketplace-sale/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/soulfusers/ingame-sale/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/soulfusers/ingame-sale/trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/soulfusers/overview" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/soulfusers/nft-transaction-trend" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/soulfusers/transaction-history" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/game/soulfusers/active-users" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/game/soulfusers/cohort" exact component={DashboardContainer} props={childProps} key={document.location.pathname} />
        <AuthenticatedRoute path="/campaign" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/campaign/list/:key" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/campaign/create" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/campaign/update/:aid" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/campaign/view/:aid/:rid" exact component={DashboardContainer} props={childProps} />
        <UnauthenticatedRoute path="/signin" exact component={SignIn} props={childProps} />
        <UnauthenticatedRoute path="/signin/reset" exact component={ResetPassword} props={childProps} />
        <AuthenticatedRoute path="/lab/data1" exact component={DashboardContainer} props={childProps} />
        <AuthenticatedRoute path="/lab/data2" exact component={DashboardContainer} props={childProps} />
    </Switch>;