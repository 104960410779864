import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from '@material-ui/core/Icon';
import {
  faPuzzlePiece,
  faAd,
  faGamepad,
  faUserPlus,
  faChartLine,
  faUsers,
  faUserCheck,
  faStore,
  faTable,
  faCoins,
  faUser,
  faListAlt,
  faClone,
  faMap,
  faHamburger,
  faPaintBrush,
  faDragon,
  faWheatAwn,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as H from "helper";
import SidebarItemStyles from "./SidebarItemStyles";
import FiberNewIcon from '@material-ui/icons/FiberNew';

class SidebarItem extends Component {
  componentDidMount() {
    if (
      !this.props.currentPath ||
      this.props.activeRoute === this.props.index ||
      this.props.route.path === "/"
    )
      return;
    this.props.toggleMenu(this.props.index);
    this.props.toggleSubMenu(this.props.subIndex);
  }

  getIcon(route, classes) {
    if (typeof route.icon === "object") {
      return <route.icon className={classes.menuIcon} />;
    } else if (route.icon === null) {
      return <route.icon />;
    } else {
      let faIcon = faStore;
      if (route.icon === "fa ad") {
        faIcon = faAd;
      } else if (route.icon === "fa gamepad") {
        faIcon = faClone;
      } else if (route.icon === "fa puzzle") {
        faIcon = faPuzzlePiece;
      } else if (route.icon === "fa user-plus") {
        faIcon = faUserPlus;
      } else if (route.icon === "fa chart-line") {
        faIcon = faChartLine;
      } else if (route.icon === "fa users") {
        faIcon = faListAlt;
      } else if (route.icon === "table") {
        faIcon = faTable;
      } else if (route.icon === "luckyfarmer") {
        faIcon = faWheatAwn;
      } else if (route.icon === "audience") {
        faIcon = faUsers;
      } else if (route.icon === "activeUsers") {
        faIcon = faUserCheck;
      } else if (route.icon === "kamuiVerse") {
        faIcon = faMap;
      } else if (route.icon === "cookinburger") {
        faIcon = faHamburger;
      } else if (route.icon === "dragonRamen") {
        faIcon = faDragon;
      } else if (route.icon === "graffitiracer") {
        faIcon = faPaintBrush;
      } else if (route.icon === "soulfusers") {
        faIcon = faWandSparkles;
      } else if (route.icon === "roguerollrulers") {
        faIcon = faCoins;
      }

      if (route.icon === "bouncybunny") {
        return <i className={classNames(classes.menuIconFa, "fa fa-bunny fa-lg")}></i>
      } else {
        return !route.icon ? null : <FontAwesomeIcon className={classes.menuIconFa} icon={faIcon} />;
      }
    }
  }

  render() {
    const {
      classes,
      route,
      index,
      activeRoute,
      activeSubRoute,
      toggleMenu,
      toggleSubMenu,
      isProfileMenu,
      disableMenu,
      opened,
      toggleDrawer,
    } = this.props;

    const badge = (badge) => {
      if (!badge) return;
      const badgeClassName = classNames(classes.badge, {
        [classes[`${badge.type}`]]: badge.type !== "default",
      });
      return (
        <Typography
          className={classNames(classes.badge, badgeClassName)}
          component="div"
        >
          {badge.value}
        </Typography>
      );
    };

    // if (route.type === 'external') {
    //     return (
    //         <a href={route.path} target="_blank" key={index} className={classes.menuLink} rel="noopener noreferrer">
    //             <ListItem className={classes.menuItem} button>
    //                 <ListItemIcon>
    //                     <route.icon className={classes.menuIcon} />
    //                 </ListItemIcon>
    //                 <Typography variant="body1" className="flexSpacer">{H.FormatNumStrHelper.capitalize(route.name)}</Typography>
    //                 {badge(route.badge)}
    //             </ListItem>
    //         </a>
    //     );
    // }

    if (route.type === "submenu") {
      return (
        <div
          className={
            activeRoute === index ? classes.menuCollapsed : classes.menuClosed
          }
        >
          <ListItem
            className={classes.menuItem}
            button
            key={index}
            onClick={() => {
              toggleMenu(index);
              toggleDrawer(route.type);
            }}
          >
            {!opened ? (
              <Tooltip
                title={H.FormatNumStrHelper.capitalize(route.name)}
                placement="right"
              >
                <ListItemIcon>{this.getIcon(route, classes)}</ListItemIcon>
              </Tooltip>
            ) : (
              <ListItemIcon>{this.getIcon(route, classes)}</ListItemIcon>
            )}
            <Typography variant="body2" className="flexSpacer">
              {H.FormatNumStrHelper.capitalize(route.name)}
            </Typography>
            {badge(route.badge)}
            <ListItemIcon className={classes.caret}>
              {activeRoute === index ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </ListItemIcon>
          </ListItem>
          <Collapse
            in={activeRoute === index 
              ? opened ? true : false
              : false}
            timeout="auto"
            unmountOnExit
          >
            <List disablePadding>
              {route.children.map((subitem, index) =>
                subitem.type === "submenu" ? (
                  <div
                    className={
                      activeSubRoute === index
                        ? classes.menuCollapsed
                        : classes.menuClosed
                    }
                    key={index}
                  >
                    <ListItem
                      className={classes.menuItem}
                      button
                      key={index}
                      onClick={() => {
                        toggleSubMenu(index);
                        toggleDrawer(subitem.type);
                      }}
                    >
                      {!opened ? (
                        <Tooltip
                          title={H.FormatNumStrHelper.capitalize(subitem.name)}
                          placement="right"
                        >
                          <ListItemIcon>
                            {this.getIcon(subitem, classes)}
                          </ListItemIcon>
                        </Tooltip>
                      ) : (
                        <ListItemIcon></ListItemIcon>
                      )}
                      <Typography variant="body2" className="flexSpacerRight">
                        {H.FormatNumStrHelper.capitalize(subitem.name)}
                      </Typography>
                      {badge(subitem.badge)}
                      <ListItemIcon className={classes.caretSub}>
                        {activeSubRoute === index ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <ArrowDropDownIcon />
                        )}
                      </ListItemIcon>
                    </ListItem>
                    <Collapse
                      in={activeSubRoute === index ? true : false}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List disablePadding>
                        {subitem.children.map((sub, index) => (
                          <NavLink
                            to={`${route.path ? route.path : ""}${
                              subitem.path ? subitem.path : ""
                            }${sub.path ? sub.path : ""}`}
                            exact
                            className={classes.menuLink}
                            activeClassName={classes.menuActive}
                            key={index}
                          >
                            <ListItem className={classes.menuItem} button>
                              {!opened ? (
                                <Tooltip
                                  title={H.FormatNumStrHelper.capitalize(
                                    sub.name
                                  )}
                                  placement="right"
                                >
                                  <ListItemIcon></ListItemIcon>
                                </Tooltip>
                              ) : (
                                <ListItemIcon></ListItemIcon>
                              )}
                              <Typography
                                variant="body2"
                                className={classNames(
                                  classes.subMenuLabel2,
                                  "flexSpacer"
                                )}
                              >
                                {H.FormatNumStrHelper.capitalize(sub.name)}
                              {sub.new &&
                                <Icon className={classes.newIcon} style={{ marginLeft:4 }}>
                                  <FiberNewIcon />
                                </Icon>
                              }
                              </Typography>
                              {badge(sub.badge)}
                            </ListItem>
                          </NavLink>
                        ))}
                      </List>
                    </Collapse>
                  </div>
                ) : (
                  <NavLink
                    to={`${route.path ? route.path : ""}${
                      subitem.path ? subitem.path : ""
                    }`}
                    exact
                    className={classes.menuLink}
                    activeClassName={classes.menuActive}
                    key={index}
                  >
                    {subitem.category &&
                      <div className={classes.categoryLbl}>
                        {subitem.category}
                      </div>
                    }
                    <ListItem className={classes.menuItem} button>
                      {!opened ? (
                        <Tooltip
                          title={H.FormatNumStrHelper.capitalize(subitem.name)}
                          placement="right"
                        >
                          <ListItemIcon></ListItemIcon>
                        </Tooltip>
                      ) : (
                        <ListItemIcon></ListItemIcon>
                      )}
                      <Typography
                        variant="body2"
                        className={classNames(
                          classes.subMenuLabel,
                          "flexSpacer"
                        )}
                      >
                        {H.FormatNumStrHelper.capitalize(subitem.name)}
                        {subitem.new &&
                        <Icon className={classes.newIcon} style={{ marginLeft:4 }}>
                          <FiberNewIcon />
                        </Icon>
                        }
                      </Typography>
                      {badge(subitem.badge)}
                    </ListItem>
                  </NavLink>
                )
              )}
            </List>
          </Collapse>
        </div>
      );
    }

    return (
      <NavLink
        to={disableMenu ? "#" : route.path}
        exact
        className={classes.menuLink}
        activeClassName={classes.menuActive}
        key={index}
      >
        {isProfileMenu ? (
          <ListItem
            className={classes.profileMenuItem}
            button
            onClick={() => toggleMenu(index)}
          >
            <Typography variant="body2" className="flexSpacer">
              {H.FormatNumStrHelper.capitalize(route.name)}
            </Typography>
            {badge(route.badge)}
          </ListItem>
        ) : !opened ? (
          <ListItem
            className={classes.menuItem}
            button
            onClick={() => toggleMenu(index)}
            disabled={disableMenu}
          >
            <Tooltip
              title={H.FormatNumStrHelper.capitalize(route.name)}
              placement="right"
            >
              <ListItemIcon>{this.getIcon(route, classes)}</ListItemIcon>
            </Tooltip>
            <Typography variant="body2" className="flexSpacer">
              {H.FormatNumStrHelper.capitalize(route.name)}
            </Typography>
          </ListItem>
        ) : (
          <ListItem
            className={classes.menuItem}
            button
            onClick={() => toggleMenu(index)}
            disabled={disableMenu}
          >
            <ListItemIcon>{this.getIcon(route, classes)}</ListItemIcon>
            <Typography variant="body2" className="flexSpacer">
              {H.FormatNumStrHelper.capitalize(route.name)}
            </Typography>
          </ListItem>
        )}
      </NavLink>
    );
  }
}

SidebarItem.prototypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.object,
  index: PropTypes.number,
  activeRoute: PropTypes.number,
  toggleMenu: PropTypes.func,
};

export default withStyles(SidebarItemStyles)(SidebarItem);
