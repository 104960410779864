import { DATA } from '../../constants';

const initialState  = {
    loading: {
        ov: false,
        ovByCountry: false,
        ovByItem: false,
        ovByPmid: false,
        ovByGame: false,
        ovByRid: false,
    },
    tabFocused: "country",
    focusTabIndex: 0,
    dataOverview: {},
    dataByCountry: {
        amount: {
            fiat: [],
            dep: [],
        },
        count: {
            fiat: [],
            dep: [],
        },
    },
    dataByItem: {
        amount: {
            fiat: [],
            dep: [],
        },
        count: {
            fiat: [],
            dep: [],
        },
    },
    dataByPmid: {
        amount: {
            fiat: [],
            dep: [],
        },
        count: {
            fiat: [],
            dep: [],
        },
    },
    dataByGame: {
        amount: {
            fiat: [],
            dep: [],
        },
        count: {
            fiat: [],
            dep: [],
        }
    },
    dataByRid: {},
    dataByOccurrence: [],
};

export default function inGameItemSalesOverviewReducer(state = initialState, action) {
    switch (action.type) {
        case DATA.SET_IN_GAME_SALES_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case DATA.SET_INGAME_SALES_OVERVIEW_TAB:
            return {
                ...state,
                tabFocused: action.payload
            };
        case DATA.SET_INGAME_SALES_FOCUS_TAB_INDEX:
            return {
                ...state,
                focusTabIndex: action.payload
            };
        case DATA.SET_IN_GAME_SALES_DATA_OVERVIEW:
            return {
                ...state,
                dataOverview: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_COUNTRY:
            return {
                ...state,
                dataByCountry: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_GAME:
            return {
                ...state,
                dataByGame: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_ITEM:
            return {
                ...state,
                dataByItem: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_PMID:
            return {
                ...state,
                dataByPmid: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_GAME:
            return {
                ...state,
                dataByGame: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_RID:
            return {
                ...state,
                dataByRid: action.payload
            };
        case DATA.SET_INGAME_SALES_DATA_BY_OCCURRENCE:
            return {
                ...state,
                dataByOccurrence: action.payload
            };
        default:
            return state
    }
}
