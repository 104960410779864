import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setNftSalesByOccurrence, setPageTopLoader } from 'actions';
import { getJwtToken, getDataFromRDS } from 'api';
import { DATA, ENDPOINT } from '../../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const loading = state => state.inGameItemSales.loading;
export const dataLoaded = state => state.data.dataLoaded;
export const occurrenceType = state => state.nftSales.nftSalesByOccurrenceType;
export const occurrenceGame = state => state.nftSales.nftSalesByOccurrenceGame;

export function* getNftSalesByOccurrence(action) {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        let gameTarget = yield select(occurrenceGame); //action.payload;
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
        const dataType = yield select(occurrenceType);
        let dataTable = "SxiNftSalesProd1stV2";
        if (dataType === "auction") {
            dataTable = "SxiNftSalesProd2ndV1";
        } else if (dataType === "fiat") {
            dataTable = "SxiNftSalesProdFiatV1";
        }

        // 1st time
        let sqlQueryKaisu1Trend = {
            query: `
            SELECT
                DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                COUNT(*) AS first_time_appearance_count
            FROM (
                SELECT
                    from_pmid,
                    MIN(logDate) AS first_occurrence_date
                FROM ${dataTable}
                WHERE
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                    AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                    AND contents_id = '${contentId}'
                GROUP BY from_pmid
            ) AS first_occurrences
            WHERE
                DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
            GROUP BY date_only
            ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        // 2nd time
        let sqlQueryKaisu2Trend = {
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        from_pmid,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            from_pmid,
                            logDate,
                            @row_num := IF(@current_purchase = from_pmid, @row_num + 1, 1) AS row_num,
                            @current_purchase := from_pmid
                        FROM
                            ${dataTable},
                            (SELECT @row_num := 0, @current_purchase := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            from_pmid, logDate
                    ) AS ranked
                    WHERE
                        row_num = 2
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        let sqlQueryKaisu3Trend = {
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        from_pmid,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            from_pmid,
                            logDate,
                            @row_num := IF(@current_purchase = from_pmid, @row_num + 1, 1) AS row_num,
                            @current_purchase := from_pmid
                        FROM
                            ${dataTable},
                            (SELECT @row_num := 0, @current_purchase := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            from_pmid, logDate
                    ) AS ranked
                    WHERE
                        row_num = 3
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        let sqlQueryKaisu4Trend = {
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        from_pmid,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            from_pmid,
                            logDate,
                            @row_num := IF(@current_purchase = from_pmid, @row_num + 1, 1) AS row_num,
                            @current_purchase := from_pmid
                        FROM
                            ${dataTable},
                            (SELECT @row_num := 0, @current_purchase := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            from_pmid, logDate
                    ) AS ranked
                    WHERE
                        row_num = 4
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        let sqlQueryKaisu5Trend = {
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        from_pmid,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            from_pmid,
                            logDate,
                            @row_num := IF(@current_purchase = from_pmid, @row_num + 1, 1) AS row_num,
                            @current_purchase := from_pmid
                        FROM
                            ${dataTable},
                            (SELECT @row_num := 0, @current_purchase := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            from_pmid, logDate
                    ) AS ranked
                    WHERE
                        row_num > 4
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        const [dataTempKaisu1, dataTempKaisu2, dataTempKaisu3, dataTempKaisu4, dataTempKaisu5Plus] = yield all([
            call(getDataFromRDS, sqlQueryKaisu1Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu2Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu3Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu4Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu5Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        const dataKaisuTrend1 = JSON.parse(dataTempKaisu1.body);
        const dataKaisuTrend2 = JSON.parse(dataTempKaisu2.body);
        const dataKaisuTrend3 = JSON.parse(dataTempKaisu3.body);
        const dataKaisuTrend4 = JSON.parse(dataTempKaisu4.body);
        const dataKaisuTrend5 = JSON.parse(dataTempKaisu5Plus.body);

        // aggregate data
        // Set xAxisDate
        let xAxisDate = [], dataFirstArray = [], dataTwoArray = [], dataThreeArray = [], dataFourArray = [], dataFiveArray = [];
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(dateFrom, dateTo)
        _.each(daysArray, function(data) {
            xAxisDate.push(data);
        });

        xAxisDate.forEach(date => {
            // 1st time
            const data1st = _.find(dataKaisuTrend1, item => _.get(item, '[0].stringValue') === date);
            if (data1st) {
                dataFirstArray.push(_.get(data1st, '[1].longValue'));
            } else {
                dataFirstArray.push(0);
            }

            // 2 times
            const dataTwo = _.find(dataKaisuTrend2, item => _.get(item, '[0].stringValue') === date);
            if (dataTwo) {
                dataTwoArray.push(_.get(dataTwo, '[1].longValue'));
            } else {
                dataTwoArray.push(0);
            }

            // 3 times
            const dataThree = _.find(dataKaisuTrend3, item => _.get(item, '[0].stringValue') === date);
            if (dataThree) {
                dataThreeArray.push(_.get(dataThree, '[1].longValue'));
            } else {
                dataThreeArray.push(0);
            }

            // 4 times
            const dataFour = _.find(dataKaisuTrend4, item => _.get(item, '[0].stringValue') === date);
            if (dataFour) {
                dataFourArray.push(_.get(dataFour, '[1].longValue'));
            } else {
                dataFourArray.push(0);
            }

            // 5 times
            const dataFive = _.find(dataKaisuTrend5, item => _.get(item, '[0].stringValue') === date);
            if (dataFive) {
                dataFiveArray.push(_.get(dataFive, '[1].longValue'));
            } else {
                dataFiveArray.push(0);
            }
        });

        const countData = {
            date: xAxisDate,
            series: [
              dataFirstArray,
              dataTwoArray,
              dataThreeArray,
              dataFourArray,
              dataFiveArray,
            ],
        };
        const recordArray = [countData];
        yield put(setNftSalesByOccurrence(recordArray));
        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(DATA.GET_NFT_SALES_DATA_BY_OCCURRENCE, getNftSalesByOccurrence);
    yield takeEvery(DATA.SET_NFT_SALES_DATA_BY_OCCURRENCE_TYPE, getNftSalesByOccurrence);
}
