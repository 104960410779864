import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setCohortData, setCohortNftData, setPageTopLoader, setCohortSizeSelected, setCohortRangeSelected, setCohortCountryOptions, setCohortRidOptions } from 'actions';
import { getJwtToken, getDataFromRDS } from 'api';
import { DATA, ENDPOINT } from '../constants';
import * as H from 'helper';

export const cohortTargetSelect = state => state.cohort.cohortFocused;
export const cohortSizeSelect = state => state.cohort.cohortSize;
export const cohortRangeSelect = state => state.cohort.cohortRange;
export const cohortCountrySelected = state => state.cohort.cohortCountrySelected;
export const cohortRidSelected = state => state.cohort.cohortRidSelected;
export const cohortDataSelect = state => state.cohort.cohortData;
export const cohortNftDataSelect = state => state.cohort.cohortNftData;
export const cohortDateRangeSelected = state => state.cohort.cohortDateRange;

const unescapeUnicode = function(str) {
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function(m0, m1) {
      return String.fromCharCode(parseInt(m1, 16));
    });
};

export function* getCohortData() {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        const cohortTarget = yield select(cohortTargetSelect);
        const cohortSize = yield select(cohortSizeSelect);
        const cohortRange = yield select(cohortRangeSelect);
        const cohortCountry = yield select(cohortCountrySelected);
        const cohortRid = yield select(cohortRidSelected);
        const cohortDateRange = yield select(cohortDateRangeSelected);
        const isDataNew = moment(cohortDateRange[0]).isAfter('2023-06-30') ? true : false;

        // get option
        if (localStorage.getItem('cohortOptions_'+cohortTarget) === null || cohortTarget !== "cookinburger") {
            const sqlQueryCohortCountry = H.SqlHelper.getCohortCountry(cohortTarget, cohortSize, cohortRange, cohortDateRange, isDataNew);
            const sqlQueryCohortRid = H.SqlHelper.getCohortRid(cohortTarget, cohortSize, cohortRange, cohortDateRange, isDataNew);
            const [cohortCountryTemp, cohortRidTemp] = yield all([
                call(getDataFromRDS, sqlQueryCohortCountry, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, sqlQueryCohortRid, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            // country
            const countryDataReturned = JSON.parse(cohortCountryTemp.body);
            let newCountryOption = [{name: "全て", value:"all"}];
            _.forEach(countryDataReturned, function(data) {
                newCountryOption.push(
                    {name: data[1]["stringValue"], value: data[1]["stringValue"]}
                )
            });

            // rid
            const ridDataReturned = JSON.parse(cohortRidTemp.body);
            let newRidOption = [{name: "全て", value:"all"}];
            _.forEach(ridDataReturned, function(data) {
                newRidOption.push(
                    {name: unescapeUnicode(data[2]["stringValue"]) + '  [ ' + data[1]["stringValue"] + ' ]', value: data[1]["stringValue"]}
                )
            });

            const localStorageObject = {
                'date': moment().tz("Asia/Singapore").format('YYYY/MM/DD'),
                'country': newCountryOption,
                'rid': newRidOption
            }
            localStorage.setItem('cohortOptions_'+cohortTarget, JSON.stringify(localStorageObject));
            yield put(setCohortCountryOptions(newCountryOption));
            yield put(setCohortRidOptions(newRidOption));
        } else {
            let storedCohortOptions = JSON.parse(localStorage.getItem("cohortOptions_"+cohortTarget));
            const dayDifference = moment.tz("Asia/Singapore").diff(moment(storedCohortOptions.date), 'days');
            if (dayDifference > 0 || cohortTarget === "cookinburger") {
                const sqlQueryCohortCountry2 = H.SqlHelper.getCohortCountry(cohortTarget, cohortSize, cohortRange, cohortDateRange, isDataNew);
                const sqlQueryCohortRid2 = H.SqlHelper.getCohortRid(cohortTarget, cohortSize, cohortRange, isDataNew);
                const [cohortCountryTemp, cohortRidTemp] = yield all([
                    call(getDataFromRDS, sqlQueryCohortCountry2, ENDPOINT.GET_DATA_RDS, jwtToken),
                    call(getDataFromRDS, sqlQueryCohortRid2, ENDPOINT.GET_DATA_RDS, jwtToken),
                ]);
                const countryDataReturned2 = JSON.parse(cohortCountryTemp.body);
                let newCountryOption2 = [{name: "全て", value:"all"}];
                _.forEach(countryDataReturned2, function(data) {
                    newCountryOption2.push(
                        {
                            name: data[1]["stringValue"] === "n/a" ? "不明" : data[1]["stringValue"],
                            value: data[1]["stringValue"]
                        }
                    )
                });

                // rid
                const ridDataReturned = JSON.parse(cohortRidTemp.body);
                let newRidOption2 = [{name: "全て", value:"all"}];
                _.forEach(ridDataReturned, function(data) {
                    newRidOption2.push(
                        {name: unescapeUnicode(data[2]["stringValue"]) + '  [ ' + data[1]["stringValue"] + ' ]', value: data[1]["stringValue"]}
                    )
                });

                const localStorageObject2 = {
                    'date': moment().tz("Asia/Singapore").format('YYYY/MM/DD'),
                    'country': newCountryOption2,
                    'rid': newRidOption2
                }
                localStorage.setItem('cohortOptions_'+cohortTarget, JSON.stringify(localStorageObject2));
                yield put(setCohortCountryOptions(newCountryOption2));
                yield put(setCohortRidOptions(newRidOption2));
            } else {
                yield put(setCohortCountryOptions(storedCohortOptions.country));
                yield put(setCohortRidOptions(storedCohortOptions.rid));
            }
        }

        // target, day, retention, 7
        const sqlQueryForCohort = H.SqlHelper.getCohortQuery(cohortTarget, cohortSize, cohortRange, cohortCountry.value, cohortRid.value, cohortDateRange, isDataNew);
        const sqlQueryCohortReg = H.SqlHelper.getCohortRegQuery(cohortTarget, cohortSize, cohortRange, cohortCountry.value, cohortRid.value, cohortDateRange, isDataNew);
        const sqlQuerNftCohort = H.SqlHelper.getCohortNftQuery(cohortTarget, cohortSize, cohortRange, cohortCountry.value, cohortRid.value, cohortDateRange, isDataNew);
        const sqlQueryCohortFistTime = H.SqlHelper.getCohortNftFirstTimeQuery(cohortTarget, cohortSize, cohortRange, cohortCountry.value, cohortRid.value, cohortDateRange, isDataNew);

        const [
            dataTemp,
            dataTemp2,
            dataNftTemp,
            dataNftFirstTimeTemp,
        ] = yield all([
            call(getDataFromRDS, sqlQueryForCohort, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCohortReg, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerNftCohort, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCohortFistTime, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataReturned = JSON.parse(dataTemp.body);
        const dataRegReturned = JSON.parse(dataTemp2.body);
        const dataNft = JSON.parse(dataNftTemp.body);
        const dataNftFirstTime = JSON.parse(dataNftFirstTimeTemp.body);
        // console.log("YO dataReturned:", dataReturned);
        // console.log("YO dataRegReturned:", dataRegReturned);
        // console.log("YOYO dataNft:", dataNft);
        // console.log("YOYO dataNftFirstTime:", dataNftFirstTime);

        let newDataArray = [], eachData = [], allTempArray = [], allSumArray = [];
        let newNftDataArray = [], eachNftData = [], allTempNftArray = [], allSumNftArray = [];
        if (cohortSize === 'day') {
            _.map(
                dataReturned,
                (v, fIndex) => {
                    _.map(
                        v,
                        (nV, index) => {
                            let insertValue = nV['stringValue'];
                            if (nV['stringValue'].indexOf("-") === -1) {
                                if (index == 1) {
                                    insertValue = Number(dataRegReturned[fIndex][0]['longValue']); // data self
                                } else {
                                    insertValue = Number(nV['stringValue']); // data self
                                }
                            }
                            eachData.push(insertValue);
                            if (typeof insertValue == 'number') {
                                allTempArray.push(Number(insertValue));
                            }
                        }
                    )
                    newDataArray.push(eachData);
                    allSumArray.push(allTempArray);
                    eachData = [];
                    allTempArray = [];
                }
            );
            _.map(
                dataNft,
                (v, fIndex) => {
                    _.map(
                        v,
                        (nV, index) => {
                            let insertValue = nV['stringValue'];
                            if (nV['stringValue'].indexOf("-") === -1) {
                                if (index == 1) {
                                    insertValue = Number(dataNftFirstTime[fIndex][0]['longValue']); // data self
                                } else {
                                    insertValue = Number(nV['stringValue']); // data self
                                }
                            }
                            eachNftData.push(insertValue);
                            if (typeof insertValue == 'number') {
                                allTempNftArray.push(Number(insertValue));
                            }
                        }
                    )
                    newNftDataArray.push(eachNftData);
                    allSumNftArray.push(allTempNftArray);
                    eachNftData = [];
                    allTempNftArray = [];
                }
            )
        } else {
            _.map(
                dataReturned,
                (v, fIndex) => {
                    _.map(
                        v,
                        (nV, index) => {
                            let insertValue = nV['longValue']
                            ? cohortSize === 'week'
                                ? moment(v[v.length - 1]['longValue'].toString()).add(nV['longValue'], 'weeks').startOf('week').format('YYYY-MM-DD')+ ' ~ '
                                + moment(v[v.length - 1]['longValue'].toString()).add(nV['longValue'], 'weeks').startOf('week').add(6, 'days').format('YYYY-MM-DD')
                                : nV['longValue']+'月'
                            : index == 1 ? Number(dataRegReturned[fIndex][0]['longValue']) : Number(nV['stringValue'])
                            eachData.push(insertValue);
                            if (typeof insertValue == 'number') {
                                allTempArray.push(Number(insertValue));
                            }
                        }
                    )
                    newDataArray.push(eachData);
                    allSumArray.push(allTempArray);
                    eachData = [];
                    allTempArray = [];
                }
            )
        }
        const allSumData = _.map(_.unzip(allSumArray), _.sum);
        const allLblAddedArray = ['合計', ...allSumData];
        const combinedDataArray = [allLblAddedArray, ...newDataArray];

        // Nft
        const allSumNftData = _.map(_.unzip(allSumNftArray), _.sum);
        const allLblAddedNftArray = ['合計', ...allSumNftData];
        const combinedDataNftArray = [allLblAddedNftArray, ...newNftDataArray];

        const storeCohortData = yield select(cohortDataSelect);
        // var newArr2 = _.set(_.find(cohortData, {id: cohortTarget}), 'data', dataReturned);
        let newCohortData = _.map(storeCohortData, function(data) {
            return data.id === cohortTarget ? {id: cohortTarget, size: cohortSize, metric: 'retention', range: cohortRange, data: combinedDataArray} : data;
        });
        yield put(setCohortData(newCohortData));

        const storeCohortNftData = yield select(cohortNftDataSelect);
        let newCohortNftFirstPurchaseData = _.map(storeCohortNftData, function(data) {
            return data.id === cohortTarget ? {id: cohortTarget, size: cohortSize, metric: 'retention', range: cohortRange, data: combinedDataNftArray} : data;
        });
        yield put(setCohortNftData(newCohortNftFirstPurchaseData));

        let cohortSizeSelectedObj = {};
        if (cohortSize === 'day') {
            cohortSizeSelectedObj = {name: "日別", value:"day"};
        } else if (cohortSize === 'week') {
            cohortSizeSelectedObj = {name: "週別", value:"week"};
        } else {
            cohortSizeSelectedObj = {name: "月別", value:"month"};
        }
        let cohortRangeSelectedObj = {};
        if (cohortRange === '7days') {
            cohortRangeSelectedObj = {name: "過去7日", value:"7days"}
        } else if (cohortRange === '14days') {
            cohortRangeSelectedObj = {name: "過去14日", value:"14days"}
        } else if (cohortRange === '21days') {
            cohortRangeSelectedObj = {name: "過去21日", value:"21days"}
        } else if (cohortRange === '30days') {
            cohortRangeSelectedObj = {name: "過去30日", value:"30days"}
        } else if (cohortRange === '1week') {
            cohortRangeSelectedObj = {name: "先週", value:"1week"}
        } else if (cohortRange === '2weeks') {
            cohortRangeSelectedObj = {name: "過去2週間", value:"2weeks"}
        } else if (cohortRange === '3weeks') {
            cohortRangeSelectedObj = {name: "過去3週間", value:"3weeks"}
        } else if (cohortRange === '6weeks') {
            cohortRangeSelectedObj = {name: "過去6週間", value:"6weeks"}
        } else if (cohortRange === '9weeks') {
            cohortRangeSelectedObj = {name: "過去9週間", value:"9weeks"}
        } else if (cohortRange === '12weeks') {
            cohortRangeSelectedObj = {name: "過去12週間", value:"12weeks"}
        } else if (cohortRange === '1month') {
            cohortRangeSelectedObj = {name: "今月", value:"1month"}
        } else if (cohortRange === '2month') {
            cohortRangeSelectedObj = {name: "過去2ヶ月", value:"2month"}
        } else if (cohortRange === '3month') {
            cohortRangeSelectedObj = {name: "過去3ヶ月", value:"3month"}
        } else if (cohortRange === '6month') {
            cohortRangeSelectedObj = {name: "過去6ヶ月", value:"6month"}
        } else if (cohortRange === '12month') {
            cohortRangeSelectedObj = {name: "過去12ヶ月", value:"12month"}
        } else if (cohortRange === 'custom') {
            cohortRangeSelectedObj = {name: "カスタム", value:"custom"}
        }
        yield put(setCohortSizeSelected(cohortSizeSelectedObj));
        yield put(setCohortRangeSelected(cohortRangeSelectedObj));
        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(DATA.GET_COHORT_DATA, getCohortData);
}