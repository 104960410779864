import { KPI } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    avgRevenueDataLoading: false,
    avgRevenueGameOption: [],
    avgRevenueGameSelected: [],
    avgRevenueCountryOption: [],
    avgRevenueCountrySelected: [],
    avgRevenueRidOption: [],
    avgRevenueRidSelected: [],
    avgRevenueData: [],
    avgRevenueDateRange: [startDate, endDate],
    countryLoading: false,
    ridLoading: false,
    allPmMenuSelect: false,
    avgRevenueDataByGame: [],
    avgRevenueDataByCountry: [],
    avgRevenueDataByRid: [],
    avgRevenueDataByGamePaid: [],
    avgRevenueDataByCountryPaid: [],
    avgRevenueDataByRidPaid: [],
    csvDataPaidPmid: [],
    csvDataAllPmid: [],
    kpiSalesDateRange: [startDate, endDate],
    kpiSalesData: [],
    kpiSalesDataSizeSelected: {name: "DAU", value: "dau"},
};

export default function kpiReducer(state = initialState, action) {
    switch (action.type) {
        case KPI.SET_AVG_REVENUE_LOADING:
            return {
                ...state,
                avgRevenueDataLoading: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATE_RANGE:
            return {
                ...state,
                avgRevenueDateRange: action.payload
            };
        case KPI.SET_AVG_REVENUE_GAME_OPTION:
            return {
                ...state,
                avgRevenueGameOption: action.payload
            };
        case KPI.SET_AVG_REVENUE_COUNTRY_OPTION:
            return {
                ...state,
                avgRevenueCountryOption: action.payload
            };
        case KPI.SET_AVG_REVENUE_RID_OPTION:
            return {
                ...state,
                avgRevenueRidOption: action.payload
            };
        case KPI.SET_AVG_REVENUE_COUNTRY_SELECTED:
            return {
                ...state,
                avgRevenueCountrySelected: action.payload
            };
        case KPI.SET_AVG_REVENUE_RID_SELECTED:
            return {
                ...state,
                avgRevenueRidSelected: action.payload
            };
        case KPI.SET_ALL_PM_FOR_AVG_REVENUE_SELECT:
            return {
                ...state,
                allPmMenuSelect: action.payload
            };
        case KPI.SET_AVG_REVENUE_GAME_SELECTED:
            return {
                ...state,
                avgRevenueGameSelected: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATA_BY_GAME:
            return {
                ...state,
                avgRevenueDataByGame: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATA_BY_COUNTRY:
            return {
                ...state,
                avgRevenueDataByCountry: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATA_BY_RID:
            return {
                ...state,
                avgRevenueDataByRid: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATA_BY_GAME_PAID:
            return {
                ...state,
                avgRevenueDataByGamePaid: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATA_BY_COUNTRY_PAID:
            return {
                ...state,
                avgRevenueDataByCountryPaid: action.payload
            };
        case KPI.SET_AVG_REVENUE_DATA_BY_RID_PAID:
            return {
                ...state,
                avgRevenueDataByRidPaid: action.payload
            };
        case KPI.SET_PAID_PMID_CSV_DATA:
            return {
                ...state,
                csvDataPaidPmid: action.payload
            };
        case KPI.SET_ALL_PMID_CSV_DATA:
            return {
                ...state,
                csvDataAllPmid: action.payload
            };
        case KPI.SET_KPI_SALES_DATE_RANGE:
            return {
                ...state,
                kpiSalesDateRange: action.payload
            };
        case KPI.SET_KPI_SALES_DATA:
            return {
                ...state,
                kpiSalesData: action.payload
            };
        case KPI.SET_KPI_SALES_DATA_SIZE_SELECTED:
            return {
                ...state,
                kpiSalesDataSizeSelected: action.payload
            };
        default:
            return state
    }
};
