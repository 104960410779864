import { KAMUIVERSE } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    kamuiVerseNftTrendDateLoading: false,
    kamuiVerseNftTrendDateRange: [startDate, endDate],
    trendData: [],
    nftTrendDataCountAuction: [],
    nftTrendDataAmountAuction: [],
    nftTrendDataCountStore: [],
    nftTrendDataAmountStore: [],
};

export default function kamuiverseNftTrendReducer(state = initialState, action) {
    switch (action.type) {
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_DATA_LOADING:
            return {
                ...state,
                kamuiVerseNftTrendDateLoading: action.payload
            };
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_DATE_RANGE:
            return {
                ...state,
                kamuiVerseNftTrendDateRange: action.payload
            };
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_PAGE_DATA:
            return {
                ...state,
                trendData: action.payload
            };
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_COUNT_AUCTION:
            return {
                ...state,
                nftTrendDataCountAuction: action.payload
            };
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_AMOUNT_AUCTION:
            return {
                ...state,
                nftTrendDataAmountAuction: action.payload
            };
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_COUNT_STORE:
            return {
                ...state,
                nftTrendDataCountStore: action.payload
            };
        case KAMUIVERSE.SET_KAMUIVERSE_NFT_TREND_AMOUNT_STORE:
            return {
                ...state,
                nftTrendDataAmountStore: action.payload
            };
        default:
            return state
    }
};
