const HeaderStyles = theme => ({
  appBar: {
    boxShadow: 'none',
    position: 'relative',
    zIndex: theme.zIndex.drawer + 100,
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
    backgroundColor: '#fff',
    borderBottomWidth: '1px',
    borderBottom: 'solid #d4d4d4'
  },
  toolBar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  branding: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '50px',
    padding: `20px 100px 0 0`,
    marginLeft: '5px'
  },
  logo: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '180px',
    },
  },
  rightMenuArea: {
    marginLeft: 'auto',
    order: 2
  },
  avatar: {
    float: 'right',
    marginRight: '10px'
  },
  username: {
    float: 'left',
    fontSize: '13px',
    marginTop: '10px',
    marginRight: '10px',
    color: '#000',
    textDecoration: 'underline'
  }
});

export default HeaderStyles;