import * as H from 'helper';

const CampaignHelper = {
    getCampaignNameByRid: function(rid, campaignArray) {
        let returnValue = '';
        let filterRid = rid;
        for (let i=0; i<campaignArray.length; i++) {
            if (campaignArray[i]['value'] === filterRid) {
                returnValue = campaignArray[i]['name'];
            }
        }

        return returnValue === '' ? '---' : returnValue;
    }
}

export default CampaignHelper;