import { drawerWidth, sidebarFontSize } from 'styles/variables';

const SidebarStyles = theme => ({
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    maxWidth: drawerWidth,
    height: '100%',
    zIndex: theme.zIndex.drawer + 99,
    fontSize: sidebarFontSize,
    color: '#575757',
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px',
    },
    zIndex: '1000'
  },
  backdrop: {
    [theme.breakpoints.down('sm')]: {
      top: '56px',
    },
    [theme.breakpoints.up('sm')]: {
      top: '64px',
    },
  },
  upperDiv: {
    paddingTop: '80px',
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
    },
  },
  profileLabel:{
    paddingLeft: '15px',
    fontWeight: 'normal'
  },
  bottomDiv: {
    width: drawerWidth,
    fontSize: sidebarFontSize,
    position: 'absolute',
    bottom: '0px',
  },
  bottomMenu: {
    paddingLeft: '16px',
  },
  categoryLbl: {
    fontSize: 13,
    marginLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  }
});

export default SidebarStyles;