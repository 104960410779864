import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MapIcon from '@material-ui/icons/Map';
import TocIcon from '@material-ui/icons/Toc';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getHomePictreePhotoShoot,
  setPictreeView,
  getPictreeDataPhotoDenchuKaisuTrend,
  getPhotoShootDetailsData,
  setPictreeChartSize,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import ChartConfig from "utils/ChartConfig";
import BarStackNormalization from "components/Charts/BarStackNormalization";
import PhotoShoot from "components/PicTree/Home/PhotoShoot";
import PhotoShootMap from "components/PicTree/Game/Map/PhotoShootMap";
import SingleSelect from "components/Select/SingleSelect";
import PicTreeTable from "components/Table/PicTreeTable";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    lblGameName: {
      color: '#000',
      fontSize: 13,
      minWidth: 80,
    },
    dataCellTd: {
      fontSize: 13,
      paddingRight: 10,
      paddingBottom: 0,
      paddingLeft: 10,
      textAlign: 'right',
      verticalAlign: 'middle',
      borderBottom: '1px solid #eee6e6',
      borderRight: '1px solid #eee6e6',
      height: 45,
    },
    tTdHeader: {
      paddingLeft: '1rem',
      paddingRight: 19,
      fontWeight: 'bold',
      height: 39,
    },
    tTd: {
      height: 44,
      paddingLeft: '1rem',
      borderBottom: '1px solid #eee',
      borderCollapse: 'collapse',
    },
  })
);

const PicTreeReviewAppearance = ({
  setCurrentPage,
  pageTopLoader,
  getHomePictreePhotoShoot,
  setPictreeView,
  pictreeView,
  pictreeChartSize,
  dataPhotoShootDetails,
  getPictreeDataPhotoDenchuKaisuTrend,
  getPhotoShootDetailsData,
  setPictreeChartSize,
  photoShootDenchuKaisuTrend,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [chartSize, setChartSize] = useState({ name: "日別", value: "day" });
  const [seriesData, setSeriesData] = useState([]);
  const [xAxisData, setSAxisData] = useState([]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    getPictreeDataPhotoDenchuKaisuTrend("day");
    setPictreeView("photo/chart");
  }, []);

  useEffect(() => {
    if (pictreeView === "photo/map") {
      getPhotoShootDetailsData();
    }
  }, [pictreeView]);

  useEffect(() => {
    if (photoShootDenchuKaisuTrend.length > 0) {
      setSeriesData(photoShootDenchuKaisuTrend[0]["series"]);
      setSAxisData(photoShootDenchuKaisuTrend[0]["date"]);
    }
  }, [photoShootDenchuKaisuTrend]);

  const handleView = (view) => {
    setPictreeView(view);
  };

  const handleChange = (event) => {
    setChartSize(event.target.value);
    setPictreeChartSize(event.target.value)
    getPictreeDataPhotoDenchuKaisuTrend(event.target.value);
  };

  const getExportData = (data) => {
    let exportData = [];
    if (data.length > 0) {
      let headerArray = ["電柱撮影出現データ"];
      let numOfHeaderData = data[0]["date"].length;
      for (let j = 0; j < numOfHeaderData; j++) {
        headerArray.push(data[0]["date"][j]);
      }

      const countArray = ["初回"].concat(data[0]["series"][0]);
      const count2Array = ["2回目"].concat(data[0]["series"][1]);
      const count3Array = ["3回目"].concat(data[0]["series"][2]);
      const count4Array = ["4回目"].concat(data[0]["series"][3]);
      const count5Array = ["5回目"].concat(data[0]["series"][4]);
      const count6Array = ["6回+"].concat(data[0]["series"][5]);
      exportData = [
        headerArray,
        countArray,
        count2Array,
        count3Array,
        count4Array,
        count5Array,
        count6Array
      ]
    }
    return exportData;
  };

  const getTableLabelColumn = () => {
    return (
      <>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>初回</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>2回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>3回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>4回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>5回目</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>6回目+</div>
          </td>
        </tr>
      </>
    );
  };

  const getDataTable = (data) => {
    return (
      <>
        <tr>
          {data[0]["series"][0].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][1].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][2].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][3].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][4].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["series"][5].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
      </>
    );
  };


  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée</Typography>
              <Typography variant="body1">Game</Typography>
              <Typography variant="body1">撮影物</Typography>
              <Typography variant="body1">出現回数 - 電柱</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree" pictree />
          </Grid>
        </Grid>

        {/* <Grid container spacing={0} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <PhotoShoot />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}

        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: 16, marginRight: 16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item style={{ marginTop: 0, marginLeft: 48, }}>
          {pictreeView === "photo/chart" &&
            <SingleSelect
              selected={chartSize}
              options={[
                { name: "時間別", value: "hour" },
                { name: "日別", value: "day" },
              ]}
              handleChange={handleChange}
            />
          }
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="row"
              style={{ marginTop: 0, marginRight: 16, marginBottom: 8 }}
              justifyContent="flex-end"
              >
              <Grid item></Grid>
              <Grid item className={classes.fillRemailSpace}></Grid>
              <Grid item>
                <Chip avatar={<ShowChartIcon />} label="チャート" onClick={() => handleView("photo/chart")}
                  variant={pictreeView === "photo/chart" ? "default" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip avatar={<MapIcon />} label="マップ" onClick={() => handleView("photo/map")}
                  variant={pictreeView === "photo/map" ? "default" : "outlined"}
                  disabled
                />
              </Grid>
              <Grid item>
                <Chip avatar={<TocIcon />} label="テーブル" onClick={() => handleView("photo/table")}
                  variant={pictreeView === "photo/table" ? "default" : "outlined"}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {pictreeView === "photo/chart" &&
          <>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : chartState ? (
                xAxisData.length > 0 ? (
                  <BarStackNormalization
                    legend={[
                      "初回" , "2回目", "3回目", "4回目", "5回目", "6回目+"
                    ]}
                    lblSaveImage={""}
                    xAisData={xAxisData}
                    seriesData={seriesData}
                    chartType={"line"}
                    chartSize={pictreeChartSize}
                  />
                ) : (
                  <div className={classes.selectGameTxt}>
                    <Chip
                      label="No Data"
                      style={{
                        color: V.textColor,
                        backgroundColor: V.placeholderColor,
                      }}
                    />
                  </div>
                )
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              <PicTreeTable
                game="PicTree"
                dataLabel={[]}
                data={photoShootDenchuKaisuTrend}
                tableKey={[]}
                csvFileName="PicTree - 出現回数 - 電柱"
                getExportData={getExportData}
                getDataTable={getDataTable}
                getTableLabelColumn={getTableLabelColumn}
              />
            </Grid>
          </Grid>
          </>
        }
        {/* {pictreeView === "photo/map" &&
         dataPhotoShootDetails.length > 0 &&
          <PhotoShootMap records={dataPhotoShootDetails} type="photoshoot" />
        } */}
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeReviewAppearance.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  pictreeView: state.pictree.pictreeView,
  pictreeChartSize: state.pictree.chartSize,
  dataPhotoShootDetails: state.pictree.dataPhotoShootDetails,
  photoShootDenchuKaisuTrend: state.pictree.photoShootDenchuKaisuTrend,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getHomePictreePhotoShoot: () => dispatch(getHomePictreePhotoShoot()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
  getPictreeDataPhotoDenchuKaisuTrend: (param) => dispatch(getPictreeDataPhotoDenchuKaisuTrend(param)),
  getPhotoShootDetailsData: () => dispatch(getPhotoShootDetailsData()),
  setPictreeChartSize: (param) => dispatch(setPictreeChartSize(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeReviewAppearance));
