import moment from 'moment-timezone';
import DayOfWeek from 'utils/DayOfWeek';

const FormatDateHelper = {
    correctDateTZ: function(datePassed) {
        datePassed = new Date(datePassed);
        return new Date( datePassed.getTime() - datePassed.getTimezoneOffset() * -60000 );
    },

    dateFormatShort: function(value) {
        let dateObj = {Monday:'Mon', Tuesday:'Tue', Wednesday:'Wed', Thursday:'Thu', Friday:'Fri', Saturday:'Sat', Sunday:'Sun'};
        let key = value;
        return dateObj[key];
    },

    getISOdayToString: function(value) {
        let dayObj = {1:'Mon', 2:'Tue', 3:'Wed', 4:'Thu', 5:'Fri', 6:'Sat', 7:'Sun'};
        let key = value;
        return dayObj[key];
    },

    convertHrLabel: function(hrValue) {
        let returnValue = '';
        if (hrValue < 10) {
            returnValue = '0'+hrValue+':00';
        } else {
            returnValue = hrValue+':00';
        }
        return returnValue;
    },

    getDaysOfWeekIndex: function(value) {
        // Input {1: true, 2: true, 3: true, 4: true, 5: true, 6: false, 7: false}
        let daysOfWeek = '';
        Object.keys(value).forEach(key => {
            if (value[key]) {
                daysOfWeek = daysOfWeek + key + ",";
            }
        });
        return daysOfWeek.replace(/,$/, ""); // Output "1,2,3,4,5"
    },

    dateFormatter: function(value) {
        if (!value || value === "None")
            return value;

        const dateUTCStr = value;
        const dateUTCStrMoment = moment.tz(dateUTCStr, "GMT");
        // const userTz = moment.tz.guess();
        // const userTz = 'Asia/Tokyo';
        // const localDate = dateUTCStrMoment.tz(userTz).format('YY/MM/DD  h:mm a');
        const localDate = dateUTCStrMoment.format('MM/DD/YY  h:mm a');
        return localDate
    },

    getDateRange: function(range, dtQuery, dtQueried) {
        if (dtQueried !== undefined && dtQueried[range]) {
            return dtQueried[range];
        } else {
            if (range === 'start_date') {
                range = 'startDate';
            }
            if (range === 'end_date') {
                range = 'endDate';
            }
            return dtQuery[range];
        }
    },

    getTimeRange: function(range, dtQuery, dtQueried) {
        if (dtQueried !== undefined && dtQueried[range]) {
            return dtQueried[range]+'h';
        } else {
            if (range === 'start_hr') {
                range = 'startHr';
            }
            if (range === 'end_hr') {
                range = 'endHr';
            }
            return dtQuery[range]+'h';
        }
    },

    getDayString: function(dtQuery, dtQueried) {
        let dayToReturn = '', dayOfWeekPassed;
        if (dtQueried !== undefined && dtQueried['days_of_week']) {
            dayOfWeekPassed = dtQueried['days_of_week'];
            let returnedArray = dayOfWeekPassed.split(",");
            let dayOfWeekValue = DayOfWeek.items;
            for (let i=0; i< returnedArray.length; i++) {
                let indexNumbered = Number(returnedArray[i]);
                let dayStr = dayOfWeekValue[indexNumbered-1]['tip'];
                dayToReturn = dayToReturn + dayStr + ', '
            }
            dayToReturn = dayToReturn.replace(/, $/, "");
            return dayToReturn;
        } else if (dtQuery['startDate'] !== null && dtQuery['daysOfWeek']) {
            let dayStr;
            dayOfWeekPassed = dtQuery['daysOfWeek'];
            let dayOfWeekValue = DayOfWeek.items;
            Object.keys(dayOfWeekPassed).forEach(key => {
                if (dayOfWeekPassed[key]) {
                    dayStr = dayOfWeekValue[parseInt(key)-1]['tip'];
                    dayToReturn = dayToReturn + dayStr + ', '
                }
            });
            dayToReturn = dayToReturn.replace(/, $/, "");
            return dayToReturn;
        } else {
            return dayToReturn;
        }
    },

    getSubtractDays: function(range) {
        // Used at SqlHelper for the Cohort date range subtraction
        // Return : array value for momentjs subtract function
        let returnDataArray = [];
        if (range === '7days') {
            returnDataArray = [7, 'days'];
        } else if (range === '14days') {
            returnDataArray = [14, 'days'];
        } else if (range === '21days') {
            returnDataArray = [21, 'days'];
        } else if (range === '30days') {
            returnDataArray = [30, 'days'];
        } else if (range === '1week') {
            returnDataArray = [1, 'weeks'];
        } else if (range === '2weeks') {
            returnDataArray = [2, 'weeks'];
        } else if (range === '3weeks') {
            returnDataArray = [3, 'weeks'];
        } else if (range === '6weeks') {
            returnDataArray = [6, 'weeks'];
        } else if (range === '9weeks') {
            returnDataArray = [9, 'weeks'];
        } else if (range === '12weeks') {
            returnDataArray = [12, 'weeks'];
        } else if (range === '1month') {
            returnDataArray = [1, 'months'];
        } else if (range === '2month') {
            returnDataArray = [2, 'months'];
        } else if (range === '3month') {
            returnDataArray = [3, 'months'];
        } else if (range === '6month') {
            returnDataArray = [6, 'months'];
        } else if (range === '12month') {
            returnDataArray = [12, 'months'];
        }
        return returnDataArray;
    },

    getDaysListBetweenDays: function(dateFrom, dateTo) {
        var dateArray = [];
        var currentDate = moment(dateFrom);
        var stopDate = moment(dateTo);
        while (currentDate <= stopDate) {
            dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    },

    getHoursListBetweenDays: function(dateFrom, dateTo) {
        var hoursArray = [];
        var currentDate = moment(dateFrom);
        var stopDate = moment(dateTo);
        while (currentDate <= stopDate) {
            for (var i = 0; i < 24; i++) {
                var formattedHour = moment(currentDate).set('hour', i).format('YYYY-MM-DD HH:mm');
                hoursArray.push(formattedHour);
            }
            currentDate = moment(currentDate).add(1, 'days');
        }
        return hoursArray;
    },    

    formatDateRangeWithDash: function(dateRange) {
        return [moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD'),];
    },

    convertToUtcFromLocal: function(date, format, timeZone) {
        const utcDate = moment.tz(date, format, timeZone).toISOString();
        return utcDate;
    },

    getCustomUnixFromAndToTimestamp: function(dateRange) {
        let unixStampToReturn = [];
        let dateFrom = moment(dateRange[0]).format('YYYY/MM/DD');
        let dateTo = moment(dateRange[1]).format('YYYY/MM/DD');
        return unixStampToReturn = [
            moment(dateFrom+" 00:00:00+8:00").format('x'),
            moment(dateTo+" 23:59:59+8:00").format('x')
        ];
    },

}

export default FormatDateHelper;