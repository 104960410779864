import React, { Fragment, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import * as H from "helper";

const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    padding: theme.spacing(1) * 2,
    "&:last-child": {
      paddingBottom: theme.spacing(1) * 2,
    },
  },
  box: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRight: "1px solid #dcdcdc",
  },
  boxLast: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  title: {
    display: "block",
    height: 32,
    fontSize: 14,
  },
  value: {
    paddingTop: 16,
  },
  numSection: {
    display: "block",
    paddingTop: 16,
  },
  circleProgress: {
    marginTop: 40,
    marginBottom: 8,
  },
  txtPrev: {
    fontSize: 13,
  },
  gainLossImg: {
    paddingTop: 8,
  },
  dotContainer: {
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  dot: {
    borderRadius: 8,
    display: "inline-block",
    height: 12,
    width: 12,
    marginRight: 6,
    cursor: "pointer",
  },
  currency: {
    fontSize: 14,
  },
}));

const StatBoxCarousel = ({
  title,
  loading,
  data,
  isCount,
  depPrice,
  selectedCurrency,
  noConvert,
  prefix,
  lastItem,
}) => {
  const classes = useStyles();
  const [dotValue, setDotValue] = useState(0);

  const getPrevComponent = (value, prevValue, title) => {
    const diffValue = value - prevValue;
    const diffRatio = (diffValue / prevValue) * 100;

    let imageName = "";
    if (title === "停止" && diffValue !== 0) {
      imageName = value > prevValue ? "loss" : "gain";
    } else if (diffValue !== 0) {
      imageName = value > prevValue ? "gain" : "loss";
    }

    if (!prevValue) {
      return <div className={classes.txtPrev}>---</div>;
    }

    return (
      <div className={classes.txtPrev}>
        {imageName !== "" && (
          <img
            className={classes.gainLossImg}
            src={`/static/images/${imageName}.png`}
          />
        )}
        {H.FormatNumStrHelper.formatCommaThousand(diffValue)} (
        {Math.abs(diffRatio.toFixed(2))}%)
      </div>
    );
  };

  const handleDotClick = (value) => {
    setDotValue(value);
  };

  const getPrefix = (selectedCurrency) => {
    if (selectedCurrency === "usd") {
      return "US$";
    } else if (selectedCurrency === "sgd") {
      return "S$";
    } else if (selectedCurrency === "jpy") {
      return "¥";
    } else {
      return "";
    }
  };

  const handleCurrencyConvert = (amount) => {
    let returnAmount = Number(amount);
    if (selectedCurrency === "usd") {
      returnAmount = returnAmount * depPrice.usd;
    } else if (selectedCurrency === "sgd") {
      returnAmount = returnAmount * depPrice.sgd;
    } else if (selectedCurrency === "jpy") {
      returnAmount = returnAmount * depPrice.jpy;
    }
    return returnAmount;
  };

  return (
    <Grid
      container
      alignItems={"center"}
      direction={"row"}
      justifyContent={"flex-start"}
    >
      <Grid item xs={12}>
        <div className={lastItem ? classes.boxLast : classes.box}>
          <Grid container direction={"row"} justifyContent={"flex-start"}>
            <Grid item xs={12}>
              <Typography
                variant="caption"
                className={classes.title}
                dangerouslySetInnerHTML={{ __html: title }}
              ></Typography>
            </Grid>
          </Grid>
          <div className={classes.numSection}>
            <Grid container direction={"row"} justifyContent={"space-between"}>
              {loading && (
                <div className={classes.circleProgress}>
                  <CircularProgress size={20} />
                </div>
              )}
              {!loading && data !== undefined && data.length > 0 && dotValue === 0 && (
                <Fragment>
                  <Grid item xs={4} lg={6}>
                    <Typography variant="caption" className={classes.value}>
                      {data[0]["title"]}
                    </Typography>
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      style={{ marginBottom: 8, width: "100%" }}
                    >
                      <Grid item xs={4} sm={6}>
                        {
                          <Typography variant="h6">
                            {/* <span className={classes.currency}>
                              {isCount ? "" : getPrefix(selectedCurrency)}
                            </span> */}
                            {H.FormatNumStrHelper.formatCommaThousand(
                                data[0]["current"]
                            )}
                            {/* {isCount
                              ? H.FormatNumStrHelper.formatCommaThousand(
                                  data[0]["current"]
                                )
                              : H.FormatNumStrHelper.formatCommaThousand(
                                  handleCurrencyConvert(data[0]["current"])
                                )} */}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={8} sm={6}>
                        {data[0]["current"] === "-"
                        ? "--"
                        :getPrevComponent(
                          data[0]["current"],
                          data[0]["prev"],
                          data[0]["title"]
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} lg={6}>
                    <Typography variant="caption" className={classes.value}>
                      {data[1]["title"]}
                    </Typography>
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      style={{ marginBottom: 8, width: "100%" }}
                    >
                      <Grid item xs={4} sm={6}>
                        {
                          <Typography variant="h6">
                            {/* <span className={classes.currency}>
                              {isCount ? "" : getPrefix(selectedCurrency)}
                            </span> */}
                            {H.FormatNumStrHelper.formatCommaThousand(
                                  data[1]["current"]
                            )}
                            {/* {isCount
                              ? H.FormatNumStrHelper.formatCommaThousand(
                                  data[1]["current"]
                                )
                              : H.FormatNumStrHelper.formatCommaThousand(
                                  handleCurrencyConvert(data[1]["current"])
                                )} */}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={8} sm={6}>
                        {data[1]["current"] === "-"
                        ? "--"
                        :getPrevComponent(
                          data[1]["current"],
                          data[1]["prev"],
                          data[1]["title"]
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              {!loading && data !== undefined && data.length > 0 && dotValue === 1 && (
                <Fragment>
                  <Grid item xs={4} lg={6}>
                    <Typography variant="caption" className={classes.value}>
                      {data[2]["title"]}
                    </Typography>
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      style={{ marginBottom: 8, width: "100%" }}
                    >
                      <Grid item xs={4} sm={12}>
                        {
                          <Typography variant="h6">
                            <span className={classes.currency}>
                              {noConvert ? prefix : getPrefix(selectedCurrency)}
                            </span>
                            {noConvert
                              ? H.FormatNumStrHelper.formatCommaThousand(
                                  data[2]["current"]
                                )
                              : data[2]["current"] === "-"
                                ? "---" 
                                : H.FormatNumStrHelper.formatCommaThousand(
                                  handleCurrencyConvert(data[2]["current"])
                                )}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={8} sm={12}>
                        {data[2]["current"] === "-"
                        ? "--"
                        : getPrevComponent(
                          data[2]["current"],
                          data[2]["prev"],
                          data[2]["title"]
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.length > 3 && (
                    <Grid item xs={4} lg={6}>
                      <Typography variant="caption" className={classes.value}>
                        {data[3]["title"]}
                      </Typography>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        style={{ marginBottom: 8, width: "100%" }}
                      >
                        <Grid item xs={4} sm={6}>
                          {
                            <Typography variant="h6">
                              <span className={classes.currency}>
                                {noConvert ? prefix : getPrefix(selectedCurrency)}
                              </span>
                              {noConvert
                                ? H.FormatNumStrHelper.formatCommaThousand(
                                    data[3]["current"]
                                  )
                                : data[3]["current"] === "-"
                                  ? "--"
                                  : H.FormatNumStrHelper.formatCommaThousand(
                                    handleCurrencyConvert(data[3]["current"])
                                  )}
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={8} sm={6}>
                          {data[3]["current"] === "-"
                          ? "--"
                          : getPrevComponent(
                            data[3]["current"],
                            data[3]["prev"],
                            data[3]["title"]
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              )}
              {!loading && data !== undefined && data.length > 0 && dotValue === 2 && (
                <Fragment>
                  <Grid item xs={4} lg={6}>
                    <Typography variant="caption" className={classes.value}>
                      {data[4]["title"]}
                    </Typography>
                    <Grid
                      container
                      spacing={0}
                      direction="column"
                      style={{ marginBottom: 8, width: "100%" }}
                    >
                      <Grid item xs={4} sm={6}>
                        {
                          <Typography variant="h6">
                            <span className={classes.currency}>
                              {noConvert ? prefix : getPrefix(selectedCurrency)}
                            </span>
                            {noConvert
                              ? H.FormatNumStrHelper.formatCommaThousand(
                                  data[4]["current"]
                                )
                              : data[4]["current"] === "-"
                                ? "--"
                                : H.FormatNumStrHelper.formatCommaThousand(
                                  handleCurrencyConvert(data[4]["current"])
                                )}
                          </Typography>
                        }
                      </Grid>
                      <Grid item xs={8} sm={6}>
                        {data[4]["current"] === "-"
                          ? "--"
                          : getPrevComponent(
                          data[4]["current"],
                          data[4]["prev"],
                          data[4]["title"]
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {data.length > 5 && (
                    <Grid item xs={4} lg={6}>
                      <Typography variant="caption" className={classes.value}>
                        {data[5]["title"]}
                      </Typography>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        style={{ marginBottom: 8, width: "100%" }}
                      >
                        <Grid item xs={4} sm={6}>
                          {
                            <Typography variant="h6">
                              <span className={classes.currency}>
                                {isCount ? "" : getPrefix(selectedCurrency)}
                              </span>
                              {isCount
                                ? H.FormatNumStrHelper.formatCommaThousand(
                                    data[5]["current"]
                                  )
                                : H.FormatNumStrHelper.formatCommaThousand(
                                    handleCurrencyConvert(data[5]["current"])
                                  )}
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={8} sm={6}>
                          {data[5]["current"] === "-"
                          ? "---"
                          : getPrevComponent(
                            data[5]["current"],
                            data[5]["prev"],
                            data[5]["title"]
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Fragment>
              )}
            </Grid>
          </div>
          <Grid item xs={12}>
            {!loading && data !== undefined && data.length > 0 && (
              <div className={classes.dotContainer}>
                {data.length > 2 && (
                  <span
                    onClick={() => {
                      handleDotClick(0);
                    }}
                    className={classes.dot}
                    style={{
                      backgroundColor: dotValue === 0 ? "#494949" : "#afafaf",
                    }}
                  ></span>
                )}
                {data.length > 2 && (
                  <span
                    onClick={() => {
                      handleDotClick(1);
                    }}
                    className={classes.dot}
                    style={{
                      backgroundColor: dotValue === 1 ? "#494949" : "#afafaf",
                    }}
                  ></span>
                )}
                {data.length > 4 && (
                  <span
                    onClick={() => {
                      handleDotClick(2);
                    }}
                    className={classes.dot}
                    style={{
                      backgroundColor: dotValue === 2 ? "#494949" : "#afafaf",
                    }}
                  ></span>
                )}
              </div>
            )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

StatBoxCarousel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  color: PropTypes.string,
};

const mapStateToProps = (state) => ({
  depPrice: state.page.depPrice,
  selectedCurrency: state.page.selectedCurrency,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(StatBoxCarousel));
