import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ChartStyle from "styles/ChartStyle";
import { chartLineWidth } from "styles/variables";
import ChartConfig from "utils/ChartConfig";
import * as H from "helper";

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    marginTop: 16,
    marginLeft: 16,
    width: "100%",
    display: "block",
  },
}));

const LineChartCountDepTrend = ({
  title,
  legend,
  lblSaveImage,
  xAisData,
  seriesData,
  chartType,
  currency,
}) => {
  const classes = useStyles();
  const [seriesType, setSeriesType] = useState(chartType);
  const [chartData, setChartData] = useState([]);
  const [color, setColorArray] = useState([]);
  const [stackState, setStackState] = useState("");
  const [zoomStart, setZoomStart] = useState(0);
  const [zoomEnd, setZoomEnd] = useState(100);

  useEffect(() => {
    echarts.registerTheme("BarChartTheme", ChartStyle);
  }, []);

  useEffect(() => {
    let eachChartSeries = {},
      seriesDataArray = [],
      colorArray = [],
      objIndex = 0;
    _.forOwn(seriesData, function (value, key) {
      let chartType = value.id;
      colorArray.push(ChartConfig.chartColorByType[value.id]);
      _.forOwn(value, function (value2, key2) {
        if (key2 === "data") {
          eachChartSeries.name = legend[objIndex];
          eachChartSeries.type =
            value.id.indexOf("-amount") !== -1 ? "bar" : "line";
          if (value.id.indexOf("-amount") !== -1) {
            eachChartSeries.yAxisIndex = 1;
          }
          eachChartSeries.data = value2;
          eachChartSeries.lineStyle = { width: chartLineWidth };
          if (value.id.indexOf("-unique") !== -1) {
            eachChartSeries.lineStyle = { type: "dashed" };
          }
          eachChartSeries.symbolSize =
            seriesData[0]["date"].length > 60 ? 4 : 8;
          eachChartSeries.barGap = 0;
          eachChartSeries.barMaxWidth = 20;
          seriesDataArray.push(eachChartSeries);
          eachChartSeries = {};
        }
      });
      objIndex++;
    });
    setChartData(seriesDataArray);
    setColorArray(colorArray);
  }, [seriesData, seriesType]);

  const magicTypeChanged = (param) => {
    if (param.currentType === "stack") {
      setStackState("__ec_magicType_stack__");
    } else if (param.currentType === "tiled") {
      setStackState(null);
    } else {
      setSeriesType(param.currentType);
    }
  };

  const dataZoomChanged = (param) => {
    setZoomStart(param.batch[0]["start"]);
    setZoomEnd(param.batch[0]["end"]);
  };

  const onEvents = {
    magictypechanged: magicTypeChanged,
    datazoom: dataZoomChanged,
  };

  return (
    <div className={classes.chartContainer}>
      <ReactEcharts
        option={{
          title: {
            text: title,
            textStyle: {
              fontWeight: "normal",
              fontSize: 14,
            },
            padding: [2, 0, 0, 30],
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "none",
            },
            order: "seriesAsc",
          },
          grid: {
            left: 64,
            top: "25%",
            right: 48,
            bottom: 42,
          },
          legend: {
            show: true,
            data: legend,
            padding: [
              5, // up
              10, // right
              5, // down
              10, // left
            ],
          },
          toolbox: {
            show: true,
            feature: {
              magicType: {
                show: false,
                type: ["bar", "line"],
                title: {
                  line: "Line",
                  bar: "Bar",
                  stack: "Stack",
                  tiled: "Side by Side",
                },
                icon: {
                  line: "image://../../static/images/font-icon/line-chart.svg",
                  bar: "image://../../static/images/font-icon/bar-chart.svg",
                },
              },
              saveAsImage: {
                show: false,
                title: "Save",
                name: lblSaveImage,
                icon: "image://../../static/images/font-icon/save-chart.svg",
              },
            },
          },
          xAxis: {
            type: "category",
            data: xAisData,
          },
          yAxis: [
            {
              type: "value",
              name: "件数",
              minInterval: 1,
            },
            {
              type: "value",
              name: currency == undefined ? "DEP" : currency,
              axisLabel: {
                formatter: function (value, index) {
                  return H.FormatNumStrHelper.formatKandM(value);
                },
              },
            },
          ],
          dataZoom: [
            {
              disabled: true,
              type: "inside",
              start: zoomStart,
              end: zoomEnd,
            },
          ],
          series: chartData,
          color: color,
        }}
        theme="BarChartTheme"
        notMerge={true}
        lazyUpdate={true}
        onEvents={onEvents}
        style={{ height: "350px", width: "98%" }}
      />
    </div>
  );
};

LineChartCountDepTrend.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(LineChartCountDepTrend));
