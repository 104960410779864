import React, { useState, useEffect, useRef } from "react";
import _ from 'lodash';
import { useParams, useHistory } from 'react-router-dom';
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress, Checkbox, TextField, Button, Grid, Chip, Box, Collapse, IconButton, Typography, Paper } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GetAppIcon from '@material-ui/icons/GetApp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, setTxHistorySearchTerm, getUserListData, setUserPageListDataFilter, setFilterDrawerState,
    getUserListDataByTerm, getUserCsvData, setNftTxCsvData, setUserPageNumber } from 'actions';
import LocalCalendar from 'components/Calendar/LocalCalendar';
import * as C from 'components';
import * as V from 'styles/variables';
import * as H from 'helper';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function setChipLabel (label, classes) {
    const colorKeyName = 'chipColor'+label.toLowerCase().replace(/[^A-Z0-9]+/ig, "");
    const newLabel = label === "NFT:DEP" || label === "nftdep" ? "PlayMining NFT" : label;
    return <Chip
                className={classes[colorKeyName]}
                size="small"
                label={newLabel}
        />
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell align="left" component="th" scope="row">
            {row.pmid}
          </TableCell>
          <TableCell align="center">{setChipLabel(row.game, classes)}</TableCell>
          <TableCell align="center">{row.adCode}</TableCell>
          <TableCell align="center">{row.adName}</TableCell>
          <TableCell align="center">{row.created_at}</TableCell>
          {/* <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell> */}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            {/* <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  History
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Customer</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Total price ($)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse> */}
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

const UsersByAdCode = ({
    setCurrentPage,
    currentPage,
    setTxHistorySearchTerm,
    assetOption,
    getUserListData,
    userStatData,
    setUserPageListDataFilter,
    pageTopLoader,
    setFilterDrawerState,
    filterDrawerState,
    assetSearchTerm,
    pageNumber,
    getUserListDataByTerm,
    getUserCsvData,
    setNftTxCsvData,
    userCsvData,
    userCsvLoader,
    setUserPageNumber,
    userPageListData,
    userPageListDataFilter,
}) => {
    const classes = useStyles();
    const params = useParams();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [assetOptions, setAssetOptions] = useState(assetOption);
    const [adCode, setAdCode] = useState({name:""});
    const [txRowHisotryData, setTxRowHisotryData] = useState([]);
    const [noData, setNodata] = useState(false);
    const [defaultDateRange, setDefaultDateRagne] = useState([
        moment().tz("Asia/Singapore").subtract(6, 'days').format('YYYY/MM/DD'),
        moment().tz("Asia/Singapore").format('YYYY/MM/DD')
    ]);
    const [totalPageNum, setTotalPageNum] = useState(0);
    const [totalAdNum, setTotalAdNum] = useState('--');
    const csvLinkRef = useRef(null);

    const handleChangeOption = (data) => {
        setBtnState(false);
    }

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        getUserListData(pageNumber);
        setNftTxCsvData([]);
    }, []);

    useEffect(() => {
        if (userCsvData.length > 0) {
            csvLinkRef.current.link.click();
            setNftTxCsvData([]);
        }
    }, [userCsvData]);

    useEffect(() => {
        setAssetOptions(assetOption);
    }, [assetOption]);

    useEffect(() => {
        const pageCount = userStatData[0]['countInRange'] / 50;
        setTotalPageNum(Math.ceil(pageCount));
        setTotalAdNum(H.FormatNumStrHelper.formatCommaThousand(userStatData[0]['totalAd']))
    }, [userStatData]);

    useEffect(() => {
        if (userPageListData.length > 0) {
            let dataRows = [];
            _.each(userPageListData, function(dataRow) {
                let rowObj = {
                    "pmid": dataRow["pmid"],
                    "game": dataRow["game"],
                    "adCode": dataRow["rid"],
                    "adName": dataRow["cname"],
                    "created_at": dataRow["createdAt"],
                }
                dataRows.push(rowObj);
                rowObj = {};
            });
            setTxRowHisotryData(dataRows);
            setNodata(false);
        } else {
            setNodata(true);
        }
    }, [userPageListData]);

    const handlePageChange = (event, value) => {
        setUserPageNumber(value);
        if (assetSearchTerm.length > 0 && 
            assetSearchTerm[0]['value'] !== "") {
            getUserListDataByTerm();
        } else {
            getUserListData(value);
        }
    };

    const setRange = (newValue) => {
        const startDate = moment(newValue[0]).format('YYYY-MM-DD');
        const endDate = moment(newValue[1]).format('YYYY-MM-DD');
        let newFilter = [ ... userPageListDataFilter ];
        newFilter[0]['dateRange'] = [startDate, endDate];
        setUserPageListDataFilter(newFilter);
        setBtnState(false);
    }

    const showData = (value) => {
        if (value === "--") {
            return value;
        }
        return H.FormatNumStrHelper.formatCommaThousand(value);
    }

    const fetchData = () => {
        if (assetSearchTerm.length > 0 && 
            assetSearchTerm[0]['value'] !== "") {
            getUserListDataByTerm();
        } else {
            getUserListData(pageNumber);
        }
        setBtnState(!btnStateDataFetch);
    }

    const handleInputChange = (data, type) => {
        switch(type) {
            case "ad_code":
                setAdCode({name:data});
                break;
            default:
        }
        const termObject = {
            type: type,
            value: data.replace(/\s\s+/g, '\ ')
        }
        let cloneTerm = [...assetSearchTerm];

        const objIndex = cloneTerm.findIndex((termObject => termObject.type == type));
        if (objIndex === -1) {
            cloneTerm.push(termObject);
        } else {
            cloneTerm[objIndex].value = data;
        }
        setTxHistorySearchTerm(cloneTerm);
    }

    return (
        <C.ContentWrapper>
            <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>

                <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div>Total Users registered with Ads : {totalAdNum === "NaN" ? "--" : totalAdNum}</div>
                        {/* <Autocomplete
                            //multiple
                            freeSolo
                            disablePortal
                            limitTags={3}
                            size="small"
                            id="select-game"
                            value={adCode}
                            options={assetOptions}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                                </React.Fragment>
                            )}
                            onChange={(e, newValue) => {
                                handleChangeOption(newValue);
                            }}
                            onInputChange={(e, newValue) => {
                                handleInputChange(newValue, 'ad_code');
                            }}
                            style={{ minWidth: 400 }}
                            renderInput={(params) => (
                                <TextField {...params} label="Search by Ad Code" />
                            )}
                            className={classes.autoSelect}
                        /> */}
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <LocalCalendar
                                    setRange={setRange}
                                    setBtnState={setBtnState}
                                    defaultRange={defaultDateRange}
                                    newRange={[
                                        userPageListDataFilter[0]['dateRange'][0],
                                        userPageListDataFilter[0]['dateRange'][1],
                                    ]}
                                />
                            </Grid>
                            <Grid item>
                                <Button 
                                    //disabled={btnStateDataFetch} 
                                    className={classes.dataBtn} 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={() => fetchData()}>
                                    GO
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button 
                                    className={classes.filterBtn} 
                                    variant="contained" 
                                    color="default" 
                                    onClick={() => setFilterDrawerState(!filterDrawerState ? currentPage : false )}>
                                    FILTER
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                </Grid>
            </Grid>

            <Grid container spacing={0} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <Paper className={classes.statsSection}>
                        <Grid container direction="row" className={classes.gridSection} justifyContent="flex-start">
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4} lg={2}>
                                <Grid container direction="row" className={classes.gridSection} justifyContent="space-between">
                                    <Grid item>
                                        <div className={classes.statTitle}>Total:</div>
                                    </Grid>
                                    <Grid item>
                                        {userCsvLoader && 
                                            <CircularProgress className={classes.titleDl} size={20} />
                                        }
                                        {!userCsvLoader &&
                                            <>
                                            <IconButton onClick={() => getUserCsvData()} className={classes.titleDl} size="small"
                                            disabled={userStatData[0]['countInRange'] > 10000 ? true : false}>
                                                <GetAppIcon />
                                            </IconButton>
                                            </>
                                        }
                                        <CSVLink
                                            data={userCsvData}
                                            filename='UsersByAdCode_data.csv'
                                            className='hidden'
                                            ref={csvLinkRef}
                                            target='_blank'
                                        />
                                    </Grid>
                                </Grid>
                                <div className={classes.statValue}>{showData(userStatData[0]['countInRange'])}</div>
                            </Grid>
                            {/* <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>DEP総額:</div>
                                <div className={classes.statValue}>{showData(userStatData[0]['totalDep'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>1次件数:</div>
                                <div className={classes.statValue}>{showData(userStatData[0]['totalPrimeTx'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>2次件数:</div>
                                <div className={classes.statValue}>{showData(userStatData[0]['totalSecondTx'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6}  md={4} lg={2}>
                                <div className={classes.statTitle}>1次DEP総額:</div>
                                <div className={classes.statValue}>{showData(userStatData[0]['totalPrimeDep'])}</div>
                            </Grid>
                            <Grid className={classes.statBox} item xs={12} sm={6} md={4} lg={2}>
                                <div className={classes.statTitle}>2次DEP総額:</div>
                                <div className={classes.statValue}>{showData(userStatData[0]['totalSecondDep'])}</div>
                            </Grid> */}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridSection} alignItems="center" justifyContent="center">
                <Grid item xs={12} md={12} lg={10}>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table stickyHeader aria-label="collapsible table" className={classes.table}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell align="left">PMID</TableCell>
                                    <TableCell align="center">GAME</TableCell>
                                    <TableCell align="center">Ad Code</TableCell>
                                    <TableCell align="center">Campaign Name</TableCell>
                                    <TableCell align="center">DATE (SGT)</TableCell>
                                    {/* <TableCell /> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {pageTopLoader && (
                                <TableRow className={classes.dataLoadingTxt} >
                                    <TableCell align="center" colSpan={7}>Loading...</TableCell>
                                </TableRow>
                            )}
                            {!noData && txRowHisotryData.map((row, index) => (
                                <Row key={index} row={row} />
                            ))}
                            {!pageTopLoader && noData && (
                                <TableRow className={classes.dataLoadingTxt} >
                                    <TableCell align="center" colSpan={7}>No Data</TableCell>
                                </TableRow>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {totalPageNum > 0 &&
            <Grid container spacing={1} className={classes.pageSection} alignItems="center" justifyContent="center">
                <Pagination count={totalPageNum} page={pageNumber} onChange={handlePageChange} />
            </Grid>    
            }
        </C.ContentWrapper>
    );
};

UsersByAdCode.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    assetSearchLoading: state.nftDepTxHisotry.assetSearchLoading,
    assetOption: state.nftDepTxHisotry.assetOption,
    pageTopLoader: state.page.pageTopLoader,
    filterDrawerState: state.page.filterDrawerState,
    currentPage: state.page.currentPage,
    assetSearchTerm: state.nftDepTxHisotry.assetSearchTerm,
    pageNumber: state.users.pageNumber,
    userStatData: state.users.userStatData,
    userPageListData: state.users.userPageListData,
    userPageListDataFilter: state.users.userPageListDataFilter,
    userCsvData: state.users.userCsvData,
    userCsvLoader: state.users.userCsvLoader,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setTxHistorySearchTerm: (param) => dispatch(setTxHistorySearchTerm(param)),
    getUserListData: (param) => dispatch(getUserListData(param)),
    setUserPageListDataFilter: (param) => dispatch(setUserPageListDataFilter(param)),
    setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
    getUserListDataByTerm: () => dispatch(getUserListDataByTerm()),
    getUserCsvData: () => dispatch(getUserCsvData()),
    setNftTxCsvData: (param) => dispatch(setNftTxCsvData(param)),
    setUserPageNumber: (param) => dispatch(setUserPageNumber(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(UsersByAdCode));

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            '& > *': {
              //borderBottom: 'unset',
            },
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        pageSection: {
            marginTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        table: {
            minHeight: "500px",
            overflowY: "scroll"
        },
        dataLoadingTxt: {
            // marginTop: theme.spacing(4),
            // marginLeft: theme.spacing(4),
        },
        statsSection: {
            minHeight: 60,
            padding: theme.spacing(2),
            borderRadius: 4,
        },
        statBox: {
            marginTop: 0,
            minHeight: 60,
            paddingLeft: 8,
            borderRight: '1px solid #dcdcdc',
            "&:last-child": {
                borderRight: 'none',
            },
        },
        statTitle: {
            fontSize: 13,
        },
        statValue: {
            fontSize: 15,
            paddingTop: 8,
            fontWeight: 500,
        },
        filterBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
        },
        chipColorjobtribes: {
            marginTop: theme.spacing(2),
            backgroundColor: V.jobtribes,
            color: '#fff',
        },
        chipColorpuzzle: {
            marginTop: theme.spacing(2),
            backgroundColor: V.puzzle,
            color: '#fff',
        },
        chipColorluckyfarmer: {
            marginTop: theme.spacing(2),
            backgroundColor: V.luckyfarmer,
            color: '#fff',
        },
        chipColorpmportal: {
            marginTop: theme.spacing(2),
            backgroundColor: V.pmportal,
            color: '#fff',
        },
        chipColornftdep: {
            marginTop: theme.spacing(2),
            backgroundColor: V.nftdep,
            color: '#fff',
        },
        chipColorcookinburger: {
            marginTop: theme.spacing(2),
            backgroundColor: V.cookinburger,
            color: '#fff',
        },
        chipColorgraffitiracer: {
            marginTop: theme.spacing(2),
            backgroundColor: V.graffitiracer,
            color: '#fff',
        },
        chipColordragonramen: {
            marginTop: theme.spacing(2),
            backgroundColor: V.dragonramen,
            color: '#fff',
        },
        titleDl: {
            marginRight: 16,
            color: "#000",
        },
    }),
);
