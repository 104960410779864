import { LOSTARCHIVE } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    lostArchiveNftTrendDateLoading: false,
    lostArchiveNftTrendDateRange: [startDate, endDate],
    trendData: [],
    nftTrendDataCountAuction: [],
    nftTrendDataAmountAuction: [],
    nftTrendDataCountStore: [],
    nftTrendDataAmountStore: [],
    nftTrendDataCountStoreFiat: [],
    nftTrendDataAmountStoreFiat: [],
    nftTrendDataAuctionAll: {
        count: '-',
        amount: '-',
        unique: '-',
    },
    nftTrendDataStoreAll:  {
        count: '-',
        amount: '-',
        unique: '-',
    },
    nftTrendDataStoreAllFiat:  {
        count: '-',
        amount: '-',
        unique: '-',
    },
};

export default function lostArchiveNftTrendReducer(state = initialState, action) {
    switch (action.type) {
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_DATA_LOADING:
            return {
                ...state,
                lostArchiveNftTrendDateLoading: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_DATE_RANGE:
            return {
                ...state,
                lostArchiveNftTrendDateRange: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_PAGE_DATA:
            return {
                ...state,
                trendData: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_COUNT_AUCTION:
            return {
                ...state,
                nftTrendDataCountAuction: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AMOUNT_AUCTION:
            return {
                ...state,
                nftTrendDataAmountAuction: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE:
            return {
                ...state,
                nftTrendDataCountStore: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE:
            return {
                ...state,
                nftTrendDataAmountStore: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_COUNT_STORE_FIAT:
            return {
                ...state,
                nftTrendDataCountStoreFiat: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AMOUNT_STORE_FIAT:
            return {
                ...state,
                nftTrendDataAmountStoreFiat: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_AUCTION_ALL:
            return {
                ...state,
                nftTrendDataAuctionAll: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_STORE_ALL:
            return {
                ...state,
                nftTrendDataStoreAll: action.payload
            };
        case LOSTARCHIVE.SET_LOSTARCHIVE_NFT_TREND_STORE_ALL_FIAT:
            return {
                ...state,
                nftTrendDataStoreAllFiat: action.payload
            };
        default:
            return state
    }
};
