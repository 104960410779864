import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid, Card, CardHeader, CardContent, Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as C from 'components';
import * as H from 'helper';
import { setCurrentPage, getTrendDataByAid, getRegDataByAid, setCampaignIdToFetch,
    setCampaignTrendFetchRid
} from 'actions';

const CampaignView2 = ({
    trendChartData,
    trendDataLoading,
    setCampaignIdToFetch,
    campaignDataLoadingById,
    campaignStatsById,
    setCampaignTrendFetchRid,
    campaignViewStats,
}) => {
    const classes = useStyles();
    const params = useParams(); // Get affiliateId from URL

    useEffect(() => {
        setCurrentPage('view');
        getRegDataByAid();
        getTrendDataByAid();
        setCampaignIdToFetch(Number(params.aid));
        setCampaignTrendFetchRid(params.rid);
    }, []);

    const getChartData = () => {
        let chartData = [];
        if (trendChartData.length > 0) {
            let dataObj = {};
            for (let i=0; i<trendChartData[0]['date'].length; i++) {
                dataObj['日付'] = trendChartData[0]['date'][i];
                dataObj['初アクセス'] = trendChartData[0]['first'][i];
                if (campaignStatsById[0]['type'] !== 'campaign_game')
                    dataObj['ゲーム遷移'] = trendChartData[0]['conv'][i];
                dataObj['PMID登録'] = trendChartData[0]['reg'][i];
                chartData.push(dataObj);
                dataObj = {}
            }
        };
        return chartData;
    }

    const getStatsData = (field) => {
        if (campaignDataLoadingById) {
            return '--';
        } else if (campaignStatsById.length > 0) {
            if (field === 'name') {
                return H.FormatNumStrHelper.unEscapeUnicode(campaignStatsById[0][field]);
            } else if (field === 'cvrConv') {
                if (campaignStatsById[0]['type'] === 'campaign_game' || campaignStatsById[0]['Game'] === "PM Portal") {
                    return '--';
                } else {
                    return (campaignStatsById[0]['gameVisit']/campaignStatsById[0]['firstTime']*100).toFixed(2)+'%';
                }
            } else if (field === 'cvrReg') {
                if (campaignStatsById[0]['registered'] === 0) {
                    return '0.00%';
                }
                if (campaignStatsById[0]['type'] === 'campaign_game') {
                    return (campaignStatsById[0]['registered']/campaignStatsById[0]['firstTime']*100).toFixed(2)+'%';
                } else {
                    return (campaignStatsById[0]['registered']/campaignStatsById[0]['gameVisit']*100).toFixed(2)+'%';
                }
            } else if (field === 'type') {
                return H.FormatNumStrHelper.formatCampaignType(campaignStatsById[0]['type']);
            } else {
                return campaignStatsById[0][field];
            }
        }
    }

    const getRegCvr = (cp) => {
        if (cp === undefined) return '--';
        if (cp.type === 'campaign_game' || cp.property === "PM Portal") {
            return (campaignViewStats.regist/campaignViewStats.firstTime*100).toFixed(2)+'%';
        } else if (cp.type === 'campaign' && cp.property !== "PM Portal")  {
            return (campaignViewStats.regist/campaignViewStats.gameVisit*100).toFixed(2)+'%';
        }
    }

    return (
        <C.ContentWrapper>
            <Grid container spacing={8}>
                <Grid item xs={12}>{getStatsData('name')}  ({getStatsData('type')})</Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} lg={2}>
                    <C.StatCard
                            title="キャンペーンコード"
                            value={getStatsData('rid')}
                            color="#3f51b5"
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <C.StatCard
                            title="① 初アクセス"
                            value={campaignViewStats.firstTime}
                            color="#61cdbb"
                            type='fill'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <C.StatCard
                            title="② ゲーム遷移"
                            value={campaignViewStats.gameVisit}
                            color="#115375"
                            type='fill'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <C.StatCard
                            title="③ CV PMID登録数"
                            value={campaignViewStats.regist}
                            color="#fb8072"
                            type='fill'
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <C.StatCard
                            title="ゲーム遷移率 [②/①]"
                            value={
                                campaignViewStats.gameVisit === '--' ? '--' : 
                                (campaignViewStats.gameVisit/campaignViewStats.firstTime*100).toFixed(2)+'%'
                            }
                            color="#f44336"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={2}>
                        <C.StatCard
                            title="CVR：PMID登録率 [③/②]"
                            value={getRegCvr(campaignStatsById[0])}
                            color="#ffd740"
                        />
                    </Grid>
                <Grid item xs={12}>
                    <Card spacing={8} className={classes.trendContainer}>
                        <CardHeader title={'アクセス推移'}
                            action={
                                trendChartData.length > 0 &&
                                    !trendDataLoading &&
                                        <CSVLink
                                            data={getChartData()}
                                            filename={campaignStatsById[0]['rid'] + ' ' +
                                            H.FormatNumStrHelper.unEscapeUnicode(campaignStatsById[0]['name'])}
                                        >
                                            <Button
                                                variant="contained"
                                                color="default"
                                                className={classes.button}
                                                startIcon={<GetApp />}
                                            >
                                                CSV出力
                                            </Button>
                                        </CSVLink>
                            }
                        />
                        <CardContent>
                            <C.LineChartCampaignRidTrend campaignData={campaignStatsById} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </C.ContentWrapper>
    )
}

CampaignView2.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    campaignDataLoadingById: state.campaign.campaignDataLoadingById,
    campaignStatsById: state.campaign.campaignStatsById,
    trendDataLoading: state.campaign.trendDataLoading,
    trendChartData: state.campaign.trendChartData,
    campaignViewStats: state.campaign.campaignViewStats,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getTrendDataByAid: (param) => dispatch(getTrendDataByAid(param)),
    getRegDataByAid: (param) => dispatch(getRegDataByAid(param)),
    setCampaignIdToFetch: (param) => dispatch(setCampaignIdToFetch(param)),
    setCampaignTrendFetchRid: (param) => dispatch(setCampaignTrendFetchRid(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignView2);

const useStyles = makeStyles((theme) =>
    createStyles({
        sectionTitle: {
            fontSize: 14,
            float: 'left',
            marginLeft: -24,
        },
        sectionTitleRight: {
            fontSize: 13,
            float: 'right',
        },
        card: {
            padding: 8,
        },
        liItem: {
            lineHeight: 1.8,
            display: 'inline',
            paddingRight: 24,
        },
        liItemLarge: {
            fontSize: 20,
            listStyle: 'none',
            display: 'inline',
            paddingRight: 24,
        },
        tableTdEmail: {
            paddingLeft: 16,
            textAlign: 'left',
            maxWidth: 300,
        },
        tableThCountryTh: {
            textAlign: 'right',
            borderBottom: "1px solid rgb(212, 212, 212)"
        },
        tableTdCountry: {
            // paddingLeft: 16,
            textAlign: 'right',
        },
        tableTdCountryFlag: {
            width: 30,
            textAlign: 'left',
        },
        tableTdCity: {
            paddingLeft: 16,
            textAlign: 'center',
        },
        tBold: {
            fontWeight: 600,
        },
        countryName: {
            //float: 'left'
        },
        countryFlag: {
            marginTop: 6,
            marginLeft: 8,
            width: 24,
        },
        trendContainer: {
            height: 350,
        }
    }),
);
