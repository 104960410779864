import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { setActiveUsersDateRange } from 'actions';

const useStyles = makeStyles((theme) =>
    createStyles({
        monthPicker: {
            marginTop: 11,
            marginLeft: 16,
            width: 120,
        },
    }),
);

const ActiveUsersMonthRangePicker = ({
    setActiveUsersDateRange,
    cohortDateRange,
    setBtnState,
}) => {
    const classes = useStyles();
    const [minStartMonth, setMinStartMonth] = useState(new Date("2020-05-28"))
    const [selectedStartMonth, handleStartMonth] = useState(new Date('2021-06-30'));
    const [selectedEndMonth, handleEndMonth] = useState(new Date('2021-07-31'));

    useEffect(() => {
        const currentJstDate = moment().tz("Asia/Tokyo").format('YYYY/MM/DD');
    }, []);

    const setStartMonth = (month) => {
        console.log('setStartMonth:', moment(month).format('YYYY/MM'));
        handleStartMonth(month);
    }

    const setEndMonth = (month) => {
        console.log('setEndMonth:', moment(month).format('YYYY/MM'));
        handleEndMonth(moment(month).format('YYYY/MM'));
    }

    return (
        <Fragment>
        <div className={classes.monthPicker}>
            <DatePicker
                variant="inline"
                openTo="month"
                renderInput={props => {
                    let newProps = props;
                    newProps.helperText = "";
                    return <TextField size="small" {...newProps} />;
                }}
                minDate={minStartMonth}
                maxDate={new Date("2021-07-31")}
                views={["year", "month"]}
                label="Start 月"
                value={selectedStartMonth}
                onChange={setStartMonth}
            />
        </div>
        <div className={classes.monthPicker}>
            <DatePicker
                variant="inline"
                openTo="month"
                renderInput={props => {
                    let newProps = props;
                    newProps.helperText = "";
                    return <TextField size="small" {...newProps} />;
                }}
                minDate={minStartMonth}
                maxDate={new Date("2021-07-31")}
                views={["year", "month"]}
                label="End 月"
                value={selectedEndMonth}
                onChange={setEndMonth}
            />
        </div>
        </Fragment>
    );
}

ActiveUsersMonthRangePicker.prototypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    cohortDateRange: state.cohort.cohortDateRange,
});

const mapDispatchToProps = (dispatch) => ({
    setActiveUsersDateRange: (param) => dispatch(setActiveUsersDateRange(param)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(null, { withTheme: true })(ActiveUsersMonthRangePicker));