import * as H from 'helper';

const QueryHelper = {
    getFilterStr: function(filterValues) {
        let metricValue = '';
        for (let i=0; i<filterValues['group'].length; i++) {
            metricValue = metricValue + filterValues['group'][i]['value'] + ',';
        }
        metricValue = metricValue.replace(/,$/, "");
        return metricValue;
    },

    getLocationQuery: function(location, key) {
        if (location['poi'] !== null) {
            if (key !== 'poi') {
                return null
            } else {
                if (Array.isArray(location['poi']) && location['poi'].length > 1) {
                    let locationIdsStr = ''
                    for (let i=0; i<location['poi'].length; i++) {
                        locationIdsStr= locationIdsStr + location['poi'][i]['value'] + ',';
                    }
                    locationIdsStr = locationIdsStr.replace(/,$/, "");
                    return locationIdsStr;
                } else {
                    return location['poi'][0]['value'];
                }
            }
        } else {
            if (key === 'polygon') {
                return location.polygon;
            } else {
                return H.QueryHelper.concatLocationValue(location, key);
            }
        }
    },

    concatLocationValue: function(location, key) {
        if (location[key] === null)
            return null;

        let queryStr = '';
        for (let i=0; i<location[key].length; i++) {
            queryStr = queryStr + location[key][i]['value'] + ',';
        }
        return queryStr.replace(/,$/, "");
    },

    getUrlQueryParam: function(query, url = window.location.href) {
        query = query.replace(/[[]]/g, "\\$&");
        const regex = new RegExp("[?&]" + query + "(=([^&#]*)|&|#|$)", "i");
        const results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
}

export default QueryHelper;