import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import { setWalletBalanceData, setPageTopLoader } from 'actions';
import { getJwtToken, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export function* getWalletBalanceData(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setPageTopLoader(true));

        let depositAccountId = "", checkAccountId = "";
        switch(action.payload) {
            case "dragonRamen":
                depositAccountId = 'cl9coe8fi0cde1umjgibz141v';
                checkAccountId = 'cl9coe8ft0cdg1umjdzux5org';
                break;
            case "lostarchiveplus":
                depositAccountId = 'cleo05zpl0040agpjb0kl8c1k';
                checkAccountId = 'cleo05zpl003zagpj81c9du9z';
                break;
            case "roguerollrulers":
                depositAccountId = 'cljqp852nq3axj5e3zapskl5';
                checkAccountId = 'cljqp8fgkq3qp3dcq1aodfn7';
                break;
            case "bouncybunny":
                depositAccountId = 'cl0544931675084a54b0529266';
                checkAccountId = 'cl40b1359010464484989d4082';
                break;
            default:
                break;
        }

        const sqlQueryDeposit = {"query": 
        `SELECT *, account.label, wallet_address FROM coin_balance
         JOIN account ON coin_balance.account_id = account.id
         JOIN wallet_address ON coin_balance.account_id = wallet_address.account_id
         WHERE coin_balance.account_id = '${depositAccountId}' LIMIT 1`}
         const sqlQueryCheck = {"query": 
         `SELECT *, account.label, wallet_address FROM coin_balance
          JOIN account ON coin_balance.account_id = account.id
          JOIN wallet_address ON coin_balance.account_id = wallet_address.account_id
          WHERE coin_balance.account_id = '${checkAccountId}' LIMIT 1`}

        const [
            dataDepositTemp,
            dataCheckTemp,
        ] = yield all([
            call(getDataFromSxi, sqlQueryDeposit, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryCheck, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);

        const returnData = [
            {"deposit": dataDepositTemp},
            {"check": dataCheckTemp},
        ]
        yield put(setWalletBalanceData(returnData));
        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_WALLET_BALANCE_DATA, getWalletBalanceData);
}
