import { DATA } from '../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    activeUsersGameOption: [],
    activeUsersGameSelected: [],
    activeUsersSizeOption: {name: "DAU", value: "dau"},
    activeUsersSizeSelected: {name: "DAU", value: "dau"},
    activeUsersCountryOption: [{name: "全て", value:"all"}],
    activeUsersCountrySelected: [],
    activeUsersRidOption: [{name: "全て", value:"all"}],
    activeUsersRidSelected: [],
    activeUsersData: [
        {
            id: 'nftdep',
            data: [],
            date: [],
            avg: 0,
        },
        {
            id: 'jobtribes',
            data: [],
            date: [],
            avg: 0,
        },
        {
            id: 'puzzle',
            data: [],
            date: [],
            avg: 0,
        },
        {
            id: 'luckyfarmer',
            data: [],
            date: [],
            avg: 0,
        },
    ],
    activeUsersDataByGame: [],
    activeUsersUniqueTotalData: [
        {
            id: 'playMining',
            uniqueTotal: 0,
            total: 0,
        },
        {
            id: 'nftdep',
            uniqueTotal: 0,
        },
        {
            id: 'jobtribes',
            uniqueTotal: 0,
        },
        {
            id: 'puzzle',
            uniqueTotal: 0,
        },
        {
            id: 'luckyfarmer',
            uniqueTotal: 0,
        },
    ],
    activeUsersUniquePaidData: 0,
    activeUsersDateRange: [startDate, endDate],
    activeUsersDateDiff: 7,
    countryLoading: false,
    ridLoading: false,
    allPmMenuSelect: false,
};

export default function activeUsersReducer(state = initialState, action) {
    switch (action.type) {
        case DATA.SET_ACTIVE_USERS_UNIQUE_TOTAL_DATA:
            return {
                ...state,
                activeUsersUniqueTotalData: action.payload
            };
        case DATA.SET_ACTIVE_USERS_UNIQUE_PAID_DATA:
            return {
                ...state,
                activeUsersUniquePaidData: action.payload
            };
        case DATA.SET_ACTIVE_USERS_PAGE_DATA:
            return {
                ...state,
                activeUsersData: action.payload
            };
        case DATA.SET_ACTIVE_USERS_PAGE_DATA_BY_GAME:
            return {
                ...state,
                activeUsersDataByGame: action.payload
            };
        case DATA.SET_ACTIVE_USERS_GAME_OPTION:
            return {
                ...state,
                activeUsersGameOption: action.payload
            };
        case DATA.SET_ACTIVE_USERS_GAME_SELECTED:
        return {
            ...state,
            activeUsersGameSelected: action.payload
        };
        case DATA.SET_ACTIVE_USERS_SIZE_OPTION:
            return {
                ...state,
                activeUsersSizeOption: action.payload
            };
        case DATA.SET_ACTIVE_USERS_SIZE_SELECTED:
            return {
                ...state,
                activeUsersSizeSelected: action.payload
            };
        case DATA.SET_ACTIVE_USERS_DATE_RANGE:
            return {
                ...state,
                activeUsersDateRange: action.payload
            };
        case DATA.SET_ACTIVE_USERS_DATE_DIFF:
            return {
                ...state,
                activeUsersDateDiff: action.payload
            };
        case DATA.RESET_ACTIVE_USERS_GAME_SELECTED:
            return {
                ...state,
                activeUsersGameSelected: [],
                activeUsersRidSelected: [],
            };
        case DATA.SET_ACTIVE_USERS_COUNTRY_LOADING:
            return {
                ...state,
                countryLoading: action.payload
            };
        case DATA.SET_ACTIVE_USERS_COUNTRY_OPTION:
            return {
                ...state,
                activeUsersCountryOption: action.payload
            };
        case DATA.SET_ACTIVE_USERS_COUNTRY_SELECTED:
            return {
                ...state,
                activeUsersCountrySelected: action.payload
            };
        case DATA.SET_ACTIVE_USERS_RID_OPTION:
            return {
                ...state,
                activeUsersRidOption: action.payload
            };
        case DATA.SET_ACTIVE_USERS_RID_SELECTED:
            return {
                ...state,
                activeUsersRidSelected: action.payload
            };
        case DATA.SET_ACTIVE_USERS_RID_LOADING:
            return {
                ...state,
                ridLoading: action.payload
            };
        case DATA.SET_ALL_PM_MENU_SELECT:
            return {
                ...state,
                allPmMenuSelect: action.payload
            };
        default:
            return state
    }
};
