import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, TextField, Button, Grid, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { setCurrentPage, setNftDepTrendOption, setNftDepTrendOptionSelected, getNftDepTrendPageData, getTrendTxKinds,
    setNftDepTrendDataCountrySelected, getNftDepTrendDataCountry, getNftDepTrendDataGames, setNftDepTrendDataGameSelected } from 'actions';
import * as C from 'components';
import * as V from 'styles/variables';
import * as H from 'helper';
import ChartConfig from 'utils/ChartConfig';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NftDepTrend = ({
    trendOption,
    trendOptionSelected,
    trendData,
    trendDataAmount,
    setCurrentPage,
    setNftDepTrendOption,
    setNftDepTrendOptionSelected,
    getTrendTxKinds,
    getNftDepTrendPageData,
    trendDataLoading,
    countryLoading,
    trendDataCountryOption,
    setNftDepTrendDataCountrySelected,
    getNftDepTrendDataCountry,
    trendDataCountrySelected,
    getNftDepTrendDataGames,
    trendDataGameOption,
    trendDataGameSelected,
    setNftDepTrendDataGameSelected,
    trendDataSummary,
}) => {
    const classes = useStyles();
    const [btnStateDataFetch, setBtnState] = useState(true);
    const [chartState, setChartState] = useState(false);
    const [chartData, setChartData] = useState([]);

    const handleChangeOption = (data) => {
        let optionSelectedArray = [];
        _.forEach(data, function(item, i) {
            optionSelectedArray.push(item.value)
        });
        setNftDepTrendDataGameSelected(data === [] ? [] : data);
        getNftDepTrendPageData();
    }

    const handleChangeCountry = (data) => {
        setNftDepTrendDataCountrySelected(data === [] ? [] : data);
        getNftDepTrendPageData();
    }

    useEffect(() => {
        setCurrentPage(window.location.pathname);
        // getTrendTxKinds();
        getNftDepTrendPageData();
        getNftDepTrendDataCountry();
        getNftDepTrendDataGames();
    }, []);

    useEffect(() => {
        // combine data
        const dataCombined = trendData.concat(trendDataAmount);
        setChartData(dataCombined);
        setChartState(true);
    }, [trendData, trendDataAmount]);

    useEffect(() => {
        // if (trendOptionSelected.length == 0) {
        //     setBtnState(true);
        // } else {
        //     getNftDepTrendDataCountry();
        //     setBtnState(false);
        // }
    }, [trendOptionSelected]);

    const getAveValue = (amount, count) => {
        let dataToReturn = amount/count;
        dataToReturn = dataToReturn.toFixed(0);
        return H.FormatNumStrHelper.formatCommaThousand(dataToReturn);
      };

    const fetchData = () => {
        getNftDepTrendPageData();
        getNftDepTrendDataCountry();
        getNftDepTrendDataGames();
        setChartState(true);
        //setBtnState(!btnStateDataFetch);
    }

    return (
        <C.ContentWrapper>
            <div className={classes.filterSection}>
            <Grid container spacing={0} direction="row" justifyContent="space-between">
                <Grid item>
                    <Grid container spacing={0} direction="row">
                        <Grid item>
                            <Autocomplete
                                multiple
                                limitTags={3}
                                size="small"
                                id="select-game"
                                value={trendDataGameSelected}
                                options={trendDataGameOption}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(option, { selected }) => (
                                    <Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                    </Fragment>
                                )}
                                onChange={(e, newValue) => {
                                    handleChangeOption(newValue);
                                }}
                                style={{ minWidth: 300 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="ゲーム" placeholder={trendDataGameSelected.length === 0 ? "選択してください" : ""} />
                                )}
                                className={classes.autoSelect}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                multiple
                                limitTags={3}
                                size="small"
                                id="select-country"
                                value={trendDataCountrySelected}
                                style={{ minWidth: 300 }}
                                options={trendDataCountryOption}
                                getOptionLabel={(option) => option.value}
                                // defaultValue={trendDataCountryOption[0]}
                                disableClearable={false}
                                loading={countryLoading}
                                renderOption={(option, { selected }) => (
                                    <Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                    </Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params} variant="standard" label="国" placeholder={trendDataCountrySelected.length === 0 ? "全て" : ""}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                            <Fragment>
                                                {countryLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                            ),
                                        }}
                                    />
                                )}
                                onChange={(e, newValue) => {
                                    handleChangeCountry(newValue);
                                }}
                                // renderInput={(params) => (
                                //     <TextField {...params} label="国" placeholder={trendOption.length === 0 ? "選択してください" : ""} />
                                // )}
                                className={classes.autoSelect}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={0} direction="row">
                        <Grid item>
                            <C.CalendarRangeOnly
                                setBtnState={setBtnState}
                                page="nftDepTrend"
                            />
                        </Grid>
                        <Grid>
                        <Button 
                            //disabled={btnStateDataFetch} 
                            className={classes.dataBtn} 
                            variant="contained" 
                            color="primary" 
                            onClick={() => fetchData()}>
                            GO
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </div>

            <Grid
                container
                spacing={1}
                direction="row"
                className={classes.summarySection}
            >
                <Grid item sm={12} lg={4} className={classes.totalBox}>
                1次DEP取引<br />
                件数: {trendDataSummary.count1st === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.count1st)}&nbsp;&nbsp;|
                &nbsp;&nbsp;DEP: {trendDataSummary.total1st === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.total1st)}&nbsp;&nbsp;|
                &nbsp;&nbsp;ユニーク: {trendDataSummary.unique1st === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.unique1st)}&nbsp;&nbsp;|
                &nbsp;&nbsp;ユニークDEP平均: {trendDataSummary.total1st === '-' ? "-" : getAveValue(trendDataSummary.total1st, trendDataSummary.unique1st)}
                </Grid>
                <Grid item sm={12} lg={4} className={classes.totalBox}>
                1次FIAT取引<br />
                件数: {trendDataSummary.countFiat === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.countFiat)}&nbsp;&nbsp;|
                &nbsp;&nbsp;USD: {trendDataSummary.totalFiat === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.totalFiat)}&nbsp;&nbsp;|
                &nbsp;&nbsp;ユニーク: {trendDataSummary.uniqueFiat === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.uniqueFiat)}&nbsp;&nbsp;|
                &nbsp;&nbsp;ユニーク USD平均: USD{trendDataSummary.totalFiat === '-' || trendDataSummary.totalFiat == 0 ? "-" : getAveValue(trendDataSummary.totalFiat, trendDataSummary.uniqueFiat)}
                </Grid>                
                <Grid item sm={12} lg={4} className={classes.totalBox}>
                2次取引<br />
                件数: {trendDataSummary.count2nd === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.count2nd)}&nbsp;&nbsp;|
                &nbsp;&nbsp;DEP: {trendDataSummary.total2nd === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.total2nd)}&nbsp;&nbsp;|
                &nbsp;&nbsp;ユニーク: {trendDataSummary.unique2nd === '-' ? '-' : H.FormatNumStrHelper.formatCommaThousand(trendDataSummary.unique2nd)}&nbsp;&nbsp;|
                &nbsp;&nbsp;ユニークDEP平均: {trendDataSummary.total2nd === '-' ? "-" : getAveValue(trendDataSummary.total2nd, trendDataSummary.unique2nd)}
                </Grid>
            </Grid>

            <Grid container spacing={1} className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={12}>
                {trendDataLoading 
                    ? <div className={classes.loaderBox}>
                        <img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} />
                      </div> 
                    : chartState
                        ? chartData !== undefined && chartData[0]['data'].length > 0
                          ? <C.LineChartNftDepTrend
                                legend={ChartConfig.legendNftDepTrend}
                                lblSaveImage={"DEP取引種別"}
                                xAisData={chartData[0]['date']}
                                seriesData={chartData}
                                chartType={'line'}
                            />
                          : <div className={classes.selectGameTxt}>                              
                              <Chip label="Loading.." style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                            </div>
                        : null
                }
                </Grid>
            </Grid>
            <div className={classes.activeUsersTable}>
                <C.NftDepTrendTable />
            </div>
        </C.ContentWrapper>
    );
};

NftDepTrend.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    trendOption: state.nftDepTrend.trendOption,
    trendOptionSelected: state.nftDepTrend.trendOptionSelected,
    trendData: state.nftDepTrend.trendData,
    trendDataAmount: state.nftDepTrend.trendDataAmount,
    trendDataLoading: state.nftDepTrend.trendDataLoading,
    countryLoading: state.nftDepTrend.countryLoading,
    trendDataCountryOption: state.nftDepTrend.trendDataCountryOption,
    trendDataCountrySelected: state.nftDepTrend.trendDataCountrySelected,
    trendDataGameOption: state.nftDepTrend.trendDataGameOption,
    trendDataGameSelected: state.nftDepTrend.trendDataGameSelected,
    trendDataSummary: state.nftDepTrend.trendDataSummary,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    setNftDepTrendOption: (param) => dispatch(setNftDepTrendOption(param)),
    setNftDepTrendOptionSelected: (param) => dispatch(setNftDepTrendOptionSelected(param)),
    getNftDepTrendPageData: () => dispatch(getNftDepTrendPageData()),
    getTrendTxKinds: () => dispatch(getTrendTxKinds()),
    setNftDepTrendDataCountrySelected: (param) => dispatch(setNftDepTrendDataCountrySelected(param)),
    getNftDepTrendDataCountry: () => dispatch(getNftDepTrendDataCountry()),
    getNftDepTrendDataGames: () => dispatch(getNftDepTrendDataGames()),
    setNftDepTrendDataGameSelected: (param) => dispatch(setNftDepTrendDataGameSelected(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(NftDepTrend));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBoxForUnique: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 110,
        },
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        filterSection: {
            display: 'flex',
            backgroundColor: '#fafafa',
            zIndex: 1000,
            paddingTop: 16,
            paddingLeft: 8,
            marginTop: -12,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            color: V.btnLabelColor,
        },
        activeUsersTable: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        lineChart: {

        },
        selectGameForUnique: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 110,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        summarySection: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(5),
            fontSize: 14,
        },
        selectGameTxt: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 280,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
        flexContainerNotFix: {
            display: 'flex',
            overflown: 'hidden',
            width: '100%',
            flexFlow: 'row wrap',
            marginLeft: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBox: {
            alignSelf: 'center',
            paddingLeft: 8,
            paddingRight: 16,
            borderRight: '1px solid #dcdcdc',
        },
        flexItemLeftBoxTotal: {
            fontSize: 14,
            alignSelf: 'top',
            paddingLeft: 0,
            paddingRight: 4,
        },
        monthPicker: {
            marginTop: 11,
            marginLeft: 16,
            width: 120,
        },
        cardTitle: {
            fontSize: 16,
        },
        progressFunnel: {
            marginTop: '160px',
            marginBottom: '160px',
        },
        progress4Funnel: {
            marginTop: '160px',
            marginLeft: '30%',
            marginBottom: '160px',
        },
        funnelLegendWrapper: {
            marginTop: '16px',
            marginBottom: '70px',
        },
        funnelLegendRow: {
            display: 'block',
        },
        funnelLegend: {
            width: '30px',
            height: '10px',
            float: 'left',
            clear: 'both',
            paddingBottom: '8px',
        },
        legendLabel: {
            float: 'left',
            marginLeft: '7px',
            fontSize: '13px',
        },
        legendValue: {
            float: 'right',
            fontSize: '13px',
        },
        colorFunnelTop: {
            backgroundColor: '#46aaee',
        },
        colorFunnelBottom: {
            backgroundColor: '#f53b78',
        },
    }),
);
