import { COOKINBURGER } from '../../constants';
import moment from "moment";

let startDate = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
let endDate = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');

const initialState  = {
    cookinBurgerGamePaidTrendDateLoading: false,
    cookinBurgerGamePaidTrendDateRange: [startDate, endDate],
    //trendData: [],
    gameTrendData: [],
    gameTrendDataUnique: [],
    gameTrendUnqiueCount: [],
    gameTrendDataAmount: [],
};

export default function cookinBurgerGamePaidTrendReducer(state = initialState, action) {
    switch (action.type) {
        case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATA_LOADING:
            return {
                ...state,
                cookinBurgerGamePaidTrendDateLoading: action.payload
            };
        case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATE_RANGE:
            return {
                ...state,
                cookinBurgerGamePaidTrendDateRange: action.payload
            };
        // case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_PAGE_DATA:
        //     return {
        //         ...state,
        //         trendData: action.payload
        //     };
        case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATA_SALES:
            return {
                ...state,
                gameTrendData: action.payload
            };
        case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_UNIQUE:
            return {
                ...state,
                gameTrendDataUnique: action.payload
            };
        case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_DATA_UNIQUE_COUNT:
            return {
                ...state,
                gameTrendUnqiueCount: action.payload
            };
        case COOKINBURGER.SET_COOKINBURGER_GAME_TREND_AMOUNT:
            return {
                ...state,
                gameTrendDataAmount: action.payload
            };            
        default:
            return state
    }
};
