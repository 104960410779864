import React, { Component } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import * as H from 'helper';

export class ScholarGuildPie extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelColor: '#FFF',
            legendData : [],
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    componentDidMount() {

    }

    magicTypeChanged = (param) => {
        console.log('param:', param);
    }

    getLegendData = (data) => {
        let dataTorReturn = [];
        for (let i=0; i<data.length; i++) {
            dataTorReturn.push(data[i]['name']);
        }
        return dataTorReturn;
    }

    render () {
        const { classes, title, data, height='150px', show=true, suffix, tooltipPosition, lblPercent } = this.props;

        let onEvents = {
            'magictypechanged': this.magicTypeChanged,
        }

        return (
            <div>
                <div className={classes.pieChartTitle}>{title}</div>
                {data[0] === 'loading' ? (
                    <div className={classes.circleProgress}><CircularProgress /></div>
                ) : (
                <ReactEcharts
                    option={{
                        grid: {
                            left: 0,
                            top: -20,
                            right: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'item',
                            position: tooltipPosition,
                            show: show,
                            formatter: function (data) {
                                return `${data.name}: <br />
                                ${H.FormatNumStrHelper.formatCommaThousand(data.value)} ${suffix} (${data.percent}%)`
                            },
                        },
                        series: [
                            {
                                name: title,
                                type: 'pie',
                                radius: ['50%', '65%'],
                                left: 0,
                                top: -20,
                                avoidLabelOverlap: false,
                                label: {
                                    show: true,
                                    position: 'center',
                                    fontSize: 18,
                                    formatter: lblPercent
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '14',
                                        fontWeight: 'normal'
                                    },
                                },
                                labelLine: {
                                    show: false
                                },
                                data: data,
                                hoverOffset: 3,
                            }
                        ],
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{height: height, width: '300px'}}
                />
                )}
            </div>
        )
    }
}

ScholarGuildPie.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ChartStyle, { withTheme: true })(ScholarGuildPie));
