import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setInGameSalesLoading, setInGameItemSalesOverviewData, setInGameItemSalesByCountry, setInGameItemSalesByItem,
    setInGameItemSalesByPmid, setInGameItemSalesByGame, setInGameItemSalesByOccurrence, setPageTopLoader } from 'actions';
import { getJwtToken, getDataFromRDS } from 'api';
import { DATA, ENDPOINT } from '../../constants';
import * as H from 'helper';
import JtcbShopItem from 'utils/JtcbShopItem';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const depPrice = state => state.page.depPrice;
export const loading = state => state.inGameItemSales.loading;
export const dataLoaded = state => state.data.dataLoaded;

export function* getInGameSalesOverview(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ov = true;
        yield put(setInGameSalesLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        // prev
        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');
        const rangeStartPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEndPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const querySalesAmountCount = H.SqlHelper.getInGameSalesInRange({table: 'SxiInGameFiatSalesProd2', range: [rangeStart, rangeEnd], target:contentId});
        const querySalesAmountCountPrev = H.SqlHelper.getInGameSalesInRange({table: 'SxiInGameFiatSalesProd2', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        const querySalesDepAmountCount = H.SqlHelper.getInGameSalesInRange({table: 'SxiInGameDepSalesProd', range: [rangeStart, rangeEnd], target:contentId});
        const querySalesDepAmountCountPrev = H.SqlHelper.getInGameSalesInRange({table: 'SxiInGameDepSalesProd', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        // stats
        const queryFiatStats = H.SqlHelper.getInGameSalesStatsInRange({table: 'SxiInGameFiatSalesProd2', range: [rangeStart, rangeEnd], target:contentId});
        const queryFiatStatsPrev = H.SqlHelper.getInGameSalesStatsInRange({table: 'SxiInGameFiatSalesProd2', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        let queryDepStats = H.SqlHelper.getInGameSalesStatsInRange({table: 'SxiInGameDepSalesProd', range: [rangeStart, rangeEnd], target:contentId});
        let queryDepStatsPrev = H.SqlHelper.getInGameSalesStatsInRange({table: 'SxiInGameDepSalesProd', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        // fiat first time
        const queryFirstTime = H.SqlHelper.getInGameSalesFirstTimePurchase({table: 'SxiInGameFiatSalesProd2', range: [rangeStart, rangeEnd], target:contentId});
        const queryFirstTimePrev = H.SqlHelper.getInGameSalesFirstTimePurchase({table: 'SxiInGameFiatSalesProd2', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        // dep first time
        let queryDepFirstTime = H.SqlHelper.getInGameSalesFirstTimePurchase({table: 'SxiInGameDepSalesProd', range: [rangeStart, rangeEnd], target:contentId});
        let queryDepFirstTimePrev = H.SqlHelper.getInGameSalesFirstTimePurchase({table: 'SxiInGameDepSalesProd', range: [rangeStartPrev, rangeEndPrev], target:contentId});

        if (gameTarget === "jobtribes" || gameTarget === "ingame-sale") {
            // stats
            queryDepStats = H.SqlHelper.getJtcbInGameSalesStatsInRange({table: 'SxiInGameDepSalesProd', range: [rangeStart, rangeEnd], target:contentId});
            queryDepStatsPrev = H.SqlHelper.getJtcbInGameSalesStatsInRange({table: 'SxiInGameDepSalesProd', range: [rangeStartPrev, rangeEndPrev], target:contentId});
            // first time
            queryDepFirstTime = H.SqlHelper.getJtcbInGameSalesFirstTimePurchase({table: 'SxiInGameDepSalesProd', range: [rangeStart, rangeEnd], target:contentId});
            queryDepFirstTimePrev = H.SqlHelper.getJtcbInGameSalesFirstTimePurchase({table: 'SxiInGameDepSalesProd', range: [rangeStartPrev, rangeEndPrev], target:contentId});
        }

        const [
            dataAmountCount,
            dataAmountCountPrev,
            fiatStats,
            fiatStatsPrev,
            dataFirstTime,
            dataFirstTimePrev,
            dataDepAmountCount,
            dataDepAmountCountPrev,
            depStats,
            depStatsPrev,
            dataDepFirstTime,
            dataDepFirstTimePrev,
        ] = yield all([
            call(getDataFromRDS, querySalesAmountCount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesAmountCountPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryFiatStats, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryFiatStatsPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryFirstTime, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryFirstTimePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesDepAmountCount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesDepAmountCountPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryDepStats, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryDepStatsPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryDepFirstTime, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, queryDepFirstTimePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        // jtcb shop item data fetch
        let jtcbShopItemSalesAmount = 0, jtcbShopItemSalesCount = 0, jtcbShopItemSalesCountUni = 0;
        let jtcbShopItemSalesAmountPrev = 0, jtcbShopItemSalesCountPrev = 0, jtcbShopItemSalesCountUniPrev = 0;
        if (gameTarget === "jobtribes") {
            const queryShopSalesDepAmountCount = H.SqlHelper.getJtcbInGameDepSalesInRange({table: 'SxiInGameJtcbShopSalesProd', range: [rangeStart, rangeEnd], target:contentId});
            const queryShopSalesDepAmountCountPrev = H.SqlHelper.getJtcbInGameDepSalesInRange({table: 'SxiInGameJtcbShopSalesProd', range: [rangeStartPrev, rangeEndPrev], target:contentId});
            const [
                tempShopSalesAmountCount,
                tempShopSalesAmountCountPrev,
            ] = yield all([
                call(getDataFromRDS, queryShopSalesDepAmountCount, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, queryShopSalesDepAmountCountPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            const dataShopSalesAmountCount = JSON.parse(tempShopSalesAmountCount.body);
            const dataShopSalesAmountCountPrev = JSON.parse(tempShopSalesAmountCountPrev.body);
            if (dataShopSalesAmountCount[0][0]["stringValue"] !== undefined) {
                jtcbShopItemSalesAmount = Number(dataShopSalesAmountCount[0][0]["stringValue"]);
                jtcbShopItemSalesCount = dataShopSalesAmountCount[0][1]["longValue"];
                jtcbShopItemSalesCountUni = dataShopSalesAmountCount[0][2]["longValue"];
            }
            if (dataShopSalesAmountCountPrev[0][0]["stringValue"] !== undefined) {
                jtcbShopItemSalesAmountPrev = Number(dataShopSalesAmountCountPrev[0][0]["stringValue"]);
                jtcbShopItemSalesCountPrev = dataShopSalesAmountCountPrev[0][1]["longValue"];
                jtcbShopItemSalesCountUniPrev = dataShopSalesAmountCountPrev[0][2]["longValue"];
            }
        }

        const dataAmountCountReturn = JSON.parse(dataAmountCount.body);
        const dataAmountCountPrevReturn = JSON.parse(dataAmountCountPrev.body);
        const dataFiatStats = JSON.parse(fiatStats.body);
        const dataFiatStatsPrev = JSON.parse(fiatStatsPrev.body);
        const dataFirstTimeReturn = JSON.parse(dataFirstTime.body);
        const dataFirstTimePrevReturn = JSON.parse(dataFirstTimePrev.body);
        const dataDepFirstTimeReturn = JSON.parse(dataDepFirstTime.body);
        const dataDepFirstTimePrevReturn = JSON.parse(dataDepFirstTimePrev.body);

        const dataDepAmountCountReturn = JSON.parse(dataDepAmountCount.body);
        const dataDepAmountCountPrevReturn = JSON.parse(dataDepAmountCountPrev.body);
        const dataDepStats = JSON.parse(depStats.body);
        const dataDepStatsPrev = JSON.parse(depStatsPrev.body);

        // Fiat
        const fiatAmountValue = dataAmountCountReturn[0][0]['isNull'] ? 0 : Number(dataAmountCountReturn[0][0]['stringValue']);
        const fiatCountValue = dataAmountCountReturn[0][1]['longValue'];
        const dataUniCntValue = dataAmountCountReturn[0][2]['longValue'];

        // DEP
        let depAmountValue = dataDepAmountCountReturn[0][0]['isNull'] ? 0 : Number(dataDepAmountCountReturn[0][0]['stringValue']);
        let depCountValue = dataDepAmountCountReturn[0][1]['longValue'];
        let dataDepUniCntValue = dataDepAmountCountReturn[0][2]['longValue'];
        if (jtcbShopItemSalesAmount > 0) {
            depAmountValue = jtcbShopItemSalesAmount;
            depCountValue = jtcbShopItemSalesCount;
            dataDepUniCntValue = jtcbShopItemSalesCountUni;
        }

        // fiat prev
        const fiatAmountValuePrev = dataAmountCountPrevReturn[0][0]['isNull'] ? 0 : Number(dataAmountCountPrevReturn[0][0]['stringValue']);
        const fiatCountValuePrev = dataAmountCountPrevReturn[0][1]['longValue'];
        const dataUniCntValuePrev = dataAmountCountPrevReturn[0][2]['longValue'];

        // dep prev
        let depAmountValuePrev = dataDepAmountCountPrevReturn[0][0]['isNull'] ? 0 : Number(dataDepAmountCountPrevReturn[0][0]['stringValue']);
        let depCountValuePrev = dataDepAmountCountPrevReturn[0][1]['longValue'];
        let dataDepUniCntValuePrev = dataDepAmountCountPrevReturn[0][2]['longValue'];
        if (jtcbShopItemSalesAmountPrev > 0) {
            depAmountValuePrev = jtcbShopItemSalesAmountPrev;
            depCountValuePrev = jtcbShopItemSalesCountPrev;
            dataDepUniCntValuePrev = jtcbShopItemSalesCountUniPrev;
        }

        // stats data
        const fiatTxStats = [
            {
                title: "件数",
                type: "max",
                current: fiatCountValue,
                prev: fiatCountValuePrev,
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: dataUniCntValue,
                prev: dataUniCntValuePrev,
            },
            {
                title: "最大値",
                type: "max",
                current: fiatCountValue > 0 ? Number(dataFiatStats[0][0]['stringValue']) : 0,
                prev: fiatCountValuePrev > 0 ? Number(dataFiatStatsPrev[0][0]['stringValue']) : 0,
            },
            {
                title: "最小値",
                type: "min",
                current: fiatCountValue > 0 ? Number(dataFiatStats[0][1]['stringValue']) : 0,
                prev: fiatCountValuePrev > 0 ? Number(dataFiatStatsPrev[0][1]['stringValue']) : 0,
            },
            {
                title: "平均値",
                type: "avg",
                current: fiatCountValue > 0 ? Number(dataFiatStats[0][2]['stringValue']) : 0,
                prev: fiatCountValuePrev > 0 ? Number(dataFiatStatsPrev[0][2]['stringValue']) : 0,
            },
        ];

        const depTxStats = [
            {
                title: "件数",
                type: "max",
                current: depCountValue,
                prev: depCountValuePrev,
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: dataDepUniCntValue,
                prev: dataDepUniCntValuePrev,
            },
            {
                title: "最大値",
                type: "max",
                current: depCountValue > 0 ? Number(dataDepStats[0][0]['stringValue']) : 0,
                prev: depCountValuePrev > 0 ? Number(dataDepStatsPrev[0][0]['stringValue']) : 0,
            },
            {
                title: "最小値",
                type: "min",
                current: depCountValue > 0 ? Number(dataDepStats[0][1]['stringValue']) : 0,
                prev: depCountValuePrev > 0 ? Number(dataDepStatsPrev[0][1]['stringValue']) : 0,
            },
            {
                title: "平均値",
                type: "avg",
                current: depCountValue > 0 ? Number(dataDepStats[0][2]['stringValue']) : 0,
                prev: depCountValuePrev > 0 ? Number(dataDepStatsPrev[0][2]['stringValue']) : 0,
            },
        ];

        // first time purchaser
        let currentSum = 0, prevSum = 0, fiatSum = 0, depSum = 0;
        const firstTimePurchaseCountValue = {
            current : currentSum + dataFirstTimeReturn[0][0]["longValue"] + dataDepFirstTimeReturn[0][0]["longValue"],
            prev: prevSum + dataFirstTimePrevReturn[0][0]["longValue"] + dataDepFirstTimePrevReturn[0][0]["longValue"],
            fiat: fiatSum + dataFirstTimeReturn[0][0]["longValue"],
            dep: dataDepFirstTimeReturn[0][0]["longValue"],
        };

        const overviewSalesValue = {
            amountTotal: fiatAmountValue + depAmountValue,
            amountTotalPrev: fiatAmountValuePrev + depAmountValuePrev,
            countTotal: fiatCountValue + depCountValue,
            countTotalPrev: fiatCountValuePrev + depCountValuePrev,
            fiatAmount: fiatAmountValue,
            fiatCount: fiatCountValue,
            fiatUnique: dataUniCntValue,
            fiatAmountPrev: fiatAmountValuePrev,
            fiatCountPrev: fiatCountValuePrev,
            fiatUniquePrev: dataUniCntValuePrev,
            depAmount: depAmountValue,
            depCount: depCountValue,
            depUnique: dataDepUniCntValue,
            depAmountPrev: depAmountValuePrev,
            depCountPrev: depCountValuePrev,
            depUniquePrev: dataDepUniCntValuePrev,
            fiatTxStats: fiatTxStats,
            depTxStats: depTxStats,
            firstTimePurchase: firstTimePurchaseCountValue
        };

        yield put(setInGameItemSalesOverviewData(overviewSalesValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ov = false;
        yield put(setInGameSalesLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

export function* getInGameSalesByCountry(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByCountry = true;
        yield put(setInGameSalesLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        // query
        const querySalesAmountByCountryFiat = H.SqlHelper.getInGameItemSalesByCoutry({table: 'SxiInGameFiatSalesProd2', data: 'SUM(total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        const querySalesCountByCountryFiat = H.SqlHelper.getInGameItemSalesByCoutry({table: 'SxiInGameFiatSalesProd2', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        let querySalesAmountByCountryDep = H.SqlHelper.getInGameItemSalesByCoutry({table: 'SxiInGameDepSalesProd', data: 'SUM(total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        let querySalesCountByCountryDep = H.SqlHelper.getInGameItemSalesByCoutry({table: 'SxiInGameDepSalesProd', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        if (gameTarget === "jobtribes" || gameTarget === "ingame-sale") {
            querySalesAmountByCountryDep = H.SqlHelper.getJtcbInGameItemSalesByCoutry({table: 'SxiInGameDepSalesProd', data: 'SUM(TC.total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
            querySalesCountByCountryDep = H.SqlHelper.getJtcbInGameItemSalesByCoutry({table: 'SxiInGameDepSalesProd', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        }

        const [
            dataAmountFiat,
            dataCountFiat,
            dataAmountDep,
            dataCountDep,
        ] = yield all([
            call(getDataFromRDS, querySalesAmountByCountryFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByCountryFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesAmountByCountryDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByCountryDep, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataAmtCountryReturn = JSON.parse(dataAmountFiat.body);
        const dataCntCountryReturn = JSON.parse(dataCountFiat.body);
        const dataDepAmtCountryReturn = JSON.parse(dataAmountDep.body);
        const dataDepCntCountryReturn = JSON.parse(dataCountDep.body);

        // Fiat amount
        let returnFiatAmountDataArray = [], returnFiatCountDataArray = [], dataFiatByCountry = {};
        _.each(dataAmtCountryReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataFiatByCountry.country = data[1]['stringValue'];
                dataFiatByCountry.value = Number(data[0]['stringValue']);
                returnFiatAmountDataArray.push(dataFiatByCountry);
                dataFiatByCountry = {};
            }
        });

        // Fiat count
        _.each(dataCntCountryReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataFiatByCountry.country = data[1]['stringValue'];
                dataFiatByCountry.value = Number(data[0]['longValue']);
                returnFiatCountDataArray.push(dataFiatByCountry);
                dataFiatByCountry = {};
            }
        });

        // dep amount
        let returnDepAmountDataArray = [], returnDepCountDataArray = [], dataDepByCountry = {};
        _.each(dataDepAmtCountryReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataDepByCountry.country = data[1]['stringValue'];
                dataDepByCountry.value = Number(data[0]['stringValue']);
                returnDepAmountDataArray.push(dataDepByCountry);
                dataDepByCountry = {};
            }
        });

        // dep count
        _.each(dataDepCntCountryReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataDepByCountry.country = data[1]['stringValue'];
                dataDepByCountry.value = Number(data[0]['longValue']);
                returnDepCountDataArray.push(dataDepByCountry);
                dataDepByCountry = {};
            }
        });

        const txValue = {
            amount: {
                fiat: returnFiatAmountDataArray,
                dep: returnDepAmountDataArray,
            },
            count: {
                fiat: returnFiatCountDataArray,
                dep: returnDepCountDataArray,
            },
        }
        yield put(setInGameItemSalesByCountry(txValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByCountry = false;
        yield put(setInGameSalesLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getInGameSalesByItem(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByItem = true;
        yield put(setInGameSalesLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        // query
        const querySalesAmountByFiat = H.SqlHelper.getInGameItemSalesByItem({table: 'SxiInGameFiatSalesProd2', data: 'SUM(total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        const querySalesCountByFiat = H.SqlHelper.getInGameItemSalesByItem({table: 'SxiInGameFiatSalesProd2', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        const querySalesAmountByDep = H.SqlHelper.getInGameItemSalesByItem({table: 'SxiInGameDepSalesProd', data: 'SUM(total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        const querySalesCountByDep = H.SqlHelper.getInGameItemSalesByItem({table: 'SxiInGameDepSalesProd', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});

        const [
            dataAmountFiat,
            dataCountFiat,
            dataAmountDep,
            dataCountDep,
        ] = yield all([
            call(getDataFromRDS, querySalesAmountByFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesAmountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataAmtReturn = JSON.parse(dataAmountFiat.body);
        const dataCntReturn = JSON.parse(dataCountFiat.body);
        const dataDepAmtReturn = JSON.parse(dataAmountDep.body);
        const dataDepCntReturn = JSON.parse(dataCountDep.body);

        // Fiat amount
        let returnFiatAmountDataArray = [], returnFiatCountDataArray = [], dataFiatByItem = {};
        _.each(dataAmtReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataFiatByItem.country = data[1]['stringValue'];
                dataFiatByItem.value = Number(data[0]['stringValue']);
                dataFiatByItem.contents = H.ContentsHelper.getContentsNameById(data[2]['stringValue']);
                returnFiatAmountDataArray.push(dataFiatByItem);
                dataFiatByItem = {};
            }
        });

        // Fiat count
        _.each(dataCntReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataFiatByItem.country = data[1]['stringValue'];
                dataFiatByItem.value = Number(data[0]['longValue']);
                dataFiatByItem.contents = H.ContentsHelper.getContentsNameById(data[2]['stringValue']);
                returnFiatCountDataArray.push(dataFiatByItem);
                dataFiatByItem = {};
            }
        });

        // dep amount
        let returnDepAmountDataArray = [], returnDepCountDataArray = [], dataDepByItem = {};
        _.each(dataDepAmtReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataDepByItem.country = data[1]['stringValue'];
                dataDepByItem.value = Number(data[0]['stringValue']);
                dataDepByItem.contents = H.ContentsHelper.getContentsNameById(data[2]['stringValue']);
                returnDepAmountDataArray.push(dataDepByItem);
                dataDepByItem = {};
            }
        });

        // dep count
        _.each(dataDepCntReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataDepByItem.country = data[1]['stringValue'];
                dataDepByItem.value = Number(data[0]['longValue']);
                dataDepByItem.contents = H.ContentsHelper.getContentsNameById(data[2]['stringValue']);
                returnDepCountDataArray.push(dataDepByItem);
                dataDepByItem = {};
            }
        });

        // jtcb item data fetch
        let sortedAmountArray = [], sortedCountArray = [];
        if (gameTarget === "jobtribes" || gameTarget === "ingame-sale") {
            const querySalesPastAmountByDep = H.SqlHelper.getJtcbInGameItemSalesByItem({table: 'SxiInGameJtcbShopSalesProd', data: 'SUM(total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
            const querySalesPastCountByDep = H.SqlHelper.getJtcbInGameItemSalesByItem({table: 'SxiInGameJtcbShopSalesProd', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});

            const [
                dataShopAmountDep,
                dataShopCountDep,
            ] = yield all([
                call(getDataFromRDS, querySalesPastAmountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
                call(getDataFromRDS, querySalesPastCountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            ]);
            const dataShopDepAmtReturn = JSON.parse(dataShopAmountDep.body);
            const dataShopDepCntReturn = JSON.parse(dataShopCountDep.body);

            // format item id to label value
            if (dataShopDepCntReturn.length > 0) {
                let returnDepShopAmountDataArray = [], returnDepShopCountDataArray = [];
                // amount
                dataDepByItem = {};
                _.each(dataShopDepAmtReturn, function(data, index) {
                    if (data['country'] !== null && data['country'] !== "") {
                        dataDepByItem.country = JtcbShopItem.itemLabel[data[1]['stringValue']] !== undefined ? JtcbShopItem.itemLabel[data[1]['stringValue']] : `item id: ${data[1]['stringValue']}`;
                        dataDepByItem.value = Number(data[0]['stringValue']);
                        dataDepByItem.contents = H.ContentsHelper.getContentsNameById(data[2]['stringValue']);
                        returnDepShopAmountDataArray.push(dataDepByItem);
                        dataDepByItem = {};
                    }
                });

                // count
                _.each(dataShopDepCntReturn, function(data, index) {
                    if (data['country'] !== null && data['country'] !== "") {
                        dataDepByItem.country = JtcbShopItem.itemLabel[data[1]['stringValue']] !== undefined ? JtcbShopItem.itemLabel[data[1]['stringValue']] : `item id: ${data[1]['stringValue']}`;
                        dataDepByItem.value = Number(data[0]['longValue']);
                        dataDepByItem.contents = H.ContentsHelper.getContentsNameById(data[2]['stringValue']);
                        returnDepShopCountDataArray.push(dataDepByItem);
                        dataDepByItem = {};
                    }
                });

                const combinedAmountArray = [...returnDepAmountDataArray, ...returnDepShopAmountDataArray].reduce((result, current) => {
                    const existingItem = result.find(item => item.country === current.country);
                    if (existingItem) {
                        // If same item exists, combine the values
                        existingItem.value += current.value;
                    } else {
                        // If same item doesn't exist, add it to the result
                        result.push({ ...current });
                    }
                    return result;
                    }, []);
                    sortedAmountArray = combinedAmountArray.sort((a, b) => b.value - a.value);

                const combinedCountArray = [...returnDepCountDataArray, ...returnDepShopCountDataArray].reduce((result, current) => {
                    const existingItem = result.find(item => item.country === current.country);
                    if (existingItem) {
                        // If same item exists, combine the values
                        existingItem.value += current.value;
                    } else {
                        // If same item doesn't exist, add it to the result
                        result.push({ ...current });
                    }
                    return result;
                    }, []);
                    sortedCountArray = combinedCountArray.sort((a, b) => b.value - a.value);
            }
        }

        const txValue = {
            amount: {
                fiat: returnFiatAmountDataArray,
                dep: sortedAmountArray.length > 0 ? sortedAmountArray : returnDepAmountDataArray,
            },
            count: {
                fiat: returnFiatCountDataArray,
                dep: sortedCountArray.length > 0 ? sortedCountArray : returnDepCountDataArray,
            },
        }
        yield put(setInGameItemSalesByItem(txValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByItem = false;
        yield put(setInGameSalesLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getInGameSalesByPmid(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByPmid = true;
        yield put(setInGameSalesLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        // query
        const querySalesAmountByFiat = H.SqlHelper.getInGameItemSalesBy({table: 'SxiInGameFiatSalesProd2', data: 'SUM(total_amount)', column: 'pmId', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
        const querySalesCountByFiat = H.SqlHelper.getInGameItemSalesBy({table: 'SxiInGameFiatSalesProd2', data: 'COUNT(*)', column: 'pmId', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
        let querySalesAmountByDep = H.SqlHelper.getInGameItemSalesBy({table: 'SxiInGameDepSalesProd', data: 'SUM(total_amount)', column: 'pmId', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
        let querySalesCountByDep = H.SqlHelper.getInGameItemSalesBy({table: 'SxiInGameDepSalesProd', data: 'COUNT(*)', column: 'pmId', range: [rangeStart, rangeEnd], target:contentId, limit: 20});

        if (gameTarget === "jobtribes" || gameTarget === "ingame-sale") {
            querySalesAmountByDep = H.SqlHelper.getJtcbInGameItemSalesBy({table: 'SxiInGameDepSalesProd', data: 'SUM(TC.total_amount)', column: 'TC.pmId', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
            querySalesCountByDep = H.SqlHelper.getJtcbInGameItemSalesBy({table: 'SxiInGameDepSalesProd', data: 'COUNT(*)', column: 'TC.pmId', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
        }

        const [
            dataAmountFiat,
            dataCountFiat,
            dataAmountDep,
            dataCountDep,
        ] = yield all([
            call(getDataFromRDS, querySalesAmountByFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesAmountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataAmtReturn = JSON.parse(dataAmountFiat.body);
        const dataCntReturn = JSON.parse(dataCountFiat.body);
        const dataDepAmtReturn = JSON.parse(dataAmountDep.body);
        const dataDepCntReturn = JSON.parse(dataCountDep.body);

        // Fiat amount
        let returnFiatAmountDataArray = [], returnFiatCountDataArray = [], dataFiatByItem = {};
        _.each(dataAmtReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataFiatByItem.pmid = data[1]['stringValue'];
                dataFiatByItem.country = data[2]['stringValue'];
                dataFiatByItem.value = Number(data[0]['stringValue']);
                dataFiatByItem.tx = data[3]['longValue'];
                returnFiatAmountDataArray.push(dataFiatByItem);
                dataFiatByItem = {};
            }
        });

        // Fiat count
        _.each(dataCntReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataFiatByItem.pmid = data[1]['stringValue'];
                dataFiatByItem.country = data[2]['stringValue'];
                dataFiatByItem.value = Number(data[0]['longValue']);
                returnFiatCountDataArray.push(dataFiatByItem);
                dataFiatByItem = {};
            }
        });

        // dep amount
        let returnDepAmountDataArray = [], returnDepCountDataArray = [], dataDepByItem = {};
        _.each(dataDepAmtReturn, function(data, index) {
            if (data['country'] !== null && data['country'] !== "") {
                dataDepByItem.pmid = data[1]['stringValue'];
                dataDepByItem.country = data[2]['stringValue'];
                dataDepByItem.value = Number(data[0]['stringValue']);
                dataDepByItem.tx = data[3]['longValue'];
                returnDepAmountDataArray.push(dataDepByItem);
                dataDepByItem = {};
            }
        });

        // dep count
        _.each(dataDepCntReturn, function(data, index) {
            dataDepByItem.pmid = data[1]['stringValue'];
            dataDepByItem.country = data[2]['stringValue'];
            dataDepByItem.value = Number(data[0]['longValue']);
            returnDepCountDataArray.push(dataDepByItem);
            dataDepByItem = {};
        });

        const txValue = {
            amount: {
                fiat: returnFiatAmountDataArray,
                dep: returnDepAmountDataArray,
            },
            count: {
                fiat: returnFiatCountDataArray,
                dep: returnDepCountDataArray,
            },
        }
        yield put(setInGameItemSalesByPmid(txValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByPmid = false;
        yield put(setInGameSalesLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getInGameSalesByGame(action) {
    try {
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovByGame = true;
        yield put(setInGameSalesLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const contentId = gameTarget === "ingame-sale" ? undefined : H.ContentsHelper.getContentsIdByName(gameTarget);

        // query
        const querySalesAmountByFiat = H.SqlHelper.getInGameItemSalesByGame({table: 'SxiInGameFiatSalesProd2', data: 'SUM(total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
        const querySalesCountByFiat = H.SqlHelper.getInGameItemSalesByGame({table: 'SxiInGameFiatSalesProd2', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 20});
        const querySalesAmountByDep = H.SqlHelper.getInGameItemDepSalesByGame({table: 'SxiInGameDepSalesProd', data: 'SUM(TC.total_amount)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});
        const querySalesCountByDep = H.SqlHelper.getInGameItemDepSalesByGame({table: 'SxiInGameDepSalesProd', data: 'COUNT(*)', range: [rangeStart, rangeEnd], target:contentId, limit: 50});

        const [
            dataAmountFiat,
            dataCountFiat,
            dataAmountDep,
            dataCountDep,
        ] = yield all([
            call(getDataFromRDS, querySalesAmountByFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByFiat, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesAmountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, querySalesCountByDep, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const dataAmtReturn = JSON.parse(dataAmountFiat.body);
        const dataCntReturn = JSON.parse(dataCountFiat.body);
        const dataDepAmtReturn = JSON.parse(dataAmountDep.body);
        const dataDepCntReturn = JSON.parse(dataCountDep.body);

        // Fiat amount
        let returnFiatAmountDataArray = [], returnFiatCountDataArray = [], dataFiatByItem = {};
        _.each(dataAmtReturn, function(data, index) {
            if (data[2]['longValue'] > 0) {
                dataFiatByItem.value = Number(data[0]['stringValue']);
                dataFiatByItem.title = H.ContentsHelper.getContentsNameById(data[1]['stringValue']);
                returnFiatAmountDataArray.push(dataFiatByItem);
                dataFiatByItem = {};
            }
        });

        // Fiat count
        _.each(dataCntReturn, function(data, index) {
            if (data[0]['longValue'] > 0) {
                dataFiatByItem.value = Number(data[0]['longValue']);
                dataFiatByItem.title = H.ContentsHelper.getContentsNameById(data[1]['stringValue']);
                returnFiatCountDataArray.push(dataFiatByItem);
                dataFiatByItem = {};
            }
        });

        // dep amount
        let returnDepAmountDataArray = [], returnDepCountDataArray = [], dataDepByItem = {};
        _.each(dataDepAmtReturn, function(data, index) {
            dataDepByItem.value = Number(data[0]['stringValue']);
            dataDepByItem.title = H.ContentsHelper.getContentsNameById(data[1]['stringValue']);
            returnDepAmountDataArray.push(dataDepByItem);
            dataDepByItem = {};
        });

        // dep count
        _.each(dataDepCntReturn, function(data, index) {
            dataDepByItem.value = Number(data[0]['longValue']);
            dataDepByItem.title = H.ContentsHelper.getContentsNameById(data[1]['stringValue']);
            returnDepCountDataArray.push(dataDepByItem);
            dataDepByItem = {};
        });

        const txValue = {
            amount: {
                fiat: returnFiatAmountDataArray,
                dep: returnDepAmountDataArray,
            },
            count: {
                fiat: returnFiatCountDataArray,
                dep: returnDepCountDataArray,
            },
        }
        yield put(setInGameItemSalesByGame(txValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovByGame = false;
        yield put(setInGameSalesLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export function* getInGameSalesByOccurrence(action) {
    try {
        yield put(setPageTopLoader(true));
        const jwtToken = yield call(getJwtToken);
        const gameTarget = action.payload;

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

        // 1st time
        let sqlQueryKaisu1Trend = {
            query: `
            SELECT
                DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Tokyo')) AS date_only,
                COUNT(*) AS first_time_appearance_count
            FROM (
                SELECT
                    pmId,
                    MIN(logDate) AS first_occurrence_date
                FROM SxiInGameDepSalesProd
                WHERE
                    CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                    AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                    AND contents_id = '${contentId}'
                GROUP BY pmId
            ) AS first_occurrences
            WHERE
                DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
            GROUP BY date_only
            ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        // 2nd time
        let sqlQueryKaisu2Trend = {
            // query: `
            //     SELECT
            //         DATE(CONVERT_TZ(second_occurrence_date, 'UTC', 'Asia/Tokyo')) AS date_only,
            //         COUNT(*) AS second_time_appearance_count
            //     FROM (
            //         SELECT
            //             pmId,
            //             MIN(logDate) AS first_occurrence_date,
            //             CASE WHEN COUNT(*) = 2 THEN MIN(logDate) ELSE NULL END AS second_occurrence_date
            //         FROM SxiInGameDepSalesProd
            //         WHERE
            //             CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
            //             AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
            //             AND contents_id = '${contentId}'
            //         GROUP BY pmId
            //     ) AS occurrences
            //     WHERE
            //     second_occurrence_date IS NOT NULL AND
            //         DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
            //     GROUP BY date_only
            //     ORDER BY date_only;
            // `,
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        pmId,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            pmId,
                            logDate,
                            @row_num := IF(@current_denchu = pmId, @row_num + 1, 1) AS row_num,
                            @current_denchu := pmId
                        FROM
                            SxiInGameDepSalesProd,
                            (SELECT @row_num := 0, @current_denchu := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            pmId, logDate
                    ) AS ranked
                    WHERE
                        row_num = 2
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        let sqlQueryKaisu3Trend = {
            // query: `
            //     SELECT
            //         DATE(CONVERT_TZ(appearance_count, 'UTC', 'Asia/Tokyo')) AS date_only,
            //         COUNT(*) AS first_time_appearance_count
            //     FROM (
            //         SELECT
            //             pmId,
            //             MIN(logDate) AS first_occurrence_date,
            //             CASE WHEN COUNT(*) = 3 THEN MIN(logDate) ELSE NULL END AS appearance_count
            //         FROM SxiInGameDepSalesProd
            //         WHERE
            //             CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
            //             AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
            //             AND contents_id = '${contentId}'
            //         GROUP BY pmId
            //     ) AS occurrences
            //     WHERE
            //         appearance_count IS NOT NULL AND
            //         DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
            //     GROUP BY date_only
            //     ORDER BY date_only;
            // `,
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        pmId,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            pmId,
                            logDate,
                            @row_num := IF(@current_denchu = pmId, @row_num + 1, 1) AS row_num,
                            @current_denchu := pmId
                        FROM
                            SxiInGameDepSalesProd,
                            (SELECT @row_num := 0, @current_denchu := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            pmId, logDate
                    ) AS ranked
                    WHERE
                        row_num = 3
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        let sqlQueryKaisu4Trend = {
            // query: `
            //     SELECT
            //         DATE(CONVERT_TZ(appearance_count, 'UTC', 'Asia/Tokyo')) AS date_only,
            //         COUNT(*) AS first_time_appearance_count
            //     FROM (
            //         SELECT
            //             pmId,
            //             MIN(logDate) AS first_occurrence_date,
            //             CASE WHEN COUNT(*) = 4 THEN MIN(logDate) ELSE NULL END AS appearance_count
            //         FROM SxiInGameDepSalesProd
            //         WHERE
            //             CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
            //             AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
            //             AND contents_id = '${contentId}'
            //         GROUP BY pmId
            //     ) AS occurrences
            //     WHERE
            //         appearance_count IS NOT NULL AND
            //         DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
            //     GROUP BY date_only
            //     ORDER BY date_only;
            // `,
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        pmId,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            pmId,
                            logDate,
                            @row_num := IF(@current_denchu = pmId, @row_num + 1, 1) AS row_num,
                            @current_denchu := pmId
                        FROM
                            SxiInGameDepSalesProd,
                            (SELECT @row_num := 0, @current_denchu := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            pmId, logDate
                    ) AS ranked
                    WHERE
                        row_num = 4
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        let sqlQueryKaisu5Trend = {
            // query: `
            //     SELECT
            //         DATE(CONVERT_TZ(appearance_count, 'UTC', 'Asia/Tokyo')) AS date_only,
            //         COUNT(*) AS first_time_appearance_count
            //     FROM (
            //         SELECT
            //             pmId,
            //             MIN(logDate) AS first_occurrence_date,
            //             CASE WHEN COUNT(*) > 4 THEN MIN(logDate) ELSE NULL END AS appearance_count
            //         FROM SxiInGameDepSalesProd
            //         WHERE
            //             CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
            //             AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
            //             AND contents_id = '${contentId}'
            //         GROUP BY pmId
            //     ) AS occurrences
            //     WHERE
            //         appearance_count IS NOT NULL AND
            //         DATE(CONVERT_TZ(first_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
            //     GROUP BY date_only
            //     ORDER BY date_only;
            // `,
            query: `
                SELECT
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) AS date_only,
                    COUNT(*) AS nth_time_appearance_count
                FROM (
                    SELECT
                        pmId,
                        logDate AS nth_occurrence_date
                    FROM (
                        SELECT
                            pmId,
                            logDate,
                            @row_num := IF(@current_denchu = pmId, @row_num + 1, 1) AS row_num,
                            @current_denchu := pmId
                        FROM
                            SxiInGameDepSalesProd,
                            (SELECT @row_num := 0, @current_denchu := '') AS vars
                        WHERE
                            CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') >= '2023-06-30 00:00:00'
                            AND CONVERT_TZ(logDate, 'UTC', 'Asia/Singapore') <= '${dateTo} 23:59:59'
                            AND contents_id = '${contentId}'
                        ORDER BY
                            pmId, logDate
                    ) AS ranked
                    WHERE
                        row_num > 4
                ) AS second_occurrences
                WHERE
                    DATE(CONVERT_TZ(nth_occurrence_date, 'UTC', 'Asia/Singapore')) BETWEEN '${dateFrom}' AND '${dateTo}'
                GROUP BY date_only
                ORDER BY date_only;
            `,
            database: "KpiDashboard",
            type: "list",
        };

        const [dataTempKaisu1, dataTempKaisu2, dataTempKaisu3, dataTempKaisu4, dataTempKaisu5Plus] = yield all([
            call(getDataFromRDS, sqlQueryKaisu1Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu2Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu3Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu4Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
            call(getDataFromRDS, sqlQueryKaisu5Trend, ENDPOINT.GET_DATA_USERS, jwtToken),
        ]);
        const dataKaisuTrend1 = JSON.parse(dataTempKaisu1.body);
        const dataKaisuTrend2 = JSON.parse(dataTempKaisu2.body);
        const dataKaisuTrend3 = JSON.parse(dataTempKaisu3.body);
        const dataKaisuTrend4 = JSON.parse(dataTempKaisu4.body);
        const dataKaisuTrend5 = JSON.parse(dataTempKaisu5Plus.body);

        // aggregate data
        // Set xAxisDate
        let xAxisDate = [], dataFirstArray = [], dataTwoArray = [], dataThreeArray = [], dataFourArray = [], dataFiveArray = [];
        const daysArray = H.FormatDateHelper.getDaysListBetweenDays(dateFrom, dateTo)
        _.each(daysArray, function(data) {
            xAxisDate.push(data);
        });

        xAxisDate.forEach(date => {
            // 1st time
            const data1st = _.find(dataKaisuTrend1, item => _.get(item, '[0].stringValue') === date);
            if (data1st) {
                dataFirstArray.push(_.get(data1st, '[1].longValue'));
            } else {
                dataFirstArray.push(0);
            }

            // 2 times
            const dataTwo = _.find(dataKaisuTrend2, item => _.get(item, '[0].stringValue') === date);
            if (dataTwo) {
                dataTwoArray.push(_.get(dataTwo, '[1].longValue'));
            } else {
                dataTwoArray.push(0);
            }

            // 3 times
            const dataThree = _.find(dataKaisuTrend3, item => _.get(item, '[0].stringValue') === date);
            if (dataThree) {
                dataThreeArray.push(_.get(dataThree, '[1].longValue'));
            } else {
                dataThreeArray.push(0);
            }

            // 4 times
            const dataFour = _.find(dataKaisuTrend4, item => _.get(item, '[0].stringValue') === date);
            if (dataFour) {
                dataFourArray.push(_.get(dataFour, '[1].longValue'));
            } else {
                dataFourArray.push(0);
            }

            // 5 times
            const dataFive = _.find(dataKaisuTrend5, item => _.get(item, '[0].stringValue') === date);
            if (dataFive) {
                dataFiveArray.push(_.get(dataFive, '[1].longValue'));
            } else {
                dataFiveArray.push(0);
            }
        });

        const countData = {
            date: xAxisDate,
            series: [
              dataFirstArray,
              dataTwoArray,
              dataThreeArray,
              dataFourArray,
              dataFiveArray,
            ],
        };
        const recordArray = [countData];
        yield put(setInGameItemSalesByOccurrence(recordArray));
        yield put(setPageTopLoader(false));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(DATA.GET_IN_GAME_SALES_DATA_OVERVIEW, getInGameSalesOverview);
    yield takeEvery(DATA.GET_INGAME_SALES_DATA_BY_COUNTRY, getInGameSalesByCountry);
    yield takeEvery(DATA.GET_INGAME_SALES_DATA_BY_ITEM, getInGameSalesByItem);
    yield takeEvery(DATA.GET_INGAME_SALES_DATA_BY_PMID, getInGameSalesByPmid);
    yield takeEvery(DATA.GET_INGAME_SALES_DATA_BY_GAME, getInGameSalesByGame);
    yield takeEvery(DATA.GET_INGAME_SALES_DATA_BY_OCCURRENCE, getInGameSalesByOccurrence)
}
