import { put, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setInGameSalesTrendLoading,
  setInGameItemSalesTrendDataCount,
  setInGameItemSalesTrendDataAmount,
  setInGameItemSalesTrendItemsData,
  setInGameItemSalesTrendDataCountUnique,
  setInGameItemDepSalesTrendDataCount,
  setInGameItemDepSalesTrendDataAmount,
  setInGameItemDepSalesTrendDataCountUnique,
  setInGameItemSalesTrendDataCountByItem,
  setInGameItemSalesTrendDataAmountByItem,
} from "actions";
import { getJwtToken, getDataFromRDS } from "api";
import { DATA, ENDPOINT } from "../../constants";
import * as H from "helper";

export const currentPage = (state) => state.page.currentPage;
export const startDate = (state) => state.page.dateRange[0];
export const endDate = (state) => state.page.dateRange[1];
export const startPrevDate = (state) => state.page.prevDateRange[0];
export const endPrevDate = (state) => state.page.prevDateRange[1];
export const depPrice = (state) => state.page.depPrice;
export const loading = (state) => state.inGameItemSalesTrend.loading;
export const dataLoaded = (state) => state.data.dataLoaded;
export const itemSelected = (state) => state.inGameItemSalesTrend.itemSelected;
export const typeSelected = (state) => state.inGameItemSalesTrend.typeSelected;

export function* getInGameItemSalesTrendDataChart(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    const gameTarget = action.payload;
    const loadingStateBegin = yield select(loading);
    let loadingUpdate = { ...loadingStateBegin };
    loadingUpdate.chart = true;
    yield put(setPageTopLoader(true));
    yield put(setInGameSalesTrendLoading(loadingUpdate));

    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    dateTo = moment(dateTo).format("YYYY-MM-DD");
    const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(
      `${dateFrom}T00:00:00.000Z`,
      "YYYY-MM-DD HH:mm:ss",
      "Asia/Singapore"
    );
    const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(
      `${dateTo}T23:59:59.999Z`,
      "YYYY-MM-DD HH:mm:ss",
      "Asia/Singapore"
    );
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);
    let allQueryArray = [], uniqueQueryArray = [];
    let allDepQueryArray = [], uniqueDepQueryArray = [];

    // fiat
    const querySalesCountTrend = H.SqlHelper.getInGameItemSalesCountTrend({
      table: "SxiInGameFiatSalesProd2",
      range: [rangeStart, rangeEnd],
      target: contentId,
    });
    const querySalesAmountTrend = H.SqlHelper.getInGameItemSalesAmountTrend({
      table: "SxiInGameFiatSalesProd2",
      range: [rangeStart, rangeEnd],
      target: contentId,
    });
    const querySalesCountTrendUnique= H.SqlHelper.getInGameItemSalesCountTrend({
      table: "SxiInGameFiatSalesProd2",
      range: [rangeStart, rangeEnd],
      target: contentId,
      unique: true,
    });

    // dep
    const querySalesCountTrendDep = H.SqlHelper.getInGameItemSalesCountTrend({
      table: "SxiInGameDepSalesProd",
      range: [rangeStart, rangeEnd],
      target: contentId,
    });
    const querySalesAmountTrendDep = H.SqlHelper.getInGameItemSalesAmountTrend({
      table: "SxiInGameDepSalesProd",
      range: [rangeStart, rangeEnd],
      target: contentId,
    });
    const querySalesCountTrendUniqueDep = H.SqlHelper.getInGameItemSalesCountTrend({
      table: "SxiInGameDepSalesProd",
      range: [rangeStart, rangeEnd],
      target: contentId,
      unique: true,
    });

    // fiat
    allQueryArray = [
      call(
        getDataFromRDS,
        querySalesCountTrend,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        querySalesAmountTrend,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ];

    uniqueQueryArray = [
      call(
        getDataFromRDS,
        querySalesCountTrendUnique,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ];

    // dep
    allDepQueryArray = [
      call(
        getDataFromRDS,
        querySalesCountTrendDep,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        querySalesAmountTrendDep,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),      
    ];

    uniqueDepQueryArray = [
      call(
        getDataFromRDS,
        querySalesCountTrendUniqueDep,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ];

    const gameItemSelected = yield select(itemSelected);
    const gameTypeSelected = yield select(typeSelected);
    if (gameItemSelected.length > 0) {
      const itemCommaSeparted = "'" + gameItemSelected.join("', '") + "'"
      _.forEach(gameItemSelected, function(item, i) {
        let tableValue = "SxiInGameFiatSalesProd2";
        switch (gameTypeSelected) {
          case "dep":
            tableValue = "SxiInGameDepSalesProd";
            break;
          default:
        }

        // count
        let sqlQueryItemCountTrend2 = gameTypeSelected !== "all" 
        ? H.SqlHelper.getInGameItemSalesCountTrendByItem({
            table: tableValue,
            range: [rangeStart, rangeEnd],
            target: contentId,
            item: itemCommaSeparted
          })
        : H.SqlHelper.getInGameItemSalesCountTrendByItemFiatAndDep({
          range: [rangeStart, rangeEnd],
          target: contentId,
          item: itemCommaSeparted
        });
        allQueryArray.push(call(getDataFromRDS, sqlQueryItemCountTrend2, ENDPOINT.GET_DATA_RDS, jwtToken));

        // amount
        let sqlQueryItemAmountTrend2 = gameTypeSelected !== "all" 
        ? H.SqlHelper.getInGameItemSalesAmountTrendByItem({
          table: tableValue,
          range: [rangeStart, rangeEnd],
          target: contentId,
          item: itemCommaSeparted
        })
        : H.SqlHelper.getInGameItemSalesAmountTrendByItemFiatAndDep({
          range: [rangeStart, rangeEnd],
          target: contentId,
          item: itemCommaSeparted
        });
        allQueryArray.push(call(getDataFromRDS, sqlQueryItemAmountTrend2, ENDPOINT.GET_DATA_RDS, jwtToken));
      });
    }

    let [dataTemp1c, dataTemp1a, dataTemp2c, dataTemp2a, dataTemp3c, dataTemp3a,
         dataTemp4c, dataTemp4a, dataTemp5c, dataTemp5a, dataTemp6c, dataTemp6a] = yield all(allQueryArray);

    const dataCountTrend = JSON.parse(dataTemp1c.body);
    const dataAmountTrend = JSON.parse(dataTemp1a.body);

    const [dataTempCountTrendUni] = yield all(uniqueQueryArray);
    const dataCountTrendUni = JSON.parse(dataTempCountTrendUni.body);

    // dep
    let [dataTemp1cDep, dataTemp1aDep] = yield all(allDepQueryArray);
    const dataCountTrendDep = JSON.parse(dataTemp1cDep.body);
    const dataAmountTrendDep = JSON.parse(dataTemp1aDep.body);

    // dep unique
    const [dataTempCountTrendUniDep] = yield all(uniqueDepQueryArray);
    const dataCountTrendUniDep = JSON.parse(dataTempCountTrendUniDep.body);

    // calendar date range
    const range = moment.range(dateFrom, dateTo);
    const days = Array.from(range.by("days"));
    const dateArray = days.map((m) => m.format("YYYY-MM-DD"));

    let fiatItemCountArray = [],
      fiatItemAmountArray = [],
      inGameItemSalesCountData = [],
      inGameItemSalesAmountData = [],
      foundIndex = -1,
      inGameItemSalesCountDataUnique = [];

    _.forEach(dateArray, function (date, i) {
      const exists = dataCountTrend.some((subArray) =>
        subArray.some((item) => item.stringValue === date)
      );
      if (exists) {
        dataCountTrend.some((subArray, subIndex) => {
          const index = subArray.findIndex((item) => item.stringValue === date);
          if (index !== -1) {
            foundIndex = [subIndex, index];
            return true;
          }
          return false;
        });
        fiatItemCountArray.push(dataCountTrend[foundIndex[0]][1]["longValue"]);
        fiatItemAmountArray.push(
          Number(dataAmountTrend[foundIndex[0]][1]["stringValue"])
        );
      } else {
        fiatItemCountArray.push(0);
        fiatItemAmountArray.push(0);
      }
    });

    inGameItemSalesCountData.push({
      id: "ingame-item-fiat-count1",
      data: fiatItemCountArray,
      date: dateArray,
    });

    inGameItemSalesAmountData.push({
      id: "ingame-item-fiat-amount1",
      data: fiatItemAmountArray,
      date: dateArray,
    });

    // dep
    let depItemCountArray = [],
    depItemAmountArray = [],
    inGameItemSalesCountDataDep = [],
    inGameItemSalesAmountDataDep = [],
    foundIndexDep = -1,
    inGameItemSalesCountDataUniqueDep = [];

    _.forEach(dateArray, function (date, i) {
      const existsDep = dataCountTrendDep.some((subArray) =>
        subArray.some((item) => item.stringValue === date)
      );

      if (existsDep) {
        dataCountTrendDep.some((subArray, subIndex) => {
          const index = subArray.findIndex((item) => item.stringValue === date);
          if (index !== -1) {
            foundIndexDep = [subIndex, index];
            return true;
          }
          return false;
        });
        depItemCountArray.push(dataCountTrendDep[foundIndexDep[0]][1]["longValue"]);
        depItemAmountArray.push(
          Number(dataAmountTrendDep[foundIndexDep[0]][1]["stringValue"])
        );
      } else {
        depItemCountArray.push(0);
        depItemAmountArray.push(0);
      }
    });

    inGameItemSalesCountDataDep.push({
      id: "ingame-item-dep-count1",
      data: depItemCountArray,
      date: dateArray,
    });

    inGameItemSalesAmountDataDep.push({
      id: "ingame-item-dep-amount1",
      data: depItemAmountArray,
      date: dateArray,
    });

    // unique fiat count
    const dataCountTrendUnique = [];
    _.forEach(dateArray, function (date, i) {
      const exists = dataCountTrendUni.some((subArray) =>
        subArray.some((item) => item.stringValue === date)
      );
      if (exists) {
        dataCountTrendUni.some((subArray, subIndex) => {
          const index = subArray.findIndex((item) => item.stringValue === date);
          if (index !== -1) {
            foundIndex = [subIndex, index];
            return true;
          }
          return false;
        });
        dataCountTrendUnique.push(dataCountTrendUni[foundIndex[0]][1]["longValue"]);
      } else {
        dataCountTrendUnique.push(0);
      }
    });
    inGameItemSalesCountDataUnique.push({
      id: "ingame-item-fiat-count-unique",
      data: dataCountTrendUnique,
      date: dateArray,
    });

    // unique dep count
    const dataCountTrendUniqueDep = [];
    _.forEach(dateArray, function (date, i) {
      const exists = dataCountTrendUniDep.some((subArray) =>
        subArray.some((item) => item.stringValue === date)
      );
      if (exists) {
        dataCountTrendUniDep.some((subArray, subIndex) => {
          const index = subArray.findIndex((item) => item.stringValue === date);
          if (index !== -1) {
            foundIndex = [subIndex, index];
            return true;
          }
          return false;
        });
        dataCountTrendUniqueDep.push(dataCountTrendUniDep[foundIndex[0]][1]["longValue"]);
      } else {
        dataCountTrendUniqueDep.push(0);
      }
    });
    inGameItemSalesCountDataUniqueDep.push({
      id: "ingame-item-dep-count-unique",
      data: dataCountTrendUniqueDep,
      date: dateArray,
    });

    // fiat
    yield put(setInGameItemSalesTrendDataCount(inGameItemSalesCountData));
    yield put(setInGameItemSalesTrendDataAmount(inGameItemSalesAmountData));
    yield put(setInGameItemSalesTrendDataCountUnique(inGameItemSalesCountDataUnique));

    // dep
    yield put(setInGameItemDepSalesTrendDataCount(inGameItemSalesCountDataDep));
    yield put(setInGameItemDepSalesTrendDataAmount(inGameItemSalesAmountDataDep));
    yield put(setInGameItemDepSalesTrendDataCountUnique(inGameItemSalesCountDataUniqueDep));

    // by item
    // per item data aggregation
    let inGameItemSalesCountDataByItem = [],
        inGameItemSalesAmountDataByItem = [];

    if (dataTemp2c !== undefined) {
      let fiatItemCountArray2 = [],
      fiatItemAmountArray2 = []
      const dataCountTrend2 = JSON.parse(dataTemp2c.body);
      const dataAmountTrend2 = JSON.parse(dataTemp2a.body);

      _.forEach(dateArray, function (date, i) {
        const exists = dataCountTrend2.some((subArray) =>
          subArray.some((item) => item.stringValue === date)
        );
        if (exists) {
          dataCountTrend2.some((subArray, subIndex) => {
            const index = subArray.findIndex((item) => item.stringValue === date);
            if (index !== -1) {
              foundIndex = [subIndex, index];
              return true;
            }
            return false;
          });
          fiatItemCountArray2.push(dataCountTrend2[foundIndex[0]][1]["longValue"]);
          fiatItemAmountArray2.push(
            Number(dataAmountTrend2[foundIndex[0]][1]["stringValue"])
          );
        } else {
          fiatItemCountArray2.push(0);
          fiatItemAmountArray2.push(0);
        }
      });

      inGameItemSalesCountDataByItem.push({
        id: "ingame-item-fiat-count2",
        data: fiatItemCountArray2,
        date: dateArray,
      });
  
      inGameItemSalesAmountDataByItem.push({
        id: "ingame-item-fiat-amount2",
        data: fiatItemAmountArray2,
        date: dateArray,
      });
    };

    if (dataTemp3c !== undefined) {
      let fiatItemCountArray3 = [],
      fiatItemAmountArray3 = []
      const dataCountTrend3 = JSON.parse(dataTemp3c.body);
      const dataAmountTrend3 = JSON.parse(dataTemp3a.body);

      _.forEach(dateArray, function (date, i) {
        const exists = dataCountTrend3.some((subArray) =>
          subArray.some((item) => item.stringValue === date)
        );
        if (exists) {
          dataCountTrend3.some((subArray, subIndex) => {
            const index = subArray.findIndex((item) => item.stringValue === date);
            if (index !== -1) {
              foundIndex = [subIndex, index];
              return true;
            }
            return false;
          });
          fiatItemCountArray3.push(dataCountTrend3[foundIndex[0]][1]["longValue"]);
          fiatItemAmountArray3.push(
            Number(dataAmountTrend3[foundIndex[0]][1]["stringValue"])
          );
        } else {
          fiatItemCountArray3.push(0);
          fiatItemAmountArray3.push(0);
        }
      });

      inGameItemSalesCountDataByItem.push({
        id: "ingame-item-fiat-count3",
        data: fiatItemCountArray3,
        date: dateArray,
      });
  
      inGameItemSalesAmountDataByItem.push({
        id: "ingame-item-fiat-amount3",
        data: fiatItemAmountArray3,
        date: dateArray,
      });
    };

    if (dataTemp4c !== undefined) {
      let fiatItemCountArray4 = [],
      fiatItemAmountArray4 = []
      const dataCountTrend4 = JSON.parse(dataTemp4c.body);
      const dataAmountTrend4 = JSON.parse(dataTemp4a.body);

      _.forEach(dateArray, function (date, i) {
        const exists = dataCountTrend4.some((subArray) =>
          subArray.some((item) => item.stringValue === date)
        );
        if (exists) {
          dataCountTrend4.some((subArray, subIndex) => {
            const index = subArray.findIndex((item) => item.stringValue === date);
            if (index !== -1) {
              foundIndex = [subIndex, index];
              return true;
            }
            return false;
          });
          fiatItemCountArray4.push(dataCountTrend4[foundIndex[0]][1]["longValue"]);
          fiatItemAmountArray4.push(
            Number(dataAmountTrend4[foundIndex[0]][1]["stringValue"])
          );
        } else {
          fiatItemCountArray4.push(0);
          fiatItemAmountArray4.push(0);
        }
      });

      inGameItemSalesCountDataByItem.push({
        id: "ingame-item-fiat-count4",
        data: fiatItemCountArray4,
        date: dateArray,
      });
  
      inGameItemSalesAmountDataByItem.push({
        id: "ingame-item-fiat-amount4",
        data: fiatItemAmountArray4,
        date: dateArray,
      });
    };

    if (dataTemp5c !== undefined) {
      let fiatItemCountArray5 = [],
      fiatItemAmountArray5 = []
      const dataCountTrend5 = JSON.parse(dataTemp5c.body);
      const dataAmountTrend5 = JSON.parse(dataTemp5a.body);

      _.forEach(dateArray, function (date, i) {
        const exists = dataCountTrend5.some((subArray) =>
          subArray.some((item) => item.stringValue === date)
        );
        if (exists) {
          dataCountTrend5.some((subArray, subIndex) => {
            const index = subArray.findIndex((item) => item.stringValue === date);
            if (index !== -1) {
              foundIndex = [subIndex, index];
              return true;
            }
            return false;
          });
          fiatItemCountArray5.push(dataCountTrend5[foundIndex[0]][1]["longValue"]);
          fiatItemAmountArray5.push(
            Number(dataAmountTrend5[foundIndex[0]][1]["stringValue"])
          );
        } else {
          fiatItemCountArray5.push(0);
          fiatItemAmountArray5.push(0);
        }
      });

      inGameItemSalesCountDataByItem.push({
        id: "ingame-item-fiat-count5",
        data: fiatItemCountArray5,
        date: dateArray,
      });
  
      inGameItemSalesAmountDataByItem.push({
        id: "ingame-item-fiat-amount5",
        data: fiatItemAmountArray5,
        date: dateArray,
      });
    };

    if (dataTemp6c !== undefined) {
      let fiatItemCountArray6 = [],
      fiatItemAmountArray6 = []
      const dataCountTrend6 = JSON.parse(dataTemp6c.body);
      const dataAmountTrend6 = JSON.parse(dataTemp6a.body);

      _.forEach(dateArray, function (date, i) {
        const exists = dataCountTrend6.some((subArray) =>
          subArray.some((item) => item.stringValue === date)
        );
        if (exists) {
          dataCountTrend6.some((subArray, subIndex) => {
            const index = subArray.findIndex((item) => item.stringValue === date);
            if (index !== -1) {
              foundIndex = [subIndex, index];
              return true;
            }
            return false;
          });
          fiatItemCountArray6.push(dataCountTrend6[foundIndex[0]][1]["longValue"]);
          fiatItemAmountArray6.push(
            Number(dataAmountTrend6[foundIndex[0]][1]["stringValue"])
          );
        } else {
          fiatItemCountArray6.push(0);
          fiatItemAmountArray6.push(0);
        }
      });

      inGameItemSalesCountDataByItem.push({
        id: "ingame-item-fiat-count6",
        data: fiatItemCountArray6,
        date: dateArray,
      });
  
      inGameItemSalesAmountDataByItem.push({
        id: "ingame-item-fiat-amount6",
        data: fiatItemAmountArray6,
        date: dateArray,
      });
    };

    yield put(setInGameItemSalesTrendDataCountByItem(inGameItemSalesCountDataByItem));
    yield put(setInGameItemSalesTrendDataAmountByItem(inGameItemSalesAmountDataByItem));

    // update the loading state
    const loadingStateEnd = yield select(loading);
    let loadingUpdate2 = { ...loadingStateEnd };
    loadingUpdate2.chart = false;
    yield put(setInGameSalesTrendLoading(loadingUpdate2));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getInGameItemSalesTrendDataTable(action) {
  try {
    const jwtToken = yield call(getJwtToken);
    const gameTarget = action.payload;
    const loadingStateBegin = yield select(loading);
    let loadingUpdate = { ...loadingStateBegin };
    loadingUpdate.table = true;
    yield put(setPageTopLoader(loadingUpdate.table));
    yield put(setInGameSalesTrendLoading(loadingUpdate));

    let dateFrom = yield select(startDate);
    let dateTo = yield select(endDate);
    dateFrom = moment(dateFrom).format("YYYY-MM-DD");
    dateTo = moment(dateTo).format("YYYY-MM-DD");
    const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(
      `${dateFrom}T00:00:00.000Z`,
      "YYYY-MM-DD HH:mm:ss",
      "Asia/Singapore"
    );
    const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(
      `${dateTo}T23:59:59.999Z`,
      "YYYY-MM-DD HH:mm:ss",
      "Asia/Singapore"
    );
    const contentId = H.ContentsHelper.getContentsIdByName(gameTarget);

    // fiat query
    const querySalesAmountByCountryFiat =
      H.SqlHelper.getInGameItemSalesByItem({
        table: "SxiInGameFiatSalesProd2",
        data: "SUM(total_amount)",
        range: [rangeStart, rangeEnd],
        target: contentId,
        limit: 1000,
      });
    const querySalesCountByCountryFiat = H.SqlHelper.getInGameItemSalesByItem(
      {
        table: "SxiInGameFiatSalesProd2",
        data: "COUNT(*)",
        range: [rangeStart, rangeEnd],
        target: contentId,
        limit: 1000,
      }
    );
    // dep query
    const querySalesAmountByCountryDep = H.SqlHelper.getInGameItemSalesByItem({
      table: "SxiInGameDepSalesProd",
      data: "SUM(total_amount)",
      range: [rangeStart, rangeEnd],
      target: contentId,
      limit: 1000,
    });
    const querySalesCountByCountryDep = H.SqlHelper.getInGameItemSalesByItem(
      {
        table: "SxiInGameDepSalesProd",
        data: "COUNT(*)",
        range: [rangeStart, rangeEnd],
        target: contentId,
        limit: 1000,
      }
    );
    // both query
    const querySalesAmountByCountryFiatAndDep = H.SqlHelper.getInGameSalesByFiatAndDep({
      data: "SUM(total_amount)",
      range: [rangeStart, rangeEnd],
      target: contentId,
      limit: 1000,
    });

    const [
      dataAmountFiat,
      dataCountFiat,
      dataAmountDep,
      dataCountDep,
      dataAmountFiatDep,
    ] = yield all([
      call(
        getDataFromRDS,
        querySalesAmountByCountryFiat,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        querySalesCountByCountryFiat,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        querySalesAmountByCountryDep,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        querySalesCountByCountryDep,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
      call(
        getDataFromRDS,
        querySalesAmountByCountryFiatAndDep,
        ENDPOINT.GET_DATA_RDS,
        jwtToken
      ),
    ]);

    const dataAmtReturn = JSON.parse(dataAmountFiat.body);
    const dataCntReturn = JSON.parse(dataCountFiat.body);
    const dataDepAmtReturn = JSON.parse(dataAmountDep.body);
    const dataDepCntReturn = JSON.parse(dataCountDep.body);
    const dataFiatDepReturn = JSON.parse(dataAmountFiatDep.body);
    // console.log("todo1: combine fiat and dep amt:", dataFiatDepReturn);
    // console.log("todo2: combine fiat and dep count:", dataCntReturn, dataDepCntReturn);

    // Total calc for fiat
    let totalAmount = 0, totalCount = 0;
    _.each(dataAmtReturn, function (data, index) {
        totalAmount = totalAmount+Number(data[0]["stringValue"]);
    });
    _.each(dataCntReturn, function (data, index) {
        totalCount = totalCount+Number(data[0]["longValue"]);
    });

    // Total calc for dep
    let totalAmountDep = 0;
    _.each(dataDepAmtReturn, function (data, index) {
      totalAmountDep = totalAmountDep+Number(data[0]["stringValue"]);
    });

    // both total calc
    let totalBothAmount = 0;
    _.each(dataFiatDepReturn, function (data, index) {
      totalBothAmount = totalBothAmount+Number(data[0]["stringValue"]);
    });

    // Fiat amount
    let returnFiatAmountDataArray = [],
      returnFiatCountDataArray = [],
      dataFiatByItem = {};
    _.each(dataAmtReturn, function (data, index) {
        dataFiatByItem.rank = index+1;
        dataFiatByItem.item = data[1]["stringValue"];
        dataFiatByItem.value = Number(data[0]["stringValue"]);
        dataFiatByItem.ratio = ((Number(data[0]["stringValue"]))/totalAmount*100).toFixed(2);
        returnFiatAmountDataArray.push(dataFiatByItem);
        dataFiatByItem = {};
    });

    // Fiat count
    _.each(dataCntReturn, function (data, index) {
        dataFiatByItem.rank = index+1;
        dataFiatByItem.item = data[1]["stringValue"];
        dataFiatByItem.value = Number(data[0]["longValue"]);
        dataFiatByItem.ratio = Number(data[0]["longValue"])/totalCount*100;
        returnFiatCountDataArray.push(dataFiatByItem);
        dataFiatByItem = {};
    });

    // Dep amount
    let returnDepAmountDataArray = [],
      returnDepCountDataArray = [],
      dataDepByItem = {};
    _.each(dataDepAmtReturn, function (data, index) {
        dataDepByItem.rank = index+1;
        dataDepByItem.item = data[1]["stringValue"];
        dataDepByItem.value = Number(data[0]["stringValue"]);
        dataDepByItem.ratio = ((Number(data[0]["stringValue"]))/totalAmountDep*100).toFixed(2);
        returnDepAmountDataArray.push(dataDepByItem);
        dataDepByItem = {};
    });

    // Dep count
    _.each(dataDepCntReturn, function (data, index) {
      dataDepByItem.rank = index+1;
      dataDepByItem.item = data[1]["stringValue"];
      dataDepByItem.value = Number(data[0]["longValue"]);
      dataDepByItem.ratio = Number(data[0]["longValue"])/totalBothAmount*100;
      returnDepCountDataArray.push(dataDepByItem);
      dataDepByItem = {};
    });

    // both amount
    let returnFiatDepAmountDataArray = [],
    dataFiatDepByItem = {};
    _.each(dataFiatDepReturn, function (data, index) {
      dataFiatDepByItem.rank = index+1;
      dataFiatDepByItem.item = data[1]["stringValue"];
      dataFiatDepByItem.value = Number(data[0]["stringValue"]);
      dataFiatDepByItem.ratio = ((Number(data[0]["stringValue"]))/totalBothAmount*100).toFixed(2);
      returnFiatDepAmountDataArray.push(dataFiatDepByItem);
      dataFiatDepByItem = {};
    });

    const txValue = {
      amount: {
        fiat: returnFiatAmountDataArray,
        dep: returnDepAmountDataArray,
        all: returnFiatDepAmountDataArray,
      },
      count: {
        fiat: returnFiatCountDataArray,
        dep: returnDepCountDataArray,
        all: []
      },
    };
    yield put(setInGameItemSalesTrendItemsData(txValue));

    // update the loading state
    const loadingStateEnd = yield select(loading);
    let loadingUpdate2 = { ...loadingStateEnd };
    loadingUpdate2.table = false;
    yield put(setInGameSalesTrendLoading(loadingUpdate2));
    yield put(setPageTopLoader(loadingUpdate2.table));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(DATA.GET_INGAME_SALES_DATA_TREND,getInGameItemSalesTrendDataChart);
  yield takeEvery(DATA.GET_INGAME_SALES_TREND_DATA_ITEMS, getInGameItemSalesTrendDataTable);
}
