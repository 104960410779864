import React, { useState, useEffect } from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  TextField,
  Button,
  Grid,
  Chip,
  Box,
  Collapse,
  IconButton,
  Typography,
  Paper,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { setTxHistorySearchTerm, setInGameItemOptionSelected, getInGameItemHistoryData, setInGamePageNumber, 
  setInGamePyamentOptionSelected, getInGameItemOptionData, setInGamePmidOptionSelected, getInGamePmidOptionData
} from "actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
    },
    root: {
      //backgroundColor: '#fafafa',
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    filterApplyBtn: {
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(6),
      color: "#FFF",
    },
    toggleButton: {
      width: "33%",
    },
  })
);

const InGameItemTxFilter = ({
  assetOption,
  setTxHistorySearchTerm,
  assetSearchTerm,
  property,
  setInGameItemOptionSelected,
  setInGamePyamentOptionSelected,
  setInGamePmidOptionSelected,
  getInGameItemHistoryData,
  setInGamePageNumber,
  itemOption,
  getInGameItemOptionData,
  itemPmidOption,
  itemPmidSelected,
  getInGamePmidOptionData,
  itemOptionLoading,
  itemPmidOptionLoading,
}) => {
  const classes = useStyles();
  const [applyBtnState, setApplyBtnState] = useState(true);
  const [assetOptions, setAssetOptions] = useState(assetOption);
  const [assetOptionSelected, setAssetOptionSelected] = useState([]);
  const [assetName, setAssetName] = useState({ name: "" });
  const [assetId, setAssetId] = useState({ name: "" });
  const [byPmid, setByPmid] = useState({ name: "" });
  const [targetName, setTargetName] = useState("");
  const [alignment, setAlignment] = useState('both');

  useEffect(() => {
    if (property === "item") {
      setTargetName("アイテム");
    } else {
      setTargetName("ASSET");
    }
  }, []);

  useEffect(() => {
    setInGamePyamentOptionSelected(alignment);
    const game = window.location.pathname.split("/");
    getInGameItemOptionData(game[2]);
    getInGamePmidOptionData(game[2]);
  }, [alignment]);

  useEffect(() => {
    const game = window.location.pathname.split("/");
    getInGamePmidOptionData(game[2]);
  }, [assetOptionSelected]);

  useEffect(() => {
    const game = window.location.pathname.split("/");
    getInGameItemOptionData(game[2]);
  }, [itemPmidSelected]);

  useEffect(() => {
    setAssetOptionSelected([]);
    setInGameItemOptionSelected([]);
  }, [window.location.pathname]);

  const handleChangeOption = (data) => {
    let optionSelectedArray = [];
    _.forEach(data, function(item, i) {
      if (item.name !== undefined) {
        optionSelectedArray.push(item.name);
      } else if (item.pmid !== undefined) {
        optionSelectedArray.push(item.pmid);
      }
    });
    setAssetOptionSelected(optionSelectedArray);
    setInGameItemOptionSelected(optionSelectedArray);
    setApplyBtnState(false);
  };

  const handleChangeOptionPmid = (data) => {
    let pmidSelectedArray = [];
    if (data !== null && data.pmid !== undefined) {
      pmidSelectedArray.push(data.pmid);
    }
    setInGamePmidOptionSelected(pmidSelectedArray);
  };

  const handleInputChange = (data, type) => {
    switch (type) {
      case "asset_name":
        setAssetName({ name: data });
        break;
      case "asset_id":
        setAssetId({ name: data });
        break;
      case "pmid":
        setByPmid({ name: data });
        break;
      default:
    }
    const termObject = {
      type: type,
      value: data,
    };
    let cloneTerm = [...assetSearchTerm];

    const objIndex = cloneTerm.findIndex(
      (termObject) => termObject.type == type
    );
    if (objIndex === -1) {
      cloneTerm.push(termObject);
    } else {
      cloneTerm[objIndex].value = data;
    }

    setTxHistorySearchTerm(cloneTerm);
  };

  const applyFilter = () => {
    setInGamePageNumber(1);
    const game = window.location.pathname.split("/");
    getInGameItemHistoryData({page: 1, target: game[2], item: assetOptionSelected});
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <div style={{fontSize:12, paddingBottom:4}}>支払方法</div>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleAlignment}
            size="small"
            style={{ width: '350px' }}
          >
            <ToggleButton value="both" className={classes.toggleButton}>
              Fiat & DEP
            </ToggleButton>
            <ToggleButton value="fiat" className={classes.toggleButton}>
              Fiat
            </ToggleButton>
            <ToggleButton value="dep" className={classes.toggleButton}>
              DEP
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={itemOption.length > 0 ? itemOption[0]['item'] : []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}<br />[{option.count}件]
              </React.Fragment>
            )}
            onChange={(e, newValue) => {
              handleChangeOption(newValue);
            }}
            style={{ fontSize: 11 }}
            renderInput={(params) => (
              <TextField {...params} label={itemOptionLoading ? "ロード中.." : `アイテム名`} />
            )}
          />          
        </Grid>
        <Grid item>
          <Autocomplete
            size="small"
            id="select-asset"
            options={itemPmidOption[0] !== undefined ? itemPmidOption[0]['pmid'] : []}
            disableCloseOnSelect
            getOptionLabel={(option) => option.pmid}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.pmid}<br />[{option.count}件]
              </React.Fragment>
            )}
            onChange={(e, newValue) => {
              handleChangeOptionPmid(newValue);
            }}
            style={{ fontSize: 11 }}
            renderInput={(params) => (
              <TextField {...params} label={itemPmidOptionLoading ? "ロード中.." : "PMID"} />
            )}
            className={classes.autoSelect}
          />
        </Grid>
        <Grid item>
          <Button
            //disabled={btnStateDataFetch}
            className={classes.filterApplyBtn}
            variant="contained"
            color="primary"
            onClick={() => applyFilter()}
          >
            適用
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

InGameItemTxFilter.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  assetOption: state.nftDepTxHisotry.assetOption,
  assetSearchTerm: state.nftDepTxHisotry.assetSearchTerm,
  itemOption: state.inGameItemSalesHistory.itemOption,
  itemPmidOption: state.inGameItemSalesHistory.itemPmidOption,
  itemPmidSelected: state.inGameItemSalesHistory.itemPmidSelected,
  itemOptionLoading: state.inGameItemSalesHistory.itemOptionLoading,
  itemPmidOptionLoading: state.inGameItemSalesHistory.itemPmidOptionLoading,
});

const mapDispatchToProps = (dispatch) => ({
  setTxHistorySearchTerm: (param) => dispatch(setTxHistorySearchTerm(param)),
  setInGameItemOptionSelected: (param) => dispatch(setInGameItemOptionSelected(param)),
  getInGameItemHistoryData: (param) => dispatch(getInGameItemHistoryData(param)),
  setInGamePageNumber: (param) => dispatch(setInGamePageNumber(param)),
  setInGamePyamentOptionSelected: (param) => dispatch(setInGamePyamentOptionSelected(param)),
  getInGameItemOptionData: (param) => dispatch(getInGameItemOptionData(param)),
  setInGamePmidOptionSelected: (param) => dispatch(setInGamePmidOptionSelected(param)),
  getInGamePmidOptionData: (param) => dispatch(getInGamePmidOptionData(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(InGameItemTxFilter));
