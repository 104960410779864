import React, { Fragment, useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import GamePuzzleStyles from 'pages/GamePuzzleStyles';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import * as C from 'components';
import * as H from 'helper';
import * as V from 'styles/variables';
import ChartConfig from 'utils/ChartConfig';
import { setCurrentPage, getActiveUsersData, getUserEventData, getUserStageClearData, getUserStageFailedData } from 'actions';

const GamePuzzleEvents = ({
    classes,
    setCurrentPage,
    getActiveUsersData,
    activeUsersTrendData,
    getUserEventData,
    userEventTrendData,
    getUserStageClearData,
    getUserStageFailedData,
    userEventStageClearData,
    userEventStageFailedData,
}) => {
    const [tabFocused, setTabFocused] = useState('map');

    useEffect(() => {
        setCurrentPage('puzzle');
        getActiveUsersData();
        getUserEventData();
        getUserStageClearData();
        getUserStageFailedData();
    }, []);

    return (
        <Fragment>
            <div className={classes.root}>
            <C.CalendarRangePicker
                mDate={'2020/09/28'}
                page="puzzle"
            />
            </div>
            <C.ContentWrapper>
                {/* Active Users Panel */}
                <Grid container spacing={3} className={classes.pageSectionTop}>
                    <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <div className={classes.cardHeadSection}>
                                <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                    Puzzle アクティブユーザー
                                </Typography>
                                <div className={classes.titleAction}>
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.cardContentSection}>
                                <C.PuzzleActiveUsers />
                            </div>
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>

                {/* Active Users Chart */}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardHeadSection}>
                                    <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                        Puzzle アクティブユーザートレンド
                                    </Typography>
                                    <div className={classes.titleAction}>
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className={classes.cardContentSectionChart}>
                                    <C.LineChart2
                                        legend={false}
                                        color={V.acquisitionPallette}
                                        lblSaveImage={"Puzzle アクティブユーザー"}
                                        xAisData={activeUsersTrendData.date}
                                        seriesData={activeUsersTrendData}
                                        chartType={'bar'}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Events Panel */}
                <Grid container spacing={1} className={classes.gridSection}>
                    <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <div className={classes.cardHeadSection}>
                                <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                    Puzzle 行動データ
                                </Typography>
                                <div className={classes.titleAction}>
                                    <IconButton aria-label="settings">
                                        <MoreVertIcon />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={classes.cardContentSection}>
                                <C.PuzzleUserEvents />
                            </div>
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>

                {/* Events Trend Chart */}
                <Grid container spacing={1} className={classes.gridSection}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardHeadSection}>
                                    <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                        Puzzle 行動トレンド
                                    </Typography>
                                    <div className={classes.titleAction}>
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div className={classes.cardContentSectionChart}>
                                    <C.LineChart2
                                        legend={ChartConfig.legendPuzzleUserEvents}
                                        color={V.puzzleUserEventPallette}
                                        lblSaveImage={"Puzzle 行動トレンド"}
                                        xAisData={userEventTrendData.date}
                                        seriesData={userEventTrendData}
                                        chartType={'bar'}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container spacing={1}>
                    <Grid item sm={12} lg={6}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardHeadSection}>
                                    <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                        ステージ毎の成功数 : 全期間 {H.FormatNumStrHelper.formatCommaThousand(userEventStageClearData.total)}
                                    </Typography>
                                    <div className={classes.titleAction}>
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <C.PuzzleStage
                                    barColour={V.successColor}
                                    data={userEventStageClearData.data}
                                    total={userEventStageClearData.total}
                                    loading={userEventStageClearData.loading} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Card>
                            <CardContent>
                                <div className={classes.cardHeadSection}>
                                    <Typography className={classes.cardTitle} variant="h6" component="h2" gutterBottom>
                                    ステージ毎の失敗数 : 全期間 {H.FormatNumStrHelper.formatCommaThousand(userEventStageFailedData.total)}
                                    </Typography>
                                    <div className={classes.titleAction}>
                                        <IconButton aria-label="settings">
                                            <MoreVertIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <C.PuzzleStage
                                    barColour={V.failedColor}
                                    data={userEventStageFailedData.data}
                                    total={userEventStageFailedData.total}
                                    loading={userEventStageFailedData.loading} />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </C.ContentWrapper>
        </Fragment>
    );
};

GamePuzzleEvents.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    activeUsersTrendData: state.puzzle.activeUsersTrendData,
    userEventTrendData: state.puzzle.userEventTrendData,
    userEventStageClearData: state.puzzle.userEventStageClearData,
    userEventStageFailedData: state.puzzle.userEventStageFailedData,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getActiveUsersData: () => dispatch(getActiveUsersData()),
    getUserEventData: () => dispatch(getUserEventData()),
    getUserStageClearData: () => dispatch(getUserStageClearData()),
    getUserStageFailedData: () => dispatch(getUserStageFailedData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(GamePuzzleStyles, { withTheme: true })(GamePuzzleEvents));