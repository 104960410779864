import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import { put, call, all, takeEvery, select } from 'redux-saga/effects';
import _ from 'lodash';
import { setActiveUsersData, setActiveUsersTrendData, setUserEventData, setUserEventTrendData, setUserStageClearData, setUserStageFailedData } from 'actions';
import { PUZZLE, PAGE } from '../constants';
import moment from 'moment-timezone';

export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];

export function* handlePuzzleActiveUsersData() {
    try {
        let activeUsersData = {}, activeUsersTrendData = {};
        let loading = 'loading';
        activeUsersData.yesterday = loading;
        activeUsersData.yesterdayPrev = loading;
        activeUsersData.last7 = loading;
        activeUsersData.last7Prev = loading;
        activeUsersData.last14 = loading;
        activeUsersData.last14Prev = loading;
        activeUsersData.last1mo = loading;
        activeUsersData.last1moPrev = loading;
        yield put(setActiveUsersData(activeUsersData));

        // Handling dates and period
        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        console.log('hoy dateFrom:', dateFrom);

        let unixDateFrom = moment(dateFrom+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unixDateTo = moment(dateTo+" 23:59:59").subtract(46800, 'seconds').format('x');
        console.log('hoy log range:', unixDateFrom, unixDateTo);

        // Yesterday
        let dateYesterday = moment().tz("Asia/Singapore").subtract(1, 'days').format('YYYY-MM-DD');
        let unix1DayFrom = moment(dateYesterday+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unix1DayTo = moment(dateYesterday+" 23:59:59").subtract(46800, 'seconds').format('x');

        const dateYesterdayPrev = moment(dateYesterday).subtract(1, 'days').format('YYYY-MM-DD');
        let unix1DayFromPrev = moment(dateYesterdayPrev+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unix1DayToPrev = moment(dateYesterdayPrev+" 23:59:59").subtract(46800, 'seconds').format('x');

        // Last 7 days
        const dateLast7day = moment().tz("Asia/Singapore").subtract(7, 'days').format('YYYY-MM-DD');
        let unix7DayFrom = moment(dateLast7day+" 00:00:00").subtract(46800, 'seconds').format('x');

        const date7DayFromPrev = moment(dateLast7day).subtract(7, 'days').format('YYYY-MM-DD');
        const date7DayToPrev = moment(dateLast7day).subtract(1, 'days').format('YYYY-MM-DD');
        let unix7DayFromPrev = moment(date7DayFromPrev+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unix7DayToPrev = moment(date7DayToPrev+" 23:59:59").subtract(46800, 'seconds').format('x');

        // Last 14 days
        const dateLast14day = moment().tz("Asia/Singapore").subtract(14, 'days').format('YYYY-MM-DD');
        let unix14DayFrom = moment(dateLast14day+" 00:00:00").subtract(46800, 'seconds').format('x');

        const date14DayPrev = moment(dateLast14day).subtract(14, 'days').format('YYYY-MM-DD');
        const date14DayToPrev = moment(dateLast14day).subtract(1, 'days').format('YYYY-MM-DD');
        let unix14DayFromPrev = moment(date14DayPrev+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unix14DayToPrev = moment(date14DayToPrev+" 23:59:59").subtract(46800, 'seconds').format('x');

        // Last 30 days
        const dateLast30day = moment().tz("Asia/Singapore").subtract(30, 'days').format('YYYY-MM-DD');
        let unix30DayFrom = moment(dateLast30day+" 00:00:00").subtract(46800, 'seconds').format('x');

        const date30DayPrev = moment(dateLast30day).subtract(30, 'days').format('YYYY-MM-DD');
        const date30DayToPrev = moment(dateLast30day).subtract(1, 'days').format('YYYY-MM-DD');
        let unix30DayFromPrev = moment(date30DayPrev+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unix30DayToPrev = moment(date30DayToPrev+" 23:59:59").subtract(46800, 'seconds').format('x');

        const [dataYes, dataYesPrev, sevenData, sevenDataPrev, fourteenData, fourteenDataPrev, thirtyData, thirtyDataPrev] = yield all([
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix1DayFrom), dateTo: Number(unix1DayTo)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix1DayFromPrev), dateTo: Number(unix1DayToPrev)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix7DayFrom), dateTo: Number(unix1DayTo)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix7DayFromPrev), dateTo: Number(unix7DayToPrev)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix14DayFrom), dateTo: Number(unix1DayTo)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix14DayFromPrev), dateTo: Number(unix14DayToPrev)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix30DayFrom), dateTo: Number(unix1DayTo)})),
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unix30DayFromPrev), dateTo: Number(unix30DayToPrev)})),
        ]);
        console.log('======');
        console.log('log 1day:', _.sumBy(dataYes.data.getCountActiveUsersByDatePuzzle, d => {return d.count}));
        console.log('log 1day prev:', _.sumBy(dataYesPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count;}));
        console.log('log 7day:', _.sumBy(sevenData.data.getCountActiveUsersByDatePuzzle, d => {return d.count}));
        console.log('log 7day prev:', _.sumBy(sevenDataPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count}));
        console.log('log 14day', _.sumBy(fourteenData.data.getCountActiveUsersByDatePuzzle, d => {return d.count}));
        console.log('log 14day prev', _.sumBy(fourteenDataPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count;}));
        console.log('log 30day', _.sumBy(thirtyData.data.getCountActiveUsersByDatePuzzle, d => {return d.count}));
        console.log('log 30day prev', _.sumBy(thirtyDataPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count}));
        console.log('======');
        activeUsersData = {
            yesterday: _.sumBy(dataYes.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            yesterdayPrev: _.sumBy(dataYesPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            last7: _.sumBy(sevenData.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            last7Prev: _.sumBy(sevenDataPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            last14: _.sumBy(fourteenData.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            last14Prev: _.sumBy(fourteenDataPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            last1mo: _.sumBy(thirtyData.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
            last1moPrev: _.sumBy(thirtyDataPrev.data.getCountActiveUsersByDatePuzzle, d => {return d.count}),
        };
        yield put(setActiveUsersData(activeUsersData));

        // Active Users Trend
        console.log('hoy unixDateFrom: ', unixDateFrom, unixDateTo);
        const [dataTempCountActiveUsersByDate] = yield all([
            API.graphql(graphqlOperation(queries.getCountActiveUsersByDatePuzzle, { dateFrom: Number(unixDateFrom), dateTo: Number(unixDateTo)})),
        ]);

        const activeUsersArray = dataTempCountActiveUsersByDate.data.getCountActiveUsersByDatePuzzle;

        let dateArray = [];
        const range = moment.range(dateFrom, dateTo);
        const days = Array.from(range.by('days'));
        dateArray = days.map(m => m.format('MM/DD/YY'));

        let dataArray = [];
        _.each(activeUsersArray, function(data) {
            dataArray.push(data['count']);
        });

        activeUsersTrendData.date = dateArray;
        activeUsersTrendData.activeUsersTrendData = dataArray;
        yield put(setActiveUsersTrendData(activeUsersTrendData));
    } catch (error) {
        console.log(error);
    }
}

export function* handlePuzzleUserEventData() {
    try {
        let userEventData = {}, userEventsTrendData = {};
        let loading = 'loading';
        userEventData.play = loading;
        userEventData.playPrev = loading;
        userEventData.clear = loading;
        userEventData.clearPrev = loading;
        userEventData.failed = loading;
        userEventData.failedPrev = loading;
        userEventData.kesoCount = loading;
        userEventData.kesoCountPrev = loading;
        userEventData.kesoTotal = loading;
        userEventData.kesoTotalPrev = loading;
        yield put(setUserEventData(userEventData));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        let unixDateFrom = moment(dateFrom+" 00:00:00").subtract(46800, 'seconds').format('x');
        let unixDateTo = moment(dateTo+" 23:59:59").subtract(46800, 'seconds').format('x');
        const [dataTmpPlay, dataTmpClear, dataTmpFailed, dataTmpKeso, dataTmpKesoTotal] = yield all([
            API.graphql(graphqlOperation(queries.getCountUserEventsByDatePuzzle, { table: 'PuzzleGamePlayStartProd', dateFrom: Number(unixDateFrom), dateTo: Number(unixDateTo)})),
            API.graphql(graphqlOperation(queries.getCountUserEventsByDatePuzzle, { table: 'PuzzleGameStageClearProd', dateFrom: Number(unixDateFrom), dateTo: Number(unixDateTo)})),
            API.graphql(graphqlOperation(queries.getCountUserEventsByDatePuzzle, { table: 'PuzzleGameStageFailedProd', dateFrom: Number(unixDateFrom), dateTo: Number(unixDateTo)})),
            API.graphql(graphqlOperation(queries.getCountUserEventsByDatePuzzle, { table: 'PuzzleGameCoinGetProd', dateFrom: Number(unixDateFrom), dateTo: Number(unixDateTo)})),
            API.graphql(graphqlOperation(queries.getTotalKesoAmountByDatePuzzle, { table: 'PuzzleGameCoinGetProd', dateFrom: Number(unixDateFrom), dateTo: Number(unixDateTo)})),
        ]);

        userEventData = {
            play: _.sumBy(dataTmpPlay.data.getCountUserEventsByDatePuzzle, d => {return d.count}),
            clear: _.sumBy(dataTmpClear.data.getCountUserEventsByDatePuzzle, d => {return d.count}),
            failed: _.sumBy(dataTmpFailed.data.getCountUserEventsByDatePuzzle, d => {return d.count}),
            kesoCount: _.sumBy(dataTmpKeso.data.getCountUserEventsByDatePuzzle, d => {return d.count}),
            kesoTotal: _.sumBy(dataTmpKesoTotal.data.getTotalKesoAmountByDatePuzzle, d => {return d.total}),
        }
        yield put(setUserEventData(userEventData));

        // User Events Trend
        let dateArray = [];
        const range = moment.range(dateFrom, dateTo);
        const days = Array.from(range.by('days'));
        dateArray = days.map(m => m.format('MM/DD/YY'));

        const userEventPlay = dataTmpPlay.data.getCountUserEventsByDatePuzzle;
        let playArray = [];
        _.each(userEventPlay, function(data) {
            playArray.push(data['count']);
        });

        const userEventClear = dataTmpClear.data.getCountUserEventsByDatePuzzle;
        let clearArray = [];
        _.each(userEventClear, function(data) {
            clearArray.push(data['count']);
        });

        const userEventFailed = dataTmpFailed.data.getCountUserEventsByDatePuzzle;
        let failedArray = [];
        _.each(userEventFailed, function(data) {
            failedArray.push(data['count']);
        });

        const userEventKeso = dataTmpKeso.data.getCountUserEventsByDatePuzzle;
        let kesoArray = [];
        _.each(userEventKeso, function(data) {
            kesoArray.push(data['count']);
        });

        userEventsTrendData.date = dateArray;
        userEventsTrendData.play = playArray;
        userEventsTrendData.clear = clearArray;
        userEventsTrendData.failed = failedArray;
        userEventsTrendData.keso = kesoArray;
        yield put(setUserEventTrendData(userEventsTrendData));
    } catch (error) {
        console.log(error);
    }
}

export function* handlePuzzleUserStageClearData() {
    try {
        let userStageClearData = { loading: true };
        yield put(setUserStageClearData(userStageClearData));
        const [tmpDataClear] = yield all([
            API.graphql(graphqlOperation(queries.getUniqueCountOfColumnNoLimit, { column: "stage", table: 'PuzzleGameStageClearProd', group: 'stage', sort: 'DESC'})),
        ]);
        const stageClearTotal = _.sumBy(tmpDataClear.data.getUniqueCountOfColumnNoLimit, d => {return d.count});
        userStageClearData.loading = false;
        userStageClearData.total = stageClearTotal;
        userStageClearData.data = tmpDataClear.data.getUniqueCountOfColumnNoLimit;
        yield put(setUserStageClearData(userStageClearData));
        // console.log('yoyo total clear: ', stageClearTotal);
        // console.log('yoyo tmpDataClear:', tmpDataClear.data.getUniqueCountOfColumnNoLimit);
    } catch (error) {
        console.log(error);
    }
}

export function* handlePuzzleUserStageFailedData() {
    try {
        let userStageFailedData = { loading: true };
        yield put(setUserStageFailedData(userStageFailedData));
        const [tmpDataFailed] = yield all([
            API.graphql(graphqlOperation(queries.getUniqueCountOfColumnNoLimit, { column: "stage", table: 'PuzzleGameStageFailedProd', group: 'stage', sort: 'DESC'})),
        ]);
        const stageFailedTotal = _.sumBy(tmpDataFailed.data.getUniqueCountOfColumnNoLimit, d => {return d.count});
        userStageFailedData.loading = false;
        userStageFailedData.total = stageFailedTotal;
        userStageFailedData.data = tmpDataFailed.data.getUniqueCountOfColumnNoLimit
        yield put(setUserStageFailedData(userStageFailedData));
        // console.log('yoyo total failed: ', stageFailedTotal);
        // console.log('yoyo tmpDataFailed: ', tmpDataFailed.data.getUniqueCountOfColumnNoLimit);
    } catch (error) {
        console.log(error);
    }
}

export default function* watchMapSettings() {
    yield takeEvery(PUZZLE.GET_ACTIVE_USERS_DATA, handlePuzzleActiveUsersData);
    yield takeEvery(PUZZLE.GET_USER_EVENT_DATA, handlePuzzleUserEventData);
    yield takeEvery(PAGE.GET_PUZZLE_PAGE_DATA, handlePuzzleUserEventData);
    yield takeEvery(PUZZLE.GET_USER_STAGE_CLEAR_DATA, handlePuzzleUserStageClearData);
    yield takeEvery(PUZZLE.GET_USER_STAGE_FAILED_DATA, handlePuzzleUserStageFailedData);
}