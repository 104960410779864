import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import * as C from 'components';
import * as V from 'styles/variables';

const Geo = ({classes, acquisitionGeoDataReg, acquisitionDataRegAdVsOrganic, acquisitionDataRegNewVsExist}) => {
    useEffect(() => {
        // console.log('yoyo', acquisitionGeoDataReg);
        // console.log('yoyo2:', acquisitionGeoDataReg.pmidRegNumCountryAd.count);
    }, [acquisitionGeoDataReg]);

    return(
        <Grid container spacing={1}>
            <Grid item sm={12} md={12} lg={8}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="登録国数"
                            value={acquisitionGeoDataReg.pmidRegNumCountry}
                            country={'globe'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="広告経由<br />PMID最多国"
                            value={acquisitionGeoDataReg.pmidRegNumCountryAd}
                            country={acquisitionGeoDataReg.pmidRegNumCountryAd.country}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="Organic<br />PMID最多国"
                            value={acquisitionGeoDataReg.pmidRegNumCountryOrg}
                            country={acquisitionGeoDataReg.pmidRegNumCountryOrg.country}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="新規PMID<br />登録最多国"
                            value={acquisitionGeoDataReg.pmidRegNewCountry}
                            country={acquisitionGeoDataReg.pmidRegNewCountry.country}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={2}>
                        <C.StatBox
                            title="既存PMID<br />登録最多国"
                            value={acquisitionGeoDataReg.pmidRegExistCountry}
                            country={acquisitionGeoDataReg.pmidRegExistCountry.country}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={4}>
                <Grid container spacing={2}>
                    <div style={{display:'flex', height: 140}}>
                        <C.DoughnutChart
                            title="広告 vs Organic"
                            data={acquisitionDataRegAdVsOrganic}
                            color={V.geoPallette}
                            legendRight={0}
                            legendTop={35}
                            marginLeft={'-20%'}
                        />
                        <C.DoughnutChart
                            title="PMID 新規 vs 既存"
                            data={acquisitionDataRegNewVsExist}
                            color={V.geoPallette}
                            legendRight={15}
                            legendTop={35}
                            marginLeft={0}
                        />
                    </div>
                </Grid>
            </Grid>
        </Grid>
    )
};

Geo.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    acquisitionGeoDataReg: state.puzzleGeo.acquisitionGeoDataReg,
    acquisitionDataRegAdVsOrganic: state.puzzle.acquisitionDataRegAdVsOrganic,
    acquisitionDataRegNewVsExist: state.puzzle.acquisitionDataRegNewVsExist,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(Geo));