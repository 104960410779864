import React, { Fragment, useState, useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Chip, Checkbox, TextField, Button, } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { setCurrentPage, getJtcbScholarAmuletData, getJtcbScholarAmuletDataByJob, setAmuletSelected } from 'actions';
import * as C from 'components';
import * as H from 'helper';
import * as V from 'styles/variables';
import ChartConfig from 'utils/ChartConfig';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ScholarAmulet = ({
    dateRange,
    amuletData,
    amuletDataJob,
    setCurrentPage,
    getJtcbScholarAmuletData,
    getJtcbScholarAmuletDataByJob,
    loading,
    loadingAmuletJob,
    setAmuletSelected,
    amuletSelected,
}) => {
    const classes = useStyles();
    const [chartState, setChartState] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [jobOption, setJobOption] = useState([]);
    const [allJob, setAllJob] = useState(true);
    const [jobSelected, setJobSelected] = useState([]);
    const [btnDisabled, setBtnDisabled] = useState(false);

    useEffect(() => {
        setCurrentPage("jobtribes/scholar/amulet");
        getJtcbScholarAmuletData();
        getJtcbScholarAmuletDataByJob();
    }, []);

    useEffect(() => {
        setChartData(amuletData);
        if (amuletData[0] !== undefined && amuletData[0]['data'].length > 0) {
            setChartState(true);
        }
    }, [amuletData]);

    useEffect(() => {
        let amuletJobArray = [{ name: '全アミュレット', value: 'all' }], eachJobObj = {};
        _.each(amuletDataJob, function(data, index) {
            eachJobObj.name = data.name + " (" + data.count + ")";
            eachJobObj.value = data.name;
            amuletJobArray.push(eachJobObj);
            eachJobObj = {};
        });
        setJobOption(amuletJobArray);
    }, [amuletDataJob]);

    const rankTableData = [
        { title: '#', field: 'rank', width: 50, cellStyle: {textAlign:'center'}},
        { title: 'JOB', field: 'name', width: 150, cellStyle: {textAlign:'left'}},
        { title: '貸出数', field: 'count', width: 200, type: 'numeric', cellStyle: {textAlign:'center'},
            render: rowData => <div>{H.FormatNumStrHelper.formatCommaThousand(rowData.count)}</div>
        },
        { title: '全体比率',field: 'ratio', type: 'numeric', cellStyle: {textAlign:'left', marginLeft: 32},
            render: rowData => 
            <Fragment>
                <div style={{display:'flex', height: 16}}>
                    <div style={{paddingRight: 16, width: 24}}>{rowData.ratio}%</div>
                    <div style={{backgroundColor: '#65C9A5', width:rowData.ratio+'%', marginLeft: 20, marginTop:3}}></div>
                </div>
            </Fragment>
        },
    ];

    const handleChangeOption = (data) => {
        let optionSelectedArray = [], optionSelectedForStore = [];
        _.forEach(data, function(d, i) {
            optionSelectedArray.push(d.value);
            optionSelectedForStore.push(d);
        });
        if (optionSelectedArray.indexOf("all") !== -1) {
            setAllJob(true);
        } else {
            setAllJob(false);
        }

        setJobSelected(optionSelectedArray);
        setAmuletSelected(optionSelectedForStore);

        if (optionSelectedArray.length === 5) {
            setBtnDisabled(true);
        } else if (optionSelectedArray.length < 5){
            setBtnDisabled(false);
        }
    }

    const checkDisable = () => {
        return jobSelected.length > 4 ? true : false;
    }

    const checkSelect = (value, selected) => {
        if (value === "all") {
            return allJob ? true : false;
        } else {
            return selected;
        }
    }

    const getAutoComplete = () => {
        return (
            <Grid container spacing={0} direction="row">
                <Grid item>
                <Autocomplete
                multiple
                limitTags={3}
                size="small"
                id="select-job"
                options={jobOption}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                renderOption={(option, { selected }) => (
                    <Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={checkSelect(option.value, selected)}
                    />
                    {option.name}
                    </Fragment>
                )}
                onChange={(e, newValue) => {
                    handleChangeOption(newValue);
                }}
                style={{ minWidth: 650, maxWidth: 650 }}
                renderInput={(params) => (
                    <TextField {...params} label="By Job (MAX 5つまで選択可能です)" placeholder={chartData[0]['data'].length === 0 ? "Jobを選択してください" : ""} />
                )}
                className={classes.autoSelect}
                defaultValue={amuletSelected}
                getOptionDisabled={checkDisable}
                />
                </Grid>
                <Grid item>
                <Button 
                    //disabled={btnDisabled} 
                    className={classes.dataBtn} 
                    variant="contained" 
                    color="default" 
                    onClick={() => fetchData()}>
                    GO
                </Button>
                </Grid>
            </Grid>
        )
    }

    const fetchData = () => {
        setBtnDisabled(true);
        getJtcbScholarAmuletData();
    }

    const getLegend = () => {
        let legendArray = [], legendName = "";
        _.each(amuletData, function(data) {
            legendName = data.id === "allAmulet" ? "全アミュレット" : data.id;
            legendArray.push(legendName);
        });
        return legendArray;
    }

    return (
        <Fragment>
            <C.ContentWrapper>
            <Grid container spacing={3} direction="row" className={classes.gridSection}>
                <Grid item xs={12} md={12} lg={8}>
                {loading 
                    ? <div className={classes.loaderBox}>
                        <img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} />
                      </div> 
                    : chartState
                        ? chartData[0]['data'].length > 0
                          ? <Fragment>
                              <div>{getAutoComplete()}</div>
                              <C.LineChartTrend
                                legend={getLegend()}
                                lblSaveImage={"スカラーアミュレット"}
                                xAisData={chartData[0]['date']}
                                seriesData={chartData}
                                chartType={'line'}
                                height={300}
                              />
                            </Fragment>
                          : <div className={classes.selectTxt}>
                              <Chip label="No Data" style={{color:V.textColor, backgroundColor:V.placeholderColor}} />
                            </div>
                        : null
                }
                    <C.TrendTable
                        title="アミュレット貸出日別データ"
                        noDataText="データがありません"
                        trendData={amuletData}
                        dateRange={dateRange}
                        loading={loading}
                        exportTitle="JTCB スカラーアミュレット貸出日別データ"
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <C.RankTable
                        title="Job別"
                        tableData={amuletDataJob}
                        columnData={rankTableData}
                        loading={loadingAmuletJob}
                        height={600}
                        search={true}
                    />
                </Grid>
            </Grid>
            </C.ContentWrapper>
        </Fragment>
    );
};

ScholarAmulet.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dateRange: state.page.dateRange,
    loading: state.jtcbScholarAmulet.loading,
    loadingAmuletJob: state.jtcbScholarAmulet.loadingAmuletJob,
    amuletData: state.jtcbScholarAmulet.amuletData,
    amuletDataJob: state.jtcbScholarAmulet.amuletDataJob,
    amuletSelected: state.jtcbScholarAmulet.amuletSelected,
});

const mapDispatchToProps = dispatch => ({
    setCurrentPage: (param) => dispatch(setCurrentPage(param)),
    getJtcbScholarAmuletData: () => dispatch(getJtcbScholarAmuletData()),
    getJtcbScholarAmuletDataByJob: () => dispatch(getJtcbScholarAmuletDataByJob()),
    setAmuletSelected: (param) => dispatch(setAmuletSelected(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(ScholarAmulet));

const useStyles = makeStyles((theme) =>
    createStyles({
        loaderBoxForUnique: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 110,
        },
        loaderBox: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 280,
        },
          loaderBarImg: {
            width: 40,
        },
        dataBtn: {
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            height: theme.spacing(4),
            //color: V.btnLabelColor,
        },
        dataTable: {
            padding: 16,
            borderSpacing: 0,
        },
        autoSelect: {
            marginTop: 8,
            marginLeft: 16,
        },
        gridSection: {
            marginTop: theme.spacing(1),
        },
        selectTxt: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 280,
            flexWrap: 'wrap',
            '& > *': {
                margin: theme.spacing(0.5),
            },
        },
    }),
);
