import React, { Component } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import { firstTimeColor, conversionColor, regColor, chartLineWidth, genericColorPallette } from 'styles/variables';
import * as H from 'helper';

export class PieChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelColor: '#FFF',
            legendData : [],
        };

        echarts.registerTheme('BarChartTheme', ChartStyle);
    }

    componentDidMount() {

    }

    magicTypeChanged = (param) => {
        console.log('param:', param);
    }

    getLegendData = (data) => {
        let dataTorReturn = [];
        for (let i=0; i<data.length; i++) {
            dataTorReturn.push(data[i]['name']);
        }
        return dataTorReturn;
    }

    getData = (data, metric) => {
        let returnData = [], dataObj = {};
        for (let i=0; i<data.length; i++) {
            dataObj.name = data[i]['plan'];
            if (metric === "DEP預入額") {
                dataObj.value = data[i]['dep'];
            } else if (metric === "預け入れPMID数") {
                dataObj.value = data[i]['count'];
            } else {
                dataObj.value = data[i]['paid'];
            }
            returnData.push(dataObj);
            dataObj = {};
        }
        return returnData;
    }

    getMonthLabel = (rate) => {
        let dataReturn = "";
        if (rate === "5.576") {
            dataReturn = "1ヶ月("+rate+"%)";
        } else if (rate === "8.523") {
            dataReturn = "3ヶ月("+rate+"%)";
        } else {
            dataReturn = "6ヶ月("+rate+"%)";
        }
        return dataReturn;
    }

    render () {
        const { classes, title, data, color, marginLeft, height='150px', show=true, metric } = this.props;

        let onEvents = {
            'magictypechanged': this.magicTypeChanged,
        }

        return (
            <div style={{marginLeft: marginLeft}}>
                <div className={classes.pieChartTitle}>{title}</div>
                {/* {data[0] === 'loading' ? (
                    <div className={classes.circleProgress}><CircularProgress /></div>
                ) : ( */}
                <ReactEcharts
                    option={{
                        grid: {
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        },
                        tooltip: {
                            trigger: 'item',
                            //formatter: '{a} <br/>{b}: {c} ({d}%)',
                            formatter: function (data) {
                                // return `${this.getMonthLabel(data.name)}: <br />
                                return `利率 ${data.name}% <br />
                                ${H.FormatNumStrHelper.formatCommaThousand(data.value)} (${data.percent}%)`
                            },
                            show: show,
                        },
                        series: [
                            {
                              name: metric,
                              type: 'pie',
                              radius: '80%',
                              data : this.getData(data, metric),
                              emphasis: {
                                itemStyle: {
                                  shadowBlur: 10,
                                  shadowOffsetX: 0,
                                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                              }
                            }
                        ],
                        color: genericColorPallette,
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    onEvents={onEvents}
                    style={{height: '300px', width: '400px'}}
                />
                {/* )} */}
            </div>
        )
    }
}

PieChart.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(ChartStyle, { withTheme: true })(PieChart));
