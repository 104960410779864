import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import moment from 'moment-timezone';
import * as H from "helper";
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) =>
  createStyles({
    totalStatsBox: {
      marginTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontSize: 14,
      borderRight: "1px solid #dcdcdc",
    },
    tsTitle: {
      fontFamily: "Roboto",
      fontSize: 14,
      height: theme.spacing(3),
      fontWeight: 400,
    },
    tsPrimeContent: {
      marginBottom: theme.spacing(2),
      display: "flex",
      width: "95%",
      fontSize: 20,
      fontWeight: 500,
    },
    tsContent: {
      marginBottom: theme.spacing(0.3),
      display: "flex",
      width: "95%",
      borderBottom: "1px dashed #dcdcdc",
    },
    tsPrimeLabel: {
      height: theme.spacing(3),
      fontWeight: 500,
      flex: 1,
      textAlign: "left",
    },
    tsLabel0: {
      flexGrow: 2,
      textAlign: "left",
    },
    tsLabel: {
      flexGrow: 3,
      textAlign: "left",
    },
    lblPercent: {
      flexGrow: 1,
      textAlign: "right",
      fontSize: 13,
    },
  })
);

const Users = ({
  totalRegStatsByXDate,
  acquisitionHomeDataReg,
  acquisitionHomeDataRegPrev,
  acquisitionHomeDataRegLast,
  dateRangePicTree,
  homeDataUser,
}) => {
  const classes = useStyles();
  useEffect(() => {}, []);

  const getStatValue = (data) => {
    if (data === "loading" || data === "-") {
      return "Loading..";
    } else {
      return H.FormatNumStrHelper.formatCommaThousand(data);
    }
  };

  const getRatioAdOrg = (num) => {
    if (num === "-" || num === "loading") return "--%";
    return ((num / totalRegStatsByXDate.gamePmid) * 100).toFixed(2) + "%";
  };

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={12} lg={12}>
        <Grid container spacing={0}>
          <Grid item sm={12} md={12} lg={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className={classes.totalStatsBox}>
                  <div className={classes.tsTitle}>
                    {dateRangePicTree[1]} 時点の合計
                  </div>
                  <div className={classes.tsPrimeContent}>
                    {homeDataUser.total !== undefined ? homeDataUser.total : "-"}
                    {/* {getStatValue(totalRegStatsByXDate.gamePmid)} */}
                  </div>
                  <div className={classes.tsContent}>
                    <span className={classes.tsLabel0}>Organic :</span>
                    <span className={classes.lblPercent}>
                      {/* {getStatValue(totalRegStatsByXDate.org)} */}
                      {homeDataUser.total !== undefined ? homeDataUser.total : "-"}
                    </span>
                    <span className={classes.lblPercent}>
                      {/* ({getRatioAdOrg(totalRegStatsByXDate.org)}) */}
                      (100%)
                    </span>
                  </div>
                  {/* <div className={classes.tsContent}>
                    <span className={classes.tsLabel0}>広告経由 :</span>
                    <span className={classes.lblPercent}>
                      {getStatValue(totalRegStatsByXDate.ads)}
                      -
                    </span>
                    <span className={classes.lblPercent}>
                      ({getRatioAdOrg(totalRegStatsByXDate.ads)})
                      (-- %)
                    </span>
                  </div> */}
                  <div className={classes.tsContent}>
                    <span className={classes.tsLabel0}>PMID連携 :</span>
                    <span className={classes.lblPercent}>
                      {homeDataUser.pmidTotal !== undefined ? homeDataUser.pmidTotal : "-"}
                    </span>
                    <span className={classes.lblPercent}>
                      (-- %)
                    </span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={5}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <StatBoxSlim
                  title="新規登録数"
                  value={homeDataUser.newTotal !== undefined ? homeDataUser.newTotal : "-"}
                  prevValue={homeDataUser.newOrgPrev !== undefined ? homeDataUser.newOrgPrev : "-"}
                  tip={"アプリインストール後の<br />新規 PicTrée 登録者数"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatBoxSlim
                  title="Organic vs 広告経由登録"
                  value={acquisitionHomeDataReg.pmidRegAdTotal}
                  prevValue={acquisitionHomeDataRegPrev.pmidRegAdTotal}
                  tip={"Coming Soon"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatBoxSlim
                  title="PMID連携数"
                  value={homeDataUser.pmidNew !== undefined ? homeDataUser.pmidNew : "-"}
                  prevValue={homeDataUser.pmidPrev !== undefined ? homeDataUser.pmidPrev : "-"}
                  tip={"ゲーム登録後、PlayMining IDと連携したユーザー数"}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} lg={5}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <StatBoxSlim
                  title="DAU"
                  value={homeDataUser.dau !== undefined ? homeDataUser.dau : "-"}
                  prevValue={homeDataUser.dauPrev !== undefined ? homeDataUser.dauPrev : "-"}
                  tip={"Daily Active Users<br />DAU算出日 : " + moment(dateRangePicTree[1]).format("M/D")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatBoxSlim
                  title="WAU"
                  value={homeDataUser.wau !== undefined ? homeDataUser.wau : "-"}
                  prevValue={homeDataUser.wauPrev !== undefined ? homeDataUser.wauPrev : "-"}
                  tip={"Weekly Active Users</br >WAU算出の7日 :" + moment(dateRangePicTree[0]).format("M/D") + " - " + moment(dateRangePicTree[1]).format("M/D")}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StatBoxSlim
                  title="MAU"
                  value={homeDataUser.mau !== undefined ? homeDataUser.mau : "-"}
                  prevValue={homeDataUser.mauPrev !== undefined ? homeDataUser.mauPrev : "-"}
                  tip={"Monthly Active Users</br >MAU算出の30日 : " + moment(dateRangePicTree[0]).subtract(30, 'days').format("M/D") + " - " + moment(dateRangePicTree[1]).format("M/D")}
                  lastItem={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Users.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  totalRegStatsByXDate: state.home.totalRegStatsByXDate,
  acquisitionHomeDataReg: state.home.acquisitionHomeDataReg,
  acquisitionHomeDataRegPrev: state.home.acquisitionHomeDataRegPrev,
  acquisitionHomeDataRegLast: state.home.acquisitionHomeDataRegLast,
  dateRangePicTree: state.page.dateRangePicTree,
  homeDataUser: state.pictree.homeDataUser,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Users));
