/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteCustomers = /* GraphQL */ `
  mutation DeleteCustomers($id: Int!) {
    deleteCustomers(id: $id) {
      id
      name
      phone
      email
      balance
    }
  }
`;
export const createCustomers = /* GraphQL */ `
  mutation CreateCustomers($createCustomersInput: CreateCustomersInput!) {
    createCustomers(createCustomersInput: $createCustomersInput) {
      id
      name
      phone
      email
      balance
    }
  }
`;
export const updateCustomers = /* GraphQL */ `
  mutation UpdateCustomers($updateCustomersInput: UpdateCustomersInput!) {
    updateCustomers(updateCustomersInput: $updateCustomersInput) {
      id
      name
      phone
      email
      balance
    }
  }
`;
export const deleteRegistration = /* GraphQL */ `
  mutation DeleteRegistration($id: Int!) {
    deleteRegistration(id: $id) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const createRegistration = /* GraphQL */ `
  mutation CreateRegistration(
    $createRegistrationInput: CreateRegistrationInput!
  ) {
    createRegistration(createRegistrationInput: $createRegistrationInput) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const updateRegistration = /* GraphQL */ `
  mutation UpdateRegistration(
    $updateRegistrationInput: UpdateRegistrationInput!
  ) {
    updateRegistration(updateRegistrationInput: $updateRegistrationInput) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const deleteRegistrationProd = /* GraphQL */ `
  mutation DeleteRegistrationProd($id: Int!) {
    deleteRegistrationProd(id: $id) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const createRegistrationProd = /* GraphQL */ `
  mutation CreateRegistrationProd(
    $createRegistrationProdInput: CreateRegistrationProdInput!
  ) {
    createRegistrationProd(
      createRegistrationProdInput: $createRegistrationProdInput
    ) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const updateRegistrationProd = /* GraphQL */ `
  mutation UpdateRegistrationProd(
    $updateRegistrationProdInput: UpdateRegistrationProdInput!
  ) {
    updateRegistrationProd(
      updateRegistrationProdInput: $updateRegistrationProdInput
    ) {
      id
      game
      rid
      pmId
      exist
      regDate
    }
  }
`;
export const deletePuzzleGamePlayStart = /* GraphQL */ `
  mutation DeletePuzzleGamePlayStart($id: Int!) {
    deletePuzzleGamePlayStart(id: $id) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const createPuzzleGamePlayStart = /* GraphQL */ `
  mutation CreatePuzzleGamePlayStart(
    $createPuzzleGamePlayStartInput: CreatePuzzleGamePlayStartInput!
  ) {
    createPuzzleGamePlayStart(
      createPuzzleGamePlayStartInput: $createPuzzleGamePlayStartInput
    ) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const updatePuzzleGamePlayStart = /* GraphQL */ `
  mutation UpdatePuzzleGamePlayStart(
    $updatePuzzleGamePlayStartInput: UpdatePuzzleGamePlayStartInput!
  ) {
    updatePuzzleGamePlayStart(
      updatePuzzleGamePlayStartInput: $updatePuzzleGamePlayStartInput
    ) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const deletePuzzleGamePlayStartProd = /* GraphQL */ `
  mutation DeletePuzzleGamePlayStartProd($id: Int!) {
    deletePuzzleGamePlayStartProd(id: $id) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const createPuzzleGamePlayStartProd = /* GraphQL */ `
  mutation CreatePuzzleGamePlayStartProd(
    $createPuzzleGamePlayStartProdInput: CreatePuzzleGamePlayStartProdInput!
  ) {
    createPuzzleGamePlayStartProd(
      createPuzzleGamePlayStartProdInput: $createPuzzleGamePlayStartProdInput
    ) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const updatePuzzleGamePlayStartProd = /* GraphQL */ `
  mutation UpdatePuzzleGamePlayStartProd(
    $updatePuzzleGamePlayStartProdInput: UpdatePuzzleGamePlayStartProdInput!
  ) {
    updatePuzzleGamePlayStartProd(
      updatePuzzleGamePlayStartProdInput: $updatePuzzleGamePlayStartProdInput
    ) {
      id
      game
      rid
      pmId
      country
      logDate
    }
  }
`;
export const createPuzzleRegData = /* GraphQL */ `
  mutation CreatePuzzleRegData(
    $input: CreatePuzzleRegDataInput!
    $condition: ModelPuzzleRegDataConditionInput
  ) {
    createPuzzleRegData(input: $input, condition: $condition) {
      id
      dateFrom
      dateTo
      dataset
      name
      createdAt
      updatedAt
    }
  }
`;
export const updatePuzzleRegData = /* GraphQL */ `
  mutation UpdatePuzzleRegData(
    $input: UpdatePuzzleRegDataInput!
    $condition: ModelPuzzleRegDataConditionInput
  ) {
    updatePuzzleRegData(input: $input, condition: $condition) {
      id
      dateFrom
      dateTo
      dataset
      name
      createdAt
      updatedAt
    }
  }
`;
export const deletePuzzleRegData = /* GraphQL */ `
  mutation DeletePuzzleRegData(
    $input: DeletePuzzleRegDataInput!
    $condition: ModelPuzzleRegDataConditionInput
  ) {
    deletePuzzleRegData(input: $input, condition: $condition) {
      id
      dateFrom
      dateTo
      dataset
      name
      createdAt
      updatedAt
    }
  }
`;
