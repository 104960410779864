import React, { useEffect } from "react";
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChartStyle from 'styles/ChartStyle';
import { chartLineWidth } from 'styles/variables';

const useStyles = makeStyles((theme) => ({
    progress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 300,
    },
    chartContainer: {
        marginTop: 16,
        width: '100%',
        display: 'block'
    }
}));

const CohortLineChart = ({
    color, xAisData, dataLoading, seriesData, seriesRowData, seriesRowNftData
}) => {
    const classes = useStyles();

    useEffect(() => {
        echarts.registerTheme('BarChartTheme', ChartStyle);
    }, []);

    return (
        <div className={classes.chartContainer}>
            {dataLoading === true ? (
                <div className={classes.progress}><CircularProgress /></div>
            ) : (
                <ReactEcharts
                    option={{
                        tooltip: {
                            trigger: "axis",
                            axisPointer : {
                                type : 'none'
                            },
                            //formatter: '{b0} 維持率<br />{c0}%',
                            formatter: function (data) {
                                const mei1 = seriesRowNftData[data[0]['dataIndex']] !== undefined ? `(${seriesRowNftData[data[0]['dataIndex']]}名)` : '';
                                const mei2 = seriesRowData[data[0]['dataIndex']] !== undefined ? `(${seriesRowData[data[0]['dataIndex']]}名)` : '';
                                const isNftData = data[1] !== undefined
                                ? `${data[1]['marker']} ${data[1]['seriesName']} : ${data[1]['data']}% ${mei1}`
                                : ``;
                                return `${data[0]['axisValue']} 維持率<br />
                                ${isNftData}<br />
                                ${data[0]['marker']} ${data[0]['seriesName']} :  ${data[0]['data']}% ${mei2}`;
                            },
                            order:'seriesDesc',
                        },
                        legend: {
                            show: true,
                            data: ['新規登録', 'NFT初購入']
                        },
                        grid: {
                            left: 48,
                            top: 48,
                            right: 48,
                            bottom: 32
                        },
                        xAxis: {
                          type: "category",
                          data: xAisData
                        },
                        yAxis: {
                          type: "value",
                          axisLabel : {
                            formatter: '{value} %'
                          },
                        },
                        series: [
                            {
                                name: '新規登録',
                                data: seriesData[0],
                                lineStyle: {
                                    width: chartLineWidth,
                                },
                                type: 'line',
                                symbolSize: 10,
                            },
                            {
                                name: 'NFT初購入',
                                data: seriesData[1],
                                lineStyle: {
                                    width: chartLineWidth,
                                },
                                type: 'line',
                                symbolSize: 10,
                            }
                        ],
                        color: color,
                    }}
                    theme='BarChartTheme'
                    notMerge={true}
                    lazyUpdate={true}
                    style={{height: '300px', width: '100%'}}
                />
            )}
        </div>
    );
};

CohortLineChart.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
    dataLoading: state.puzzle.dataLoading,
    comparisonDateDiff: state.page.comparisonDateDiff,
});

const mapDispatchToProps = dispatch => ({
    //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(CohortLineChart));