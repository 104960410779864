import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setDataLoading, setAllDepSalesData, setAllDepFeesData, setAllTxCountData, setTodaysSalesData, setActiveWalletData,
setTxUniqueNewData, setTxStoreUniqueNewData, setTxBlind, setTxSnap, setDataLoadingForBy, setTxAuctionStatsData, setTxStoreStatsData, setTxFiatStatsData,
setStakingOvDataCount, setStakingOvDataDep, setTxFirstTimePurchaseStatsData } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch, getCurrentDepExchangeRate, getDataFromSxi } from 'api';
import { NFTDEP, ENDPOINT } from '../constants';
import * as H from 'helper';

export const currentPage = state => state.page.currentPage;
export const startDate = state => state.page.dateRange[0];
export const endDate = state => state.page.dateRange[1];
export const startPrevDate = state => state.page.prevDateRange[0];
export const endPrevDate = state => state.page.prevDateRange[1];
export const depPrice = state => state.page.depPrice;
export const loading = state => state.nftDep.loading;

export function* getSalesOverviewDataSxi() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.allDepSales = true;
        loadingUpdate.allTxCount = true;
        loadingUpdate.allDepFees = true;
        loadingUpdate.txAuction = true;
        loadingUpdate.txStore = true;
        loadingUpdate.activeWallet = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        // const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        // const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeStart = `${dateFrom}T00:00:00.000Z`;
        const rangeEnd = `${dateTo}T23:59:59.999Z`;

        // prev
        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');
        const rangeStartPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEndPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQuerySalesPrimaryInRange = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStart, rangeEnd]});
        const sqlQuerySales2ndInRange = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStart, rangeEnd]});
        const sqlQuerySalesPrimaryInRangePrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQuerySales2ndInRangePrev = H.SqlHelper.getDepSalesInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryMarketStatsPrimary = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStart, rangeEnd]});
        const sqlQueryMarketStats2nd = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStart, rangeEnd]});
        const sqlQueryMarketStatsPrimaryPrev = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd1stV2', type: 'REVENUE_1ST', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryMarketStats2ndPrev = H.SqlHelper.getMarketStatsInRange3({table: 'SxiNftSalesProd2ndV1', type: 'TRADE_2ND', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQuerWithdrawFeesAmount = H.SqlHelper.getMarketWithDrawalFeesInRange2({range: [rangeStart, rangeEnd]});
        const sqlQuerWithdrawFeesAmountPrev = H.SqlHelper.getMarketWithDrawalFeesInRange2({range: [rangeStartPrev, rangeEndPrev]});
        const sqlQuerySalesFiatInRange = H.SqlHelper.getFiatSalesInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd]});
        const sqlQuerySalesFiatInRangePrev = H.SqlHelper.getFiatSalesInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryFiatStats = H.SqlHelper.getFiatStatsInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStart, rangeEnd]});
        const sqlQueryFiatStatsPrev = H.SqlHelper.getFiatStatsInRange2({table: 'SxiNftSalesProdFiatV1', type: 'CREDIT_PAYMENT_MARKET', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryFirstTimeBuy = H.SqlHelper.getFirstTimePurchaseGroupByEventType({table: 'SxiNftSalesProdFirstPurchaseV1', range: [rangeStart, rangeEnd]});
        const sqlQueryFirstTimeBuyPrev = H.SqlHelper.getFirstTimePurchaseGroupByEventType({table: 'SxiNftSalesProdFirstPurchaseV1', range: [rangeStartPrev, rangeEndPrev]});

        const [
            dataPrimary,
            dataSedondary,
            dataPrimaryPrev,
            dataSedondaryPrev,
            dataPrimaryStats,
            dataSedondaryStats,
            dataPrimaryStatsPrev,
            dataSedondaryStatsPrev,
            dataWithdrawFeesAmount,
            dataWithdrawFeesAmountPrev,
            dataFiat,
            dataFiatPrev,
            dataFiatStats,
            dataFiatStatsPrev,
            dataFirstTimeBuy,
            dataFisrtTimeBuyPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQuerySalesPrimaryInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySales2ndInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySalesPrimaryInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySales2ndInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStatsPrimary, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStats2nd, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStatsPrimaryPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMarketStats2ndPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerWithdrawFeesAmount, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerWithdrawFeesAmountPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySalesFiatInRange, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQuerySalesFiatInRangePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFiatStats, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFiatStatsPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFirstTimeBuy, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryFirstTimeBuyPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const data1stReturn = JSON.parse(dataPrimary.body);
        const data2ndReturn = JSON.parse(dataSedondary.body);
        const data1stPrevReturn = JSON.parse(dataPrimaryPrev.body);
        const data2ndPrevReturn = JSON.parse(dataSedondaryPrev.body);
        const stats1stReturn = JSON.parse(dataPrimaryStats.body);
        const stats2ndReturn = JSON.parse(dataSedondaryStats.body);
        const stats1stPrevReturn = JSON.parse(dataPrimaryStatsPrev.body);
        const stats2ndPrevReturn = JSON.parse(dataSedondaryStatsPrev.body);
        const dataFiatReturn = JSON.parse(dataFiat.body);
        const dataFiatReturnPrev = JSON.parse(dataFiatPrev.body);
        const statsFiatReturn = JSON.parse(dataFiatStats.body);
        const statsFiatPrevReturn = JSON.parse(dataFiatStatsPrev.body);
        const statsFirstTimeReturn = JSON.parse(dataFirstTimeBuy.body);
        const statsFirstPrevTimeReturn = JSON.parse(dataFisrtTimeBuyPrev.body);

        const allDepSalesValue = {
            total: Number(data1stReturn[0][0]['stringValue'])+Number(data2ndReturn[0][0]['stringValue']),
            prev: Number(data1stPrevReturn[0][0]['stringValue'])+Number(data2ndPrevReturn[0][0]['stringValue']),
            auction: Number(data2ndReturn[0][0]['stringValue']),
            store: Number(data1stReturn[0][0]['stringValue']),
            totalFiat: dataFiatReturn[0][1]['isNull'] ? 0 : Number(dataFiatReturn[0][1]['stringValue']),
            totalFiatPrev: dataFiatReturnPrev[0][1]['isNull'] ? 0 : Number(dataFiatReturnPrev[0][1]['stringValue']),
        };
        yield put(setAllDepSalesData(allDepSalesValue));

        // Count
        const allTxCountValue = {
            total: data1stReturn[0][1]['longValue']+data2ndReturn[0][1]['longValue']+dataFiatReturn[0][2]['longValue'],
            prev: data1stPrevReturn[0][1]['longValue']+data2ndPrevReturn[0][1]['longValue']+dataFiatReturnPrev[0][2]['longValue'],
            auction: data2ndReturn[0][1]['longValue'],
            store: data1stReturn[0][1]['longValue'],
            fiat: Number(dataFiatReturn[0][2]['longValue']),
        };
        yield put(setAllTxCountData(allTxCountValue));

        // auction stats
        const auctionTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: data2ndReturn[0][1]['longValue'],
                prev: data2ndPrevReturn[0][1]['longValue'],
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: data2ndReturn[0][2]['longValue'],
                prev: data2ndPrevReturn[0][2]['longValue'],
            },
            {
                title: "最大値",
                type: "max",
                current: stats2ndReturn[0][0]['longValue'],
                prev: stats2ndPrevReturn[0][0]['longValue'],
            },
            {
                title: "最小値",
                type: "min",
                current: stats2ndReturn[0][2]['longValue'],
                prev: stats2ndPrevReturn[0][2]['longValue'],
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(stats2ndReturn[0][1]['stringValue']),
                prev: Number(stats2ndPrevReturn[0][1]['stringValue']),
            },
        ];
        yield put(setTxAuctionStatsData(auctionTxStatsData));

        // store stats
        const storeTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: data1stReturn[0][1]['longValue'],
                prev: data1stPrevReturn[0][1]['longValue'],
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: data1stReturn[0][2]['longValue'],
                prev: data1stPrevReturn[0][2]['longValue'],
            },
            {
                title: "最大値",
                type: "max",
                current: stats1stReturn[0][0]['longValue'],
                prev: stats1stPrevReturn[0][0]['longValue'],
            },
            {
                title: "最小値",
                type: "min",
                current: stats1stReturn[0][2]['longValue'],
                prev: stats1stPrevReturn[0][2]['longValue'],
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(stats1stReturn[0][1]['stringValue']),
                prev: Number(stats1stPrevReturn[0][1]['stringValue']),
            },
        ];
        yield put(setTxStoreStatsData(storeTxStatsData));

        // fiat stats
        const fiatTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: dataFiatReturn[0][2]['longValue'],
                prev: dataFiatReturnPrev[0][2]['longValue'],
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: dataFiatReturn[0][3]['longValue'],
                prev: dataFiatReturnPrev[0][3]['longValue'],
            },
            {
                title: "最大値",
                type: "max",
                current: Number(statsFiatReturn[0][0]['stringValue']),
                prev: Number(statsFiatPrevReturn[0][0]['stringValue']),
            },
            {
                title: "最小値",
                type: "min",
                current: Number(statsFiatReturn[0][2]['stringValue']),
                prev: Number(statsFiatPrevReturn[0][2]['stringValue']),
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(statsFiatReturn[0][1]['stringValue']),
                prev: Number(statsFiatPrevReturn[0][1]['stringValue']),
            },
        ];        
        yield put(setTxFiatStatsData(fiatTxStatsData));

        // Fees:
        const feesSum = JSON.parse(dataWithdrawFeesAmount.body);
        const feesSumPrev = JSON.parse(dataWithdrawFeesAmountPrev.body);
        // const allDepFeesValue = {
        //     total: (Math.abs(dataWithdrawFeesAmount[0]['SUM']))*0.06 + Number(data2ndReturn[0][0]['stringValue'])*0.06,
        //     prev: (Math.abs(dataWithdrawFeesAmountPrev[0]['SUM']))*0.06 + Number(data2ndPrevReturn[0][0]['stringValue'])*0.06,
        //     auction: Number(data2ndReturn[0][0]['stringValue'])*0.06,
        //     withdraw: (Math.abs(feesSum[0][0]['stringValue']))*0.06//dataWithdrawFeesAmount.dep_total_amount.value
        // };
        const allDepFeesValue = {
            total: (Number(feesSum[0][0]['stringValue']))*0.06 + Number(data2ndReturn[0][0]['stringValue'])*0.06,
            prev: (Number(feesSumPrev[0][0]['stringValue']))*0.06 + Number(data2ndPrevReturn[0][0]['stringValue'])*0.06,
            auction: Number(data2ndReturn[0][0]['stringValue'])*0.06,
            withdraw: (Number(feesSum[0][0]['stringValue']))*0.06//dataWithdrawFeesAmount.dep_total_amount.value
        };
        yield put(setAllDepFeesData(allDepFeesValue));

        // Active Wallet
        const activeWalletCountValue = {
            current : data1stReturn[0][2]['longValue']+data2ndReturn[0][2]['longValue']+dataFiatReturn[0][3]['longValue'],
            prev: data1stPrevReturn[0][2]['longValue']+data2ndPrevReturn[0][2]['longValue']+dataFiatReturnPrev[0][3]['longValue'],
            auction: data2ndReturn[0][2]['longValue'],
            store: data1stReturn[0][2]['longValue'],
            fiat: dataFiatReturn[0][3]['longValue'],
        };
        yield put(setActiveWalletData(activeWalletCountValue));

        let currentSum = 0, prevSum = 0, auctionSum = 0, storeSum = 0, fiatSum = 0;
        _.each(statsFirstTimeReturn, function(fData) {
            currentSum = currentSum + fData[1]['longValue'];
            switch(fData[0]['stringValue']) {
                case "REVENUE_1ST":
                    storeSum = fData[1]['longValue'];
                    break;
                case "TRADE_2ND":
                    auctionSum = fData[1]['longValue'];
                    break;
                case "CREDIT_PAYMENT_MARKET":
                    fiatSum = fData[1]['longValue'];
                    break;
                default:
            }
        });
        _.each(statsFirstPrevTimeReturn, function(pData) {
            prevSum = prevSum + pData[1]['longValue'];
        }); 
        // FirstTime Purhcase
        const firstTimePurchaseCountValue = {
            current : currentSum,
            prev: prevSum,
            auction: auctionSum,
            store: storeSum,
            fiat: fiatSum,
        };
        yield put(setTxFirstTimePurchaseStatsData(firstTimePurchaseCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.allDepSales = false;
        loadingUpdate2.allTxCount = false;
        loadingUpdate2.allDepFees = false;
        loadingUpdate2.activeWallet = false;
        loadingUpdate2.txAuction = false;
        loadingUpdate2.txStore = false;
        loadingUpdate2.activeWallet = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

export function* getSalesOverviewData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.allDepSales = true;
        loadingUpdate.allTxCount = true;
        loadingUpdate.allDepFees = true;
        loadingUpdate.txAuction = true;
        loadingUpdate.txStore = true;
        loadingUpdate.activeWallet = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        const sourceAucStoreAmount = H.ElasticSearch.getAucStoreCount(dateFrom, dateTo);
        const esQueryAucStoreDataStore = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceAucStoreAmount}`};
        const sourceAucStoreAmountPrev = H.ElasticSearch.getAucStoreCount(datePrevFrom, datePrevTo);
        const esQueryAucStoreDataPrev = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceAucStoreAmountPrev}`};

        const [
            dataAucStoreAmount,
            dataAucStoreAmountPrev
        ] = yield all([
            call(getDataFromElasticSearch, esQueryAucStoreDataStore, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryAucStoreDataPrev, ENDPOINT.GET_DEP_SALES, jwtToken),
        ]);

        // const sourceAuctionAmount = H.ElasticSearch.getTotalDepAuctionTx(dateFrom, dateTo);
        // const esQueryDataAuction = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceAuctionAmount}`};

        // const sourceStoreAmount = H.ElasticSearch.getTotalDepStoreTx(dateFrom, dateTo);
        // const esQueryDataStore = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceStoreAmount}`};

        // const sourceAuctionAmountPrev = H.ElasticSearch.getTotalDepAuctionTx(datePrevFrom, datePrevTo);
        // const esQueryDataAuctionPrev = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceAuctionAmountPrev}`};

        // const sourceStoreAmountPrev = H.ElasticSearch.getTotalDepStoreTx(datePrevFrom, datePrevTo);
        // const esQueryDataStorePrev = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceStoreAmountPrev}`};

        // const [
        //     dataAuctionAmount,
        //     dataStoreAmount,
        //     dataAuctionAmountPrev,
        //     dataStoreAmountPrev
        // ] = yield all([
        //     call(getDataFromElasticSearch, esQueryDataAuction, ENDPOINT.GET_DEP_SALES, jwtToken),
        //     call(getDataFromElasticSearch, esQueryDataStore, ENDPOINT.GET_DEP_SALES, jwtToken),
        //     call(getDataFromElasticSearch, esQueryDataAuctionPrev, ENDPOINT.GET_DEP_SALES, jwtToken),
        //     call(getDataFromElasticSearch, esQueryDataStorePrev, ENDPOINT.GET_DEP_SALES, jwtToken),
        // ]);

        const allDepSalesValue = {
            total: dataAucStoreAmount.total_dep.value,
            prev: dataAucStoreAmountPrev.total_dep.value,
            auction: dataAucStoreAmount.kind.buckets[0].dep_total_amount.value,
            store: dataAucStoreAmount.kind.buckets[1].dep_total_amount.value,
        };
        yield put(setAllDepSalesData(allDepSalesValue));
        
        // Count
        const allTxCountValue = {
            total: dataAucStoreAmount.kind.buckets[0].tx_count.value + dataAucStoreAmount.kind.buckets[1].tx_count.value,
            prev: dataAucStoreAmountPrev.kind.buckets[0].tx_count.value + dataAucStoreAmountPrev.kind.buckets[1].tx_count.value,
            auction: dataAucStoreAmount.kind.buckets[0].tx_count.value,
            store: dataAucStoreAmount.kind.buckets[1].tx_count.value,
        };
        yield put(setAllTxCountData(allTxCountValue));

        // auction stats
        const auctionTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: dataAucStoreAmount.kind.buckets[0].tx_count.value,
                prev: dataAucStoreAmountPrev.kind.buckets[0].tx_count.value,
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: dataAucStoreAmount.kind.buckets[0].unique_pmid_from_to.value,
                prev: dataAucStoreAmountPrev.kind.buckets[0].unique_pmid_from_to.value,
            },
            {
                title: "最大値",
                type: "max",
                current: dataAucStoreAmount.kind.buckets[0].max_price.value,
                prev: dataAucStoreAmountPrev.kind.buckets[0].max_price.value,
            },
            {
                title: "最小値",
                type: "min",
                current: dataAucStoreAmount.kind.buckets[0].min_price.value,
                prev: dataAucStoreAmountPrev.kind.buckets[0].min_price.value,
            },
            {
                title: "平均値",
                type: "avg",
                current: dataAucStoreAmount.kind.buckets[0].avg_price.value,
                prev: dataAucStoreAmountPrev.kind.buckets[0].avg_price.value,
            },
            {
                title: "中央値",
                type: "median",
                current: dataAucStoreAmount.kind.buckets[0].median_price.values['50.0'],
                prev: dataAucStoreAmountPrev.kind.buckets[0].median_price.values['50.0'],
            },
        ];
        yield put(setTxAuctionStatsData(auctionTxStatsData));

        // store stats
        const storeTxStatsData = [
            {
                title: "件数",
                type: "max",
                current: dataAucStoreAmount.kind.buckets[1].tx_count.value,
                prev: dataAucStoreAmountPrev.kind.buckets[1].tx_count.value,
            },
            {
                title: "ユニークPMID",
                type: "max",
                current: dataAucStoreAmount.kind.buckets[1].unique_pmid_from.value,
                prev: dataAucStoreAmountPrev.kind.buckets[1].unique_pmid_from.value,
            },
            {
                title: "最大値",
                type: "max",
                current: dataAucStoreAmount.kind.buckets[1].max_price.value,
                prev: dataAucStoreAmountPrev.kind.buckets[1].max_price.value,
            },
            {
                title: "最小値",
                type: "min",
                current: dataAucStoreAmount.kind.buckets[1].min_price.value,
                prev: dataAucStoreAmountPrev.kind.buckets[1].min_price.value,
            },
            {
                title: "平均値",
                type: "avg",
                current: dataAucStoreAmount.kind.buckets[1].avg_price.value,
                prev: dataAucStoreAmountPrev.kind.buckets[1].avg_price.value,
            },
            {
                title: "中央値",
                type: "median",
                current: dataAucStoreAmount.kind.buckets[1].median_price.values['50.0'],
                prev: dataAucStoreAmountPrev.kind.buckets[1].median_price.values['50.0'],
            },
        ];
        yield put(setTxStoreStatsData(storeTxStatsData));

        // Fees
        // const totalAuctionAndStore = dataAucStoreAmount.total_dep.value;
        // const totalAuctionAndStorePrev = dataAucStoreAmountPrev.total_dep.value;

        const sourceWithdrawFeesAmount = H.ElasticSearch.getTotalDepWithdrawFees(dateFrom, dateTo);
        const esQueryDataWithdrawFees = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceWithdrawFeesAmount}`};

        const sourceWithdrawFeesAmountPrev = H.ElasticSearch.getTotalDepWithdrawFees(datePrevFrom, datePrevTo);
        const esQueryDataWithdrawFeesPrev = {"es": "daa", "query": "aggregate", "index": "receipts-*", "src": `${sourceWithdrawFeesAmountPrev}`};

        let auctionNewFeeTotal = 0;
        // Current
        if (moment(dateFrom).isBefore('2022-06-07') && moment(dateTo).isBefore('2022-06-07')) {
            // fees both 10%
            const sourceWithdrawFeesAmountBefore = H.ElasticSearch.getTotalDepWithdrawFeesNew(dateFrom, dateTo);
            const esQueryDataWithdrawFeesBefore = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceWithdrawFeesAmountBefore}`};
            const dataWithdrawFeesAmount = yield call(getDataFromElasticSearch, esQueryDataWithdrawFeesBefore, ENDPOINT.GET_DEP_SALES, jwtToken);
            auctionNewFeeTotal = dataWithdrawFeesAmount.total_price.value*0.2;
        } else if (moment(dateFrom).isAfter('2022-06-07') && moment(dateTo).isAfter('2022-06-07')) {
            // fees both 3%
            const sourceWithdrawFeesAmountNew = H.ElasticSearch.getTotalDepWithdrawFeesNew(dateFrom, dateTo);
            const esQueryDataWithdrawFeesNew = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceWithdrawFeesAmountNew}`};
            const dataWithdrawFeesAmount = yield call(getDataFromElasticSearch, esQueryDataWithdrawFeesNew, ENDPOINT.GET_DEP_SALES, jwtToken);
            auctionNewFeeTotal = dataWithdrawFeesAmount.total_price.value*0.06;
        } else if (moment(dateFrom).isBefore('2022-06-07') && moment(dateTo).isAfter('2022-06-07')) {
            // fees some 10% and some 3%
            const sourceFeesChangeBefore = H.ElasticSearch.getTotalDepFeesWithChange(dateFrom, dateTo, 0);
            const esQueryFeesChangeBefore = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceFeesChangeBefore}`};

            const sourceFeesChangeAfter = H.ElasticSearch.getTotalDepFeesWithChange(dateFrom, dateTo, 1);
            const esQueryFeesChangeAfter = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceFeesChangeAfter}`};

            const [
                dataFeesAmountBefore,
                dataFeesAmountAfter,
            ] = yield all([
                call(getDataFromElasticSearch, esQueryFeesChangeBefore, ENDPOINT.GET_DEP_SALES, jwtToken),
                call(getDataFromElasticSearch, esQueryFeesChangeAfter, ENDPOINT.GET_DEP_SALES, jwtToken),
            ]);
            auctionNewFeeTotal = dataFeesAmountBefore.total_price.value*0.2 + dataFeesAmountAfter.total_price.value*0.06;
        }

        // Prev
        let auctionNewFeeTotalPrev = 0;
        if (moment(datePrevFrom).isBefore('2022-06-07') && moment(datePrevTo).isBefore('2022-06-07')) {
            // fees both 10%
            const sourceWithdrawFeesAmountBefore = H.ElasticSearch.getTotalDepWithdrawFeesNew(datePrevFrom, datePrevTo);
            const esQueryDataWithdrawFeesBefore = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceWithdrawFeesAmountBefore}`};
            const dataWithdrawFeesAmount = yield call(getDataFromElasticSearch, esQueryDataWithdrawFeesBefore, ENDPOINT.GET_DEP_SALES, jwtToken);
            auctionNewFeeTotalPrev = dataWithdrawFeesAmount.total_price.value*0.2;
        } else if (moment(datePrevFrom).isAfter('2022-06-07') && moment(datePrevTo).isAfter('2022-06-07')) {
            // fees both 3%
            const sourceWithdrawFeesAmountNew = H.ElasticSearch.getTotalDepWithdrawFeesNew(datePrevFrom, datePrevTo);
            const esQueryDataWithdrawFeesNew = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceWithdrawFeesAmountNew}`};
            const dataWithdrawFeesAmount = yield call(getDataFromElasticSearch, esQueryDataWithdrawFeesNew, ENDPOINT.GET_DEP_SALES, jwtToken);
            auctionNewFeeTotalPrev = dataWithdrawFeesAmount.total_price.value*0.06;
        } else if (moment(datePrevFrom).isBefore('2022-06-07') && moment(datePrevTo).isAfter('2022-06-07')) {
            // fees some 10% and some 3%
            const sourceFeesChangeBefore = H.ElasticSearch.getTotalDepFeesWithChange(datePrevFrom, datePrevTo, 0);
            const esQueryFeesChangeBefore = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceFeesChangeBefore}`};

            const sourceFeesChangeAfter = H.ElasticSearch.getTotalDepFeesWithChange(datePrevFrom, datePrevTo, 1);
            const esQueryFeesChangeAfter = {"es": "daa", "query": "aggregate", "index": "asset_transaction_history", "src": `${sourceFeesChangeAfter}`};

            const [
                dataFeesAmountBeforePrev,
                dataFeesAmountAfterPrev,
            ] = yield all([
                call(getDataFromElasticSearch, esQueryFeesChangeBefore, ENDPOINT.GET_DEP_SALES, jwtToken),
                call(getDataFromElasticSearch, esQueryFeesChangeAfter, ENDPOINT.GET_DEP_SALES, jwtToken),
            ]);
            auctionNewFeeTotalPrev = dataFeesAmountBeforePrev.total_price.value*0.2 + dataFeesAmountAfterPrev.total_price.value*0.06;
        }

        const [
            dataWithdrawFeesAmount,
            dataWithdrawFeesAmountPrev,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryDataWithdrawFees, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryDataWithdrawFeesPrev, ENDPOINT.GET_DEP_SALES, jwtToken),
        ]);

        // Fetch all auction sales data
        const allDepFeesValue = {
            total: dataWithdrawFeesAmount.dep_total_amount.value + auctionNewFeeTotal,
            prev: dataWithdrawFeesAmountPrev.dep_total_amount.value + auctionNewFeeTotalPrev,
            auction: auctionNewFeeTotal,
            withdraw: dataWithdrawFeesAmount.dep_total_amount.value
        };
        yield put(setAllDepFeesData(allDepFeesValue));

        // Active Wallet
        const activeWalletCountValue = {
            current : dataAucStoreAmount.active_wallet_count.value,
            prev: dataAucStoreAmountPrev.active_wallet_count.value,
            auction: dataAucStoreAmount.kind.buckets[0].unique_pmid_from_to.value,
            store: dataAucStoreAmount.kind.buckets[1].unique_pmid_from.value,
        };
        yield put(setActiveWalletData(activeWalletCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.allDepSales = false;
        loadingUpdate2.allTxCount = false;
        loadingUpdate2.allDepFees = false;
        loadingUpdate2.activeWallet = false;
        loadingUpdate2.txAuction = false;
        loadingUpdate2.txStore = false;
        loadingUpdate2.activeWallet = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getFeesOverviewData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.allDepFees = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        // prev
        const rangeStartPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEndPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQuerWithdrawFeesAmount = H.SqlHelper.getMarketWithDrawalFeesInRange({range: [rangeStart, rangeEnd]});
        const sqlQuerWithdrawFeesAmountPrev = H.SqlHelper.getMarketWithDrawalFeesInRange({range: [rangeStartPrev, rangeEndPrev]});

        const [
            dataWithdrawFeesAmount,
            dataWithdrawFeesAmountPrev,
        ] = yield all([
            call(getDataFromSxi, sqlQuerWithdrawFeesAmount, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQuerWithdrawFeesAmountPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.allDepFees = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getTxCountOverviewData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.allTxCount = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        let unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('X'),
            moment(datePrevTo+" 23:59:59+8:00").format('X')
        ];

        const sourceCountAuction = H.ElasticSearch.getCountAuctionTx(dateFrom, dateTo);
        const esQueryData = {"es": "daa", "query": "searchCount", "index": "receipts-*", "src": `${sourceCountAuction}`};

        const sourceCountStore = H.ElasticSearch.getCountStoreTx(dateFrom, dateTo);
        const esQueryDataStore = {"es": "daa", "query": "searchCount", "index": "receipts-*", "src": `${sourceCountStore}`};

        const sourceCountAuctionPrev = H.ElasticSearch.getCountAuctionTx(datePrevFrom, datePrevTo);
        const esQueryCountAuctionPrev = {"es": "daa", "query": "searchCount", "index": "receipts-*", "src": `${sourceCountAuctionPrev}`};

        const sourceCountStorePrev = H.ElasticSearch.getCountStoreTx(datePrevFrom, datePrevTo);
        const esQueryCountStorePrev = {"es": "daa", "query": "searchCount", "index": "receipts-*", "src": `${sourceCountStorePrev}`};

        const [
            auctionCount,
            storeCount,
            auctionCountPrev,
            storeCountPrev,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryData, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryDataStore, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryCountAuctionPrev, ENDPOINT.GET_DEP_SALES, jwtToken),
            call(getDataFromElasticSearch, esQueryCountStorePrev, ENDPOINT.GET_DEP_SALES, jwtToken),
        ]);

        const allTxCountValue = {
            total: auctionCount + storeCount,
            prev: auctionCountPrev + storeCountPrev,
            auction: auctionCount,
            store: storeCount,
        };
        yield put(setAllTxCountData(allTxCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.allTxCount = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getAuctionTxStatsData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.txAuction = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        let unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('X'),
            moment(datePrevTo+" 23:59:59+8:00").format('X')
        ];

        const sqlQueryMaxAuctionTx = {
            "query": `SELECT MAX(DISTINCT amount) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAvgAuctionTx = {
            "query": `SELECT AVG(DISTINCT amount) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMinAuctionTx = {
            "query": `SELECT MIN(DISTINCT amount) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMedianAuctionTx = H.SqlHelper.getMedianData('NftDepReceiptAuctionProd', 'amount', `logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`);
        const sqlQueryModeAuctionTx = H.SqlHelper.getModeData('NftDepReceiptAuctionProd', 'amount', `logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`);

        // Prev query
        const sqlQueryMaxAuctionTxPrev = {
            "query": `SELECT MAX(DISTINCT amount) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAvgAuctionTxPrev = {
            "query": `SELECT AVG(DISTINCT amount) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMinAuctionTxPrev = {
            "query": `SELECT MIN(DISTINCT amount) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMedianAuctionTxPrev = H.SqlHelper.getMedianData('NftDepReceiptAuctionProd', 'amount', `logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`);
        const sqlQueryModeAuctionTxPrev = H.SqlHelper.getModeData('NftDepReceiptAuctionProd', 'amount', `logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`);

        const [
            tempMaxAuction,
            tempAvgAuction,
            tempMinAuction,
            tempMedianAuction,
            tempModeAuction,
            tempMaxAuctionPrev,
            tempAvgAuctionPrev,
            tempMinAuctionPrev,
            tempMedianAuctionPrev,
            tempModeAuctionPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQueryMaxAuctionTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAvgAuctionTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMinAuctionTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMedianAuctionTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryModeAuctionTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMaxAuctionTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAvgAuctionTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMinAuctionTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMedianAuctionTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryModeAuctionTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedAuctionMax = JSON.parse(tempMaxAuction.body);
        const parsedAuctionAvg = JSON.parse(tempAvgAuction.body);
        const parsedAuctionMin = JSON.parse(tempMinAuction.body);
        const parsedAuctionMedian = JSON.parse(tempMedianAuction.body);
        const parsedAuctionMode = JSON.parse(tempModeAuction.body);
        const parsedAuctionMaxPrev = JSON.parse(tempMaxAuctionPrev.body);
        const parsedAuctionAvgPrev = JSON.parse(tempAvgAuctionPrev.body);
        const parsedAuctionMinPrev = JSON.parse(tempMinAuctionPrev.body);
        const parsedAuctionMedianPrev = JSON.parse(tempMedianAuctionPrev.body);
        const parsedAuctionModePrev = JSON.parse(tempModeAuctionPrev.body);

        const auctionTxStatsData = [
            {
                title: "最大値",
                type: "max",
                current: parsedAuctionMax[0][0]['longValue'],
                prev: parsedAuctionMaxPrev[0][0]['longValue']
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(parsedAuctionAvg[0][0]['stringValue']).toFixed(0),
                prev: Number(parsedAuctionAvgPrev[0][0]['stringValue']).toFixed(0)
            },
            {
                title: "最小値",
                type: "min",
                current: parsedAuctionMin[0][0]['longValue'],
                prev: parsedAuctionMinPrev[0][0]['longValue']
            },
            {
                title: "中央値",
                type: "median",
                current: Number(parsedAuctionMedian[0][0]['stringValue']).toFixed(0),
                prev: Number(parsedAuctionMedianPrev[0][0]['stringValue']).toFixed(0)
            },
            {
                title: "最頻値",
                type: "mode",
                current: parsedAuctionMode[0][0]['longValue'],
                prev: parsedAuctionModePrev[0][0]['longValue']
            },
        ];
        yield put(setTxAuctionStatsData(auctionTxStatsData));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.txAuction = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getStoreTxStatsData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.txStore = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        let unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('X'),
            moment(datePrevTo+" 23:59:59+8:00").format('X')
        ];

        const sqlQueryMaxStoreTx = {
            "query": `SELECT MAX(DISTINCT amount) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAvgStoreTx = {
            "query": `SELECT AVG(DISTINCT amount) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMinStoreTx = {
            "query": `SELECT MIN(DISTINCT amount) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMedianStoreTx = H.SqlHelper.getMedianData('NftDepReceiptStoreProd', 'amount', `logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`);
        const sqlQueryModeStoreTx = H.SqlHelper.getModeData('NftDepReceiptStoreProd', 'amount', `logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`);

        // Prev query
        const sqlQueryMaxStoreTxPrev = {
            "query": `SELECT MAX(DISTINCT amount) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryAvgStoreTxPrev = {
            "query": `SELECT AVG(DISTINCT amount) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMinStoreTxPrev = {
            "query": `SELECT MIN(DISTINCT amount) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };
        const sqlQueryMedianStoreTxPrev = H.SqlHelper.getMedianData('NftDepReceiptStoreProd', 'amount', `logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`);
        const sqlQueryModeStoreTxPrev = H.SqlHelper.getModeData('NftDepReceiptStoreProd', 'amount', `logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`);

        const [
            tempMaxStore,
            tempAvgStore,
            tempMinStore,
            tempMedianStore,
            tempModeStore,
            tempMaxStorePrev,
            tempAvgStorePrev,
            tempMinStorePrev,
            tempMedianStorePrev,
            tempModeStorePrev,
        ] = yield all([
            call(getDataFromRDS, sqlQueryMaxStoreTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAvgStoreTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMinStoreTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMedianStoreTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryModeStoreTx, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMaxStoreTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryAvgStoreTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMinStoreTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryMedianStoreTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryModeStoreTxPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);

        const parsedStoreMax = JSON.parse(tempMaxStore.body);
        const parsedStoreAvg = JSON.parse(tempAvgStore.body);
        const parsedStoreMin = JSON.parse(tempMinStore.body);
        const parsedStoreMedian = JSON.parse(tempMedianStore.body);
        const parsedStoreMode = JSON.parse(tempModeStore.body);
        const parsedStoreMaxPrev = JSON.parse(tempMaxStorePrev.body);
        const parsedStoreAvgPrev = JSON.parse(tempAvgStorePrev.body);
        const parsedStoreMinPrev = JSON.parse(tempMinStorePrev.body);
        const parsedStoreMedianPrev = JSON.parse(tempMedianStorePrev.body);
        const parsedStoreModePrev = JSON.parse(tempModeStorePrev.body);

        const storeTxStatsData = [
            {
                title: "最大値",
                type: "max",
                current: parsedStoreMax[0][0]['longValue'],
                prev: parsedStoreMaxPrev[0][0]['longValue']
            },
            {
                title: "平均値",
                type: "avg",
                current: Number(parsedStoreAvg[0][0]['stringValue']).toFixed(0),
                prev: Number(parsedStoreAvgPrev[0][0]['stringValue']).toFixed(0)
            },
            {
                title: "最小値",
                type: "min",
                current: parsedStoreMin[0][0]['longValue'],
                prev: parsedStoreMinPrev[0][0]['longValue']
            },
            {
                title: "中央値",
                type: "median",
                current: Number(parsedStoreMedian[0][0]['stringValue']).toFixed(0),
                prev: Number(parsedStoreMedianPrev[0][0]['stringValue']).toFixed(0)
            },
            {
                title: "最頻値",
                type: "mode",
                current: parsedStoreMode[0][0]['longValue'],
                prev: parsedStoreModePrev[0][0]['longValue']
            },
        ];
        yield put(setTxStoreStatsData(storeTxStatsData));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.txStore = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getActiveWalletOverviewData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.activeWallet = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        let unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('X'),
            moment(datePrevTo+" 23:59:59+8:00").format('X')
        ];
        // console.log("TS LOG: ", unixStampTime);
        const sqlQueryCountActiveWallet = {
            // "query": `SELECT count(DISTINCT fromPmid, toPmid) AS count FROM NftDepReceiptProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "query": `SELECT COUNT(*) FROM
            ( SELECT fromPmid FROM NftDepReceiptProd WHERE fromPmid != 'none' AND logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}
              UNION
              SELECT toPmid FROM NftDepReceiptProd WHERE toPmid != 'none' AND logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}
            ) AS tmp`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountActiveWalletAuction = {
            "query": `SELECT COUNT(*) FROM
            ( SELECT fromPmid FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}
              UNION
              SELECT toPmid FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}
            ) AS tmp`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountActiveWalletStore = {
            // "query": `SELECT COUNT(*) FROM
            // ( SELECT fromPmid FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}
            //   UNION
            //   SELECT toPmid FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}
            // ) AS tmp`,
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]};`,
            "database": "KpiDashboard",
            "type": "list"
        };

        // Prev query
        const sqlQueryCountActiveWalletPrev = {
            //"query": `SELECT count(DISTINCT fromPmid, toPmid) AS count FROM NftDepReceiptProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]};`,
            "query": `SELECT COUNT(*) FROM
            ( SELECT fromPmid FROM NftDepReceiptProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}
              UNION
              SELECT toPmid FROM NftDepReceiptProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}
            ) AS tmp`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const [
            tempActiveWallet,
            tempActiveWalletPrev,
            tempActiveWalletAuction,
            tempActiveWalletStore,
        ] = yield all([
            call(getDataFromRDS, sqlQueryCountActiveWallet, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountActiveWalletPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountActiveWalletAuction, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountActiveWalletStore, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedActiveWallet = JSON.parse(tempActiveWallet.body);
        const parsedActiveWalletPrev = JSON.parse(tempActiveWalletPrev.body);
        const parsedActiveWalletAuction = JSON.parse(tempActiveWalletAuction.body);
        const parsedActiveWalletStore = JSON.parse(tempActiveWalletStore.body);

        const activeWalletCountValue = {
            current : parsedActiveWallet[0][0]['longValue'],
            prev: parsedActiveWalletPrev[0][0]['longValue'],
            auction: parsedActiveWalletAuction[0][0]['longValue'],
            store: parsedActiveWalletStore[0][0]['longValue'],
        };
        yield put(setActiveWalletData(activeWalletCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.activeWallet = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getTxUniqueNewData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.txUniqueNew = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        let unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        let unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('X'),
            moment(datePrevTo+" 23:59:59+8:00").format('X')
        ];

        const sqlQueryCountTxSellUnique = {
            "query": `SELECT COUNT(DISTINCT toPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxBuyUnique = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };

        // Prev query
        const sqlQueryCountTxSellPrev = {
            "query": `SELECT COUNT(DISTINCT toPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxBuyPrev = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxSellNewUnique = {
            "query": `SELECT COUNT(DISTINCT toPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} AND toPmid IN (SELECT toPmid FROM NftDepReceiptAuctionProd GROUP BY toPmid HAVING COUNT(*) = 1);`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxBuyNewUnique = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} AND fromPmid IN (SELECT fromPmid FROM NftDepReceiptAuctionProd GROUP BY fromPmid HAVING COUNT(*) = 1);`,
            "database": "KpiDashboard",
            "type": "list"
        };

        // Prev query
        const sqlQueryCountTxSellNewPrev = {
            "query": `SELECT COUNT(DISTINCT toPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]} AND toPmid IN (SELECT toPmid FROM NftDepReceiptAuctionProd GROUP BY toPmid HAVING COUNT(*) = 1);`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxBuyNewPrev = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptAuctionProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]} AND fromPmid IN (SELECT fromPmid FROM NftDepReceiptAuctionProd GROUP BY fromPmid HAVING COUNT(*) = 1);`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const [
            tempTxSell,
            tempTxBuy,
            tempTxSellPrev,
            tempTxBuyPrev,
            tempTxSellNew,
            tempTxBuyNew,
            tempTxSellNewPrev,
            tempTxBuyNewPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQueryCountTxSellUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxBuyUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxSellPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxBuyPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxSellNewUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxBuyNewUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxSellNewPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxBuyNewPrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parseTxSell = JSON.parse(tempTxSell.body);
        const parsedTxBuy = JSON.parse(tempTxBuy.body);
        const parseTxSellPrev = JSON.parse(tempTxSellPrev.body);
        const parsedTxBuyPrev = JSON.parse(tempTxBuyPrev.body);
        const parseTxSellNew = JSON.parse(tempTxSellNew.body);
        const parsedTxBuyNew = JSON.parse(tempTxBuyNew.body);
        const parseTxSellNewPrev = JSON.parse(tempTxSellNewPrev.body);
        const parsedTxBuyNewPrev = JSON.parse(tempTxBuyNewPrev.body);

        const txUniqueCountValue = {
            uniqueSellNew: {
                current : parseTxSellNew[0][0]['longValue'],
                prev: parseTxSellNewPrev[0][0]['longValue'],
            },
            uniqueSellRepeat: {
                current : parseTxSell[0][0]['longValue'] - parseTxSellNew[0][0]['longValue'],
                prev: parseTxSellPrev[0][0]['longValue'] - parseTxSellNewPrev[0][0]['longValue'],
            },
            uniqueBuyNew: {
                current : parsedTxBuyNew[0][0]['longValue'],
                prev: parsedTxBuyNewPrev[0][0]['longValue'],
            },
            uniqueBuyRepeat: {
                current : parsedTxBuy[0][0]['longValue'] - parsedTxBuyNew[0][0]['longValue'],
                prev: parsedTxBuyPrev[0][0]['longValue'] - parsedTxBuyNewPrev[0][0]['longValue'],
            },
        };
        yield put(setTxUniqueNewData(txUniqueCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.txUniqueNew = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getTxStoreUniqueNewData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.txUniqueNewStore = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        const unixStampTime = [
            moment(dateFrom+" 00:00:00+8:00").format('X'),
            moment(dateTo+" 23:59:59+8:00").format('X')
        ];
        const unixStampTimePrev = [
            moment(datePrevFrom+" 00:00:00+8:00").format('X'),
            moment(datePrevTo+" 23:59:59+8:00").format('X')
        ];

        const sqlQueryCountTxStoreBuyNewUnique = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]} AND fromPmid IN (SELECT fromPmid FROM NftDepReceiptStoreProd GROUP BY fromPmid HAVING COUNT(*) = 1);`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxStoreBuyUnique = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTime[0]} AND ${unixStampTime[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };

        // Prev
        const sqlQueryCountTxStoreBuyNewUniquePrev = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]} AND fromPmid IN (SELECT fromPmid FROM NftDepReceiptStoreProd GROUP BY fromPmid HAVING COUNT(*) = 1);`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const sqlQueryCountTxStoreBuyUniquePrev = {
            "query": `SELECT COUNT(DISTINCT fromPmid) FROM NftDepReceiptStoreProd WHERE logDate BETWEEN ${unixStampTimePrev[0]} AND ${unixStampTimePrev[1]}`,
            "database": "KpiDashboard",
            "type": "list"
        };

        const [
            tempTxStoreNewBuy,
            tempTxStoreBuy,
            tempTxStoreNewBuyPrev,
            tempTxStoreBuyPrev,
        ] = yield all([
            call(getDataFromRDS, sqlQueryCountTxStoreBuyNewUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxStoreBuyUnique, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxStoreBuyNewUniquePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
            call(getDataFromRDS, sqlQueryCountTxStoreBuyUniquePrev, ENDPOINT.GET_DATA_RDS, jwtToken),
        ]);
        const parsedTxStoreNewBuy = JSON.parse(tempTxStoreNewBuy.body);
        const parsedTxStoreBuy = JSON.parse(tempTxStoreBuy.body);
        const parsedTxStoreNewBuyPrev = JSON.parse(tempTxStoreNewBuyPrev.body);
        const parsedTxStoreBuyPrev= JSON.parse(tempTxStoreBuyPrev.body);

        const txStoreUniqueCountValue = {
            uniqueStoreNew: {
                current : parsedTxStoreNewBuy[0][0]['longValue'],
                prev: parsedTxStoreNewBuyPrev[0][0]['longValue'],
            },
            uniqueStoreRepeat: {
                current : parsedTxStoreBuy[0][0]['longValue'] - parsedTxStoreNewBuy[0][0]['longValue'],
                prev: parsedTxStoreBuyPrev[0][0]['longValue'] - parsedTxStoreNewBuyPrev[0][0]['longValue'],
            },
        };
        yield put(setTxStoreUniqueNewData(txStoreUniqueCountValue));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.txUniqueNewStore = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

export function* getTodaysDepSales() {
    try {
        const jwtToken = yield call(getJwtToken);
        //let storedDepPrice = yield select(depPrice);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.todaysDepSales = true;
        yield put(setDataLoading(loadingUpdate));

        const currentSgtDate = moment().tz("Asia/Singapore").format('YYYY-MM-DD')
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${currentSgtDate}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${currentSgtDate}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQueryTodaysSalesPrimary = H.SqlHelper.getTodaysDepSales({type: 'OFFICIAL_STORE', range: [rangeStart, rangeEnd]});
        const sqlQueryTodaysSales2nd = H.SqlHelper.getTodaysDepSales({type: 'USERS', range: [rangeStart, rangeEnd]});
        // const sourceRange = H.ElasticSearch.getTodaysDepSales();
        // const esQueryData = {"es": "daa", "query": "sales", "index": "receipts-*", "src": `${sourceRange}`};

        const [
            dataDepRates,
            dataPrimary,
            dataSedondary,
        ] = yield all([
            call(getCurrentDepExchangeRate),
            call(getDataFromSxi, sqlQueryTodaysSalesPrimary, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryTodaysSales2nd, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);

        // let auctionCountTotal = 0, auctionDepTotal = 0, storeCountTotal = 0, storeDepTotal = 0;
        // _.each(dataTemp, function(data) {
        //     _.each(data[3]['buckets'], function(buckets) {
        //         if (buckets["key"] === "auction") {
        //             auctionCountTotal = auctionCountTotal + buckets["doc_count"];
        //             auctionDepTotal = auctionDepTotal + buckets[1]['value'];
        //         } else {
        //             storeCountTotal = storeCountTotal + buckets["doc_count"];
        //             storeDepTotal = storeDepTotal + buckets[1]['value'];
        //         }
        //     });
        // });

        const todaysDepSalesObj = {
            auction: {
                count: dataSedondary[0]['count'],
                dep: dataSedondary[0]['orderPrice'],
                usd: dataSedondary[0]['orderPrice'] * dataDepRates.deapcoin.usd,
                sgd: dataSedondary[0]['orderPrice'] * dataDepRates.deapcoin.sgd,
                jpy: dataSedondary[0]['orderPrice'] * dataDepRates.deapcoin.jpy,
            },
            store: {
                count: dataPrimary[0]['count'],
                dep: dataPrimary[0]['orderPrice'],
                usd: dataPrimary[0]['orderPrice'] * dataDepRates.deapcoin.usd,
                sgd: dataPrimary[0]['orderPrice'] * dataDepRates.deapcoin.sgd,
                jpy: dataPrimary[0]['orderPrice'] * dataDepRates.deapcoin.jpy,
            },
        };
        yield put(setTodaysSalesData(todaysDepSalesObj));

        // // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.todaysDepSales = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

// @todo, not in use, remove once no issue detected
export function* getWinnnigBidData() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.todaysDepSales = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        const sourceRange = H.ElasticSearch.getCountOfWinningBid(dateFrom, dateTo);
        const esQueryData = {"es": "daa", "query": "searchCount", "index": "auction_history", "src": `${sourceRange}`};

        const [
            dataTemp,
            dataDepRates,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryData, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
            call(getCurrentDepExchangeRate),
        ]);

        // let auctionCountTotal = 0, auctionDepTotal = 0, storeCountTotal = 0, storeDepTotal = 0;
        // _.each(dataTemp, function(data) {
        //     _.each(data[3]['buckets'], function(buckets) {
        //         if (buckets["key"] === "auction") {
        //             auctionCountTotal = auctionCountTotal + buckets["doc_count"];
        //             auctionDepTotal = auctionDepTotal + buckets[1]['value'];
        //         } else {
        //             storeCountTotal = storeCountTotal + buckets["doc_count"];
        //             storeDepTotal = storeDepTotal + buckets[1]['value'];
        //         }
        //     });
        // });

        // const todaysDepSalesObj = {
        //     auction: {
        //         count: auctionCountTotal,
        //         dep: auctionDepTotal,
        //         usd: auctionDepTotal * dataDepRates.deapcoin.usd,
        //         sgd: auctionDepTotal * dataDepRates.deapcoin.sgd,
        //         jpy: auctionDepTotal * dataDepRates.deapcoin.jpy,
        //     },
        //     store: {
        //         count: storeCountTotal,
        //         dep: storeDepTotal,
        //         usd: storeDepTotal * dataDepRates.deapcoin.usd,
        //         sgd: storeDepTotal * dataDepRates.deapcoin.sgd,
        //         jpy: storeDepTotal * dataDepRates.deapcoin.jpy,
        //     },
        // };
        // yield put(setTodaysSalesData(todaysDepSalesObj));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.todaysDepSales = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

export function* getCountAuctionBlindAndOtherTx() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.txBlindSnap = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');

        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
 
        // prev
        const rangeStartPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEndPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        const sqlQueryBlindCount = H.SqlHelper.getMarketCountByTxType({marketType: 'BLIND_AUCTION', range: [rangeStart, rangeEnd]});
        const sqlQueryBlindCountPrev = H.SqlHelper.getMarketCountByTxType({marketType: 'BLIND_AUCTION', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryPromptCount = H.SqlHelper.getMarketCountByTxType({marketType: 'PROMPT_PURCHASE', range: [rangeStart, rangeEnd]});
        const sqlQueryPromptCountPrev = H.SqlHelper.getMarketCountByTxType({marketType: 'PROMPT_PURCHASE', range: [rangeStartPrev, rangeEndPrev]});

        const [
            dataBlindCount,
            dataBlindCountPrev,
            dataPromptCount,
            dataPromptCountPrev,
        ] = yield all([
            call(getDataFromSxi, sqlQueryBlindCount, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryBlindCountPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryPromptCount, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryPromptCountPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);

        const txBlindData = {
            count: {
                current: dataBlindCount[0]['COUNT'],
                prev: dataBlindCountPrev[0]['COUNT'],
            }
        };

        const txSnapData = {
            count: {
                current: dataPromptCount[0]['COUNT'],
                prev: dataPromptCountPrev[0]['COUNT'],
            }
        };

        yield put(setTxBlind(txBlindData));
        yield put(setTxSnap(txSnapData));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.txBlindSnap = false;
        yield put(setDataLoading(loadingUpdate2));

    } catch (err) {
        console.log(err);
    }
}

export function* getStakingCountAndDepAmount() {
    try {
        const jwtToken = yield call(getJwtToken);
        const loadingStateBegin = yield select(loading);
        let loadingUpdate = {...loadingStateBegin};
        loadingUpdate.ovStaking = true;
        yield put(setDataLoading(loadingUpdate));

        let dateFrom = yield select(startDate);
        let dateTo = yield select(endDate);
        dateFrom = moment(dateFrom).format('YYYY-MM-DD');
        dateTo = moment(dateTo).format('YYYY-MM-DD');
        const rangeStart = H.FormatDateHelper.convertToUtcFromLocal(`${dateFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEnd = H.FormatDateHelper.convertToUtcFromLocal(`${dateTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        let datePrevFrom = yield select(startPrevDate);
        let datePrevTo = yield select(endPrevDate);
        datePrevFrom = moment(datePrevFrom).format('YYYY-MM-DD');
        datePrevTo = moment(datePrevTo).format('YYYY-MM-DD');
        const rangeStartPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevFrom}T00:00:00.000Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');
        const rangeEndPrev = H.FormatDateHelper.convertToUtcFromLocal(`${datePrevTo}T23:59:59.999Z`, "YYYY-MM-DD HH:mm:ss", 'Asia/Singapore');

        // Staking Count
        const sqlQueryGetNewActive = H.SqlHelper.getStakingCountByStatus({status: 'ACTIVE', timeType: 'create_datetime', range: [rangeStart, rangeEnd]});
        const sqlQueryGetNewActivePrev = H.SqlHelper.getStakingCountByStatus({status: 'ACTIVE', timeType: 'create_datetime', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryGetMatured = H.SqlHelper.getStakingCountByStatus({status: 'MATURED', timeType: 'update_datetime', range: [rangeStart, rangeEnd]});
        const sqlQueryGetMaturedPrev = H.SqlHelper.getStakingCountByStatus({status: 'MATURED', timeType: 'update_datetime', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryGetCancel = H.SqlHelper.getStakingCountByStatus({status: 'CANCELED', timeType: 'update_datetime', range: [rangeStart, rangeEnd]});
        const sqlQueryGetCancelPrev = H.SqlHelper.getStakingCountByStatus({status: 'CANCELED', timeType: 'update_datetime', range: [rangeStartPrev, rangeEndPrev]});

        // Staking Amount
        const sqlQueryGetNewAmount = H.SqlHelper.getStakingDepAmountByStatus({status: 'ACTIVE', timeType: 'create_datetime', range: [rangeStart, rangeEnd]});
        const sqlQueryGetNewAmountPrev = H.SqlHelper.getStakingDepAmountByStatus({status: 'ACTIVE', timeType: 'create_datetime', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryGetMaturedAmount = H.SqlHelper.getStakingDepAmountByStatus({status: 'MATURED', timeType: 'update_datetime', range: [rangeStart, rangeEnd]});
        const sqlQueryGetMaturedAmountPrev = H.SqlHelper.getStakingDepAmountByStatus({status: 'MATURED', timeType: 'update_datetime', range: [rangeStartPrev, rangeEndPrev]});
        const sqlQueryGetAmountCancel = H.SqlHelper.getStakingDepAmountByStatus({status: 'CANCELED', timeType: 'update_datetime', range: [rangeStart, rangeEnd]});
        const sqlQueryGetAmountCancelPrev = H.SqlHelper.getStakingDepAmountByStatus({status: 'CANCELED', timeType: 'update_datetime', range: [rangeStartPrev, rangeEndPrev]});

        const [
            dataNewTemp,
            dateNewTempPrev,
            dataMaturedTemp,
            dataMaturedTempPrev,
            dataCancelTemp,
            dataCancelTempPrev,
            dataNewAmountTemp,
            dataNewAmountTempPrev,
            dataMaturedAmountTemp,
            dataMaturedAmountTempPrev,
            dataCancelAmountTemp,
            dataCancelAmountTempPrev,
        ] = yield all([
            call(getDataFromSxi, sqlQueryGetNewActive, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetNewActivePrev, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetMatured, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetMaturedPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetCancel, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetCancelPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetNewAmount, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetNewAmountPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetMaturedAmount, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetMaturedAmountPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetAmountCancel, ENDPOINT.GET_DATA_SXI, jwtToken),
            call(getDataFromSxi, sqlQueryGetAmountCancelPrev, ENDPOINT.GET_DATA_SXI, jwtToken),
        ]);

        // key 0:新規, 1:満了, 2:解約
        const dataStakingOvCount = [
            {
                title: "新規",
                type: "new",
                current: dataNewTemp[0]['COUNT'],
                prev: dateNewTempPrev[0]['COUNT'],
            },
            {
                title: "満了",
                type: "mature",
                current: dataMaturedTemp[0]['COUNT'],
                prev: dataMaturedTempPrev[0]['COUNT'],
            },
            {
                title: "解約",
                type: "cancel",
                current: dataCancelTemp[0]['COUNT'],
                prev: dataCancelTempPrev[0]['COUNT'],
            },
        ]
        const dataStakingOvDep = [
            {
                title: "新規",
                type: "new",
                current: dataNewAmountTemp[0]['SUM'],
                prev: dataNewAmountTempPrev[0]['SUM'],
            },
            {
                title: "満了",
                type: "mature",
                current: dataMaturedAmountTemp[0]['SUM'],
                prev: dataMaturedAmountTempPrev[0]['SUM'],
            },
            {
                title: "解約",
                type: "cancel",
                current: dataCancelAmountTemp[0]['SUM'],
                prev: dataCancelAmountTempPrev[0]['SUM'],
            },
        ];

        yield put(setStakingOvDataCount(dataStakingOvCount));
        yield put(setStakingOvDataDep(dataStakingOvDep));

        // update the loading state
        const loadingStateEnd = yield select(loading);
        let loadingUpdate2 = {...loadingStateEnd};
        loadingUpdate2.ovStaking = false;
        yield put(setDataLoading(loadingUpdate2));
    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_TODAYS_SALES_DATA, getTodaysDepSales);
    //yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getSalesOverviewData);
    yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getSalesOverviewDataSxi);
    //yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getFeesOverviewData);
    //yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getTxCountOverviewData);
    // yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getAuctionTxStatsData);
    // yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getStoreTxStatsData);
    // yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getActiveWalletOverviewData);
    // yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getTxUniqueNewData);
    // yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getTxStoreUniqueNewData);
    // yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getWinnnigBidData);
    //yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getCountAuctionBlindAndOtherTx);
    //yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getFees);
    //yield takeEvery(NFTDEP.GET_SALES_OVERVIEW_DATA, getStakingCountAndDepAmount);
}
