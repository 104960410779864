import React, { useState, useEffect } from "react";
import _ from "lodash";
import clsx from "clsx";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import * as H from "helper";
import {
  getSalesOverviewDataPicTree,
} from "actions";
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) => ({
  titlePaper: {
    paddingTop: 8,
    paddingRight: 8,
    marginBottom: 8,
    marginLeft: 8,
    fontSize: 18,
  },
  statsBox: {
    padding: 8,
  },
  bottomBoxArea: {
    fontSize: 14,
    marginTop: 16,
  },
}));

const SalesStats = ({
  loading,
  allTxCount,
  txFirstTimePurchaseStats,
  depPrice,
  selectedCurrency,
  salesOverview,
}) => {
  const classes = useStyles();

  const handleCurrencyConvert = (amount) => {
    let returnAmount = Number(amount);
    if (selectedCurrency === "usd") {
      returnAmount = returnAmount * depPrice.usd;
    } else if (selectedCurrency === "sgd") {
      returnAmount = returnAmount * depPrice.sgd;
    } else if (selectedCurrency === "jpy") {
      returnAmount = returnAmount * depPrice.jpy;
    }
    return returnAmount;
  };

  const getPrefix = (selectedCurrency) => {
    if (selectedCurrency === "usd") {
      return "US$";
    } else if (selectedCurrency === "sgd") {
      return "S$";
    } else if (selectedCurrency === "jpy") {
      return "¥";
    } else {
      return "";
    }
  };

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: -8, marginBottom: 8, width: "100%" }}
    >
      <Paper style={{ padding: 8, marginBottom: 8, width: "98.6%" }}>
        <>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8, width: "100%" }}
        >
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="コイン通算売上 (2024/4/13~)"
              value={
                loading
                  ? "loading"
                  : salesOverview.length > 0 ? salesOverview[0]["totalCoinAllTime"] : "--"
              }
              valueExtra={`
                JPY: ${
                salesOverview.length > 0
                ? H.FormatNumStrHelper.formatCommaThousand(salesOverview[0]["currencyTtl"])
                : "--"}`
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="コイン売上総額"
              value={
                loading
                  ? "loading"
                  : salesOverview.length > 0 ? salesOverview[0]["totalCoin"] : "--"
              }
              prevValue={
                loading
                  ? "loading"
                  : salesOverview.length > 0 ? salesOverview[0]["totalCoinPrev"] : "--"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="コイン購入件数"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countTx"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countTxPrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="JPY 販売"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["currencySum"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["currencySumPrev"] : "--"}
              suffix="円"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="ユニーク購入者数"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countUnique"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countUniquePrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="初購入者数"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countFirst"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countFirstPrev"] : "--"}
              // lastItem
            />
          </Grid>
        </Grid>

        {/* NFT */}
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8, width: "100%" }}
        >
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="NFT通算売上 (2024/04/13~)"
              value={
                loading
                  ? "loading"
                  : salesOverview.length > 0
                    ? salesOverview[0]["nft1stAmountAllTime"] + salesOverview[0]["nft2ndAmountAllTime"] + salesOverview[0]["nftApiAmountAllTime"]
                    : "--"
              }
              suffix="DEP"
              valueExtra={`
                マケプレ: ${
                salesOverview.length > 0
                ? H.FormatNumStrHelper.formatCommaThousand(salesOverview[0]["nft1stAmountAllTime"] + salesOverview[0]["nft2ndAmountAllTime"])
                : "--"}
                <br />ゲーム内: ${salesOverview.length > 0
                ? H.FormatNumStrHelper.formatCommaThousand(salesOverview[0]["nftApiAmountAllTime"])
                : "--"}`
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="NFT売上総額"
              value={
                loading
                  ? "loading"
                  : salesOverview.length > 0
                    ? salesOverview[0]["nft1stAmount"] + salesOverview[0]["nft2ndAmount"] + salesOverview[0]["nftApiAmount"]
                    : "--"
              }
              prevValue={
                loading
                  ? "loading"
                  : salesOverview.length > 0
                    ? salesOverview[0]["nft1stAmountPrev"] + salesOverview[0]["nft2ndAmountPrev"] + salesOverview[0]["nftApiAmountPrev"]
                    : "--"
              }
              suffix="DEP"
              valueExtra={`
                マケプレ: ${
                salesOverview.length > 0
                ? H.FormatNumStrHelper.formatCommaThousand(salesOverview[0]["nft1stAmount"] + salesOverview[0]["nft2ndAmount"])
                : "--"}
                <br />ゲーム内: ${salesOverview.length > 0
                ? H.FormatNumStrHelper.formatCommaThousand(salesOverview[0]["nftApiAmount"])
                : "--"}`
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="NFT取引件数"
              value={loading ? "loading" : salesOverview.length > 0
                ? salesOverview[0]["nft1stCount"] + salesOverview[0]["nft2ndCount"] + salesOverview[0]["nftApiCount"]
                : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countTxPrev"] : "--"}
              valueExtra={`
                マケプレ: ${
                salesOverview.length > 0
                ? salesOverview[0]["nft1stCount"]
                : "--"}
                <br />ゲーム内: ${salesOverview.length > 0
                ? salesOverview[0]["nftApiCount"]
                : "--"}`
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="NFTオークション販売"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["nft2ndCount"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["nft2ndCountPrev"] : "--"}
              suffix="DEP"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="NFTストア販売"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["nft1stAmount"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["nft1stAmountPrev"] : "--"}
              suffix="DEP"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="NFTゲーム内販売"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["nftApiAmount"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["nftApiAmountPrev"] : "--"}
              suffix="DEP"
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="初購入者数"
              value={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countFirst"] : "--"}
              prevValue={loading ? "loading" : salesOverview.length > 0 ? salesOverview[0]["countFirstPrev"] : "--"}
              lastItem
            />
          </Grid> */}
        </Grid>
        </>
      </Paper>
    </Grid>
  );
};

SalesStats.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  allTxCount: state.nftDep.allTxCount,
  txFirstTimePurchaseStats: state.nftDep.txFirstTimePurchaseStats,
  depPrice: state.page.depPrice,
  selectedCurrency: state.page.selectedCurrency,
  salesOverview: state.pictree.salesOverview,
});

const mapDispatchToProps = (dispatch) => ({
  getSalesOverviewDataPicTree: () => dispatch(getSalesOverviewDataPicTree()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(SalesStats));
