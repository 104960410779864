import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setHomePictreeUser,
  setHomePictreeCheckIn,
  setHomePictreePhotoShoot,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";
import seasonConfig from '../../utils/pictree/SeasonDataConfig';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;
export const startPrevDate = state => state.page.prevDateRangePicTree[0];
export const endPrevDate = state => state.page.prevDateRangePicTree[1];

const getIndexForDate = (data, date) => {
  const targetDate = moment(date);
  for (let i = 0; i < data.length; i++) {
      const startDate = moment(data[i].dateRange[0]);
      const endDate = moment(data[i].dateRange[1]);
      if (targetDate.isBetween(startDate, endDate, null, '[]')) {
        return i;
      }
  }
  return -1; // If no matching date range is found
};

export function* getPictreeDataHomeUser(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const datePrevFrom = yield select(startPrevDate);
    const datePrevTo = yield select(endPrevDate);

    // Date Handling
    const getDateDiff = (dateFrom, subDays) => {
      return moment(dateFrom).tz("Asia/Tokyo").subtract(subDays, 'days').format('YYYY-MM-DD');
    }
    const wauPrevEnd = getDateDiff(rangeTarget[1], 7);
    const wauPrevBegin = getDateDiff(wauPrevEnd, 6);
    const mauPrevEnd = getDateDiff(rangeTarget[1], 30);
    const mauPrevBegin = getDateDiff(mauPrevEnd, 29);

    const sqlQueryUsers = {"query": `SELECT COUNT(*) AS total_registrations FROM player`};
    const sqlQueryUsersInRange = {"query": `
        SELECT COUNT(*) AS registrations_in_range
        FROM player
        WHERE CONVERT_TZ(registered_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59';
    `};
    const sqlQueryUsersInRangePrev = {"query": `
        SELECT COUNT(*) AS registrations_in_range_prev
        FROM player
        WHERE CONVERT_TZ(registered_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59';
    `};
    const sqlQueryCountDauUser = {
      "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                BETWEEN '${rangeTarget[1]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountDauUserPrev = {
      "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                BETWEEN '${getDateDiff(rangeTarget[1], 1)} 00:00:00' AND '${getDateDiff(rangeTarget[1], 1)} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountWauUser = {
      "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                BETWEEN '${getDateDiff(rangeTarget[1], 7)} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountWauUserPrev = {
      "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                BETWEEN '${wauPrevBegin} 00:00:00' AND '${wauPrevEnd} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountMauUser = {
      "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                BETWEEN '${getDateDiff(rangeTarget[1], 30)} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountMauUserPrev = {
      "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                BETWEEN '${mauPrevBegin} 00:00:00' AND '${mauPrevEnd} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };

    const sqlQueryUsersPmid = {"query": `
      SELECT COUNT(*) AS total_pmid_sync
      FROM player ply
      JOIN account_connect_for_playmining acnp ON ply.id = acnp.player_id
      JOIN player_event	pe ON ply.id = pe.player_id
      WHERE pe.type = 'CONNECTED_TO_PLAYMINING'
    `};
    const sqlQueryUsersPmidInRange = {"query": `
      SELECT COUNT(*) AS pmid_sync_in_range
      FROM player ply
      JOIN account_connect_for_playmining acnp ON ply.id = acnp.player_id
      JOIN player_event	pe ON ply.id = pe.player_id
      WHERE CONVERT_TZ(occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
      AND pe.type = 'CONNECTED_TO_PLAYMINING';
    `};
    const sqlQueryUsersPmidInRangePrev = {"query": `
      SELECT COUNT(*) AS pmid_sync_in_range_prev
      FROM player ply
      JOIN account_connect_for_playmining acnp ON ply.id = acnp.player_id
      JOIN player_event	pe ON ply.id = pe.player_id
      WHERE CONVERT_TZ(occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
      AND pe.type = 'CONNECTED_TO_PLAYMINING';
    `};
    const [dataHomeUserTotal, dataHomeUser, dataHomeUserPrev,
        dataHomeDau, dataHomeDauPrev, dataHomeWau, dataHomeWauPrev, dataHomeMau, dataHomeMauPrev,
        dataTotalPmidSync, dataPmidSync, dataPmidSyncPrev,] = yield all([
        call(getDataFromSxi, sqlQueryUsers, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
        call(getDataFromSxi, sqlQueryUsersInRange, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
        call(getDataFromSxi, sqlQueryUsersInRangePrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
        call(getDataFromRDS, sqlQueryCountDauUser, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountDauUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountWauUser, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountWauUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountMauUser, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountMauUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromSxi, sqlQueryUsersPmid, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
        call(getDataFromSxi, sqlQueryUsersPmidInRange, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
        call(getDataFromSxi, sqlQueryUsersPmidInRangePrev, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);
    const totalUsers = dataHomeUserTotal === null ? 0 : dataHomeUserTotal[0]["total_registrations"];
    const countUsersInRange = dataHomeUser === null ? 0 : dataHomeUser[0]["registrations_in_range"];
    const countUsersInRangePrev = dataHomeUserPrev === null ? 0 : dataHomeUserPrev[0]["registrations_in_range_prev"];

    const totalPmid = dataTotalPmidSync === null ? 0 : dataTotalPmidSync[0]["total_pmid_sync"];
    const countPmidInRange = dataPmidSync === null ? 0 : dataPmidSync[0]["pmid_sync_in_range"];
    const countPmidInRangePrev = dataPmidSyncPrev === null ? 0 : dataPmidSyncPrev[0]["pmid_sync_in_range_prev"];

    const countDau = JSON.parse(dataHomeDau.body);
    const countDauPrev = JSON.parse(dataHomeDauPrev.body);
    const countWau = JSON.parse(dataHomeWau.body);
    const countWauPrev = JSON.parse(dataHomeWauPrev.body);
    const countMau = JSON.parse(dataHomeMau.body);
    const countMauPrev = JSON.parse(dataHomeMauPrev.body);

    const homeUserData = {
        total: totalUsers,
        newTotal: countUsersInRange,
        newOrg: countUsersInRange,
        newOrgPrev: countUsersInRangePrev,
        pmidTotal: totalPmid,
        pmidNew: countPmidInRange,
        pmidPrev: countPmidInRangePrev,
        // newAd: dataArppuArray,
        dau: countDau[0][0]["longValue"],
        dauPrev: countDauPrev[0][0]["longValue"],
        wau: countWau[0][0]["longValue"],
        wauPrev: countWauPrev[0][0]["longValue"],
        mau: countMau[0][0]["longValue"],
        mauPrev: countMauPrev[0][0]["longValue"],
    };

    yield put(setHomePictreeUser(homeUserData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataHomeCheckIn(action) {
    try {
      yield put(setPageTopLoader(true));
      const jwtToken = yield call(getJwtToken);
      const rangeTarget = yield select(dateRange);
      const datePrevFrom = yield select(startPrevDate);
      const datePrevTo = yield select(endPrevDate);

      const sqlQueryCountCheckIn = {
        "query": `SELECT COUNT(*) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInPrev = {
        "query": `SELECT COUNT(*) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInKisu = {
        "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInKisuPrev = {
        "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInUniqueUser = {
        "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountCheckInUniqueUserPrev = {
        "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountMostCheckIn = {
        "query": `SELECT denchuId, COUNT(*) AS transaction_count FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
                  GROUP BY denchuId ORDER BY transaction_count DESC LIMIT 1;`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountMostCheckInUser = {
        "query": `SELECT uid, COUNT(*) AS transaction_count FROM DenchuCheckInEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
                  GROUP BY uid ORDER BY transaction_count DESC LIMIT 1;`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const sqlQueryCountUniqueActiveUser = {
        "query": `SELECT COUNT(DISTINCT deviceId) FROM DenchuGamePlayStartPmEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
                  BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };

      const [dataHomeCheckIn, dataHomeCheckInPrev, dataHomeCheckInKisu, dataHomeCheckInKisuPrev,
          dataHomeCheckInUniqueUser, dataHomeCheckInUniqueUserPrev, dataMostCheckIn, dataMostCheckInUser, dataUniqueActive ] = yield all([
          call(getDataFromRDS, sqlQueryCountCheckIn, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInKisu, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInKisuPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInUniqueUser, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountCheckInUniqueUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountMostCheckIn, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountMostCheckInUser, ENDPOINT.GET_DATA_USERS, jwtToken),
          call(getDataFromRDS, sqlQueryCountUniqueActiveUser, ENDPOINT.GET_DATA_USERS, jwtToken),
      ]);
      const countCheckIn = JSON.parse(dataHomeCheckIn.body);
      const countCheckInValue = countCheckIn[0][0]["longValue"];
      const countCheckInPrev = JSON.parse(dataHomeCheckInPrev.body);
      const countCheckInValuePrev = countCheckInPrev[0][0]["longValue"];

      // Kisu
      const countCheckInKisu = JSON.parse(dataHomeCheckInKisu.body);
      const countCheckInKisuValue = countCheckInKisu[0][0]["longValue"];
      const countCheckInKisuPrev = JSON.parse(dataHomeCheckInKisuPrev.body);
      const countCheckInKisuValuePrev = countCheckInKisuPrev[0][0]["longValue"];

      // Unique Users
      const countCheckInUniqueUser = JSON.parse(dataHomeCheckInUniqueUser.body);
      const countCheckInUniqueUserValue = countCheckInUniqueUser[0][0]["longValue"];
      const countCheckInUniqueUserPrev = JSON.parse(dataHomeCheckInUniqueUserPrev.body);
      const countCheckInUniqueUserValuePrev = countCheckInUniqueUserPrev[0][0]["longValue"];

      // Most CheckIn Kisu
      const countCheckInMost = JSON.parse(dataMostCheckIn.body);
      const countCheckInMostValue = countCheckInMost.length === 0 ? "--" : countCheckInMost[0][0]["stringValue"];

      // Most CheckIn User
      const countCheckInMostUser = JSON.parse(dataMostCheckInUser.body);
      const countCheckInMostUserValue = countCheckInMostUser.length === 0 ? "--" : countCheckInMostUser[0][0]["stringValue"];

      // CheckIn Ratio
      const countUniqueActive = JSON.parse(dataUniqueActive.body);
      const countUniqueActiveValue = countUniqueActive[0][0]["longValue"];
      const checkInRatio = (countCheckInUniqueUserValue / countUniqueActiveValue * 100).toFixed(2);

      const homeCheckInData = {
          checkInCount: countCheckInValue,
          checkInCountPrev: countCheckInValuePrev,
          checkInCountKisu: countCheckInKisuValue,
          checkInCountKisuPrev: countCheckInKisuValuePrev,
          checkInCountUniqueUser: countCheckInUniqueUserValue,
          checkInCountUniqueUserPrev: countCheckInUniqueUserValuePrev,
          checkInMost: countCheckInMostValue,
          checkInMostUser: countCheckInMostUserValue,
          checkInRatio: checkInRatio,
        };
      yield put(setHomePictreeCheckIn(homeCheckInData));
      yield put(setPageTopLoader(false));
    } catch (err) {
      console.log(err);
    }
}

export function* getPictreeDataHomePhotoShoot(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const datePrevFrom = yield select(startPrevDate);
    const datePrevTo = yield select(endPrevDate);

    const sqlQueryCountPhotoDenchu = {
      "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPhotoDenchuPrev = {
      "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPhotoMH = {
      "query": `SELECT COUNT(DISTINCT mhId) FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPhotoMHPrev = {
      "query": `SELECT COUNT(DISTINCT mhId) FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPhotoHH = {
      "query": `SELECT COUNT(DISTINCT hhId) FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPhotoHHPrev = {
      "query": `SELECT COUNT(DISTINCT hhId) FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountUniqueUser = {
      "query": `
        SELECT
          COUNT(DISTINCT uid) AS unique_uid_count
        FROM (
          SELECT uid FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          UNION ALL
          SELECT uid FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          UNION ALL
          SELECT uid FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        ) AS combined_data;`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountUniqueUserPrev = {
      "query": `
        SELECT
          COUNT(DISTINCT uid) AS unique_uid_count
        FROM (
          SELECT uid FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
          UNION ALL
          SELECT uid FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
          UNION ALL
          SELECT uid FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        ) AS combined_data;`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPicCount = {
      "query": `
        SELECT
          SUM(picCount) AS total_pic_count
        FROM (
          SELECT picCount FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          UNION ALL
          SELECT picCount FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
          UNION ALL
          SELECT picCount FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        ) AS combined_data;`,
      "database": "KpiDashboard",
      "type": "list"
    };
    const sqlQueryCountPicCountPrev = {
      "query": `
        SELECT
          SUM(picCount) AS total_pic_count
        FROM (
          SELECT picCount FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
          UNION ALL
          SELECT picCount FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
          UNION ALL
          SELECT picCount FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
        ) AS combined_data;`,
      "database": "KpiDashboard",
      "type": "list"
    };
    // const sqlQueryCountAsset = {
    //   "query": `
    //     SELECT
    //       COUNT(DISTINCT asset_id) AS total_unique_asset_count
    //     FROM (
    //       SELECT denchuId as asset_id FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
    //       UNION
    //       SELECT mhId AS asset_id FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
    //       UNION
    //       SELECT hhId AS asset_id FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
    //     ) AS combined_data;`,
    //   "database": "KpiDashboard",
    //   "type": "list"
    // };
    // const sqlQueryCountAssetPrev = {
    //   "query": `
    //     SELECT
    //       COUNT(DISTINCT asset_id) AS total_unique_asset_count
    //     FROM (
    //       SELECT denchuId as asset_id FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
    //       UNION
    //       SELECT mhId AS asset_id FROM DenchuPhotoShootMhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
    //       UNION
    //       SELECT hhId AS asset_id FROM DenchuPhotoShootHhEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${datePrevFrom} 00:00:00' AND '${datePrevTo} 23:59:59'
    //     ) AS combined_data;`,
    //   "database": "KpiDashboard",
    //   "type": "list"
    // };

    // dupe photoshoot - denchu
    const sqlQueryCountPhotoDupeDenchu = {
      "query": `
        SELECT COUNT(*) AS total_count
        FROM (
            SELECT denchuId
            FROM DenchuPhotoShootDenchuEvtProd
            WHERE
            CONVERT_TZ(
                FROM_UNIXTIME(logDate / 1000),
                'UTC',
                'Asia/Tokyo'
            )  BETWEEN '${rangeTarget[0]} 00:00:00'
            AND '${rangeTarget[1]} 23:59:59'
            GROUP BY denchuId
            HAVING COUNT(*) > 1
        ) AS subquery;
      `,
      "database": "KpiDashboard",
      "type": "list"
    };

    // dupe photoshoot - MH
    const sqlQueryCountPhotoDupeMh = {
      "query": `
        SELECT COUNT(*) AS total_count
        FROM (
            SELECT mhId
            FROM DenchuPhotoShootMhEvtProd
            WHERE
            CONVERT_TZ(
                FROM_UNIXTIME(logDate / 1000),
                'UTC',
                'Asia/Tokyo'
            )  BETWEEN '${rangeTarget[0]} 00:00:00'
            AND '${rangeTarget[1]} 23:59:59'
            GROUP BY mhId
            HAVING COUNT(*) > 1
        ) AS subquery;
      `,
      "database": "KpiDashboard",
      "type": "list"
    };

    // dupe photoshoot - HH
    const sqlQueryCountPhotoDupeHh = {
      "query": `
        SELECT COUNT(*) AS total_count
        FROM (
            SELECT hhId
            FROM DenchuPhotoShootHhEvtProd
            WHERE
            CONVERT_TZ(
                FROM_UNIXTIME(logDate / 1000),
                'UTC',
                'Asia/Tokyo'
            )  BETWEEN '${rangeTarget[0]} 00:00:00'
            AND '${rangeTarget[1]} 23:59:59'
            GROUP BY hhId
            HAVING COUNT(*) > 1
        ) AS subquery;
      `,
      "database": "KpiDashboard",
      "type": "list"
    };

    const [dataPhotoDenchu, dataPhotoDenchuPrev, dataPhotoMh, dataPhotoMhPrev, dataPhotoHh, dataPhotoHhPrev,
           dataCountUniue, dataCountUniuePrev, dataPicCount, dataPicCountPrev,
           dataDupePhotoDenchu, dataDupePhotoMh, dataDupePhotoHh ] = yield all([
        call(getDataFromRDS, sqlQueryCountPhotoDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoDenchuPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoMH, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoMHPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoHH, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoHHPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountUniqueUser, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountUniqueUserPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPicCount, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPicCountPrev, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoDupeDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoDupeMh, ENDPOINT.GET_DATA_USERS, jwtToken),
        call(getDataFromRDS, sqlQueryCountPhotoDupeHh, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);

    // Denchu
    const countPhotoDenchu = JSON.parse(dataPhotoDenchu.body);
    const countPhotoDenchuValue = countPhotoDenchu[0][0]["longValue"];
    const countPhotoDenchuPrev = JSON.parse(dataPhotoDenchuPrev.body);
    const countPhotoDenchuValuePrev = countPhotoDenchuPrev[0][0]["longValue"];

    // MH
    const countPhotoMh = JSON.parse(dataPhotoMh.body);
    const countPhotoMhValue = countPhotoMh[0][0]["longValue"];
    const countPhotoMhPrev = JSON.parse(dataPhotoMhPrev.body);
    const countPhotoMhValuePrev = countPhotoMhPrev[0][0]["longValue"];

    // HH
    const countPhotoHh = JSON.parse(dataPhotoHh.body);
    const countPhotoHhValue = countPhotoHh[0][0]["longValue"];
    const countPhotoHhPrev = JSON.parse(dataPhotoHhPrev.body);
    const countPhotoHhValuePrev = countPhotoHhPrev[0][0]["longValue"];

    // Unique Users
    const countUniqueUser = JSON.parse(dataCountUniue.body);
    const countUniqueUserValue = countUniqueUser[0][0]["longValue"];
    const countUniqueUserPrev = JSON.parse(dataCountUniuePrev.body);
    const countUniqueUserValuePrev = countUniqueUserPrev[0][0]["longValue"];

    // Pic Count
    const countPicCount = JSON.parse(dataPicCount.body);
    const countPicCountValue = countPicCount[0][0]["stringValue"];
    const countPicCountPrev = JSON.parse(dataPicCountPrev.body);
    const countPicCountValuePrev = countPicCountPrev[0][0]["stringValue"];

    // Denchu Dupe Count
    const countDupeDenchuCount = JSON.parse(dataDupePhotoDenchu.body);
    const countDupeDenchuCountValue = countDupeDenchuCount[0][0]["longValue"];

    // Mh Dupe Count
    const countDupeMhCount = JSON.parse(dataDupePhotoMh.body);
    const countDupeMhCountValue = countDupeMhCount[0][0]["longValue"];

    // Mh Dupe Count
    const countDupeHhCount = JSON.parse(dataDupePhotoHh.body);
    const countDupeHhCountValue = countDupeHhCount[0][0]["longValue"];

    let homePhotoShootData = {
      countDenchu: countPhotoDenchuValue,
      countDenchuPrev: countPhotoDenchuValuePrev,
      countMh: countPhotoMhValue,
      countMhPrev: countPhotoMhValuePrev,
      countHh: countPhotoHhValue,
      countHhPrev: countPhotoHhValuePrev,
      countUniqueUser: countUniqueUserValue,
      countUniqueUserPrev: countUniqueUserValuePrev,
      countPic: parseInt(countPicCountValue),
      countPicPrev: parseInt(countPicCountValuePrev),
      countAsset: countPhotoDenchuValue + countPhotoMhValue + countPhotoHhValue,
      countAssetPrev: countPhotoDenchuValuePrev + countPhotoMhValuePrev + countPhotoHhValuePrev,
      countDupeDenchu: countDupeDenchuCountValue,
      countDupeMh: countDupeMhCountValue,
      countDupeHh: countDupeHhCountValue,
    };

    const index = getIndexForDate(seasonConfig.seasonConfig, rangeTarget[1]);
    let sqlQueryCountProgressDenchu = false;
    if (seasonConfig.seasonConfig[index] !== undefined) {
      sqlQueryCountProgressDenchu = {
        "query": `SELECT COUNT(DISTINCT denchuId) FROM DenchuPhotoShootDenchuEvtProd WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo') BETWEEN '${seasonConfig.seasonConfig[index]["dateRange"][0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
        "database": "KpiDashboard",
        "type": "list"
      };
      const [dataPhotoDenchuProgress] = yield all([
        call(getDataFromRDS, sqlQueryCountProgressDenchu, ENDPOINT.GET_DATA_USERS, jwtToken),
      ]);

      // Denchu Progres Count by season
      const denchuProgressCount = JSON.parse(dataPhotoDenchuProgress.body);
      const denchuProgressValue = denchuProgressCount[0][0]["longValue"];

      homePhotoShootData.progressDenchu = {
        "season": seasonConfig.seasonConfig[index]["season"],
        "range": seasonConfig.seasonConfig[index]["dateRange"],
        "taken": denchuProgressValue,
        "max": seasonConfig.seasonConfig[index]["assetRegistered"]["denchu"],
      }
    }
    yield put(setHomePictreePhotoShoot(homePhotoShootData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_HOME_USER, getPictreeDataHomeUser);
  yield takeEvery(PICTREE.GET_HOME_CHECKIN, getPictreeDataHomeCheckIn);
  yield takeEvery(PICTREE.GET_HOME_PHOTOSHOOT, getPictreeDataHomePhotoShoot);
}
