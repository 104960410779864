import { PUZZLE } from '../constants';

const initialState  = {
    acquisitionDataReg: {
        pmidRegNumTotal: '-',
        pmidRegNumAd: '-',
        pmidRegNumOrg: '-',
        pmidRegNew: '-',
        pmidRegExist: '-',
        pmidRegGender: [],
        pmidRegAgeGroup: [],
    },
    acquisitionDataRegGender: [],
    acquisitionDataRegAgeGroup: [],
    acquisitionDataRegAdVsOrganic: [],
    acquisitionDataRegNewVsExist: [],
    acquisitionDataRegTrend: {
        date: [],
        pmidRegNumTotal: [],
        pmidRegNumAd: [],
        pmidRegNumOrg: [],
        pmidRegExist: [],
        pmidRegNew: [],
    },
    activeUsersData: {
        yesterday: '-',
        yesterdayPrev: '-',
        last7: '-',
        last7Prev: '-',
        last14: '-',
        last14Prev: '-',
        last1mo: '-',
        last1moPrev: '-'
    },
    activeUsersTrendData: {
        date: [],
        activeUsersTrendData: [],
    },
    userEventData: {
        play: '-',
        playPrev: '-',
        clear: '-',
        clearPrev: '-',
        failed: '-',
        failedPrev: '-',
        kesoCount: '-',
        kesoCountPrev: '-',
        kesoTotal: '-',
        kesoTotalPrev: '-',
    },
    userEventTrendData: {
        date: [],
        play: [],
        clear: [],
        failed: [],
        keso: [],
        kesoTotal: [],
    },
    userEventStageClearData: {
        total: '-',
        data: [],
        loading: false,
    },
    userEventStageFailedData: {
        total: '-',
        data: [],
        loading: false,
    },
    dataLoading: false,
};

export default function puzzleReducer(state = initialState, action) {
    switch (action.type) {
        case PUZZLE.SET_ACQUISITION_DATA_REG:
            return {
                ...state,
                acquisitionDataReg: action.payload
            };
        case PUZZLE.SET_ACQUISITION_DATA_LOADING:
            return {
                ...state,
                dataLoading: action.payload
            };
        case PUZZLE.SET_ACQUISITION_DATA_REG_GENDER:
            return {
                ...state,
                acquisitionDataRegGender: action.payload
            };
        case PUZZLE.SET_ACQUISITION_DATA_REG_AGE_GROUP:
            return {
                ...state,
                acquisitionDataRegAgeGroup: action.payload
            };
        case PUZZLE.SET_ACQUISITION_DATA_REG_AD_VS_ORG:
            return {
                ...state,
                acquisitionDataRegAdVsOrganic: action.payload
            };
        case PUZZLE.SET_ACQUISITION_DATA_REG_NEW_VS_EXIST:
            return {
                ...state,
                acquisitionDataRegNewVsExist: action.payload
            };
        case PUZZLE.SET_ACQUISITION_DATA_REG_TREND:
            return {
                ...state,
                acquisitionDataRegTrend: action.payload
            };
        case PUZZLE.SET_ACTIVE_USERS_DATA:
            return {
                ...state,
                activeUsersData: action.payload
            };
        case PUZZLE.SET_ACTIVE_USERS_TREND_DATA:
            return {
                ...state,
                activeUsersTrendData: action.payload
            };
        case PUZZLE.SET_USER_EVENT_DATA:
            return {
                ...state,
                userEventData: action.payload
            };
        case PUZZLE.SET_USER_EVENT_TREND_DATA:
            return {
                ...state,
                userEventTrendData: action.payload
            };
        case PUZZLE.SET_USER_STAGE_CLEAR_DATA:
            return {
                ...state,
                userEventStageClearData: action.payload
            };
        case PUZZLE.SET_USER_STAGE_FAILED_DATA:
            return {
                ...state,
                userEventStageFailedData: action.payload
            };
        default:
            return state
    }
}