import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Checkbox,
  TextField,
  Button,
  Grid,
  Chip,
  Box,
  Collapse,
  IconButton,
  Typography,
  Paper,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { setTxHistorySearchTerm } from "actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) =>
  createStyles({
    calendarWrapper: {
      paddingLeft: 8,
    },
    root: {
      //backgroundColor: '#fafafa',
      zIndex: 1000,
      paddingTop: 16,
      paddingLeft: 8,
      marginTop: -12,
    },
    filterApplyBtn: {
      //marginTop: 12,
      position: "fixed",
      bottom: theme.spacing(5),
      right: theme.spacing(6),
    },
    test: {
      height: 1200,
    },
  })
);

const NftDepTxFilter = ({
  assetSearchLoading,
  assetOption,
  setTxHistorySearchTerm,
  assetSearchTerm,
  property,
}) => {
  const classes = useStyles();
  const [applyBtnState, setApplyBtnState] = useState(true);
  const [assetOptions, setAssetOptions] = useState(assetOption);
  const [assetName, setAssetName] = useState({ name: "" });
  const [assetId, setAssetId] = useState({ name: "" });
  const [byPmid, setByPmid] = useState({ name: "" });
  const [targetName, setTargetName] = useState("");

  useEffect(() => {
    if (property === "item") {
      setTargetName("アイテム");
    } else {
      setTargetName("ASSET");
    }
  }, []);

  const handleChangeOption = (data) => {
    // let optionSelectedArray = [];
    // _.forEach(data, function(item, i) {
    //     optionSelectedArray.push(item.value)
    // });
    // setNftDepTrendOptionSelected(data);
    setApplyBtnState(false);
  };

  const handleInputChange = (data, type) => {
    switch (type) {
      case "asset_name":
        setAssetName({ name: data });
        break;
      case "asset_id":
        setAssetId({ name: data });
        break;
      case "pmid":
        setByPmid({ name: data });
        break;
      default:
    }
    const termObject = {
      type: type,
      value: data,
    };
    let cloneTerm = [...assetSearchTerm];

    const objIndex = cloneTerm.findIndex(
      (termObject) => termObject.type == type
    );
    if (objIndex === -1) {
      cloneTerm.push(termObject);
    } else {
      cloneTerm[objIndex].value = data;
    }

    setTxHistorySearchTerm(cloneTerm);
  };

  const applyFilter = () => {
    console.log("HEYHEY Apply Filter");
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Autocomplete
            size="small"
            id="select-asset"
            value={assetName}
            options={assetOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            onChange={(e, newValue) => {
              handleChangeOption(newValue);
            }}
            onInputChange={(e, newValue) => {
              handleInputChange(newValue, "asset_name");
            }}
            style={{ minWidth: 300 }}
            renderInput={(params) => (
              <TextField {...params} label={`${targetName}名`} />
            )}
            className={classes.autoSelect}
          />
        </Grid>
        {/* <Grid item>
                    <Autocomplete
                        size="small"
                        id="select-asset-id"
                        value={assetId}
                        options={assetOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        onChange={(e, newValue) => {
                            handleChangeOption(newValue);
                        }}
                        onInputChange={(e, newValue) => {
                            handleInputChange(newValue, 'asset_id');
                        }}
                        style={{ minWidth: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="ASSET ID" placeholder={"ASSET ID 全文一致"} />
                        )}
                        className={classes.autoSelect}
                    />
                </Grid> */}
        {/* <Grid item>
                    <Autocomplete
                        size="small"
                        id="select-pmid"
                        value={byPmid}
                        options={assetOptions}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                            </React.Fragment>
                        )}
                        onChange={(e, newValue) => {
                            handleChangeOption(newValue);
                        }}
                        onInputChange={(e, newValue) => {
                            handleInputChange(newValue, 'pmid');
                        }}
                        style={{ minWidth: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="PMID" placeholder={"PMID 全文一致"} />
                        )}
                        className={classes.autoSelect}
                    />
                </Grid> */}
        {/* <Grid item>
          <Button
            //disabled={btnStateDataFetch}
            className={classes.filterApplyBtn}
            variant="contained"
            color="default"
            onClick={() => applyFilter()}
          >
            APPLY
          </Button>
        </Grid> */}
      </Grid>
    </div>
  );
};

NftDepTxFilter.prototypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  assetSearchLoading: state.nftDepTxHisotry.assetSearchLoading,
  assetOption: state.nftDepTxHisotry.assetOption,
  assetSearchTerm: state.nftDepTxHisotry.assetSearchTerm,
});

const mapDispatchToProps = (dispatch) => ({
  setTxHistorySearchTerm: (param) => dispatch(setTxHistorySearchTerm(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(NftDepTxFilter));
