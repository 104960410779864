import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import ChartStyle from "styles/ChartStyle";
import { chartLineWidth } from "styles/variables";
import ChartConfig from "utils/ChartConfig";
import * as V from 'styles/variables';

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    marginTop: 16,
    marginLeft: 16,
    width: "100%",
    display: "block",
  },
}));

const BarStackNormalization = ({
  legend,
  lblSaveImage,
  xAisData,
  seriesData,
  chartType,
  chartSize,
}) => {
  const classes = useStyles();
  const [seriesType, setSeriesType] = useState(chartType);
  const [chartData, setChartData] = useState([]);
  const [color, setColorArray] = useState([]);
  const [stackState, setStackState] = useState("");
  const [zoomStart, setZoomStart] = useState(0);
  const [zoomEnd, setZoomEnd] = useState(100);

  useEffect(() => {
    echarts.registerTheme("BarChartTheme", ChartStyle);
  }, []);

  useEffect(() => {
    if (seriesData.length > 0) {
      setChartData(seriesData);
    }
  }, [seriesData]);

  const magicTypeChanged = (param) => {
    if (param.currentType === "stack") {
      setStackState("__ec_magicType_stack__");
    } else if (param.currentType === "tiled") {
      setStackState(null);
    } else {
      setSeriesType(param.currentType);
    }
  };

  const dataZoomChanged = (param) => {
    setZoomStart(param.batch[0]['start']);
    setZoomEnd(param.batch[0]['end']);
  };

  const onEvents = {
    'magictypechanged': magicTypeChanged,
    'datazoom': dataZoomChanged,
  };

  // const rawData = [
  //   [833, 33, 1, 9, 3, 1, 8], // x1
  //   [22, 0, 0, 0, 0, 0, 0], // x2
  //   [9, 0, 0, 0, 0, 0, 0], // x3
  //   [0, 0, 0, 0, 0, 0, 0], //
  //   [0, 0, 0, 0, 0, 0, 0],
  // ];

  // const totalData = rawData.reduce((acc, curr) => {
  //   curr.forEach((val, index) => {
  //     acc[index] = (acc[index] || 0) + val;
  //   });
  //   return acc;
  // }, []);
  // console.log("YOYO totalData:", totalData);
  const totalData = 4217;

  const series = legend.map((name, sid) => ({
    name,
    type: 'bar',
    stack: 'total',
    barMaxWidth: '40',
    label: {
      show: false,
      //formatter: (params) => (Math.round(params.value * 1000) / 10) + '%'
      //formatter: (params) => params.value > 0 ? `${params.value} (${((params.value / totalData) * 100).toFixed(2)} %)` : ""
      formatter: (params) => params.value > 0 ? `${params.value}` : ""
    },
    data: seriesData[sid].map((d, did) => {
      //return d / totalData[did]; // season 1: 4217, season 2: 5994
      return d;
    })
  }));

  const xAxisData = xAisData;
  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'none'
      }
    },
    legend: {
      //selectedMode: false
    },
    grid: {
      left: 100,
      right: 100,
      top: 50,
      bottom: 50
    },
    yAxis: {
      type: 'value'
    },
    xAxis: {
      type: 'category',
      data: xAxisData
    },
    color: V.genericColorPallette,
    series
  };

  return (
    <div className={classes.chartContainer}>
      <ReactEcharts
        option={option}
        theme="BarChartTheme"
        notMerge={true}
        lazyUpdate={true}
        onEvents={onEvents}
        style={{ height: "400px", width: "98%" }}
      />
    </div>
  );
};

BarStackNormalization.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(BarStackNormalization));
