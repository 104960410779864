import { put, takeEvery, all, call, select } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setStakingDataLoading, setStakingStatData, setStakingTotalData, setPageTopLoader } from 'actions';
import { getJwtToken, getDataFromRDS, getDataFromElasticSearch } from 'api';
import { NFTDEP, ENDPOINT } from '../../constants';
import * as H from 'helper';

export function* getStakingStatData() {
    try {
        const jwtToken = yield call(getJwtToken);
        yield put(setPageTopLoader(true));

        const sourceStaking = H.ElasticSearch.getStakingStatData();
        const esQueryAsset = {
            "es": "daa",
            "query": "simpleSearch",
            "index": "user_staking_plans",
            "src": `${sourceStaking}`
        };

        const [
            dataStakingTemp,
        ] = yield all([
            call(getDataFromElasticSearch, esQueryAsset, ENDPOINT.GET_DATA_ES_DAA, jwtToken),
        ]);

        const stakingStatData = dataStakingTemp.aggregations.terms.buckets;
        let stakingObj = {}, stakingDataArray = [];
        _.each(stakingStatData, function(data) {
            stakingObj.plan = Number(data.key);
            stakingObj.dep = Math.ceil(data.dep_amount.value);
            stakingObj.count = data.doc_count;
            stakingObj.paid = Math.ceil(data.interest_paid.value);
            stakingDataArray.push(stakingObj);
            stakingObj = {};
        });
        const sortedData = _.sortBy(stakingDataArray, ['plan']);

        yield put(setStakingStatData(sortedData));
        yield put(setPageTopLoader(false));

    } catch (err) {
        console.log(err);
    }
}

export default function* watchPageSettings() {
    yield takeEvery(NFTDEP.GET_STAKING_STAT_DATA, getStakingStatData);
}
