// Doc https://material-table.com/#/docs/all-props https://github.com/mbrn/material-table

import React, { Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from "material-table";
import { withStyles } from "@material-ui/core/styles";
import * as H from "helper";

const Top10AmountByCountry = ({
  classes,
  title,
  source,
  loading,
  dataByCountry,
  selectedCurrency,
  depPrice,
  dateRange,
  dataInGameItemByCountry,
}) => {
  const [geoData, setGeoData] = useState([]);

  useEffect(() => {
    let dataSource = [];
    switch (source) {
      case "auction":
        dataSource = dataByCountry.amount.auction;
        break;
      case "store":
        dataSource = dataByCountry.amount.store;
        break;
      case "fiat":
        dataSource = dataByCountry.amount.fiat;
        break;
      case "ingame-fiat":
        dataSource = dataInGameItemByCountry.amount.fiat;
        break;
      case "ingame-dep":
        dataSource = dataInGameItemByCountry.amount.dep;
        break;
      default:
    }
    let totalNum = _.sumBy(dataSource, (data) => {
      return data.value;
    });
    let dataArray = [],
      dataObj = {};
    for (let i = 0; i < dataSource.length; i++) {
      dataObj.no = i + 1;
      dataObj.url =
        dataSource[i]["country"] === "(not set)" ||
        dataSource[i]["country"] === "n/a"
          ? false
          : H.CountryHelper.getCountryCode(dataSource[i]["country"]);
      dataObj.country = dataSource[i]["country"];
      dataObj.num_amount =
        selectedCurrency === "dep"
          ? dataSource[i]["value"]
          : calcConvert(selectedCurrency, dataSource[i]["value"]);
      dataObj.num_ratio = ((dataSource[i]["value"] / totalNum) * 100).toFixed(
        2
      );
      dataArray.push(dataObj);
      dataObj = {};
    }
    setGeoData(dataArray);
  }, [dataByCountry, selectedCurrency, dataInGameItemByCountry]);

  const getCurrencyLbl = (selectedCurrency) => {
    let lblToReturn = "取引額";
    if (selectedCurrency === "usd") {
      lblToReturn = lblToReturn + " (US$)";
    } else if (selectedCurrency === "sgd") {
      lblToReturn = lblToReturn + " (S$)";
    } else if (selectedCurrency === "jpy") {
      lblToReturn = lblToReturn + " (¥)";
    }
    return lblToReturn;
  };

  const calcConvert = (currency, amount) => {
    let amtReturn = 0;
    if (currency === "usd") {
      amtReturn = amount * depPrice.usd;
    } else if (currency === "sgd") {
      amtReturn = amount * depPrice.sgd;
    } else if (currency === "jpy") {
      amtReturn = amount * depPrice.jpy;
    }
    return amtReturn;
  };

  return (
    <MaterialTable
      isLoading={loading.ovByCountry}
      style={{ padding: 16, fontSize: 14, minHeight: 280 }}
      title={
        <div style={{ fontSize: 18, fontWeight: 500, color: "#000" }}>
          {title}
        </div>
      }
      columns={[
        {
          title: "#",
          field: "no",
          width: 50,
          cellStyle: { textAlign: "center" },
        },
        {
          title: "国",
          field: "country",
          width: 350,
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex" }}>
                <div>
                  {rowData.url && (
                    <img
                      src={rowData.url}
                      style={{ paddingTop: 2, paddingRight: 8, width: 24 }}
                    />
                  )}
                </div>
                <div style={{ paddingTop: 4 }}>
                  {rowData.country === "n/a" ? "不明" : rowData.country}
                </div>
              </div>
            </Fragment>
          ),
        },
        {
          title: getCurrencyLbl(selectedCurrency),
          field: "num_amount",
          width: 200,
          type: "numeric",
          cellStyle: { textAlign: "center" },
          render: (rowData) => (
            <div>
              {H.FormatNumStrHelper.formatCommaThousand(rowData.num_amount)}
            </div>
          ),
        },
        {
          title: "全体比率",
          field: "num_ratio",
          type: "numeric",
          cellStyle: { textAlign: "left", marginLeft: 32 },
          render: (rowData) => (
            <Fragment>
              <div style={{ display: "flex", height: 16 }}>
                <div style={{ paddingRight: 16, width: 24 }}>
                  {rowData.num_ratio}%
                </div>
                <div
                  style={{
                    backgroundColor: "#65C9A5",
                    width: rowData.num_ratio + "%",
                    marginLeft: 20,
                    marginTop: 3,
                  }}
                ></div>
              </div>
            </Fragment>
          ),
        },
      ]}
      data={geoData}
      options={{
        search: false,
        paging: false,
        sorting: false,
        exportButton: { csv: true },
        exportFileName: `${dateRange[0]}-${dateRange[1]} ${title}`,
        rowStyle: {
          textAlign: "center",
        },
        headerStyle: { textAlign: "center" },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: "表示可能なデータがありません",
        },
      }}
    />
  );
};

Top10AmountByCountry.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  // loading: state.nftDepOverview.loading,
  dataByCountry: state.nftDepOverview.dataByCountry,
  selectedCurrency: state.page.selectedCurrency,
  depPrice: state.page.depPrice,
  dateRange: state.page.dateRange,
  dataInGameItemByCountry: state.inGameItemSales.dataByCountry,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Top10AmountByCountry));
